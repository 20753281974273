import React, {useEffect} from "react";
import CustomerMainLayout from '../CustomerMainLayout'
import { CheckCircleOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments'
import FileConstants from "../../../../FileConstants";
import axios from "axios";

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const AmazonOauth = ()=> {

    useEffect(()=> {
        const params = new URLSearchParams(window.location.search);
        const stateToken =  params.get('state');
        const authCode = params.get('spapi_oauth_code');
        const localStateToken = localStorage.getItem("amazonStateToken")
        if ((stateToken && localStateToken && authCode) && (stateToken == localStateToken)) {
            let payload = {
                'company_id': COMPANY_ID,
                'customer_username': CUSTOMER_USERNAME,
                'action': "get_auth",
                'auth_code': authCode
            }
            axios.post(ENVIRONMENT.AWS_AUTH_LAMBDA_HELPER, payload).then((res)=>{
                console.log(res.data);
            })
        }
    },[])

        return (
            <CustomerMainLayout >
                <div style={{width:"100%", height: "100%",display:"flex", alignItems:"center", padding:"40px", flexDirection:"column"}}>
                    <p className="manrope f-24" style={{color:"green"}}>Authorization Successfull <CheckCircleOutlined /></p>
                    <p className="manrope f-16" style={{marginTop: "40px"}} > Press the Amazon button on PDP to upload product content on Amazon </p>
                    <img style={{width:"800px", borderRadius:"8px", filter: "drop-shadow(2px 4px 6px rgb(0,0,0,.2))"}} className="pdp-image" src={require("../../../../assets/images/amazon-pdp.jpg")} alt = "amazon-pdp" />
                </div>
            </CustomerMainLayout>
        );
}

export default AmazonOauth;
