import React, { useEffect, useState } from 'react';
import { Row, Col, Breadcrumb } from 'antd';
import ENVIRONMENT from '../../../../environments';
import DottedLoader from '../../DottedLoader';
import axios from 'axios';
import TemplateDetails from '../TemplateDetails/TemplateDetails';
import CustomerMainLayout from '../CustomerMainLayout';


const CustomerTemplateRenders = (props) => {
    const { match: { params } } = props;
    const [templateDetails, setTemplateDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {

        let payload = {
            id: params.id,
            action: 'get'
        }
        axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
            .then(res => {
                console.log(res.data[0])
                if (res.data && res.data[0]) {
                    setTemplateDetails(res.data[0]);
                }
                setLoading(false);
            })
    }, []);

    return (<CustomerMainLayout selected="6">
        {loading ? <DottedLoader /> :
            <Row style={{ padding: 24 }}>
                <Col span={24}>
                    <Breadcrumb className='justify-in-start'>
                        <Breadcrumb.Item>
                            <a className='manrope f-14' href='/store'>Scenes on Store</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <div className='manrope f-14 black-33'>{templateDetails.name}</div>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>

                <TemplateDetails templateDetails={templateDetails} />
            </Row>}
    </CustomerMainLayout>);
}

export default CustomerTemplateRenders;