import React from "react";
import { Button, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import AddItemToStore from "../../AddItemToStore";

const StoreActions = ({
  is_store_item,
  setRemoveFromStore,
  setSellLoader,
  setRemoveLoader,
  setSellVisible,
  removeFromStore,
  removeLoader,
  sellVisible,
  price,
  sellLoader,
  cancelSellModal,
  roomDetails,
  room_id,
  onChangePrice,
}) => {
  const updateStore = (payload) => {
    axios
      .post(ENVIRONMENT.SET_STORE_PRICE, payload)
      .then((response) => {
        setSellLoader(false);
        setRemoveLoader(false);
        setRemoveFromStore(false);
        if (is_store_item == -2) {
          message.info("Price updated successfully!");
        } else if (is_store_item == 1) {
          message.info("Item removed from store successfully!");
        } else {
          message.info("Price submitted successfully!");
        }
        window.location.href = "/rooms";
      })
      .catch((error) => {
        setSellLoader(false);
        console.log(error);
        message.error("Error.");
      });
  };

  const setStoreRequests = () => {
    let payload = {
      room_id: room_id,
    };
    //for assigning to admin
    if (is_store_item == -2) {
      payload.is_store_item = -2;
    } else if (is_store_item == 1) {
      payload.is_store_item = 0;
    } else {
      payload.is_store_item = -2;
    }

    let price_payload = {
      entity_id: room_id,
      customer_username: localStorage.getItem("username"),
      entity_type: "room",
      proposed_price: price,
    };
    setRemoveLoader(true);
    setSellLoader(true);
    console.log(price_payload);
    axios
      .post(ENVIRONMENT.UPDATE_ROOM, payload)
      .then((res) => {
        if (is_store_item == -2) {
          price_payload.action = "update";
        } else if (is_store_item == 1) {
          price_payload.action = "remove";
        } else {
          price_payload.action = "insert";
        }

        updateStore(price_payload);
      })
      .catch((error) => {
        setRemoveLoader(false);
        console.log(error);
        message.error("Error in removing from store.");
      });
  };

  const removeItemFromStore = () => {
    let payload = {
      is_store_item: 0,
      room_id: room_id,
    };
    console.log(payload);
    setRemoveLoader(true);
    axios
      .post(ENVIRONMENT.UPDATE_ROOM, payload)
      .then((res) => {
        setStoreRequests();
      })
      .catch((error) => {
        setRemoveLoader(false);
        console.log(error);
        message.error("Error in removing from store.");
      });
  };

  return (
    <>
      <AddItemToStore
        type={"customer_details"}
        entity_type={"room"}
        visible={sellVisible}
        price={price}
        add_store_item_loader={sellLoader}
        onCancel={cancelSellModal}
        selected_item={roomDetails}
        onChangePrice={onChangePrice}
        addToStore={setStoreRequests}
      />
      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        onCancel={() => setRemoveFromStore(false)}
        visible={removeFromStore}
        closable={false}
        footer={[
          <span className="justify-in-center">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay-gray square font-12"
              onClick={() => setRemoveFromStore(false)}
            >
              Cancel
            </Button>
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-12"
              onClick={() => removeItemFromStore()}
            >
              {removeLoader ? (
                <span>
                  Removing <LoadingOutlined spin />
                </span>
              ) : (
                <span> Okay </span>
              )}
            </Button>
          </span>,
        ]}
      >
        <div
          style={{
            margin: "30px auto 0px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="manrope f-16 black-00 w-600">
            You're about to remove this space from store. Are you sure you want
            to remove?
          </h2>
        </div>
      </Modal>
    </>
  );
};

export default StoreActions;
