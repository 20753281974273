import React, { useEffect, useState } from "react";
import { Table,Button,Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import useTableFilter from "../../UseTableFilter/useTableFilter";
import DottedLoader from "../../DottedLoader";

const FreeTrial = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [getColumnSearchProps] = useTableFilter();
  const [isModalVisible, setIsModalVisible] = useState(false);


  const getSubscriptionTableColumns = () => {
    return [
      {
        title: "Username",
        dataIndex: "customer_username",
        key: "customer_username",
        ...getColumnSearchProps("customer_username"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Company",
        dataIndex: "company_name",
        key: "company_name",
        ...getColumnSearchProps("company_name"),
        render: (text, record) => (
          <a
            target="_blank"
            onClick={() => window.location.href = '/admin-company-usage/' + record.company_id}
          >
            {text}
          </a>
        ),
      },
      {
        title: "Subscription Package",
        dataIndex: "subscription_package",
        key: "subscription_package",
      },
      {
        title: "Account Manager",
        dataIndex: "account_manager_username",
        key: "account_manager_username",
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Button type="link" onClick={() => handleAssignAccountManager(record)}>
            Make me Account Manager
          </Button>
        ),
      },
    ];
  };
  const handleAssignAccountManager = async (record) => {
    const payload = record.company_name && record.company_name.trim() !== ""
    ? {
        company_id: parseInt(record.company_id, 10),
        account_manager: localStorage.getItem('username'),
        action: "assign_account_manager",
      }
    : record.customer_username && record.customer_username.trim() !== ""
    ? {
        customer_username: record.customer_username,
        account_manager: localStorage.getItem('username'),
        action: "assign_account_manager",
      }
    : null;
    try {
      await axios.post(ENVIRONMENT.ACCOUNT_MANAGER_CONTROLLER, payload);
      setIsModalVisible(true); 
    } catch (error) {
      console.error("Error assigning account manager:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsTableDataLoading(true);
      try {
        const payload = {
          subscription_package: "Free Trial",
        };
        const res = await axios.post(ENVIRONMENT.FUNNEL_TABLES_DATA, payload);
        const data = res.data;

        setDataSource(data);
        setFilteredDataSource(data); 
        props.setFreeTrialSizeCount(data.length);
      } catch (error) {
        console.error("Error fetching free trial users:", error);
      } finally {
        setIsTableDataLoading(false);
      }
    };

    fetchData();
  }, [props.setFreeTrialSizeCount]);

  useEffect(() => {
    let filteredData = dataSource;

    if (props.selectedManagers.length > 0) {
      filteredData = filteredData.filter(user =>
        props.selectedManagers.includes(user.account_manager_username)
      );
    }

    if (props.selectedCompanies.length > 0) {
      filteredData = filteredData.filter(user =>
        props.selectedCompanies.includes(user.company_name)
      );
    }

    setFilteredDataSource(filteredData);
    props.setFreeTrialSizeCount(filteredData.length);
  }, [props.selectedManagers, props.selectedCompanies, dataSource, props.setFreeTrialSizeCount]);

  return (
    <div className="free-trial-container">
      {isTableDataLoading ? (
        <DottedLoader />
      ) : (
        <Table
          dataSource={filteredDataSource}
          columns={getSubscriptionTableColumns()}
          scroll={{ x: true }}
          style={{ overflowX: "scroll" }}
        />
      )}

      <Modal
        title="Success"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <p>Account Manager assigned successfully!</p>
      </Modal>
    </div>
  );
};

export default FreeTrial;
