export const LIST_COLLECTION = 'LIST_COLLECTION';
export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const COLLECTION_SUCCESS = 'COLLECTION_SUCCESS';
export const COLLECTION_FAILURE = 'COLLECTION_FAILURE';
export const FETCH_COLLECTION_PRODUCTS = 'FETCH_COLLECTION_PRODUCTS';
export const FETCH_COLLECTION_ROOMS = 'FETCH_COLLECTION_ROOMS';
export const DELETE_COLLECTION_PRODUCT = 'DELETE_COLLECTION_PRODUCT';
export const listCollections = (coll) => ({
  type: LIST_COLLECTION,
  payload: coll,
});
export const createCollection = (coll) => ({
  type: CREATE_COLLECTION,
  payload: coll,
});
export const updateCollection = (coll) => ({
  type: UPDATE_COLLECTION,
  payload: coll,
});
export const getCollectionProducts = (coll) => ({
  type: FETCH_COLLECTION_PRODUCTS,
  payload: coll,
});
export const getCollectionRooms = (coll) => ({
  type: FETCH_COLLECTION_ROOMS,
  payload: coll,
});
export const collectionSuccessAction = (coll) => ({
  type: COLLECTION_SUCCESS,
  payload: coll,
});
export const collectionFailureAction = (error) => ({
  type: COLLECTION_FAILURE,
  payload: error,
});
export const deleteCollectionProduct = (coll) => ({
  type: DELETE_COLLECTION_PRODUCT,
  payload: coll,
});
