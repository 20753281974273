import * as THREE from "three";
import {HorizontalBlurShader} from "three/examples/jsm/shaders/HorizontalBlurShader";
import {VerticalBlurShader} from "three/examples/jsm/shaders/VerticalBlurShader";

export default class ContactShadows {

    camera = null; 
    scene = null;
    renderer = null;
    stats = null;
    model = null;
    meshes = [];
    state = null;
    shadowGroup = null;
    plane = null;
    fillPlane = null;
    enableShadows = false;
    modelBbox = null;
    constructor(camera, scene, renderer, modelBbox) {
        this.camera = camera;
        this.scene = scene;
        this.renderer = renderer;
        this.state = {
            shadow: {
                blur: 5,
                darkness: 5,
                opacity: 1,
                maxDarkness: 10
            },
            plane: {
                color: '#ffffff',
                opacity: 1,
            }
        };
        this.modelBbox = modelBbox;
        this.addShadows();
    }
    
    changePlaneColor(color) {
        this.state.plane.color = color;
        this.fillPlane.material = new THREE.MeshBasicMaterial({
            blending: THREE.MultiplyBlending,
            blendSrc: THREE.SrcColorFactor,
            blendDst: THREE.DstColorFactor,
        });
    }

    addShadows() {
               
        const PLANE_WIDTH = (this.modelBbox.max.x - this.modelBbox.min.x) * 1.2
        const PLANE_HEIGHT = (this.modelBbox.max.z - this.modelBbox.min.z) * 1.2
        const CAMERA_HEIGHT = (this.modelBbox.max.y - this.modelBbox.min.y) * 0.7;

        // like Mesh depthMaterial, but goes from black to transparent
        this.depthMaterial = new THREE.ShaderMaterial({
            uniforms: {
                darkness: {
                    value: this.state.shadow.darkness
                },
                maxDarkness: {
                    value: this.state.shadow.maxDarkness
                }
            },
            fragmentShader: `
            uniform float darkness;
            uniform float maxDarkness;
            void main() {
                gl_FragColor = vec4( 0, 0, 0, 1.0 * darkness / maxDarkness );
            }`
        });
        
        // calculating the shadow offset 
        let modelHeight = this.modelBbox.max.y - this.modelBbox.min.y;
        let shadowsOffset = 0.01;
        if ( modelHeight < 0.5 ) {
            shadowsOffset = modelHeight *  0.01;
        }
                
        // the container, if you need to move the plane just move this
        this.shadowGroup = new THREE.Group();
        this.shadowGroup.position.y = this.modelBbox.min.y - shadowsOffset;
        this.scene.add( this.shadowGroup );

        // the render target that will show the shadows in the plane texture
        this.renderTarget = new THREE.WebGLRenderTarget( 512, 512 );
        this.renderTarget.texture.generateMipmaps = false;

        // the render target that we will use to blur the first render target
        this.renderTargetBlur = new THREE.WebGLRenderTarget( 512, 512 );
        this.renderTargetBlur.texture.generateMipmaps = false;


        // make a plane and make it face up
        const planeGeometry = new THREE.PlaneGeometry( PLANE_WIDTH, PLANE_HEIGHT ).rotateX( Math.PI / 2 );
        const planeMaterial = new THREE.MeshBasicMaterial( {
            map: this.renderTarget.texture,
            opacity: this.state.shadow.opacity,
            transparent: true,
            depthWrite: false,
            colorWrite: true,
        } );
        this.plane = new THREE.Mesh( planeGeometry, planeMaterial );
        // make sure it's rendered after the fillPlane
        this.plane.renderOrder = 2;
        this.shadowGroup.add( this.plane );

        // the y from the texture is flipped!
        this.plane.scale.y = - 1;

        this.blurPlane = new THREE.Mesh( planeGeometry );
        this.blurPlane.visible = false;
        this.shadowGroup.add( this.blurPlane );

        // the plane with the color of the ground
        const fillPlaneMaterial = new THREE.MeshBasicMaterial( {
            color: this.state.plane.color,
            opacity: this.state.plane.opacity,
            transparent: true,
            depthWrite: false,
            colorWrite: true,
        } );
        this.fillPlane = new THREE.Mesh( planeGeometry, fillPlaneMaterial );
        this.fillPlane.rotateX( Math.PI );
        this.shadowGroup.add( this.fillPlane );

        // the camera to render the depth material from
        this.shadowCamera = new THREE.OrthographicCamera( - PLANE_WIDTH / 2, PLANE_WIDTH / 2, PLANE_HEIGHT / 2, - PLANE_HEIGHT / 2, 0, CAMERA_HEIGHT );
        this.shadowCamera.rotation.x = Math.PI / 2; // get the camera to look up
        this.shadowGroup.add( this.shadowCamera );

        this.horizontalBlurMaterial = new THREE.ShaderMaterial( HorizontalBlurShader );
        this.horizontalBlurMaterial.depthTest = false;

        this.verticalBlurMaterial = new THREE.ShaderMaterial( VerticalBlurShader );
        this.verticalBlurMaterial.depthTest = false;

    }
    blurShadow( amount ) {

        this.blurPlane.visible = true;

        // blur horizontally and draw in the renderTargetBlur
        this.blurPlane.material = this.horizontalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTarget.texture;
        this.horizontalBlurMaterial.uniforms.h.value = amount * 1 / 256;

        this.renderer.setRenderTarget( this.renderTargetBlur );
        this.renderer.render( this.blurPlane, this.shadowCamera );

        // blur vertically and draw in the main renderTarget
        this.blurPlane.material = this.verticalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTargetBlur.texture;
        this.verticalBlurMaterial.uniforms.v.value = amount * 1 / 256;

        this.renderer.setRenderTarget( this.renderTarget );
        this.renderer.render( this.blurPlane, this.shadowCamera );

        this.blurPlane.visible = false;

    }


     animate( ) {

        if (this.enableShadows == false) {
            return;
        }
       
        // remove the background
        const initialBackground = this.scene.background;
        this.scene.background = null;

        // force the depthMaterial to everything
        this.scene.overrideMaterial = this.depthMaterial;

        // render to the render target to get the depths
        this.renderer.setRenderTarget( this.renderTarget );
        this.renderer.render( this.scene, this.shadowCamera );

        // and reset the override material
        this.scene.overrideMaterial = null;

        this.blurShadow( this.state.shadow.blur )
		this.blurShadow( this.state.shadow.blur * 0.4 );

        // reset and render the normal this.scene
        this.renderer.setRenderTarget( null );
        this.scene.background = initialBackground;


    }

    hideShadows() {
        this.enableShadows = false;
        this.shadowGroup.visible = false;
    }

    showShadows() {
        this.enableShadows = true;
        this.shadowGroup.visible = true;
    }

}

			