import React, { useState, useEffect, useRef } from 'react';
import CircularSlider from 'react-circular-slider-svg';
import './SunControls.scss';

const SunElevation = (props) => {
    const [sliderSize, setSliderSize] = useState(0);
    const parentRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                const parentWidth = parentRef.current.offsetWidth;
                setSliderSize(Math.floor(parentWidth * 0.7));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const svgElement = document.querySelector('#svg-container-2 svg');
        if (svgElement) {
            svgElement.setAttribute('viewBox', `0 0 ${sliderSize} ${sliderSize}`);
            svgElement.setAttribute('width', '100%');
            svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');
        }
    }, [sliderSize]);

    const imgSize = sliderSize * 0.15; // Adjust as needed
    const lineThickness = Math.floor(sliderSize * 0.02);
    const lineLength = sliderSize * 0.5;

    return (
        <div
        id="svg-container-2"
        ref={parentRef}
        style={{
            position: 'relative',
            left: sliderSize/2,
            transform: 'translateX(-50%)'
            }}>
            <img className="house-image"
                style={{ width: imgSize, height: imgSize }}
                src={require("../../../assets/images/house.svg")}
                alt="House"
            />
            <div className='bottom-line'
                style={{ borderTop: `${lineThickness}px solid #1f79e0`, width: lineLength }}
            ></div>
            <div className='left-line'
                style={{ borderLeft: `${lineThickness}px solid #1f79e0`, height: lineLength }}
            ></div>
            {sliderSize > 0 && (
                <CircularSlider
                    size={sliderSize}
                    trackWidth={Math.floor(sliderSize * 0.03)}
                    minValue={0}
                    maxValue={90}
                    startAngle={180}
                    endAngle={270}
                    handle1={{
                        value: props.value,
                        onChange: v => {
                            props.onChange(v);
                        }
                    }}
                    arcColor="#7caee8"
                    arcBackgroundColor="#7caee8"
                    coerceToInt={true}
                    handleSize={Math.floor(sliderSize * 0.05)}
                    style={{ zIndex: 2 }}
                />
            )}
        </div>
    );
};

export default SunElevation;
