import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Input, Card, Select, Row, Col, Button, InputNumber } from "antd";
import AdminMainLayout from '../AdminMainLayout';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import MaterialTable from "../MaterialTable/MaterialTable";

const { TabPane } = Tabs;
const { Option } = Select;

class AdminMaterialJobs extends React.Component {
  state = { size: "small" };

  constructor(props){
    super(props)
    let customer_username = new URLSearchParams(window.location.search).get("customer");  
    if (customer_username == undefined) {
      customer_username = ""
    }

    this.state = {
      customer_username_filter: customer_username,
      admin_qa_count: null,
      admin_view_count: null,
      admin_rejected_count: null,
      being_fixed_count: null,
      failed_aborted_count:null,
      enqueued_count: null,
      artist_review_count: null,
      customers_names: [],
      filter_product_id: "",
    };  
  }

  componentDidMount(){
    this.populateCustomersList();  
  }

  populateCustomersList = () => {
    axios.post(ENVIRONMENT.CUSTOMER_GET_BATCH, {
      })
        .then(res => {
          var customers_names = [];
          for (let i = 1; i <= (res.data).length; i++) {
            customers_names.push(res.data[i - 1]['customer_username']);

          }
          
          this.setState({
            customers_names: customers_names
          })
        })
    
  }

  setAdminQACount = (value) => {
    this.setState({
      admin_qa_count: value
    });
  }

  setAdminViewCount = (value) => {
    this.setState({
      admin_view_count: value
    });
  }

  setAdminRejectedCount = (value) => {
    this.setState({
      admin_rejected_count: value
    });
  }

  setBeingFixedCount = (value) => {
    this.setState({
      being_fixed_count: value
    });
  }

  setFailedAbortedCount = (value) => {
    this.setState({
      failed_aborted_count: value
    });
  }

  setEnqueuedCount = (value) => {
    this.setState({
      enqueued_count: value
    });
  }

  setArtistReviewCount = (value) => {
    this.setState({
      artist_review_count: value
    });
  }

  onChange = value => {
    this.setState({
      customer_username_filter: value
    });
  };

  onFilterIdChange = value => {
    this.setState({
      filter_product_id: value
    });
  }

  applyCustomerFilter = () => {
    window.location.href = "/admin_materials?customer=" + this.state.customer_username_filter
  }

  applyIdFilter = () => {
    window.location.href = "/admin_materials/" + this.state.filter_product_id
  }

  render() {
    const { size } = this.state;
    const { customer_username_filter } = this.state;

    return (
      <AdminMainLayout selected={"16"}>
        <Row style={{marginTop: 24}} gutter={24}>
          <Col span={4}>
            <p style={{margin: 3, fontSize: 16}}>
              <b>Go to Material Id:</b>
            </p>
          </Col>
          <Col span={4}>
            <InputNumber style={{ width: "100%" }} onChange={this.onFilterIdChange} />
          </Col>
          <Col span={4}>
                <Button type="primary" onClick={() => this.applyIdFilter()} disabled={(this.state.filter_product_id == "")}>Apply Filter</Button>
          </Col>
        </Row>
        <Tabs style={{marginTop: 32}}  defaultActiveKey="1" tabBarStyle={{textAlign: 'center'}} animated={false}>
          <TabPane tab={(this.state.admin_qa_count==null)?"QA Pending":"QA Pending (" + this.state.admin_qa_count + ")"} key="1" forceRender={this.state.customer_username_filter != ""}>
            <MaterialTable
              customer_username_filter={customer_username_filter}
              state="admin_review"
              setCount={this.setAdminQACount}
              adminTable={true}
            />
          </TabPane>
         
          <TabPane tab={(this.state.admin_rejected_count==null)?"Being Fixed":"Being Fixed (" + this.state.admin_rejected_count + ")"} key="3" forceRender={this.state.customer_username_filter != ""}>
            <MaterialTable
              customer_username_filter={customer_username_filter}
              state="admin_rejected"
              setCount={this.setAdminRejectedCount}
              adminTable={true}
            />
          </TabPane>
          <TabPane tab={(this.state.being_fixed_count==null)?"Enqueued":"Enqueued (" + this.state.being_fixed_count + ")"} key="4" forceRender={this.state.customer_username_filter != ""}>
            <MaterialTable
              customer_username_filter={customer_username_filter}
              state="being_processed"
              setCount={this.setBeingFixedCount}
              adminTable={true}
            />
          </TabPane>
          <TabPane tab={(this.state.failed_aborted_count==null)?"Failed & Aborted":"Failed & Aborted (" + this.state.failed_aborted_count + ")"} key="5" forceRender={this.state.customer_username_filter != ""}>
            <MaterialTable
              customer_username_filter={customer_username_filter}
              state="failed_aborted"
              setCount={this.setFailedAbortedCount}
              adminTable={true}
            />
          </TabPane>        
        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminMaterialJobs;