import React, { useState, useRef } from 'react';
import { Input, Button, Form, Row, Col, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { INVITE_USER } from '../../../../environments/env';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';

const { Option } = Select;

const AddCustomerForm = (props) => {
    const formRef = useRef();
    const {onSuccess} = props
    const [email, setEmail] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [failureModal, setFailureModal] = useState(false);
    const [faliureMessage, setFailureMessage] = useState('');
    const [faliureHeading, setFailureHeading] = useState('');
    const [successModal, setSuccessModal] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState('');
    const [error, setError] = useState(null);

    const handleSendInvite = () => {
        setButtonLoader(true);
        const payload = {
            email: email.toLowerCase(),
            type: 'customer',
            subscription_type: subscriptionType,
            invited_by: localStorage.getItem('username')
        };

        axios.post(INVITE_USER, payload)
            .then(res => {
                if (res.data === 1 || res.data === "1") {
                    setSuccessModal(true);
                } else if (res.data === 0 || res.data === "0" ) {
                    setFailureModal(true);
                    setFailureHeading("User already exists!");
                    setFailureMessage("Oops. A user already exists on the email you entered.");
                } else if (res.data === -1 || res.data === "-1") {
                    setFailureModal(true);
                    setFailureHeading("Invite already sent!");
                    setFailureMessage("Oops. An invite is already sent to the email you entered.");
                }
                setButtonLoader(false);
            })
            .catch(err => {
                setError("An error occurred. Please try again.");
                setButtonLoader(false);
            });
    };

    const changeEmail = (event) => setEmail(event.target.value);

    const selectSubscription = (value) => setSubscriptionType(value);

    return (
        <>
            <Row className="justify-space-between" style={{ marginTop: 30, marginBottom: 10 }}>
                <Col>
                    <h3 className="manrope f-28 w-900">
                        Customer Account Invite
                    </h3>
                </Col>
            </Row>
            <div className="custom-frm" style={{ maxWidth: 400, marginBottom: '20px' }}>
                <Form className="input-form login-form" ref={formRef} onFinish={handleSendInvite}>
                    <div className="manrope f-14 black-55" style={{ marginTop: 10, marginBottom: 10 }}>
                        Email <span style={{ color: 'red' }}>*</span>
                    </div>
                    <Form.Item
                        name="email"
                        rules={[
                            { type: 'email', message: 'Please enter a valid email.' },
                            { required: true, message: 'Please enter an email.' },
                            { pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: "Spaces are not allowed, whitespace found at the end." },
                        ]}
                    >
                        <Input
                            disabled={buttonLoader}
                            className="manrope f-16 black-55 library-search"
                            onChange={changeEmail}
                            placeholder="Email"
                        />
                    </Form.Item>
                        <Form.Item rules={[{ required: true, message: 'Please select a valid subscription package.' }]} name="payment_type">
                            <Select showArrow disabled={buttonLoader} onSelect={selectSubscription} className="manrope f-16 black-55 tree-select-material" placeholder="Subscription Package">
                                <Option value="freemium" className="manrope f-16 select-view">Freemium</Option>
                                <Option disabled value="basic" className="manrope f-16 select-view">Basic</Option>
                                <Option disabled value="pro" className="manrope f-16 select-view">Pro</Option>
                                <Option disabled value="premium" className="manrope f-16 select-view">Premium</Option>
                                <Option value="enterprise" className="manrope f-16 select-view">Enterprise</Option>
                            </Select>
                        </Form.Item>
                    {error && <div className="text-danger">{error}</div>}
                    <Button style={{ marginTop: 15 }} disabled={buttonLoader} id="assignment-submit-button" className="modal-okay square font-16" type="primary" htmlType="submit">
                        Send Invite {buttonLoader && <LoadingOutlined />}
                    </Button>
                </Form>
            </div>
            <SuccessModal
                visible={successModal}
                heading="Account Invite Sent!"
                text="Account Invite has been sent on the entered email."
                footer={[
                    <div className="justify-in-center">
                        <Button style={{ margin: "auto" }} className="modal-okay square font-14" onClick={() => {
                            onSuccess()
                        }}>
                            Okay
                        </Button>
                    </div>
                ]}
            />
            <WarningModal
                visible={failureModal}
                onCancel={() => setFailureModal(false)}
                heading={faliureHeading}
                text={faliureMessage}
                footer={[
                    <div className="justify-in-end">
                        <Button style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => setFailureModal(false)}>
                            Okay
                        </Button>
                    </div>
                ]}
            />
        </>
    );
};

export default AddCustomerForm;
