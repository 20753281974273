import React from "react";
import axios from 'axios';
import "antd/dist/antd.css";
import ENVIRONMENTS from '../../../../environments'
import ArtistMainLayout from '../ArtistMainLayout';
import '@ant-design/compatible/assets/index.css';
import {  Row, Col, Button, message, Select, Typography, Table, Image } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";

const { Option } = Select;
const { Text } = Typography;
const DATE_FORMAT = 'MM/DD/YYYY';
const days_of_week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']


class ArtistSettings extends React.Component {
  state = {
    profile_id: '',
    artist_username: '',
    profile_name: '',
    email: '',
    billing_type: '',
    is_active: false,
    hourly_rate: "",
    rating: 0,
    availability: {},
    data_source: [],
    artist_data_fetched: false,
    edit_availability: false,
    edit_button_loader: false,
  };

  componentDidMount() {
    axios.post(ENVIRONMENTS.GET_ARTIST_PROFILE, {
      artist_username: localStorage.getItem('username')
    })
      .then(res => {
        console.log('artist = ', res.data);
        this.setState({
          profile_id: res.data['artist_id'],
          artist_username: res.data['artist_username'],
          profile_name: res.data['profile_name'],
          email: res.data['email'],
          billing_type: res.data['billing_type'],
          hourly_rate: res.data['hourly_rate'],
          is_active: res.data['is_active'],
          rating: res.data['rating'],
          availability: res.data['availability'],
          artist_data_fetched: true
        }, () =>this.setDataSource())
      })
  }

  handleChange = (value, elem_id) => {
    let availability_hours = this.state.availability;
    availability_hours[elem_id] = value;
    this.setState({
      availability: availability_hours
    })

  }
  
  getReadableAvailabilityValue = (value) => {
    if(value == 0)
      return 'off';
    else
      return value;
  }


  setDataSource = () => {
    let table_data = [];
    days_of_week.map((ele, index) => {
      table_data.push({
        key: index,
        day: ele,
        hours: 
          <React.Fragment>
            {/* if editing, display the select  */}
            {this.state.edit_availability ? 
            <span>
              <Select id={ele} onChange={(value, elem) => this.handleChange(value, ele)} defaultValue={this.state.availability[ele]} className="availability-hours-select" size={"large"}>
                {
                ['off' , 1, 2, 3, 4, 5, 6, 7, 8].map((element, array_index) => {
                  return <Option value={array_index}>{element}</Option>
                })
              }
              </Select>

            </span> : this.getReadableAvailabilityValue(this.state.availability[ele])
        }
          </React.Fragment>
      })
    })
    this.setState({
      data_source: table_data
    })
  }

  display_edit_save_availability_button = () => {
    if(this.state.edit_availability){
      return <Button disabled={this.state.edit_button_loader} className="save-button font-12" onClick={() => this.updateAvailabilityHours()}>
        Save &nbsp;{this.state.edit_button_loader ? <LoadingOutlined/>: ''}
      </Button> 
    }
    else{
      return <Button className="save-button font-12" onClick={()=>{this.setState({edit_availability: true}, () => {this.setDataSource()})}}>
        Edit
      </Button>
    }
  }

  hoursSum = () => {
    let total_hours = 0;
    for (const [key, value] of Object.entries(this.state.availability)){
      if(!isNaN(value))
        total_hours += value;
    } 
    return total_hours;
  }

  updateAvailabilityHours = () => {
    this.setState({
      edit_button_loader: true
    })
    let payload = {
      'profile_id': this.state.profile_id,
      'availability': this.state.availability
    }

    axios.post(ENVIRONMENTS.UPDATE_ARTIST_PROFILE, payload).then(
      res=> {
        // Update state and re-render the table
        this.setState({
          edit_availability: false,
          edit_button_loader: false
        }, ()=> this.setDataSource());
        message.info('Your Availability Information Updated.')
      }
    )

  }

  columns = [
    {
      title: 'Days',
      dataIndex: 'day',
      width: '50%',
      align: 'center'
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      width: '50%',
      align: 'center'
    },
  ]

  render() {
    return (
        <ArtistMainLayout selected='5'>
          {this.state.artist_data_fetched ? 
          <React.Fragment>
            <Row className="artist-settings-profile">
              <Col span={3}>
                <div>
                  <Image src="/img/artist-profile-img.svg" preview={false}>
                  </Image>
                </div>
              </Col>

              <Col span={21}>
                <div style={{paddingLeft: 20, paddingTop: 12}}>
                  <div>
                    <p style={{fontSize: 20, fontWeight: 600, marginBottom: 12}}>{this.state.profile_name}</p>
                  </div>
                  { this.state.email ? 
                    <div style={{display: 'flex'}}>
                      <p className="artist-personal-info">Email: </p>
                      <p className="artist-personal-info" style={{paddingLeft: 3}}>{this.state.email}</p>
                    </div>
                    : ''
                  }
                </div>
              </Col>

            </Row>

            {/* Artist Availability info */}
            <Row style={{marginTop: 25}}>
              <Col span={17}>
                <span style={{fontSize: 20, fontWeight: 600}}>My Availability</span>
              </Col>

              <Col span={7} style={{alignSelf: 'center', display: 'flex', justifyContent: 'flex-end'}}>
                {this.display_edit_save_availability_button()}
              </Col>

            </Row>
            <Row style={{marginTop: 20}}>
              <Col span={24}>
                <Table
                columns={this.columns}
                bordered={true}
                dataSource={this.state.data_source}
                pagination={false}

                // sum of days in the footer
                summary={pageData => {
                  let total_hours = 0;
                  total_hours = this.hoursSum();
          
                  return (
                    <>
                      <Table.Summary.Row style={{backgroundColor: '#FAFAFA', textAlign: 'center', fontWeight: 'bold'}}>
                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text>{total_hours}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
                >
                </Table>
              </Col>
            </Row>
          </React.Fragment>
          :  <DottedLoader/> }
          
        </ArtistMainLayout>
    );
  }
}

export default ArtistSettings;