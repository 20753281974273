import React,{useState} from 'react';
import { Logout } from "react-cognito";
import { Layout, Button, Progress, Modal } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import ENVIRONMENT from "../../../../environments";
import axios from 'axios';
import './ArtistAssignmentResults.scss';
import DottedLoader from '../../DottedLoader';

const { Content } = Layout;

function ArtistTestPass({
  colorAccuracy,
  dimensionalAccuracy,
  uvAccuracy,
  vrayAccuracy,
  pbrAccuracy,
  geometryAccuracy,
  additionalNotes,
  hourlyRate,
  billingType,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getWidth = (value) => {
    return value * 10;
  };
  const getColor = (value) => {
    if (value) {
      if (value > 0 && value <= 5) {
        return "#E19B12";
      } else {
        return "#29B862";
      }
    } else {
      return "#e0e0e0";
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("all3d_jwt_token");
    localStorage.removeItem("all3d_jwt_token");
    localStorage.removeItem("managed_customer_username");
    localStorage.removeItem("managed_company_id");
    localStorage.removeItem("managed_company_name")
    localStorage.removeItem("is_msprovider");
    localStorage.removeItem("managed_customer_email");
    localStorage.removeItem("username");
    localStorage.removeItem("company_id");
    window.history.pushState("ALL3D Console", "ALL3D Console", "/");
    window.location.href = "/";
  };

  const onSubmit = () => {
    const payload = {
      username: localStorage.getItem("username"),
      action: "add_artist_to_portal",
    };
    setIsLoading(true);
    console.log(payload);
    axios.post(ENVIRONMENT.TEST_ARTIST_CONTROLLER, payload).then((res) => {
      console.log(res.data);
      setIsLoading(false);
      showModal();
    });
  };

  return (
    <>
      {isLoading ? (
        <DottedLoader />
      ) : (
        <>
          <Content className="layout-container layout-snow" id="layout-section">
            <div className="scored-results-container">
              <img
                className="scored-results-img"
                alt="Artist Test Passed"
                src={require("../../../../assets/images/happy.png")}
              />
              <h1 className="manrope f-16 w-600 mb-10 ">
                Congratulations {localStorage.getItem("username")}!
              </h1>
              <p className="manrope f-16 w-400 mb-28 text-center letter-spacing-pt2">
                You have successfully passed our test. Please review a summary
                of your test results below. Click on Let's Get Started to get
                started on the portal.
              </p>
              <div className="test-results-container mb-20">
                <div className="manrope w-500 f-16 title">Test Results</div>
                <div className="matrix-container">
                  <div className="head-container">
                    <div>Geometry Accuracy</div>
                    <div>{geometryAccuracy}/10</div>
                  </div>
                  <div>
                    <Progress
                      percent={getWidth(geometryAccuracy)}
                      showInfo={false}
                      strokeColor={getColor(geometryAccuracy)}
                      trailColor="#e0e0e0"
                    />
                  </div>
                </div>
                <div className="matrix-container">
                  <div className="head-container">
                    <div>Vray Accuracy</div>
                    <div>{vrayAccuracy}/10</div>
                  </div>
                  <div>
                    <Progress
                      percent={getWidth(vrayAccuracy)}
                      showInfo={false}
                      strokeColor={getColor(vrayAccuracy)}
                      trailColor="#e0e0e0"
                    />
                  </div>
                </div>
                <div className="matrix-container">
                  <div className="head-container">
                    <div>PBR Accuracy</div>
                    <div>{pbrAccuracy}/10</div>
                  </div>
                  <div>
                    <Progress
                      percent={getWidth(pbrAccuracy)}
                      showInfo={false}
                      strokeColor={getColor(pbrAccuracy)}
                      trailColor="#e0e0e0"
                    />
                  </div>
                </div>
                <div className="matrix-container">
                  <div className="head-container">
                    <div>Color Accuracy</div>
                    <div>{colorAccuracy}/10</div>
                  </div>
                  <div>
                    <Progress
                      percent={getWidth(colorAccuracy)}
                      showInfo={false}
                      strokeColor={getColor(colorAccuracy)}
                      trailColor="#e0e0e0"
                    />
                  </div>
                </div>
                <div className="matrix-container">
                  <div className="head-container">
                    <div>Dimensional Accuracy</div>
                    <div>{dimensionalAccuracy}/10</div>
                  </div>
                  <div>
                    <Progress
                      percent={getWidth(dimensionalAccuracy)}
                      showInfo={false}
                      strokeColor={getColor(dimensionalAccuracy)}
                      trailColor="#e0e0e0"
                    />
                  </div>
                </div>
                <div className="matrix-container">
                  <div className="head-container">
                    <div>UV</div>
                    <div>{uvAccuracy}/10</div>
                  </div>
                  <Progress
                    percent={getWidth(uvAccuracy)}
                    showInfo={false}
                    strokeColor={getColor(uvAccuracy)}
                    trailColor="#e0e0e0"
                  />
                </div>
                {additionalNotes === null || additionalNotes === "" ? (
                  ""
                ) : (
                  <>
                    <div className="matrix-container">
                      <div className="head-container manrope f-16 w-500">
                        <div className="mb-10">Note</div>
                      </div>
                      <div className="notes-container manrope f-14 w-600">
                        {additionalNotes}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Button
                className="outlined-button manrope f-16 w-500"
                type="primary"
                onClick={onSubmit}
              >
                Let's Get Started <ArrowRightOutlined />
              </Button>
            </div>
            <Modal
              title="Note"
              className=""
              maskClosable={false}
              onCancel={handleCancel}
              visible={isModalOpen}
              width={"40%"}
              centered={true}
              footer={[
                <div className="justify-in-end" key="submit">
                  <Logout onLogout={clearLocalStorage}>
                    <Button className="modal-okay square font-14">OK</Button>
                  </Logout>
                </div>,
              ]}
            >
              <p>
                You will have to login again to unlock your ALL3D experience as
                an Artist.
              </p>
            </Modal>
          </Content>
        </>
      )}
    </>
  );
}

export default ArtistTestPass;
