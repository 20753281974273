import React from 'react'
import { InfoCircleOutlined, LoadingOutlined, PlusCircleFilled } from '@ant-design/icons';
import { ReactComponent as MoreIcon } from '../../../icons/more.svg'
import { Card, Dropdown, Menu } from 'antd';


function SiloPreviewShots(props) {
    
    const getMenuOptions = (name, index,resolution) => {
        return (
            <Menu>
                <Menu.Item>
                    <a className="manrope f-14 black" onClick={() => {
                        props.setEditCamera(true);
                        props.setTakeSiloShotModal(true);
                        props.selectOrientation("Custom");
                        props.onImageWidthRes(resolution.split('x')[0]);
                        props.onImageHeightRes(resolution.split('x')[1]);
                        props.setImageWidth(resolution.split('x')[0]);
                        props.setImageHeight(resolution.split('x')[1]);
                    }}>
                        Edit</a>
                </Menu.Item>
                <Menu.Item>
                    <a className="manrope f-14 black" onClick={() => props.deleteSnapshot(name,index)}>Delete</a>
                </Menu.Item>
            </Menu>
        )
    }
    
  return (
    <div style={{height:"94%", justifyContent:"space-evenly", display:"flex", flexDirection:"column", margin:"2% 4%"}}>
        { props.camera.length !== 0 ? 
            <>
                <span className='manrope f-14 black silo-heading-main' >
                    Create Shot List 
                </span>
                <span className='manrope f-20 black'>
                    Select all that apply from these standard angles or set up your own custom angles.
                    <p style={{marginTop:"0.75vh"}} className='manrope f-14 black'>
                        <InfoCircleOutlined style={{marginRight:"5px"}}/>
                        Note: These are snapshots of the 3D model, not rendered images.
                    </p>
                </span>
                <div className='custom-scroll' style={{height:"75%", width:"67vw", display:"flex", flexWrap:"wrap", alignContent:"flex-start", overflowY:"scroll"}}>
                    {props.camera.map ( (view, index) => (
                        <Card style={{background:''+props.hexColor+'', textAlign:'center'}} onClick={()=> props.addSelectedCamera(index,view.name)} key={index} className={props.selectedCameras.includes(view.name)?'card-silo-preview-bordered':'card-silo-preview'}>
                            <img src={view.thumbnail}/>
                            <div>
                                <Dropdown overlay={getMenuOptions(view.name, index, view.resolution)} className='delete-silo'>
                                    <MoreIcon style={{background:"#FAFAFA", padding:"2px", borderRadius:"4px"}}/>
                                </Dropdown>
                            </div>
                            <span style={{position:"absolute", bottom:"4%"}} className='manrope f-14 black'>
                                {view.camera_display_name == '' || view.camera_display_name == undefined ? view.name : view.camera_display_name}
                            </span>
                        </Card>
                    ))}
                    <Card style={{background:'#FFFFFF', textAlign:'center'}} onClick={()=>{
                            props.setDefaultLighting()
                            props.setEditCamera(false);
                            props.setTakeSiloShotModal(true);
                        }}
                        className='card-silo-preview-no-border'>
                        <PlusCircleFilled style={{fontSize:"50px",color:'#276DD7'}}/>
                        <span style={{color:"#276DD7", marginTop:"20px"}} className='manrope f-14'>
                            Add Custom Angle
                        </span>
                    </Card>
                </div>
            </>
            :
            <div style={{fontSize:"28px", position:"fixed", top:"46vh", left:"45vw", display:"flex", flexDirection:"column"}} >
                <LoadingOutlined  size="large" spin />
                <span className='manrope f-14 black' style={{marginTop:"1vh", marginLeft:"4px"}}>
                    {props.loadedProgress}%
                </span>
            </div>        
        }
    </div>
  )
}

export default SiloPreviewShots