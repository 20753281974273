import React from 'react';
import { Modal, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
 
const DeleteEntityModal = (props) => {

    return (
    <Modal
    className="modal-share-success"
    width={620}
    visible={props.visible}
    onCancel={props.onCancel}
    closable={false}
    footer={[
        <span className="justify-in-center">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={props.onCancel}>
                Cancel
            </Button>
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => props.delete_model(props.selected_id)}>
                {props.deletingModelLoader? <span> Deleting <LoadingOutlined spin /></span>:<span> Okay </span>}
            </Button>
        </span>
    ]}
    centered={true}>
    <div style={{padding:"40px 40px 0 40px",textAlign:"center"}}>
      <div>
          <h2 className="manrope f-24 black-14 justify-in-center">{props.heading}</h2>
          <p className="manrope f-16 grey-77">{props.subText}</p>
      </div>
    </div>           
  </Modal>
      );
}

export default DeleteEntityModal;