import React from 'react';
import AdminMainLayout from '../AdminMainLayout';
import CustomerAccountUsage from '../../CustomerComponents/CustomerAccountUsage/CustomerAccountUsage';

const AdminSystemUsage = (props) => {
   
    const { match: { params } } = props;
   
    return (
        <AdminMainLayout selected="9">
            <CustomerAccountUsage
            account_type={"admin"}
            username={params.username}
            company_id={params.company_id}
            />
        </AdminMainLayout>
    );

}

export default AdminSystemUsage;
  