import React from 'react';
import PropTypes from 'prop-types';
import { LockOutlined, SyncOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { Input, Button, Checkbox, Row, Col, Form } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class NewPasswordRequiredForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
      password: '',
    };
  }

  onSubmit = () => {
    // console.log(this.state.password);
    // event.preventDefault();
    this.props.onSubmit(this.state.password);
  }

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  render = () => {
    const formDiv = {
      'display': 'flex',
      'justifyContent': 'space-around',
      'margin-top': '40px',
    }

    const formStyle = {
      'background-color': 'white',
      'borderRadius': '5px'

    }

    const logoStyle = {
      'display': 'flex',
      'justifyContent': 'space-around',
      'marginBottom': '30px'
    }
    return <Row>
        <Col span={12}>
          <img className="branding-image" src={require("../../../../assets/images/login-branding.jpg")} />
        </Col>
        <Col span={12}>
          <div className="input-form custom-frm" style={formDiv}>
            <div style={formStyle}>
              <div style={formDiv}>
                <div style={formStyle}>
                {/* <form onSubmit={this.onSubmit}>
                  <div>{this.props.error}</div>
                  <label>
                    Password
                    <input type="password" placeholder="new password" onChange={this.changePassword} required />
                  </label>
                  <button type="submit">Set new password</button>
                </form> */}
                <Form onFinish={this.onSubmit} onFinishFailed={(e) => console.log(e) } className="login-form" style={{ width: "300px" }}>
                    <h3 className="text-center text-login-logo"> <span>All</span><span className="text-red">3D</span> </h3>
                    <p>You must set your password on first login</p>
                    <Form.Item name='password'
                      rules={[{ required: true, message: 'Please input your Password!' },
                      {pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$", message: "Password should be atleast 8 characters including atleast one uppercase, one lowser, one special character and a digit" }
                    ]}
                    >
                        
                        <Input
                          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                          type="password"
                          placeholder="Password"
                          onChange={this.changePassword}
                        />
                    </Form.Item>
                    <Form.Item >

                      <Button type="danger" htmlType="submit" className="login-form-button">
                        Set New Password {this.state.isLoading ? <LoadingOutlined spin /> : ''}
                      </Button>
                    </Form.Item>
                    <div className="text-danger text-center">{this.props.error}</div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    
  }
}
NewPasswordRequiredForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

export default NewPasswordRequiredForm;
