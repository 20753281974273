import * as THREE from "three"

var WallMarker = function () {

	var _unit = {
        X: new THREE.Vector3( 1, 0, 0 ),
        Y: new THREE.Vector3( 0, 1, 0 ),
        Z: new THREE.Vector3( 0, 0, 1 ),
        aX: new THREE.Vector3( -1, 0, 0 ),
        aY: new THREE.Vector3( 0, -1, 0 ),
        aZ: new THREE.Vector3( 0, 0, -1 ),
	};

	THREE.Object3D.call( this );

	// aX == Negative X Axis && aY == Negative Y Axis. This should always work. Change only if needed.
	this.horizontalMovementDir = _unit.aX;
	this.verticalMovementDir = _unit.aY;

	this.zOffset = 0.0;

	// Pass wall object as first param and preferred wall normal as second param.
	this.setForWall = function ( wall, preferredDirection ) {

		this.wallBBox = new THREE.Box3().setFromObject ( wall );
		this.preferredDirection = preferredDirection;

		// This will align the marker with the wall such that the marker will be facing the wall. This will ensure easy raycasting later on.
		this.lookAt( this.getWorldPosition( new THREE.Vector3() ).add( this.preferredDirection.clone().negate() ) );
	};

	this.resetPosition = function () {
		
		this.position.copy(this.wallBBox.min);

		if(Math.abs(this.preferredDirection.x.toFixed(3)) >= Math.abs(this.preferredDirection.z.toFixed(3)) ) {
			if(this.preferredDirection.x > 0) {
				this.position.x = this.wallBBox.max.x;
	
				if(this.preferredDirection.z > 0) {
					this.position.z = this.wallBBox.min.z;
				}
				else {
					this.position.z = this.wallBBox.max.z;
				}
			}
			else {
				this.position.x = this.wallBBox.min.x;
	
				if(this.preferredDirection.z >= 0) {
					this.position.z = this.wallBBox.min.z;
				}
				else {
					this.position.z = this.wallBBox.max.z;
				}
			}

			if(this.preferredDirection.z != 0 && Math.sign(this.preferredDirection.x) == Math.sign(this.preferredDirection.z)) {
				this.horizontalMovementDir = _unit.X;
			}
		}
		else {
			if(this.preferredDirection.z > 0) {
				this.position.z = this.wallBBox.max.z;
	
				if(this.preferredDirection.x > 0) {
					this.position.x = this.wallBBox.min.x;
				}
				else {
					this.position.x = this.wallBBox.max.x;
				}
			}
			else {
				this.position.z = this.wallBBox.min.z;
	
				if(this.preferredDirection.x >= 0) {
					this.position.x = this.wallBBox.min.x;
				}
				else {
					this.position.x = this.wallBBox.max.x;
				}
			}
			
			if(this.preferredDirection.z != 0 && Math.sign(this.preferredDirection.x)!= Math.sign(this.preferredDirection.z)) {
				this.horizontalMovementDir = _unit.X;
			}
		}

		// Change the offset if needed
		this.translateOnAxis( _unit.Z, this.zOffset );

		let wallSize = new THREE.Vector3()
        this.wallBBox.getSize(wallSize);
		this.moveVertically(- wallSize.y + 0.02);
	}

	this.moveForward = function ( offset ) {

		this.translateOnAxis( _unit.aZ, offset );

	};

	this.moveHorizontally = function ( offset ) {

		this.translateOnAxis( this.horizontalMovementDir, offset );

	};

	this.moveVertically = function ( offset ) {

		this.translateOnAxis( this.verticalMovementDir, offset );

	};

	this.getRayOriginsFromMarker = function ( objWidth, objHeight ) {

		let origins = {};

		origins.o1 = this.getWorldPosition( new THREE.Vector3() );

		this.moveHorizontally( objWidth );
		origins.o2 = this.getWorldPosition( new THREE.Vector3() );

		this.moveVertically( objHeight );
		origins.o3 = this.getWorldPosition( new THREE.Vector3() );
		
		this.moveHorizontally( -objWidth );
		origins.o4 = this.getWorldPosition( new THREE.Vector3() );

		// Bringing it back to its original position
		this.moveVertically( - objHeight );

		return origins;

	}
};

WallMarker.prototype = Object.assign( Object.create( THREE.Object3D.prototype ), {
    constructor: WallMarker,
    isWallMarker: true
} );

export { WallMarker };