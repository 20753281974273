/**
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 * @author ScieCode / http://github.com/sciecode
 */

import * as THREE from "three";
import { SpotLightHelper } from "three";

// This set of controls performs orbiting, dollying (zooming), and panning.
// Unlike TrackballControls, it maintains the "up" direction object.up (+Y by default).
//
//    Orbit - left mouse / touch: one-finger move
//    Zoom - middle mouse, or mousewheel / touch: two-finger spread or squish
//    Pan - right mouse, or left mouse + ctrl/meta/shiftKey, or arrow keys / touch: two-finger move

var OrbitControlsSilo = function ( object, domElement, lightGroup ) {

	if ( domElement === undefined ) console.warn( 'THREE.OrbitControls: The second parameter "domElement" is now mandatory.' );
	if ( domElement === document ) console.error( 'THREE.OrbitControls: "document" should not be used as the target "domElement". Please use "renderer.domElement" instead.' );

	this.object = object;
	this.domElement = domElement;
	this.lightGroup = lightGroup;

	// Set to false to disable this control
	this.enabled = true;

	// "target" sets the location of focus, where the object orbits around
	this.target = new THREE.Vector3();

	// How far you can dolly in and out ( PerspectiveCamera only )
	this.minDistance = 0;
	this.maxDistance = Infinity;

	// How far you can zoom in and out ( OrthographicCamera only )
	this.minZoom = 0;
	this.maxZoom = Infinity;

	// How far you can orbit vertically, upper and lower limits.
	// Range is 0 to Math.PI radians.
	this.minPolarAngle = 0; // radians
	this.maxPolarAngle = Math.PI; // radians

	// How far you can orbit horizontally, upper and lower limits.
	// If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
	this.minAzimuthAngle = - Infinity; // radians
	this.maxAzimuthAngle = Infinity; // radians


	this.minPolarAngleSet = 0;
	this.maxPolarAngleSet = Math.PI;

	// Set to true to enable damping (inertia)
	// If damping is enabled, you must call controls.update() in your animation loop
	this.enableDamping = false;
	this.dampingFactor = 0.05;

	// This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
	// Set to false to disable zooming
	this.enableZoom = true;
	this.zoomSpeed = 1.0;

	// Set to false to disable rotating
	this.enableRotate = true;
	this.rotateSpeed = 1.0;

	// Set to false to disable panning
	this.enablePan = true;
	this.panSpeed = 1.0;
	this.screenSpacePanning = true; // if true, pan in screen-space
	this.keyPanSpeed = 7.0;	// pixels moved per arrow key push

	// Set to true to automatically rotate around the target
	// If auto-rotate is enabled, you must call controls.update() in your animation loop
	this.autoRotate = false;
	this.autoRotateSpeed = 2.0; // 30 seconds per round when fps is 60

	// Set to false to disable use of the keys
	this.enableKeys = true;

	// The four arrow keys
	this.keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 };

	// Mouse buttons
	this.mouseButtons = { LEFT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.DOLLY, RIGHT: THREE.MOUSE.PAN };

	// Touch fingers
	this.touches = { ONE: THREE.TOUCH.ROTATE, TWO: THREE.TOUCH.DOLLY_PAN };

	// for reset
	this.target0 = this.target.clone();
	this.initialStart = this.target.clone();
	this.position0 = this.object.position.clone();
	this.zoom0 = this.object.zoom;

	this.polarAngle = 0;
	this.azimuthalAngle = 0;

	this.stateAngle = -1;
	this.restrictedState = false;
	this.boundariesThreshold = 0.8;
	this.rotationBoundary = 2.0;
	this.sliderPosition = 100;
	this.sliderLimit = 200;
	this.sliderRange = 900;
	this.panX = this.sliderLimit/2;
	this.panXBound = this.panX;
	this.sliderBound = this.sliderPosition;
	this.horizontalFOV = 0;
	this.horizontalBound = this.sliderLimit;
	this.modelPos = null;
	this.state = false;
	this.currNDCPoint = null;


	// public methods
	//

	this.getPolarAngle = function () {

		return spherical.phi;

	};

	this.getAzimuthalAngle = function () {

		return spherical.theta;

	};

	this.setPolarAngle = function(angle) {
		if ( !scope.enabled) {
			return;
		}
		this.polarAngle = angle;
		this.stateAngle = 1;
		scope.update(); 
		scope.updateVerticalSlider();
		scope.updateHorizontalSlider();
		
	}

	this.setAzimuthalAngle = function(angle) {
		if ( !scope.enabled) {
			return;
		}
		this.azimuthalAngle = angle
		this.stateAngle = 2;
		scope.update(); 
		scope.updateVerticalSlider();
		scope.updateHorizontalSlider();
		
	}
	
	

	this.setRestrictedState = function(state) {
		this.restrictedState = state;
		if (this.restrictedState == true) {
			this.minPolarAngle = this.getPolarAngle() - Math.PI/6; // radians
			this.maxPolarAngle = this.getPolarAngle() + Math.PI/6; // radians
		
			// How far you can orbit horizontally, upper and lower limits.
			// If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
			this.minAzimuthAngle = this.getAzimuthalAngle() - Math.PI/6; // radians
			this.maxAzimuthAngle = this.getAzimuthalAngle() + Math.PI/6; // radians
			this.boundariesThreshold = 0.4;
			this.state = true;
		}
		else {
			this.minPolarAngle = this.minPolarAngleSet; // radians
			this.maxPolarAngle = this.maxPolarAngleSet; // radians
		
			// How far you can orbit horizontally, upper and lower limits.
			// If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
			this.minAzimuthAngle = - Infinity; // radians
			this.maxAzimuthAngle = Infinity; // radians
			this.boundariesThreshold = 0.8;
			this.state = false;
		}
		scope.update();
	}

	this.setPanningLimits = function(threshold) {
		this.boundariesThreshold = threshold;
	}

	this.setHorizontalFOV = function(fov) {
		this.horizontalFOV = fov;
	}

	this.setFocalLength = function(value) {
		this.object.setFocalLength(value);
	}

	this.saveState = function () {

		scope.target0.copy( scope.target );
		scope.position0.copy( scope.object.position );
		scope.zoom0 = scope.object.zoom;

	};

	this.reset = function () {

		scope.panX = scope.sliderLimit/2;
		scope.panXBound = scope.panX;
		scope.sliderPosition = scope.sliderLimit/2;
		scope.sliderBound = scope.sliderLimit/2;
		scope.target.copy( scope.target0 );
		scope.object.position.copy( scope.position0 );
		scope.object.zoom = scope.zoom0;
		scope.object.updateProjectionMatrix();
		scope.dispatchEvent( changeEvent );
		scope.update();
		state = STATE.NONE;

	};


	this.setModelPosition = function(modelPos) {

		this.modelPos = modelPos;

	}

	this.setRotationBoundary = function(rotation) {
		this.rotationBoundary = 1.0;
	}

	this.updatePan = function(deltaX, deltaY) {
		pan(deltaX, deltaY);
		scope.update();
	}

	this.updateVerticalPan = function (sliderPosition) {
		if ( !scope.enabled) {
			return;
		}
		var position = scope.object.position;
		var element = scope.domElement;
		var offset = new THREE.Vector3();
		offset.copy( position ).sub( scope.target );
		var targetDistance = offset.length();
		targetDistance *= Math.tan( ( scope.object.fov / 2 ) * Math.PI / 180.0 );
		var diff = - sliderPosition + scope.sliderPosition;
		var distance = (2 * targetDistance / element.clientHeight) * (element.clientHeight/scope.sliderLimit) * diff;
		panUp (distance,  scope.object.matrix);
		scope.sliderPosition = sliderPosition;
		scope.sliderBound = scope.sliderPosition;
		scope.update();
	}

	this.updateHorizontalPan = function (sliderPosition) {
		if ( !scope.enabled) {
			return;
		}
		var position = scope.object.position;
		var element = scope.domElement;
		var offset = new THREE.Vector3();
		offset.copy( position ).sub( scope.target );
		var targetDistance = offset.length();
		targetDistance *= Math.tan( ( scope.horizontalFOV / 2 ) * Math.PI / 180.0 );
		var diff = - sliderPosition + scope.panX;
		var distance = (2 * targetDistance / element.clientHeight) * (element.clientHeight/scope.sliderLimit) * diff;
		panLeft (distance,  scope.object.matrix);
		scope.panX = sliderPosition;
		scope.panXBound = scope.panX;
		scope.update();
	}

	this.setHorizontalBoundary = function () {
		scope.horizontalBound = scope.getHorizontalPosition() * 2;
		
	}

	this.resetHorizontalBoundary = function () {
		scope.horizontalBound = scope.sliderLimit;
	}

	this.resetHorizontalPanning = function () {
		scope.updateHorizontalPan(100);
	}

	this.resetPanning = function () {
		scope.updateVerticalPan(100);
	}

	this.zoomIn = function() {
		dollyIn( getZoomScale() );
		scope.update();
		if (scope.restrictedState == false) {
			scope.updateVerticalSlider();
			scope.updateHorizontalSlider();	
		}
		
	}

	this.zoomOut = function() {
		dollyOut( getZoomScale() );
		scope.update();
		if (scope.restrictedState == false) {
			scope.updateVerticalSlider();
			scope.updateHorizontalSlider();	
		}
	}

	this.updateVerticalSlider = function () {

		if ( !scope.enabled) {
			return;
		}
		if (scope.currNDCPoint) {
			var ndcpoint_intial = scope.currNDCPoint.clone();
			var ndcpoint_new = scope.getNDCPoint(scope.modelPos.clone(), scope.object);
			scope.sliderBound += ((ndcpoint_intial.y - ndcpoint_new.y) * scope.sliderLimit)/ (scope.domElement.clientHeight);
			if (scope.sliderBound >=0 && scope.sliderBound <= scope.sliderLimit) {
				scope.sliderPosition = scope.sliderBound;
			}
			else {
				scope.sliderBound = scope.sliderPosition;
			}
		}
		
	}

	this.updateHorizontalSlider = function () {
		if ( !scope.enabled) {
			return;
		}
		if(scope.currNDCPoint) {
			var ndcpoint_intial = scope.currNDCPoint.clone();
			var ndcpoint_new = scope.getNDCPoint(scope.modelPos, scope.object);
			var diff = ((ndcpoint_intial.x - ndcpoint_new.x) * scope.horizontalBound)/ (scope.domElement.clientWidth) ;
			scope.panXBound += diff;
			if (scope.panXBound >0 && scope.panXBound < scope.horizontalBound) {
				scope.panX = scope.panXBound;
			}
			else {
				scope.panXBound = scope.panX;
			}
		}
		
	}

	this.getSliderPosition = function () {
		return scope.sliderPosition;
	}

	this.setSliderPosition = function (sliderPos) {
		if ( !scope.enabled) {
			return;
		}		scope.sliderPosition = sliderPos;
		scope.sliderBound = sliderPos;
	}

	this.getHorizontalPosition = function () {
		return scope.panX;
	}

	this.setHorizontalPosition = function (sliderPos) {
		if ( !scope.enabled) {
			return;
		}
		scope.panX = sliderPos;
		scope.panXBound = sliderPos;
	}

	this.limitWheeling = function() {
		let flag = true;
		// check for models position for the corners;
		var npoint = scope.worldToNDC(scope.modelPos, scope.object);
		if (npoint.x <= -scope.boundariesThreshold || npoint.x >= scope.boundariesThreshold
			|| npoint.y <= -scope.boundariesThreshold|| npoint.y >= scope.boundariesThreshold){ // moving left
			flag = false;
		}
		return !flag;	
	}


	this.limitRotation = function () {
		let flag = true;
		// check for models position for the corners;
		if (scope.modelPos) {
			var npoint = scope.worldToNDC(scope.modelPos, scope.object);
			if (npoint.x <= -scope.rotationBoundary || npoint.x >= scope.rotationBoundary
				|| npoint.y <= -scope.rotationBoundary|| npoint.y >= scope.rotationBoundary){ // moving left
				flag = false;
			}
		}
		
		return !flag;	
	}



	// this method is exposed, but perhaps it would be better if we can make it private...
	this.update = function () {
		
		var offset = new THREE.Vector3();
		// so camera.up is the orbit axis
		var quat = new THREE.Quaternion().setFromUnitVectors( object.up, new THREE.Vector3( 0, 1, 0 ) );
		var quatInverse = quat.clone().inverse();

		var lastPosition = new THREE.Vector3();
		var lastQuaternion = new THREE.Quaternion();

		return function update() {

			if (scope.modelPos) {
				scope.currNDCPoint = scope.getNDCPoint(scope.modelPos.clone(), scope.object);
			}

			var prevPos = scope.object.position.clone();
			var prevSpherical = spherical.clone();
			var prevTarget = scope.target.clone();
			var position = scope.object.position;

			offset.copy( position ).sub( scope.target );

			// rotate offset to "y-axis-is-up" space
			offset.applyQuaternion( quat );

			// angle from z-axis around y-axis
			spherical.setFromVector3( offset );

			if ( scope.autoRotate && state === STATE.NONE ) {

				rotateLeft( getAutoRotationAngle() );

			}


			
			if (scope.stateAngle == 1) {
				spherical.phi = scope.polarAngle;
			}
			else if (scope.stateAngle == 2) {
				spherical.theta = scope.azimuthalAngle;
			}
			
			
			if ( scope.enableDamping ) {

				spherical.theta += sphericalDelta.theta * scope.dampingFactor;
				spherical.phi += sphericalDelta.phi * scope.dampingFactor;

			} else {

				spherical.theta += sphericalDelta.theta;
				spherical.phi += sphericalDelta.phi;

			}
			scope.stateAngle = -1;


			// restrict theta to be between desired limits
			spherical.theta = Math.max( scope.minAzimuthAngle, Math.min( scope.maxAzimuthAngle, spherical.theta ) );

			// restrict phi to be between desired limits
			spherical.phi = Math.max( scope.minPolarAngle, Math.min( scope.maxPolarAngle, spherical.phi ) );


			spherical.makeSafe();


			spherical.radius *= scale;

			// restrict radius to be between desired limits
			spherical.radius = Math.max( scope.minDistance, Math.min( scope.maxDistance, spherical.radius ) );

			// move target to panned location

			if ( scope.enableDamping === true ) {

				scope.target.addScaledVector( panOffset, scope.dampingFactor );

			} else {

				scope.target.add( panOffset );

			}

			offset.setFromSpherical( spherical );

			// rotate offset back to "camera-up-vector-is-up" space
			offset.applyQuaternion( quatInverse );

			position.copy( scope.target ).add( offset );

			scope.object.lookAt( scope.target );

			if ( scope.enableDamping === true ) {

				sphericalDelta.theta *= ( 1 - scope.dampingFactor );
				sphericalDelta.phi *= ( 1 - scope.dampingFactor );

				panOffset.multiplyScalar( 1 - scope.dampingFactor );

			} else {

				sphericalDelta.set( 0, 0, 0 );

				panOffset.set( 0, 0, 0 );

			}
			

			scale = 1;

			if (scope.state == false && scope.limitRotation() == true) {
				position.copy(prevPos);
				scope.target.copy(prevTarget);
				spherical.copy(prevSpherical);
				scope.object.lookAt( scope.target );

			}

			// update condition is:
			// min(camera displacement, camera rotation in radians)^2 > EPS
			// using small-angle approximation cos(x/2) = 1 - x^2 / 8
			
			
			

			if ( zoomChanged ||
				lastPosition.distanceToSquared( scope.object.position ) > EPS ||
				8 * ( 1 - lastQuaternion.dot( scope.object.quaternion ) ) > EPS ) {

				scope.dispatchEvent( changeEvent );

				lastPosition.copy( scope.object.position );
				lastQuaternion.copy( scope.object.quaternion );
				zoomChanged = false;
					
				
				// this.lightGroup.position.set(scope.object.position.x, scope.object.position.y , scope.object.position.z);
				// let lightRotation = new THREE.Euler(scope.object.rotation._x,scope.object.rotation._y,scope.object.rotation._z, scope.object.rotation._order)
				this.lightGroup.setRotationFromEuler(scope.object.rotation);
				
				return true;

			}

			return false;

		};

	}();

	this.dispose = function () {

		scope.domElement.removeEventListener( 'contextmenu', onContextMenu, false );
		scope.domElement.removeEventListener( 'mousedown', onMouseDown, false );
		scope.domElement.removeEventListener( 'wheel', onMouseWheel, false );

		scope.domElement.removeEventListener( 'touchstart', onTouchStart, false );
		scope.domElement.removeEventListener( 'touchend', onTouchEnd, false );
		scope.domElement.removeEventListener( 'touchmove', onTouchMove, false );

		document.removeEventListener( 'mousemove', onMouseMove, false );
		document.removeEventListener( 'mouseup', onMouseUp, false );

		scope.domElement.removeEventListener( 'keydown', onKeyDown, false );

		//scope.dispatchEvent( { type: 'dispose' } ); // should this be added here?

	};

	this.worldToNDC = function ( worldPoint , activeCamera) {
        let NDCPoint = new THREE.Vector3();
        NDCPoint.copy(worldPoint);
        activeCamera.updateMatrixWorld();
		NDCPoint.project( activeCamera );
		return NDCPoint;
	}

	

	this.NDCToScreenCoordinates = function (NDCPoint) {
        let NDCToPixelX = ((NDCPoint.x + 1) / 2) * scope.domElement.clientWidth;
        let NDCToPixelY = (1 - (NDCPoint.y + 1) / 2) * scope.domElement.clientHeight;
        let screenPoint = new THREE.Vector2(NDCToPixelX, NDCToPixelY);
        return screenPoint;
	};

	this.getNDCPoint = function (worldPoint, activeCamera) {
		var ndcpoint = scope.worldToNDC(worldPoint, activeCamera);
		var screenPoint = scope.NDCToScreenCoordinates(ndcpoint);
		return screenPoint;
	}
	//
	// internals
	//

	var scope = this;

	var changeEvent = { type: 'change' };
	var startEvent = { type: 'start' };
	var endEvent = { type: 'end' };

	var STATE = {
		NONE: - 1,
		ROTATE: 0,
		DOLLY: 1,
		PAN: 2,
		TOUCH_ROTATE: 3,
		TOUCH_PAN: 4,
		TOUCH_DOLLY_PAN: 5,
		TOUCH_DOLLY_ROTATE: 6
	};

	var state = STATE.NONE;

	var EPS = 0.000001;

	// current position in spherical coordinates
	var spherical = new THREE.Spherical();
	var sphericalDelta = new THREE.Spherical();

	var scale = 1;
	var panOffset = new THREE.Vector3();
	var zoomChanged = false;

	var rotateStart = new THREE.Vector2();
	var rotateEnd = new THREE.Vector2();
	var rotateDelta = new THREE.Vector2();

	var panStart = new THREE.Vector2();
	var panEnd = new THREE.Vector2();
	var panDelta = new THREE.Vector2();

	var dollyStart = new THREE.Vector2();
	var dollyEnd = new THREE.Vector2();
	var dollyDelta = new THREE.Vector2();

	function getAutoRotationAngle() {

		return 2 * Math.PI / 60 / 60 * scope.autoRotateSpeed;

	}

	function getZoomScale() {

		return Math.pow( 0.95, scope.zoomSpeed );

	}

	function rotateLeft( angle ) {

		sphericalDelta.theta -= angle;

	}

	function rotateUp( angle ) {

		sphericalDelta.phi -= angle;

	}

	var panLeft = function () {
		if ( !scope.enabled) {
			return;
		}
		var v = new THREE.Vector3();

		return function panLeft( distance, objectMatrix ) {

			var targetDistance = new THREE.Vector3().copy( scope.object.position ).sub( scope.target ).length();
			targetDistance *= Math.tan( ( scope.horizontalFOV / 2 ) * Math.PI / 180.0 );
			var diff = (scope.horizontalBound * distance)/(2 * targetDistance);
			scope.panXBound -= diff;
			if (scope.panXBound >=0 && scope.panXBound <= scope.horizontalBound){
				v.setFromMatrixColumn( objectMatrix, 0 ); // get X column of objectMatrix
				v.multiplyScalar( - distance );
				panOffset.add( v );
				scope.panX -= diff;
			}
			else{
				scope.panXBound = scope.panX;
			}
			
		};

	}();

	var panUp = function () {
		if ( !scope.enabled) {
			return;
		}
		var v = new THREE.Vector3();

		return function panUp( distance, objectMatrix) {

			if ( scope.screenSpacePanning === true ) {
				v.setFromMatrixColumn( objectMatrix, 1 );

			} else {

				v.setFromMatrixColumn( objectMatrix, 0 );
				v.crossVectors( scope.object.up, v );
			}

		v.multiplyScalar( distance );
		var position = scope.object.position;
		var offset = new THREE.Vector3();
		offset.copy( position ).sub( scope.target );
		var targetDistance = offset.length();
		// half of the fov is center to top of screen
		targetDistance *= Math.tan( ( scope.object.fov / 2 ) * Math.PI / 180.0 );
		var diff = (scope.sliderLimit * distance)/(2 * targetDistance);
		scope.sliderBound -= diff;
		if (scope.sliderBound >=0 && scope.sliderBound <= scope.sliderLimit){
			panOffset.add( v );
			scope.sliderPosition -= (diff);
		}
		else {
			scope.sliderBound = scope.sliderPosition;
		}
		};

	}();

	// deltaX and deltaY are in pixels; right and down are positive
	var pan = function () {
		if ( !scope.enabled) {
			return;
		}
		var offset = new THREE.Vector3();

		return function pan( deltaX, deltaY ) {

			var element = scope.domElement;

			if ( scope.object.isPerspectiveCamera ) {

				// perspective
				var position = scope.object.position;
				offset.copy( position ).sub( scope.target );
				var targetDistance = offset.length();

				// half of the fov is center to top of screen
				targetDistance *= Math.tan( ( scope.object.fov / 2 ) * Math.PI / 180.0 );

				// we use only clientHeight here so aspect ratio does not distort speed
				panLeft( 2 * deltaX * targetDistance / element.clientHeight, scope.object.matrix );
				panUp( 2 * deltaY * targetDistance / element.clientHeight, scope.object.matrix);

			} else if ( scope.object.isOrthographicCamera ) {

				// orthographic
				panLeft( deltaX * ( scope.object.right - scope.object.left ) / scope.object.zoom / element.clientWidth, scope.object.matrix );
				panUp( deltaY * ( scope.object.top - scope.object.bottom ) / scope.object.zoom / element.clientHeight, scope.object.matrix );

			} else {

				// camera neither orthographic nor perspective
				console.warn( 'WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.' );
				scope.enablePan = false;

			}

		};

	}();

	function dollyIn( dollyScale ) {

		if ( scope.object.isPerspectiveCamera ) {
			scale /= dollyScale;

		} else if ( scope.object.isOrthographicCamera ) {

			scope.object.zoom = Math.max( scope.minZoom, Math.min( scope.maxZoom, scope.object.zoom * dollyScale ) );
			scope.object.updateProjectionMatrix();
			zoomChanged = true;

		} else {

			console.warn( 'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.' );
			scope.enableZoom = false;

		}

	}

	function dollyOut( dollyScale ) {

		if ( scope.object.isPerspectiveCamera ) {
			
			scale *= dollyScale;

		} else if ( scope.object.isOrthographicCamera ) {

			scope.object.zoom = Math.max( scope.minZoom, Math.min( scope.maxZoom, scope.object.zoom / dollyScale ) );
			scope.object.updateProjectionMatrix();
			zoomChanged = true;

		} else {

			console.warn( 'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.' );
			scope.enableZoom = false;

		}

	}

	//
	// event callbacks - update the object state
	//

	function handleMouseDownRotate( event ) {

		rotateStart.set( event.clientX, event.clientY );

	}

	function handleMouseDownDolly( event ) {

		dollyStart.set( event.clientX, event.clientY );

	}

	function handleMouseDownPan( event ) {

		panStart.set( event.clientX, event.clientY );

	}

	function handleMouseMoveRotate( event ) {

		
		rotateEnd.set( event.clientX, event.clientY );

		rotateDelta.subVectors( rotateEnd, rotateStart ).multiplyScalar( scope.rotateSpeed );

		var element = scope.domElement;

		var prevPos = scope.object.position.clone();

		rotateLeft( 2 * Math.PI * rotateDelta.x / element.clientHeight ); // yes, height

		rotateUp( 2 * Math.PI * rotateDelta.y / element.clientHeight );

		rotateStart.copy( rotateEnd );

		
		scope.update();

		
		scope.updateVerticalSlider();
		scope.updateHorizontalSlider();
		

		
		
		

	}

	function handleMouseMoveDolly( event ) {

		dollyEnd.set( event.clientX, event.clientY );

		dollyDelta.subVectors( dollyEnd, dollyStart );

		if ( dollyDelta.y > 0 ) {

			//dollyIn( getZoomScale() );
			scope.zoomIn();

		} else if ( dollyDelta.y < 0 ) {

			if (scope.state == true || (scope.state == false && scope.limitWheeling() == false)) {
				// dollyOut( getZoomScale() );
				scope.zoomOut();
			} 
			

		}

		dollyStart.copy( dollyEnd );

		scope.update();

	}

	function handleMouseMovePan( event ) {

		panEnd.set( event.clientX, event.clientY );

		panDelta.subVectors( panEnd, panStart ).multiplyScalar( scope.panSpeed );

		pan( panDelta.x, panDelta.y );

		panStart.copy( panEnd );

		scope.update();

	}

	function handleMouseUp( /*event*/ ) {

		// no-op

	}

	function handleMouseWheel( event ) {

		if ( event.deltaY < 0 ) {

			if (scope.state == true || (scope.state == false && scope.limitWheeling() == false)) {
				// dollyOut( getZoomScale() );
				scope.zoomOut();
			}
			

		} else if ( event.deltaY > 0 ) {

			// dollyIn( getZoomScale() );
			scope.zoomIn();

		}

		scope.update();

	}

	function handleKeyDown( event ) {

		var needsUpdate = false;

		switch ( event.keyCode ) {

			case scope.keys.UP:
				pan( 0, scope.keyPanSpeed );
				needsUpdate = true;
				break;

			case scope.keys.BOTTOM:
				pan( 0, - scope.keyPanSpeed );
				needsUpdate = true;
				break;

			case scope.keys.LEFT:
				pan( scope.keyPanSpeed, 0 );
				needsUpdate = true;
				break;

			case scope.keys.RIGHT:
				pan( - scope.keyPanSpeed, 0 );
				needsUpdate = true;
				break;

		}

		if ( needsUpdate ) {

			// prevent the browser from scrolling on cursor keys
			event.preventDefault();

			scope.update();

		}


	}

	function handleTouchStartRotate( event ) {

		if ( event.touches.length == 1 ) {

			rotateStart.set( event.touches[ 0 ].pageX, event.touches[ 0 ].pageY );

		} else {

			var x = 0.5 * ( event.touches[ 0 ].pageX + event.touches[ 1 ].pageX );
			var y = 0.5 * ( event.touches[ 0 ].pageY + event.touches[ 1 ].pageY );

			rotateStart.set( x, y );

		}

	}

	function handleTouchStartPan( event ) {

		if ( event.touches.length == 1 ) {

			panStart.set( event.touches[ 0 ].pageX, event.touches[ 0 ].pageY );

		} else {

			var x = 0.5 * ( event.touches[ 0 ].pageX + event.touches[ 1 ].pageX );
			var y = 0.5 * ( event.touches[ 0 ].pageY + event.touches[ 1 ].pageY );

			panStart.set( x, y );

		}

	}

	function handleTouchStartDolly( event ) {

		var dx = event.touches[ 0 ].pageX - event.touches[ 1 ].pageX;
		var dy = event.touches[ 0 ].pageY - event.touches[ 1 ].pageY;

		var distance = Math.sqrt( dx * dx + dy * dy );

		dollyStart.set( 0, distance );

	}

	function handleTouchStartDollyPan( event ) {

		if ( scope.enableZoom ) handleTouchStartDolly( event );

		if ( scope.enablePan ) handleTouchStartPan( event );

	}

	function handleTouchStartDollyRotate( event ) {

		if ( scope.enableZoom ) handleTouchStartDolly( event );

		if ( scope.enableRotate ) handleTouchStartRotate( event );

	}

	function handleTouchMoveRotate( event ) {

		if ( event.touches.length == 1 ) {

			rotateEnd.set( event.touches[ 0 ].pageX, event.touches[ 0 ].pageY );

		} else {

			var x = 0.5 * ( event.touches[ 0 ].pageX + event.touches[ 1 ].pageX );
			var y = 0.5 * ( event.touches[ 0 ].pageY + event.touches[ 1 ].pageY );

			rotateEnd.set( x, y );

		}

		rotateDelta.subVectors( rotateEnd, rotateStart ).multiplyScalar( scope.rotateSpeed );

		var element = scope.domElement;

		rotateLeft( 2 * Math.PI * rotateDelta.x / element.clientHeight ); // yes, height

		rotateUp( 2 * Math.PI * rotateDelta.y / element.clientHeight );

		rotateStart.copy( rotateEnd );

	}

	function handleTouchMovePan( event ) {

		if ( event.touches.length == 1 ) {

			panEnd.set( event.touches[ 0 ].pageX, event.touches[ 0 ].pageY );

		} else {

			var x = 0.5 * ( event.touches[ 0 ].pageX + event.touches[ 1 ].pageX );
			var y = 0.5 * ( event.touches[ 0 ].pageY + event.touches[ 1 ].pageY );

			panEnd.set( x, y );

		}

		panDelta.subVectors( panEnd, panStart ).multiplyScalar( scope.panSpeed );

		pan( panDelta.x, panDelta.y );

		panStart.copy( panEnd );

	}

	function handleTouchMoveDolly( event ) {

		var dx = event.touches[ 0 ].pageX - event.touches[ 1 ].pageX;
		var dy = event.touches[ 0 ].pageY - event.touches[ 1 ].pageY;

		var distance = Math.sqrt( dx * dx + dy * dy );

		dollyEnd.set( 0, distance );

		dollyDelta.set( 0, Math.pow( dollyEnd.y / dollyStart.y, scope.zoomSpeed ) );

		dollyIn( dollyDelta.y );

		dollyStart.copy( dollyEnd );

	}

	function handleTouchMoveDollyPan( event ) {

		if ( scope.enableZoom ) handleTouchMoveDolly( event );

		if ( scope.enablePan ) handleTouchMovePan( event );

	}

	function handleTouchMoveDollyRotate( event ) {

		if ( scope.enableZoom ) handleTouchMoveDolly( event );

		if ( scope.enableRotate ) handleTouchMoveRotate( event );

	}

	function handleTouchEnd( /*event*/ ) {

		// no-op

	}

	//
	// event handlers - FSM: listen for events and reset state
	//

	function onMouseDown( event ) {

		if ( scope.enabled === false ) return;

		// Prevent the browser from scrolling.

		event.preventDefault();

		// Manually set the focus since calling preventDefault above
		// prevents the browser from setting it automatically.

		scope.domElement.focus ? scope.domElement.focus() : window.focus();

		switch ( event.button ) {

			case 0:

				switch ( scope.mouseButtons.LEFT ) {

					case THREE.MOUSE.ROTATE:

						if ( event.ctrlKey || event.metaKey || event.shiftKey ) {

							if ( scope.enablePan === false ) return;

							handleMouseDownPan( event );

							state = STATE.PAN;

						} else {

							if ( scope.enableRotate === false ) return;

							handleMouseDownRotate( event );

							state = STATE.ROTATE;

						}

						break;

					case THREE.MOUSE.PAN:

						if ( event.ctrlKey || event.metaKey || event.shiftKey ) {

							if ( scope.enableRotate === false ) return;

							handleMouseDownRotate( event );

							state = STATE.ROTATE;

						} else {

							if ( scope.enablePan === false ) return;

							handleMouseDownPan( event );

							state = STATE.PAN;

						}

						break;

					default:

						state = STATE.NONE;

				}

				break;


			case 1:

				switch ( scope.mouseButtons.MIDDLE ) {

					case THREE.MOUSE.DOLLY:

						if ( scope.enableZoom === false ) return;

						handleMouseDownDolly( event );

						state = STATE.DOLLY;

						break;


					default:

						state = STATE.NONE;

				}

				break;

			case 2:

				switch ( scope.mouseButtons.RIGHT ) {

					case THREE.MOUSE.ROTATE:

						if ( scope.enableRotate === false ) return;

						handleMouseDownRotate( event );

						state = STATE.ROTATE;

						break;

					case THREE.MOUSE.PAN:

						if ( scope.enablePan === false ) return;

						handleMouseDownPan( event );

						state = STATE.PAN;

						break;

					default:

						state = STATE.NONE;

				}

				break;

		}

		if ( state !== STATE.NONE ) {

			document.addEventListener( 'mousemove', onMouseMove, false );
			document.addEventListener( 'mouseup', onMouseUp, false );

			scope.dispatchEvent( startEvent );

		}

	}

	function onMouseMove( event ) {

		if ( scope.enabled === false ) return;

		event.preventDefault();

		switch ( state ) {

			case STATE.ROTATE:

				if ( scope.enableRotate === false ) return;

				handleMouseMoveRotate( event );

				break;

			case STATE.DOLLY:

				if ( scope.enableZoom === false ) return;

				handleMouseMoveDolly( event );

				break;

			case STATE.PAN:

				if ( scope.enablePan === false ) return;

				handleMouseMovePan( event );

				break;

		}

	}

	function onMouseUp( event ) {

		if ( scope.enabled === false ) return;

		handleMouseUp( event );

		document.removeEventListener( 'mousemove', onMouseMove, false );
		document.removeEventListener( 'mouseup', onMouseUp, false );

		scope.dispatchEvent( endEvent );

		state = STATE.NONE;

	}

	function onMouseWheel( event ) {

		if ( scope.enabled === false || scope.enableZoom === false || ( state !== STATE.NONE && state !== STATE.ROTATE ) ) return;

		event.preventDefault();
		event.stopPropagation();

		scope.dispatchEvent( startEvent );

		handleMouseWheel( event );

		scope.dispatchEvent( endEvent );

	}

	function onKeyDown( event ) {

		if ( scope.enabled === false || scope.enableKeys === false || scope.enablePan === false ) return;

		handleKeyDown( event );

	}

	function onTouchStart( event ) {

		if ( scope.enabled === false ) return;

		event.preventDefault();

		switch ( event.touches.length ) {

			case 1:

				switch ( scope.touches.ONE ) {

					case THREE.TOUCH.ROTATE:

						if ( scope.enableRotate === false ) return;

						handleTouchStartRotate( event );

						state = STATE.TOUCH_ROTATE;

						break;

					case THREE.TOUCH.PAN:

						if ( scope.enablePan === false ) return;

						handleTouchStartPan( event );

						state = STATE.TOUCH_PAN;

						break;

					default:

						state = STATE.NONE;

				}

				break;

			case 2:

				switch ( scope.touches.TWO ) {

					case THREE.TOUCH.DOLLY_PAN:

						if ( scope.enableZoom === false && scope.enablePan === false ) return;

						handleTouchStartDollyPan( event );

						state = STATE.TOUCH_DOLLY_PAN;

						break;

					case THREE.TOUCH.DOLLY_ROTATE:

						if ( scope.enableZoom === false && scope.enableRotate === false ) return;

						handleTouchStartDollyRotate( event );

						state = STATE.TOUCH_DOLLY_ROTATE;

						break;

					default:

						state = STATE.NONE;

				}

				break;

			default:

				state = STATE.NONE;

		}

		if ( state !== STATE.NONE ) {

			scope.dispatchEvent( startEvent );

		}

	}

	function onTouchMove( event ) {

		if ( scope.enabled === false ) return;

		event.preventDefault();
		event.stopPropagation();

		switch ( state ) {

			case STATE.TOUCH_ROTATE:

				if ( scope.enableRotate === false ) return;

				handleTouchMoveRotate( event );

				scope.update();

				break;

			case STATE.TOUCH_PAN:

				if ( scope.enablePan === false ) return;

				handleTouchMovePan( event );

				scope.update();

				break;

			case STATE.TOUCH_DOLLY_PAN:

				if ( scope.enableZoom === false && scope.enablePan === false ) return;

				handleTouchMoveDollyPan( event );

				scope.update();

				break;

			case STATE.TOUCH_DOLLY_ROTATE:

				if ( scope.enableZoom === false && scope.enableRotate === false ) return;

				handleTouchMoveDollyRotate( event );

				scope.update();

				break;

			default:

				state = STATE.NONE;

		}

	}

	function onTouchEnd( event ) {

		if ( scope.enabled === false ) return;

		handleTouchEnd( event );

		scope.dispatchEvent( endEvent );

		state = STATE.NONE;

	}

	function onContextMenu( event ) {

		if ( scope.enabled === false ) return;

		event.preventDefault();

	}

	//

	scope.domElement.addEventListener( 'contextmenu', onContextMenu, false );

	scope.domElement.addEventListener( 'mousedown', onMouseDown, false );
	scope.domElement.addEventListener( 'wheel', onMouseWheel, false );

	scope.domElement.addEventListener( 'touchstart', onTouchStart, false );
	scope.domElement.addEventListener( 'touchend', onTouchEnd, false );
	scope.domElement.addEventListener( 'touchmove', onTouchMove, false );

	scope.domElement.addEventListener( 'keydown', onKeyDown, false );

	// make sure element can receive keys.

	if ( scope.domElement.tabIndex === - 1 ) {

		scope.domElement.tabIndex = 0;

	}

	// force an update at start

	this.update();

};

OrbitControlsSilo.prototype = Object.create( THREE.EventDispatcher.prototype );
OrbitControlsSilo.prototype.constructor = OrbitControlsSilo;



// This set of controls performs orbiting, dollying (zooming), and panning.
// Unlike TrackballControls, it maintains the "up" direction object.up (+Y by default).
// This is very similar to OrbitControls, another set of touch behavior
//
//    Orbit - right mouse, or left mouse + ctrl/meta/shiftKey / touch: two-finger rotate
//    Zoom - middle mouse, or mousewheel / touch: two-finger spread or squish
//    Pan - left mouse, or arrow keys / touch: one-finger move

export { OrbitControlsSilo};
