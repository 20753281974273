import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Input, Row, Col, Table, Tabs, Button, Space, Empty, Tooltip } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import FileConstants from "../../../../FileConstants";
import { getReadableModelFormat, getModelTypeString } from "../../Utilities";
import { LoadingOutlined } from '@ant-design/icons';
import { DEFAULT_LIGHTING_RIG, RIG_BASE_URL } from '../../../../environments/env';
import * as Utilities from '../../Utilities'
import "./index.scss"
const { TabPane } = Tabs;

let searchInput = ''
let cancelToken;

const SystemUsage = (props) => {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, setLoading] = useState(true);
    const [systemUsage, setSystemUsage] = useState([]);
    const [entityType, setEntityType] = useState('product_models');
    const [filters, setFilters] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [error, setError] = useState(false);


    useEffect(() => {
        if (props.account_type && (props.company_id || props.username)) {
            setLoading(true);
            getSystemUsage();
        }
    }, [entityType, props.display_data, props.date_filter_type, props.start_date, props.end_date, props.username, props.company_id, props.account_type, props.dataType, props.selectedProject])


    //Assign a model type to column
    const addVariationTypeForModelType = (model_type, data_record) => {
        let result = model_type;
        if (model_type == 'Change' && data_record['variation_type']) {
            result = result + ' (' + FileConstants.variationDict(data_record['variation_type']) + ')';
        }
        else if (result == '') {
            result = 'N/A';
        }
        return result;
    }

    const getAppliedFilterValue = (filter) => {
        // return already applied filter value or null
        const url = new URL(window.location);
        let value = null;
        value = url.searchParams.get(filter);
        if (value)
            value = value.split(',');
        return value;
    }

    //Set query params value with filter
    const setFilterQueryParams = (filters) => {
        setSelectedFilters(filters);
        const url = new URL(window.location);

        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                url.searchParams.set(key, value);
            }
            else if (url.searchParams.has(key)) {
                url.searchParams.delete(key);
            }
        }
        window.history.replaceState({}, '', url)
    }

    const getColumns = () => {
        return [
            {
                title: <span className="manrope f-14 black-14">Request Type</span>,
                dataIndex: 'request_type',
                key: 'request_type',
                filters: filters && filters.filter_request_type,
                defaultFilteredValue: getAppliedFilterValue('request_type'),
                onFilter: (value, record) => record.request_type === value,
                render: (text) => (
                    <span className="manrope f-12 black-14">{FileConstants.setRequestTypeText(text)}</span>),
            },
            {
                title: <span className="manrope f-14 black-14">Name</span>,
                dataIndex: 'name',
                key: 'name',
                ...getColumnSearchProps('name'),
                render: (text) => (
                    <span className="manrope f-12 black-14">{text}</span>),
            },
            {
                title: <span className="manrope f-14 black-14">ALL3D ID</span>,
                dataIndex: 'id',
                key: 'id',
                ...getColumnSearchProps('id'),
                sorter: (a, b) => new Date(a.id) - new Date(b.id),
                render: (text, record) => (
                    <a className="manrope f-12" href={FileConstants.getRequestLinks(record, entityType)} target="_blank">
                        {text}
                    </a>
                ),
            },
            {
                title: <span className="manrope f-14 black-14">Revision Of</span>,
                dataIndex: 'revision_of',
                key: 'revision_of',
                render: (text, record) => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                sorter: (a, b) => a.revision_of.localeCompare(b.revision_of),
                hidden: entityType != 'lifestyle_photography' && entityType != 'managed_services'
            },
            {
                title: <span className="manrope f-14 black-14">Category</span>,
                dataIndex: 'category',
                key: 'category',
                filters: filters && filters.filter_categories,
                defaultFilteredValue: getAppliedFilterValue('category'),
                onFilter: (value, record) => record.category === value,
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                hidden: entityType == 'lifestyle_photography' || entityType == 'managed_services'
            },
            {
                title: <span className="manrope f-14 black-14">Item ID</span>,
                dataIndex: 'brand_id',
                key: 'brand_id',
                ...getColumnSearchProps('brand_id'),
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                hidden: entityType == 'lifestyle_photography' || entityType == 'managed_services'
            },
            {
                title: <span className="manrope f-14 black-14">Billing Date</span>,
                dataIndex: 'invoiced_on',
                key: 'invoiced_on',
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                sorter: (a, b) => new Date(a.invoiced_on) - new Date(b.invoiced_on),
                hidden: props.account_type == "customer"
            },
            {
                title: <span className="manrope f-14 black-14">Created On</span>,
                dataIndex: 'created_on',
                key: 'created_on',
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
                hidden: entityType == 'managed_services'
            },
            {
                title: <span className="manrope f-14 black-14">Last Modified</span>,
                dataIndex: 'last_modified',
                key: 'last_modified',
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                sorter: (a, b) => new Date(a.last_modified) - new Date(b.last_modified),
            },
            {
                title: <span className="manrope f-14 black-14">Image Name</span>,
                dataIndex: 'camera_name',
                key: 'camera_name',
                render: (text, record) => <a className="manrope f-12" href={FileConstants.getRequestLinks(record).replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(record.platform)) || '#'} target="_blank">
                    {text || 'N/A'}
                </a>,
                sorter: (a, b) => a.camera_name.localeCompare(b.camera_name),
                hidden: entityType == 'product_models' || entityType == 'space_models' || entityType == 'materials'
            },
            {
                title: <span className="manrope f-14 black-14">Resolution</span>,
                dataIndex: 'resolution',
                key: 'resolution',
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                filters: filters && filters.filter_resolution,
                defaultFilteredValue: getAppliedFilterValue('resolution'),
                onFilter: (value, record) => record.resolution === value,
                hidden: entityType == 'product_models' || entityType == 'space_models' || entityType == 'materials'
            },
            {
                title: <span className="manrope f-14 black-14">Lighting Rig</span>,
                dataIndex: 'rig_id',
                key: 'rig_id',
                render: (text, record) => <a target='_blank' className="manrope f-12" href={record.rig_file ? RIG_BASE_URL + record.rig_file[0].uid + '/' + record.rig_file[0].name : DEFAULT_LIGHTING_RIG}>
                    {record.rig_file ? record.rig_file[0].name : 'Default Rig'}</a>,
                hidden: entityType != 'product_photography'
            },
            {
                title: <span className="manrope f-14 black-14">Dimensions</span>,
                dataIndex: 'dimensions',
                key: 'dimensions',
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
                hidden: entityType != 'materials'
            },
            {
                title: <span className="manrope f-14 black-14">Model Type</span>,
                dataIndex: 'model_type',
                key: 'model_type',
                filters: filters && filters.filter_model_type,
                defaultFilteredValue: getAppliedFilterValue('model_type'),
                onFilter: (value, record) => addVariationTypeForModelType(getModelTypeString(record), record) === value,
                render: (text, record) => <span className="manrope f-12 black-14">{addVariationTypeForModelType(getModelTypeString(record), record)}</span>,
                hidden: entityType != 'product_models'
            },
            {
                title: <span className="manrope f-14 black-14">Requirement</span>,
                dataIndex: 'need_to_model',
                key: 'need_to_model',
                filters: filters && filters.filter_requirement,
                defaultFilteredValue: getAppliedFilterValue('need_to_model'),
                onFilter: (value, record) => getReadableModelFormat(record.need_to_model || getModelTypeString(record) || 'None') === value,
                render: (text, record) => <span className="manrope f-12 black-14">{getReadableModelFormat(text || getModelTypeString(record) || 'None')}</span>,
                hidden: entityType != 'product_models'
            },
            {
                title: <span className="manrope f-14 black-14">Assigned Artist</span>,
                dataIndex: 'assigned_artist',
                key: 'assigned_artist',
                filters: filters && filters.filter_artists,
                defaultFilteredValue: getAppliedFilterValue('assigned_artist'),
                onFilter: (value, record) => record.assigned_artist === value,
                render: text => <span className="manrope f-12 black-14">{text || 'Not Assigned'}</span>,
                hidden: entityType == 'lifestyle_photography' || entityType == 'managed_services' || entityType == 'product_photography' || props.account_type == "customer"
            },
            {
                title: <span className="manrope f-14 black-14">Customer Username</span>,
                dataIndex: 'customer_username',
                key: 'customer_username',
                filters: filters && filters.filter_customers,
                defaultFilteredValue: getAppliedFilterValue('customer_username'),
                onFilter: (value, record) => record.customer_username === value,
                render: text => <span className="manrope f-12 black-14">{text}</span>,
            },
            {
                title: <span className="manrope f-14 black-14">Status</span>,
                dataIndex: 'entity_status',
                key: 'entity_status',
                filters: filters && filters.filter_status,
                defaultFilteredValue: getAppliedFilterValue('entity_status'),
                onFilter: (value, record) => record.entity_status === value,
                render: text => <span className="manrope f-12 black-14 capitalize">{(entityType == 'product_models' || entityType == 'space_models' || entityType == 'materials')
                    ? props.account_type == 'customer' ? FileConstants.MODEL_STATUS_STRING_FOR_CUSTOMER[text.toString()] : FileConstants.MODEL_STATUS_STRING[text.toString()] : entityType == 'lifestyle_photography' ? FileConstants.SCENE_STATUS_STRING[text] : text}</span>,
            },
            {
                title: <span className="manrope f-14 black-14">Project IDs</span>,
                dataIndex: 'project_ids',
                key: 'project_ids',
                hidden: entityType == 'materials',
                render: text => <span className="manrope f-12 black-14 capitalize">{getProjectIds(text)}</span>,
            },

        ].filter(col => !col.hidden);
    }

    const getProjectIds = (text) => {
            if (text && text !== "") {
                let projects = JSON.parse(text);
                return projects.map((pro, index) => (
                    <Tooltip key={index} title={<span className='manrope f-12 white'>{pro.name}</span>}>
                        <a className='manrope f-12' href={`/projects/${pro.project_id}`} target='_blank'>{pro.project_id}</a>{index == projects.length - 1 ? "" : ", "}
                    </Tooltip>
                ));
            }
            return "N/A";
               
    }
    /*Get system usage according to entity type passed*/
    const getSystemUsage = () => {
        //Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Previous Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()
        let payload = {}
        payload.request_type = entityType;
        payload.date_filter_type = props.date_filter_type;
        payload.action = 'get_details';
        if (props.company_id != -1) {
            payload.company_id = props.company_id
        } else {
            payload.customer_username = props.username;
        }
        payload.start_date = props.start_date;
        payload.end_date = props.end_date;
        payload.data_type = "overall";

        if (props.dataType != "overall") {
            payload.data_type = props.dataType;
            payload.project_id = parseInt(props.selectedProject);
        }

        axios.interceptors.response.use(
            response => response,
            error => {
              // Check if it's a network error by inspecting the error message or error code
              if (error.toString().includes("Network Error")) {
                console.error("Detected network error or CORS issue.");
                setError(true);
              }
              return Promise.reject(error);
            }
          );
        
        axios.post(ENVIRONMENT.GET_SYSTEM_USAGE, payload, { cancelToken: cancelToken.token })
            .then(res => {
                if (res.data) {
                    setError(false);
                    populateData(res.data)
                    setLoading(false);
                }
            }).catch(err => {
                console.log(err)
            });
    }


    const changeTab = (key) => {
        clearAllFilters();
        setEntityType(FileConstants.typesOfServices[parseInt(key)]);
        forceUpdate();
    }

    /* Pass usage data and segregate data according to request types
    Prepare filters */
    const populateData = (data) => {
        let usage_data = [];
        let returned_values = {}
        let filter_values = {
            filter_categories_values: [],
            filter_customer_values: [],
            filter_artist_values: [],
            filter_model_type_values: [],
            filter_status_values: [],
            filter_request_types_values: [],
            filter_requirement_values: [],
            filter_resolution_values: []
        }
        let values = {
            categories_values: [],
            customer_values: [],
            artist_values: [],
            model_type_values: [],
            status_values: [],
            request_types_values: [],
            requirement_values: [],
            resolution_values: []
        };

        if (Object.keys(data).length > 0) {
            for (const [key, value] of Object.entries(data)) {
                if (key == entityType) {
                    if (value.length > 0) {
                        value.map((arr) => {
                            usage_data = usage_data.concat(arr);
                            arr.map(ele => {
                                returned_values = createFilters(ele,
                                    filter_values,
                                    values);
                                values = returned_values.all_values
                                filter_values = returned_values.filters
                            });
                        });
                        
                        setSystemUsage(usage_data);

                        break;
                    } else {
                        setSystemUsage([])
                    }
                }
            }
        }
        setSystemUsage(usage_data);
        if (usage_data.length > 0) {
            handleFilteringOptions(filter_values.filter_categories_values,
                filter_values.filter_customer_values,
                filter_values.filter_status_values,
                filter_values.filter_artist_values,
                filter_values.filter_model_type_values,
                filter_values.filter_request_types_values,
                filter_values.filter_requirement_values,
                filter_values.filter_resolution_values,
                values);
        }
    }

    const getRowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    
    /* Prepare filter data according to request types */
    const createFilters = (data, filter_values, values) => {

        if (filter_values) {
            let request_type_value = data['request_type'];
            if (request_type_value != "" && filter_values.filter_request_types_values && !filter_values.filter_request_types_values.includes(request_type_value)) {
                filter_values.filter_request_types_values.push(request_type_value);
            }
            if (request_type_value != '') {
                values.request_types_values.push(request_type_value)
            }

            let category_value = data['category'];
            if (category_value != "" && filter_values.filter_categories_values && !filter_values.filter_categories_values.includes(category_value)) {
                filter_values.filter_categories_values.push(category_value);
            }
            if (category_value != '') {
                values.categories_values.push(category_value)
            }

            let resolution_value = data['resolution'];
            if (resolution_value != "" && filter_values.filter_resolution_values && !filter_values.filter_resolution_values.includes(resolution_value)) {
                filter_values.filter_resolution_values.push(resolution_value);
            }
            if (resolution_value != '') {
                values.resolution_values.push(resolution_value)
            }

            let status_value = data['entity_status'];
            if (status_value != "" && filter_values.filter_status_values && !filter_values.filter_status_values.includes(status_value)) {
                filter_values.filter_status_values.push(status_value);
            }
            if (status_value != '') {
                values.status_values.push(status_value)
            }

            let customer_value = data['customer_username'];
            if (customer_value != "" && filter_values.filter_customer_values && !filter_values.filter_customer_values.includes(customer_value)) {
                filter_values.filter_customer_values.push(customer_value);
            }
            if (customer_value != '') {
                values.customer_values.push(customer_value)
            }

            let artist_value = data['assigned_artist']
            if (artist_value != "" && filter_values.filter_artist_values && !filter_values.filter_artist_values.includes(artist_value)) {
                filter_values.filter_artist_values.push(artist_value)
            }
            if (artist_value != '') {
                values.artist_values.push(artist_value)
            }

            let model_type_value = addVariationTypeForModelType(getModelTypeString(data), data)
            if (model_type_value != "" && filter_values.filter_model_type_values && !filter_values.filter_model_type_values.includes(model_type_value)) {
                filter_values.filter_model_type_values.push(model_type_value)
            }
            if (model_type_value != '') {
                values.model_type_values.push(model_type_value)
            }

            let requirement_value = getReadableModelFormat(data.need_to_model || getModelTypeString(data) || 'None')
            if (requirement_value != "" && filter_values.filter_requirement_values && !filter_values.filter_requirement_values.includes(requirement_value)) {
                filter_values.filter_requirement_values.push(requirement_value)
            }
            if (requirement_value != '') {
                values.requirement_values.push(requirement_value)
            }

            let filters = {
                filter_categories_values: filter_values.filter_categories_values,
                filter_request_types_values: filter_values.filter_request_types_values,
                filter_customer_values: filter_values.filter_customer_values,
                filter_status_values: filter_values.filter_status_values,
                filter_artist_values: filter_values.filter_artist_values,
                filter_model_type_values: filter_values.filter_model_type_values,
                filter_requirement_values: filter_values.filter_requirement_values,
                filter_resolution_values: filter_values.filter_resolution_values
            }
            let all_values = {
                categories_values: values.categories_values,
                customer_values: values.customer_values,
                artist_values: values.artist_values,
                model_type_values: values.model_type_values,
                status_values: values.status_values,
                request_types_values: values.request_types_values,
                requirement_values: values.requirement_values,
                resolution_values: values.resolution_values
            }
            return {
                filters,
                all_values
            }
        }

    }

    // Search in table functionality
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    defaultValue={getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        defaultFilteredValue: getAppliedFilterValue(dataIndex),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        }
    });

    //Search value according to filter
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    //Reset filter value
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    // Clear all filters when switching tabs
    const clearAllFilters = () => {
        searchInput = '';
        setSearchText('');
        setSearchedColumn('');
        if (selectedFilters) {
            const url = new URL(window.location);
            for (const [key, value] of Object.entries(selectedFilters)) {
                if (url.searchParams.has(key)) {
                    url.searchParams.delete(key);
                }
            }
            window.history.replaceState({}, '', url)
        }
        setSelectedFilters(null);
    };

    //Get counts of filters and populate their data in filters
    const handleFilteringOptions = (filter_categories_values,
        filter_customer_values,
        filter_status_values,
        filter_artist_values,
        filter_model_type_values,
        filter_request_types_values,
        filter_requirement_values,
        filter_resolution_values,
        values) => {

        let filter_categories_objects = [];
        let filter_customer_objects = [];
        let filter_status_objects = [];
        let filter_artist_objects = [];
        let filter_model_type_objects = [];
        let filter_request_type_objects = [];
        let filter_requirement_objects = [];
        let filter_resolution_objects = [];

        filter_categories_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_customer_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_status_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_artist_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_model_type_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_request_types_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_requirement_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });

        let counts = {
            category_count: getCounts(values.categories_values),
            customer_count: getCounts(values.customer_values),
            status_count: getCounts(values.status_values),
            artist_count: getCounts(values.status_values),
            model_type_count: getCounts(values.model_type_values),
            request_type_count: getCounts(values.request_types_values),
            requirement_count: getCounts(values.requirement_values),
            resolution_count: getCounts(values.resolution_values)
        }

        filter_categories_values.forEach(filter_categories_value => {
            filter_categories_objects.push({
                text: `${filter_categories_value} (${counts['category_count'][filter_categories_value]})`,
                value: filter_categories_value,
            })
        });

        filter_customer_values.forEach(filter_customer_value => {
            filter_customer_objects.push({
                text: filter_customer_value,
                value: filter_customer_value,
            })
        });

        filter_status_values.forEach(filter_status_value => {
            filter_status_objects.push({
                text: `${(entityType == 'product_models' || entityType == 'space_models' 
                || entityType == 'materials') ? props.account_type == 'customer' && !FileConstants.isMSProvider ? FileConstants.MODEL_STATUS_STRING_FOR_CUSTOMER[filter_status_value.toString()] : FileConstants.MODEL_STATUS_STRING[filter_status_value] : 
                entityType == 'lifestyle_photography' ? FileConstants.SCENE_STATUS_STRING[filter_status_value] : filter_status_value} (${counts['status_count'][filter_status_value]})`,
                value: filter_status_value,
            })
        });

        filter_artist_values.forEach(filter_artist_value => {
            filter_artist_objects.push({
                text: filter_artist_value,
                value: filter_artist_value,
            })
        });

        filter_model_type_values.forEach(filter_model_type_value => {
            filter_model_type_objects.push({
                text: filter_model_type_value == 'Change' ? 'Change (All Types)' : filter_model_type_value,
                value: filter_model_type_value,
            })
        });

        filter_request_types_values.forEach(filter_request_type_value => {
            filter_request_type_objects.push({
                text: `${FileConstants.setRequestTypeText(filter_request_type_value)} (${counts['request_type_count'][filter_request_type_value]})`,
                value: filter_request_type_value,
            })
        });

        filter_requirement_values.forEach(filter_requirement_value => {
            filter_requirement_objects.push({
                text: filter_requirement_value,
                value: filter_requirement_value,
            })
        });

        filter_resolution_values.forEach(filter_resolution_value => {
            filter_resolution_objects.push({
                text: `${filter_resolution_value}  (${counts['resolution_count'][filter_resolution_value]})`,
                value: filter_resolution_value,
            })
        });

        let filters = {
            filter_categories: filter_categories_objects,
            filter_customers: filter_customer_objects,
            filter_status: filter_status_objects,
            filter_artists: filter_artist_objects,
            filter_model_type: filter_model_type_objects,
            filter_request_type: filter_request_type_objects,
            filter_requirement: filter_requirement_objects,
            filter_resolution: filter_resolution_objects
        };

        setFilters(filters);
    }

    // Return count of requests
    const getCounts = (data) => {
        const counts = {};

        for (const num of data) {
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }

        return counts;
    }
    

    //Return default filter value
    const getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
        let filteredValue = getAppliedFilterValue(dataIndex);
        if (!selectedKeys)
            setSelectedKeys(filteredValue);
        return filteredValue;
    }

    return (
        <>{props.display_data && error == false ?
            <Row style={{ margin: "10px 10px 0 10px" }}>
                <Col span={24}>
                    <Tabs onChange={changeTab}>
                        {FileConstants.typesOfServices.map((type, index) => (
                            type == "managed_services" || type == 'shared_items' ? "" :
                                <TabPane tab={`${FileConstants.typeOfDict(type)} ${entityType == type && !loading  && !props.summaryLoader  ? `(${systemUsage.length})` : !loading && !props.summaryLoader && props.totalCount[type] ? `(${props.totalCount[type]})` : !props.totalCount[type] ? `(${0})` : ``}`} key={index}>
                                    {props.summaryLoader || loading ? <div className="justify-in-center" style={{ width: '100%' }}>
                                        <LoadingOutlined style={{ fontSize: 32, marginTop: 20, marginBottom: 20 }} />
                                    </div> :
                                        <Row>
                                            <Col span={24}>
                                                <Table
                                                    rowClassName={getRowClassName}
                                                    tableLayout='fixed'
                                                    onChange={
                                                        (pagination, filters, sorter, extra) => {
                                                            setFilterQueryParams(filters)
                                                        }
                                                    }
                                                    columns={getColumns()}
                                                    dataSource={systemUsage} />
                                            </Col>
                                        </Row>}
                                </TabPane>
                        ))}
                    </Tabs>
                </Col>
            </Row> : <Empty
                style={{ marginTop: 20, marginBottom: 20 }}
                description={error ? "Please try again with a shorter date range" : "Billing data for this month does not exist yet."}
                className="manrope f-14"
            />}
        </>
    );

}

export default SystemUsage;
