import React, { useState, useEffect }  from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, message } from 'antd';
import Annotation from '../Annotation';
import { updateRoom, fetchRoom} from "../../../../../redux/actions";
import {connect} from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import AWS from 'aws-sdk';
import $ from 'jquery';
import axios from 'axios';
import ENVIRONMENT  from '../../../../../environments'
import { BUCKET_NAME } from '../../../../../environments/env';
function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const RoomElementsForm = (props) => {

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue } = props.form;
    const [submitType, setSubmitType] = useState('submit');
    const [elementNames, setElementNames] = useState([]);
    const [canvasObj, setCanvasObj] = useState(null);
    const [loader, setLoader] = useState(true);

    $('form input').keydown(function (e) {
        e.stopImmediatePropagation();
        if (e.key == 'Enter') {
            var inputs = $(this).parents("form").eq(0).find(":input");
            if (inputs[inputs.index(this) + 1] != null) {     
                inputs[inputs.index(this) + 1].focus();
                inputs[inputs.index(this) + 1].click((event)=> {
                    event.stopImmediatePropagation();
                })
            }
            e.preventDefault();
            return false;
        }
    });

    useEffect(() => {
        if(props.roomReducer.isCreated == true || props.roomReducer.isUpdated == true) {
            if(submitType == 'continue') {
                let onboarding_string = '';
                let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                if(onboarding != undefined){
                    onboarding_string = '&onboarding=true'
                }
                let product_state_string = '';
                let product_state = new URLSearchParams(window.location.search).get("product_state");
                if(product_state != undefined){
                    product_state_string = '&product_state='+product_state
                }

                props.history.push({
                    pathname: '/add-new-room',
                    search: `?step=${submitType == 'continue' ? 4: 1}&room_id=${props.roomReducer.room.room_id}${onboarding_string}${product_state_string}&origin=${props.redirectionPath}`
                });
                props.next();
            } else if (submitType == 'save')  {
                // message.info('Space saved successfully');
                props.handlePageRedirection();
                document.activeElement.blur();
            }
            let elementNames = [];
            for(let i=0; i<props.roomReducer.room.elements.length; i++)
            {
                if (props.roomReducer.room.elements[i]) {
                    elementNames.push(props.roomReducer.room.elements[i].elementName);   
                }
                
            }
            setElementNames(elementNames);
        }
        if(props.roomReducer.error) {
            message.error(props.roomReducer.error);
        }

        // Temporary fix for field because field not rendering data in edit mode
        for(let i=0;i<5;i++) {
            setTimeout(() => {
                setFieldsValue(patchRoom(props.roomReducer.room));
            },1000);
        }
        
    }, [props.roomReducer.room]);

    const handleRedirection = () => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let product_state_string = '';
        let product_state = new URLSearchParams(window.location.search).get("product_state");
        let step_no = '0';
        if(onboarding){
            if (product_state != undefined) {
                step_no = '4'
            } else {
                step_no = '14';
            }
            if(product_state != undefined){
                product_state_string = '&product_state='+product_state
            }
            props.history.push({
                pathname: '/home',
                search: `?step_no=${step_no}&room_state=added${product_state_string}`
            }); 
        } else {
            if(props.redirectionPath){
                props.history.push({
                    pathname: props.redirectionPath
                });  
            } else {
                props.history.push({
                    pathname: '/home'
                }); 
            }
        }
       
    }

    const uploadFloorplanToAWS = (room_id, blobData) => {
        console.log('Uploading floorplan...')
        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: { 
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });
            const S3 = new AWS.S3();
            const objParams = {
                Bucket: ENVIRONMENT.BUCKET_NAME,
                Key: "room_assets/floorplan/" + room_id + ".jpg",
                ACL: 'public-read',
                Body: blobData,
                ContentType: "image/jpeg",
                CacheControl: 'no-cache'
            };
        
            S3.upload(objParams, function (err, data) {
                console.log(err ? 'ERROR!' : 'UPLOADED.');
            });
        });
    }

    const uploadFloorplanToGoogle = (room_id, blobData) => {
        let thumbnail_path = "room_assets/floorplan/" + room_id + ".jpg";
        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': thumbnail_path
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response);
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': thumbnail_path
                    })
                  }
              };
        
              xhr.send(blobData);
          });
    }

    const upload_floorplan = (room_id, blobData) => {
        if(props.roomReducer.room.platform == 'google')
        {
            uploadFloorplanToGoogle(room_id, blobData)
        } else {
            uploadFloorplanToAWS(room_id, blobData)
        }        
    }

    const update_floorplan = () => {
        if ( props.roomReducer.room.floorplan != undefined ){
            if( props.roomReducer.room.floorplan[0] != undefined ){
                let canvas = document.getElementById("c");
                let dataUrl = canvas.toDataURL("image/jpeg");
                var blobData = dataURItoBlob(dataUrl);
                upload_floorplan(props.roomReducer.room.room_id, blobData);
            }     
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                update_floorplan();
                props.updateRoom({...formatRoom(values), room_id: props.roomReducer.room.room_id});
            }
        });
    };

    const formatRoom = (form) => {
        let product = {...form};
        return product;
    }

    const patchRoom = (product) => {
        let updatedProduct = {elements: []};
        Object.keys(product).forEach((key) => {
            if(key != 'elements') {
                updatedProduct[key] = product[key];
            }
        });
        product.elements && product.elements.forEach((element) => {
            updatedProduct.elements.push(element.elementName);
            updatedProduct[element.elementName] =element.elementImages;
            // updatedProduct[`${element.elementName}_text`] =element.elementText;
            let text = {};
            if (Array.isArray(element.elementText)) {
                text = {
                    [element.elementName+"_text"]: element.elementText
                }
            } else {
                let element_text = [];
                element_text.push(element.elementText);
                text = {
                    [element.elementName+"_text"]: element_text
                }
            }
            updatedProduct[`${element.elementName}_text`] = element.elementText;
        });
        return updatedProduct;
    }

    let elementsMenu  = '';
    if (props.roomReducer.room.elements && props.roomReducer.room.elements.length > 0)
    {
        elementsMenu = props.roomReducer.room.elements.map((ele, index) => {
            return ele ?<div>
                <a onClick={(e) => console.log(e.target.innerText)}>
                    {ele.elementName}
                </a>
            </div>:''
        });
    }

    return (
        <Form className="input-form" onSubmit={handleSubmit}>
            <Row gutter={16}>
              
                <Col span={24}>
                <h5 style={{textAlign: 'left', marginBottom: 5}} className='manrope f-14 black-00'>You can tag space elements added in previous step on your floor planner. </h5>
                <h5 style={{textAlign: 'left'}}  className='manrope f-12 black-55'>Your labelled floorplan won't be saved if you exit without completing the form.</h5>

                <Col span={24}>
                    {loader ? <div className="justify-in-center" style={{width: '100%'}}>
                        <LoadingOutlined style={{fontSize: 24, fontWeight: 600, marginBottom: 12}}/>
                    </div>: ""}
                </Col>
                    <Annotation elementNames={elementNames} canvas={setCanvasObj} loader={loader}
                    setLoader={setLoader}
                     room_id={props.roomReducer.room.room_id}></Annotation>
                </Col>

                <Col span={24} style={{display:"flex",justifyContent:"space-between",marginTop: '15px'}}>
                    <Form.Item>
                        <Button onClick={handleRedirection} className="modal-okay-gray square font-14">
                            Cancel
                        </Button>
                    </Form.Item>
                    <Form.Item className="justify-buttons">
                        <Button onClick={(e) => {
                            canvasObj.deactivateAll();
                            canvasObj.renderAll();
                            setSubmitType('save');
                        }}  className="modal-okay-gray square font-14" htmlType="submit" style={{marginRight: '5px'}}>
                            Save Draft & Exit {props.roomReducer.isLoading && submitType == 'save'?     <LoadingOutlined spin />: ''}
                        </Button>
                        <Button onClick={(e) => {
                            canvasObj.deactivateAll();
                            canvasObj.renderAll();
                            setSubmitType('continue');
                            }}  className="modal-okay-green square font-14" htmlType="submit">
                            Continue  {props.roomReducer.isLoading && submitType == 'continue'? <LoadingOutlined spin />: ''}
                            
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
const RoomElements = Form.create({ name: 'room_elements' })(RoomElementsForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
    getRoom: (room) => {
        dispatch(fetchRoom(room));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(RoomElements)
)