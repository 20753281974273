import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import CustomHeader from '../../CustomHeader';
import Sidebar from './CustomerSidebar';
import './CustomerMainLayout.scss';

const { Header, Content } = Layout;

class CustomerMainLayout extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          collapsed: true,
          selected: props.selected,
          current_page: this.props.match.url,
          remove_global_padding: props.remove_global_padding
      };
  }

  toggle = () => {
      this.setState({
          collapsed: !this.state.collapsed,
      });
  };

  render() { 
      return (
          <div className="gx-app-layout">
              <Header className="gx-app-header">
                  <CustomHeader />
              </Header>
              <Layout className="gx-app-main">
                  <Sidebar selected={this.props.selected} />
                  <Content className={`gx-layout-content ${this.state.remove_global_padding ? `customer-main-layout-pd-0` : ``}`}>
                      {this.props.children}
                  </Content>
              </Layout>
          </div>
      );
  }  // Closing bracket for render method
}

export default withRouter(CustomerMainLayout);
