import React, { useState, useEffect,useCallback }  from 'react';
import { withRouter, Link } from 'react-router-dom'
import {connect} from "react-redux";
import AWS from 'aws-sdk';
import _ from "lodash" // Import the entire lodash library
import axios from 'axios';
import * as JSZip from 'jszip';
import { DeleteOutlined, InfoCircleOutlined, LoadingOutlined, PaperClipOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Radio, Select, Upload, message, TreeSelect, Collapse, Checkbox, Modal, Card, Tooltip, InputNumber,Form } from 'antd';
import 'rc-color-picker/assets/index.css';
import * as Constants from "../../CustomerComponents/Constants";
import * as Utilities from "../../Utilities";
import { createRoom, updateRoom, fetchRoom } from "../../../../redux/actions";
import $ from 'jquery';
import ENVIRONMENT  from '../../../../environments'
import SpaceTags from '../../FormUtilities/SpaceTags';
import FileConstants from "../../../../FileConstants";
import LicenseFields from '../../FormUtilities/LicenseFields';
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as Styles from '../../../../Styles';
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import CardSaveDrawer from '../../CardSaveDrawer/CardSaveDrawer';
import WarningModal from '../../WarningModal/WarningModal';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const { Option } = Select;
const { Panel } = Collapse;

const progress_bar = Styles.progress_bar;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

let canvasContainer = null;

const SpaceVariationRequest = (props) => {

    const [submitType, setSubmitType] = useState('submit')
    const [publicLicensePanel, setPublicLicensePanel] = useState(["1"]);
    const [privateLicensePanel, setPrivateLicensePanel] = useState(["1"]);
    const [publicLicenseChecked, setPublicLicenseChecked] = useState(false);
    const [privateLicenseChecked, setPrivateLicenseChecked] = useState(false);
    const [forSale, setForSale] = useState(false);
    const [forCreativeCommon, setForCreativeCommon] = useState(false);
    const [creativeCommon, setCreativeCommon] = useState('');
    const [formValidationFailed, setFormValidationFailed] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [archiveValidationError, setArchiveValidationError] = useState('');
    const [submittingSaveRequest, setSubmittingSaveRequest] = useState(false);
    const [submittingCreateRequest, setSubmittingCreateRequest] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [categoryValue, setCategoryValue] = useState('');
    const [elementArray, setElementArray] = useState([]);
    const [spaceCreated, setSpaceCreated] = useState(false);
    const [selectedOutsideIndex, setSelectedOutsideIndex] = useState(0);
    const [initialFormValue, setInitialValues] = useState({});
    const [floorPlan, setFloorPlan] = useState([]);
    const [spacePhotosList, setSpacePhotosList] = useState([]);
    const [spaceElementPhotos, setSpaceElementPhotos] = useState([]);
    const [spaceVideosList, setSpaceVideosList] = useState([]);
    const [rawFileList, setRawFileList] = useState([]);
    const [outsidePhotoList, setOutsidePhotoList] = useState([]);
    const [floorPlanUploaded, setFloorPlanUploaded] = useState(false);
    const [, updateState] = React.useState();
    const [textFieldArr, setTextFieldArr] = useState([]);
    const [selectedOutsideView, setSelectedOutsideView] = useState(FileConstants.viewOutsideImages[0]);
    const { match: { params } } = props;
    const [blob_data, setBlobData] = useState(false);
    const [form] = Form.useForm();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [canvasObj, setCanvasObj] = useState(null);
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
    const [spaceCreatePayload,setSpaceCreatePayload] = useState(null);	
    const [cardSaveFailure, setCardSaveFailure] = useState(false);	
    const [spaceName, setSpaceName] = useState('');
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [requestPrice, setRequestPrice] = useState(-1);
    const [prices, setPrices] = useState([]);
    const [cardStatus, setCardStatus] = useState(null);
    const [requestStatus, setRequestStatus] = useState(null);
    const [price, setPrice] = useState(-1);
    const [priceError, setPriceError] = useState('');
    const [variantOf, setVariantOf] = useState('');
    const [spaceCategories, setSpaceCategories] = useState([]);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const SPACE_NAME_PREFIX = "Variation of ";


    useEffect(() => {
        setSpaceCategoriesData();
        fetchProjectData();
    },[]);

    useEffect(() => {
        if(submittingSaveRequest){
            if (spaceCreated && !floorPlanUploaded && floorPlan && floorPlan.length > 0) {
                let space_id = props.roomReducer.room.room_id;
                if (elementArray.length > 0) {
                    upload_floorplan(space_id, blob_data);
                } else {
                    setFloorPlanUploaded(true);
                }
            }
            if (floorPlanUploaded || (floorPlan && floorPlan.length == 0)) {
                props.submitVariation(1);
            }
        }
        else if(submittingCreateRequest) {
            if (!floorPlanUploaded && floorPlan && floorPlan.length > 0) {
                let space_id = props.roomReducer.room.room_id;
                if (elementArray.length > 0) {
                    update_floorplan(space_id, true);
                } else {
                    setFloorPlanUploaded(true);
                }
               
            } else {
                props.submitVariation(0);
            }
        } else {
            let room_id = props.space_id;
            if (room_id != undefined) {
                if (form && form.current) {
                    console.log('Initial Values', form.current.getFieldsValue());
                    setInitialValues(form.current.getFieldsValue());
                }

                if (props.space_details) {
                    getSpaceCategories();
                    setVariantOf(props.space_details.variant_of);
                    console.log('Space Details',props.space_details);
                    setSpaceName(SPACE_NAME_PREFIX + props.space_details.room_name);
                    setFloorPlanSettings(props.space_details.floorplan);
                    setOutsidePhotoList(props.space_details.outside_view_custom);
                    setRawFileList(props.space_details.raw_files);
                    setSpacePhotosList(props.space_details.room_photos);
                    setSpaceVideosList(props.space_details.video);
                    setSelectedOutsideView(props.space_details.outside_view);
                    if (props.automated_variation) {
                        setCategoryValue("Automated");
                    }
                    else {
                        setCategoryValue(props.space_details.category);
                    }
                    if (props.space_details.outside_view) {
                        if (props.space_details.outside_view.name) {
                            if (props.space_details.outside_view.name.includes('Sunlight')) {
                                selectOutsideView(0);
                            } else if (props.space_details.outside_view.name.includes('Countryside')) {
                                selectOutsideView(1);
                            } else if (props.space_details.outside_view.name.includes('Garden')) {
                                selectOutsideView(2);
                            } else {
                                setSelectedOutsideIndex(-1);
                                setSelectedOutsideView({});
                            }
                        } else {
                            setSelectedOutsideIndex(-1);
                            setSelectedOutsideView({});
                        }
                        
                    }
                    if (props.space_details.licensing_options != undefined) {
                        let licensing_options = props.space_details.licensing_options;
                        if (licensing_options.license != undefined) {
                            if (licensing_options.license == 'public') {
                                setPublicLicenseChecked(true);
                                if (licensing_options.public_option != undefined) {
                                    if (licensing_options.public_option == 'forSale') {
                                        setForSale(true);
                                    } else {
                                        setForCreativeCommon(true);
                                        if (licensing_options.creative_common != undefined) {
                                            setCreativeCommon(licensing_options.creative_common);
                                        }
                                    }
                                }
    
                            }
                            else {
                                setPrivateLicenseChecked(true);
                            }
                        }
                    }

                    if (props.space_details.elements && props.space_details.elements.length > 0) {
                        let arr = [];
                        props.space_details.elements.map(element => {
                            if (element) {
                                arr.push(element.elementName);
                            }
                            
                        })
                        setElementArray(arr);
                        setTextFieldArr(arr);
                        form.current.setFieldsValue({elements: arr});
                        let elements_array = [];
                        props.space_details.elements.map(element => {
                            if (element) {
                                elements_array[element.elementName] = element.elementImages;
                            }
                        })
                        setSpaceElementPhotos(elements_array);                        
                    }
                    
                }

            }
        }

    },[props.roomReducer,floorPlanUploaded]);

    const fetchProjectData = async () => {
        let payload = {
            order_by: "id desc",
            required_fields: [
              "id",
              "name"
            ],
            filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
          };
    
        await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {setProjects(res.data);});
        console.log("PROJECTS:",projects);
      };

    const migrateData = (room_id) => {
        if(props.roomReducer.room.platform != ENVIRONMENT.getUploadPlatform())
        {
            let copy_to = "";
            let copy_from = "";
            if(props.roomReducer.room.platform == "aws"){
                copy_from = "aws";
                copy_to = "google";
            }else{
                copy_from = "google";
                copy_to = "aws";
            }

            let payload = {
                "entity_type" : "room",
                "id" : room_id,
                "copy_from" : copy_from,
                "copy_to" : copy_to
            }

            axios.post(ENVIRONMENT.VARIANT_DATA_MIGRATOR, payload)
                .then(res => {});
        }
    }

    const loadUserRequestStatus = () => {
        let payload = {
          "username": localStorage.getItem('username'),
          "request_type": "variation_room_model",
          "action": "check",
          "category": categoryValue
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                console.log(res.data)
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
    
            });
    }

    useEffect(() => {
        if (categoryValue) {
            loadUserRequestStatus();
        }
    },[categoryValue])

    useEffect(() => {
        if (textFieldArr.length > 0 && (props.roomReducer.isCreated == false && props.roomReducer.isUpdated == false)) {
            props.space_details.elements.map(element => {
                if (element) {
                    let image = {
                        [element.elementName]: element.elementImages
                    }
                    let text = {};
                    if (Array.isArray(element.elementText)) {
                        text = {
                            [element.elementName+"_text"]: element.elementText
                        }
                    } else {
                        let element_text = [];
                        element_text.push(element.elementText);
                        text = {
                            [element.elementName+"_text"]: element_text
                        }
                    }
                    form.current.setFieldsValue(text);
                }
            })
            console.log('Initial Values After adding elements ', form.current.getFieldsValue());
        }
    
    },[textFieldArr])

    useEffect(() => {
        if( floorPlan.length > 0 && elementArray.length > 0) {
            if (canvasObj == null) {
                setCanvasObject(true);
            }     
        }
    },[elementArray]);

    const cancelPaymentConfirmation = () => {
        setPaymentConfirmationModal(false);
        setSubmittingCreateRequest(false);
    }

    const setCanvasObject = (first_element) => {
        if (canvasObj != null) { //checking for floorplan: the case where a new floorplan is added
            canvasObj.dispose();
            canvasObj.clear();
            setCanvasObj(null);
           
            $('#floorplan-canvas').hide();
            
            $('#floorplan-canvas').siblings('.upper-canvas').remove();
            $('#floorplan-canvas').parent('.canvas-container').before($('#floorplan-canvas'));
            $('.canvas-container').remove();
            $('#floorplan-canvas').show();
           
        } 
        let canvas_object = new window.fabric.Canvas('floorplan-canvas');
        setCanvasObj(canvas_object);
        elementArray.map(element => {
            setText(element, canvas_object);
        });    
    }

    useEffect(() => {
        if (canvasObj != null) {
            adjustCanvasSize();
        }
        
    },[canvasObj]);

    const getSpaceCategories = () => {
        let payload = {
            "request_type": props.requestType,
        }
    
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then(res => {
            console.log(res);
            if (res.data) {
                setPrices(res.data);
            }
        })
    }
    
    useEffect(() => {
        changeRequestPrice(categoryValue);
    }, [prices,categoryValue])

    const adjustCanvasSize = () => {
        canvasContainer = document.getElementById("canvas-settings");
        if (canvasContainer != null && floorPlan.length > 0) {
            let floorplanName = floorPlan[0].name;
            let floorplanURL = ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.BASE_URI + floorPlan[0].uid + "/" +  floorPlan[0].name;
            if(floorplanName.includes('.pdf')) {
                handlePdfFloorplan(floorplanURL, canvasContainer);
            }
            else {
                setCanvasBackground(floorplanURL, canvasContainer);   
            }  
        }
    }


    const setText = (text, canvas_object) => {
        if (canvas_object != null) {
            let t = new window.fabric.Text(text, {
                fontFamily: 'Manrope',
                stroke: '#D40003'
            });
            canvas_object.add(t);
            forceUpdate();    
        }
    }

    const changeRequestPrice = (category) => {
        let price =  prices.filter((request) => request.request_type == props.requestType && request.category == category);
        console.log(price);
        if (price[0]) {
            setRequestPrice(parseInt(price[0].price));
            setSelectedPriceCategory(price[0].category);
        }
    }

    const changeSpaceCategory = (e) => {
        console.log(e)
        changeRequestPrice(e.target.value);
    }

    const handlePdfFloorplan = (floorplanURL, cDim) => {
        var loadingTask = pdfjsLib.getDocument({ url: floorplanURL });
        loadingTask.promise.then(function (pdf) {
            pdf.getPage(1).then((page) => {
                let scale = 1;
                let viewport = page.getViewport({scale});
                //
                // Prepare canvas using PDF page dimensions
                //
                let canvas = document.getElementById('pdf-canvas');
                let context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
    
                //
                // Render PDF page into canvas context
                //
                let task = page.render({canvasContext: context, viewport: viewport})
                task.promise.then(function(){
                    let imageDataFromPDF = canvas.toDataURL('image/jpeg');
                    setCanvasBackground(imageDataFromPDF, cDim);
                });
                });
        });
    }

    const setCanvasBackground = (floorplanURL, cDim) => {
        window.fabric.Image.fromURL(floorplanURL, (oImg) => {
            let scaleW = cDim.clientWidth / oImg.width;
            let scaleH = cDim.clientHeight / oImg.height;

            if (oImg.width < oImg.height) {
                if (scaleH > scaleW) {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleW,
                        scaleY: scaleW,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
                else {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleH,
                        scaleY: scaleH,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
            }
            else if (oImg.width > oImg.height) {
                if (scaleH > scaleW) {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleW,
                        scaleY: scaleW,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
                else {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleH,
                        scaleY: scaleH,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
            }
            else if (oImg.width > 949) {
                if (scaleH > scaleW) {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleW,
                        scaleY: scaleW,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
                else {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleH,
                        scaleY: scaleH,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
            }
            else if(oImg.width < 949 ){
                if (oImg.width == oImg.height) {
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleY: scaleW,
                            scaleX: scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    } else {
                        oImg.set({
                            opacity: 1,
                            scaleY: scaleH,
                            scaleX: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }

                } else {
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleY: scaleH,
                            scaleX: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                    else {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleW,
                            scaleY:scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                }
                
            }
            if (canvasObj != null) {
                canvasObj.setBackgroundImage(oImg);
                canvasObj.renderAll();
            }           
        },{ crossOrigin: 'anonymous'});
    }

    const changeWindowSize = () => {
        if (canvasObj != null) {
            if(floorPlan.length > 0) {
                adjustCanvasSize();
            }
        }
    }

    useEffect(() => {
        changeWindowSize();
        forceUpdate();
       
    },[window.devicePixelRatio]);
    
    window.addEventListener('resize', changeWindowSize, false);

    const setFloorPlanSettings = (fileList) => {
        setFloorPlan(fileList);
        if (Utilities.validateFileUploads(fileList) != "uploading" && Utilities.validateFileUploads(fileList) != "error") {
            if (elementArray.length > 0 && fileList.length > 0) {
                setCanvasObject(false);
            }
        }
       
    }

    const uploadFloorplanToAWS = (room_id, blobData) => {
        console.log('Uploading floorplan...')
        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: { 
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });
            const S3 = new AWS.S3();
            const objParams = {
                Bucket: ENVIRONMENT.BUCKET_NAME,
                Key: "room_assets/floorplan/" + room_id + ".jpg",
                ACL: 'public-read',
                Body: blobData,
                ContentType: "image/jpeg",
                CacheControl: 'no-cache'
            };
        
            S3.upload(objParams, function (err, data) {
                if (err) {
                    setFloorPlanUploaded(false);
                    message.error('Unable to upload floorplan, please check your internet connection');
                } else {
                    setFloorPlanUploaded(true);
                }
                console.log(err ? 'ERROR!' : 'Floor PLAN UPLOADED.');
            });
        });
    }

    const uploadFloorplanToGoogle = (room_id, blobData) => {
        let thumbnail_path = "room_assets/floorplan/" + room_id + ".jpg";

        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': thumbnail_path
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response);
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': thumbnail_path
                    })
                  }
              };
        
              xhr.send(blobData);
          });
    }

    const upload_floorplan = (room_id, blobData) => {
        if(props.roomReducer.room.platform == 'google')
        {
            uploadFloorplanToGoogle(room_id, blobData)
        } else {
            uploadFloorplanToAWS(room_id, blobData)
        }
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'variation_room_model') {
                confirmPaymentAndSubmit();
            }
            
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=variation_room_model', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);  
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const update_floorplan = (room_id, elements_added) => {
        console.log(room_id,elements_added,floorPlan)
        if ( floorPlan.length > 0 ){
            if (elements_added) {
                let canvas = document.getElementById("floorplan-canvas");
                let dataUrl = canvas.toDataURL("image/jpeg");
                var blobData = Utilities.dataURItoBlob(dataUrl);
                setBlobData(blobData);
                if (room_id != -1) {
                    upload_floorplan(room_id, blobData);
                }
                
            } else {
                var canvas = document.createElement("canvas");
                let context = canvas.getContext('2d');
                let base_image = new Image();
                base_image.src = ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.BASE_URI + floorPlan[0].uid + "/" + floorPlan[0].name;
                console.log(base_image.src);
                base_image.onload = function(){
                    context.drawImage(base_image, 100, 100);
                }
                let dataUrl = canvas.toDataURL("image/jpeg");
                var blobData = Utilities.dataURItoBlob(dataUrl);
                setBlobData(blobData);
                if (room_id != -1) {
                    upload_floorplan(room_id, blobData);
                }            
            }
           
        }
    }

    useEffect(()=> {
        checkIfValidAgain();
    }, [publicLicenseChecked, privateLicenseChecked, forSale, forCreativeCommon, creativeCommon]);

    const checkIfValidAgain = (e) => {
        if(e != undefined) {
            e.preventDefault();
        }
        if(formValidationFailed){
            form.current.validateFields().then(values => {
                if(validateCustomFields()){
                    setFormValidationFailed(false);
                }
            
            });
            
            
        }
    }


    const getLicensingOptions = () => {
        let licensing_options = {
            license: publicLicenseChecked?'public':'private'
        }

        if(publicLicenseChecked){
            licensing_options.public_option = forSale?'forSale':'forCreativeCommon';
            if(forCreativeCommon){
                licensing_options.creative_common =  creativeCommon; 
            }
        }

        return licensing_options;
    }

    const validateCustomFields = () => {
        if (!publicLicenseChecked && !privateLicenseChecked){
            return false;
        }
        else if (publicLicenseChecked && (!forSale && !forCreativeCommon) ){
            return false;
        }
        else if (publicLicenseChecked && forCreativeCommon && creativeCommon == '') {
            return false;
        }
        else{
            return true;
        }
    }

    const addElements = (array) => {
        let index = array.length - 1;
        if (array[index] != "") {
            if (canvasObj) {
                if (array.length > elementArray.length) {
                    
                    setText(array[index], canvasObj);
                }
            }
        let elements_array = array.filter(element => element != "");
        console.log(elements_array);
        setElementArray(elements_array);

        }
    }

    const deselectElement = (value) => {
        if (canvasObj) {
            let canvas_obj = canvasObj.getObjects();
            canvas_obj = canvas_obj.filter(obj => {
                if (obj.text == value) {
                    canvasObj.remove(obj);
                }
            });
        }
        
    }

    const deleteElement = (value) => {
        deselectElement(value);
        let elements = elementArray.filter(element => element != value);
        setElementArray(elements);
        form.current.setFieldsValue({elements: elements});
    }

    const changePrice = (e) => {
        setPrice(e.target.value);
    }

    const handleSubmit = (values) => {
        console.log(values);
        let space_form_data = values;

        let floorplanUploadStatus = 'not_started';
        values['floorplan'] = floorPlan;
        floorplanUploadStatus = Utilities.validateFileUploads(values['floorplan']);

        let photoUploadStatus = 'not_started';
        values['room_photos'] = spacePhotosList;
        photoUploadStatus = Utilities.validateFileUploads(values['room_photos']);

        let rawFileStatus = 'not_started';
        values['raw_files'] = rawFileList;
        rawFileStatus = Utilities.validateFileUploads(values['raw_files']);

        let videoFileStatus = 'not_started';
        values['video'] = spaceVideosList;
        videoFileStatus = Utilities.validateFileUploads(values['video']);

        let outsideFileStatus = 'not_started';
        values['outside_view_custom'] = outsidePhotoList;
        outsideFileStatus = Utilities.validateFileUploads(values['outside_view_custom']);

        let space_element_check = 1;
        if (elementArray.length > 0) {
            elementArray.map(element => {
                let element_status = Utilities.validateFileUploads(spaceElementPhotos[element]);
                if (element_status == 'uploading') {
                    space_element_check = 0;
                } else if (element_status == "error") {
                    space_element_check = -1;
                }
              
            });
        }
        

        if (publicLicenseChecked && forSale && price == -1) {
            setPriceError('Please enter a price')
        }       
        else if (space_element_check == 0 || photoUploadStatus == 'uploading' || rawFileStatus == 'uploading' || videoFileStatus == 'uploading' || floorplanUploadStatus == 'uploading' || outsideFileStatus == 'uploading') {
            setUploadError('Uploads are in progress, please wait till file uploads are completed.')
        } else if (space_element_check == -1 || photoUploadStatus == 'error' || rawFileStatus == 'error' || videoFileStatus == 'error' ||  floorplanUploadStatus == 'error' || outsideFileStatus == 'error') {
            setUploadError('Error occured in uploading files, please re-upload your files.')
        } else if (((floorPlan && floorPlan.length > 0) || floorplanUploadStatus == 'done'))  {
            if (validateCustomFields() && (props.automated_variation || (outsidePhotoList && outsidePhotoList.length != 0 || selectedOutsideIndex != -1))) {
                setUploadError('');
                setPriceError('');
                space_form_data.licensing_options = getLicensingOptions();
                space_form_data.variant_of = variantOf == '' ? props.space_id : variantOf;
                space_form_data.immediate_parent_variant = props.space_id;

                space_form_data.outside_view_custom = outsidePhotoList;
                if (selectedOutsideIndex != -1) {
                    space_form_data.outside_view = selectedOutsideView;
                }

                if (elementArray.length > 0) {
                    console.log(space_form_data.elements)
                    let element_json = [];
                    let element_data = {};
                    elementArray.map((element) => {
                        if (element) {
                            element_data = {};
                            element_data.elementName = element;
                            element_data.elementImages = spaceElementPhotos[element];
                            element_data.elementText = space_form_data[`${element}_text`];
                            element_json.push(element_data);
                            
                        }
                    })
                    space_form_data.elements = element_json;
                }
                
                let space_id = props.savedID;
                if (forSale && price != -1) {
                    space_form_data.price = price;
                }

                console.log(space_id);
                let gateway_payload = {};
                if (space_id != -1) {
                    space_form_data.room_id = space_id;
                    space_form_data.model_status = "2";
                    console.log("Update Space");
                    let payload = {...space_form_data, room_id: space_id};
                    console.log('Payload ', payload);
                    if (floorPlan && floorPlan.length > 0) {
                        if (elementArray.length > 0) {
                            update_floorplan(space_id, true);
                        } else {
                            update_floorplan(space_id, false);
                        }
                    }
                    let requestPayload = payload;
                    requestPayload['username'] = localStorage.getItem('username');
                    gateway_payload = {
                        "username": localStorage.getItem('username'),
                        "request_type": props.requestType,
                        "update": "",
                        "action": "perform",
                        "request_payload": requestPayload,
                        "category": selectedPriceCategory,
                        "request_id": Date.now().toString()
                    }
                }
                else {
                    console.log("Create Space");
                    setSpaceCreated(true);
                    let payload = {...space_form_data, required_assets: ['3D Model'],create_room: 1};
                    console.log('Payload ', payload);
                    let company_id = localStorage.getItem('company_id');
                    if(company_id != undefined) {
                        payload.company_id = company_id;
                    }
                    let requestPayload = payload;
                    requestPayload['username'] = localStorage.getItem('username');
                    if (props.automated_variation) {
                        requestPayload['automated_variation'] = props.automated_variation;
                        requestPayload['room_variation'] = props.space_variation;
                    }
                    gateway_payload = {
                        "username": localStorage.getItem('username'),
                        "request_type": props.requestType,
                        "action": "perform",
                        "request_payload": requestPayload,
                        "category": selectedPriceCategory,
                        "request_id": Date.now().toString()
                    }           
                }
                gateway_payload["request_payload"]['user_type'] = "customer"
                if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null || FileConstants.MANAGED_COMPANY_ID != null)) {
                    gateway_payload["request_payload"]['requested_for'] = FileConstants.MANAGED_CUSTOMER_USERNAME
                }   
                if (requestStatus == 'allowed' || requestStatus == 'always_allowed') {
                    migrateData(props.roomReducer.room.room_id);
                    setSubmittingCreateRequest(true);
                    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status!=undefined && res.data.status == 1) {
                        setSubmittingCreateRequest(false);
                        props.submitVariation(0);
                        }
                        else{
                            message.error('something went wrong');
                        }
                    });
                }
                if (requestStatus == 'payment_required') {
                    console.log("Card status", cardStatus);
                    setSpaceCreatePayload(gateway_payload);
                    if (cardStatus == 1) {
                        setPaymentConfirmationModal(true);
                    } 
                    if (cardStatus == 0) {
                        setPaymentConfirmationModal(true);
                    }
                }
                if (requestStatus == 'not_allowed') {
                    setInAppLimitExceededModal(true);
                } 
            } else if (!props.automated_variation) {
                
                if (outsidePhotoList.length == 0 && selectedOutsideIndex == -1) {
                    setUploadError('Outside information missing! Either select an outside image or upload your own in the section provided.')
                } else {
                    setFormValidationFailed(true);
                }
            }
        } else {
            console.log('No space photos',spacePhotosList,photoUploadStatus)
            if ((floorPlan.length == 0 && floorplanUploadStatus == 'not_started')) {
                setUploadError('Please upload floorplan')
            }
        }
    }

    const setSpaceCategoriesData = () => {
        let payload = {
          'request_type': props.requestType
        }
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
        .then(res => {
          let categories = []
          if (res.data) {
            res.data.map((request) => {
              if (!categories.includes(request.category)) {
                categories.push(request.category);
              }
            })
            setSpaceCategories(categories);
          }
        })
      }


    const initiateConfirmation = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'variation_room_model') {
                confirmPaymentAndSubmit();
            }
            
        }, false);
        
        if (cardStatus == 1) {
            confirmPaymentAndSubmit();
        } 
        if (cardStatus == 0) {
            let child_window = window.open('/save-card?action=variation_room_model', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);  
              }
            }, 500);
            setCardSaveFlowTriggered(true);
        }
    }


    const confirmPaymentAndSubmit = (payload = null) => {
        if (payload == null) {
            payload = spaceCreatePayload
        }
        console.log(JSON.stringify(payload))
        setSubmittingCreateRequest(true);
        migrateData(props.roomReducer.room.room_id);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                if (res.data.status!=undefined && res.data.status == 1) {
                setSubmittingCreateRequest(false);
                setPaymentConfirmationModal(false);
                props.submitVariation(0);
                }
                else{
                    setPaymentFailureMessage(res.data.error_message);
                    setPaymentFailureModal(true);
                    setSubmittingCreateRequest(false);

                }
            });
    }

    const saveRequestIntermedietely = (callback = null) => {
        setSubmittingCreateRequest(true);
        console.log(JSON.stringify(spaceCreatePayload))
        let payload = {
            'request_type': props.requestType,
            'request_body': spaceCreatePayload,
            'processed': false
        }
        if (props.paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
                .then(res => {
                    console.log(res);
                    setSubmittingCreateRequest(false);
                    setPaymentConfirmationModal(false);
                    if (callback!= null)
                    {
                    callback(res.data[0].request_id,props.requestType);
                    }
                });
        } else {
            setSubmittingCreateRequest(false);
            setPaymentConfirmationModal(false);
            props.submitVariation(0);
            if (callback!= null)
            {
                callback(props.paymentRequestID,props.requestType);
            }
        }
    }

    const handleSave = () => {
        let values = form.current.getFieldsValue();
        console.log('handleSave ',values);        
        let space_form_data = values;

        let floorplanUploadStatus = 'not_started';
        values['floorplan'] = floorPlan;
        floorplanUploadStatus = Utilities.validateFileUploads(values['floorplan']);

        let photoUploadStatus = 'not_started';
        values['room_photos'] = spacePhotosList;
        photoUploadStatus = Utilities.validateFileUploads(values['room_photos']);

        let rawFileStatus = 'not_started';
        values['raw_files'] = rawFileList;
        rawFileStatus = Utilities.validateFileUploads(values['raw_files']);

        let videoFileStatus = 'not_started';
        values['video'] = spaceVideosList;
        videoFileStatus = Utilities.validateFileUploads(values['video']);

        let outsideFileStatus = 'not_started';
        values['outside_view_custom'] = outsidePhotoList;
        outsideFileStatus = Utilities.validateFileUploads(values['outside_view_custom']);

        let space_element_check = 1;
        if (elementArray.length > 0) {
            elementArray.map(element => {
                let element_status = Utilities.validateFileUploads(spaceElementPhotos[element]);
                if (element_status == 'uploading') {
                    space_element_check = 0;
                } else if (element_status == "error") {
                    space_element_check = -1;
                }
              
            });
        }
        
        if (space_element_check == 0 || photoUploadStatus == 'uploading' || rawFileStatus == 'uploading' || videoFileStatus == 'uploading' || floorplanUploadStatus == 'uploading' || outsideFileStatus == 'uploading') {
            setUploadError('Uploads are in progress, please wait till file uploads are completed.')
        } else if (space_element_check == -1 || photoUploadStatus == 'error' || rawFileStatus == 'error' || videoFileStatus == 'error' ||  floorplanUploadStatus == 'error' || outsideFileStatus == 'error') {
            setUploadError('Error occured in uploading files, please re-upload your files.')
        } else {
            if((values['room_name'] != "" && values['room_name'] != undefined) && (values['category'] != "" && values['category'] != undefined)){
                setUploadError('');
                space_form_data.licensing_options = getLicensingOptions();
                if (!privateLicenseChecked && !publicLicenseChecked) {
                    space_form_data.licensing_options = {};
                }
                
                space_form_data.outside_view_custom = outsidePhotoList;
                if (selectedOutsideIndex != -1) {
                    space_form_data.outside_view = selectedOutsideView;
                }
                space_form_data.variant_of = variantOf == '' ? props.space_id : variantOf;
                space_form_data.immediate_parent_variant = props.space_id;

                if (forSale && price != -1) {
                    space_form_data.price = price;
                }
                if (elementArray.length > 0) {

                    console.log(space_form_data.elements,'space form elements')
                    let element_json = [];
                    let element_data = {};
                    console.log(elementArray);
                    elementArray.map((element) => {
                        if (element) {
                            console.log('ele',element)
                            element_data = {};
                            element_data.elementName = element;
                            element_data.elementImages = !spaceElementPhotos[element] ? [] : spaceElementPhotos[element];
                            element_data.elementText = !space_form_data[`${element}_text`] ? [] :space_form_data[`${element}_text`];
                            element_json.push(element_data);
                        }
                    })
                    
                    space_form_data.elements = element_json;
                    console.log('after',element_json)
                }

                let space_id = props.savedID;
                console.log(space_id);
                if (space_id != -1) {
                    space_form_data.room_id = space_id;
                    console.log("Update Space");
                    let payload = {...space_form_data, room_id: space_id};
                    console.log('Payload ', payload)
                    
                    if (floorPlan && floorPlan.length > 0) {
                        if (elementArray.length > 0) {
                            update_floorplan(space_id, true);
                        } else {
                            update_floorplan(space_id, false);
                        }
                    }
                    
                    props.updateRoom(payload);
                }
                else {
                    console.log("Create Space");
                    setSpaceCreated(true);
                    let payload = {...space_form_data, required_assets: ['3D Model']};
                    console.log('Payload ', payload);
                    console.log(JSON.stringify(payload));
                    let company_id = localStorage.getItem('company_id');
                    if(company_id != undefined) {
                        payload.company_id = company_id;
                    }
                    props.createRoom(payload);
                    if (floorPlan && floorPlan.length > 0) {
                        if (elementArray.length > 0) {
                            update_floorplan(-1, true);
                        } else {
                            update_floorplan(-1, false);
                        }
                    }
                   
                }
                setSubmittingSaveRequest(true);
            }
            else{
                if (values['room_name'] == "" || values['room_name'] == undefined) {
                    setUploadError('You must enter the space name to Save your Progress.')
                } else if (values['category'] == ""|| values['category'] == undefined) {
                    setUploadError('You must enter the space category to Save your Progress.')
                }
            }
        }
    }

    const controlLicenseDetailPanels = (value) => {
        if(value){
            setPublicLicensePanel(["1"]);
            setPrivateLicensePanel(["1"]);
        }
        else{
            setPublicLicensePanel();
            setPrivateLicensePanel();
        }
    }

    const changePrivateLicenseChecked = (value) => {
        setPrivateLicenseChecked(value);
        controlLicenseDetailPanels(!value);
        if(value){
            setPublicLicenseChecked(!value);   
        }
    }

    const changePublicLicenseChecked = (value) => {
        setPublicLicenseChecked(value);
        controlLicenseDetailPanels(!value);
        if(value){
            setPrivateLicenseChecked(!value);
        }
    }

    const changeForSale = (value) => {
        setForSale(value);
        if(value){
            setForCreativeCommon(!value);
        }
    }

    const changeForCreativeCommon = (value) => {
        setForCreativeCommon(value);
        if(value) {
            setForSale(!value);
        }
    }
   

    const handleChangeFloorplan = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFloorPlan(fileList);

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
            setFloorPlanSettings(fileList);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }
    }

    const handleSpaceElementChange = (info, element_name) => {
        let element_photos = spaceElementPhotos;
        let fileList = [...info.fileList];
        element_photos[element_name] = fileList;
        setSpaceElementPhotos(element_photos);

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }

    }

    const handleChangeSpacePhotos = (info) => {
        let fileList = [...info.fileList];
        setSpacePhotosList(fileList);

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }
    }

    const handleChangeRawFiles = (info) => {
        let fileList = [...info.fileList];
        setRawFileList(fileList);

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }
    }

    const handleChangeVideoFiles = (info) => {
        let fileList = [...info.fileList];
        setSpaceVideosList(fileList);

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }
    }

    const selectOutsideView = (index) => {
        setSelectedOutsideView(FileConstants.viewOutsideImages[index]);  
        setSelectedOutsideIndex(index);
        setOutsidePhotoList([]);
        form.current.setFieldsValue({outside_view_custom: []});
    }

    const handleChangeOutsideView = (info) => {
        let fileList = [...info.fileList];
        setOutsidePhotoList(fileList);
        
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
            setSelectedOutsideIndex(-1);
            setSelectedOutsideView({});
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }
    }

    const changeSpaceName = (e) => {
        setSpaceName(e.target.value);
    }
    

    return <div>
        {props.roomReducer.isLoading? 
        <div className="text-center"> 
        <LoadingOutlined type="sync" spin /> </div>: "" }
        <Form 
        scrollToFirstError
        onFinishFailed={file => {
            setUploadError('Please enter all required information before submitting.');
        }}
        ref={form}
        className="space-variation-form" 
        initialValues={{
            room_name: props.space_details && (SPACE_NAME_PREFIX + props.space_details.room_name),
            brand_id: props.space_details && props.space_details.brand_id,
            tags: props.space_details && props.space_details.tags,
            category: props.space_details && props.space_details.category,       
            reference_urls: props.space_details && props.space_details.reference_urls,
            floorplan:  floorPlan,
            room_photos: spacePhotosList,
            video: spaceVideosList,
            raw_files: rawFileList,
            outside_view_custom: outsidePhotoList,
            licensing_options: props.space_details && props.space_details.licensing_options,
            guidelines: props.savedID == -1 ? "" : props.space_details && props.space_details.guidelines
        }}        
        onFinish={handleSubmit} 
        layout="vertical">
            <div>
            <Row type='flex' gutter={12}>
                <Col span={24}>
                    <Collapse style={{marginTop: 22}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                        <Panel  header={<span className="manrope f-14 grey-77 w-700">Space Details</span>} key="1">
                            <Row  className="justify-space-between" style={{marginTop: 20,alignItems:'baseline'}}>

                                <Col span={24} style={{marginBottom: 20}}>
                                    <Form.Item  colon={false} label={<span className="manrope f-14 black-55 w-900">Space Name</span>} name="room_name" rules={[{ required: true, message: 'Please enter space name.' }]}>
                                        <Input placeholder={'Enter Space Name'} className="manrope f-12 black-55 library-search"/>
                                    </Form.Item>
                                </Col>

                                <Col span={12} style={{marginBottom: 20,paddingRight: 12}}>
                                    <Form.Item name="project"  label={(<span className='manrope f-14 black-55 w-900'>Projects&nbsp;</span>)} colon={false} rules={[{ required: false, message: 'Please select project.' }]}>
                                            <Select
                                                style ={{lineHeight: "220%"}}
                                                className="tree-select-material adjust-height-46 f-12"
                                                showSearch
                                                placeholder="Select or type in project name"
                                            >
                                                {Object.keys(projects).map((key) => (
                                                    <Select.Option
                                                        key={projects[key].id}
                                                        className="manrope f-12 black-55 select-view"
                                                        value={projects[key].id}
                                                    >
                                                        {projects[key].name}
                                                    </Select.Option>
                                                    ))}
                                            </Select>
                                    </Form.Item>
                                </Col>
                                
                                <Col span={12} style={{marginBottom: 20}}>
                                    <div className="material-field">
                                        <Form.Item  label={<span className="manrope f-14 black-55 w-900">Space Category</span>} name="category" className="material-category-form"
                                            rules={[{ required: (!props.space_details.category), message: 'Please select a space category.' }]}>
                                                <Select
                                                    onChange={changeSpaceCategory}
                                                    className={`manrope f-12 black-55 tree-select-material adjust-height-46`}
                                                    disabled={props.space_details.category}
                                                    placeholder="Select your space category"
                                                    >
                                                    {spaceCategories && spaceCategories.map((category,index) => {
                                                        return <Option className="manrope f-12 black-55 select-view" value={category}>{category}</Option>

                                                    })}
                                                </Select>
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col span={12} style={{marginBottom: 20,paddingRight: 12}}>
                                    <Form.Item  colon={false} label={<span className="manrope f-14 black-55 w-900">Tags</span>} name="tags" rules={[{ required: false, message: 'Please add Space Tags!' }]}>
                                        <SpaceTags  className="tree-select-material f-12 adjust-height-46 item-height-35"/>
                                    </Form.Item>
                                </Col>
                                
                                <Col span={12} style={{marginBottom: 20}}>
                                    <Form.Item  colon={false} label={<span className="manrope f-14 black-55 w-900">Space ID</span>} name="brand_id" rules={[{ required: false, message: 'Please enter Space ID!' }]}>
                                        <Input placeholder={'Enter Space ID'} className="manrope f-12 black-55 library-search"/>
                                    </Form.Item>
                                </Col>
                                {!props.automated_variation && <> <Col span={24} style={{marginBottom: 20}}>
                                    <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-900">Reference URL(s)</span>} name="reference_urls" rules={[{ required: false, type:'array', message: 'Please add reference url(s).' }]}>
                                        <Select className="manrope f-12 black-55 select-tags selection-popup" mode="tags" placeholder="Enter Reference URL(s) that you would like us to have a look at, for your space model">
                                            <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24} style={{marginBottom: 20}}>
                                    <Form.Item  name="guidelines" label={<span className="manrope f-14 black-55 w-900">Are there any changes required that you want us to make? Please mention below.</span>} colon={false}> 
                                        <Input.TextArea rows={3} className="manrope f-12 black-55 library-search"
                                            placeholder = "Enter details for any changes you want us to make" />
                                    </Form.Item>
                                </Col>

                                <Col span={24} className="justify-in-start manrope f-14 black-55 w-900" style={{marginBottom: 10}}>
                                    <span className="manrope f-12 red-d9">*</span>&nbsp;Floor Plan
                                </Col>
                                <Row gutter={[16,16]} className="justify-space-between" style={{width: "100%", alignItems:"flex-start"}}>
                                    <Col  xxl={10}  xl={24} lg={24}>
                                        <img src="/img/floorplan.png"/>
                                    </Col>
                                    <Col xxl={14}  xl={24} lg={24}>
                                        <Form.Item
                                        colon={false}
                                        label={''}
                                        name="floorplan"
                                        rules={[{ required: false, message: 'Please upload floorplan.' }]}>
                                            <Upload
                                                className={(Utilities.validateFileUploads(floorPlan) == "uploading" ||  Utilities.validateFileUploads(floorPlan) == "error") ? "ant-upload-picture-card-wrapper upload-box-references" : "ant-upload-picture-card-wrapper upload-box-references"}
                                                multiple={false}
                                                onChange = {handleChangeFloorplan}
                                                fileList={floorPlan}
                                                progress= {progress_bar}
                                                {...(Constants.getUploadProps())}
                                                onRemove={file => {
                                                    setFloorPlanSettings([]);
                                                    
                                                    setErrorMessage('');
                                                    return true;
                                                }}
                                            
                                                listType={"text"}
                                                >
                                                    {Utilities.validateFileUploads(floorPlan) == "uploading"  ? 
                                                        <div className="justify-in-center">
                                                            <div className="manrope f-12 grey-99 justify-in-center">Uploading...</div>
                                                        </div> : 
                                                        Utilities.validateFileUploads(floorPlan) == "done" || Utilities.validateFileUploads(floorPlan) == "not_started" ? 
                                                        <span>
                                                            <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                            <p className="manrope f-12 grey-77">Drop your floorplan here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                                                            <p className="manrope f-12 grey-77">
                                                            Supported formats: png, jpg, pdf
                                                            </p>
                                                        </span>:
                                                    Utilities.validateFileUploads(floorPlan) == "error" ? 
                                                    <span>
                                                        <div className="justify-in-center">
                                                            <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                            <div className="manrope f-12 red justify-in-center">Upload Failed</div>
                                                        </div>                        
                                                    </span> :
                                                    ''}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                        
                                <Col span={24} className="justify-in-start manrope f-14 black-55 w-900" style={{marginBottom: 10,marginTop: 20}}>Space Model Archives (Existing Space Model File)</Col>
                                <Col lg={8} xl={6}  style={{marginBottom: 20}}>
                                    <a download href={ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + props.space_id + '.zip'}>
                                        <div className="existing-model-box">
                                            <img src="/img/box-model.png" style={{marginRight: 20}}/>
                                            <div className="manrope f-12 black-55">{props.space_id + '.zip'}</div>
                                        </div>
                                    </a>
                                </Col>

                                <Col span={24} className="justify-in-start manrope f-14 black-55 w-900" style={{marginBottom: 10}}>
                                    Space Photos</Col>

                                <Col span={24} className="upload-pd" style={{marginBottom: 20, cursor:"default"}}>
                                    <Form.Item
                                    colon={false}
                                    label=""
                                    name="room_photos"
                                    className={spacePhotosList && spacePhotosList.length == 0 ? "" : "picture-card-custom"}
                                    rules={[{ required: false, message: 'You must upload at least one space photo to continue.' }]}>
                                        <Upload
                                            className={spacePhotosList && spacePhotosList.length == 0 ? "ant-upload-picture-card-wrapper upload-box-references": "upload-picture-card"}
                                            accept=".zip,.jpg,.jpeg,.png,.tiff"
                                            multiple={true}
                                            onChange = {handleChangeSpacePhotos}
                                            fileList = {spacePhotosList}
                                            progress= {progress_bar}
                                            {...(Constants.getUploadProps())}
                                            onRemove={file => {
                                                const index = spacePhotosList.indexOf(file);
                                                const newFileList = spacePhotosList.slice();
                                                newFileList.splice(index, 1);
                                                setSpacePhotosList(newFileList);
                                                newFileList.forEach((file) => {
                                                    if (file.status !== "error"){
                                                        setHasError(false)
                                                    }
                                                    else {
                                                        setHasError(true)
                                                    }
                                                })
                                                return true;
                                            }}
                                            listType={spacePhotosList && spacePhotosList.length == 0 ? "text" : "picture-card"}>
                                                {Utilities.validateFileUploads(spacePhotosList) == "done" || Utilities.validateFileUploads(spacePhotosList) == "uploading" ? 
                                                <span>
                                                    <PlusOutlined  className="manrope f-12 grey-77"/>

                                                    <p className="manrope f-12 grey-99">Drop or <span style={{color: "#276DD7"}}>Browse</span> to add more.</p>
                                                
                                                </span> : 
                                                Utilities.validateFileUploads(spacePhotosList) == "error" ? 
                                                <span>
                                                    <div className="justify-in-center">
                                                        <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                        <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                    </div>                        
                                                </span> :
                                                (Utilities.validateFileUploads(spacePhotosList) == "not_started" ? 
                                                    <span>
                                                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                        <p className="manrope f-12 grey-77">Drop your photos here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                                                        <p className="manrope f-12 grey-77">
                                                        Supported formats: jpg, png, tiff
                                                        </p>
                                                    </span>: '')}
                                        </Upload>
                                    </Form.Item>
                                </Col> </>}
                            </Row>
                    
                        </Panel>
                    </Collapse>
                </Col>
                {!props.automated_variation && <> <Col span={24}>
                    <Collapse style={{marginTop: 22}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>

                        <Panel  header={<span className="manrope f-14 grey-77 w-700">Space Elements and Outside View</span>} key="1">
                            <Col span={24}>
                            {floorPlan && floorPlan.length > 0 && Utilities.validateFileUploads(floorPlan) == "done"  ?
                            <Col span={24} className="justify-in-start manrope f-14 black-55 w-900" style={{marginBottom: 10,marginTop: 20}}>Add Space Elements&nbsp;
                                <Tooltip title={<span className="manrope f-12 white">Space elements consist of closets, flooring (tiles/wood/laminate etc), wall paper, wall paint, lighting, built-in-shelves, moulding etc. Add as many space elements as you need to describe your space, and provide references in the relevant section for each element.</span>}><InfoCircleOutlined /></Tooltip>
                            </Col>: ''}

                            {floorPlan && floorPlan.length > 0 && Utilities.validateFileUploads(floorPlan) == "done" ?
                            <Col span={24} style={{marginBottom: 20}}>
                                
                                <Form.Item className="manrope f-12 black-55 select-tags" name="elements" label=""  colon={false}  rules={[ {type: 'array',required:false, message: 'Please add space elements.'} ]}>
                            
                                    <Select onDeselect={deselectElement} onChange={addElements} className="manrope f-12 black-55" mode="tags" placeholder="Add your space elements">
                                        <Option className="manrope f-12 black-55 select-view" value=""></Option>
                                    </Select>
                                    
                                </Form.Item>
                            </Col>:''}

                            <Row className="justify-in-start">
                            {(floorPlan && floorPlan.length > 0 && Utilities.validateFileUploads(floorPlan) == "done") && elementArray && elementArray.map((element_name, index) => (
                                <Col style={{marginBottom: 10,marginRight: 10}} key={index}>
                                    <div className="justify-space-between" style={{marginBottom: 8}}>
                                        <div className="note-bg-artist manrope f-14 black-55">{element_name}</div>
                                        <DeleteOutlined className="note-bg-artist manrope f-14 black-55" style={{cursor:"pointer"}} onClick={() => {deleteElement(element_name)}}/>
                                    </div>
                                    <Card style={{padding: 16}}>
                                        <div className="manrope f-14 grey-77" style={{marginBottom: 5}}>Reference Images</div>
                                        <Form.Item
                                        style={{marginBottom: 10}}
                                        colon={false}
                                        label=""
                                        name={`${element_name}`}
                                        rules={[{ required: false, message: 'Please upload reference images' }]}>
                                            <Upload
                                                className={"upload-picture-card-200"}
                                                accept=".zip,.jpg,.jpeg,.png,.tiff"
                                                multiple={true}
                                                onChange = {(info) => {
                                                    handleSpaceElementChange(info,element_name);
                                                    console.log(spaceElementPhotos)
                                                }}
                                                fileList = {spaceElementPhotos[element_name]}
                                                progress={progress_bar}
                                                {...(Constants.getUploadProps())}
                                                onRemove={file => {
                                                    let element_photos = spaceElementPhotos;
                                                    const index = element_photos[element_name].indexOf(file);
                                                    const newFileList = element_photos[element_name].slice();
                                                    newFileList.splice(index, 1);
                                                    element_photos[element_name] = newFileList;
                                                    setSpaceElementPhotos(element_photos);
                                                    
                                                    return true;
                                                }}
                                                listType={"picture-card"}>
                                                    {Utilities.validateFileUploads(spaceElementPhotos[element_name]) == "done" || Utilities.validateFileUploads(spaceElementPhotos[element_name]) == "uploading" || Utilities.validateFileUploads(spaceElementPhotos[element_name]) == "not_started" ? 
                                                    <span>
                                                        <PlusOutlined  className="manrope f-12 grey-77"/>

                                                        <p className="manrope f-12 grey-77" style={{marginBottom: 5}}>Drag image here, or <span style={{color: "#276DD7"}}>Browse</span>.</p>
                                                        <p className="manrope f-12 grey-77">
                                                        Supported formats: jpg, png, tiff
                                                        </p>
                                                    </span> : 
                                                    Utilities.validateFileUploads(spaceElementPhotos[element_name]) == "error" ? 
                                                    <span>
                                                        <div className="justify-in-center">
                                                            <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                            <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                        </div>                        
                                                    </span> : ''}
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item colon={false} label={<span className="manrope f-14 grey-77">Reference URL(s)</span>} name={element_name + '_text'} rules={[{ required: false, type:'array', message: 'Please add reference url(s).' }]}>
                                            <Select className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter Reference URL(s) of your elements">
                                                <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                            </Select>
                                        </Form.Item>

                                        
                                    </Card>
                                </Col>
                            ))}
                            </Row>
                            {<>
                                <Col span={24} className="manrope f-14 grey-77" style={{marginBottom: 8,marginTop: 10,display:(floorPlan && floorPlan.length > 0 && Utilities.validateFileUploads(floorPlan) == "done") && elementArray.length > 0 ? 'block' : 'none'}}>Click and Drag Elements to Place Them</Col>
                                <Col span={24}>
                                    <Card style={{marginBottom: 20}}>
                                        {/* <div className="justify-space-between manrope f-18 red-03 capitalize bg red-ff pd-10-16">
                                            <img src="/img/drag_guide.png"/>&nbsp;
                                            Glass Wall
                                        </div> */}

                                        <div id="canvas-settings" style={{display: (floorPlan && floorPlan.length > 0 && Utilities.validateFileUploads(floorPlan) == "done") > 0 && elementArray.length > 0 ? 'block' : 'none'}}>
                                            <canvas height="500" width="949" style={{objectFit:"contain"}} id="floorplan-canvas"></canvas>
                                        </div>
                                        <canvas id="pdf-canvas" style={{display: "none"}}></canvas>


                                    </Card>
                                </Col>
                            </>}

                            <Col span={24} className="justify-in-start manrope f-14 black-55 w-900" style={{marginBottom: 10}}>
                                <span className="manrope f-14 red-d9">*</span>&nbsp;Image For Outside&nbsp;
                                <Tooltip title={<span className="manrope f-12 white">You can either select one of the provided outside views or upload your own.</span>}><InfoCircleOutlined /></Tooltip>
                            </Col>

                            <Col span={24} className="justify-in-start" style={{alignItems:"flex-start",flexWrap: outsidePhotoList && outsidePhotoList.length > 0 ? 'wrap' : 'nowrap'}}>
                                <Tooltip title={<span className="manrope f-12 white">Plain Sunlight (Default)</span>}>
                                    <Card style={{marginRight: 10,cursor:"pointer" ,border: selectedOutsideIndex == 0 ? "1px solid black" : "1px solid #737373"}} bordered onClick={() => selectOutsideView(0)}>
                                        <img className="lib-mat cover" src={ENVIRONMENT.STATIC_FILES_BASE_URL + 'outside-plain.JPG'}/>
                                        {selectedOutsideIndex == 0 ?
                                        <img className="success-img" src="/img/success.png"/> : ''}
                                    </Card>
                                </Tooltip>
                                <Tooltip title={<span className="manrope f-12 white">Countryside</span>}>
                                    <Card style={{marginRight: 10, cursor:"pointer", border: selectedOutsideIndex == 1 ? "1px solid black" : "1px solid #737373"}} bordered onClick={() => selectOutsideView(1)}>
                                        <img className="lib-mat cover" src={ENVIRONMENT.STATIC_FILES_BASE_URL + 'outside3.JPG'}/>
                                        {selectedOutsideIndex == 1 ?
                                        <img className="success-img" src="/img/success.png"/> : ''}
                                    </Card>
                                </Tooltip>
                                <Tooltip title={<span className="manrope f-12 white">Garden</span>}>
                                    <Card style={{marginRight: 10, cursor:"pointer", border: selectedOutsideIndex == 2 ? "1px solid black" : "1px solid #737373"}} bordered onClick={() => selectOutsideView(2)}>
                                        <img className="lib-mat cover" src={ENVIRONMENT.STATIC_FILES_BASE_URL + 'outside4.JPG'}/>
                                        {selectedOutsideIndex == 2 ?
                                        <img className="success-img" src="/img/success.png"/> : ''}
                                    </Card>
                                </Tooltip>
                                <Form.Item
                                colon={false}
                                label={''}
                                className="picture-card-custom"
                                name="outside_view_custom"
                                rules={[{ required: false, message: 'You must upload an image for outside view.' }]}>
                                    <Upload
                                        className={"upload-picture-card"}
                                        accept=".zip,.jpg,.jpeg,.png,.tiff"
                                        multiple={true}
                                        onChange = {handleChangeOutsideView}
                                        fileList = {outsidePhotoList}
                                        progress= {progress_bar}
                                        {...(Constants.getUploadProps())}
                                        onRemove={file => {
                                            const index = outsidePhotoList.indexOf(file);
                                            const newFileList = outsidePhotoList.slice();
                                            newFileList.splice(index, 1);
                                            setOutsidePhotoList(newFileList);
                                            if (newFileList.length == 0) {
                                                selectOutsideView(0);
                                            }
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error"){
                                                    setHasError(false)
                                                }
                                                else {
                                                    setHasError(true)
                                                }
                                            })
                                            return true;
                                        }}
                                        listType={"picture-card"}>
                                            {Utilities.validateFileUploads(outsidePhotoList) == "done" || Utilities.validateFileUploads(outsidePhotoList) == "uploading" ? 
                                            <span>
                                                <p className="manrope f-12 grey-99">Drop or <span style={{color: "#276DD7"}}>Browse</span> to add more.</p>
                                            </span> : 
                                            Utilities.validateFileUploads(outsidePhotoList) == "error" ? 
                                            <span>
                                                <div className="justify-in-center">
                                                    <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                    <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                </div>                        
                                            </span> :
                                            (Utilities.validateFileUploads(outsidePhotoList) == "not_started" ? 
                                                <span>
                                                    <p className="manrope f-12 grey-77">Drop or <span style={{color: "#276DD7"}}>Browse</span> more images.</p>
                                                </span>: '')}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            </Col>
                        </Panel>
                    </Collapse>
                </Col>

                <Col span={24}>
                    <Collapse style={{marginTop: 22}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                        <Panel  header={<span className="manrope f-14 grey-77 w-700">Add Raw Files or Videos of your Space Model</span>} key="1">
                            <Row  className="justify-space-between" style={{marginBottom: 32, marginTop: 20}}>
                                <Col span={12}  style={{padding:"0 6px"}}>
                                    <Form.Item
                                    colon={false}
                                    label={''}
                                    name="video"
                                    rules={[{ required: false, message: 'Please upload videos' }]}>
                                        <Upload
                                            className={"upload-lg-btn ant-upload-picture-card-wrapper upload-box-references"}
                                            multiple={true}
                                            onChange = {handleChangeVideoFiles}
                                            fileList={spaceVideosList}
                                            progress= {progress_bar}
                                            {...(Constants.getUploadProps())}
                                            onRemove={file => {
                                                const index = spaceVideosList.indexOf(file);
                                                const newFileList = spaceVideosList.slice();
                                                newFileList.splice(index, 1);
                                                setSpaceVideosList(newFileList);
                                                newFileList.forEach((file) => {
                                                    if (file.status !== "error"){
                                                        setHasError(false)
                                                    }
                                                    else {
                                                        setHasError(true)
                                                    }
                                                })
                                                setErrorMessage('');
                                                return true;
                                            }}
                                            listType={"text"}>
                                                {Utilities.validateFileUploads(spaceVideosList) == "uploading"  ? 
                                                    <div className="justify-in-center">
                                                        <div className="manrope f-12 grey-99 justify-in-center">Uploading...</div>
                                                    </div> : 
                                                    Utilities.validateFileUploads(spaceVideosList) == "done" || Utilities.validateFileUploads(spaceVideosList) == "not_started" ? 
                                                    <span>
                                                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                        <p className="manrope f-12 grey-77">Drop 360 video of your space or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                                                        <p className="manrope f-12 grey-77">
                                                        Supported formats: MP4, AVI
                                                        </p>
                                                    </span>:
                                                Utilities.validateFileUploads(spaceVideosList) == "error" ? 
                                                <span>
                                                    <div className="justify-in-center">
                                                        <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                        <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                    </div>                        
                                                </span> :
                                                ''}
                                        </Upload>
                                    </Form.Item>
                                </Col>

                                <Col span={12}  style={{padding:"0 6px"}}>
                                    <Form.Item
                                    colon={false}
                                    label={''}
                                    name="raw_files"
                                    rules={[{ required: false, message: 'Please upload raw files.' }]}>
                                        <Upload
                                            className={"upload-lg-btn ant-upload-picture-card-wrapper upload-box-references"}
                                            multiple={true}
                                            onChange = {handleChangeRawFiles}
                                            fileList={rawFileList}
                                            progress= {progress_bar}
                                            {...(Constants.getUploadProps())}

                                            onRemove={file => {
                                                const index = rawFileList.indexOf(file);
                                                const newFileList = rawFileList.slice();
                                                newFileList.splice(index, 1);
                                                setRawFileList(newFileList);
                                                setErrorMessage('');
                                                return true;
                                            }}
                                        
                                            listType={"text"}
                                            >
                                                {Utilities.validateFileUploads(rawFileList) == "uploading"  ? 
                                                    <div className="justify-in-center">
                                                        <div className="manrope f-12 grey-99 justify-in-center">Uploading...</div>
                                                    </div> : 
                                                    Utilities.validateFileUploads(rawFileList) == "done" || Utilities.validateFileUploads(rawFileList) == "not_started" ? 
                                                    <span>
                                                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                        <p className="manrope f-12 grey-77">Drop raw space files here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                                                        <p className="manrope f-12 grey-77">
                                                        Supported formats: .zip, png, jpg, pdf etc
                                                        </p>
                                                    </span>:
                                                Utilities.validateFileUploads(rawFileList) == "error" ? 
                                                <span>
                                                    <div className="justify-in-center">
                                                        <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                        <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                    </div>                        
                                                </span> :
                                                ''}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row> 

                        </Panel>
                    </Collapse>
                </Col></>}

                <Col span={24}>
                    <Collapse style={{marginTop: 32}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                        <Panel  header={<span className="manrope f-14 grey-77 w-700">Space Model Licensing and Copyrights</span>} key="1">
                            <LicenseFields
                            changePrice={changePrice}
                            creative_commons_text={FileConstants.creative_commons_text}
                            creativeCommon={creativeCommon}
                            setCreativeCommon={setCreativeCommon}
                            forSale={forSale}
                            changeForSale={changeForSale}
                            publicLicenseChecked={publicLicenseChecked}
                            forCreativeCommon={forCreativeCommon}
                            changeForCreativeCommon={changeForCreativeCommon}
                            formValidationFailed={formValidationFailed}
                            publicLicensePanel={publicLicensePanel}
                            changePublicLicenseChecked={changePublicLicenseChecked}
                            privateLicenseChecked={privateLicenseChecked}
                            privateLicensePanel={privateLicensePanel}
                            changePrivateLicenseChecked={changePrivateLicenseChecked}
                            />
                        </Panel>
                    </Collapse>
                </Col>
                                                                    
                   
                {errorMessage == "" ? '' :
                    <Col span={24} style={{marginTop: 10,marginBottom: 20}}>
                        <div className="justify-space-between err-bg manrope f-12 red-error">
                            <img src="/img/error-small.png" style={{marginRight: 8}}/>
                            <span>{errorMessage}</span>
                        </div>
                    </Col>}
                {priceError == "" ? '' :
                <Col span={24} style={{marginTop: 10,marginBottom: 20}}>
                    <div className="justify-space-between err-bg manrope f-12 red-error">
                        <img src="/img/error-small.png" style={{marginRight: 8}}/>
                        <span>{priceError}</span>
                    </div>
                </Col>}
                        
                {uploadError == "" ? '' :
                <Col span={24} style={{marginTop: 10,marginBottom: 20}}>
                    <div className="justify-space-between err-bg manrope f-12 red-error">
                        <img src="/img/error-small.png" style={{marginRight: 8}}/>
                        <span>{uploadError}</span>
                    </div>
                </Col>}
                </Row>
                <Row >
                <Col span={24} className="justify-in-end">
                    <div className='justify-in-end'>
                    {!props.automated_variation && <Button style={{marginBottom: 10,marginRight: 10}} className="modal-okay-gray square font-14" disabled={submittingSaveRequest || submittingCreateRequest} onClick={handleSave}>
                        Save Progress&nbsp;{submittingSaveRequest == true ? <LoadingOutlined type="sync" spin />: ''}
                    </Button>}
                
                    <Form.Item>
                        <Button className="modal-okay square font-14" type="primary" htmlType="submit" disabled={submittingCreateRequest || submittingSaveRequest}>
                            Submit&nbsp;{submittingCreateRequest == true ? <LoadingOutlined type="sync" spin />: ''}
                        </Button>
                    </Form.Item>
                    </div>
                </Col>
                </Row>
                </div>
            </Form>
            <PaymentConfirmationModal
            name={spaceName}
            visible={paymentConfirmationModal}
            amount={"$"+ requestPrice}
            footer={[
            <span style={{display: 'flex', justifyContent:'center'}}>
                <Button disabled={submittingCreateRequest} className="modal-okay-gray font-14 square" onClick={cancelPaymentConfirmation}>
                Cancel
                </Button>
                <Button disabled={submittingCreateRequest || cardSaveFlowTriggered} className="modal-okay font-14 square" onClick={initiateConfirmation}>
                {cardStatus == 1 && requestStatus == "payment_required" ? "Confirm & Process Payment" :
                cardStatus == 0 && requestStatus == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm"} {submittingCreateRequest == true ? <LoadingOutlined type="sync" spin />: ''}
                </Button>
            </span>
            ]}
            text={"Please pay the amount below to proceed. It can take 24 to 72+ hours for completion of the model based on complexity"}
            onCancel={cancelPaymentConfirmation}/>
             <CardSaveDrawer
             setCardSaveFailure={props.setCardSaveFailure}
             saveRequestIntermedietely={saveRequestIntermedietely}
             visible={props.cardSaveDrawer} 
             onClose={() => props.setCardSaveDrawer(false)} 
            />

            <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />

            <PaymentFailureModal 
            paymentFailureMessage={paymentFailureMessage}
            setPaymentFailureModal={setPaymentFailureModal}
            updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
            paymentFailureModal={paymentFailureModal}
            />
            
    </div>
}



const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    createRoom: (room) => {
        dispatch(createRoom(room));
    },
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
    getRoom: (room) => {
        dispatch(fetchRoom(room));
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(SpaceVariationRequest)
)
