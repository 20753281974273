import React, { Component } from "react";
import * as THREE from "three";
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';


const style = {
//   height: 420 // we can control scene size by setting container dimensions
  width: '70vw'
};

class ModelOBJViewer extends Component {
  componentDidMount() {
      this.init();
	  setTimeout(() => {document.styleSheets[0].insertRule('canvas { outline:none; border:none; }', 0)}, 3000)
}

  componentWillUnmount() {
  
  }

  componentDidUpdate() {
	while(this.scene.children.length > 0){
		console.log(this.scene.children) 
		this.scene.remove(this.scene.children[0]); 
	}
	this.setupSceneLights();
	console.log('component update called');
	// this.setupScene();
	this.loadAsset(this.props.model_url);
  }

  init = () => {
    // this.sceneContainer 	= document.getElementById('item-viewer');
    this.sceneContainer 	= this.el;
	this.loadingManager	    = this.buildLoadingManager();
	this.objLoader 			= this.buildOBJLoader();
	this.mtlLoader 		    = this.buildMTLLoader();
	this.raycaster 		= new THREE.Raycaster();
	this.updateScreenProps();
	this.setupScene();
	this.addEventListeners( this.sceneRenderer );
	let url = null;
	if (this.props.model_url != undefined) {
		url = this.props.model_url;
	}
	else {
		url = new URLSearchParams(window.location.search).get("model_url")
	}
	this.assetURL = url;
	// let url = "https://all3d-reconstruction.s3.amazonaws.com/temp_results/1592848713_AppTest3_971_0.ply";
	console.log("model url: ", url);
	this.loadAsset( url)
	this.animate();
}

buildScene = () => {
	const scene 		= new THREE.Scene();
	scene.background 	= new THREE.Color("#ffffff");
	return scene;
}

buildSceneRenderer = ( width, height ) => {
	const renderer 				= new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
	// renderer.gammaOutput 		= true;
	// renderer.gammaFactor 		= 2.0;
	// renderer.shadowMap.enabled	= true;
	// renderer.shadowMap.type 	= THREE.PCFSoftShadowMap;
	renderer.outputEncoding = THREE.LinearEncoding;
	renderer.setClearColor( 0x000000 );
	renderer.setPixelRatio( window.devicePixelRatio );
	renderer.setSize( width, height );
	return renderer;
}

buildLoadingManager = () => {
	const manager = new THREE.LoadingManager();
	manager.addHandler( /\.dds$/i, new DDSLoader() );
	manager.onLoad = () => {
		console.log( 'Loading complete!');
		if (this.props.loaderId != undefined) {
			document.getElementById(this.props.loaderId).style.display = 'None';
	}
};
	return manager
}

buildOBJLoader = () => {
	const objLoader = new OBJLoader(this.loadingManager);
    return objLoader;
}

buildMTLLoader = () => {
	const mtlLoader = new MTLLoader(this.loadingManager);
    return mtlLoader;
}

buildOrbitControls = ( camera, renderer ) => {
	const controls = new OrbitControls( camera, renderer.domElement );
	return controls;
}

buildOrbitCamera = ( width, height ) => {
	const orbitCamera = new THREE.PerspectiveCamera( 45, width / height, 0.25, 20 );
	orbitCamera.position.set( 0, 0, 0 );
	return orbitCamera;
}

addEventListeners = ( renderer ) => {
	window.addEventListener( 'resize', this.onWindowResize, false );
}

loadEnvironment = ( url ) => {
	const format 	= '.jpg';
	const env 		= new THREE.CubeTextureLoader().load( [
		url + 'px' + format, url + 'nx' + format,
		url + 'py' + format, url + 'ny' + format,
		url + 'pz' + format, url + 'nz' + format
	] );
	return env;
}

setupSceneLights = () => {
	var lightGroup 		= new THREE.Group()
	
	const color = 0xFFFFFF;

	var keyLight 		= new THREE.DirectionalLight( color, 1 );
    keyLight.position.set( -7, 5, 5 );
    keyLight.name 	= 'key_light';

    var fillLight = new THREE.DirectionalLight( color, 0.6 );
    fillLight.position.set( 5, 3, 5 );
    fillLight.name 	= 'fill_light';

    var backLight = new THREE.DirectionalLight( color, 0.6 );
    backLight.position.set( 0, 3, -5 );
    backLight.name 	= 'back_light';

	var ambientLight = new THREE.AmbientLight(color, 0.2);
	ambientLight.position.set( 0, 0, 0);


    lightGroup.add( keyLight );
    lightGroup.add( fillLight );
    lightGroup.add( backLight );
	lightGroup.add( ambientLight );
	
    this.scene.add( lightGroup )
}

setupSceneCameras = () => {
	this.orbitCamera = this.buildOrbitCamera( this.sceneWidth, this.sceneHeight );
	this.scene.add( this.orbitCamera );
}

setupControls = () => {
	this.orbitControls 	= this.buildOrbitControls( this.orbitCamera, this.sceneRenderer );
}

setupScene = () => {
	this.scene 			= this.buildScene();
	this.sceneRenderer 	= this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
	this.envMap			= this.loadEnvironment( '/models/maps/' );

	this.setupSceneLights();
	this.setupSceneCameras();
	this.setupControls();

	this.sceneContainer.appendChild( this.sceneRenderer.domElement );
}

onProgress = function ( xhr ) {
	if ( xhr.lengthComputable ) {
		const percentComplete = xhr.loaded / xhr.total * 100;
		console.log( Math.round( percentComplete, 2 ) + '% downloaded' );
	}
};

onError =  ( error ) => {
	let path = this.assetURL.split("/");
	let objFilename = path.at(-2) + ".obj"
	console.log("objfilename", objFilename);
	this.objLoader
		.setPath( "https://all3d-reconstruction.s3.amazonaws.com/temp_results/" )
		.load( objFilename, ( object ) => {

			var box = new THREE.Box3().setFromObject( object );

			var size = box.getSize( new THREE.Vector3() ).length();

			var center = box.getCenter( new THREE.Vector3() );

			this.orbitControls.reset();

			object.position.x 			+= ( object.position.x - center.x );
			object.position.y 			+= ( object.position.y - center.y );
			object.position.z 			+= ( object.position.z - center.z );
			this.orbitControls.minDistance 	= size/2;
			this.orbitControls.maxDistance 	= size * 10;
			this.orbitCamera.near 			= size / 100;
			this.orbitCamera.far 			= size * 100;

			this.orbitCamera.updateProjectionMatrix();
			this.orbitCamera.position.copy( center );

			this.orbitCamera.position.x -= size;
			this.orbitCamera.position.y += size / 3.0;
			this.orbitCamera.position.z += size;

			this.orbitCamera.lookAt( center );
			this.orbitControls.saveState();

			this.scene.add( object );
			this.onWindowResize();
		})
};

loadAsset = ( assetURL ) => {
	console.log("asset url", assetURL);

	let materialFilename = 'atlas1.mtl';
	let objFilename = 'atlas1.obj';
	this.mtlLoader.setPath( assetURL ).load( materialFilename, ( materials ) => {
		materials.preload();
		this.objLoader
		.setMaterials( materials )
		.setPath( assetURL )
		.load( objFilename, ( object ) => {

			var box = new THREE.Box3().setFromObject( object );

			var size = box.getSize( new THREE.Vector3() ).length();

			var center = box.getCenter( new THREE.Vector3() );

			this.orbitControls.reset();

			object.position.x 			+= ( object.position.x - center.x );
			object.position.y 			+= ( object.position.y - center.y );
			object.position.z 			+= ( object.position.z - center.z );
			this.orbitControls.minDistance 	= size/2;
			this.orbitControls.maxDistance 	= size * 10;
			this.orbitCamera.near 			= size / 100;
			this.orbitCamera.far 			= size * 100;

			this.orbitCamera.updateProjectionMatrix();
			this.orbitCamera.position.copy( center );

			this.orbitCamera.position.x -= size;
			this.orbitCamera.position.y += size / 3.0;
			this.orbitCamera.position.z += size;

			this.orbitCamera.lookAt( center );
			this.orbitControls.saveState();
			// object.traverse( ( child ) => {
			// 	if( child.isMesh ) {
			// 		console.log("material", child.material);
			// 		console.log("map",child.material.map);
			// 		console.log("map",assetURL);
			// 		let map = child.material.map.clone();
			// 		child.material = new THREE.MeshBasicMaterial();
			// 		child.material.map = map;
			// 		child.material.map.needsUpdate = true;
			// 	}
			// });
			console.log(object);
			this.scene.add( object );
			this.onWindowResize();
		}, this.onProgress);
	}, this.onProgress, this.onError);
}

updateScreenProps = () => {
	this.sceneWidth 		= this.sceneContainer.clientWidth;
	this.sceneHeight 	= this.sceneWidth*0.5625;;
	this.aspect 			= this.sceneWidth / this.sceneHeight;
}

onWindowResize = () => {
	this.updateScreenProps();

	this.sceneRenderer.setSize( this.sceneWidth, this.sceneHeight );

	this.orbitCamera.aspect = this.aspect;
	this.orbitCamera.updateProjectionMatrix();
}

update = () => {

}

render3 = () => {
	this.orbitControls.update();
	this.sceneRenderer.clear();
	this.sceneRenderer.render( this.scene, this.orbitCamera );
}

animate = () => {
	window.requestAnimationFrame( this.animate );
	this.render3();
	this.update();
}

  render() {
    return <div style={{width: (this.props.width != undefined)?this.props.width:'70vw'}} ref={ref => (this.el = ref)} />;
  }
}

class ProductOBJViewer extends React.Component {
  state = { isMounted: true };

  render() {
    const { isMounted = true } = this.state;
    return (
     <ModelOBJViewer/>
    );
  }
}

export default ModelOBJViewer;