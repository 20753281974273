import React, { useState } from "react";
import { Row, Col, message } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import "./TestArtistDetails.scss";

function TestArtistDetails({
  testID,
  username,
  skype,
  billingType,
  country,
  yearsOfExperience,
  proposedHourlyRate,
  proposedWorkableHoursPerWeek,
  portfolioFiles,
  portfolioUrl,
  readingEnglishProficiency,
  writingEnglishProficiency,
  listeningEnglishProficiency,
}) {
  const getProficiency = (prof) => {
    if (prof > 5 && prof <= 10) {
      return "Good Proficiency";
    } else if (prof > 0 && prof <= 5) {
      return "Average Proficiency";
    } else {
      return "No Proficiency";
    }
  };
  return (
    <>
      <div className="details-container">
        <Row gutter={20}>
          <Col span={12} className="gutter-row">
            <div className="mb-20">
              <div className="br-top-4 manrope w-500 f-16 title">
                Personal Details
              </div>
              <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                <div className="values-container">
                  Username
                  <div className="mt-10 text-grey">{username}</div>
                </div>
                {skype ? (
                  <div className="values-container">
                    Skype
                    <div className="mt-10 text-grey">{skype}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="values-container">
                  Country
                  <div className="mt-10 text-grey">{country}</div>
                </div>
                <div className="values-container">
                  Years of Experience
                  <div className="mt-10 text-grey">{yearsOfExperience}</div>
                </div>
              </div>
            </div>
            <div className="mb-20">
              <div className="br-top-4 manrope w-500 f-16 title">
                Hourly Rate & Availability
              </div>
              <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                <div className="values-container">
                  Proposed Workable Hours
                  <div className="mt-10 text-grey">
                    {proposedWorkableHoursPerWeek} hrs (per week)
                  </div>
                </div>
                <div className="values-container">
                  Billing Type
                  {billingType === "hourly" ? (
                    <div className="mt-10 text-grey">Hourly</div>
                  ) : (
                    <div className="mt-10 text-grey">Per Model</div>
                  )}
                </div>
                <div className="values-container">
                  Proposed Rate
                  <div className="mt-10 text-grey">${proposedHourlyRate}</div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12} className="gutter-row">
            {portfolioFiles.length > 0 || portfolioUrl ? (
              <div className="mb-20">
                <div className="br-top-4 manrope w-500 f-16 title">
                  Portfolio
                </div>
                <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                  {portfolioFiles.length > 0
                    ? portfolioFiles.map((file, index) => (
                        <a
                          key={index}
                          className="portfolio-container"
                          href={
                            ENVIRONMENT.BASE_URL + file.uid + "/" + file.name
                          }
                          download={file.name}
                          target="_blank"
                        >
                          <div className="portfolio-file-container">
                            <div className="mr-10 manrope w-500 f-14">
                              <PaperClipOutlined />
                            </div>
                            <div className="text-grey">{file.name}</div>
                          </div>
                        </a>
                      ))
                    : ""}

                  {portfolioUrl ? (
                    <div className="values-container">
                      Link
                      <div className="mt-10 text-grey">
                        <a href={portfolioUrl} target="_blank">
                          {portfolioUrl}
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="mb-20">
              <div className="br-top-4 manrope w-500 f-16 title">
                Language Proficiency
              </div>
              <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                <div className="values-container">
                  Written
                  <div className="mt-10 text-grey">
                    {getProficiency(writingEnglishProficiency)}
                  </div>
                </div>
                <div className="values-container">
                  Reading
                  <div className="mt-10 text-grey">
                    {getProficiency(readingEnglishProficiency)}
                  </div>
                </div>
                <div className="values-container">
                  Listening
                  <div className="mt-10 text-grey">
                    {getProficiency(listeningEnglishProficiency)}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TestArtistDetails;
