import React, { useEffect, useState, useRef } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  LoadingOutlined,
  MessageOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  UserAddOutlined,
  CloseOutlined,
  CheckOutlined,
  LockOutlined,
  ExpandAltOutlined,
  EditOutlined,
  DownloadOutlined,
  PlusOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Select,
  Form,
  Button,
  Tooltip,
  Breadcrumb,
  Tag,
  Modal,
  Divider,
  message,
  Radio, 
  Image,
  Spin,
  Tabs,
  Card,
  Empty,
  Dropdown,
  Menu,
  Checkbox
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import EntitiesList from "./EntitiesList";
import LifestylesList from "./LifestylesList";
import { withRouter } from "react-router-dom";
import { Header } from "antd/lib/layout/layout";
import Filter from "./Filter";
import { getRelevanceBasedOrderedSearchResult } from '../../Utilities';
import ProjectAccess from "./ProjectAccessModal";
import ProjectConstants from "./Constants";
import ProjectComments from "./ProjectComments";
import ProductAssetModals from "../ProductComponents/ProductAssetModals";
import { getProductRender } from "../../../../redux/actions";
import SuccessModal from "../../SuccessModal/SuccessModal";
import SiloList from "./SiloList";
import _ from "lodash"
const { Option } = Select;
const { TabPane } = Tabs

let CUSTOMER_USERNAME = localStorage.getItem("username")
let COMPANY_ID = localStorage.getItem("company_id");
let MANAGED_COMPANY_ID = localStorage.getItem("managed_company_id");
let SHARED_ENTITIES = localStorage.getItem("shared_entities");
let IS_MSPROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

// Sort the lifestyles by their resolution in following order
const resolutionSortingOrder = ["4k", "2k", "1k", "custom"];

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "black",
    }}
    spin
  />
);

const RestrictedContainer = () => {
  return (
    <div className="blur-background">
      <div className="load-container">
        <LockOutlined className='on-start-load loading-icon' size="large" />
        <h3 className="load-message large">Oops! You don't have access!</h3>
        <h3 className="load-message">Please request the owner of this collaboration to grant you access.</h3>
      </div>
    </div>)
}

const CustomerProject = (props) => {
  const {
    match: { params },
  } = props;
  const projectId = params.id;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedModelType, setSelectedModelType] = useState('all');
  const [selectedReviewStatus, setSelectedReviewStatus] = useState([]);
  const [projectStatus, setProjectStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [isAccessModal, setIsAccessModal] = useState(false);
  const [accessLevel, setAccesLevel] = useState("");
  const [accessActions, setAccessActions] = useState([]);
  const [isCommentsDrawer, setIsCommentsDrawer] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const [tagUserList, setTagUserList] = useState([]);
  const [customGroups, setCustomGroups] = useState([]);
  const [isEditName, setIsEditName] = useState(false);
  const [editNameLoader, setEditNameLoader] = useState(false)
  const [projectMetadata, setProjectMetadata] = useState({})
  const [displayCreateSceneButton, setDisplayCreateSceneButton] = useState(false)
  const [commentCount, setCommentCount] = useState(0)
  const [lifestyleRenders, setLifestyleRenders] = useState([])
  const [selectedLifestyleRender, setSelectedLifestyleRender] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResolutionFilter, setSelectedResolutionFilter] = useState("all")
  const [silos, setSilos] = useState([]);
  const [productBasicInfo, setProductBasicInfo] = useState(null);
  const [selectedAssetModal, setSelectedAssetModal] = useState(null);
  const [currentProductId, setCurrentProductId] = useState(0);
  const [currentPage, setCurrentPage] = useState(props.match.path)

  const [usersAndCustomGroupsList, setUsersAndCustomGroupsList] = useState([])
  const [loadingLifestyles, setLoadingLifestyle] = useState(false)
  const [preparingArchive, setPreparingArchive] = useState(false);
  const [loadingSilos, setLoadingSilos] = useState(false);
  const [currentScene, setCurrentScene] = useState(-1);
  const [currentSceneName, setCurrentSceneName] = useState('');
  const [replicateAssets, setReplicateAssets] = useState(false)
  const [duplicateSceneWaitModal, setDuplicateSceneWaitModal] = useState(false)
  const [duplicateSceneModal, setDuplicateSceneModal] = useState(false);
  const [duplicateSceneLoader, setDuplicateSceneLoader] = useState(false);
  const [userScenes, setUserScenes] = useState([])
  const [whiteBalancedImages, setWhiteBalancedImages] = useState({});
  const [tabActiveKey, setTabActiveKey] = useState("1");

  const [duplicate_form] = Form.useForm()

  const editNameRef = useRef(null)

  const tab=new URL(window.location.href).searchParams.get("tab")

  const tabNameMapping={
    'active': 'Active Projects',
    'requested': 'Requested Projects',
    'approved': 'Approved Projects',
  }

  const modelTypeFilters = [
    {
      label: "All",
      value: "all"
    },
    {
      label: "Products",
      value: "product",
    },
    {
      label: "Scenes",
      value: "scene",
    },
    {
      label: "Spaces",
      value: "room",
    },
    {
      label: "Props",
      value: "props",
    }
  ];

  const resolutionFilter = [
    {
      label: "All",
      value: "all"
    },
    {
      label: "1K",
      value: "1k",
    },
    {
      label: "2K",
      value: "2k",
    },
    {
      label: "4K",
      value: "4k",
    },
    {
      label: "Custom",
      value: "custom",
    }
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab2');
    if (tab) {
      setTabActiveKey(tab);
    }
  }, []);

  const handleModelTypeChange = (e) => {
    setSelectedModelType(e.target.value);
  };

  const getProjectEntitiesPayload = () => {
    let payload = {
      order_by: "id desc",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "company_id",
        "thumbnail",
        "status",
        "project_id",
        "entity_type",
        "product_model_type",
        "last_modified",
        "category",
        "platform",
        "collection_id",
        "scene_created_from"
      ],
      filter_string: `(project_id__exact='${projectId}')`,
    };

    return payload;
  };


  const fetchProjectEntities = async () => {
    let payload = getProjectEntitiesPayload();
    await axios
      .post(ENVIRONMENT.GET_PROJECTS_ENTITY_BATCH, payload)
      .then((res) => {
        console.log(res.data)
        const data = transformStatusData(res.data);
        setData(data);
        isSceneCreationAllowed(data)
        setFilteredData(data);
      });
  };

  const classifyImageResolution = (height, width) => {
    const maxDimension = Math.max(height, width);

    if (Math.abs(maxDimension - 1024) < 100) {
        return '1k';
    } else if (Math.abs(maxDimension - 2048) < 100) {
        return '2k';
    } else if (Math.abs(maxDimension - 4096) < 100) {
        return '4k';
    } else {
        return 'custom';
    }
};

  // Create a mapping from resolution to its position in custom order
  const orderMap = resolutionSortingOrder.reduce((acc, resolution, index) => {
    acc[resolution] = index;
    return acc;
  }, {});

  const populateSceneRendersData = (data) => {
    let white_balance_renders_switch = {};
    let renders = []
    for(let item of data){
      if(item.final_renders && item.final_renders['final_renders']){
        for(let render of item.final_renders['final_renders']){
          if(render['complaint_status'] !== 'resolved'){
            let render_link = ENVIRONMENT.getBaseURL(item.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + item.id + '/' + render.filename.replace('.tiff', '.jpg')
            let unique_filename = item.id + '_' + render.filename.split('.')[0]
            let resolution = classifyImageResolution(render.image_height, render.image_width)
            renders.push({
              ...render,
              'request_link': render_link,
              'scene_id': item.id,
              'unique_filename': unique_filename,
              'resolution': resolution,
              'platform': item.platform
            })
            if(render['white_balanced_image'] != undefined ) {
              white_balance_renders_switch[unique_filename] = false;
            }

          }
        }
      }
    }
    if (white_balance_renders_switch !== undefined && Object.keys(white_balance_renders_switch).length > 0) {
      setWhiteBalancedImages(white_balance_renders_switch);
    }
    renders = renders.sort((a,b) => { 
      let dateComparison = new Date(b.time_updated) - new Date(a.time_updated)
      if (dateComparison === 0) { // If time_updated is the same, compare by filename in descending order
          return b.filename.localeCompare(a.filename); // Descending order for filename
      }
      return dateComparison;
    });
    renders = renders.filter((ele) => ele.status != 'hidden' && !ele.template_render)
    const uniqueImages = removeDuplicates(renders);
    setLifestyleRenders(uniqueImages);
    console.log("renders", uniqueImages)
    setLoadingLifestyle(false)
  }

  const populateSceneImages = (data) => {
    let white_balance_renders_switch = {};
    let renders = _.cloneDeep(data);
    if (white_balance_renders_switch !== undefined && Object.keys(white_balance_renders_switch).length > 0) {
      setWhiteBalancedImages(white_balance_renders_switch);
    }
    renders = renders.sort((a, b) => {
      let dateComparison = new Date(b.time_updated) - new Date(a.time_updated)
      if (dateComparison === 0) { // If time_updated is the same, compare by filename in descending order
        return b.filename.localeCompare(a.filename); // Descending order for filename
      }
      return dateComparison;
    });
    renders = renders.filter((ele) => ele.status != 'hidden' && !ele.template_render)
    const uniqueImages = removeDuplicates(renders);
    setLifestyleRenders(uniqueImages);
    console.log("renders", uniqueImages)
    setLoadingLifestyle(false)
  }


  const removeDuplicates = (imageArray) => {
    const uniqueImages = [];
    const seen = new Set();
  
    imageArray.forEach((image) => {
      const uniqueKey = image.request_link;
      if (!seen.has(uniqueKey)) {
        seen.add(uniqueKey);
        uniqueImages.push(image);
      }
    });
  
    return uniqueImages;
  };
  

  const populateSilosData = (data) => {
    let silos = []
    if(data?.length > 0)
    for(let item of data){
        if(item.image_status == 'completed' && ((item.is_hidden !== true))){
        let silo_base_uri = '/' + item.username;
        let unique_attributer = 'username';
        if(item.company_id){
            silo_base_uri = '/company/' + item.company_id
            // unique_attributer = 'company';
            unique_attributer = item.company_id
        }
        else if (item.username) {
            unique_attributer = item.username
        }
        let imageUrl = ENVIRONMENT.getBaseURL(item['platform']) + ENVIRONMENT.QA_IMG_URI + item['product_id'] + silo_base_uri + '/' + item.camera_name + '.' + item.image_data.img_format.replace('tiff', 'jpg');
        let unique_filename = item.product_id + '_' + item.camera_name + '_' + unique_attributer;
        let resolution = classifyImageResolution(item.image_data.height, item.image_data.width)
        silos.push({
            ...item,
            'request_link': imageUrl,
            'unique_filename': unique_filename,
            'resolution': resolution,
            'filename': item.camera_name + '.' + item.image_data.img_format.replace('tiff', 'jpg')
        })
        }
    }
    silos = silos.sort((a,b) => { 
      let dateComparison = new Date(b.time_updated) - new Date(a.time_updated)
      return dateComparison;
    });
    const uniqueImages = removeDuplicates(silos);
    setSilos(uniqueImages);
    console.log("unuq images", uniqueImages)
    setLoadingSilos(false);
  }

  const isSceneCreationAllowed = (data) => {
    // this function sets the value of displayCreateSceneButton to true when at least one space and one product is approved

    let approved_or_in_review_items = data.filter(item => item.status == 'approved' || item.status == 'in_review')
    let allow_scene_creation = false
    if(approved_or_in_review_items.length > 0){
          allow_scene_creation = true
      }

    setDisplayCreateSceneButton(allow_scene_creation)
  }

  const fetchProjectDetails = async () => {
    let payload = {
      order_by: "id desc",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "company_id",
        "meta_data",
        "status",
      ],
      filter_string: `(id__exact='${projectId}')`,
    };

    await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
      setProjectName(res.data[0].name);
      setProjectStatus(res.data[0].status);
      setProjectMetadata(res.data[0].meta_data)
      setCommentCount(res.data[0].meta_data.comment_count ? res.data[0].meta_data.comment_count : 0)
    });
  };

  const transformStatusData = (data) => {
    const transformedData = data.map((item) => {
      let status = "";
      if (item.status == "5") {
        status = "approved";
      } else if ([2, 3, 6, 8, 11, 7].includes(item.status)) {
        status = "in_progress";
      } else if (item.status == "4") {
        status = "in_review";
      } else if (item.status == "-4" || item.status == "-5") {
        status = "rejected";
      }
      else if (item.status == "1" || item.status == "10") {
        status = "incomplete";
      }
      else if (item.status == "0" && item.entity_type == "scene"){
        status = "incomplete"
      }
      return {
        ...item,
        status: status,
      };
    });

    return getProjectDataWithKeywords(transformedData);
  };

  const fetchProjectProductSilos = () => {
    setLoadingSilos(true);
    let payload = {"project_ids": [projectId]};
    axios
      .post(ENVIRONMENT.GET_PROJECTS_SILOS, payload)
      .then((res) => {
        console.log("Res", res.data)
        populateSilosData(res.data);
      });
  };

  const getProjectLifestyles = () => {
    setLoadingLifestyle(true)
    if(projectId){
      let payload = {
        'project_id': projectId
      }
      axios.post(ENVIRONMENT.GET_PROJECTS_LIFESTYLES, payload)
      .then(res => {
        if(res && res.data){
          populateSceneRendersData(res.data)
        }
      })
    }

  }

  useEffect(() => {
    CUSTOMER_USERNAME = localStorage.getItem("username")
    COMPANY_ID = localStorage.getItem("company_id");
    MANAGED_COMPANY_ID = localStorage.getItem("managed_company_id");
    SHARED_ENTITIES = localStorage.getItem("shared_entities");
    IS_MSPROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
    const fetchPageData = async () => {
        
      setIsDataLoading(true);
      getSharedUsers();
      await getProjectLifestyles();
      await fetchProjectDetails();
      await fetchProjectEntities();
      await fetchProjectProductSilos();
      getSceneNames();
      setIsDataLoading(false);
    };
    fetchPageData();
  }, []);

  useEffect(() => {
    let filteredData = data;
    if (selectedModelType != 'all' && selectedModelType != 'renders') {
      if(selectedModelType != 'props'){
        filteredData = filteredData.filter((item) => {
          return selectedModelType === item.entity_type && item.product_model_type != 'props';
        });
      }
      else{
        filteredData = filteredData.filter((item) => {
          return selectedModelType === item.product_model_type;
        });
      }
    }
    if (selectedReviewStatus.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedReviewStatus.includes(item.status);
      });
    }
    if (searchValue.length > 0) {
      filteredData = getRelevanceBasedOrderedSearchResult(searchValue, filteredData)
    }
    setFilteredData(filteredData);
  }, [searchValue, selectedModelType, selectedReviewStatus]);


  useEffect(() => {
    combineUsersAndGroups()
  }, [customGroups, tagUserList])


  useEffect(() => {
    if (accessLevel) {
      setAccessActions(ProjectConstants.access_actions[accessLevel]);
    }
  }, [accessLevel]);


  const closeTags = (entity) => {
    const reviewFilters = selectedReviewStatus.filter((item) => {
      return entity != item;
    });
    setSelectedReviewStatus(reviewFilters);
  };

  const handleApproveProject = () => {
    setLoading(true);
    let payload = { action: "move_to_approved", project_id: projectId };
    axios.post(ENVIRONMENT.UPDATE_PROJECT, payload).then((res) => {
      message.success(`Project approved successfully`);
      setLoading(false);
      window.location.href = `/library-projects?origin=${currentPage}`;
    });
  };

  const getProjectDataWithKeywords = (projects) => {
    const result = projects.map((obj) => {
      let keywords = [];

      for (const keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, ' ').split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      for (const keyword of obj.category?.replace(/[^a-zA-Z0-9 ]/g, ' ')?.split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      return { ...obj, 'keywords': keywords };
    });
    return result;
  };

  const handleEditCancel = () => {
    setIsEditName(false)
  }

  const handleNameSubmit = () => {
    let project_name = editNameRef.current.input.value
    console.log('edit name = ', project_name)
    setEditNameLoader(true)
    let payload = {
      action: 'update_name',
      project_id: projectId,
      name: project_name
    }

    axios.post(ENVIRONMENT.UPDATE_PROJECT, payload)
    .then(res => {
      if(res){
        setIsEditName(false)
        setEditNameLoader(false)
        
        message.info(res.data.message)
        if(res.data.status == 200)
          setProjectName(project_name)
      }
    })

  }

  const combineUsersAndGroups = () => {
    let usersAndGroups = []
    if (tagUserList.filter(item => item.access_level && item.accessed_from != 'restricted').length > 0){
      usersAndGroups = [{
        id: 'all',
        display: 'all',
        access_level: 'viewer'}].concat(tagUserList.filter(item => item.access_level && item.accessed_from != 'restricted'))

    }
    
    customGroups.filter(item => item.access_level && item.access_level != 'restricted').map(item => {
        // if check to avoid duplicate groups
        if(!usersAndCustomGroupsList.map(item => item.display).includes(item.group_name)){
            usersAndGroups.push({
                id: item.group_name,
                display: item.group_name,
                access_level: item.access_level
            })
        }
    })
    setUsersAndCustomGroupsList(usersAndGroups)
}

  const getSharedUsers = () => {
    let payload = {
      'action': 'get',
      'project_id': params.id
    };
    let company_id = COMPANY_ID;
    if (IS_MSPROVIDER && MANAGED_COMPANY_ID) {
      company_id = MANAGED_COMPANY_ID;
    }
    if (company_id) {
      payload['company_id'] = company_id;
    }
    axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setProjectUsers(res.data.filter(item => !item.group_id));
          
          //  get company custom groups
          let payload = {
            'action': 'list_groups'
          }
          if(localStorage.getItem('company_id'))
            payload['company_id'] = localStorage.getItem('company_id')
          else
            payload['created_by'] = localStorage.getItem('username')

          axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload).then(res1 => {
              if (res1?.data) {
                  addAccessValueOfCustomGroups(res1.data, res.data)
              }
          })

          let shared_customers = res.data.filter(item => !item.group_id).map(item => {
            return {
              id: item.username,
              display: item.is_msprovider ? item.username + ' (ALL3D)' : item.username,
              email: item.email,
              access_level: item.access_level
            }
          });
          console.log('Shared Users', shared_customers)
          setTagUserList(shared_customers);
          let user_access = res.data.filter(item => item.username == localStorage.getItem('username')).map(item => item.access_level);

          if (user_access && user_access.length > 0) {
            setAccesLevel(user_access[0]);
          } else if (props && props.match && props.match.path.includes("admin-collaborate-tool")) {
            setAccesLevel('view');
          }
          else {
            setAccesLevel('restricted');
          }
        } else {
          setAccesLevel('restricted');
        }

      });
  };

  function addAccessValueOfCustomGroups(customGroups, users) {
    /* 
        customGroups: list of user's or company's groups
        users: list of users with whom the item is shared. If user is part of a group it will have group_id as well.
    */

    // Iterate list of users, if user is part of a group then set the group will have same access as of this user.
    let groupIdAccessMapping = {}
    users.map((user) => {
        if (user.group_id) {
            groupIdAccessMapping[user.group_id] = user.access_level
        }
    })

    // display only those groups that has users
    const updatedGroups = customGroups.filter(item => item.users.length > 0).map((group) => ({
        ...group,
        access_level: groupIdAccessMapping[group.group_id] || 'restricted'  // if group does not has access level set that means this item was never shared with that group thus restricted access
    }))

    setCustomGroups(updatedGroups)
}

const getCombinedRendersCount = (count_key) => {
  let count = 0;
  if(projectMetadata[count_key]){
    count = Object.values(projectMetadata[count_key]).reduce((a, b) => a+b, 0)
  }
  return count
}

  const getTagName = (entity) => {
    let tag = "";
    ProjectConstants.review_status_filters.map((category, index) => {
      if (category.value == entity) {
        tag = category.label;
      }});
    ProjectConstants.model_type_filters.map((category, index) => {
      if (category.value == entity) {
        tag = category.label;
      }})
    return tag;
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedLifestyleRender(null);
  };

   const selectAssetAndSetBasicInfo = (id, entity, asset) => {
    setCurrentProductId(id);
    let payload = { product_id: id, username: entity.customer_username }
    if (entity.company_id) {
        payload.company_id = entity.company_id
    }
    props.getProduct(payload);
    setProductBasicInfo(entity);
    setSelectedAssetModal(asset);
  }
 
  const isComplaintInprogress = (render) => {
    let complaint_inprogress = false;
    if (render.flagged_by_user) {
        complaint_inprogress = true
        for (let final_render of lifestyleRenders) {
            if (final_render.scene_id == render.scene_id && final_render.revision_of == render.filename) {
                complaint_inprogress = false;
                break;
            }
        }
    }
    return complaint_inprogress
    }
  

    async function checkFileExists(url, loader) {
        console.log('file existing');
        try {
          const response = await fetch(url, { method: 'HEAD' });
          if (response.ok) {
            window.location.href = url;
            setPreparingArchive(false);
            loader();
          } else {
            setTimeout(() => checkFileExists(url, loader), 5000); // try again in 5 seconds
          }
        } catch (error) {
          setTimeout(() => checkFileExists(url, loader), 5000); // try again in 5 seconds
        }
      }
    
    const downloadSiloImagesArchive = () => {
        setPreparingArchive(true);
        const loader = message.loading('Preparing your Archive...', 0);
        let downloadData = [];
        if(silos.length > 0){
            silos.map((silo) => {
                let silo_base_uri = '';
                if(silo.company_id){
                    silo_base_uri = 'company/' + silo.company_id
                }
                else{
                    silo_base_uri = silo.username
                }
                let fileData = {};
                let imageUrl =ENVIRONMENT.QA_IMG_URI + silo['product_id'] + '/' + silo_base_uri + '/' + silo.camera_name + '.' + silo.image_data.img_format.replace('tiff', 'jpg');
                fileData['url'] = imageUrl
                fileData['display_name'] = silo.hasOwnProperty('camera_display_name') ? silo.camera_display_name : silo.camera_name;
                fileData['platform'] = silo.platform
                downloadData.push(fileData)
            });
        }

        const payload = {
            'files': downloadData,
            'project_id': projectId,
            'type': 'silos',
        };
        axios.post(ENVIRONMENT.INIT_BULK_PROJECT_ARCHIVE, payload).then((res) => {
            console.log('res = ', res);
            if (res['data'] && res['data']['archiveUrl']) {
              checkFileExists(res['data']['archiveUrl'], loader);
            }
          });
    }

    const downloadLifestyleImagesArchive = () => {
        setPreparingArchive(true);
        const loader = message.loading('Preparing your Archive...', 0);
        let downloadData = [];
        if(lifestyleRenders.length > 0){
            lifestyleRenders.map((render) => {
                if(render.status == 'rendered' && render.filename != '360Camera.jpg')
                {
                    let fileData = {};
                    let render_link = ENVIRONMENT.SCENE_THUMBNAIL_URI + render.scene_id + '/' + render.filename
                    fileData['url'] = render_link
                    fileData['display_name'] = render.hasOwnProperty('display_name') ? render.display_name : render.filename;
                    fileData['platform'] = render.platform
                    downloadData.push(fileData)
                }
            });
        }
        
        const payload = {
            'files': downloadData,
            'project_id': projectId,
            'type': 'lifestyles',
        };
        axios.post(ENVIRONMENT.INIT_BULK_PROJECT_ARCHIVE, payload).then((res) => {
            console.log('res = ', res);
            if (res['data'] && res['data']['archiveUrl']) {
              checkFileExists(res['data']['archiveUrl'], loader);
            }
          });
    }

    function getResolutionType(imageData) {
        const width = parseInt(imageData.width, 10);
        const height = parseInt(imageData.height, 10);
        
        const maxDimension = Math.max(width, height);
        if (maxDimension >= 0 && maxDimension <= 1024) {
            return '1K';
        } else if (maxDimension >= 1025 && maxDimension <= 2048) {
            return '2K';
        } else if (maxDimension >= 2049) {
            return '4K';
        }
    }

    const changeCurrentSceneName = (e) => {
        setCurrentSceneName(e.target.value)
    }

    const getScenePayload = () => {
        let payload = {
            "order_by": "id desc",
            "required_fields": [
                "id",
                "name"
            ]
        }
        let filter_string = "";
        let scene_status = "scene_status__in=[3]";
        let is_hidden = "is_hidden__not=true"
        let company_id = "";
        let shared_username = "";
        let customer_username =  `customer_username__exact='${CUSTOMER_USERNAME}'`;
        let scene_template_null_true = "(scene_template_id__isnull=true)"
        let is_hidden_managed_scene = "(is_hidden__is=true&&managed_scene__is=true)" // case when an msp removes scene after sharing with customer, customer should still be able to see it
        let scene_template_null_false_managed_scene = "((scene_template_id__isnull=true)||(scene_template_id__isnull=false&&designed_for__isnull=false))" // if a scene is a managed and template scene, show them
        if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined && SHARED_ENTITIES.split("_").includes("scene"))
        {
            company_id =  `owned_by_companies__contains=ARRAY[${COMPANY_ID}]||company_id__exact=${COMPANY_ID}`;
            filter_string = `(${company_id}||${customer_username})&&(${scene_status})&&((${is_hidden})||${is_hidden_managed_scene})&&${scene_template_null_false_managed_scene}`;
        }
        else if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined && !SHARED_ENTITIES.split("_").includes("scene"))
        {
            shared_username = "shared_username__exact='" + CUSTOMER_USERNAME + "'"
            filter_string = `(owned_by_companies__contains=ARRAY[${COMPANY_ID}]&&${shared_username})||(${customer_username}&&${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
        }
        else
        {
            filter_string = `(${customer_username}&&${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
        }
        
        payload["filter_string"] = filter_string;
        return payload;
    }

    const getSceneNames = () => {
        if(userScenes.length == 0){
            let payload = getScenePayload();
            axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
            .then(res => {
                setUserScenes(res.data)
            })
        }
    }

    useEffect(() => {
        if(duplicateSceneModal){
            duplicate_form.resetFields();
        }
    }, [duplicateSceneModal])


    const isSceneExist = (sceneName) => {
        let sceneExists = false;
        
        userScenes.map(item => {
            if(item.name == sceneName){
                sceneExists = true
            }
        })

        return sceneExists
      };

    const duplicateScene = async () => {
        try{
            await duplicate_form.validateFields(['scene_name']);
            setDuplicateSceneLoader(true)
            let payload = {
                'body': {
                    'action': 'customer_scene_replica',
                    'customer_username': CUSTOMER_USERNAME,
                    'company_id': COMPANY_ID,
                    'scene_id': currentScene,
                    'scene_name': currentSceneName,
                    'collection_name': currentSceneName,
                    'share_company_wide': true,
                    'project_id': projectId
                }
            };

            if(replicateAssets){
                payload['body']['replicate_assets'] = replicateAssets
            }

            console.log("****", payload)
            axios.post(ENVIRONMENT.SCENE_REPLICA, payload)
            .then((response) => {
                setDuplicateSceneLoader(false)
                setDuplicateSceneModal(false)
                setDuplicateSceneWaitModal(true);
              })
            .catch((error) => {
                setDuplicateSceneLoader(false)
                setDuplicateSceneModal(false)
                setDuplicateSceneWaitModal(true);
            });
        }
        catch(error){
            console.log('Error = ', error)
        }
        
    }



    const handleTabChange = (key) => {
      setTabActiveKey(key);
    
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('tab2', key);
    
      window.history.pushState(null, null, `?${searchParams.toString()}`);
    
      if (tabActiveKey !== key) {
        setSelectedResolutionFilter('all');
      }
    }
    

  const menu_images = (
    <Menu style={{ width: "165px" }}>
        <Menu.Item key={1} className={lifestyleRenders && lifestyleRenders.length == 0 ? "not-allowed-setting" : ""}>
            <a  className='manrope f-12' target="_blank" 
            onClick={() => downloadLifestyleImagesArchive()} 
            disabled={lifestyleRenders && lifestyleRenders.length == 0 ? true : false}> 
            All Lifestyle Images <DownloadOutlined style={{float: "right"}} type="download" />
            </a>
        </Menu.Item>
        <Menu.Item key={2} className={silos && silos.length == 0 ? "not-allowed-setting" : ""}>
            <a  className='manrope f-12' target="_blank" 
            onClick={() => downloadSiloImagesArchive()}
            disabled={silos && silos.length == 0 ? true : false}> 
            Silo Image(s) <DownloadOutlined style={{float: "right"}} type="download" />
            </a>
        </Menu.Item>
    </Menu>
);

  const ApproveModal = () => {
    return (
      <Modal
        className="approve-modal"
        width={600}
        centered={true}
        visible={isApproveModal}
        onCancel={() => setIsApproveModal(false)}
        footer={[
          <span className="justify-in-end">
            <Button
              style={{ textAlign: "center" }}
              disabled={loading}
              className="modal-okay square font-14"
              onClick={handleApproveProject}
            >
              Approve and Move to Libraries {loading ? <LoadingOutlined /> : ""}
            </Button>
          </span>,
        ]}
      >
        <h2 style={{padding:20}} className="manrope f-16 black-00 w-600">
          Is Your Project Complete?
        </h2>
        <Divider style={{ margin: 0 }} />
        <p style={{padding:20}} className="manrope f-14 black-55 p-20">
          Approving your project will move the project and all associated
          products, spaces, and scenes to your libraries.
        </p>
      </Modal>
    );
  };


  return (
    <div className="my-products">
      <CustomerMainLayout selected="21">
        <Header className="product-nav-header projects-nav-bar project-page-header">
          <Row className="display-flex j-s-b a-c">
            <Col span={12}>
              <Breadcrumb className="display-flex j-s a-c">
                <Breadcrumb.Item>
                  <a
                    className="manrope f-14 lh-28 black-73 white-space-text"
                    href={`/`}
                  >
                    <ArrowLeftOutlined className="mr-8" />
                    Your Dashboard
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                  <a
                    className="manrope f-14 lh-28 black-73 white-space-text"
                    href={tab==="approved"?`/library-projects?tab=${tab}`: `/projects?tab=${tab}`}
                  >
                  { `Your ${tabNameMapping[tab]}`}
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="manrope f-14 lh-28 black-d9 limit-lines limit-1">
                  {projectName}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {projectMetadata && projectMetadata['collaboration_count'] > 0 && (
              <Col span={6} className="justify-in-end ">
                <a
                  href={`/collaborations?project_id=${projectId}`}
                  className="manrope f-14 w-500 blue"
                >
                  See Collaborations for this Project
                  <ArrowRightOutlined />
                </a>
              </Col>
            )}
            {tab==='active'?
            <Col span={6} className="justify-in-end">
            {(displayCreateSceneButton && accessActions.includes('create')) && (
                <Col>
                  <Tooltip
                    title={
                      <span className="manrope f-12 white">Create Scene</span>
                    }
                  >
                    <Button
                      onClick={() => {
                        // window.location.href = "/add_scene?origin=/projects&project_id=" + projectId;
                        window.location.href = "/create-empty-scene?origin=/projects&project_id=" + projectId;
                      }}
                      type="primary"
                      className="invite-button mr-8"
                    >
                      Create Scene
                    </Button>
                  </Tooltip>
                </Col>
              )}
              <Dropdown overlay={
                <Menu>
                  <Menu.Item>
                      <a className="manrope f-14" href={`/new_project?origin=/projects/${projectId}?tab=active&isEdit=true&projectId=${projectId}&step=${3}`}>Products and Props</a>
                  </Menu.Item>

                  <Menu.Item>
                      <a className="manrope f-14" href={`/new_project?origin=/projects/${projectId}?tab=active&isEdit=true&projectId=${projectId}&step=${2}`}>Spaces</a>
                  </Menu.Item>

                  <Menu.Item>
                      <a className="manrope f-14" href={`/new_project?origin=/projects/${projectId}?tab=active&isEdit=true&projectId=${projectId}&step=${4}`}>Scenes</a>
                  </Menu.Item>

                  </Menu>
                } trigger={['click']}>
                   <Button type="primary"
                      className="invite-button">
                    <PlusOutlined/> Add
                   </Button>
             </Dropdown>
              </Col>:<></>}
          </Row>
        </Header>
        <Row
          className="justify-space-between"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <>
            <Col span={12} className='display-flex j-s a-c'>
                {(isEditName) ?
                <div id="name_form" className='manrope f-16 w-400 grey-8c nameEditField'>
                    <Input ref={editNameRef} className='manrope f-16' style={{width:"90%"}} defaultValue={projectName} bordered={false} />
                    <div className='nameEditIcons'>
                        {editNameLoader ?
                        <LoadingOutlined className='ml-16' /> :
                        <>
                            <CloseOutlined className='nameIcon' onClick={handleEditCancel} />
                            <CheckOutlined className='nameIcon' onClick={handleNameSubmit} />
                        </>}
                    </div>
                </div> :
                <Tooltip title={<span className="manrope f-12 white">{projectName}</span>}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                  <div
                      className="manrope f-20 w-600 clamp-text-400"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {projectName}
                  </div>
                  {accessLevel == 'owner' &&
                    <EditOutlined style={{color: "#276DD7"}} className='ml-10' onClick={()=> {
                        setIsEditName(true)
                        }} 
                    />}
                  </div>
                </Tooltip>
                }
              </Col>
            <Col lg={12} xl={12} className="justify-in-end">
              <Col lg={15} xl={15}>
                <Form>
                  <Input.Group compact size="large" className="d-inline">
                    <Input
                      placeholder={tabActiveKey == "1" ? "Search by product, space etc" : "Search by image name"}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      className="manrope f-14 mat-search-bar"
                      style={{ marginLeft: IS_MSPROVIDER ? 5 : "" }}
                    ></Input>
                  </Input.Group>
                </Form>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col>
                <Filter
                  selectedModelType={selectedModelType}
                  selectedReviewStatus={selectedReviewStatus}
                  setSelectedModelType={setSelectedModelType}
                  setSelectedReviewStatus={setSelectedReviewStatus}
                  isReviewFilter={true}
                />
              </Col>
              {accessActions.includes('comment') &&
                <Col>
                  <Tooltip
                    title={
                      <span className="manrope f-12 white">Add Comments</span>
                    }
                  >
                    <div>
                        <div
                            className="icon-button-container ml-8"
                            onClick={() => setIsCommentsDrawer(true)}
                        >
                            <MessageOutlined />
                        </div>
                        <div className='comment-count'>{commentCount}</div>
                    </div>
                  </Tooltip>
                </Col>
              }
              <div id="components-dropdown-demo-dropdown-button">
                <Tooltip title="Download Links" placement="top">
                    <span className={isDataLoading ? "not-allowed-setting" : ""} >
                        <div className={isDataLoading ? "pointer-none-setting": ""}>
                            <Dropdown overlay={menu_images} trigger={['click']} disabled={preparingArchive}>
                                <div className="icon-button-container ml-8">
                                    <DownloadOutlined />
                                </div>
                            </Dropdown> 
                        </div>
                    </span>    
                </Tooltip>
              </div>
              {(projectStatus && accessActions.includes('invite')) &&
                <Col>
                  <Tooltip
                    title={
                      <span className="manrope f-12 white">Invite people</span>
                    }
                  >
                    <Button onClick={() => setIsAccessModal(true)} type="primary" className="invite-button ml-8">
                      <UserAddOutlined />
                      Invite
                    </Button>
                  </Tooltip>
                </Col>}
              
            </Col>
          </>
        </Row>
        <Row className="mb-10">
          <Col span={12}>
            {/* models count */}
            <span className="manrope f-14 w-400 mr-10" style={{color: '#8C8C8C'}}>Models</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{projectMetadata['product_count']} {projectMetadata['product_count'] == 1 ? 'Product' : 'Products'}</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{projectMetadata['room_count']} {projectMetadata['room_count'] == 1 ? 'Space' : 'Spaces'}</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{projectMetadata['scene_count']} {projectMetadata['scene_count'] == 1 ? 'Scene' : 'Scenes'}</span>

            {/* Renders count */}
            <span className="manrope f-14 w-400 mr-10 ml-50" style={{color: '#8C8C8C'}}>Images</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{getCombinedRendersCount('silo_count')} {getCombinedRendersCount('silo_count') == 1 ? 'Silo' : 'Silos'}</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{getCombinedRendersCount('lifestyle_count')} {getCombinedRendersCount('lifestyle_count') == 1 ? 'Lifestyle' : 'Lifestyles'}</span>
            {getCombinedRendersCount('silo_count') + getCombinedRendersCount('lifestyle_count') > 0 ?
            <Tooltip title={
                <Row>
                    {projectMetadata.silo_count && 
                        <Col span={12} className="justify-in-start">
                            <span style={{marginLeft: 5}}>
                                <span className='manrope f-12 black w-600' style={{marginBottom: 10}}>
                                    Silos
                                </span>
                                {
                                Object.keys(projectMetadata.silo_count).map(key => (
                                    <div className="justify-in-start" style={{ textAlign: "right" }}>
                                        <span className='manrope f-12' style={{color: "#276DD7", marginRight: 15, marginTop: 8}}>{projectMetadata.silo_count[key]}</span>
                                        <span className='manrope f-12 black' style={{marginTop: 8}}>{key}</span>
                                    </div>
                                ))
                                }
                            </span>
                        </Col>
                    }
                    {projectMetadata.lifestyle_count &&
                        <Col span={12} className="justify-in-start">
                            <span style={{marginRight: 20, marginLeft: 10}}>
                                <span className='manrope f-12 black w-600' style={{marginBottom: 10}}>
                                    Lifestyles
                                </span>
                                {
                                Object.keys(projectMetadata.lifestyle_count).map(key => (
                                    <div className="justify-in-start" style={{ textAlign: "left" }}>
                                        <span className='manrope f-12' style={{color: "#276DD7", marginRight: 15, marginTop: 8}}>{projectMetadata.lifestyle_count[key]}</span>
                                        <span className='manrope f-12 black' style={{marginTop: 8}}>{key}</span>
                                    </div>
                                ))
                                }
                            </span>
                        </Col>
                    }
                </Row>
            }
            color={"white"}>
                <img src='/img/info-blue.svg' style={{marginLeft: 3, marginBottom: 2}}/>
              </Tooltip>
              : ''}
          </Col>
        </Row>
        
          <div>
            {isDataLoading ? (
              <DottedLoader />
            ) : 
            <Tabs onChange={(key) => handleTabChange(key)}
            activeKey={tabActiveKey}
            className="product-tab library" tabBarGutter={16} size="large">
              <TabPane key="1" tab="Models" forceRender>

                {/* Radio buttons for filtration */}
                <Col span={12} offset={12} className="mb-20">
                  <div className="manrope f-14 mb-20" style={{float: 'right'}}>
                      <span className="mr-20 w-400" style={{color: '#8C8C8C'}}>
                          Show
                      </span>
                      <Radio.Group onChange={handleModelTypeChange} value={selectedModelType}>
                        {
                          modelTypeFilters.map((item) => {
                            return <Radio value={item.value}>
                              <span className="manrope f-14 w-500" style={{color: '#262626'}}>{item.label}</span>
                            </Radio>
                          })
                        }
                      </Radio.Group>
                  </div>
                </Col>

                {/* Show applied filters */}
                {(selectedReviewStatus.length > 0) && (
                  <Col span={24} style={{marginBottom: 10 }}>
                    <h4 className="manrope f-14 black-55 italic">
                      Showing results for
                    </h4>
                    <div>
                      {selectedReviewStatus.map((category) => {
                        return (
                          <Tag
                            key={category}
                            onClose={() => closeTags(category, "space_category")}
                            closable
                            visible={true}
                            className="manrope f-12 black-55 tag-filter"
                          >
                            Review Status:{" "}
                            <span className="manrope f-12 grey-77">{getTagName(category)}</span>
                          </Tag>
                        );
                      })}
                    </div>
                  </Col>
                )}
                <EntitiesList selectAssetAndSetBasicInfo={selectAssetAndSetBasicInfo}
                projectStatus={projectStatus} accessActions={accessActions} data={filteredData} isFilter = {selectedModelType != 'all' > 0 ||
                  selectedReviewStatus.length > 0} 
                  setCurrentScene={setCurrentScene}
                  setCurrentSceneName={setCurrentSceneName}
                  setDuplicateSceneModal={setDuplicateSceneModal}
                  />
              </TabPane>

              <TabPane key="2" tab="Lifestyles">

                {/* Show resolution filter */}
                <Row>
                  <Col span={12} offset={12} >
                    <div className="manrope f-14 mb-20" style={{float: 'right'}}>
                        <span className="mr-20 w-400" style={{color: '#8C8C8C'}}>
                            Resolution
                        </span>
                        <Radio.Group onChange={(e) => setSelectedResolutionFilter(e.target.value)} value={selectedResolutionFilter}>
                          {
                            resolutionFilter.map((item) => {
                              return <Radio value={item.value}>
                                <span className="manrope f-14 w-500" style={{color: '#262626'}}>{item.label}</span>
                              </Radio>
                            })
                          }
                        </Radio.Group>
                    </div>
                  </Col>
                </Row>
                <LifestylesList projectId={projectId}
                    populateSceneImages={populateSceneImages}
                    searchValue={searchValue}
                    white_balanced_images={whiteBalancedImages}
                    downloadLifestyleImagesArchive={downloadLifestyleImagesArchive}
                    selectedResolutionFilter={selectedResolutionFilter}
                    setSelectedResolutionFilter={setSelectedResolutionFilter}
                    lifestyleRenders={lifestyleRenders}
                    projectMetadata={projectMetadata}
                    setProjectMetadata={setProjectMetadata}
                    setLifestyleRenders={setLifestyleRenders}
                    loadingLifestyles={loadingLifestyles}
                />
              </TabPane>
              <TabPane key="3" tab="Silos">
                <Row>
                  <Col span={12} offset={12} >
                    <div className="manrope f-14 mb-20" style={{ float: 'right' }}>
                      <span className="mr-20 w-400" style={{ color: '#8C8C8C' }}>
                        Resolution
                      </span>
                      <Radio.Group onChange={(e) => setSelectedResolutionFilter(e.target.value)} value={selectedResolutionFilter}>
                        {
                          resolutionFilter.map((item) => {
                            return <Radio value={item.value}>
                              <span className="manrope f-14 w-500" style={{ color: '#262626' }}>{item.label}</span>
                            </Radio>
                          })
                        }
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
                <SiloList
                  searchValue={searchValue}
                  selectedResolutionFilter={selectedResolutionFilter}
                  setSelectedResolutionFilter={setSelectedResolutionFilter}
                  downloadSiloImagesArchive={downloadSiloImagesArchive}
                  getResolutionType={getResolutionType}
                  projectId={projectId}
                  silos={silos}
                  populateSilosData={populateSilosData}
                  loadingSilos={loadingSilos}
                  setProjectMetadata={setProjectMetadata}
                  projectMetadata={projectMetadata}
                />
              </TabPane>
            </Tabs>
            }
        </div>
        {(projectStatus == "ready_for_scenes" && accessActions.includes('approve_project')) && (
          <div className="bottom-menu">
            <Button
              onClick={() => setIsApproveModal(true)}
              disabled={loading}
              className="product-btn approve-button f-14 ml-16 br-4"
            >
              Approve Project
            </Button>
          </div>
        )}
        <ApproveModal />
        { accessLevel == 'restricted' &&
          <RestrictedContainer />
        }
        <ProjectComments
          projectId={projectId}
          projectName={projectName}
          isCommentsDrawer={isCommentsDrawer}
          setIsCommentsDrawer={setIsCommentsDrawer}
          tagUserList={tagUserList}
          commentCount={commentCount}
          setCommentCount={setCommentCount}
          customGroups={customGroups.filter(item => item.access_level != 'restricted')}
          usersAndCustomGroupsList={usersAndCustomGroupsList}
        />
        <ProjectAccess
          setProjectUsers={setProjectUsers}
          projectName={projectName}
          projectId={projectId}
          projectUsers={projectUsers}
          isAccessModal={isAccessModal}
          setIsAccessModal={setIsAccessModal}
          getSharedUsers={getSharedUsers}
          accessLevel={accessLevel}
          customGroups={customGroups}
        />
        <ProductAssetModals
        currentPage={currentPage}
        props={props}
        accessed_from={'project_products'}
        currentProductId={currentProductId}
        setCurrentProductId={setCurrentProductId}
        selectedAssetModal={selectedAssetModal}
        productBasicInfo={productBasicInfo}
        />
        
      <Modal
        maskClosable={true}
        centered
        onCancel={handleCancel}
        visible={isModalOpen}
        destroyOnClose={true}
        width={"fit-content"}
        bodyStyle={{ padding: 0 }}
        style={{
          padding: "20px 0px 20px 0px",
          minWidth: "25%",
          maxWidth: "90%",
        }}
        footer={null}
        className="img-modal"
      >
        {selectedLifestyleRender ? (
          <>
            <Image
              src={selectedLifestyleRender.request_link}
              className="render-img"
              preview={false}
              placeholder={
                <div className="img-loader justify-in-center">
                  <Spin indicator={antIcon} />
                </div>
              }
            />
          </>
        ) : (
          ""
        )}
      </Modal>

        <Modal
            visible={duplicateSceneModal}
            onCancel={() => {
                if(!duplicateSceneLoader){
                    setDuplicateSceneModal(false)  
                }}}
            width={700}
            className="modal-space"
            bodyStyle={{padding: 0}}
            maskClosable={false}
            footer={[
                <div className="justify-in-end">
                    <Button className="modal-okay-gray square font-12" key="back" onClick={() => setDuplicateSceneModal(false)} disabled={duplicateSceneLoader}>
                    Cancel
                    </Button>
                    <Button className="modal-okay square font-12" key="ok" onClick={duplicateScene} disabled={duplicateSceneLoader}>
                        Duplicate Scene {duplicateSceneLoader ? <LoadingOutlined /> : ''}
                    </Button>
                </div>
            ]}>
            <Form
                form={duplicate_form}
                initialValues={{
                    scene_name: currentSceneName,
                    replicate_assets: replicateAssets
                }}
            >
                <div style={{padding:"30px",display:"flex"}}>
                    <Col span={24}>
                        <h2 className="modal-heading manrope f-14 black-00 w-600">Add Scene Name</h2>
                        <h5 className='manrope f-12 bg grey' style={{lineHeight: 'inherit'}}><InfoCircleOutlined/>&nbsp;This will be the name of your scene. The duplicated scene will appear in your project.</h5>
                        <h5 className='manrope f-12 bg grey' style={{lineHeight: 'inherit'}}><InfoCircleOutlined/>&nbsp;A copy of the space being used in this scene will be created and added to your project.</h5>
                        <Form.Item
                            name="scene_name"
                            rules={[{ required: true, message: 'Please add scene name.' },
                            {
                                validator: async(_, value) => {
                                    // if scene with same name exists
                                    if(value){
                                    let isSceneDuplicate = isSceneExist(value);
                                    if (isSceneDuplicate) {
                                        throw new Error('You already have a scene with same name in your library!');
                                    }
                                }
                                }
                            }]}
                        >
                            <Input onChange={changeCurrentSceneName} className='manrope f-12 black-55' placeholder='Enter Scene Name' style={{width: "100%",height:"40px"}}/>

                        </Form.Item>
                        <Form.Item>
                            <Checkbox name="replicate_assets" className="black-checkbox manrope f-12 black-55" onChange={e => setReplicateAssets(e.target.checked)}>
                                Copy Lifestyles, 360s and 3D Tour
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </div>
            </Form>
        </Modal>
        <SuccessModal
            visible={duplicateSceneWaitModal}
            heading={"Duplicate Scene Requested!"}
            text={"You have successfully requested a duplicate of the scene. Scene imagery are being replicated which can take upto a minute."}
            footer={[
            <div className='justify-in-center'>
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-16" 
            onClick={() => {
                    setDuplicateSceneWaitModal(false);
                    window.location.href = '/projects/' + projectId
                }}>
                Okay
            </Button>
            </div>
            ]}
        />
      </CustomerMainLayout>
    </div>
  );
};


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerProject)
)