import React from "react";
import { Layout } from "antd";
import FailedAndAbortedJobs from "../FailedAndAbortedJobs/FailedAndAbortedJobs";

const { Content } = Layout;

const AutomationDetails = () => {
  return (
    <>
      <Layout>
        <Content className="analytics-content">
            <FailedAndAbortedJobs />
        </Content>
      </Layout>
    </>
  );
};

export default AutomationDetails;