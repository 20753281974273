import React, { useContext, useState, useRef } from "react";
import { Form, Input, message, Modal, Select, Row, Col, Button, Collapse, Tooltip, Empty, Popover } from "antd";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import { LoadingOutlined, EditOutlined, InfoCircleOutlined, UserOutlined, EyeOutlined, InfoOutlined } from '@ant-design/icons';
import ProjectConstants from "./Constants";
import FileConstants from "../../../../FileConstants";

const { Option } = Select;
const { Panel } = Collapse;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const ProjectAccess = (props) => {
    const { isAccessModal, setIsAccessModal, projectUsers, projectId, projectName, setProjectUsers, 
            getSharedUsers, accessLevel, customGroups } = props;
    const [accessUpdateLoader, setAccessUpdateLoader] = useState(false);
    const [updatingAccessEmails, setUpdatingAccessEmails] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const formRef = { useRef };

    const handleAccessChange = (email, access_level = "co-owner") => {
        // Keep track of email address whose access is being updated
        if (!updatingAccessEmails.includes(email)) {
            let emails = updatingAccessEmails;
            emails.push(email);
            console.log('emails =', emails)
            setUpdatingAccessEmails(emails);
            setAccessUpdateLoader(true);
            forceUpdate()
        }

        let payload = {
            action: 'insert_or_update',
            project_id: projectId,
            username: localStorage.getItem('username'),
            emails: [email],
            access_level: access_level,
            display_name: projectName
        };

        axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
            .then(res => {
                if (res && res.data.statusCode == 200) {
                    updateAccessValueInList(email, access_level)
                    // Remove the email address when it's API completes
                    let updated_emails = updatingAccessEmails.filter(item => item !== email);
                    setUpdatingAccessEmails(updated_emails)
                    message.success('Access Updated Successfully!');
                    getSharedUsers();
                } else if (res && res.data.statusCode == 400) {
                    message.error('Error in changing access');
                }
                setAccessUpdateLoader(false);
            })
    };

    function handleGroupAccessChange(group_id, access_level) {
        setAccessUpdateLoader(true);
        // Keep track of email address whose access is being updated
        let records = updatingAccessEmails;
        if(!records[group_id])
            records.push(group_id)
        setUpdatingAccessEmails(records);
        forceUpdate();
        let emails = []
        for(let group of customGroups){
            if(group.group_id == group_id){
                for(let user of group.users){
                    emails.push(user.email)
                }
                break;
            }
        }

        let payload = {
            action: 'insert_or_update',
            project_id: projectId,
            username: localStorage.getItem('username'),
            access_level: access_level,
            group_id: group_id,
            display_name: projectName,
            emails: emails
        }

        axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
            .then(res => {
                setAccessUpdateLoader(false);
                let updating_emails_or_group_ids = updatingAccessEmails.filter(item => item != group_id)
                setUpdatingAccessEmails(updating_emails_or_group_ids);
                message.success(<span className="capitalize">{'Project shared successfully!'}</span>);
                getSharedUsers();
                forceUpdate();
            });
    }

    const updateAccessValueInList = (email, access_level) => {
        let users = projectUsers
        users.map(item => {
            if (item.email == email) {
                item['access_level'] = access_level
            }
        })
        setProjectUsers(users)
    };

    const handleShareInvite = (values) => {
        let email = values['email'];
        let payload = {
            project_id: projectId,
            username: localStorage.getItem('username'),
            emails: [email],
            access_level: values["access_level"] || "editor",
            action: 'insert_or_update',
            invitation: true,
            display_name: projectName  // pass display name to show in email
        };
        setAccessUpdateLoader(true);
        console.log('Payload', payload)
        axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
            .then(res => {
                setAccessUpdateLoader(false)
                if (res && res.data.statusCode == 200) {
                    getSharedUsers();
                    message.success('User granted access successfully!');
                    //formRef.current.resetFields();
                }
                else {
                    if (res && res.data.statusCode == 400)
                        message.error(res.data.message);
                }
            })
    };

    return (
        <Modal
            className="collaborate-access-modal"
            width={600}
            destroyOnClose={true}
            visible={isAccessModal}
            closable={true}
            onCancel={() => setIsAccessModal(false)}
            title={
            <div className="justify-in-start">
                <span className="manrope f-16">Project Access&nbsp;&nbsp;</span>
                    <Popover content={<ul className="manrope f-14 black-33" style={{ padding: "12px 16px 0px 16px" }}>
                        {Object.keys(ProjectConstants.access_action_descriptions).map(key => (
                            <li key={key}>{ProjectConstants.access_action_descriptions[key]}</li>
                        ))}
                    </ul>} title={<span className="manrope f-16 black-33">Permission Types</span>}>
                        <span><InfoCircleOutlined /></span>
                    </Popover>
            </div>}
        >
            <div>
                <span className="manrope f-14 mt-10">People who can view</span>
                
                <Collapse accordion={true} defaultActiveKey={['1']} >
                    <Panel header="Individuals" key="1">
                        <div className="collaborators-list custom-scroll">
                            {projectUsers.map((item, index) => {
                                return (
                                    <div key={index} className="collaborators-list-item">
                                        <div className="collaborator-email-username">
                                            <span
                                                className="manrope f-14 w-700"
                                                style={{ display: "block" }}
                                            >
                                                {item.display_name}
                                            </span>
                                            <span
                                                className="manrope f-12 w-400"
                                                style={{ display: "block" }}
                                            >
                                                {item.email}
                                            </span>
                                        </div>
                                        <div span={8} className="display-flex j-e">
                                            {true ? (
                                                // Allow collaboration owner to make co-owners

                                                ["owner"].includes(item.access_level) || CUSTOMER_USERNAME == item.username  ? (
                                                    <span className="manrope f-14 w-500 line-height-20 pd-r-10 display-flex a-c j-c">
                                                        {ProjectConstants.access_levels[item.access_level]}
                                                    </span>
                                                ) : (
                                                    <span className="manrope f-14 w-500 line-height-20">
                                                        {updatingAccessEmails.includes(item.email) &&
                                                            accessUpdateLoader ? (
                                                            <LoadingOutlined />
                                                        ) : (
                                                            ""
                                                        )}

                                                        {/* If current user is editor then don't allow changing access of the users who has higher access then editor or viewer */}
                                                        {accessLevel == 'editor' && !['editor', 'viewer', 'restricted'].includes(item.access_level) && item.access_level != undefined ?
                                                            <span className="manrope f-14 w-500 line-height-20 pd-r-10 display-flex a-c j-c">
                                                                {ProjectConstants.access_levels[item.access_level]}
                                                            </span>
                                                        :
                                                        <Select
                                                            suffixIcon={
                                                                <EditOutlined
                                                                    className={
                                                                        updatingAccessEmails.includes(item.email) &&
                                                                            accessUpdateLoader
                                                                            ? ""
                                                                            : "collaborators-access"
                                                                    }
                                                                />
                                                            }
                                                            className="collaborators-access"
                                                            dropdownMatchSelectWidth={false}
                                                            defaultValue={
                                                                !item.access_level || item.access_level == "restricted"
                                                                    ? "Invite"
                                                                    : item.access_level
                                                            }
                                                            disabled={
                                                                updatingAccessEmails.includes(item.email) &&
                                                                    accessUpdateLoader ? (
                                                                    <LoadingOutlined />
                                                                ) : (
                                                                    ""
                                                                )
                                                            }
                                                            onChange={(e) => handleAccessChange(item.email, e)}
                                                        >
                                                            {ProjectConstants.new_edit_access_levels.map(
                                                                (item, index) => (
                                                                    ///* if currently logged in user has editor access, then only show editor and viewer options in dropdown */}
                                                                    accessLevel == 'editor' ?
                                                                        ['editor', 'viewer'].includes(item.value) &&
                                                                            (
                                                                            <Option
                                                                                style={{ fontSize: 12, fontStyle: "Manrope" }}
                                                                                value={item.value}
                                                                            >
                                                                            <Tooltip title={<span className="manrope f-12 white">
                                                                                {ProjectConstants.access_action_descriptions[item.value]}
                                                                            </span>}>
                                                                                {item.label}
                                                                            </Tooltip>
                                                                            </Option>)
                                                                    :
                                                                        (<Option
                                                                            style={{ fontSize: 12, fontStyle: "Manrope" }}
                                                                            value={item.value}
                                                                        >
                                                                            <Tooltip title={<span className="manrope f-12 white">
                                                                                {ProjectConstants.access_action_descriptions[item.value]}
                                                                            </span>}>
                                                                            {item.label}
                                                                            </Tooltip>
                                                                        </Option>)
                                                                )
                                                            )}
                                                        </Select>}
                                                    </span>
                                                )
                                            ) : ProjectConstants.edit_access_levels.includes(
                                                item.access_level
                                            ) ? (
                                                <span className="manrope f-14 w-500 line-height-20 pd-r-10 display-flex a-c j-c">
                                                    {ProjectConstants.access_levels[item.access_level]}
                                                </span>
                                            ) : (
                                                <span className="manrope f-14 w-500 line-height-20">
                                                    {updatingAccessEmails.includes(item.email) &&
                                                        accessUpdateLoader ? (
                                                        <LoadingOutlined />
                                                    ) : (
                                                        ""
                                                    )}
                                                    <Button
                                                        ghost
                                                        className="action-icon-btn blue manrope f-14 blue hover-change"
                                                        disabled={
                                                            updatingAccessEmails.includes(item.email) &&
                                                                accessUpdateLoader ? (
                                                                <LoadingOutlined />
                                                            ) : (
                                                                ""
                                                            )
                                                        }
                                                        onClick={() => handleAccessChange(item.email)}
                                                    >
                                                        {updatingAccessEmails.includes(item.email) &&
                                                            accessUpdateLoader ? (
                                                            <LoadingOutlined />
                                                        ) : (
                                                            ""
                                                        )}
                                                        &nbsp;Invite
                                                    </Button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Panel>

                    <Panel header={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                        Groups 
                        <Tooltip  title={<span className="manrope f-12 white">Use groups to share items with multiple users at once.</span>}> <InfoCircleOutlined /> </ Tooltip></span>
                        <a onClick={(e) => {window.location.href = '/account-details?tab=group'; e.stopPropagation()}}> Manage Groups</a>
                        </div>} key="2">

                        <div className="collaborators-list custom-scroll mt-10" >
                            {customGroups.length > 0 ?
                                customGroups.map((item, index) => {
                                    return (
                                        <div key={index} className="collaborators-list-item">
                                            <div className="collaborator-email-username">
                                                <span className="manrope f-14 w-500" style={{ display: 'block' }}><img src={'/img/group_icon.svg'} /> {item.group_name}</span>
                                            </div>
                                            <div>
                                                {updatingAccessEmails.includes(item.group_id) ? <LoadingOutlined /> : ''}
                                                <Select
                                                    suffixIcon={<EditOutlined className={updatingAccessEmails.includes(item.email) || (item.access_level != 'restricted' && accessLevel == 'view') || item.username == localStorage.getItem('username') ? "" : 'collaborators-access'} />}
                                                    className="collaborators-access"
                                                    dropdownMatchSelectWidth={false}
                                                    defaultValue={item.access_level == "restricted" ? "Invite" : item.access_level}
                                                    disabled={updatingAccessEmails.includes(item.group_id) || (item.access_level != 'restricted' && accessLevel == 'view') ? true : false}
                                                    onChange={(e) => handleGroupAccessChange(item.group_id, e)}
                                                >
                                                    {ProjectConstants.new_edit_access_levels.map(
                                                        (item, index) => (
                                                            // if currently logged in user has editor access, then only show editor and viewer options in dropdown
                                                            accessLevel == 'editor' ?
                                                                ['editor', 'viewer'].includes(item.value) &&
                                                                    (<Option
                                                                        style={{ fontSize: 12, fontStyle: "Manrope" }}
                                                                        value={item.value}
                                                                    >
                                                                        <Tooltip title={<span className="manrope f-12 white">
                                                                            {ProjectConstants.access_action_descriptions[item.value]}
                                                                        </span>}>
                                                                            {item.label}
                                                                        </Tooltip>
                                                                    </Option>)
                                                            :
                                                                (<Option
                                                                    style={{ fontSize: 12, fontStyle: "Manrope" }}
                                                                    value={item.value}
                                                                >
                                                                    <Tooltip title={<span className="manrope f-12 white">
                                                                        {ProjectConstants.access_action_descriptions[item.value]}
                                                                    </span>}>
                                                                        {item.label}
                                                                    </Tooltip>
                                                                </Option>)
                                                               
                                                        )
                                                    )}
                                                </Select>
                                            </div>
                                        </div>
                                    );
                                })
                            : 
                            <Empty
                                style={{ marginTop: 20, marginBottom: 20 }}
                                description={"No groups created yet."}
                                className="manrope f-14"
                            />
                            }
                        </div>
                    </Panel>
                </Collapse>


                
                
                <div className="mt-20">
                    <p className="manrope f-14 mt-10 mb-5">Invite</p>
                    <Form
                        ref={formRef}
                        onFinish={handleShareInvite}
                        initialValues={{
                            access_level: "editor",
                        }}
                    >
                        <Row gutter={8}>
                            <Col span={true ? 15 : 20}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { type: "email", message: "Please enter a valid email." },
                                        { required: true, message: "Please enter an email." },
                                        {
                                            pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                                            message:
                                                " Spaces are not allowed, whitespace found at the end.",
                                        },
                                    ]}
                                >
                                    <Input
                                        className="collab-access-form-fields"
                                        placeholder="Type email here"
                                    />
                                </Form.Item>
                            </Col>
                            {true ? (
                                <Col span={5}>
                                    <Form.Item name="access_level">
                                        <Select
                                            className="collab-access-form-fields manrope f-12 mb-15"
                                            options={ProjectConstants.new_edit_access_levels.filter(item => {
                                                return (accessLevel == 'editor' ? (['editor', 'viewer'].includes(item.value) ? item : '') : item)
                                            })}
                                        ></Select>
                                    </Form.Item>
                                </Col>
                            ) : (
                                ""
                            )}
                            <Col span={4}>
                                <Button
                                    htmlType="submit"
                                    disabled={accessUpdateLoader}
                                    ref={formRef}
                                    className="modal-okay square font-12"
                                >
                                    Invite{" "}
                                    {accessUpdateLoader ? (
                                        <LoadingOutlined />
                                    ) : (
                                        ""
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default ProjectAccess;
