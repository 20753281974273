import React, { useEffect, useRef, useState } from 'react';
import FileConstants from '../../../../FileConstants';
import ENVIRONMENT from '../../../../environments';
import DottedLoader from '../../DottedLoader';
import { LoadingOutlined, InfoCircleOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Select,
  Table,
  Space,
  Typography,
  Radio,
  Button,
  Card,
  Modal,
  message,
  Tooltip,
  Divider
} from 'antd';
const { Text } = Typography;

const { Option } = Select;

const ProductPresetManagement = (props) => {
  const productPresetsData = useRef([]);
  const [filteredProductPresets, setFilteredProductPresets] = useState([]);
  const [productPresetModal, setProductPresetModal] = useState(false);
  const [radioValue, setRadioValue] = useState('new_preset');
  const [skylineCustomers, setSkylineCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [allCustomerProducts, setAllCustomerProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [filteredPresetsData, setFilteredPresetsData] = useState([]);
  const [modalProductPresetID, setModalProductPresetID] = useState(null);
  const [modalProductPresetName, setModalProductPresetName] = useState(null);
  const [modalProductID, setModalProductID] = useState(null);
  const [modalProductName, setModalProductName] = useState(null);
  const [modalProductCustomerNames, setModalProductCustomerNames] = useState(
      [],
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [presetToDelete, setPresetToDelete] = useState(null)

  const fetchData = async () => {
    try {
      await Promise.all([
        getSkylineCustomers(),
        getAllCustomerProducts(),
        getProductPresetData(),
      ]).then(() => {
        if (props?.selectedCustomer) {
          handleSelectedCustomer(props.selectedCustomer);
        }
        setLoadingState(false);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (props.refreshData) {
      fetchData();
      props.setRefreshData(false)
    }
  }, [props.refreshData]);

  const getProductPresetData = ( onProductsSet = null ) => {
    const payload = { action: 'get_products' };

    if (FileConstants.COMPANY_ID != null) {
      payload['company_id'] = FileConstants.COMPANY_ID;
    } else {
      payload['customer_username'] = FileConstants.CUSTOMER_USERNAME;
    }

    return axios
        .post(ENVIRONMENT.PRODUCT_PRESET_CONTROLLER, payload)
        .then((res) => {
          if (res && res['data']) {
            productPresetsData.current = res.data.data;
            if (onProductsSet) {
              onProductsSet();
            }
          }
        });
  };

  const getAllCustomerProducts = () => {
    return axios
        .post(ENVIRONMENT.LIST_PRODUCT, {
          required_fields: ['id', 'name'],
          filter_string: `(company_id__exact=${FileConstants.COMPANY_ID}&&model_status__in=[5])`,
          order_by: 'id desc',
        })
        .then((res) => {
          if (res && res.data) {
            setAllCustomerProducts(res.data);
          }
        });
  };

  const getSkylineCustomers = () => {
    const payload = {
      'company_id': FileConstants.COMPANY_ID,
    };
    return axios
        .post(ENVIRONMENT.CUSTOMER_PRESET_GET_BATCH, payload)
        .then((res) => {
          if (res && res.data) {
            const customers = [];
            res.data['data'].map((item) => {
              customers.push(item['customer_username']);
            });
            setSkylineCustomers(customers);
          }
        });
  };

  const updateProductPresetData = () => {
    setButtonLoader(true);
    if (!modalProductPresetID) {
      message.error('Preset should be selected.');
      setButtonLoader(false);
      return;
    }
    const payload = {
      action: 'update_product',
      product_id: modalProductID,
      preset_id: modalProductPresetID,
      customer_name: modalProductCustomerNames[0],
    };

    axios
        .post(ENVIRONMENT.PRODUCT_PRESET_CONTROLLER, payload)
        .then((res) => {
          if (res && res['data']) {
            if (res.data.statusCode != 200) {
              message.error(res.data.errorMessage);
            } else {
              message.success('Product\'s preset updated successfully.');
            }
            const updatedArray = productPresetsData.current.map((obj) =>
            obj.product_id === modalProductID &&
            obj.customer_name === modalProductCustomerNames[0] ?
              {
                ...obj,
                preset_id: modalProductPresetID,
                preset_name: modalProductPresetName,
              } :
              obj,
            );
            productPresetsData.current = [...updatedArray];
            handleSelectedCustomer(modalProductCustomerNames[0]);
          }
        })
        .finally(() => {
          setButtonLoader(false);
          setProductPresetModal(false);
          setModalProductID(null);
          setModalProductCustomerNames([]);
          setModalProductPresetID(null);
          setModalProductPresetName(null);
        });
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleSelectedCustomer = (value) => {
    setSelectedCustomer(value);
    const filteredPresets = productPresetsData.current.filter(
        (obj) => obj.customer_name === value,
    );
    setFilteredProductPresets(filteredPresets);
  };

  const handleModalSelectedCustomer = (selectedCustomerNames) => {
    setModalProductCustomerNames(selectedCustomerNames);

    const filteredPresets = productPresetsData.current.filter((obj) =>
      selectedCustomerNames.includes(obj.customer_name),
    );

    const filteredProducts = allCustomerProducts.filter(
        ({ id: productID1 }) =>
          !filteredPresets.some(
              ({ product_id: productID2 }) => productID2 === productID1,
          ),
    );
    setModalProductID(null);
    setFilteredProducts(filteredProducts);
  };

  const handleFilterPresets = (productID, customerNames, presetID = null) => {
    const uniquePresetIds = new Set();
    if (presetID) {
      uniquePresetIds.add(presetID);
    }
    const filteredPresets = productPresetsData.current.filter((preset) => {
      if (
        preset.product_id === productID &&
        !customerNames.includes(preset.customer_name) &&
        !uniquePresetIds.has(preset.preset_id)
      ) {
        uniquePresetIds.add(preset.preset_id);
        return true;
      }
      return false;
    });

    setFilteredPresetsData(filteredPresets);
  };

  const deletePreset = (preset) => {

    const customerUsername = preset.customer_name
    const productID = preset.product_id;
    const presetID = preset.preset_id;

    let payload = {
      'customer_name': customerUsername,
      'product_id': productID,
      'preset_id': presetID,
      'action': 'delete_product'
    }
    axios.post(ENVIRONMENT.PRODUCT_PRESET_CONTROLLER, payload)
    .then((res) => {
        if (res && res['data']) {
          setPresetToDelete(null);
          setDeleteModalVisible(false)
          message.success("Preset Deleted Successfully!")
          getProductPresetData( () => {
            if (selectedCustomer) {
              handleSelectedCustomer(selectedCustomer)
            }
          } );
        }
        else
        {
            message.error('Error deleting preset!')
        }
      });
  }

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const columns = [
    {
      title: 'Product ID',
      dataIndex: 'product_id',
      key: 'product_id',
      sorter: (a, b) => a.product_id - b.product_id,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
    },
    {
      title: 'Camera Preset',
      dataIndex: 'preset_name',
      key: 'preset_name',
    },
    {
      title: 'Action',
      key: 'edit',
      render: (text, record) => (
        <div>
          <Link
            to={{
              pathname: `silo-tool/${record.product_id}`,
              search: `?preset=${record.preset_id}`,
              state: {
                bulkFlowData: {
                  'productID': record.product_id,
                  'presetID': record.preset_id,
                  'productName': record.product_name,
                  'customerNames': [record.customer_name],
                  'action_type': "update",
                },
              },
            }}
          > Edit Preset</Link>
          <React.Fragment>
              <Divider style={{backgroundColor: "#000000"}} type='vertical' />
              <span>
                <a onClick={()=> { setPresetToDelete(record); setDeleteModalVisible(true) }}>Delete</a>
                  <Modal
                    visible={deleteModalVisible}
                    className="silo-success-modal"
                    closable={true}
                    width={788}
                    onCancel={()=> { setDeleteModalVisible(false)}}
                    footer={[
                      <div className="justify-in-center" key="modalFooter">
                        <Button className="modal-okay-gray square font-14" onClick={()=> { setDeleteModalVisible(false)}}>
                          Cancel
                        </Button>
                        <Button className="modal-okay square font-14" onClick={()=>{ deletePreset(presetToDelete) }}>
                          Delete
                        </Button>
                      </div>
                    ]}
                  >
                    <div style={{ padding: "16px 6px 8px 14px" }}>
                      <h2 className="share-modal-heading" style={{ textAlign: "center", marginBottom: 8 }}>Are you sure you want to delete the preset?</h2>
                    </div>
                  </Modal>
              </span>  
          </React.Fragment>
        </div>
      ),
    },
  ];
  return (
    <div>
      {loadingState ? (
        <DottedLoader />
      ) : (
        <Space
          direction='vertical'
          size='middle'
          style={{
            display: 'flex',
            marginLeft: '10px',
          }}
        >
          <Space
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Space>
              <Text> Select Customer : </Text>
              <Select
                className='manrope f-12 black-55 tree-select-material'
                showSearch
                allowClear
                placeholder=' ----------- '
                optionFilterProp='children'
                value={selectedCustomer}
                onChange={handleSelectedCustomer}
                filterOption={filterOption}
                style={{ width: '200px', borderRadius: '8px' }}
                options={skylineCustomers.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </Space>

            <Button
              style={{ float: 'right', marginBottom: 10 }}
              className='modal-okay square font-14'
              onClick={() => {
                setProductPresetModal(true);
              }}
            >
              Create Product Preset
            </Button>
          </Space>

          <Modal
            title={
                'Create New Product Preset'
            }
            visible={productPresetModal}
            closable={true}
            maskClosable={true}
            onCancel={() => {
              setProductPresetModal(false);
              setModalProductID(null);
              setModalProductCustomerNames([]);
              setModalProductPresetID(null);
              setModalProductPresetName(null);
              setProductPresetModal(false);
            }}
            footer={null}
          >
            <Space
              direction='vertical'
              size='middle'
              style={{
                display: 'flex',
                marginLeft: '10px',
                marginTop: '10px',
              }}
            >
              <Space
                direction='vertical'
                size='small'
                style={{
                  display: 'flex',
                }}
              >
                <Text>Customer Name: </Text>
                <Select
                  className='manrope f-12 black-55 select-tags'
                  mode='tags'
                  showSearch
                  allowClear
                  placeholder=' ----------- '
                  optionFilterProp='children'
                  value={modalProductCustomerNames}
                  onChange={handleModalSelectedCustomer}
                  filterOption={filterOption}
                  style={{ width: '100%', borderRadius: '8px' }}
                  options={skylineCustomers.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Space>
              <Space
                direction='vertical'
                size='small'
                style={{
                  display: 'flex',
                }}
              >
                <Text>Product Name: </Text>
                <Select
                  className='manrope f-12 black-55 tree-select-material'
                  showSearch
                  allowClear
                  placeholder=' ----------- '
                  optionFilterProp='children'
                  value={
                    modalProductID
                  }
                  onChange={(e, option) => {
                    setModalProductID(e);
                    setModalProductName(option?.label);
                    handleFilterPresets(e, modalProductCustomerNames);
                  }}
                  filterOption={filterOption}
                  style={{ width: '100%', borderRadius: '8px' }}
                  options={filteredProducts.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  disabled={
                    modalProductCustomerNames.length === 0
                  }
                />
              </Space>

              <Space
                direction='vertical'
                style={{
                  display: 'flex',
                }}
              >
                <Text>Camera Angle Setting : </Text>
                <Radio.Group
                  disabled={
                    modalProductCustomerNames.length === 0 || !modalProductID
                  }
                  onChange={handleRadioChange}
                  value={radioValue}
                >
                  <Radio
                    style={{ marginLeft: '20px', fontWeight: 'normal' }}
                    value='new_preset'
                  >
                    Add New Preset
                  </Radio>
                  <Radio
                    style={{ marginLeft: '20px', fontWeight: 'normal' }}
                    value='existing_preset'
                  >
                    Add From Existing Preset
                  </Radio>
                </Radio.Group>
              </Space>
              <Card
                style={{
                  width: 'fit-content',
                  marginLeft: '20px',
                  padding: '10px',
                }}
              >
                {radioValue == 'new_preset' ? (
                  <Space>
                    <Link
                      to={{
                        pathname: `silo-tool/${modalProductID}`,
                        state: {
                          bulkFlowData: {
                            'productID': modalProductID,
                            'productName': modalProductName,
                            'customerNames': modalProductCustomerNames,
                            'action_type': "create",
                          },
                        },
                      }}
                    >
                      <Button
                        className='modal-okay square font-12'
                        disabled={
                          modalProductCustomerNames.length === 0 ||
                          !modalProductID
                        }
                      >
                        Add New Preset
                      </Button>
                    </Link>
                  </Space>
                ) : (
                  <Space
                    direction='vertical'
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Text>Existing Presets <Tooltip title={<span className='manrope f-12 white'> User can use existing preset or can create a new preset from existing preset.</span>}><InfoCircleOutlined/></Tooltip> </Text>
                    <Space>
                      <Select
                        className='manrope f-12 black-55 tree-select-material'
                        showSearch
                        allowClear
                        placeholder=' ----------- '
                        optionFilterProp='children'
                        value={modalProductPresetID}
                        onChange={(e, option) => {
                          setModalProductPresetID(e);
                          setModalProductPresetName(option?.label);
                        }}
                        filterOption={filterOption}
                        disabled={
                          modalProductCustomerNames.length === 0 ||
                          !modalProductID
                        }
                        style={{
                          minWidth: '200px',
                          maxWidth: '300px',
                          borderRadius: '8px',
                        }}
                      >
                        {productPresetsData.current.map((item) => (
                          <Option
                            className='manrope f-12 select-view'
                            key={item.preset_id}
                            value={item.preset_id}
                            label={item.product_name + " | " + item.customer_name}
                          >
                            {item.product_name + " | " + item.customer_name}
                          </Option>
                        ))}
                      </Select>
                      <Link
                        to={{
                          pathname: `silo-tool/${modalProductID}`,
                          search: `?preset=${modalProductPresetID}`,
                          state: {
                            bulkFlowData: {
                              'productID': modalProductID,
                              'productName': modalProductName,
                              'customerNames': modalProductCustomerNames,
                              'action_type': "create",
                            },
                          },
                        }}
                      >
                        <Button
                          className='modal-okay square font-12'
                          disabled={
                            modalProductCustomerNames.length === 0 ||
                            !modalProductPresetID
                          }
                        >
                          Create Preset
                        </Button>
                      </Link>
                    </Space>
                  </Space>
                )}
              </Card>
            </Space>
          </Modal>

          <Table
            dataSource={filteredProductPresets}
            columns={columns}
            bordered={true}
          ></Table>
        </Space>
      )}
    </div>
  );
};

export default ProductPresetManagement;
