import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import Constants from '../../Constants.js'
import ENVIRONMENT from "../../../environments/";

export default class AssetLoadingManager {

	_baseURL 		= "";
	_dracoLoader 	= null;
	_loader 		= null;
	_collection		= {};
	_onLoadAction	= null;

	debugEngine = null;
	texturesList = {};

    /**
     * Asset Loading Manager allows you to manage loading of assets in the scene
     * @param {URL} baseURL- The base url to download the assets glb from.
	 * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
	constructor ( baseURL, managersDict ) {

		this._baseURL	= baseURL;
		this._loader 	= new GLTFLoader();
		this.texLoader = new THREE.TextureLoader();
		
		this._dracoLoader = new DRACOLoader();
		this._dracoLoader.setDecoderPath( '/js/libs/draco/gltf/' );
        this._loader.setDRACOLoader( this._dracoLoader );

		this.debugEngine = managersDict[Constants.Manager.DebugEngine];
	}

	/**
	 * Converts a loader.load request into a promise
	 * @param {URL} assetURL  - The url to download the asset from
	 * @param {String} assetName - The name/id of the asset
	 * @param {Number} index 	- The index/count of the asset
	 */
	promisfyLoader(assetURL, assetName, index)
	{
		return new Promise((resolve,reject) => {
			this._loader.load( assetURL, ( data ) => resolve(data), 
				( xhr ) => {
					this.dispatchEvent( { type: "loadingProgress", assetType: "sceneAsset", assetName: assetName , value: Math.round( ( xhr.loaded / xhr.total ) * 100 ) } );
				},
				( error ) => {
					reject(assetName);
					this.dispatchEvent( {
						type: "loadingProgress",
						assetType: "collection",
						value: Math.round( ( ( index + 1) / this._collectionIDs.length ) * 100 ),
						assetsLoaded: ( index + 1 ),
						totalAssets: this._collectionIDs.length
					} );
				} )
		});
	}


	/**
	 * Load the assets collection
	 * @param {Array} collectionIDs - Array containing asset names/ids
	 * @param {Function} onLoadAction - Call back function post collection loading
	 * @param {Function} onAssetLoad - Call back function to place loaded asset in the scene
	 */
	loadCollection ( collectionIDs, onLoadAction, onAssetLoad = null ) {

		this._collectionIDs = collectionIDs;
		this._onLoadAction	= onLoadAction;
		this._onAssetLoad = onAssetLoad;
		var scope = this;
		let loadPromises = [];

		for (var i = 0 ; i < this._collectionIDs.length; i++) {
			let index = i;
			let assetName	= this._collectionIDs[index];
			if( this._collection[ assetName ] == undefined ) {

				let assetURL 	= ENVIRONMENT.getBaseURL(window.productPlatform[assetName]) + ENVIRONMENT.ASSET_GLB_URI + "low/" + assetName + ".glb";
                loadPromises.push(this.promisfyLoader(assetURL, assetName, index).then(
					data=>{
						{
							let object	= data.scene;
							object.name = assetName;
							this._collection[assetName] = object;
							this.dispatchEvent( {
								type: "loadingProgress",
								assetType: "collection",
								value: Math.round( ( ( index + 1) / this._collectionIDs.length ) * 100 ),
								assetsLoaded: ( index + 1 ),
								totalAssets: this._collectionIDs.length
							} );

							if (scope._onAssetLoad != null) {
								scope._onAssetLoad(index);
							}

						}
					},
					data=>{
						{
							this.debugEngine.debugLog("promise failed for: ", data);
						}
					}
					)
				);

			}
		}

		Promise.all(loadPromises).then(()=>{
		
			this.debugEngine.debugLog("All Done");
			this._onLoadAction();
		});
	}

	/**
	 * Load next asset in the list
	 * @param {Number} index - The count/index number of the next asset to be loaded
	 */
	loadNextAsset ( index ) {

		if ( index < this._collectionIDs.length ) {

			let assetName	= this._collectionIDs[index];

			if( this._collection[ assetName ] == undefined ) {

				let assetURL 	= this._baseURL + assetName + ".glb";

				this._loader.load( assetURL, ( data ) => {

					let object	= data.scene;
					object.name = assetName;
					this._collection[assetName] = object;
					this.dispatchEvent( {
						type: "loadingProgress",
						assetType: "collection",
						value: Math.round( ( ( index + 1) / this._collectionIDs.length ) * 100 ),
						assetsLoaded: ( index + 1 ),
						totalAssets: this._collectionIDs.length
					} );
					this.loadNextAsset( index + 1 );
				},
				( xhr ) => {
					this.dispatchEvent( { type: "loadingProgress", assetType: "sceneAsset", assetName: assetName , value: Math.round( ( xhr.loaded / xhr.total ) * 100 ) } );
				},
				( error ) => {
					this.loadNextAsset( index + 1 );
					this.dispatchEvent( {
						type: "loadingProgress",
						assetType: "collection",
						value: Math.round( ( ( index + 1) / this._collectionIDs.length ) * 100 ),
						assetsLoaded: ( index + 1 ),
						totalAssets: this._collectionIDs.length
					} );
				} );

			}

			else {

				this.loadNextAsset( index + 1 );
				this.dispatchEvent( {
					type: "loadingProgress",
					assetType: "collection",
					value: Math.round( ( ( index + 1) / this._collectionIDs.length ) * 100 ),
					assetsLoaded: ( index + 1 ),
					totalAssets: this._collectionIDs.length
				} );

			}

		}

		else {
			this._onLoadAction();
		}

	}


	/**
	 * Load a single asset from the collection
	 * @param {String} assetID - The id of the asset to be loaded
	 * @param {Function} onLoadAction - Callback function to be invoked post asset loading
	 */
	loadAsset ( assetID, onLoadAction ) {

		let action = onLoadAction;
		let assetURL 	= this._baseURL + assetID + ".glb";

		if( this._collection[ assetID ] == undefined ) {

			this._loader.load( assetURL, ( data ) => {

				let object = data.scene;
				object.name = assetID;
				this._collection[ assetID ] = object;
				action();
			},
			( xhr ) => {
				this.dispatchEvent( { type: "loadingProgress", assetType: "sceneAsset", assetName: assetID , value: Math.round( ( xhr.loaded / xhr.total ) * 100 ) } );
			},
			( error ) => {
				this.debugEngine.debugLog( error );
			} );
		}
		else {
			action();
		}
	}

	/**
	 * Load space glb 
	 * @param {URL} assetURL - The url to download the space from 
	 * @param {Function} onLoadAction 
	 */
	loadSpaceAsset ( assetURL, onLoadAction ) {

		let action = onLoadAction;

		this._loader.load( assetURL, ( data ) => {

			let object = data.scene;
			object.name = "Space";
			action( object );

		},
	 	( xhr ) => {
	 		this.dispatchEvent( { type: "loadingProgress", assetType: "space" , value: Math.round( ( xhr.loaded / xhr.total ) * 100 ) } );
	 	},
		( error ) => {

			this.debugEngine.debugLog( error );
			action( null );

		} );
	}

	/**
	 * Load texture asset
	 * @param {URL} textureURL - The url to load the texture from
	 * @param {*} onTextureLoaded - callback to function once texture is loaded
	 */
	loadTexture( textureURL, onTextureLoaded) {
		if (this.texturesList[textureURL]) {
			if (onTextureLoaded != null) {
				let textureData = this.texturesList[textureURL].clone();
                textureData.needsUpdate = true;
				onTextureLoaded(textureURL, textureData);
			}
		}
		else {
			this.texLoader.load(textureURL, (texture) => {
				this.texturesList[textureURL] = texture.clone();
                this.texturesList[textureURL].needsUpdate = true;	
				if (onTextureLoaded != null) {
					onTextureLoaded(textureURL, texture);
				}
			}) 
		}
	}

	/**
	 * Retrieve asset against provided id
	 * @param {String} assetName - The asset to be retreived
	 */
	getAsset ( assetName ) {

		return this._collection[ assetName ];

	}

	/**
	 * Remove asset from the collection against the given id/name
	 * @param {String} assetName - The name of the asset to be removed
	 */
	removeAssetFromCollection( assetName ) {
		delete this._collection[assetName];
	}

	/**
	 * Attach event listener to asset loading manager for logging loading progress
	 * @param {Event} type - The type of event
	 * @param {Function} listener - The listener callback function
	 */
	addEventListener( type, listener ) {

		if ( this._listeners === undefined ) { this._listeners = {}; }

		var listeners = this._listeners;

		if ( listeners[ type ] === undefined ) {

			listeners[ type ] = [];

		}

		if ( listeners[ type ].indexOf( listener ) === - 1 ) {

			listeners[ type ].push( listener );

		}

	}

	/**
	 * Check if the event listener is already attached against the specific event
	 * @param {Event} type 
	 * @param {Function} listener 
	 */
	hasEventListener( type, listener ) {

		if ( this._listeners === undefined ) { return false; }

		var listeners = this._listeners;

		return listeners[ type ] !== undefined && listeners[ type ].indexOf( listener ) !== - 1;

	}

	/**
	 * Remove the listener against the specific event
	 * @param {Event} type 
	 * @param {Function} listener 
	 */
	removeEventListener( type, listener ) {

		if ( this._listeners === undefined ) { return; }

		var listeners = this._listeners;
		var listenerArray = listeners[ type ];

		if ( listenerArray !== undefined ) {

			var index = listenerArray.indexOf( listener );

			if ( index !== - 1 ) {

				listenerArray.splice( index, 1 );

			}

		}

	}

	/**
	 * Invoke action against specific event
	 * @param {Event} event
	 */
	dispatchEvent( event ) {

		if ( this._listeners === undefined ) { return; }

		var listeners = this._listeners;
		var listenerArray = listeners[ event.type ];

		if ( listenerArray !== undefined ) {

			event.target = this;

			var array = listenerArray.slice( 0 );

			for ( var i = 0, l = array.length; i < l; i ++ ) {

				array[ i ].call( this, event );

			}

		}

	}
}
