import React, { useEffect, useState } from 'react';
import ENVIRONMENT from '../../../../environments';
import './styles.css'
import {
    Row,
    Col,
    Button,
    Modal,
    Upload,
    Menu,
    Dropdown,
    Select,
    Collapse,
    Image,
    Card,
    Tabs,
    Input,
    Checkbox,
    Typography,
    List,
    Radio,
    Carousel,
    TreeSelect,
    Tooltip,
    message
} from 'antd';
import {
    RightOutlined,
    LeftOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    DownOutlined,
    LoadingOutlined,
    EyeOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import AdminMainLayout from '../AdminMainLayout';
import ProductDetailsComponent from '../../ProductDetailsComponent'
import ProductStaticGuidelinesCard from '../../../App/ArtistComponents/ProductStaticGuidelines/ProductStaticGuidelinesCard';
import { Link } from "react-router-dom";
import ReviewProduct from './ReviewProduct';
import ImageOrdering from '../ImageOrdering';
import axios from 'axios';
import * as Constants from "../../CustomerComponents/Constants";
import QuickRenders from '../../ArtistComponents/QuickRendersComponent';
import { ReactComponent as EmptyBox } from '../../../../icons/empty-box.svg'
import { ReactComponent as SmallEmptyBox } from '../../../../icons/small-empty-box.svg'
import ClayModel from '../../ClayModel';
import DottedLoader from '../../DottedLoader';
import SuccessModal from '../../SuccessModal/SuccessModal';
import FileConstants from '../../../../FileConstants';
import GroupItemsModal from '../../GroupItemsModal/GroupItemsModal';
import VariationBaseModelDetails from '../../ArtistComponents/VariationBaseModelDetails';
import * as Utilites from '../../Utilities';
import WarningModal from '../../WarningModal/WarningModal';

const { Paragraph } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

const treeSelectStyle = {
    maxHeight: 400,
    overflow: 'auto',
    fontFamily: 'Manrope',
    fontSize: 12,
    color: "#555555"
  }

const ProductDetailsView = (props) => {

    const { getFieldDecorator } = props.form;
    const [showDetailedRejection, setShowDetailedRejection] = useState(false);
    const [lightJobOutput, setLightJobOutput] = useState('test_render');
    const [orderedImages, setOrderedImages] = useState([]);
    const [optedForQARender, setOptedForQARenders] = useState(false);
    const [productImagesShared, setProductImagesShared] = useState(false);
    const [approvingProduct, setApprovingProduct] = useState(false);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [shareImagesModal, setShareImagesModal] = useState(false);
    const [groupItemsModal, setGroupItemModal] = useState(false);
    const [approveToCustomerLibrary, setApproveToCustomerLibrary] = useState(false)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const validStatusMoveAdminQA = [5, 4, -5, -4, 8, 11, 12];
    const [showARWarning, setShowARWarning] = useState(false);

    useEffect(() => {
        if  (props.productDetails.model_status == 5) {
            if ((props.productDetails.variant_of != '' || props.productDetails.variant_of != undefined)
                && props.productDetails.variation_type === 'ar_conversion' &&
                props.productDetails.need_to_model === 'ar' &&
                props.productDetails.ar_type === 'product_ar_integration'
            ) {
                setShowARWarning(true);
            }
        }
    }, [props.productDetails])

    const showRejectionModal = () => {
        setShowDetailedRejection(true);
    };


    const handleCancel = () => {
        setShowDetailedRejection(false);
    };

    const updateNominatedMaterials = (updatedListOfNominatedMaterials) => {

        props.setNominatedMaterials(updatedListOfNominatedMaterials);

        console.log(props.nominableMaterials)
        console.log(updatedListOfNominatedMaterials)
    }

    const showShareImagesModal = () => {
        setShareImagesModal(true);
        props.setShowNominateMaterialsModal(false);
    }

    const approveProduct = (e) => {
        setApprovingProduct(true);
        let nominate_materials_payload = [];
        for (let i = 0; i < props.nominatedMaterials.length; i++) {
            const element = props.nominatedMaterials[i];
            let payloadItem = {
            product_id: props.params.id,
            material_name: element,
            admin_selected: true
            }

            nominate_materials_payload.push(payloadItem);
        }

        axios.post(ENVIRONMENT.PRODUCT_MATERIAL_EXPORT_INFO_UPDATE_BATCH, nominate_materials_payload).then(res => {
            console.log("product material export info update batch result", res.status);
        });

        let ordered_rig_images = [];
        orderedImages.map((img) => {
            if (img.selected && !img.src.split('/').slice(-1)[0].includes('Dimensions'))
                ordered_rig_images.push(img.src.split('/').slice(-1)[0]);
        })

        // Approve the product
        // perspectiveRenders will have the selected renders in specific order decided by Admin on approval
         let update_payload = {
            product_id: props.params.id,
            model_status: "4",
            username: localStorage.getItem('username'),
            perspective_renders: ordered_rig_images,
            materials_to_extract: props.nominatedMaterials
        }
        // Approve and add to customer's library option was used
        if (approveToCustomerLibrary) {
            update_payload['model_status'] = "5"
        }

        if (props.productDetails.requested_for && props.productDetails.requested_for != "") {
            // Product is created by an MSP and needs to be transferred to the customer
            update_payload.customer_username = props.productDetails.requested_for
            axios.post(ENVIRONMENT.MSP_UPDATE_PRODUCT, update_payload)
            .then(res => {
                setShareImagesModal(false);
                setProductImagesShared(true);
            })
        } else {
            axios.post(ENVIRONMENT.UPDATE_PRODUCT, update_payload)
            .then(res => {
                setShareImagesModal(false);
                setProductImagesShared(true);
            })
        }
    }

    const download_links_menu = (
        <Menu style={{ width: "190px" }}>
            <Menu.Item>
                <a className='manrope f-12' target="_blank" href={ENVIRONMENT.getBaseURL(props.productReducer.product['platform']) + ENVIRONMENT.LOW_POLY_MAX_URI + props.params.id + '.zip' } > MAX Download Link <DownloadOutlined style={{ float: "right" }} /></a>
            </Menu.Item>
        </Menu>
    )

    let menu = null;
    if([3, -3, -4, -5].includes(parseInt(props.productReducer.product.model_status))){
        menu = (
            <Menu style={{ width: "145px",fontFamily:"Avenir" }}>
                <Menu.Item key={1} onClick = {() => props.setRenderOverrideModal(true)}>
                    <span style={{cursor: "pointer"}}> Preview Renders</span>
                </Menu.Item>
                <Menu.Item key={2} onClick = {() => props.setProductModelOverrideMadal(true)}>
                    <span style={{cursor: "pointer"}}> Model Override</span>
                </Menu.Item>
                <Menu.Item key={3} onClick = {() => props.setProductCustomLightingMadal(true)}>
                    <span style={{cursor: "pointer"}}> Lighting</span>
                </Menu.Item>
                <Menu.Item key={4} onClick = {() => props.setProductRegenerateConfirm(true)}>
                    <span style={{cursor: "pointer"}}> Regenerate</span>
                </Menu.Item>
                {validStatusMoveAdminQA.includes(parseInt(props.productReducer.product.model_status)) &&
                <Menu.Item key={5}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.sendProductToAdminQA()}>Move to Admin QA</span>
                </Menu.Item>
                }
            </Menu>
        );
    }
    else if(validStatusMoveAdminQA.includes(parseInt(props.productReducer.product.model_status))){
        menu = (
            <Menu style={{ width: "150px",fontFamily:"Avenir" }}>
                <Menu.Item key={1}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.sendProductToAdminQA()}>Move to Admin QA</span>
                </Menu.Item>
            </Menu>
        );
    }

    const getActorUsername = (item) => {
        let result = "";
        if(item.username != ""){
            result = item.username;
        }
        else if(item.username == ""){
            // No username readily available, figure out who performed the action.

            // CASE: Artist assigned a product to himself
            if(item.attribute_name == "assigned_artist" && item.after_value != ""){
                result = item.after_value;
            }
        }
        return result;
    }

    const getItemHistoryAction = (item) => {

        let result = ""
        // model was deleted by artist
        if(item.additional_message == "model_delete_request"){
            result = "Model was Deleted by Artist"
        }

        // admin model status change
        else if(item.additional_message == "admin_status_change" && item.after_value == "3"){
            result = "Model was moved to Admin QA"
        }

        // Handle Rejection cases
        else if(item.additional_message == "rejected_by_customer"){
            result = "Model was Rejected by Customer"
        }
        else if(item.additional_message == "rejected_by_admin"){
            result = "Model was Rejected by Admin"
        }
        // Messages for customer requests i.e. silo, mp4, threesixty, dimension render
        else if(item.additional_message == "mp4_generated"){
            result = "MP4 Video was generated by the System"
        }
        else if(item.additional_message == "mp4_request"){
            result = "MP4 Video was requested by the Customer"
        }
        else if(item.additional_message == "dim_image_generated"){
            result = "Dimensional Image was generated by the System"
        }
        else if(item.additional_message == "dim_image_requested"){
            result = "Dimensional Image was requested by the Customer"
        }
        else if(item.additional_message == "silo_images_generated"){
            result = "Silo Image(s) were generated by the System"
        }
        else if(item.additional_message == "silo_images_request"){
            result = "Silo Image(s) were requested by the Customer"
        }
        else if(item.additional_message == "threesixty_generated"){
            result = "360 Spin was generated by the System"
        }
        else if(item.additional_message == "threesixty_request"){
            result = "360 Spin was requested by the Customer"
        }
        else if(item.additional_message == "perspective_renders_generated"){
            result = "Perspective Renders were Generated by the System"
        }
        else if(item.additional_message == "auto_unassign"){
            result = "Model was Auto Unassigned By System"
        }
        else if(item.additional_message == "transfer_msp_product") {
            result = `Model was moved from MSP(${item.before_value}) to customer`;
        }
        // Handle cases for model approval and rejection denied
        else if(item.attribute_name && item.attribute_name=="model_status" && item.username != "System"){
            if(item.before_value == "3" && item.after_value == "4"){
                result = "Model was Approved by Admin"
            }
            else if(item.before_value == "3" && item.after_value == "5"){
                result = "Model was Approved and Added to Customer's Library by Admin"
            }
            else if(item.before_value == "4" && item.after_value == "5"){
                result = "Model was Approved by Customer"
            }
            else if(item.before_value == "-5" || item.before_value == "-4" && item.after_value == "4"){
                result = "Customer rejection was denied by Admin"
            }
            else if(item.after_value == "6" && item.additional_message=="quick_render_request"){
                result = "Artist Uploaded the model for Validation and Quick Renders"
            }
            else if(item.after_value == "6" && item.additional_message=="model_submission_request"){
                result = "Artist submitted the model and Preview Renders were requested."
            }
            else if(item.after_value == "11"){
                result = "Model's automation job failed."
            }
            else if(item.after_value == "12"){
                result = "Model's automation job aborted."
            }
        }
        // Return appropriate admin override action
        else if(item.attribute_name == "" && item.admin_override_action != ""){
            if(item.admin_override_action == "perspective_renders_override"){
                result = "Admin Override was used to Replace the Preview Renders"
            }
            else if(item.admin_override_action == "product_custom_rig"){
                result = "Admin Override was used to generate Preview Renders with a custom lighting rig"
            }
            else if(item.admin_override_action == "regenerate_qa_assets"){
                result = "Admin Override was used to Regenerate QA Assets"
            }
            else if(item.admin_override_action == "model_override"){
                result = "Admin Override was used to Model Override"
            }
        }
        // Return Assigned to artist or unasssigned from artist
        else if(item.attribute_name && item.attribute_name == "assigned_artist"){
            if(item.before_value == "" && item.after_value != ""){
                if(item.username != ""){
                    result = "Model was assigned to Artist by Admin"
                }
                else{
                    result = "Model was self assigned by Artist"
                }
            }
            else if(item.before_value != "" && item.after_value == ""){
                if(item.username != ""){
                    result = "Model was unassigned by Admin"
                }
            }
            else if(item.before_value != "" && item.after_value != ""){
                if(item.username != ""){
                    result = "Admin assigned the model to Another artist"
                }
            }
        }
        // Track the system actions
        else if(item.attribute_name && item.attribute_name == "model_status" && item.username == "System"){
            if(item.before_value == "6" && item.after_value == "8"){
                if(item.additional_message == "quick_renders_generated")
                    result = "Model was Valid and Quick Renders were generated."
                if(item.additional_message == "quick_renders_not_generated")
                    result = "Model was Invalid and returned to the artist."
            }
            else if(item.before_value == "6" && item.after_value == "3"){
                result = "System generated the Perspective Renders and moved the Product to QA Pending"
            }
            else if(item.before_value == "6" && item.after_value == "4"){
                result = "System generated the Perspective Renders and moved the Product to Customer QA"
            }
        }

        else if(item.attribute_name == "model_retries" && item.after_value == "0"){
            result = "Model retries were reset by Admin"
        }
        return result
    };


    // in this function, additional_attachment data will be passed as ele
    const getRejectionDetailsForActivityModel = (ele) => {
        return (
            <div style={{marginTop: 20}}>
            {ele != "" && ele.rejection_details != "" ?
                <span style={{paddingRight: 5}}>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7, paddingLeft: 5}}>
                    Other Information
                </span>
                <span>
                <span className="category-artist" style={{marginBottom: 30, fontSize: 17, paddingLeft: 5, color: '#555555'}}>
                    {ele.rejection_details}
                </span>
                </span>
            </span> : ""}

      {( ele != "" && ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

          <span>
          <div className="category-artist" style={{textAlign: 'left', marginBottom: 7, marginTop: 20, paddingLeft: 5}}>
          Uploads / Files
          </div>
          <div style={{display: 'flex', flexWrap: 'wrap', paddingLeft: 5}}>
            {ele.reference_files && ele.reference_files.map((file,index) => (
                <a className="upload-a-settings" style={{display: 'flex'}} target="_blank" href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}/> :
                (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                <div className="category-artist" style={{fontSize: 14, textAlign: 'left', marginRight: 10, color: '#555555'}}>{file.name}</div>
                </a>
            ))}
          </div>

      </span>: ''}
    </div>
        )
    }

    // This function will return details of activities like, mp4, silo and dimensional image
    const getAdditionalDetails = (item) => {

        return (
            <div>
                { item.additional_message == "silo_images_request" || item.additional_message == "silo_images_generated" ?
                    <div style={{display:'flex'}}>
                        <div style={{paddingTop: 10, paddingLeft: 30}}>No. of Silo Images: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.length : ''}</span></div>
                        <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.rig}</span></div>
                    </div>
                    : ""
                }

                {
                item.additional_message == "dim_image_requested" || item.additional_message == "dim_image_generated" ?
                    <div style={{display: "flex"}}>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Image Resolution: <span style={{color:"#333333", paddingLeft: 5}}> { item.additional_attachment.additional_data? item.additional_attachment.additional_data.resolution : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Image DPI: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data ? item.additional_attachment.additional_data.dpi : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.rig}</span></div>
                    </div> : ""
                }

                { item.additional_message == "mp4_request" || item.additional_message == "mp4_generated" ?
                    <div style={{display: "flex"}}>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>MP4 Resolution: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.resolution : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Frame Count: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.frame_count:''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Frame Rate: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.frame_rate :''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.rig :''}</span></div>
                    </div> : ""
                }

                { item.additional_message == "threesixty_request" || item.additional_message == "threesixty_generated" ?
                    <div style={{display: "flex"}}>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>360 Spin Resolution: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data?  parseInt(item.additional_attachment.additional_data.frame_resolution) :'' }K</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Frame Count: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data?  item.additional_attachment.additional_data.frame_count : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data?  item.additional_attachment.rig : ''}</span></div>
                    </div> : ""
                }
                { item.additional_message =="perspective_renders_generated" && item.additional_attachment.rig?
                    <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.rig}</span></div>
                    :''
                }
            </div>
        )
    }

    const activity_sort_menu = (
        <Menu style={{ width: "205px",fontFamily:"Avenir" }}>
            <Menu.Item key={1}>
                <span style={{cursor: "pointer"}} onClick = {() => props.sortActivity(true)}>Oldest Activity First</span>
            </Menu.Item>
            <Menu.Item key={2}>
                <span style={{cursor: "pointer"}} onClick = {() => props.sortActivity(false)}>Most Recent Activity First</span>
            </Menu.Item>
        </Menu>
    );

    const formateDate = (dateTime) => {
        let date = dateTime.split(' ')[0];
        date = new Date(date);
        date = date.toLocaleDateString('US', {day: 'numeric', month: 'long', year: 'numeric'})
        return date
    }


    const handleImage = (event) => {
        let href = event.currentTarget.getAttribute('href');
        event.preventDefault();
        if(href.includes('.jpg') || href.includes('.png') || href.includes('.jpeg') || href.includes('.JPG') || href.includes('.PNG') || href.includes('.JPEG')){
            setImageUrl(event.currentTarget.getAttribute('href'));
            setShowImage(true);
        }
        else{
            window.open(href)
        }
    }

    let product_text_guidelines = '';
    if (props.productDetails.guidelines != undefined && props.productDetails.guidelines != '') {
        product_text_guidelines = props.productDetails.guidelines.split('\n').map((item, i) => {
            if(item != "")
                return <li><div className="category-artist medium align-left" key={i}>{item}</div></li>;
        });
    }
    let dimensions_text = '';
    if (props.productDetails.dimensions_text != undefined && props.productDetails.dimensions_text != '') {
        dimensions_text = (
            <li>
                <div className="category-artist medium align-left">
                    <span>{props.productDetails.dimensions_text}</span>
                </div>
            </li>)
        }

    let product_references = ''
    if (props.productDetails.reference_urls != undefined && props.productDetails.reference_urls.length > 0) {
        product_references = props.productDetails.reference_urls.map((ele, index) => {
            return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
            <span className="note-text gray urls"><a href={ele} target='_blank' style={{color: "#276DD7"}}>{ele}</a></span>
            </div>
        });
    }

    let product_manuals = ''
    if(props.productDetails.product_manuals != undefined){
            product_manuals = props.productDetails.product_manuals.map((ele, index) => {
            let file_url = ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((ele.originFileObj ? ele.originFileObj.uid : ele.uid) + '/' + ele.name);
            return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
            <span className="note-text gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
            </div>
        });
    }

    let product_lighting_rig = '';
    if(props.productLightingRig.rig_file){
        product_lighting_rig = <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
        <span className="note-text gray urls"><a href={props.productLightingRig.rig_file} target='_blank' style={{color: "#276DD7"}}>{props.productLightingRig.rig_file}</a></span>
        </div>
    }

    let product_raw_files = '';
    if(props.productDetails.raw_files != undefined){
        product_raw_files = props.productDetails.raw_files.map((ele, index) => {
        let file_url = ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
        return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
        <span className="note-text gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
        </div>
    });
}

    let display_dimension_table = true;

    let model_height = "N/A", model_depth = "N/A", model_width = "N/A";
    if(props.productReducer.product.model_info){
        // If model_info has low poly info
        if(props.productReducer.product.model_info.low){
            model_height = props.productReducer.product.model_info.low.height || "N/A";
            model_depth = props.productReducer.product.model_info.low.depth || "N/A";
            model_width = props.productReducer.product.model_info.low.width || "N/A";
        }
        else if(props.productReducer.product.model_info.high){
            // if model_info has high poly info
            model_height = props.productReducer.product.model_info.high.height || "N/A";
            model_depth = props.productReducer.product.model_info.high.depth || "N/A";
            model_width = props.productReducer.product.model_info.high.width || "N/A";
        }
        else {
            // for backward compatibility, where model_info only contains model_dimenions and vertex count
            model_height = props.productReducer.product.model_info.height || "N/A";
            model_depth = props.productReducer.product.model_info.depth || "N/A";
            model_width = props.productReducer.product.model_info.width || "N/A";
        }
    }

    if (model_depth == "N/A", model_height == "N/A", model_width == "N/A"){
        display_dimension_table = false;
    }
    let material_files_urls = []
    if(props.productDetails.material_files && props.productDetails.material_files != []){

        let material_files_array = (props.productDetails.material_files || []);
        if(!Array.isArray(props.productDetails.material_files))
        {
            if(props.productDetails.material_files != undefined){
                if('componentImages' in props.productDetails.material_files){
                    material_files_array = props.productDetails.material_files['componentImages'];
                }
            }
        }

        material_files_urls = material_files_array.map((ele, index) => {
            return ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
        });
    }

    const information_not_available = (
        <Col style={{display: 'flex', padding: '30px 0px 10px 10px', alignItems: 'center'}}>
            <SmallEmptyBox />
            <div>
                <span style={{fontSize: 16, color: '#555555', paddingLeft: 20}}>No Information Found</span>
            </div>
        </Col>
    )

    let high_poly_vertex = null, low_poly_vertex = null;
    if(props.productReducer.product.model_info){
        if(props.productReducer.product.model_info.low){
            low_poly_vertex = props.productReducer.product.model_info.low.vertex_count;
        }
        else if(props.productReducer.product.need_to_model == 'ar'){
            // for backward compatibility
            low_poly_vertex = props.productReducer.product.model_info.vertex_count;
        }

        if(props.productReducer.product.model_info.high){
            high_poly_vertex = props.productReducer.product.model_info.high.vertex_count;
        }
        else if(props.productReducer.product.need_to_model != 'ar'){
            // for backward compatibility
            high_poly_vertex = props.productReducer.product.model_info.vertex_count;
        }
    }
    
    let high_poly_triangles = null, low_poly_triangles = null;
    if(props.productReducer.product.metadata && !Array.isArray(props.productReducer.product.metadata)){
        let metadata = (JSON.parse(props.productReducer.product.metadata)).metadata
        high_poly_triangles = metadata?.high?.triangle_count;
        low_poly_triangles = metadata?.low?.triangle_count;
    }

    let product_photos = []
    if(props.productDetails.product_photos){
        Object.keys(props.productDetails.product_photos).forEach((key) => {
            let photos_array = props.productDetails.product_photos[key];
            if(!Array.isArray(photos_array))
            {
                if(key in photos_array){
                    photos_array = props.productDetails.product_photos[key][key];
                }
                else{
                    photos_array = [];
                }
            }
            let product_photos_subset = (photos_array || []).map((ele, index) => {
                let file_url = ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                return <Col>
                <Card className="product-artist-card">
                {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                <Image
                className="product-photo-artist" src={file_url}
                />
                  :
                <a href={file_url} target='blank'>
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                  <img
                  className="product-photo-artist" src={'/img/file-icon.png'}
                  />
                  </Tooltip>
                </a>    }
                </Card>
            </Col>
            });
            if (key == 'product_photos_all')
            {
                product_photos.unshift(product_photos_subset);
            }
            else{
                product_photos.push(product_photos_subset);
            }

        });
        if (props.productDetails && props.productDetails.product_photos && Array.isArray(props.productDetails.product_photos) && product_photos && product_photos[0] && product_photos[0].length == 0) {
            product_photos = []
            props.productDetails.product_photos.map((ele) => {
                let file_url = ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                product_photos.push(<Col>
                <Card className="product-artist-card">
                {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                <Image
                className="product-photo-artist" src={file_url}
                />
                  :
                <a href={file_url} target='blank'>
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                  <img
                  className="product-photo-artist" src={'/img/file-icon.png'}
                  />
                  </Tooltip>
                </a>    }
                </Card>
            </Col>)
            });
        }
    }

    let category_attachment = []
    if (props.productDetails.category_attachment && props.productDetails.category_attachment.length > 0) {
        props.productDetails.category_attachment.map((image,index) => {
            let file_url = ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
                category_attachment.push(<Col>
                <Card className="product-artist-card">
                {image.name.toLowerCase().includes('jpg') || image.name.toLowerCase().includes('jpeg') || image.name.toLowerCase().includes('png') || image.name.toLowerCase().includes('svg') ?
                <Image
                className="product-photo-artist" src={file_url}
                />
                  :
                <a href={file_url} target='blank'>
                  <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                  <img
                  className="product-photo-artist" src={'/img/file-icon.png'}
                  />
                  </Tooltip>
                </a>    }
                </Card>
            </Col>)
        })
    }

    let rejections_data = '';
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
        let ele = props.rejectionDetails[0];
      rejections_data = (
        <div style={{marginTop: 20}}>
          <div className='artist-upload-txt small align-text'>Date <span style={{color:"#276DD7"}}>{Utilites.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span></div>
          {ele.feedback_link ? <div className='artist-upload-txt small align-text'>Feedback <a href={ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.PRODUCT_FEEDBACK_URI + props.params.id + "/"  + ele.feedback_link + '.pdf'} target='_blank' style={{color:"#276DD7"}}>{ele.feedback_link + '.pdf'}</a></div> : ''}
              {ele.feedback_link ? <div style={{ marginTop: 4, marginBottom: 4, display: 'block' }}>
                  <div className="category-artist text-left" style={{ textAlign: 'left' }}>
                      Feedback Images
                  </div>
                  <div className='justify-in-start wrap'>
                      {props.feedbackImages && props.feedbackImages.map((file, index) => (
                          <Image className="image-setting-feedback" src={file.url} />
                      ))}
                  </div>
              </div> : ''}
          <div style={{display: "flex"}}>
              <Col span={(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ? 16 : 24}>
              <span style={{paddingRight: 5, overflow: 'hidden'}}>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7}}>
                    Comment
                </span>
                <span style={{display: 'flex'}}>
                <span className="category-artist" style={{marginBottom: 30, fontSize: 16, width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left',color:"#276DD7"}}>
                    {ele.rejection_details}
                </span>
                </span>
              </span>
              </Col>


            {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

                    <Col span={8}>
                        <div className="category-artist" style={{textAlign: 'left', marginBottom: 7}}>
                            Uploads
                        </div>
                        {ele.reference_files && ele.reference_files.length > 0 ?
                            <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])}>
                            {(ele.reference_files[0].name.includes('.jpg') || ele.reference_files[0].name.includes(".png") || ele.reference_files[0].name.includes(".PNG") || ele.reference_files[0].name.includes(".svg")) ?
                            <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])}/> :
                            (ele.reference_files[0].name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                            <img className="upload-image-settings" src={'/img/ele.reference_files[0]-icon.png'}/> }
                            <span className="category-artist" style={{fontSize: 14, textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', width: '90%', whiteSpace: 'nowrap'}}>{ele.reference_files[0].name}</span>
                            </a>
                        : ''}
                    </Col>
            : ''}
          </div>

        </div>
      );
    }

    // sum up rejection count
    let admin_rejection_count = 0;
    let customer_rejection_count = 0;
    let last_rejection_by = null;
    let last_rejection_date = null;
    if(props.rejectionDetails){
        props.rejectionDetails.map((ele) => {
            if(ele.qa_role == "admin")
            {
                admin_rejection_count += 1;
                if(!last_rejection_by){
                    last_rejection_by = 'admin';
                    last_rejection_date = ele.qa_time;
                }
            }
            if(ele.qa_role == "" && !ele.customer_rejection){
                customer_rejection_count += 1;
                if(!last_rejection_by){
                    last_rejection_by = 'customer';
                    last_rejection_date = ele.qa_time;
                }
            }
        })
    }

    // structuring data for item history
    let item_history_data = '';

    if (props.itemHistory && props.itemHistory.length > 0){
        item_history_data = props.itemHistory.map((ele, index) => (

            <React.Fragment>

                <div style={{margin:5}}>
                <div style={{marginTop: 25}}>
                    <div className='note-bg-artist'>Date <span style={{color:"#276DD7", paddingLeft: 5}}>{Utilites.convertUtcToLocalTimezone(ele.action_time) || '-'}</span>
                    <span style={{paddingLeft: 25}}>Activity</span><span style={{color:"#276DD7", paddingLeft: 5}}>{getItemHistoryAction(ele)}</span>
                    </div>
                </div>

                <div style={{display: "flex"}}>
                    <div style={{paddingTop: 10, paddingLeft: 4}}>Activity By: <span style={{color:"#333333", paddingLeft: 5}}> {getActorUsername(ele)}</span></div>
                    {

                    ele.additional_attachment && ele.additional_attachment.rejection_details?
                    <div style={{paddingTop: 10, paddingLeft: 40}}>
                        <span>Rejection Comments: <span style={{color:"#333333", paddingLeft: 5}}> {ele.additional_attachment.rejection_details} </span></span>
                        {(ele.additional_attachment.reference_files != [] && ele.additional_attachment.reference_files != undefined) && (typeof (ele.additional_attachment.reference_files) == "object" && Object.keys(ele.additional_attachment.reference_files).length != 0)?
                        ele.additional_attachment.reference_files.map((file) => (
                            <span style={{display:'block'}}>
                            <a style={{color:"#276DD7", lineHeight: '120%'}} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                            {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                            <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                            (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                            <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                            </a>
                            </span>
                        ))
                    :''}
                    </div>
                    :''
                    }
                    {/* If model assigned to artist by admin, show assigned artist name as well */}
                    {ele.attribute_name == "assigned_artist" && ele.after_value != "" && ele.username != "" ?
                        <div style={{paddingTop: 10, paddingLeft: 30}}>Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.after_value}</span></div> : ""
                    }

                    {/* If admin unassigned a model from artist, show before artist name as well */}
                    {ele.attribute_name == "assigned_artist" && ele.after_value == "" && ele.username != "" ?
                        <div style={{paddingTop: 10, paddingLeft: 30}}>Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.before_value}</span></div> : ""
                    }

                    {/* Admin changed the artist, show the previous artist */}
                    {ele.attribute_name == "assigned_artist" && ele.after_value != "" && ele.before_value != "" && ele.username != "" ?
                        <div style={{paddingTop: 10, paddingLeft: 30}}> Previous Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.before_value}</span></div> : ""
                    }

                    {/* If it was an MP4, silo or dimensional request show additional details */}
                    {getAdditionalDetails(ele)}
                </div>

                {/* If the item was rejected show rejection details */}
                {ele.attribute_name == "model_status" && (ele.after_value == "-4" || ele.after_value == "-5") ? getRejectionDetailsForActivityModel(ele.additional_attachment) : ""}

                </div>
            </React.Fragment>

        ));
    }

    let detailed_rejection_data = '';
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
     detailed_rejection_data = props.rejectionDetails.map((ele, index) => (
        !ele.customer_rejection ?
        <div style={{marginTop:2, maxWidth:"941px"}}>
        <span style={{marginTop:15, display:"block" }} className='note-bg-artist'>Date <span style={{color:"#276DD7", paddingLeft: 3, marginRight:25}}>{Utilites.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span>  Activity By: <span style={{marginLeft:5,color:"#276dd7"}}>{ele.qa_person}</span></span>
        {ele.feedback_link ?
            <div className='justify-in-start w-100' style={{ margin: 2, padding: 2, marginBottom: 7 }}>
                <span className="category-artist text-left" style={{ display: 'block', float: 'left' }}>
                    Feedback:
                </span>
                <a className='category-artist ml-8' href={ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.PRODUCT_FEEDBACK_URI + props.params.id + "/" + ele.feedback_link + '.pdf'} target='_blank'
                    style={{ color: "#276DD7" }}>
                    {ele.feedback_link + '.pdf'}
                </a>
            </div> : ''}
            {ele.feedback_link ? <div style={{ marginTop: 4, marginBottom: 4, display: 'block' }}>
              <div className="category-artist text-left" style={{ textAlign: 'left' }}>
                Feedback Images
              </div>
              <div className='justify-in-start wrap'>
                {props.feedbackImages && props.feedbackImages.map((file, index) => (
                  <Image className="image-setting-feedback" src={file.url} />
                ))}
              </div>
            </div> : ''}
        {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?
        <div style={{display: "block", margin: 2, padding:2, width:'100%'}} >
              <div>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7, width:"50%", float:'left'}}>
                    Comments:
                </span>
                <span className="category-artist" style={{ marginBottom: 7, width:"50%", float:'left'}}>
                    Uploads:
                </span>
              </div>
              
              <div style={{marginBottom:10, width:'100%'}}>
               
                <span className="" style={{textAlign:"left", fontSize: 14, color:"#333333", width:"51%" , float:'left'}}>
                    {ele.rejection_details}
                </span>
              <div style={{float:'left', width:'47%'}}>
                <Carousel style={{width:"100%"}} arrows={true} nextArrow={<RightOutlined />} prevArrow={<LeftOutlined/>}>

                    {ele.reference_files && ele.reference_files.map((file,index) => (

                        <a onClick={handleImage} style={{color:"#276DD7", lineHeight: '120%'}} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                        <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                        <img className="upload-image-settings-reject" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                        (file.name.includes(".tiff") ) ?  <img className="upload-image-settings-reject" src={'/img/tiff_icon.png'}/> :
                        <img className="upload-image-settings-reject" src={'/img/file-icon.png'}/> }
                        </div>
                        <p className="category-artist" style={{fontSize: 14, textAlign: 'center', color: '#555555'}}>{file.name}</p>
                        </a>

                    ))}

                </Carousel>
              </div>

          </div>
        </div>
        :
        <>
        <div className='justify-in-start' style={{ marginTop: 4 }}>
            <span className="category-artist" style={{textAlign: 'left', width:"10%", float:'left'}}>
                Comments:
            </span>
            <span className="" style={{textAlign:"left", fontSize: 14, color:"#333333", width:"90%" , float:'left'}}>
                {ele.rejection_details}
            </span>
        </div>
        </>
        }
      </div>
      : ''
     ));
    }

    let max_retries = 5;

    return (
    <AdminMainLayout selected='2'>
         {props.loadingProgress && props.isConfigurable && (props.productReducer.product.model_status != 2 && props.productReducer.product.model_status != 6 &&  props.productReducer.product.model_status != 8) ?
                // <div className="loader-config center-align-v"  style={{textAlign: "center"}}>
                //     <LoadingOutlined style={{textAlign: "center", fontSize:"24px",marginBottom: 20}} size="large" spin />
                //     <h3 className="manrope f-24 w-600 black-33" style={{textAlign: "center",margin:"auto"}}>Loading Product Model</h3>
                //     <h3 className="manrope f-18 grey-73">Please wait a few moments while we load the model...</h3>
                // </div>
                <DottedLoader/>
                : ""}
                {/* filter: props.loadingProgress ? "blur(12px)": "" */}
            <Row type="flex" justify="space-around" style={{ marginTop: "12px",filter: (props.loadingProgress && props.isConfigurable && (![2, 6, 8, 11, 12].includes(parseInt(props.productReducer.product.model_status)))) ? "blur(12px)": "", pointerEvents: (props.loadingProgress && props.isConfigurable && (![2, 6, 8, 11, 12].includes(parseInt(props.productReducer.product.model_status)))) ? "none" : "auto"}}>
                {(props.appArtistModel == false) ?
                <Col span={22}>
                    <div style={{display: 'flex', float: 'right', marginBottom: 5, justifyContent: 'space-between'}}>
                        {( props.productReducer.product.model_status && !([3, -3, 4, 5].includes(parseInt(props.productReducer.product.model_status))) && window.location.href.includes("admin_products")) ?
                            <Dropdown overlay={download_links_menu} trigger={['click']} >
                                <Button className="modal-okay" style={{float: 'right', marginRight: 2, minHeight: '42px', maxHeight: '42px'}}>
                                    <span className="modal-okay-text">Download Links <DownOutlined/></span>
                                </Button>
                            </Dropdown> 
                            : ""}
                        <Button className="modal-okay" style={{marginRight: 2, minHeight: '42px', maxHeight: '42px'}} onClick={() => window.location.href = ('/admin_product_test/' + props.params.id)}>
                            <span className="modal-okay-text">Test in Scene Creator</span>
                        </Button>
                        {(props.productReducer.product.model_status == 3 ||props.productReducer.product.model_status == -3 || validStatusMoveAdminQA.includes(parseInt(props.productReducer.product.model_status))) ?
                        <Dropdown overlay={menu} trigger={['click']} >
                            <Button className="modal-okay-orange" style={{float: 'right', marginRight: 2, minHeight: '42px', maxHeight: '42px'}}>
                                <span className="modal-okay-text">Admin Overrides <DownOutlined/></span>
                            </Button>
                        </Dropdown> : ""}
                    </div>
                </Col> :
                <Col span={22}>
                    <div style={{display: 'flex', float: 'left', marginBottom: 5}}>
                        <div className='app-model-label'>
                            Cleaned Up Model Requested From All3D App
                        </div>
                    </div>
                </Col>
                }
                <Col span={22}>
                    {!props.isConfigurable && (![2,6,8,11,12].includes(parseInt(props.productReducer.product.model_status))) ?
                    <div>
                        {(props.productReducer.product.model_status == 3) ?
                        <Button className={(props.productReducer.product.rejection_details ? `modal-okay square comparison-btn admin-compare rejection-notice ${props.compareView ? `comp-view` : ``}` : `modal-okay square comparison-btn admin-compare ${props.compareView ? `comp-view` : ``}`)} onClick={() => props.setCompareView(!props.compareView)}>
                            <span className="modal-okay-text">{props.compareView == false ? <span>Compare With Reference Images <ArrowRightOutlined/></span> : <span><ArrowLeftOutlined/> Back to Original View</span>}</span>
                        </Button> : ""}
                        {(props.productReducer.product.model_status == 3) && props.compareView==true ?
                        <Row>
                        <Col span={12}>
                            <ProductDetailsComponent storePage={0} {...props} dimensionCrypto={props.dimensionCrypto} cryptoMatteExists={props.cryptoMatteExists} compareView={true} adminScrollCheck={false} adminCheck={true} embed={false} showMetadata={true}/>
                        </Col>
                            <Col span={12}>
                                <ProductStaticGuidelinesCard {...props} compareView={true} adminScrollCheck={false} adminCheck={true} embed={false} rejectionDetails={props.rejectionDetails}/>
                            </Col>
                        </Row>
                        : props.productReducer.product.model_status == 8 ?
                        <Row>
                            <Col span={12}>
                                <QuickRenders {...props} compareView={true} embed={false}/>
                            </Col>
                            <Col span={12}>
                                <ProductStaticGuidelinesCard {...props} adminScrollCheck={false} adminCheck={true} compareView={true} embed={false} rejectionDetails={props.rejectionDetails}/>
                            </Col>
                        </Row>
                        : <ProductDetailsComponent storePage={0}  dimensionCrypto={props.dimensionCrypto} cryptoMatteExists={props.cryptoMatteExists} adminCheck={false} compareView={false} adminScrollCheck={true} {...props} embed={false} showMetadata={true}/>
                        }
                    </div>
                    : props.isConfigurable && (![2,6,8,11,12].includes(parseInt(props.productReducer.product.model_status))) ?
                    <Row>
                        <Col span={24}>
                            <ClayModel
                            toggleLoader={props.toggleLoader}
                            componentInfo={props.componentInfo}
                            productMaterialArr={props.productMaterialArr}
                            loadingProgress={props.loadingProgress}></ClayModel>
                        </Col>
                    </Row>: ''}

                    <Row style={{ marginTop: "15px", marginBottom: 20, alignItems: 'center'}}>
                        <Col span={24} style={{display: 'flex', alignItems: 'center',justifyContent:'space-between'}}>
                            <span>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p className="artist-product-heading">{props.productReducer.product.product_name}</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                {(props.model_type_details && props.productReducer.product.model_type != undefined && props.productReducer.product.model_type != "" && props.model_type_details[props.productReducer.product.model_type])?
                                    <div className ="note-bg-artist" style={{ alignItems: 'center', display: 'flex'}}>
                                        <span className="note-text gray">Customer Uploaded Model ({props.model_type_details[props.productReducer.product.model_type]['label']})</span>
                                    </div>
                                : ""}
                                </div>
                           </span>

                           <span>
                                {(props.productReducer.product['2d'] != undefined && props.productReducer.product['2d'].length <= 2)?
                                <Button className="share-btn" style={{marginRight: 8}} onClick={() => props.setGenerateRendersConfirmModal(true)}><span className="share-btn-txt"> Generate ALL Perspectives </span>
                                </Button>:""}
                                <Button className="share-btn" onClick={() => props.showEmbedModel()}><span className="share-btn-txt">Public Links</span>
                                </Button>
                            </span>
                        </Col>

                    </Row>

                    {props.groupedProducts && props.groupedProducts.length > 0 ?
                    <Row style={{marginBottom: 15, marginTop: -10}}>
                        <Col className="justify-in-start" span={24}>
                            <span className="product-artist-description manrope f-12">
                                <strong>Note:</strong> This item is part of a group. <span onClick={() => setGroupItemModal(true)} style={{color: 'blue', cursor: 'pointer'}}>View Group Items</span>.
                            </span>
                        </Col>


                    </Row> : ''
                    }

                    <Row style={{marginBottom: 22}}>
                    {props.productReducer.product.assigned_artist && props.productReducer.product.assigned_artist != "" ?
                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Artist:</span>
                            <div className ="note-bg-artist gray">
                            <span className="note-text gray">{props.productReducer.product.assigned_artist}</span>
                            </div>
                        </Col>
                        : ""}
                        {(props.productReducer.product.customer_username != undefined) ?
                            <Col style={{display:"flex",alignItems:"center",marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>Customer</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.productReducer.product.customer_username}</span>
                                </div>
                            </Col>
                        : ""}
                        {(props.productDetails.requested_for && props.productDetails.requested_for != "") ?
                            <Col style={{display:"flex",alignItems:"center",marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>Requested For</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.productDetails.requested_for}</span>
                                </div>
                            </Col>
                        : ""}
                        {(props.productDetails.parent_category || props.productDetails.category) &&
                        <Col style={{display:"flex",alignItems:"center",marginRight:'40px', marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Category</span>
                            <div className ="note-bg-artist gray">
                            <span className="note-text gray">
                                {props.productDetails.parent_category &&  props.productDetails.category ?
                                <span>
                                {props.productDetails.parent_category + ' / ' + props.productDetails.category}
                                </span> :
                                <span> {!props.productDetails.parent_category ?
                                props.productDetails.category
                                : !props.productDetails.category ? props.productDetails.parent_category : ''}
                                </span>}
                            </span>
                            </div>
                        </Col>}
                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>ID</span>
                            <div className ="note-bg-artist gray">
                            <span className="note-text gray">{props.productReducer.product.product_id}</span>
                            </div>
                        </Col>
                        {props.productReducer.product.brand_id ?
                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>Item ID</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.productReducer.product.brand_id}</span>
                                </div>
                            </Col>
                        : ""}
                        {props.productDetails && props.productDetails.licensing_options &&  props.productDetails.licensing_options.license ?
                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>License</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.productDetails.licensing_options.license}
                                    {props.productDetails.licensing_options.license == 'public' ? props.productDetails.licensing_options.public_option == 'forCreativeCommon' ?
                                    <Tooltip title={<span className='manrope f-12 white'>{props.productDetails.licensing_options.creative_common}</span>}>
                                        <img style={{marginLeft: 5}} src={FileConstants.getLicensingIcon(props.productDetails.licensing_options.creative_common)}/>
                                    </Tooltip>:
                                    <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                                        <img style={{marginLeft: 5}} src={FileConstants.getLicensingIcon(props.productDetails.licensing_options.public_option)}/>
                                    </Tooltip>: '' }
                                    </span>
                                </div>
                            </Col>
                        : ""}
                         {props.productDetails.variant_of ?
                            <Col style={{display:"flex",alignItems:"center", marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Model Type</span>
                            <Row>
                                <Col>
                                    <div className="note-bg-artist gray" style={{marginRight: 16}}>
                                        <span className="note-text gray">Change</span>
                                    </div>
                                </Col>

                            </Row>
                            </Col> : ""}
                            {props.productDetails.variation_type ?
                            <Col style={{display:"flex",alignItems:"center", marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Change Type</span>
                            <Row>
                                <Col>
                                    <div className="note-bg-artist gray" style={{marginRight: 16}}>
                                        <span className="note-text gray">{FileConstants.variationDict(props.productDetails.variation_type, props.productDetails.need_to_model)}</span>
                                    </div>
                                </Col>

                            </Row>
                            </Col> : ""}
                        {props.productReducer.product.required_assets != undefined &&  props.productReducer.product.required_assets.length > 0?
                            <Col style={{display:"flex",alignItems:"center", marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Required Outputs</span>
                            <Row>
                                {props.productReducer.product.required_assets.map(x => (
                                <Col>
                                    <div className="note-bg-artist gray" style={{marginRight: 16}}>
                                        <span className="note-text gray">{x}</span>
                                    </div>
                                </Col>
                                ))}
                            </Row>
                            </Col> : ""}
                        {props.productDetails.segmented ?
                        <>
                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>Segmented Model: </span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.productDetails.segmented ? ' Yes': ' No'}</span>
                                </div>
                            </Col>
                        </>
                        : ""}

                    </Row>

                    {/* Model Status */}
                    <Row style={{marginTop: 15}}>
                    <Col style={{display:"flex",alignItems:"center"}}>
                            <span className="category-artist" style={{marginRight:16}}>Status:</span>
                            <div className ="note-bg-artist red" style={{display: "block", fontSize: '1.8rem'}}>
                            {

                                (() => {
                                    if (props.productReducer.product.model_status == "1")
                                        return <span className="note-text red product-status">Model is incomplete, and is yet to be submitted by the Customer.</span>
                                    if (props.productReducer.product.model_status == "2" && !props.productReducer.product.assigned_artist)
                                        return <span className="note-text red product-status">Model is yet to be Assigned to an Artist.</span>
                                    if (props.productReducer.product.model_status == "2" && props.productReducer.product.assigned_artist)
                                        return <span className="note-text red product-status">Product has been Assigned to an Artist for Modeling.</span>
                                    if (props.productReducer.product.model_status == "3")
                                        return <span className="note-text red product-status">Model is Pending for Admin QA since {props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "-3")
                                        return <span className="note-text red product-status">Model's file validation get failed after Model Override by Admin on {props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "4")
                                        return <span className="note-text red product-status">Model is Pending for Customer QA since {props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "-4" && (last_rejection_by && last_rejection_by == "admin"))
                                        return <span className="note-text red product-status">Model was Rejected by Admin on {last_rejection_date ? formateDate(last_rejection_date) : props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "-4" && (last_rejection_by && last_rejection_by == "customer"))
                                        return <span className="note-text red product-status">Model was Rejected by Customer on {last_rejection_date ? formateDate(last_rejection_date) : props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "5" && props.previousState != "3")
                                        return <span className="note-text red product-status">Model has been Approved by Customer since {props.productReducer.product.last_modified}.</span>
                                        if (props.productReducer.product.model_status == "5" && props.previousState == "3")
                                        return <span className="note-text red product-status">Model was Approved and Added to Customer's Library by Admin since {props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "-5")
                                        return <span className="note-text red product-status">Model has been Rejected by Customer since {props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "6")
                                        return <span className="note-text red product-status">Model is being Processed since {props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "7")
                                        return <span className="note-text red product-status">Model has been under preprocessing since {props.productReducer.product.last_modified}.</span>
                                    if (props.productReducer.product.model_status == "8")
                                        return <span className="note-text red product-status">Quick Renders were generated on {props.productReducer.product.last_modified} and now Artist needs to Finalize the Model.</span>
                                    if (props.productReducer.product.model_status == "9")
                                        return <span className="note-text red product-status">The model was approved on {props.productReducer.product.last_modified} and now its materials need to be approved as well.</span>
                                    if (props.productReducer.product.model_status == "10")
                                        return <span className="note-text red product-status">Model was sent back to customer on {props.productReducer.product.last_modified} as further details are required.</span>
                                    if (props.productReducer.product.model_status == "11")
                                        return <span className="note-text red product-status">Model's automation job failed on {props.productReducer.product.last_modified}</span>
                                    if (props.productReducer.product.model_status == "12")
                                        return <span className="note-text red product-status">Model's automation job aborted on {props.productReducer.product.last_modified}</span>
                                })()
                            }

                            </div>

                            <Button type="link" disabled={props.itemHistory.length == 0} onClick={() => {props.setItemHistoryModal(true); }} className='note-text blue item-history' style={{display: 'inline', marginLeft: 20}}>
                                    View Activity Timeline{props.itemHistory.length == 0 ? '(No Records Found)' : ''}
                                    {/* {props.activitySortAscending == true ? props.sortActivity(false) : '' } */}
                            </Button>

                        </Col>
                    </Row>

                    {props.productDetails.variant_of ?
                        <Row style={{marginTop: 10}}>
                        <Col style={{display:"flex",alignItems:"center"}}>
                                <span className="category-artist" style={{marginRight:16}}>Parent Model:</span>
                                <div className="note-bg-artist red urls">
                                    <span className="note-text red urls">
                                        <a href={ENVIRONMENT.PORTAL_LINK + 'admin_products/' + props.productDetails.variant_of}>{ENVIRONMENT.PORTAL_LINK + 'admin_products/' + props.productDetails.variant_of} </a>
                                    </span>
                                </div>
                        </Col>
                        </Row> : ''}

                    {/* Reset Model Retries Option for Admin */}
                    {props.productReducer.product.model_status == 8 && props.retriesReset == false && props.productDetails.model_retries == max_retries ?
                        <Row style={{marginTop: 15}}>
                            <Col className='retries-div'>
                                <Row>
                                    <div className="note-bg-artist" style={{marginRight: 8, display: "flex"}}>
                                        <div style={{marginRight: 10}}>
                                            <span className="note-text" style={{color: '#555555', fontSize: 16}}>The artist has used the maximum number({max_retries}) of attempts to get this model right. Reset the number of retries if further fixes are required.</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Button disabled={(props.resetRequestInProgress===true)} className="modal-okay square" style={{marginRight: 3}} onClick={() => props.reset_model_retries()}>
                                                <span className="modal-okay-text">Reset Retries {props.resetRequestInProgress == true?<LoadingOutlined spin/>:""}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                    </Row> : ""}

                    <Row style={{marginBottom: 16, marginTop: 15}}>
                    {rejections_data ?
                        <Col sm={24} lg={24} xl={11} style={{marginRight: 30}} className="note-bg-artist red">

                            <div>
                                <div className='note-text red' style={{display: 'inline'}}>
                                    Rejection Comments
                                </div>
                                <Button type="link" onClick={() => {showRejectionModal()}} className='note-text blue rejection-history-view' style={{display: 'inline', position: 'absolute', right: 20}}>
                                    View Details
                                </Button>
                            </div>
                            {/* Admin Rejection count and customer rejection count */}
                            {admin_rejection_count + customer_rejection_count > 0 ?
                            <Row style={{alignItems: 'center', marginTop: 4}}>
                                <span className="category-artist" style={{marginRight:16}}>Count:</span>
                                {admin_rejection_count ?
                                    <Col style={{display: 'flex'}}>
                                            <span style={{color:"#276DD7", marginRight: 16}}>Admin: {admin_rejection_count}</span>
                                    </Col>
                                : ""}

                                {customer_rejection_count ?
                                    <Col style={{display:"flex"}}>
                                        <span style={{color:"#276DD7"}}>Customer: {customer_rejection_count}</span>
                                    </Col>
                                : ""}
                            </Row> : ''}
                            {rejections_data}
                        </Col> : "" }
                        {product_text_guidelines != "" || dimensions_text != "" ?
                            <Col sm={24} lg={24} xl={12} className="note-bg-artist gray">
                                <div>
                                    <div className='note-text gray' style={{display: 'inline'}}>
                                        Initial Comments and Guidelines
                                    </div>
                                </div>
                                <div style={{marginTop: 5}}>
                                    <ul style={{paddingLeft: 20}}>
                                        {product_text_guidelines}
                                        {dimensions_text}
                                    </ul>
                                </div>
                            </Col>: ""}
                    </Row>

                    <Row style={{paddingTop: 30}}>
                        <Col span={24}>
                        <Tabs defaultActiveKey="stats" className="product-tab" size="large">
                            <TabPane  tab="Modeling Stats / Dimension Validation" key="stats" style={{padding: 10}}>
                                <Row>

                                    <Col sm={24} xl={14} lg={14} style={{paddingRight: 30}}>
                                         {/* Modeling Time */}

                                         <Col span={24} style={{paddingTop: 30}}>
                                            {props.totalModelingTime() != "0 Hours" ?
                                                <React.Fragment>

                                                    <div>
                                                        <span className="product-txt-heading">Initial Modeling Time</span>
                                                        <span className="note-text blue" style={{paddingLeft: 10}}>{props.totalModelingTime()}</span>
                                                    </div>
                                                    <table className="stats-table" style={{marginTop: 13}}>
                                                    <tr>
                                                    {props.productReducer.product.time1 != undefined && props.productReducer.product.time1 != "" && props.productReducer.product.time1 != "0" ?
                                                        <td colSpan={2}>
                                                            <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>High Poly</span>
                                                        </td>  : ""}
                                                    {props.productReducer.product.time4 != undefined && props.productReducer.product.time4 != "" && props.productReducer.product.time4 != "0" && props.productReducer.product.time2 != undefined && props.productReducer.product.time2 != "" && props.productReducer.product.time2 != "0" ?
                                                        <td colSpan={2}>
                                                            <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Low Poly</span>
                                                        </td>  : ""}

                                                    </tr>
                                                    <tr>
                                                    {props.productReducer.product.time1 != undefined && props.productReducer.product.time1 != "" && props.productReducer.product.time1 != "0" ?
                                                        <td colSpan={props.productReducer.product.time3 != undefined && props.productReducer.product.time3 != "" && props.productReducer.product.time3 != "0" ? 1 : 2}>
                                                                <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>High Poly Geometry</span>
                                                                <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(props.productReducer.product.time1)}</span>
                                                        </td>
                                                        : ""}
                                                        {props.productReducer.product.time3 != undefined && props.productReducer.product.time3 != "" && props.productReducer.product.time3 != "0" ?
                                                        <td colSpan={props.productReducer.product.time1 != undefined && props.productReducer.product.time1 != "" && props.productReducer.product.time1 != "0" ? 1 : 2}>
                                                                <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>{props.productReducer.product.need_to_model.includes("high_pbr")? "High Poly PBR Textures" : "Vray Textures"}</span>
                                                                <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(props.productReducer.product.time3)}</span>
                                                        </td>
                                                        : ""}
                                                        {props.productReducer.product.time2 != undefined && props.productReducer.product.time2 != "" && props.productReducer.product.time2 != "0" ?
                                                        <td colSpan={props.productReducer.product.time4 != undefined && props.productReducer.product.time4 != "" && props.productReducer.product.time4 != "0" ? 1 : 2}>
                                                                <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Low Poly Geometry</span>
                                                                <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(props.productReducer.product.time2)}</span>
                                                        </td>
                                                        : ""}
                                                        {props.productReducer.product.time4 != undefined && props.productReducer.product.time4 != "" && props.productReducer.product.time4 != "0" ?
                                                        <td colSpan={props.productReducer.product.time2 != undefined && props.productReducer.product.time2 != "" && props.productReducer.product.time2 != "0" ? 1 : 2}>
                                                                <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>{props.productReducer.product.need_to_model.includes("high_pbr")? "Low Poly PBR Textures" : "PBR Textures"}</span>
                                                                <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(props.productReducer.product.time4)}</span>
                                                        </td>
                                                        : ""}
                                                    </tr>
                                                    </table>
                                                </React.Fragment>
                                                :
                                                    <div>
                                                        {/* When modeling time is not available show that it is a customer uploaded model.  */}
                                                        {(props.model_type_details && props.productReducer.product.model_type != undefined && props.productReducer.product.model_type != "" && props.model_type_details[props.productReducer.product.model_type]) ?
                                                        <div >
                                                            <span className="product-txt-heading" style={{marginRight: 10}}>Initial Modeling Time</span>
                                                            <Col style={{display: 'flex', padding: '30px 0px 10px 10px', alignItems: 'center'}}>
                                                                <SmallEmptyBox />
                                                                <div>
                                                                    <span style={{fontSize: 16, color: '#555555', paddingLeft: 20}}>{props.model_type_details[props.productReducer.product.model_type]['label']} Model Uploaded by Customer.</span>
                                                                </div>
                                                            </Col>

                                                        </div>
                                                        :
                                                        // When no modleing time available and it is not a customer uploaded model then show the no information place holder //
                                                        <div>
                                                            <span className="product-txt-heading">Initial Modeling Time</span>
                                                            {information_not_available}
                                                        </div> }
                                                    </div>

                                            }
                                        </Col>

                                        {/* Fixes Time */}
                                        {props.fixesDetails != undefined && props.fixesDetails.length > 0 ?
                                            <Col span={24} style={{paddingTop: 30, paddingBottom: 10}}>
                                                <React.Fragment>
                                                    <div>
                                                        <span className="product-txt-heading">Total Fixing Time</span>
                                                        <span className="note-text blue" style={{paddingLeft: 10}}>{props.totalFixesTime()}</span>
                                                    </div>
                                                    <table className="stats-table" style={{marginTop: 13, display: 'block'}}>
                                                        <tr>
                                                        {
                                                            (() => {
                                                                var fixes_array = []
                                                                for(var i=0; i<props.fixesDetails.length; i++)
                                                                {
                                                            fixes_array.push(
                                                                        <td style={{display: 'inline-block'}}>
                                                                            <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Fix {props.fixesDetails.length - i}</span>
                                                                            <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(props.fixesDetails[i].fix_time)}</span>
                                                                        </td>
                                                                )
                                                                }
                                                                return fixes_array
                                                            })()
                                                        }
                                                        </tr>

                                                    </table>
                                                </React.Fragment>
                                            </Col>
                                            :
                                            ""
                                            }

                                        {/* File Sizes */}
                                        <Col span={24} style={{paddingTop: 30}}>
                                        {props.totalFileSize > 0 && props.totalFileSize != props.newGlbSize?
                                        <React.Fragment>
                                            <div>
                                                <span className="product-txt-heading">Total Bundle Size</span>
                                                <span className="note-text blue" style={{paddingLeft: 10}}>{props.convertByteSize(props.totalFileSize)}</span>
                                            </div>
                                            <table className="stats-table" style={{marginTop: 13, tableLayout: "fixed", width: "94%"}}>
                                            <tr>
                                            {props.highPolyModelSize + props.highPolyTextureSize > 0 ?
                                                <td colSpan={2}>
                                                    <div style={{display: 'flex'}}>
                                                    <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>High Poly</span>
                                                    <span className="note-text blue" style={{paddingLeft: 10, paddingTop: 5}}>{props.convertByteSize(props.highPolyModelSize + props.highPolyTextureSize)}</span>
                                                    </div>
                                                </td>  : ""}
                                            {props.lowPolyModelSize + props.lowPolyTextureSize > 0 ?
                                                <td colSpan={2}>
                                                    <div style={{display: 'flex'}}>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Low Poly</span>
                                                        <span className="note-text blue" style={{paddingLeft: 10, paddingTop: 5}}>{props.convertByteSize(props.lowPolyModelSize + props.lowPolyTextureSize)}</span>
                                                    </div>
                                                </td>  : ""}
                                            {props.newGlbSize > 0 ?
                                                <td>
                                                <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>GLB</span>
                                            </td>  : ""}

                                            </tr>
                                            <tr>
                                                {props.highPolyModelSize > 0 ?
                                                <td colSpan={props.highPolyTextureSize > 0 ? 1 : 2}>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Mesh</span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(props.highPolyModelSize)}</span>
                                                </td>
                                                : ""}
                                                {props.highPolyTextureSize > 0 ?
                                                <td colSpan={props.highPolyModelSize > 0 ? 1 : 2}>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Texture file</span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(props.highPolyTextureSize)}</span>
                                                </td>
                                                : ""}
                                                {props.lowPolyModelSize > 0 ?
                                                <td colSpan={props.lowPolyTextureSize > 0 ? 1 : 2}>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Mesh</span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(props.lowPolyModelSize)}</span>
                                                </td>
                                                : ""}
                                                {props.lowPolyTextureSize > 0 ?
                                                <td colSpan={props.lowPolyModelSize > 0 ? 1 : 2}>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Texture file</span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(props.lowPolyTextureSize)}</span>
                                                </td>
                                                : ""}
                                                {props.newGlbSize > 0 ?
                                                <td colSpan={1}>
                                                    {/* <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Texture file</span> */}
                                                    <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(props.newGlbSize)}</span>
                                                </td>
                                                : ""}

                                            </tr>
                                            </table>
                                        </React.Fragment>
                                        : props.archiveSize > 0 || props.glbSize > 0?
                                        <React.Fragment>
                                            <div>
                                                <span className="product-txt-heading">Total Bundle Size</span>
                                                <span className="note-text blue" style={{paddingLeft: 10}}>{props.convertByteSize((props.archiveSize + props.glbSize)/(1024*1024))}</span>
                                            </div>
                                            <table className="stats-table" style={{marginTop: 13}}>
                                            <tr>
                                                {props.archiveSize > 0 ?
                                                <td>
                                                    <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Archive Size</span>
                                                    <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(props.archiveSize/(1024*1024))}</span>
                                                </td>
                                                : ""}
                                                {props.glbSize > 0 ?
                                                <td>
                                                    <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>GLB Size</span>
                                                    <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(props.glbSize/(1024*1024))}</span>
                                                </td>
                                                : ""}
                                            </tr>
                                            </table>
                                        </React.Fragment>
                                        :
                                        <div style={{paddingBottom: 13}}>
                                            <span className="product-txt-heading">Total Bundle Size</span>
                                            {information_not_available}
                                        </div>
                                        }
                                        </Col>

                                        {low_poly_vertex || high_poly_vertex || high_poly_triangles || low_poly_triangles ?
                                        <Col span={24} style={{paddingTop: 30}}>
                                             <span className="product-txt-heading">{low_poly_triangles||high_poly_triangles ? "Triangle Count" : "Vertex Count"}</span>
                                            <table className="stats-table" style={{marginTop: 13}}>
                                            <tr>
                                                {low_poly_vertex ?
                                                    <td>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>
                                                            {low_poly_triangles ? "Low Poly Triangle Count" : "Low Poly Vertex Count"}
                                                        </span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>
                                                            {low_poly_triangles ? parseInt(low_poly_triangles).toLocaleString() : parseInt(low_poly_vertex).toLocaleString()}
                                                        </span>
                                                    </td>
                                                : ""}
                                                {high_poly_vertex ?
                                                    <td>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>
                                                            {high_poly_triangles ? "High Poly Triangle Count" :"High Poly Vertex Count"}
                                                        </span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>
                                                            {high_poly_triangles ? parseInt(high_poly_triangles).toLocaleString() : parseInt(high_poly_vertex).toLocaleString()}
                                                        </span>
                                                    </td>
                                                : ""}
                                            </tr>
                                            </table>
                                        </Col>
                                     : ""}

                                    </Col>

                                    <Col sm={24}  xl={10} lg={10}>
                                    <div style={{paddingTop: 30}}>
                                    {display_dimension_table && props.productReducer.product.model_files && props.productReducer.product.model_files.length == 0 ?
                                    <React.Fragment>
                                        {/* Artist Created Model */}
                                        <span className="product-txt-heading">Dimension Validation</span>
                                        <div style={{display: 'inline',background: '#FEF6F6', padding: 8, marginLeft: 10, borderRadius: 3}}>
                                            <span className="note-text" style={{color: props.dimensionValidation=='Invalid'?'red':'green'}}>{props.dimensionValidation}</span>
                                        </div>

                                        <table className="stats-table dimension-table" style={{marginTop: 12, width: "100%"}}>
                                            <tr>
                                                <th></th>
                                                <th>Customer Dimensions</th>
                                                <th>3D Model Dimensions</th>
                                            </tr>
                                            <tr>
                                                <td style={{fontSize: 16}}>Height<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>Inches</span></td>
                                                <td>{(props.productReducer.product.height != undefined) ? props.productReducer.product.height : "N/A"}</td>
                                                <td>{model_height}</td>
                                            </tr>
                                            <tr>
                                            <td style={{fontSize: 16}}>Width<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>Inches</span></td>
                                                <td>{(props.productReducer.product.width != undefined) ? props.productReducer.product.width : "N/A"}</td>
                                                <td>{model_width}</td>
                                            </tr>
                                            <tr>
                                            <td style={{fontSize: 16}}>Depth<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>Inches</span></td>
                                                <td>{(props.productReducer.product.depth != undefined) ? props.productReducer.product.depth : "N/A"}</td>
                                                <td>{model_depth}</td>
                                            </tr>
                                        </table>
                                        </React.Fragment>
                                    : display_dimension_table && props.productReducer.product.model_files && props.productReducer.product.model_files.length > 0 ?
                                    <React.Fragment>
                                        {/* Customer Uplaoded Model */}
                                        <span className="product-txt-heading">Customer Uploaded Model</span>
                                        <table className="stats-table dimension-table" style={{marginTop: 12, width: "100%"}}>
                                            <tr>
                                                <th></th>
                                                <th>3D Model Dimensions</th>
                                            </tr>
                                            <tr>
                                                <td style={{fontSize: 16}}>Height<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>Inches</span></td>
                                                <td>{model_height}</td>
                                            </tr>
                                            <tr>
                                            <td style={{fontSize: 16}}>Width<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>Inches</span></td>
                                                <td>{model_width}</td>
                                            </tr>
                                            <tr>
                                            <td style={{fontSize: 16}}>Depth<span style={{paddingLeft: 6, fontSize: 14, color: '#777777'}}>Inches</span></td>
                                                <td>{model_depth}</td>
                                            </tr>
                                        </table>
                                    </React.Fragment>
                                    : ""}
                                    </div>
                                    {
                                        props.validation_generated  ?
                                        <div style={{marginTop: 36}}>
                                            <div className="product-txt-heading" style={{marginBottom: 14}}>Model Validation Summary</div>
                                            {props.invalid_val_length == 0 ? '' :
                                            <Collapse
                                            bordered={false}
                                            defaultActiveKey={['1']}
                                            expandIconPosition={'right'}
                                            expandIcon={({ isActive }) => <div className="draw-full-circle red" style={{padding: "7px 11px",top: 7}}><span className="artist-upload-txt small white">{props.invalid_val_length}</span></div>}
                                            className="collapse-validation">
                                            <Panel header={<span className="modal-text red">What Went Wrong?</span>} key="1" className="site-collapse-custom-panel">
                                                <Collapse defaultActiveKey={['1']} accordion className="collapse-validation-collapse" ghost expandIconPosition={'right'}>
                                                {props.invalid_data_high.length == 0 ? '' :
                                                <Panel key='1' className="site-collapse-custom-panel" header={<span className='width-height-label'>High Poly</span>}>
                                                    <List
                                                    bordered={false}
                                                    dataSource={props.invalid_data_high}
                                                    className="list-validation"
                                                    renderItem={item => (
                                                    <List.Item className="list-item-validation">
                                                        <Typography.Text mark style={{marginRight: 10}} className="mark-style"><img style={{backgroundColor: "#fafafa"}} src="/img/alert-triangle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                    </List.Item>
                                                    )}
                                                />
                                                </Panel>}
                                                {props.invalid_data_low.length == 0 ? '' :
                                                <Panel  key='2' className="site-collapse-custom-panel" header={<span className='width-height-label'>Low Poly</span>}>
                                                    <List
                                                    bordered={false}
                                                    dataSource={props.invalid_data_low}
                                                    className="list-validation"
                                                    renderItem={item => (
                                                    <List.Item className="list-item-validation">
                                                        <Typography.Text mark style={{marginRight: 10}} className="mark-style"><img style={{backgroundColor: "#fafafa"}} src="/img/alert-triangle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                    </List.Item>
                                                    )}
                                                />
                                                </Panel>}
                                                </Collapse>
                                            </Panel>
                                            </Collapse>}
                                            {props.success_val_length == 0 ? '' :
                                            <Collapse
                                            bordered={false}
                                            defaultActiveKey={props.invalid_val_length == 0 && props.auto_val_length == 0 ? ['1']: ['0']}
                                            expandIconPosition={'right'}
                                            expandIcon={({ isActive }) => <div className="draw-full-circle" style={{padding: "7px 11px",top: 7}}><span className="artist-upload-txt small white">{props.success_val_length}</span></div>}
                                            className="collapse-validation">
                                            <Panel header={<span className="modal-text green">Successfully Validated</span>} key="1" className="site-collapse-custom-panel">
                                            <Collapse defaultActiveKey={['1']} accordion className="collapse-validation-collapse" ghost expandIconPosition={'right'}>
                                            {props.successfully_validated_high.length == 0 ? '' :
                                                <Panel collapsible={props.successfully_validated_low.length == 0 ? 'disabled' : 'header'} showArrow={props.successfully_validated_low.length == 0 ? false : true} key='1' className="site-collapse-custom-panel" header={<span className='width-height-label'>High Poly</span>}>
                                                <List
                                                bordered={false}
                                                dataSource={props.successfully_validated_high}
                                                className="list-validation"
                                                renderItem={item => (
                                                    <List.Item className="list-item-validation">
                                                    <Typography.Text mark style={{marginRight: 10}}><Checkbox className="green-checkbox" checked={true}/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                    </List.Item>
                                                )}/>
                                                </Panel>}
                                                {props.successfully_validated_low.length == 0 ? '' :
                                                <Panel key='2' collapsible={props.successfully_validated_high.length == 0 ? 'disabled' : 'header'} showArrow={props.successfully_validated_high.length == 0 ? false : true} className="site-collapse-custom-panel" header={<span className='width-height-label'>Low Poly</span>}>
                                                <List
                                                    bordered={false}
                                                    dataSource={props.successfully_validated_low}
                                                    className="list-validation"
                                                    renderItem={item => (
                                                    <List.Item className="list-item-validation">
                                                        <Typography.Text mark style={{marginRight: 10}}><Checkbox className="green-checkbox" checked={true}/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                    </List.Item>
                                                    )}/>
                                                </Panel>}
                                            </Collapse>
                                            </Panel>
                                            </Collapse>}
                                            {props.auto_val_length == 0 ? '' :
                                            <Collapse
                                            bordered={false}
                                            defaultActiveKey={props.invalid_val_length == 0  ? ['1']: ['0']}
                                            expandIconPosition={'right'}
                                            expandIcon={({ isActive }) => <div className="draw-full-circle yellow" style={{padding: "7px 11px",top: 7}}><span className="artist-upload-txt small white">{props.auto_val_length}</span></div>}
                                            className="collapse-validation">
                                            <Panel header={<span className="modal-text yellow">Automatically Fixed</span>} key="1" className="site-collapse-custom-panel">
                                                <Collapse defaultActiveKey={['1']} accordion className="collapse-validation-collapse" ghost expandIconPosition={'right'}>
                                                {props.automatically_fixed_high.length == 0 ? '' :
                                                <Panel key='1' className="site-collapse-custom-panel" header={<span className='width-height-label'>High Poly</span>}>
                                                    <List
                                                    bordered={false}
                                                    dataSource={props.automatically_fixed_high}
                                                    className="list-validation"
                                                    renderItem={item => (
                                                    <List.Item className="list-item-validation">
                                                        <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-circle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                    </List.Item>
                                                    )}/>
                                                </Panel>}
                                                {props.automatically_fixed_low.length == 0 ? '' :
                                                <Panel key='2' className="site-collapse-custom-panel" header={<span className='width-height-label'>Low Poly</span>}>
                                                    <List
                                                    bordered={false}
                                                    dataSource={props.automatically_fixed_low}
                                                    className="list-validation"
                                                    renderItem={item => (
                                                        <List.Item className="list-item-validation">
                                                        <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-circle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                        </List.Item>
                                                    )}/>
                                                </Panel>}
                                                </Collapse>
                                            </Panel>
                                            </Collapse>}
                                        </div>
                                        : ''

                                     }

                                    </Col>

                                </Row>
                            </TabPane>
                            <TabPane tab="Product Model Information" key="model_info" style={{marginRight: 120}}>
                                {/* <Row style={{marginTop: 30}}>
                                    <Col style={{display:"flex",alignItems:"center",marginRight:'40px'}}>
                                        <span className="category-artist" style={{marginRight:16}}>Width</span>
                                        <div className ="note-bg-artist gray">
                                        <span className="note-text gray">{model_width == "N/A" ? "N/A" : model_width.toFixed(1)} inches</span>
                                        </div>
                                    </Col>
                                    <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                        <span className="category-artist" style={{marginRight:16}}>Height</span>
                                        <div className ="note-bg-artist gray">
                                        <span className="note-text gray">{model_height == "N/A" ? "N/A" : model_height.toFixed(1)} inches</span>
                                        </div>
                                    </Col>
                                    <Col style={{display:"flex",alignItems:"center"}}>
                                        <span className="category-artist" style={{marginRight:16}}>Depth</span>
                                        <div className ="note-bg-artist gray">
                                        <span className="note-text gray">{model_depth == "N/A" ? "N/A" : model_depth.toFixed(1)} inches</span>
                                        </div>
                                    </Col>
                                    </Row> */}

                                    <VariationBaseModelDetails product_id={props.productReducer.product.product_id}
                                     productDetails={props.productDetails} category_attachment={category_attachment} selectedMaterials={props.libraryMaterials} changeVisibility={props.changeVisibility} 
                                     basePlatform={props.basePlatform} basePlatformLoader={props.basePlatformLoader}/>

                                    {/* Variation */}
                                    {props.productDetails.variant_of ?
                                    <Row style={{marginBottom:10,marginTop: 30}}>

                                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                            <span className="category-artist" style={{marginRight:16}}>Model Type</span>
                                            <div className ="note-bg-artist gray">
                                                <span className="note-text blue" >Change</span>
                                            </div>
                                        </Col>

                                        {props.productDetails.variation_type ?
                                            <Col style={{display:"flex",alignItems:"center"}}>
                                                <span className="category-artist" style={{marginRight:16}}>Change Type</span>
                                                <div className ="note-bg-artist gray">
                                                    <span className="note-text blue" >{FileConstants.variationDict(props.productDetails.variation_type, props.productDetails.need_to_model)}</span>
                                                </div>
                                            </Col> : ""}
                                    </Row>
                                    : "" }

                                    {props.productDetails.immediate_parent_variant  ?
                                     <Row style={{marginTop:30}}>
                                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                            <span className="category-artist" style={{marginRight:16}}>Base Model for Change</span>
                                            <div style={{display:"block"}}>
                                                <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                                                    {props.basePlatformLoader ? ('Fetching base model link...') :
                                                     <span className="note-text gray urls"><a href={ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.immediate_parent_variant + '.zip'} target='_blank' style={{color: "#276DD7"}}>{ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.immediate_parent_variant + '.zip'}</a></span>}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>:
                                    props.productDetails.variant_of  ?
                                    <Row style={{marginTop:30}}>
                                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                            <span className="category-artist" style={{marginRight:16}}>VBase Model for Change</span>
                                            <div style={{display:"block"}}>
                                                <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                                                {props.basePlatformLoader ? ('Fetching base model link...') :
                                                    <span className="note-text gray urls"><a href={ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.variant_of + '.zip'} target='_blank' style={{color: "#276DD7"}}>{ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.variant_of + '.zip'}</a></span>}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                        : ""}
                                    
                                    {/* Refernce urls and manual */}
                                    {props.productDetails.reference_urls != undefined && props.productDetails.reference_urls.length > 0  ?
                                    <Row style={{marginTop:30}}>
                                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                            <span className="category-artist" style={{marginRight:16}}>Reference URL(s)</span>
                                            <div style={{display:"block"}}>
                                                {product_references}
                                            </div>
                                        </Col>
                                    </Row>
                                        : ""}
                                        {props.productDetails.product_manuals != undefined && props.productDetails.product_manuals.length > 0 ?
                                        <Row>
                                            <Col style={{display:"flex",alignItems:"center"}}>
                                                <span className="category-artist" style={{marginRight:16}}>Product Manual(s)</span>
                                                <div style={{display:"block"}}>
                                                    {product_manuals}
                                                </div>
                                            </Col>
                                        </Row>
                                    : ""}

                                    {/* Product Custom Lighting Rig used */}
                                    {props.productLightingRig ?
                                    <Row style={{marginTop:30}}>
                                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                            <span className="category-artist" style={{marginRight:16}}>Product Lighting Rig</span>
                                            <div style={{display:"block"}}>
                                                {product_lighting_rig}
                                            </div>
                                        </Col>
                                    </Row> : ''}

                                    {/* Raw Files */}
                                    {props.productDetails.raw_files != undefined &&  props.productDetails.raw_files.length > 0 ?
                                        <Row>
                                            <Col style={{display:"flex",alignItems:"center"}}>
                                                <span className="category-artist" style={{marginRight:16}}>Raw File(s)</span>
                                                <div style={{display:"block"}}>
                                                    {product_raw_files}
                                                </div>
                                            </Col>
                                        </Row>
                                        : ""}

                                    {/* Product Colors */}
                                    {props.productDetails.color_name != "" || props.productDetails.similar_color != "" ?
                                    <Row style={{marginTop:30}}>
                                        {props.productDetails.color_name != "" ?
                                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                                <span className="category-artist" style={{marginRight:16}}>Product Color</span>
                                                <div className ="note-bg-artist gray">
                                                    <span className="note-text blue" >{props.productDetails.color_name}</span>
                                                </div>
                                        </Col> : ""
                                    }
                                        {props.productDetails.similar_color != "" ?
                                            <Col style={{display:"flex",alignItems:"center"}}>
                                                <span className="category-artist" style={{marginRight:16}}>Closest Color Match</span>
                                                <div className ="note-bg-artist gray">
                                                    <span className="note-text blue" >{props.productDetails.similar_color}</span>
                                                </div>
                                            </Col> : ""}
                                    </Row>
                                    : "" }

                                    {/* Product Segments */}
                                    {props.productDetails.segmented != undefined ?
                                    <>
                                        {props.productDetails.segmented != "" ?
                                        <Row style={{marginTop:30}}>
                                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                                <span className="category-artist" style={{marginRight:16}}>Segmented Model</span>
                                                <div className ="note-bg-artist gray">
                                                    <span className="note-text blue" >{props.productDetails.segmented ? 'Yes': 'No'}</span>
                                                </div>
                                        </Col>
                                        </Row> : ""

                                        }

                                     {props.productDetails.model_segments && props.productDetails.model_segments.length > 0 ?
                                        <Row style={{marginTop:30}}>
                                                <Col style={{display:"flex",alignItems:"center"}}>
                                                    <span className="category-artist" style={{marginRight:16}}>Model Segments</span>
                                                    <div className ="note-bg-artist gray">
                                                        <span className="note-text blue" >{props.productDetails.model_segments.map((segment, index) => {
                                                            if(index>0){
                                                                return ", "+segment
                                                            }
                                                            else {
                                                                return segment
                                                            }
                                                        })}</span>
                                                    </div>
                                            </Col>
                                        </Row>: ""}
                                          {/* Segment Files uploaded by customer */}
                                        {props.productDetails.segment_photos && props.productDetails.segment_photos.length > 0 ?
                                        <Row style={{marginTop: 30}}>
                                            <Col span={24} style={{marginBottom: 10}}>
                                                <span className="category-artist">Segment Photos Uploaded By Customer</span>
                                            </Col>
                                            {props.productDetails.segment_photos.map((x)=>{
                                            return( <Col>
                                                <Card className="product-artist-card">
                                                <Image
                                                    className="product-photo-artist" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(x.uid + '/' + x.name)}
                                                />
                                                </Card>
                                            </Col>
                                            )
                                            })
                                            }

                                    </Row> : ""}
                                    </>
                                    : "" }

                                    {props.componentInfo && props.componentInfo.length > 0 ?
                                    <Row style={{marginTop:30,marginBottom: 16}}>
                                         <Col style={{display:"flex",alignItems:"center"}}>
                                         <span className="category-artist" style={{marginRight:16}}>Components</span>

                                        {props.componentInfo.map((component,index) => (
                                            <div className ="note-bg-artist gray" style={{marginRight: 5}}>
                                                <span className="note-text blue" >{component.name}</span>
                                            </div>
                                        ))}
                                        </Col>
                                    </Row> : ''}
                                    {console.log(props.productMaterialArr)}

                                    {!props.isConfigurable && props.componentInfo && props.componentInfo.length > 0 && props.componentInfo.map((component, index) => (
                                        <Row style={{marginBottom:16}}>
                                            <Col>
                                            <div className="note-text gray" style={{marginBottom:16, textTransform: 'capitalize'}}>
                                                {component.using_material_library == "1" ?
                                                <span>
                                                Material Selected from Material Library for {component.name}
                                                </span> : component.using_material_library == "0" ?
                                                <span>
                                                Material Uploaded for {component.name}
                                                </span> : ''
                                                }
                                            </div>
                                            <Row>
                                                <Col style={{display:"flex",alignItems:"baseline",marginBottom: 8}}>
                                                <div className="category-artist medium" style={{marginRight: 8}}>
                                                    Category
                                                </div>
                                                <div className ="note-bg-artist gray" style={{marginRight: 5}}>
                                                    {component.using_material_library == "0" ?
                                                    <span className="note-text blue" >{props.productMaterialArr && props.productMaterialArr[component.name] && props.productMaterialArr[component.name].componentMaterial}</span>:
                                                    <span className="note-text blue" >{props.productMaterialArr && props.productMaterialArr[component.name] && props.productMaterialArr[component.name]['category']}</span>
                                                    }
                                                </div>
                                            </Col>
                                            </Row>
                                            <Row style={{display:"flex"}}>
                                            <Col>
                                                <Card className="product-artist-card">
                                                {props.productMaterialArr && component.using_material_library == "1" && props.productMaterialArr[component.name] && props.productMaterialArr[component.name].renders && props.productMaterialArr[component.name].renders.data && props.productMaterialArr[component.name].renders.data && props.productMaterialArr[component.name].renders.data.thumbnail ?
                                                    <Image
                                                    preview={{
                                                    visible: props.imageVisible[component.name],
                                                    maskClassName: 'customize-mat-icon',
                                                    mask: (
                                                    <span>
                                                        {/* <EyeOutlined  style={{marginRight: 20,fontSize: 24}} onClick={() => props.toggleVisible(component)}/> */}
                                                        <DownloadOutlined  style={{fontSize: 24}} onClick={() => window.location.href = props.productMaterialArr[component.name]['input_bundle']}/>
                                                    </span>
                                                    ),
                                                    onVisibleChange: props.changeVisibility,
                                                    }}
                                                    className="product-photo-artist" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.productMaterialArr[component.name]['id'] + '/' + encodeURIComponent(props.productMaterialArr[component.name].renders.data.thumbnail)}
                                                /> : (props.productMaterialArr && component.using_material_library == "1" && props.productMaterialArr[component.name] && props.productMaterialArr[component.name].material_files) ?
                                                <Image
                                                    preview={{
                                                    visible: props.imageVisible[component.name],
                                                    maskClassName: 'customize-mat-icon',
                                                    mask: (
                                                    <span>
                                                        <DownloadOutlined  style={{fontSize: 24}} onClick={() => window.location.href = props.productMaterialArr[component.name]['input_bundle']}/>
                                                    </span>
                                                    ),
                                                    onVisibleChange: props.changeVisibility,
                                                    }}
                                                    className="product-photo-artist" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(props.productMaterialArr[component.name].material_files[0].uid + '/' + props.productMaterialArr[component.name].material_files[0].name)}
                                                />
                                                :
                                                (props.productMaterialArr && component.using_material_library == "0" && props.productMaterialArr[component.name]) ?
                                                <Image
                                                    preview={{
                                                    visible: props.imageVisible[component.name],
                                                    maskClassName: 'customize-mat-icon',
                                                    mask: (
                                                    <span>
                                                        <EyeOutlined  style={{fontSize: 24}}/>
                                                    </span>
                                                    ),
                                                    onVisibleChange: props.changeVisibility,
                                                    }}
                                                    className="product-photo-artist" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI +  encodeURIComponent(props.productMaterialArr[component.name].componentImages[0].uid + '/' + props.productMaterialArr[component.name].componentImages[0].name)}
                                                /> :''}

                                                </Card>
                                            </Col>
                                            </Row>
                                            </Col>
                                        </Row>
                                        )) }

                                    {/* Product Materials */}
                                    {props.componentInfo && props.componentInfo.length == 0 && props.productDetails.materials && props.productDetails.materials.length > 0 ?
                                    <Row style={{marginTop:30}}>
                                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px'}}>
                                                <span className="category-artist" style={{marginRight:16}}>Product Materials</span>
                                                <div className ="note-bg-artist gray">
                                                    <span className="note-text blue" >{props.productDetails.materials.map((material, index) => {
                                                        if(index>0){
                                                            return ", "+material
                                                        }
                                                        else {
                                                            return material
                                                        }
                                                    })}</span>
                                                </div>
                                        </Col>
                                    </Row>: ""
                                }

                                    {/* Material Files uploaded by customer */}
                                    {material_files_urls && material_files_urls.length > 0 ?
                                    <Row>
                                        <Col span={24} style={{marginTop: 30, marginBottom: 20}}>
                                            <span className="category-artist" style={{marginRight:16, fontSize: 18}}>Materials Uploaded by Customer</span>
                                        </Col>
                                        {material_files_urls.map((x)=>{
                                           return( <Col>
                                            <Card className="product-artist-card">
                                            {x.toLowerCase().includes('jpg') || x.toLowerCase().includes('jpeg') || x.toLowerCase().includes('png') || x.toLowerCase().includes('svg') ?
                                                <Image
                                                className="product-photo-artist" src={x}
                                                />
                                                :
                                                <a href={x} target='blank'>
                                                <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                <img
                                                className="product-photo-artist" src={'/img/file-icon.png'}
                                                />
                                                </Tooltip>
                                                </a>    }
                                            </Card>
                                          </Col>
                                           )
                                        })
                                        }

                                    </Row> : ""}
                                    {props.libraryMaterials && props.libraryMaterials.length > 0 ?
                                    <div style={{marginBottom: 30}}>
                                        <Row>
                                        <Col span={24} style={{marginTop: 30, marginBottom: 20}}>
                                            <span className="category-artist" style={{marginRight:16, fontSize: 18}}>Selected Materials from Material Library</span>
                                        </Col>
                                        {props.libraryMaterials.map(mat => (
                                            <Col>
                                                <Card className="product-artist-card">
                                                {mat.renders.data && mat.renders.data.thumbnail &&
                                                <Image
                                                    preview={{
                                                        visible: false,
                                                        maskClassName: 'customize-mat-icon',
                                                        mask: (
                                                        <div className='manrope f-12' style={{textAlign: 'center'}}>
                                                            <div>{mat.name}</div>
                                                            <div><DownloadOutlined  style={{fontSize: 24, marginTop: 10}}/></div>
                                                        </div>
                                                        ),
                                                      }}
                                                    onClick={() => window.location.href =ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.MATERIAL_FILE_URI + mat.id + '/' +  mat.id + '.zip'}
                                                    className="product-photo-artist" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.MATERIAL_FILE_URI + mat.id + '/' +  encodeURIComponent(mat.renders.data.thumbnail)}
                                                    />}

                                                </Card>
                                            </Col>

                                        ))}
                                        </Row>
                                    </div>: ''}
                                    {props.productDetails.category_type ?
                                    <Row  style={{marginTop: 10}}>
                                        <Col span={24} style={{display:"flex",alignItems:"center"}}>
                                            <span className="category-artist" style={{marginRight:16}}>Bedding Type</span>
                                            {FileConstants.HIDDEN_CATEGORY[props.productDetails.category].map((cat, index) => (
                                                cat == props.productDetails.category_type ?
                                                <div className ="note-bg-artist gray">
                                                    <span className="note-text gray">
                                                        {cat}
                                                    </span>
                                                </div> : ''
                                            ))}
                                        </Col>
                                        {props.productDetails.category_attachment && props.productDetails.category_attachment.length > 0 ?
                                        <Col span={24} style={{marginTop: 10, display: 'flex'}}>
                                            {category_attachment}
                                        </Col>
                                        // : props.productDetails.category_type == "Bed Frame Only" ?
                                        // <Col span={24} style={{marginTop: 10}}>
                                        //     <Col>
                                        //         <Card className="product-artist-card">
                                        //         <Image
                                        //         className="product-photo-artist" src={Object.values(FileConstants.HIDDEN_CATEGORY[props.productDetails.category])[0][0].image}
                                        //         />
                                        //         </Card>
                                        //     </Col>
                                        // </Col>
                                        : ''}
                                    </Row>:
                                    FileConstants.HIDDEN_CATEGORY[props.productDetails.category] ?
                                    <Row style={{marginTop: 10,marginLeft:'0px'}}>
                                        <Col span={24} style={{display:"flex",alignItems:"center"}}>
                                        <span className="category-artist" style={{marginRight:16}}>Bedding Type</span>
                                            <div className ="note-bg-artist gray">
                                                <span className="note-text gray">
                                                    White Mattress and Pillows
                                                </span>
                                            </div>
                                        </Col>
                                        <Col span={24} style={{marginTop: 10}}>
                                            <Col>
                                                <Card className="product-artist-card">
                                                    <Image
                                                    className="product-photo-artist" src='/img/simple_bedding.png'
                                                    />
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Row> : FileConstants.HIDDEN_CATEGORY[props.productDetails.parent_category] ?
                                    <Row style={{marginTop: 10,marginLeft:'0px'}}>
                                        <Col span={24} style={{display:"flex",alignItems:"center"}}>
                                        <span className="category-artist" style={{marginRight:16}}>Bedding Type</span>
                                            <div className ="note-bg-artist gray">
                                                <span className="note-text gray">
                                                    {props.productDetails.category}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col span={24} style={{marginTop: 10}}>
                                            <Col>
                                                <Card className="product-artist-card">
                                                    <Image
                                                    className="product-photo-artist" src={'/img/bedframe.jpg'}
                                                    />
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Row>: ''}
                                    {product_photos.length > 0 ?
                                        <React.Fragment>
                                            <Col span={24} style={{marginTop: 30, marginBottom: 20}}>
                                                <span className="category-artist" style={{marginRight:16, fontSize: 18}}>Reference Photos</span>
                                            </Col>
                                            <Row style={{display:"flex"}}>
                                                {product_photos}
                                            </Row>
                                        </React.Fragment>
                                    : ""}



                            </TabPane>
                        </Tabs>

                        </Col>

                    </Row>

                    <div style={{ height: "50px" }}></div>
                    <hr />
                </Col>
                {/* Following is QA Pending Section which is hidden for now, might gets re-enabled later on  */}
                {/* <Col span={6}>
                        <h5 style={{marginTop: "18px"}}>QA Pending Products</h5>
                        {props.productReducer.products.filter((ele) => ele.model_status==4).map((ele, i) => {
                            return <Link key={i} to={`/admin_products/${ele.id}`} style={{marginBottom: "5px"}}>
                                <Card bodyStyle={{padding: "12px"}} style={{marginTop: 16 }} loading={false}>
                                    <Meta
                                        avatar={
                                            <Avatar size={60} src={ele.thumbnail || require("../../../../assets/images/chair.png")}/>
                                        }
                                        title={ele.name}
                                        description={ele.category}
                                    />
                                </Card>
                            </Link>
                        })}
                    </Col> */}
            </Row>

            {((props.productReducer.product.model_status == "9" || props.productReducer.product.model_status == "-3" || props.productReducer.product.model_status == "3") && props.showReview) ? <ReviewProduct approveTxt={('Approve')} setApproveToCustomerLibrary={setApproveToCustomerLibrary} platform={props.productReducer.product.platform} rejectTxt="Reject for Fix" notShowStatusPopup={true} onApprove={props.onApprove} onReject={props.onReject} model_status={props.productReducer.product.model_status} referenceFiles={props.productReducer.product.reference_files} rejectionDetails={props.productReducer.product.rejection_details} assigned_artist={(props.productReducer.product.assigned_artist !== undefined) ? props.productReducer.product.assigned_artist : null} onSendToCustomer={props.onSendToCustomer}></ReviewProduct> : ''}
            {(props.productReducer.product.model_status == "-5" && props.showReview) ? <ReviewProduct approveTxt='Deny Rejection' rejectTxt="Assign to artist" onApprove={props.onApprove} platform={props.productReducer.product.platform} onReject={props.onReject} referenceFiles={props.productReducer.product.reference_files} model_status={props.productReducer.product.model_status} rejectionDetails={props.productReducer.product.rejection_details} assigned_artist={(props.productReducer.product.assigned_artist !== undefined) ? props.productReducer.product.assigned_artist : null} onSendToCustomer={props.onSendToCustomer}></ReviewProduct> : ''}
            <Modal bodyStyle={{ paddingTop: "2px" }}
                title="Upload your Custom Renders"
                visible={props.renderOverrideModal}
                width={800}
                centered={true}
                onCancel={() => props.cancelRenderOverrideModal()}
                footer={[
                    <Button key="back" className="modal-okay" onClick={props.handleSubmitRenderReplaceForm}>
                        <div className="modal-okay-text">Replace</div>
                    </Button>
                ]}
            >
                <div>
                <Form className="input-form">
                <Row type="flex">
                    <Col span={12} style={{padding: 12}}>
                        <p className="text-danger">All of the current perspective renders will be replaced with the set of renders you upload.</p>
                        <div className="d-flex" style={{justifyContent:"center"}}>
                            <div style={{textAlign:"center",minWidth:"100%"}}>
                                <Form.Item name={'perspective_images'} label="Perspective Images" colon={false}>
                                {getFieldDecorator('perspective_images', {
                                    valuePropName: 'perspective_images',
                                    getValueFromEvent: e => e && e.fileList,
                                    rules: [{ required: true, message: 'Please upload perspective renders to replace' }]
                                })(
                                    <Upload
                                        accept=".jpg,.jpeg"
                                        {...Constants.getUploadProps(props.productDetails.platform)}
                                        multiple="true"
                                        listType="text"
                                        fileList = {props.perspectiveRenders}
                                        onChange = {props.handlePerspectiveRenderChange}
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                    >
                                    <div className="d-flex">
                                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                        <div className="ant-upload-text">
                                        <div className="up-info">Drop your Files</div>
                                        <div>Support: JPG</div>
                                        </div>
                                    </div>
                                    </Upload>
                                )}
                                </Form.Item>
                            </div>
                        </div>
                        <p>Please drop your images in the order you want them to appear on product page.</p>
                    </Col>
                    <Col span={12} style={{padding: 12}}>
                        <p className="text-danger">Your current dimensional render will be replaced with the render you upload.</p>
                        <div className="d-flex" style={{justifyContent:"center"}}>
                            <div style={{textAlign:"center",minWidth:"100%"}}>
                                <Form.Item name={'dimensional_render'} label="Dimensional Render" colon={false}>
                                {getFieldDecorator('dimensional_render', {
                                    valuePropName: 'dimensional_render',
                                    getValueFromEvent: e => e && e.fileList,
                                })(
                                    <Upload
                                        accept=".jpg,.jpeg"
                                        {...Constants.getUploadProps(props.productDetails.platform)}
                                        multiple="true"
                                        listType="text"
                                        fileList = {props.dimensionalRender}
                                        onChange = {props.handleDimensionalRenderChange}
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                    >
                                    <div className="d-flex">
                                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                        <div className="ant-upload-text">
                                        <div className="up-info">Drop your Files</div>
                                        <div>Support: JPG</div>
                                        </div>
                                    </div>
                                    </Upload>
                                )}
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                    </Row>
                    {(props.uploadError != '')?
                        <div className="ant-legacy-form-explain" style={{color: "#ff4d4f", textAlign: "center"}}>{props.uploadError}</div>
                    :""}
                </Form>
                </div>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Model Override"
                    visible={props.productModelOverrideMadal}
                    width={500}
                    centered={true}
                    onCancel={() => props.cancelProductModelOverrideMadal()}
                    footer={[
                        <Button disabled={props.uploadError == "" ? false : true} key="back" className="modal-okay" onClick={props.handleSubmitModelOverrideForm}>
                            <div className="modal-okay-text">Regenerate Assets</div>
                        </Button>
                    ]}
                >
                    <div>
                    <Form className="input-form">
                    <Row type="flex">
                        <Col span={24} style={{padding: 12}}>
                            <p className="text-danger">All QA Assets (renders, glb and usdz ) will be regenerated with the product model you upload. You will be notified via email to review the assets again once processed.</p>
                            <div className="d-flex" style={{justifyContent:"center"}}>
                                <div style={{textAlign:"center",minWidth:"100%"}}>
                                    <Form.Item name={'override_model'} label="Product Model" colon={false}>
                                    {getFieldDecorator('override_model', {
                                        valuePropName: 'override_model',
                                        getValueFromEvent: e => e && e.fileList,
                                        rules: [{ required: true, message: 'Please upload perspective renders to replace' }]
                                    })(
                                        <Upload
                                            accept=".zip"
                                            {...Constants.getUploadProps(props.productDetails.platform)}
                                            multiple="true"
                                            listType="text"
                                            fileList = {props.overideProductModel}
                                            beforeUpload={async (file) => {
                                                props.setUploadError("");
                                                if(file.name != props.params.id + '.zip'){
                                                  props.setUploadError("Invalid archive name, archive must be named " + props.params.id + '.zip');
                                                  props.setModelArchiveValid(false);
                                                  return false;
                                                }
                                                else {
                                                    var validation = await props.checkFolderStructure(file)
                                                    props.setModelArchiveValid(validation);
                                                    return validation
                                                }

                                            }}
                                            onChange = {props.handleOverrideModelChange}
                                            className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                        >
                                        <div className="d-flex">
                                            <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                            <div className="ant-upload-text">
                                            <div className="up-info">Drop your Files</div>
                                            <div>Support: ZIP</div>
                                            </div>
                                        </div>
                                        </Upload>
                                    )}
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>

                        </Row>
                        {(props.uploadError != '')?
                            <div className="ant-legacy-form-explain" style={{color: "#ff4d4f", textAlign: "center"}}>{props.uploadError}</div>
                        :""}
                    </Form>
                    </div>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Custom Lighting"
                    visible={props.productCustomLightingMadal}
                    width={900}
                    centered={true}
                    onCancel={() => props.cancelProductCustomLightingMadal()}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={(e) => props.handleSubmitProductCustomRigForm(e, false, lightJobOutput )}>
                            <div className="modal-okay-text">
                                {(props.addNewRig == true)?'Regenerate with New Rig':'Regenerate'}
                            </div>
                        </Button>
                    ]}
                >
                    <div>
                    <Form className="input-form">
                    <Row type="flex">
                        <Col span={24} style={{padding: 12}}>
                        {lightJobOutput == 'test_render' ?
                        <p className="text-danger">A Single test render with the selected perspective and selected rig will be generated. This will not Override the already generated QA images.</p>
                        : <p className="text-danger">All Preview Renders will be regenerated with the new lighting rig. You will be notified via email to review the renders again once processed.</p> }
                        </Col>
                        <Col span={8} style={{padding: 12}}>
                            <Form.Item name="custom_lighting_rig" label={"Choose from Existing Rigs"}  colon={false}>
                                {getFieldDecorator('custom_lighting_rig', {
                                    initialValue: props.rigsList[0].id,
                                    rules: [
                                        { required: true, message: "Please select Custom Lighting Rig"}
                                    ],
                                })(
                            <Select
                                style={{color:"#333333"}}
                                defaultValue={props.rigsList[0].id}
                                disabled={(props.addNewRig==true)}
                            >
                                {props.rigsList.map((ele, index) => {
                                    return <Option key={index} value={ele.id}>{ele.name}</Option>
                                })}
                            </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{padding: 12}}>
                            <h1 style={{marginBottom: 7, fontWeight: 500, fontSize: 16, marginTop: 13}}><b>Generate</b></h1>
                            <Radio.Group defaultValue='test_render' onChange = {(e) => setLightJobOutput(e.target.value)}>
                                <Radio value="test_render">Single Test Render</Radio>
                                <Radio value="all_renders">All Perspective Renders</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={8} style={{padding: 12}}>
                            {lightJobOutput=='test_render' ?
                                <Form.Item name="render_perspective" label={"Choose the Perspective"}  colon={false}>
                                {getFieldDecorator('render_perspective', {
                                    initialValue: 'HeadOn',
                                })(
                            <Select
                                style={{color:"#333333"}}
                                defaultValue='HeadOn'
                                disabled={lightJobOutput!='test_render'}
                            >

                            <Option key='1' value='HeadOn'>Head On</Option>
                            <Option key='2' value='Side'>Side</Option>
                            <Option key='3' value='Back'>Back</Option>
                            <Option key='4' value='FrontAngled'>Front Angled</Option>
                            <Option key='5' value='BackAngled'>Back Angled</Option>
                            <Option key='6' value='TopDown'>Top Down</Option>

                            </Select>
                                )}
                            </Form.Item>
                        : ""}
                        </Col>
                    </Row>
                        <a style={{marginLeft: 12}} onClick={() => props.setAddNewRig(!props.addNewRig)}>{(props.addNewRig == true)?'Discard':'Add New Rig'}</a>
                        {
                            (props.addNewRig == true)?
                            <Row>
                                <Col span={12} style={{padding: 12}}>
                                    <Form.Item name="name" label="New Rig Name" colon={false}>
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input Rig name' }],
                                        })(
                                            <Input
                                                placeholder="Please type your lighting rig name"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{padding: 12}}>
                                    <div className="d-flex" style={{justifyContent:"center"}}>
                                        <div style={{textAlign:"center",minWidth:"100%"}}>
                                            <Form.Item name={'rig_file'} label="Rig Archive" colon={false}>
                                            {getFieldDecorator('rig_file', {
                                                valuePropName: 'rig_file',
                                                getValueFromEvent: e => e && e.fileList,
                                                rules: [{ required: true, message: 'Please upload rig archive' }]
                                            })(
                                                <Upload
                                                    accept=".zip"
                                                    {...Constants.getUploadProps(props.productDetails.platform)}
                                                    multiple="true"
                                                    listType="text"
                                                    fileList = {props.lightingRigFile}
                                                    onChange = {props.handleLightingRigFileChange}
                                                    className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                                >
                                                <div className="d-flex">
                                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                                    <div className="ant-upload-text">
                                                    <div className="up-info">Drop your Files</div>
                                                    <div>Support: ZIP</div>
                                                    </div>
                                                </div>
                                                </Upload>
                                            )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            :""
                        }


                    </Form>
                    </div>
                    {(props.uploadError != '')?
                        <div className="ant-legacy-form-explain" style={{color: "#ff4d4f", textAlign: "center"}}>{props.uploadError}</div>
                    :""}
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Upload your Custom Renders"
                    visible={props.renderOverrideModalConfirm}
                    width={500}
                    centered={true}
                    onCancel={() => props.cancelRenderOverrideModalConfirm()}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={(e) => props.handleSubmitRenderReplaceForm(e, true)}>
                            <div className="modal-okay-text">{(props.renderOverrideLoader == true)? <span><LoadingOutlined spin /> Replacing</span>:<span>Confirm</span>}</div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to replace orignal QA renders with your custom renders?</h4>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Model Override"
                    visible={props.productModelOverrideMadalConfirm}
                    width={500}
                    centered={true}
                    onCancel={() => props.cancelProductModelOverrideMadalConfirm()}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={(e) => props.handleSubmitModelOverrideForm(e, true)}>
                            <div className="modal-okay-text">{(props.modelOverrideLoader == true)? <span><LoadingOutlined spin /> Submitting Request</span>:<span>Confirm</span>}</div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to regenerate QA Assets with updated model?</h4>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Custom Lighting"
                    visible={props.productCustomLightingMadalConfirm}
                    width={500}
                    centered={true}
                    onCancel={() => props.cancelProductCustomLightingMadalConfirm()}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={(e) => props.handleSubmitProductCustomRigForm(e, true, lightJobOutput)}>
                            <div className="modal-okay-text">{(props.lightingOverrideLoader == true)? <span><LoadingOutlined spin /> Submitting Request</span>:<span>Confirm</span>}
                            </div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to regenerate QA Assets with new lighting Rig?</h4>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Regenerate Assets Request"
                    visible={props.productRegenerateConfirm}
                    width={500}
                    centered={true}
                    onCancel={() => props.setProductRegenerateConfirm(false)}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={() => props.regenerateModelConfirm()}>
                            <div className="modal-okay-text">{(props.modelRegernateLoader == true)? <span><LoadingOutlined spin /> Submitting Request</span>:<span>Confirm</span>}
                            </div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to regenerate QA Assets?</h4>
                </Modal>

                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Regenerate Assets Request"
                    visible={props.generateRendersConfirmModal}
                    width={500}
                    centered={true}
                    onCancel={() => props.setGenerateRendersConfirmModal(false)}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={() => props.generateRendersConfirm()}>
                            <div className="modal-okay-text">{(props.modelRegernateLoader == true)? <span><LoadingOutlined spin /> Submitting Request</span>:<span>Confirm</span>}
                            </div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to generate all perspectives?</h4>
                </Modal>


                {/* Modal for image showing  */}

                <Modal
                className="rejection-image"
                style={{width:'100vw'}}
                closeIcon={
                    <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
                    style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}

                    />
                }
                footer={false} visible={showImage} onCancel={() => {setImageUrl(''); setShowImage(false); }}>
                    <a href={imageUrl} target="_blank" >
                        <img src={imageUrl} style={{width:'100%', height:"100%"}}/>
                    </a>
                </Modal>

                {/* Modal for rejection history  */}
                <Modal

                closeIcon={
                    <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
                    style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}
                    />
                }
                visible={showDetailedRejection} onCancel={handleCancel} footer={false} width={1000}>
                    <div style={{fontSize: 24, color: '#141414'}}>
                            Rejection Comments
                    </div>
                    <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}}>
                        <div style={{display:'grid'}}>
                            {detailed_rejection_data}
                        </div>

                    </div>

                </Modal>

                {/* Modal showing item's complete history */}
                <Modal visible={props.itemHistoryModal}
                maskClosable={true}
                closeIcon={
                    <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
                    style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}
                    onClick={() => props.setItemHistoryModal(false)}
                    />

                }
                onCancel={() => props.setItemHistoryModal(false)}
                footer={false}
                width={1000}>
                    <Row>
                        <Col span={18} style={{fontSize: 24, color: '#141414'}}>
                                Activity Timeline
                        </Col>
                        <Col span={5}>
                        <Dropdown overlay={activity_sort_menu} trigger={['click']} style={{backgroundColor: '#FAFAFC', borderRadius: 2}} >
                            <Button id="item-modal-dropdown" style={{float: 'right', marginRight: 10}}>
                                <span >{props.activitySortAscending == true ? "Oldest Activity First" : "Most Recent Activity First"}<DownOutlined/></span>
                            </Button>
                        </Dropdown>
                        </Col>
                    </Row>

                    <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}} >
                        <div>
                            {item_history_data}
                        </div>

                    </div>

                </Modal>

                {/* Modal for successful reset of retries */}
                <Modal
                    className="modal-share-success"
                    width={620}
                    visible={props.showRetriesModal}
                    closable={false}
                    footer={[
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={() => props.setShowRetriesModal(false)}>
                            <div className="modal-okay-text">Okay</div>
                        </Button>
                    ]}
                    centered={true}
                    >
                    <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                        <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                        <div>
                            <h2 className="success-modal-text">Retries reset successfully!</h2>
                            <p className="modal-text">The artist has been successfully given {max_retries} more tries to get this model right.</p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={props.embedModal}
                    className="modal-share"
                    onCancel={props.handleEmbedCancel}
                    footer={[
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={props.handleEmbedOk}>
                            <div className="modal-okay-text">Embed</div>
                        </Button>
                    ]}
                >
                    <div style={{padding:"16px 6px 0 14px"}}>
                      <h2 className="share-modal-heading">Embedding Details</h2>
                    <span>

                        <h4 className="modal-text">URL for Public Viewer</h4>
                        <Paragraph className="modal-radio-anchor bg" copyable={{ text: `https://app.all3d.ai/product-details/${props.params.id}` }}>
                            <Link to={'/product-details/' + props.params.id} {...props} target="_blank">
                                Public Viewer
                            </Link>
                        </Paragraph>

                        {props.productReducer.product['360'] &&
                            <span>
                                <br />
                                <h4 className="modal-text">URL for Product 360</h4>
                                <Paragraph className="modal-radio-anchor bg" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_360/${props.params.id}` }}>
                                    <Link to={'/product_public_viewer/product_360/' + props.params.id} target="_blank">
                                        Product 360
                                    </Link>
                                </Paragraph>

                            </span>}


                            {/* {(props.productReducer.product.need_to_model == "ar"  || props.productReducer.product.need_to_model == "high_res_and_ar") ? */}
                            <span>
                                <br />
                                <h4 className="modal-text">URL for Product 3D Model</h4>
                                <Paragraph className="modal-radio-anchor bg" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_3d_model/${props.params.id}` }}>
                                    <Link to={'/product_public_viewer/product_3d_model/' + props.params.id} target="_blank">
                                        Product 3D Model
                                    </Link>
                                </Paragraph>

                            </span>
                             {/* : ''} */}

                        <br />
                        <br />
                        <h4 className="modal-text">Embed in your site</h4>
                        <p className="modal-radio-anchor" style={{color:"#999999"}} id="iframe-text">{'<iframe allow-popups style="width: 700px; height: 700px;" src="' + 'http://app.all3d.ai/product-details/' + props.params.id + '"></iframe>'}</p>
                    </span>
                    </div>
                </Modal>

                {/* Modal for successful product status change to 3 */}
                <Modal
                    className="modal-share-success"
                    width={620}
                    visible={props.adminModelStatusChange}
                    closable={false}
                    footer={[
                        <div className="justify-in-center">


                        <Button key="back" style={{textAlign:"center"}} className="modal-okay font-14" onClick={() => window.location.href = '/admin_products'}>
                            Okay
                        </Button>
                        </div>
                    ]}
                    centered={true}
                    >
                    <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                        <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                        <div>
                            <h2 className="success-modal-text">Product Moved to Admin QA</h2>
                            <p className="modal-text">The product has been successfully moved to Admin QA.</p>
                        </div>
                    </div>
                </Modal>

                {/* Modal for material nomination */}
                <Modal visible={props.showNominateMaterialsModal}
                maskClosable={false}
                closable={false}
                onCancel={() => props.setShowNominateMaterialsModal(false)}
                footer={[
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button className="modal-okay-gray square font-14" onClick={() => (props.setShowNominateMaterialsModal(false), setCancelConfirmation(true))}>
                            Cancel
                        </Button>
                        <Button style={{textAlign:"center"}} disabled={approvingProduct} className="modal-okay square font-14" onClick={(e) => {showShareImagesModal(e)}}>
                            Next
                        </Button>
                    </div>

                ]}
                width={1000}
                centered={true}>
                    <div className="approve-product-modal">
                        <h2 style={{textAlign: 'center', color: '#555555'}}>
                            Select and Share Materials with Customer
                        </h2>
                        <p style={{textAlign: 'center', font: 18}}>
                            Select the Product Materials you want to share with the customer. The selected Materials will be added to the customer's Material Library.
                        </p>

                        <div style={{marginTop: 30}}>
                            <TreeSelect className="manrope f-14 black-55"
                                showArrow
                                style={{ width: '100%',height:"auto" }}
                                dropdownStyle={treeSelectStyle}
                                placeholder="Select your product model materials"
                                allowClear
                                multiple
                                treeDefaultExpandAll
                                treeData= {props.nominableMaterials}
                                // defaultValue= {props.preNominatedMaterials}
                                onChange={updateNominatedMaterials}
                                notFoundContent={<span><h5>No Result Found</h5></span>}
                            >
                            </TreeSelect>
                        </div>

                    </div>

                </Modal>

                {/* Modal for before approve actions */}
                <Modal visible={shareImagesModal}
                maskClosable={false}
                closable={false}
                onCancel={() => setShareImagesModal(false)}
                footer={[
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button className="modal-okay-gray square font-14" onClick={() => (setShareImagesModal(false), setCancelConfirmation(true))}>
                            Cancel
                        </Button>
                        <Button style={{textAlign:"center"}} disabled={approvingProduct} className="modal-okay square font-14" onClick={(e) => {approveProduct(e)}}>
                            Share Images {approvingProduct ? <LoadingOutlined spin/> : ""}
                        </Button>
                    </div>

                ]}
                width={1000}
                centered={true}>
                    <div className="approve-product-modal" style={{height: '75vh', overflowY: 'scroll'}}>
                        <h2 style={{textAlign: 'center', color: '#555555'}}>
                            Approve and Share Images with Customer
                        </h2>
                        <p style={{textAlign: 'center', font: 18}}>
                            Select the Images you want to share with the customer. You can also change the order of how Images appear to the customer.
                        </p>

                        <div className ="note-bg-artist" style={{ alignItems: 'center', display: 'flex', marginBottom: 10, marginRight: 10}}>
                            <span className="note-text gray" style={{fontSize: 14}}><span style={{fontWeight: "bold", color: '#262626'}}>Note: </span>Change the order of how the images appear on the customer side by just clicking and dragging the image tiles to their new position.</span>
                        </div>
                        <div>
                            <ImageOrdering props={props} images={orderedImages} setImages={setOrderedImages}/>
                        </div>

                    </div>

                </Modal>

                {/* Modal for images shared success */}
                <Modal
                    className="image-share-success"
                    width={620}
                    visible={productImagesShared}
                    closable={false}
                    footer={false}
                    centered={true}
                    >
                    <div style={{padding:"30px 48px 0 48px",textAlign:"center"}}>
                        <img src='/img/success.png' style={{width:"60px",height:"60px",marginBottom:"40px"}} alt="Alert"/>
                        <div>
                            <h2 className="success-modal-text" style={{fontSize: 32, fontWeight: 500, color: '#555555'}}>Images shared successfully!</h2>
                            <p className="modal-text" style={{fontSize: 16, fontWeight: 400, color: '#777777'}}>Your approved images have been shared successfully with the customer.</p>
                        </div>
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square" onClick={() => window.history.back()}>
                            <div className="modal-okay-text">Okay</div>
                        </Button>
                    </div>
                </Modal>

                {/* Modal for Cancel confirmation */}
                <Modal
                    className="cancel-confirmation"
                    width={620}
                    visible={cancelConfirmation}
                    closable={false}
                    footer={false}
                    centered={true}
                    >
                    <div style={{padding:"30px 48px 0 48px",textAlign:"center"}}>
                        <div>
                            <h2 className="success-modal-text" style={{fontSize: 24, fontWeight: 500, color: '#555555'}}>Model will remain in QA if you cancel...</h2>
                            <p className="modal-text" style={{fontSize: 16, fontWeight: 400, color: '#777777'}}>If you cancel and not  share with customer, the model will remain in admin QA. You will have to approve it again to share.<br />Are you sure you want to  cancel?</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button style={{ marginRight: 15}} className="modal-okay-gray square font-14" onClick={() => {window.history.back()}}>
                            Cancel
                        </Button>
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => (setCancelConfirmation(false), setShareImagesModal(true))}>
                            Stay on this page
                        </Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                className="silo-success-modal"
                centered={true}
                closable={false}
                width={800}
                onCancel={() => props.setSuccessConfig(false)}
                visible={props.successConfig}
                bodyStyle={{padding: 0}}

                footer={[
                <span className="justify-in-center">
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => window.location.reload()}>
                    Okay
                    </Button>
                </span>
                ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center"}}>
                        <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                    </div>
                    <div style={{textAlign: "center",margin: "20px auto"}}>
                        <h2 className="manrope f-24 black-14 justify-in-center">Model Sent to Customer Successfully!</h2>
                        <p className="manrope f-16 grey-77">Your have successfully reviewed and approved the clay and checkerboard model.</p>
                        <p className="manrope f-16 grey-77">The model and its materials (if any) have been sent to the customer.</p>

                    </div>
                </div>
                </Modal>

                <GroupItemsModal
                    visible={groupItemsModal}
                    onCancel={() => {setGroupItemModal(false)}}
                    group_id={props.productDetails.group_id}
                    groupedProducts={props.groupedProducts}
                />

                <Modal
                className="silo-success-modal"
                centered={true}
                closable={false}
                width={800}
                onCancel={() => props.setApproveModal(false)}
                visible={props.approveModal}
                bodyStyle={{padding: 0}}

                footer={[
                <span className="justify-in-center">
                    <Button className="modal-okay-gray square font-14" onClick={props.cancelApproveModal}>
                    Not Now
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={props.reviewMaterial}>
                    Review Materials
                    </Button>
                </span>
                ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center"}}>
                        <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                    </div>
                    <div style={{textAlign: "center",margin: "20px auto"}}>
                        <h2 className="manrope f-24 black-14 justify-in-center">Model Approved Successfully!</h2>
                        <p className="manrope f-16 grey-77">Your have successfully reviewed and approved the clay and checkerboard model.</p>
                        <p className="manrope f-16 grey-77">Please proceed to review the materials, so the customer can be provided with the configurable model.</p>

                    </div>
                </div>
                </Modal>
                <Modal
                className="silo-success-modal"
                centered={true}
                closable={false}
                width={800}
                onCancel={() => props.setConfirmModal(false)}
                visible={props.confirmModal}
                bodyStyle={{padding: 0}}
                footer={[
                <span className="justify-in-center">
                    <Button className="modal-okay-gray square font-14" onClick={props.reviewMaterial}>
                    Review Material
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => props.setConfirmModal(false)}>
                    Yes, I'm Sure
                    </Button>
                </span>
                ]}>
                    <div style={{padding:"40px"}}>
                        <div style={{textAlign: "center",margin: "0px auto"}}>
                            <h2 className="manrope f-24 black-14 justify-in-center">Are you sure?</h2>
                            <p className="manrope f-16 grey-77">The customer will not receive the configurable model until you review both the model and the materials.</p>
                            <p className="manrope f-16 grey-77">Are you sure you don’t want to review the materials right now?</p>

                        </div>
                    </div>
                </Modal>
                <SuccessModal
                visible={props.overrideSuccess}
                heading={"Preview Render Replacement Request Successful!"}
                text={"Your request for replacement of Preview Renders has been successful. Please note that it may take a few seconds for the Preview Renders to be replaced and show up on the page."}
                footer={[
                   <div className="justify-in-center">
                       <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                        window.location.href = '/admin_products/' + props.params.id;
                        }}>
                           Okay
                       </Button>
                   </div>
                ]}

                />
                <WarningModal
                visible={showARWarning}
                onCancel={() => setShowARWarning(false)}
                heading={"AR Variant Already Completed"}
                text={"This AR variant has already been added to the original model. Please go to the original model to request any changes."}
                footer={[
                    <span className="justify-in-end">
                        <Button className="modal-okay-gray square font-14"
                            onClick={() => {
                                setShowARWarning(false);
                                window.location.href = '/admin_products';
                            }
                            }>
                            Back
                        </Button>
                        <Button className="modal-okay square font-14"
                            onClick={() => {
                                setShowARWarning(false);
                                window.location.href = '/admin_products/' + props.productDetails.immediate_parent_variant;
                            }
                            }>
                            Go to Original Model
                        </Button>
                    </span>
                ]}
                />

        </AdminMainLayout>
    );


}

export default Form.create({ name: 'render-override-form' })(ProductDetailsView);