
import { Empty } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';
import PanoramaViewer from '../../PanoramaViewer';

const LifeStyle360 = (props) => {

    const { baseURL, sceneId, before, after } = props

    return (
        <div style={{ width: '100%' }} className="display-flex-row manrope f-14 w-500" >
            <div style={{ borderRight: "0.01px solid #E3E3E3", width: "50%", paddingRight: 15 }} className="align-col-left">
                <p style={{ paddingLeft: 15, paddingTop: 12, paddingBottom: 2 }}>Before</p>
                <PanoramaViewer id={'360Camera'} customClass={'panorama-default-styles complaint'} threeSixtyUrl={baseURL + sceneId + '/' + before.replace('tiff', 'jpg')} />
            </div>
            <div className="align-col-left" style={{ width: "50%",  paddingRight: 15 }}>
                <p style={{ marginLeft:15, paddingTop: 12, paddingBottom: 2 }} >{ after!='rejected'? after? 'After' : 'In Progress': 'Reviewed & Resolved'}</p>
                {after === 'rejected' ? 
                    <div className='in-progress-paddings flex' >
                        <CheckCircleOutlined className="manrope f-14 set-svg-dims"/>
                    </div>    
                : after ? <PanoramaViewer id={'360Camera2'} customClass={'panorama-default-styles complaint'} threeSixtyUrl={baseURL + sceneId + '/' + after.replace('tiff', 'jpg')} /> :
                        <div className={`in-progress-paddings flex`} >
                            <img className="lifestyle-image-settings-custom h-100" src={(require("../../../../../assets/images/inProgress icon.png"))}></img>
                        </div>
                }
            </div>
        </div>

    )
}

export default LifeStyle360;