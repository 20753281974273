import React, { useContext } from 'react';
import FileConstants from '../../../../FileConstants';
import {
    Button, Row, Col, Tooltip, Divider, Card
} from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';


const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");


const ProductValidationState = () => {
    const { collaborateMode, access_level, validationState, openRejectModal, productData, projectID } = useContext(CollaborateQAContext);

    return (!collaborateMode && (([0, 1, 2].includes(validationState)) || ((validationState == -2) && (productData && productData.model_status != 4))) ?
        <Row className='model-inprogress-box'>
            {validationState == 2 ?
                <Row type="flex" style={{ justifyContent: "flex-start" }} className='w-100'>
                    <Col span={12}>
                        <div className='validation-uploaded-model-rejection-div'>
                            <Card title={<div><CloseCircleOutlined className='mr-4' /><span className='validation-uploaded-model-header-rejection'>{FileConstants.UploadValidationTitle[validationState]}</span></div>}>
                                <span className='manrope f-14 validation-uploaded-model-rejection'>
                                    {FileConstants.UploadValidationMessage[validationState]}
                                </span>
                                {(["co-owner", "owner"].includes(access_level) || (projectID && access_level == "edit")) ?
                                    <>
                                        <Divider />
                                        <div className='justify-in-end'>
                                            <Button className="modal-okay square font-12" onClick={openRejectModal}>
                                                Create a New Model
                                            </Button>
                                        </div>
                                    </> : ''}
                            </Card>
                        </div>
                    </Col>
                </Row>
                :
                ''}

            {(validationState == 2 || validationState == 0) ?
                <Row type="flex" style={{ justifyContent: "flex-start" }} className='w-100'>
                    <Col span={12}>
                        <div className={validationState == 0 ? 'validation-uploaded-model-warning-div' : validationState == 1 ? 'validation-uploaded-model-success-div' : ''}>
                            <Card title={
                                <div>
                                    {validationState == 0 ? <InfoCircleOutlined className='mr-4' /> : validationState == 1 ? <CheckCircleOutlined className='mr-4' /> : ''}
                                    <span className={validationState == 0 ? 'validation-uploaded-model-header-warning' : validationState == 1 ? 'validation-uploaded-model-header-success' : ''}>
                                        {
                                            FileConstants.UploadValidationTitle[validationState]
                                        }
                                    </span>
                                </div>}
                            >
                                <span className='manrope f-14 validation-uploaded-model-success'>
                                    {FileConstants.UploadValidationMessage[validationState]}
                                </span>
                            </Card>
                        </div>
                    </Col>
                </Row>
                :
                ''}
            {(validationState == -2) && (productData && productData.model_status != 4 && productData.model_status != 5 && !collaborateMode) ?
                <Row type="flex" style={{ justifyContent: "flex-start" }} className='w-100'>
                    <Col span={12}>
                        <div className={'validation-uploaded-model-warning-div'}>
                            <Card title={
                                <div>
                                    <ClockCircleOutlined className='validation-uploaded-model-header-warning' />
                                    <span className={'validation-uploaded-model-header-warning'}>
                                        Model is in progress
                                    </span>
                                </div>}>
                                <span className='manrope f-14 validation-uploaded-model-success'>
                                    Please wait while our system is processing your model
                                </span>
                            </Card>
                        </div>
                    </Col>
                </Row>
                :
                ''}
            {(productData && productData.model_status == 5) ?
                <Row type="flex" style={{ justifyContent: "flex-start" }} className='w-100'>
                    <Col span={12}>
                        <div className={'validation-uploaded-model-success-div'}>
                            <Card title={
                                <div>
                                     <CheckCircleOutlined className='mr-4' />
                                    <span className={'validation-uploaded-model-header-success'}>
                                        Model is Approved
                                    </span>
                                </div>}
                            >
                                <span className='manrope f-14 validation-uploaded-model-success'>
                                    This model is now in the customer's library.
                                </span>
                            </Card>
                        </div>
                    </Col>
                </Row>
                :
                ''}
        </Row> : '')

}

export default ProductValidationState;