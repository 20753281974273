import React, { useEffect, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Button, Input, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { listCollections, updateCollection } from '../../../../redux/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem("managed_customer_username");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const COMPANY_ID = localStorage.getItem("company_id");

const SceneNameInput = (props) => {
  const [sceneNames, setSceneNames] = useState([]);
  const [collectionNames, setCollectionNames] = useState([]);
  const { handleSubmit, setStep, loading } = props;
  const formRef = React.createRef();

  useEffect(() => {
    getSceneNames();
    getCollectionNames();
  }, []);

  const generatePayload = () => {
    const payload = {
      username: localStorage.getItem("username"),
    };
    if (FileConstants.isMSProvider && localStorage.getItem("managed_customer_username") != null) {
      payload.designed_for = localStorage.getItem("managed_customer_username");
    }
    if (localStorage.getItem("shared_entities") != undefined && localStorage.getItem("company_id") != undefined) {
      if (localStorage.getItem("shared_entities").includes('collection')) {
        payload.company_id = localStorage.getItem("company_id");
      }
    }
    return payload;
  };

  const getSceneNames = () => {
    const payload = generatePayload();
    axios.post(ENVIRONMENT.GET_SCENE_NAMES, payload).then((res) => {
      setSceneNames(res.data.body);
    });
  };

  const getCollectionNames = () => {
    const payload = generatePayload();
    axios.post(ENVIRONMENT.GET_COLLECTION_NAMES, payload).then((response) => {
      const names = [];
      response.data &&
        response.data.map((coll) => {
          names.push(coll.name);
        });
      setCollectionNames(names);
    });
  };

  const handleCancel = () => {
    console.log("Here", props.match.path.includes("create-empty-scene"))
    if (props.match.path.includes("create-empty-scene")) {
        setStep(4)
    }
    else{
        setStep(2)
    }
  };

  const handleFinish = (values) => {
    handleSubmit(values['scene_name']);
  };

  return (
    <Row className="scene-select-container">
      <Row className="scene-name-container">
        <p className="heading">Name your scene</p>
        <Form
          name="scene_name_input"
          ref={formRef}
          layout="vertical"
          className="input-form"
          onFinish={handleFinish}
        >
          <Form.Item
            name="scene_name"
            label={
              <span className="manrope f-14 black-00 w-600">Scene Name</span>
            }
            rules={[
              { required: true, message: 'Please enter scene name.' },
              {
                validator: async (_, value) => {
                  // if scene with same name exists
                  if (value) {
                    if (
                      (sceneNames && sceneNames.includes(value)) ||
                      (collectionNames && collectionNames.includes(value))
                    ) {
                      throw new Error(
                          'Name must be unique. Please use a different name.',
                      );
                    }
                  }
                },
              },
            ]}
            colon={false}
          >
            <Input
              className="manrope f-10 black-55"
              placeholder="Enter your scene name"
            />
          </Form.Item>
          <Row
            type="flex"
            style={{ marginTop: 20, justifyContent: 'flex-end' }}
          >
            <Button
              style={{ marginBottom: 10, marginRight: 10 }}
              className="outline-dark-blue-btn square font-12 manrope"
              onClick={handleCancel}
              disabled = {loading}
            >
              Back
            </Button>
            <Form.Item>
              <Button
                className="product-btn dark-blue f-12 ml-16 br-4"
                type="primary"
                htmlType="submit"
                disabled= {loading}
              >
                Save & Continue {loading ? <LoadingOutlined /> : ''}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
    </Row>
  );
};
const mapStateToProps = (state) => state;

export default withRouter(
    connect(mapStateToProps)(SceneNameInput),
);
