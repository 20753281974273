import React, { useState, useEffect, useCallback, useRef } from 'react';
import ENVIRONMENT from '../../../../environments';
import { Form } from '@ant-design/compatible';
import {
    message,
    Tag
} from 'antd';
import { getProductRender, listProducts, rejectProduct, updateProduct } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import ProductDetailsView from './ProductDetailsView';
import $ from 'jquery';
import FileConstants from '../../../../FileConstants';
import * as JSZip from 'jszip';
import * as Utilities from '../../Utilities'
import { IMAGE_DIMENSIONAL_URI, PRODUCT_QA_COMB_URI, QA_IMG_URI } from '../../../../environments/env';

const Validation_threshold = 0.97;

const allRequiredAssets = ['High Poly Geometry',
      'Low Poly Geometry',
      'Vray Materials',
      'PBR Materials',
    ];

const highRequiredAssets = ['High Poly Geometry',
    'Vray Materials',
  ];

const highPBRRequiredAssets = ['High Poly Geometry',
    'PBR Materials',
];

const allPBRRequiredAssets = ['High Poly Geometry',
    'Low Poly Geometry',
    'PBR Materials',
];


  const lowRequiredAssets = ['Low Poly Geometry',
  'PBR Materials',
];

const AdminProductDetails = (props) => {

    const [qaNotes, setQaNotes] = useState('');
    const [rejectionDetails, setRejectionDetails] = useState([]);
    const [itemHistory, setItemHistory] = useState([]);
    const [itemHistoryModal, setItemHistoryModal] = useState(false);
    const [activitySortAscending, setActivitySortAscending] = useState(false);
    const [productDetails, setProductDetails] = useState([]);
    const [fixesDetails, setFixesDetails] = useState([]);
    const [archiveSize, setArchiveSize] = useState(0);
    const [glbSize, setGlbSize] = useState(0);
    const [dimensionValidation, setDimensionValidation] = useState('Invalid');
    const [compareView, setCompareView] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [resetRequestInProgress, setResetRequestInProgress] = useState(false);
    const [retriesReset, setRetriesReset] = useState(false);
    const [adminModelStatusChange, setAdminModelStatusChange] = useState(false);
    const [showRetriesModal, setShowRetriesModal] = useState(false);
    const [showReview, setShowReview] = useState(true);
    const [newGlbSize, setNewGlbSize] = useState(0);
    const [modelTypes, setModelTypes] = useState([]);
    const [renderOverrideModal, setRenderOverrideModal] = useState(false);
    const [renderOverrideModalConfirm, setRenderOverrideModalConfirm] = useState(false);
    const [perspectiveRenders, setPerspectiveRenders] = useState([]);
    const [dimensionalRender, setDimensionalRender] = useState([]);
    const [productModelOverrideMadal, setProductModelOverrideMadal] = useState(false);
    const [productModelOverrideMadalConfirm, setProductModelOverrideMadalConfirm] = useState(false);
    const [overideProductModel, setOverideProductModel] = useState([]);
    const [productCustomLightingMadal, setProductCustomLightingMadal] = useState(false);
    const [productCustomLightingMadalConfirm, setProductCustomLightingMadalConfirm] = useState(false);
    const [productRegenerateConfirm, setProductRegenerateConfirm] = useState(false);
    const [generateRendersConfirmModal, setGenerateRendersConfirmModal] = useState(false);
    const [rigsList, setRigsList] = useState([{id: null, name: null}]);
    const [addNewRig, setAddNewRig] = useState(false);
    const [renderOverrideLoader, setRenderOverrideLoader] = useState(false);
    const [modelOverrideLoader, setModelOverrideLoader] = useState(false);
    const [lightingOverrideLoader, setLightingOverrideLoader] = useState(false);
    const [modelRegernateLoader, setModelRegernateLoader] = useState(false);
    const [lightingRigFile, setLightingRigFile] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const [validation_report, setValidationReport] = useState([]);
    const [product_valid ,setProductValid] = useState(false);
    const [validation_generated,setValidationGenerated] = useState(false);
    const [automatically_fixed_high, setAutoFixHigh] = useState([]);
    const [automatically_fixed_low, setAutoFixLow] = useState([]);
    const [invalid_data_high, setInvalidDataHigh] = useState([]);
    const [invalid_data_low, setInvalidDataLow] = useState([]);
    const [successfully_validated_high, setSuccessHigh] = useState([]);
    const [successfully_validated_low,setSuccessLow] = useState([]);
    const [success_val_length, setSuccessLength] = useState(0);
    const [invalid_val_length, setInvalidLength] = useState(0);
    const [auto_val_length, setAutoLength] = useState(0);
    const [confirmModal, setConfirmModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [embedModal, setEmbedModal] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(true);
    const [relatedMaterials, setRelatedMaterials] = useState([]);
    const [allApproved, setAllApproved] = useState(false);
    const [successConfig, setSuccessConfig] = useState(false);
    const [isConfigurable, setIsConfigurable] = useState(false);
    const [componentInfo, setComponentInfo] = useState([]);
    const [productMaterialArr, setProductMatArr] = useState([]);
    const [imageVisible, setImageVisible] = useState([]);
    const [modelArchiveValid, setModelArchiveValid] = useState(false);
    const [appArtistModel, setAppArtistModel] = useState(false);
    const { match: { params } } = props;
    const [, updateState] = React.useState();
    const [libraryMaterials, setLibraryMaterials] = useState([]);
    const [showNominateMaterialsModal, setShowNominateMaterialsModal] = useState(false);
    const [nominableMaterials, setNominableMaterials] = useState([]);
    const [preNominatedMaterials, setpreNominatedMaterials] = useState([]);
    const [nominatedMaterials, setNominatedMaterials] = useState([]);
    const [productLightingRig, setProductLightingRig] = useState('');
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [highPolyModelSize, setHighPolyModelSize] = useState(0);
    const [highPolyTextureSize, setHighPolyTextureSize] = useState(0);
    const [lowPolyTextureSize, setLowPolyTextureSize] = useState(0);
    const [lowPolyModelSize, setLowPolyModelSize] = useState(0);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [cryptoMatteExists, setCryptoMatteExists] = useState(false);
    const [dimensionCrypto, setDimensionCrypto] = useState(false);
    const [overrideSuccess, setOverrideSuccess] = useState(false);
    const [groupedProducts, setGroupedProducts] = useState([])
    const [automatedProductVariant, setAutomatedProductVariant] = useState(false);
    const [previousState, setPreviousState] = useState(-1);
    const [basePlatform, setBasePlatform] = useState('aws');
    const [basePlatformLoader, setBasePlatformLoader] = useState(true);
    const [feedbackImages, setFeedbackImages] = useState([]);
    const modelOverride = useRef(false);


    const getVideoUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name === 'VIDEO');
        if(link ) {
            return link.model_url
        } else {
            return ''
        }
    }

    const toggleLoader = () => {
        setLoadingProgress(false);
    }

    
    const getProductQAComparisonImages = () => {
        // this function will get you the images that the customer has added feedback on
        const payload = {
            "required_fields": ['id', 'combination_image', 'preview_image', 'platform',
                'reference_image', 'state', 'annotation_count', 'last_modified', 'last_modified_by'],
            "filter_string": "(product_id__exact=" + params.id + ")",
            "order_by": "id desc"
        }
        axios.post(ENVIRONMENT.COLLABORATE_QA_IMAGERY, payload)
            .then(res => {
                console.log(res.data);
                let image_list = [];

                res.data.map((img) => {
                    let low_url = ENVIRONMENT.getBaseURL(img.platform) + PRODUCT_QA_COMB_URI + params.id + '/' + img.combination_image;
                    let url = ENVIRONMENT.getBaseURL(img.platform) + PRODUCT_QA_COMB_URI + params.id + '/' + img.combination_image;

                    let type = "comb_image";
                    if (img.preview_image && !img.combination_image && !img.reference_image) {
                        type = "low_res_preview";
                        url = ENVIRONMENT.getBaseURL(img.platform) + QA_IMG_URI + img.preview_image;
                        low_url = url.replace('/perspective_renders/', '/low_res_perspective_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(img.platform));
                        if (img.preview_image.includes("_Dimensions")) {
                            url = ENVIRONMENT.getBaseURL(img.platform) + IMAGE_DIMENSIONAL_URI + img.preview_image;
                            low_url = url.replace('/dimensional_renders/', '/low_res_dimensional_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(img.platform));
                        }
                    } else if (img.reference_image && !img.preview_image && !img.combination_image) {
                        type = "reference_image";
                        url = ENVIRONMENT.getBaseURL(img.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(img.reference_image);
                        low_url = ENVIRONMENT.getBaseURL(img.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(img.reference_image);
                    }
                    if (img.state && (Object.entries(img.state).length !== 0 || img.state.length > 0)
                        && img.state.data && img.state.data.objects && img.state.data.objects.length > 0) {
                        image_list.push({
                            "id": img.id,
                            "url": url,
                            "low_url": low_url,
                            "type": type,
                            "main_type": type,
                            "status": "rendered",
                            "state": img.state,
                            "annotation_count": img.annotation_count
                        });
                    }
                })
                setFeedbackImages(image_list);
            });
    }

    const cancelApproveModal = () => {
        setApproveModal(false);
        setConfirmModal(true);
    }

    const reviewMaterial = () => {
        window.location.href = "/admin_materials/" + relatedMaterials[0].id;
    }

    const changeVisibility = (visible) => {
        console.log(visible)
    }




    useEffect(() => {
        if (props.productReducer.product.model_info != undefined )
        {
            let validation = 'Valid';
            if (props.productReducer.product.height != undefined) {
                let model_height = props.productReducer.product.height;
                let required_height = null;
                if(props.productReducer.product.model_info.low){
                    // when model_info contains low poly info
                    required_height = props.productReducer.product.model_info.low.height;
                }
                else if(props.productReducer.product.model_info.high){
                    // when model_info contains high poly info
                    required_height = props.productReducer.product.model_info.high.height;
                }
                else{
                    // for backward compatibility.
                    required_height = props.productReducer.product.model_info.height;
                }
                let height_factor = (model_height < required_height) ? (model_height/required_height) : (required_height/model_height);

                if(height_factor < Validation_threshold){
                    validation = 'Invalid'
                }
                else {
                    let width_diff = props.productReducer.product.width / props.productReducer.product.depth;
                    let model_info_depth = null;
                    let model_info_width = null;
                    if(props.productReducer.product.model_info.low){
                        // when model_info contains low poly info
                        model_info_depth = props.productReducer.product.model_info.low.depth;
                        model_info_width = props.productReducer.product.model_info.low.width;
                    }
                    else if(props.productReducer.product.model_info.high){
                        // when model_info contains high poly info
                        model_info_depth = props.productReducer.product.model_info.high.depth;
                        model_info_width = props.productReducer.product.model_info.high.width;
                    }
                    else{
                        // for backward compatibility.
                        model_info_depth = props.productReducer.product.model_info.depth;
                        model_info_width = props.productReducer.product.model_info.width;
                    }

                    let depth_diff = model_info_width / model_info_depth;
                    let required_width = null;
                    let required_depth = null;
                    if ((width_diff >= 1 && depth_diff >= 1) || (width_diff < 1 && depth_diff < 1) || (width_diff == 1 && depth_diff == 1))
                    {
                        required_width = props.productReducer.product.width;
                        required_depth = props.productReducer.product.depth;
                    }
                    else
                    {
                        required_width = props.productReducer.product.depth;
                        required_depth = props.productReducer.product.width;
                    }

                    let width_factor = (model_info_width < required_width) ? (model_info_width/required_width) : (required_width/model_info_width);
                    let depth_factor = (model_info_depth < required_depth) ? (model_info_depth/required_depth) : (required_depth/model_info_depth);

                    if((width_factor < Validation_threshold) || (depth_factor < Validation_threshold)){
                        validation = 'Invalid'
                    }
                }
                setDimensionValidation(validation);
            }
        }
        getProductQAComparisonImages();
    }, [props]);


    useEffect(() => {
        if(productDetails && productDetails.group_id){
            let payload = {
                'group_ids': [productDetails.group_id]
            }
            axios.post(ENVIRONMENT.GROUP_IDS_GET_BATCH, payload)
            .then(res => {
                console.log('grouped ids', res.data)
                let grouped_products = [];
                res.data.map((item) => {
                    if(item.model_status != "1" && (item.company_id == productDetails.company_id || item.customer_username == productDetails.customer_username))
                        grouped_products.push(item)
                })
                setGroupedProducts(grouped_products);
            })
        }
    }, [productDetails])

    useEffect(() => {
        props.getProduct({ product_id: params.id, admin: true, debug: true });
    }, [params]);

    useEffect(() => {
        if (props.productReducer.product != undefined) {
            let model_use_cases = props.productReducer.product.need_to_model;
            let user_model_type = props.productReducer.product.model_type;
            if (user_model_type == "" && model_use_cases == "high_res") {
                setModelTypes(['high_res']);
                props.productReducer.product.required_assets=highRequiredAssets;
            }
            if (user_model_type == "" && model_use_cases == "high_pbr") {
                setModelTypes(['high_pbr']);
                props.productReducer.product.required_assets=highPBRRequiredAssets;
            }
            if (user_model_type == "" && model_use_cases == "ar"){
                props.productReducer.product.required_assets=lowRequiredAssets;
                setModelTypes(['ar']);
            }
            if (user_model_type == "" && model_use_cases == "high_res_and_ar"){
                props.productReducer.product.required_assets=allRequiredAssets;
                setModelTypes(['high_res', 'ar']);
            }
            if (user_model_type == "" && model_use_cases == "high_pbr_and_ar"){
                props.productReducer.product.required_assets=allPBRRequiredAssets;
                setModelTypes(['high_pbr', 'ar']);
            }
            if (user_model_type == "high_res" && model_use_cases == "ar"){
                props.productReducer.product.required_assets=allRequiredAssets;
                setModelTypes(['high_res', 'ar']);
            }
            if (user_model_type == "high_pbr" && model_use_cases == "ar"){
                props.productReducer.product.required_assets=allPBRRequiredAssets;
                setModelTypes(['high_pbr', 'ar']);
            }
            if (user_model_type == "ar" && model_use_cases == "high_res"){
                props.productReducer.product.required_assets=allRequiredAssets;
                setModelTypes(['high_res', 'ar']);
            }
            if (user_model_type == "ar" && model_use_cases == "high_pbr"){
                props.productReducer.product.required_assets=allPBRRequiredAssets;
                setModelTypes(['high_pbr', 'ar']);
            }
            if (user_model_type == "ar" && model_use_cases == ""){
                props.productReducer.product.required_assets=lowRequiredAssets;
                setModelTypes(['ar']);
            }
            if (user_model_type == "high_res" && model_use_cases == ""){
                props.productReducer.product.required_assets=highRequiredAssets;
                setModelTypes(['high_res']);
            }
            if (user_model_type == "high_pbr" && model_use_cases == ""){
                props.productReducer.product.required_assets=highPBRRequiredAssets;
                setModelTypes(['high_pbr']);
            }

            if (props.productReducer.product.required_assets != undefined) {
                if (props.productReducer.product.required_assets.includes("ar") ||
                    props.productReducer.product.required_assets.includes("animation")
                ) {
                    setQaNotes("Kindly view GLTF and Perspective Images for QA. ");
                } else {
                    setQaNotes("Kindly view Prerendered 360 spin and Perspective Images for QA. ");
                }

                if(props.productReducer.product['360'] === undefined && props.productReducer.product.required_assets.includes("pre-rendered-360") ){
                    setQaNotes(qaNotes + "Pre-Rendered 360 will be gernated after admin approval.");
                }

                if((getVideoUrl() === '') && props.productReducer.product.required_assets.includes("mp4")){
                    setQaNotes(qaNotes + "MP4 Video will be gernated after admin approval.");
                }
            }
            else {
                setQaNotes("Kindly view Prerendered 360 spin and Perspective Images for QA. ");
            }
            setModelSizes();
            checkCryptomatte();
        }

    }, [props.productReducer.product]);

    const checkCryptomatte = () => {
        if(props.productReducer.product != undefined){
            if(props.productReducer.product['2d'] != undefined && props.productReducer.product['2d'].length > 0){
                props.productReducer.product['2d'].map((url) => {
                    let splitted_url = url.split('/')
                    let last_token = splitted_url[splitted_url.length - 1];
                    let image_name = last_token.split('.');
                    let image_name_w_format = image_name[0] + '_cMatte.jpg'
                    let new_url = ENVIRONMENT.getBaseURL(props.productReducer.product.platform) + ENVIRONMENT.QA_IMG_URI + image_name_w_format;
                    if (image_name[0].includes('_Dimensions')) {
                        new_url =ENVIRONMENT.getBaseURL(props.productReducer.product.platform) +  ENVIRONMENT.IMAGE_DIMENSIONAL_URI + image_name_w_format;
                    }
                    if (!cryptoMatteExists) {
                        axios.head(new_url,{})
                        .then(res => {
                            setCryptoMatteExists(true);

                            if (image_name[0].includes('_Dimensions')) {
                                setDimensionCrypto(true);
                            }
                        })
                    }
                });
            }
        }
    }

    const setModelSizes = () => {
        let high_poly_model_size = 0;
        let high_poly_texture_size = 0;
        let low_poly_model_size = 0;
        let low_poly_texture_size = 0;
        let total_file_size = 0;
        let glb_size = 0;
        if(props.productReducer.product.model_info != undefined) {
            high_poly_model_size = props.productReducer.product.model_info.size_high_poly_model || 0;
            high_poly_texture_size = props.productReducer.product.model_info.size_high_poly_textures || 0;
            low_poly_model_size = props.productReducer.product.model_info.size_low_poly_model || 0;
            low_poly_texture_size = props.productReducer.product.model_info.size_low_poly_textures || 0;
            glb_size = props.productReducer.product.model_info.glb_size || 0;
            high_poly_model_size = high_poly_model_size - high_poly_texture_size;
            low_poly_model_size = low_poly_model_size - low_poly_texture_size;
            if(glb_size == 0){
                getGlbSize();
                glb_size = glbSize/(1024*1024);
            }
            total_file_size = high_poly_model_size + high_poly_texture_size + low_poly_model_size + low_poly_texture_size + glb_size;
        }

        if(props.productReducer.product.model_info == undefined || total_file_size == 0 || total_file_size == glb_size){
            getGlbSize();
            getArchiveSize();
        }
        setHighPolyModelSize(high_poly_model_size);
        setHighPolyTextureSize(high_poly_texture_size);
        setLowPolyModelSize(low_poly_model_size);
        setLowPolyTextureSize(low_poly_texture_size);
        setTotalFileSize(total_file_size);
        setNewGlbSize(glb_size);
    }

    const onReject = (values) => {
        let model_updated_status = "-4"
        if (automatedProductVariant == true) {
            rejectAutomatedVariantRequestStatus();
        }
        let assigned_artist = props.productReducer.product.assigned_artist;
        let payload = {
            product_id: params.id,
            model_status: model_updated_status
        }
        if(assigned_artist != values.assigned_artist)
            payload['currently_assigned_artist'] = values.assigned_artist
        else
            payload['currently_assigned_artist'] = assigned_artist
        props.updateProduct(payload);
        let rejectPayload = {
            "product_id": params.id,
            "rejection_details": values.msg,
            "reference_files": values.reference_files,
            "qa_role": "admin",
            "rejection_against_artist": assigned_artist,
            "username": localStorage.getItem("username")
        };
        if(assigned_artist != values.assigned_artist)
        {
            payload.assigned_artist = values.assigned_artist;
            let modelAssignPayload = {
                "product_id": params.id,
                "model_status": model_updated_status,
                "assigned_artist": values.assigned_artist,
                "admin_username": localStorage.getItem('username'),
                "action": "product_rejection"
            }
            if(assigned_artist!=''){
                modelAssignPayload['original_artist'] = assigned_artist;
            }
            axios.post(ENVIRONMENT.ASSIGN_PRODUCT_MODEL, modelAssignPayload)
        }
        axios.post(ENVIRONMENT.ENTITY_MODEL_REJECT, rejectPayload)
        .then(res => {
            window.location.reload();
        })
    }

    const rejectAutomatedVariantRequestStatus = () => {
        let payload = {
            product_id: params.id,
            status: FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS["REJECTED"]
        }
        axios.post(ENVIRONMENT.PRODUCT_VARIATION_REQUEST_UPDATE, payload)
    }

    const highres_usecases = [
        'Product Photography',
        'Lifestyle Photography'
    ];

    const ar_usecases = [
        'AR',
        'Customization of Product (Web)',
        'Customization of Product (AR)'
    ];

    const model_type_details = {
        'high_res': {
            'label': 'High Resolution',
            'usecase_list': highres_usecases
        },
        'ar': {
            'label': 'AR',
            'usecase_list': ar_usecases
        }
    };

    const model_application_labels = {
        'perspective_images':'Product Perspective Photos',
        'pre-rendered-360':'Pre-Rendered Product 360',
        'mp4':'Mp4 Video',
        'dimensional_image':'Dimensional Image',
        'ar':'AR Model (GLB)',
    };

    const materialNominationTreeData = (material_info) => {
        let title = material_info['material_name'];
        if(material_info['artist_selected']){
            title =<p style={{display: 'flex', marginBottom: 0}}>{material_info['material_name']}
            <Tag color='geekblue' style={{marginLeft: 'auto'}} className="suggested-by-artist-tag">
            Suggested By Artist
            </Tag></p>
        }
        return title;

    }



    const onSendToCustomer = (values) => {
        let payload = {
            product_id: params.id,
            model_status: "10",
            admin_comments: values.admin_comments
        }

        if (values.admin_attachments != undefined) {
            payload.admin_attachments = values.admin_attachments
        }
        props.updateProduct(payload);
    }

    const onApprove = () => {
        if (isConfigurable) {//when product is configurable

            if (relatedMaterials && relatedMaterials.length > 0) {
                    if (allApproved) {
                        props.updateProduct({
                            product_id: params.id,
                            username: localStorage.getItem('username'),
                            model_status: "4"
                        });
                        let payload_approve = {
                            product_id: params.id,
                            customer_username: props.productReducer.product.customer_username,
                            materials: []
                        }
                        let material_entry = {};

                        relatedMaterials.map((data,index) => {
                            data.material.map((mat) => {
                                material_entry = {
                                material_id: mat.id,
                                status: 5
                                };
                                payload_approve.materials.push(material_entry);
                            });
                        });

                        axios.post(ENVIRONMENT.UPDATE_MATERIALS_BATCH,payload_approve)
                        .then(res => {
                            setSuccessConfig(true);
                        });
                } else {
                    if (props.productReducer.product.model_status != "9") {
                        props.updateProduct({
                            product_id: params.id,
                            model_status: "9"
                        });
                    }
                    setApproveModal(true);
                }
            } else if (relatedMaterials && relatedMaterials.length == 0) {
                props.updateProduct({
                    product_id: params.id,
                    username: localStorage.getItem('username'),
                    model_status: "4"
                });

                setSuccessConfig(true);
            }
        } else if (!isConfigurable && relatedMaterials.length == 0){ //when product is simple
            if (appArtistModel == true) {
                approveScannedProduct();
            } else {
                setShowNominateMaterialsModal(true);
            }
        }
    }

    const approveScannedProduct = () => {
        let update_payload = {
            product_id: params.id,
            model_status: "5",
            app_model: true,
            username: localStorage.getItem('username')
        }
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, update_payload)
        .then(res => {
            message.success('Product Model Approved!');
            window.location.href = '/admin_products';
        })
    }

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setIsFullScreen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_PRODUCT_REJECTIONS, { product_id: params.id })
        .then(res => {
            setRejectionDetails(res.data);
        })
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_BATCH_ITEM_HISTORY, { entity_id: params.id, entity_type: 'Product' })
        .then(res => {
            setItemHistory(res.data);
            if (res.data.length > 0) {
                if (res.data[0].before_value != undefined) {
                    setPreviousState(res.data[0].before_value);
                }
            }
        })
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_PRODUCT_FIXES_LIST, { product_id: params.id })
        .then(res => {
            setFixesDetails(res.data);
        })
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_PRODUCT_LIGHTING_RIG, { product_id: params.id})
            .then(res => {
                setProductLightingRig(res.data);
            })
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id: params.id })
        .then(res => {
            console.log(res.data, 'fetch');
            let product_info = res.data;
            let is_configurable = res.data.is_configurable;
            if (res.data.artist_model_requested != undefined && res.data.artist_model_requested == true) {
                setAppArtistModel(true);
            }
            setIsConfigurable(is_configurable);
            setProductDetails(res.data);
            setValidationReport(res.data['validation_report']);
            if (res.data.variant_of != null ) {
                let variant_payload = {
                    product_id: params.id
                };
                axios.post(ENVIRONMENT.PRODUCT_VARIATION_REQUEST_GET, variant_payload)
                    .then(res => {
                        let response = res.data;
                        if (response) {
                            if (response['status'] == FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS["COMPLETED"]) {
                                setAutomatedProductVariant(true)
                            }
                        }
                    });
            }
            let payload = {
                product_id: params.id
            };
            axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH,payload)
            .then(res => {
                let response = res.data;
                if (response) {
                    response = response.body;

                    setLibraryMaterials(response);
                    console.log('Materials',response)

                }
            });
            let temp_arr = [];
            axios.post(ENVIRONMENT.PRODUCT_COMPONENT_GET_BATCH,{
              product_id: params.id,
            }).then(res => {

              let component_info = JSON.parse(res.data.body);
              setComponentInfo(component_info);
              console.log(component_info,'Component_info');

              if (!is_configurable) {

                component_info.map((component, index) => {

                  if (component.using_material_library == "1") {
                    let payload_comp_get = {
                      product_id: params.id,
                      component_id: component.id
                    }
                    console.log(payload_comp_get);
                    axios.post(ENVIRONMENT.PRODUCT_COMPONENT_GET,payload_comp_get).then(res => {

                      let material_info = JSON.parse(res.data.body);
                      console.log('Material Info', material_info);
                      let payload = {};
                      if (material_info[0]) {
                        payload = {
                          material_id: material_info[0]['material_id']
                        };
                        console.log(payload, 'Material ID');

                        axios.post(ENVIRONMENT.MATERIAL_GET, payload)
                        .then(res => {

                          let material = JSON.parse(res.data.body);
                          console.log(material, 'material')
                          temp_arr[component.name] = material;

                          setProductMatArr(temp_arr);

                        });
                      }
                    });
                  } else if (component.using_material_library == "0") {
                        product_info.components && product_info.components.map((component_obj) => {
                        if (component_obj.componentName == component.name) {
                            temp_arr[component.name] = component_obj;
                        }
                        });
                        setProductMatArr(temp_arr);
                        forceUpdate();
                        console.log('When library is 0',temp_arr);
                  }
                });
              }
            });
            if (is_configurable) {
                // let payload = {
                //     product_id: params.id
                // };
                // axios.post(GET_CONFIGURATION_DATA, payload)
                // .then(res => {
                //   addMaterials(res.data.materials);
                // });
            }
            let product_id = null;
            if(res.data.immediate_parent_variant)
                product_id = res.data.immediate_parent_variant;
            else if(res.data.variant_of)
                product_id = res.data.variant_of;
                
            if(product_id != null){
            axios.post(ENVIRONMENT.FETCH_PRODUCT, {product_id})
                .then(res => {
                    setBasePlatform(res.data.platform);
                    setBasePlatformLoader(false);
                })
            }
        })
    }, []);

    useEffect(() => {
        // Load product material nomination data
        axios.post(ENVIRONMENT.PRODUCT_MATERIAL_EXPORT_INFO_GET_BATCH, {
            product_id: params.id,
        }).then(res => {
            console.log("product material export data: ", res.data.body)
            let productMaterialExportInfo = res.data.body;
            let tempNominatedMaterialsArray = [];
            let preSelectedTreeData = [];
            let treeData = [];
            for (let i = 0; i < (productMaterialExportInfo).length; i++) {
            let treeItem = {
                key: productMaterialExportInfo[i]['material_name'],
                title: materialNominationTreeData(productMaterialExportInfo[i]),
                value: productMaterialExportInfo[i]['material_name'],
                children: []
            }
            treeData.push(treeItem);
            }

            setNominableMaterials(treeData);
            setpreNominatedMaterials(preSelectedTreeData);
            setNominatedMaterials(tempNominatedMaterialsArray);
        });
    },[]);

    const addMaterials = (materials) => {

        setRelatedMaterials(materials);

        let check_if_all_approved = true;
        if (materials.length > 0) {
            materials.map((mat,index) => {
                if (mat.status != 9) {
                  check_if_all_approved = false;
                }
              });
        }

        if (check_if_all_approved) {
            setAllApproved(true);
        }
    }


    useEffect(() => {
        setValidationData();
    },[validation_report]);

    useEffect(() => {
        window.CI360.destroy();
        window.CI360.init();
    }, [isFullScreen]);

    const loadRigsData = (callbackMethod = undefined, lightJobOutput, render_perspective) => {

        axios.post(ENVIRONMENT.LIGHTING_RIG_GET_BATCH, {
        })
          .then(res => {
            setRigsList(res.data);
            if (callbackMethod != undefined)
            {
                callbackMethod(res.data[0].id, lightJobOutput, render_perspective);
            }
          });
      }

      useEffect(() => {
        loadRigsData();
      }, []);

    const cancelRenderOverrideModal = () => {
        props.form.resetFields();
        setUploadError('');
        setRenderOverrideModal(false);
        setDimensionalRender([]);
        setPerspectiveRenders([]);
    }

    const cancelRenderOverrideModalConfirm = () => {
        props.form.resetFields();
        setUploadError('');
        setRenderOverrideModalConfirm(false);
        setDimensionalRender([]);
        setPerspectiveRenders([]);
    }

    const cancelProductModelOverrideMadal = () => {
        props.form.resetFields();
        setUploadError('');
        setProductModelOverrideMadal(false);
        setOverideProductModel([]);
    }

    const cancelProductModelOverrideMadalConfirm = () => {
        props.form.resetFields();
        setUploadError('');
        setProductModelOverrideMadalConfirm(false);
        setOverideProductModel([]);
    }

    const cancelProductCustomLightingMadal = () => {
        props.form.resetFields();
        setUploadError('');
        setProductCustomLightingMadal(false);
        setLightingRigFile([]);
    }

    const cancelProductCustomLightingMadalConfirm = () => {
        props.form.resetFields();
        setUploadError('');
        setProductCustomLightingMadalConfirm(false);
        setLightingRigFile([]);
    }

    const validateFileUploads = (files) => {
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                if (file['status'] == 'error') {
                    return 'error';
                }
            }
        }
        return 'success';
    }

    const handleDimensionalRenderChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setDimensionalRender(fileList);
    };

    const handlePerspectiveRenderChange = info => {
        let fileList = [...info.fileList];
        setPerspectiveRenders(fileList);
    };

    const handleOverrideModelChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setOverideProductModel(fileList);
    };

    const handleLightingRigFileChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setLightingRigFile(fileList);
    };

    const handleSubmitRenderReplaceForm = (e, confirm = false) => {
        e.preventDefault();
        props.form.validateFields(['perspective_images', 'dimensional_render'], (err, values) => {
          if (!err) {

                let uploadStatus = validateFileUploads(perspectiveRenders.concat(dimensionalRender));
                if (uploadStatus == 'uploading') {
                    setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                }
                else if (uploadStatus == 'error') {
                    setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                }
                else if (uploadStatus == 'success'){
                    setUploadError('');

                    if(confirm == false){
                        setRenderOverrideModal(false);
                        setRenderOverrideModalConfirm(true);
                    }
                    else {
                        setRenderOverrideLoader(true);
                        let payload = {
                            product_id: params.id,
                            override_perspective_renders: perspectiveRenders,
                            username: localStorage.getItem('username'),
                        }
                        if (dimensionalRender.length > 0) {
                            payload.override_dimensional_render = dimensionalRender
                        }
                        axios.post(ENVIRONMENT.PRODUCT_RENDERS_OVERRIDE, payload)
                        .then(res => {
                            setOverrideSuccess(true);
                            setRenderOverrideModalConfirm(false);
                        })
                    }
                }
          }
        });
    }

    const checkFolderStructure = (file) => {
        let return_check = true;

        let folderStructure = {
            'vray_exist' : false,
            'high_exist' : false,
            'low_exist' : false,
            'pbr_exist' : false,
            'high_pbr_exist' : false,
            'max_high_id' : false,
            'max_low_id' : false,
            'id' : 0
        }
    
        let new_zip = new JSZip();
        return new_zip.loadAsync(file)
        .then((zip) => {
          for(let key in zip['files']){
            console.log(key)
            if (key.includes('/High') && !key.includes("_MACOSX/High")) {
              setUploadError("Incorrect folder structure! Correct your folder structure and reupload file.")
              return_check = false;
            } else if (key.includes('high/')){
              setUploadError("Incorrect naming structure! Correct your folder naming and reupload file.")
              return_check = false;
            } else if (key.includes('High/')){
              folderStructure['high_exist'] = true;
              let max_id = key.split('/')[1];
              if (max_id === (params.id + '.max')) {
                folderStructure['max_high_id'] = true;
              }
            }
    
            if ( folderStructure['substance_painter_file'] != undefined && key.includes('.spp') && ( key.includes('HighPBRTextures') || key.includes('PBRTextures') || key.includes('VrayTextures') ) ) {
              folderStructure['substance_painter_file'] = true;
            }
    
            if (key.includes('VrayTextures')){
              folderStructure['vray_exist'] = true;
            }
    
            if (key.includes('HighPBRTextures')){
              folderStructure['high_pbr_exist'] = true;
            }
    
            if (key.includes('/Low') && !key.includes("_MACOSX/Low")) {
                setUploadError("Incorrect folder structure! Correct your folder structure and reupload file.")
              return_check = false;
    
            } else if (key.includes('low/')){
                setUploadError("Incorrect naming structure! Correct your folder naming and reupload file.")
              return_check = false;
            } else if (key.includes('Low/')){
              folderStructure['low_exist'] = true;
              let max_id = key.split('/')[1];
              if (max_id == (params.id + '.max')) {
                folderStructure['max_low_id'] = true;
              }
            }
            if (key.includes('PBRTextures')){
              folderStructure['pbr_exist'] = true;
            }
    
            if ((key.includes('Low/') && key.includes('PBRTextures/')) || (key.includes('High/') && key.includes('VrayTextures/'))) {
              setUploadError("Folder hierarchy is not correct. Correct your folder structure and reupload file.")
              return_check = false;
    
            }
          }
    
          folderStructure['id'] = params.id
    
          let upload_error_message = ""
          upload_error_message =  Utilities.checkMaxBundleFolderStructure(props.productReducer.product.need_to_model,folderStructure )
          if (upload_error_message != ""){
            setUploadError(upload_error_message)
            return_check = false
          }
          
          if (!return_check) {
            console.log('****returning false')
            return false;
          } else {
            console.log('****returning true')
            return true;
          }
        });
      }

    const handleSubmitModelOverrideForm = (e, confirm = false) => {
        e.preventDefault();
        props.form.validateFields(['override_model'], (err, values) => {
            if (!err) {

                let uploadStatus = validateFileUploads(overideProductModel);
                if (modelArchiveValid == false) {}
                else if (uploadStatus == 'uploading') {
                    setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                }
                else if (uploadStatus == 'error') {
                    setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                }
                else if (uploadStatus == 'success'){
                    setUploadError('');

                    if (confirm == false) {
                        setProductModelOverrideMadal(false);
                        setProductModelOverrideMadalConfirm(true);
                    }
                    else {
                        setModelOverrideLoader(true);
                        let payload = {
                            product_id: params.id,
                            override_model: overideProductModel,
                            username: localStorage.getItem('username'),
                        }
                        axios.post(ENVIRONMENT.ADMIN_MODEL_OVERRIDE, payload)
                        .then(res => {
                                // Remove the lighting renders for this product
                                axios.post(ENVIRONMENT.REMOVE_PRODUCT_RENDERS, {product_id: params.id})
                                modelOverride.current = true;
                                regenerateModel();
                        })
                    }
                }
            }
        });
    }

    const handleSubmitProductCustomRigForm = (e, confirm = false, lightJobOutput = 'test_render') => {

        if(addNewRig == false) {
            handleSetExistingRig(e, confirm, lightJobOutput);
        }
        else {
            handleAddandSetNewRig(e, confirm, lightJobOutput);
        }
    }

    const handleSetExistingRig = (e, confirm = false, lightJobOutput = 'test_render') => {
        e.preventDefault();
        props.form.validateFields(['custom_lighting_rig', 'render_perspective'], (err, values) => {
            console.log(err,values);
            if (!err) {
                if (confirm == false) {
                    setProductCustomLightingMadal(false);
                    setProductCustomLightingMadalConfirm(true);
                }
                else {
                    setLightingOverrideLoader(true);
                    setProductCustomRig(values.custom_lighting_rig, lightJobOutput, values.render_perspective);
                }
            }
        });
    }

    const handleAddandSetNewRig = (e, confirm = false, lightJobOutput = 'test_render') => {
        e.preventDefault();
        props.form.validateFields(['name', 'rig_file'], (err, values) => {
            if (!err) {
                let uploadStatus = validateFileUploads(lightingRigFile);
                if (uploadStatus == 'uploading') {
                    setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                }
                else if (uploadStatus == 'error') {
                    setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                }
                else if (uploadStatus == 'success'){
                    setUploadError('');
                    if (confirm == false) {
                        setProductCustomLightingMadal(false);
                        setProductCustomLightingMadalConfirm(true);
                    }
                    else {
                        setLightingOverrideLoader(true);
                        let new_rig_payload = {
                            name: values.name,
                            rig_file: lightingRigFile
                        }
                        axios.post(ENVIRONMENT.LIGHTING_RIG_CREATE, new_rig_payload)
                        // Adding new Lighting Rig
                        .then(res => {
                            props.form.validateFields(['render_perspective'], (err, values) => {
                                if (!err) {
                                    loadRigsData(setProductCustomRig, lightJobOutput, values.render_perspective);
                                }
                                else{
                                    console.log('error occured in adding a new rig');
                                    console.log(err);
                                }
                            });
                        })
                    }
                }
            }
        });
    }

    const setProductCustomRig = (rig_id, lightJobOutput, render_perspective) => {
        if(lightJobOutput != 'test_render')
        {
            // Set lighting rig when it is not a test_render request
            let payload = {
                product_id: params.id,
                rig_id: rig_id,
                username: localStorage.getItem('username'),
            }
            console.log(payload,'Payload');
            axios.post(ENVIRONMENT.SET_PRODUCT_CUSTOM_RIG, payload)
            .then(res => {
                    regenerateModel(lightJobOutput, true, true, render_perspective);
            })
        }
        else{
            regenerateModel(lightJobOutput, true, true, render_perspective, rig_id);
        }
    }

    const regenerateModelConfirm = () => {
        setModelRegernateLoader(true);
        regenerateModel();
    }

    const generateRendersConfirm = () => {
        setModelRegernateLoader(true);
        regenerateModel('all_renders', true);
    }

    const convertTimeToHoursString = (hours) => {
        hours = Math.round(hours * 1000) / 1000 // rounding to 2 decimal places only if needed
        var result = "";
        if(hours > 0){
            result += hours;
            result += hours == 1 ? " Hour " : " Hours ";
        }
        else{
            result="0 Hours"
        }
        return result;
    }


    const totalModelingTime = () => {
        var hours = +props.productReducer.product.time1 +
            +props.productReducer.product.time2 +
            +props.productReducer.product.time3 +
            +props.productReducer.product.time4;
            return convertTimeToHoursString(hours);
    }

    const getArchiveSize = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name === 'MAX');
        let size = 0;

        if(link)
        {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product['platform']))).then(res => {
                if(res.headers['content-length']){
                    size = parseInt(res.headers['content-length']);
                    setArchiveSize(size);
                }
            });
        }
    }

    const getGlbSize = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name === 'GLB');
        let size = 0;
        if(link)
        {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product['platform']))).then(res => {
                if(res.headers['content-length']){
                    size = parseInt(res.headers['content-length']);
                    setGlbSize(size);
                }
            });
        }
    }

    const convertByteSize = (size_in_mbs) => {
        let result = size_in_mbs.toFixed(2) + " MBs";
        if(size_in_mbs/1024 > 1024) // means more then 1 GB
        {
            size_in_mbs = (size_in_mbs/(1024)).toFixed(1); // size in GBs
            result =  size_in_mbs+" GBs";
        }
        return result;
    }


    const totalFixesTime = () => {
        var hours = 0;
        for(var i=0; i<fixesDetails.length; i++){
            hours += +fixesDetails[i].fix_time;
        }
        return convertTimeToHoursString(hours)
    }

    const regenerateModel = (lightJobOutput = 'all_renders', onlyRendersJob = false, lighting_rig_override = false, render_perspective = 'HeadOn', rig_id=null) => {
        let product_id = params.id;
        let body = {};
        let archive_name = product_id + '.zip';
        body['product_id'] = product_id;
        body['model_type'] = 'low_poly_max';
        body['model_archive'] = archive_name;
        body['admin_override'] = true;
        body['rejected_case'] = true;
        body['username']= localStorage.getItem('username')
        // The lighting_rig_override veriable will only be true when regenerate model request is made after updating the rig
        if(lighting_rig_override == true){
            body['lighting_rig_override'] = true;
        }
        if(modelOverride.current){
            body['model_override'] = true;
            modelOverride.current = false;
        }
        if (lightJobOutput == 'test_render') {
            body['test_render'] = true;
            body['render_perspective'] = render_perspective; // it is only needed when it is a test render request
            // pass the rig id to product-model-creat lambda, it will directly use the rig without associating with the product
            if(rig_id)
            {
                body['rig_id'] = rig_id;
            }
        }
        if (onlyRendersJob == true) {
            body['renders_only'] = true;
        }

        axios.post(ENVIRONMENT.MODEL_CREATE, { body })
        .then(res => {
            //updating product status - will be removed once we have added status updated in model create API
            let payload = {
              model_status : 6,
              product_id: product_id
            }
            axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
              .then(res => {
                window.location.href = '/admin_products';
            })
        });
    }

    const sendProductToAdminQA = () => {
        const hideMessage = message.loading('Moving Product to Admin QA', 0)
        let payload = {
            model_status: 3,
            product_id: params.id,
            admin_status_change: true,
            username: localStorage.getItem('username')
        }
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
              .then(res => {
                setAdminModelStatusChange(true);
                hideMessage()
            })
    }
    const showEmbedModel = () => {
        setEmbedModal(true);
    }

    const handleEmbedCancel = () => {
        setEmbedModal(false);
    }

    const handleEmbedOk = () => {
        setEmbedModal(false);
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($("#iframe-text").text()).select();
        document.execCommand("copy");
        $temp.remove();
        message.success('Embed link copied to clipboard!');
    }

    const reset_model_retries = () => {
        setResetRequestInProgress(true);
        let product_id = params.id;
        let payload = {
            product_id: product_id,
            model_retries: 0,
            username: localStorage.getItem('username')
        }
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
        .then(res => {
            if(res.status == 200){
                setRetriesReset(true);
                setResetRequestInProgress(false);
                setShowRetriesModal(true);
            }
        })
    }

    // if the veriable sort_asc is true, sort in ascending order, not needed the first time,
    const sortActivity = (sort_asc) => {
        setActivitySortAscending(sort_asc);
        if(sort_asc == true)
            itemHistory.sort((a, b) => (a.id < b.id) ? -1 : 1);
        else
            itemHistory.sort((a, b) => (a.id < b.id) ? 1 : -1);

        forceUpdate();
    }


    const setValidationData = () => {
        let automatically_fixed_high = [];
        let automatically_fixed_low = []
        let successfully_validated_high = [];
        let successfully_validated_low = [];
        let invalid_data_high = [];
        let invalid_data_low = [];
        let success_val_length = 0;
        let invalid_val_length = 0;
        let auto_val_length = 0;

        if (validation_report != undefined) {
          if (validation_report['High'] != undefined && Object.keys(validation_report['High']).length != 0) {
            if (validation_report['High']['hierarchy']) {
              if (!validation_report['High']['hierarchy'].valid && validation_report['High']['hierarchy'].fixed) {
                automatically_fixed_high.push('Heirarchy');
              }

              if (validation_report['High']['hierarchy'].valid && !validation_report['High']['hierarchy'].fixed) {
                successfully_validated_high.push('Heirarchy');
              }

              if (!validation_report['High']['hierarchy'].valid && !validation_report['High']['hierarchy'].fixed) {
                invalid_data_high.push(<span>Heirarchy (Refer to Section 6 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }

            if (validation_report['High']['pivot']) {
              if (!validation_report['High']['pivot'].valid && validation_report['High']['pivot'].fixed){
                automatically_fixed_high.push('Pivot');
              }

              if (validation_report['High']['pivot'].valid && !validation_report['High']['pivot'].valid.fixed){
                successfully_validated_high.push('Pivot');
              }

              if (!validation_report['High']['pivot'].valid && !validation_report['High']['pivot'].fixed){
                invalid_data_high.push(<span>Pivot (Refer to Section 6 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }

            if (validation_report['High']['transform']) {
              if (!validation_report['High']['transform']['position'].valid && validation_report['High']['transform']['position'].fixed) {
                automatically_fixed_high.push('Transform (Position)');
              }
              if (!validation_report['High']['transform']['rotation'].valid && validation_report['High']['transform']['rotation'].fixed) {
                automatically_fixed_high.push('Transform (Rotation)');
              }
              if (!validation_report['High']['transform']['scale'].valid && validation_report['High']['transform']['scale'].fixed) {
                automatically_fixed_high.push('Transform (Scale)');
              }

              if (validation_report['High']['transform']['position'].valid && !validation_report['High']['transform']['position'].fixed) {
                successfully_validated_high.push('Transform (Position)');
              }
              if (validation_report['High']['transform']['rotation'].valid && !validation_report['High']['transform']['rotation'].fixed) {
                successfully_validated_high.push('Transform (Rotation)');
              }
              if (validation_report['High']['transform']['scale'].valid && !validation_report['High']['transform']['scale'].fixed) {
                successfully_validated_high.push('Transform (Scale)');
              }

              if (!validation_report['High']['transform']['position'].valid && !validation_report['High']['transform']['position'].fixed) {
                invalid_data_high.push(<span>Transform (Position) (Refer to Section 8 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
              if (!validation_report['High']['transform']['rotation'].valid && !validation_report['High']['transform']['rotation'].fixed) {
                invalid_data_high.push(<span>Transform (Rotation) (Refer to Section 8 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
              if (!validation_report['High']['transform']['scale'].valid && !validation_report['High']['transform']['scale'].fixed) {
                invalid_data_high.push(<span>Transform (Scale) (Refer to Section 8 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }

            }

            if (validation_report['High'].vertex_count) {
              successfully_validated_high.push(`Vertex Count is ${validation_report['High'].vertex_count}`);
            }
            if (!isConfigurable) {
                if (validation_report['High']['vray_materials'] != undefined) {
                validation_report['High']['vray_materials'].map((material) => {
                    if (!material.valid) {
                    invalid_data_high.push(<span>{material.name} is not a valid VRay Material. (Refer to Section 7 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
                    }
                })
                }

                if (validation_report['High']['vray_textures'] != undefined) {
                validation_report['High']['vray_textures'].map((texture) => {
                    if (!texture.found) {
                    invalid_data_high.push(<span>{texture.name} was not found in VRay Textures. (Refer to Section 7 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
                    }
                })
                }
            } else if (isConfigurable) {
                if (validation_report['High']['configurable'] != undefined) {
                    if (validation_report['High']['configurable'].valid) {
                      successfully_validated_high.push(`Configuration`);
                    } else {
                      if (validation_report['High']['configurable'].missing_components) {
                        validation_report['High']['configurable'].missing_components.map(comp => {
                          invalid_data_high.push(`Mesh does not exist for ${comp.name}`);
                        })
                      }
                    }
                }
            }
          }

          if (validation_report['Low'] != undefined && Object.keys(validation_report['Low']).length != 0) {
            if (validation_report['Low']['hierarchy']) {

              if (!validation_report['Low']['hierarchy'].valid && validation_report['Low']['hierarchy'].fixed) {
                automatically_fixed_low.push('Heirarchy');
              }

              if (validation_report['Low']['hierarchy'].valid && !validation_report['Low']['hierarchy'].fixed) {
                successfully_validated_low.push('Heirarchy');
              }

              if (!validation_report['Low']['hierarchy'].valid && !validation_report['Low']['hierarchy'].fixed) {
                invalid_data_low.push(<span>Heirarchy (Refer to Section 6 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }

            if (validation_report['Low']['pivot']) {
              if (!validation_report['Low']['pivot'].valid && validation_report['Low']['pivot'].fixed){
                automatically_fixed_low.push('Pivot');
              }

              if (validation_report['Low']['pivot'].valid && !validation_report['Low']['pivot'].valid.fixed){
                successfully_validated_low.push('Pivot');
              }

              if (!validation_report['Low']['pivot'].valid && !validation_report['Low']['pivot'].fixed){
                invalid_data_low.push(<span>Pivot (Refer to Section 6 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }

            if (validation_report['Low']['transform']) {
              if (!validation_report['Low']['transform']['position'].valid && validation_report['Low']['transform']['position'].fixed) {
                automatically_fixed_low.push('Transform (Position)');
              }
              if (!validation_report['Low']['transform']['rotation'].valid && validation_report['Low']['transform']['rotation'].fixed) {
                automatically_fixed_low.push('Transform (Rotation)');
              }
              if (!validation_report['Low']['transform']['scale'].valid && validation_report['Low']['transform']['scale'].fixed) {
                automatically_fixed_low.push('Transform (Scale)');
              }

              if (validation_report['Low']['transform']['position'].valid && !validation_report['Low']['transform']['position'].fixed) {
                successfully_validated_low.push('Transform (Position)');
              }
              if (validation_report['Low']['transform']['rotation'].valid && !validation_report['Low']['transform']['rotation'].fixed) {
                successfully_validated_low.push('Transform (Rotation)');
              }
              if (validation_report['Low']['transform']['scale'].valid && !validation_report['Low']['transform']['scale'].fixed) {
                successfully_validated_low.push('Transform (Scale)');
              }

              if (!validation_report['Low']['transform']['position'].valid && !validation_report['Low']['transform']['position'].fixed) {
                invalid_data_low.push(<span>Transform (Position) (Refer to Section 8 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
              if (!validation_report['Low']['transform']['rotation'].valid && !validation_report['Low']['transform']['rotation'].fixed) {
                invalid_data_low.push(<span>Transform (Rotation) (Refer to Section 8 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
              if (!validation_report['Low']['transform']['scale'].valid && !validation_report['Low']['transform']['scale'].fixed) {
                invalid_data_low.push(<span>Transform (Scale) (Refer to Section 8 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }

            if (validation_report['Low'].vertex_count) {
              if (validation_report['Low'].vertex_count > 50000) {
                invalid_data_low.push(`Vertex Count is ${validation_report['Low'].vertex_count}`);
              } else if (validation_report['Low'].vertex_count > 30000) {
                automatically_fixed_low.push(`Warning! Vertex Count is ${validation_report['Low'].vertex_count}`);
              }
              else if (validation_report['Low'].vertex_count <= 30000) {
                successfully_validated_low.push(`Vertex Count is ${validation_report['Low'].vertex_count}`);
              }
            }

            if (!isConfigurable) {
                if (validation_report['Low']['materials'] != undefined) {
                    validation_report['Low']['materials'].map((material) => {
                      if (!material.valid) {
                        invalid_data_low.push(<span>{material.name} of class, {material.class}, is not a valid material. (Refer to Section 7 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
                      }
                    })
                }

                  if (validation_report['Low']['pbr_textures'] != undefined) {
                    validation_report['Low']['pbr_textures'].map((texture) => {
                      if (!texture.found) {
                        invalid_data_low.push(<span>{texture.name} was not found. (Refer to Section 7 of <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">guidelines</a>)</span>);
                      }
                    })
                }
            } else if (isConfigurable) {
                if (validation_report['Low']['configurable'] != undefined) {
                    if (validation_report['Low']['configurable'].valid) {
                      successfully_validated_low.push(`Configuration`);
                    } else {
                      if (validation_report['Low']['configurable'].missing_components) {
                        validation_report['Low']['configurable'].missing_components.map(comp => {
                          invalid_data_low.push(`Mesh does not exist for ${comp.name}`);
                        })
                      }
                    }
                }
            }

          }
        }
        success_val_length = successfully_validated_high.length + successfully_validated_low.length;
        auto_val_length = automatically_fixed_high.length + automatically_fixed_low.length;
        invalid_val_length = invalid_data_high.length + invalid_data_low.length;

        if (validation_report != undefined  && (success_val_length != 0 || auto_val_length != 0 || invalid_val_length != 0)) {
          let not_empty = false;
          if (validation_report['High'] != undefined && Object.keys(validation_report['High']).length != 0) {
            if (!validation_report['High']['product_valid']) {
                setProductValid(false);
            }
            not_empty = true;
          }
          if (validation_report['Low'] != undefined && Object.keys(validation_report['Low']).length != 0) {
            if (!validation_report['Low']['product_valid']) {
              setProductValid(false);
            }
            not_empty = true;
          }

          if (not_empty) {
            setValidationGenerated(true);
          }
        } else {
          setValidationGenerated(false);
        }

        setAutoFixHigh(automatically_fixed_high);
        setAutoFixLow(automatically_fixed_low);
        setSuccessHigh(successfully_validated_high);
        setSuccessLow(successfully_validated_low);
        setInvalidDataHigh(invalid_data_high);
        setInvalidDataLow(invalid_data_low);
        setSuccessLength(success_val_length);
        setInvalidLength(invalid_val_length);
        setAutoLength(auto_val_length);

      }



    return (
        <ProductDetailsView {...props}
        setRenderOverrideModal={setRenderOverrideModal}
        groupedProducts={groupedProducts}
        setProductModelOverrideMadal={setProductModelOverrideMadal}
        setProductCustomLightingMadal={setProductCustomLightingMadal}
        setProductRegenerateConfirm={setProductRegenerateConfirm}
        productDetails={productDetails}
        productReducer={props.productReducer}
        productLightingRig={productLightingRig}
        params={params}
        rejectionDetails={rejectionDetails}
        itemHistory={itemHistory}
        setItemHistoryModal={setItemHistoryModal}
        setActivitySortAscending={setActivitySortAscending}
        activitySortAscending={activitySortAscending}
        sortActivity={sortActivity}
        itemHistoryModal={itemHistoryModal}
        compareView={compareView}
        setCompareView={setCompareView}
        fixesDetails={fixesDetails}
        convertTimeToHoursString={convertTimeToHoursString}
        totalFixesTime={totalFixesTime}
        totalModelingTime={totalModelingTime}
        archiveSize={archiveSize}
        glbSize={glbSize}
        convertByteSize={convertByteSize}
        dimensionValidation={dimensionValidation}
        showReview={showReview}
        model_type_details={model_type_details}
        modelTypes={modelTypes}
        model_application_labels={model_application_labels}
        onApprove={onApprove}
        onReject={onReject}
        onSendToCustomer={onSendToCustomer}
        renderOverrideLoader={renderOverrideLoader}
        cancelRenderOverrideModal={cancelRenderOverrideModal}
        handleSubmitRenderReplaceForm={handleSubmitRenderReplaceForm}
        perspectiveRenders={perspectiveRenders}
        handlePerspectiveRenderChange={handlePerspectiveRenderChange}
        dimensionalRender={dimensionalRender}
        handleDimensionalRenderChange={handleDimensionalRenderChange}
        uploadError={uploadError}
        productModelOverrideMadal={productModelOverrideMadal}
        cancelProductModelOverrideMadal={cancelProductModelOverrideMadal}
        handleSubmitModelOverrideForm={handleSubmitModelOverrideForm}
        overideProductModel={overideProductModel}
        setUploadError={setUploadError}
        setModelArchiveValid={setModelArchiveValid}
        handleOverrideModelChange={handleOverrideModelChange}
        productCustomLightingMadal={productCustomLightingMadal}
        cancelProductCustomLightingMadal={cancelProductCustomLightingMadal}
        handleSubmitProductCustomRigForm={handleSubmitProductCustomRigForm}
        addNewRig={addNewRig}
        rigsList={rigsList}
        setAddNewRig={setAddNewRig}
        lightingRigFile={lightingRigFile}
        handleLightingRigFileChange={handleLightingRigFileChange}
        renderOverrideModalConfirm={renderOverrideModalConfirm}
        cancelRenderOverrideModalConfirm={cancelRenderOverrideModalConfirm}
        handleSubmitRenderReplaceForm={handleSubmitRenderReplaceForm}
        renderOverrideLoader={renderOverrideLoader}
        productModelOverrideMadalConfirm={productModelOverrideMadalConfirm}
        cancelProductModelOverrideMadalConfirm={cancelProductModelOverrideMadalConfirm}
        handleSubmitModelOverrideForm={handleSubmitModelOverrideForm}
        modelOverrideLoader={modelOverrideLoader}
        productCustomLightingMadalConfirm={productCustomLightingMadalConfirm}
        cancelProductCustomLightingMadalConfirm={cancelProductCustomLightingMadalConfirm}
        lightingOverrideLoader={lightingOverrideLoader}
        productRegenerateConfirm={productRegenerateConfirm}
        setProductRegenerateConfirm={setProductRegenerateConfirm}
        regenerateModelConfirm={regenerateModelConfirm}
        generateRendersConfirm={generateRendersConfirm}
        modelRegernateLoader={modelRegernateLoader}
        renderOverrideModal={renderOverrideModal}
        setValidationData={setValidationData}
        product_valid={product_valid}
        validation_generated={validation_generated}
        automatically_fixed_high={automatically_fixed_high}
        automatically_fixed_low={automatically_fixed_low}
        invalid_data_high={invalid_data_high}
        invalid_data_low={invalid_data_low}
        successfully_validated_high={successfully_validated_high}
        successfully_validated_low={successfully_validated_low}
        success_val_length={success_val_length}
        invalid_val_length={invalid_val_length}
        auto_val_length={auto_val_length}
        reset_model_retries={reset_model_retries}
        retriesReset={retriesReset}
        showRetriesModal={showRetriesModal}
        setShowRetriesModal={setShowRetriesModal}
        resetRequestInProgress={resetRequestInProgress}
        sendProductToAdminQA={sendProductToAdminQA}
        adminModelStatusChange={adminModelStatusChange}
        setAdminModelStatusChange={setAdminModelStatusChange}
        showEmbedModel={showEmbedModel}
        embedModal={embedModal}
        showNominateMaterialsModal={showNominateMaterialsModal}
        setShowNominateMaterialsModal={setShowNominateMaterialsModal}
        setProductCustomRig={setProductCustomRig}
        handleEmbedOk={handleEmbedOk}
        handleEmbedCancel={handleEmbedCancel}
        sendProductToAdminQA={sendProductToAdminQA}
        adminModelStatusChange={adminModelStatusChange}
        setAdminModelStatusChange={setAdminModelStatusChange}
        generateRendersConfirmModal={generateRendersConfirmModal}
        setGenerateRendersConfirmModal={setGenerateRendersConfirmModal}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        approveModal={approveModal}
        setApproveModal={setApproveModal}
        cancelApproveModal={cancelApproveModal}
        reviewMaterial={reviewMaterial}
        loadingProgress={loadingProgress}
        setLoadingProgress={setLoadingProgress}
        toggleLoader={toggleLoader}
        relatedMaterials={relatedMaterials}
        successConfig={successConfig}
        setSuccessConfig={setSuccessConfig}
        isConfigurable={isConfigurable}
        componentInfo={componentInfo}
        productMaterialArr={productMaterialArr}
        imageVisible={imageVisible}
        changeVisibility={changeVisibility}
        appArtistModel={appArtistModel}
        libraryMaterials={libraryMaterials}
        nominableMaterials = {nominableMaterials}
        setNominableMaterials = {setNominableMaterials}
        preNominatedMaterials = {preNominatedMaterials}
        nominatedMaterials = {nominatedMaterials}
        setNominatedMaterials = {setNominatedMaterials}
        highPolyModelSize={highPolyModelSize}
        highPolyTextureSize={highPolyTextureSize}
        lowPolyModelSize={lowPolyModelSize}
        lowPolyTextureSize={lowPolyTextureSize}
        totalFileSize={totalFileSize}
        newGlbSize={newGlbSize}
        cryptoMatteExists={cryptoMatteExists}
        setOverrideSuccess={setOverrideSuccess}
        overrideSuccess={overrideSuccess}
        dimensionCrypto={dimensionCrypto}
        previousState={previousState}
        basePlatform={basePlatform}
        basePlatformLoader={basePlatformLoader}
        checkFolderStructure={checkFolderStructure}
        feedbackImages={feedbackImages}
        />
    );


}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    updateProduct: (product) => {
        dispatch(updateProduct(product));
    },
    listProducts: () => {
        dispatch(listProducts());
    },
    rejectProduct: (product) => {
        dispatch(rejectProduct(product))
    }
});

export default Form.create({ name: 'render-override-form' })(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminProductDetails)
))