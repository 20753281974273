import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/catch';
import { ajax } from 'rxjs-compat/observable/dom/ajax';
import ENVIRONMENT from '../../environments'
import {
  FETCH_PRODUCT,
  GET_SCANNED_PRODUCTS,
  RENDER_PRODUCT,
  CREATE_PRODUCT,
  LIST_PRODUCT,
  LIST_STORE_PRODUCT,
  UPDATE_PRODUCT,
  REJECT_PRODUCT,
  productFailureAction,
  productSuccessAction,
  productRejectSuccessAction, getProductRender,
} from '../actions';

export const fetchProduct = (action$) => {
  return action$
      .ofType(FETCH_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.FETCH_PRODUCT,
            payload, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productSuccessAction({ ...data.response, isCreated: false, isUpdated: false,  isRejected: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to get product.' })));
      });
};

export const renderProduct = (action$) => {
  return action$
      .ofType(RENDER_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.RENDER_PRODUCT,
            { ...payload, username: payload.username ? payload.username : localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productSuccessAction({ ...data.response, isCreated: false, isUpdated: false,  isRejected: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to get product.' })));
      });
};


export const listProducts = (action$) => {
  return action$
      .ofType(LIST_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.LIST_PRODUCT,
            { username: localStorage.getItem('username'), company_id: localStorage.getItem('company_id') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productSuccessAction({ products: data.response, isCreated: false, isUpdated: false,  isRejected: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to create product. Fill missing fields.' })));
      });
};

export const listStoreProducts = (action$) => {
  return action$
      .ofType(LIST_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.LIST_PRODUCT,
            { approved: '' }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productSuccessAction({ products: data.response, isCreated: false, isUpdated: false, isRejected: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to create product. Fill missing fields.' })));
      });
};

export const getScannedProducts = (action$) => {
  return action$
      .ofType(GET_SCANNED_PRODUCTS)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.GET_SCANNED_ROOMS,
            { username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productSuccessAction({ scannedProducts: data.response.models, isCreated: false, isUpdated: false, isRejected: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to get product' })));
      });
};


export const createProduct = (action$) => {
  return action$
      .ofType(CREATE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.CREATE_PRODUCT,
            { ...payload, username: localStorage.getItem('username'), company_id: localStorage.getItem('company_id') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productSuccessAction({ ...data.response, isCreated: true, isUpdated: false, isRejected: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to create product. Fill missing fields.' })));
      });
};


export const updateProduct = (action$) => {
  return action$
      .ofType(UPDATE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.UPDATE_PRODUCT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productSuccessAction({ ...data.response, isCreated: false, isUpdated: true, isRejected: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to update product. Fill missing fields.' })));
      });
};

export const rejectProduct = (action$) => {
  return action$
      .ofType(REJECT_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.ENTITY_MODEL_REJECT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => productRejectSuccessAction({ ...data.response, isCreated: false, isUpdated: false, isRejected: true }))
            .map( (data) => getProductRender(payload))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to update product. Fill missing fields.' })));
      });
};
