import React, {useState} from 'react';
import { Modal,Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import AddCustomerForm from './AddCustomerForm';

const AddCustomerModal = (props) => {

  const [isAddCustomerModal, setIsAddCustomerModal] = useState(false);

  const handleCancel = () => {
    setIsAddCustomerModal(false);
  }

  const handleOpen = () => {
    setIsAddCustomerModal(true);
  }

  return (
    <>
        <Button onClick={handleOpen} className="store-button grey store-button-text text-white">
            Add Customer
            <UserAddOutlined />
        </Button>
        <Modal
        className="sell-on-store"
        maskClosable={true}
        destroyOnClose={true}
        visible={isAddCustomerModal}
        onCancel={handleCancel}
        footer={null}>
            <AddCustomerForm onSuccess= {handleCancel} />
        </Modal>
    </>);
}

export default AddCustomerModal;