export const FETCH_ROOM = 'FETCH_ROOM';
export const GET_SCANNED_ROOMS = 'GET_SCANNED_ROOMS';
export const RENDER_ROOM = 'RENDER_ROOM';
export const LIST_ROOM = 'LIST_ROOM';
export const CREATE_ROOM = 'CREATE_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const ROOM_SUCCESS = 'ROOM_SUCCESS';
export const ROOM_FAILURE = 'ROOM_FAILURE';
export const REJECT_ROOM = 'REJECT_ROOM';
export const ROOM_REJECT_SUCCESS = 'ROOM_REJECT_SUCCESS';

export const fetchRoom = (room) => ({
  type: FETCH_ROOM,
  payload: room,
});

export const getRoomRender = (room) => ({
  type: RENDER_ROOM,
  payload: room,
});

export const getScannedRooms = (room) => ({
  type: GET_SCANNED_ROOMS,
  payload: room,
});


export const listRooms = (room) => ({
  type: LIST_ROOM,
  payload: room,
});

export const createRoom = (room) => ({
  type: CREATE_ROOM,
  payload: room,
});

export const updateRoom = (room) => ({
  type: UPDATE_ROOM,
  payload: room,
});

export const roomSuccessAction = (room) => ({
  type: ROOM_SUCCESS,
  payload: room,
});

export const roomFailureAction = (error) => ({
  type: ROOM_FAILURE,
  payload: error,
});

export const rejectRoom = (room) => ({
  type: REJECT_ROOM,
  payload: room,
});

export const roomRejectSuccessAction = (room) => ({
  type: ROOM_REJECT_SUCCESS,
  payload: room,
});