import React from "react";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'


class AdminCategoryOther extends React.Component {
    state = {
      treeData: {}
    };

    onChange = e => {
        this.setState({ size: e.target.value });
    };

    onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
    };

    render() {
        const { size } = this.state;
        return (
            <div>No Data Availabe</div>
        );
    }
}

export default AdminCategoryOther;