import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";
import CustomerProductContext from "../../ContextFiles/CustomerProductContext";
import { Button, Row, Col, Form, Select, message, Modal, Switch } from "antd";
import ENVIRONMENT from "../../../../environments";
import SuccessModal from "../../SuccessModal/SuccessModal";
import { LoadingOutlined } from "@ant-design/icons";
import FileConstants from "../../../../FileConstants";
const { Option } = Select;

const ProductMP4Modal = () => {
  const {
    currentId,
    product_id,
    mp4DetailsModal,
    setMp4DetailsModal,
    requestStatus,
    cardStatus,
    productData,
    setStatusOfMp4Video,
    statusOfMp4Video,
    selectedPriceCategory,
    mp4Price,
    setInAppLimitExceededModal,
    setPaymentFailureModal,
    setCardSaveFlowTriggered,
    setPaymentFailureMessage,
    cardSaveFlowTriggered,
    openDimensionalModal,
    openThreeSixtyModal,
    statusLoader,
    variantSelected,
    setStatusLoader,
    setVariantSelected,
    setVariationProductData,
    setVariationAssetData,
    variationAssetData,
    setSavedVariationProductDetails,
    variationProductData,
  } = useContext(CustomerProductContext);

  const [requestType, setRequestType] = useState("");
  const [mp4Payload, setMp4Payload] = useState(null);
  const [mp4SuccessModal, setMp4SuccessModal] = useState(false);
  const [mp4GenerationLoader, setMp4GenerationLoader] = useState(false);
  const [mp4Resolution, setMp4Resolution] = useState("1000");
  const [mp4FrameCount, setMp4FrameCount] = useState("72");
  const [mp4FpsCount, setMp4FpsCount] = useState("10");
  const [replaceMP4Confirm, setReplaceMP4Confirm] = useState(false);
  const [newMP4Status, setNewMP4Status] = useState("");
  const [dropShadows, setDropShadows] = useState(false);

  useEffect(() => {
    if (variantSelected) {
      setStatusLoader(true);
      if (variationAssetData) {
        if (variationAssetData.mp4_details != undefined) {
          if (Object.entries(variationAssetData.mp4_details).length !== 0) {
            setNewMP4Status(variationAssetData["mp4_details"].asset_status);
          } else {
            setNewMP4Status("");
          }
        } else {
          setNewMP4Status("");
        }
        setStatusLoader(false);
      }
    } else {
      setNewMP4Status(statusOfMp4Video);
    }
  }, [variantSelected]);

  const handleMp4FrameChange = (value) => {
    setMp4FrameCount(value);
  };

  const handleFpsChange = (value) => {
    setMp4FpsCount(value);
  };

  const handleMp4ResChange = (value) => {
    setMp4Resolution(value);
  };

  const handleMp4Submit = (values) => {
    let payload = {};
    payload.product_id = variantSelected ? currentId : product_id;
    payload.username = localStorage.getItem("username");
    payload.mp4_details = values;
    payload.mp4_details.captured_by = localStorage.getItem("username");
    payload.mp4_details.asset_status = "pending";
    payload.mp4_job_status = "generate";
    payload.generate_asset = 1;
    let company_id = localStorage.getItem("company_id");
    if (dropShadows) {
      payload.custom_rig = FileConstants.lightRigUrl["white-bg-dropShadow-on"];
    }
    let product_data = variantSelected ? variationProductData : productData;
    if (company_id != undefined && product_data.company_id != undefined) {
      if (company_id == product_data.company_id) {
        payload.company_id = company_id;
      }
    }
    setMp4Payload(payload);
    setRequestType("product_video");
    let requestPayload = payload;
    requestPayload["username"] = localStorage.getItem("username");
    let gateway_payload = {
      username: localStorage.getItem("username"),
      request_type: "product_video",
      action: "perform",
      request_payload: requestPayload,
      category: selectedPriceCategory,
      request_id: Date.now().toString(),
    };
    if (newMP4Status != "") {
      if (
        requestStatus == "allowed" ||
        requestStatus == "always_allowed" ||
        requestStatus == "not_allowed"
      ) {
        setMp4GenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload).then((res) => {
          if (res.data.status != undefined && res.data.status == 1) {
            setMp4SuccessModal(true);
            closeMP4Modal();
            setMp4GenerationLoader(false);
            setStatusOfMp4Video("pending");
          } else {
            if (res.data.request_allowed == "not_allowed") {
              setInAppLimitExceededModal(true);
              closeMP4Modal();
              setMp4GenerationLoader(false);
            } else {
              message.error("something went wrong");
            }
          }
        });
      }
      if (requestStatus == "payment_required") {
        window.addEventListener(
          "card_saved",
          (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == "product_video") {
              mp4RequestAPICall(gateway_payload);
            }
          },
          false
        );

        if (cardStatus == 1) {
          mp4RequestAPICall(gateway_payload);
        }
        if (cardStatus == 0) {
          let child_window = window.open(
            "/save-card?action=product_video",
            "",
            "width=600,height=600"
          );
          let refreshInterval = setInterval(() => {
            if (child_window.closed) {
              setCardSaveFlowTriggered(false);
              clearInterval(refreshInterval);
            }
          }, 500);
          setCardSaveFlowTriggered(true);
        }
      }
    } else {
      setReplaceMP4Confirm(true);
      closeMP4Modal();
      setMp4GenerationLoader(false);
    }
  };

  const mp4RequestAPICall = (gateway_payload) => {
    setMp4GenerationLoader(true);
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload).then((res) => {
      if (res.data.status != undefined && res.data.status == 1) {
        setMp4SuccessModal(true);
        closeMP4Modal();
        setMp4GenerationLoader(false);
        setStatusOfMp4Video("pending");
      } else {
        if (res.data.request_allowed == "not_allowed") {
          setInAppLimitExceededModal(true);
          closeMP4Modal();
          setMp4GenerationLoader(false);
        } else {
          setPaymentFailureMessage(res.data.error_message);
          setPaymentFailureModal(true);
          setMp4GenerationLoader(false);
        }
      }
    });
  };

  const replaceMP4 = () => {
    setRequestType("product_video");
    let gateway_payload = {
      username: localStorage.getItem("username"),
      request_type: "product_video",
      action: "perform",
      request_payload: mp4Payload,
      category: selectedPriceCategory,
      request_id: Date.now().toString(),
    };

    if (newMP4Status == "") {
      if (
        requestStatus == "allowed" ||
        requestStatus == "always_allowed" ||
        requestStatus == "not_allowed"
      ) {
        setMp4GenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload).then((res) => {
          if (res.data.status != undefined && res.data.status == 1) {
            setReplaceMP4Confirm(false);
            setMp4SuccessModal(true);
            closeMP4Modal();
            setStatusOfMp4Video("pending");
            setMp4GenerationLoader(false);
          } else {
            if (res.data.request_allowed == "not_allowed") {
              setInAppLimitExceededModal(true);
              closeMP4Modal();
              setMp4GenerationLoader(false);
            } else {
              message.error("something went wrong");
            }
          }
        });
      }
      if (requestStatus == "payment_required") {
        window.addEventListener(
          "card_saved",
          (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == "product_video") {
              mp4ReplaceRequestAPICall(gateway_payload);
            }
          },
          false
        );

        if (cardStatus == 1) {
          mp4ReplaceRequestAPICall(gateway_payload);
        }
        if (cardStatus == 0) {
          let child_window = window.open(
            "/save-card?action=product_video",
            "",
            "width=600,height=600"
          );
          let refreshInterval = setInterval(() => {
            if (child_window.closed) {
              setCardSaveFlowTriggered(false);
              clearInterval(refreshInterval);
            }
          }, 500);
          setCardSaveFlowTriggered(true);
        }
      }
    }
  };

  const mp4ReplaceRequestAPICall = (gateway_payload) => {
    setMp4GenerationLoader(true);
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload).then((res) => {
      if (res.data.status != undefined && res.data.status == 1) {
        setReplaceMP4Confirm(false);
        setMp4SuccessModal(true);
        closeMP4Modal();
        setStatusOfMp4Video("pending");
        setMp4GenerationLoader(false);
      } else {
        if (res.data.request_allowed == "not_allowed") {
          setInAppLimitExceededModal(true);
          closeMP4Modal();
          setMp4GenerationLoader(false);
        } else {
          setPaymentFailureMessage(res.data.error_message);
          setPaymentFailureModal(true);
          setMp4GenerationLoader(false);
        }
      }
    });
  };

  const closeMP4Modal = () => {
    setMp4DetailsModal(false);
    setVariationProductData(null);
    setVariationAssetData(null);
    setVariantSelected(false);
    setSavedVariationProductDetails(null);
  };

  return (
    <>
      <Modal
        visible={mp4DetailsModal}
        className="modal-share"
        width={788}
        onCancel={closeMP4Modal}
        footer={
          statusLoader
            ? ""
            : [
                <div
                  className={
                    requestStatus == "payment_required" ||
                    requestStatus == "not_allowed"
                      ? "justify-space-between"
                      : "justify-in-end"
                  }
                >
                  {requestStatus == "payment_required" ||
                  requestStatus == "not_allowed" ? (
                    <div className="manrope f-14 black-55">
                      Total Price:{" "}
                      <span
                        className="manrope f-14 w-700"
                        style={{ color: "#25D6A4" }}
                      >
                        {"$" + mp4Price}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <Button
                    disabled={cardSaveFlowTriggered || mp4GenerationLoader}
                    style={{ textAlign: "center" }}
                    className="modal-okay square font-14"
                    onClick={() =>
                      document.getElementById("generate-mp4-button").click()
                    }
                  >
                    {mp4GenerationLoader ? (
                      <>
                        Submitting Request
                        <LoadingOutlined style={{ marginLeft: 6 }} spin />
                      </>
                    ) : (
                      <>
                        {cardStatus == 1 &&
                        (requestStatus == "payment_required" ||
                          requestStatus == "not_allowed")
                          ? "Confirm & Process Payment"
                          : cardStatus == 0 &&
                            (requestStatus == "payment_required" ||
                              requestStatus == "not_allowed")
                          ? "Confirm & Enter Payment Details"
                          : "Confirm"}
                      </>
                    )}
                  </Button>
                </div>,
              ]
        }
      >
        {statusLoader ? (
          <LoadingOutlined className="loading-center mt-10 mb-20" />
        ) : (
          <div style={{ padding: "16px 16px 0 16px" }}>
            <h2
              className="manrope f-18 black-14 w-700"
              style={{ marginBottom: 20 }}
            >
              MP4 Video Configuration
            </h2>
            {newMP4Status != "" ? (
              <div
                style={{ padding: "10px 16px", marginTop: 0, marginBottom: 30 }}
                className="note-flag"
              >
                <span className="manrope f-14 grey-73">
                  <span className="manrope f-14 grey-73 w-700">Note:</span>
                  &nbsp;Please select a configuration different than before to
                  request another MP4 Video.
                </span>
              </div>
            ) : (
              ""
            )}
            <span>
              <Form onFinish={handleMp4Submit} layout="vertical">
                <Row type="flex" gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      name="frame_count"
                      className="material-category-form"
                      label={
                        <span className="manrope f-14 black-55 w-900">
                          Video Smoothness
                        </span>
                      }
                      initialValue="72"
                      colon={false}
                    >
                      <Select
                        value={mp4FrameCount}
                        onChange={handleMp4FrameChange}
                        className="manrope black-55 tree-select-material f-12"
                        defaultValue={"72"}
                      >
                        <Option value="72" className="manrope f-12 select-view">
                          72 Frames
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ marginTop: 24 }}>
                    <Form.Item
                      name="frame_rate"
                      className="material-category-form"
                      label={
                        <span className="manrope f-14 black-55 w-900">
                          Video Speed
                        </span>
                      }
                      initialValue="10"
                      colon={false}
                    >
                      <Select
                        value={mp4FpsCount}
                        onChange={handleFpsChange}
                        className="manrope black-55 tree-select-material f-12"
                        defaultValue={"10"}
                      >
                        <Option value="10" className="manrope f-12 select-view">
                          10 fps (Default)
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ marginTop: 24 }}>
                    <Form.Item
                      name="resolution"
                      className="material-category-form"
                      label={
                        <span className="manrope f-14 black-55 w-900">
                          Video Quality
                        </span>
                      }
                      initialValue="1080"
                      colon={false}
                    >
                      <Select
                        value={mp4Resolution}
                        onChange={handleMp4ResChange}
                        className="manrope black-55 tree-select-material f-12"
                        defaultValue={"1080"}
                      >
                        <Option
                          value="1080"
                          className="manrope f-12 select-view"
                        >
                          1080p (Default)
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ marginTop: 24 }}>
                    <Form.Item>
                      <span>Drop Shadows: </span>
                      <Switch
                        value={dropShadows}
                        onChange={(checked) => {
                          setDropShadows(checked);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Button
                    id="generate-mp4-button"
                    type="primary"
                    htmlType="submit"
                    style={{ display: "none" }}
                  >
                    Submit
                  </Button>
                </Row>
              </Form>
            </span>
          </div>
        )}
      </Modal>

      <Modal
        closable={false}
        visible={replaceMP4Confirm}
        className="modal-share-success"
        width={600}
        onCancel={() => setReplaceMP4Confirm(false)}
        footer={[
          <div className="justify-in-center">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay-black square font-12"
              onClick={() => setReplaceMP4Confirm(false)}
            >
              Cancel MP4
            </Button>
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-12"
              onClick={() => replaceMP4()}
            >
              Replace Older MP4{" "}
              {mp4GenerationLoader ? <LoadingOutlined spin /> : ""}
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            margin: "30px auto 0px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2
            className="manrope f-16 black-00 w-600"
            style={{ marginBottom: 8 }}
          >
            Your Older MP4 Video Will Be Replaced
          </h2>
          <div className="manrope f-14 black-55">
            Your previously generated MP4 video will be replaced with the new
            one. Are you sure you want continue with your request?
          </div>
        </div>
      </Modal>
      <SuccessModal
        visible={mp4SuccessModal}
        onCancel={() => setMp4SuccessModal(false)}
        heading={"MP4 Video Requested Successfully!"}
        closable={true}
        text={
          "You have successfully requested your new mp4 video. The current mp4 video will be replaced as soon as the new one is available. We will notify you once it is ready."
        }
        footer={[
          <div className="justify-in-center">
            <Button
              className="modal-okay square font-12"
              htmlType="submit"
              onClick={() => {
                window.location.href = "/silo-tool/" + product_id;
              }}
            >
              Create Silo Images
            </Button>

            <Button
              className="modal-okay square font-12"
              htmlType="submit"
              onClick={() => {
                setMp4SuccessModal(false);
                openDimensionalModal();
              }}
            >
              Create Dimensional Image
            </Button>
            <Button
              className="modal-okay square font-12"
              htmlType="submit"
              onClick={() => {
                setMp4SuccessModal(false);
                openThreeSixtyModal();
              }}
            >
              Create 360 Spin
            </Button>
          </div>,
        ]}
      />
    </>
  );
};

export default ProductMP4Modal;
