import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControlsSilo } from './OrbitControlsSilo.js';
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {HorizontalBlurShader} from "three/examples/jsm/shaders/HorizontalBlurShader";
import {VerticalBlurShader} from "three/examples/jsm/shaders/VerticalBlurShader";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import FileConstants from './FileConstants';
import { dom, GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js'
import { combineAll } from "rxjs-compat/operator/combineAll";
import { max, min, throwIfEmpty } from "rxjs/operators";
import { getKeyThenIncreaseKey } from "antd/lib/message";
import { element } from "prop-types";
import { ThemeProvider } from "styled-components";
import { ASSET_GLB_URL, SILO_CONFIG_JSON } from "./environments/env.js";
import { PVB } from './PVB.js'
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import ContactShadows from "./ContactShadows.js";
import HDR from './env/neutral.hdr'
export default class Inspector {

	constructor (productID,sceneContainerID, updateScreenWidth, initial_silo_data, placementType, setLoadedProgressFunc = null, perspectiveImageFunc = null, modelLoadedFunc = null, setSliderPosition = null, assetBaseURL = null) {
		this.productID = productID;
		this.configFile = SILO_CONFIG_JSON;
		this.sceneContainer = document.getElementById(sceneContainerID);
		this.grid = document.getElementById("grid");
		this.gridSnapshot = document.getElementById("grid-snapshot");
		this.gridState = false;
		this.imageData = '';
		this.setSliderPosition= setSliderPosition;
		this.inspectorName = sceneContainerID;
		this.selectedImage = '';
		this.moveHorizontal =  document.querySelector('.horizontal');
		this.moveVertical = document.querySelector('.vertical');
		this.repeatPttrn = document.querySelector('.repeat');
		this.txtRotateBtn = document.querySelector('#rotateTexture');
		this.txtRotateBtnAnti = document.querySelector('#rotateTextureAnti');
		this.screenshotBtn = document.querySelector('#takeScreenshot');
		this.handleDiv = document.createElement('div');
        this.handleDiv.id = "camera";
        this.handleDiv.innerHTML = '<img style="position: absolute;top: 1px;width:32px;height:32px;right: -12px;-moz-user-select: none;-webkit-user-select: none;user-select: none;" src="/img/camera_widget.svg"/>';
        this.slider_handle = document.getElementsByClassName("ant-slider-handle")[0];
		if (this.slider_handle != undefined)
	        this.slider_handle.appendChild(this.handleDiv);
		this.clockwiseValue = 0;
		this.antiClockwiseValue = 0;
		this.zoomedInState = false;
		this.updatedScreenWidth = updateScreenWidth;
		this.updatedSiloData = [];
		this.siloInformation = [];
		this.siloImagesStates = [];
		this.siloRemovalArray = [];
		this.siloImages = initial_silo_data;
		this.prevRenders = null;
		if (this.siloImages != null || this.siloImages != undefined) {
			this.prevRenders = this.siloImages.length;
			// this.siloImages.map((data) => {
			// 	this.setExistingSiloStates();
			// });
		} else if (this.siloImages == undefined) {
			this.siloImages = [];
		}
		this.selectedPerspectiveIndex = 0;
		this.panToggle = false;
		this.toggleInPerspective = false;

		this.perspectiveImageFunc = perspectiveImageFunc;
		this.setLoadedProgressFunc = setLoadedProgressFunc;

		this.screenshotCount = 0;
		this.updateScreenProps();

		this.loader = this.buildGLTFLoader();

		this.scene = this.buildScene();
		this.sceneWidth = this.sceneContainer.clientWidth;
		this.sceneHeight = this.sceneContainer.clientHeight;
		this.sceneRenderer = this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
		this.sceneContainer.appendChild( this.sceneRenderer.domElement );
		this.lights = [];
		this.lightTemperature = this.rgbToNormalized(FileConstants.temperatureColors[5000]);
		this.buildSceneLights();
		this.gridHelper = null;
		this.arrowHelperX = null;
		this.arrowHelperY = null;
		this.arrowHelperZ = null;
		this.lightGroup = new THREE.Group()
		this.scene.add(this.lightGroup);
		this.camera = new THREE.PerspectiveCamera( 37.8, this.sceneWidth / this.sceneHeight, 1, 1000 ); // 37.8 vertical fov == 35mm lens focal length
		this.controls = new OrbitControlsSilo( this.camera, this.sceneRenderer.domElement, this.lightGroup );
		this.controls.enablePan = false;
		this.controls.minDistance = -Infinity;
		this.controls.maxDistance = Infinity;
		this.controls.zoomSpeed = 0.2;
		this.controls.enableZoom = true;

		this.baseURL = assetBaseURL;
 
       	if ( this.productID != undefined ) {
           this.loadModel(this.productID);
		}

		this.pointer = new THREE.Vector2();
		this.raycaster = new THREE.Raycaster();
		this.imgFormat = null;
		this.imgDPI = null;
		this.imgResolutions = [];
		this.ViewNames = [];
		this.placementType = placementType;
		this.aspectApplied = false;
		this.aspect_type = 'horizontal';
		this.aspect_ratio = '16:9';

		this.boundariesThreshold = 0.8;
		this.prevCamPos = this.controls.object.position.clone();
		this.prevTargetPos = this.controls.target.clone();
		this.prevSliderPos = this.controls.getSliderPosition();
		this.prevHorizontalSlider = this.controls.getHorizontalPosition();
		this.sceneRenderer.sortObjects = true;
		this.composer = null;
		this.renderPass = null;
		this.horizontalBlur = null;
		this.verticalBlur = null;
		this.horizontalFOV = 0;
		this.defaultHorizontalFOV = 0;
		this.perspectivesCount = 0;

		this.createAxes();

		this.buildGUI();
		this.setupEventListeners();

		//this.blurEffects();
		this.animate();
		this.onWindowResize();
		this.modelType = null;
		this.configurationData = null;
		this.modelLoadedFunc = modelLoadedFunc;
		this.regularFeatures = ["Base","Body","Legs"];
		this.uniqueFeatures = [];
		this.modelPVB = null;

		this.cornerViewOffets = {
			"back" : 0.8,
			"front" : 0.2
		}

		this.innerCube = null;
		this.outerCube = null;
		this.blindCubeSize = new THREE.Vector3(1.5,1.5,0);

		this.siloInformation = {
			product_id: this.productID,
			silo_data: this.siloImages
		}

		this.tromboneZoomFactor = 1;
		
		this.loadingProgress = 0;

		this.greyMat = new THREE.MeshStandardMaterial();
		this.greyMat.color.setRGB(0.2, 0.2, 0.2);
		this.greyMat.envMapIntensity = 0.5;
		
		this.isGreyMaterial = false;
		
		this.originalMaterials = new Object();
		
	}

	// *************************** blur effects **************************//

	blurEffects() {


		this.composer = new EffectComposer(this.sceneRenderer);

		this.renderPass = new RenderPass(this.scene, this.camera);
		this.composer.addPass(this.renderPass);

		this.horizontalBlur = new ShaderPass({
			...HorizontalBlurShader,
			uniforms: {
			...HorizontalBlurShader.uniforms,
			h: {value: 0.3 / 512.0},
			},
		})
		this.composer.addPass(this.horizontalBlur)

		this.verticalBlur = new ShaderPass({
			...VerticalBlurShader,
			uniforms: {
			...VerticalBlurShader.uniforms,
			v: {value: 0.3 / 512.0},
			},
		})
		this.composer.addPass(this.verticalBlur);



	}

	// *************************** silo images **************************//

	showGridForSilos() {

        if (this.gridState == false) {
			this.setGridForSilos(true);
		}
        else {
            this.setGridForSilos(false);
        }
	}

	updateGridForSilo() {
		if  (this.gridState == true) {
			this.setGridForSilos(false);
			this.setGridForSilos(true);
		}
	}

	setGridForSilos(state) {
		if (state == true) {
			this.grid = document.getElementById("grid");
			this.gridSnapshot = document.getElementById("grid-snapshot")
			this.gridSnapshot.height = this.sceneHeight;
			this.gridSnapshot.width = this.sceneWidth;
			this.gridSnapshot.style.display = "inline";
			this.grid.style.margin = "0 auto"
			this.grid.style.display = "flex";
			this.grid.style.justifyContent = "center";
		}
		else {
			this.gridSnapshot.style.display = "none";
		}
		this.gridState = state;

	}

	// to create axes and grid for rotation in the scene
	createAxes() {
		const size = 10;
		const divisions = 10;
		this.gridHelper = new THREE.GridHelper( size, divisions );
		this.scene.add( this.gridHelper );
		// Direction: up
		var dir = new THREE.Vector3( 0, 1, 0 );
		dir.normalize();
		var origin = new THREE.Vector3( 0, 0, 0 );
		var length = 1;
		var hex = null;
		hex = 0x0000ff;
		this.arrowHelperX = new THREE.ArrowHelper( dir, origin, length, hex );
		dir.set( 1, 0, 0 );
		hex = 0xff0000;
		this.arrowHelperY = new THREE.ArrowHelper( dir, origin, length, hex );
		dir.set( 0, 0, 1 );
		hex = 0x00ff00;
		this.arrowHelperZ = new THREE.ArrowHelper( dir, origin, length, hex );
		this.scene.add( this.arrowHelperX );
		this.scene.add( this.arrowHelperY );
		this.scene.add( this.arrowHelperZ );
		this.arrowHelperX.line.material.depthTest = false;
		this.arrowHelperY.line.material.depthTest = false;
		this.arrowHelperZ.line.material.depthTest = false;
		this.arrowHelperX.cone.material.depthTest = false;
		this.arrowHelperY.cone.material.depthTest = false;
		this.arrowHelperZ.cone.material.depthTest = false;
		this.arrowHelperX.line.material.transparent = true;
		this.arrowHelperY.line.material.transparent = true;
		this.arrowHelperZ.line.material.transparent = true;
		this.arrowHelperX.cone.material.transparent = true;
		this.arrowHelperY.cone.material.transparent = true;
		this.arrowHelperZ.cone.material.transparent = true;
		this.arrowHelperX.renderOrder = 1;
		this.arrowHelperY.renderOrder = 2;
		this.arrowHelperZ.renderOrder = 3;
		var width = 2.5;
		this.arrowHelperX.line.material.linewidth = width;
		this.arrowHelperY.line.material.linewidth = width;
		this.arrowHelperZ.line.material.linewidth = width;
	}



	placeAxes() {
		let scope = this;
		if (scope.model!= null){
			var length = 2;
			this.arrowHelperX.position.copy(this.controls.target);
			this.arrowHelperY.position.copy(this.controls.target);
			this.arrowHelperZ.position.copy(this.controls.target);
			if (this.zoomedInState == false) {
				this.gridHelper.position.copy(this.controls.target);
				this.arrowHelperX.position.copy(this.controls.target);
				this.arrowHelperY.position.copy(this.controls.target);
				this.arrowHelperZ.position.copy(this.controls.target);
				this.arrowHelperX.position.y -= this.model.size.y/2;
				this.arrowHelperY.position.y -= this.model.size.y/2;
				this.arrowHelperZ.position.y -= this.model.size.y/2;
				this.gridHelper.position.y -= this.model.size.y/2;
			}
			else {
				length = 0.3;
			}
			var scaleVector = new THREE.Vector3();
			var scaleFactor = 5;
			var scale = scaleVector.subVectors(this.arrowHelperX.position, this.camera.position).length() / scaleFactor;
			this.arrowHelperX.scale.set(scale,scale,scale);
			scale = scaleVector.subVectors(this.arrowHelperY.position, this.camera.position).length() / scaleFactor;
			this.arrowHelperY.scale.set(scale,scale,scale);
			scale = scaleVector.subVectors(this.arrowHelperZ.position, this.camera.position).length() / scaleFactor;
			this.arrowHelperZ.scale.set(scale,scale,scale);
			this.arrowHelperX.setLength(length);
			this.arrowHelperY.setLength(length);
			this.arrowHelperZ.setLength(length);
		}
	}



	getSceneRender() {
        return this.sceneRenderer;
    }
	// set image format information
	setImgFormat(value) {
		this.imgFormat = value;
	}

	// set DPI information
	setImgDPI(value) {
		this.imgDPI = value;
	}
	
	getImageDPI() {
		return this.imgDPI;
	}

	getImgFormat() {
		return this.imgFormat;
	}
	
	getAspect() {
		return this.aspect;
	}
	
	// set resolution selected by user (high medium low) to image width and height to be passed to 3d max
	//"1920 x 1080"
	setResolution(resolution) {
		this.imgWidth = parseInt(resolution.split('x')[0]);
		this.imgHeight = parseInt(resolution.split('x')[1]);
	}

	// calculate width according to aspect ratio if custom height is entered
	setCustomResolutionWidth(height,new_aspect) {
		if (new_aspect != null) {
			this.aspect = new_aspect;
		}
		this.imgHeight = height;
		this.imgWidth =  height * this.aspect;
		return this.imgWidth;
	}

	// calculate height according to aspect ratio if custom width is entered
	setCustomResolutionHeight(width,new_aspect) {
		if (new_aspect != null) {
			this.aspect = new_aspect;
		}
		this.imgWidth = width;
		this.imgHeight = width / this.aspect;
		return this.imgHeight;
	}

	setCustomHeight(height) {
		this.imgHeight = height;
		return this.imgHeight;
	}

	setCustomWidth(width) {
		this.imgWidth = width;
		return this.imgWidth;
	}
	 // convert from degree to radians
	 toRadians (angle) {
		 return angle * Math.PI / 180;
	}

	customZoomIn(zoomX, zoomY, zoomZ) {
		this.camera.position.set( this.camera.position.x - ((this.camera.position.x - this.controls.target.x) * zoomX) , this.camera.position.y - ((this.camera.position.y - this.controls.target.y) * zoomY) , this.camera.position.z - ((this.camera.position.z - this.controls.target.z) * zoomZ) );
	}

	zoomInCamera() {
		this.zoomIn();
		this.updateTromboneZoom();
    }

	zoomIn(zoomFactor = 0.1) {
		this.camera.position.set( this.camera.position.x - (this.camera.position.x - this.controls.target.x) * zoomFactor , this.camera.position.y - (this.camera.position.y - this.controls.target.y) * zoomFactor , this.camera.position.z - (this.camera.position.z - this.controls.target.z) * zoomFactor );
    }

	zoomOutCamera() {
		this.zoomOut();
		this.updateTromboneZoom();
	}

	zoomOut(zoomFactor = 0.1) {
		this.camera.position.set( this.camera.position.x + (this.camera.position.x - this.controls.target.x) * zoomFactor, this.camera.position.y + (this.camera.position.y - this.controls.target.y) * zoomFactor, this.camera.position.z + (this.camera.position.z - this.controls.target.z) * zoomFactor);
	}


	getViewNames() {
		return this.ViewNames;
	}


	changeBackGroundColor(color) {
		this.scene.background = new THREE.Color(color);
		this.contactShadows.changePlaneColor(color)
	}
	
	setConfigurationData(data) {
		this.configurationData = data;
	}
	
	// set perspectives data from config file
	setPerspectives () {
		this.defaultHorizontalFOV = this.horizontalFOV;
		var imagesData =  this.configurationData[this.modelType]["images"]
		for (let i = 0; i< imagesData.length; i++) {
			this.ViewNames.push(imagesData[i]["display_name"]);
		}
		// if (this.placementType) {
		// 	let polar_angles = this.configurationData["polar_angle_limits"];;
		// 	for (let i = 0; i < polar_angles.length; i++) {
		// 		if (this.placementType === polar_angles[i]["placement_type"]) {
		// 			this.controls.minPolarAngleSet = THREE.MathUtils.degToRad(this.configurationData["polar_angle_limits"][i]["min"]);
		// 			this.controls.maxPolarAngleSet = THREE.MathUtils.degToRad(this.configurationData["polar_angle_limits"][i]["max"]);
		// 			this.controls.update();
		// 			break;
		// 		}
		// 	}
		// }
		for (let i in this.uniqueFeatures) {
			let shotInfo = {}
			shotInfo["system_name"] = "unique";
			shotInfo["display_name"] = "unique feature";
			shotInfo["polar_angle"] = 90;
			shotInfo["azimuthal_angle"] = 0;
			shotInfo["category"] = 'unique';
			this.configurationData[this.modelType]["images"].push(shotInfo);
			this.ViewNames.push("unique feature");
		}

		if (this.configurationData[this.modelType]["blinds"] && this.configurationData[this.modelType]["blinds"] == true) {
			let shotInfo = {}
			shotInfo["system_name"] = "blinds";
			shotInfo["display_name"] = "Inside Mount";
			shotInfo["category"] = 'blind';
			this.configurationData[this.modelType]["images"].push(shotInfo);
			this.ViewNames.push(shotInfo["display_name"]);
			let shotInfo2 = {}
			shotInfo2["system_name"] = "blinds";
			shotInfo2["display_name"] = "Outside Mount";
			shotInfo2["category"] = 'blind';
			this.configurationData[this.modelType]["images"].push(shotInfo2);
			this.ViewNames.push(shotInfo2["display_name"]);


		}


		return imagesData.length;
	}

	// default zoom for perspectives
	estimateZoom() {
		return 0.2;
	}


	// get center of model
	getModelPosition(){
		var modelCenter = new THREE.Vector3();
		if(this.model) {
			this.model.bBox.getCenter( modelCenter );
		}
		return modelCenter;
	}


	// enable axes view
	enableAxes(){
		this.gridHelper.visible = true;
		this.arrowHelperX.visible = true;
		this.arrowHelperY.visible = true;
		this.arrowHelperZ.visible = true;
		this.render();
		this.axisEnabled = true;
	}

	// disable axes view
	disableAxes(){
		this.arrowHelperX.visible = false;
		this.arrowHelperY.visible = false;
		this.arrowHelperZ.visible = false;
		this.gridHelper.visible = false;
		this.render();
		this.axisEnabled = false;
	}

	// convert fov
	convertHorizontaltoVertical() {
		 // desired horizontal fov, in degrees
		this.camera.fov = Math.atan( Math.tan( this.horizontalFOV * Math.PI / 360 ) / this.camera.aspect ) * 360 / Math.PI; // degrees
		this.camera.updateProjectionMatrix();
		this.controls.update();
	}

	// convert fov
	convertVerticaltoHorizontal() {
	   this.horizontalFOV = Math.atan( Math.tan( this.camera.fov * Math.PI / 360 ) * this.camera.aspect ) * 360 / Math.PI; // degrees;
	   this.controls.setHorizontalFOV(this.horizontalFOV);
	   this.controls.update();
   }

   // get camera focal length
   getCameraFocalLength() {
	   return this.camera.getFocalLength().toFixed(0)
   }


	// set horizontal fov from the frontend
	setHorizontalFOV(fov) {
		if (this.controls.enabled) {
			this.horizontalFOV = fov;
			this.controls.setHorizontalFOV(parseInt(this.horizontalFOV));
			this.controls.update();
			this.convertHorizontaltoVertical();
		}
	}
	
	getVerticalFov() {
		return this.camera.fov
	}

	// update horizontal fov from frontend
	updateHorizontalFOV(fov) {
		this.setHorizontalFOV(fov);
		this.adjustZoomOnFOVChange();
		this.update();
		this.render();
	}

	// set default horizontal fov
	setDefaultHorizontalFOV(fov) {
		this.defaultHorizontalFOV = fov;
	}

	// get default horizontal fov
	getDefaultHorizontalFOV() {
		return this.defaultHorizontalFOV;
	}

	// reset fov to default
	resetHorizontalFov() {
		this.horizontalFOV = this.defaultHorizontalFOV;
		this.controls.setHorizontalFOV(this.horizontalFOV);
		this.controls.update();
		this.convertHorizontaltoVertical();
	}

	// set horizontal fov from the frontend
	setFocalLength(value) {
		if(this.controls.enabled) {
			this.controls.setFocalLength(value);
			this.controls.update();
			this.convertVerticaltoHorizontal();
			this.adjustZoomOnFOVChange();
			this.update();
			this.render();
		}
	}

	// convert fov to focal length
	convertFOVtoFocalLength(fovValue) {
		return Math.floor(this.camera.getFilmHeight()/2) / (Math.tan((fovValue/2) * (Math.PI/180)))
	}

	// convert focal length to fov
	convertFocalLengthToFOV (focalLength) {
		return Math.floor(2 * Math.atan((this.camera.getFilmHeight()/2) / focalLength) * 180 / Math.PI);
	}

	// adjust zoom on fov change - trombone effect
	adjustZoomOnFOVChange() {
		this.camera.updateProjectionMatrix();
		const fov = this.camera.fov;
		const width = this.tromboneZoomFactor;
		const distance = width / (2 * Math.tan(THREE.MathUtils.degToRad(fov * 0.5)));
		const lerpDist = distance / (this.controls.target.distanceTo(this.controls.object.position));
		this.camera.position.lerpVectors(this.controls.target, this.camera.position, lerpDist);
	  }


	// get horizontal fov
	getHorizontalFOV() {
		return parseInt(this.horizontalFOV);
	}
	
	changePerspective (name) {
		let imagesData = this.siloInformation["silo_data"]
		for (let index in imagesData){
			if (imagesData[index]['camera_name'] == name) {
				this.selectedPerspectiveIndex = index;
				this.controls.reset()
				if (imagesData[index]['image_data']['world_position'] != undefined)
					this.camera.position.set(imagesData[index]['image_data']['world_position'].x, imagesData[index]['image_data']['world_position'].y, imagesData[index]['image_data']['world_position'].z)
				this.controls.target.copy(imagesData[index]['image_data']['lookAt']);
				this.controls.setPolarAngle(imagesData[index]['image_data']['polar_angle']);
				this.controls.setAzimuthalAngle(imagesData[index]['image_data']['azimuthal_angle']);
				this.controls.update();
				this.updateTromboneZoom();
				this.update();
				this.render();		
			}
		}
	}

	// switch between the perspectives
	changePerspectives(index,setPerspectiveFunc = null) {
		this.setInsideBlindSilo(false);
		this.setOutsideBlindSilo(false);
		if (this.toggleInPerspective) {
			this.controls.reset();
		}
		let imagesData = this.configurationData[this.modelType]["images"]
		let shotCategory = imagesData[index]['category']
		if (shotCategory != "blind") {
			this.selectedPerspectiveIndex = index;
			this.setCameraPolarAngle(imagesData[index]['polar_angle']);
			this.setCameraAzimuthalAngle(imagesData[index]['azimuthal_angle']);
			if (imagesData[index]['world_position'] != undefined)
				this.camera.position.set(imagesData[index]['world_position'].x, imagesData[index]['world_position'].y, imagesData[index]['world_position'].z);
			if (imagesData[index]['lookAt'] != undefined)
				this.controls.target.copy(imagesData[index]['lookAt']);
			if (imagesData[index]['horizontal_fov'] != undefined)
				this.setHorizontalFOV(imagesData[index]['horizontal_fov']);	
			this.controls.update();
			let targetVector = null;
			if (shotCategory == "detail") {
				if (imagesData[index]["display_name"].toLowerCase().includes("corner")) {
					let cornerVector = this.modelPVB.getCorners().c5.clone();
					cornerVector.y = this.getModelPosition().y;
					targetVector = cornerVector.add(this.getModelPosition().clone()).multiplyScalar(0.2);
					let modelHeight = this.modelPVB.halfHeight * 2.0;
					targetVector.y = modelHeight - (imagesData[index]["height"] * modelHeight);
					this.callZoomedInState(targetVector);
					this.customZoomIn(imagesData[index]["zoomX"], imagesData[index]["zoomY"], 0.5);
				}
				else if (imagesData[index]["display_name"].toLowerCase().includes("foot") || imagesData[index]["display_name"].toLowerCase().includes("legs")) {
					targetVector = this.getModelPosition();
					targetVector.y = imagesData[index]["height"];
					this.callZoomedInState(targetVector);
					this.customZoomIn(imagesData[index]["zoomX"], imagesData[index]["zoomY"], imagesData[index]["zoomZ"]);

				}
				else if (imagesData[index]["display_name"].toLowerCase().includes("back")) {
					targetVector = this.getModelPosition();
					targetVector.y = this.modelPVB.halfHeight * imagesData[index]["height"];
					this.callZoomedInState(targetVector);
					this.customZoomIn(imagesData[index]["zoomX"], imagesData[index]["zoomY"], imagesData[index]["zoomZ"]);
				}
			}
			else if (shotCategory == "unique"){
				for (let i in this.uniqueFeatures) {
					if(imagesData[index]["display_name"] == this.uniqueFeatures[i].name){
						this.callZoomedInState(this.uniqueFeatures[i].getWorldPosition(new THREE.Vector3()));
						this.zoomIn(0.5);
					}
				}

			}
			if (this.configurationData[this.modelType]["camera_height"]) {
				let camera_height = this.configurationData[this.modelType]["camera_height"];
				this.controls.object.position.y = (camera_height * 0.0254) ;
				this.controls.update()
			}
			this.updateTromboneZoom();
			this.enableAxes();
			this.update();
			this.render();
			if (setPerspectiveFunc != null) {
				setPerspectiveFunc(index);
			}
		}
		else {
			// set image
			this.switchToZoomedOut();
			this.resetHorizontalFov();
			this.controls.reset();
			this.configureCameraForBlinds();
			this.update();
			if (imagesData[index]["display_name"].toLowerCase().includes("inside mount")) {
				if (setPerspectiveFunc != null) {

					this.createInsideBlindSilo(setPerspectiveFunc, index);
				}
				else {
					this.setInsideBlindSilo(true);
				}

			}
			else {
				if (setPerspectiveFunc != null) {

					this.createOutsideBlindSilo(setPerspectiveFunc, index);
				}
				else {
					this.setOutsideBlindSilo(true);
				}

			}
		}

	}

	configureCameraForBlinds() {
		if (!this.model || !this.model.size) {
			return;
		}
		this.resetZoomingFactors();
		let vFov = this.camera.fov * ( Math.PI / 180 );
		let hFov = 2 * Math.atan ( ( this.sceneWidth / this.sceneHeight ) * Math.tan( vFov / 2 ) );
		let hFactor = 1.1 + ( hFov / 100.0 );
		let vFactor = 1.0 + ( vFov / 100.0 );
		let hFovLen = Math.abs ( this.blindCubeSize.x * hFactor );
		let vFovLen = Math.abs ( this.blindCubeSize.y * vFactor );
		let halfDepth = Math.abs( this.blindCubeSize.z / 2.0) ;

		let hDolly = hFovLen / Math.tan( hFov );
		hDolly *= ( hDolly / ( hDolly - halfDepth ) );
		let vDolly = vFovLen / Math.tan( vFov );
		vDolly *= ( vDolly / ( vDolly - halfDepth ) );

		if ( hDolly > vDolly )
		{
			this.camera.position.set( this.camera.position.x, this.camera.position.y, hDolly );
		}
		else
		{
			this.camera.position.set( this.camera.position.x, this.camera.position.y, vDolly);
		}


	}

	createInsideBlindSilo(setPerspectiveFunc = null, index = null) {
		this.model.visible = false;
		this.controls.enabled = false;
		var scope = this;
		new THREE.TextureLoader().load(
			require('./assets/images/insideBlind.jpg'),
			 ( texture )=> {
				// in this example we create the material when the texture is loaded
				var material = new THREE.MeshBasicMaterial( {
					map: texture
				 } );
					var geometry = new THREE.BoxGeometry(this.blindCubeSize.x, this.blindCubeSize.y, this.blindCubeSize.z);
					 var cube = new THREE.Mesh(geometry, material);
					scope.scene.add(cube);
					cube.position.copy(scope.controls.target0);
					scope.innerCube = cube;
					this.disableAxes();
					scope.update();
					scope.render();
					if (setPerspectiveFunc != null) {
						setPerspectiveFunc(index);
						scope.innerCube.visible = false;
						scope.enableAxes();
						scope.update();
						scope.render();

					}
			},undefined, ( err )=> { console.error( 'An error happened.' );}
		);

	}

	createOutsideBlindSilo( setPerspectiveFunc = null, index = null) {
		this.model.visible = false;
		this.controls.enabled = false;
		var scope = this;
		new THREE.TextureLoader().load(
			require('./assets/images/outsideBlind.jpg'),
			 ( texture )=> {
				// in this example we create the material when the texture is loaded
				var material = new THREE.MeshBasicMaterial( {
					map: texture
				 } );
					var geometry = new THREE.BoxGeometry(this.blindCubeSize.x, this.blindCubeSize.y, this.blindCubeSize.z);
					 var cube = new THREE.Mesh(geometry, material);
					scope.scene.add(cube);
					cube.position.copy(scope.controls.target0);
					scope.outerCube = cube;
					this.disableAxes();
					scope.update();
					scope.render();
					if (setPerspectiveFunc != null) {
						setPerspectiveFunc(index);
						scope.outerCube.visible = false;
						scope.enableAxes();
						scope.update();
						scope.render();
					}
			},
			undefined, ( err )=> { console.error( 'An error happened.' );}
		);
	}

	setInsideBlindSilo(state ) {
		if (state == true) {
			this.controls.enabled = false;
			if (this.innerCube != null) {
				this.innerCube.visible = true;
			}
			this.model.visible = false;
			this.disableAxes();
			this.update();
			this.render();
		}
		else {
			this.controls.enabled = true;
			if (this.innerCube != null) {
				this.innerCube.visible = false;

			}
			this.model.visible = true;
			this.update();
			this.render();
		}

	}

	setOutsideBlindSilo(state) {
		if (state == true) {
			this.controls.enabled = false;
			if (this.outerCube != null) {
				this.outerCube.visible = true;
			}
			this.model.visible = false;
			this.disableAxes();
			this.update();
			this.render();
		}
		else {
			this.controls.enabled = true;
			if (this.outerCube != null) {
				this.outerCube.visible = false;
			}
			this.model.visible = true;
			this.update();
			this.render();
		}

	}

	// request silos for default perpsectives
	requestSilosForDefaultPerspectives() {

		for (let i = 0; i < this.perspectivesCount; i++) {
			this.changePerspectives(i);
			// take snapshot
			this.saveModelSnapshot();
		}
	}

	resetCameraSetting() {
		if (this.controls.enabled) {
			this.switchToZoomedOut();
			this.changePerspectives(this.selectedPerspectiveIndex);
		}
	}

	resetCameraPreserveSetting() {

	}


	saveCameraState() {
	}

	resetCameraState() {
	}

	changeCameraControls(camera_name) {
		this.setInsideBlindSilo(false);
		this.setOutsideBlindSilo(false);
		var i = 0;
		for (var index=0; index < this.siloImages.length; index ++){
			if (this.prevRenders != null) {
				i = index + this.prevRenders;
			} else {
				i = index;
			}

            if (this.siloImages[i]['camera_name'] == camera_name)
            {
				this.switchToZoomedOut();
				this.controls.setAzimuthalAngle(this.siloImages[i].image_data.azimuthal_angle);
				this.controls.setPolarAngle(this.siloImages[i].image_data.polar_angle);
				this.controls.target.copy(this.siloImages[i].image_data.lookAt);
				this.update();
				this.camera.position.copy(this.siloImages[i].image_data.world_position);
				this.update();
				if (this.siloImagesStates[index] == true) {
					this.switchToZoomedIn();
				}
				this.render();
				break;
            }
		}
	}

	togglePan(value, toggleInPerspective = true) {
		this.panToggle = value;
		this.manualPanning = false;
		this.toggleInPerspective = toggleInPerspective;
		if (this.panToggle == false) {
			this.controls.enablePan = false;
			this.controls.enableRotate = true;
			this.controls.mouseButtons = {
				LEFT: THREE.MOUSE.ROTATE,
				MIDDLE: THREE.MOUSE.DOLLY,
				RIGHT: THREE.MOUSE.ROTATE
			}
			this.controls.update();
		}
		else {
			if (toggleInPerspective == false) {
				this.controls.saveState();
			}
			this.updateVerticalSlider();
			this.controls.enablePan = true;
			this.controls.enableRotate = false;
			this.controls.mouseButtons = {
				LEFT: THREE.MOUSE.PAN,
				MIDDLE: THREE.MOUSE.DOLLY,
				RIGHT: THREE.MOUSE.PAN
			}
			this.controls.update();
		}
	}
	
	getCameraWorldPosition() {
		let worldPosition = new THREE.Vector3();
		this.camera.getWorldPosition(worldPosition);
		return worldPosition
	}

	// values to export to 3d max
	get3DMaxValues(resolution,camera_name, image_status = null,rig_name, camera_display_name, aspect) {
		var worldPosition = new THREE.Vector3();
		this.camera.getWorldPosition(worldPosition);
		let dict3DMax = {
			width: parseFloat(this.imgWidth),
			height: parseFloat(this.imgHeight),
			img_dpi: parseInt(this.imgDPI),
			img_resolution: resolution,
			aspect_ratio: aspect,
			img_format: this.imgFormat,
			fov: this.camera.fov,
			horizontal_fov: this.horizontalFOV,
			lookAt: this.controls.target.clone(),
			world_position: worldPosition.clone(),
			polar_angle: this.controls.getPolarAngle(),
			azimuthal_angle: this.controls.getAzimuthalAngle(),
			lighting : "default"
		}
		if (this.zoomedInState == true) {
			this.siloImagesStates.push(true);
		}
		else {
			this.siloImagesStates.push(false);
		}
		

		let siloImageStatus = {
			camera_name: camera_name,
			camera_display_name:camera_display_name,
			image_data: dict3DMax,
			rig_name: rig_name,
			rig_url: rig_name,
			captured_by: localStorage.getItem('username')
		}

		if (image_status != null) {
			siloImageStatus.image_status = image_status;
		}
		this.siloImages.push(siloImageStatus);
		this.siloRemovalArray = this.siloImages;

		this.siloInformation = {
			product_id: this.productID,
			silo_data: this.siloImages
		}
	}


	edit3DMaxValues(camera_name,resolution,imageFormat,dpi,aspect, width,height,rig_name,fov_value) {
		this.setHorizontalFOV(fov_value);
		for (var i=0; i < this.siloImages.length; i++){
			if (this.siloImages[i]['camera_name'] == camera_name)
			{
				this.siloImages[i]['image_data']['img_format'] = imageFormat;
				this.siloImages[i]['captured_by'] = localStorage.getItem("username");
				this.siloImages[i]['image_data']['img_dpi'] = dpi;
				this.siloImages[i]['image_data']['img_resolution'] = resolution;
				this.siloImages[i]['image_data']['width'] = width;
				this.siloImages[i]['image_data']['height'] = height;
				this.siloImages[i]['image_data']['aspect_ratio'] = aspect;
				this.siloImages[i]['image_data']['fov'] = this.camera.fov;
				this.siloImages[i]['rig_name'] = rig_name;
				if (FileConstants.lightRigUrl[rig_name] != undefined) {
					this.siloImages[i]['rig_url'] = FileConstants.lightRigUrl[rig_name];
				} else {
					this.siloImages[i]['rig_url'] = rig_name;
				}
			}
		}
		this.siloInformation = {
			product_id: this.productID,
			silo_data: this.siloImages
		}
	}

	receiveUpdatedData = (data) => {
		this.updatedSiloData = data;
	}

	removeByCameraName(name) {
		let newArray = [];
		let newSiloImagesStates = [];
		let camera_deleted = false;
        for (var i=0; i < this.siloImages.length; i++){
            if (this.siloImages[i]['camera_name'] == name && !camera_deleted)
            {
				camera_deleted = true;
			}
			else {
				if (camera_deleted){
					let cameraToks = this.siloImages[i]['camera_name'].split("_")[1]
					if (cameraToks != undefined){
						this.siloImages[i]['camera_name'] = 'camera_' + (parseInt(cameraToks)-1)
					}	
				}
				newArray.push(this.siloImages[i]);
				newSiloImagesStates.push(this.siloImagesStates[i]);
			}
		}
		this.siloImagesStates = newSiloImagesStates;
		this.siloImages = newArray;
		this.siloInformation = {
			product_id: this.productID,
			silo_data: newArray
		}
	}

	updateDisplayName(camera_name, camera_display_name) {
        for (var i=0; i < this.siloImages.length; i++){
            if (this.siloImages[i]['camera_name'] == camera_name)
            {
				this.siloImages[i]['camera_display_name'] = camera_display_name;
			}
		}
	}

	getSiloImages () {
		return this.siloImages;
	}

	// save model snapshot
	saveModelSnapshot() {
		this.screenshotCount = this.screenshotCount + 1;
		let imgData;
        try {
			let strMime = "image/jpeg";
            let strDownloadMime = "image/octet-stream";
			imgData = this.sceneRenderer.domElement.toDataURL( strMime );
			this.saveFile( imgData.replace( strMime, strDownloadMime ), this.model.name + "_textureRef" + "_" + this.screenshotCount + ".jpg");
        } catch (e) {
            console.log(e);
            return;
		}
	}


	defaultRenderer(){
		this.aspectApplied = true;
			let height = window.innerHeight - 320;
			let width = this.sceneContainer.clientWidth;
			if(width/height >= 1.78) {
				this.sceneHeight = window.innerHeight - 320;
				this.sceneWidth  = this.sceneHeight * 1.78;
			}
			else {
				this.sceneWidth 		= this.sceneContainer.clientWidth;
				this.sceneHeight 	    = this.sceneWidth*0.5625;
			}
			this.aspect = this.sceneWidth/this.sceneHeight;
			this.setRendererParam();
	}

	// alter renderer parameters according to changed height width
	setRendererParam() {
		var sliderPos = this.getCameraHeight();
		var horizontalPos = this.controls.getHorizontalPosition();
		this.sceneRenderer.setSize(this.sceneWidth, this.sceneHeight);

		if (this.composer!=null) {
			this.composer.setSize(this.sceneWidth, this.sceneHeight);
			this.renderPass.setSize(this.sceneWidth, this.sceneHeight);
			this.horizontalBlur.setSize(this.sceneWidth, this.sceneHeight);
			this.verticalBlur.setSize(this.sceneWidth, this.sceneHeight);

		}
		this.camera.aspect = this.aspect;
		this.camera.updateProjectionMatrix();
		this.convertHorizontaltoVertical();
		this.controls.resetPanning();
		this.controls.resetHorizontalPanning();
		this.update();
		this.controls.updateVerticalPan(sliderPos);
		this.controls.updateHorizontalPan(horizontalPos);
		this.render();

	}

	// change aspect ratio for the snapshots  landscape, portrait, square or custom
	changeOrientation(ratio = "16:9", type = "Custom"){
		this.defaultRenderer();
		this.aspect_type = type;
		this.aspect_ratio = ratio;
		var widthR = 0;
		var heightR = 0;
		var newAspect = 0;
		if (typeof ratio === 'string' || ratio instanceof String) {
			widthR = ratio.split(':')[0];
			heightR = ratio.substring(ratio.indexOf(':') + 1);
			newAspect = widthR/heightR;
		}
		else {
			newAspect = ratio;
		}
		if(type == "Horizontal") {
			this.aspectApplied = true;
			let height = window.innerHeight - 320;
			let width = this.sceneContainer.clientWidth;
			if(width/height >= 1.78) {
				this.sceneHeight = window.innerHeight - 320;
				this.sceneWidth  = this.sceneHeight * 1.78;
			}
			else {
				this.sceneWidth 		= this.sceneContainer.clientWidth;
				this.sceneHeight 	    = this.sceneWidth*0.5625;
			}
		}
		else if (type == "Vertical") {
			this.aspectApplied = true;
			this.sceneHeight = this.sceneContainer.clientHeight;
			this.sceneWidth = this.sceneHeight*0.5625;
		}
		else if (type == "Square") {
			this.aspectApplied = true;
			if (this.sceneContainer.clientWidth < this.sceneContainer.clientHeight){
				this.sceneWidth = this.sceneContainer.clientWidth;
				this.sceneHeight = this.sceneContainer.clientWidth;
			}
			else {
				this.sceneWidth = this.sceneContainer.clientHeight;
				this.sceneHeight = this.sceneContainer.clientHeight;
			}
		}
		else if (type == "Custom") {
			this.aspectApplied = true;
			if(newAspect > 1) {
				let height = window.innerHeight - 320;
				let width = this.sceneContainer.clientWidth;
				if(width/height >= newAspect){
					this.sceneHeight = window.innerHeight - 320;
					this.sceneWidth  = this.sceneHeight * newAspect;
				}
				else {
					this.sceneWidth 		= this.sceneContainer.clientWidth;
					this.sceneHeight 	    = this.sceneWidth*(1/newAspect);
				}
			}
			else {
				this.sceneHeight = this.sceneContainer.clientHeight;
				this.sceneWidth = this.sceneHeight* newAspect;
			}
		}
		this.aspect = this.sceneWidth/this.sceneHeight;
		this.setRendererParam();
		this.updateGridForSilo();

	}

	// set resolutions for image renders;
	setResolutions(width,height) {
		// calculate high, medium, low resolutions;
		this.imgResolutions = [];
		if (width / height > 1) {
			this.imgResolutions.push(1024 + 'x' + Math.round(height / (width / 1024))); // low
			this.imgResolutions.push(1024*2 + 'x' + Math.round(height*2 / (width / 1024))); // low
			this.imgResolutions.push(1024*3 + 'x' + Math.round(height*3 / (width / 1024))); // low
			this.imgResolutions.push(1024*4 + 'x' + Math.round(height*4 / (width / 1024))); // low	
		}
		else {
			this.imgResolutions.push(Math.round(width / (height / 1024)) + 'x' + 1024); // low
			this.imgResolutions.push(Math.round(width*2 / (height / 1024)) + 'x' + 1024*2); // low
			this.imgResolutions.push(Math.round(width*3 / (height / 1024)) + 'x' + 1024*3); // low
			this.imgResolutions.push(Math.round(width*4 / (height / 1024)) + 'x' + 1024*4); // low
		}
		
		// this.imgResolutions.push(width*2 + 'x' + height*2); // medium
		// this.imgResolutions.push(width*3 + 'x' + height*3); // medium
		// this.imgResolutions.push(width*4 + 'x' + height*4); // high
		return this.imgResolutions;
	}
	
	
	getResolutions(width,height,type) {
		let imgResolutions = [];
		// calculate high, medium, low resolutions;
		if (type == 'Portrait') {
			let res4k = Math.round(4096 * (width/height));
			let res2k = Math.round(2048 * (width/height));
			let res1k = Math.round(1024 * (width/height));
			imgResolutions.push(res4k + 'x4096'); // high
			imgResolutions.push(res2k + 'x2048'); // medium
			imgResolutions.push(res1k + 'x1024'); // low
		}
		else {
			let res4k = Math.round(4096 * (height/width));
			let res2k = Math.round(2048 * (height/width));
			let res1k = Math.round(1024 * (height/width));
			imgResolutions.push('4096x' + res4k); // high
			imgResolutions.push('2048x' + res2k); // medium
			imgResolutions.push('1024x' + res1k); // low
		}
		
		return imgResolutions;
	}

	
	


	//******************************************************* */


	getParams ( url ) {
		let params = {};
		let parser = document.createElement('a');
		parser.href = url;
		let query = parser.search.substring(1);
		let vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
		return params;
	}

	buildScene () {
        const scene = new THREE.Scene();
        scene.background = new THREE.Color("#ffffff");
        this.loadEnvironment(scene);
        return scene;
    }

    buildSceneRenderer ( width, height ) {
        const renderer = new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.gammaFactor = 2.2;
		renderer.shadowMap.enabled = true;
		  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        // renderer.setClearColor( 0x000000 );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        return renderer;
    }

	buildGLTFLoader () {
		const gltfLoader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath( '/js/libs/draco/gltf/' );
        gltfLoader.setDRACOLoader( dracoLoader );
        return gltfLoader;
	}
	
	addDefaultLights () {
		
		this.lights.push(this.keyLight);
		this.lights.push(this.fillLight);
		this.lights.push(this.rimLight);
		
		this.scene.add( this.keyLight );
		this.scene.add( this.fillLight );
		this.scene.add( this.rimLight );
	}

	buildSceneLights () {

		RectAreaLightUniformsLib.init();

		this.keyLight = new THREE.RectAreaLight( 0xffffff, 8, 5, 5 );
		this.fillLight = new THREE.RectAreaLight( 0xffffff, 3, 5, 5 );
		this.rimLight = new THREE.RectAreaLight( 0xffffff, 3, 5, 5 );

		this.keyLight.position.set( -3.75, 2.5, 5.0 );
		this.fillLight.position.set( 5.0, 0.0, 1.25 );
		this.rimLight.position.set( 0.0, 5.0, -1.25 );

		let origin = new THREE.Vector3( 0, 0, 0 );
		this.keyLight.lookAt(origin);
		this.fillLight.lookAt(origin);
		this.rimLight.lookAt(origin);
		
		this.addDefaultLights();
	}

	buildGUI () {

		this.uOffset = 0.0;
		this.vOffset = 0.0;
		this.textureRepeat = 1.0;

		let scope = this;
		this.textureRotation = () => {
			scope.selectedMaterial.map.rotation += ( Math.PI / 2 );
			this.clockwiseValue = scope.selectedMaterial.map.rotation;
			this.antiClockwiseValue = scope.selectedMaterial.map.rotation;
		}

		this.screenshot = () => {
			scope.saveScreenshot();
		}

		this.textureRotationAnti = () => {
			scope.selectedMaterial.map.rotation -= ( Math.PI / 2 );
			this.antiClockwiseValue = scope.selectedMaterial.map.rotation;
			this.clockwiseValue = scope.selectedMaterial.map.rotation;;
		}

		this.gui = new GUI( { width: 350,autoPlace: false,visibility:"hidden" });
		// var GUIContainer = document.getElementById('gui-controls');
		// GUIContainer.appendChild(this.gui.domElement);

		this.uCtrl = this.gui.add( this, 'uOffset', 0.0, 1.0, 0.01 ).name( 'Move Horizontal' );
		this.vCtrl = this.gui.add( this, 'vOffset', 0.0, 1.0, 0.01 ).name( 'Move Vertical' );
		this.repeatCtrl = this.gui.add( this, 'textureRepeat', 1.0, 10.0, 0.5 ).name( 'Repeat' );
		this.gui.add( this, 'textureRotation' ).name( 'Rotate 90°' );
		this.gui.add( this, 'textureRotationAnti').name( 'Rotate 90 Anti ');
		this.gui.add( this, 'screenshot' ).name( 'Take Screenshot' );

		this.uCtrl.onChange( ()=> {
			scope.selectedMaterial.map.offset.x = this.uOffset;
		} );
		this.xAxis = (value) => {
			scope.selectedMaterial.map.offset.x = value;
		}

		this.vCtrl.onChange( ()=> {
			this.selectedMaterial.map.offset.y = this.vOffset;
		} );
		this.yAxis = (value) => {
			scope.selectedMaterial.map.offset.y = value;
		}

		this.repeatCtrl.onChange( ()=> {
			this.selectedMaterial.map.repeat.set( this.textureRepeat, this.textureRepeat );
		} );

		this.repeatPattern = (value) => {
			scope.selectedMaterial.map.repeat.set( value, value );
		}

		this.gui.hide();
		this.gui.close();
		if (this.moveHorizontal != null && this.moveVertical != null && this.repeatPttrn != null && this.txtRotateBtn != null && this.txtRotateBtnAnti != null && this.screenshotBtn != null){
			this.moveHorizontal.addEventListener('mousedown', this.xAxis, false);
			this.moveVertical.addEventListener('mousedown', this.yAxis, false);
			this.repeatPttrn.addEventListener('mousedown', this.repeatPattern, false);
			this.txtRotateBtn.addEventListener('click', this.textureRotation, false);
			this.txtRotateBtnAnti.addEventListener('click',this.textureRotationAnti, false);
			this.screenshotBtn.addEventListener('click',this.screenshot, false);
		}
	}
	getClockwiseValue () {
		return this.clockwiseValue;
	}

	getAntiClockwiseValue () {
		return this.antiClockwiseValue;
	}

	updateAndShowGUI () {
		this.uOffset = this.selectedMaterial.map.offset.x;
		this.vOffset = this.selectedMaterial.map.offset.y;
		this.textureRepeat = this.selectedMaterial.map.repeat.x;

		this.uCtrl.updateDisplay();
		this.vCtrl.updateDisplay();
		this.repeatCtrl.updateDisplay();

		this.gui.show();

	}

	hideGUI () {
		this.gui.hide();
	}

	setupEventListeners () {
		this.sceneRenderer.domElement.addEventListener( 'mousedown', this.onPointerDown, false );
		this.sceneRenderer.domElement.addEventListener( 'mousemove', this.onPointerMove, false );
		this.sceneRenderer.domElement.addEventListener( 'wheel', this.onMouseWheel, false );
		this.sceneRenderer.domElement.addEventListener( 'dblclick', this.onDoubleClick, false );
		this.sceneContainer.addEventListener( 'dragover', this.onDragOver, false );
		this.sceneContainer.addEventListener( 'dragenter', this.onDragEnter, false );
		this.sceneContainer.addEventListener( 'dragleave', this.onDragLeave, false );
		this.sceneContainer.addEventListener( 'drop', this.onDrop, false );
		window.addEventListener( 'mouseup', this.onPointerContainerOut, false );
	}

	loadEnvironment (scene) {
		const Envloader = new RGBELoader();
		Envloader.load(HDR, function (tex) {
			tex.mapping = THREE.EquirectangularReflectionMapping;
			// scene.environment = tex;
		});
    }

	loadModel ( id ) {
		this.loader.load( this.baseURL + id + ".glb",
			( data ) => this.onModelLoaded( id, data ),
			( xhr ) => this.onLoadingProgress( xhr ),
			( error ) => this.onLoadingFailed( error ) );

	}

	resetZoomingFactors() {
		if (!this.model || !this.model.size) {
			return;
		}
		let length = this.model.size.length();
		this.model.bBox.getCenter( this.controls.target );
		this.controls.minDistance = length * 0.5;
		this.controls.maxDistance = length * 25.0;
		this.camera.near = length / 100.0;
		this.camera.far = length * 100.0;
		this.camera.updateProjectionMatrix();

	}
	

	configureCameraAndControls () {
		if (!this.model || !this.model.size) {
			return;
		}
		this.resetZoomingFactors();
		let vFov = this.camera.fov * ( Math.PI / 180 );
		let hFov = 2 * Math.atan ( ( this.sceneWidth / this.sceneHeight ) * Math.tan( vFov / 2 ) );
		let hFactor = 1.1 + ( hFov / 100.0 );
		let vFactor = 1.2 + ( vFov / 100.0 );
		let hFovLen = Math.abs ( this.model.size.x * hFactor );
		let vFovLen = Math.abs ( this.model.size.y * vFactor );
		let halfDepth = Math.abs( this.model.size.z / 2.0) ;

		let hDolly = hFovLen / Math.tan( hFov );
		hDolly *= ( hDolly / ( hDolly - halfDepth ) );
		let vDolly = vFovLen / Math.tan( vFov );
		vDolly *= ( vDolly / ( vDolly - halfDepth ) );

		if ( hDolly > vDolly )
		{
			this.camera.position.set( this.camera.position.x, this.camera.position.y, hDolly );
		}
		else
		{
			this.camera.position.set( this.camera.position.x, this.camera.position.y, vDolly);
		}


	}

	configureLights () {
		// Ideally lights should be configured here and not in the buildSceneLights method
	}

	onModelLoaded ( id, data )  {

		if (data) {
			this.model = data.scene;
			this.model.name = id;

			this.model.bBox = new THREE.Box3().setFromObject( this.model );
			this.model.size = new THREE.Vector3();
			this.model.bBox.getSize( this.model.size );

			this.configureCameraAndControls();
			this.configureLights();

			this.scene.add( this.model );

			this.controls.saveState();
			this.togglePan(false,this.toggleInPerspective);

			this.model.renderOrder = 0;
			this.controls.setModelPosition(this.getModelPosition());
			this.controls.setRotationBoundary(this.model.size.length());

			this.contactShadows = new ContactShadows(this.camera, this.scene, this.sceneRenderer, this.model.bBox);

			if (this.placementType == undefined || this.placementType == null || this.placementType == "") {
				this.setPlacementType();
			}
			this.lightGroup.position.set(this.getModelPosition().x, this.getModelPosition().y, this.getModelPosition().z);

			
			this.model.traverse((child) => {
				if (child.isMesh) {
					this.originalMaterials[child.name] = child.material;
				}
			});
			
		}

		this.modelLoadedFunc(true);

		// let scope = this;
		// this.model.traverse(function (child) {
		// 	if (child.isMesh && !scope.regularFeatures.includes(child.name)) {
		// 		scope.uniqueFeatures.push(child);
		// 	}
		// });

	}
	
	toggleGreyMat(value) {
		this.isGreyMatOn = value;
		let scope = this;
		this.model.traverse((child) => {
		  if (child.isMesh) {
			if (value) {
			  child.material = scope.greyMat;
			}
			else {
			  child.material = scope.originalMaterials[child.name];
			}
		  }
		});
	}
	
	ConvertPositionFromMaxToThree (MaxPosition) {
		let ThreePos = new THREE.Vector3(0,0,0);
		ThreePos.x = MaxPosition.x;
		ThreePos.y = MaxPosition.z;		
		ThreePos.z = -(MaxPosition.y);
		return ThreePos;
	}
	
	getLightDirVector ( direction ) {
        switch ( direction ) {
            case "east":
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(1,0,0))
            case "west": 
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(-1,0,0))
            case "north": 
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(0,1,0))
            case "south": 
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(0,-1,0))
            case "north_east": 
                return this.ConvertPositionFromMaxToThree( new THREE.Vector3(1,1,0.7))
            case "north_west": 
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(-1,1,0.7))
            case "south_east": 
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(1,-1,0.7))
            case "south_west":
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(-1,-1,0.7))
            case "top": 
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(0,0,1))
            default:
                return this.ConvertPositionFromMaxToThree(new THREE.Vector3(-1,-1,0.7))
        }
    }
	
	buildVrayLight ( lightname, size, intensity, direction, shadowType ) {
        
        let customLight = new THREE.SpotLight(0xffffff, intensity);
		let modelPos = this.getModelPosition();
		customLight.position.set(modelPos.x, modelPos.y ,modelPos.z);
		customLight.target.position.set(modelPos.x, modelPos.y ,modelPos.z); 
		let directionVec = this.getLightDirVector(direction);
		customLight.translateOnAxis(directionVec, size*2);
		
		customLight.name = lightname;
		customLight.decay = 0;
		customLight.angle = 0.5;
		customLight.distance = 0;
		
		customLight.castShadow = true;
		customLight.shadow.mapSize.width = 512;
		customLight.shadow.mapSize.height = 512;
		customLight.shadow.camera.near = 0.1;
		customLight.shadow.camera.far = 500;
		customLight.shadow.camera.fov = 30;
		customLight.shadow.focus = 1.0;
		this.lights.push(customLight);
		this.lightGroup.add(customLight.target);
		this.lightGroup.add(customLight);
    }
	
 	BuildLightRig ( type, power, maxLen, lightingData ) {
		let config = lightingData
		if (config[type] != undefined) {
			
			for ( let i=0; i<config[type].length; i++) {
				this.buildVrayLight(config[type][i]["name"], maxLen, (power*config[type][i]["factor"]), config[type][i]["direction"], config[type][i]["shadow_type"]);
			}
			
			const ambientLight = new THREE.AmbientLight(0xffffff, 1);
			this.lights.push(ambientLight);
			this.scene.add(ambientLight);
			this.scene.add(this.lightGroup);
		}
	}
	
	removeLights() {
		
		for ( let i=this.lightGroup.children.length; i >= 0; i-- ) {
			this.lightGroup.remove(this.lightGroup.children[i]);
		}	
		
		for (let index in this.lights){
			this.scene.remove(this.lights[index]);
		}
		
	}
	
	SetupLighting (lightingSetup, lightingData ) {
		this.removeLights();
		
		let size = this.model.size;
		let maxLen = Math.max(size.x, size.y, size.z);
		let power = this.GetMaxLightIntensity(maxLen);		
				
		this.lights = [];
		if (lightingSetup == 'default') {
			this.model.children[0].traverse(child => {
				if( child.isMesh ) {
					child.receiveShadow = false;
					child.castShadow = false;
				}
			})
			this.addDefaultLights();			
		}
		else {
			this.model.children[0].traverse(child => {
				if( child.isMesh ) {
					child.receiveShadow = true;
					child.castShadow = true;	
					child.material.envMapIntensity = 0.3;
				}
			})
			this.BuildLightRig(lightingSetup, power, maxLen, lightingData);			
		}
		
		for (let index in this.lights) {
			this.lights[index].color.r = this.lightTemperature.r;
			this.lights[index].color.g = this.lightTemperature.g;
			this.lights[index].color.b = this.lightTemperature.b;
		}
		
	}
	
	GetMaxLightIntensity (maxLen) {
		let magicConstant = 0.003125 * 45;
		let radius = (maxLen * 2);
		let radiusSquared = (radius * radius);
		return (magicConstant * radiusSquared);
	}
	
	rgbToNormalized(hexColor) {
		let r = 1
		let g = 1
		let b = 1
		
		if (hexColor == undefined)
			return { r, g, b }
		
			// Remove leading hash symbol (#) if present
		const color = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
	  
		// Check if color is valid (3 characters or 6 characters)
		if (color.length !== 3 && color.length !== 6) {
		  console.warn(`Invalid color format: ${hexColor}`);
		  return null;
		}
	  
		// Convert each hex pair to a decimal value (0-255)
		r = parseInt(color.substring(0, 2), 16) / 255;
		g = parseInt(color.substring(color.length === 3 ? 1 : 2, color.length === 3 ? 3 : 4), 16) / 255;
		b = parseInt(color.substring(color.length === 3 ? 2 : 4), 16) / 255;
	  
		return { r, g, b };
	}
	
	setLightTemperature ( lightTemp ) {
		let rgbValue = this.rgbToNormalized(FileConstants.temperatureColors[lightTemp]);
		this.lightTemperature = rgbValue;
		for (let index in this.lights) {
			this.lights[index].color.r = rgbValue.r;
			this.lights[index].color.g = rgbValue.g;
			this.lights[index].color.b = rgbValue.b;
		}
		this.update();
	}
	
	setPlacementType() {
        const minY 	= this.model.bBox.min.y;
        const maxY 	= this.model.bBox.max.y;
        let tolerance = 0.003; // this basically means a tolerance of 0.1 inches

        if( minY >= -tolerance && maxY > 0.0 ) {
            this.placementType = 'Floor';
        }

        else if( minY < 0.0 && maxY <= tolerance ) {
            this.placementType = 'Ceiling';
        }

        else if( minY < 0.0 && maxY > 0.0 ) {
            this.placementType = 'Wall';
        }
	}

	checkIsFloorItem() {
		return this.placementType == 'Floor';
	}

	showShadows() {
		if (this.contactShadows) {
			this.contactShadows.showShadows();
		}

	}

	hideShadows() {
		if (this.contactShadows) {
			this.contactShadows.hideShadows();
		}
	}

	addPerspectives(configurationData, modelType) {
		let size = new THREE.Box3().setFromObject(this.model.children[0]).getSize(new THREE.Vector3());
		this.model.children[0].userData.size = size;
		this.model.children[0].userData.scale = new THREE.Vector3(1,1,1);
		this.modelPVB = new PVB(this.model.children[0]);
		this.configurationData = configurationData;
		this.modelType = modelType;
		this.perspectivesCount = this.setPerspectives();
		if (this.perspectiveImageFunc != null) {
			this.perspectiveImageFunc( this.perspectivesCount );
		}
	}

	onLoadingProgress ( xhr ) {
		this.loadingProgress = Math.ceil(xhr.loaded/xhr.total * 100);
		this.setLoadedProgressFunc(this.loadingProgress)
	}

	onLoadingFailed ( error ) {

	}


	// convert world position to ndc point;
	worldToNDC ( worldPoint , activeCamera) {
        let NDCPoint = new THREE.Vector3();
        NDCPoint.copy(worldPoint);
        activeCamera.updateMatrixWorld();
        NDCPoint.project( activeCamera );
        return NDCPoint;
	}

	// set rotation and polar angle according to the rotation slider's updated value
	setCameraPolarAngle(polarAngle){
		let angle = polarAngle;
		let phi = THREE.MathUtils.degToRad(angle);
		this.controls.setPolarAngle(phi);
	}

	setCameraAzimuthalAngle(azimuthAngle){
		let angle = azimuthAngle;
		let theta = THREE.MathUtils.degToRad(angle);
		this.controls.setAzimuthalAngle(theta);
		this.updateVerticalSlider();
	}


	// reset sliderPosition
	updateVerticalSlider() {
		if ( this.controls.enabled) {
			this.setSliderPosition(this.getCameraHeight(), 0);
		}

	}

	// return slider position for camera widget tool
	getSliderPosition() {
		return this.sliderPosition;
	}

	//return Polar Angle in degrees
	getPolarAngle() {
		return (this.controls.getPolarAngle() * (180/ Math.PI));
	}

	//return Azimuthal Angle in degrees
	getAzimuthalAngle() {
		return ( this.controls.getAzimuthalAngle() * (180/ Math.PI));
	}

	// update rotation slider value on the basis of manual panning
	updateRoundSlider() {
		if ( this.controls.enabled) {
			var pi = Math.PI;
			let angle = 90 - (this.controls.getPolarAngle() * (180/pi));
			this.slider_handle.style.transform = "rotate("+angle+"deg)";
			window.$('#slider-silo').roundSlider('setValue',angle);
		}
	}

	// set camera height from the value passed from the camera widget height tool slider
	setCameraHeight(value) {
		this.controls.updateVerticalPan(value * 900);
		if (this.zoomedInState == true) {
			this.raycaster.set(this.camera.position.clone(), new THREE.Vector3().subVectors(this.controls.target.clone(), this.camera.position.clone()).normalize() );
			let result = this.raycaster.intersectObjects([this.model], true )[0] || false;
			if (result != false) {
				this.controls.minDistance = (result.point.z -  this.controls.target.z) + 0.05;
			}
		}
	}



	// update camera tool slider value depending on height of the model changed using manual panning
	getCameraHeight() {
			return this.controls.getSliderPosition();

	}

	// align camera
	alignCameraForSilos() {
		// reset to 90 degrees
		this.controls.setPolarAngle(90 * Math.PI/180);
		this.controls.update();
	}

	/* We compute the angle nearest to the 4 cardinal directions (North, East, West, South) and move the camera to face that side exactly */
	alignCameraHorizontallyForSnapshot() {

		/* Taking the direction which camera is facing */
        let cameraWorldDirection = this.camera.getWorldDirection();

        let East = new THREE.Vector3(1,0,0);
        let West = new THREE.Vector3(-1,0,0);
        let North = new THREE.Vector3(0,0,1);
        let South = new THREE.Vector3(0,0,-1);

        let distanceMatrix = {
            "East" : East,
            "West" : West,
            "North" : North,
            "South" : South,
        }

		let directionAngle = {
			"East" : -1.5708,
            "West" : 1.5708,
            "North" : -3.1415,
            "South" : 0,
		}

        let minDistanceVector = "";
		let minDistance = 0;

        /* Calculating the difference of the camera angle to the cardinal sides and calculating the minimum angle*/
        for (var key in distanceMatrix) {
            let distanceTo = cameraWorldDirection.angleTo(distanceMatrix[key])
            if ( minDistanceVector == "" ) {
                minDistanceVector = key;
				minDistance = distanceTo;
            }
            else {
                if ( distanceTo < minDistance){
                    minDistanceVector = key;
					minDistance = distanceTo;
                }
            }
        }

		/* Setting the camera angle to that of the cardinal side with minimum distance */
		this.controls.setAzimuthalAngle(directionAngle[minDistanceVector])
    }


	switchToZoomedIn() {
		if(this.zoomedInState != true) {
			this.raycaster.set(this.camera.position.clone(), new THREE.Vector3().subVectors(this.controls.target.clone(), this.camera.position.clone()).normalize() );
			let result = this.raycaster.intersectObjects([this.model], true )[0] || false;
			if (result != false) {
				this.controls.minDistance = result.point.distanceTo(this.controls.target) + 0.035;
			}
			this.controls.maxDistance = this.model.size.length() * 1.5;
			this.controls.setRestrictedState(true);
		}
		this.zoomedInState = true;

	}

	callZoomedInState(point) {

		if (this.zoomedInState == false){
			this.prevTargetPos = this.controls.target.clone();
			this.prevCamPos = this.controls.object.position.clone();
			this.prevSliderPos = this.controls.getSliderPosition();
			this.prevHorizontalSlider = this.controls.getHorizontalPosition();
		}
		this.controls.setHorizontalBoundary();
		var azimuthal_angle = this.controls.getAzimuthalAngle();
		var polar_angle = this.controls.getPolarAngle();
		this.controls.target.copy(point.clone());
		this.controls.setAzimuthalAngle(azimuthal_angle);
		this.controls.setPolarAngle(polar_angle);
		this.update();
		this.switchToZoomedIn();
	}

	switchToZoomedOut() {
		this.resetZoomingFactors();
		this.zoomedInState = false;
		this.controls.setRestrictedState(false);
		this.controls.update();
	}

	callZoomedOutState() {
		this.switchToZoomedOut();
		if (this.prevTargetPos != null && this.prevCamPos != null){
			this.controls.object.position.copy(this.prevCamPos);
			this.controls.target.copy(this.prevTargetPos);
			this.controls.setSliderPosition(this.prevSliderPos);
			this.updateVerticalSlider();
			this.controls.resetHorizontalBoundary();
			this.controls.setHorizontalPosition(this.prevHorizontalSlider);
		}
		this.update();
	}


	onPointerDown = ( event ) => {
		event.preventDefault();
		this.raycaster.setFromCamera( this.pointer, this.camera);
		let intersect = this.raycaster.intersectObjects( this.scene.children, true )[0] || false;
		if ( intersect ) {
			this.selectedMaterial = intersect.object.material;
			if (this.inspectorName != 'silo-inspector'){
				this.updateAndShowGUI();
			}
		}
		else {
			this.hideGUI();
		}

		if (this.panToggle == false) {
			let element = document.getElementById('silo-inspector');
			if (element) {
				element.style.cursor = 'grabbing';
			}
		}
	}


	// set manual panning and its constrains
	onPointerMove = ( event ) => {
		const bbox = event.target.getBoundingClientRect();
		const layerX = event.clientX - bbox.x;
		const layerY = event.clientY - bbox.y;
		this.updatePointerPosition( layerX, layerY );
	}



	onPointerContainerOut = ( event ) => {
		this.updateVerticalSlider();

		if (this.panToggle == false) {
			let element = document.getElementById('silo-inspector');
			if (element) {
				element.style.cursor = 'default';
			}
		}
	}



	// for zoom in zoom out of any model point
	onDoubleClick = (event) => {
		var scope = this;
		scope.raycaster.setFromCamera( scope.pointer, scope.camera);
		let intersectedObjects = scope.raycaster.intersectObjects( [ scope.model ], true );
		if (intersectedObjects[0] ) {

			this.callZoomedInState(intersectedObjects[0].point);
		}
		else if (this.zoomedInState == true) {
			// go to previous position
			this.callZoomedOutState();
		}
	}

	updateTromboneZoom(){
		this.tromboneZoomFactor = (this.controls.target.distanceTo( this.camera.position)) * (2 * Math.tan(THREE.MathUtils.degToRad(this.camera.fov * 0.5)));
	}

	// zoom in and zoom out constrains using mouse trackpad
	onMouseWheel = ( event ) => {
		this.updateVerticalSlider();
		this.updateTromboneZoom();
	}

	onDragOver = ( event ) => {

    	event.preventDefault();
    	this.updatePointerPosition( event.layerX, event.layerY );
		event.dataTransfer.dropEffect = 'copy';
    }
    onDragEnter = ( event ) => {

    }

    onDragLeave = ( event ) => {

    }

	selectedMaterialImage = (id) => {
		this.selectedImage = id;
		// let im = document.getElementById(this.selectedImage);
		// im.addEventListener('mousedown',this.onDrop,false);

	}

	onDrop = ( event ) => {

    	event.preventDefault();

    	let scope = this;
		// var reader = new FileReader();
		// reader.addEventListener( 'load', function ( event ) {

			scope.raycaster.setFromCamera( scope.pointer, scope.camera);
			let intersectedObjects = scope.raycaster.intersectObjects( [ scope.model ], true );
			if ( intersectedObjects[0] ) {

				// var image = document.createElement('img');
				// image.src = event.target.result;
				var image = document.getElementById(scope.selectedImage);
		        var newTex = new THREE.Texture(image);
		        newTex.wrapS = THREE.RepeatWrapping;
				newTex.wrapT = THREE.RepeatWrapping;
		        newTex.needsUpdate = true;

		        scope.selectedMaterial = intersectedObjects[0].object.material;
				scope.selectedMaterial.map = newTex;
				scope.selectedMaterial.roughnessMap = null;
				scope.selectedMaterial.metalnessMap = null;
				scope.selectedMaterial.normalMap = null;
				scope.selectedMaterial.needsUpdate = true;
				scope.updateAndShowGUI();
			}
			else {
				scope.hideGUI();
			}

		// }, false );

		// if ( event.dataTransfer.files[ 0 ].type.includes("image") ) {
		// reader.readAsDataURL( event.dataTransfer.files[ 0 ] );
		// }

    }

    updatePointerPosition ( offsetX, offsetY ) {

    	// calculate pointer position in normalized device coordinates
        // (-1 to +1) for both components
        this.pointer.x = ( offsetX / this.sceneWidth ) * 2 - 1;
        this.pointer.y = - ( offsetY / this.sceneHeight ) * 2 + 1;

	}


	updateScreenProps () {
		if (this.aspectApplied) {
			this.changeOrientation(this.aspect_ratio,this.aspect_type);
		}
	}

	getVarSnapshot() {
		return this.imageData;
	}

    saveScreenshot() {

        //let imgData, imgNode;
        try {
            let strMime = "image/jpeg";
            // let strDownloadMime = "image/octet-stream";
            // imgData = this.sceneRenderer.domElement.toDataURL( strMime );
			this.imageData = this.sceneRenderer.domElement.toDataURL( strMime );
			// this.saveFile( imgData.replace( strMime, strDownloadMime ), this.model.name + "_textureRef.jpg" );

        } catch (e) {
            console.log(e);
            return;
        }

	}

    saveFile (strData, filename) {

        let link = document.createElement( 'a' );

        if ( typeof link.download === 'string' ) {
            document.body.appendChild( link );
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild( link );
        }

        else {
            // location.replace( uri );
        }

	}

	setSilosInformation(siloInformation) {
		this.siloInformation = siloInformation;
	}

	getSilosInformation() {
		return this.siloInformation;
	}

    onWindowResize () {

		this.updateScreenProps();
		this.sceneRenderer.domElement.style.margin = "0 auto"
		this.sceneRenderer.domElement.style.display = "flex";
		this.sceneRenderer.domElement.style.flexDirection = "row";
		this.sceneRenderer.domElement.style.justifyContent = "center";
		this.sceneRenderer.domElement.style.alignItems = "center";
		this.setRendererParam();
	}




	render () {

		if (this.contactShadows!=null) {
			this.contactShadows.animate();
		}
		this.sceneRenderer.clear();
		this.sceneRenderer.render( this.scene, this.camera );
		if (this.composer!=null) {
			this.composer.render();
		}


	}

	update () {

		this.controls.update();
		this.updateRoundSlider();
		this.placeAxes();


	}

	animate () {

		requestAnimationFrame( ()=>{ this.animate(); } );
        this.update();
        this.render();

	}
}

export { Inspector }