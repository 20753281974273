import * as THREE from 'three'
import Constants from "../Constants.js"
import {isConvexHull} from "../HelperFunctions.js"

export default class RaycastManager {

    raycaster = null;
    mouse = null;
    sceneAssets = null;

    rotationControls = null;
    spaceManager = null;
    sceneCreator = null;
    debugEngine = null;

    /**
     * List containing references to all possible targets when finding scene assets.
     */
    focusTargets = [];

    /**
     * Object Placement Manager allows you to translate, stack, clone & delete assets in scene
     * @param {SceneCreator} sceneCreator - The main scene creator instance.
     * @param {THREE.Vector2} mouse - Reference to vector2 containing mouse position in NDC (normalized device coordinates).
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(sceneCreator, mouse, managersDict) {
        this.raycaster 		= new THREE.Raycaster();
        
        this.sceneCreator = sceneCreator;
        this.mouse = mouse;
        this.setupRequiredManagers(managersDict);
    }

    /**
     * Setup the managers required by this module to work.
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    setupRequiredManagers(managersDict) {
        this.rotationControls = managersDict[Constants.Manager.RotationControls];
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.debugEngine = managersDict[Constants.Manager.DebugEngine];
    }

    /**
     * Reset raycaster to far to default value (infinity).
     */
    resetFarValue() {
        this.raycaster.far = Infinity;
    }

    /**
     *  @param {Array} sceneAssets - An array that contains references to all the assets currently in the scene.
     */
    setAssetsList( sceneAssets ) {
        this.sceneAssets = sceneAssets;
    }

    /**
     * Build focus targets list.
     */
    buildFocusTargetList() {
        this.focusTargets = [];
        this.focusTargets = this.focusTargets.concat( this.sceneAssets );
        this.focusTargets = this.focusTargets.concat( this.spaceManager.walls );
        this.focusTargets = this.focusTargets.concat( this.spaceManager.floors );
        this.focusTargets = this.focusTargets.concat( this.spaceManager.doors );
        this.focusTargets = this.focusTargets.concat( this.spaceManager.miscNodes );
    }

    /**
     * Update raycaster according to given properties.
     * @param {THREE.Vector3} origin - Origin vector of ray.
     * @param {THREE.Vector3} directionVector - Normalized direction vector of ray.
     * @param {Number} raycastLength - Ray length.
     */
    updateRaycasterProperties(origin, directionVector, raycastLength) {
        let near = 0;                               
        let far = raycastLength;   
        
        this.raycaster.near = near;
        this.raycaster.far = far;
        this.raycaster.set(origin, directionVector);
    }

    /**
     * Raycast according to given properties and return the closest intersecting object.
     * @param {THREE.Vector3} origin - Origin vector of ray.
     * @param {THREE.Vector3} direction - Normalized direction vector of ray.
     * @param {Array} intersectableObjects - Objects that can be intersected.
     */
    setAndIntersect(origin, direction, intersectableObjects)
    {
        let result = null;
        if(intersectableObjects) {
            this.raycaster.set(origin, direction );
            result = this.raycaster.intersectObjects(intersectableObjects, true )[0] || false;
        }
        return result;
    }

    /**
     * Raycast according to given properties and return all the intersecting objects instead of just the closest one.
     * @param {THREE.Vector3} origin - Origin vector of ray.
     * @param {THREE.Vector3} direction - Normalized direction vector of ray.
     * @param {Array} intersectableObjects - Objects that can be intersected.
     */
    setAndIntersectAll(origin, direction, intersectableObjects) {
        let result = null;
        if(intersectableObjects) {
            this.raycaster.set(origin, direction );
            result = this.raycaster.intersectObjects(intersectableObjects, true );
        }
        return result;
    }

    /**
     * Raycast from camera to mouse and return the closest or all intersecting objects.
     * @param {THREE.Vector2} mouseCoords - 2D coordinates of the mouse, in normalized device coordinates (NDC)
     * @param {THREE.Camera} camera - Camera from which the ray should originate.
     * @param {Array} intersectableObjects - Objects that can be intersected.
     * @param {Boolean} findAllIntersects - Flag to decide wether the result should return the closest object or all intersected objects.
     */
    setFromCameraAndIntersect(mouseCoords, camera, intersectableObjects, findAllIntersects = false) {
        this.raycaster.setFromCamera( mouseCoords, camera );
        let results = this.raycaster.intersectObjects( intersectableObjects , true ) || false;
        if(results && results.length > 0) {
            if(findAllIntersects) {
                return results;
            }
            else {
                return results[0];
            }
        }
        return false;
    }

    /**
     * Check intersection with rotation controls by raycasting from active camera to current mouse position.
     * Any other obstacles between the rotation controls and ray origin wont be considered.
     */
    cameraIntersectRotationControl() {
        this.raycaster.setFromCamera( this.mouse, this.sceneCreator.activeCamera );
        let intersect = this.raycaster.intersectObjects( [ this.rotationControls.children[0].picker, this.rotationControls.children[1].picker ], true ) [0] || false;
        if( intersect ) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Check direct intersection with scene assets by raycasting from active camera to current mouse position.
    * @param {Boolean} clipping - Flag to decide whether space items will be considered in raycasting or not.
    * Space items wont be considered in case of clipping
    */
    cameraIntersectSceneAssets( clipping = false ) {
        this.raycaster.setFromCamera( this.mouse, this.sceneCreator.activeCamera );
        let intersects ;
        if (!clipping) { 
            intersects = this.raycaster.intersectObjects( this.focusTargets, true );
        } else {
            intersects = this.raycaster.intersectObjects( this.sceneAssets, true );
        }
        
        // Remove convex hulls from results
        intersects = intersects.filter( ( item ) => {
            return !( isConvexHull(item.object) ) ;
        } );

        let intersect = intersects[0] || false;
        if( intersect && intersect.object.userData.isSceneAsset != undefined ) {
            return intersect;
        }
        else {
            return false;
        }
    }
    
    /**
     * Check direct intersection with space walls & floors by raycasting from active camera to current mouse position.
     * Any other obstacles in between wont be considered.
     */
    cameraIntersectRoom() {
        this.raycaster.setFromCamera( this.mouse, this.sceneCreator.activeCamera );
        let wallsAndFloor = this.spaceManager.walls.concat(this.spaceManager.floors);
        let intersect = this.raycaster.intersectObjects( wallsAndFloor, true )[ 0 ] || false;
        if( intersect ) {
            return intersect;
        }
        else {
            return false;
        }
    }

    /**
     * Check direct intersection with space floors by raycasting from active camera to current mouse position.
     * Any other obstacles in between wont be considered.
     */
    cameraIntersectFloor() {
        this.raycaster.setFromCamera( this.mouse, this.sceneCreator.activeCamera );
        let intersect = this.raycaster.intersectObjects( this.spaceManager.floors, true )[ 0 ] || false;
        if( intersect ) {
            return intersect;
        }
        else {
            return false;
        }
    }

    /**
     * Check intersection with objects by sending out uniformly distributed raycasts over a line.
     * @param {THREE.Vector3} lineOrigin - Origin point for the line.
     * @param {THREE.Vector3} lineTerminal - Terminal point for the line.
     * @param {THREE.Vector3} directionVector - Normalized direction vector for the rays.
     * @param {Number} raycastLength - Ray length.
     * @param {Number} numOfSamplePoints - Number of rays to cast over the line.
     * @param {Array} intersectableObjects - Objects that can be intersected.
     * @param {Array} resultsArray - Reference to array that will contain the closest intersected objects for all the raycasts.
     */
    intersectObjectsOverLine(lineOrigin, lineTerminal, directionVector, raycastLength, numOfSamplePoints, intersectableObjects, resultsArray, debugBool = false) {
        let interval = 1 / numOfSamplePoints;
        
        for (let alpha = 0; alpha <= 1; alpha = alpha + interval) {
            lineOrigin.lerp(lineTerminal, alpha);

            this.updateRaycasterProperties(lineOrigin, directionVector, raycastLength);
            let intersects = this.raycaster.intersectObjects( intersectableObjects, true );

            // Remove convex hulls from results
            intersects = intersects.filter( ( item ) => {
                return !( isConvexHull(item.object) ) ;
            } );

            // this.debugEngine.debugLog(intersects);
            if (debugBool == true) {
                this.debugEngine.drawRayCastGizmo(this.raycaster);

            }
            
            if (intersects.length > 0) {
                resultsArray.push(intersects[0]);
            }
        }
    }

    /**
     * Check intersection with objects by sending out uniformly distributed raycasts over the diagonal (currently topleft to bottomRight diagonal is being used) of the face.
     * @param {THREE.Vector3} topLeft - Top left corner of the face.
     * @param {THREE.Vector3} topRight - Top Right corner of the face.
     * @param {THREE.Vector3} bottmLeft - Bottom left corner of the face.
     * @param {THREE.Vector3} bottomRight - Bottom right corner of the face.
     * @param {THREE.Vector3} center - Center of the face.
     * @param {THREE.Vector3} directionVector - Normalized direction vector for the rays.
     * @param {Number} raycastLength - Ray length.
     * @param {Array} resultsArray - Reference to array that will contain the closest intersected objects for all the raycasts.
     * @param {Array} intersectableObjects - Objects that can be intersected.
     * @param {Number} centreLerpFactor - Amount by which corners are lerped (pulled) towards center of the face (Should be between 0 to 1).
     * @param {Number} numOfSamplePoints - Number of rays to cast over the line.
     */
    intersectObjectsOverFaceDiagonal(topLeft, topRight, bottmLeft, bottomRight, center, directionVector, raycastLength, resultsArray, intersectableObjects, centreLerpFactor = 0, debugBool = false, numOfSamplePoints = 3) {

        // Move towards center
        topLeft.lerp(center, centreLerpFactor);
        topRight.lerp(center, centreLerpFactor);
        bottmLeft.lerp(center, centreLerpFactor);
        bottomRight.lerp(center, centreLerpFactor);

        // Raycast diagonally over the face area
        this.intersectObjectsOverLine( new THREE.Vector3().copy(topLeft), new THREE.Vector3().copy(bottomRight), directionVector, raycastLength, numOfSamplePoints, intersectableObjects, resultsArray, debugBool);

        this.resetFarValue();
    }
}