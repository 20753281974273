import React from 'react';
import { Modal} from 'antd';
 
const WarningModal = (props) => {

    return (
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={700}
        onCancel={props.onCancel}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        centered={true}
        footer={props.footer}>
        <div className="justify-in-start" style={{padding:"30px"}}>
            <img style={{width: 85,height:85,marginRight: 20}} src={"/img/alert-triangle.svg"}></img>
            <div>
                <h2  className="manrope f-16 black-00 justify-in-start">{props.heading}</h2>
                <p className="manrope f-14 black-55">{props.text}</p>
            </div>     
        </div>       
    </Modal>
      );
}

export default WarningModal;