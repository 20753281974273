import React, { useState } from "react";
import { Carousel, Button, Card, Modal } from "antd";
import {
  CloseOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import * as Utilities from "../../Utilities";
import { useEffect } from "react";
import FileConstants from "../../../../FileConstants";
import ENVIRONMENT from "../../../../environments";

const ARTIST_TOUR_COOKIE_NAME = "artist-tour-viewed";
const ARTIST_TOUR_VIEWED_VALUE = "tour_viewed";

const ArtistOnboarding = () => {
  let carousel = React.createRef();
  const [newStep, setNewStep] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState("");
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [congratsModal, setCongratsModal] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [triggerNext, setTriggerNext] = useState(false);
  const [carouselStart, setCarouselStart] = useState(1);

  useEffect(() => {
    let tour_cookies = Utilities.getCookie(ARTIST_TOUR_COOKIE_NAME);
    console.log(tour_cookies)
    if(tour_cookies != ARTIST_TOUR_VIEWED_VALUE){
      setNewStep(1)
      let data_arr = [];
      FileConstants.artistTourOptions.map((option) => {
        data_arr[option] = FileConstants.get_artist_tour_image(option);
      });
      setCarouselData(data_arr);
    }

  }, []);

  const setCurrentStep = (path) => {
    if (path == "close") {
      setIsTourOpen(false);
      setNewStep(-1);
      Utilities.setCookie(
        ARTIST_TOUR_COOKIE_NAME,
        ARTIST_TOUR_VIEWED_VALUE,
        365
      );
    }
  };

  const openFeatureSteps = (feature) => {
    if (carousel && carousel.current) {
      carousel.goTo(0, true);
    }
    setNewStep(3);
    setSelectedFeature(feature);
  };

  const prev = () => {
    //if user moves back from step from carousel then close the carousel
    if (carouselStart == 1) {
      carousel.goTo(0, true);
      setSelectedFeature("");
      setCongratsModal(false);
      setTriggerNext(false);
    } else {
      carousel.prev();
      //this detects that the user moved the carousel through next/prev button
      setTriggerNext(true);
    }
  };

  const onCancelCongrats = () => {
    setCongratsModal(false);
    setSelectedFeature("");
    setNewStep(2)
  };

  const next = () => {
    carousel.next();
    //this detects that the user moved the carousel through next/prev button
    setTriggerNext(true);
  };

  const closeCarouselSlides = () => {
    setSelectedFeature("");
    setCarouselStart(1);
    carousel.goTo(0, true);
  };

  const onChangeCarousel = (before, to) => {
    console.log(carousel);
    setCarouselStart(to + 1);
    if (selectedFeature != "") {
      //if user completes the tour i.e if the next step is equal to the number of images on s3, then show congrats modal
      if (
        before == carouselData[selectedFeature].length - 1 &&
        to == 0 &&
        triggerNext
      ) {
        setCongratsModal(true);
        setCurrentStep("close");
        setTriggerNext(false);
      } else if (
        before == 0 &&
        to == carouselData[selectedFeature].length - 1 &&
        triggerNext
      ) {
        //if the user goes back from the first step, then close carousel and move to previous step
        setSelectedFeature("");
        setCongratsModal(false);
        setTriggerNext(false);
      }
    }
  };

  const onCancelCarousel = () => {
    carousel.goTo(0, true);
    setSelectedFeature("");
  };


  return (
    <React.Fragment>
      {/* Carousal Modal */}
      <Modal
        onCancel={onCancelCarousel}
        title={null}
        closable={false}
        maskClosable={false}
        footer={null}
        visible={selectedFeature != "" && !congratsModal}
        bodyStyle={{ padding: 0 }}
        className="tour-modal"
        centered={true}
      >
        <div
          style={{ padding: "20px 28px" }}
          id={`modal-container`}
          className="container-portrait"
        >
          {carouselData[selectedFeature] &&
          carouselData[selectedFeature].length > 0 ? (
            <>
              <div
                className="manrope f-24 black-14 w-600 justify-in-center"
                style={{ textAlign: "center", margin: "auto" }}
              >
                {FileConstants.getTourHeading(selectedFeature)}
              </div>

              <div
                className="justify-in-center"
                style={{ position: "relative" }}
              >
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Carousel
                    dots={false}
                    autoplay={false}
                    ref={(node) => (carousel = node)}
                    style={{ width: "100%" }}
                    beforeChange={onChangeCarousel}
                  >
                    {carouselData[selectedFeature].map(
                      (img, index) =>
                        index >= 0 && (
                          <div>
                            <img
                              style={{ marginTop: 21 }}
                              className="carousel-help"
                              src={img}
                              // alt="carousel help"
                            />
                          </div>
                        )
                    )}
                  </Carousel>
                </div>
              </div>

              <div className="justify-space-between" style={{ marginTop: 28 }}>
                <Button
                  className="modal-okay-light-gray square font-14"
                  onClick={closeCarouselSlides}
                >
                  Skip Tour
                </Button>
                <div className="justify-in-end">
                  <Button
                    className="modal-okay-light-gray square font-14"
                    style={{ marginRight: 30 }}
                    onClick={() => prev()}
                  >
                    <LeftOutlined />
                  </Button>
                  <p
                    style={{ marginBottom: 0, marginRight: 30 }}
                    className="manrope f-14 black-14"
                  >
                    {carouselStart} / {carouselData[selectedFeature].length}
                  </p>
                  <Button
                    className="modal-okay square font-14"
                    onClick={() => next()}
                  >
                    {" "}
                    <RightOutlined />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div style={{ height: "500px" }} className="justify-in-center">
              <LoadingOutlined style={{ fontSize: 40, textAlign: "center" }} />
            </div>
          )}
        </div>
      </Modal>

      {/* Tour Feature Modal */}
      <Modal
        title={null}
        closable={false}
        footer={null}
        visible={newStep == 2 && !congratsModal}
        bodyStyle={{ padding: 0 }}
        className="tour-explore-modal"
        centered={true}
      >
        <div style={{ padding: "28px" }}>
          <div
            className="manrope f-24 black-14 w-600 justify-in-center"
            style={{ textAlign: "center", margin: "auto" }}
          >
            Let’s Explore The Basics
          </div>
          <div className="justify-in-center" style={{ flexWrap: "wrap" }}>
            {FileConstants.ARTIST_TOUR_FEATURE.map((ele, index) => (
              <Card
                style={{
                  padding: 12,
                  marginBottom: 12,
                  marginTop: 12,
                  marginRight: 12,
                }}
                onClick={() => openFeatureSteps(ele.value)}
              >
                <img className="explore-img" src={ele.image} />
                <div
                  className="manrope f-14 black-14 w-500"
                  style={{ textAlign: "center", marginTop: 12 }}
                >
                  {ele.text}
                </div>
              </Card>
            ))}
          </div>
          <div className="justify-in-end" style={{ marginTop: 28 }}>
            <Button
              className="modal-okay-light-gray square font-14"
              style={{ marginRight: 30 }}
              onClick={() => setCurrentStep("close")}
            >
              Skip Tour
            </Button>
          </div>
        </div>
      </Modal>

      {/* Congrats Modal */}
      <Modal
        className="congrats-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={600}
        onCancel={onCancelCongrats}
        visible={congratsModal}
        bodyStyle={{ padding: 0 }}
        footer={[
          Utilities.deviceType() == "desktop" && (
            <div className="justify-in-center">
              <Button
                className="modal-okay-light-gray square font-16"
                onClick={onCancelCongrats}
              >
                Okay
              </Button>
            </div>
          ),
        ]}
      >
        <div style={{ padding: "40px" }} id={`modal-container`}>
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: 85, height: 85 }}
              src={"/img/smiley.png"}
              alt="smiley"
            ></img>
          </div>
          <div style={{ textAlign: "center", margin: "20px auto" }}>
            <h2 className=" manrope f-20 green-75 w-600 justify-in-center">
              Congratulations!
            </h2>
            <p className="manrope f-16 black-55">
              {FileConstants.congratsMessage(selectedFeature)}
            </p>
          </div>
        </div>
      </Modal>

      {/* Welcome Modal */}
      <Modal
        title={null}
        closable={false}
        footer={null}
        visible={newStep == 1}
        bodyStyle={{ padding: 0 }}
        width={800}
        centered={true}
      >
        <div style={{ padding: 40 }}>
          <img
            style={{ width: "100%" }}
            src={require("../../../../assets/images/welcome_to_all3d.jpg")}
          ></img>
          <div style={{ width: 620, textAlign: "center", margin: "auto" }}>
            <h3 style={{ marginTop: 12 }}>Welcome to ALL3D!</h3>
            <p>
              The ALL3D team is happy to have you as an Artist on the platform.
              As an Artist you will get a chance to work on exciting models for
              interior products and spaces. Since you are new on the platform,
              we will help you understand the basics of how things work
            </p>
            <div className="justify-in-center" style={{ marginTop: 32 }}>
              <Button
                className="modal-okay-light-gray square font-16"
                style={{ marginRight: 30 }}
                onClick={() => setCurrentStep("close")}
              >
                Skip Tour
              </Button>
              <Button
                className="modal-okay square font-16"
                onClick={() => {
                  setCurrentStep("close");
                  setNewStep(newStep + 1);
                }}
              >
                Let's Get Started
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ArtistOnboarding;
