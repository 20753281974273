import React, { useContext } from 'react';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import { WarningOutlined } from '@ant-design/icons';

const LoadingContainer = (props) => {
    return (<div className="load-container">
        {props.icon}
        <h3 className="load-message large">{props.heading}</h3>
        <h3 className="load-message">{props.sub_text}</h3>
    </div>)
}

const SceneCollaboratorLoader = () => {
    const { sceneLoadFailed} = useContext(CollaborateContext);

    return (
        sceneLoadFailed ?
            <LoadingContainer
                icon={<WarningOutlined className='on-start-load warning-icon' size="large" />}
                heading={"Oops! An error occured."}
                sub_text={"An error occured while trying to load Scene Creator. Please contact admin."} />
            :
            ""
    );
}


export default SceneCollaboratorLoader;
