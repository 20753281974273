import React, { useContext } from 'react';
import _ from "lodash";
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { Row, Col, Typography } from 'antd';
import './ProductQA.scss'

const { Paragraph } = Typography;

const PhoneLinkContainer = (props) => {
    return (<div className='ar-view-box display-flex j-c a-c'>
        <img src={props.phone_icon} />
        <div className="name-container">
            <div className="manrope f-16 black-26 lh-16">AR for {props.phone_type}</div>
            <Paragraph className="manrope f-14 blue lh-14" copyable={{ text: props.getUrl }}>
                Copy link
            </Paragraph>
        </div>
    </div>)
}


const ARViewTab = () => {
    const { getUSDZUrl, getAndroidUrl } = useContext(CollaborateQAContext);

    return (<Row className='product-qa-image'>
        <Col span={24} className='display-flex j-c a-c gg-40'>
            <PhoneLinkContainer
                phone_type={"iPhone"}
                phone_icon={"/img/collaborate_qa/Iphone.svg"}
                getUrl={getUSDZUrl()}
            />
            <PhoneLinkContainer
                phone_type={"Android"}
                phone_icon={"/img/collaborate_qa/Android.svg"}
                getUrl={getAndroidUrl()}
            />
        </Col>
    </Row>)
}

export default ARViewTab;