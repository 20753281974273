import React, { useContext } from 'react';
import _ from "lodash";
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { Row, Col, Button } from 'antd';
import './ProductQA.scss'
import ENVIRONMENT from '../../../../environments';
import * as Utilities from '../../Utilities';


const RejectionDetails = () => {
    const { setShowDetailedRejection, setRejectionImageUrl, product_id,
        setShowRejectionImage, customerRejectionComments, latestRejection, platform } = useContext(CollaborateQAContext);


    const showRejectionModal = () => {
        setShowDetailedRejection(true);
    }


    let rejections_data = '';

    if (latestRejection != null) {
        rejections_data = (
            <div style={{ marginTop: 20 }}>
                <div className='manrope f-12 black-55 align-text' style={{ marginBottom: 8 }}>Date <span style={{ color: "#333333" }}>{Utilities.convertUtcToLocalTimezone(latestRejection.qa_time) || '-'}</span></div>
                <div style={{ display: "flex", flexDirection: 'column' }}>
                    <Col span={24}>
                        {latestRejection.feedback_link ?
                            <span style={{ paddingRight: 5 }}>
                                <span className="manrope f-12 black-55" style={{ textAlign: 'left', display: 'block', marginBottom: 4 }}>
                                    Feedback
                                </span>
                                <span style={{ display: 'flex' }}>
                                    <a className="manrope f-12 mb-0 text-left"
                                        href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.PRODUCT_FEEDBACK_URI + product_id + "/" + latestRejection.feedback_link + '.pdf'}
                                        target='_blank'>
                                        {latestRejection.feedback_link + '.pdf'}
                                    </a>
                                </span>
                            </span> : ''}
                    </Col>
                    <Col span={24}>
                        <span style={{ paddingRight: 5, overflow: 'hidden' }}>
                            <span className="manrope f-12 black-55 text-left mb-4" style={{ display: 'block' }}>
                                Comment(s)
                            </span>
                            <span style={{ display: 'flex' }}>
                                <span className="manrope f-12 black-33 mb-0 text-left" style={{ width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    {latestRejection.rejection_details}
                                </span>
                            </span>
                        </span>
                    </Col>


                    {(latestRejection.reference_files != [] && latestRejection.reference_files != undefined) && (typeof (latestRejection.reference_files) == "object" && Object.keys(latestRejection.reference_files).length != 0) ?
                        <Col span={8}>
                            <div className="category-artist" style={{ textAlign: 'left', marginBottom: 7 }}>
                                Uploads
                            </div>
                            {latestRejection.reference_files && latestRejection.reference_files.length > 0 ?
                                <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(latestRejection.reference_files[0]['uid'] + '/' + latestRejection.reference_files[0]['name'])}>
                                    {(latestRejection.reference_files[0].name.includes('.jpg') || latestRejection.reference_files[0].name.includes(".png") || latestRejection.reference_files[0].name.includes(".PNG") || latestRejection.reference_files[0].name.includes(".svg")) ?
                                        <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(latestRejection.reference_files[0]['uid'] + '/' + latestRejection.reference_files[0]['name'])} /> :
                                        (latestRejection.reference_files[0].name.includes(".tiff")) ? <img className="upload-image-settings" src={'/img/tiff_icon.png'} /> :
                                            <img className="upload-image-settings" src={'/img/ele.reference_files[0]-icon.png'} />}
                                    <span className="category-artist text-left" style={{ fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', width: '90%', whiteSpace: 'nowrap' }}>{latestRejection.reference_files[0].name}</span>
                                </a>
                                : ''}
                        </Col>
                        : ''}
                </div>

            </div>
        );
    }


    return (rejections_data ? <Row className='m-20'>
        <Col sm={24} lg={24} xl={12} className="note-bg-artist pd-20 red">
            <div className='justify-space-between'>
                <div className='manrope f-14 red'>
                    Rejection Comments
                </div>
                <Button type="link" onClick={showRejectionModal} className='manrope f-14 blue hover-change'>
                    View Details
                </Button>
            </div>
            {rejections_data}
        </Col>
    </Row> : "")
}

export default RejectionDetails;