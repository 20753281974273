import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
} from '../actions';

const initialState = {
  dashboardData: {
    counts: {
      'rooms': -1,
      'products': -1,
      'scenes': -1,
      'collections': -1,
      'scene_inProgress': -1,
      'scene_qaPending': -1,
      'scene_approved': -1,
      'store': -1
    },
    productsGraph: {},
    storeGraph: {},
    roomsGraph: {},
    scenesGraph: {},
    suggestedProducts: [],
    entities: [],
  },
  isLoading: false,
  error: null,
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        dashboardData: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        dashboardData: {},
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
