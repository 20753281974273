  import React, { useState, useEffect } from 'react';
  import ENVIRONMENT from '../../../../environments';
  import { Form } from '@ant-design/compatible';
  import { Row, Button, message, Modal, Col, Input, Upload, Select } from 'antd';
  import * as Constants from "../../CustomerComponents/Constants";
  import axios from 'axios';
  import AWS from 'aws-sdk';
import { BUCKET_NAME } from '../../../../environments/env';

  const { TextArea } = Input;
  const { Option } = Select;

  const ReviewProduct = (props) => {
    const [visible, setVisible] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [refFileList, setRefFileList] = useState([]);
    const [adminAttach, setAdminAttach] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [customerModelReivewVisible, setCustomerModelReviewVisible] = useState(false);
    const [artistList, setArtistList] = useState([{artist_profile: props.assigned_artist, hourly_rate: null}]);
    const { getFieldDecorator } = props.form;

    const onApprove = () => {
      props.onApprove();
      if(props.notShowStatusPopup == undefined)
        message.info("Product status updated successfully");
    }

    const handleCancel = () => {
      setVisible(false);
    }

    const handleCancelCustomerForm = () => {
      setCustomerModelReviewVisible(false);
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      props.form.validateFields(['msg', 'reference_files', 'assigned_artist'], (err, values) => {
        if (!err) {
          if (values.reference_files == undefined) {
            values.reference_files = props.referenceFiles;
          }
          let uploadStatus = validateFileUploads(values['reference_files']);
          if (uploadStatus == 'uploading') {
              setUploadError('Uploads are in progress, please wait till file uploads are completed.')
          }
          else if (uploadStatus == 'error') {
              setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
          }
          else if (uploadStatus == 'done' || uploadStatus == 'not_started') {
              setVisible(false);
              props.onReject(values);
          }
        }
      });
    }

    const validateFileUploads = (files) => {
      let status_done = false;
      if (files != undefined) {
          for(var file of files) {
              if (file['status'] == 'uploading') {
                  return 'uploading';
              }
              else if (file['status'] == 'error') {
                  return 'error';
              }
              else if (file['status'] == 'done') {
                  status_done = true;
              }
          }
      }
      if (status_done) {
          return 'done'
      }
      return 'not_started';
  }

  const handleChangeRefFile = info => {
    let fileList = [...info.fileList];
    setRefFileList(fileList);
    setUploading(true);
    console.log(info,info.file);
    if (info.file.status === "done") {
        setHasError(false);
        setErrorMessage('');
        setUploading(false);
    } else if (info.file.status === "error") {
        console.log(info.file.error.message,info.file);
        setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
        if (info.file.error.code.includes('Credentials')) {
            setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
        }  else if (info.file.error.code.includes('Network')) {
            setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
        }
        setHasError(true);
        setUploading(false);

    }
  };

  const handleChangeAdminFile = info => {
    let fileList = [...info.fileList];
    setAdminAttach(fileList);
    setUploading(true);
    console.log(info,info.file);
    if (info.file.status === "done") {
        setHasError(false);
        setErrorMessage('');
        setUploading(false);
    } else if (info.file.status === "error") {
        console.log(info.file.error.message,info.file);
        setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
        if (info.file.error.code.includes('Credentials')) {
            setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
        }  else if (info.file.error.code.includes('Network')) {
            setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
        }
        setHasError(true);
        setUploading(false);
    }
  };


    const handleSubmitCustomerForm = (e) => {
      e.preventDefault();
      props.form.validateFields(['admin_comments', 'admin_attachments'], (err, values) => {
        if (!err) {
          setCustomerModelReviewVisible(false);
          props.onSendToCustomer(values);
        }
      });
    }

    const loadArtistsData = () => {

      axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
      })
        .then(res => {
          let artist_list = [];
          res.data.map(artist => {
            if (artist.is_active == true || artist.is_active == "True") {
              artist_list.push(artist);
            }
          });
    
          setArtistList(artist_list);
        });
    }

    useEffect(() => {
      loadArtistsData();
    }, []);

    return <div className="product-status-review-admin">
      <Row type="flex" justify="space-between">
        <h4></h4>
        <div>
          {props.model_status == 3 ? <Button disabled={false} type="default" style={{marginRight: 10}} onClick={() =>{props.setApproveToCustomerLibrary(true); onApprove();}}>Approve and Add to Customer's Library</Button>: ''}
          {props.model_status != 9 ? <Button disabled={false} type="default" onClick={() => setCustomerModelReviewVisible(true)}>Send Back to Customer</Button>: ''}
          {props.model_status != 9 ? <Button disabled={false} type="default" onClick={() => setVisible(true)} style={{ marginLeft: "10px" }}>{props.rejectTxt}</Button>: ''}
          {(props.approveTxt != "" && props.model_status != -3)?
            <Button type="danger" onClick={() => onApprove()} style={{ marginLeft: "10px" }}>{props.approveTxt}</Button>:""
          }
        </div>
      </Row>
      
      <Modal className="model-time-modal"
          visible={visible}
          onOk={handleSubmit}
          maskClosable={false}
          onCancel={handleCancel}>
        <div style={{padding: "32px"}}>
          <div className="model-time-h" style={{marginBottom: 30}}>Confirm Rejection</div>
          <Form className="input-form">
          <Form.Item className="artist-upload-txt align-text" name="msg" label="Rejection Message" colon={false}>
              {getFieldDecorator('msg', {
                rules: [{ required: true, message: "Message is required!" }]
              })(
                <TextArea
                rows={5}
                className="custom-input-contain"
                style={{height: "auto !important"}}
                placeholder = "Enter Rejection Message"
                defaultValue={props.rejectionDetails}
                />
              )}
            </Form.Item>
            <Form.Item className="artist-upload-txt align-text confirm-height" name ={'reference_files'} label="Reference Files"  colon={false}>
              {getFieldDecorator('reference_files', {
                valuePropName: 'reference_files',
                getValueFromEvent: e => e && e.fileList,
              })(
                <Upload defaultFileList={props.referenceFiles} 
                // accept=".zip,.png,.jpg,.jpeg,.pdf"
                onRemove={file => {
                  const index = refFileList.indexOf(file);
                  const newFileList = refFileList.slice();
                  newFileList.splice(index, 1);
                  setRefFileList(newFileList);
                  console.log(newFileList)
                  newFileList.forEach((file) => {
                      if (file.status !== "error"){
                          setHasError(false)
                      }
                      else {
                          setHasError(true)
                      }
                  })
                  return true;
              }}
              fileList={refFileList}
              onChange = {handleChangeRefFile}
              openFileDialogOnClick={!(validateFileUploads(refFileList) == 'error')}
              {...Constants.getUploadProps(props.platform)} 
              multiple="true" 
              listType="text" 
              className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-height-confirm">
                  <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                    <div style={{textAlign:"center"}}>
                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                        <div className="ant-upload-text ant-upload-text-w" >
                            <div className="up-info">Drop your reference files.</div>
                            {/* <div>SUPPORTED FORMATS: ZIP, JPG, PNG, PDF, DOCS</div> */}
                        </div>
                        {(validateFileUploads(refFileList) == 'error') && (<Button
                            onClick={() => Constants.triggerFailedFilesUpload('reference_files', refFileList, setRefFileList)}
                            disabled={refFileList.length === 0}
                            style={{position:'relative',top: 0}}
                            loading={uploading}
                            className="retry-btn" ghost
                            >
                            {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                        </Button>)}
                    </div>
                  </div>
                </Upload>
              )}
            </Form.Item>
            {(uploadError != '')?
            <div className="ant-legacy-form-explain" style={{color: "#ff4d4f", textAlign: "center"}}>{uploadError}</div> :""}
              {(validateFileUploads(refFileList) == 'error') ? 
                <div className='retry-error-message left'>{errorMessage}</div>
            : ''}
            <Form.Item name="assigned_artist" label={"Assigned Artist"}  colon={false}>
                  {getFieldDecorator('assigned_artist', {
                      initialValue: props.assigned_artist,
                      rules: [
                          { required: true, message: "Please select Artist"}
                      ],
                  })(
              <Select
                  showSearch
                  optionFilterProp="children"
                  style={{color:"#333333"}}
                  defaultValue={props.assigned_artist}>
                  {artistList.map((ele, index) => {
                    return <Option key={index} value={ele.artist_profile}>{ele.artist_profile + " at rate " + ele.hourly_rate}</Option>
                  })}
              </Select>
                  )}
            </Form.Item>
            <Form.Item style={{textAlign:'right'}}>
              <Button onClick={handleSubmit} className="modal-okay square" type="primary" htmlType="submit">
                  <div className="modal-okay-text">Okay</div>
              </Button>
            </Form.Item>
            </Form>
        </div>
      </Modal>
      <Modal bodyStyle={{ paddingTop: "2px" }}
        title="Confirm"
        visible={customerModelReivewVisible}
        onOk={handleSubmitCustomerForm}
        onCancel={handleCancelCustomerForm}>
        <div>
          <Form className="input-form">
            <Form.Item name="admin_comments" label="Message for Customer" colon={false}>
              {getFieldDecorator('admin_comments', {
                rules: [{ required: true, message: "Message is required!" }]
              })(
                <TextArea
                  placeholder="Enter Message"
                  rows={5}
                  style={{height: "auto !important"}}
                  defaultValue={props.rejectionDetails}
                />
              )}
            </Form.Item>
            <Form.Item name={'admin_attachments'} label="Reference Files" colon={false}>
              {getFieldDecorator('admin_attachments', {
                valuePropName: 'admin_attachments',
                getValueFromEvent: e => e && e.fileList,
              })(
                <Upload defaultFileList={props.referenceFiles} {...Constants.getUploadProps(props.platform)} 
                onRemove={file => {
                  message.success(`${file.name} removed successfully!`)
                  const index = adminAttach.indexOf(file);
                  const newFileList = adminAttach.slice();
                  newFileList.splice(index, 1);
                  setAdminAttach(newFileList);
                  console.log(newFileList)
                  newFileList.forEach((file) => {
                      if (file.status !== "error"){
                          setHasError(false)
                      }
                      else {
                          setHasError(true)
                      }
                  })
                  return true;
              }}
              fileList={adminAttach}
              onChange = {handleChangeAdminFile}
              openFileDialogOnClick={!(validateFileUploads(adminAttach) == 'error')}
                multiple="true" listType="text" className="upload-lg-btn ant-upload-picture-card-wrapper">
                  <div className="d-flex">
                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                    <div className="ant-upload-text">
                      <div className="up-info">Drop your Files</div>
                      {/* <div>Support: JPG, PNG</div> */}
                    </div>
                    {(validateFileUploads(adminAttach) == 'error') && (<Button
                            onClick={() => Constants.triggerFailedFilesUpload('admin_attachments', adminAttach, setAdminAttach)}
                            disabled={adminAttach.length === 0}
                            loading={uploading}
                            style={{position:'relative',top: 0}}
                            className="retry-btn" ghost
                            >
                            {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                        </Button>)}
                  </div>
                </Upload>
              )}
            </Form.Item>
            {(validateFileUploads(adminAttach) == 'error') ? 
                <div className='retry-error-message left'>{errorMessage}</div>
            : ''}
            </Form>
          </div>
        </Modal>
      </div>

  }
  export default Form.create({ name: 'review-form' })(ReviewProduct)