import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Button, Collapse, Tooltip, Tabs, Row, Col, Input, message, Select, Typography, Checkbox, Empty } from 'antd';
import { LoadingOutlined, EditOutlined, LinkOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import axios from 'axios';

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const formRef = { useRef };

const CUSTOMER_USERNAME = localStorage.getItem('username');

export default function AddEntityToProject({ entityId, entityType, modalVisible, setModalVisible, projects }) {
    const [buttonLoader, setButtonLoader] = useState(false);

    function handleSubmit(values) {
        setButtonLoader(true)
        let payload = {
            'project_id': values.project,
            'generate_thumbnail': true
        }
        if (entityType === 'Product') {
            payload['products_list'] = [entityId]
        }
        else if (entityType === 'Room') {
            payload['rooms_list'] = [entityId]
        }
        else if (entityType === 'Scene') {
            payload['scenes_list'] = [entityId]
        }
        

        axios.post(ENVIRONMENT.ADD_ENTITY_BATCH_TO_PROJECT, payload)
            .then(res => {
                setButtonLoader(true)
                if (res.data.status == 201) {
                    message.info(`${entityType} added successfully to the project!`)
                    setButtonLoader(false)
                    setModalVisible(false)
                }
                else if (res.data.status == 400) {
                    message.info(`${entityType} is already in the project!`)
                    setButtonLoader(false)
                    setModalVisible(false)
                }
            })
    }

    return (<>

        <Modal
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            className="modal-share"
            footer={null}
        >
            <div>
                <h3>Add {entityType} to the project</h3>
                <Form
                    ref={formRef}
                    onFinish={handleSubmit}
                >
                    <div>
                        <span className='manrope f-14 black-55 w-700 mb-10'>Projects&nbsp;</span>
                        <Form.Item name="project" colon={false} rules={[{ required: false, message: 'Please select project.' }]}>
                            <Select
                                style={{ lineHeight: "220%" }}
                                className="tree-select-material adjust-height-46 f-12"
                                showSearch
                                placeholder="Select or type in project name"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {Object.keys(projects).map((key) => (
                                    <Select.Option
                                        key={projects[key].id}
                                        className="manrope f-12 black-55 select-view"
                                        value={projects[key].id}
                                    >
                                        {projects[key].name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <span className="justify-in-end mt-20">
                        <Button disabled={buttonLoader} className="modal-okay-gray square font-14 mr-10" onClick={() => setModalVisible(false)}>
                            Cancel
                        </Button>

                        <Button htmlType="submit" disabled={buttonLoader} className="modal-okay square font-14">
                            Submit&nbsp;{buttonLoader ? <LoadingOutlined /> : ''}
                        </Button>
                    </span>
                </Form>
            </div>
        </Modal>
    </>)
}