import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tooltip, Modal, Button, message, Dropdown, Menu } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as Utilities from '../../Utilities';
import ENVIRONMENT from '../../../../environments';
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'

import axios from "axios";

const ActiveModelsKeys = {
    review: "in-review",
    progress: "in-progress",
    drafts: "drafts"
}

const ActiveModelCard = (props) => {
    let entity = props.model;
    let type = props.type;

    const [deleteItemModal, setDeleteItemModal] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [isMenuVisible, setIsMenuVisible] = useState(false)

    const getStatusString = (status) => {
        if (status == "1" || status == "11") {
            return "Incomplete";
        } else if (["2", "3", "6", "7", "8"].includes(status)) {
            return "ALL3D is creating";
        } else if (status == "4") {
            return "Needs Review";
        } else {
            return "ALL3D is creating";
        }
    };

    const getThumbnail = (ele) => {
        let uri = '';
        if (ele.type == 'Product') {
            uri = 'product_assets/thumbnail/'
        } else if (ele.type == 'Space') {
            uri = `room_assets/thumbnail/${ele.id}/`
        }
        if (ele.thumbnail != "") {
            return ENVIRONMENT.getBaseURL(ele.platform) + uri + ele.thumbnail;
        }
    };

    const getIconString = (status) => {
        if (status == "1") {
            return '/img/exclamation-red.svg';
        } else if (["2", "3", "6", "7", "8", "11"].includes(status)) {
            return '/img/carbon_time.svg';
        } else if (status == "4") {
            return '/img/exclamation-red.svg';
        } else {
            return '/img/carbon_time.svg';
        }
    };

    const onClickActiveModels = (id, type, entity) => {
        if (type == 'Product') {
            if (props.modelsTabKey == ActiveModelsKeys.drafts) {
                if (entity.uploaded_model && entity.model_status == "-6") {
                    window.location.href = `/product_page/${id}`
                } else if (entity.uploaded_model) {
                    window.location.href = `/upload-product-model/${id}?uploaded-model=true`
                } else if (entity.designed_product) {
                    window.location.href = `/add-new-product/${id}`
                } else {
                    window.location.href = `/create-existing-physical-product/${id}`
                }
            } else {
                if (entity.model_status === "4") {
                    window.location.href = `/product-qa/${id}`
                } else {
                    window.location.href = `/product_page/${id}`
                }
                
            }
        } else if (type == 'Space') {
            if (props.modelsTabKey == ActiveModelsKeys.drafts) {
                window.location.href = `/add-new-room?step=0&room_id=${id}`
            } else {
                window.location.href = `/room-qa/${id}`;
            }
        } else if (type == 'Material') {
            if (props.modelsTabKey == ActiveModelsKeys.drafts) {
                if (entity.is_scanned) {
                    window.location.href = `/upload-scanned-material/${id}`;
                } else {
                    window.location.href = `/create-new-material/${id}`;
                }
            } else {
                props.setMaterialModal(true);
                props.setSelectedMaterial('');
                let payload = {
                    material_id: id
                };
                axios.post(ENVIRONMENT.MATERIAL_GET, payload)
                .then(res => {
                    let material_data = JSON.parse(res.data.body);
                    props.setSelectedMaterial(material_data);
                });
            }
        }
    };

    const deleteIncompleteItem = (id, type) => {
        setDeleteLoader(true);
        let payload = {
            entity_id: id,
            entity_type: type,
        };
    
        axios.post(ENVIRONMENT.DELETE_INCOMPLETE_REQUEST, payload)
        .then(() => {
            message.success(type + ' Deleted Successfully.')
            setDeleteLoader(false)
            setDeleteItemModal(false)
            setTimeout(() => window.location.reload(), 600)
        })
        .catch((error) => {
            message.error('Error in deleting');
            setDeleteLoader(false);
            setDeleteItemModal(false);
        });
      };

    const handleDeleteClick = (e) => {
        setDeleteItemModal(true)
        e.stopPropagation()
        setIsMenuVisible(false)
    }

    return (
        <>
            <Card style={{cursor: "pointer"}} onClick={() =>onClickActiveModels(entity.id, entity.type, entity)}>
            {type == 'drafts' ?
                <Dropdown className="group-settings more-icon" onClick={e => e.stopPropagation()}
                    visible={isMenuVisible}
                    onVisibleChange={(flag) => setIsMenuVisible(flag)}
                    overlay={
                <Menu>
                    <Menu.Item>
                        <a className="manrope f-14" onClick={handleDeleteClick}>
                                Delete {entity.type}</a>
                    </Menu.Item>
                    </Menu>
                } trigger={['click']}>
                <MoreIcon />
                </Dropdown> : ''
            }
                <Row>
                    {type == ActiveModelsKeys.review ? 
                        <Col span={24}>
                            <img
                            style={{ objectFit: "contain", width: "100%", height: 250, zIndex:1 }}
                            src={getThumbnail(entity)}/>
                        </Col> :
                        <Col span={24} style={{ height: 250, backgroundColor: '#fafafa' }} className="justify-in-center">
                            <img
                            style={{ objectFit: "contain", width: "40%", height: "40%", zIndex:1 }}
                            src={require(`../../../../assets/images/${entity.type == "Product" ? "product" : entity.type == "Space" ? "space" : "material"}_incomplete.svg`)}
                            />
                        </Col>
                    }
                </Row>
                <Row style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}>
                    <Col span={12} className="justify-in-start">
                        <span className={`model-type-btn ${entity.type == "Product" ? "blue" : entity.type == "Space" ? "yellow" : "red"}`}>{entity.type}</span>
                    </Col>
                    <Col span={12} className="justify-in-end" >
                        <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(entity.last_modified, 'tooltip')}</span>}>                                                  
                            <span className="manrope f-12 grey-77 w-500 time-clock-bg"  style={{marginLeft: 4}}>
                                <img src='/img/carbon_time.svg' style={{marginRight: 2}}/>{Utilities.timestampToTimeSince(entity.last_modified)}
                            </span>
                        </Tooltip>
                    </Col>
                    <Col span={24} style={{ marginTop: 10 }}>
                        <Tooltip title={entity.name}>
                            <p className="info-text size-14 black limit-line w-280">{entity.name}</p>
                        </Tooltip>
                    </Col>
                    {type == ActiveModelsKeys.review ? 
                    <Col span={24}>
                        <Tooltip title={entity.category}>
                            <div className="justify-in-start" style={{ marginRight: 12, marginBottom: 4, textAlign: "left" }}>
                                <span className="info-text size-14 light-gray" style={{ marginRight: 10 }}>Category</span>
                                <span className="info-text size-14 black">{entity.category}</span>
                            </div>
                        </Tooltip>
                    </Col>
                    : ""}
                </Row>
                <Row style={{ marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 15 }}>
                    <Col span={3} className="justify-in-start">
                        <img src={getIconString(entity.model_status)} style={{ width: 24, height: 24 }}/>
                    </Col>
                    <Col span={21} className="justify-in-start">
                        <span className="info-text size-14 black w-600">{getStatusString(entity.model_status)}</span>
                    </Col>
                </Row>
            </Card>

            <Modal
                className="silo-success-modal"
                width={800}
                centered={true}
                visible={deleteItemModal}
                onCancel={() => setDeleteItemModal(false)}
                closable={true}
                footer={[
                    <span className="justify-in-center">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => setDeleteItemModal(false)}>
                            Cancel
                        </Button>
                        <Button key="back" disabled={deleteLoader} style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => deleteIncompleteItem(entity.id, entity.type)}>
                            {deleteLoader? <span> Deleting <LoadingOutlined spin /></span>:<span> Yes, Delete {entity.type} </span>}
                        </Button>
                    </span>
                ]}
                    > 
                    <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h2 className="manrope f-24 black-55 w-900">
                        {`You are about to delete an incomplete ${entity.type}`}
                    </h2>
                    <p className="manrope f-18 black-33">Are you sure you want to proceed?</p>
                    </div>  
            </Modal>
        </>
    );
};

export default ActiveModelCard;