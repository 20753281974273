import React, { useState, useMemo } from "react";
import { Button, Menu, Modal, Tooltip, Dropdown } from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import SpaceColorVariationModal from "../ProductComponents/SpaceColorVariationModal";
import OtherVariationsAction from "./OtherVariationsAction";
import AddEntityToProject from "../AddEntityToProject/AddEntityToProject";

const QUERY_PARAMS_TO_RETAIN = ["category", "search"];

const SpaceVariantAction = ({
  roomDetails,
  params,
  savedID,
  spaceDetails,
  userProjects,
}) => {
  const companyId = localStorage.getItem("company_id");
  const [dummySceneSetup, setDummySceneSetup] = useState(false);
  const [dummySceneError, setDummySceneError] = useState(false);
  const [variationModal, setVariationModal] = useState(false);
  const [spaceColorVariationModal, setSpaceColorVariationModal] =
    useState(false);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(null);


  //VARIATION MODAL FUNCTIONS
  const openVariationModal = () => {
    setVariationModal(true);
  };

  const cancelVariationModal = () => {
    setVariationModal(false);
  };

  const handleCustomerDummyScene = () => {
    if (roomDetails.customer_dummy_scene_id == "") {
      setDummySceneSetup(true);
      createCollection(
        "Dummy-collection-" + roomDetails.room_id,
        roomDetails.room_id
      );
    } else {
      window.location.href =
        "/scene_builder/" +
        roomDetails.customer_dummy_scene_id +
        "?customer_dummy_scene=true";
    }
  };

  const createCollection = (collectionName, roomId) => {
    const payload = {
      collection_name: collectionName,
      username: localStorage.getItem("username"),
    };

    if (companyId != undefined) {
      payload.company_id = companyId;
    }

    axios
      .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
      .then((res) => {
        addRoomToCollection(res.data.collection_id, roomId);
      })
      .catch(() => {
        setDummySceneError(true);
      });
  };

  const addRoomToCollection = (collectionId, roomId) => {
    const payload = {
      collection_id: collectionId,
      room_id: roomId,
      username: localStorage.getItem("username"),
    };
    axios
      .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
      .then((res) => {
        createScene(collectionId, roomId);
      })
      .catch(() => {
        console.log("Room could not be added to collection ", collectionId);
        setDummySceneError(true);
      });
  };

  const createScene = (collectionId, roomId) => {
    {
      const payload = {
        scene_name: "Dummy-scene-" + roomId,
        collection_id: collectionId,
        room_id: roomId,
        is_hidden: true,
        username: localStorage.getItem("username"),
      };

      if (companyId != undefined) {
        payload.company_id = companyId;
      }

      axios
        .post(ENVIRONMENT.UPDATE_SCENE, payload)
        .then((res) => {
          const sceneId = res.data["scene_id"];

          let update_payload = {
            room_id: roomId,
            customer_dummy_scene_id: sceneId,
          };
          axios
            .post(ENVIRONMENT.UPDATE_ROOM, update_payload)
            .then((response) => {
              setDummySceneSetup(false);
              window.location.href =
                "/scene_builder/" + sceneId + "?customer_dummy_scene=true";
            });
        })
        .catch(() => {
          setDummySceneError(true);
        });
    }
  };

  const showSpaceVariationModal = () => {
    setSpaceColorVariationModal(true);
  };


  const createVariantMenu = useMemo(
    () => (
      <Menu
        title={
          <Tooltip
            title={
              "You can make adjustments like, change the colour, size, textures,  etcetera and request a variation of this space model."
            }
          >
            <span style={{ cursor: "pointer" }}>Space Variation</span>
          </Tooltip>
        }
        style={{width: "180px"}}
      >
        <Menu.Item
          onClick={() => {
            showSpaceVariationModal();
          }}
          className='manrope f-14'
          key={0}
        >
          <Tooltip
            title={
              "You can make adjustments like color and texture changes through the configurator tool and request a variation of this space model."
            }
          >
            <span style={{ cursor: "pointer" }}>Color & Texture Variation</span>
          </Tooltip>
        </Menu.Item>
        {[5].includes(parseInt(roomDetails.model_status)) && (
          <Menu.Item key={1} onClick={openVariationModal} className='manrope f-14'>
            <Tooltip
              title={
                "You can make adjustments like size etcetera and request a variation of this space model."
              }
            >
              <span style={{ cursor: "pointer" }}>Other Variations</span>
            </Tooltip>
          </Menu.Item>
        )}
      </Menu>
    ),
    [roomDetails.model_status, params, savedID, roomDetails, spaceDetails]
  );

  return (
    <>
    <div id="components-dropdown-demo-dropdown-button mr-8">
        <Dropdown
            overlay={createVariantMenu}
            trigger={["click"]}
            placement="bottomRight"
        >
            <Button className="product-btn dark-blue f-16 br-4" style={{width: "180px",height: "42px"}}>
               Space Variant
            </Button>
        </Dropdown>
    </div>

      <Modal visible={dummySceneSetup} closable={false} footer={null}>
        <div>
          <span>
            <LoadingOutlined className="loading-center mb-20" />
          </span>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-18 black-00 w-600 capitalize"
          >
            Please Wait!
          </h2>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-16 black-00 w-500"
          >
            We are setting up the space for you.
          </h2>
        </div>
      </Modal>
      <Modal visible={dummySceneError} closable={true} footer={null}>
        <div>
          <span>
            <LoadingOutlined className="loading-center mb-20" />
          </span>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-18 black-00 w-600 capitalize"
          >
            Error Occurred
          </h2>
          <h2
            style={{ textAlign: "center" }}
            className="manrope f-16 black-00 w-500"
          >
            An error occurred while setting up the space. Please try again
            later.
          </h2>
        </div>
      </Modal>
      <SpaceColorVariationModal
        openVariationTool={() =>
          handleCustomerDummyScene()
        }
        spaceColorVariationModal={spaceColorVariationModal}
        setSpaceColorVariationModal={setSpaceColorVariationModal}
      />
      <OtherVariationsAction
        params={params}
        savedID={savedID}
        roomDetails={roomDetails}
        spaceDetails={spaceDetails}
        setVariationModal={setVariationModal}
        variationModal={variationModal}
      />
      {userProjects ? (
        <AddEntityToProject
          entityId={selectedSpace}
          entityType="Room"
          modalVisible={projectModalVisible}
          setModalVisible={setProjectModalVisible}
          projects={userProjects}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SpaceVariantAction;
