import React, { useState, useEffect } from "react";
import AdminMainLayout from "../AdminMainLayout";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import ArtistTestModelViewer from "../ArtistTestModelViewer/ArtistTestModelViewer";
import ModelingStats from "../ModelingStats/ModelingStats";
import ModelInformation from "../ModelInformation/ModelInformation";
import TestArtistDetails from "../TestArtistDetails/TestArtistDetails";
import TestScoreDetails from "../TestScoreDetails/TestScoreDetails";
import TestModelStaticGuidelinesCard from "../TestModelStaticGuidelinesCard/TestModelStaticGuidelinesCard";
import TestHeading from "./TestHeading";
import { Redirect } from "react-router";
import {
  Row,
  Col,
  Modal,
  Layout,
  Button,
  Form,
  Input,
  Tabs,
  InputNumber,
  Popover,
  message,
} from "antd";
import { LoadingOutlined, ArrowRightOutlined, ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import DottedLoader from "../../DottedLoader";
import EditSkillMatrixModal from "../EditSkillMatrixModal/EditSkillMatrixModal";
import ArtistMainLayout from "../../ArtistComponents/ArtistMainLayout";

const { Footer, Content } = Layout;
const { TextArea } = Input;
const { TabPane } = Tabs;

function ArtistTestDetails(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [geometryAccuracy, setGeometryAccuracy] = useState(null);
  const [vrayAccuracy, setVrayAccuracy] = useState(null);
  const [pbrAccuracy, setPbrAccuracy] = useState(null);
  const [colorAccuracy, setColorAccuracy] = useState(null);
  const [dimensionalAccuracy, setDimensionalAccuracy] = useState(null);
  const [uvAccuracy, setUvAccuracy] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [testID, setTestID] = useState(null);
  const [renders, setRenders] = useState([]);
  const [size, setSize] = useState("small");
  const [isLoading, setIsLoading] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [skype, setSkype] = useState(null);
  const [country, setCountry] = useState(null);
  const [yearsOfExperience, setYearsOfExperience] = useState(null);
  const [proposedHourlyRate, setProposedHourlyRate] = useState(null);
  const [scoredDimensionalAccuracy, setScoredDimensionalAccuracy]= useState(null);
  const [scoredVrayAccuracy, setScoredVrayAccuracy] = useState(null);
  const [scoredPbrAccuracy, setScoredPbrAccuracy] = useState(null);
  const [scoredUVAccuracy, setScoredUVAccuracy]= useState(null);
  const [scoredColorAccuracy, setScoredColorAccuracy]= useState(null);
  const [scoredGeometryAccuracy, setScoredGeometryAccuracy]= useState(null);
  const [scoredAdditionalNotes, setScoredAdditionalNotes]= useState(null);
  const [proposedWorkableHoursPerWeek, setProposedWorkableHoursPerWeek] =
    useState(null);
  const [portfolioFiles, setPortfolioFiles] = useState([]);
  const [portfolioUrl, setportfolioUrl] = useState(null);
  const [readingEnglishProficiency, setReadingEnglishProficiency] =
    useState(null);
  const [writingEnglishProficiency, setWritingEnglishProficiency] =
    useState(null);
  const [listeningEnglishProficiency, setListeningEnglishProficiency] =
    useState(null);
  const [billingType, setBillingType] = useState(null);
  const [compareView, setCompareView] = useState(false);
  const [referenceImages, setReferenceImages] = useState([]);
  const [fbxGenerated, setFbxGenerated] = useState(null);
  const [glbGenerated, setGlbGenerated] = useState(null);
  const [usdzGenerated, setUsdzGenerated] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [agencyBillingRate, setAgencyBillingRate] = useState(null)
  const [agencyBillingType, setAgencyBillingType] = useState(null)
  const [testArtistSkills, setTestArtistSkills] = useState([])
  const [platform, setPlatform] = useState('aws')
  const {isSuperArtist} = props;

  useEffect(() => {
    const payload = {
      output: 'serial'
    };
    axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
        setAllCategories(res.data);
    });

      axios.post(ENVIRONMENT.SKILL_GET_BATCH, {}).then((res) => {
        setSkillList(res.data);
      });
   }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const submitTestScore = () => {
    if (
      colorAccuracy === null ||
      dimensionalAccuracy === null ||
      geometryAccuracy === null ||
      vrayAccuracy === null ||
      pbrAccuracy === null
    ) {
      setIsError(true);
      setErrorMessage("Please fill all the required metrics.");
    } else {
      setIsError(false);
      setErrorMessage("");
      const payload = {
        additional_notes: additionalNotes,
        color_accuracy: colorAccuracy,
        dimensional_accuracy: dimensionalAccuracy,
        geometry_accuracy: geometryAccuracy,
        vray_accuracy : vrayAccuracy,
        pbr_accuracy : pbrAccuracy,
        uv_accuracy: uvAccuracy,
        action: "update_artist_test_admin",
        test_id: testID,
        status: "successfully_scored"
      };
      setIsLoading(true);
      axios.post(ENVIRONMENT.TEST_ARTIST_CONTROLLER, payload).then((res) => {
        setIsLoading(false);
        console.log(res.data);
        message.info("You have successfully scored the test")
        setTimeout(() => {
          if (isSuperArtist) {
            setRedirectURL("/artist_test_list")
          } else {
            setRedirectURL("/admin_artist_list");
          }
        }, 1000)
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const changeAdditionalNotes = (event) => {
    setAdditionalNotes(event.target.value);
  };

  useEffect(() => {
    const url = window.location.href;
    const id = url.split("/")[4];
    setTestID(id);
    console.log("ID", id);
    const payload = {
      test_id: id,
    };
    setIsFetchingData(true);
    axios.post(ENVIRONMENT.GET_ARTIST_TEST, payload).then((res) => {
      console.log(res.data);
      setIsFetchingData(false);
      if (res.data.perspective_renders != []){
        let perspective = [];
        for (let i = 0; i<res.data.perspective_renders.length; i++ ){
          if (!res.data.perspective_renders[i].includes('.exr') && !res.data.perspective_renders[i].includes('.tiff')){
            perspective.push(res.data.perspective_renders[i])
          }
        }
        setRenders(perspective)
      }
      // setRenders(res.data.perspective_renders);
      setStatus(res.data.status);
      setUsername(res.data.username);
      setSkype(res.data.skype);
      setCountry(res.data.country);
      setYearsOfExperience(res.data.years_of_experience);
      setProposedHourlyRate(res.data.proposed_hourly_rate);
      setProposedWorkableHoursPerWeek(res.data.proposed_working_hours_per_week);
      setPortfolioFiles(res.data.portfolio_files);
      setportfolioUrl(res.data.portfolio_url);
      setReadingEnglishProficiency(res.data.reading_english_proficiency);
      setWritingEnglishProficiency(res.data.writing_english_proficiency);
      setListeningEnglishProficiency(res.data.listening_english_proficiency);
      setScoredPbrAccuracy(res.data.pbr_accuracy);
      setScoredVrayAccuracy(res.data.vray_accuracy);
      setScoredAdditionalNotes(res.data.additional_notes);
      setScoredColorAccuracy(res.data.color_accuracy);
      setScoredDimensionalAccuracy(res.data.dimension_accuracy);
      setScoredGeometryAccuracy(res.data.geometry_accuracy);
      setScoredUVAccuracy(res.data.uv_accuracy);
      setBillingType(res.data.billing_type);
      setGlbGenerated(res.data.glb_generated);
      setFbxGenerated(res.data.fbx_generated);
      setUsdzGenerated(res.data.usdz_generated);
      setTestArtistSkills(res.data.skills)
      if(res.data['invite_details']){
        if(res.data['invite_details']['hourly_rate'])
          setAgencyBillingRate(res.data['invite_details']['hourly_rate'])
        if(res.data['invite_details']['billing_type'])
          setAgencyBillingType(res.data['invite_details']['billing_type'])
      }
    });
  }, []);

  const showEditModal = (visible) => {
    setIsEditModalOpen(visible);
  };

  // Get Model Reference Images given to the artist
  useEffect(() => {
    if (testID) {
    const payload = {
      test_id: testID,
    };
    axios
      .post(ENVIRONMENT.GET_TEST_ARTIST_PRODUCT_DETAILS, payload)
      .then((res) => {
        console.log(res.data);
        const refImages = [];
        if(res.data.product_manuals){
          refImages.push(...res.data.product_manuals)
        }
        refImages.push(...res.data.product_photos);
        setReferenceImages(refImages);
        setPlatform(res.data.platform);
      });
  }
  }, [testID]);


  const testDetails = (
    <>
      <Row type="flex" justify="space-around" style={{ marginTop: "12px" }}>
        <Col span={22}>
          <Layout className="mb-90">
            <Content>
              {renders.length > 0 ? (
                <div>
                  <Button
                    className={`modal-okay square comparison-btn admin-compare ${
                      compareView ? `comp-view` : ``
                    }`}
                    onClick={() => setCompareView(!compareView)}
                  >
                    <span className="modal-okay-text">
                      {compareView == false ? (
                        <span>
                          Compare With Reference Images{" "}
                              <ArrowRightOutlined />
                        </span>
                      ) : (
                        <span>
                          <ArrowLeftOutlined /> Back to Original View
                        </span>
                      )}
                    </span>
                  </Button>
                </div>
              ) : (
                ""
              )}
              {compareView ? (
                <>
                  <Row>
                    <Col span={12}>
                      <ArtistTestModelViewer
                        test_id={testID}
                        perspective_renders={renders}
                        adminScrollCheck={true}
                        compareView={true}
                        glbGenerated={glbGenerated}
                        fbxGenerated={fbxGenerated}
                        usdzGenerated={usdzGenerated}
                        platform={'aws'}
                      />
                    </Col>
                    <Col span={12}>
                      <TestModelStaticGuidelinesCard
                        referenceImages={referenceImages}
                        platform={platform}
                        adminScrollCheck={true}
                        compareView={true}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <ArtistTestModelViewer
                  test_id={testID}
                  perspective_renders={renders}
                  adminScrollCheck={false}
                  compareView={false}
                  glbGenerated={glbGenerated}
                  fbxGenerated={fbxGenerated}
                  usdzGenerated={usdzGenerated}
                  platform={'aws'}
                />
              )}
  
              <TestHeading />
              <Tabs
                defaultActiveKey="1"
                size={size}
                tabBarStyle={{ textAlign: "center" }}
                animated={false}
              >
                {status === "successfully_scored" ? (
                  <>
                    <TabPane tab="Test Score Details" key="1">
                      <TestScoreDetails
                        geometry_accuracy={scoredGeometryAccuracy}
                        uv_accuracy={scoredUVAccuracy}
                        color_accuracy={scoredColorAccuracy}
                        dimensional_accuracy={scoredDimensionalAccuracy}
                        vray_accuracy={scoredVrayAccuracy}
                        pbr_accuracy={scoredPbrAccuracy}
                        additional_notes={scoredAdditionalNotes}
                      />
                    </TabPane>
                    <TabPane tab="Artist Details" key="2">
                      <TestArtistDetails
                        is_active={true}
                        testID={testID}
                        username={username}
                        skype={skype}
                        billingType={billingType}
                        country={country}
                        yearsOfExperience={yearsOfExperience}
                        proposedHourlyRate={proposedHourlyRate}
                        proposedWorkableHoursPerWeek={
                          proposedWorkableHoursPerWeek
                        }
                        portfolioFiles={portfolioFiles}
                        portfolioUrl={portfolioUrl}
                        readingEnglishProficiency={
                              readingEnglishProficiency
                            }
                        writingEnglishProficiency={
                              writingEnglishProficiency
                            }
                        listeningEnglishProficiency={
                              listeningEnglishProficiency
                            }
                      />
                    </TabPane>
                    <TabPane tab="Modeling Stats" key="3">
                      <ModelingStats is_active={true} testID={testID} />
                    </TabPane>
                    <TabPane tab="Model Information" key="4">
                      <ModelInformation is_active={false} testID={testID} />
                    </TabPane>
                  </>
                ) : (
                  <>
                    <TabPane tab="Modeling Stats" key="1">
                      <ModelingStats is_active={true} testID={testID} />
                    </TabPane>
                    <TabPane tab="Model Information" key="2">
                      <ModelInformation is_active={false} testID={testID} />
                    </TabPane>
                  </>
                )}
              </Tabs>
            </Content>
          </Layout>
  
          <Layout className="footer-layout">
            <Footer className="footer footer-container">
              {status === "successfully_scored" ? (
                <Button
                  className="modal-okay square font-14 float-right"
                  key="set_hourly_rate"
                  onClick={showEditModal}
                >
                  Set Skill Level and Rate
                </Button>
              ) : (
                <Button
                  className="modal-okay square font-14 float-right"
                  key="submit_test"
                  onClick={showModal}
                >
                  Submit Score
                </Button>
              )}
            </Footer>
          </Layout>
        </Col>
      </Row>
      <Modal
        title="Submit Score"
        className=""
        maskClosable={false}
        onCancel={handleCancel}
        visible={isModalOpen}
        width={"40%"}
        centered={true}
        footer={[
          <div className="justify-in-end" key="submit_test">
            <Button
              className="modal-okay square font-14"
              disabled={isLoading}
              onClick={submitTestScore}
            >
              {redirectURL === "/admin_artist_list" ?
                <Redirect to={redirectURL} />
              : redirectURL === "/artist_test_list" ? 
                <Redirect to={redirectURL} /> 
              : 
              ""
              }
              Confirm Submission
              {isLoading ? <LoadingOutlined spin /> : ""}
            </Button>
          </div>,
        ]}
      >
        <Form
          onFinishFailed={(e) => console.log(e)}
          className="modal-form mb-0"
          layout="vertical"
        >
          <div style={{marginBottom: 10}}>
            <span style={{color: 'red'}}>Note: If rating is less than 5 for any aspect of the test. The artist will be considered failed.</span>
          </div>
          <div className="items-flex">
            <Form.Item name="geometry_accuracy" className="w-49">
              <div className="manrope w-500 f-14 text-grey">
                <p>
                  <span style={{ color: "#E54547" }}>*&nbsp;</span>Geometry
                  Accuracy
                </p>
              </div>
              <div className="input-number-test-admin">
                <InputNumber
                  name="Geometry"
                  min={0}
                  max={10}
                  onChange={(val) => setGeometryAccuracy(val)}
                  placeholder="Rate out of 10"
                />
              </div>
            </Form.Item>
            <Form.Item name="uv" className="w-49">
              <div className="manrope w-500 f-14 text-grey">
                <p>
                  <span style={{ color: "#E54547" }}>*&nbsp;</span>UV
                      Quality
                </p>
              </div>
              <div className="input-number-test-admin">
                <InputNumber
                  name="UV"
                  min={0}
                  max={10}
                  onChange={(val) => setUvAccuracy(val)}
                  placeholder="Rate out of 10"
                />
              </div>
            </Form.Item>
          </div>
          <div className="items-flex">
            <Form.Item name="vray_accuracy" className="w-49">
              <div className="manrope w-500 f-14 text-grey">
                <p>
                  <span style={{ color: "#E54547" }}>*&nbsp;</span>VRAY
                      Accuracy
                </p>
              </div>
              <div className="input-number-test-admin">
                <InputNumber
                  name="Vray"
                  min={0}
                  max={10}
                  onChange={(val) => setVrayAccuracy(val)}
                  placeholder="Rate out of 10"
                />
              </div>
            </Form.Item>
            <Form.Item name="pbr_accuracy" className="w-49">
              <div className="manrope w-500 f-14 text-grey">
                <p>
                  <span style={{ color: '#E54547' }}>*&nbsp;</span>PBR
                      Accuracy
                </p>
              </div>
              <div className="input-number-test-admin">
                <InputNumber
                  name="PBR"
                  min={0}
                  max={10}
                  onChange={(val) => setPbrAccuracy(val)}
                  placeholder="Rate out of 10"
                />
              </div>
            </Form.Item>
          </div>
  
          <div className="items-flex">
            <Form.Item name="color_accuracy" className="w-49">
              <div className="manrope w-500 f-14 text-grey">
                <p>
                  <span style={{ color: "#E54547" }}>*&nbsp;</span>Color
                      Accuracy
                </p>
              </div>
              <div className="input-number-test-admin">
                <InputNumber
                  name="Color"
                  min={0}
                  max={10}
                  onChange={(val) => setColorAccuracy(val)}
                  placeholder="Rate out of 10"
                />
              </div>
            </Form.Item>
            <Form.Item name="dimension_accuracy" className="w-49">
              <div className="manrope w-500 f-14 text-grey">
                <p>
                  <span style={{ color: "#E54547" }}>*&nbsp;</span>
                  Dimensional Accuracy
                </p>
              </div>
              <div className="input-number-test-admin">
                <InputNumber
                  name="Dimensional"
                  min={0}
                  max={10}
                  onChange={(val) => setDimensionalAccuracy(val)}
                  placeholder="Rate out of 10"
                />
              </div>
            </Form.Item>
          </div>
  
          <Form.Item name="additional_notes">
            <div className="manrope w-500 f-14 text-grey">
              <p>
                Additional Notes &nbsp;
                <Popover
                  placement="topLeft"
                  content="These notes will be visible to the artist."
                >
                  <InfoCircleOutlined style={{ color: '#276DD7' }}/>
                </Popover>
              </p>
            </div>
            <TextArea
              placeholder="Any additional notes? Type them here"
              value={additionalNotes}
              rows={2}
              onChange={changeAdditionalNotes}
            ></TextArea>
          </Form.Item>
        </Form>
        {isError ? (
          <div className="manrope w-500 f-12">
            <p style={{ color: "#E54547" }}>{errorMessage}</p>
          </div>
        ) : (
          ""
        )}
      </Modal>
      <EditSkillMatrixModal
        isTestArtist={true}
        inputInviteDetails={false}
        skill_list={skillList}
        visible={isEditModalOpen}
        set_visibility={showEditModal}
        artist_skills={testArtistSkills}
        all_categories={allCategories}
        proficient_categories={allCategories}
        billing_type={agencyBillingType || billingType} // pass agency billing details if they are set else pass the artist propsed billing details
        billing_rate={agencyBillingRate || proposedHourlyRate}
        username={username}
        hideBillingFormInput={agencyBillingRate != null && agencyBillingType != null}
        testID={testID}
        skype={skype}
      />
    </>
  );

  return (
    <>
      {isFetchingData || (status === "" || status == null) ? (
        <DottedLoader />
      ) : (
        <>
          { !isSuperArtist ? (<AdminMainLayout selected="8">
            {testDetails}
          </AdminMainLayout>):
           status==="artist_completed" ?
            (<ArtistMainLayout selected="10"> {testDetails}</ArtistMainLayout>) 
            :
            <Redirect to="/artist_test_list" />
          }
        </>
      )}
    </>
  );
}

export default ArtistTestDetails;
