import React from 'react';
import { LockOutlined } from '@ant-design/icons';

const LoadingContainer = (props) => {
    return (<div className="load-container">
        {props.icon}
        <h3 className="load-message large">{props.heading}</h3>
        <h3 className="load-message">{props.sub_text}</h3>
    </div>)
}

export default function EntityAccessDenied ({entityType}) {

    return (
        <LoadingContainer
            icon={<LockOutlined className='on-start-load loading-icon' size="large" />}
            heading={"Oops! You don't have access!"}
            sub_text={`Please request the owner of this ${entityType} to grant you access.`} 
        />
    );
}