import React from 'react';
import PropTypes from 'prop-types';
import { changePassword } from 'react-cognito';
import CustomHeader from "../../CustomHeader";
import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Input, Button } from "antd";
const { Header } = Layout;

class ChangePasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      oldPassword: '',
      newPassword: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { store } = this.context;
        const state = store.getState();
        const user = state.cognito.user;
        event.preventDefault();
        changePassword(user, this.state.oldPassword, this.state.newPassword).then(
            () => this.setState({ error: 'Password changed' }),
            error => this.setState({ error }));
      }
    });
  }

  changeOldPassword = (event) => {
    this.setState({ oldPassword: event.target.value });
  }

  changeNewPassword = (event) => {
    this.setState({ newPassword: event.target.value });
  }

  render = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Header className="header">
          <CustomHeader></CustomHeader>
        </Header>
        <Row type="flex" justify="space-around">
          <Col span={8}>
            <Form onSubmit={this.onSubmit} className="login-form input-form password-frm" style={{marginTop: 32}}>
              <h3 className="text-center text-login-logo"> <span>Change Password</span> </h3>
              <Form.Item>
                {getFieldDecorator('old_password', {
                  rules: [{ required: true, message: 'Please input your old password!' }],
                })(
                    <Input
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Old Password"
                        onChange={this.changeOldPassword}
                    />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('New password', {
                  rules: [{ required: true, message: 'Please input your New Password!' }],
                })(
                    <Input
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="New Password"
                        onChange={this.changeNewPassword}
                    />,
                )}
              </Form.Item>
              <Form.Item>
                <Button type="danger" htmlType="submit" className="login-form-button">
                  Set new password
                </Button>
                <span className="text-danger"> {this.state.error}</span>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );

  }
}
ChangePasswordForm.contextTypes = {
  store: PropTypes.object,
};

export default Form.create({ name: 'change_password_form' })(ChangePasswordForm);
