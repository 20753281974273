import React, { Component } from "react";
import {
   ZoomInOutlined,
   ZoomOutOutlined,
   RedoOutlined
} from '@ant-design/icons';
import {
  Button, Tooltip
} from 'antd';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

class ImageZoomAdmin extends Component {
   state = {
            image: this.props.image,
            height: window.innerHeight-202,
            width: "",
            limitToBounds: true,
            panningEnabled: true,
            transformEnabled: true,
            pinchEnabled: true,
            limitToWrapper: true,
            disabled: false,
            dbClickEnabled: true,
            lockAxisX: false,
            lockAxisY: false,
            velocityEqualToMove: true,
            enableWheel: true,
            enableTouchPadPinch: true,
            enableVelocity: true,
            limitsOnWheel: false,
            minScale: 1,
            maxScale: 8,
        } 

    render() {
        const {
            limitToBounds,
            panningEnabled,
            transformEnabled,
            pinchEnabled,
            limitToWrapper,
            disabled,
            dbClickEnabled,
            lockAxisX,
            lockAxisY,
            velocityEqualToMove,
            enableWheel,
            enableTouchPadPinch,
            enableVelocity,
            limitsOnWheel,
            minScale,
            maxScale,
          } = this.state;

        return (
        <TransformWrapper
            options={{
                limitToBounds,
                transformEnabled,
                disabled,
                limitToWrapper,
                minScale,
                maxScale,
            }}
            pan={{
                disabled: !panningEnabled,
                lockAxisX,
                lockAxisY,
                velocityEqualToMove,
                velocity: enableVelocity,
            }}
            pinch={{ disabled: !pinchEnabled }}
            doubleClick={{ disabled: !dbClickEnabled }}
            wheel={{
            wheelEnabled: enableWheel,
            touchPadEnabled: enableTouchPadPinch,
            limitsOnWheel,
            }}
        >
            {({
                zoomIn,
                zoomOut,
                resetTransform,
                setDefaultState,
                positionX,
                positionY,
                scale,
                previousScale,
                
                options: { limitToBounds, transformEnabled, disabled },
                ...rest
            }) => (
            <React.Fragment>
                {this.props.notShowToolButtons == undefined ?
                <div className={this.props.compare ? "tools compare" : (this.props.material ? "tools material-tool" : this.props.material_modal ? "tools material-modal" : "tools")} style={{marginRight: 10}}>
                    <Tooltip title={<span className="manrope f-14 white">Zoom In</span>} placement="bottom">
                        <Button className="admin-tool-btn" icon={<ZoomInOutlined className="admin-tool-icon"/>} onClick={zoomIn}/>
                    </Tooltip>
                    <Tooltip title={<span className="manrope f-14 white">Zoom Out</span>} placement="bottom">
                        <Button className="admin-tool-btn" icon={<ZoomOutOutlined className="admin-tool-icon"/>} onClick={zoomOut}/>
                    </Tooltip>
                    <Tooltip title={<span className="manrope f-14 white">Reset Zoom</span>} placement="bottom">
                        <Button className="admin-tool-btn" icon={<RedoOutlined className="admin-tool-icon"/>} onClick={resetTransform}/>
                    </Tooltip>
                </div> : ""}
                <TransformComponent style={{margin: "auto"}}>
                    <img src={this.props.image && (this.props.image.toLowerCase().includes('jpg') || this.props.image.toLowerCase().includes('jpeg') || this.props.image.toLowerCase().includes('png') || this.props.image.toLowerCase().includes('svg')) ?
                    this.props.image: this.props.image ? '/img/file-icon.png': ''}
                    style={this.props.custom_class}
                    className={this.props.className}
                    alt="Zoomed Image" />
                </TransformComponent>
            </React.Fragment>
            )}
        </TransformWrapper>
        );
    }
    }

export default ImageZoomAdmin;