import React, { useState, useEffect } from "react";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import {
    Row,
    Col,
    Modal,
    Form,
    Table,
    Input,
    Select,
    Button,
    message,
} from "antd";
import FileConstants from "../../../../FileConstants";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;
const formRef = React.createRef();
const output_filetypes = ['tiff', 'jpg', 'png']
const naming_conventions = ['Skyline SKU', 'TCIN', 'UPC']

function PresetManagement(props) {
    const [lightingRigs, setLightingRigs] = useState([]);
    const [customerPresets, setCustomerPresets] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [cameraPresets, setCameraPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        let payload = {
            company_id: FileConstants.COMPANY_ID,
        };
        fetchAndSetCustomerPresetData()
        axios.post(ENVIRONMENT.LIGHTING_RIG_GET_BATCH, payload).then((res) => {
            if (res && res["data"]) {
                setLightingRigs(res["data"]);
            }
        });

        // get list of camera presets
        axios.post(ENVIRONMENT.LIST_CAMERA_PRESETS, payload).then((res) => {
            if (res && res["data"]) {
                setCameraPresets(res.data.data);
            }
        });
    }, []);

    const fetchAndSetCustomerPresetData = () => {
        let payload = {
            company_id: FileConstants.COMPANY_ID,
        };
        setDataLoading(true);
        axios.post(ENVIRONMENT.CUSTOMER_PRESET_GET_BATCH, payload).then((res) => {
            if (res.data && res.data.statusCode == 200) {
                setCustomerPresets(res.data.data);
                setDataLoading(false);
            }
        });
    }

    const getImageResolutionString = (customer_preset) => {
        let res = "";
        res =
            customer_preset["img_width"] +
            " x " +
            customer_preset["img_height"] +
            " @ " +
            customer_preset["img_dpi"] +
            " dpi";
        return res;
    };

    const getRigUrl = (rig_name) => {
        let url = ''
        lightingRigs.map((item) => {
            if (item['name'] == rig_name) {
                url = item['rig_file']
            }
        })

        return url
    }

    const getCameraPresetId = (camera_preset_name) => {
        let preset_id = ''
        cameraPresets.map((item) => {
            if (item['name'] == camera_preset_name) {
                preset_id = item['camera_preset_id']
            }
        })
        return preset_id
    }


    const updatePreset = (values) => {
        console.log('Update preset = ', values)
        let payload = {
            'customer_username': values['customer_username'],
            'preset': {
                "img_height": values["img_height"],
                "img_width": values["img_width"],
                "img_format": values["img_format"],
                "img_dpi": values["img_dpi"],
                "rig_name": values["rig_name"],
                "rig_url": getRigUrl(values["rig_name"]),
                "naming_convention": values["naming_convention"]
            },
            'company_id': FileConstants.COMPANY_ID

        }

        if(selectedPreset){
            payload['action'] = 'edit'
        }
        else{
            payload['action'] = 'create'
        }

        console.log('payload = ', payload)
        setButtonLoader(true);
        axios.post(ENVIRONMENT.CUSTOMER_PRESET_UPDATE, payload)
            .then(res => {
                if (res && res['data']['statusCode'] == 200) {
                    message.success(res['data']['body'])
                }
                else if(res && res['data']['statusCode'] == 400){
                    message.error(res['data']['error_message'])
                }
                setButtonLoader(false)
                setEditModalVisible(false)
                fetchAndSetCustomerPresetData()
                props.setRefreshData(true)
            })

    };


    const columns = [
        {
            title: "Customer",
            dataIndex: "customer_username",
            key: "customer_username",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Lighting Rig",
            dataIndex: "rig_name",
            key: "rig_name",
            render: (text, record) => (
                <span>{record["customer_preset"]["rig_name"]}</span>
            ),
        },
        {
            title: "Naming Convention",
            dataIndex: "naming_convention",
            key: "naming_convention",
            render: (text, record) => (
                <span>{record["customer_preset"]["naming_convention"]}</span>
            ),
        },
        {
            title: "Action",
            key: "edit",
            render: (text, record) => (
                <span
                    onClick={() => {
                        setEditModalVisible(true);
                        setSelectedPreset(record);
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                >
                    Edit Preset
                </span>
            ),
        },
    ];

    return (
        <div>
            <Button
                style={{float: 'right', marginBottom: 10}}
                className="modal-okay square font-12"
                onClick={() =>
                    {
                        setSelectedPreset(null);
                        setEditModalVisible(true)
                    }
                }
            >
                Add Customer
            </Button>

            <Table
                dataSource={customerPresets}
                columns={columns}
                bordered={true}
                loading={{ indicator: <LoadingOutlined />, spinning: dataLoading }}
            ></Table>

            <Modal
                visible={editModalVisible}
                closable={true}
                destroyOnClose={true}
                style={{ width: "80%", minWidth: "50vw" }}
                maskClosable={true}
                onCancel={() => {
                    setEditModalVisible(false);
                    setSelectedPreset(null);
                }}
                footer={[
                    <div className="justify-in-end">
                        <Button
                            className="modal-okay-gray square font-14"
                            onClick={() => {
                                setEditModalVisible(false);
                                setSelectedPreset(null);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={buttonLoader}
                            className="modal-okay square font-14"
                            htmlType="submit"
                            onClick={() =>
                                document.getElementById("configuration_form_submit").click()
                            }
                        >
                            Save&nbsp;{buttonLoader ? <LoadingOutlined /> : ""}
                        </Button>
                    </div>,
                ]}
            >
                <div style={{ padding: "16px 16px 24px 16px" }}>
                    <div
                        className="manrope f-20 black-14"
                        style={{ marginBottom: 16, textTransform: "capitalize" }}
                    >
                        {selectedPreset ? "Edit Customer Preset" : "Add Customer"}
                        <Form
                            ref={formRef}
                            name="material-form"
                            onFinish={updatePreset}
                            initialValues={{
                                customer_username: selectedPreset
                                    ? selectedPreset["customer_username"]
                                    : "",
                                img_width: selectedPreset ? selectedPreset['customer_preset']['img_width'] : "",
                                img_height: selectedPreset ? selectedPreset['customer_preset']['img_height'] : "",
                                img_dpi: selectedPreset ? selectedPreset['customer_preset']['img_dpi'] : "",
                                img_format: selectedPreset ? selectedPreset['customer_preset']['img_format'] : "",
                                naming_convention: selectedPreset ? selectedPreset['customer_preset']['naming_convention'] : "",
                                rig_name: selectedPreset ? selectedPreset['customer_preset']['rig_name'] : "",
                                camera_preset_name: selectedPreset ? selectedPreset['camera_preset_name'] : ""
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <div
                                        className="manrope f-14 black-55"
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                    >
                                        Customer Name
                                    </div>
                                    <Form.Item
                                        style={{ marginBottom: 32 }}
                                        name="customer_username"
                                        className="manrope f-14 w-600 black-55"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please type customer name",
                                            },
                                        ]}
                                    >
                                        <Input disabled={selectedPreset ? true : false} placeholder="Customer Name" className="manrope f-10 black-55 library-search"
                                            style={{ marginBottom: 15 }}>

                                        </Input>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <div
                                        className="manrope f-14 black-55"
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                    >
                                        Lighting Rig
                                    </div>
                                    <Form.Item
                                        style={{ marginBottom: 32 }}
                                        name="rig_name"
                                        className="manrope f-14 w-600 black-55"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select the lighting rig",
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select Lighting Rig"
                                            className="manrope f-12 black-55 tree-select-material"
                                            style={{ marginBottom: 15 }}
                                        >
                                            {lightingRigs.map((item) => {
                                                return <Option value={item['name']}>{item['name']}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <div
                                        className="manrope f-14 black-55"
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                    >
                                        Naming Convention
                                    </div>
                                    <Form.Item
                                        style={{ marginBottom: 32 }}
                                        name="naming_convention"
                                        className="manrope f-14 w-600 black-55"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select naming convention",
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select Naming Convention"
                                            className="manrope f-12 black-55 tree-select-material"
                                            style={{ marginBottom: 15 }}
                                        >
                                            {naming_conventions.map((item) => {
                                                return <Option value={item}>{item}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <div className="info-icon-text">
                                    <img className="align-icon fixed-height" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                                    You can set the Resolution, DPI and Image file type in Manage Product Presets
                                </div>
                                <Form.Item style={{ display: "none" }}>
                                    <Button
                                        id="configuration_form_submit"
                                        style={{ display: "none" }}
                                        className="modal-okay square font-14"
                                        htmlType="submit"
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>

                            </Row>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default PresetManagement;
