import React, { useState, useEffect } from 'react';
import { Input, Row, Col, Table, Button, Space, Empty } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import axios from 'axios';
import ENVIRONMENT from '../../../environments';
import FileConstants from '../../../FileConstants';
import { getReadableModelFormat, getModelTypeString } from "../Utilities";
import { LoadingOutlined } from '@ant-design/icons';
import "./index.scss"

let searchInput = ''
let cancelToken;
const SharedItemUsage = (props) => {

    const [loading, setLoading] = useState(true);
    const [sharedItemUsage, setSharedItemUsage] = useState([]);
    const [filters, setFilters] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(null);

    useEffect(() => {
        if (props.account_type && (props.company_id || props.username)) {
            setLoading(true);
            getSharedItemUsage();
        }
    }, [props]);

    const getRowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };


    const getAppliedFilterValue = (filter) => {
        // return already applied filter value or null
        const url = new URL(window.location);
        let value = null;
        value = url.searchParams.get(filter);
        if (value)
            value = value.split(',');
        return value;
    }

    //Set query params value with filter
    const setFilterQueryParams = (filters) => {
        setSelectedFilters(filters);
        const url = new URL(window.location);

        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                url.searchParams.set(key, value);
            }
            else if (url.searchParams.has(key)) {
                url.searchParams.delete(key);
            }
        }
        window.history.replaceState({}, '', url)
    }

    const getColumns = () => {
        return [
            {
                title: <span className="manrope f-14 black-14">Request Type</span>,
                dataIndex: 'request_type',
                key: 'request_type',
                filters: filters && filters.filter_request_type,
                defaultFilteredValue: getAppliedFilterValue('request_type'),
                onFilter: (value, record) => record.request_type === value,
                render: (text) => (
                    <span className="manrope f-12 black-14">{FileConstants.setRequestTypeText(text)}</span>),
            },
            {
                title: <span className="manrope f-14 black-14">Name</span>,
                dataIndex: 'name',
                key: 'name',
                ...getColumnSearchProps('name'),
                render: (text) => (
                    <span className="manrope f-12 black-14">{text}</span>),
            },
            {
                title: <span className="manrope f-14 black-14">ALL3D ID</span>,
                dataIndex: 'id',
                key: 'id',
                ...getColumnSearchProps('id'),
                render: (text, record) => (
                    <a className="manrope f-12" href={FileConstants.getRequestLinks(record, 'shared_items')} target="_blank">
                        {text}
                    </a>
                ),
            },
            {
                title: <span className="manrope f-14 black-14">Category</span>,
                dataIndex: 'category',
                key: 'category',
                filters: filters && filters.filter_categories,
                defaultFilteredValue: getAppliedFilterValue('category'),
                onFilter: (value, record) => record.category === value,
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
            },
            {
                title: <span className="manrope f-14 black-14">Item ID</span>,
                dataIndex: 'brand_id',
                key: 'brand_id',
                ...getColumnSearchProps('brand_id'),
                render: text => <span className="manrope f-12 black-14">{text || 'N/A'}</span>,
            },
            {
                title: <span className="manrope f-14 black-14">Last Modified</span>,
                dataIndex: 'last_modified',
                key: 'last_modified',
                render: text => <span className="manrope f-12 black-14">{text}</span>,
                sorter: (a, b) => new Date(a.last_modified) - new Date(b.last_modified),
            },
            {
                title: <span className="manrope f-14 black-14">Created On</span>,
                dataIndex: 'created_on',
                key: 'created_on',
                render: text => <span className="manrope f-12 black-14">{text}</span>,
                sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
            },
            {
                title: <span className="manrope f-14 black-14">Customer Username</span>,
                dataIndex: 'customer_username',
                key: 'customer_username',
                filters: filters && filters.filter_customers,
                defaultFilteredValue: getAppliedFilterValue('customer_username'),
                onFilter: (value, record) => record.customer_username === value,
                render: text => <span className="manrope f-12 black-14">{text}</span>,
            },

        ].filter(col => !col.hidden);
    }

    /*Get system usage according to entity type passed*/
    const getSharedItemUsage = () => {
        //Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Previous Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()
        let payload = {}
        payload.request_type = "shared_items";
        payload.action = 'get_details';
        payload.date_filter_type = props.date_filter_type;
        if (props.company_id != -1) {
            payload.company_id = props.company_id
        } else {
            payload.customer_username = props.username;
        }
        payload.start_date = props.start_date;
        payload.end_date = props.end_date;

        axios.post(ENVIRONMENT.GET_SYSTEM_USAGE, payload, { cancelToken: cancelToken.token })
            .then(res => {
                if (res.data) {
                    populateData(res.data);
                    setLoading(false);
                }
            }).catch(err => {
                console.log(err)
            });
    }


    /* Pass usage data and segregate data according to request types
    Prepare filters */
    const populateData = (data) => {
        let usage_data = [];
        let returned_values = {}
        let filter_values = {
            filter_categories_values: [],
            filter_customer_values: [],
            filter_status_values: [],
            filter_request_types_values: [],
        }
        let values = {
            categories_values: [],
            customer_values: [],
            status_values: [],
            request_types_values: [],
        };

        if (Object.keys(data).length > 0) {
            for (const [key, value] of Object.entries(data)) {
                if (key == 'shared_items') {
                    if (value.length > 0) {
                        value.map((arr) => {
                            usage_data = usage_data.concat(arr);
                            arr.map(ele => {
                                returned_values = createFilters(ele,
                                    filter_values,
                                    values);
                                values = returned_values.all_values
                                filter_values = returned_values.filters
                            });
                        });

                        setSharedItemUsage(usage_data);
                        break;
                    } else {
                        setSharedItemUsage([])
                    }
                }
            }
        }
        setSharedItemUsage(usage_data);
        if (usage_data.length > 0) {
            handleFilteringOptions(filter_values.filter_categories_values,
                filter_values.filter_customer_values,
                filter_values.filter_status_values,
                filter_values.filter_request_types_values,
                values);
        }
    }

    /* Prepare filter data according to request types */
    const createFilters = (data, filter_values, values) => {

        if (filter_values) {
            let request_type_value = data['request_type'];
            if (request_type_value != "" && filter_values.filter_request_types_values && !filter_values.filter_request_types_values.includes(request_type_value)) {
                filter_values.filter_request_types_values.push(request_type_value);
            }
            if (request_type_value != '') {
                values.request_types_values.push(request_type_value)
            }

            let category_value = data['category'];
            if (category_value != "" && filter_values.filter_categories_values && !filter_values.filter_categories_values.includes(category_value)) {
                filter_values.filter_categories_values.push(category_value);
            }
            if (category_value != '') {
                values.categories_values.push(category_value)
            }

            let status_value = data['entity_status'];
            if (status_value != "" && filter_values.filter_status_values && !filter_values.filter_status_values.includes(status_value)) {
                filter_values.filter_status_values.push(status_value);
            }
            if (status_value != '') {
                values.status_values.push(status_value)
            }

            let customer_value = data['customer_username'];
            if (customer_value != "" && filter_values.filter_customer_values && !filter_values.filter_customer_values.includes(customer_value)) {
                filter_values.filter_customer_values.push(customer_value);
            }
            if (customer_value != '') {
                values.customer_values.push(customer_value)
            }


            let filters = {
                filter_categories_values: filter_values.filter_categories_values,
                filter_request_types_values: filter_values.filter_request_types_values,
                filter_customer_values: filter_values.filter_customer_values,
                filter_status_values: filter_values.filter_status_values,
            }
            let all_values = {
                categories_values: values.categories_values,
                customer_values: values.customer_values,
                status_values: values.status_values,
                request_types_values: values.request_types_values,
            }
            return {
                filters,
                all_values
            }
        }

    }

    // Search in table functionality
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    defaultValue={getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        defaultFilteredValue: getAppliedFilterValue(dataIndex),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        }
    });

    //Search value according to filter
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    //Reset filter value
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    // Clear all filters when switching tabs
    const clearAllFilters = () => {
        searchInput = '';
        setSearchText('');
        setSearchedColumn('');
        if (selectedFilters) {
            const url = new URL(window.location);
            for (const [key, value] of Object.entries(selectedFilters)) {
                if (url.searchParams.has(key)) {
                    url.searchParams.delete(key);
                }
            }
            window.history.replaceState({}, '', url)
        }
        setSelectedFilters(null);
    };

    //Assign a model type to column
    const addVariationTypeForModelType = (model_type, data_record) => {
        let result = model_type;
        if (model_type == 'Change' && data_record['variation_type']) {
            result = result + ' (' + FileConstants.variationDict(data_record['variation_type']) + ')';
        }
        else if (result == '') {
            result = 'N/A';
        }
        return result;
    }


    //Get counts of filters and populate their data in filters
    const handleFilteringOptions = (filter_categories_values,
        filter_customer_values,
        filter_status_values,
        filter_request_types_values,
        values) => {

        let filter_categories_objects = [];
        let filter_customer_objects = [];
        let filter_status_objects = [];
        let filter_request_type_objects = [];

        filter_categories_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_customer_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_status_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_request_types_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });

        let counts = {
            category_count: getCounts(values.categories_values),
            customer_count: getCounts(values.customer_values),
            status_count: getCounts(values.status_values),
            request_type_count: getCounts(values.request_types_values),
        }

        filter_categories_values.forEach(filter_categories_value => {
            filter_categories_objects.push({
                text: `${filter_categories_value} (${counts['category_count'][filter_categories_value]})`,
                value: filter_categories_value,
            })
        });

        filter_customer_values.forEach(filter_customer_value => {
            filter_customer_objects.push({
                text: filter_customer_value,
                value: filter_customer_value,
            })
        });

        filter_status_values.forEach(filter_status_value => {
            filter_status_objects.push({
                text: `${FileConstants.MODEL_STATUS_STRING[filter_status_value]} (${counts['status_count'][filter_status_value]})`,
                value: filter_status_value,
            })
        });


        filter_request_types_values.forEach(filter_request_type_value => {
            filter_request_type_objects.push({
                text: `${FileConstants.setRequestTypeText(filter_request_type_value)} (${counts['request_type_count'][filter_request_type_value]})`,
                value: filter_request_type_value,
            })
        });



        let filters = {
            filter_categories: filter_categories_objects,
            filter_customers: filter_customer_objects,
            filter_status: filter_status_objects,
            filter_request_type: filter_request_type_objects,
        };

        setFilters(filters);
    }

    // Return count of requests
    const getCounts = (data) => {
        const counts = {};

        for (const num of data) {
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }

        return counts;
    }

    //Return default filter value
    const getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
        let filteredValue = getAppliedFilterValue(dataIndex);
        if (!selectedKeys)
            setSelectedKeys(filteredValue);
        return filteredValue;
    }

    return (
        <>{props.display_data ?
            <Row>
                <Col span={24}>
                    {loading ? <div className="justify-in-center" style={{ width: '100%' }}>
                        <LoadingOutlined style={{ fontSize: 32, marginTop: 20, marginBottom: 20 }} />
                    </div> :
                        <Row>
                            <Col span={24}>
                                <Table
                                    rowClassName={getRowClassName}
                                    tableLayout='fixed'
                                    onChange={
                                        (pagination, filters, sorter, extra) => {
                                            setFilterQueryParams(filters)
                                        }
                                    }
                                    columns={getColumns()}
                                    dataSource={sharedItemUsage} />
                            </Col>
                        </Row>}
                </Col>
            </Row>: 
            <Empty
             style={{ marginTop: 20, marginBottom: 20 }}
             description={"Billing data for this month does not exist yet."}
             className="manrope f-14"
         />}
        </>
    );

}

export default SharedItemUsage;
