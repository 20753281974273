import React, { useState, useEffect,useCallback }  from 'react';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Button, Radio, Modal, Tooltip, Popover, Steps, Checkbox } from 'antd';
import FileConstants from "../../../../FileConstants";
import ModelVariationRequest from '../ModelVariationRequest/ModelVariationRequest';
import MspWarningModal from '../MspWarningModal/MspWarningModal';
import DottedLoader from '../../DottedLoader';

const { Step } = Steps;
const allRequiredAssets = FileConstants.allRequiredAssets;
const highRequiredAssets = FileConstants.highRequiredAssets;
const lowRequiredAssets = FileConstants.lowRequiredAssets;
const ModelVariationRequestModal = (props) => {
    const [requiredAssets, setRequiredAssets] = useState([]);
    const [isMspUserWarningModal, setIsMspUserWarningModal] = useState(false);
    const [userModelType, setUserModelType] = useState('high_res');
    const [modelTypes, setModelTypes] = useState([]);
    useEffect(() => {
        if (props.product_details) {
            let need_to_model = props.product_details.need_to_model
            let model_type = props.product_details.model_type;
            let selected_model_type = "";
            if((need_to_model == "high_res" && model_type == "ar") || 
               (need_to_model == "ar" && model_type == "high_res") ){
                selected_model_type = "high_res_and_ar";
                setUserModelType('high_res_and_ar');
            }
            else if((need_to_model == "high_pbr" && model_type == "ar") ||
                (need_to_model == "ar" && model_type == "high_pbr")){
                selected_model_type = "high_pbr_and_ar";
                setUserModelType('high_pbr_and_ar');
            }
            else if(need_to_model && !model_type){
                selected_model_type = need_to_model;
                setUserModelType(need_to_model);
            }
            else if(!need_to_model && model_type){
                selected_model_type = model_type;
                setUserModelType(model_type);
            }
            else{
                selected_model_type = "high_res";
                setUserModelType('high_res');
            }     
            setModelTypes(selected_model_type.split("_and_"));
        }
    }, [props.variationType, props.product_details]);


    const handleWarningModalClose = () => {
        setIsMspUserWarningModal(false);
    }

    return  <Modal
    visible={props.variationModal}
    className="scrollable-modal"
    maskClosable={false}
    closable={false}
    destroyOnClose={true}
    onCancel={props.closeVariationModal}
    footer={[

    ]}>
    <div>
    {props.variationLoader ? (
            <LoadingOutlined className="loading-center mt-10 mb-20" />
            ) : <>
    <div style={{padding: "5% 5% 0 5%"}}>
        {
        (localStorage.getItem("is_msprovider") == 'true') && localStorage.getItem('managed_customer_username') &&
        <Row gutter={[12,15]}>
            <Col span={24}>
            <div className="custom-alert info">
            You are requesting this Product Model Variation for customer {localStorage.getItem('managed_customer_username')}
            <>{(localStorage.getItem('managed_company_name') != undefined) ? (' (Company: ' + localStorage.getItem('managed_company_name') + ')'):''}</>
            </div>
            </Col>
        </Row>
        }
        <div className="justify-space-between" style={{marginBottom: 20}}>
            <div className="manrope f-24 black-33 w-700">Request Product Model Variation&nbsp;
            <Tooltip
                title={<span className="manrope f-12 white">
                    You can make adjustements like, change the colour, size, textures,  etcetera and request a variation of  this model.
                </span>}>
                <InfoCircleOutlined style={{cursor: "pointer"}}/>
            </Tooltip>
            </div>
            <div className="manrope f-20 grey-77" style={{cursor:"pointer"}} onClick={props.closeVariationModal}>x</div>
        </div>
        {props.savedID != -1 ?
        <div className="note-bg-specs" style={{marginTop: 20,marginBottom: 20, width:"max-content"}}>
            <div className="manrope f-14 blue">
                Previous Progress Saved
            </div>
        </div>: ''}
        {(props.variationType == 'hardware' || props.variationType == 'other') && props.variationStep == 1 ?
        <div className="note-bg-specs" style={{marginTop: 20,marginBottom: 20, width:"max-content", padding: 10}}>
            <div className="manrope f-14 blue">
                This will be charged as a new product model.
            </div>
        </div>: ''}
        <Steps current={props.variationStep} className='step-class' style={{marginBottom: 20}}>
            <Step className={`manrope f-14 ${props.variationStep == 0 ? `blue`: `green`}`} title={<span>Variation Type</span>} />
            <Step className={`manrope f-14 ${props.variationStep == 0 ? `grey-6b`: `blue`}`} title="Model Details" />
        </Steps>
    </div>

        {props.variationStep == 0 ?
        <div style={{padding: "0% 5% 0 5%", overflowY: "scroll", height: 400}}>
            <p className="manrope f-14 black-55" style={{marginBottom: 5}}>
                Please Select Variation Type
            </p>
            <Row style={{width: "100%"}}>
                <Col span={24}>
                    <Radio.Group value={props.variationType}  onChange={props.onChangeVariationType}  className="model-type-radio justify-align-start" style={{marginBottom: 13,flexWrap:"wrap"}}>
                        {FileConstants.VARIATION_TYPES.map((type, index) => {
                        return (props.product_details &&
                        ((!FileConstants.HIDDEN_CATEGORY[props.product_details.category] && (props.product_details.parent_category && !FileConstants.HIDDEN_CATEGORY[props.product_details.parent_category]))
                        && type.value=='bedding') ||
                        (type.value == 'ar_conversion' &&  userModelType == "high_pbr_and_ar") ||
                        (type.value == 'high_conversion' && modelTypes.includes('high_res')) ?
                        '':
                        <Radio style={{marginBottom: 12,width: "23%",whiteSpace:"break-spaces"}} value={type.value}>
                            {/* <span style={{color: "#25D6A4"}} className='variation-price' >
                                ${type.price}
                            </span> */}
                            <span className="manrope f-14 black-55" >
                                {type.heading}
                                <div style={{marginTop: 12,marginBottom: 12}} className="manrope f-12 grey-77">
                                {
                                    type.value =='ar_conversion' ?
                                        <Radio.Group className='ar-radio' value={props.arVariationType} onChange={props.onChangeARVariationType}>
                                            { !modelTypes.includes('ar') && <Radio value={'ar'} >
                                                <span className='manrope f-12 black-33'>
                                                    For AR
                                                </span>
                                            </Radio>}
                                            { !modelTypes.includes("high_pbr") && <Radio value={'high_pbr'}>
                                            <span className='manrope f-12 black-33'>
                                                For Photography
                                            </span>
                                            </Radio>}
                                        </Radio.Group>
                                    :
                                    ''
                                }
                                    {type.text}&nbsp;
                                    {(type.value == 'hardware' || type.value == 'other') &&
                                    <span className='manrope f-12 black-33'>(will be treated as a new product model)</span>}
                                </div>
                                {type.image ?
                                  <Popover content={<img className="bedding-img image-150" src={type.image}/>}>
                                  <img className="bedding-img image-150" src={type.image}/>
                                </Popover>
                                  : ''}

                            </span>
                        </Radio>);
                        })}
                    </Radio.Group>
                </Col>
            </Row>
        </div>
        :
        <ModelVariationRequest
        requestStatus={props.requestStatus}
        cardStatus={props.cardStatus}
        variationType={props.variationType}
        arVariationType={props.arVariationType}
        setVariationStep={props.setVariationStep}
        model_depth={props.model_depth}
        model_width={props.model_width}
        model_height={props.model_height}
        variationSaved={props.variationSaved}
        savedID={props.savedID}
        variationCreated={props.variationCreated}
        product_id={props.product_id}
        product_details={props.product_details}
        /> }

    {props.variationStep == 0 ?
      <div className="stick-buttons">
        <div className="justify-space-between">
            <Button className="modal-okay-gray font-14 square" onClick={props.closeVariationModal}>
                Cancel
            </Button>
            <Button className="modal-okay font-14 square" onClick={() => props.setVariationStep(1)}>
                Proceed
            </Button>
        </div>
      </div>: ''}
      </>}
    </div>
    <MspWarningModal
        visible = {isMspUserWarningModal}
        handleModalClose = {handleWarningModalClose}
    />
  </Modal>
}



export default ModelVariationRequestModal;
