import React, { useState } from "react";
import "antd/dist/antd.css";
import { FilterOutlined } from "@ant-design/icons";
import { Select, Tooltip, Drawer } from "antd";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProjectConstants from "./Constants";

const { Option } = Select;

const Filter = (props) => {
  const {
    selectedReviewStatus,
    setSelectedReviewStatus,
    isReviewFilter,
    disabled = false
  } = props;
  const [isFilterModal, setIsFilterModal] = useState(false);
 
  const handleFilterModalClose = () => {
    setIsFilterModal(false);
  };

  const handleReviewStatusChange = (value) => {
    setSelectedReviewStatus(value);
  };

  return (
    <>
      <Tooltip
        title={<span className="manrope f-12 white">Apply Filters</span>}
      >
        <div
          className="icon-button-container ml-8"
          onClick={() => {
            !disabled &&
            setIsFilterModal(true);
          }}
        >
          <FilterOutlined />
        </div>
      </Tooltip>
      <Drawer
        width={360}
        visible={isFilterModal}
        onClose={handleFilterModalClose}
        placement="right"
      >
        <div style={{ padding: 20 }}>
          <h2 className="manrope f-18 black-14 w-700">Filters</h2>
          {isReviewFilter && (
            <div>
              <h5 className="manrope f-16 black-14" style={{ marginTop: 20 }}>
                Review status
              </h5>
              <Select
                className={`tree-select-material f-14 filter bg-light`}
                showSearch={true}
                value={selectedReviewStatus}
                onChange={handleReviewStatusChange}
                placeholder="Search and select review status"
                mode="multiple"
              >
                {ProjectConstants.review_status_filters?.map((category, index) => {
                    return (
                      <Option
                        key={category.label}
                        className="manrope f-12 select-view"
                        value={category.value}
                      >
                        {category.label}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(Filter));
