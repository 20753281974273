import {
    CREATE_STORE_PRODUCT,
    STORE_PRODUCT_FAILURE,
    RENDER_STORE_PRODUCT,
    FETCH_STORE_PRODUCT,
    STORE_PRODUCT_REJECT_SUCCESS,
    REJECT_STORE_PRODUCT,
    GET_SCANNED_STORE_PRODUCTS,
    STORE_PRODUCT_SUCCESS, UPDATE_STORE_PRODUCT, LIST_STORE_PRODUCT, LIST_FOR_STORE_PRODUCT,
  } from '../actions';
  
  const initialState = {
    product: {},
    products: [],
    scannedProducts: [],
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    error: null,
  };
  
  export default function storeReducer(state = initialState, action) {
    switch (action.type) {
      case LIST_STORE_PRODUCT:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case LIST_FOR_STORE_PRODUCT:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case GET_SCANNED_STORE_PRODUCTS:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case RENDER_STORE_PRODUCT:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case FETCH_STORE_PRODUCT:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case CREATE_STORE_PRODUCT:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case UPDATE_STORE_PRODUCT:
        return {
          product: { ...state.product, ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case REJECT_STORE_PRODUCT:
        return {
          product: state.product,
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case STORE_PRODUCT_REJECT_SUCCESS:
        return {
          product: state.product,
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: false,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case STORE_PRODUCT_SUCCESS:
        return {
          product: { ...action.payload, ...state.product },
          products: action.payload.products || state.products,
          scannedProducts: action.payload.scannedProducts || state.scannedProducts,
          isLoading: false,
          isCreated: action.payload.isCreated,
          isUpdated: action.payload.isUpdated,
          error: null,
        };
      case STORE_PRODUCT_FAILURE:
        return {
          product: state.product,
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: false,
          isCreated: false,
          isUpdated: false,
          error: action.payload.message,
        };
      default:
        return state;
    }
  }
  