import React, {useState, useEffect} from 'react';
import { InfoCircleOutlined,LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import AWS from 'aws-sdk';
import { Row, Col, Button, Upload, message, Tooltip } from 'antd';
import {updateRoom} from "../../../../../redux/actions";
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import * as Constants from "../../Constants";
import $ from 'jquery';
import { BUCKET_NAME } from '../../../../../environments/env';


const RoomFloorPlanForm = (props) => {

    const twoDAssetsOptions = ['Perspective Images', '360 Spin'];
    const threeDAssetsOptions = ['GLTF (AR - Mobile - Web) Images', 'USDZ (AR - Mobile - Web)',
        'OBJ (AR - Mobile - Web)', 'High Poly Max (AR - Mobile - Web)'
    ];
    const required_assets = ['3D Model', 'HD Images', 'AR Compatible Model', 'Web Compatible Model'];

    const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue} = props.form;


    const [submitType, setSubmitType] = useState('submit');
    const [uploadError, setUploadError] = useState('');
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [floorFileList, setFloorFileList] = useState([]);

    useEffect(() => {
        if(props.roomReducer.room.floorplan && props.roomReducer.room.floorplan.length > 0){
            setFloorFileList(props.roomReducer.room.floorplan)
        }

        if(props.roomReducer.isCreated == true || props.roomReducer.isUpdated == true) {
            console.log("roomReducer step2",props.roomReducer.room)
            if(submitType == 'continue') {
                let onboarding_string = '';
                let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                if(onboarding != undefined){
                    onboarding_string = '&onboarding=true'
                }
                let product_state_string = '';
                let product_state = new URLSearchParams(window.location.search).get("product_state");
                if(product_state != undefined){
                    product_state_string = '&product_state='+product_state
                }

                props.history.push({
                    pathname: '/add-new-room',
                    search: `?step=${submitType == 'continue' ? 2: 0}&room_id=${ props.roomReducer.room.room_id}${onboarding_string}${product_state_string}&origin=${props.redirectionPath}`
                });
                props.next();
            } else if (submitType == "save") {
                props.handlePageRedirection();
                document.activeElement.blur();
            }
        }
        if(props.roomReducer.error) {
            message.error(props.roomReducer.error);
        }
        setFieldsValue(props.roomReducer.room);
    }, [props.roomReducer]);

    $('form input').keydown(function (e) {
        if (e.key == 'Enter') {
            var inputs = $(this).parents("form").eq(0).find(":input");
            if (inputs[inputs.index(this) + 1] != null) {     
                inputs[inputs.index(this) + 1].focus();
            }
            e.preventDefault();
            return false;
        }
    });

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const handleChange = (info) => {
        let fileList = [...info.fileList];
        setFloorFileList(fileList);
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    }

    const triggerFloorplanUpload = () => {
        $('#room_floorplan_floorplan').trigger('click');
        let button = document.getElementById('room_floorplan_floorplan');
        button.disabled = false;
    }

    const handleRedirection = () => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let product_state_string = '';
        let product_state = new URLSearchParams(window.location.search).get("product_state");
        let step_no = '0';
        if(onboarding){
            if (product_state != undefined) {
                step_no = '4'
            } else {
                step_no = '14';
            }
            if(product_state != undefined){
                product_state_string = '&product_state='+product_state
            }
            props.history.push({
                pathname: '/home',
                search: `?step_no=${step_no}&room_state=added${product_state_string}`
            }); 
        } else {
            if(props.redirectionPath){
                props.history.push({
                    pathname: props.redirectionPath
                });  
            } else {
                props.history.push({
                    pathname: '/home'
                }); 
            }
        }
       
    }

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                let uploadStatus = validateFileUploads(values['floorplan']);
                if (uploadStatus == 'uploading') {
                    setUploadError('Upload is in progress, please wait till file upload is complete.')
                }
                else if (uploadStatus == 'error') {
                    setUploadError('Error occured in uploading file, please re-upload your file.')
                }
                else {
                    setUploadError('');
                    let id = new URLSearchParams(window.location.search).get("room_id");
                    props.updateRoom({...values, room_id: id});
                }
                
            }
        });
    };



    return (
        <React.Fragment>
            <Form  className="input-form"  onSubmit={handleSubmit}>
                <Row gutter={16}>


                    <Col span={24}>
                        <h3 className='manrope f-16 black-00 w-600'>Sample Floor Plan</h3>
                        <img height="500px" width="100%" className="" src={require("../../../../../assets/images/floorplan.jpg")} alt=""></img>
                    </Col>

                    <Col span={24} id="floor-upload" style={{marginTop: 10}}>
                    <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerFloorplanUpload}>
                        <div  style={{textAlign:"left",minWidth:"100%"}}>
                            <Form.Item name ="floorplan"  label={<span  className='manrope f-14 black-00 w-700'>Floor Plan&nbsp;<Tooltip title={<span className='manrope f-12 white'>Room floorplan just like sample provided above.</span>}><InfoCircleOutlined /></Tooltip></span>} colon={false}>
                                {props.roomReducer.room.floorplan && getFieldDecorator('floorplan', {
                                    valuePropName: 'floorplan',
                                    getValueFromEvent: e => e && e.fileList,
                                    rules: [{ required: true, message: 'You must upload a floor plan photo.' }]
                                })(
                                
                                    <Upload 
                                    accept=".pdf,.png,.jpg,.jpeg"
                                    fileList={floorFileList}
                                    onChange={handleChange}
                                    openFileDialogOnClick={false}
                                    {...(Constants.getUploadProps(props.roomReducer.room.platform))}
                                    multiple={true}
                                    onRemove={file => {
                                        let button = document.getElementById('room_floorplan_floorplan');
                                        button.disabled = true;
                                        const index = floorFileList.indexOf(file);
                                        const newFileList = floorFileList.slice();
                                        newFileList.splice(index, 1);
                                        setFloorFileList(newFileList);
                                        console.log(newFileList)
                                        newFileList.forEach((file) => {
                                            if (file.status !== "error"){
                                                setHasError(false)
                                            }
                                            else {
                                                setHasError(true)
                                            }
                                        })
                                        return true;
                                    }}
                                    listType="text"
                                    className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                    progress= {{
                                        strokeColor: {
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                        },
                                        strokeWidth: 3,
                                        format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                    }}>

                                        <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                        <img className="" src={require("../../../../../assets/images/upload.png")} alt=""></img>
                                                        <div className="ant-upload-text">
                                                            <div style={{lineHeight:"150%"}} className="up-info manrope f-10">Drop your <span className="highlight-text"> floor plan </span> photo, or browse</div>
                                                            <div className='manrope f-10'>Supported formats: JPG, PNG, PDF</div>
                                                        </div>

                                                        {(validateFileUploads(floorFileList) == 'error') && (<Button
                                                        onClick={(event) => Constants.triggerFailedFilesUpload('room_floorplan_floorplan', floorFileList, setFloorFileList, event)}
                                                        disabled={floorFileList.length === 0}
                                                        loading={uploading}
                                                        style={{top: 0}}
                                                        className="retry-btn manrope f-12" ghost
                                                        >
                                                        {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                    </Button>)}
                                                </div>
                                            </div>
                                        
                                    </Upload>
                                        
                                )}
                            </Form.Item>
                            </div>
                        </div>
                    </Col>
                    {(uploadError != '')?
                        <div className="manrope f-12 red" style={{textAlign: "center"}}>{uploadError}</div>
                    :""}
                      {(validateFileUploads(floorFileList) == 'error') ? 
                        <div className="manrope f-12 red" style={{ textAlign: "center"}}>
                            {errorMessage}
                        </div>: ''}
                    <Col span={24} style={{display:"flex",justifyContent:"space-between",marginTop: '15px'}}>
                        <Form.Item>
                            <Button onClick={handleRedirection} className="modal-okay-gray square font-14">
                                Cancel
                            </Button>
                        </Form.Item>
                        <Form.Item className="justify-buttons">
                            <Button onClick={() => setSubmitType('save')} className="modal-okay-gray square font-14" htmlType="submit" style={{marginRight: '5px'}}>
                            Save Draft & Exit {props.roomReducer.isLoading && submitType == 'save'?     <LoadingOutlined spin />: ''}
                            </Button>
                            <Button onClick={() => setSubmitType('continue')} className="modal-okay-green square font-14" htmlType="submit">
                                Continue {props.roomReducer.isLoading && submitType == 'continue'? <LoadingOutlined spin />: ''}
                                
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
}

const RoomFloorPlan = Form.create({name: 'room_floorplan'})(RoomFloorPlanForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
});
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(RoomFloorPlan)
)