export const progress_bar = {
        strokeColor: {
    '0%': '#108ee9',
    '100%': '#87d068',
    },
    strokeWidth: 3,
    format: percent => `${parseFloat(percent.toFixed(2))}%`,
}

export const categoryStyle = {
    maxHeight: 400,
    overflow: 'auto',
    fontFamily: 'Manrope',
    fontSize: 12,
    color: "#555555"
}

export const categoryStyleF10 = {
    maxHeight: 400,
    overflow: 'auto',
    fontFamily: 'Manrope',
    fontSize: 10,
    color: "#555555"
}

export const borderStyle = {
    border: "1px solid #e3e3e3 ",
    padding: 20, 
    borderRadius: 4
}

export const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

export const borderStyleEmpty = {

}

export const layout_maping = {
    1: [1],
    2: [2],
    3: [1, 2],
    4: [2, 2],
    5: [2, 3],
    6: [3, 3],
}

export const collageSettings = {
    width: 'auto',
    height: [],
    layout: [],
    photo: '',
};


export const height_maping = {
    1: ['200px'],
    2: ['200px'],
    3: ['100px','100px'],
    4: ['100px','100px'],
    5: ['100px','100px'],
    6: ['100px','100px'],
}

export const folder_border = {
    width: '94%',
    border: '1px solid #e3e3e3'
}
