import React from 'react';
import { Table } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'

const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class ScenesTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandable,
    tableLayout: 'fixed',
    title: undefined,
    showHeader,
    footer: false,
    rowSelection: {},
    scroll: undefined,
    hasData: true,
    dataSource: null,
    reviewAction: 'hide',
  };

  getColumns = () => [
    {
      title: 'Scene Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Scene Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.reviewAction,
      render: (text, record) => (
        <span>
          <a onClick={() => window.location.href = ('/admin_scenes/' + record.id)}>Review</a>
        </span>
      ),
    },
  ];

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    if (this.props.state === 'unhappy') {
      this.handleAssignedData();
    }

  }

  handleAssignedData = () => {
    axios.post(ENVIRONMENT.GET_SCENE_BATCH, {
      unhappy: ""
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        var data = [];
        for (let i = 1; i <= (res.data).length; i++) {
          data.push({
            key: i,
            id: res.data[i - 1]['id'],
            name: res.data[i - 1]['name'],
          });

        }

        this.setState(
          {
            reviewAction: "show"
          }
        );

        this.setState({
          dataSource: data
        })
      })
  }

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
        }
      </div>
    );
  }
}

export default ScenesTable;
