import React, { useState,useEffect } from 'react';
import { Card,Row,Col, Button,Switch,notification,Modal,Tooltip, Progress, message } from 'antd';
import './SubscriptionPlansDetails.scss'
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import {CheckOutlined,InfoCircleOutlined, CloseOutlined  } from '@ant-design/icons';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate/SubscriptionPackageUpdate'
import FileConstants from '../../../../FileConstants';


const openNotificationError = placement => {
    notification.open({
        message: 'Error',
        description:
          'There was an error in upgrading your subscription.',
          placement,
        icon:<CloseOutlined  style={{ color: '#ee2233' }}/>
      });
}

const openNotificationOk = placement => {
    notification.open({
      message: 'Updated',
      description:
        'Your package has been updated.',
        duration : 1.2,
        placement,
      icon:<CheckOutlined  style={{ color: '#10ef10' }}/>,
      onClose : ()=> {window.location.href = "/account-details?tab=subs"}
    });
  };

const SubscriptionPlanDetails = (props) => {
    const [planData, setPlanData] = useState({});
    const [usageData, setUsageData] = useState({});
    const [loader, setLoader] = useState(true);
    const [renewal, setRenewal] = useState(false);
    const [lifestylePercentage, setLifestylePercentage] = useState(0);
    const [siloPercentage, setSiloPercentage] = useState(0);
    const [usedLifeStyleTokens, setUsedLifestyleToekns] = useState(0);
    const [usedSiloTokens, setUsedSiloTokens] = useState(0);
    const [availableLifeStyleTokens, setAvailbaleLifestyleToekns] = useState(0);
    const [availableSiloTokens, setAvailbaleSiloToekns] = useState(0);
    const [storeProductsAvaible, setStoreProductsAvailable] = useState(0);
    const [storeProductsUsed, setStoreProductsUsed] = useState(0);
    const [storeRoomsAvailable, setStoreRoomsAvailable] = useState(0);
    const [storeRoomsUsed, setStoreRoomsUsed] = useState(0);
    const [uploadModelsUsed, setUploadModelsUsed] = useState(0);
    const [uploadModelsAvailable, setUploadModelsAvailable] = useState(0);
    const [subscriptionModal, setSubscriptionModal] = useState(false)
    const [subscriptionPlan,setSubscriptionPlan] = useState("")
    const [subscriptionPlanUpdate,setSubscriptionPlanUpdate] = useState("")
    const [billingData, setBillingData] = useState([]);
    const [subscriptionLoader, setSubscriptionLoader] = useState(false)
    const [renewalModal,setRenewalModal] = useState(false)
    const [renewalLoader, setRenewalLoader] = useState(false)
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    
    let onChangeRenewal = () => {
        setRenewalLoader(true);
        let payload ={
            username: localStorage.getItem('username'),
            auto_renew_subscription: false
        }
        axios.post(ENVIRONMENT.UPDATE_CUSTOMERS_SETTINGS, payload)
        .then( () => {
            setRenewalModal(false)
            setRenewal(false);
            setRenewalLoader(false);
            message.success('Your subscription is cancelled successfully');
        });

    }

    const upgradeSubscriptionPlan = () => {

        if (props.cardStatus == 1)
        {
            setSubscriptionLoader(true)
            let payload ={
            username: localStorage.getItem('username'),
            subscription_type: subscriptionPlanUpdate
            }
            console.log('Subscription payload',payload);
            axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
            .then( (response) => {
                console.log(response)
                if(response.data.errorMessage){
                    openNotificationError('topRight')
                }
                else{
                    openNotificationOk('topRight')
                }
            setSubscriptionModal(false)
            setSubscriptionLoader(false)
            })
            .catch((error) => {
            });
        } else {
            message.info('Please Save your Card First')
        }
        
    }


    let setDataForPercentages = (data) => {
        for (let index in data){
            if(data[index]['name'] == 'scene_lifestyle_image'){
                setLifestylePercentage((parseInt(data[index]['used'])/ (parseInt(data[index]['used'])+parseInt(data[index]['available']))*100).toFixed(1))
                setUsedLifestyleToekns(parseInt(data[index]['used']))
                setAvailbaleLifestyleToekns(data[index]['available'])
            }
            if(data[index]['name'] == 'product_prop'){
                setStoreProductsAvailable(parseInt(data[index]['available']))
                setStoreProductsUsed(parseInt(data[index]['used']))
            }
            if(data[index]['name'] == 'room_prop'){
                setStoreRoomsAvailable(parseInt(data[index]['available']))
                setStoreRoomsUsed(parseInt(data[index]['used']))
            }
            if(data[index]['name'] == 'existing_product_model'){
                setUploadModelsAvailable(parseInt(data[index]['available']))
                setUploadModelsUsed(parseInt(data[index]['used']))
            }
            if(data[index]['name'] == 'product_silo'){
                setSiloPercentage((parseInt(data[index]['used'])/ (parseInt(data[index]['used'])+parseInt(data[index]['available']))*100).toFixed(1))
                setUsedSiloTokens(parseInt(data[index]['used']))
                setAvailbaleSiloToekns(data[index]['available'])
            }
        }
        setLoader(false)
    }

    useEffect(() => {
    
    let plan_payload={
        "data" : "subscription-plan",
        "username" : localStorage.getItem('username'),
    }
    setLoader(true);
    axios.post(ENVIRONMENT.GET_UPDATE_SUBSCRIPTION_DATA, plan_payload)
    .then(res => {
        setPlanData(res.data)
        setSubscriptionPlan(res.data['name'])
        setIsSubscriptionActive(res.data['is_active']);
        setRenewal(res.data['auto_renew_subscription'])
    });



    let usage_payload = {
        "username": localStorage.getItem('username'),
        }
        axios.post(ENVIRONMENT.CUSTOMER_USAGE_QUOTA, usage_payload)
        .then(res => {
            console.log("here we are", res.data)
            setUsageData(res.data)
            setDataForPercentages(res.data)
        })

    let billing_payload = {
        "username": localStorage.getItem('username')
    }
    axios.post(ENVIRONMENT.GET_BILLING_DATA, billing_payload)
        .then(res => {
            
            setBillingData(res.data)
        },
        error => {
            console.log(error)
        })


    },[]);

    const openSubscriptionUpgradePopup = () => {
        setSubscriptionPlanUpdate("");
        setSubscriptionModal(true);
    }

  return (
    <div className='account-details-modals'>
        <Row>
        {
                planData['name'] != "enterprise"
                ?
                <React.Fragment>
                <Col style={{padding:"5px 4px"}} span={12}>
                
                <Card loading={loader} className='grey-background' style={{marginBottom:"10px",height:"120px"}}>
                    <div className='card-heading'>
                        <span className='manrope f-18'>{(planData['display_name'] == 'Free Trial') ? (planData['display_name']):(planData['display_name'] + ' Plan')}</span>
                        {
                            planData['is_active'] == true?
                            <div className='float-right manrope f-12'><span className='manrope f-18 dollar'>${planData['price']} </span> <span>/ Month</span></div>
                            : 
                            <div className='float-right manrope f-12'><span className='manrope f-14'>Click below to see subscription packages</span></div>
                        }
                        
                    </div>
                    <div className='card-heading'>
                        {
                            planData['is_active'] == true?
                            <span>
                                {
                                    (planData['display_name'] == 'Free Trial') ? 
                                    <span>Expiry Date: <b>{new Date(Date.parse(planData['end_time'])).toLocaleString('en-us', {year:'numeric', month:'short', day:'numeric'})}</b></span>
                                    :
                                    <span>
                                        {renewal?
                                        <span>Renewal Date: <b>{new Date(Date.parse(planData['end_time'])).toLocaleString('en-us', {year:'numeric', month:'short', day:'numeric'})}</b></span>
                                        :
                                        <span>End Date: <b>{new Date(Date.parse(planData['end_time'])).toLocaleString('en-us', {year:'numeric', month:'short', day:'numeric'})}</b></span>
                                        }
                                    </span>
                                }
                            </span>
                            :
                            <span className='manrope f-14' style={{color:"#EE2211"}}> Expired </span>
                        }
                        <div className='float-right'>
                            {(planData['name'] != "premium" || planData['is_active'] === false)?
                            <Button ghost  type="primary" shape="square" size={"medium"} onClick={()=>openSubscriptionUpgradePopup()}>
                                Upgrade Plan
                            </Button>:null}
                        </div>
                    </div>
                </Card>
                <div className='renewal-modal'>
                <Card style={{height:"300px"}} loading={loader} className='grey-background'>
                    <div className='card-heading'>
                        <span className='manrope f-18'>Billing History</span>
                    </div>
                    <div className='card-heading'>
                        {
                            billingData.map( (bill,index) => (
                                <>
                                <div className='Manrope f-14' style={{minHeight: 44}}>
                                    <div>
                                        <span> Subscription Renewal</span>
                                        <span className='float-right' style={{color: (bill['renewal'] ==='Scheduled')? "#25A759" : "#E19B12"}}>{bill['renewal']}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {/* To be uncommented once invoice flow is generated */}
                                            {/* <a href=''>Export Invoice</a> */}
                                        </span>
                                        <span className='float-right'>
                                            Date : {bill['date']}
                                        </span>
                                    </div>
                                    
                                </div>
                                {index == billingData.length-1 ? "" : <hr/>}
                                </>
                            ))
                        }
                    </div>  
                </Card>
                </div>
            </Col>
            {console.log('step',props.tour_step)}
            <Col  data_tut="reactour__accountDetails" style={{padding:"5px 4px"}} span={12}>
            <Card loading={loader} style={{height:"430px"}} className='grey-background'>
                    <div className='card-heading'>
                        <span className='manrope f-18'>Total Usage</span>
                    </div>
                    <div className='card-heading'>
                        <p className='manrope f-16'><b>Breakdown</b></p>
                        {planData['is_active'] ? '':  <p style={{color:"#EE1122"}}>Your subscription has expired. Please renew subscription to renew your quota.</p> }
                        <p className='manrope f-14' style={{marginTop:20,marginBottom:5}}>
                            <span style={{marginRight: 4}}>Product Models or Lifestyle Images</span>
                            <span className='float-right'>{lifestylePercentage}% used</span>
                                {subscriptionPlan == 'freemium' ?
                                    <Tooltip title={<span className="manrope f-12 white">
                                        {parseInt(availableLifeStyleTokens)} 1k left
                                        </span>}>
                                        <InfoCircleOutlined/>
                                    </Tooltip>
                                    :
                                    <Tooltip title={<span className="manrope f-12 white">{
                                        parseInt(availableLifeStyleTokens/8) > 0 ?
                                        <span> 
                                            {parseInt(availableLifeStyleTokens/FileConstants.modelRequestTokens)} Product Models left<br/>
                                            OR <br/>
                                            {parseInt(availableLifeStyleTokens/4)} 4k left OR<br/>
                                            {parseInt(availableLifeStyleTokens/2)} 2k left OR<br/>
                                            {parseInt(availableLifeStyleTokens/1)} 1k left <br/>
                                        </span>
                                        :
                                        parseInt(availableLifeStyleTokens/4) > 0 ?
                                            <span> 
                                                {parseInt(availableLifeStyleTokens/4)} 4k left OR<br/>
                                                {parseInt(availableLifeStyleTokens/2)} 2k left OR<br/>
                                                {parseInt(availableLifeStyleTokens/1)} 1k left <br/>
                                            </span>
                                        :
                                        parseInt(availableLifeStyleTokens/2) > 0 ?
                                        <span>
                                            {parseInt(availableLifeStyleTokens/2)} 2k left OR<br/>
                                            {parseInt(availableLifeStyleTokens/1)} 1k left <br/>
                                        </span>
                                        :
                                        <span>
                                            {parseInt(availableLifeStyleTokens/1)} 1k left <br/>
                                        </span>
                                    }</span>}> <InfoCircleOutlined/> </Tooltip>
                                }
                        </p>
                        <Progress percent={parseInt(lifestylePercentage)} showInfo={false} type='line' strokeColor={planData['is_active']? "#7198FF" : "#666666"} trailColor={"#D9D9D9"}  />
                        {(!isNaN(siloPercentage))?
                        <span>
                        <p className='manrope f-14' style={{marginTop:20,marginBottom:5}}>
                            <span style={{marginRight: 4}}>Product Images</span>
                            <span className='float-right'>{siloPercentage}% used</span>
                                {subscriptionPlan == 'freemium' ?
                                    <Tooltip title={<span className="manrope f-12 white">
                                        {parseInt(availableSiloTokens)} 1k left
                                        </span>}>
                                        <InfoCircleOutlined/>
                                    </Tooltip>
                                    :
                                    <Tooltip title={<span className="manrope f-12 white">{
                                        parseInt(availableSiloTokens/4) > 0 ?
                                            <span> 
                                                {parseInt(availableSiloTokens/4)} 4k left OR<br/>
                                                {parseInt(availableSiloTokens/2)} 2k left OR<br/>
                                                {parseInt(availableSiloTokens/1)} 1k left <br/>
                                            </span>
                                        :
                                        parseInt(availableSiloTokens/2) > 0 ?
                                        <span>
                                            {parseInt(availableSiloTokens/2)} 2k left OR<br/>
                                            {parseInt(availableSiloTokens/1)} 1k left <br/>
                                        </span>
                                        :
                                        <span>
                                            {parseInt(availableSiloTokens/1)} 1k left <br/>
                                        </span>
                                    }</span>}> <InfoCircleOutlined/> </Tooltip>
                                }
                        </p>
                        <Progress percent={parseInt(siloPercentage)} showInfo={false} type='line' strokeColor={planData['is_active']? "#7198FF" : "#666666"} trailColor={"#D9D9D9"}  />
                        </span>:''}

                        <p className='manrope f-14' style={{marginTop:20,marginBottom:5}}>ALL3D Store Product Models <span className='float-right'>{storeProductsUsed}/{storeProductsUsed+storeProductsAvaible} used</span></p>
                        <Progress percent={(storeProductsUsed/(storeProductsUsed+ storeProductsAvaible)*100)} showInfo={false} type='line' strokeColor={planData['is_active']? "#7198FF" : "#666666"} trailColor={"#D9D9D9"}  />
                        
                        <p className='manrope f-14' style={{marginTop:20,marginBottom:5}}>ALL3D Store Space Models <span className='float-right'>{storeRoomsUsed}/{storeRoomsUsed+storeRoomsAvailable} used</span></p>
                        <Progress percent={(storeRoomsUsed)/(storeRoomsUsed+storeRoomsAvailable)*100} showInfo={false} type='line' strokeColor={planData['is_active']? "#7198FF" : "#666666"} trailColor={"#D9D9D9"}  />
                        
                        <p className='manrope f-14' style={{marginTop:20,marginBottom:5}}>Upload 3D Models <span className='float-right'>{uploadModelsUsed}/{uploadModelsUsed+ uploadModelsAvailable} used</span></p>
                        <Progress percent={(uploadModelsUsed/(uploadModelsUsed+ uploadModelsAvailable)* 100)} showInfo={false} type='line' strokeColor={planData['is_active']? "#7198FF" : "#666666"} trailColor={"#D9D9D9"}  />
                        
                    </div>
                </Card>
            </Col>
            {renewal && planData['display_name'] != 'Free Trial'?
            <Col span={12}>
            <div style={{marginTop: 4, marginLeft: 4}}>
                <span style={{display:"unset"}}  className="manrope f-12 w-500">Click <a onClick={() => setRenewalModal(true)}>here</a> to cancel your subscription</span>
            </div>
            </Col>:""}
            </React.Fragment>
                :
                <Col style={{padding:"5px 4px"}} span={12}>
                
                <Card loading={loader} className='grey-background' style={{marginBottom:"10px",height:"120px"}}>
                    <div className='card-heading'>
                        <span className='manrope f-18'>{planData['display_name']} Plan</span>
                    </div>
                    
                </Card>
                </Col>
    }

        </Row>
        {
            planData['name'] != "enterprise"
            ?
        <SubscriptionPackageUpdate
            footer={[<span className="justify-in-end">
                    <Button key="back" className="modal-okay-gray square font-14" onClick={() => setSubscriptionModal(false)}>
                        Cancel
                    </Button>
                    <Button  loading={subscriptionLoader} disabled={(subscriptionPlanUpdate === "")}  className="modal-okay square font-14" onClick={upgradeSubscriptionPlan}>
                        <span>Upgrade </span>
                    </Button>
                </span>]}
            currentSubscriptionPlan={subscriptionPlan}  
            subscriptionPlan={subscriptionPlanUpdate}
            setSubscriptionPlan={setSubscriptionPlanUpdate}
            isSubscriptionActive={isSubscriptionActive}
            visible={subscriptionModal}
            setSubscriptionModal={setSubscriptionModal}
            onCancel={()=>setSubscriptionModal(false)}
            />   
            :''
        }
        <Modal
        closable={false}
        visible={renewalModal}
        footer={[<span className="justify-in-end">
        <Button  className="modal-okay-gray square font-14" onClick={() => setRenewalModal(false)}>
            Cancel
        </Button>
        <Button loading={renewalLoader} className="modal-okay square font-14" onClick={onChangeRenewal}>
            <span>Yes</span>
        </Button>
    </span>]}
        >
            <span className='manrope f-16'>
                Are you sure you want to cancel your subscription?
            </span>
        </Modal>
    </div>
  );
}

export default SubscriptionPlanDetails