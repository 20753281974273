import React from 'react';
import { Table, Divider, message } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'

const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class InvoicesTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandable,
    title: undefined,
    showHeader,
    footer: false,
    tableLayout: 'fixed',
    rowSelection: {},
    scroll: undefined,
    hasData: true,
    dataSource: null,
  };

  getColumns = () => [
    {
      title: 'Invoice Id',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      render: text => <span>{text}</span>,
    },
    {
        title: 'Customer',
        dataIndex: 'customer_username',
        key: 'customer_username',
        render: text => <span>{text}</span>,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => <span>{text}</span>,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: text => <span>{text}</span>,
    },
    {
        title: 'Time Issued',
        dataIndex: 'time_issued',
        key: 'time_issued',
        render: text => <span>{text}</span>,
    },

  ];

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    axios.post(ENVIRONMENT.INVOICE_GET_BATCH, {
      })
        .then(res => {
          console.log(res);
          console.log(res.data);
          var data = [];
          for (let i = 1; i <= (res.data).length; i++) {
            console.log(i);
            data.push({
              key: i,
              invoice_id: res.data[i - 1]['invoice_id'],
              customer_username: res.data[i - 1]['customer_username'],
              status: res.data[i - 1]['status'],
              amount: res.data[i - 1]['amount'],
              time_issued: res.data[i - 1]['time_issued'],
            });

          }
          
          this.setState({
            dataSource: data
          })
        })
    
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
        }
      </div>
    );
  }
}

export default InvoicesTable;
