export const REORDER = 'REORDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const MOVE_TO_FOLDER = 'MOVE_TO_FOLDER';
export const GET = 'GET';
export const LOAD_PAGE = 'LOAD_PAGE';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const getArrangements = (data) => ({
  type: GET,
  payload: data,
});

export const reorderData = (data) => ({
  type: REORDER,
  payload: data
});

export const renameFolder = (data) => ({
  type: RENAME_FOLDER,
  payload: data,
});

export const createFolder = (data) => ({
  type: CREATE_FOLDER,
  payload: data,
});

export const moveToFolder = (data) => ({
  type: MOVE_TO_FOLDER,
  payload: data,
});

export const loadPage = () => ({
  type: LOAD_PAGE
});

export const successAction = (data) => ({
  type: SUCCESS,
  payload: data,
});

export const failureAction = (error) => ({
  type: FAILURE,
  payload: error,
});