class VirtualTourInfoExporter {
	
	tourInfo = {
		newScheme: true,
		panos: [],
		hotspots: {}
	}

	addPano ( title, hotspots ) {
		let pano = {};
		pano.title = title;
		pano.filename = ( title + ".jpg" );
		pano.hotspots = hotspots;
		this.tourInfo.panos.push( pano );
	}
	addCameraHotspots (cameraHotspots) {
		this.tourInfo.hotspots = cameraHotspots;
	}

	generateTourInfoJSON () {
		return JSON.stringify( this.tourInfo );
	}

}

export { VirtualTourInfoExporter };
