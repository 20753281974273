import React from "react";
import ENVIRONMENT from '../../../../environments'
import { Tree } from "antd";
import axios from 'axios';

const treeData = [
  {
    title: 'parent 1',
    key: '0-0',
    children: [
      {
        title: 'parent 1-0',
        key: '0-0-0',
        disabled: true,
        children: [
          {
            title: 'leaf',
            key: '0-0-0-0',
          },
          {
            title: 'leaf',
            key: '0-0-0-1',
          },
        ],
      },
      {
        title: 'parent 1-1',
        key: '0-0-1',
        children: [
          {
            title: (
              <span
                style={{
                  color: '#1890ff',
                }}
              >
                sss
              </span>
            ),
            key: '0-0-1-0',
          },
        ],
      },
    ],
  },
  {
    title: 'parent 2',
    key: '1-0',
  }
];

function getCategoriesData(catDataAray) {
  var catDataDict = {};

  for (let i = 1; i <= (catDataAray).length; i++) {
    if (catDataAray[i - 1].parent_id === "") {
      catDataDict[catDataAray[i - 1].material_id] = []
    }
  }
  return catDataDict;
}

class AdminMaterialTree extends React.Component {
  state = {
    treeData: {}
  };

  componentDidMount() {
    let data = [];

    axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
    })
      .then(res => {
        let categoriesData = getCategoriesData(res.data);
        console.log(categoriesData);
        console.log(res.data)
        for (let i = 1; i <= (res.data).length; i++) {
          if (res.data[i - 1].parent_id === "") {
            data.push({
              key: res.data[i - 1]['material_id'],
              title: res.data[i - 1]['name'],
              children: categoriesData[res.data[i - 1]['material_id']],
            });
          }

        }
        this.setState({
          treeData: data
        })

      });
  }

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  render() {
    return (
      <Tree
        onSelect={this.onSelect}
        treeData={this.state.treeData}
      />
    );
  }
}

export default AdminMaterialTree;