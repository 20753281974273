import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Input, Button } from "antd";
import AdminMainLayout from '../AdminMainLayout';

import InvoicesTable from '../InvoicesTable'

const { TabPane } = Tabs;

class AdminInvoiceData extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"9"}>
        <div style={{display: "flex", padding: 10}}>
          <Input
              prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Search Invoices"
              onChange={this.changeUsername}
          />
          <Button type="primary" onClick={() => window.location.href = "/admin_invoice_create"}>Create Invoice</Button>
        </div>
        <InvoicesTable />
        
      </AdminMainLayout>
    );
  }
}

export default AdminInvoiceData;