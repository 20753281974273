import React, { useEffect } from "react";
import SpaceVariationRequestModal from "../SpaceVariationRequestModal/SpaceVariationRequestModal";
import { useState } from "react";

import SuccessModal from "../../SuccessModal/SuccessModal";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import { Button } from "antd";
import WarningModal from "../../WarningModal/WarningModal";

const OtherVariationsAction = ({
  savedID,
  roomDetails,
  spaceDetails,
  params,
  variationModal,
  setVariationModal,
}) => {
  const [saveModal, setSaveModal] = useState(false);
  const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const [cardStatus, setCardStatus] = useState(null);
  const [cardSaveFailure, setCardSaveFailure] = useState(false);
  const [paymentRequestID, setPaymentRequestID] = useState(-1);
  const [variationSuccessModal, setVariationSuccessModal] = useState(false);

  useEffect(() => {}, []);

  const cancelVariationModal = () => {
    setVariationModal(false);
  };

  const submitVariation = (status) => {
    setVariationModal(false);
    if (status == 0) {
      setVariationSuccessModal(true);
    } else {
      setSaveModal(true);
    }
  };

  const loadUserRequestStatus = () => {
    let payload = {
      username: localStorage.getItem("username"),
      action: "check",
      request_type: "variation_room_model",
    };
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload).then((res) => {
      setRequestStatus(res.data["request_allowed"]);
      setCardStatus(res.data["card_status"]);
    });
  };

  useEffect(() => {
    let payment_redirected = new URLSearchParams(window.location.search).get(
      "payment_redirected"
    );
    let payment_failure = new URLSearchParams(window.location.search).get(
      "payment_failure"
    );
    let payment_request_id = new URLSearchParams(window.location.search).get(
      "request_id"
    );

    if (payment_redirected) {
    }
    if (payment_failure) {
      if (payment_request_id) {
        setPaymentRequestID(payment_request_id);
        setCardSaveDrawer(true);
      }
    }

    loadUserRequestStatus();
  }, []);

  return (
    <>
      <SpaceVariationRequestModal
        cardSaveDrawer={cardSaveDrawer}
        setCardSaveDrawer={setCardSaveDrawer}
        requestType="variation_room_model"
        requestStatus={requestStatus}
        cardStatus={cardStatus}
        setCardSaveFailure={setCardSaveFailure}
        cardSaveFailure={cardSaveFailure}
        paymentRequestID={paymentRequestID}
        variationLoader={false}
        variationModal={variationModal}
        closeVariationModal={cancelVariationModal}
        savedID={savedID}
        submitVariation={submitVariation}
        space_details={savedID == -1 ? roomDetails : spaceDetails}
        space_id={roomDetails.room_id}
      />
      <SuccessModal
        visible={variationSuccessModal}
        onCancel={() => setVariationSuccessModal(false)}
        heading={"Space Model Variation Requested Successfully!"}
        text={
          "Your Space Model's Variation request has been submitted. You will be notified via email once your Space Model is ready to be reviewed."
        }
        footer={[
          <div className="justify-in-center">
            <Button
              className="modal-okay square font-14"
              htmlType="submit"
              onClick={() => window.location.reload()}
            >
              Okay
            </Button>
          </div>,
        ]}
      />
      <SuccessModal
        visible={saveModal}
        onCancel={() => setSaveModal(false)}
        heading={"Information Saved Successfully!"}
        text={
          "You can come back any time by reopening this form or from the incomplete section to fill out the remaining information."
        }
        footer={[
          <div className="justify-in-center">
            <Button
              className="modal-okay square font-14"
              htmlType="submit"
              onClick={() => window.location.reload()}
            >
              Okay
            </Button>
          </div>,
        ]}
      />
      <WarningModal
        visible={cardSaveFailure}
        onCancel={() => setCardSaveFailure(false)}
        heading={"Unable to Save Payment Details"}
        text={
          "Failed to process payment details. Please try another payment method."
        }
        footer={[
          <div className="justify-in-end">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-14"
              onClick={() => setCardSaveFailure(false)}
            >
              Okay
            </Button>
          </div>,
        ]}
      />
    </>
  );
};

export default OtherVariationsAction;
