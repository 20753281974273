import React, {useState, useEffect, useCallback, useRef} from 'react';
import ENVIRONMENT from '../../../../environments';
import { DownOutlined, LoadingOutlined,LeftOutlined, LinkOutlined, WindowsFilled, RightOutlined, ArrowLeftOutlined, ArrowRightOutlined, DownloadOutlined,InfoCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import moment from 'moment';
import axios from 'axios';
import AWS from "aws-sdk";
import { ClockCircleOutlined } from '@ant-design/icons';
import {
    Row,
    Col,
    Button,
    Checkbox,
    Select,
    Tabs,
    Menu,
    Card,
    Form,
    Modal,
    Tooltip,
    Dropdown,
    Typography,
    Collapse,
    Image,
    Input,
    Carousel,
} from 'antd';
import CustomerMainLayout from '../CustomerMainLayout';
import {Link } from "react-router-dom";
import ReviewProduct from '../ReviewForm/ReviewProductForm';
import SpaceTags from '../../FormUtilities/SpaceTags';
import RoomPhotosViewer from '../RoomPhotosViewer'
import SpaceVariationRequest from '../SpaceVariationRequest/SpaceVariationRequest';
import ThreesixtyTestRenderViewer from '../ThreesixtyTestRenderViewer';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import FileConstants from '../../../../FileConstants';
import SpaceVariationRequestModal from '../SpaceVariationRequestModal/SpaceVariationRequestModal';
import AddItemToStore from '../../AddItemToStore';
import * as Utilities from '../../Utilities';
import ImageZoomAdmin from '../ImageZoomAdmin/ImageZoomAdmin';
import EmailSelect from '../../FormUtilities/EmailSelect';
import EntityAccessDenied from '../EntityAccessDenied/EntityAccessDenied';
import SpaceColorVariationModal from '../ProductComponents/SpaceColorVariationModal';


import "./RoomStyles.scss";

const { SubMenu } = Menu;
const { TabPane } = Tabs;
const { Option } = Select;
const formRef = { useRef };
const { Paragraph } = Typography;
const { Panel } = Collapse;
const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const base_uri = ENVIRONMENT.BASE_URI + 'space_test_renders/';
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const IS_MSPROVIDER = FileConstants.isMSProvider;
const image_style = { width: '100%',height: "calc(100vh -  202px)",objectFit:"contain" };
const image_style_in_progress = { width: '100%',height: "calc(100vh -  202px)",objectFit:"contain", filter:"blur(8px)" };
const fullscreen_image_style = { width: "100vw",height: "100vh",objectFit:"contain" };
const fullscreen_image_style_in_progress = { width: "100vw",height: "100vh",objectFit:"contain",  filter:"blur(8px)" };
const QUERY_PARAMS_TO_RETAIN = ['category', 'search'];

const RoomDetailsView = (props) =>  {
    const companyId = localStorage.getItem('company_id');
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [imageUrl, setImageUrl] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [showDetailedRejection, setShowDetailedRejection] = useState(false);
    const [countRoomDimensions, setCountRoomDimensions] = useState(-1);
    const [sharingEmails, setSharingEmails] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [formRef] = Form.useForm();
    const [form] = Form.useForm();
    const [display360, setDisplay360] = useState(true)
    const [selectedRenderUrl, setSelectedRenderUrl]= useState('');
    const [isApproveAccess, setIsApproveAccess] = useState(false);
    const url = new URL(window.location);
    const projectId = new URLSearchParams(url.search).get("project_id");
    const [selectedRenderStatus, setSelectedRenderStatus] = useState('')
    const [loading, setLoading] = useState(false);
    const [dummySceneSetup, setDummySceneSetup] = useState(false);
    const [dummySceneError, setDummySceneError] = useState(false);
    const [spaceColorVariationModal, setSpaceColorVariationModal] = useState(false);
    
    useEffect(() => {
        if (projectId) {
            getSharedUsers();
        } else {
            setIsApproveAccess(true);
        }
    }, []);

 
    useEffect(()=>{
        if( props.roomReducer.room.validation_report
            && props.roomReducer.room.validation_report.High
            && props.roomReducer.room.validation_report.High.room_dimensions
            && Object.keys(props.roomReducer.room.validation_report.High.room_dimensions).length > 0
        ){
           setCountRoomDimensions(Object.keys(props.roomReducer.room.validation_report.High.room_dimensions).length)
        }
        checkScroll();
        window.addEventListener("resize", checkScroll());
        document.addEventListener("wheel", () => {checkScroll();}, false);

        return () => {
            window.removeEventListener("resize", checkScroll);
            document.removeEventListener("wheel", () => {
                checkScroll();
            }, false);

        }
    },[props])

    const handleCustomerDummyScene = () => {
        console.log("PROPS", props);
        if(props.roomDetails.customer_dummy_scene_id == ""){
            setDummySceneSetup(true);
            createCollection("Dummy-collection-" + props.roomDetails.room_id, props.roomDetails.room_id)
        } else{
            window.location.href = '/scene_builder/' + props.roomDetails.customer_dummy_scene_id + '?customer_dummy_scene=true';
        }
    };

    const createCollection = (collectionName, roomId) => {
        const payload = {
          collection_name: collectionName,
          username: localStorage.getItem('username'),
        };
    
        if (companyId != undefined) {
          payload.company_id = companyId;
        }
    
        axios
            .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
            .then((res) => {
              addRoomToCollection(res.data.collection_id, roomId);
            })
            .catch(() => {
                setLoading(false);
                setDummySceneError(true);
            });
    };

    const addRoomToCollection = (collectionId, roomId) => {
        const payload = {
            collection_id: collectionId,
            room_id: roomId,
            username: localStorage.getItem('username'),
        }
        axios
            .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
            .then((res) => {
                createScene(collectionId, roomId);
            })
            .catch(() => {
                console.log("Room could not be added to collection ", collectionId)
                setLoading(false);
                setDummySceneError(true);
            });
    };

    const createScene = (collectionId, roomId) => {
        {
          const payload = {
            scene_name: "Dummy-scene-" + roomId,
            collection_id: collectionId,
            room_id: roomId,
            is_hidden: true,
            username: localStorage.getItem('username'),
          };
    
          if (companyId != undefined) {
            payload.company_id = companyId;
          }
    
          axios
              .post(ENVIRONMENT.UPDATE_SCENE, payload)
              .then((res) => {
                const sceneId = res.data['scene_id'];

                let update_payload = {"room_id": roomId, "customer_dummy_scene_id": sceneId}
                axios.post(ENVIRONMENT.UPDATE_ROOM, update_payload).then((response) => {
                    console.log(response);
                    setLoading(false);
                    setDummySceneSetup(false);
                    window.location.href = '/scene_builder/' + sceneId + '?customer_dummy_scene=true';
                });
              })
              .catch(() => {
                setLoading(false);
                setDummySceneError(true);
            });
        }
    };

    const handleImage = (event) => {
        event.preventDefault();
        setImageUrl(event.currentTarget.getAttribute('href'));
        setShowImage(true);
    }

    const showRejectionModal = () => {
        setShowDetailedRejection(true);
    };

    const showSpaceVariationModal = () => {
        setSpaceColorVariationModal(true);
        props.setRequestCategory('Automated');
    }

    const onChangeAllowDownloading = (e) => {
        let default_sharing_value = 'embed'
        if(e.target.checked){
            props.setSelectedPermission(e.target.value)
        }
        else{
            props.setSelectedPermission(default_sharing_value)
        }
    }

    const handleShareEmailChange = (e) => {
        setSharingEmails(e)
    }

    const validateEmails = (_, value) => {
        // Check if the value is an array
        if (Array.isArray(value)) {
          // Regular expression for email validation
          const emailRegex = /^\S+@\S+\.\S+$/;
          const invalidEmails = value.filter(email => !emailRegex.test(email));
    
          // Clear previous error message
          setEmailError('');
    
          if (value.length === 0) {
            return Promise.reject();
          }
    
          if (invalidEmails.length > 0) {
            setEmailError('Please enter valid email address');
            return Promise.reject();
          }
        }
        
        return Promise.resolve();
      };
    
      const handleClearEmails = () => {
        form.setFieldsValue({ emails: [] });
        setEmailError('');
        setSharingEmails([])
      };

    const getLibraryUrl = (props) => {
        console.log('get library URL')
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        let queryString = ''
        console.log('search params =', searchParams)
        for(const [key, value] of searchParams.entries()){
            if(key != 'entity_id' && QUERY_PARAMS_TO_RETAIN.includes(key)){
                queryString = `${queryString}&${key}=${encodeURIComponent(value)}`
            }
        }

        if (props.folderParentID) {
            return '/rooms?folder_id=' + props.folderParentID + queryString;
        };
        return '/rooms?entity_id=' + props.params.id + queryString;
    }

    const redirectPage = () => {
        if (props.storePage == 0) {
            window.location.href = getLibraryUrl(props);
        } else  {
            window.location.href = '/store'
        }
    }

    const handleCancel = () => {
        setShowDetailedRejection(false);
    };

    const getSharedUsers = () => {
        let payload = {
          'action': 'get',
          'project_id': projectId
        };
        let company_id = COMPANY_ID;
        if (IS_MSPROVIDER && MANAGED_COMPANY_ID) {
          company_id = MANAGED_COMPANY_ID;
        }
        if (company_id) {
          payload['company_id'] = company_id;
        }
        axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
          .then(res => {
            if (res.data && res.data.length > 0) {
              let user_access = res.data.filter(item => item.username == localStorage.getItem('username')).map(item => item.access_level);
    
              if (user_access && user_access == 'quality_control' || user_access == 'owner' || user_access == 'co_owner') {
                setIsApproveAccess(true);
              } 
            }
          });
      };

    const menu = (
        <Menu className='manrope f-12' style={{ width: "170px"}}>
            <Menu.Item key={2}>
                <a className='manrope f-12' target="_blank" onClick={() => props.downloadSceneImagesArchive()}> 360 Image(s) <DownloadOutlined style={{float: "right"}} type="download" /></a>
            </Menu.Item>
            {props.customerRendersGenerated.length > 0?
                <Menu.Item key={3}>
                    <a className='manrope f-12' target="_blank" onClick={() => props.downloaCustomerGeneratedImagesArchive()}> Generated Image(s) <DownloadOutlined style={{float: "right"}} type="download" /></a>
                </Menu.Item>:''
            }
            {(props.roomReducer.room.download_links || []).map((ele, index) => {
              return  <Menu.Item key={index}>
                    <a className='manrope f-12' target="_blank" href={ele.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']))}> {ele.display_name} download link <DownloadOutlined style={{float: "right"}} type="download" /></a>
                </Menu.Item>
            })}
        </Menu>
    );

    let rejections_data = '';
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
        console.log(props.rejectionDetails, "rejection data")
        let ele = props.rejectionDetails[0];
      rejections_data = (
        <div style={{marginTop: 20}}>
          <div className='manrope f-12 black-55 align-text' style={{marginBottom: 8}}>Date <span style={{color:"#333333"}}>{moment(ele.qa_time, 'YYYY-MM-DD HH:mm:ss').toDate().toLocaleString('en-us') || '-'}</span></div>
          <div style={{display: "flex"}}>
              <Col span={(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ? 16 : 24}>
              <span style={{paddingRight: 5, overflow: 'hidden'}}>
                <span className="manrope f-12 black-55" style={{textAlign: 'left',display: 'block', marginBottom: 4}}>
                    Comment
                </span>
                <span style={{display: 'flex'}}>
                <span className="manrope f-12 black-33" style={{marginBottom: 0, width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left'}}>
                    {ele.rejection_details}
                </span>
                </span>
              </span>
              </Col>


            {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

                    <Col span={8}>
                        <div className="category-artist" style={{textAlign: 'left', marginBottom: 7}}>
                            Uploads
                        </div>
                        {ele.reference_files && ele.reference_files.length > 0 ?
                            <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])}>
                            {(ele.reference_files[0].name.includes('.jpg') || ele.reference_files[0].name.includes(".png") || ele.reference_files[0].name.includes(".PNG") || ele.reference_files[0].name.includes(".svg")) ?
                            <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI+ encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])}/> :
                            (ele.reference_files[0].name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                            <img className="upload-image-settings" src={'/img/ele.reference_files[0]-icon.png'}/> }
                            <span className="category-artist" style={{fontSize: 14, textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', width: '90%', whiteSpace: 'nowrap'}}>{ele.reference_files[0].name}</span>
                            </a>
                        : ''}
                    </Col>
            : ''}
          </div>

        </div>
      );
    }

    let detailed_rejection_data = '';
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
     detailed_rejection_data = props.rejectionDetails.map((ele, index) => (
        <div style={{marginTop:2, maxWidth:"941px"}}>
        <span style={{marginTop:15, display:"block" }} className='note-bg-artist manrope f-12'>Date: <span style={{color:"#276DD7", paddingLeft: 3, marginRight:25}}>{moment(ele.qa_time, 'YYYY-MM-DD HH:mm:ss').toDate().toLocaleString('en-us') || '-'}</span>  Activity By: <span style={{marginLeft:5,color:"#276dd7"}}>{ele.qa_person}</span></span>
        {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?
        <div style={{display: "block", margin: 2, padding:2, width:'100%'}} >
              <div>
                <span className="manrope f-14 black-55" style={{textAlign: 'left',display: 'block', marginBottom: 4, width:"50%", float:'left'}}>
                    Comments:
                </span>
                <span className="manrope f-14 black-55" style={{ marginBottom: 4, width:"50%", float:'left'}}>
                    Uploads:
                </span>
              </div>
              <div style={{marginBottom:10, width:'100%'}}>
                <span className="manrope f-12 black-33" style={{textAlign:"left",  width:"51%" , float:'left'}}>
                    {ele.rejection_details}
                </span>
              <div style={{float:'left', width:'47%'}}>
                <Carousel style={{width:"100%"}} arrows={true} nextArrow={<RightOutlined />} prevArrow={<LeftOutlined/>}>

                    {ele.reference_files && ele.reference_files.map((file,index) => (

                        <a onClick={handleImage} style={{color:"#276DD7", lineHeight: '120%'}} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                        <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                        <img className="upload-image-settings-reject" src={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                        (file.name.includes(".tiff") ) ?  <img className="upload-image-settings-reject" src={'/img/tiff_icon.png'}/> :
                        <img className="upload-image-settings-reject" src={'/img/file-icon.png'}/> }
                        </div>
                        <p className="manrope f-12" style={{textAlign: 'center', color: '#555555'}}>{file.name}</p>
                        </a>

                    ))}

                </Carousel>
              </div>

          </div>
        </div>
        :
        <div className='justify-in-start' style={{marginTop: 4}}>
            <span className="manrope f-14 black-55" style={{textAlign: 'left', width:"10%", float:'left'}}>
                Comments:
            </span>
            <span className="manrope f-12 black-33" style={{textAlign:"left", float:'left'}}>
                {ele.rejection_details}
            </span>
        </div>
        }
      </div>
     ));
    }

    let total_file_size = 0;
    let high_poly_model_size = 0, glb_size = 0, archive_size = 0;
    if(props.roomReducer.room.model_info != undefined) {
        high_poly_model_size =props.roomReducer.room.model_info.size_high_poly_model || 0;
        glb_size = props.roomReducer.room.model_info.glb_size || 0;
        total_file_size = high_poly_model_size + glb_size;
    }

    if(high_poly_model_size == 0){
        // high poly and Vray size not found in DB so doing the head request and calculating the archive size
        props.getArchiveSize();
        archive_size = props.archiveSize/(1024*1024); //converting in MBs
        total_file_size = total_file_size + archive_size;
    }

    if(glb_size == 0){
        // glb size was not found in DB so doing the head request and calculating the glb size
        props.getGlbSize();
        glb_size = props.glbSize/(1024*1024); // converting the size in MBs
        total_file_size = total_file_size + glb_size;
    }

    const generate_menu = (
        <Menu style={{ width: "145px",fontFamily:"Manrope" }}>
            <SubMenu 
                title={
                        <Tooltip title={"You can make adjustments like, change the colour, size, textures,  etcetera and request a variation of this space model."}>
                            <span style={{cursor: "pointer"}}>Space Variation</span>
                        </Tooltip>
                    }
                popupOffset={[0, 0]}
            >
                <Menu.Item onClick={() => {showSpaceVariationModal()}}
                >
                    <Tooltip title={"You can make adjustments like color and texture changes through the configurator tool and request a variation of this space model."}>
                            <span style={{cursor: "pointer"}}>Color & Texture Variation</span>
                    </Tooltip>
                    
                </Menu.Item>
                {([5].includes(parseInt(props.roomReducer.room.model_status))) && 
                    <Menu.Item key={0}  onClick = {props.openVariationModal}>
                        <Tooltip title={"You can make adjustments like size etcetera and request a variation of this space model."}>
                            <span style={{cursor: "pointer"}}>Other Variations</span>
                        </Tooltip>
                    </Menu.Item>
                }
            </SubMenu>
            {([4,5].includes(parseInt(props.roomReducer.room.model_status))) && 
                <Menu.Item key={1}  onClick = {() => {handleCustomerDummyScene()}}>
                    <Tooltip title={"You can create different images for this space model"}>
                        <span style={{cursor: "pointer"}}>Space Image</span>
                    </Tooltip>
                </Menu.Item>
            }

        </Menu>
    );

    const leftScroll = () => {
        var elem = document.getElementById('review-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft -= 273;
            var left = document.getElementById('left-scroll-btn');
            var right = document.getElementById('right-scroll-btn');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            
            if (right!=null) {
                right.style.visibility = 'visible';
                elem.style.left = "20px";
            }
        }
    }

    const rightScroll = () => {
        var elem = document.getElementById('review-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft += 273;
            var left = document.getElementById('left-scroll-btn');
            var right = document.getElementById('right-scroll-btn');
            elem.style.left = "94px";
            if (right != null) {
                if (left != null) {
                    left.style.display = "inline-block";
                }
                
                var newScrollLeft = elem.scrollLeft;
                var divWidth = elem.offsetWidth;
                var scrollwidth = elem.scrollWidth;
                if(newScrollLeft === scrollwidth - divWidth){
                    right.style.visibility = 'hidden';
                }
            }
            
        }
    }

    const checkScroll = () => {
        var elem = document.getElementById('review-scrollable');
        var carousel_elem = document.getElementById('pdp-carousel-ref'); 
        if (elem != null) {
            elem.style.scrollBehavior = "unset";

            var y1 = elem.scrollTop;  
            elem.scrollTop += 1; 
            var y2 = elem.scrollTop ; 
            elem.scrollTop -= 1; 
            var y3 = elem.scrollTop; 
            elem.scrollTop = y1; 
        
            var x1 = elem.scrollLeft;  
            elem.scrollLeft += 1; 
            var x2 = elem.scrollLeft; 
            elem.scrollLeft -= 1; 
            var x3 = elem.scrollLeft; 
            elem.scrollLeft = x1; 
            var right = document.getElementById('right-scroll-btn');
            var left = document.getElementById('left-scroll-btn');
            let nextValue = elem.scrollLeft - 273;

            if (elem.style.left == "94px") {
                elem.style.left = "94px";
            }
            else {
                if ( elem.scrollLeft == 0) {
                    elem.style.left = "20px";
                }
            }
            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
                if (left != null) {
                    if (elem.style.left == "20px" &&  elem.scrollLeft == 0) {
                        left.style.display = "none"; 
                    }
                }
                if (elem.style.left == "20px"  &&  nextValue <= 0) {// for right scroll
                    elem.style.width = "100%";
                    if (props.compareView) {
                        carousel_elem.style.width = "80%";
                    }
                }
                else { // for left scroll
                    elem.style.width = "100%";
                    if (props.compareView) {
                        carousel_elem.style.width = "80%";
                    }
                }
            }
            if (!props.compareView && props.adminScrollCheck) {
                elem.style.width = "100%";
                elem.style.left = "20px";
            }

            if (right != null  && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
            }
            if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // right.style.visibility = 'hidden';
                left.style.display = "none";
            }
            // ---- NEED FOR LATER USE ----
            // console.log("H",x1 !== x2 || x2 !== x3); 
            // console.log("V",y1 !== y2 || y2 !== y3);
        }
    }     

    const topAndTestRenders = () => {
        let card_img = []
        let index = 0
        if(props.roomDetails.test_renders != undefined){
            Object.keys(props.roomDetails.test_renders).forEach((key) => {
                if(!props.roomDetails.test_renders[key].is_hidden) {
                    let ele = props.roomDetails.test_renders[key];
                    let file_url = ENVIRONMENT.getBaseURL(props.roomDetails.platform) + base_uri + ele.uid + '/' + ele.name;
                    const user_type = ele.generated_by === 'Customer' ? 'Cust.' : ele.generated_by ? ele.generated_by : 'Artist'
                    const render_type = props.roomDetails.test_renders[key].type;
                    const scene_id = user_type == 'Cust.' ? props.roomDetails.customer_dummy_scene_id : props.roomDetails.dummy_scene_id
                    if(ele.status == 'rendered'){
                        file_url = ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + scene_id + '/' + ele.filename;
                    }
                    else if(ele.status == 'pending'){
                        file_url = ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + scene_id + '/' + ele.preview_render.replace('tiff','jpg');
                    }
                    index++;
                    let test_renders_subset = 
                        <Col>
                            {render_type == "360" ?
                                <Tooltip title={"360"} placement="top">
                                <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{marginRight:"10px"}}>
                                    <div className="product-view-icon" onClick={() => {props.changeSelectedImage("Test_" + ele.filename, file_url); setDisplay360(true);}}>
                                        <span style={{background: "url(/img/360-tn.jpg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                                        <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", padding: "5px 0px" }}>
                                        <div style={{
                                            borderRadius: "4px",
                                            background: "rgba(0, 0, 0, 0.3)",
                                            width: "70%",
                                            margin: "auto",
                                        }}><span className="tag-txt">{user_type}</span></div>
                                        </div>
                                    </div>
                                </Card>
                                </Tooltip>
                            :
                            <Card key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className="product-thumbnail-card" style={{ border: "1px solid #f0f0f0" }}>
                                <div onClick={() => (setSelectedRenderUrl(file_url), setSelectedRenderStatus(ele.status) , setDisplay360(false))} style={{ cursor: "pointer" }}>
                                    <img style={{ cursor: "pointer", height: "40px", width: "40px", objectFit: "contain", display: "block" }} src={file_url} />
                                    <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", padding: "5px 0px" }}>
                                        <div style={{
                                            borderRadius: "4px",
                                            background: "rgba(0, 0, 0, 0.3)",
                                            width: "70%",
                                            margin: "auto",
                                        }}><span className="tag-txt">{user_type}</span></div>
                                    </div>
                                </div>
                            </Card>
                            }
                        </Col>
                    if (key === 'product_photos_all')
                    {
                        card_img.unshift(test_renders_subset);
                    }
                    else{
                        card_img.push(test_renders_subset);
                    }
                }
            });
        }
        if(props.roomDetails.top_view_render != ""){
            let top_view_render_url = ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.SPACE_TOP_VIEW_RENDER_URI + props.roomDetails.top_view_render
            let top_view_render_html = <Col>
                                            <Card key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className="product-thumbnail-card" style={{ border: "1px solid #f0f0f0" }}>
                                            <div onClick={() => (setSelectedRenderUrl(top_view_render_url), setSelectedRenderStatus('') , setDisplay360(false))} style={{ cursor: "pointer" }}>
                                                <img style={{ cursor: "pointer", height: "40px", width: "40px", objectFit: "contain", display: "block" }} src={top_view_render_url} />
                                                <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", padding: "5px 0px" }}>
                                                    <div style={{
                                                        borderRadius: "4px",
                                                        background: "rgba(0, 0, 0, 0.3)",
                                                        width: "70%",
                                                        margin: "auto",
                                                    }}><span className="tag-txt">Top</span></div>
                                                </div>
                                            </div>
                                        </Card>
                                        </Col>
            card_img.push(top_view_render_html);
            index++;
        }
        return card_img
    }

    return(
        <>
       
        <CustomerMainLayout selected='5'>
        {props.roomReducer.room.model_status && props.roomReducer.room.model_status != 4 &&
        props.roomReducer.room.model_status != 5 ?<Row className='model-inprogress-box'>
            <Row type="flex" style={{ justifyContent: "flex-start" }} className='w-100'>
                    <Col span={12}>
                        <div className={'validation-uploaded-model-warning-div'}>
                            <Card title={
                                <div>
                                    <ClockCircleOutlined className='validation-uploaded-model-header-warning' />
                                    <span className={'validation-uploaded-model-header-warning'}>
                                        {(props.roomReducer.room.model_status == -4 || props.roomReducer.room.model_status == -5) ? 'Space is being fixed': 'Space model is in progress'}
                                    </span>
                                </div>}>
                                <span className='manrope f-14 validation-uploaded-model-success'>
                                {(props.roomReducer.room.model_status == -4 || props.roomReducer.room.model_status == -5) ? 'Space is currently unavailable as it is being fixed by ALL3D' : 'Please wait while our system is processing your model'}     
                                </span>
                            </Card>
                        </div>
                    </Col>
            </Row>
        </Row>:<></>}
            {
            (FileConstants.isMSProvider) &&
            props.roomReducer.room.model_status == 4 && props.selectedSpace.requested_for != ''
            &&
            <Row style={{marginTop:20}}>
                <Col span={24}>
                <div className="custom-alert info">
                You requested this Space for customer {props.selectedSpace.requested_for}
                </div>
                </Col>
            </Row>
            }
        {props.roomReducer.isLoading ? <DottedLoader/> :
        
        props.userAccess == 'restricted' ? 
        <EntityAccessDenied 
            entityType={'space'} />
            :
        <Row type="flex" justify="space-around" className="space-side-margins" style={{marginTop:"20px"}}>
        <Col span={24}>
            { (props.roomReducer.room.model_status == '4') ?
                <Row type="flex" style={{justifyContent: "space-between"}}>
                    <h2  className="manrope f-14 w-600" style={{marginBottom: 10,alignSelf:"center"}}>
                        <Link className="store-bd" to={`/rooms`} style={{color:"#999999"}}>Space QA</Link> / <span className="active-route-link-color">{props.roomReducer.room.room_name}</span>
                    </h2>


                </Row>
                :
                ""
            }

            {(props.roomReducer.room.model_status == 5) ?
                <div style={{display: "flex", justifyContent: "space-between",alignItems:"baseline"}}>
                    <h2  className="manrope f-14 w-600" style={{marginBottom: 10, cursor: 'pointer'}}><span onMouseDown={redirectPage} className="store-bd">{props.storePage == 0 ? "Space Library" : "Store"}</span>
                    {props.roomReducer.room.category ? 
                        <span className="active-route-link-color">
                        {' / ' + props.roomReducer.room.category + ' / ' + props.roomReducer.room.room_name}
                        </span> :
                        ""
                        }

                    </h2>
            </div> : ''}
        <Row style={{display: 'flex'}}>
        { ( (props.roomReducer.room.model_status == 5 || props.roomReducer.room.model_status == 4) && props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length == 0) ?
            // Single Area 360
            <Col span={props.compareView ? 12 : 24}>
                <Card className={props.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} style={{overflowY: 'hidden'}} bodyStyle={{padding: props.isFullScreen ?0:8,overflow:'hidden'}}>
                        {selectedRenderStatus === 'pending' ? (
                                            <div className="thumbnail-btn-bg blur">
                                                <span className="thumbnail-btn">Render in Progress...</span>
                                            </div>
                                        ) : null} 
                        {
                            !display360 && (props.roomDetails.top_view_render!= "" || props.roomDetails.test_renders!= "")?
                                <ImageZoomAdmin custom_class={props.isFullScreen ? selectedRenderStatus === 'pending' ? fullscreen_image_style_in_progress :fullscreen_image_style : selectedRenderStatus === 'pending' ? image_style_in_progress : image_style} compare={true} notShowToolButtons={true} image={selectedRenderUrl} />
                            :
                            ""
                        }
                    {(props.roomReducer.room.model_status == 4 && !props.isFullScreen && !props.compareView &&  props.roomReducer.room.room_photos && props.roomReducer.room.room_photos.length > 0) ?
                        <Button style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"75px"}} className='modal-okay square comparison-btn admin-compare'  onClick={() => (props.setCompareView(!props.compareView), forceUpdate())}>
                            <span className="modal-okay-text">{props.compareView == false ? <span>Comparison View <ArrowRightOutlined/></span> : ""}</span>
                        </Button> :
                    ""}
                    
                    <div className='pdp-carousel-scroll' id='pdp-carousel-ref' >
                        <div className="icon-con-left" id="left-scroll-btn" onClick={leftScroll}>
                            <RightOutlined  className="left-button-scr" />
                        </div>
                        <div id="review-scrollable" className="pdp-scrollable">
                            <Tooltip title={"360"} placement="top">
                                <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{marginRight:"10px"}}>
                                    <div className="product-view-icon" onClick={() => {props.changeSelectedImage("360Camera", ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + props.params.id + '/360Camera.jpg'); setDisplay360(true);}}>
                                        <span style={{background: "url(/img/360-tn.jpg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                                    </div>
                                </Card>
                            </Tooltip>
                            {topAndTestRenders()}
                        </div>
                        <div className="icon-con" id="right-scroll-btn" onClick={rightScroll}>
                            <RightOutlined  className="right-button-scr" />
                        </div>
                    </div>
                        
                    {!props.compareView ?
                    <Tooltip title={(props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                        <img
                        src={props.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}
                        alt="Fullscreen Icon"
                        style={{position:"absolute", bottom:"7px",zIndex:"8",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"8px"}}
                        className="product-full-screen-icon"
                        onClick={() => props.setFullScreen(!props.isFullScreen)} type={(props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                    </Tooltip> : "" }
                    {(!props.isFullScreen && props.storePage == 0 
                        && (([5, 3, -5].includes(parseInt(props.roomReducer.room.model_status))) 
                        && ([-5, 3].includes(parseInt(props.roomReducer.room.model_status)) || props.roomReducer.room.is_store_item == "" || props.roomReducer.room.customer_username == localStorage.getItem("username") || props.roomReducer.room.company_id == FileConstants.COMPANY_ID || FileConstants.isMSProvider)) ? 
                        <div style={{ position: "absolute", zIndex: 2, top: "20px", right: props.roomReducer.room.model_status == 4 ? "" :"20px",left:  props.roomReducer.room.model_status == 4 ? 20 : ""} } id="components-dropdown-demo-dropdown-button">
                            {props.roomReducer.room.customer_username == FileConstants.CUSTOMER_USERNAME || props.roomReducer.room.company_id == FileConstants.COMPANY_ID || FileConstants.isMSProvider || (new URLSearchParams(window.location.search).has('project_id')) ?
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button className="modal-okay square font-14">Download Links <DownOutlined /></Button>
                            </Dropdown> : "" }
                        </div> : "")}
                        {props.roomReducer.isLoading ? <LegacyIcon className="model-pending-msg" type="sync" spin />: <div>
                            {props.roomReducer.room.model_status == '2' ? <h3 className="model-pending-msg">Model will be ready by 24 hours.</h3>
                            : ''}
                        </div>}
                         <div id={props.selectedImage} style={{cursor:"grabbing",background:"white", display:( display360 ?"block":"none")}} >
                        </div>
                        <div id={`loading-container-${props.selectedImage}`}>
                            <DottedLoader id={`loadingImages-${props.selectedImage}`}/>
                        </div>
                </Card>
            </Col>
            :
            (props.roomReducer.room.model_status == 5 || props.roomReducer.room.model_status == 4) && (props.roomReducer.room.three_sixties) ?
                // Multi Area 360
                <Col span={props.compareView ? 12 : 24}>
                    <Card className={props.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} style={{overflowY: 'hidden'}} bodyStyle={{padding: props.isFullScreen ?0:8,overflow:'hidden'}}>
                        {selectedRenderStatus === 'pending' ? (
                            <div className="thumbnail-btn-bg blur">
                                <span className="thumbnail-btn">Render in Progress...</span>
                            </div>
                        ) : null} 
                        {
                            !display360 && (props.roomDetails.top_view_render!= "" || props.roomDetails.test_renders!= "")?
                                <ImageZoomAdmin custom_class={props.isFullScreen ? selectedRenderStatus === 'pending' ? fullscreen_image_style_in_progress :fullscreen_image_style : selectedRenderStatus === 'pending' ? image_style_in_progress : image_style}  compare={true} notShowToolButtons={true} image={selectedRenderUrl} />
                            :
                            ""
                        }
                        {(props.roomReducer.room.model_status == 4 && !props.isFullScreen && !props.compareView &&  props.roomReducer.room.room_photos && props.roomReducer.room.room_photos.length > 0) ?
                            <Button style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"75px"}} className='modal-okay square comparison-btn admin-compare'  onClick={() => (props.setCompareView(!props.compareView), forceUpdate())}>
                                <span className="modal-okay-text">{props.compareView == false ? <span>Comparison View <ArrowRightOutlined/></span> : ""}</span>
                            </Button> :
                        ""}
                        {!props.compareView ?
                        <Tooltip title={(props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                            <img
                            src={props.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}
                            alt="Fullscreen Icon"
                            style={{position:"absolute", bottom:"20px",zIndex:"8",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}}
                            className="product-full-screen-icon"
                            onClick={() => props.setFullScreen(!props.isFullScreen)} type={(props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                        </Tooltip> : ""}
                        {(!props.isFullScreen && props.storePage == 0 
                            && (([5, 3, -5].includes(parseInt(props.roomReducer.room.model_status))) 
                            && ([-5, 3].includes(parseInt(props.roomReducer.room.model_status)) || props.roomReducer.room.is_store_item == "" || props.roomReducer.room.customer_username == localStorage.getItem("username") || props.roomReducer.room.company_id == FileConstants.COMPANY_ID)) ? 
                            <div style={{ position: "absolute", zIndex: 2, top: "20px", right: props.roomReducer.room.model_status == 4 ? "" :"20px",left:  props.roomReducer.room.model_status == 4 ? 20 : ""} } id="components-dropdown-demo-dropdown-button">
                                {props.roomReducer.room.customer_username == FileConstants.CUSTOMER_USERNAME || props.roomReducer.room.company_id == FileConstants.COMPANY_ID ?
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button className="modal-okay square font-14">Download Links <DownOutlined /></Button>
                                </Dropdown> : ""}
                            </div> : "")}
                        <div id={props.selectedImage} style={{cursor:"grabbing",background:"white", display:( display360 ?"block":"none")}}>
                        </div>
                        
                        <div id={`loading-container-${props.selectedImage}`}>
                            <DottedLoader id={`loadingImage-${props.selectedImage}`}/>
                        </div>
                        
                        <div className='pdp-carousel-scroll' id='pdp-carousel-ref' style={{zIndex: 999}}>
                            <div className="icon-con-left" id="left-scroll-btn" onClick={leftScroll}>
                                <RightOutlined  className="left-button-scr" />
                            </div>
                            <div id="review-scrollable" className="pdp-scrollable">
                            {props.roomReducer.room.three_sixties.map((name,index) => (
                                <Tooltip title={name.split(".")[0]} placement="top">
                                    <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{marginRight:"10px"}}>
                                        <div className="product-view-icon" onClick={() => { props.changeSelectedImage(name, ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + props.params.id + '/' + name); setDisplay360(true);}}>
                                            <span style={{background: "url(/img/360-tn.jpg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                                        </div>
                                    </Card>
                                </Tooltip>))}
                                {topAndTestRenders()}
                            </div>
                            <div className="icon-con" id="right-scroll-btn" onClick={rightScroll}>
                                <RightOutlined  className="right-button-scr" />
                            </div>
                        </div>
                    </Card>
                </Col>

                :
                ""
            }

            {props.roomReducer.room.model_status == 4 && props.compareView == true ?
                <Col span={props.compareView ? 12 : 24}>
                <Card className={props.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: props.isFullScreen ?0:8}}>
                    {(props.roomReducer.room.model_status == 4 && !props.isFullScreen && props.compareView &&  props.roomDetails.room_photos && props.roomDetails.room_photos.length > 0) ?
                    <Button style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"20px"}} className='modal-okay square comparison-btn admin-compare'  onClick={() => (props.setCompareView(!props.compareView), forceUpdate())}>
                        <span className="modal-okay-text">{props.compareView == false ? <span>Comparison View <ArrowRightOutlined/></span> : <span><ArrowLeftOutlined/> Back to Original View</span>}</span>
                    </Button> : ""}
                        {props.roomReducer.isLoading ? <LegacyIcon className="model-pending-msg" type="sync" spin />: <div>
                            {props.roomReducer.room.model_status == '6' ? <h3 className="model-pending-msg">Model is being Processed.</h3>
                            : ''}
                        </div>}
                        {props.roomReducer.room.model_status != 2 ?
                            <RoomPhotosViewer rejectionDetails={props.rejectionDetails}  roomDetails={props.roomDetails} compareView={props.compareView} adminScrollCheck={false} adminCheck={true} embed={false} />
                        : ""}
                </Card>
                </Col>
                :
                ""
            }

        </Row>

                        <Row type="flex" style={{ justifyContent: "space-between", marginTop: "22px",marginBottom:"12px" }}>
                            <Col span={10} className="justify-in-start">
                                <Tooltip title={<span className="manrope f-12 white">{props.roomReducer.room.room_name}</span>}>
                                    <span className="manrope f-20 black-33 clamp-text w-60 capitalize">
                                        {props.roomReducer.room.room_name}
                                    </span>
                                </Tooltip>
                                    <span>
                                    {props.roomReducer.room.model_status == 5 && props.storePage == 0 && props.userAccess == 'edit' ?
                                    <Tooltip title={<span className="manrope f-12 white">Edit Space Info</span>}>
                                        <img style={{width: 30, height: 30}} id="edit-icon" src ="/img/edit-w-hover.png" className="edit-icon-data" onClick={props.openEditForm} onMouseOut={props.onMouseOut} onMouseOver={props.onMouseOver}/>
                                    </Tooltip>: ''}
                                    </span>
                            </Col>
                            <Col span={12} className='justify-in-end'>
                                {(props.roomReducer.room.model_status == 5 || props.roomReducer.room.model_status == 4) && props.storePage == 0 && !window.location.href.includes("msp_spaces")  ?
                                <Button className="modal-okay-invert square font-14" style={{marginRight: "16px"}} onClick={() => props.setSharingModal(true)}>
                                    Share Space
                                </Button>: ''}


                                {([4,5].includes(parseInt(props.roomReducer.room.model_status))) && props.storePage == 0 && props.userAccess == 'edit' ?
                                <div style={{display:"flex",justifyContent:"flex-end"}}>
                                    <Dropdown overlay={generate_menu} trigger={['click']}>
                                        <Button className="modal-okay-orange square font-14">Generate&nbsp;<DownOutlined/></Button>
                                    </Dropdown>
                                </div>
                                : ''}
                            </Col>
                        </Row>
            

        {/* Room Rejection Details */}
        {rejections_data  && props.storePage == 0   ?
            <Row style={{marginBottom: 16, marginTop: 15}}>
                <Col sm={24} lg={24} xl={12} className="note-bg-artist pd-8 red">

                    <div className='justify-space-between'>
                        <div className='manrope f-14 red' style={{display: 'inline'}}>
                            Rejection Comments
                        </div>
                        <Button type="link" onClick={() => {showRejectionModal()}} className='manrope f-14 blue hover-change' style={{display: 'inline', position: 'absolute', right: 20}}>
                            View Details
                        </Button>
                    </div>

                    {rejections_data}
                </Col>
            </Row> : ""}

        <Row type="flex" style={{justifyContent: "space-between", marginTop: "0px",alignItems:'flex-start'}}>
            <Col span={14}>
                <div className="justify-in-start" style={{flexWrap: 'wrap',alignItems: 'flex-start'}}>
                    {props.roomReducer.room.category ?
                    <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Category</div>
                    <div className="manrope f-12 black-33 w-600">
                    {props.roomReducer.room.category}
                    </div>
                    </Col>: ''}
                    {props.roomReducer.room.style && props.roomReducer.room.style.length > 0 ?
                        <Col span={6} style={{marginBottom:"10px"}}>
                            <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Style</div>
                            <div className="manrope f-12 black-33 w-600">
                            {props.roomReducer.room.style}
                            </div>
                        </Col>: ''}
                    
                    {props.roomReducer.room.brand_id ?
                    <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Item ID</div>
                    <div className="manrope f-12 black-33 w-600">
                    {props.roomReducer.room.brand_id}
                    </div>
                    </Col>
                    : ""}

                    {props.roomReducer.room.licensing_options && props.roomReducer.room.licensing_options.license ?
                    <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>License</div>
                        <div className="manrope f-12 black-33 w-600 capitalize">{props.roomReducer.room.licensing_options.license == 'private' ? props.roomReducer.room.licensing_options.license : ''}
                            {props.roomReducer.room.licensing_options.license == 'public' ? props.roomReducer.room.licensing_options.public_option == 'forCreativeCommon' ?
                            <Tooltip title={<span className='manrope f-12 white'>{props.roomReducer.room.licensing_options.creative_common}</span>}>
                                <img style={{marginLeft: 5}} src={FileConstants.getLicensingIcon(props.roomReducer.room.licensing_options.creative_common)}/>
                            </Tooltip>:
                             <span>
                                <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                                    <img src={FileConstants.getLicensingIcon(props.roomReducer.room.public_option)}/>
                                </Tooltip>
                                &nbsp;<Tooltip title={<span className='manrope f-12 white'>Not Downloadable</span>}><img src='/img/not_downloadable.png'/></Tooltip>
                            </span> : '' }
                        </div>
                    </Col> : ""}

                    {props.roomReducer.room.reference_urls && props.roomReducer.room.reference_urls.length > 0 && (props.storePage === 0 || FileConstants.ALL3D_COMPANY_IDS().includes(COMPANY_ID))  ?
                        <Col span={6} style={{marginBottom:"10px"}}>
                            <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}> Reference URL(s)</div>
                            {props.roomReducer.room.reference_urls.map((ele, index) => {
                            return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{textAlign:"left",marginBottom:4}}><a href={ele} target='_blank'>{ele}</a></div>
                            })}
                        </Col>
                        : ""}
                        {props.roomReducer.room.raw_files && props.roomReducer.room.raw_files.length > 0  ?
                        <Col span={6} style={{marginBottom:"10px"}}>
                            <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}> Raw Files</div>
                            {props.roomReducer.room.raw_files.map((ele, index) => {
                            return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{textAlign:"left",marginBottom:4}}><a href={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name)} target='_blank'>{ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name)}</a></div>
                            })}
                        </Col>
                        : ""}
                        {props.roomReducer.room.video && props.roomReducer.room.video.length > 0   ?
                        <Col span={6} style={{marginBottom:"10px"}}>
                            <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}> Videos</div>
                            {props.roomReducer.room.video.map((ele, index) => {
                            return <div className="manrope f-12 blue hover-change clamp-text w-60" style={{textAlign:"left",marginBottom:4}}><a href={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name)} target='_blank'>{ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name)}</a></div>
                            })}
                        </Col>
                        : ""}
                         {props.roomReducer.room.immediate_parent_variant && (props.storePage === 0) && (localStorage.getItem("username") == props.roomReducer.room.customer_username || props.roomReducer.room.company_id == localStorage.getItem('company_id')) ?
                                <Col span={6} style={{marginBottom:"10px"}}>
                                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>
                                        Immediate Parent Model:
                                    </div>
                                    <div className="manrope f-12 blue hover-change clamp-text w-60" style={{textAlign:"left",marginBottom:4}}><a href={ENVIRONMENT.getBaseURL(props.baseImmidiateVariantPlatform) + ENVIRONMENT.SPACE_LOW_POLY_URI + props.roomReducer.room.immediate_parent_variant + '.zip'} target='_blank'>{ENVIRONMENT.getBaseURL(props.baseImmidiateVariantPlatform) + ENVIRONMENT.SPACE_LOW_POLY_URI + props.roomReducer.room.immediate_parent_variant + '.zip'}</a></div>
                                </Col>
                            :''}
                        {props.roomReducer.room.variant_of && (props.storePage === 0 ) && (localStorage.getItem("username") == props.roomReducer.room.customer_username || props.roomReducer.room.company_id == localStorage.getItem('company_id')) ?
                                <Col span={6} style={{marginBottom:"10px"}}>
                                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>
                                        Variation Base Model:
                                    </div>
                                    <div className="manrope f-12 blue hover-change clamp-text w-60" style={{textAlign:"left",marginBottom:4}}><a href={ENVIRONMENT.getBaseURL(props.baseVariantPlatform) + ENVIRONMENT.SPACE_LOW_POLY_URI + props.roomReducer.room.variant_of + '.zip'} target='_blank'>{ENVIRONMENT.getBaseURL(props.baseVariantPlatform) + ENVIRONMENT.SPACE_LOW_POLY_URI + props.roomReducer.room.variant_of + '.zip'}</a></div>
                                </Col>
                            :''}

                        {props.roomReducer.room.model_info && props.roomReducer.room.model_info.vertex_count &&
                            <Col span={6} style={{marginBottom:"10px"}}>
                                <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Vertex Count</div>
                                <div className="manrope f-12 black-33 w-600">
                                    {parseInt(props.roomReducer.room.model_info.vertex_count).toLocaleString()}
                                </div>
                            </Col>}

                        {total_file_size > 0 &&
                        <Col span={6} style={{marginBottom:"10px"}}>
                        <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Total Bundle Size</div>
                        <div className="manrope f-12 black-33 w-600">
                        {props.convertByteSize(total_file_size)}
                        </div>
                        </Col>}

                        {props.roomReducer.room && props.roomReducer.room.tags && props.roomReducer.room.tags.length > 0 ?
                            <Col span={6} style={{marginBottom:"10px"}}>
                                <div className="manrope f-12 grey-99" style={{textAlign:"left",marginBottom: 4}}>
                                Tags
                                </div>
                                <div className="justify-in-start" style={{display:"-webkit-inline-box"}}>
                                {props.roomReducer.room.tags && props.roomReducer.room.tags.map((tag, index) => (
                                    <span className="manrope f-12 black-33 w-600">
                                    {index> 0 ? `, ${tag}` : `${tag}`}
                                    </span>
                                    ))
                                }
                                </div>
                            </Col>
                            : ''}

                        {(props.roomReducer.room.guidelines && props.roomReducer.room.guidelines != "" && (props.storePage === 0 || FileConstants.ALL3D_COMPANY_IDS().includes(COMPANY_ID)))?
                            <Col span={6} style={{marginBottom:"10px"}}>
                                <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Guidelines/ Comments</div>
                                {props.roomReducer.room.guidelines.split('\n').map((item, i) => {
                                    return <div className="manrope f-12 black-33 w-600" style={{textAlign:"left",marginBottom:4}} key={i}>{item}</div>
                                })}
                            </Col>
                        : ''}
                </div>
            </Col>
            <Col className='justify-in-end'>
            {( props.roomReducer.room.model_status == 5 ) && (CUSTOMER_USERNAME == props.roomReducer.room.customer_username)  && props.storePage == 0   ?
            <Button className={`modal-okay-invert square font-14 ${props.roomReducer.room.is_store_item == 1 ? `pd-15` : ``}`} style={{marginRight: "16px"}} onClick={() => props.performStoreAction()}>
                {props.roomReducer.room.is_store_item == 1 ? 'Remove from Store' : 'Sell on Store'}
            </Button> : ''}
            </Col>
        </Row>

        <hr/>

        {
            countRoomDimensions > 0
            ?
            <Row style={{marginBottom:10}}>
            <Col span={countRoomDimensions == 1 ? 8 : 10}>
            <Card
            title={"Dimensions (inches)"}
            >
            <Row>
                {
                countRoomDimensions > 1? props.roomReducer.room.validation_report
                && props.roomReducer.room.validation_report.High
                && props.roomReducer.room.validation_report.High.room_dimensions &&
                Object.keys(props.roomReducer.room.validation_report.High.room_dimensions).map((ele, index) => {
                    return(
                        <Col className='dimensions-col' span={12}>
                            <span  className='manrope f-14 grey-99 dimensions-span'>
                                {ele}
                            </span>
                            <span  className='manrope f-12 black-33 w-600 dimensions-span'  >
                                D {parseInt(props.roomReducer.room.validation_report.High.room_dimensions[ele].length)} " W {parseInt(props.roomReducer.room.validation_report.High.room_dimensions[ele].width)} " H {parseInt(props.roomReducer.room.validation_report.High.room_dimensions[ele].height)} "
                            </span>
                        </Col>
                    )
                })
                :
                ''
                }
                {props.roomReducer.room.validation_report
                && props.roomReducer.room.validation_report.High
                && props.roomReducer.room.validation_report.High.room_dimensions &&
                <Col className='dimensions-col' span={countRoomDimensions == 1 ? 18 : 12}>
                    <span className='manrope f-14 grey-99 dimensions-span'>
                        Total
                    </span>
                    <span className='manrope f-12 black-33 w-600 dimensions-span'>
                        D {parseInt(props.roomReducer.room.validation_report.High.dimensions.depth)} " W {parseInt(props.roomReducer.room.validation_report.High.dimensions.width)} " H {parseInt(props.roomReducer.room.validation_report.High.dimensions.height)} "
                    </span>
                </Col>}
            </Row>
            </Card>
            </Col>
            </Row>
            :
            ''
        }


        <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px",alignItems:'center'}}>
            <Col span={16}>
                {props.roomReducer.room.floorplan && props.roomReducer.room.floorplan[0] ?
                    <div style={{marginBottom:"10px"}}>
                        <div className="manrope f-14 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>
                            Floorplan
                        </div>
                        <Col>
                            <Card className="product-artist-card">
                                <Image
                                className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(props.roomReducer.room.floorplan [0].uid + '/' + props.roomReducer.room.floorplan [0].name)}
                                />
                            </Card>
                        </Col>
                    </div>
                : ''}
            </Col>
        </Row>

        {props.storePage == 0 &&
        <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px",alignItems:'center'}}>
            <Col span={16}>
                <div className="justify-in-start" style={{alignItems: 'flex-start'}}>
                    {props.roomReducer.room.room_photos && props.roomReducer.room.room_photos.length > 0 ?
                        <div style={{marginBottom:"10px"}}>
                            <div className="manrope f-14 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Reference Images</div>
                            <Row>
                            {props.roomReducer.room.room_photos.map(photo => (
                                <Col>
                                    <Card className="reference-img-card">
                                        <Image
                                        className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)}
                                        />
                                    </Card>
                                </Col>
                            ))}
                            </Row>
                        </div>
                    : ''}

                </div>
                </Col>
        </Row>}

        {props.storePage == 0 &&
        <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px",alignItems:'center'}}>
            <Col span={16}>
            {props.roomReducer.room.elements && props.roomReducer.room.elements.length > 0 ?
                    <div style={{marginBottom:"12px"}}>

                    <div className="manrope f-14 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>
                        Space Elements
                    </div>

                    <div className="j-start-a-start ">
                    {props.roomReducer.room.elements.map(element => (
                        element ?
                        <Col style={{maxWidth:"25%",marginRight:"50px"}}>
                            <div className="bg-tag" style={{width:'auto',marginBottom:"4px"}}>
                                <span className="manrope f-12 black-55 capitalize">
                                    {element.elementName}
                                </span>
                            </div>
                            {((element.elementImages && element.elementImages.length > 0) || ((element.elementText && !Array.isArray(element.elementText)) || (element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0)  )) &&
                            <div>
                         {/* <Card className="reference-img-card" style={{padding: 8, marginTop: 8}}> */}
                                {element.elementImages && element.elementImages.length > 0 && <div>

                                    <div className="justify-in-start" style={{marginBottom:"4px"}}>
                                    {element.elementImages.map(photo => (
                                        <Card className="reference-img-card">
                                            <Image
                                            className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)}
                                            />
                                        </Card>
                                    ))}
                                    </div>

                                </div>}
                                {element.elementText && !Array.isArray(element.elementText) ?
                                    <div>
                                    <div className="manrope f-12 grey-55" style={{marginBottom: 8}}>Reference URL(s)</div>
                                    <div className="manrope f-12 blue">{element.elementText}</div>
                                </div>:
                                element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0 ?
                                <div>
                                    <div className="manrope f-12 grey-55" style={{marginBottom: 8}}>Reference URL(s)</div>
                                    {element.elementText.map((element_text) => (
                                        <div style={{display:'block',marginBottom: 5}} className="manrope f-12 blue hover-change clamp-w-width"><a target="_blank" href={element_text}>{element_text}</a></div>
                                    ))}
                                </div> :''}
                            {/* </Card> */}
                            </div>
                            }

                        </Col> : ''

                    ))}
                    </div>
                </div>: ''}
            </Col>
        </Row>}

        {props.storePage == 0 &&
        <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px",alignItems:'center'}}>
            <Col span={16}>
            {props.roomReducer.room.outside_view_custom && props.roomReducer.room.outside_view_custom.length > 0 ?
                <div style={{marginBottom:"10px"}}>
                    <div className="manrope f-14 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>
                        Uploaded Outside View
                    </div>
                    <Row>
                        {props.roomReducer.room.outside_view_custom.map(photo => (
                            <Col>
                                <Card className="reference-img-card">
                                    <Image
                                    className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>: ''}
            </Col>
        </Row>}

        {props.storePage == 0 && <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px",alignItems:'center'}}>
            <Col span={16}>
            {props.roomReducer.room.outside_view && props.roomReducer.room.outside_view.image  ?
                <div style={{marginBottom:"10px"}}>
                    <div className="manrope f-14 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>
                        Outside View
                    </div>
                    <Row>
                        <Col>
                            <Card className="reference-img-card">
                                <Image
                                className="reference-img-photo" src={props.roomReducer.room.outside_view.image}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>: ''}
            </Col>
        </Row>}
    </Col>

    </Row>}

    <AddItemToStore
     type={"customer_details"}
     entity_type={"room"}
     visible={props.sellVisible}
     price={props.price}
     add_store_item_loader={props.sellLoader}
     onCancel={props.cancelSellModal}
     selected_item={props.roomReducer.room}
     onChangePrice={props.onChangePrice}
     addToStore={props.setStoreRequests}
    />

    <Modal
    className="modal-share-success"
    width={600}
    centered={true}
    onCancel={() => props.setRemoveFromStore(false)}
    visible={props.removeFromStore}
    closable={false}
    footer={[
        <span className="justify-in-center">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-12" onClick={() => props.setRemoveFromStore(false)}>
                Cancel
            </Button>
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-12" onClick={() => props.removeItemFromStore()}>
                {props.removeLoader? <span> Removing <LoadingOutlined spin /></span>:<span> Okay </span>}
            </Button>
        </span>
    ]}
    >
        <div style={{margin:"30px auto 0px auto",textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <h2 className="manrope f-16 black-00 w-600">You're about to remove this space from store. Are you sure you want to remove?</h2>
        </div>
    </Modal>

    {props.roomReducer.room.model_status == "4" && !window.location.href.includes("msp_spaces") && isApproveAccess ? <ReviewProduct adminForm={false} roomDetail={true} roomDetailModal={false} onApprove={props.onApprove} onReject ={props.onReject} 
    approveLoader={props.approveLoader}
    rejectionLoader={props.rejectionLoader}
    setRejectionLoader={props.setRejectionLoader}
    platform={props.roomReducer.room.platform}
    /> : ''}

    <SuccessModal
    visible={props.successModal}
    onCancel={() =>  props.setSuccessModal(false)}
    heading={"Space Shared Successfully!"}
    text={"This space has been added to the accounts of those people whose email addresses you entered."}
    footer={[
        <div className="justify-in-center">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => {props.setSuccessModal(false); handleClearEmails()}}>
                Okay
            </Button>
        </div>
    ]}
    />

    <SuccessModal
    visible={props.saveSuccessModal}
    onCancel={() =>  props.setSaveSuccessModal(false)}
    heading={"Space Data Updated Successfully!"}
    text={"Space data has been updated, you can edit it again by clicking on the Edit button."}
    footer={[
        <div className="justify-in-center">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => window.location.href = "/rooms/"+props.params.id+`?folder_id=${props.folderParentID}`}>
                Okay
            </Button>
        </div>
    ]}
    />


    <Modal
        className="rejection-image"
        style={{width:'100vw'}}
        closeIcon={
            <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
            style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}

            />
        }
        footer={false} visible={showImage} onCancel={() => {setImageUrl(''); setShowImage(false); }}>
            <a href={imageUrl} target="_blank" >
                <img src={imageUrl} style={{width:'100%', height:"100%"}}/>
            </a>
    </Modal>

    {/* Modal for rejection history */}
    <Modal visible={showDetailedRejection} onCancel={handleCancel} footer={false} width={1000}>
        <div className='manrope f-18 black-14 w-600'>
            Rejection Comments
        </div>
        <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}}>
            <div style={{display:'grid'}}>
                {detailed_rejection_data}
            </div>

        </div>

    </Modal>

    <Modal
        visible={props.editForm}
        className="modal-share"
        width={788}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={props.closeEditForm}
        footer={[
            <div className="justify-in-end">
                <Button disabled={props.buttonLoader} className="modal-okay square font-14" onClick={() => document.getElementById("save-space-data").click()}>
                    Save&nbsp;{props.buttonLoader ? <LoadingOutlined/> : ''}
                </Button>
            </div>
        ]}
    >
        <div style={{padding:"16px 6px 0 14px"}}>
            <div className="justify-space-between" style={{marginBottom: 20}}>
                <h2 className="manrope f-18 black-14">Edit Information</h2>
                <h2 className="manrope f-18 grey-77" style={{cursor:"pointer"}} onClick={props.closeEditForm}>X</h2>
            </div>

            <Form initialValues={{
                room_name: props.roomReducer.room.room_name,
                brand_id: props.roomReducer.room.brand_id,
                category: props.roomReducer.room.category,
                tags: props.roomReducer.room.tags,
                reference_urls: props.roomReducer.room.reference_urls,
                style: props.roomReducer.room.style,
            }}
            onFinish={props.saveSpaceData}
             layout="vertical">
                <Row type='flex' gutter={12}>
                    <Col span={24} style={{marginBottom: 20}}>
                        <Form.Item label={<span className="manrope f-14 black-55">Name</span>} name="room_name" rules={[{ required: true, message: 'Please enter space name.' }]}>
                            <Input disabled={props.buttonLoader} placeholder={'Enter Space Name'} className="manrope f-12 black-55 library-search"/>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{marginBottom: 20}}>
                        <Form.Item label={<span className="manrope f-14 black-55">Space ID</span>} name="brand_id" rules={[{ required: false, message: 'Please enter space ID.' }]}>
                            <Input disabled={props.buttonLoader} placeholder={'Enter Space ID'} className="manrope f-12 black-55 library-search"/>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{marginBottom: 20}}>
                        <Form.Item label={<span className="manrope f-14 black-55">Tags</span>} name="tags" rules={[{ type:'array', required: false, message: 'Please add space tags.' }]}>
                            <Select disabled={props.buttonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter space tags">
                                <Option value="" className="manrope f-12 black-55 select-view"></Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{marginBottom: 20}}>
                        <Form.Item label={<span className="manrope f-14 black-55">Category</span>} name="category" rules={[{ required: true, message: 'Please select a space category.' }]}>
                             <Select
                                className={`manrope f-12 black-55 tree-select-material f-12`}
                                disabled={props.buttonLoader}
                                placeholder="Select your space category"
                                >
                                    {props.spaceCategories && props.spaceCategories.map((category) => {
                                        return <Option className="manrope f-12 black-55 select-view" value={category}>{category}</Option>

                                    })}
                                </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{marginBottom: 20}}>
                        <Form.Item label={<span className="manrope f-14 black-55">Style</span>} name="style" rules={[{ required: false, message: 'Please select a space style.' }]}>
                             <Select
                                className={`manrope f-12 black-55 tree-select-material item-height-35`}
                                disabled={props.buttonLoader}
                                placeholder="Select your space style"
                                >
                                    {FileConstants.SPACE_STYLES.map((style) => {
                                        return <Option className="manrope f-12 black-55 select-view" value={style}>{style}</Option>

                                    })}
                                </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={<span className="manrope f-14 black-55">Reference URL(s)</span>} name="reference_urls" rules={[{ type:'array', required: false,  message: 'Please add reference url(s).' }]}>
                            <Select disabled={props.buttonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter Reference URL(s) that you would like us to have a look at, for your space model">
                                <Option value="" className="manrope f-12 black-55 select-view"></Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {props.errorMessage == "" ? '' :
                    <Col span={24}  style={{marginTop: 20}}>
                        <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{props.errorMessage}</span></div>
                    </Col>}
                    <Button id="save-space-data" type="primary" htmlType="submit" style={{"display": "none"}}>
                        Submit
                    </Button>
                </Row>
            </Form>
        </div>
    </Modal>
    <SpaceVariationRequestModal
    cardSaveDrawer={props.cardSaveDrawer}
    setCardSaveDrawer={props.setCardSaveDrawer}
    requestType={"variation_room_model"}
    requestStatus={props.requestStatus}
    cardStatus={props.cardStatus}
    setCardSaveFailure={props.setCardSaveFailure}
    cardSaveFailure={props.cardSaveFailure}
    paymentRequestID={props.paymentRequestID}
    variationLoader={false}
    variationModal={props.variationModal}
    closeVariationModal={props.cancelVariationModal}
    savedID={props.savedID}
    submitVariation={props.submitVariation}
    space_details={props.savedID == -1 ? props.roomReducer.room: props.spaceDetails}
    space_id={props.params.id}
    />

    <SuccessModal
    visible={props.variationSuccessModal}
    onCancel={() =>  props.setVariationSuccessModal(false)}
    heading={"Space Model Variation Requested Successfully!"}
    text={"Your Space Model's Variation request has been submitted. You will be notified via email once your Space Model is ready to be reviewed."}
    footer={[
        <div className="justify-in-center">

            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => window.location.reload()}>
               Okay
            </Button>
        </div>
    ]}
    />
    <SuccessModal
    visible={props.saveModal}
    onCancel={() =>  props.setSaveModal(false)}
    heading={"Information Saved Successfully!"}
    text={"You can come back any time by reopening this form or from the incomplete section to fill out the remaining information."}
    footer={[
         <div className="justify-in-center">

            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => window.location.reload()}>
               Okay
            </Button>
        </div>
    ]}
    />
    <SuccessModal
    visible={props.approvalSuccess}
    onCancel={() =>  props.setApprovalSuccess(false)}
    heading={"Space Approved Successfully"}
    text={"You have successfully approved this space. Click okay to continue."}
    footer={[
         <div className="justify-in-center">
            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => window.location.reload()}>
               Okay
            </Button>
        </div>
    ]}
    />
    <WarningModal
    visible={props.cardSaveFailure}
    onCancel={() => props.setCardSaveFailure(false)}
    heading={"Unable to Save Payment Details"}
    text={"Failed to process payment details. Please try another payment method."}
    footer={[
    <div className="justify-in-end">
        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => props.setCardSaveFailure(false)}>
            Okay
        </Button>
    </div>
    ]}/>
    <Modal
    visible={dummySceneSetup}
    closable={false}
    footer={null}
    >
        <div>
            <span><LoadingOutlined className='loading-center mb-20'/></span>
             <h2 style={{textAlign: "center"}} className="manrope f-18 black-00 w-600 capitalize">Please Wait!</h2>
             <h2 style={{textAlign: "center"}} className="manrope f-16 black-00 w-500">We are setting up the space for you.</h2>
        </div>
    </Modal>
    <Modal
    visible={dummySceneError}
    closable={true}
    onCancel={()=>{
        setDummySceneError(false);
        setDummySceneSetup(false);
    }}
    footer={null}
    >
        <div>
            <span><LoadingOutlined className='loading-center mb-20'/></span>
             <h2 style={{textAlign: "center"}} className="manrope f-18 black-00 w-600 capitalize">Error Occurred</h2>
             <h2 style={{textAlign: "center"}} className="manrope f-16 black-00 w-500">An error occurred while setting up the space. Please try again later.</h2>
        </div>
    </Modal>
    <SpaceColorVariationModal openVariationTool={()=> handleCustomerDummyScene()} spaceColorVariationModal={spaceColorVariationModal} setSpaceColorVariationModal={setSpaceColorVariationModal} />
    </CustomerMainLayout>
    {props.roomReducer.room.model_status && props.roomReducer.room.model_status != 4 &&
        props.roomReducer.room.model_status != 5 ? (
          <div
            style={{
              background: "#FFFFFF",
              boxShadow: "0px -8px 25px rgba(0, 0, 0, 0.04)",
              position: "absolute",
              bottom: "2px",
              right: 0,
              backgroundColor: "#D8D8D8",
              padding: "20px",
              borderRadius: "5px",
            }}
            className="bottom-bar"
          >
            <Row type="flex" style={{ justifyContent: "flex-end" }}>
              <h4></h4>
              <div>
                <Button
                  disabled={true}
                  type="default"
                  size="large"
                  className="inprogress-btn"
                >
                  <div className="accept-btn-text red">In Progress</div>
                </Button>
              </div>
            </Row>
          </div>
        ) : (
          ""
        )}
    </>)
}


export default RoomDetailsView;