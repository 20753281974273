import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import ENVIRONMENT from '../../../../environments';
import '@ant-design/compatible/assets/index.css';
import AWS from 'aws-sdk';
import axios from 'axios';
import { Row, Button, message, Modal, Input, Upload, Select} from 'antd';
import * as Constants from "../Constants";
import { BUCKET_NAME } from '../../../../environments/env';

const { TextArea } = Input;
const { Option } = Select;

const ReviewProduct = (props) => {
    const [visible, setVisible] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [refFileList, setRefFileList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [artistList, setArtistList] = useState([{artist_profile: props.assigned_artist, hourly_rate: null}]);
    const { getFieldDecorator } = props.form;

    const onApprove = () => {
        props.onApprove();
        const isAdminQA = props?.isAdminQA || false;
        if (props.adminForm && props.adminRoom && !(isAdminQA)) {
            message.info("Room status updated successfully!");
        }
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const loadArtistsData = () => {

        axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
        })
          .then(res => {
            let artist_list = [];
            res.data.map(artist => {
                if (artist.is_active == true || artist.is_active == "True") {
                  artist_list.push(artist);
                }
              });

              setArtistList(artist_list);
          });
      }

    useEffect(() => {
        loadArtistsData();
    }, []);

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const handleChangeRefFile = info => {
        let fileList = [...info.fileList];
        setRefFileList(fileList);
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            message.error(`${info.file.name} file upload failed.`);
            setHasError(true);
            setUploading(false);
    
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        props.form.validateFields((err, values) => {
            if (!err) {
                if (props.adminForm) {
                    if (values.reference_files == undefined) {
                        values.reference_files = props.referenceFiles;
                    }
                }
                let uploadStatus = validateFileUploads(values['reference_files']);
                if (uploadStatus == 'uploading') {
                    setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                }
                else if (uploadStatus == 'error') {
                    setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                }
                else if (uploadStatus == 'done' || uploadStatus == 'not_started') {
                    props.setRejectionLoader(true);
                    props.onReject(values);
                }

            }
        });
    }

    return <div className={props.roomDetailModal ? "room-status-review-modal" : (props.adminForm ? "product-status-review-admin" : "product-review-btns" )} style={{background:"#FFFFFF",boxShadow:"0px -8px 25px rgba(0, 0, 0, 0.04)"}}>
         <Row type="flex" style={{ display: "flex", justifyContent: (props.adminForm || props.roomDetailModal) ? "space-between" : "flex-end",margin: (props.adminForm || props.roomDetailModal) ? 0: "0px 5p%"}}>
                {(props.adminForm || props.roomDetailModal) ? <h4> </h4> : "" }
               {props.adminForm ?  
               <div  className='justify-in-end'>
                <Button className="outline-red-btn square font-14" disabled={false}  onClick={()=> {props.setApproveToCustomerLibrary(true);}}>Approve and Add to Customer's Library</Button>
                <Button className="outline-red-btn square font-14" disabled={false} type="default" onClick={()=> setVisible(true) } style={{ marginLeft: "10px" }}>{props.rejectTxt}</Button>
                <Button className="modal-okay-green square font-14" type="danger" onClick={()=> onApprove()} style={{ marginLeft: "10px" }}>{props.approveTxt}</Button>
                </div> : 
               <div className='justify-in-end'>
                    <Button className="outline-red-btn square font-14" type="default" onClick={()=> setVisible(true)}>
                        Reject
                    </Button>
                    <Button className="modal-okay-green square font-14" type="danger" onClick={()=> onApprove()} style={{marginLeft: "10px"}} disabled={props.approveLoader} loading={props.approveLoader}>
                        Approve
                    </Button>
                </div> }
           
        </Row>
        <Modal className="model-time-modal"
            visible={visible}
            onOk={handleSubmit}
            maskClosable={false}
            onCancel={handleCancel}>
            <div style={{padding: "32px"}}>
                <div className="manrope f-16 black-00 w-600" style={{marginBottom: 20}}>Confirm Rejection</div>
                <Form className="input-form" onSubmit={handleSubmit}>
                <Form.Item className="manrope f-14 black-55  align-text" name="msg" label="Rejection Message" colon={false}>
                        {getFieldDecorator('msg', {
                            rules: [{required: true, message: "Rejection message is required!"}]
                        })(
                            <TextArea
                            rows={5}
                            className="manrope f-12 grey-77"
                            style={{height: "auto !important"}}
                            placeholder = "Enter Rejection Message"
                            defaultValue={props.adminForm ? props.rejectionDetails : ""}
                            />
                        )}
                    </Form.Item>
                    {props.roomDetailModal ? "" :
                        <Form.Item className="manrope f-14 black-55 confirm-height" name ={'reference_files'} label="Reference Files"  colon={false}>
                        {getFieldDecorator('reference_files', {
                            valuePropName: 'reference_files',
                            getValueFromEvent: e => e && e.fileList,
                        })(
                            <Upload 
                            defaultFileList={props.adminForm ? props.referenceFiles : ""} 
                            // accept=".zip,.png,.jpg,.jpeg,.pdf"
                            {...Constants.getUploadProps(props.platform)} 
                            multiple="true" 
                            listType="text" 
                            onRemove={file => {
                                message.success(`${file.name} removed successfully!`)
                                const index = refFileList.indexOf(file);
                                const newFileList = refFileList.slice();
                                newFileList.splice(index, 1);
                                setRefFileList(newFileList);
                                console.log(newFileList)
                                newFileList.forEach((file) => {
                                    if (file.status !== "error"){
                                        setHasError(false)
                                    }
                                    else {
                                        setHasError(true)
                                    }
                                })
                                return true;
                            }}
                            fileList={refFileList}
                            onChange = {handleChangeRefFile}
                            openFileDialogOnClick={!(validateFileUploads(refFileList) == 'error')}
                            className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-height-confirm">
                                <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                    <div style={{textAlign:"center"}}>
                                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                        <div className="manrope f-12 black-55" >
                                            <div className="up-info">Drop your reference files.</div>
                                            {/* <div>SUPPORTED FORMATS: ZIP,JPG,PNG,PDF,DOCS</div> */}
                                        </div>
                                        {(validateFileUploads(refFileList) == 'error') && (<Button
                                            onClick={() => Constants.triggerFailedFilesUpload('reference_files', refFileList, setRefFileList)}
                                            disabled={refFileList.length === 0}
                                            style={{position:'relative',top: 0}}
                                            loading={uploading}
                                            className="retry-btn manrope f-12 black-55" ghost
                                            >
                                            {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                        </Button>)}
                                    </div>
                                </div>
                            </Upload>
                        )}
                    </Form.Item>
                    }
                    {props.roomDetailModal ? "" :
                    (validateFileUploads(refFileList) == 'error') ? 
                        <div className='retry-error-message left'>{errorMessage}</div>
                    : ''
                    }
                    {props.adminRoom && props.adminForm ? 
                        <Form.Item className="manrope f-14 black-55" name="assigned_artist" label={"Assigned Artist"}  colon={false}>
                            {getFieldDecorator('assigned_artist', {
                                initialValue: props.assigned_artist,
                                rules: [
                                    { required: true, message: "Please select Artist"}
                                ],
                            })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                className="manrope f-12 black-55"
                                defaultValue={props.assigned_artist}>
                                {artistList.map((ele, index) => {
                                    return <Option key={index} value={ele.artist_profile} className="manrope f-12 select-view">{ele.artist_profile + " at rate " + ele.hourly_rate}</Option>
                                })}
                            </Select>
                            )}
                </Form.Item>
                    
                    : ""}
                    <div className='justify-in-end'>
                        <Form.Item>
                            <Button className="modal-okay square font-14" onClick={handleSubmit} type="primary" htmlType="submit" disabled={props.rejectionLoader} loading={props.rejectionLoader}>
                                Reject
                            </Button>
                        </Form.Item>
                    </div>
                     {(uploadError != '')?
                        <div className="manrope f-12 red" style={{color: "#ff4d4f", textAlign: "center"}}>{uploadError}</div>
                    :""}
                </Form>
            </div>
        </Modal>
    </div>

}
export default Form.create({ name: 'review-form' })(ReviewProduct);