import React, { useEffect, useState, useRef, useMemo } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  LoadingOutlined,
  MessageOutlined,
  ArrowLeftOutlined,
  FilterOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Card,
  Select,
  Radio,
  Form,
  Button,
  Modal,
  Checkbox,
  Tooltip,
  Breadcrumb,
  Tag,
  Tabs,
  Drawer,
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileConstants from "../../../../FileConstants";
import { Header } from "antd/lib/layout/layout";
import NewProject from "./NewProject";
import AddNewProjectEntities from "./AddNewProjectEntities";

const AddNewProject = (props) => {
    const [step, setStep] = useState(1);
    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedScenes, setSelectedScenes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectionPath, setRedirectionPath] = useState(null);
    const [existingProjectData,setExistingProjectData]= useState([]);

    useEffect(() => {
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        setRedirectionPath(redirection_path);
    }, []);

    useEffect(()=>{
        const isEdit = new URLSearchParams(window.location.search).get("isEdit");
        const projectId = new URLSearchParams(window.location.search).get("projectId");
    },[])

    const origin = useMemo(()=> new URLSearchParams(window.location.search).get("origin") ,[])
    const isEdit = useMemo(()=> new URLSearchParams(window.location.search).get("isEdit") ,[])
    const editProjectId = useMemo(()=> new URLSearchParams(window.location.search).get("projectId") ,[])
    const editStep = useMemo(()=> new URLSearchParams(window.location.search).get("step") ,[])



    const getProjectEntitiesPayload = (projectId) => {
        let payload = {
          order_by: "id desc",
          required_fields: [
            "id",
            "name",
            "customer_username",
            "company_id",
            "thumbnail",
            "status",
            "project_id",
            "entity_type",
            "product_model_type",
            "last_modified",
            "category",
            "platform",
          ],
          filter_string: `(project_id__exact='${projectId}')`,
        };
    
        return payload;
      };
    
      const fetchProjectEntities = async (projectId) => {
        let payload = getProjectEntitiesPayload(projectId);
        await axios
          .post(ENVIRONMENT.GET_PROJECTS_ENTITY_BATCH, payload)
          .then((res) => {
            setExistingProjectData([...res.data])
          });
      };

      const existingSpaces= useMemo(() => existingProjectData.filter((obj)=> obj.entity_type === 'room').map((obj)=>obj.id) ,[existingProjectData])

      const existingScenes= useMemo(() => existingProjectData.filter((obj)=> obj.entity_type === 'scene').map((obj)=>obj.id) ,[existingProjectData])

      const existingProducts= useMemo(() => existingProjectData.filter((obj)=> obj.entity_type === 'product').map((obj)=>obj.id) ,[existingProjectData])


     useEffect(()=>{
        if(isEdit && editProjectId && editStep) {
            setStep(+editStep)
            fetchProjectEntities(editProjectId);
        }   
     },[editProjectId,isEdit,editStep]) 


    const handleUpdateProject = () => {
        setIsLoading(true);
        if (selectedProducts.length == 0 && selectedRooms.length == 0 && selectedScenes.length == 0) {
            window.location.href = 'projects?tab=requested';
        }

        let payload = {"project_id": projectId}

        if(isEdit){
            payload = {'project_id': editProjectId};
        }

        if (selectedProducts.length != 0) {
            payload.products_list = selectedProducts.map((obj)=>obj.product_id)
        }
        if (selectedRooms.length != 0) {
            payload.rooms_list = selectedRooms.map((obj)=>obj.room_id)
        }
        if(selectedScenes.length != 0){
            payload.scenes_list = selectedScenes.map((obj)=>obj?.id)
        }

        if(!isEdit)
            payload.generate_thumbnail = true


        axios.post(ENVIRONMENT.ADD_ENTITY_BATCH_TO_PROJECT, payload)
        .then((response) => {
            setIsLoading(false);
            if(isEdit)
                window.location.href = origin;
            else
                window.location.href = `projects/${projectId}?tab=active`;
        })
        .catch((error) => {
            setIsLoading(false);
        });
    }

    return ( 
        <CustomerMainLayout selected='14'>
            <div>
                {(step == 1) ?
                    <Row style={{marginTop: 50}}>
                        <Col span={12} offset={6}>
                            <NewProject
                            redirectionPath={redirectionPath}
                            setProjectId={setProjectId}
                            setProjectName={setProjectName}
                            setStep={setStep}
                            />
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                    :
                    ""
                }
                {(step >= 2)?
                    <div>
                    <AddNewProjectEntities 
                        step={step}
                        setStep={setStep}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        selectedRooms={selectedRooms}
                        setSelectedRooms={setSelectedRooms}
                        selectedScenes={selectedScenes}
                        setSelectedScenes={setSelectedScenes}
                        handleUpdateProject={handleUpdateProject}
                        isLoading={isLoading}
                        isEdit={isEdit}
                        preselectedProducts= {existingProducts}
                        preselectedSpaces= {existingSpaces}
                        preselectedScenes= {existingScenes}
                        origin={origin}
                        />
                    </div>
                    :
                    ""
                }
            </div>
        </CustomerMainLayout>
        )
}

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(AddNewProject));