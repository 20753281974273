import React, { useEffect, useState } from "react";
import { Col, Empty, Row } from "antd";
import ActiveModelCard from "./ActiveModelCard";
import * as Utilities from '../../Utilities'

const ActiveModelsKeys = {
    review : "in-review",
    progress : "in-progress",
    drafts : "drafts"
}

const ActiveModelsList = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        let data = [...props.data];

        if (props.searchValue == '' && !props.filtersApplied && props.modelTypeFilterValue === 'all') {
            data = [...props.data]; 
        } else {
            if(props.modelTypeFilterValue != 'all'){
                data = data.filter(item => item.type === props.modelTypeFilterValue)
            }
            if (props.searchValue != '') {
                data = Utilities.getRelevanceBasedOrderedSearchResult(props.searchValue, data);
            }
            if (props.searchValue == '' && props.filtersApplied) {
                data = filterProducts(data);
            }
        }
        setData(data);
        setModelCounts(data)
    }, [props.data, props.searchValue, props.filtersApplied, props.filtersAppliedFlag, props.modelTypeFilterValue]);

    const filterProducts = (models) => {
        return models.filter(props.returnSearchValue);
    };

    const setModelCounts = (data) => {
        let type = props.type;

        if(type === ActiveModelsKeys.review)
            props.setReviewTabCount(data.length)
        if(type === ActiveModelsKeys.progress)
            props.setInProgressTabCount(data.length)
        if(type === ActiveModelsKeys.drafts)
            props.setDraftTabCount(data.length)
    };

    return (
        <Row gutter={[25,25]}>
            {data && data.length > 0 ?
            data.map (entity => (
                <Col span={6}>
                    <ActiveModelCard
                    model={entity}
                    type={props.type}
                    modelsTabKey={props.modelsTabKey}
                    setMaterialModal={props.setMaterialModal}
                    setSelectedMaterial={props.setSelectedMaterial}/>
                </Col>
            )) : <Empty style={{ marginTop: 20, marginBottom: 20, margin: 'auto' }}
                description={`No Models`}
                className="manrope f-14"
                />}
        </Row>
    );
};

export default ActiveModelsList;