export default {
  chart: {
    width: '800',
    type: 'column',
  },
  title: {
    text: 'My scenes',
  },
  credits: {
    enabled: false,
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    categories: [
    ],
    crosshair: false,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Scenes count',
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [{
    name: '',
    color: '#DA3926',
    data: [],

  }],
};
