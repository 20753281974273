import React from 'react'
import { Row , Col } from 'antd'
import SiloShots from './SiloShots'
import SiloSettings from './SiloSettings'

export default function SiloSubmitRequest(props) {
  return (
    <div className="silo-content">
        <Row style={{height:"98%"}}>
            <Col style={{height:"100%", overflowY:"scroll"}} className='custom-scroll' span={24}>
                <SiloShots setResolutionBar={false} {...props}/>
            </Col>
            {/* <Col span={7}>
                <div className='silo-settings-container'>
                <SiloSettings {...props}/>
            </div>
            </Col> */}
        </Row>
    </div>
  )
}
