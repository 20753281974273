import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/catch';
import { ajax } from 'rxjs-compat/observable/dom/ajax';
import ENVIRONMENT from '../../environments'
import {
  REORDER,
  RENAME_FOLDER,
  CREATE_FOLDER,
  GET,
  successAction,
  failureAction,
  MOVE_TO_FOLDER
} from '../actions';

export const getArrangements = (action$) => {
  return action$
      .ofType(GET)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.ENTITY_ORGANIZER,
            payload, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => {
              console.log('rearrangement dattaa',payload, data);
              return successAction({ ...data.response, isCreated: false, isUpdated: false, isLoading: false, isReordered: false })} 
              )
            .catch((error) => Promise.resolve(failureAction({ message: 'Unable to get arrangement.' })));
      });
};

export const reorderData = (action$) => {
  return action$
      .ofType(REORDER)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.ENTITY_ORGANIZER,
            { ...payload }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) =>{ 
              console.log('dataaaa', data);
              return successAction({ ...data.response, isCreated: false, isUpdated: false, isLoading: false, isReordered: true })})
            .catch((error) => Promise.resolve(failureAction({ message: 'Unable to reorder data.' })));
      });
};

export const renameFolder = (action$) => {
  return action$
      .ofType(RENAME_FOLDER)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.ENTITY_ORGANIZER,
            { ...payload, username: localStorage.getItem('username'), company_id: localStorage.getItem('company_id') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => {
              console.log('rename folder API:', data)
              return successAction( { ...data.response, isCreated: false, isUpdated: false,  isReordered: true })}
              )
            .catch((error) => Promise.resolve(failureAction({ message: 'Unable to update name.' })));
      });
};

export const createFolder = (action$) => {
  return action$
      .ofType(CREATE_FOLDER)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.ENTITY_ORGANIZER,
            { ...payload, username: localStorage.getItem('username'), company_id: localStorage.getItem('company_id') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => {
              console.log('create folder API : ', { ...payload, username: localStorage.getItem('username'), company_id: localStorage.getItem('company_id') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') }, data)
              return successAction({ ...data.response, isCreated: true, isUpdated: false, isLoading: false, isReordered: false})
          })
            .catch((error) => Promise.resolve(failureAction({ message: 'Unable to update name.' })));
      });
};


export const moveToFolder = (action$) => {
  return action$
      .ofType(MOVE_TO_FOLDER)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.ENTITY_ORGANIZER,
            { ...payload, username: localStorage.getItem('username'), company_id: localStorage.getItem('company_id') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => {
              console.log('move to folder API : ', { ...payload, username: localStorage.getItem('username'), company_id: localStorage.getItem('company_id') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') }, data)
              return successAction({ ...data.response, isCreated: true, isUpdated: false, isLoading: false, isReordered: false})
          })
            .catch((error) => Promise.resolve(failureAction({ message: 'Unable to update name.' })));
      });
};