import React, {useState, useEffect}  from 'react';
import SpaceVariationRequest from '../SpaceVariationRequest/SpaceVariationRequest';
import {
    Modal,
    Tooltip, Row, Col
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import DottedLoader from '../../DottedLoader';
import MspWarningModal from '../MspWarningModal/MspWarningModal';

const SpaceVariationRequestModal = (props) => {
    const [isMspUserWarningModal, setIsMspUserWarningModal] = useState(false);

    const handleWarningModalClose = () => {
        setIsMspUserWarningModal(false);
    }

    return  <Modal
    visible={props.variationModal}
    className="aspect-ratio-modal medium"
    maskClosable={false}
    closable={false}
    destroyOnClose={true}
    onCancel={props.closeVariationModal}
    footer={[
        
    ]}>
        <div style={{padding: "5% 5% 0 5%"}}>
            {props.variationLoader ? <DottedLoader/> : <>
            {
            (localStorage.getItem("is_msprovider") == 'true') && localStorage.getItem('managed_customer_username') &&
            <Row gutter={[12,15]}>
                <Col span={24}>
                <div className="custom-alert info">
                You are requesting this Space Variation for customer {localStorage.getItem('managed_customer_username')}
                <>{(localStorage.getItem('managed_company_name') != undefined) ? (' (Company: ' + localStorage.getItem('managed_company_name') + ')'):''}</>
                </div>
                </Col>
            </Row>
            }
            <div className="justify-space-between" style={{marginBottom: 20}}>
                <div className="manrope f-24 black-33 w-700">Request Space Variation&nbsp;
                <Tooltip 
                    title={<span className="manrope f-12 white">
                        You can make adjustements like, change the colour, size, textures,  etcetera and request a variation of  this model.
                    </span>}>
                    <InfoCircleOutlined style={{cursor: "pointer"}}/>
                </Tooltip>
                </div>
                <div className="manrope f-20 grey-77" style={{cursor:"pointer"}} onClick={props.closeVariationModal}>x</div>
            </div>
            {props.savedID != -1 ?
            <div className="note-bg-specs" style={{marginTop: 20,marginBottom: 20, width:"max-content"}}>
                <div className="manrope f-14 blue">
                    Previous Progress Saved
                </div>
            </div>: ''}
            <SpaceVariationRequest
            cardSaveDrawer={props.cardSaveDrawer}
            setCardSaveDrawer={props.setCardSaveDrawer}
            requestType={props.requestType}
            requestStatus={props.requestStatus}
            cardStatus={props.cardStatus}
            setCardSaveFailure={props.setCardSaveFailure}
            cardSaveFailure={props.cardSaveFailure}
            paymentRequestID={props.paymentRequestID}
            space_id={props.space_id}
            submitVariation={props.submitVariation}
            savedID={props.savedID}
            space_details={props.space_details} 
            automated_variation={props.automated_variation ? props.automated_variation : false}
            space_variation={props.automated_variation ? props.space_variation : null}
            />
            </>}
        </div>
        <MspWarningModal
            visible = {isMspUserWarningModal}
            handleModalClose = {handleWarningModalClose}
    />
</Modal>
}


export default SpaceVariationRequestModal
