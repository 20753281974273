import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Row, Col, Typography, List, Collapse, Checkbox } from 'antd';
import DottedLoader from '../../DottedLoader';
import { version } from 'less';

const { Panel } = Collapse;

function ModelingStats({ testID }) {
  const [vertexCount, setVertexCount] = useState(null);
  const [width, setWidth] = useState(null);
  const [depth, setDepth] = useState(null);
  const [height, setHeight] = useState(null);
  const [dimensionValidation, setDimensionValidation] = useState(false);
  const [hierarchyValid, setHierarchyValid] = useState(false);
  const [hierarchyFixed, setHierarchyFixed] = useState(false);
  const [pivotValid, setPivotValid] = useState(false);
  const [pivotFixed, setPivotFixed] = useState(false);
  const [positionValid, setPositionValid] = useState(false);
  const [positionFixed, setPositionFixed] = useState(false);
  const [rotationValid, setRotationValid] = useState(false);
  const [rotationFixed, setRotationFixed] = useState(false);
  const [scaleValid, setScaleValid] = useState(false);
  const [scaleFixed, setScaleFixed] = useState(false);

  const [lowVertexCount, setLowVertexCount] = useState(null);
  const [lowWidth, setLowWidth] = useState(null);
  const [lowDepth, setLowDepth] = useState(null);
  const [lowHeight, setLowHeight] = useState(null);
  const [lowHierarchyValid, setLowHierarchyValid] = useState(false);
  const [lowHierarchyFixed, setLowHierarchyFixed] = useState(false);
  const [lowPivotValid, setLowPivotValid] = useState(false);
  const [lowPivotFixed, setLowPivotFixed] = useState(false);
  const [lowPositionValid, setLowPositionValid] = useState(false);
  const [lowPositionFixed, setLowPositionFixed] = useState(false);
  const [lowRotationValid, setLowRotationValid] = useState(false);
  const [lowRotationFixed, setLowRotationFixed] = useState(false);
  const [lowScaleValid, setLowScaleValid] = useState(false);
  const [lowScaleFixed, setLowScaleFixed] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [lowPolyVertex, setLowPolyVertex] = useState(false);
  const [highPolyVertex, setHighPolyVertex] = useState(false);
  const [customerModelWidth, setCustomerModelWidth] = useState(null);
  const [customerModelDepth, setCustomerModelDepth] = useState(null);
  const [customerModelHeight, setCustomerModelHeight] = useState(null);

  const [automatically_fixed_high, setAutomatically_fixed_high] = useState([]);
  const [automatically_fixed_low, setAutomatically_fixed_low] = useState([]);
  const [successfully_validated_high, setSuccessfully_validated_high] =
    useState([]);
  const [successfully_validated_low, setSuccessfully_validated_low] = useState(
      [],
  );
  const [invalid_data_low, setInvalid_data_low] = useState([]);
  const [invalid_data_high, setInvalid_data_high] = useState([]);
  const [fbxGenerated, setFbxGenerated] = useState(null);
  const [glbGenerated, setGlbGenerated] = useState(null);
  const [usdzGenerated, setUsdzGenerated] = useState(null);
  const [assetsGenerated, setAssetsGenerated] = useState([]);
  const [assetsNotGenerated, setAssetsNotGenerated] = useState([]);
  const [totalHighValidCount, setTotalHighValidCount] = useState(0);
  const [totalHighFixedCount, setTotalHighFixedCount] = useState(0);
  const [totalHighInvalidCount, setTotalHighInvalidCount] = useState(0);
  const [totalLowValidCount, setTotalLowValidCount] = useState(0);
  const [totalLowFixedCount, setTotalLowFixedCount] = useState(0);
  const [totalLowInvalidCount, setTotalLowInvalidCount] = useState(0);
  const [totalAssetsGeneratedCount, setTotalAssetsGeneratedCount] = useState(0);
  const [totalAssetsNotGeneratedCount, setTotalAssetsNotGeneratedCount] = useState(0);
  const [versionValid,setVersionValid] = useState(true);
  const [validationReportGenerated, setValidationReportGenerated] = useState(true);

  const getHighData = (validation_report) => {
    let validCount = 0;
    let fixedCount = 0;
    let invalidCount = 0;
    const { High } = validation_report;

    if (High.dimensions) {
      const { dimensions } = High;
      setWidth(dimensions.width);
      setDepth(dimensions.depth);
      setHeight(dimensions.height);
    }

    if (High.vertex_count) {
      setVertexCount(High.vertex_count);
      setHighPolyVertex(true);
    }

    if (High.hierarchy) {
      const { hierarchy } = High;
      if (hierarchy.valid === true) {
        setHierarchyValid(true);
        successfully_validated_high.push("Heirarchy");
        validCount += 1;
      } else {
        setHierarchyValid(false);
        invalid_data_high.push("Heirarchy");
        invalidCount += 1;
      }
      if (hierarchy.fixed === true) {
        setHierarchyFixed(true);
        automatically_fixed_high.push("Heirarchy");
        fixedCount += 1;
        invalid_data_high.pop("Heirarchy");
        invalidCount -= 1;
      } else {
        setHierarchyFixed(false);
      }
    }

    if (High.pivot) {
      const { pivot } = High;
      if (pivot.valid === true) {
        setPivotValid(true);
        successfully_validated_high.push("Pivot");
        validCount += 1;
      } else {
        setPivotValid(false);
        invalid_data_high.push("Pivot");
        invalidCount += 1;
      }
      if (pivot.fixed === true) {
        setPivotFixed(true);
        automatically_fixed_high.push("Pivot");
        fixedCount += 1;
        invalid_data_high.pop("Pivot");
        invalidCount -= 1;
      } else {
        setPivotFixed(false);
      }
    }

    if (High.transform) {
      const { transform } = High;

      if (transform.position) {
        const { position } = transform;

        if (position.valid === true) {
          setPositionValid(true);
          successfully_validated_high.push("Transform (Position)");
          validCount += 1;
        } else {
          setPositionValid(false);
          invalid_data_high.push("Transform (Position)");
          invalidCount += 1;
        }
        if (position.fixed === true) {
          setPositionFixed(true);
          automatically_fixed_high.push("Transform (Position)");
          fixedCount += 1;
          invalid_data_high.pop("Transform (Position)");
          invalidCount -= 1;
        } else {
          setPositionFixed(false);
        }
      }

      if (transform.rotation) {
        const { rotation } = transform;
        if (rotation.valid === true) {
          setRotationValid(true);
          successfully_validated_high.push("Transform (Rotation)");
          validCount += 1;
        } else {
          setRotationValid(false);
          invalid_data_high.push("Transform (Rotation)");
          invalidCount += 1;
        }
        if (rotation.fixed === true) {
          setRotationFixed(true);
          automatically_fixed_high.push("Transform (Rotation)");
          fixedCount += 1;
          invalid_data_high.pop("Transform (Rotation)");
          invalidCount -= 1;
        } else {
          setRotationFixed(false);
        }
      }

      if (transform.scale) {
        const { scale } = transform;
        if (scale.valid === true) {
          setScaleValid(true);
          successfully_validated_high.push("Transform (Scale)");
          validCount += 1;
        } else {
          setScaleValid(false);
          invalid_data_high.push("Transform (Scale)");
          invalidCount += 1;
        }
        if (scale.fixed === true) {
          setScaleFixed(true);
          automatically_fixed_high.push("Transform (Scale)");
          fixedCount += 1;
          invalid_data_high.pop("Transform (Scale)");
          invalidCount -= 1;
        } else {
          setScaleFixed(false);
        }
      }
    }
  setTotalHighInvalidCount(invalidCount);
  setTotalHighValidCount(validCount);
  setTotalHighFixedCount(fixedCount);
  };

  const getLowData = (validation_report) => {
        let validCount = 0;
        let fixedCount = 0;
        let invalidCount = 0;
    const { Low } = validation_report;

    if (Low.dimensions) {
      const { dimensions } = Low;
      setLowWidth(dimensions.width);
      setLowDepth(dimensions.depth);
      setLowHeight(dimensions.height);
    }

    if (Low.vertex_count) {
      setLowVertexCount(Low.vertex_count);
      setLowPolyVertex(true);
    }

    if (Low.hierarchy) {
      const { hierarchy } = Low;
      if (hierarchy.valid === true) {
        setLowHierarchyValid(true);
        successfully_validated_low.push("Heirarchy");
        validCount += 1;
      } else {
        setLowHierarchyValid(false);
        invalid_data_low.push("Heirarchy");
        invalidCount += 1;
      }
      if (hierarchy.fixed === true) {
        setLowHierarchyFixed(true);
        automatically_fixed_low.push("Heirarchy");
        fixedCount += 1;
        invalid_data_low.pop("Heirarchy");
        invalidCount -= 1;
      } else {
        setLowHierarchyFixed(false);
      }
    }

    if (Low.pivot) {
      const { pivot } = Low;
      if (pivot.valid === true) {
        setLowPivotValid(true);
        successfully_validated_low.push("Pivot");
        validCount += 1;
      } else {
        setLowPivotValid(false);
        invalid_data_low.push("Pivot");
        invalidCount += 1;
      }
      if (pivot.fixed === true) {
        setLowPivotFixed(true);
        automatically_fixed_low.push("Pivot");
        fixedCount += 1;
        invalid_data_low.pop("Pivot");
        invalidCount -= 1;
      } else {
        setLowPivotFixed(false);
      }
    }

    if (Low.transform) {
      const { transform } = Low;

      if (transform.position) {
        const { position } = transform;

        if (position.valid === true) {
          setLowPositionValid(true);
          successfully_validated_low.push("Transform (Position)");
          validCount += 1;
        } else {
          setLowPositionValid(false);
          invalid_data_low.push("Transform (Position)");
          invalidCount += 1;
        }
        if (position.fixed === true) {
          setLowPositionFixed(true);
          automatically_fixed_low.push("Transform (Position)");
          fixedCount += 1;
          invalid_data_low.pop("Transform (Position)");
          invalidCount -= 1;
        } else {
          setLowPositionFixed(false);
        }
      }

      if (transform.rotation) {
        const { rotation } = transform;
        if (rotation.valid === true) {
          setLowRotationValid(true);
          successfully_validated_low.push("Transform (Rotation)");
          validCount += 1;
        } else {
          setLowRotationValid(false);
          invalid_data_low.push("Transform (Rotation)");
          invalidCount += 1;
        }
        if (rotation.fixed === true) {
          setLowRotationFixed(true);
          automatically_fixed_low.push("Transform (Rotation)");
          fixedCount += 1;
          automatically_fixed_low.pop("Transform (Rotation)");
          invalidCount -= 1;
        } else {
          setLowRotationFixed(false);
        }
      }

      if (transform.scale) {
        const { scale } = transform;
        if (scale.valid === true) {
          setLowScaleValid(true);
          successfully_validated_low.push("Transform (Scale)");
          validCount += 1;
        } else {
          setLowScaleValid(false);
          invalid_data_low.push("Transform (Scale)");
          invalidCount += 1;
        }
        if (scale.fixed === true) {
          setLowScaleFixed(true);
          automatically_fixed_low.push("Transform (Scale)");
          fixedCount += 1;
          invalid_data_low.pop("Transform (Scale)");
          invalidCount -= 1;
        } else {
          setLowScaleFixed(false);
        }
      }
    }
    setTotalLowInvalidCount(invalidCount);
    setTotalLowValidCount(validCount);
    setTotalLowFixedCount(fixedCount);
  };

  const getGeneratedAssets = (
      glb_generated,
      fbx_generated,
      usdz_generated,
      perspective_renders,
  ) => {
    let assetsGeneratedCount = 0;
    let assetsNotGeneratedCount = 0;
    if (glb_generated) {
      setGlbGenerated(glb_generated);
      assetsGeneratedCount += 1;
      assetsGenerated.push("GLB");
    } else {
      assetsNotGeneratedCount += 1;
      assetsNotGenerated.push("GLB");
    }
    if (fbx_generated) {
      setFbxGenerated(fbx_generated);
      assetsGeneratedCount += 1;
      assetsGenerated.push("FBX");
    } else {
      assetsNotGeneratedCount += 1;
      assetsNotGenerated.push("FBX");
    }
    if (usdz_generated) {
      setUsdzGenerated(usdz_generated);
      assetsGeneratedCount += 1;
      assetsGenerated.push("USDZ");
    } else {
      assetsNotGeneratedCount += 1;
      assetsNotGenerated.push("USDZ");
    }
    if (perspective_renders.length > 0) {
      assetsGeneratedCount += 1;
      assetsGenerated.push("Renders");
    } else {
      assetsNotGeneratedCount += 1;
      assetsNotGenerated.push("Renders");
    }
    setTotalAssetsGeneratedCount(assetsGeneratedCount);
    setTotalAssetsNotGeneratedCount(assetsNotGeneratedCount);
  };

  // Get 3D model Automation Job Dimensions
  useEffect(() => {
    if (testID) {
      const payload = {
        test_id: testID,
      };
      setIsLoading(true);
      axios.post(ENVIRONMENT.GET_ARTIST_TEST, payload).then((res) => {
        console.log(res.data);
        // Get Model Dimensions given to the artist
        axios
          .post(ENVIRONMENT.GET_TEST_ARTIST_PRODUCT_DETAILS, payload)
          .then((res) => {
            setIsLoading(false);
            console.log(res.data);
            setCustomerModelDepth(res.data.length);
            setCustomerModelHeight(res.data.height);
            setCustomerModelWidth(res.data.width);
          });
        getGeneratedAssets(
          res.data.glb_generated,
          res.data.fbx_generated,
          res.data.usdz_generated,
          res.data.perspective_renders
        );
        setDimensionValidation(res.data.version_valid);
        const { validation_report } = res.data.validation_report;
        
        if (validation_report.version_valid == false){
          setVersionValid(false)
        }
        
        if (validation_report.report !== true){
          setValidationReportGenerated(false)
        }
        

        if (validation_report.High && validation_report.Low) {
          if (
            validation_report.High !== null ||
            validation_report.High !== {}
          ) {
            getHighData(validation_report);
          }
          if (validation_report.Low !== null || validation_report.Low !== {}) {
            getLowData(validation_report);
          }
        } else {
          if (validation_report.High) {
            getHighData(validation_report);
          }

          if (validation_report.Low) {
            getLowData(validation_report);
          }
        }
      });
    }
  }, [testID]);

  return (
    <>
      {isLoading ? (
        <DottedLoader />
      ) : (
        <>
          <Row gutter={20}>
            <Col span={12} className="gutter-row">
              <Row>
                <Col span={24} style={{ paddingTop: 30 }}>
                  <span className="product-txt-heading">Vertex Count</span>
                  <table className="stats-table" style={{ marginTop: 13 }}>
                    <tr>
                      {lowPolyVertex ? (
                        <td>
                          <span
                            className="note-text gray"
                            style={{
                              display: "block",
                              color: "#777777",
                              fontSize: 14,
                              paddingTop: 7,
                            }}
                          >
                            Low Poly Vertex Count
                          </span>
                          <span style={{ fontSize: 16, paddingTop: 7 }}>
                            {lowVertexCount}
                          </span>
                        </td>
                      ) : (
                        ""
                      )}
                      {highPolyVertex ? (
                        <td>
                          <span
                            className="note-text gray"
                            style={{
                              display: "block",
                              color: "#777777",
                              fontSize: 14,
                              paddingTop: 7,
                            }}
                          >
                            High Poly Vertex Count
                          </span>
                          <span style={{ fontSize: 16, paddingTop: 7 }}>
                            {vertexCount}
                          </span>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </table>
                </Col>
              </Row>
              <Row>
                <div style={{ paddingTop: 30 }}>
                  {/* Artist Created Model */}
                  <span className="product-txt-heading">
                    Dimension Validation
                  </span>
                  <div
                    style={{
                      display: "inline",
                      background: "#FEF6F6",
                      padding: 8,
                      marginLeft: 10,
                      borderRadius: 3,
                    }}
                  >
                    <span
                      className="note-text"
                      style={{
                        color: dimensionValidation === false ? "red" : "green",
                      }}
                    >
                      {dimensionValidation === false ? "Invalid" : "Valid"}
                    </span>
                  </div>

                  <table
                    className="stats-table dimension-table"
                    style={{ marginTop: 12, width: "100%" }}
                  >
                    <tr>
                      <th></th>
                      <th>Customer Dimensions</th>
                      <th>3D Model Dimensions</th>
                    </tr>
                    <tr>
                      <td style={{ fontSize: 16 }}>
                        Height
                        <span
                          style={{
                            paddingLeft: 6,
                            fontSize: 14,
                            color: "#777777",
                          }}
                        >
                          Inches
                        </span>
                      </td>
                      <td>
                        {customerModelHeight != undefined
                          ? customerModelHeight
                          : "N/A"}
                      </td>
                      <td>{height ? height : lowHeight}</td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: 16 }}>
                        Width
                        <span
                          style={{
                            paddingLeft: 6,
                            fontSize: 14,
                            color: "#777777",
                          }}
                        >
                          Inches
                        </span>
                      </td>
                      <td>
                        {customerModelWidth != undefined
                          ? customerModelWidth
                          : "N/A"}
                      </td>
                      <td>{width ? width : lowWidth}</td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: 16 }}>
                        Depth
                        <span
                          style={{
                            paddingLeft: 6,
                            fontSize: 14,
                            color: "#777777",
                          }}
                        >
                          Inches
                        </span>
                      </td>
                      <td>
                        {customerModelDepth != undefined
                          ? customerModelDepth
                          : "N/A"}
                      </td>
                      <td>{depth ? depth : lowDepth}</td>
                    </tr>
                  </table>
                </div>
              </Row>
            </Col>
            <Col span={12} className="gutter-row">
              <Row>
                <Col span={24} style={{ paddingTop: 36 }}>
                  <div>
                    <div
                      className="product-txt-heading"
                      style={{ marginBottom: 14 }}
                    >
                      Automation Job Summary
                    </div>
                    {totalAssetsNotGeneratedCount === 0 ? (
                      ""
                    ) : (
                      <Collapse
                        bordered={false}
                        defaultActiveKey={["1"]}
                        expandIconPosition={"right"}
                        expandIcon={({ isActive }) => (
                          <div
                            className="draw-full-circle red"
                            style={{ padding: "9px 3px" }}
                          >
                            <span className="artist-upload-txt small white">
                              {totalAssetsNotGeneratedCount}
                            </span>
                          </div>
                        )}
                        className="collapse-validation"
                      >
                        <Panel
                          header={
                            <span className="modal-text red">
                              Assets Not Generated
                            </span>
                          }
                          key="1"
                          className="site-collapse-custom-panel"
                        >
                          {assetsNotGenerated.length === 0 ? (
                            ""
                          ) : (
                            <List
                              style={{ marginLeft: 16 }}
                              bordered={false}
                              dataSource={assetsNotGenerated}
                              className="list-validation"
                              renderItem={(item) => (
                                <List.Item className="list-item-validation">
                                  <Typography.Text
                                    mark
                                    style={{ marginRight: 10 }}
                                    className="mark-style"
                                  >
                                    <img
                                      style={{ backgroundColor: "#fafafa" }}
                                      src="/img/alert-triangle.png"
                                    />
                                  </Typography.Text>
                                  <span className="flag-text grayish-black">
                                    {item}
                                  </span>
                                </List.Item>
                              )}
                            />
                          )}
                        </Panel>
                      </Collapse>
                    )}
                    {totalAssetsGeneratedCount === 0 ? (
                      ""
                    ) : (
                      <Collapse
                        bordered={false}
                        defaultActiveKey={
                          totalAssetsNotGeneratedCount === 0 ? ["1"] : ["0"]
                        }
                        expandIconPosition={"right"}
                        expandIcon={({ isActive }) => (
                          <div
                            className="draw-full-circle"
                            style={{ padding: "9px 3px" }}
                          >
                            <span className="artist-upload-txt small white">
                              {totalAssetsGeneratedCount}
                            </span>
                          </div>
                        )}
                        className="collapse-validation"
                      >
                        <Panel
                          header={
                            <span className="modal-text green">
                              Assets Generated
                            </span>
                          }
                          key="1"
                          className="site-collapse-custom-panel"
                        >
                          {assetsGenerated.length === 0 ? (
                            ""
                          ) : (
                            <List
                              style={{ marginLeft: 16 }}
                              bordered={false}
                              dataSource={assetsGenerated}
                              className="list-validation"
                              renderItem={(item) => (
                                <List.Item className="list-item-validation">
                                  <Typography.Text
                                    mark
                                    style={{ marginRight: 10 }}
                                  >
                                    <Checkbox
                                      className="green-checkbox"
                                      checked={true}
                                    />
                                  </Typography.Text>
                                  <span className="flag-text grayish-black">
                                    {item}
                                  </span>
                                </List.Item>
                              )}
                            />
                          )}
                        </Panel>
                      </Collapse>
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ paddingTop: 36 }}>
                  <div>
                    <div
                      className="product-txt-heading"
                      style={{ marginBottom: 14 }}
                    >
                      Model Validation Summary
                    </div>
                    {
                      versionValid === false ?
                        <div>
                          <img
                            style={{ backgroundColor: "#fafafa" }}
                            src="/img/alert-triangle.png"
                          />
                          <span style={{marginLeft:"5px"}} className='manrope f-14'>
                            The version for the max file is incorrect                            
                          </span>
                        </div>
                      :
                      ''
                    }
                    {
                      validationReportGenerated === false ?
                        <div>
                          <img
                            style={{ backgroundColor: "#fafafa" }}
                            src="/img/alert-triangle.png"
                          />
                          <span style={{marginLeft:"5px"}} className='manrope f-14'>
                            Validation Report for this test could not be generated
                          </span>
                        </div>
                      :
                      ''
                    }
                    <div>
                      
                    </div>
                    <div>
                      
                    </div>
                    {totalHighInvalidCount + totalLowInvalidCount === 0 ? (
                      ""
                    ) : (
                      <Collapse
                        bordered={false}
                        defaultActiveKey={["1"]}
                        expandIconPosition={"right"}
                        expandIcon={({ isActive }) => (
                          <div
                            className="draw-full-circle red"
                            style={{ padding: "9px 3px" }}
                          >
                            <span className="artist-upload-txt small white">
                              {totalHighInvalidCount + totalLowInvalidCount}
                            </span>
                          </div>
                        )}
                        className="collapse-validation"
                      >
                        <Panel
                          header={
                            <span className="modal-text red">
                              What Went Wrong?
                            </span>
                          }
                          key="1"
                          className="site-collapse-custom-panel"
                        >
                          <Collapse
                            defaultActiveKey={["1"]}
                            accordion
                            className="collapse-validation-collapse"
                            ghost
                            expandIconPosition={"right"}
                          >
                            {invalid_data_high.length === 0 ? (
                              ""
                            ) : (
                              <Panel
                                key="1"
                                className="site-collapse-custom-panel"
                                header={
                                  <span className="width-height-label">
                                    High Poly
                                  </span>
                                }
                              >
                                <List
                                  bordered={false}
                                  dataSource={invalid_data_high}
                                  className="list-validation"
                                  renderItem={(item) => (
                                    <List.Item className="list-item-validation">
                                      <Typography.Text
                                        mark
                                        style={{ marginRight: 10 }}
                                        className="mark-style"
                                      >
                                        <img
                                          style={{ backgroundColor: "#fafafa" }}
                                          src="/img/alert-triangle.png"
                                        />
                                      </Typography.Text>
                                      <span className="flag-text grayish-black">
                                        {item}
                                      </span>
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            )}
                            {invalid_data_low.length === 0 ? (
                              ""
                            ) : (
                              <Panel
                                key="2"
                                className="site-collapse-custom-panel"
                                header={
                                  <span className="width-height-label">
                                    Low Poly
                                  </span>
                                }
                              >
                                <List
                                  bordered={false}
                                  dataSource={invalid_data_low}
                                  className="list-validation"
                                  renderItem={(item) => (
                                    <List.Item className="list-item-validation">
                                      <Typography.Text
                                        mark
                                        style={{ marginRight: 10 }}
                                        className="mark-style"
                                      >
                                        <img
                                          style={{ backgroundColor: "#fafafa" }}
                                          src="/img/alert-triangle.png"
                                        />
                                      </Typography.Text>
                                      <span className="flag-text grayish-black">
                                        {item}
                                      </span>
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            )}
                          </Collapse>
                        </Panel>
                      </Collapse>
                    )}
                    {totalHighValidCount + totalLowValidCount === 0 ? (
                      ""
                    ) : (
                      <Collapse
                        bordered={false}
                        defaultActiveKey={
                          totalHighInvalidCount + totalLowInvalidCount === 0 &&
                          totalHighFixedCount + totalLowFixedCount === 0
                            ? ["1"]
                            : ["0"]
                        }
                        expandIconPosition={"right"}
                        expandIcon={({ isActive }) => (
                          <div
                            className="draw-full-circle"
                            style={{ padding: "9px 3px" }}
                          >
                            <span className="artist-upload-txt small white">
                              {totalHighValidCount + totalLowValidCount}
                            </span>
                          </div>
                        )}
                        className="collapse-validation"
                      >
                        <Panel
                          header={
                            <span className="modal-text green">
                              Successfully Validated
                            </span>
                          }
                          key="1"
                          className="site-collapse-custom-panel"
                        >
                          <Collapse
                            accordion
                            className="collapse-validation-collapse"
                            ghost
                            expandIconPosition={"right"}
                          >
                            {successfully_validated_high.length === 0 ? (
                              ""
                            ) : (
                              <Panel
                                collapsible={
                                  successfully_validated_high.length === 0
                                    ? "disabled"
                                    : "header"
                                }
                                showArrow={
                                  successfully_validated_high.length === 0
                                    ? false
                                    : true
                                }
                                key="1"
                                className="site-collapse-custom-panel"
                                header={
                                  <span className="width-height-label">
                                    High Poly
                                  </span>
                                }
                              >
                                <List
                                  bordered={false}
                                  dataSource={successfully_validated_high}
                                  className="list-validation"
                                  renderItem={(item) => (
                                    <List.Item className="list-item-validation">
                                      <Typography.Text
                                        mark
                                        style={{ marginRight: 10 }}
                                      >
                                        <Checkbox
                                          className="green-checkbox"
                                          checked={true}
                                        />
                                      </Typography.Text>
                                      <span className="flag-text grayish-black">
                                        {item}
                                      </span>
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            )}
                            {successfully_validated_low.length === 0 ? (
                              ""
                            ) : (
                              <Panel
                                key="2"
                                collapsible={
                                  successfully_validated_low.length === 0
                                    ? "disabled"
                                    : "header"
                                }
                                showArrow={
                                  successfully_validated_low.length === 0
                                    ? false
                                    : true
                                }
                                className="site-collapse-custom-panel"
                                header={
                                  <span className="width-height-label">
                                    Low Poly
                                  </span>
                                }
                              >
                                <List
                                  bordered={false}
                                  dataSource={successfully_validated_low}
                                  className="list-validation"
                                  renderItem={(item) => (
                                    <List.Item className="list-item-validation">
                                      <Typography.Text
                                        mark
                                        style={{ marginRight: 10 }}
                                      >
                                        <Checkbox
                                          className="green-checkbox"
                                          checked={true}
                                        />
                                      </Typography.Text>
                                      <span className="flag-text grayish-black">
                                        {item}
                                      </span>
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            )}
                          </Collapse>
                        </Panel>
                      </Collapse>
                    )}
                    {totalHighFixedCount + totalLowFixedCount === 0 ? (
                      ""
                    ) : (
                      <Collapse
                        bordered={false}
                        defaultActiveKey={
                          (totalHighInvalidCount+totalLowInvalidCount) === 0 ? ["1"] : ["0"]
                        }
                        expandIconPosition={"right"}
                        expandIcon={({ isActive }) => (
                          <div
                            className="draw-full-circle yellow"
                            style={{ padding: "9px 3px" }}
                          >
                            <span className="artist-upload-txt small white">
                              {totalHighFixedCount + totalLowFixedCount}
                            </span>
                          </div>
                        )}
                        className="collapse-validation"
                      >
                        <Panel
                          header={
                            <span className="modal-text yellow">
                              Automatically Fixed
                            </span>
                          }
                          key="1"
                          className="site-collapse-custom-panel"
                        >
                          <Collapse
                            defaultActiveKey={["1"]}
                            accordion
                            className="collapse-validation-collapse"
                            ghost
                            expandIconPosition={"right"}
                          >
                            {automatically_fixed_high.length === 0 ? (
                              ""
                            ) : (
                              <Panel
                                key="1"
                                className="site-collapse-custom-panel"
                                header={
                                  <span className="width-height-label">
                                    High Poly
                                  </span>
                                }
                              >
                                <List
                                  bordered={false}
                                  dataSource={automatically_fixed_high}
                                  className="list-validation"
                                  renderItem={(item) => (
                                    <List.Item className="list-item-validation">
                                      <Typography.Text
                                        className="mark-style"
                                        mark
                                        style={{ marginRight: 10 }}
                                      >
                                        <img
                                          style={{ backgroundColor: "#fafafa" }}
                                          src="/img/alert-circle.png"
                                        />
                                      </Typography.Text>
                                      <span className="flag-text grayish-black">
                                        {item}
                                      </span>
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            )}
                            {automatically_fixed_low.length === 0 ? (
                              ""
                            ) : (
                              <Panel
                                key="2"
                                className="site-collapse-custom-panel"
                                header={
                                  <span className="width-height-label">
                                    Low Poly
                                  </span>
                                }
                              >
                                <List
                                  bordered={false}
                                  dataSource={automatically_fixed_low}
                                  className="list-validation"
                                  renderItem={(item) => (
                                    <List.Item className="list-item-validation">
                                      <Typography.Text
                                        className="mark-style"
                                        mark
                                        style={{ marginRight: 10 }}
                                      >
                                        <img
                                          style={{ backgroundColor: "#fafafa" }}
                                          src="/img/alert-circle.png"
                                        />
                                      </Typography.Text>
                                      <span className="flag-text grayish-black">
                                        {item}
                                      </span>
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            )}
                          </Collapse>
                        </Panel>
                      </Collapse>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default ModelingStats;
