import React, { useContext } from 'react';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import CollaborateConstants from './Constants';
import { Tooltip, Popover, Input, Button, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const SecondaryOptions = (props) => {
    return (<Popover overlayClassName="sub-toolbar"
        content={<div className='display-flex j-c a-c dir-col'>
            {props.extra_action.extra_actions.map((more_extra_action, index) => (
                <Tooltip
                    key={index}
                    placement={"right"}
                    title={<span className='manrope f-12 white'>
                        {more_extra_action.tooltip}
                    </span>}>
                    <div className={`tool-box-text ${props.objectThickness == more_extra_action.main_action ? `selected` : ``}`}
                        onClick={() => props.extraOption(props.extra_action.main_action, more_extra_action.main_action)}>
                        <div className={`mb-0 manrope f-12 ${more_extra_action.main_action} ${props.objectThickness == more_extra_action.main_action ? `white` : `grey`} tool-box-text-color`}>
                        </div>
                    </div>
                </Tooltip>
            ))}
        </div>}
        trigger="click"
        open={(props.secondary_selected_action == "pen_thickness" && props.extra_action.main_action == "pen_thickness")}>
        <Tooltip placement={"top"}
            title={<span className='manrope f-12 white'>
                {props.extra_action.tooltip}
            </span>}>
            <div className={`tool-box ${props.secondary_selected_action == props.extra_action.main_action ? `selected` : ``}`} onClick={() => props.selectAction(props.basic_action.main_action, props.extra_action.main_action)}>
                <img src={props.extra_action.icon} className='tool-icons' />
            </div>
        </Tooltip>
    </Popover>)
}

const MainOptions = (props) => {
    return (
        <Popover overlayClassName="sub-toolbar"
            content={
                <div className='display-flex j-c a-c'>
                    {props.basic_action.extra_actions.map((extra_action, index) => (
                        extra_action.extra_actions.length > 0 ?
                            <SecondaryOptions
                                key={index}
                                extra_action={extra_action}
                                objectThickness={props.objectThickness}
                                extraOption={props.extraOption}
                                secondary_selected_action={props.secondary_selected_action}
                                selectAction={props.selectAction}
                                basic_action={props.basic_action}
                            /> :
                            <Tooltip placement={"top"}
                                key={index}
                                title={<span className='manrope f-12 white'>
                                    {extra_action.tooltip}
                                </span>}>
                                <div className={`tool-box ${props.secondary_selected_action == extra_action.main_action ? `selected` : ``}`}
                                    onClick={() => props.selectAction(props.basic_action.main_action, extra_action.main_action)}>
                                    <img src={extra_action.icon} className='tool-icons' />
                                </div>
                            </Tooltip>
                    ))}
                </div>}
            trigger="click"
            open={(props.selected_action === props.basic_action.main_action) &&
                (["text", "shape", "draw"].includes(props.selected_action))}>
            <Tooltip placement={"top"}
                title={<span className='manrope f-12 white'>
                    {props.basic_action.tooltip}
                </span>}>
                <div className={`tool-box ${props.annotateLoader || props.save_loader ? `disable` : ``} ${props.selected_action == props.basic_action.main_action ? `selected` : ``}`} onClick={() => props.selectAction(props.basic_action.main_action)}>
                    <img src={props.basic_action.icon} className='tool-icons' />
                </div>
            </Tooltip>
        </Popover>
    )
}

const CanvasToolbar = () => {
    const { selected_action, secondary_selected_action, objectColor, objectThickness,
        selectAction, extraOption, changeColor, onColorClick, initialLoader, refreshLoader,
        access_level, annotateLoader, save_loader, saveAndExit, saveCollaborationData,
         saveExitLoader, setCollabRenderOverrideModal, setCollabSendEmailModal } = useContext(CollaborateContext);

    const isMSProivder = (localStorage.getItem("is_msprovider") == "true")
    return (
        <Row className={`collaborate-toolbar display-flex ${initialLoader ? `blur-canvas` : ``}`}>
            <Col span={8} className='display-flex j-s a-c'>
                {(CollaborateConstants.edit_access_levels.includes(access_level)) && 
                <>
                {(isMSProivder) ?
                <Button disabled={initialLoader || refreshLoader || save_loader || annotateLoader} className='basic-collab-btn f-14 ml-8' onClick={() => setCollabRenderOverrideModal(true)}>
                    <span>Render Override</span>
                </Button>:''}
                </>}
            </Col>
            <Col span={isMSProivder ? 9 : 8} className='display-flex j-c a-c'>
                {(CollaborateConstants.edit_access_levels.includes(access_level)) &&
                    <>
                        {CollaborateConstants.COLLABORATE_ACTIONS.map((basic_action, index) => (
                            basic_action.extra_actions.length == 0 ?
                                basic_action.icon_type == "color" ?
                                    <Tooltip key={index}
                                        title={<span className='manrope f-12 white'>
                                            {basic_action.tooltip}
                                        </span>}>
                                        <div className='color-input-wrapper'>
                                            <Input disabled={annotateLoader || save_loader} className="object-color-input" type="color" value={objectColor}
                                                onChange={changeColor} onClick={onColorClick} />
                                        </div>
                                    </Tooltip> :
                                    <Tooltip key={index}
                                        title={<span className='manrope f-12 white'>
                                            {basic_action.tooltip}
                                        </span>}>
                                        <div className={`tool-box ${annotateLoader || save_loader ? `disable` : ``} ${selected_action == basic_action.main_action ? `selected` : ``}`}
                                            onClick={() => selectAction(basic_action.main_action)}>
                                            <img src={basic_action.icon} className='tool-icons' />
                                        </div>
                                    </Tooltip> :
                                <MainOptions
                                    key={index}
                                    basic_action={basic_action}
                                    objectThickness={objectThickness}
                                    selected_action={selected_action}
                                    secondary_selected_action={secondary_selected_action}
                                    extraOption={extraOption}
                                    selectAction={selectAction}
                                    annotateLoader={annotateLoader}
                                    save_loader={save_loader}
                                />
                        ))}
                        <div className='collab-divider-container'>
                            <div className='collab-tool-divider'></div>
                        </div>
                    </>}

                {access_level != "" && <>
                    {CollaborateConstants.CANVAS_ACTIONS.map((basic_action, index) => (
                        basic_action.main_action == "select" && access_level != "view" ? "" :
                        (basic_action.main_action == "send_email") ?
                        (isMSProivder) ? 
                        <Tooltip
                            key={index}
                            title={<span className='manrope f-12 white'>
                                {basic_action.tooltip}
                            </span>}>
                            <div className={`tool-box ${annotateLoader || save_loader ? `disable` : ``} ${selected_action == "send_email" ? `selected` : ``}`}
                                onClick={() => setCollabSendEmailModal(true)}>
                                <img src={basic_action.icon} className='tool-icons' />
                            </div>
                        </Tooltip> : "" :
                        <Tooltip
                            key={index}
                            title={<span className='manrope f-12 white'>
                                {basic_action.tooltip}
                            </span>}>
                            <div className={`tool-box ${annotateLoader || save_loader ? `disable` : ``} ${selected_action == basic_action.main_action ? `selected` : ``}`}
                                onClick={() => selectAction(basic_action.main_action, "")}>
                                <img src={basic_action.icon} className='tool-icons' />
                            </div>
                        </Tooltip>
                    ))}
                </>}
            </Col>
            <Col span={isMSProivder ? 7 : 8} className='display-flex j-e a-c'>
            {(CollaborateConstants.edit_access_levels.includes(access_level)) ?
                <>
                <Button disabled={save_loader || initialLoader || annotateLoader || saveExitLoader || refreshLoader} className='basic-collab-btn f-14' onClick={saveAndExit}>
                    {saveExitLoader ? <span>Saving <LoadingOutlined /></span> : <span>Save and Exit</span>}
                </Button>
                <Button disabled={save_loader || initialLoader || annotateLoader || saveExitLoader || refreshLoader} className='basic-collab-btn f-14 ml-8' onClick={saveCollaborationData}>
                    {save_loader ? <span>Saving <LoadingOutlined /></span> : <span>Save</span>}
                </Button>
                </>
            : ''
            }
            </Col>
        </Row>
    );
}


export default CanvasToolbar;
