import React, {useEffect} from "react";
import { v4 as uuidv4 } from 'uuid';

const AmazonOauthCard = () => {

    const handleAuthorize = () => {
        const stateToken = uuidv4();
        localStorage.setItem("amazonStateToken", stateToken);
        const authUrl = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.ae3caef0-cf7c-4414-9c9b-223c0be89cce&redirect_uri=https://app.all3d.ai/amazon&state=${stateToken}`;
        window.location.href = authUrl;
    }

    return (
        <div className="integration-card">
            <p className="manrope f-24">Authorize to Amazon</p>
            <img style={{width:"30%"}} src={require("../../../../assets/images/aws-logo.svg")} alt="aws-logo"></img>
            <p className="manrope f-14 mt-40">Integrate your Amazon account to easily upload your products from our platform.</p>
            <button className="auhtorize-button" onClick={handleAuthorize}>Authorize</button>
        </div>
    );
}

export default AmazonOauthCard;