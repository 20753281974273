import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Modal } from 'antd';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';

const SubmitFeedbackModal = () => {
    const { approveModel, rejectionConfirmation, setRejectionConfirmation, openRejectModal } = useContext(CollaborateQAContext);


    return (<Modal
        className='approve-modal'
        closable={true}
        visible={rejectionConfirmation}
        onCancel={() => setRejectionConfirmation(false)}
        footer={[
            <div className='justify-in-end'>
                <Button className='modal-okay square font-12' onClick={openRejectModal}>
                    Fix as a New Model
                </Button>
            </div>
        ]}
        title={<span className='manrope f-14 black-33 w-600'>Submit feedback and fix as a new model?</span>}>
        <>
            <p className='manrope f-14 black-55 ml-20 mt-5'>
                You will be charged the same amount for the fix as you would be for creating a new model.
            </p>
        </>
    </Modal>)

}

export default SubmitFeedbackModal;