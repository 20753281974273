/**
 * @author Mugen87 / https://github.com/Mugen87
 */

// ConvexGeometry
import * as THREE from "three";
import {ConvexHull} from "./ConvexHull";

export const ConvexGeometry = function ( object ) {

	THREE.Geometry.call( this );

	let bufferGeo = new ConvexBufferGeometry( object );
	this.tolerance = bufferGeo.tolerance;
	this.fromBufferGeometry( bufferGeo );
	this.mergeVertices();

	this.distanceToPoint = function ( object, face, point ) {

		var triangle;

		triangle = new THREE.Triangle();

		var a = new THREE.Vector3().copy(this.vertices[face.a]);
		var b = new THREE.Vector3().copy(this.vertices[face.b]);
		var c = new THREE.Vector3().copy(this.vertices[face.c]);
		object.localToWorld( a );
		object.localToWorld( b );
		object.localToWorld( c );

		triangle.set( a, b, c );

		let normal = new THREE.Vector3();
		let midpoint = new THREE.Vector3();
		triangle.getNormal( normal );
		triangle.getMidpoint( midpoint );

		let constant = normal.dot( midpoint );

		return normal.dot( point ) - constant;

	}

	this.containsPoint = function ( object, point ) {

		var faces = this.faces;

		for ( var i = 0, l = faces.length; i < l; i ++ ) {

			var face = faces[ i ];

			// compute signed distance and check on what half space the point lies

			let distanceToPoint = this.distanceToPoint( object, face, point );
			// console.log(distanceToPoint, this.bufferGeo.tolerance);
			if ( distanceToPoint > this.tolerance ) return false;

		}

		return true;

	}

};

ConvexGeometry.prototype = Object.create( THREE.Geometry.prototype );
ConvexGeometry.prototype.constructor = ConvexGeometry;

// ConvexBufferGeometry

const ConvexBufferGeometry = function ( object ) {

	THREE.BufferGeometry.call( this );

	// buffers

	var vertices = [];
	var normals = [];

	if ( ConvexHull === undefined ) {

		console.error( 'THREE.ConvexBufferGeometry: ConvexBufferGeometry relies on THREE.ConvexHull' );

	}

	this.convexHull = new ConvexHull().setFromObject( object );

	this.tolerance = this.convexHull.tolerance;

	// generate vertices and normals

	var faces = this.convexHull.faces;

	for ( var i = 0; i < faces.length; i ++ ) {

		var face = faces[ i ];
		var edge = face.edge;

		// we move along a doubly-connected edge list to access all face points (see HalfEdge docs)

		do {

			var point = edge.head().point;

			vertices.push( point.x, point.y, point.z );
			normals.push( face.normal.x, face.normal.y, face.normal.z );

			edge = edge.next;

		} while ( edge !== face.edge );

	}

	// build geometry

	this.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
	this.setAttribute( 'normal', new THREE.Float32BufferAttribute( normals, 3 ) );	

	this.containsPoint = function ( point ) {

		var faces = this.convexHull.faces;

		for ( var i = 0, l = faces.length; i < l; i ++ ) {

			var face = faces[ i ];

			// compute signed distance and check on what half space the point lies

			let distanceToPoint = face.distanceToPoint( point );
			console.log(distanceToPoint, this.tolerance);
			if ( distanceToPoint > this.tolerance ) return false;

		}

		return true;

	}
};

ConvexBufferGeometry.prototype = Object.create( THREE.BufferGeometry.prototype );
ConvexBufferGeometry.prototype.constructor = ConvexBufferGeometry;
