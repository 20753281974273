
import * as THREE from 'three'

export default class DebugEngine {
    
    scene = null;

    enableDebugRaycasts = false;
    enableLogs = true;
    enableDebugger = false;
    raycastColor = 0xff0000;

    /**
     * Variable to keep track of the start of some time interval.
     */
    intervalStartTime = 0;

    /**
     * Debug Engine provides debugging related functionality.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     */
    constructor(scene) {
        this.scene = scene;
    }

    /**
     * Visualize a ray in scene using the current raycaster settings, if enableDebugRaycasts is set.
     * @param {THREE.Raycaster} raycaster - The raycaster object to use.
     */
    drawRayCastGizmo(raycaster) {
        if(this.enableDebugRaycasts) {
            this.scene.add(new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, raycaster.far, this.raycastColor) );
        }
    }

    /**
     * Show log on console, if enableLogs is set.
     * @param {Object} log - The log to add.
     */
    debugLog(log) {
        if(this.enableLogs) {
            console.log(log);
        }
    }

    /**
     * Set interval start time to current time.
     * Should be called at the start of code block whose time needs to measured.
     */
    setIntervalStartToCurrentTime() {
        this.intervalStartTime = Date.now();
    }

    /**
     * Log elapsed time for this interval.
     * Interval start is set using setIntervalStartToCurrentTime().
     * Should be called at the end of code block whose time needs to measured.
     * @param {Object} label - The logs to add to console alongside measured time.
     */
    logIntervalTime(label) {
        let now = Date.now();
        console.log( "*** " + label + " : " + ( ( now - this.intervalStartTime ) / 1000 ) + " seconds ***" );
        this.intervalStartTime = now;
    }
}