import React from "react";
import AdminMainLayout from "../AdminMainLayout";
import { Tabs, Row, Col } from "antd";
import SystemThroughput from "../SystemThroughput/SystemThroughput";
import AutomationDetails from "../AutomationDetails/AutomationDetails";
import ModelingCostReport from "../ModelingCostReport/ModelingCostReport"
import ArtistEfficiency from "../ArtistEfficiency/ArtistEfficiency";

const { TabPane } = Tabs;

const AdminAnalytics = () => {
  return (
    <>
      <AdminMainLayout selected={"18"} color={true}>
        <Row style={{ marginTop: 30 }} gutter={24}>
          <Col>
            <p className="analytics-txt-heading">
              Analytics Dashboard
            </p>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey="1"
          size="small"
          tabBarStyle={{ textAlign: "center" }}
          animated={false}
          tabBarGutter={32}>
          <TabPane tab="System Throughput" key="1">
            <SystemThroughput />
          </TabPane>

          <TabPane tab="Automation Details" key="2">
            <AutomationDetails />
          </TabPane>

          <TabPane tab="Modeling Cost" key="3">
            <ModelingCostReport />
          </TabPane>

          <TabPane tab="Artist Efficiency" key="4">
            <ArtistEfficiency />
          </TabPane>

          <TabPane tab="Admin Efficiency" key="5">
            <div className="manrope f-32 black-14">Coming Soon</div>
          </TabPane>

          <TabPane tab="Managed Service Provider Stats" key="6">
            <div className="manrope f-32 black-14">Coming Soon</div>
          </TabPane>
        </Tabs>
      </AdminMainLayout>
    </>
  );
};

export default AdminAnalytics;