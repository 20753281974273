import React from 'react';
import { Table, Divider, Row, Col, Checkbox, Modal, Button, Tooltip, message, Input, Space } from 'antd';
import { LoadingOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import * as CustomerUsageUtilities from '../../CustomerUsageUtilities'
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import InvoiceDatePicker from '../InvoiceDatePicker';
const DATE_FORMAT = 'MM/DD/YYYY';


const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class CompanyTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    hasData: true,
    dataSource: null,
    collaborationSettingsModal: false,
    currentCompany: -1,
    currentCompanySharedEntities: [],
    updateSettingsLoader: false,
    request_prices: [],
    message_load: '',
    selected_company: null,
    usage_data: [],
    start_date: moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT),
    end_date: moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT),
    range_picker: false,
  };

  getColumns = () => [
    {
      title: 'Company ID',
      dataIndex: 'company_id',
      key: 'company_id',
      sorter: (a, b) => a.company_id - b.company_id,
      ...this.getColumnSearchProps('company_id','Company ID'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company Domain',
      dataIndex: 'company_domain',
      key: 'entity_type',
      ...this.getColumnSearchProps('company_domain','Company Domain'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company Name',
      dataIndex: 'company_display_name',
      key: 'company_display_name',
      ...this.getColumnSearchProps('company_display_name','Company Name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Actions',
      key: 'action_usage',
      dataIndex: 'action_usage',
      render: (text, record) => (
        <span>
          <a onClick={() => this.showCollaborationModal(record.company_id, record.shared_entities)}>Set Sharing Settings</a>
          <Divider type="vertical" />
          <a onClick={() => this.openRangePicker(record)}>Download Invoice</a>
          <Divider type="vertical" />
          <a target="_blank" onClick={() => window.location.href = '/admin-company-usage/' + record.company_id}>View System Usage</a>
        </span>
      ),
    },
  ];

  componentDidMount = () => {
    this.handleDataSource();
    this.getRequestTypePrices();
  }

  cancelRangePicker = () => {
    this.setState({
      range_picker: false,
      exported_data_loading: false,
    });
  }

  openRangePicker = (company) => {
    this.setState({
      range_picker: true,
      selected_company: company,
      exported_data_loading: false,
    });
  }

  downloadCompanyInvoice = () => {
    this.getInvoiceData(this.state.selected_company)
  }

  getInvoiceData = (company) => {
    let loader = message.loading("Please wait, while we prepare invoice data...", 0)

    this.setState({
      message_load: loader,
      exported_data_loading: true
    });
    let payload = {
      action: "get_all_details",
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      company_id: company.company_id
    }

    console.log("Payload ", payload)

    axios.post(ENVIRONMENT.GET_INVOICES_DATA, payload)
      .then(res => {
        let usage_data = {};
        if (res.data) {
          console.log(res.data)
          usage_data = res.data;
          this.setState({
            usage_data: usage_data
          }, () => {
            this.createShareableData(company);
          })

        }
      }).catch(err => {
        console.log(err)
      });
  }


  getRequestTypePrices = async () => {
    let payload = { type: 'invoice' }
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
      .then(res => {
        if (res.data) {
          let request_prices = res.data;
          this.setState({
            request_prices: request_prices
          });
        }
      })
  }


  createShareableData = async (company) => {
    let workbook = new ExcelJS.Workbook();
    let response = await fetch('/Resources/invoice_template.xlsx');
    let buffer = await response.arrayBuffer();
    await workbook.xlsx.load(buffer);

    let summary_template = workbook.getWorksheet("Invoice");
    let detail_template = workbook.getWorksheet("Details");

    summary_template.getCell('A8').value = company.company_display_name;
    summary_template.getCell('F8').value = this.state.start_date + ' - ' + this.state.end_date;
    summary_template.getCell('H5').value = this.state.start_date;
    detail_template.getCell('D7').value = company.company_display_name;
    detail_template.getCell('B8').value = this.state.start_date;


    let row_values = [];
    let row = null;
    let summary_values = [];
    let row_index = 0;
    let row_count = 0;
    let summary_last_col = summary_template.lastColumn._number;
    FileConstants.typesOfServices.map((service) => {
      if (this.state.usage_data[service] && this.state.usage_data[service].length > 0) {

        row_values = [FileConstants.typeOfDict(service).toUpperCase(), '', '', '', '', '', '', '', ''];
        row = detail_template.addRow(row_values);
        row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } };
        row.alignment = { wrapText: false, horizontal: 'left', vertical: 'middle' };
        row.font = { name: "Calibri", size: "10", color: { argb: "FFFFFF" }, bold: true };

        this.state.usage_data[service].sort((a, b) => a['request_type'].localeCompare(b['request_type']));

        this.state.usage_data[service].map((data_request, data_index) => {
          row_count = row_count + 1;
          row_index = 16 + data_index;

          row_values = [FileConstants.setRequestTypeText(data_request['request_type'], false), '', '', '', data_request['request_data'].length, '', '', '', ''];
          row = detail_template.addRow(row_values);
          row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0000' } };
          row.alignment = { wrapText: false, horizontal: 'left', vertical: 'middle' };
          row.font = { name: "Calibri", size: "10", color: { argb: "FFFFFF" }, bold: true };

          summary_values[1] = FileConstants.setRequestTypeText(data_request['request_type'], true);
          summary_values[6] = data_request['request_data'].length;
          summary_values[7] = this.getPriceOfRequest(data_request['request_type']);
          summary_values[8] = data_request['request_data'].length * this.getPriceOfRequest(data_request['request_type']);
          summary_template.insertRow(16 + data_index, summary_values);

          for (let index = summary_last_col; index > 0; index--) {
            summary_template.getRow(row_index).getCell(index).style = {
              numFmt: index < 7 ? '' : '$#,##0.00;[Red]-$#,##0.00',
              font: {
                name: "Calibri", size: "10", color: { argb: "434343" }
              },
              fill: {
                type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' }
              },
              border: {
                top: { style: 'dotted', color: { argb: '000000' } },
                left: { style: 'dotted', color: { argb: index < 6 ? 'FFFFFF' : '000000' } },
                bottom: { style: 'dotted', color: { argb: '000000' } },
              },
              alignment: {
                wrapText: false, vertical: 'middle', horizontal: index == 1 ? 'left' : 'center'
              }
            }
          }


          data_request['request_data'].map((data) => {
            row_values = [data.id,
            data.name,
            FileConstants.requestTypeCategory(data_request['request_type']) ? data.camera_name : data.category,
            data.variation_type,
            data.last_modified_date,
            data.request_link,
            data.resolution,
            data.item_status,
            data.customer_username];

            row = detail_template.addRow(row_values);
            row.font = { name: "Calibri", size: "10", color: { argb: "434343" } }
            row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };
            row.border = {
              top: { style: 'dotted', color: { argb: '000000' } },
              left: { style: 'dotted', color: { argb: '000000' } },
              bottom: { style: 'dotted', color: { argb: '000000' } },
              right: { style: 'dotted', color: { argb: '000000' } }
            };
            row.alignment = { wrapText: false, vertical: 'middle', horizontal: 'left' }
          })

        })

      }
    })

    let endRow = summary_template.lastRow._number;
    summary_template.getRow(endRow - 2).getCell(summary_last_col).value = { formula: `SUM(H16:H${16 + row_count})` };
    summary_template.getRow(endRow - 5).getCell(summary_last_col).value = { formula: `SUM(H16:H${16 + row_count})` };

    let new_buffer = await workbook.xlsx.writeBuffer({ base64: true });
    let fileType = 'application/vnd.ms-excel'
    let extension = '.xlsx';
    let new_blob = new Blob([new_buffer], { type: fileType });

    let filename = company.company_display_name + "_" + this.state.start_date + '_' + this.state.end_date;
    filename = filename.replaceAll(" ", "_");
    filename = filename.replaceAll("/", "_");

    saveAs(new_blob, filename + extension);

    this.setState({
      exported_data_loading: false,
      show_excel: true,
      range_picker: false
    });
    setTimeout(this.state.message_load);
  }

  getPriceOfRequest = (request_type) => {
    let price = this.state.request_prices.filter(request => {
      return request.request_type == request_type
    });
    if (price[0]) {
      return price[0].price;
    } else {
      return 0;
    }
  }

  handleDataSource = () => {
    let payload = {}
    axios.post(ENVIRONMENT.COMPANY_GET_BATCH, payload)
      .then(res => {
        console.log(res);
        console.log(res.data);
        var data = [];
        for (let i = 1; i <= (res.data).length; i++) {
          data.push({
            key: i,
            company_id: res.data[i - 1]['company_id'],
            company_domain: res.data[i - 1]['company_domain'],
            company_display_name: res.data[i - 1]['company_display_name'],
            shared_entities: res.data[i - 1]['shared_entities']
          });
        }
        // sort alpahabatically
        data = data.sort(function (a, b) {
          return (a.company_display_name.toLowerCase() > b.company_display_name.toLowerCase() ? 1 : -1);
        });
        console.log(data);
        this.setState({
          dataSource: data
        })
      })
  }

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  showCollaborationModal = (companyId, sharedEntities) => {
    console.log(companyId)
    console.log(sharedEntities)
    this.setState({
      currentCompany: companyId,
      currentCompanySharedEntities: sharedEntities,
      collaborationSettingsModal: true
    });
  }

  hideCollaborationModal = () => {
    this.setState({
      collaborationSettingsModal: false
    });
  }

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };


  // Search in table functionality
  getColumnSearchProps = (dataIndex,placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  setCompanySharedEntities = (e, entity) => {
    let companySharedEntities = this.state.currentCompanySharedEntities;
    if (e.target.checked && !companySharedEntities.includes(entity)) {
      companySharedEntities.push(entity)
      this.setState({
        currentCompanySharedEntities: companySharedEntities
      });
    } else {
      companySharedEntities.splice(companySharedEntities.indexOf(entity), 1);
      this.setState({
        currentCompanySharedEntities: companySharedEntities
      });
    }
  }

  handleUpdateSettings = () => {
    this.setState({
      updateSettingsLoader: true
    });
    let payload = {
      'company_id': this.state.currentCompany,
      'shared_entities': this.state.currentCompanySharedEntities
    }

    axios.post(ENVIRONMENT.COMPANY_UPDATE, payload)
      .then(res => {
        message.info('Company Collaboration Settings Updated');
        window.location.href = '/admin_customers';
      })
  }

  onCalenderChange = (date_values) => {
    if (date_values) {
      if (date_values[0]) {
        this.setState({
          start_date: date_values[0].format(DATE_FORMAT)
        })
      }
      if (date_values[1]) {
        this.setState({
          end_date: date_values[1].format(DATE_FORMAT)
        })
      }
    }
  }

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <span>
        <div>
          {dataSource == undefined ? <DottedLoader /> : ""}
          {
            dataSource &&
            <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
          }
        </div>
        <Modal
          visible={this.state.collaborationSettingsModal}
          onOk={this.hideCollaborationModal}
          onCancel={this.hideCollaborationModal}
          className="modal-share"
          footer={[
            <div className='justify-in-end'>
              <Button key="back" className="modal-okay square font-14" onClick={() => this.handleUpdateSettings()}>
                {this.state.updateSettingsLoader ? <span>Updating<LoadingOutlined spin style={{ marginLeft: 6 }} /></span> : <span>Update Settings</span>}
              </Button>
            </div>
          ]}
        >
          <div style={{ padding: "16px 6px 0 14px" }}>
            <h2 className="share-modal-heading">Shared Entities</h2>
            <Row style={{ marginBottom: "30px" }} className="modal-radio-text">
              <Col span={12}>
                <Checkbox
                  defaultChecked={true}
                  className="black-checkbox"
                  onChange={(e) => this.setCompanySharedEntities(e, 'product')}
                  checked={this.state.currentCompanySharedEntities.includes('product')}
                >
                  <span>Product Library <Tooltip title={<span className="manrope f-12 white">Check this option if you want product library to be shared among all company users.</span>}>
                    <InfoCircleOutlined />
                  </Tooltip></span>
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  defaultChecked={true}
                  className="black-checkbox"
                  onChange={(e) => this.setCompanySharedEntities(e, 'rproduct')}
                  checked={this.state.currentCompanySharedEntities.includes('rproduct')}
                >
                  <span>Review Products <Tooltip title={<span className="manrope f-12 white">Check this option if you want products to be visible in review section of all company users.</span>}>
                    <InfoCircleOutlined />
                  </Tooltip></span>
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  defaultChecked={true}
                  className="black-checkbox"
                  onChange={(e) => this.setCompanySharedEntities(e, 'material')}
                  checked={this.state.currentCompanySharedEntities.includes('material')}
                >
                  <span>Material Library <Tooltip title={<span className="manrope f-12 white">Check this option if you want material library to be shared among all company users.</span>}>
                    <InfoCircleOutlined />
                  </Tooltip></span>
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  defaultChecked={true}
                  className="black-checkbox"
                  onChange={(e) => this.setCompanySharedEntities(e, 'rroom')}
                  checked={this.state.currentCompanySharedEntities.includes('rroom')}
                >
                  <span>Review Spaces <Tooltip title={<span className="manrope f-12 white">Check this option if you want spaces to be visible in review section among all company users.</span>}>
                    <InfoCircleOutlined />
                  </Tooltip></span>
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  defaultChecked={true}
                  className="black-checkbox"
                  onChange={(e) => this.setCompanySharedEntities(e, 'room')}
                  checked={this.state.currentCompanySharedEntities.includes('room')}>
                  <span>Space Library <Tooltip title={<span className="manrope f-12 white">Check this option if you want space library to be shared among all company users.</span>}>
                    <InfoCircleOutlined />
                  </Tooltip></span>
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  defaultChecked={true}
                  className="black-checkbox"
                  onChange={(e) => this.setCompanySharedEntities(e, 'scene')}
                  checked={this.state.currentCompanySharedEntities.includes('scene')}>
                  <span>Scene Library <Tooltip title={<span className="manrope f-12 white">Check this option if you want scene library to be shared among all company users.</span>}>
                    <InfoCircleOutlined />
                  </Tooltip></span>
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  defaultChecked={true}
                  className="black-checkbox"
                  onChange={(e) => this.setCompanySharedEntities(e, 'collection')}
                  checked={this.state.currentCompanySharedEntities.includes('collection')}>
                  <span>Collection Library <Tooltip title={<span className="manrope f-12 white">Check this option if you want collection library to be shared among all company users.</span>}>
                    <InfoCircleOutlined />
                  </Tooltip></span>
                </Checkbox>
              </Col>
            </Row>
          </div>
        </Modal>
        <InvoiceDatePicker
          single_invoice={true}
          company={this.state.selected_company}
          loader={this.state.exported_data_loading}
          onCancel={this.cancelRangePicker}
          visible={this.state.range_picker}
          exportData={this.downloadCompanyInvoice}
          onCalenderChange={this.onCalenderChange}
          startDate={this.state.start_date}
          endDate={this.state.end_date}
        />
      </span>
    );
  }
}

export default CompanyTable;