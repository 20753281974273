import { message } from "antd";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";

export const snakeToCamelWithSpaces = (snakeStr) => {
  return snakeStr
    .toLowerCase()
    .replace(/(_\w)/g, function (match) {
      return " " + match[1].toUpperCase();
    })
    .replace(/^./, function (match) {
      return match.toUpperCase();
    });
};

export const saveToZip = (filename, urls) => {
  let loader = message.loading("Preparing to download..", 0);
  const zip = new JSZip();
  urls.forEach((url) => {
    const blobPromise = fetch(url, { cache: "no-store" }).then((r) => {
      if (r.status === 200) return r.blob();
      return Promise.reject(new Error(r.statusText));
    });
    const name = url.split("/").slice(-1)[0];
    zip.file(name, blobPromise);
  });

  zip
    .generateAsync({ type: "blob" })
    .then((blob) => {
      saveAs(blob, filename);
      setTimeout(loader);
    })
    .catch((e) => console.log(e));
};
