
import * as THREE from "three";

export default class LostAndFoundArea {

    materialColor = 0x808080;

    areaObj = {
        root: null,
        ceiling: null,
        floor: null,
        walls: [],
        doors: [],
        miscItemsParent: null
    }
    size = null;
    box = new THREE.Box3();

    constructor(items) {
        
        // let box = new THREE.Box3();
        // box.setFromObject(items[0]);

        
        // let elementBox = new THREE.Box3();
        // let elementBoxSize = new THREE.Vector3();
        // for (let index = 1; index < items.length; index++) {
        //     const element = items[index];
            
        //     if (element != undefined && element != null){
        //         elementBox.setFromObject(element);
        //         elementBox.getSize(elementBoxSize);
        //         box.expandByVector(elementBoxSize);
        //     }
        // }

        let boxSize = new THREE.Vector3(5, 3, 7);
        // box.getSize(boxSize);
        // boxSize.divide(new THREE.Vector3(3, 1, 2));
        this.size = boxSize;

        let materialColor = this.materialColor;

        this.areaObj.root = new THREE.Object3D();
        this.areaObj.floor = new THREE.Mesh( new THREE.BoxBufferGeometry(boxSize.x, 0.1, boxSize.z), new THREE.MeshStandardMaterial( {color: 0x323232} ) );
        this.areaObj.floor.name = 'LFAArea_Floor'
        this.areaObj.floor.castShadow = false;
        this.areaObj.floor.receiveShadow = true;
        this.hideElement(this.areaObj.floor);
        this.areaObj.floor.position.y -= boxSize.y/2;
        this.areaObj.root.add( this.areaObj.floor );

        this.areaObj.ceiling = new THREE.Mesh( new THREE.BoxBufferGeometry(boxSize.x, 0.1, boxSize.z), new THREE.MeshStandardMaterial( {color: materialColor} ) );
        this.hideElement(this.areaObj.ceiling);
        this.areaObj.ceiling.castShadow = false;
        this.areaObj.ceiling.position.y += boxSize.y/2;
        this.areaObj.ceiling.name = "LFAArea_Ceiling";
        this.areaObj.ceiling.userData.height = 0.1;
        this.areaObj.root.add( this.areaObj.ceiling );

        let wallBack = new THREE.Mesh( new THREE.BoxBufferGeometry(boxSize.x, boxSize.y, 0.1), new THREE.MeshStandardMaterial( {color: materialColor} ) );
        wallBack.position.z -= boxSize.z/2;
        this.hideElement(wallBack);
        wallBack.name = "LFAArea_WallBack";
        this.areaObj.walls.push(wallBack);
        this.areaObj.root.add( wallBack );

        let wallFront = new THREE.Mesh( new THREE.BoxBufferGeometry(boxSize.x, boxSize.y, 0.1), new THREE.MeshStandardMaterial( {color: materialColor} ) );
        wallFront.position.z += boxSize.z/2;
        this.hideElement(wallFront);
        wallFront.name = "LFAArea_WallFront";
        this.areaObj.walls.push(wallFront);
        this.areaObj.root.add( wallFront );

        let wallLeft = new THREE.Mesh( new THREE.BoxBufferGeometry(0.1, boxSize.y, boxSize.z), new THREE.MeshStandardMaterial( {color: materialColor} ) );
        wallLeft.position.x -= boxSize.x/2;
        this.hideElement(wallLeft);
        wallLeft.name = "LFAArea_WallLeft";
        this.areaObj.walls.push(wallLeft);
        this.areaObj.root.add( wallLeft );
    }

    getRootNode() {
        return this.areaObj.root;
    }

    hideElement(element) {
        element.material.transparent = true;
        element.material.opacity = 0;
        element.material.depthWrite = false;
    }
}