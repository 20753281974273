import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col, Modal, Tabs } from 'antd';
import ProductImageCarousel from './ProductImageCarousel';
import FileConstants from '../../../../FileConstants';
import ReactPlayer from 'react-player';

const { TabPane } = Tabs;

const ProductAssetViewer = () => {
    const { productAssetData, product_id, selectedAsset, statusOf360Spin, statusOfMp4Video,
        productAssetViewer, closeProductAssetViewer, setSelectedAsset, getDownloadUrl } = useContext(CustomerProductContext);

    const changeAssetTab = (key) => {
        setSelectedAsset(key);
    }

    return (
        <Modal
            className='product-asset-viewer-modal'
            visible={productAssetViewer}
            footer={null}
            closable={true}
            onCancel={closeProductAssetViewer}>
            <Row>
                <Col span={24}>
                    <Tabs
                        className='items-table'
                        onChange={changeAssetTab}
                        tabBarGutter={32}
                        activeKey={selectedAsset}
                        size="large">
                        <TabPane
                            tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "images" ? `selected` : ``}`}>Images</span>}
                            key={"images"}
                            className="pd-10">
                            <ProductImageCarousel />
                        </TabPane>
                        <TabPane
                            tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "3d_preview" ? `selected` : ``}`}>3D Preview</span>}
                            key={"3d_preview"}
                            className="pd-10">
                            <div className='glb-height'>
                                <Row id='model-inspector-frame' className='model-inspector-viewer-customer'>
                                    <iframe id='model-viewer-3d' className='model-inspector-frame-customer' src={FileConstants.ModelInspector + product_id} />
                                </Row>
                            </div>
                        </TabPane>
                        {statusOf360Spin && (
                            <TabPane
                                tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "360_view" ? `selected` : ``}`}>360 Spin</span>}
                                key={"360_view"}
                                className="pd-10">
                                <div id="container-360" className="product-viewer-360-container">
                                    {statusOf360Spin === "pending" ?
                                        (<div className="render-in-progress">
                                            360 Spin in Progress
                                        </div>) : (<div
                                            className="cloudimage-360"
                                            id="image-360"
                                            data-folder={productAssetData['360']?.["data-folder"]}
                                            data-filename={productAssetData['360']?.["data-filename"]}
                                            data-amount={productAssetData['360']?.["data-amount"]} />
                                        )}
                                </div>
                            </TabPane>
                        )}
                        {statusOfMp4Video && (
                            <TabPane
                                tab={<span className={`manrope f-14 asset-tab-heading ${selectedAsset === "mp4_video" ? `selected` : ``}`}>MP4 Video</span>}
                                key={"mp4_video"}
                                className="pd-10">
                                <Row className={`${statusOfMp4Video === "pending" ? `product-viewer-mp4-viewer` : ``} `}>
                                    {statusOfMp4Video === "pending" ?
                                        (<div className="render-in-progress">
                                            MP4 Video in Progress
                                        </div>) :
                                        (<ReactPlayer
                                            width={"100%"}
                                            height={"calc(100vh - 140px)"}
                                            url={getDownloadUrl("VIDEO")}
                                            controls={true}
                                            loop={true} />
                                        )}
                                </Row>
                            </TabPane>
                        )}
                    </Tabs>
                </Col>
            </Row>
        </Modal>
    );
}

export default ProductAssetViewer;
