import React from "react";
import { SearchOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { Tabs, Input } from "antd";
import FileConstants from "../../../../FileConstants";
import RoomsTable from '../../RoomsTable';
import MaterialTable from "../../AdminComponents/MaterialTable/MaterialTable";

const { TabPane } = Tabs;

class ArtistMaterialsData extends React.Component {
  state = { size: "small" , key :1};

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  componentDidMount = () => {
    if(document.getElementsByClassName("ant-tabs")[0] != undefined){
      document.getElementsByClassName("ant-tabs")[0].insertBefore(document.getElementsByClassName('description-products')[0], document.getElementsByClassName("ant-tabs")[0].children[1])
    }
  }

  render() {
    const { size,key } = this.state;
    return (
      <div className="artist-product-data">
        <span style={{marginBottom:20}} className="manrope f-24 33">
            Materials Page
        </span>
        <div style={{marginBottom:16, marginTop:16}} className="description-products">
          <span className="product-artist-description manrope f-12">
            <InfoCircleOutlined/> {FileConstants.DescriptionText[this.state.key]}
          </span>
        </div>
        <Tabs  className='items-table' defaultActiveKey="1" size={size} tabBarStyle={{ textAlign: 'center' }} animated={false} onTabClick={(e)=> {this.setState({key:e})}}>
          <TabPane tab="Pending" key="1">
            <MaterialTable state="enqueued" artist={true}/>
          </TabPane>
          <TabPane tab="Rejected" key="2">
            <MaterialTable state="admin_rejected" artist={true}/>
          </TabPane>
          <TabPane tab="Being Processed" key="4">
            <MaterialTable state="being_processed"artist={true} />
          </TabPane>
          <TabPane tab="Admin Review" key="5">
            <MaterialTable state="admin_review" artist={true}/>
          </TabPane>
          <TabPane tab="Failed & Aborted" key="6">
            <MaterialTable state="failed_aborted" artist={true}/>
          </TabPane>

        </Tabs>
      </div>
    );
  }
}

export default ArtistMaterialsData;