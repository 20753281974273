import React from 'react';
import { InputNumber, Slider,Tooltip, Upload, Card, Button, Tabs, Row, message} from 'antd';
import {LoadingOutlined, UndoOutlined} from '@ant-design/icons';
import { SketchPicker } from "react-color";
import ENVIRONMENT from '../../../../environments'
import * as Styles from "../../../../Styles"
import * as Constants from "../../CustomerComponents/Constants";
import FileConstants from '../../../../FileConstants'

const PROGRESS_BAR = Styles.progress_bar;

class SpaceConfigurator extends React.Component{

    state= {
        color: null,
        selectedType: 'Space',
        selectionColorConfigurable: null,
        selectedMaterial:null,
        selectedUploadedTexture: null,
        selectedMaterialConfig:null,
        glossiness: 0,
        horizontalRepeat: FileConstants.MATERIAL_PROPERTIES.defaultRepeat,
        verticalRepeat: FileConstants.MATERIAL_PROPERTIES.defaultRepeat,
        horizontalOffset: 0,
        verticalOffset: 0,
        rotation: 0,
        texture: null,
        enableMaterialUpload: this.props.enableMaterialUpload,
        applyConfigurationToAll : false,
        preserveTexture : false,
        configApplied: false,
        uploadControllers:{},
        activeTab: "edit-color"
    }

    componentDidMount(){
        const selectionType = this.props.spaceSelectionType;
        if (selectionType != '') {
            this.setState({
                selectedType: selectionType
            })
        }
        const selectionColorConfigurable = this.props.spaceSelectionColorConfigurable;
        let preserveTexture = selectionColorConfigurable;

        const configInfo = this.props.spaceSelectionConfig; 
        if (configInfo && Object.keys(configInfo).length > 0) {
        
            if (configInfo.type == 'color') {
                this.setState({
                    color: configInfo.hexCode
                })
                preserveTexture = configInfo.base_material_asset != null;
            } 
            else {
                let textureSource = null;
                if (configInfo.type == 'material') {
                    textureSource = ENVIRONMENT.getBaseURL(configInfo.platform) + ENVIRONMENT.MATERIAL_FILE_URI + configInfo.id + '/' + encodeURIComponent(configInfo.base_map);
                }
                else {
                    textureSource = ENVIRONMENT.getBaseURL(configInfo.platform) + ENVIRONMENT.SPACE_MATERIAL_FILE_URI + configInfo.id + '/' + encodeURIComponent(configInfo.base_map);
                }
                this.setState({
                    selectedMaterial:configInfo.id,
                    selectedMaterialConfig:configInfo,
                    glossiness: 100 - Math.round(configInfo.roughness * 100),
                    horizontalRepeat: 1/configInfo.horizontal_repeat,
                    verticalRepeat: 1/configInfo.vertical_repeat,
                    horizontalOffset: configInfo.horizontal_offset,
                    verticalOffset: configInfo.vertical_offset,
                    rotation:configInfo.rotation,
                    texture: textureSource
                })
                this.handleTabChange('add-custom');
            }
        }
        this.setSelectionColorConfigurable(selectionColorConfigurable, ()=> {
            this.changePreserveTextureOption(preserveTexture);
        });
        
    }

    handleTabChange = (key) => {
        this.setState({ activeTab: key }, ()=> {
            this.isColorTabActive() ? this.props.setPreserveTextureOption(this.state.preserveTexture) : this.props.setPreserveTextureOption(false);
            this.setColorConfigurableDisclaimer();
        }); 
    };

    setSelectionColorConfigurable = (colorConfigurable, callback) => {
        this.setState({
            selectionColorConfigurable: colorConfigurable
        }, ()=> {
            if(callback) {
                callback();
            }
        })
    }

    setColorConfigurableDisclaimer = () => {
        if (this.isColorTabActive()) {
            if (this.state.preserveTexture) {
                this.props.setDisclaimer("Changing the color will preserve the texture on color change");
            }
            else {
                this.props.setDisclaimer("Changing the color will not preserve the texture on color change");
            }
        }
        else {
            this.props.setDisclaimer("");
        }
    }

    isColorTabActive() {
        return this.state.activeTab == "edit-color";
    }

    changePreserveTextureOption = ( state, updateColor = false ) => {
        this.setState({
            preserveTexture: state
        }, ()=> {
            this.setColorConfigurableDisclaimer();
            this.props.setPreserveTextureOption(this.state.preserveTexture);
            updateColor && this.isColorTabActive() && this.applyColor(this.state.color);
            if (state) {
                this.setState({
                    applyConfigurationToAll : false
                }, ()=> {
                    this.props.restoreMaterialToAllSpaceSelection()
                })
            }
        })
    }

    applyColor = (color) => {
        if (color) {
            let configInfo = {
                "hexCode": color,
                "type":"color"
            }
            this.setState({
                color:color,
                selectedMaterial: null,
                selectedUploadedTexture:null,
                horizontalRepeat: FileConstants.MATERIAL_PROPERTIES.defaultRepeat,
                verticalRepeat: FileConstants.MATERIAL_PROPERTIES.defaultRepeat,
                horizontalOffset: 0,
                verticalOffset: 0,
                rotation: 0,
                glossiness: 0,
                selectedMaterialConfig: configInfo,
                configApplied: true
            })
            this.props.applyColorToSpaceSelection(color);
            if (this.state.applyConfigurationToAll) {
                this.props.applyColorToAllSpaceSelection(color);
            }
        }
    }

    applyMaterial = (material) => {
        if (this.state.selectedMaterial != material.id) {
            let configInfo = this.state.selectedMaterialConfig;
            if (configInfo == null) {
                configInfo = {};
            }
            configInfo["id"] = material["id"];
            configInfo["type"] = material["type"];
            configInfo["base_map"] = material["swatch"];
            configInfo["roughness"] = 1 - (this.state.glossiness/100);
            configInfo["horizontal_repeat"] = 1/this.state.horizontalRepeat;
            configInfo["vertical_repeat"] = 1/this.state.verticalRepeat;
            configInfo["horizontal_offset"] = this.state.horizontalOffset;
            configInfo["vertical_offset"] = this.state.verticalOffset;
            configInfo["rotation"] = this.state.rotation;
            let textureSource = material.baseURL + encodeURIComponent(material.swatch);
            this.setState({
                selectedMaterial: material.id,
                selectedUploadedTexture:null,
                selectedMaterialConfig: configInfo,
                texture: textureSource,
                configApplied: true
            })
            this.props.applyMaterialToSpaceSelection(textureSource, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                ,this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
            if (this.state.applyConfigurationToAll) {
                this.props.applyMaterialToAllSpaceSelection(textureSource, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                    ,this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
            }
        } 
    }

    applyUploadedTexture = (material) => {
        if (this.state.selectedUploadedTexture != material) {
            let textureSource = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.uid + '/' + material.name)
            let configInfo = {}
            configInfo["id"] = null;
            configInfo["type"] = "texture";
            configInfo["base_map"] = material.name;
            configInfo["roughness"] = 1 - (this.state.glossiness/100);
            configInfo["horizontal_repeat"] = 1/this.state.horizontalRepeat;
            configInfo["vertical_repeat"] = 1/this.state.verticalRepeat;
            configInfo["horizontal_offset"] = this.state.horizontalOffset;
            configInfo["vertical_offset"] = this.state.verticalOffset;
            configInfo["rotation"] = this.state.rotation;
            configInfo["upload"] = true;
            configInfo["thumbnail"] = material;
            this.setState({
                selectedUploadedTexture: material,
                selectedMaterial: null,
                selectedMaterialConfig: configInfo,
                texture: textureSource,
                configApplied: true
            })
            this.props.applyMaterialToSpaceSelection(textureSource, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                , this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
            if (this.state.applyConfigurationToAll) {
                this.props.applyMaterialToAllSpaceSelection(textureSource, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                    , this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
            }
        }
    }

    applyRoughness = (glossiness) => {
        let configInfo = this.state.selectedMaterialConfig;
        configInfo["roughness"] = 1 - ( glossiness/100 );
        this.setState({
            selectedMaterialConfig: configInfo,
            glossiness: glossiness
        })
        this.props.applyMaterialToSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
            ,glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
        if (this.state.applyConfigurationToAll) {
            this.props.applyMaterialToAllSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                ,glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
        }
    }

    setHorizontalRepeat = (horizontalRepeat) => {
        if (horizontalRepeat < FileConstants.MATERIAL_PROPERTIES.minRepeat) {
            horizontalRepeat = FileConstants.MATERIAL_PROPERTIES.minRepeat;
        }
        else if (horizontalRepeat > FileConstants.MATERIAL_PROPERTIES.maxRepeat) {
            horizontalRepeat = FileConstants.MATERIAL_PROPERTIES.maxRepeat;
        } 
        let configInfo = this.state.selectedMaterialConfig;
        configInfo["horizontal_repeat"] = 1/horizontalRepeat;
        this.setState({
            selectedMaterialConfig: configInfo,
            horizontalRepeat: horizontalRepeat
        })
        this.props.applyMaterialToSpaceSelection(this.state.texture, 1/horizontalRepeat, 1/this.state.verticalRepeat
            , this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
        if (this.state.applyConfigurationToAll) {
            this.props.applyMaterialToAllSpaceSelection(this.state.texture, 1/horizontalRepeat, 1/this.state.verticalRepeat
                , this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
        }
    }

    setVerticalRepeat = (verticalRepeat) => {
        if (verticalRepeat < FileConstants.MATERIAL_PROPERTIES.minRepeat) {
            verticalRepeat = FileConstants.MATERIAL_PROPERTIES.minRepeat;
        }
        else if (verticalRepeat > FileConstants.MATERIAL_PROPERTIES.maxRepeat) {
            verticalRepeat = FileConstants.MATERIAL_PROPERTIES.maxRepeat;
        } 
        let configInfo = this.state.selectedMaterialConfig;
        configInfo["vertical_repeat"] = 1/verticalRepeat;
        this.setState({
            selectedMaterialConfig: configInfo,
            verticalRepeat: verticalRepeat
        })
        this.props.applyMaterialToSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/verticalRepeat
            , this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
        if (this.state.applyConfigurationToAll) {
            this.props.applyMaterialToAllSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/verticalRepeat
                , this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
        }
    }

    setHorizontalOffset = (horizontalOffset) => {
        if (horizontalOffset < 0) {
            horizontalOffset = 0;
        }
        else if (horizontalOffset > FileConstants.MATERIAL_PROPERTIES.maxRepeat) {
            horizontalOffset = FileConstants.MATERIAL_PROPERTIES.maxRepeat;
        } 
        let configInfo = this.state.selectedMaterialConfig;
        configInfo["horizontal_offset"] = horizontalOffset;
        this.setState({
            selectedMaterialConfig: configInfo,
            horizontalOffset: horizontalOffset
        })
        this.props.applyMaterialToSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
            , this.state.glossiness/100, horizontalOffset, this.state.verticalOffset, this.state.rotation)
        if (this.state.applyConfigurationToAll) {
            this.props.applyMaterialToAllSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                , this.state.glossiness/100, horizontalOffset, this.state.verticalOffset, this.state.rotation)
        }
    }

    setVerticalOffset = (verticalOffset) => {
        if (verticalOffset < 0) {
            verticalOffset = 0;
        }
        else if (verticalOffset > FileConstants.MATERIAL_PROPERTIES.maxRepeat) {
            verticalOffset = FileConstants.MATERIAL_PROPERTIES.maxRepeat;
        } 
        let configInfo = this.state.selectedMaterialConfig;
        configInfo["vertical_offset"] = verticalOffset;
        this.setState({
            selectedMaterialConfig: configInfo,
            verticalOffset: verticalOffset
        })
        this.props.applyMaterialToSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
            , this.state.glossiness/100, this.state.horizontalOffset, verticalOffset, this.state.rotation)
        if (this.state.applyConfigurationToAll) {
            this.props.applyMaterialToAllSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                , this.state.glossiness/100, this.state.horizontalOffset, verticalOffset, this.state.rotation)
        }
    }

    setRotation = () => {
        const angle = (this.state.rotation + 90) % 360;
        this.setState({
            rotation: angle
        })
        let configInfo = this.state.selectedMaterialConfig;
        configInfo["rotation"] = angle;
        this.props.applyMaterialToSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
            ,this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, angle)
        if (this.state.applyConfigurationToAll) {
            this.props.applyMaterialToAllSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                ,this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, angle)
        }
    }

    applyColorToAll = () => {
        let checked = !this.state.applyConfigurationToAll;
        this.setState({
            applyConfigurationToAll: checked
        })
        if (this.state.configApplied && checked) {
            this.props.applyColorToAllSpaceSelection(this.state.color);
        }
        else if (this.state.configApplied && !checked) {
            this.props.restoreMaterialToAllSpaceSelection();
        }
    }

    applyMaterialToAll = () => {
        let checked = !this.state.applyConfigurationToAll;
        this.setState({
            applyConfigurationToAll: checked
        })
        if (this.state.configApplied && checked) {
            this.props.applyMaterialToAllSpaceSelection(this.state.texture, 1/this.state.horizontalRepeat, 1/this.state.verticalRepeat
                ,this.state.glossiness/100, this.state.horizontalOffset, this.state.verticalOffset, this.state.rotation)
        }
        else if (this.state.configApplied && !checked) {
            this.props.restoreMaterialToAllSpaceSelection();
        }
    }

    setUploadedTexturesList = (uploadedTexturesList) => {
        this.props.setUploadedTexturesList(uploadedTexturesList);
    }

    handleChangeTexturesList = (info) => {
        this.props.setUploadedTexturesList(info.fileList);
        const { file, fileList } = info;
        // Check if file is uploading
        if (file.status === 'uploading' && !this.state.uploadControllers[file.uid]) {
            const abortController = new AbortController();
            this.state.uploadControllers[file.uid] = abortController;

            // Set a timeout to abort the upload if it takes too long (e.g., 30 seconds)
            setTimeout(() => {
                if (file.status === 'uploading') {
                    this.state.uploadControllers[file.uid].abort();
                    delete this.state.uploadControllers[file.uid];
                    message.error(`${file.name} upload timed out.`);
                    // Update the file status to error
                    const newFileList = fileList.filter(f => f.uid !== file.uid);
                    this.props.setUploadedTexturesList(newFileList);
                }
            }, 35000);
        }
        else if (file.status == "done") {
            delete this.state.uploadControllers[file.uid];
        }
    }

    discardConfiguration() {
        this.props.discardSpaceConfigurationSettings();
    }

    resetConfiguration() {
        this.props.resetSpaceConfigurationSettings();
    }

    updateConfiguration() {
        this.props.updateSpaceConfigurationSettings(this.state.selectedMaterialConfig, (material)=> {this.updateAppliedUploadedTexture(material)});
    }

    updateAppliedUploadedTexture(material) {
        let textures = this.props.textureInfo;
        textures = textures.filter(item => item.name !== material["swatch"]);
        this.setUploadedTexturesList(textures);
        if (material.update) {
            let configInfo = {};
            configInfo["id"] = material["id"];
            configInfo["type"] = material["type"];
            configInfo["base_map"] = material["swatch"];
            configInfo["roughness"] = 1 - (this.state.glossiness/100);
            configInfo["horizontal_repeat"] = 1/this.state.horizontalRepeat;
            configInfo["vertical_repeat"] = 1/this.state.verticalRepeat;
            configInfo["horizontal_offset"] = this.state.horizontalOffset;
            configInfo["vertical_offset"] = this.state.verticalOffset;
            configInfo["rotation"] = this.state.rotation;
            this.setState({
                selectedMaterial: material.id,
                selectedUploadedTexture:null,
                selectedMaterialConfig: configInfo,
                texture: material.baseURL + encodeURIComponent(material.swatch)
            })
        }
    }

    render(){
        return <div className="side-drawer-card"> 
            <div className="side-drawer-heading">
                <div>
                    {this.state.selectedType} Settings
                </div>
                <Tooltip title={<span className='manrope f-12 white'>Reset to original configuration of the space</span>}> 
                <div className="side-drawer-reset-button" onClick={()=>{this.resetConfiguration()}}>
                    <UndoOutlined style={{color: "#276DD7", fontSize:"1.0vw", paddingTop:"1%", paddingBottom:"1%", paddingLeft:"5px", paddingRight:"5px"}}/>
                    Reset
                </div>
                </Tooltip>
            </div>
            <div className="side-drawer-element">
                <Tabs activeKey={this.state.activeTab} onChange={this.handleTabChange}>
                    <Tabs.TabPane tab="Edit Color" key="edit-color" >
                        <Row className="align-in-center position-relative">
                            <div className="overflow-hidden">
                                <SketchPicker
                                onChange={(color) => {
                                    this.applyColor(color.hex);
                                }}
                                width="fit-content"
                                color={this.state.color ? this.state.color : "#000000"}
                                presetColors={[]}
                                disableAlpha = {true}
                                />
                            </div>
                        </Row>
                        {
                        this.isColorTabActive() &&
                        <Row>
                            <Tooltip title={!this.state.selectionColorConfigurable && <span className='manrope f-12 white'>Color change is not supported for this selection while preserving the existing texture.</span>}> 
                            <div className="side-drawer-checkbox align-center checkbox-margin">
                                <input disabled={!this.state.selectionColorConfigurable} className={!this.state.selectionColorConfigurable ? "side-drawer-menu-icon left-align disabled" : "side-drawer-menu-icon left-align"} type="checkbox" checked = {this.state.preserveTexture}
                                    onChange={()=>{this.changePreserveTextureOption(!this.state.preserveTexture, true)}} />
                                <span className={!this.state.selectionColorConfigurable ?"side-drawer-text large disabled-thin" : "side-drawer-text large"}>Preserve Texture</span>
                           </div>
                           </Tooltip>
                        </Row>
                        }
                        <Row>
                        <Tooltip title={this.state.preserveTexture && <span className='manrope f-12 white'>Apply to All is disabled since preserve texture might not be supported for all the selections.</span>}> 
                            <div className="side-drawer-checkbox align-center checkbox-margin">
                                <input disabled={this.state.preserveTexture} className={this.state.preserveTexture ? "side-drawer-menu-icon left-align disabled" : "side-drawer-menu-icon left-align"} type="checkbox" checked = {this.state.applyConfigurationToAll}
                                    onChange={this.applyColorToAll} />
                                <span className={this.state.preserveTexture ? "side-drawer-text large disabled-thin" : "side-drawer-text large"}>Apply Configuration To All {this.state.selectedType}s</span>
                           </div>
                        </Tooltip>
                        </Row>
                        <Row>
                            <div className="justify-in-center side-drawer-buttons-group position-relative"  >
                                <Button className="square font-14 side-drawer-button cancel-button" htmlType="submit" onClick={()=>{this.discardConfiguration();}}>
                                        Cancel
                                </Button>
                                <Button className="square font-14 side-drawer-button okay-button" htmlType="submit" onClick={()=>{this.updateConfiguration();}}>
                                        Apply
                                </Button>
                            </div>
                            <div>
                            <p className="side-drawer-note-text d-flex">
                                <img className="align-icon" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                                <div>
                                    Click <span className="bold">Apply</span> to update configured settings and <span className="bold">Cancel</span> to discard settings
                                </div>
                            </p>
                            </div>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Add Custom" key="add-custom" >
                        {this.state.enableMaterialUpload ?
                        <Row className="align-in-center position-relative">
                            <Upload {...Constants.upload_props} 
                                multiple={false} 
                                showUploadList={false}
                                accept={".png,.jpg,.jpeg"}
                                fileList={this.props.textureInfo} 
                                onRemove={file => {
                                    const index = this.props.textureInfo.indexOf(file);
                                    const newFileList = this.props.textureInfo.slice();
                                    newFileList.splice(index, 1);
                                    this.setUploadedTexturesList(newFileList);
                                    newFileList.forEach((newFile) => {
                                        if (newFile.status !== "error"){
                                            message.error("Error Uploading file!")
                                        }
                                    })
                                    return true;    
                                }}
                                openFileDialogOnClick={true}
                                onChange = {this.handleChangeTexturesList}
                                listType="text"
                                className="size-drawer-upload-box"
                                progress={PROGRESS_BAR}
                                >
                                <Button className="side-drawer-button upload-button" htmlType="submit" onClick={()=>{}}>
                                    Upload
                                </Button> 
                                            
                            </Upload>  
                        </Row>
                        : ''}
                        <Row className="align-in-center side-drawer-lib-wrapper">
                        <h5 className="side-drawer-text">Select from Materials And Uploads</h5>
                        <div id = "material-library-section" className="side-drawer-lib product-tab-content-scrollable">
                        {this.props.textureInfo && this.props.textureInfo.slice(0).reverse().map((material,index) => (
                        <Card className="size-fit-content" onClick={() => {this.applyUploadedTexture(material)}} >
                            { material.status == "done"  ?
                            <img className ="side-drawer-card-img" src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.uid + '/' + material.name)}/> : 
                            <div>
                                <img className ="side-drawer-card-img" />
                                <LoadingOutlined className="side-drawer-loader-img" size="large" spin />
                            </div>
                            }
                            {this.state.selectedUploadedTexture == material ?
                            <div> {this.props.spaceConfigurationApplied == true ? <img className="success-img" src="/img/success.png"/> 
                            : <LoadingOutlined className="side-drawer-loader-img" size="large" spin />}
                            </div>
                            : ''}
                            <div className="manrope black-00 side-drawer-card-text" >{}</div>
                            
                        </Card>))}
                        {this.props.materialInfo && this.props.materialInfo.map((material,index) => (
                        <Card className="size-fit-content" onClick={() => this.applyMaterial(material)} >
                            {material.swatch ?
                            <img className ="side-drawer-card-img" key={material.baseURL + material.swatch} src={material.baseURL + material.swatch}/>: ''}                            
                            {this.state.selectedMaterial == material.id ?
                            <div> {this.props.spaceConfigurationApplied == true ? <img className="success-img" src="/img/success.png"/> 
                            : <LoadingOutlined className="side-drawer-loader-img" size="large" spin />}
                            </div>
                            : ''}
                            { material.name != undefined ?
                             <Tooltip title={<span className='manrope f-12 white'>{material.name}</span>}> 
                            <div className="manrope black-00 side-drawer-card-text">{material.name}</div>
                            </Tooltip> : <div className="manrope black-00 side-drawer-card-text" >{material.name}</div>
                            }
                        </Card>
                        ))
                        }
                        </div>
                        <div className="side-drawer-slider-wrapper">
                        <hr/>
                        <Row>
                            <div className="side-drawer-checkbox">
                                <input className="side-drawer-menu-icon left-align" type="checkbox" checked = {this.state.applyConfigurationToAll}
                                    onChange={this.applyMaterialToAll} />
                                <span className="side-drawer-text large">Apply Configuration To All {this.state.selectedType}s</span>
                           </div>
                        </Row>
                        <hr/>
                        </div>
                        <div className="side-drawer-slider-wrapper side-drawer-lib justify-center product-tab-content-scrollable">
                        <h5 className="side-drawer-text">Glossiness</h5>
                        <Row className="align-in-center position-relative">
                            <div className="black-fov-slider side-drawer-slider">
                                <Slider disabled = {this.state.texture == null ? true: false}
                                    min={FileConstants.MATERIAL_PROPERTIES.minRoughness}
                                    max={FileConstants.MATERIAL_PROPERTIES.maxRoughness}
                                    onChange={this.applyRoughness}
                                    value={typeof this.state.glossiness === 'number' ? this.state.glossiness : 0}
                                />
                            </div>
                            <div className="custom-input-contain-gray side-drawer-slider-input">
                                <InputNumber disabled = {this.state.texture == null  ? true: false}
                                    className="side-drawer-input-fov"
                                    min={FileConstants.MATERIAL_PROPERTIES.minRoughness}
                                    max={FileConstants.MATERIAL_PROPERTIES.maxRoughness}
                                    value={this.state.glossiness}
                                    onChange={this.applyRoughness}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </div>
                        </Row>
                        <h5 className="side-drawer-text">Horizontal Repeat</h5>
                        <Row className="align-in-center position-relative">
                            <div className="black-fov-slider side-drawer-slider">
                                <Slider disabled = {this.state.texture == null  ? true: false}
                                    min={FileConstants.MATERIAL_PROPERTIES.minRepeat}
                                    max={FileConstants.MATERIAL_PROPERTIES.maxRepeat}
                                    onChange={this.setHorizontalRepeat}
                                    value={this.state.horizontalRepeat}
                                    step={0.01}
                                />
                            </div>
                            <div className="custom-input-contain-gray side-drawer-slider-input">
                                <InputNumber disabled = {this.state.texture == null  ? true: false}
                                    className="side-drawer-input-fov"
                                    min={FileConstants.MATERIAL_PROPERTIES.minRepeat}
                                    max={FileConstants.MATERIAL_PROPERTIES.maxRepeat}
                                    value={this.state.horizontalRepeat}
                                    onChange={this.setHorizontalRepeat}
                                    formatter={value => `${value}"`}
                                    parser={value => value.replace('"', '')}
                                    step={0.01}
                                />
                            </div>
                        </Row>
                        <h5 className="side-drawer-text">Vertical Repeat</h5>
                        <Row className="align-in-center position-relative">
                            <div className="black-fov-slider side-drawer-slider">
                                <Slider disabled = {this.state.texture == null ? true: false}
                                    min={FileConstants.MATERIAL_PROPERTIES.minRepeat}
                                    max={FileConstants.MATERIAL_PROPERTIES.maxRepeat}
                                    onChange={this.setVerticalRepeat}
                                    value={this.state.verticalRepeat}
                                    step={0.01}
                                />
                            </div>
                            <div className="custom-input-contain-gray side-drawer-slider-input">
                                <InputNumber disabled = {this.state.texture == null ? true: false}
                                    className="side-drawer-input-fov"
                                    min={FileConstants.MATERIAL_PROPERTIES.minRepeat}
                                    max={FileConstants.MATERIAL_PROPERTIES.maxRepeat}
                                    value={this.state.verticalRepeat}
                                    onChange={this.setVerticalRepeat}
                                    formatter={value => `${value}"`}
                                    parser={value => value.replace('"', '')}
                                    step={0.01}
                                />
                            </div>
                        </Row>
                        <h5 className="side-drawer-text">Horizontal Offset</h5>
                        <Row className="align-in-center position-relative">
                            <div className="black-fov-slider side-drawer-slider">
                                <Slider disabled = {this.state.texture == null ? true: false}
                                    min={0}
                                    max={1}
                                    onChange={this.setHorizontalOffset}
                                    value={this.state.horizontalOffset}
                                    step={0.01}
                                />
                            </div>
                            <div className="custom-input-contain-gray side-drawer-slider-input">
                                <InputNumber type="Number" disabled = {this.state.texture == null ? true: false}
                                    className="side-drawer-input-fov"
                                    min={0}
                                    max={1}
                                    value={this.state.horizontalOffset}
                                    onChange={this.setHorizontalOffset}
                                    formatter={value => `${value}`}
                                    parser={value => value}
                                    step={0.01}
                                />
                            </div>
                        </Row>
                        <h5 className="side-drawer-text">Vertical Offset</h5>
                        <Row className="align-in-center position-relative">
                            <div className="black-fov-slider side-drawer-slider">
                                <Slider disabled = {this.state.texture == null ? true: false}
                                    min={0}
                                    max={1}
                                    onChange={this.setVerticalOffset}
                                    value={this.state.verticalOffset}
                                    step={0.01}
                                />
                            </div>
                            <div className="custom-input-contain-gray side-drawer-slider-input">
                                <InputNumber type="Number" disabled = {this.state.texture == null ? true: false}
                                    className="side-drawer-input-fov"
                                    min={0}
                                    max={1}
                                    value={this.state.verticalOffset}
                                    onChange={this.setVerticalOffset}
                                    formatter={value => `${value}`}
                                    parser={value => value}
                                    step={0.01}
                                />
                            </div>
                        </Row>
                        <h5 className="side-drawer-text">Rotate</h5>
                        <Row className="align-in-center position-relative">
                            <button className="side-drawer-menu-button" onClick={this.setRotation} disabled = {this.state.texture == null ? true: false}>
                                <img className="side-drawer-menu-icon small" src={require("../../../../assets/images/rotate-right.png")}></img>
                            </button>
                        </Row>
                        </div>
                        <Row>
                            <div className="align-in-center side-drawer-buttons-group position-relative"  >
                                <Button className="square font-14 side-drawer-button cancel-button" htmlType="submit" onClick={()=>{this.discardConfiguration();}}>
                                    Cancel
                                </Button>
                                <Button className="square font-14 side-drawer-button okay-button" htmlType="submit" onClick={()=>{this.updateConfiguration();}}>
                                    Apply
                                </Button>
                            </div>
                            <p className="side-drawer-note-text d-flex">
                                <img className="align-icon" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                                <div>
                                    Click <span className="bold">Apply</span> to update configured settings and <span className="bold">Cancel</span> to discard settings
                                </div>
                            </p>
                        </Row>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    }
}
export default SpaceConfigurator;