import React from 'react';
import { Table } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import { ThemeConsumer } from 'styled-components';

const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class SuperArtistsComplaintsTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandable,
    title: undefined,
    showHeader,
    footer: false,
    rowSelection: {},
    tableLayout: 'fixed',
    scroll: undefined,
    hasData: true,
    dataSource: null,
    reviewAction: 'hide',
  };

  getColumns = () => [
    {
      title: 'Complaint ID',
      dataIndex: 'complaint',
      key: 'complaint',
      sorter: (a, b) => a.complaint - b.complaint,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Entity ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      onFilter: (value, record) => record.name === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <span>
          {record.status == "sent_back_to_artist" ? 
            <span >Rejected</span>
            : record.status == "rejected" ? <span >flag rejected</span> : <span >{record.status}</span>  
        }
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.reviewAction,
      render: (text, record) => (
        <span>
          <a href={'/artist_complaint/' + record.complaint}>Review</a>
        </span>
      ),
    },
  ];

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    let payload = {
      'status': this.props.state,
      'assigned_to': localStorage.getItem('username')
    }
    if(this.props.state == 'resolved_and_flag_rejected'){
      this.fetchResolvedRejectFlaggingComplaints();
    }
    else{
      this.fetchComplaints(payload)
    }

  }

  fetchResolvedRejectFlaggingComplaints = () => {
    let payload = {
      'assigned_to': localStorage.getItem('username')
    }

    axios.post(ENVIRONMENT.GET_COMPLAINT_BATCH, payload)
    .then(res => {
      var data = [];
      for (let i = 1; i <= (res.data).length; i++) {
        if(res.data[i-1]['status'] == 'resolved' || res.data[i-1]['status'] == 'rejected'){
          data.push({
            key: i,
            id: res.data[i - 1]['entity_id'],
            name: res.data[i - 1]['username'],
            complaint: res.data[i - 1]['id'],
            type:  res.data[i - 1]['complaint_against'],
            status: res.data[i - 1]['status'],
            assigned_to: res.data[i - 1]['assigned_to'],
            assigned_by: res.data[i - 1]['assigned_by']
          });
        }
      }

      this.setState({
        reviewAction: "show",
        dataSource: data,
      });

    })
  }

  fetchComplaints = (payload) => {

    axios.post(ENVIRONMENT.GET_COMPLAINT_BATCH, payload)
      .then(res => {
        var data = [];
        for (let i = 1; i <= (res.data).length; i++) {
          data.push({
            key: i,
            id: res.data[i - 1]['entity_id'],
            name: res.data[i - 1]['username'],
            complaint: res.data[i - 1]['id'],
            type:  res.data[i - 1]['complaint_against'],
            status: res.data[i - 1]['status'],
            assigned_to: res.data[i - 1]['assigned_to'],
            assigned_by: res.data[i - 1]['assigned_by']
          });
        }

        this.setState({
          reviewAction: "show",
          dataSource: data,
        });

      })
  }


  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource ?
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} /> :
          <DottedLoader />
        }
      </div>
    );
  }
}

export default SuperArtistsComplaintsTable;
