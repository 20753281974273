import { Button } from "antd";
import React from "react";


class NotAuthorized extends React.Component {

    render(){
        const {model_type = "page"} = this.props;
        return (
            <React.Fragment>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', 
                            height: '100%'}}
                >
                    <div>
                        <img style={{margin: 'auto', display: 'flex'}} src={require("../../../../assets/images/not_authorized.svg")} ></img>
                        <span style={{fontSize: 16, fontWeight: 600, display: 'flex', justifyContent: 'center'}}>Access Denied</span>
                        <span style={{fontSize: 16, fontWeight: 400, display: 'flex', textAlign: 'center'}}>You do not have the
                        permission to access this {model_type}. <br />Please contact system
                        administrator</span>
                        <Button className="modal-okay-blue square font-14" onClick={() => window.history.back()} style={{display: 'flex', margin: 'auto', marginTop: 10}}>Go Back</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default NotAuthorized;