import React from "react";
import { Layout } from "antd";
import ArtistSummary from "../ArtistSummary/ArtistSummary";

const { Content } = Layout;

const ArtistEfficiency = () => {
  return (
    <>
      <Layout>
        <Content className="analytics-content">
            <ArtistSummary />
        </Content>
      </Layout>
    </>
  );
};

export default ArtistEfficiency;