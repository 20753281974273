import React from 'react';
import 'antd/dist/antd.css';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button } from 'antd';
import CustomerMainLayout from '../CustomerMainLayout'

class DesignRoomForm extends React.Component {

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                var room_data = window.blueprint3d.model.exportSerialized();
                alert(room_data);
                console.log(room_data);
                // var body = {};
                // body['name'] = values['room_name'];
                // body['room_data'] = JSON.parse(room_data);
                // body['id'] = this.props.match.params.id;
                // body['action'] = 'update';
                // var post_data = {}
                // post_data['body'] = body;
                // axios.post('https://gtf2kgk5z7.execute-api.us-west-2.amazonaws.com/develoment/createRoomRecord',
                //     { body })
                //     .then(res => {
                //         console.log(res);
                //         console.log(res.data);
                //     })
            
            }
        });
    };

    componentDidMount = () => {
        // if((this.props.match.params.id))
        
    }

    takeSnap = () => {
        // var imgData = window.blueprint3d.three.renderer.domElement.toDataURL();
        // var img_data = '<img style="height:120px;margin-bottom:10px" src="'+imgData+'"/></br>';
        // $( "#angles-images" ).append( img_data );
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <CustomerMainLayout selected='2'>
                
                    <Row type="flex" justify="space-around" gutter={88}>
                        {/* <Col span={6}>
                            <h1 style={{ 'font-size': '30px' }}><b>Attributes</b></h1>
                            <RoomAttributesInput form={this.props.form} />
                        </Col> */}
                        <Col span={8}>
                            <div id="angles-images">
                            </div>
                            <Form onSubmit={this.handleSubmit} onChange ={this.handleChange}>
                                <Button id="" type="primary" style= {{'margin-right': '10px'}}>Generate High Res Renders</Button>
                                <Button id="room-form-submit" type="primary" htmlType="submit" style= {{'margin-right': '10px'}}>Save</Button>
                            </Form>
                        </Col>
                        <Col span={16}>
                            <h1 style={{ 'font-size': '30px' }}><b>Floor Plan</b></h1>
                            <div className='room-designer' style={{'height': '500px','width': '800px'}}>
                                
                            </div>
                        </Col>
                    </Row>
                        
            </CustomerMainLayout>
        );
    }
}

const DesignRoom = Form.create({ name: 'room_add' })(DesignRoomForm);

export default DesignRoom;
