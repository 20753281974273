import { combineEpics } from 'redux-observable';
import { fetchDashboard } from './dashboard.js';
import { fetchProduct, createProduct, updateProduct, listProducts, listStoreProducts, renderProduct, getScannedProducts, rejectProduct } from './product.js';
import { fetchStoreProduct, createStoreProduct, updateStoreProduct, listStoreProduct, listForStoreProducts, renderStoreProduct, getScannedStoreProducts, rejectStoreProduct } from './store.js';
import { getArrangements, reorderData, renameFolder, createFolder, moveToFolder } from './entityArrangemnt.js';
import { createCollection, updateCollection, listCollections, getCollectionProducts, getCollectionRooms, collectionDeleteProduct } from './collection.js';
import { fetchRoom, createRoom, updateRoom, listRooms, renderRoom, getScannedRooms, rejectRoom } from './room.js';
import { listScenes } from './scene.js';
export const rootEpic = combineEpics(fetchDashboard, fetchProduct, createProduct, updateProduct,
    listProducts, listStoreProducts, renderProduct, getScannedProducts, listScenes,
    fetchRoom, createRoom, updateRoom, listRooms, renderRoom, getScannedRooms, rejectProduct,
    createCollection, updateCollection, listCollections, getCollectionProducts, getCollectionRooms, collectionDeleteProduct,
    fetchStoreProduct, createStoreProduct, updateStoreProduct, listStoreProduct, listForStoreProducts,
    renderStoreProduct, getScannedStoreProducts, rejectStoreProduct, rejectRoom,
    getArrangements, reorderData, renameFolder,createFolder, moveToFolder
);
