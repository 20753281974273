import React, { useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import { Col, Card, Dropdown, Tooltip, Button, Menu, Empty } from 'antd';
import '@ant-design/compatible/assets/index.css';
import '../../../../styles/helper.scss'
import { Link } from 'react-router-dom'
import ENVIRONMENT from '../../../../environments';
import { SPACE_THUMBNAIL_URI, THUMBNAIL_URI } from '../../../../environments/env';
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import * as Utilities from '../../Utilities'
import _ from "lodash";
import FileConstants from '../../../../FileConstants';
import LibraryGrid from '../LibraryGrid/LibraryGrid';

const IS_MSPROVIDER = FileConstants.isMSProvider;

let get_collaborate_options = (element, openDeleteCollaboration, openMarkCollaborationAsResolved, type) => {
    return <Menu>
        {type == "unresolved" && (element.type != "product") &&
            <Menu.Item>
                <a className="manrope f-14" onClick={() => openMarkCollaborationAsResolved(element)}>Mark as resolved</a>
            </Menu.Item>}
        <Menu.Item>
            <a className="manrope f-14" onClick={() => openDeleteCollaboration(element)}>Delete</a>
        </Menu.Item>
    </Menu>
};

const CollaborationCard = (props) => {
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(1);
    const [collaborationsList, setCollaborationsList] = useState([]);
    const previousCollabListLength = useRef(null);
    const maxColumnWidth = 280;
    const rowSize = 330;
    let rendered_collaborations = null;
    let collab_list = props.collaborations;
    if (props.searchValue != '') {
        collab_list = Utilities.getRelevanceBasedOrderedSearchResult(props.searchValue, props.collaborations)
        if (props.homepage) {
            props.setSearchedCollabCount(collab_list.length);
        }
    }
    rendered_collaborations = collab_list.map((element, index) => {
        return (
            <Collaboration
                homepage={props.homepage}
                key={index}
                type={props.type}
                element={element}
                openMarkCollaborationAsResolved={props.openMarkCollaborationAsResolved}
                openDeleteCollaboration={props.openDeleteCollaboration}
            />
        );
    });

    useEffect(() => {
        let collab_list = []
        if (props.homepage) {
            collab_list = [...props.collaborations];
            if (!props.expanded) {
                collab_list = [...props.collaborations.slice(0, 4)];
            } else if (props.searchValue != '') {
                props.setSearchedCollabCount(collab_list.length);
            }
        } else {
            if (props.searchValue == '') {
                collab_list = [...props.collaborations];
                if (collab_list.length !== collaborationsList.length) {
                    props.updateTabCount(-1, props.type)
                }
            } else if (props.searchValue != '') {
                collab_list = Utilities.getRelevanceBasedOrderedSearchResult(props.searchValue, props.collaborations);
            }

            const newCollabListLength = collab_list.length;

            // Call updateTabCount if new length is different or new length is 0 and previous length is not 0
            if (newCollabListLength !== previousCollabListLength.current || newCollabListLength === 0) {
                if (newCollabListLength === 0 && previousCollabListLength.current !== 0) {
                    props.updateTabCount(newCollabListLength, props.type);
                } else if (newCollabListLength !== previousCollabListLength.current) {
                    props.updateTabCount(newCollabListLength, props.type);
                }
            }

            // Update the ref after the check
            previousCollabListLength.current = newCollabListLength;
        }

        setCollaborationsList(collab_list);
        setRowCount(Math.ceil(collab_list.length / columnCount));
    }, [props.collaborations, props.searchValue, props.expanded, props.homepage]);

    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const itemIndex = (rowIndex * columnCount) + columnIndex;
        const ele = collaborationsList[itemIndex] && collaborationsList[itemIndex];
        
        if (!ele) {
            return null;
        }
    
        return (
          <div key={key} style={style}>
            <Collaboration
                    key={itemIndex}
                    type={props.type}
                    element={ele}
                    openMarkCollaborationAsResolved={props.openMarkCollaborationAsResolved}
                    openDeleteCollaboration={props.openDeleteCollaboration}
                />
        </div>)
    };

    rendered_collaborations = 
        collaborationsList.length > 0 ?
        <LibraryGrid
          setColumnCount = {setColumnCount}
          setRowCount = {setRowCount}
          maxColumnWidth = {maxColumnWidth}
          data = {collaborationsList}
          cellRenderer={cellRenderer}
          columnCount={columnCount}
          getColumnWidth={()=>{}}
          rowCount={rowCount}
          getEstimatedRowSize={rowSize}
          handleColumnWidthChange={()=>{}}
          /> :
        <div className="no-models-div" style={{ marginTop: "25%"}}>
            <Empty description={"No Collaborations To Show"} className="manrope f-14 no-models-span" />
        </div>

    if (props.homepage) {
        rendered_collaborations = collaborationsList.map((element, index) => {
        return (
            <Col
                className="draggable-element"
                key={props.key} lg={6} sm={24} md={8} offset={0}
                id={`element-${element.id}`}
                style={{
                    display: "inline-block"
                }}>
                <Collaboration
                    key={index}
                    type={props.type}
                    element={element}
                    openMarkCollaborationAsResolved={props.openMarkCollaborationAsResolved}
                    openDeleteCollaboration={props.openDeleteCollaboration}
                />
            </Col>
        );
        });
    }
    return (<>{rendered_collaborations}</>)
}


const Collaboration = (props) => {
    const { element } = props;
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    let thumbnail_link = "";
    if (element.type == "product") {
        thumbnail_link = ENVIRONMENT.getBaseURL(element.platform) + THUMBNAIL_URI + element.thumbnail;
    } else {
        if (element.thumbnail == "") {
            thumbnail_link = ENVIRONMENT.getBaseURL(element.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + element.scene_id + '/' + element.camera_name.replace('tiff', 'jpg');
        } else {
            thumbnail_link = ENVIRONMENT.getBaseURL(element.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + element.scene_id + '/' + element.thumbnail.replace('tiff', 'jpg');
        }
    }

    const getID = () => {
        if (element.type == "product" && !props.homepage) {
            return `element-${element.product_id}`;
        }
        return `element-${element.id}`;
    }

    const getLink = () => {
        let link = `/collaborate-tool/${element.id}`;
        if  (props.homepage && element.type == "product") {
            link = `/product-qa/${element.product_id}`;
        } else if (element.type == "product" ) {
            link = `/product-qa/${element.product_id}`;
        }
        return `${link + window.location.search}`;
    }

    const performAction = () => {
        window.location.href = getLink();
    }

    const getCustomerUsername = () => {
        if (element.designed_for) {
            return element.designed_for;
        } else {
            return element.customer_username;
        }
    }

    return (
        <Col
            className="draggable-element"
            key={props.key}
            id={getID()}
            >
            <Card
                bodyStyle={{ padding: "5px" }}
                className={`card-margins card-shadow-product`}
                bordered={false}
                data-tut="reactour__approved_scene"
                style={{
                    width: "94%",
                    border: "solid 1px #e3e3e3",
                }}>
                    {IS_MSPROVIDER ? <Tooltip title={<span className='manrope f-12 white'>{getCustomerUsername()}</span>}>
                        <span className={`group-settings-checkbox-reorder-items scenery-icon`}>
                            <span className='bg-white black'>
                            <span className='manrope f-14 white'>{getCustomerUsername()}</span>
                            </span>
                        </span>
                        </Tooltip>: ""}
                <Dropdown
                    className="group-settings more-icon"
                    overlay={get_collaborate_options(element, props.openDeleteCollaboration, props.openMarkCollaborationAsResolved, props.type)}
                    trigger={["click"]}
                >
                    <Button
                        shape="circle"
                        size={"large"}
                        className="more-option-library font-14 w-900"
                        icon={<MoreIcon />}
                    ></Button>
                </Dropdown>

                <div className="stacked-card-inner">
                    <Link to={getLink()} >
                        <div
                            className="suggested-product"
                            onMouseDown={(e) => {
                                // on left click call the performAction function instead of directly navigating to link
                                if ((e.button === 0 && !(e.ctrlKey || e.metaKey))) {
                                    performAction()
                                }
                            }}
                            onClick={(e) => {
                                if (e.button === 0 && !(e.ctrlKey || e.metaKey)) {
                                    e.preventDefault();
                                }
                            }}
                            style={{ zIndex: "owned", cursor: "pointer" }}>
                            <div className="box">
                                {thumbnail_link == "" ? (
                                    <img
                                        src={require("../../../../assets/images/scene_incomplete.svg")}
                                        style={{ objectFit: "cover", width: "100%", height: 269 }}
                                    />
                                ) : (
                                    <img
                                        src={thumbnail_link}
                                        style={{ objectFit: "cover", width: "100%", height: 269 }}
                                    />
                                )}

                            </div>
                            <div
                                className="justify-in-start"
                                style={{
                                    margin: "12px 12px",
                                }}>
                                <Tooltip
                                    title={
                                        <span className="manrope f-12 white">
                                            {element.name}
                                        </span>
                                    }>
                                    <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100 z-1">
                                        {element.name}
                                    </span>

                                </Tooltip>
                                <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(element.last_modified, 'tooltip')}</span>}>
                                    <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                                        <img src='/img/carbon_time.svg' style={{ marginRight: 2 }} />{Utilities.timestampToTimeSince(element.last_modified)}
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </Link>
                </div>
            </Card>
        </Col>)

};


export default CollaborationCard;