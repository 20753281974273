import AWS from 'aws-sdk';
import axios from 'axios';
import { BUCKET_NAME, CLOUDFRONT_ID, GOOGLE_UPLOAD_CONTROLLER, getUploadPlatform } from '../../../environments/env';
import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';
import ENVIRONMENT from '../../../environments';
var upload ={};
var room_upload={};
var product_upload={};
var material_id = -1;

const validateUploadedFile = (fileUrl) => {
  console.log('Validating file = ', fileUrl)
  return new Promise((resolve, reject) => {
    axios
      .head(fileUrl)
      .then((res) => {
        const contentLength = parseInt(res.headers['content-length']);
        if (res.status === 200 && contentLength > 5) {
          console.log('File is valid');
          resolve();
        } else {
          console.log('File is invalid');
          reject('Upload Failed. Please retry');
        }
      })
      .catch((err) => {
        console.log('Error while validating a file:', err);
        reject('Upload Failed. Please retry');
      });
  });
};
export const triggerFailedFilesUpload = (formItemId, filesList, setFilesList, event = undefined) => {
  
  if(event != undefined) {
    event.stopPropagation();
  }

  let files = [];
  if (filesList != undefined) {
      for(var file of filesList) {
          if (file['status'] != 'error') {
              files.push(file);
          }
      }
  }
  setFilesList(files)
  if (formItemId[0] == '.') {
    setTimeout(() => {
      $(formItemId).trigger('click');
    },1000)
      
  } else {
    $('#' + formItemId).trigger('click');
  } 
}

export const getUploadProps = (platform = null) => {
  
  if (platform == null)
    platform = getUploadPlatform() 
  if (platform == 'google'){
    return google_upload_props;
  } else {
    return upload_props;
  }
  
}

export const getProductModelUploadProp = (platform = 'aws') => {
  if (platform == 'google') {
    return google_upload_props_product_models;
  } else {
    return upload_props_product_models;
  }
}

export const getRoomModelUploadProp = (platform = 'aws') => {
  if (platform == 'google') {
    return google_upload_props_rooms_models;
  } else {
    return upload_props_rooms_models;
  }
}

export const getMaterialQARenderUploadProp = (platform = 'aws') => {
  if (platform == 'google') {
    return google_upload_material_qa_renders;
  } else {
    return upload_material_qa_renders;
  }
}

export const getArtistTestRendersProps = (platform = 'aws') => {
  if (platform == 'google') {
    return google_upload_artist_test_renders;
  } else {
    return upload_artist_test_renders;
  }
}

export const getMaterialUploadProps = (platform = 'aws') => {
  if (platform == 'google') {
    return google_upload_props_material;
  } else {
    return upload_props_material;
  }
}

export const google_upload_props = {
  customRequest ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
      console.log('here');
      console.log(file);
      console.log(file.name)
      axios.post(GOOGLE_UPLOAD_CONTROLLER, {
          'action': 'get_asigned_urls',
          'file_uri': "test_files" + "/" + file.uid + "/" + file.name
      })
        .then(res => {
            let signedUrl = res.data;
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', signedUrl, true); 
      
            xhr.onload = function() {
                console.log(xhr)
                if (xhr.status === 200) {
                  console.log(xhr);
                  console.log("SEND FINISHED", xhr.response,file);
                  axios.post(GOOGLE_UPLOAD_CONTROLLER, {
                    'action': 'make_file_public',
                    'file_uri': "test_files" + "/" + file.uid + "/" + file.name
                  }).then(res => {
                    const fileUrl = ENVIRONMENT.getBaseURL('google') + encodeURIComponent("test_files" + "/" + file.uid + "/" + file.name)
                    validateUploadedFile(fileUrl)
                    .then(() => {
                      onSuccess(xhr.response, file);
                      console.log('Upload completed', data.response, file);
                    })
                    .catch(() => {
                      let error = {
                        code: '',
                        message: ''
                      }
                      onError(error);
                      console.log('Upload failed:', error);
                    });
                })
                } else {
                  onError('File Upload Failed');
                }
            };
      
            xhr.onerror = function() {
                console.error('An error occurred during file upload.');
            };
      
            xhr.upload.onprogress = function(event) {
              if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                onProgress(
                  {
                    percent: percentComplete
                  },
                  file
                );
              }
            }
      
            xhr.send(file);
        });

    }
  };

export const upload_props = {
  customRequest ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
    var jwt_token = localStorage.getItem('all3d_jwt_token');
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
        Logins: { 
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
        }
    });
    cognito_credentials.refresh(() => {
      AWS.config.update({
        region: 'us-west-2',
        credentials: cognito_credentials
      });
      const S3 = new AWS.S3({
        httpOptions: {
          timeout: 240000
        }
      });
      
      const objParams = {
        Bucket: BUCKET_NAME,
        Key: "test_files" + "/" + file.uid + "/" + file.name,
        ACL: 'public-read',
        Body: file,
        ContentType: file.type,
        CacheControl: 'no-cache'
      };
      upload = S3.upload(objParams)
        .on("httpUploadProgress", function({ loaded, total }) {
          onProgress(
            {
              percent: Math.round((loaded / total) * 100)
            },
            file
          );
        });
        upload.send(function(err, data) {
          if (err) {
            onError(err);
            console.log(err.code);
            console.log(err.message);
          } else {
            const fileUrl = ENVIRONMENT.getBaseURL() + encodeURIComponent("test_files" + "/" + file.uid + "/" + file.name)
            validateUploadedFile(fileUrl)
            .then(() => {
              onSuccess(data.response, file);
              console.log('Upload completed', data.response, file);
            })
            .catch(() => {
              let error = {
                code: '',
                message: ''
              }
              onError(error);
              console.log('Upload failed:', error);
            });
          }
        });
      });
    }
  };

  export const abortUpload = async ()  => {
    if (upload != undefined) {
      setTimeout(upload.abort.bind(upload),1000);
    }
    
  }

  export const google_upload_props_product_models = {
    customRequest ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) {
        console.log('here');
        console.log(file);
        console.log(file.name)
        axios.post(GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': "product_assets/low_poly_max" + "/" + file.name,
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response,file);
                    axios.post(GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': "product_assets/low_poly_max" + "/" + file.name,
                    }).then(res => {
                      const fileUrl = ENVIRONMENT.getBaseURL('google') + encodeURIComponent("product_assets/low_poly_max/" + file.name)
                      validateUploadedFile(fileUrl)
                      .then(() => {
                        onSuccess(xhr.response, file);
                        console.log('Upload completed', data.response, file);
                      })
                      .catch(() => {
                        let error = {
                          code: '',
                          message: ''
                        }
                        onError(error);
                        console.log('Upload failed:', error);
                      });
                    })
                  } else {
                    onError('File Upload Failed');
                  }
              };
        
              xhr.onerror = function() {
                  console.error('An error occurred during file upload.');
              };
        
              xhr.upload.onprogress = function(event) {
                if (event.lengthComputable) {
                  const percentComplete = (event.loaded / event.total) * 100;
                  onProgress(
                    {
                      percent: percentComplete
                    },
                    file
                  );
                }
              }
        
              xhr.send(file);
          });
  
      }
    };

  export const upload_props_product_models = {
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
    var jwt_token = localStorage.getItem('all3d_jwt_token');
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
        Logins: { 
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
        }
    });

    cognito_credentials.refresh(
      () => {
        AWS.config.update({
          region: 'us-west-2',
          credentials: cognito_credentials
        });
        
        const S3 = new AWS.S3({
          httpOptions: {
            timeout: 240000
          }
        });
    
        const objParams = {
            Bucket: BUCKET_NAME,
            Key: "product_assets/low_poly_max" + "/" + file.name,
            ACL: 'public-read',
          Body: file,
          ContentType: file.type,
          CacheControl: 'no-cache'
        };
    
        product_upload = S3.upload(objParams,{leavePartsOnError:true})
          .on("httpUploadProgress", function({ loaded, total }) {
            onProgress(
              {
                percent: Math.round((loaded / total) * 100)
              },
              file
            );
          });
          product_upload.send(function(err, data) {
            if (err) {
              onError(err);
              console.log("Something went wrong");
              console.log(err.code);
              console.log(err.message);
            } else {
              const fileUrl = ENVIRONMENT.getBaseURL() + encodeURIComponent("product_assets/low_poly_max/" + file.name)
              validateUploadedFile(fileUrl)
              .then(() => {
                onSuccess(data.response, file);
                console.log('Upload completed', data.response, file);

                // Invalidate asset
                var today = new Date();
                console.log('SENT!',today.getHours(),":",today.getMinutes(),":", today.getSeconds());

                var cloudfront = new AWS.CloudFront();
                var params = {
                  DistributionId: CLOUDFRONT_ID, 
                  InvalidationBatch: { 
                    CallerReference: uuidv4(),
                    Paths: {
                      Quantity: 1, 
                      Items: [
                        "/product_assets/low_poly_max" + "/" + file.name,
                      ]
                    }
                  }
                };
                cloudfront.createInvalidation(params, function(err, data) {
                  if (err) console.log(err, err.stack); // an error occurred
                  else     console.log(data);           // successful response
                });
              })
              .catch(() => {
                let error = {
                  code: '',
                  message: ''
                }
                onError(error);
                console.log('Upload failed:', error);
              });
            }
          });
      }
    );
      
    }
  };

  export const abortProductUpload = async ()  => {
    if (product_upload != undefined) {
      setTimeout(product_upload.abort.bind(product_upload),1000);
    }
    
  }

  export const google_upload_props_rooms_models = {
    customRequest ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) {
        console.log('here');
        console.log(file);
        console.log(file.name)
        axios.post(GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': "room_assets/low_poly_max" + "/" + file.name,
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response,file);
                    axios.post(GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': "room_assets/low_poly_max" + "/" + file.name,
                    }).then(res => {
                      const fileUrl = ENVIRONMENT.getBaseURL('google') + encodeURIComponent("room_assets/low_poly_max" + "/" + file.name)
                      validateUploadedFile(fileUrl)
                      .then(() => {
                        onSuccess(xhr.response, file);
                        console.log('Upload completed', data.response, file);
                      })
                      .catch(() => {
                        let error = {
                          code: '',
                          message: ''
                        }
                        onError(error);
                        console.log('Upload failed:', error);
                      });
                    })
                  } else {
                    onError('File Upload Failed');
                  }
              };
        
              xhr.onerror = function() {
                  console.error('An error occurred during file upload.');
              };
        
              xhr.upload.onprogress = function(event) {
                if (event.lengthComputable) {
                  const percentComplete = (event.loaded / event.total) * 100;
                  onProgress(
                    {
                      percent: percentComplete
                    },
                    file
                  );
                }
              }
        
              xhr.send(file);
          });
  
      }
    };

  export const upload_props_rooms_models = {
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
    var jwt_token = localStorage.getItem('all3d_jwt_token');
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
        Logins: { 
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
        }
    });
    cognito_credentials.refresh(
      () => {
        AWS.config.update({
          region: 'us-west-2',
          credentials: cognito_credentials
      });
  
      const S3 = new AWS.S3({
        httpOptions: {
          timeout: 240000
        }
        });
    
        const objParams = {
            Bucket: BUCKET_NAME,
            Key: "room_assets/low_poly_max" + "/" + file.name,
            ACL: 'public-read',
          Body: file,
          ContentType: file.type,
          CacheControl: 'no-cache'
        };

        console.log("Room bucket",BUCKET_NAME);
    
        room_upload = S3.upload(objParams,{leavePartsOnError:true})
          .on("httpUploadProgress", function({ loaded, total }) {
            onProgress(
              {
                percent: Math.round((loaded / total) * 100)
              },
              file
            );
          });
          room_upload.send(function(err, data) {
            if (err) {
              onError(err);
              console.log("Something went wrong");
              console.log(err.code);
              console.log(err.message);
            } else {
              const fileUrl = ENVIRONMENT.getBaseURL() + encodeURIComponent("room_assets/low_poly_max" + "/" + file.name)
              validateUploadedFile(fileUrl)
              .then(() => {
                onSuccess(data.response, file);
                console.log('Upload completed', data.response, file);

                var cloudfront = new AWS.CloudFront();
                var params = {
                  DistributionId: CLOUDFRONT_ID, 
                  InvalidationBatch: { 
                    CallerReference: uuidv4(),
                    Paths: {
                      Quantity: 1, 
                      Items: [
                        "/room_assets/low_poly_max" + "/" + file.name,
                      ]
                    }
                  }
                };
                cloudfront.createInvalidation(params, function(err, data) {
                  if (err) console.log(err, err.stack); // an error occurred
                  else     console.log(data);           // successful response
                });
              })
              .catch(() => {
                let error = {
                  code: '',
                  message: ''
                }
                onError(error);
                console.log('Upload failed:', error);
              });
            }
          });
      }
    );
    }
  };

  export const google_upload_material_qa_renders = {
    customRequest ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) {
        console.log('here');
        console.log(file);
        console.log(file.name)
        axios.post(GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': "materials/" + material_id + "/" + file.name,
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response,file);
                    axios.post(GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': "materials/" + material_id + "/" + file.name,
                    }).then(res => {
                      const fileUrl = ENVIRONMENT.getBaseURL('google') + ("materials/" + material_id + "/" + file.name)
                      validateUploadedFile(fileUrl)
                      .then(() => {
                        onSuccess(xhr.response, file);
                        console.log('Upload completed', data.response, file);
                      })
                      .catch(() => {
                        let error = {
                          code: '',
                          message: ''
                        }
                        onError(error);
                        console.log('Upload failed:', error);
                      });
                    })
                  } else {
                    onError('File Upload Failed');
                  }
              };
        
              xhr.onerror = function() {
                  console.error('An error occurred during file upload.');
              };
        
              xhr.upload.onprogress = function(event) {
                if (event.lengthComputable) {
                  const percentComplete = (event.loaded / event.total) * 100;
                  onProgress(
                    {
                      percent: percentComplete
                    },
                    file
                  );
                }
              }
        
              xhr.send(file);
          });
  
      }
    };

  export const upload_material_qa_renders = {
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
    var jwt_token = localStorage.getItem('all3d_jwt_token');
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
        Logins: { 
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
        }
    });
    cognito_credentials.refresh(
      () => {
        AWS.config.update({
          region: 'us-west-2',
          credentials: cognito_credentials
      });
  
      const S3 = new AWS.S3({
        httpOptions: {
          timeout: 240000
        }
        });
    
        const objParams = {
            Bucket: BUCKET_NAME,
            Key: "materials/" + material_id + "/" + file.name,
            ACL: 'public-read',
            Body: file,
            ContentType: file.type,
            ContentDisposition: 'attachment',
            CacheControl: 'no-cache'
        };
    
        console.log(objParams);
        S3.upload(objParams,{leavePartsOnError:true})
          .on("httpUploadProgress", function({ loaded, total }) {
            onProgress(
              {
                percent: Math.round((loaded / total) * 100)
              },
              file
            );
          }).send(function(err, data) {
            if (err) {
              onError(err);
              console.log("Something went wrong");
              console.log(err.code);
              console.log(err.message);
            } else {
              const fileUrl = ENVIRONMENT.getBaseURL() + encodeURIComponent("materials/" + material_id + "/" + file.name)
              validateUploadedFile(fileUrl)
              .then(() => {
                onSuccess(data.response, file);
                console.log('Upload completed', data.response, file);
              })
              .catch(() => {
                let error = {
                  code: '',
                  message: ''
                }
                onError(error);
                console.log('Upload failed:', error);
              });

              var cloudfront = new AWS.CloudFront();
              var params = {
                DistributionId: CLOUDFRONT_ID, 
                InvalidationBatch: { 
                  CallerReference: uuidv4(),
                  Paths: {
                    Quantity: 1, 
                    Items: [
                      "/materials/" + material_id + "/" + file.name,
                    ]
                  }
                }
              };
              cloudfront.createInvalidation(params, function(err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else     console.log(data);           // successful response
              });
            }
          });
      }
    );
    }
  };

  export const google_upload_artist_test_renders = {
    customRequest ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) {
        console.log('here');
        console.log(file);
        console.log(file.name)
        axios.post(GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': "test_files/space_test_renders/" + file.uid + "/" + file.name,
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response,file);
                    axios.post(GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': "test_files/space_test_renders/" + file.uid + "/" + file.name,
                    }).then(res => {
                      const fileUrl = ENVIRONMENT.getBaseURL('google') + encodeURIComponent("test_files/space_test_renders/" + file.uid + "/" + file.name)
                      validateUploadedFile(fileUrl)
                      .then(() => {
                        onSuccess(xhr.response, file);
                        console.log('Upload completed', data.response, file);
                      })
                      .catch(() => {
                        let error = {
                          code: '',
                          message: ''
                        }
                        onError(error);
                        console.log('Upload failed:', error);
                      });
                    })
                  } else {
                    onError('File Upload Failed');
                  }
              };
        
              xhr.onerror = function() {
                  console.error('An error occurred during file upload.');
              };
        
              xhr.upload.onprogress = function(event) {
                if (event.lengthComputable) {
                  const percentComplete = (event.loaded / event.total) * 100;
                  onProgress(
                    {
                      percent: percentComplete
                    },
                    file
                  );
                }
              }
        
              xhr.send(file);
          });
  
      }
    };

  export const upload_artist_test_renders = {
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
    var jwt_token = localStorage.getItem('all3d_jwt_token');
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
        Logins: { 
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
        }
    });
    cognito_credentials.refresh(
      () => {
        AWS.config.update({
          region: 'us-west-2',
          credentials: cognito_credentials
      });
  
      const S3 = new AWS.S3({
        httpOptions: {
          timeout: 240000
        }
        });
    
        const objParams = {
            Bucket: BUCKET_NAME,
            Key: "test_files/space_test_renders/" + file.uid + "/" + file.name,
            ACL: 'public-read',
          Body: file,
          ContentType: file.type,
          CacheControl: 'no-cache'
        };
    
        console.log(objParams);
        S3.upload(objParams,{leavePartsOnError:true})
          .on("httpUploadProgress", function({ loaded, total }) {
            onProgress(
              {
                percent: Math.round((loaded / total) * 100)
              },
              file
            );
          }).send(function(err, data) {
            if (err) {
              onError(err);
              console.log("Something went wrong");
              console.log(err.code);
              console.log(err.message);
            } else {
              const fileUrl = ENVIRONMENT.getBaseURL() + encodeURIComponent("test_files/space_test_renders/" + file.uid + "/" + file.name)
              validateUploadedFile(fileUrl)
              .then(() => {
                onSuccess(data.response, file);
                console.log('Upload completed', data.response, file);
              })
              .catch(() => {
                let error = {
                  code: '',
                  message: ''
                }
                onError(error);
                console.log('Upload failed:', error);
              });
              var cloudfront = new AWS.CloudFront();
              var params = {
                DistributionId: CLOUDFRONT_ID, 
                InvalidationBatch: { 
                  CallerReference: uuidv4(),
                  Paths: {
                    Quantity: 1, 
                    Items: [
                      "/test_files" + "/" + file.uid + "/" + file.name,
                    ]
                  }
                }
              };
              cloudfront.createInvalidation(params, function(err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else     console.log(data);           // successful response
              });
            }
          });
      }
    );
    }
  };

  export const abortRoomUpload = async ()  => {
    if (room_upload != undefined) {
      setTimeout(room_upload.abort.bind(room_upload),1000);
    }
    
  }
  export const updateMaterialID = (id) => {
    material_id = id;
  }

  export const google_upload_props_material = {
    customRequest ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) {
        console.log('here');
        console.log(file);
        console.log(file.name)
        axios.post(GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': "materials/" + material_id + "/" + file.name,
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response,file);
                    axios.post(GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': "materials/" + material_id + "/" + file.name,
                    }).then(res => {
                      const fileUrl = ENVIRONMENT.getBaseURL('google') + encodeURIComponent("materials/" + material_id + "/" + file.name)
                      validateUploadedFile(fileUrl)
                      .then(() => {
                        onSuccess(xhr.response, file);
                        console.log('Upload completed', data.response, file);
                      })
                      .catch(() => {
                        let error = {
                          code: '',
                          message: ''
                        }
                        onError(error);
                        console.log('Upload failed:', error);
                      });
                    })
                  } else {
                    onError('File Upload Failed');
                  }
              };
        
              xhr.onerror = function() {
                  console.error('An error occurred during file upload.');
              };
        
              xhr.upload.onprogress = function(event) {
                if (event.lengthComputable) {
                  const percentComplete = (event.loaded / event.total) * 100;
                  onProgress(
                    {
                      percent: percentComplete
                    },
                    file
                  );
                }
              }
        
              xhr.send(file);
          });
  
      }
    };

  export const upload_props_material = {
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials
    }) {
    var jwt_token = localStorage.getItem('all3d_jwt_token');
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
        Logins: { 
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
        }
    });
    cognito_credentials.refresh(
      () => {
        AWS.config.update({
          region: 'us-west-2',
          credentials: cognito_credentials
      });
  
      const S3 = new AWS.S3({
        httpOptions: {
          timeout: 240000
        }
        });
    
        const objParams = {
          Bucket: BUCKET_NAME,
          Key: "materials/" + material_id + "/" + file.name,
          ACL: 'public-read',
          Body: file,
          ContentType: file.type,
          CacheControl: 'no-cache'
        };
    
        console.log(objParams);
        S3.upload(objParams,{leavePartsOnError:true})
          .on("httpUploadProgress", function({ loaded, total }) {
            onProgress(
              {
                percent: Math.round((loaded / total) * 100)
              },
              file
            );
          }).send(function(err, data) {
            if (err) {
              onError();
              console.log("Something went wrong");
              console.log(err.code);
              console.log(err.message);
            } else {
              const fileUrl = ENVIRONMENT.getBaseURL() + encodeURIComponent("materials/" + material_id + "/" + file.name)
              validateUploadedFile(fileUrl)
              .then(() => {
                onSuccess(data.response, file);
                console.log('Upload completed', data.response, file);
              })
              .catch(() => {
                let error = {
                  code: '',
                  message: ''
                }
                onError(error);
                console.log('Upload failed:', error);
              });

              var cloudfront = new AWS.CloudFront();
              var params = {
                DistributionId: CLOUDFRONT_ID, 
                InvalidationBatch: { 
                  CallerReference: uuidv4(),
                  Paths: {
                    Quantity: 1, 
                    Items: [
                      "/materials/" + material_id + "/" + file.name,
                    ]
                  }
                }
              };
              cloudfront.createInvalidation(params, function(err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else     console.log(data);           // successful response
              });
            }
          });
      }
    );
    }
  };


export const upload_artist_test_props = {
  customRequest({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) {
    var jwt_token = localStorage.getItem("all3d_jwt_token");
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-west-2:5f6370ec-44f1-4603-918e-afcdee671162",
      Logins: {
        "cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j": jwt_token,
      },
    });
    cognito_credentials.refresh(() => {
      AWS.config.update({
        region: "us-west-2",
        credentials: cognito_credentials,
      });
      const S3 = new AWS.S3({
        httpOptions: {
          timeout: 240000,
        },
      });

      const objParams = {
        Bucket: BUCKET_NAME,
        Key: "artist_tests" + "/test_files/" + file.name,
        ACL: "public-read",
        Body: file,
        ContentType: file.type,
        CacheControl: 'no-cache'
      };
      
      upload = S3.upload(objParams, { leavePartsOnError: true }).on(
        "httpUploadProgress",
        function ({ loaded, total }) {
          onProgress(
            {
              percent: Math.round((loaded / total) * 100),
            },
            file
          );
        }
      );
      upload.send(function (err, data) {
        if (err) {
          onError(err);
          console.log(err.code);
          console.log(err.message);
        } else {
          const fileUrl = ENVIRONMENT.getBaseURL() + encodeURIComponent("artist_tests/test_files/" + file.name)
          validateUploadedFile(fileUrl)
          .then(() => {
            onSuccess(data.response, file);
            console.log('Upload completed', data.response, file);
          })
          .catch(() => {
            let error = {
              code: '',
              message: ''
            }
            onError(error);
            console.log('Upload failed:', error);
          });
        }
      });
    });
  },
};



export const upload_bulk_request_csv = {
  customRequest({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) {
    var jwt_token = localStorage.getItem("all3d_jwt_token");
    var cognito_credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-west-2:5f6370ec-44f1-4603-918e-afcdee671162",
      Logins: {
        "cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j": jwt_token,
      },
    });
    cognito_credentials.refresh(() => {
      AWS.config.update({
        region: "us-west-2",
        credentials: cognito_credentials,
      });
      const S3 = new AWS.S3({
        httpOptions: {
          timeout: 240000,
        },
      });

      const objParams = {
        Bucket: BUCKET_NAME,
        Key: "test_files/" + file.uid + '/' + file.name,
        ACL: "public-read",
        Body: file,
        ContentType: file.type,
        CacheControl: 'no-cache'
      };
      
      upload = S3.upload(objParams, { leavePartsOnError: true }).on(
        "httpUploadProgress",
        function ({ loaded, total }) {
          onProgress(
            {
              percent: Math.round((loaded / total) * 100),
            },
            file
          );
        }
      );
      upload.send(function (err, data) {
        if (err) {
          onError(err);
          console.log(err.code);
          console.log(err.message);
        } else {
          const fileUrl = ENVIRONMENT.getBaseURL() + "test_files/" + file.uid + '/' + file.name
          validateUploadedFile(fileUrl)
          .then(() => {
            onSuccess(data.response, file);
            console.log('Upload completed', data.response, file);
          })
          .catch(() => {
            let error = {
              code: '',
              message: ''
            }
            onError(error);
            console.log('Upload failed:', error);
          });
        }
      });
    });
  },
};