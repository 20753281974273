import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import FileConstants from '../../../../FileConstants';
import '@ant-design/compatible/assets/index.css';
import _ from 'lodash'; // Import the entire lodash library
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Button, Row, Col, Form, Input, Tooltip, Radio } from 'antd';
// import RoomsListVirtualized from './RoomsListVirtualized';
import DottedLoader from '../../DottedLoader';
import { CheckCircleFilled, FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import * as Utilities from '../../Utilities';
import ProductFiltersDrawer from '../ProductFiltersDrawer/ProductFilterDrawer';
import ProductListVirtualized from './ProductListVirtualized';
import AddProductContext from '../../ContextFiles/AddProductContext';
import ProductGridWrapper from '../ProductGridWrapper/ProductGridWrapper';
const { TabPane } = Tabs;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_SHARED_ENTITES = localStorage.getItem("managed_shared_entites");
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const SelectProduct = (props) => {
    const COMPANY_ID = FileConstants.COMPANY_ID;
    const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
    const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
    const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
    const { productsAddedToScene, setProductsAddedToScene,setStep, setLoading, setIsStore, loading, isStore, selectedRoom, requestStatusRoom, checkEnabled, page, handleNext, handleCancel, heading, preselectedProducts } = props;

    const [ownedProductData, setOwnedProductData] = useState([]);
    const [sharedProductData, setSharedProductData] = useState([]);
    const [storeProductData, setStoreProductData] = useState([]);
    const [mspUserData, setMspUserData] = useState([]);
    const [mspSharedData, setMspSharedData] = useState([]);
    const [tabActiveKey, setTabActiveKey] = useState('owned');
    const [categories, setCategories] = useState([]);
    const [isMSProvider, setIsMSProvider] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [mspFilteredData, setMspFilteredData] = useState([]);
    const [mspSharedFilteredData, setMspSharedFilteredData] = useState([]);
    const [mspSharedTabType, setMspSharedTabType] = useState('all');
    const [ownedFilteredData, setOwnedFilteredData] = useState([]);
    const [sharedFilteredData, setSharedFilteredData] = useState([]);
    const [storeFilteredData, setStoreFilteredData] = useState([]);
    const [productCategoriesData, setProductCategoriesData] = useState([])
    const [productParentCateogries, setProductParentCateogries] = useState({})
    const [productMaterialsData, setProductMaterialsData] = useState([]);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [appliedFiltersList, setAppliedFiltersList] = useState([])
    const [filterTagFunction, setFilterTagFunction] = useState(null);
    const [returnSearchValFunction, setReturnSearchValFunction] = useState(null);
    const [productsForLibrary, setProductsForLibrary] = useState([]);
    const [maxPriceProduct, setMaxPriceProduct] = useState(0);
    const [filtersDrawer, setFiltersDrawer] = useState(false);
    const [productCategoryValue, setProductCategoryValue] = useState([]);
    const [productCategoryValueWithChilds, setProductCategoryValueWithChilds] = useState([]);
    const [, setCategorySearchValue] = useState([]);
    const [productStyleCategory, setProductStyleCategory] = useState([]);
    const [colorValue, setColorValue] = useState([]);
    const [productMaterials, setProductMaterials] = useState([]);
    const [materialsCategoryValueWithChilds, setMaterialsCategoryValueWithChilds] = useState([]);
    const [productModelType, setProductModelType] = useState([]);
    const [maxPrice, setMaxPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [spaceAreas, setSpaceAreas] = useState([]);
    const [storeProductsLoader, setStoreProductsLoader] = useState(true);
    const [initialLoadProducts, setInitialLoadProducts] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [initialLoadShared, setInitialLoadShared] = useState(true);
    const [initialLoadOwned, setInitialLoadOwned] = useState(true);
    const [sharedProductLoader, setSharedProductLoader] = useState(true);
    const [ownedProductLoader, setOwnedProductLoader] = useState(true);  
    const [sharedTabType, setSharedTabType] = useState('all');
    const [tabValueSet, setTabValueSet] = useState(false);
    const [tabCount, setTabCount] = useState({owned: -1, other: -1, store: -1, msp: -1, other_msp: -1, project_products: -1});
    const [projectProducts, setProjectProducts] = useState([]);
    const [projectDataLoader, setProjectDataLoader] = useState(false);
    const [projectFilteredData, setProjectFilteredData] = useState([]);
    const [tabChangeThroughClick, setTabChangeThroughClick] = useState(false);
    const [currentTabType, setCurrentTabType] = useState("5");
    const [currentMSPTabType, setCurrentMSPTabType] = useState("5");
    const [currentProjectProductTabType, setCurrentProjectProductTabType] = useState("5");

    const getFilteredLibraryData = (currentData, modelStatus) => {
        if (modelStatus == -1)
            return currentData;
        let newData = []
        currentData.filter ((item) => {
            if (item.model_status == modelStatus) {
                newData.push(item)
            }
        })
        return newData
    }

    const getProducts = (type) => {
        if (type == "msp") {
           return getFilteredLibraryData(mspUserData, parseInt(currentMSPTabType));
        } else if (type == 'other_msp') {
            if (mspSharedTabType == 'all') {
                return mspSharedData;
            }
            else if (mspSharedTabType == 'shared') {
                const products = mspSharedData.filter((product) => (product.shared_product_type == 'shared_by_customer'))
                return products;
            }
            else if (mspSharedTabType == 'props') {
                const products = mspSharedData.filter((product) => (product.product_model_type == 'props' && product.is_store_item != 1));
                return products;
            } else {
                const products = mspSharedData.filter((product) => (product.shared_product_type == 'added_from_store'))
                return products;
            }
        } else if (type == "project_products") {
            return getFilteredLibraryData(projectProducts, parseInt(currentProjectProductTabType));;
        } else if (type == "owned") {
            return getFilteredLibraryData(ownedProductData, parseInt(currentTabType));;
        } else if (type == "store") {
            return storeProductData;
        } else {
            if (sharedTabType == 'all') {
                return sharedProductData;
            }
            else if (sharedTabType == 'shared') {
                const products = sharedProductData.filter((product) => (product.shared_product_type == 'shared_by_customer'))
                return products;
            }
            else if (sharedTabType == 'props') {
                const products = sharedProductData.filter((product) => (product.product_model_type == 'props' && product.is_store_item != 1));
                return products;
            }
            else {
                const products = sharedProductData.filter((product) => (product.shared_product_type == 'added_from_store'))
                return products;
            }
        }
    }

    const getProductsLength = (type, tab_options = 'not_all') => {
        if (type == "msp") {
            return getFilteredLibraryData(mspUserData, parseInt(currentMSPTabType)).length;
        } else if (type == 'other_msp') {
            let products_temp = mspSharedData;
            if (mspSharedTabType == 'all' || tab_options == 'all') {
                return products_temp.length;
            }
            else if (mspSharedTabType == 'shared') {
                const products = products_temp.filter((product) => (product.shared_product_type == 'shared_by_customer'))
                return products.length;
            }
            else if (mspSharedTabType == 'props') {
                const products = products_temp.filter((product) => (product.product_model_type == 'props' && product.is_store_item != 1));
                return products.length;
            }
            else {
                const products = products_temp.filter((product) => (product.shared_product_type == 'added_from_store'))
                return products.length;
            }
        } else if (type == "project_products") {
            return getFilteredLibraryData(projectProducts, parseInt(currentProjectProductTabType)).length;
        } else if (type == "owned") {
            return getFilteredLibraryData(ownedProductData, parseInt(currentTabType)).length;
        } else if (type == "store") {
            return storeProductData.length;
        } else {
            let products_temp = sharedProductData;
            if (sharedTabType == 'all' || tab_options == 'all') {
                return products_temp.length;
            }
            else if (sharedTabType == 'shared') {
                const products = products_temp.filter((product) => (product.shared_product_type == 'shared_by_customer'))
                return products.length;
            }
            else if (sharedTabType == 'props') {
                const products = products_temp.filter((product) => (product.product_model_type == 'props' && product.is_store_item != 1));
                return products.length;
            }
            else {
                const products = products_temp.filter((product) => (product.shared_product_type == 'added_from_store'))
                return products.length;
            }
        }
    }

    useEffect(() => {
        // this means that a tab has already been selected if it had data in it
        // if data is loaded in a tab later on then it is not selected
        // if (tabValueSet == false) {
            if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME || MANAGED_COMPANY_ID) && mspUserData?.length > 0) {
                setTabActiveKey("msp");
                setTabValueSet(true);
            } else if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME || MANAGED_COMPANY_ID) && mspSharedData?.length > 0) {
                setTabActiveKey("other_msp");
                setTabValueSet(true);
            } else if (projectProducts?.length > 0) {
                setTabActiveKey("project_products");
                setTabValueSet(true);
            } else if (ownedProductData?.length > 0) {
                setTabActiveKey("owned");
                setTabValueSet(true);
            } else if (sharedProductData?.length > 0) {
                setTabActiveKey("other");
                setTabValueSet(true);
            } else if (storeProductData?.length > 0) {
                setTabActiveKey("store");
                setTabValueSet(true);
            }
        // }
    }, [ownedProductData, sharedProductData, storeProductData, mspUserData, mspSharedData, projectProducts, ownedFilteredData, tabValueSet]);

    const getProjectProductsPayload = (project_id) => {
        let payload = {
            order_by: "last_modified_stamp desc",
            required_fields: ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "color_name", "materials",
                "platform", "style_category", "gtin", "tags", "product_model_type", "placement_type",
                "material_type"],
            filter_string: `(project_id__exact='${project_id}'&&model_status__in=[5, 4])`,
        };
        return payload;
    };

    const getProjectProductsList = async (project_id) => {
        setProjectDataLoader(true);
        let payload = getProjectProductsPayload(project_id);
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((response) => {
                if (response.data) {
                    let products_with_keywords = Utilities.getProductsDataWithKeywords(response.data);;
                    console.log(products_with_keywords)
                    setProjectProducts(products_with_keywords);
                    setProjectDataLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    
    const getProductPayload = (initial, type, sequence_id, current_customer = CUSTOMER_USERNAME, current_company_id = COMPANY_ID, current_shared_entities = SHARED_ENTITIES, is_customer_data = false) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "color_name", "materials",
                "platform", "style_category", "gtin", "tags", "product_model_type", "placement_type",
                 "material_type"],
        }

        if (IS_MS_PROVIDER || sequence_id == -1) {
            payload["order_by"] = "last_modified_stamp desc"
        } else {
            payload['required_fields'].push('folder_id', 'folder_name', 'parent_folder_id',
                'library_order', 'entity_order')
            payload["order_by"] = "library_order asc,entity_order asc"
        }
        let filter_string = "";
        let shared_username = "";
        let customer_username = "customer_username__exact='" + current_customer + "'"
        let customer_username_notexact = "customer_username__notexact='" + current_customer + "'"
        let company_id_notexact = ""

        let company_id = "";
        let model_status = ""
        if(IS_MS_PROVIDER && type == "owned"){
            model_status = "model_status__in=[3,4,5]"
        }
        else{
            model_status = "model_status__in=[5]"
        }
        let is_hidden = "is_hidden__not=true"
        let shared_hidden = "shared_hidden__not=true"
        let sequence_id_value = "sequence_id__exact=" + sequence_id
    

        if (type == 'shared') {
            payload['required_fields'].push('shared_product_type')
            shared_username = "shared_username__exact='" + current_customer + "'"
            filter_string = "(" + shared_username + "&&" + shared_hidden + ")"

            if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
                company_id_notexact = "company_id__notexact=" + current_company_id + "||company_id__isnull=true"
                filter_string = filter_string + "&&(" + company_id_notexact + ")&&("+customer_username_notexact+")";
            } else {
                filter_string = filter_string + "&&("+customer_username_notexact+")";
            }

        } else if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
            company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
        } else {
            if (IS_MS_PROVIDER && !is_customer_data) {
                company_id = "company_id__isnull=true"
                let requested_for = "requested_for__isnull=true"
                filter_string = "(" + company_id + "&&" + customer_username + ")&&(" + is_hidden + ")&&(" + requested_for + ")"
            } else {
                filter_string = "(" + customer_username + "&&" + is_hidden + ")"
            }
        }
        filter_string = filter_string + "&&(" + model_status + ")"
        if (sequence_id != -1) {
            filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
        }
        if (current_company_id == ENVIRONMENT.WALMART_COMPANY_ID)
        {
            if(type == 'shared'){
                company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
                filter_string = '(' + filter_string + ')||(' + `(${company_id}||${customer_username})&&(${is_hidden})&&(${model_status})&&(product_model_type__exact='props'))`
            }
            else {
                filter_string = '(' + filter_string + `)&&(product_model_type__notexact='props')`
            }
        }
        filter_string = filter_string + "&&(has_active_project__is=false)"
        payload['filter_string'] = filter_string
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        if(localStorage.getItem('super_admin_username')){
            payload['is_customer_assumed_role'] = true
            payload['super_admin_username'] = localStorage.getItem('super_admin_username')
        }
        return payload;
    }

    const fetchProductData = async (type, sequenceId, initial = false) => {
        if (!initial) {
            setDataLoading(true);
          } else {
            if (type == 'shared') {
              setInitialLoadShared(true);
            } else {
              setInitialLoadOwned(true);
            }
        }
        const payload = getProductPayload(initial, type, sequenceId);
        const data = [];
        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((res) => {
                if (res.data) {
                    const dataWithKeywords = Utilities.getProductsDataWithKeywords(res.data)
                    type === 'owned' && setOwnedProductData(dataWithKeywords);
                    type === 'shared' && setSharedProductData(dataWithKeywords);
                }
                if (initial) {
                    if (type == 'shared') {
                      setInitialLoadShared(false);
                    } else {
                      setInitialLoadOwned(false);
                    }
                  } else {
                    if (type == 'shared') {
                      setSharedProductLoader(false);
                    } else {
                      setOwnedProductLoader(false);
                    }
                  }
            });
        return data;
    };

    const openFilters = () => {
        setTabChangeThroughClick(false);
        setFiltersDrawer(true);
    };

    const setProductCategoryData = () => {

        let payload = {
            output: 'tree'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
            .then(res => {
                setProductCategoriesData(res.data);
            });

        payload = {
            output: 'serial'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
            .then(res => {
                setProductParentCateogries(res.data);
            });
    }

    const loadProductMaterialsData = () => {
        let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
            .then(res => {
                let categoriesData = Utilities.getMaterialsData(res.data);
                for (let i = 1; i <= (res.data).length; i++) {
                    if (res.data[i - 1].parent_id == "") {
                        data.push({
                            key: res.data[i - 1]['material_id'],
                            title: res.data[i - 1]['name'],
                            value: res.data[i - 1]['name'],
                            children: categoriesData[res.data[i - 1]['material_id']],
                        });
                    }

                }
                setProductMaterialsData(data);
            });
    }


    const fetchStoreProductData = async (initial = false) => {
        const data = [];
        setStoreProductsLoader(true);
        let payload = {
            item_type: 'product',
            username: CUSTOMER_USERNAME
        }
        if (initial) {
            payload.limit = '';
            setInitialLoadProducts(true);
        }
        axios.post(ENVIRONMENT.STORE_LIST, payload)
            .then((res) => {
                if (!initial) {
                    setStoreProductsLoader(false);
                }
                const dataWithKeywords = Utilities.getProductsDataWithKeywords(res.data, "store");
                setStoreProductData(dataWithKeywords);
                let max_price = dataWithKeywords.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
                setMaxPriceProduct(max_price);
            })
            .catch((error) => {
            });
        return data;
    };

    const getCustomerDataForMsprovider = async (type) => {
        const payload = getProductPayload(false, type, -1, MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true);
        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((res) => {
                if (res.data) {
                    const dataWithKeywords = Utilities.getProductsDataWithKeywords(res.data)
                    type === 'owned' && setMspUserData(dataWithKeywords);
                    type === 'shared' && setMspSharedData(dataWithKeywords);
                }
            });
    };

    const handleTabChange = (key, event_type = 'invoked') => {
        setTabActiveKey(key);
        if (key == 'store') {
            setIsStore(true);
        } else setIsStore(false);

        if (event_type == 'click') {
            setTabChangeThroughClick(true);
        }
    };

    const handleContinue = () => {
        if (props.roomType == 'collection') {
            props.handleSubmit('');
            return;
        }
        setStep(3);
    };

    const fetchData = async () => {
        setProductCategoryData();
        loadProductMaterialsData();
        if (props.project_id) {
            await getProjectProductsList(props.project_id);
            await fetchStoreProductData(true);
            setTabActiveKey('project_products');
        } else {
            fetchProductData('owned', -1, true);
            fetchProductData('shared', -1, true);
            await fetchStoreProductData(true);
            if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
                await getCustomerDataForMsprovider('owned');
                await getCustomerDataForMsprovider('shared');

            }
        }
        
        setDataLoading(false);
    };

    useEffect(() => {
        if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            setIsMSProvider(true);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (initialLoadProducts) {
            fetchStoreProductData();
        }
        
      },[initialLoadProducts]);

      useEffect(() => {
        if (!initialLoadOwned && ownedProductLoader) {
            fetchProductData('owned', -1);
        }
      }, [initialLoadOwned]);
    
      useEffect(() => {
        if (!initialLoadShared && sharedProductLoader) {
            fetchProductData('shared', -1);
        }
      }, [initialLoadShared]);

    useEffect(() => {
        setProjectFilteredData(projectProducts);
        setMspFilteredData(mspUserData);
        setMspSharedFilteredData(mspSharedData);
        setOwnedFilteredData(ownedProductData);
        setSharedFilteredData(sharedProductData);
        setStoreFilteredData(storeProductData);
    }, [mspUserData, ownedProductData, sharedProductData, storeProductData, mspSharedData, projectProducts]);

    const changeSearchValue = (value) => {
        setSearchValue(value);
    }

    const changeProductCategory = (value) => {
        let values = []
        value.map((name) => {
            const targetItemName = name;
            const targetItem = productParentCateogries.find(item => item.name === targetItemName);

            if (targetItem) {
                const result = findItemsByParentId(productParentCateogries, targetItemName, targetItem.category_id);
                result.unshift(name);
                values = [...values, ...result]
            }
        });

        if (value.length > 0
            || (productStyleCategory.length > 0)
            || (colorValue.length > 0)
            || (productMaterials.length > 0)
            || (productModelType.length > 0)) {
            setFiltersApplied(true);
        } else {
            setFiltersApplied(false);
        }
        setProductCategoryValue(value);
        setProductCategoryValueWithChilds(values);
    }

    const findChildrenByParentTitle = (parentTitle, node) => {
        if (node.title === parentTitle) {
            return node.children.map(child => child.title);
        } else if (node.children) {
            let result = [];
            for (const childNode of node.children) {
                result = result.concat(findChildrenByParentTitle(parentTitle, childNode));
            }
            return result;
        } else {
            return [];
        }
    }

    const findItemsByParentId = (data, targetItemName, parentId) => {
        const items = [];
        for (const item of data) {
            if (item.parent_id === parentId) {
                items.push(item.name);
                items.push(...findItemsByParentId(data, targetItemName, item.category_id));
            }
        }
        return items;
    }

    const goBack = () => {
        if (props.roomType == 'collection') {
            window.location.href = '/scene_renders/' + props.scene_id;
            return;
        }
        setStep(2);
    }

    const onChangeSharedTabType = (e, type) => {
        if (type == 'other') {
            setSharedTabType(e.target.value);
        } else if ('other_msp') {
            setMspSharedTabType(e.target.value);
        }
        setTabChangeThroughClick(true);
    }

    const getProductTabCount = (type) => {
        return tabCount[type] !== -1 ? tabCount[type] : getProductsLength(type);
    };


    const updateTabCount = (count, tabName) => {
        setTabCount(prevTabCount => ({
            ...prevTabCount,
            [tabName]: count
        }));
    };

    const checkFilteredResults = (productsData) => {
        let data = [];
        if(searchValue != '' && !filtersApplied) {
            data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, productsData);
        } else if (searchValue == '' && filtersApplied) {
            data = productsData.filter((entity) => { return returnSearchValFunction(entity) == true })
            data = Utilities.getRelevanceBasedFilteredResults(data, appliedFiltersList)
        } else if (searchValue != '' && filtersApplied) {
            let filter_data = productsData.filter((entity) => { return returnSearchValFunction(entity) == true })
            data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, filter_data);
            data = Utilities.getRelevanceBasedFilteredResults(data, appliedFiltersList)
        }
        return data;
    }

    const switchTabIfEmpty = () => {
        // if current selected tab is empty only then switch to other tab
        if (tabChangeThroughClick == false) {
            if (checkFilteredResults(getProducts(tabActiveKey))?.length == 0) {
                if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getProducts("msp"))?.length > 0) {
                    setTabActiveKey("msp");
                } else if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getProducts("other_msp"))?.length > 0) {
                    setTabActiveKey("other_msp");
                } else if (checkFilteredResults(getProducts("project_products"))?.length > 0) {
                    setTabActiveKey('project_products');
                } else if (checkFilteredResults(getProducts("owned"))?.length > 0) {
                    setTabActiveKey('owned');
                } else if (checkFilteredResults(getProducts("other"))?.length > 0) {
                    setTabActiveKey('other');
                } else if (checkFilteredResults(getProducts("store"))?.length > 0) {
                    setTabActiveKey('store');
                }
            }
        }
    }

    const tabs = [
        {
            type: 'msp',
            title: `${MANAGED_CUSTOMER_USERNAME}'s Models (${getProductTabCount('msp')}${searchValue != '' || filtersApplied ? ` of ${getProductsLength('msp')} items` : ``})`,
            data: getProducts('msp'),
            setData: setMspFilteredData,
        },
        {
            type: 'other_msp',
            title: `${MANAGED_CUSTOMER_USERNAME}'s Other Models (${getProductTabCount('other_msp')}${searchValue != '' || filtersApplied || mspSharedTabType != 'all' ? ` of ${getProductsLength('other_msp','all')} items` : ``})`,
            data: getProducts('other_msp'),
            setData: setMspSharedFilteredData,
        },
        {
            type: 'project_products',
            title: `Project Products ${`(${getProductTabCount('project_products')}${searchValue != '' || filtersApplied ? ` of ${getProductsLength('project_products')} items` : ``})`}`,
            data: getProducts('project_products'),
            setData: setProjectFilteredData
        },
        {
            type: 'owned',
            title: `Your Models ${ownedProductLoader ? '' : `(${getProductTabCount('owned')}${searchValue != '' || filtersApplied ? ` of ${getProductsLength('owned')} items` : ``})`}`,
            data: getProducts('owned'),
            setData: setOwnedFilteredData
        },
        {
            type: 'other',
            title: `Other Models ${sharedProductLoader ? '' : `(${getProductTabCount('other')}${searchValue != '' || filtersApplied || sharedTabType != 'all' ? ` of ${getProductsLength('other', 'all')} items` : ``})`}`,
            data: getProducts('other'),
            setData: setSharedFilteredData
        },
        {
            type: 'store',
            title: `Store Products  ${storeProductsLoader ? `` : `(${getProductTabCount('store')}${searchValue != '' || filtersApplied ? ` of ${getProductsLength('store')} items` : ``})`}`,
            data: getProducts('store'),
            setData: setStoreFilteredData
        },
    ];

    const onProductTabChange = (e, type) => {
        if (type == 'owned') {
            setCurrentTabType(e.target.value)
        } else if (type == 'msp') {
            setCurrentMSPTabType(e.target.value);
        } else if (type == 'project_products') {
            setCurrentProjectProductTabType(e.target.value);
        }
    }

    return (
        <div className="spaces-list">
            <Row className="filters-bar">
                <Col lg={10}>
                    <span className="heading">{ heading ? heading : "Add products to your scene"}</span>
                </Col>
                <Col lg={14}>
                    <Row justify="end">
                        <div className="search-bar-width w-40 mr-8">
                            <Form>
                                <Input.Group compact size="large" className="d-inline">
                                    {
                                        <Input
                                            placeholder={"Search products"}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            className="manrope f-14 mat-search-bar"
                                        />
                                    }
                                </Input.Group>
                            </Form>
                        </div>
                        <div>
                            <Tooltip
                                title={
                                    <span className="manrope f-12 white">Apply Filters</span>
                                }
                            >
                                <div
                                    className="icon-button-container"
                                    onClick={openFilters}
                                >
                                    <FilterOutlined />
                                </div>
                            </Tooltip>
                        </div>
                    </Row>
                </Col>
            </Row>
            {filterTagFunction}

            <Tabs
                onChange={(key) => handleTabChange(key, 'click')}
                className="product-tab library"
                tabBarGutter={16}
                size="large"
                activeKey={tabActiveKey}
            >
                {((!initialLoadOwned && ownedProductLoader && tabActiveKey == "owned" && (searchValue || filtersApplied)) || (sharedProductLoader && tabActiveKey == "other" && (searchValue || filtersApplied)) || (storeProductsLoader && tabActiveKey == "store" && (searchValue || filtersApplied))) ?
                <DottedLoader />:
                (!props.project_id && (initialLoadOwned || initialLoadShared)) || (props.project_id && projectDataLoader) || ((ownedProductLoader || sharedProductLoader)&&(filtersApplied || searchValue)) ? (
                    <DottedLoader />
                ) : (
                    tabs?.map((tab, index) => {
                        return (
                            ((index === 0 || index === 1) && !isMSProvider) || (index == 2 && !props.project_id) || (props.project_id && ![2, 5].includes(index)) || ([0, 1, 5].includes(index) && props.roomType == 'collection') ? null :
                                <TabPane
                                    forceRender={true}
                                    tab={tab.title}
                                    key={tab.type}
                                >
                                {IS_MS_PROVIDER && (tab.type == "msp" || tab.type == "owned" || tab.type == "project_products") && <Row>
                                    <div className="shared-models-tab manrope f-14 black-55">
                                    <Radio.Group
                                    onChange={(e) => onProductTabChange(e, tab.type)}
                                    value={tab.type == "owned" ? currentTabType : tab.type == "project_products" ? currentProjectProductTabType : currentMSPTabType}
                                    >
                                        <Radio value="5">Approved Models</Radio>
                                        <Radio value="4">In Customer QA</Radio>
                                        {tab.type == "project_products" ? '' : <Radio value="3">In Admin QA</Radio>}
                                        <Radio value="-1">All</Radio>
                                    </Radio.Group>
                                    </div>
                                </Row>}
                                {(tab.type == "other" || tab.type == "other_msp") && <Row>
                                    <div className="shared-models-tab manrope f-14 black-55 j-center-a-baseline">
                                        <span className="shared-models-tab-span">
                                            Show Items:
                                        </span>
                                        <Radio.Group onChange={(e) => onChangeSharedTabType(e, tab.type)} value={tab.type == "other" ? sharedTabType : mspSharedTabType}>
                                            <Radio value='all'>
                                                All
                                            </Radio>
                                            <Radio value='store'>
                                                Added from Store
                                            </Radio>
                                            <Radio value='shared'>
                                                Shared
                                            </Radio>
                                            {(tab.type == "other" && COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID) || (tab.type == "other_msp" && IS_MS_PROVIDER && (MANAGED_COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID)) &&
                                            <Radio value='props'>
                                                Your Props
                                            </Radio>}
                                        </Radio.Group>
                                    </div>
                                </Row>}
                                <ProductListVirtualized
                                    page={page}
                                    tabChangeThroughClick={tabChangeThroughClick}
                                    setTabChangeThroughClick={setTabChangeThroughClick}
                                    switchTabIfEmpty={switchTabIfEmpty}
                                    checkFilteredResults={checkFilteredResults}
                                    selectedRoom={selectedRoom}
                                    type="product"
                                    updateTabCount={updateTabCount}
                                    tabKey={tab.type}
                                    productsData={tab.data}
                                    productsAddedToScene={productsAddedToScene}
                                    setProductsAddedToScene={setProductsAddedToScene}
                                    searchValue={searchValue}
                                    setData={tab.setData}
                                    colorValue={colorValue}
                                    setColorValue={setColorValue}
                                    productCategoryValue={productCategoryValue}
                                    setProductCategoryValue={setProductCategoryValue}
                                    productStyleCategory={productStyleCategory}
                                    setProductStyleCategory={setProductStyleCategory}
                                    productMaterials={productMaterials}
                                    setProductMaterials={setProductMaterials}
                                    productModelType={productModelType}
                                    setProductModelType={setProductModelType}
                                    changeProductCategory={changeProductCategory}
                                    filtersApplied={filtersApplied}
                                    isStore = {isStore}
                                    appliedFiltersList={appliedFiltersList}
                                    setAppliedFiltersList={setAppliedFiltersList}
                                    returnSearchValFunction={returnSearchValFunction}
                                    requestStatusRoom = {requestStatusRoom}
                                    filterTagFunction={filterTagFunction}
                                    checkEnabled={checkEnabled}
                                    preselectedProducts={preselectedProducts}
                                    tabActiveKey={tabActiveKey}
                                />
                            </TabPane>
                        );
                    }))}
            </Tabs>

            <div className={`bottom-menu ${((!initialLoadOwned && ownedProductLoader && tabActiveKey == "owned") || (sharedProductLoader && tabActiveKey == "other") || (storeProductsLoader && tabActiveKey == "store")) ?  'justify-space-between-important' : ``} `}>
                {((!initialLoadOwned && ownedProductLoader && tabActiveKey == "owned") || (sharedProductLoader && tabActiveKey == "other") || (storeProductsLoader && tabActiveKey == "store")) &&
                <div className='manrope f-16 justify-in-start ml-168' style={{color:'#276DD7', padding:10}}>
                    <LoadingOutlined className='manrope f-24 mr-16' />
                    Loading remaining {tabActiveKey} products
                </div>}
                
                <div className='mr-20 justify-in-end'>
                    {(productsAddedToScene && productsAddedToScene.length > 0) && <div className='manrope black-d9 f-16 mr-16'><CheckCircleFilled className='mr-2' /> {productsAddedToScene.length} selected</div>}
                    <Button
                        onClick={handleCancel ? handleCancel : goBack}
                        className='product-btn br-4 f-16 light-blue pd-16'>
                        Back
                    </Button>
                    {productsAddedToScene && productsAddedToScene.length == 0 && props.roomType != 'collection' ?
                        <Button
                            onClick={handleNext ? handleNext : handleContinue}
                            className={'product-btn f-16 light-blue ml-16 br-4 pd-16'}>
                            Add Products Later {loading ? <LoadingOutlined /> : ''}
                        </Button>
                        : <Button
                            onClick={handleNext ? handleNext : handleContinue}
                            disabled={productsAddedToScene && productsAddedToScene.length == 0}
                            className={productsAddedToScene && productsAddedToScene.length == 0 ? 'product-btn br-4 f-16 disabled-grey ml-16 pd-16' : 'product-btn dark-blue f-16 ml-16 br-4 pd-16'}>
                            Continue {loading ? <LoadingOutlined /> : ''}
                        </Button>}
                
                </div>
            </div>

            <AddProductContext.Provider
                value={{
                    filtersApplied: filtersApplied,
                    setFiltersApplied: setFiltersApplied,
                    setAppliedFiltersList: setAppliedFiltersList,
                    appliedFiltersList: appliedFiltersList,
                    libraryType: isStore ? "store" : "library",
                    searchValue: searchValue,
                    space_areas: spaceAreas,
                    filtersDrawer: filtersDrawer,
                    setFiltersDrawer: setFiltersDrawer,
                    productCategoriesData: productCategoriesData,
                    productParentCateogries: productParentCateogries,
                    productMaterialsData: productMaterialsData,
                    changeSearchValue: changeSearchValue,
                    openFilters: openFilters,
                    setFilterTagFunction: setFilterTagFunction,
                    filterTagFunction: filterTagFunction,
                    returnSearchValFunction: returnSearchValFunction,
                    setReturnSearchValFunction: setReturnSearchValFunction,
                    productsForLibrary: productsForLibrary,
                    setProductsForLibrary: setProductsForLibrary,
                    maxPriceProduct: maxPriceProduct,
                    currentTabType: currentTabType
                }}
            >
                <ProductFiltersDrawer scene_flow={true} />
            </AddProductContext.Provider>
        </div>
    );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(
    mapStateToProps,
)(SelectProduct),
);
