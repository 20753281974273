import * as THREE from "three";

export default class LinearGrid {

    ceilingObj = null;
    planeNormal = null;
    intersectableObjects = null;

    itemOfInterest = '-1';
    raycastColor = 0xff0000;

    floorHeight = 0;
    heightDiffThreshold = 0.025;

    shouldInvertNormals = true;
    allowedOverlapThreshold = 0;

    constructor(plane, planeOfMovement, offset, raycaster, scene,  isInside = false, checkWallCollision = false, roomHeightOffset = 0, useDynamicOffset = false) {
        this.raycaster = raycaster;
        this.scene = scene;
        this.plane = plane;
        this.checkWallCollision = checkWallCollision;
        this.offset = offset;
        this.planeOfMovement = planeOfMovement;
        this.isInside = isInside;
        this.roomHeightOffset = roomHeightOffset;
        this.useDynamicOffset = useDynamicOffset;

        this.bb = new THREE.Box3();
        if (Array.isArray(this.plane)) {
            this.plane.forEach(element => {
                this.bb.expandByObject(element);
            });
        }
        else {
            this.bb.setFromObject( plane );
        }
        
        // Find the non-movement coordinate
        let allCoordinates = [0, 1, 2];
        this.nonMovementCoordinate = -1;
        for (let i = 0; i < allCoordinates.length; i++) {
            if (this.planeOfMovement.indexOf(allCoordinates[i]) == -1) {
                this.nonMovementCoordinate = allCoordinates[i];
            }
        }

        this.resetMarker();
    }

    resetMarker() {
        if(this.nonMovementCoordinate != -1) {
            this.marker = new THREE.Vector3(this.bb.min.x, this.bb.min.y, this.bb.min.z);
            this.nextRowStartMarker = this.marker.getComponent(this.planeOfMovement[1]);

            // Set marker position to center of plane in non movement coordinate, so that width/2 can used as offset value
            let widthOfGrid = this.bb.getSize( new THREE.Vector3() ).getComponent(this.nonMovementCoordinate);
            this.marker.setComponent(this.nonMovementCoordinate, this.bb.min.getComponent(this.nonMovementCoordinate) + (widthOfGrid / 2))

            // Set offset value for non movement coordinate equal to height of movement plane.
            // Set sign according to side of wall
            if (this.isInside) {
                this.offset.setComponent(this.nonMovementCoordinate, -widthOfGrid/2);
            }
            else {
                this.offset.setComponent(this.nonMovementCoordinate, widthOfGrid/2);
            }
        }
    }

    debugLog(log) {
        if(!Array.isArray(this.plane) && this.plane.name.toString().toLowerCase().includes( this.itemOfInterest.toLowerCase())) {
            console.log(log);
        }
    }

    drawRayCastGizmo() {
        if(!Array.isArray(this.plane) && this.plane.name.toString().toLowerCase().includes( this.itemOfInterest.toLowerCase())) {
            this.scene.add(new THREE.ArrowHelper(this.raycaster.ray.direction, this.raycaster.ray.origin, this.raycaster.far, this.raycastColor) );
        }
    }

    drawBoundingBox() {
        if(this.plane.name.toString().toLowerCase().includes( this.itemOfInterest.toLowerCase())) {
            let boxHelper = new THREE.BoxHelper( this.plane, 0xffff00 );
            this.scene.add( boxHelper );
        }
    }

    setPlaneNormal(planeNormal) {
        this.planeNormal = planeNormal;
    }

    setIntersectableObjects(intersectableObjects) {
        this.intersectableObjects = intersectableObjects;
    }

    setInvertNormalsFlag(shouldInvertNormals) {
        this.shouldInvertNormals = shouldInvertNormals;
    }

    setAllowedOverlapThreshold(threshold) {
        this.allowedOverlapThreshold = threshold;
    }

    getIntersectableObjects() {
        return this.intersectableObjects;
    }

    setCeilingObject(ceiling) {
        this.ceilingObj = ceiling;
    }

    getMarkerWithOffset() {
        let retVal = new THREE.Vector3();
        retVal.add(this.marker);
        retVal.add(this.offset);
        return retVal;
    }

    getNonMovementCoordinate() {
        return this.nonMovementCoordinate;
    }

    updateOffsetForObject(sizeBoundingBoxObj) {
        if (this.useDynamicOffset) {
            let sizeBoundingBoxGrid = new THREE.Vector3()
            this.bb.getSize(sizeBoundingBoxGrid);
            let gridPercentageCoveredByObj = sizeBoundingBoxObj.getComponent(this.planeOfMovement[1]) / sizeBoundingBoxGrid.getComponent(this.planeOfMovement[1]);
            if (gridPercentageCoveredByObj > 1) {
                gridPercentageCoveredByObj = 1;
            }

            // Set offset value according to size of object for wall items, so that large items can also fit.
            let yOffset = (0.9 - gridPercentageCoveredByObj) * sizeBoundingBoxGrid.getComponent(this.planeOfMovement[1]);
            this.offset.setComponent(this.planeOfMovement[1], yOffset);
        }
    }

    moveMarkerToNextRow() {
        // Reset to start of bounding box
        this.marker.setComponent(this.planeOfMovement[0], this.bb.min.getComponent(this.planeOfMovement[0]));

        // Update the component that defines the 2nd coordinate of plane of movement
        this.marker.setComponent(this.planeOfMovement[1], this.nextRowStartMarker);
    }

    canObjFitAtCurrentRow(sizeBoundingBoxObj) {
        let bbMin = new THREE.Vector3(this.getMarkerWithOffset().x, this.getMarkerWithOffset().y, this.getMarkerWithOffset().z);

        // Get bounding box of available space in current row
        if(bbMin.getComponent(this.nonMovementCoordinate) > this.bb.max.getComponent(this.nonMovementCoordinate)) {
            bbMin.setComponent(this.nonMovementCoordinate, this.bb.max.getComponent(this.nonMovementCoordinate));            
        }
        let boundingBoxAvailableGridSpace = new THREE.Box3( bbMin, this.bb.max)
        let sizeBoundingBoxAvailableGridSpace = new THREE.Vector3(); 
        boundingBoxAvailableGridSpace.getSize(sizeBoundingBoxAvailableGridSpace);
        // sizeBoundingBoxAvailableGridSpace.sub(this.offset);

        // Check if object can fit in available space
        if (sizeBoundingBoxAvailableGridSpace.getComponent(this.planeOfMovement[0]) < sizeBoundingBoxObj.getComponent(this.planeOfMovement[0]) || sizeBoundingBoxAvailableGridSpace.getComponent(this.planeOfMovement[1]) < sizeBoundingBoxObj.getComponent(this.planeOfMovement[1])) {
            return false;
        }
        else {
            return true;
        }
    }

    canFitObj(object) {
        let box = new THREE.Box3().setFromObject(object);
        let sizeBoundingBoxObj = new THREE.Vector3()
        box.getSize(sizeBoundingBoxObj);

        if(this.getMarkerWithOffset().getComponent(this.planeOfMovement[1]) + 0.01 > this.bb.max.getComponent(this.planeOfMovement[1])) {
            return false;
        }
        else {
            return true;
        }
    }

    canContainObj(object) {
        let box = new THREE.Box3().setFromObject( object );
        let sizeBoundingBoxObj = new THREE.Vector3()
        box.getSize(sizeBoundingBoxObj);

        this.updateOffsetForObject(sizeBoundingBoxObj);

        if(this.bb.min.getComponent(this.planeOfMovement[0]) + this.offset.getComponent(this.planeOfMovement[0]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[0]) <= this.bb.max.getComponent(this.planeOfMovement[0]) && this.bb.min.getComponent(this.planeOfMovement[1]) + this.offset.getComponent(this.planeOfMovement[1]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[1]) <= this.bb.max.getComponent(this.planeOfMovement[1])) {
            return true;
        }
        else {
            return false;
        }
    }

    canObjRunOutOfBounds(sizeBoundingBoxObj) {
        // Will placing item at current marker coordinates result in object going out of grid bounds?
        if (this.getMarkerWithOffset().getComponent(this.planeOfMovement[0]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[0]) > this.bb.max.getComponent(this.planeOfMovement[0]) || this.getMarkerWithOffset().getComponent(this.planeOfMovement[1]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[1]) > this.bb.max.getComponent(this.planeOfMovement[1])) {
            return true;
        }
        else {
            return false;
        }
    }

    isAncestor(ancestor, decendant) {
        while (decendant.parent != this.scene) {
            if (decendant.parent == ancestor) {
                return true;
            }
            decendant = decendant.parent;
        }

        return false;
    }

    isFloorObj(object){
        if (object == this.plane || this.isAncestor(this.plane, object)) {
            return true;
        }

        return false;
    }

    isCeilingObj(object){
        if ( object == this.ceilingObj || this.isAncestor(this.ceilingObj, object)) {
            return true;
        }

        return false;
    }

    didDirectIntersectFloor(checkHeight = false) {
        let intersects = this.raycaster.intersectObjects(this.intersectableObjects, true);

        // this.debugLog(intersects);
        // this.drawRayCastGizmo();
        if (this.ceilingObj == null ||  (this.ceilingObj != null && intersects.length > 0 && this.isCeilingObj(intersects[0].object))) {
            // Remove ceiling from results
            intersects = intersects.filter( ( item ) => {
                return !( this.isCeilingObj(item.object) ) ;
            } );
            let floorIndex = 0;
            if(intersects.length >= floorIndex + 1 && this.isFloorObj(intersects[floorIndex].object) ){
                let normal = intersects[floorIndex].face.normal;
                if(this.shouldInvertNormals) {
                    normal = new THREE.Vector3( intersects[floorIndex].face.normal.x, intersects[floorIndex].face.normal.z, -intersects[floorIndex].face.normal.y );
                }
                
                if (Math.abs(normal.getComponent(this.nonMovementCoordinate)) >= 0.95 && Math.abs(normal.getComponent(this.nonMovementCoordinate)) <= 1.05) {
                    // Compare height of this point with last point.
                    if(checkHeight && Math.abs(intersects[floorIndex].point.getComponent(this.nonMovementCoordinate) - this.floorHeight) > this.heightDiffThreshold) {
                        this.floorHeight = intersects[floorIndex].point.getComponent(this.nonMovementCoordinate);
                        return false;
                    }
                    this.floorHeight = intersects[floorIndex].point.getComponent(this.nonMovementCoordinate);
                    return [true, intersects];
                }
            }
        }

        if (intersects.length > 0) {
            return [false, intersects];
        }

        return [false, null];
    }

    updateRayCastObject(origin, terminal) {
        let directionVector = new THREE.Vector3();
        directionVector.subVectors(terminal, origin).normalize();
        let near = 0;
        let far = origin.distanceTo(terminal);      // far = distance from origin to terminal   
        
        this.raycaster.near = near;
        this.raycaster.far = far;
        this.raycaster.set(origin, directionVector);
    }

    checkIntersectionWithFloor(size_bb_obj) { 
        let markerWithOffset = this.getMarkerWithOffset();
        let raycastPoint = new THREE.Vector3().copy(markerWithOffset);
        let raycastOffset = size_bb_obj.getComponent(this.nonMovementCoordinate) + this.roomHeightOffset;

        let directionVector = new THREE.Vector3();
        if(this.isInside) {
            directionVector.setComponent(this.nonMovementCoordinate, 1);
            raycastPoint.setComponent(this.nonMovementCoordinate, markerWithOffset.getComponent(this.nonMovementCoordinate) - raycastOffset);
        }
        else {
            directionVector.setComponent(this.nonMovementCoordinate, -1);
            raycastPoint.setComponent(this.nonMovementCoordinate, markerWithOffset.getComponent(this.nonMovementCoordinate) + raycastOffset);
        }

        this.raycaster.far = raycastOffset * 2;
        this.raycaster.set(raycastPoint, directionVector);

        // Raycast at bottom-left corner
        this.raycastColor = 0xff0000;
        let raycastResult = this.didDirectIntersectFloor(); 
        if (!raycastResult[0]) {
            return raycastResult;
        }
        else {
            // Raycast at bottom-right corner
            raycastPoint.setComponent(this.planeOfMovement[0], raycastPoint.getComponent(this.planeOfMovement[0]) + size_bb_obj.getComponent(this.planeOfMovement[0]));
            this.raycaster.set(raycastPoint, directionVector);

            this.raycastColor = 0xFFFF00;
            let raycastResult = this.didDirectIntersectFloor(true); 
            if (!raycastResult[0]) {
                return raycastResult;
            }
            else {
                // Raycast at top-right corner
                raycastPoint.setComponent(this.planeOfMovement[1], raycastPoint.getComponent(this.planeOfMovement[1]) + size_bb_obj.getComponent(this.planeOfMovement[1]));
                this.raycaster.set(raycastPoint, directionVector);

                this.raycastColor = 0x0000FF;
                let raycastResult = this.didDirectIntersectFloor(true); 
                if (!raycastResult[0]) {
                    return raycastResult;
                }
                else {
                    // Raycast at top-left corner
                    raycastPoint.setComponent(this.planeOfMovement[0], raycastPoint.getComponent(this.planeOfMovement[0]) - size_bb_obj.getComponent(this.planeOfMovement[0]));
                    this.raycaster.set(raycastPoint, directionVector);

                    this.raycastColor = 0x000000;
                    let raycastResult = this.didDirectIntersectFloor(true); 
                    if (!raycastResult[0]) {
                        return raycastResult;
                    }
                    else {
                        // If no intersection was found at any corner of the objects bounding box.
                        return [true, null];
                    }
                }
            }
        }
    }

    checkIntersectionWithObstructionsOverLine(origin, terminalLineStart, terminalLineEnd) {
        for (let alpha = 0; alpha <= 1; alpha = alpha + 0.2) {
            this.updateRayCastObject(origin, terminalLineStart);
            let intersects = this.raycaster.intersectObjects(this.intersectableObjects, true);

            this.debugLog(intersects);
            this.drawRayCastGizmo();

            // Remove ceiling from results
            intersects = intersects.filter( ( item ) => {
                return !( this.isCeilingObj(item.object) ) ;
            } );

            if (intersects.length > 0) {
                return [true, intersects];
            }

            terminalLineStart.lerp(terminalLineEnd, alpha);
        }

        return [false, null];
    }

    checkIntersectionWithObstructionsVertically(origin) {

        let directionVector = new THREE.Vector3(0,1,0);
        let near = 0;
        let far = Infinity;      // far = distance from origin to terminal   
        
        this.raycaster.near = near;
        this.raycaster.far = far;
        this.raycaster.set(origin, directionVector);
        let intersects = this.raycaster.intersectObjects(this.intersectableObjects, true);
        // Remove ceiling from results
        intersects = intersects.filter( ( item ) => {
            return !( this.isCeilingObj(item.object) ) ;
        } );

        if (intersects.length > 0) {
            return [true, intersects];
        }
        
        return [false, null];

    }

    checkIntersectionWithObstructions(size_bb_obj) {
        // Corners should be found using bbox
        let markerWithOffset = this.getMarkerWithOffset();
        let top_lef_corner = new THREE.Vector3();
        top_lef_corner.setComponent(this.planeOfMovement[0], markerWithOffset.getComponent(this.planeOfMovement[0]));
        top_lef_corner.setComponent(this.planeOfMovement[1], markerWithOffset.getComponent(this.planeOfMovement[1]));
        
        let raycastOffset = size_bb_obj.getComponent(this.nonMovementCoordinate) * 0.1; 
        if(this.isInside) {
            top_lef_corner.setComponent(this.nonMovementCoordinate, markerWithOffset.getComponent(this.nonMovementCoordinate) - raycastOffset);
        }
        else {
            top_lef_corner.setComponent(this.nonMovementCoordinate, markerWithOffset.getComponent(this.nonMovementCoordinate) + raycastOffset);
        }

        let top_right_corner = new THREE.Vector3().copy(top_lef_corner);
        top_right_corner.setComponent(this.planeOfMovement[0], top_right_corner.getComponent(this.planeOfMovement[0]) + size_bb_obj.getComponent(this.planeOfMovement[0]));
        
        let bottom_right_corner = new THREE.Vector3().copy(top_right_corner);
        bottom_right_corner.setComponent(this.planeOfMovement[1], bottom_right_corner.getComponent(this.planeOfMovement[1]) + size_bb_obj.getComponent(this.planeOfMovement[1]));

        let bottom_left_corner = new THREE.Vector3().copy(bottom_right_corner);
        bottom_left_corner.setComponent(this.planeOfMovement[0], bottom_left_corner.getComponent(this.planeOfMovement[0]) - size_bb_obj.getComponent(this.planeOfMovement[0]));

        let upper_right_corner = new THREE.Vector3().copy(top_right_corner);
        upper_right_corner.setComponent(this.planeOfMovement[1], upper_right_corner.getComponent(this.planeOfMovement[1]) + size_bb_obj.getComponent(this.planeOfMovement[1]));
        if(this.isInside) {
            upper_right_corner.setComponent(this.nonMovementCoordinate, upper_right_corner.getComponent(this.nonMovementCoordinate) - size_bb_obj.getComponent(this.nonMovementCoordinate));
        }
        else {
            upper_right_corner.setComponent(this.nonMovementCoordinate, upper_right_corner.getComponent(this.nonMovementCoordinate) + size_bb_obj.getComponent(this.nonMovementCoordinate));
        }

        this.raycastColor = 0x808080;
        let raycastResult = this.checkIntersectionWithObstructionsOverLine(top_lef_corner.clone(), bottom_right_corner.clone() , top_right_corner.clone());
        if (!raycastResult[0]) {
            this.raycastColor = 0xFF6347;
            raycastResult = this.checkIntersectionWithObstructionsOverLine(top_lef_corner.clone(), bottom_left_corner.clone(), bottom_right_corner.clone());
        }

        if (!raycastResult[0]) {
            this.raycastColor = 0xFF6347;
            raycastResult = this.checkIntersectionWithObstructionsOverLine(top_lef_corner.clone(), bottom_right_corner.clone(), upper_right_corner.clone());
        }

        if (!raycastResult[0]) {
            this.raycastColor = 0xFF6347;
            raycastResult = this.checkIntersectionWithObstructionsVertically(top_lef_corner.clone());
        }

        return raycastResult;
    }

    raycastForObject(object) {
        const box = new THREE.Box3().setFromObject(object);
        var size_bb_obj = new THREE.Vector3()
        box.getSize(size_bb_obj);

        let raycastResult = this.checkIntersectionWithObstructions(size_bb_obj);
        raycastResult[0] = !raycastResult[0];

        return raycastResult;
    }

    placeObjAtCurrentMarker(object, sizeBoundingBoxObj) {
        object.position.setComponent(this.nonMovementCoordinate, this.getMarkerWithOffset().getComponent(this.nonMovementCoordinate));
            
        object.position.setComponent(this.planeOfMovement[0], this.getMarkerWithOffset().getComponent(this.planeOfMovement[0]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[0]) / 2);
        object.position.setComponent(this.planeOfMovement[1], this.getMarkerWithOffset().getComponent(this.planeOfMovement[1]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[1]) / 2);

        if(object.userData.placementType == "floor") {
            object.position.setComponent(this.nonMovementCoordinate, this.floorHeight + 0.001);
        }
    }

    moveGroupObjectAtMarker (group) {
        const position = this.getMarkerWithOffset();
        
        var groupBoundingBox = new THREE.Box3().setFromObject(group);
        var translationVector = position.sub(groupBoundingBox.min);
        group.translateX(translationVector.x);
        group.translateZ(translationVector.z);
    }

    getOverlapPercentage(item1, item2) {
        let bb1 = new THREE.Box3().setFromObject( item1 );
        let bb2 = new THREE.Box3().setFromObject( item2 );

        // determine the coordinates of the intersection rectangle
        let x_left = Math.max(bb1.min.getComponent(this.planeOfMovement[0]), bb2.min.getComponent(this.planeOfMovement[0]));
        let y_top = Math.max(bb1.min.getComponent(this.planeOfMovement[1]), bb2.min.getComponent(this.planeOfMovement[1]));
        let x_right = Math.min(bb1.max.getComponent(this.planeOfMovement[0]), bb2.max.getComponent(this.planeOfMovement[0]));
        let y_bottom = Math.min(bb1.max.getComponent(this.planeOfMovement[1]), bb2.max.getComponent(this.planeOfMovement[1]));

        if (x_right < x_left || y_bottom < y_top) {
            return 0.0;
        }
            
        // The intersection of two axis-aligned bounding boxes is always an
        // axis-aligned bounding box
        let intersection_area = (x_right - x_left) * (y_bottom - y_top);

        // compute the area of both AABBs
        let bb1_area = (bb1.max.getComponent(this.planeOfMovement[0]) - bb1.min.getComponent(this.planeOfMovement[0])) * (bb1.max.getComponent(this.planeOfMovement[1]) - bb1.min.getComponent(this.planeOfMovement[1]))
        let bb2_area = (bb2.max.getComponent(this.planeOfMovement[0]) - bb2.min.getComponent(this.planeOfMovement[0])) * (bb2.max.getComponent(this.planeOfMovement[1]) - bb2.min.getComponent(this.planeOfMovement[1]))

        // compute the intersection over union by taking the intersection
        // area and dividing it by the sum of prediction + ground-truth
        // areas - the interesection area
        let iou = intersection_area / (bb1_area + bb2_area - intersection_area)
        
        return iou
    }

    placeObjInGrid(object) {

        if (this.planeNormal != null) {
            // Place and orient object accordinng to current marker position, so that plane and object are in the same coordinates
            object.position.copy(this.getMarkerWithOffset());
            let objPosition = object.position.clone();
            object.lookAt(objPosition.add(this.planeNormal));
        }

        let box = new THREE.Box3().setFromObject(object);
        let sizeBoundingBoxObj = new THREE.Vector3()
        box.getSize(sizeBoundingBoxObj);

        this.updateOffsetForObject(sizeBoundingBoxObj);

        if (!this.canObjFitAtCurrentRow(sizeBoundingBoxObj)) {
            this.debugLog("Moving to next row.");
            this.moveMarkerToNextRow();
        }

        // Get floor hieght from current marker position for wall and ceiling items. For floor items, floor height will be updaed by raycast
        this.floorHeight = this.getMarkerWithOffset().getComponent(this.nonMovementCoordinate);

        let canPlaceObj = !this.canObjRunOutOfBounds(sizeBoundingBoxObj);
        let raycastResult = null;
        if (canPlaceObj && this.checkWallCollision) {
            raycastResult = this.raycastForObject(object);
            canPlaceObj = raycastResult[0];
        }

        // Check if overlap is allowed and object can be placed with overlap threshold catered.
        if (!canPlaceObj && this.allowedOverlapThreshold > 0 && raycastResult != null && raycastResult[1] != null && raycastResult[1] != undefined) {
            let onlyOverlappingWithSceneAssets = true;
            let intersects = raycastResult[1];
            for (let index = 0; index < intersects.length && onlyOverlappingWithSceneAssets; index++) {
                const element = intersects[index];
                if(element.object.userData.isSceneAsset == undefined || !element.object.userData.isSceneAsset) {
                    onlyOverlappingWithSceneAssets = false;
                }
            }

            if(onlyOverlappingWithSceneAssets) {
                this.placeObjAtCurrentMarker(object, sizeBoundingBoxObj);
                let overlapPercentage = this.getOverlapPercentage(object, raycastResult[1][0].object);
                if (overlapPercentage < this.allowedOverlapThreshold) {
                    canPlaceObj = true;
                    
                    console.log("Obstruction object :", raycastResult[1][0].object.name)
                    console.log("Overlap amount :", overlapPercentage);
                }
            }
        }

        // Place object at current marker coordinates
        if ( canPlaceObj == true ) {
            if (object.isGroup) {
                this.moveGroupObjectAtMarker(object, sizeBoundingBoxObj);
            } else {
                this.placeObjAtCurrentMarker(object, sizeBoundingBoxObj);
            }
            // var axesHelper = new THREE.AxesHelper( 1 );
            // axesHelper.position.set(object.position.x,object.position.y,object.position.z);
            // this.scene.add( axesHelper );

            // var axesHelper1 = new THREE.AxesHelper( 1 );
            // axesHelper1.position.set(objCenter.x + object.position.x, objCenter.y + object.position.y, objCenter.z + object.position.z);
            // this.scene.add( axesHelper1 );

            this.debugLog("Placing " + object.name + " at: " + this.getMarkerWithOffset().x + ", " + this.getMarkerWithOffset().y + ", " + this.getMarkerWithOffset().z);
        } else {
            this.debugLog("Skipping placement of " + object.name);
        }

        if (canPlaceObj == true) {
            // Update grid markers according to object dimentsions.
            this.marker.setComponent(this.planeOfMovement[0], this.getMarkerWithOffset().getComponent(this.planeOfMovement[0]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[0]));

            this.nextRowStartMarker = this.getMarkerWithOffset().getComponent(this.planeOfMovement[1]) + 0.1;
        }
        else {
            this.marker.setComponent(this.planeOfMovement[0], this.getMarkerWithOffset().getComponent(this.planeOfMovement[0]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[0]) / 10);
            if (this.getMarkerWithOffset().getComponent(this.planeOfMovement[1]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[1]) > this.nextRowStartMarker) {
                this.nextRowStartMarker = this.getMarkerWithOffset().getComponent(this.planeOfMovement[1]) + sizeBoundingBoxObj.getComponent(this.planeOfMovement[1]) / 10;
            }
        }

        return canPlaceObj;
    }
}
