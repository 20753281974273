import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import useTableFilter from "../../UseTableFilter/useTableFilter";
import DottedLoader from "../../DottedLoader";

const Actions = {
  get_customer_invited_details: "get_customer_invited_details",
};

const InvitedCustomers = ({ setInvitedSizeCount, selectedManagers }) => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [getColumnSearchProps] = useTableFilter();

  const getInvitedCustomerTableColumns = () => {
    return [
      {
        title: "Customer Email",
        dataIndex: "customer_email",
        key: "customer_email",
        ...getColumnSearchProps("customer_email"),
      },
      {
        title: "Invited By",
        dataIndex: "invited_by",
        key: "invited_by",
        ...getColumnSearchProps("invited_by"),
      },
      {
        title: "Subscription Type",
        dataIndex: "subscription_type",
        key: "subscription_type",
      },
      {
        title: "Invited On",
        dataIndex: "invited_on",
        key: "invited_on",
      },
    ];
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsTableDataLoading(true);
      try {
        const res = await axios.post(ENVIRONMENT.CUSTOMER_INVITED);
        const data = res.data;

        setDataSource(data);
        setFilteredDataSource(data); 
        setInvitedSizeCount(data.length);
      } catch (error) {
        console.error("Error fetching invited customers:", error);
      } finally {
        setIsTableDataLoading(false);
      }
    };

    fetchData();
  }, [setInvitedSizeCount]);

  useEffect(() => {
    let filteredData = dataSource;

    if (selectedManagers.length > 0) {
      filteredData = dataSource.filter(
        (item) => selectedManagers.includes(item.invited_by)
      );
    }

    setFilteredDataSource(filteredData);
    setInvitedSizeCount(filteredData.length);
  }, [selectedManagers, dataSource, setInvitedSizeCount]);

  return (
    <div className="invited-customers-container">
      {isTableDataLoading ? (
        <DottedLoader />
      ) : (
        <Table
          dataSource={filteredDataSource}
          columns={getInvitedCustomerTableColumns()}
          scroll
          style={{ overflowX: "scroll" }}
        />
      )}
    </div>
  );
};

export default InvitedCustomers;
