import React, { useImperativeHandle } from 'react';
import 'antd/dist/antd.css';
import jwtDecode from 'jwt-decode'
import {
    BookOutlined,
    ContainerOutlined,
    GatewayOutlined,
    HomeOutlined,
    ExclamationCircleOutlined,
    AuditOutlined,
    BorderOutlined,
    LineChartOutlined,
    SlidersOutlined,
    CloudDownloadOutlined,
    InteractionOutlined
} from '@ant-design/icons';

import { Layout, Menu } from 'antd';
import { withRouter } from 'react-router-dom'
import CustomHeader from '../../CustomHeader';
const { Header, Content, Sider } = Layout;


class AdminMainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            selected: props.selected
            // selected: '2'
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    checkUserProductActionAccess = () => {
        let token = localStorage.getItem("all3d_jwt_token");
        const decoded = jwtDecode(token);
        if(decoded['custom:additional_features']){
            return decoded['custom:additional_features'].includes("product_actions");
        }
        return false;
    }

    handleMenuItemChange = (e) => {
        if (e.key == 1) {
            this.props.history.push('/admin_home');
        }

        if (e.key == 2) {
            this.props.history.push('/admin_products');
        }

        if (e.key == 3) {
            this.props.history.push('/admin_rooms');
        }

        if (e.key == 4) {
            this.props.history.push('/admin_scenes');
        }

        if (e.key == 5) {
            this.props.history.push('/admin_categories');
        }

        if (e.key == 7) {
            this.props.history.push('/admin_artist_job_desk');
        }

        if (e.key == 10) {
            this.props.history.push('/admin_materials');
        }

        if (e.key == 11) {
            this.props.history.push('/admin_colors');
        }

        if (e.key == 13) {
            this.props.history.push('/complaints');
        }

        if (e.key == 14) {
            this.props.history.push('/automation_requests');
        }

        if (e.key == 16) {
            this.props.history.push('/admin_materials');
        }

        if (e.key == 18) {
            this.props.history.push('/admin_analytics');
        }

        if (e.key == 19) {
            this.props.history.push('/system_management');
        }

        if (e.key == 20) {
            this.props.history.push('/user_management')
        }
        if (e.key == 21) {
            this.props.history.push('/get_products_file');
        }
        if (e.key == 22){
            this.props.history.push('/update_entity');
        }

    }

    handleMouseOverSlider = () => {
        this.setState({
            collapsed: false,
        });
    }

    handleMouseLeaveSlider = () => {
        this.setState({
            collapsed: true,
        });
    }

    render() {
        return (
            <div>
                <Header className="header">
                    <CustomHeader toggleMethod={this.toggle}></CustomHeader>
                </Header>
                <Layout style={{ minHeight: "calc(100vh - 60px)" }}>
                    <Sider
                        trigger={null}
                        collapsible
                        collapsed={this.state.collapsed}
                        style={{ background: '#fff', zIndex: "3", margin: 0, minHeight: "calc(100vh - 55px)", position: "absolute" }}
                        onMouseEnter={this.handleMouseOverSlider}
                        onMouseLeave={this.handleMouseLeaveSlider}
                    >
                        <Menu theme="light" mode="inline" selectedKeys={[this.props.selected]}>
                            <Menu.Item key="1" onClick={this.handleMenuItemChange}>
                                <HomeOutlined style={{fontSize: 22}}/>
                                <span>Home</span>
                            </Menu.Item>
                            <Menu.Item key="2" onClick={this.handleMenuItemChange}>
                                <GatewayOutlined style={{fontSize: 22}}/>
                                <span>Products</span>
                            </Menu.Item>
                            <Menu.Item key="3" onClick={this.handleMenuItemChange}>
                                <ContainerOutlined style={{fontSize: 22}}/>
                                <span>Spaces</span>
                            </Menu.Item>
                            <Menu.Item key="16" onClick={this.handleMenuItemChange}>
                                <BorderOutlined style={{fontSize: 22}}/>
                                <span>Materials</span>
                            </Menu.Item>
                            <Menu.Item key="13" onClick={this.handleMenuItemChange}>
                                <ExclamationCircleOutlined style={{fontSize: 22}}/>
                                <span>Complaints</span>
                            </Menu.Item>
                            <Menu.Item key="7" onClick={this.handleMenuItemChange}>
                                <BookOutlined style={{fontSize: 22}}/>
                                <span>Artist Job Desk</span>
                            </Menu.Item>
                            <Menu.Item key="14" onClick={this.handleMenuItemChange}>
                                <AuditOutlined style={{fontSize: 22}}/>
                                <span>Automation Jobs</span>
                            </Menu.Item>
                            <Menu.Item key="19" onClick={this.handleMenuItemChange}>
                                <SlidersOutlined style={{fontSize: 22}}/>
                                <span>System Management</span>
                            </Menu.Item>
                            <Menu.Item key="20" onClick={this.handleMenuItemChange}>
                                <img className='anticon' style={{width: 22, height: 22}} src={require("../../../../icons/UserSettings.svg")}></img>
                                <span>User Management</span>
                            </Menu.Item>
                            <Menu.Item key="18" onClick={this.handleMenuItemChange}>
                                <LineChartOutlined style={{fontSize: 22}}/>
                                <span>Analytics</span>
                            </Menu.Item>
                            {/* <Menu.Item key="4" onClick={this.handleMenuItemChange}>
                                <BankOutlined />
                                <span>Scenes</span>
                            </Menu.Item> */}
                            {/* <Menu.Item key="5" onClick={this.handleMenuItemChange}>
                                <TagsOutlined />
                                <span>Categories</span>
                            </Menu.Item> */}
                            {/* <Menu.Item key="10" onClick={this.handleMenuItemChange}>
                                <TagsOutlined />
                                <span>Materials</span>
                            </Menu.Item> */}
                            {/* <Menu.Item key="11" onClick={this.handleMenuItemChange}>
                                <TagsOutlined />
                                <span>Colors</span>
                            </Menu.Item> */}
                            <Menu.Item key="21" onClick={this.handleMenuItemChange}>
                                <CloudDownloadOutlined style={{fontSize: 22}}/>
                                <span>Product Files</span>
                            </Menu.Item>
                            {this.checkUserProductActionAccess() ? 
                            <Menu.Item key="22" onClick={this.handleMenuItemChange}>
                                <InteractionOutlined style={{fontSize: 22}}/>
                                <span>Update Entity</span>
                            </Menu.Item> : ''}
                        </Menu>
                    </Sider>
                    <Layout className={this.props.color ? "background-snow" : ""}>
                        <Content className="layout-container" id="layout-section" style={{marginLeft: 80}}>
                            {this.props.children}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

export default withRouter(AdminMainLayout);
