import * as THREE from "three";

var Space = function( spaceName, spaceScene, sunLoadInfo = null ) {

	THREE.Object3D.call( this );
	this.type = 'Space';

	this.spaceName = spaceName;
	this.scene = spaceScene;
	this.customLighting = false;

	/**
     * Struct that contains all the required information for the sun object.
     */
	this.sun = {
		sunObj: null,
		targetObj: null,
		sunPosition: null,
		targetPosition: null,
		azimuthalAngle: null,
		polarAngle: null,
		loadInfo: sunLoadInfo,
		defaultSunPosition: null,
		defaultTargetPosition: null,
		enabled: true,
		sunName: "",
		sunTargetName: ""
	};

	/**
     * Dictionary containing all areas in space.
     */
	this.areas = {};

	/**
     * Array containing references to all walls in the space.
     */
	this.walls = [];

	/**
     * Array containing references to all floors in the space.
     */
	this.floors = [];

	/**
     * Array containing references to all ceilings in the space.
     */
	this.ceilings = [];

	/**
     * Array containing references to parents of all miscNodes in the space.
     */
	this.miscNodes = [];

	/**
     * Array containing references to all doors in the space.
     */
	this.doors = [];

	/**
     * Array containing references to all mouldings in the space.
     */
	this.mouldings = [];

	/**
     * Array containing references to all windows in the space.
     */
	this.windows = [];

	this.objectMaterials = {};

	function createMaterial (color) {
		return new THREE.MeshStandardMaterial({ color: color });
	}
	
	this.wallMat = createMaterial(0x808080);
	this.floorMat = createMaterial(0x999999);
	this.miscNodesMat = createMaterial(0x828282);
	this.doorsMat = createMaterial(0x4c4c4c);
	this.mouldingsMat = createMaterial(0xb2b2b2);
	this.windowsMat = createMaterial(0xbfbfbf);

	var scope = this;

	function getSunObject(names) {
		for (let name of names) {
			const sun = scope.scene.getObjectByName(name);
			if (sun) {
				return sun;
			}
		}
		return null;
	}

	/**
     * Create and setup light object for sun.
     */
	function setupSun() {

		const enabledSunNames = ['Sun', 'Sun_1'];
		const disabledSunNames = ['Sun_Disabled', 'Sun_1Disabled'];
		// Try to get enabled sun first
		let primarySun = getSunObject(enabledSunNames);

		if (!primarySun) {
			// No enabled sun found, get the first available disabled sun
			primarySun = getSunObject(disabledSunNames);
			scope.sun.enabled = false;  // Mark sun as disabled
		}
		scope.sun.sunObj = primarySun;
		scope.sun.sunName = scope.sun.sunObj.name;
		if (scope.sun.sunObj.name.includes('_1') || scope.sun.sunObj.name.includes('_2')) {
			scope.customLighting = true;
		}
		scope.sun.targetObj = scope.scene.getObjectByName( scope.sun.sunName + "Target" ) 
							|| scope.scene.getObjectByName( scope.sun.sunName + "_Target" );
		scope.sun.sunTargetName = scope.sun.targetObj.name;
		scope.sun.sunPosition = scope.sun.sunObj.position;
		scope.sun.targetPosition = scope.sun.targetObj.position;
		scope.sun.defaultSunPosition = scope.sun.sunObj.position.clone();
		scope.sun.defaultTargetPosition = scope.sun.targetObj.position.clone();

		let directionalLight = new THREE.DirectionalLight( 0xffffff, 0.3);

		directionalLight.castShadow = true;
		directionalLight.shadow.radius = 1;
		directionalLight.shadow.mapSize.width = 1024;
		directionalLight.shadow.mapSize.height = 1024;

		let d = 10;
		directionalLight.shadow.camera.left = -d;
		directionalLight.shadow.camera.right = d;
		directionalLight.shadow.camera.top = d;
		directionalLight.shadow.camera.bottom = -d;
		directionalLight.shadow.camera.far = 10000;
		directionalLight.shadow.bias = 0.00001;

		if ( scope.sun.loadInfo != null && !scope.customLighting  ) {

			let info = scope.sun.loadInfo;

			directionalLight.position.set( info.sun_position.x, info.sun_position.y, info.sun_position.z );
			directionalLight.target.position.set( info.target_position.x, info.target_position.y, info.target_position.z );
			scope.sun.sunPosition.copy( directionalLight.position );
			scope.sun.targetPosition.copy( directionalLight.target.position );

		} else {

			directionalLight.position.copy( scope.sun.sunPosition );
			directionalLight.lookAt( scope.sun.targetPosition );

		}

		scope.sun.sunObj = directionalLight;

	}

	/**
     * Setup space & populate space structure data in related arrays.
     */
	function setupSpace() {
		let spaceObject = scope.scene.getObjectByName("RootNode");
		if (spaceObject == null) {
			spaceObject = scope.scene?.children?.length > 1 ? scope.scene : scope.scene?.children[0];
		}

		let rootObj = spaceObject.children?.find(child => {
			const nameLowerCase = child.name.toLowerCase();
			return !nameLowerCase.includes("camera") && 
				   !nameLowerCase.includes("sun") && 
				   !nameLowerCase.includes("target");
			});
		console.log(scope.scene);
		console.log(rootObj);

		rootObj.updateMatrixWorld();

		// For background compatibility
		let oldSchemeFound = false;

		for ( var child of rootObj.children ) {

			if ( child.name.toLowerCase() == "structure" ) {

				// old scheme found
				oldSchemeFound = true;

			}

		}

		if ( oldSchemeFound ) {

			let defaultObj = new THREE.Object3D();
			defaultObj.name = "Default";

			rootObj.add( defaultObj );

			defaultObj.updateMatrix();

			let defaultChildren = rootObj.children.filter ( ( child ) => { return ( child != defaultObj ); } );

			for ( var i = 0; i < defaultChildren.length; i++ ) {
				defaultObj.add( defaultChildren[ i ] );
			}

		}

		//---------------------------------------------------------

		for ( var child of rootObj.children ) {

			let areaObj = {
				root: null,
				ceiling: null,
				floor: null,
				walls: [],
				floors: [],
				miscItemsParent: null,
				doors: []
			};

			areaObj.root = child;

			child.traverse( ( obj ) => {

				let objName = obj.name.toLowerCase();

				if( oldSchemeFound ) {
					if ( objName.toLowerCase() == "floor" ) {
						obj.castShadow = false;
						obj.receiveShadow = true;
						areaObj.floor = obj;
						scope.floors.push( obj );
					}

					if ( objName.toLowerCase() == "ceiling" ) {
						obj.castShadow = true;
						obj.receiveShadow = false;
						areaObj.ceiling = obj;
					}

				}

				else {

					if ( objName == ( child.name.toLowerCase() + '_floor' ) ) {
						obj.castShadow = false;
						obj.receiveShadow = true;
						areaObj.floor = obj;
						scope.floors.push( obj );
					}

					if ( objName == ( child.name.toLowerCase() + '_ceiling' ) ) {
						obj.castShadow = false;
						obj.receiveShadow = true;
						areaObj.ceiling = obj;
					}

				}

				if ( objName.includes( "misc" ) ) {
					areaObj.miscItemsParent = obj;
					obj.traverse(function(child){
						if (child.type == "Mesh") {
							scope.miscNodes.push( child );
						}

					});
				}

				else if ( objName.includes( 'door' ) && !objName.includes( 'outdoor' ) ) {
					areaObj.doors.push( obj );
					scope.doors.push( obj );
				}

				else if ( objName.includes( 'window' ) ) {
					scope.windows.push( obj );
				}

				if (areaObj.floor == null && (objName.includes('floor') && objName.includes(child.name.toLowerCase()) && /\d/.test(objName) == false)) {
					obj.castShadow = false;
					obj.receiveShadow = true;
					areaObj.floor = obj;
					scope.floors.push( obj );
				}

				if (areaObj.ceiling == null  && (objName.includes('ceiling') && objName.includes(child.name.toLowerCase()) && /\d/.test(objName) == false)) {
					obj.castShadow = false;
					obj.receiveShadow = true;
					areaObj.ceiling = obj;
				}


				if ( obj.isMesh ) {
					let newMat = obj.material.clone();
					obj.material = newMat;
					obj.castShadow = true;

					if ( objName.includes( 'ceiling' ) ) {
						obj.castShadow = true;
						obj.material.transparent = true;
						obj.material.opacity = 0;
						obj.material.depthWrite = false;

					}

					else if ( objName.includes( 'wall' ) ) {
						obj.castShadow = true;
						obj.receiveShadow = true;

						obj.material.side = THREE.DoubleSide;

						areaObj.walls.push( obj );
						scope.walls.push( obj );
					}
					else if ( objName.includes( 'floor' ) ) {
						
						obj.material.side = THREE.DoubleSide;
						areaObj.floors.push(obj);
					}
					else if ( objName.includes( 'moulding' )  || objName.includes( 'plinth' ) ) {
						obj.castShadow = true;
						obj.receiveShadow = true;

						obj.material.side = THREE.DoubleSide;
						scope.mouldings.push( obj );
					}
				}

			} );

			scope.areas[ child.name ] = areaObj;

			if( areaObj.ceiling != null ) {

				if( areaObj.ceiling.children.length > 0 ) {

					let highestObj = null;

					let currentMaxY = -1;
					let highestMaxY = -1;

					for ( let ceilingObj of areaObj.ceiling.children ) {

						currentMaxY = new THREE.Box3().setFromObject( ceilingObj ).max.y;

						if( highestMaxY != -1 ) {
							if ( currentMaxY > highestMaxY ) {
								highestObj = ceilingObj;
								highestMaxY = currentMaxY;
							}
						}

						else {
							highestObj = ceilingObj;
							highestMaxY = currentMaxY;
						}
						ceilingObj.userData.height = ( new THREE.Box3().setFromObject( ceilingObj ).getSize( new THREE.Vector3() ).y )
						scope.ceilings.push( ceilingObj);
					}

					areaObj.ceiling = highestObj;
					areaObj.ceiling.userData.height = ( new THREE.Box3().setFromObject( highestObj ).getSize( new THREE.Vector3() ).y );

				}

				else {
					areaObj.ceiling.userData.height = ( new THREE.Box3().setFromObject( areaObj.ceiling ).getSize( new THREE.Vector3() ).y );
					scope.ceilings.push( areaObj.ceiling );
				}
			}

		}

		setupSun();
	}

	/**
     * Properly dispose space from memory.
     */
	this.dispose = function() {
		this.traverse( function( child ) {

			if ( child.geometry ) child.geometry.dispose();
			if ( child.material ) child.material.dispose();

		} );
	};

	/**
     * Update world matrix of space.
     */
	this.updateMatrixWorld = function() {

		THREE.Object3D.prototype.updateMatrixWorld.call( this );
	};

	/**
     * Make some space area visible or invisible.
	 * @param {Object} area - Area whose state needs to be set.
	 * @param {Boolean} isVisible - Flag for visibility state.
     */
	this.setAreaVisible = function ( area, isVisible ) {
		area.root.visible = isVisible;
	};

	/**
     * Make some space area visible and hide all other space areas.
	 * @param {Object} area - Area to make visible.
     */
	this.isolateArea = function ( area ) {

		for( var temp in this.areas ) {

			if ( this.areas[temp] == area ) {

				this.areas[temp].root.visible = true;

			}

			else {

				this.areas[temp].root.visible = false;

			}
		}

	};

	/**
     * Make all space areas visible.
     */
	this.showAll = function () {

		for( var area in this.areas ) {

			this.areas[area].root.visible = true;

		}
	};

	/**
     * Check if object belongs to ceiling.
	 * @param {THREE.Mesh} obj - Object to check.
     */
	this.objectBelongsToCeiling = function ( obj ) {
        if ( this.ceilings.includes( obj ) ) {
            return true;
        }

        else if ( this.ceilings.includes( obj.parent) ) {
            return true;
        }

        return false;
    };



	/**
     * Check if object belongs to floor.
	 * @param {THREE.Mesh} obj - Object to check.
     */
    this.objectBelongsToFloor = function ( obj ) {
        if ( this.floors.includes( obj ) ) {
            return true;
        }

        else if ( this.floors.includes( obj.parent) ) {
            return true;
        }

        return false;
	};

	this.resetSun = function() {
		let scope = this;
		scope.sun.sunObj.position.copy( scope.sun.defaultSunPosition );
		scope.sun.targetObj.position.copy(scope.sun.defaultTargetPosition);
		scope.sun.sunObj.lookAt( scope.sun.defaultTargetPosition );
	}

	this.enableSolidCeiling = function() {
        for (let obj of this.ceilings) {
            obj.material.transparent = false;
			obj.material.opacity = 1;
			obj.material.depthWrite = true;
        }
	}
	
	this.hideCeiling = function(obj) {
		if (obj.isMesh) {
			obj.material.transparent = true;
			obj.material.opacity = 0;
			obj.material.depthWrite = false;
		}
		else if (obj.children) {
			for (let objChild of obj.children) {
				if (objChild.isMesh) {
					objChild.material.transparent = true;
					objChild.material.opacity = 0;
					objChild.material.depthWrite = false;
				}
			}
		}
	}

    this.disableCeiling = function() {
        for (let obj of this.ceilings) {
            this.hideCeiling(obj);
        }
    }

    this.enableTransparentCeiling = function() {
        for (let obj of this.ceilings) {
			if(!obj.name.includes("LFAArea"))
			{
				obj.material.transparent = true;
				obj.material.opacity = 0.2;
				obj.material.depthWrite = false;
			}
        }
	}

	this.enableGrayMode = function() {
		this.sun.sunObj.intensity = 0.8;
		this.enableGrayModeSettings(this.windows, this.windowsMat);
		this.enableGrayModeSettings(this.walls, this.wallMat);
		this.enableGrayModeSettings(this.floors, this.floorMat);
		this.enableGrayModeSettings(this.miscNodes, this.miscNodesMat);
		this.enableGrayModeSettings(this.doors, this.doorsMat);
		this.enableGrayModeSettings(this.mouldings, this.mouldingsMat);
	}

	this.enableGrayModeSettings = function (objects, material) {
		let scope = this;
		for (let obj of objects) {
			obj.traverse(function(child){
				if (child.type == "Mesh" && !scope.objectMaterials[child.uuid]) {	
					const originalMat = child.material;
					scope.objectMaterials[child.uuid] = {"object": child, "material": originalMat};
					child.material = material;
				}

			});
		}
	}

	this.disableGrayMode = function () {
		for (const key in this.objectMaterials) {
			if (this.objectMaterials.hasOwnProperty(key)) {
				const { object, material } = this.objectMaterials[key];
				object.material = material;
			}
		}
		this.objectMaterials = {};
		this.sun.sunObj.intensity = 0.3;
	}

	this.isCustomLighting = function() {
       return this.customLighting;
    }

	setupSpace();

}

Space.prototype = Object.assign( Object.create( THREE.Object3D.prototype ), {
	constructor: Space,
	isSpace: true
} );

export { Space };
