import React from 'react';
import { Select,Checkbox,Row,Col,Collapse,Input, Card , Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;

const PanelHeaderCheckbox = (props) => {
    return (
        <Checkbox
            checked={props.licenseChecked}
            onChange={(e) => {props.setLicenseChecked(e.target.checked)}}
            className="green-checkbox"
        >
        <span style={{color: (props.licenseChecked)?"black":"black-55", fontWeight: 600, whiteSpace:"nowrap" }}>{props.text}</span>
        </Checkbox>
    );
}

const LicenseFields = (props) => {

    let creativeCommons = props.creative_commons_text.map((ele, index) => {
        return <Row>
            <Checkbox 
            className='manrope f-12 bg-color light-blue'
            checked={(props.creativeCommon == ele.name)}
            onChange={(e) => {props.setCreativeCommon(ele.name)}}>
                <span className="manrope f-12 black-33">
                    <span>{ele.name}</span>
                    &nbsp;&nbsp;
                    <span style={{color: "#999999"}}>
                    <Tooltip title={<span className="manrope f-12 white">{ele.description}</span>}>
                        <img src={ele.icon}/>
                    </Tooltip>
                </span>
            </span>
        </Checkbox>
        </Row>
    })

    let publicLicensFields = (
        <div style={{marginTop: 24,marginBottom:100}}>
            <Checkbox
            checked={props.forSale}
            onChange={(e) => {props.changeForSale(e.target.checked)}}
            className="blue-checkbox bg-color light-blue justify-in-start">
                <span className="manrope f-14 black-14 w-600  justify-in-start">For Sale &nbsp;
                <Tooltip title={<span className='manrope f-12 white'>Only for ALL3D Usage</span>}><img src='/img/for_sale.png'/></Tooltip>&nbsp;<Tooltip title={<span className='manrope f-12 white'>Not Downloadable</span>}><img src='/img/not_downloadable.png'/></Tooltip></span>
            </Checkbox>
            {props.forSale?
            <Row>
                <Col span={12}  style={{padding:"0 6px"}}>
                    <div className='manrope f-14 black-55 w-900' style={{marginBottom: 8,marginTop: 10}}>Please enter a list price ($)</div>
                    <Input min="0" value={props.price != -1 ? props.price : ''}  onChange={props.changePrice} className="manrope f-12 black-55 library-search"
                    placeholder="Enter Price ($)" type="number"/>
                </Col>
                <Col span={12}  style={{padding:"0 6px"}}>
                    <p className="pricing-note-label manrope f-14 black-55">Note</p>
                    <div className="pricing-note-text manrope f-12 black-55">ALL3D will deduct a commission of 20% on each sale. The model will only be used on the All3D platform to create 3D virtual experiences. The model will not be downloadable through the Store so you will retain your IP rights.</div>
                </Col>
            </Row>
            :""}
            <Row>
                <Checkbox
                    checked={props.forCreativeCommon}
                    onChange={(e) => {props.changeForCreativeCommon(e.target.checked)}}
                    className="blue-checkbox bg-color light-blue justify-in-start"
                    style={{marginTop: 12}}>
                    <span style={{fontSize: 20}}>
                        <span  className="manrope f-14 black-14 w-600 justify-in-start">For Free and Creative Commons Licensing for Attribution
                        &nbsp;&nbsp;
                        <span className="manrope f-20 grey-99">
                        <Tooltip title={<span className="manrope f-12 white">
                                Learn more about Creative Common Licensing at <a target="_blank" href="https://www.flickr.com/creativecommons/">https://www.flickr.com/creativecommons/</a>
                                </span>}>
                            <InfoCircleOutlined />
                        </Tooltip>
                        </span>
                        </span>
                    </span>
                </Checkbox>
            </Row>
            {props.forCreativeCommon?
            <div style={{marginTop: 10,marginBottom: 20}}>
                {creativeCommons}
                {(props.formValidationFailed && (props.publicLicenseChecked && props.forCreativeCommon && props.creativeCommon == ''))?
                <div className="justify-space-between err-bg manrope f-12 red-error">
                    <img src="/img/error-small.png" style={{marginRight: 8}}/>
                    <span>Please select any creative common licensing option </span>
                </div>
                :""}
            </div>
            :""
            }
            {(props.formValidationFailed && (props.publicLicenseChecked && (!props.forSale && !props.forCreativeCommon)))?
             <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginTop: 10,marginBottom: 20}}>
                <img src="/img/error-small.png" style={{marginRight: 8}}/>
                <span>Please select at least one of the above options for public licensing</span>
            </div>
            :""}
        </div>
    );

    return (<div style={{padding: "0 6px", marginBottom:100}}>
        <Row className="manrope f-14 w-400 grey-77" style={{marginTop: 20,marginBottom: 20}}>
            Our tools give everyone, from individual creators to large companies and institutions, a simple, standardized way to grant copyright permissions to their creative work.
        </Row>
        <Row  className="justify-align-start" style={{marginBottom: 20, marginTop: 20}}>
    
            <Col span={12}   style={{padding:"0 6px"}}>
                <Card bodyStyle={(props.publicLicenseChecked)?{padding: 0, border:"1px solid #25d6a4"}:{padding: 0}}>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={[]}
                        expandIcon={() => <span></span>}
                        className="site-collapse-custom-collapse"
                        expandIconPosition="right"
                        activeKey={props.publicLicensePanel}>
                        <Panel header={<PanelHeaderCheckbox text={<span className="manrope f-14 black-55 w-600">Public</span>} licenseChecked={props.publicLicenseChecked} setLicenseChecked={props.changePublicLicenseChecked} />} key="1" className="site-collapse-custom-panel" id="licensing-panel">
                            <Row className="manrope f-12" style={{color:(props.publicLicenseChecked)?"black":"#777777", padding: 8}}>
                                You want to display your model in the ALL3D Store for other users to use in their lifestyle scenes and applications for free or for a price. For Free model you can choose the "Creative Commons" licensing to get Attribution. If you decide to sell the model on ALL3D, to subsidize or cover your initial cost for 3D model creation or make a profit from multiple sales, You will have to chose a list price for sale and ALL3D will take  commission on each sale of 9% excluding payment processing charges between 3%-5% of the listed price during the sale. By selecting a public option you also get analytics on how your product models are being used and how popular they are. You can also get help with marketing your physical products in digital forms.  
                            </Row>
                        </Panel>
                    </Collapse>
                </Card>
            </Col>
            <Col span={12}  style={{padding:"0px"}}>
                <Card bodyStyle={(props.privateLicenseChecked)?{padding: 0, border:"1px solid #25d6a4"}:{padding: 0}}>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={[]}
                        expandIcon={() =><span></span>}
                        className="site-collapse-custom-collapse"
                        expandIconPosition="right"
                        activeKey={props.privateLicensePanel}>
                        <Panel header={<PanelHeaderCheckbox text={<span className="manrope f-14 black-55 w-600">Private</span>} licenseChecked={props.privateLicenseChecked} setLicenseChecked={props.changePrivateLicenseChecked} />} key="1" className="site-collapse-custom-panel" id="licensing-panel">
                            <Row className="manrope f-12" style={{color: (props.privateLicenseChecked)?" black":"#777777", padding: 8}}>
                                You only want the model for your use and be displayed in your private 3D model product Library. You can choose to  share with your customers, clients and partners only after approving and paying for 3D model Creation. You do not want to sell this modal on the ALL3D store for other user to buy and subsidize your cost of 3D model creation. You do not want other users use the model in their scenes and other applications marketing digital models of your products.   
                            </Row>
                        </Panel>
                    </Collapse>
                </Card>
            </Col>
        </Row>
        {(props.formValidationFailed && (!props.publicLicenseChecked && !props.privateLicenseChecked)) ?
            <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginTop: 10,marginBottom: 20}}>
                <img src="/img/error-small.png" style={{marginRight: 8}}/>
                <span>Please select a licensing option</span>
            </div>
            :""}
            {(props.publicLicenseChecked) ?
                <span>
                    {publicLicensFields}
                </span>
                :
                ""}
    </div>
    );
}
export default LicenseFields;