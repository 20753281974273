import React from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import { Table, message, Divider, Select, Input, Space, Button} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as SmallEmptyBox } from '../../../../icons/small-empty-box.svg'
import AssignArtist from '../AssignArtist/AssignArtist';
import DottedLoader from '../../DottedLoader';
import FileConstants from '../../../../FileConstants';

const Option = { Select };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };
const unset_display = { display : 'unset !important' };

class MaterialTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    tableLayout: 'fixed',
    hasData: true,
    dataSource: null,
    reviewAction: 'hide',
    assign_to_artist: 'hide',
    upload_materials: 'hide',
    assign_to_self: 'hide',
    companyColumn:'hide',
    subscriptionColumn:'hide',
    reassign_material: 'hide',
    selectedMaterial : '-1',
    toggleCustomer: unset_display,
    toggleStatus: unset_display,
    assignmentModelVisible: false,
    artists: [],
    filter_categories: [],
    filter_customers: [],
    filter_companies: [],
    filter_artists: [],
    filter_requirements:[],
    filter_model_type: [],
    buttonLoader: false,
    loader: true
  };

  getColumns = () => [
    {
      title: 'Material Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ...this.getColumnSearchProps('name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'ALL3D ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      ...this.getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Item Id',
      dataIndex: 'brand_id',
      key: 'brand_id',
      width: '10%',
      ...this.getColumnSearchProps('brand_id'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Customer Username',
      dataIndex: 'username',
      key: 'username',
      className: this.state.toggleCustomer,
      filters: this.state.filter_customers,
      defaultFilteredValue: this.getAppliedFilterValue('username'),
      onFilter: (value, record) => record.username === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      sorter: (a, b) => a.key - b.key,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      className: this.state.companyColumn,
      filters: this.state.filter_companies,
      onFilter: (value, record) => record.company === value,
      defaultFilteredValue: this.getAppliedFilterValue('company'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Subscription',
      className: this.state.subscriptionColumn,
      dataIndex: 'subscription_name',
      key: 'subscription_name',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      sorter: (a, b) => a.key - b.key,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Artist',
      dataIndex: 'assigned_artist',
      key: 'assigned_artist',
      className: this.state.reviewAction,
      filters: this.state.filter_artists,
      defaultFilteredValue: this.getAppliedFilterValue('assigned_artist'),
      onFilter: (value, record) => record.assigned_artist === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: this.state.filter_categories,
      defaultFilteredValue: this.getAppliedFilterValue('category'),
      onFilter: (value, record) => record.category === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: this.state.filter_model_type,
      defaultFilteredValue: this.getAppliedFilterValue('type'),
      onFilter: (value, record) => record.type === value,
     render: text => <span>{text}</span>,
    },
    {
      title: 'Requirement',
      dataIndex: 'need_to_model',
      key: 'need_to_model',
      filters: this.state.filter_requirements,
      //defaultFilteredValue: this.getAppliedFilterValue('assigned_artist'),
      onFilter: (value, record) => record.need_to_model === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.reviewAction,
      render: (text, record) => (
        <span>
          <a href={'/admin_materials/' + record.id}>{this.props.state == "admin_review" ? <span>Review Material</span> : <span>View</span>}</a>
          {this.state.reassign_material != 'hide' ? 
            <span>
              <Divider type="vertical" />
              <a onClick={() => this.showAssignmentModal(record.id)}>Reassign</a>
            </span>
            : ''}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.assign_to_artist,
      render: (text, record) => (
        <span>
          <a onClick={() => this.showAssignmentModal(record.id)}>Assign to Artist</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.upload_materials,
      render: (text, record) => (
        
          <a href={'/artist_materials/' + record.id }>{this.props.state == "enqueued" ? <span>Upload</span> : this.props.state == "admin_rejected" ? <span>Fix and Upload</span> : <span>View</span>}</a>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.assign_to_self,
      render: (text, record) => (
        <React.Fragment>
          <span>
            <a href={'/material_guidelines/' + record.id }> View Details </a>
            <Divider type='vertical' />
            <a onClick={() => this.handleSelfAssignment(record.id)}>Assign to Self</a>
          </span>
        </React.Fragment>
      ),
    }
  ];

  componentDidMount = () => {
    this.handleDataSource();

    if (this.props.state == "enqueued") {
      this.loadArtistsData();
    }

    if(this.props.adminTable == true){
      this.setState({
        companyColumn:'',
        subscriptionColumn:''
      })
    }
  }

  removeRowFromTable = (model_id) => {
    this.setState({
      dataSource: this.state.dataSource.filter(item => item.id !== model_id)
    })
  }

  loadArtistsData = () => {

    axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        console.log(res.data)
        let artist_list = [];
        res.data.map(artist => {
          if (artist.is_active == true || artist.is_active == "True") {
            artist_list.push(artist);
          }
        });
        this.setState({
          artists: artist_list
        });
      });
  }

  setFilterQueryParams = (filters) => {
    const url = new URL(window.location);

    for (const [key, value] of Object.entries(filters)) {
      if(value){
        url.searchParams.set(key, value)
      }
      else if(url.searchParams.has(key)){
          url.searchParams.delete(key);
        }
    }
    window.history.replaceState({}, '', url)
  }

  handleSelfAssignment = (material_id) => {
    let payload = {
      assigned_artist : localStorage.getItem('username'),
      material_id: material_id.toString()
    }

    axios.post(ENVIRONMENT.UPDATE_MATERIAL, payload)
      .then(res => {
        console.log(res);
        this.removeRowFromTable(material_id)
        message.info('Material has been assigned to You');
      })
  }

  handleAssign = values => {

    let payload = {
      assigned_artist : values.artist_username,
      material_id: this.state.selectedMaterial.toString(),
      admin_username: localStorage.getItem('username')
    }
    this.setState({
      buttonLoader: true
    });

    axios.post(ENVIRONMENT.UPDATE_MATERIAL, payload)
      .then(res => {
        console.log(res);
        message.info('Material has been assigned to Specified Artist');
        this.setState({
          assignmentModelVisible: false,
          buttonLoader: false,
          
        }, ()=> window.location.reload());
      })
      
  }

  handleCancel = () => {
    this.setState({
      assignmentModelVisible: false
    });
  }

  showAssignmentModal = (id) => {
    this.setState({
      selectedMaterial: id,
      assignmentModelVisible: true
    });
  }

    // Search in table functionality
    getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            defaultValue={this.getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      defaultFilteredValue: this.getAppliedFilterValue(dataIndex),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      }
    });
  
    handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };

    getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
      let filteredValue = this.getAppliedFilterValue(dataIndex);
      if(!selectedKeys)
        setSelectedKeys(filteredValue)
      return filteredValue
    }
  
    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };

    getAppliedFilterValue = (filter) => {
      // return already applied filter value or null
      const url = new URL(window.location);
      let value = null;
      value = url.searchParams.get(filter)
      if(value)
        value = value.split(',')
      return value
    }


    handleFilteringOptions = (filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values,filter_requirement_values) => {
    
      let filter_categories_objects = [];
      let filter_customer_objects = [];
      let filter_company_objects = [];
      let filter_artist_objects = [];
      let filter_model_type_objects = [];
      let filter_requirement_objects=[];

      filter_categories_values.sort(function(a , b){
        return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
      });
      filter_customer_values.sort(function(a , b){
        return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
      });
      filter_requirement_values.sort(function(a , b){
        return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
      });
      filter_company_values.sort(function(a , b){
        return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
      });
      filter_artist_values.sort(function(a , b){
        return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
      });
      filter_model_type_values.sort(function(a , b){
        return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
      });
  
  
      filter_categories_values.forEach( filter_categories_value => {
        filter_categories_objects.push({
          text: filter_categories_value,
          value: filter_categories_value,
        })
      });

      filter_requirement_values.forEach(filter_requirement_value => {
        if (filter_requirement_value) {
          filter_requirement_objects.push({
            text: filter_requirement_value,
            value: filter_requirement_value,
          });
        }
      });
      
      
  
      filter_customer_values.forEach( filter_customer_value => {
        filter_customer_objects.push({
          text: filter_customer_value,
          value: filter_customer_value,
        })
      });
  
      filter_company_values.forEach( filter_company_value => {
        filter_company_objects.push({
          text: filter_company_value,
          value: filter_company_value,
        })
      });
      filter_artist_values.forEach( filter_artist_value => {
        filter_artist_objects.push({
          text: filter_artist_value,
          value: filter_artist_value,
        })
      });
      filter_model_type_values.forEach( filter_model_type_value => {
        filter_model_type_objects.push({
          text: filter_model_type_value,
          value: filter_model_type_value,
        })
      });
  
      this.setState({
        filter_categories: filter_categories_objects,
        filter_customers: filter_customer_objects,
        filter_companies: filter_company_objects,
        filter_artists: filter_artist_objects,
        filter_model_type: filter_model_type_objects,
        filter_requirements:filter_requirement_objects
      });
  
    }


  handleDataSource = () => {
    if (this.props.state === 'enqueued') {
      this.handleAssignedData("2");
    }else if (this.props.state === 'admin_rejected') {
      this.handleAssignedData("-4");
    } else if (this.props.state === 'failed_aborted') {
      this.handleAssignedData("11,12");
    } else if (this.props.state === "being_processed") {
      this.handleAssignedData("6");
    } else if (this.props.state === "admin_review") {
      this.handleAssignedData("3");
    } else if (this.props.state === "job_desk") {
      let data = [];
      let materials = this.props.job_desk_data['materials']
      for (let i = 0; i < materials.length; i++){
        data.push({
          key: i,
          id: materials[i]['id'],
          name: materials[i]['name'],
          brand_id: materials[i]['brand_id'],
          username: materials[i]['username'],
          assigned_artist: materials[i]["assigned_artist"],
          category: materials[i]['category'],
          created_on: materials[i]["created_on"],
          last_modified: materials[i]['last_modified'],
          need_to_model: FileConstants.MATERIAL_TYPE_LABELS[materials[i]['material_output']],
          type:  materials[i]['type'],
          status: materials[i]['status']
        });
      }
      this.setState({
        dataSource: data,
        toggleCustomer: 'hide',
        assign_to_self: '',
        loader: false
      })
    }
  }

  // This function is for artist job desk
  handleJobDeskData = () => {
    axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, {
      status: '2'
    })
      .then(res => {
        let parsedData = JSON.parse(res.data.body);
        console.log(parsedData);
        var data = [];
        for (let i = 1; i <= (parsedData).length; i++) {
          // Only display the unassigned materials
          if(!parsedData[i - 1]["assigned_artist"]){
            data.push({
              key: i,
              id: parsedData[i - 1]['id'],
              name: parsedData[i - 1]['name'],
              brand_id: parsedData[i - 1]['brand_id'],
              username: parsedData[i - 1]['username'],
              assigned_artist: parsedData[i - 1]["assigned_artist"],
              category: parsedData[i - 1]['category'],
              created_on: parsedData[i - 1]["created_on"],
              last_modified: parsedData[i - 1]['last_modified'],
              need_to_model: FileConstants.MATERIAL_TYPE_LABELS[parsedData[i - 1]['material_output']],
              type:  parsedData[i - 1]['type'],
              status: parsedData[i - 1]['status']
            });
          }
        }
        this.setState({
          dataSource: data,
          toggleCustomer: 'hide',
          assign_to_self: '',
          loader: false
        })
      });
  }

  handleAssignedData = (status_value) => {
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];
    let filter_requirement_values=[];
    console.log('handle',status_value)
     // username : localStorage.getItem('username'),
      // assigned_artist: localStorage.getItem('username')
    let payload = {
      status: status_value
    }

    if (this.props.artist) {
      payload = {
        status: status_value,
        assigned_artist: localStorage.getItem('username')
      }
    }
    
    axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, payload)
      .then(res => {
        console.log(res);
        let parsedData = JSON.parse(res.data.body);
        console.log(parsedData);
        if(this.props.setCount)
          this.props.setCount(parsedData.length);
        var data = [];
        for (let i = 1; i <= (parsedData).length; i++) {
          data.push({
            key: i,
              id: parsedData[i - 1]['id'],
              name: parsedData[i - 1]['name'],
              brand_id: parsedData[i - 1]['brand_id'],
              username: parsedData[i - 1]['username'],
              assigned_artist: parsedData[i - 1]["assigned_artist"],
              category: parsedData[i - 1]['category'],
              created_on: parsedData[i - 1]["created_on"],
              last_modified: parsedData[i - 1]['last_modified'],
              need_to_model: FileConstants.MATERIAL_TYPE_LABELS[parsedData[i - 1]['material_output']],
              type:  parsedData[i - 1]['type'],
              status: parsedData[i - 1]['status'],
              subscription_name : parsedData[i - 1]['subscription_display_name'],
              company : parsedData[i - 1]['company_name'],
              
          });          
          if (this.props.setCount != undefined){
            this.props.setCount(data.length);
          }
    
          let category_value = parsedData[i - 1]['category'];
          if(category_value != "" && !filter_categories_values.includes(category_value)){
            filter_categories_values.push(category_value);
          }
          
          let requirement_value = FileConstants.MATERIAL_TYPE_LABELS[parsedData[i - 1]['material_output']];
          if(requirement_value != "" && !filter_requirement_values.includes(requirement_value)){
            filter_requirement_values.push(requirement_value);
          }
          
          let customer_value = parsedData[i - 1]['username'];
          if(customer_value != "" && !filter_customer_values.includes(customer_value)){
            filter_customer_values.push(customer_value);
          }

          let company_value = parsedData[i - 1]['company_name'];
          if(company_value != "" && !filter_company_values.includes(company_value)){
            filter_company_values.push(company_value);
          }

          let artist_value = parsedData[i - 1]['assigned_artist']
          if(artist_value != "" && !filter_artist_values.includes(artist_value)){
            filter_artist_values.push(artist_value)
          }
          let type_value = parsedData[i - 1]['type']
          if(type_value != "" && !filter_model_type_values.includes(type_value)){
            filter_model_type_values.push(type_value)
          }

        }
        this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values,filter_requirement_values);


        if (!this.props.artist && status_value == 2) {
          this.setState({
            assign_to_artist: '',
            reviewAction:''
          });
        } else if(!this.props.artist && status_value == -4){
          this.setState({
            reassign_material: '',
            reviewAction: ''
          });
          this.loadArtistsData();

        } else if (!this.props.artist) {
          this.setState({
            reviewAction: ''
          })
        } else if (this.props.artist) {
          this.setState({
            upload_materials: '',
            toggleCustomer: 'hide',
            toggleStatus: 'hide'
          });
        }

        this.setState({
          dataSource: data,
          loader: false
        })
      })
  }

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource && !this.state.loader ?
          (dataSource && dataSource.length > 0) ?
          <Table {...this.state} onChange={
            (pagination, filters, sorter, extra) => {
              console.log('filters = ', filters)
              this.setFilterQueryParams(filters)
            } 
       } columns={this.getColumns()} dataSource={dataSource} />
       :
       <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <div>
            <SmallEmptyBox style={{marginTop: '50%', marginLeft: '44%'}} />
            <div>
                <span style={{fontSize: 16, color: '#555555', paddingLeft: 20}}>No Data Available</span>
            </div>
          </div>
       </div>
       : 
          <DottedLoader/>
        }
       <AssignArtist
        entity={'material'}
        handleCancel={this.handleCancel}
        buttonLoader={this.state.buttonLoader}
        handleAssign={this.handleAssign}
        assignmentModelVisible={this.state.assignmentModelVisible}
        artists={this.state.artists}
        />
      </div>
    );
  }
}

export default MaterialTable;
