import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Table, Select, Empty, Tag, Space, Card, Tooltip } from "antd";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/plots";
import moment from "moment";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import useTableFilter from "../../UseTableFilter/useTableFilter";
import FileConstants from "../../../../FileConstants";

const DATE_FORMAT = "DD/MM/YYYY";
const { Option } = Select;
const TOOLTIP_MESSAGE = "Click to View Details";

const Actions = {
  get_modeling_throughput_details: "get_modeling_throughput_details",
  get_modeling_throughput_customer_details:
    "get_modeling_throughput_customer_details",
};

const ModelingThroughput = () => {
  // ====> Modeling Throughput
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [customerSpaceModelsCount, setCustomerSpaceModelsCount] = useState(0);
  const [customerProductModelsCount, setCustomerProductModelsCount] =
    useState(0);
  const [all3dSpaceModelsCount, setAll3dSpaceModelsCount] = useState(0);
  const [all3dProductModelsCount, setAll3dProductModelsCount] = useState(0);
  const [openModelDetails, setOpenModelDetails] = useState(false);
  const [modelingCustomerDataSource, setModelingCustomerDataSource] =
    useState(null);
  const [modelType, setModelType] = useState(null);
  const [modelUserStatus, setModelUserStatus] = useState(null);
  const [userModelsCount, setUserModelsCount] = useState(0);
  const [modelingThroughputUsersList, setModelingThroughputUsersList] =
    useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format(DATE_FORMAT)
  );
  const [getColumnSearchProps] = useTableFilter();

  const onModelingCalenderChange = (date_values) => {
    if (date_values && date_values[0] && date_values[1]) {
      setStartDate(date_values[0].format(DATE_FORMAT));
      setEndDate(date_values[1].format(DATE_FORMAT));
    }
  };

  useEffect(() => {
    getModelingThroughputDetails();
  }, [startDate, endDate]);

  const getCumulativeCount = (response, modelType) => {
    let count = 0;
    let models = response.filter((item) => {
      return (
        item.type == modelType &&
        (item.value ? (count = count + item.value) : "")
      );
    });
    return count;
  };

  const getModelingThroughputDetails = () => {
    setIsChartLoading(true);
    setCustomerProductModelsCount(0);
    setCustomerSpaceModelsCount(0);
    setAll3dProductModelsCount(0);
    setAll3dSpaceModelsCount(0);
    const start = moment(moment(startDate, "DD/MM/YYYY")).format("YYYY-MM-DD");
    const end = moment(moment(endDate, "DD/MM/YYYY")).format("YYYY-MM-DD");
    const payload = {
      action: Actions["get_modeling_throughput_details"],
      start_date: start,
      end_date: end,
    };
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      const response = res.data;
      console.log("Modeling Throughput Details", response);
      setCustomerSpaceModelsCount(
        getCumulativeCount(response, "Customer Space Model")
      );
      setCustomerProductModelsCount(
        getCumulativeCount(response, "Customer Product Model")
      );
      setAll3dProductModelsCount(
        getCumulativeCount(response, "ALL3D Product Model")
      );
      setAll3dSpaceModelsCount(
        getCumulativeCount(response, "ALL3D Space Model")
      );
      setData(response);
      setIsChartLoading(false);
    });
  };

  const openModelDetailsModal = (type, status) => {
    setOpenModelDetails(true);
    getModelingThroughputCustomerDetails(type, status);
  };

  const getModelingThroughputCustomerDetails = (type, status) => {
    setModelType(type);
    setModelUserStatus(status);
    const start = moment(moment(startDate, "DD/MM/YYYY")).format("YYYY-MM-DD");
    const end = moment(moment(endDate, "DD/MM/YYYY")).format("YYYY-MM-DD");
    const payload = {
      action: Actions["get_modeling_throughput_customer_details"],
      start_date: start,
      end_date: end,
      model_type: type,
      user_status: status,
    };
    setIsTableDataLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      console.log("Modeling Throughput Customer Details", res.data);
      setUserModelsCount(res.data.length);
      setModelingCustomerDataSource(res.data);
      const modelingThroughputUsers = [
        ...new Set(res.data.map((item) => item["username"])),
      ];
      setModelingThroughputUsersList(modelingThroughputUsers);
      setIsTableDataLoading(false);
    });
  };

  const getModelingThroughputSelectedCustomerDetails = () => {
    const start = moment(moment(startDate, "DD/MM/YYYY")).format("YYYY-MM-DD");
    const end = moment(moment(endDate, "DD/MM/YYYY")).format("YYYY-MM-DD");
    const payload = {
      action: Actions["get_modeling_throughput_customer_details"],
      start_date: start,
      end_date: end,
      model_type: modelType,
      user_status: modelUserStatus,
      username: selectedCustomer,
    };
    setIsTableDataLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      console.log("Modeling Throughput Selected Customer Details", res.data);
      setUserModelsCount(res.data.length);
      setModelingCustomerDataSource(res.data);
      setIsTableDataLoading(false);
    });
  };

  useEffect(() => {
    if (selectedCustomer && selectedCustomer != "all") {
      setUserModelsCount(0);
      getModelingThroughputSelectedCustomerDetails();
    } else {
      setUserModelsCount(0);
      if (modelType && modelUserStatus) {
        getModelingThroughputCustomerDetails(modelType, modelUserStatus);
      }
    }
  }, [selectedCustomer]);

  const closeModelDetails = () => {
    setOpenModelDetails(false);
    setSelectedCustomer(null);
    setModelType("");
    setModelUserStatus("");
    setUserModelsCount(0);
  };

  const modelingCustomerTablecolumns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      ...getColumnSearchProps("company"),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Model ID",
      dataIndex: "model_id",
      key: "model_id",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            href={
              modelType == "Customer Product Model" ||
              modelType == "ALL3D Product Model"
                ? `/admin_products/` + record.model_id
                : `/admin_rooms/` + record.model_id
            }
          >
            {record.model_id}
          </a>
        </span>
      ),
      ...getColumnSearchProps("model_id"),
    },
    {
      title: "Model Name",
      dataIndex: "model_name",
      key: "model_name",
      ...getColumnSearchProps("model_name"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Date Approved",
      dataIndex: "date",
      key: "date",
    },
  ];
  // Modeling Throughput <====

  return (
    <>
      <Card
        className="no-hover section-container"
        size="small"
        title={
          <Row className="justify-space-between ">
            <Col span={12} className="justify-in-start">
              <span className="sub-heading">Modeling Throughput</span>
            </Col>
            <Col span={12} className="justify-in-end">
              <DateRangePicker
                start={startDate}
                end={endDate}
                date_format={DATE_FORMAT}
                onCalChange={onModelingCalenderChange}
              />
            </Col>
          </Row>
        }
      >
        <Row className="mb-20">
          <Col span={24}>
            <div className="chart-container">
              <Row className="justify-in-start" gutter={16}>
                <Col span={24}>
                  <Space size={[0, 16]} wrap>
                  <Tooltip title={TOOLTIP_MESSAGE}>
                    <Tag
                      color="geekblue"
                      className={
                        customerProductModelsCount > 0
                          ? "cursor-pointer"
                          : "disable-click"
                      }
                      onClick={() => {
                        openModelDetailsModal(
                          "Customer Product Model",
                          "customer"
                        );
                      }}
                    >
                      {`Customer Product Models ` + customerProductModelsCount}
                    </Tag>
                    </Tooltip>
                    <Tooltip title={TOOLTIP_MESSAGE}>
                    <Tag
                      color="red"
                      className={
                        customerSpaceModelsCount > 0
                          ? "cursor-pointer"
                          : "disable-click"
                      }
                      onClick={() => {
                        openModelDetailsModal(
                          "Customer Space Model",
                          "customer"
                        );
                      }}
                    >
                      {`Customer Space Models ` + customerSpaceModelsCount}
                    </Tag>
                    </Tooltip>
                    <Tooltip title={TOOLTIP_MESSAGE}>
                    <Tag
                      color="blue"
                      className={
                        all3dProductModelsCount > 0
                          ? "cursor-pointer"
                          : "disable-click"
                      }
                      onClick={() => {
                        openModelDetailsModal(
                          "ALL3D Product Model",
                          "all3d_assets"
                        );
                      }}
                    >
                      {`ALL3D Product Models ` + all3dProductModelsCount}
                    </Tag>
                    </Tooltip>
                    <Tooltip title={TOOLTIP_MESSAGE}>
                    <Tag
                      color="purple"
                      className={
                        all3dSpaceModelsCount > 0
                          ? "cursor-pointer"
                          : "disable-click"
                      }
                      onClick={() => {
                        openModelDetailsModal(
                          "ALL3D Space Model",
                          "all3d_assets"
                        );
                      }}
                    >
                      {`ALL3D Space Models ` + all3dSpaceModelsCount}
                    </Tag>
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
              {isChartLoading ? (
                <LoadingOutlined className="loading-center mt-20" />
              ) : data.length > 0 ? (
                <Line
                  {...FileConstants.LINE_PLOT_CONFIG(
                    data,
                    "date2",
                    "value",
                    "type"
                  )}
                  className="line-plot"
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </Col>
        </Row>
      </Card>

      {/* Modeling Throughput details Modal */}
      <Modal
        title={
          <>
            <Row>
              <Col span={10}>
                <div style={{ marginTop: 6 }}>
                  {modelType +
                    `s Summary ${
                      userModelsCount ? `(` + userModelsCount + `)` : ""
                    }`}
                </div>
              </Col>
              <Col span={4} offset={9}>
                <Select
                  placeholder="Select User"
                  size={"medium"}
                  style={{ width: "100%", marginRight: 20 }}
                  onChange={(val) => {
                    setSelectedCustomer(val);
                  }}
                  value={selectedCustomer}
                >
                  <Option
                    className="manrope f-14 black-55 select-view"
                    value="all"
                  >
                    Default:All
                  </Option>
                  {modelingThroughputUsersList
                    ? modelingThroughputUsersList.map((username) => (
                        <Option
                          className="manrope f-14 black-55 select-view"
                          value={username}
                        >
                          {username}
                        </Option>
                      ))
                    : ""}
                </Select>
              </Col>
            </Row>
          </>
        }
        maskClosable={false}
        onCancel={closeModelDetails}
        visible={openModelDetails}
        width={"70%"}
        centered={true}
        footer={null}
      >
        {isTableDataLoading ? (
          <LoadingOutlined className="loading-center" />
        ) : (
          <Table
            dataSource={modelingCustomerDataSource}
            columns={modelingCustomerTablecolumns}
            scroll
            style={{ overflowX: "scroll" }}
          />
        )}
      </Modal>
    </>
  );
};

export default ModelingThroughput;
