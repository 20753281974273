import React, { useContext } from 'react';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col, Tooltip } from 'antd';
import FileConstants from '../../../../FileConstants';
import ENVIRONMENT from '../../../../environments';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem('company_id');

const TextContainer = (props) => {
    return (<Tooltip title={<span className='manrope f-12 white'>{props.value}</span>}>
        <Col span={8} className='col-width-16'>
            <div className="manrope f-14 lh-14 grey-8c text-left">{props.name}</div>
            <div className="manrope f-16 lh-16 black-26 clamp-w-width">
                {props.value}
            </div>
        </Col>
    </Tooltip>)
}

const ProductInformation = () => {
    const { productData, product_id, modelWidth, baseVariantPlatform,
        modelHeight, modelDepth, productAssetData, performStoreAction, isStorePage } = useContext(CustomerProductContext);

    return (<Row className="basic-detail-container j-s-b">
        {productData["group_id"] &&
            <TextContainer
                name={"Group ID"}
                value={productData["group_id"]}
            />
        }
        {modelWidth != "N/A" && modelHeight != "N/A" && modelDepth != "N/A" &&
            <TextContainer
                name={"Dimensions"}
                value={<>{modelWidth == "N/A" ? "N/A" : modelWidth.toFixed(1)}"W x {modelHeight == "N/A" ? 'N/A' : modelHeight.toFixed(1)}"H x {modelDepth == "N/A" ? 'N/A' : modelDepth.toFixed(1)}"D</>}
            />
        }
        {!isStorePage && productData["category_type"] ?
            <TextContainer
                name={"Bedding Type"}
                value={<>{FileConstants.HIDDEN_CATEGORY[productData.category].map((cat) => (
                    cat == productData.category_type ? cat : ''
                ))}</>}
            />
            :
            !isStorePage && FileConstants.HIDDEN_CATEGORY[productData.category] ?
                <TextContainer
                    name={"Bedding Type"}
                    value={"White Mattress & Pillows"}
                />
                : !isStorePage && FileConstants.HIDDEN_CATEGORY[productData.parent_category] ?
                    <TextContainer
                        name={"Bedding Type"}
                        value={productData.category}
                    /> : ''}
        {productData["color_name"] &&
            <TextContainer
                name={"Color"}
                value={productData.color_name}
            />}
        {productData["similar_color"] &&
            <TextContainer
                name={"Similar Color"}
                value={productData.similar_color}
            />}
        {productData["hex_color"] &&
            <TextContainer
                name={"Hex Color"}
                value={productData.hex_color}
            />}
        {productData["pantone_color"] &&
            <TextContainer
                name={"Pantone Color"}
                value={productData.pantone_color}
            />}
        {productData["style_category"] &&
            <TextContainer
                name={"Style Category"}
                value={productData.style_category}
            />}
        {productData["product_model_type"] &&
            <TextContainer
                name={"Type"}
                value={FileConstants.PRODUCT_MODEL_TYPE[productData["product_model_type"]]}
            />}
        {(productData["materials"] && productData["materials"].length > 0) &&
            <Col span={8}>
                <div className="manrope f-14 lh-14 grey-8c text-left">
                    Materials
                </div>
                <div className="justify-in-start" style={{ display: 'inline-block' }}>
                    {productData["materials"].map((material, index) => (
                        <span className="manrope f-16 lh-16 black-26" key={index}>
                            {index > 0 ? `, ${material}` : `${material}`}
                        </span>
                    ))
                    }
                </div>
            </Col>}

        {(productData["tags"] && productData["tags"].length > 0) &&
            <Col span={8}>
                <div className="manrope f-14 grey-8c">Tags</div>
                <div className="justify-in-start" style={{ display: 'inline-block' }}>
                    {productData["tags"].map((tag, index) => (
                        <span className="manrope f-16 black-26" key={index}>
                            {index > 0 ? `, ${tag}` : `${tag}`}
                        </span>
                    ))
                    }
                </div>
            </Col>}

        {!isStorePage && productData["variant_of"] &&
            <TextContainer
                name={"Model Type"}
                value={"Variation"}
            />}
        {!isStorePage && productData["segmented"] &&
            <TextContainer
                name={"Segmented"}
                value={"Yes"}
            />}
        {!isStorePage && productData["variation_type"] &&
            <TextContainer
                name={"Variation Type"}
                value={FileConstants.variationDict(productData["variation_type"], productAssetData['need_to_model'])}
            />
        }
        {!isStorePage && (productData["variant_of"] && (CUSTOMER_USERNAME == productData['customer_username'] || productData['company_id'] == COMPANY_ID)) &&
            <Col span={8}>
                <div className="manrope f-14 lh-14 grey-8c text-left">Parent Model</div>
                <a className="manrope f-16 lh-16 blue"
                    href={ENVIRONMENT.getBaseURL(baseVariantPlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + productData["variant_of"] + '.zip'}
                    download>
                    {productData["variant_of"]}
                </a>
            </Col>}

        {!isStorePage && productData.licensing_options && productData.licensing_options.license
            &&
            <div className="license-container w-100">
                {productData.licensing_options.license == 'public' ?
                    <EyeOutlined className="eye-invisible" /> :
                    <EyeInvisibleOutlined className="eye-invisible" />}
                <p className="manrope f-14 black-33 lh-14 mb-0">This product’s license is set to {productData.licensing_options.license}.
                    {productData.licensing_options.license == 'public' ? productData.licensing_options.public_option == 'forCreativeCommon' ?
                        <Tooltip title={<span className='manrope f-12 white'>{productData.licensing_options.creative_common}</span>}>
                            <img className='not-downloadable ml-4' src={FileConstants.getLicensingIcon(productData.licensing_options.creative_common)} />
                        </Tooltip> :
                        <>
                            <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                                <img className="all3d-logo-dim ml-4" src={FileConstants.getLicensingIcon(productData.licensing_options.public_option)} />
                            </Tooltip>
                            &nbsp;<Tooltip title={<span className='manrope f-12 white'>Not Downloadable</span>}>
                                <img src='/img/not_downloadable.png' className='not-downloadable' />
                            </Tooltip>
                        </>
                        : ''}</p>
                {(CUSTOMER_USERNAME == productAssetData.customer_username) ?
                    <div onClick={() => performStoreAction(product_id, productData)} className='manrope f-14 blue hover-change lh-14 ml-4'>Change to {productData.is_store_item == 1 ? "private" : "public"}</div> : ''}
            </div>}
    </Row>
    );
}

export default ProductInformation;