import React, { useEffect, useState,useRef } from "react";
import ThreeSixtyViewer from "../../../../ThreeSixtyViewer";
import { Col, Tooltip, Image, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./SpaceComponents.scss";

const AssetPreviewCard = ({
  id,
  url,
  type,
  num,
  selectedRenderStatus,
  setDisplayDetailType,
  setSelected360,
  setSelectedImageUrl,
  name,
  length,
}) => {

  const [fullScreen,setFullScreen]= useState(null);
  const [imgFullScreen,setImgFullScreen]= useState(null);
  const [threeSixty,setThreeSixty] = useState({});
  const [autoRotate, setAutoRotate] = useState(false);
  const imgRef= useRef(null);

  const handleAutoRotateChange=(checked)=>{
    setAutoRotate(checked);
    threeSixty.toggleAutoRotate();

  }

  useEffect(() => {
    setTimeout(() => {
      if (type === "360") {
        
        const temp_arr  = new ThreeSixtyViewer(
          id,
          `loading-container-${id}`,
          url,
          true,
          false,
        );
        setThreeSixty(temp_arr)
      }
    }, [1000]);
  }, [type, id, url]);

  return (
    <Col
      span={num === 0 ? 24 : length ===2 ? 24 : 12}
      style={{
        height: num === 0 && length > 1
            ? "60%"
            : num !== 0
            ? "calc(40% - 20px - 5rem)"
            : "calc(100% - 20px - 5rem)",
        marginTop: num === 0? "0px" : "1rem",
      }}
      className={`${fullScreen && fullScreen === num + 1 ? "space-full-screen children-full": "" } ${fullScreen && fullScreen !== num + 1 ? "d-none":""}`}
    >
      <div className="h-full children-full">
        {num === 0 ? (
          <>
            <div
              id={id}
              className={`three-sixty-viewer grid-card-border`}
              style={{
                cursor: "grabbing",
                display: "block",
                position: "relative",
                paddingRight: num === 1 && length !==2 ? "16px" : "0px",
              }}
            >
              <Tooltip title={"Full Screen"} placement="left">
                  <img
                  onClick={(e) => {
                    e.stopPropagation();
                    fullScreen ? setFullScreen(null) : setFullScreen(num+1);
                  }}
                  className="full-screen-icon-360"
                    src={
                      fullScreen
                        ? "/img/fullScreenIcon-2.svg"
                        : "/img/fullScreenIcon.jpg"
                    }
                    alt="360 spin"
                    style={{ width: "30px", height: "30px" }}
                  />
              </Tooltip>

              <div className="space-card-action-container">
                <div className="rotate-toggle-div-flex">
                <span className="mr-4">Rotate</span>  <Switch size="small" checked={autoRotate} onChange={handleAutoRotateChange}/>
                </div>
                <Tooltip title={"360 Spin"} placement="left">
                  <div
                    className="spin-icon-360-flex"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDisplayDetailType("3d_preview");
                      setSelected360(name);
                    }}
                  >
                    <img
                      src={"/img/360-tn.jpg"}
                      alt="360 spin"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                </Tooltip>
              </div>
            

              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%,-50%)",
                  fontSize: "3rem",
                }}
                onClick={() => {
                  setDisplayDetailType("3d_preview");
                  setSelected360(name);
                }}
                className="loader-size"
                id={`loading-container-${id}`}
              >
                <LoadingOutlined
                  size="large"
                  spin
                  style={{ color: "#276dd7 !important" }}
                />
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "block",
              position: "relative",
              paddingRight: num === 1 && length !== 2 ? "16px" : "0px",
            }}
            className="children-full"
          >
            {type === "360" ? (
              <>
                <div
                  id={id}
                  className="three-sixty-viewer grid-card-border"
                  style={{
                    cursor: "grabbing",
                    display: "block",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Tooltip title={"Full Screen"} placement="left">
                      <img
                      onClick={() => {
                        fullScreen === num + 1 ? setFullScreen(null) : setFullScreen(num+1);
                      }}
                        src={
                          fullScreen === num + 1
                            ? "/img/fullScreenIcon-2.svg"
                            : "/img/fullScreenIcon.jpg"
                        }
                        className="full-screen-icon-360"
                        alt="360 spin"
                        style={{ width: "30px", height: "30px" }}
                      />
                  </Tooltip>
                  <div className="space-card-action-container">
                  {/* <div className="rotate-toggle-div"> */}
                  <div className="rotate-toggle-div-flex">
                    <span className="mr-4">Rotate</span>  <Switch size="small" checked={autoRotate} onChange={handleAutoRotateChange}/>
                  </div>
                  <Tooltip title={"360 Spin"} placement="left">
                    <div
                      className="spin-icon-360-flex"
                      onClick={() => {
                        setDisplayDetailType("3d_preview");
                        setSelected360(name);
                      }}
                    >
                      <img
                        src={"/img/360-tn.jpg"}
                        alt="360 spin"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  </Tooltip>
                  </div>
                  

                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      fontSize: "3rem",
                    }}
                    id={`loading-container-${id}`}
                    className="loader-size"
                    onClick={() => {
                      setDisplayDetailType("3d_preview");
                      setSelected360(name);
                    }}
                  >
                    <LoadingOutlined
                      size="large"
                      spin
                      style={{ color: "#276dd7 !important" }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    console.log('I AM CALLED')
                    setDisplayDetailType("images");
                    setSelectedImageUrl(url);
                  }}
                  className="grid-card-border grid-img-card space-mask-none"
                >
                  <img src={url}></img>
                </div>
              </>
              
            )}
          </div>
        )}
      </div>
    </Col>
  );
};

export default AssetPreviewCard;
