import React, { useState, useEffect, useContext } from 'react';
import CanvasToolbar from './CanvasToolbar';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import Annotation from './Annotation';

const CollaborateCanvas = () => {
    const { selected_action, initialLoader, sceneLoadFailed, access_level } = useContext(CollaborateContext);

    return (
        <div className={`collaborate-container ${initialLoader || sceneLoadFailed || access_level == "restricted" ? `blur-canvas` : ``}`}>
            <div id={`fabric-canvas`} className={`fabric-container ${selected_action}`}>
                <canvas id="canvas-editor"></canvas>
            </div>
            <CanvasToolbar />
            <Annotation context_for={"collaborate_tool"} />
        </div>
    );
}


export default CollaborateCanvas;
