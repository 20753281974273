import React, { useEffect, useState } from 'react'
import { ReactComponent as VisaIcon } from '../../../../icons/visa.svg'
import { Card ,Button,Collapse, Modal} from 'antd';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const ShowCardFlow = (props) => {
    const [cardInformation, setCardInformation] = useState([]);
    const [modalText, setModalText] = useState("");
    const [loader,setLoader] = useState(false)
    let cardNumber = {};

    useEffect(() => {
        setCardInformation(props.userInformation)
        cardInformation.map ( (info,index) => {
            cardNumber[index] = info.id
        })
    })

    const EditCreditCard = () => {        
        setModalText("Are you sure you want to delete this card and enter a new card?")
        props.setOpenModal(true)
    }

    const DeleteCreditCard = () => {
        setModalText("Are you sure you want to delete this card?")
        props.setOpenModal(true)
    }

    const handleOk = () => {
        setLoader(true)
        props.deleteUserPaymentType()
    }

    const handleCancel = () => {
        props.setOpenModal(false)
    }

    return (
        <div>
          <h2 className="manrope f-16 black-14" style={{marginBottom: 28}}>Your Credit Card Information is saved in our system.</h2>
            {cardInformation.map ( (info,index)  => (
                <Card className='grey-background' style={{marginTop:"5%",width:"540px"}} >  
                    <Panel showArrow={false} collapsible={false} header={
                        <div style={{display:"flex", padding:"2.5%"}}>
                            <VisaIcon style={{height:"45%",width:"10%",marginTop:"1%", marginLeft:"2%"}}/>
                            <div style={{ marginLeft:"3%",marginRight:"6%"}}>
                                <span style={{display:"flex",flex:"column", fontWeight:"bold"}}>
                                    {info.card.brand + "  **** **** **** "  + info.card.last4}
                                </span>
                                <span>
                                    Expires {" " + String(info.card.exp_month) + "/" + String(info.card.exp_year) }
                                </span>
                            </div>
                            
                            <div style={{position:"absolute", right:"3%"}}>
                                <Button id={index} ghost  type="primary" shape="square" icon={<EditOutlined />} size={"medium"} style={{position:"relative", marginRight:"10px"}} onClick={EditCreditCard}>Edit Card</Button>
                                <Button id={index} ghost danger type="primary" shape="square" icon={<DeleteOutlined />} size={"medium"} onClick={DeleteCreditCard}>Delete Card</Button>
                            </div>
                        </div>
                    }>
                    </Panel>
                </Card>
                
            ))}
        
            <Modal title="Edit / Delete Card" visible={props.openModal}
            footer={[<Button style={{borderRadius:"5px"}} key="back" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button style={{borderRadius:"5px"}} key="submit" type="primary" loading={loader} onClick={handleOk}>
                Delete
            </Button>
            ]}  onCancel={handleCancel}>
           
                    <h3 className='manrope f-14'>{modalText}</h3>
            </Modal>
        </div>
      );
};

export default ShowCardFlow;
