import React from "react";
import { Tabs, Card } from "antd";
import AdminMainLayout from '../AdminMainLayout';

import ItemsTable from '../../ItemsTable'
import RoomsTable from "../../RoomsTable";
import ScenesTable from "../../ScenesTable/ScenesTable";

const { TabPane } = Tabs;

class AdminStore extends React.Component {
  state = { size: "small",
  current_product_tab: "1",
};

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  setCurrentTab = (tabId) => {
    this.setState({
      current_product_tab: tabId
    });
  }

  render() {
    const { size } = this.state;
    var product_active_tab = "1";
    var space_active_tab = "1";
    var scene_active_tab = "1";
    var main_active_tab = "1";
    var active_tab = "p1";

    var query = window.location.search.substring(1);
    console.log('in component did mount', query)

    // Select main active tab i.e product, space, material, scanned_products
    var tab = query.split('=')[1]
    if(tab && tab.includes('sc'))
      main_active_tab = "3"; 
    else if(tab && tab.includes('s'))
      main_active_tab = "2"; // spaces
    else if(tab && tab.includes('p'))
      main_active_tab = "1"; // products

    // Select product active tab
    if(query === 'tab=p1'){
      product_active_tab = "1";
    }
    else if(query === 'tab=p2'){
      product_active_tab = "2";
    }
    else if(query === 'tab=p3'){
      product_active_tab = "3";
    }
    else if(query === 'tab=p4'){
      product_active_tab = "4";
    }

    // Select space active tab
    if(query === 'tab=s1'){
      space_active_tab = "1";
    }
    else if(query === 'tab=s2'){
      space_active_tab = "2";
    }
    else if(query === 'tab=s3'){
      space_active_tab = "3";
    } 

     // Select space active tab
     if(query === 'tab=sc1'){
      scene_active_tab = "1";
    }
    else if(query === 'tab=sc2'){
      scene_active_tab = "2";
    }
    else if(query === 'tab=sc3'){
      scene_active_tab = "3";
    } 
    const setCurrentTabURL = (tab_index) => {
      if(tab_index == 1)
        active_tab = "p" + this.state.current_product_tab;
      else if(tab_index == 2)
        active_tab = "s" + this.state.current_product_tab;
      else if(tab_index == 3)
        active_tab = "sc" + this.state.current_product_tab;
    
      this.props.history.push('/admin_store/?tab='+active_tab)
    }
    return (
      <AdminMainLayout selected={"19"}>
        <br />
        <Tabs defaultActiveKey={main_active_tab}  onChange={(e)=>{setCurrentTabURL(e)}}>
          <TabPane tab="Products" key="1">
            <Tabs defaultActiveKey={product_active_tab} size={size} tabBarStyle={{ textAlign: 'center' }} animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_store/?tab=p'+e)}}>
              <TabPane tab="In Store" key="1" >
                <ItemsTable state="store" currentTab={parseInt(space_active_tab)}/>
              </TabPane>
              <TabPane tab="Eligible for Store" key="2">
                <ItemsTable state="store_eligible" currentTab={parseInt(space_active_tab)}/>
              </TabPane>
              <TabPane tab="Requested By Customers" key="3">
                <ItemsTable state="requested_by_customers" currentTab={parseInt(space_active_tab)}/>
              </TabPane>
            </Tabs>
          </TabPane>
          <TabPane tab="Spaces" key="2">
            <Tabs defaultActiveKey={space_active_tab} size={size} tabBarStyle={{ textAlign: 'center' }} animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_store/?tab=s'+e)}}>
              <TabPane tab="In Store" key="1">
                <RoomsTable state="store" currentTab={parseInt(space_active_tab)}/>
              </TabPane>
              <TabPane tab="Eligible for Store" key="2">
                <RoomsTable state="store_eligible" currentTab={parseInt(space_active_tab)}/>
              </TabPane>
              <TabPane tab="Requested By Customers" key="3">
                <RoomsTable state="requested_by_customers" currentTab={parseInt(space_active_tab)}/>
              </TabPane>
            </Tabs>
          </TabPane>
          <TabPane tab="Scenes" key="3">
            <Tabs defaultActiveKey={scene_active_tab} size={size} tabBarStyle={{ textAlign: 'center' }} animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_store/?tab=sc'+e)}}>
              <TabPane tab="In Store" key="1">
                <ScenesTable  state="store" currentTab={parseInt(scene_active_tab)}/>
              </TabPane>

              <TabPane tab="Eligible For Store" key="2">
                <ScenesTable state="eligible_for_store" currentTab={parseInt(scene_active_tab)}/>
              </TabPane>

            </Tabs>
          </TabPane>

        </Tabs>
        
      </AdminMainLayout>
    );
  }
}

export default AdminStore;