import React, { useState, useEffect, useCallback } from 'react';
import { getProductRender } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FileConstants from '../../../../FileConstants';
// import * as CryptoJS from "crypto-js";
const google_viewer_style_fullscreen = { height: "94vh", width: "100%" };

const ProductPublicViewer = (props) => {
    const { match: { params } } = props;

    const [id, setId] = useState(params.id);
    const [type, setType] = useState(params.type);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [aspectRatio,setAspectRatio] = useState(0);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const MODEL_VIEWER_KEY = 'JKQ76HB9AB4HLNH8FK4';

    useEffect(() => {
        
        // Need to use for future
        // var encrypted = CryptoJS.AES.encrypt(params.id, "all3d");
        // var dataString = encrypted.toString().replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l');
        // var ciphertext = dataString.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
        // var decrypted = CryptoJS.AES.decrypt(ciphertext, "all3d");
        // console.log(decrypted.toString(CryptoJS.enc.Utf8))
        const search = window.location.search;
        const search_params = new URLSearchParams(search)
        const company_id = search_params.get('company_id') 

        if (params.username == undefined) {
            props.getProduct({ product_id: id, 'public': true });
        } else {
            let payload = {
                product_id: id,
                username: params.username
            }
            if(company_id)
                payload['company_id'] = company_id;
            props.getProduct(payload);
        }
    }, []);

    useEffect(() => {
        if (params.type == 'product_360') {
            resize360Spin();
        }
        if(document.getElementById('model-viewer-3d') != undefined && getGLBUrl() != ""){
            let viewer = document.getElementById('model-viewer-3d').contentWindow;
                let message = {
                    key : MODEL_VIEWER_KEY,
                    glbURL : getGLBUrl()
                }
            viewer.postMessage(message, "*");
        }
    }, [props]);

    React.useEffect(() => {
        if (params.type == 'product_360') {
            window.addEventListener('resize', resize360Spin); 
        }
        return () => {
            if (params.type == 'product_360') {
                window.removeEventListener('resize',  resize360Spin);
            }
        }
    });

    const getGLBUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'GLB');
        if (link) {
            return link.model_url
        } else {
            return ''
        }
    }

    const resize360Spin = () => {
        let element = document.getElementById('container-360');
        if (imageWidth == 0) {
            const img = new Image();
            img.onload = function() {
                let aspect_ratio = this.height/this.width;
                setAspectRatio(aspect_ratio);
                setImageHeight(this.height);
                setImageWidth(this.width);
                
                set360WidthStyle(element,aspect_ratio);
            }
            img.src = (props.productReducer.product['360'] && props.productReducer.product['360']["data-folder"]) + props.productReducer.product.product_id + '_Frame1.jpg';
        }
        if (imageWidth != 0) {
            set360WidthStyle(element);
        }
    }

    const set360WidthStyle = (element, aspect_ratio = null) => {
        let aspect = aspectRatio
        if (aspect_ratio != null) {
            aspect = aspect_ratio;
        }
        if (element != null) {
            element.style.visibility = "hidden";
            element.style.width = ((document.documentElement.clientHeight - 10) / aspect) + "px";
            
            element.style.visibility = "visible";
            handleViewChange('360D');
            forceUpdate();
        }
    }

    const handleViewChange = (type) => {
        if (type == '360D') {
            setTimeout(() => {
                window.CI360.destroy();
                window.CI360.init();
            }, 10);
        }
    }

    return (
        <div style={{height:"100vh"}}>
            {type == "product_360" ? <div id="container-360" className="container-360-spin" style={{ height: "100vh" }}>
                <div
                    className="cloudimage-360"
                    id="image-360"
                    data-folder={props.productReducer.product['360'] && props.productReducer.product['360']["data-folder"]}
                    data-filename={props.productReducer.product['360'] && props.productReducer.product['360']["data-filename"]}
                    data-amount={props.productReducer.product['360'] && props.productReducer.product['360']["data-amount"]}>

                </div>
            </div> : type == "product_3d_model" ? 
                <iframe id='model-viewer-3d' className='model-inspector-frame' src={ FileConstants.ModelInspector + props.productReducer.product.product_id}/>
            : '' }
        </div>
            
    );
}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductPublicViewer));