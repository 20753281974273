export const variant_grid_break_points = {
  screen_6: {
    breakpoint: { max: 4000, min: 1920 },
    items: 6,
  },
  screen_7: {
    breakpoint: { max: 1920, min: 1700 },
    items: 6,
  },
  screen_8: {
    breakpoint: { max: 1700, min: 1525 },
    items: 5,
  },
  screen_9: {
    breakpoint: { max: 1475, min: 1360 },
    items: 4,
  },
  screen_1: {
    breakpoint: { max: 1525, min: 1475 },
    items: 5,
  },
  screen_5: {
    breakpoint: { max: 1360, min: 1280 },
    items: 4,
  },
  screen_2: {
    breakpoint: { max: 1280, min: 1024 },
    items: 4,
  },
  screen_3: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  screen_4: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};

export const downloadNameMapping={
  top_view: "Top View",
  render: "Space Renders"
}