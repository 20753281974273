import React from "react";
import { Button, Tabs, Modal, Progress, DatePicker } from "antd";
import moment from 'moment';
import AdminMainLayout from '../AdminMainLayout';
import ENVIRONMENT from '../../../../environments';
import CustomersTable from '../CustomersTable'
import CompanyTable from "../CompanyTable/CompanyTable";
import axios from "axios";
import FileConstants from "../../../../FileConstants";
import { LoadingOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import InvoiceDatePicker from "../InvoiceDatePicker";
import { withRouter } from 'react-router-dom';


const DATE_FORMAT = 'MM/DD/YYYY';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

class AdminCustomersData extends React.Component {

  state = {
    start_date: moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT),
    end_date: moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT),
    active_companies: [],
    display_loader_modal: false,
    usage_data: [],
    exported_data: [],
    exported_data_loading: false,
    page_load: true,
    message_load: false,
    request_prices: [],
    file_blobs: {},
    selected_index: 0,
    range_picker: false,
    cancelled: false
  }
  handlebackclick=()=>{
    this.props.history.goBack();
  }


  cancelLoader = () => {
    this.setState({
      message_load: false,
      exported_data_loading: false,
      selected_index: 0,
      file_blobs: {},
      cancelled: true
    })
  }

  cancelRangePicker = () => {
    this.setState({
      range_picker: false,
      exported_data_loading: false,
      selected_index: 0,
      file_blobs: {},
      cancelled: false
    });
  }

  openRangePicker = () => {
    this.setState({
      range_picker: true,
      exported_data_loading: false,
      selected_index: 0,
      file_blobs: {},
      cancelled: false
    });
  }

  componentDidMount() {
    this.getActiveCompanies();
    this.getRequestTypePrices();
  }

  getActiveCompanies = () => {
    let payload = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }
    axios.post(ENVIRONMENT.GET_ACTIVE_COMPANIES, payload)
      .then(res => {
        console.log(res.data, ' Active Companies');
        let companies = [];
        let company_data = {};
        if (res.data) {
          res.data.map((company) => {
            if (company.company_name) {
              company_data = {
                company_name: company.company_name.replace(/[^a-zA-Z ]/g, ""),
                company_domain: company.company_domain,
                company_id: company.company_id,
                customer_username: company.customer_username
              }
              companies.push(company_data);
            }
          })
          this.setState({
            active_companies: companies,
            page_load: false
          });
        }
      });
  }

  getRequestTypePrices = () => {
    let payload = { type: 'invoice' }
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
      .then(res => {
        if (res.data) {
          let request_prices = res.data;
          this.setState({
            request_prices: request_prices
          });
        }
      })
  }

  getPriceOfRequest = (request_type) => {
    let price = this.state.request_prices.filter(request => {
      return request.request_type == request_type
    });
    if (price[0]) {
      return price[0].price;
    } else {
      return 0;
    }
  }


  exportData = (index = 0) => {
    if (this.state.cancelled) {
      this.setState({
        selected_index: 0
      })
      return;
    }
    if (index == 0) {
      this.setState({
        message_load: true,
        exported_data: [],
        range_picker: false
      });
      console.log(index)

    }
    let start_date = this.state.start_date;
    let end_date = this.state.end_date;
    if (this.state.active_companies.length > 0) {
      this.getInvoiceData(start_date, end_date, this.state.active_companies[index]);
    }
  }


  getInvoiceData = (startDate, endDate, company) => {
    this.setState({
      exported_data_loading: true
    });
    let payload = {
      action: "get_all_details",
      start_date: startDate,
      end_date: endDate,
    }
    if (company.company_id != '') {
      payload.company_id = company.company_id;
    } else {
      payload.customer_username = company.customer_username
    }

    axios.post(ENVIRONMENT.GET_INVOICES_DATA, payload)
      .then(res => {
        let usage_data = {};
        if (res.data) {
          usage_data = res.data;
          this.setState({
            usage_data: usage_data
          }, () => {
            this.createShareableData(company);
          })

        }
      }).catch(err => {
        console.log(err)
      });
  }


  createShareableData = async (company) => {
    let workbook = new ExcelJS.Workbook();
    let response = await fetch('/Resources/invoice_template.xlsx');
    let buffer = await response.arrayBuffer();
    await workbook.xlsx.load(buffer);

    let summary_template = workbook.getWorksheet("Invoice");
    let detail_template = workbook.getWorksheet("Details");

    summary_template.getCell('A8').value = company.company_name;
    summary_template.getCell('F8').value = this.state.start_date + ' - ' + this.state.end_date;
    summary_template.getCell('H5').value = this.state.start_date;
    detail_template.getCell('D7').value = company.company_name;
    detail_template.getCell('B8').value = this.state.start_date;


    let row_values = [];
    let row = null;
    let summary_values = [];
    let summary_row = null;
    let row_index = 0;
    let row_count = 0;
    let summary_last_col = summary_template.lastColumn._number;
    FileConstants.typesOfServices.map((service) => {
      if (this.state.usage_data[service] && this.state.usage_data[service].length > 0) {

        row_values = [FileConstants.typeOfDict(service).toUpperCase(), '', '', '', '', '', '', '', ''];
        row = detail_template.addRow(row_values);
        row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } };
        row.alignment = { wrapText: false, horizontal: 'left', vertical: 'middle' };
        row.font = { name: "Calibri", size: "10", color: { argb: "FFFFFF" }, bold: true };

        this.state.usage_data[service].sort((a, b) => a['request_type'].localeCompare(b['request_type']));

        this.state.usage_data[service].map((data_request, data_index) => {
          row_count = row_count + 1;
          row_index = 16 + data_index;

          row_values = [FileConstants.setRequestTypeText(data_request['request_type'], false), '', '', '', data_request['request_data'].length, '', '', '', ''];
          row = detail_template.addRow(row_values);
          row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0000' } };
          row.alignment = { wrapText: false, horizontal: 'left', vertical: 'middle' };
          row.font = { name: "Calibri", size: "10", color: { argb: "FFFFFF" }, bold: true };

          summary_values[1] = FileConstants.setRequestTypeText(data_request['request_type'], true);
          summary_values[6] = data_request['request_data'].length;
          summary_values[7] = this.getPriceOfRequest(data_request['request_type']);
          summary_values[8] = data_request['request_data'].length * this.getPriceOfRequest(data_request['request_type']);
          summary_row = summary_template.insertRow(16 + data_index, summary_values);

          for (let index = summary_last_col; index > 0; index--) {
            summary_template.getRow(row_index).getCell(index).style = {
              numFmt: index < 7 ? '' : '$#,##0.00;[Red]-$#,##0.00',
              font: {
                name: "Calibri", size: "10", color: { argb: "434343" }
              },
              fill: {
                type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' }
              },
              border: {
                top: { style: 'dotted', color: { argb: '000000' } },
                left: { style: 'dotted', color: { argb: index < 6 ? 'FFFFFF' : '000000' } },
                bottom: { style: 'dotted', color: { argb: '000000' } },
              },
              alignment: {
                wrapText: false, vertical: 'middle', horizontal: index == 1 ? 'left' : 'center'
              }
            }
          }


          data_request['request_data'].map((data) => {
            row_values = [data.id,
            data.name,
            FileConstants.requestTypeCategory(data_request['request_type']) ? data.camera_name : data.category,
            data.variation_type,
            data.last_modified_date,
            data.request_link,
            data.resolution,
            data.item_status,
            data.customer_username];

            row = detail_template.addRow(row_values);
            row.font = { name: "Calibri", size: "10", color: { argb: "434343" } }
            row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };
            row.border = {
              top: { style: 'dotted', color: { argb: '000000' } },
              left: { style: 'dotted', color: { argb: '000000' } },
              bottom: { style: 'dotted', color: { argb: '000000' } },
              right: { style: 'dotted', color: { argb: '000000' } }
            };
            row.alignment = { wrapText: false, vertical: 'middle', horizontal: 'left' }
          })
        })
      }
    })

    let endRow = summary_template.lastRow._number;
    summary_template.getRow(endRow - 2).getCell(summary_last_col).value = { formula: `SUM(H16:H${16 + row_count})` };
    summary_template.getRow(endRow - 5).getCell(summary_last_col).value = { formula: `SUM(H16:H${16 + row_count})` };


    let new_buffer = await workbook.xlsx.writeBuffer({ base64: true });
    let fileType = 'application/vnd.ms-excel'
    let new_blob = new Blob([new_buffer], { type: fileType });
    let blobs = this.state.file_blobs;

    let filename = company.company_name + "_" + this.state.start_date + '_' + this.state.end_date;
    filename = filename.replaceAll(" ", "_");
    filename = filename.replaceAll("/", "_");
    blobs[filename] = new_blob;

    this.setState({
      file_blobs: blobs
    }, () => {
      let index = this.state.active_companies.indexOf(company);
      if (index != this.state.active_companies.length - 1) {
        if (this.state.cancelled) {
          this.setState({
            selected_index: 0
          })
        } else {
          this.exportData(index + 1);
          this.setState({
            selected_index: index + 1
          })
        }

      } else {
        this.setState({
          exported_data_loading: false,
        }, () => {
          this.saveToZip();
        });
      }
    });

  }

  saveToZip = () => {
    const zip = new JSZip()
    for (const [key, value] of Object.entries(this.state.file_blobs)) {
      zip.file(key + '.xlsx', value);
    }

    zip.generateAsync({ type: "blob" })
      .then((blob) => {
        saveAs(blob, 'Invoices_' + this.state.start_date + '_' + this.state.end_date);
        this.setState({
          message_load: false
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onCalenderChange = (date_values) => {
    if (date_values) {
      if (date_values[0]) {
        this.setState({
          start_date: date_values[0].format(DATE_FORMAT)
        }, () => {
          this.getActiveCompanies()
        })
      }
      if (date_values[1]) {
        this.setState({
          end_date: date_values[1].format(DATE_FORMAT)
        }, () => {
          this.getActiveCompanies()
        })
      }
    }
  }

  render() {
    return (
      <AdminMainLayout selected={"20"}>
        {!this.state.page_load && <>
          <div style={{ display: "flex", padding: 10, justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`${process.env.PUBLIC_URL}/img/back_arrow.png`}
                alt="Arrow Back"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={this.handlebackclick}
              />
              <span className='manrope f-36 w-900'>
                Companies & Users
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                className="modal-okay square font-16"
                style={{ marginRight: 16 }}
                type="primary"
                onClick={() => window.location.href = "/customer_invite"}
              >
                Invite Customer
              </Button>
              <Button
                onClick={this.openRangePicker}
                disabled={this.state.exported_data_loading}
                className="modal-okay square font-16"
                style={{ marginLeft: 10 }}
              >
                {this.state.exported_data_loading ? (
                  <span><LoadingOutlined /> Preparing Data for Export</span>
                ) : (
                  <span>Download Combined Invoices</span>
                )}
              </Button>
            </div>
          </div>


          <Tabs defaultActiveKey={1} tabBarStyle={{ textAlign: 'center' }} >
            <TabPane tab="Companies" key="1">
              <CompanyTable />
            </TabPane>
            <TabPane tab="Users" key="2">
              <CustomersTable />
            </TabPane>
          </Tabs>
        </>}
        <InvoiceDatePicker
          onCancel={this.cancelRangePicker}
          visible={this.state.range_picker}
          exportData={this.exportData}
          onCalenderChange={this.onCalenderChange}
          startDate={this.state.start_date}
          endDate={this.state.end_date}
        />

        <Modal
          className="silo-success-modal"
          centered={true}
          closable={true}
          maskClosable={false}
          width={700}
          onCancel={this.cancelLoader}
          visible={this.state.message_load}
          bodyStyle={{ padding: 0 }}
          footer={null}>
          <div style={{ padding: "30px" }}>
            <div style={{ textAlign: "center" }}>
              <Progress
                type="circle"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={Math.round(this.state.selected_index / this.state.active_companies.length * 100)}
              />
            </div>
            <div style={{ textAlign: "center", margin: "20px auto" }}>
              <h2 className=" manrope f-16 black-00 w-600 justify-in-center">Loading Invoice Data...</h2>
              <p className=" manrope f-14 black-55">Please wait while we prepare invoices for download.</p>
            </div>
          </div>
        </Modal>

      </AdminMainLayout>
    );
  }
}

export default withRouter(AdminCustomersData);