import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import { LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Row, Button, message, Input, Tooltip } from 'antd';
import { updateCollection } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import FileConstants from '../../../../FileConstants'

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;

const NewCollection = (props) => {
    const [visible, setVisible] = useState(false);
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue, resetFields } = props.form;
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(true);
    const [continueButtonloader, setContinueButtonloader] = useState(false);
    const [collectionNames, setCollectionNames] = useState([]);
    const [error, setError] = useState('');
    const handleCancel = () => {
        setVisible(false);
        resetFields();
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let scene_state = new URLSearchParams(window.location.search).get("scene_state");
        let room_state = new URLSearchParams(window.location.search).get("room_state");
        let product_state = new URLSearchParams(window.location.search).get("product_state");

        if(onboarding != undefined){
            if (typeof(onboarding == 'string') || onboarding instanceof String) {
                onboarding = (onboarding.toLowerCase() === 'true');
            }
            if (onboarding) {
                if (scene_state != undefined && room_state != undefined && product_state != undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=15`
                    });  
                } else if (scene_state != undefined && room_state == undefined && product_state == undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added`
                    }); 
                }
                else if (scene_state != undefined && room_state !=undefined && product_state == undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added&room_state=added`
                    }); 
                } else if (scene_state != undefined && room_state == undefined && product_state != undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added&product_state=added`
                    }); 
                }
                else {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=15`
                    });                          
                }
            } else {
                if(props.reredirection_path){
                    props.history.push({
                        pathname: props.redirection_path
                    });
                } else {
                   props.history.push({
                        pathname: '/home'
                    });
                }
            }
        }
        else{
            if(props.reredirection_path){
                props.history.push({
                    pathname: props.redirection_path
                });
            } else {
               props.history.push({
                        pathname: '/home'
                    });
            }
        }
        
    }

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible === false) {
            resetFields();
        }
    }, [props.visible])

    useEffect(() => {
        getCollectionNames();
    }, []);

    const getCollectionNames = () => {
        let payload = {}
        let isMSProvider = localStorage.getItem("is_msprovider") === 'true' ? true : false;
        let managed_customer_username = localStorage.getItem('managed_customer_username');
        let customer_username_value = localStorage.getItem("username");

        if (isMSProvider && (managed_customer_username != null)) {
          payload.username = customer_username_value;
          payload.designed_for = managed_customer_username;
        } else {
          payload.username = customer_username_value;
       
        }
        let shared_entities = SHARED_ENTITIES;
        let company_id = COMPANY_ID;
        if (shared_entities != undefined && company_id != undefined) {
            if (shared_entities.includes('collection')) {
                payload.company_id = company_id
            }
        }
        console.log(payload);
        axios.post(ENVIRONMENT.GET_COLLECTION_NAMES, payload)
        .then((response) => {
            console.log(response);
            let names = []
            response.data.length > 0 && response.data.map((coll => {
                names.push(coll.name);
            }))
            setCollectionNames(names);
            setLoader(false);
        });
    }

    const resetSelectBox = () => {
        setFieldsValue({
            collection_name: getFieldValue("collection_name"),
            collection_id: null
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setContinueButtonloader(true);
                let payload = {};
                payload.username = localStorage.getItem("username");

                if (values.collection_name) {
                    payload.collection_name = values.collection_name;
                }
                if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
                    payload.designed_for = localStorage.getItem('managed_customer_username');
                }

                //adding company id in payload for company users
                let company_id = localStorage.getItem("company_id");
                if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
                    company_id = localStorage.getItem("managed_company_id");
                }
                if (company_id != undefined) {
                    payload.company_id = company_id
                }
                setError('');
                setIsLoading(true);
                axios.post(ENVIRONMENT.UPDATE_COLLECTION, payload)
                    .then((response) => {
                        console.log(response);
                        let collection_id = response.data["collection_id"]
                        props.setCollectionId(collection_id);
                        props.setModelSelectionView();
                        props.setCollectionName(values.collection_name);
                        message.success('Collection Added!');
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setContinueButtonloader(false);
                    });
            }
            else {
                setIsLoading(false);
            }
        });
    }

    return (
        <div>
            {
                loader ?
                <DottedLoader/>
                :
            <React.Fragment>
            {
                collectionNames.length == 0 ?
                <div style={{textAlign: "center"}}>
                    <img style={{marginTop: 20,width:90 }} src={require("../../../../assets/images/collection-icon-empty.svg")}></img>
                    <h3 className='manrope f-14 black-00 w-600'>You have no Collections!</h3>
                    <h3 className='manrope f-12 black-00'>Create a Collection to get started!</h3>
                </div>
                :
                ''
            }
            <div>
                <Form className="input-form" onSubmit={handleSubmit}>
                    <Form.Item name="collection_name" label={<span className='manrope f-14 black-00 w-600'>Collection Name</span>} colon={false}>
                        {getFieldDecorator('collection_name', {
                            rules: [
                                { required: true, message: 'Please input collection name' },
                                {
                                    validator: async(_, value) => {
                                    if(value){
                                        if (collectionNames.includes(value)){
                                        throw new Error('Please enter a different name as a collection with this name already exists in your library.');
                                      }
                                  }
                                  }
                                }
                            ],
                        })(
                            <Input className="manrope f-10 black-55"
                                placeholder="Please type your collection name"
                                onChange={() => resetSelectBox()}
                            />
                        )}
                    </Form.Item>
                </Form>
                <Row type="flex" style={ collectionNames.length == 0?{ marginTop: 20, justifyContent: "space-around" } : { marginTop: 20, justifyContent: "flex-end"}}>
                    <div style={{display:'flex'}}>
                    <Button className="outline-red-btn square font-12" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className="modal-okay-green square font-12" disabled={continueButtonloader} style={{ marginLeft: 10 }} onClick={handleSubmit}>
                        Continue {isLoading ? <LoadingOutlined/> : ""}
                    </Button>
                    </div>
                </Row>
                {error != '' ? <Row style={{marginTop: 10}}>
                    <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8,marginBottom: 8}}><img src="/img/error-small.png" style={{marginRight: 8,marginBottom:8}}/><span>{error}</span></div>
                </Row>: ''}
            </div>
            {
                collectionNames.length == 0 ?
                <div style={{textAlign: "center", marginTop: 20}}>
                    <p className='manrope f-12 grey-73'>
                        Space and Product Models make up a Collection. Use your Collection to layout your Scene to generate beautiful Lifestyle Images.
                    </p>
                </div>
                :
                ''
            }
            </React.Fragment>
        }
        </div>
    );

}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateCollection: (payload) => {
        dispatch(updateCollection(payload));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: 'add-collection' })(NewCollection))
)
