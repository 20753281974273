import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Empty, Tabs, Alert, Radio } from 'antd';
import './ProductGridWrapper.scss'
import ProductGrid from '../ProductGrid/ProductGrid';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext';
import ENVIRONMENT from '../../../../environments';

import { LoadingOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';

const { TabPane } = Tabs;
const IS_MSPROVIDER = FileConstants.isMSProvider;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');

const ProductGridWrapper = () => {
    const { displayedData, loader, libraryType, onProductTabChange, storeDataLoader, allStoreDataLoader, 
        initialLoadOwned, initialLoadShared,currentTabType, currentMSPTabType, currentProjectProductTabType,filtersApplied
        ,mspSharedTabType, sharedTabType, onChangeSharedTabType, checkFilteredResults } = useContext(AddProductsToSceneContext);

    const gridStyle = filtersApplied ? 'product-grid-row-filters'  : 'product-grid-row'

    const ModelsData = displayedData && displayedData.length > 0 && checkFilteredResults(displayedData).length > 0 ?
        <ProductGrid /> :
        <Col span={24} className="no-models-div" style={{ position: 'relative', height: '100%' }}>
            <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span-modal" />
        </Col>;
        
    const InfoAlert = 
        <Alert
        message="Please note that these items are not yet approved. Any changes made to the model files will automatically apply to the item used in the scene."
        type="info"
        showIcon
        />;

    const AllInfoAlert = 
        <Alert
        message="Please note that some items are not yet approved. Any changes made to the model files will automatically apply to the item used in the scene."
        type="info"
        showIcon
        />;

    const LoadingDiv = (props) => {
        return (
            <div className="swap-list-loader justify-in-center">
                <span className="manrope f-14 w-500">
                Please wait while we load all products... <LoadingOutlined />
                </span>
            </div>
        );
    }

    return (
        <>
        {
            (IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME != undefined) ?
            <Row className={displayedData.length > 0 && (libraryType == 'selectedCustomerOwned' || libraryType == 'OwnedLibrary' || libraryType == 'selectedCustomerOther' || libraryType == 'OtherLibrary' || libraryType == 'ProjectProducts') ? 'w-100' : `w-100 ${gridStyle}`}>
                {
                    (libraryType == "OtherLibrary" || libraryType == "selectedCustomerOther") ?
                    <>
                        <div style={{ width: "100vw" }}>
                            <div className="shared-models-tab manrope f-14 black-55" style={{ marginBottom: 10, marginRight: 20 }}>
                                <span className="shared-models-tab-span">
                                    Show Items:
                                </span>
                                <Radio.Group 
                                    value={libraryType == "OtherLibrary" ? sharedTabType : mspSharedTabType}
                                    onChange={(e) => onChangeSharedTabType(e, libraryType)}
                                   >
                                    <Radio value='all'>
                                        All
                                    </Radio>
                                    <Radio value='added_from_store'>
                                        Added from Store
                                    </Radio>
                                    <Radio value='shared_by_customer'>
                                        Shared
                                    </Radio>
                                    {(libraryType == "OtherLibrary" && COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID) || (libraryType == "selectedCustomerOther" && IS_MSPROVIDER && (MANAGED_COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID)) &&
                                            <Radio value='props'>
                                                Your Props
                                            </Radio>}
                                </Radio.Group>
                            </div> 
                            </div>
                            <div className={`w-100 ${gridStyle}`}>
                            {ModelsData}
                            </div>
                    </>:
                    (libraryType == 'selectedCustomerOwned' || libraryType == 'OwnedLibrary' || libraryType == 'ProjectProducts') ? 
                    initialLoadOwned || initialLoadShared ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    <div style={{ width: "100vw" }}>
                        <div className="shared-models-tab manrope f-14 black-55" style={{ marginBottom: 10, marginRight: 20 }}>
                            <Radio.Group
                                onChange={(e) => onProductTabChange(e, libraryType)}
                                value={libraryType == "OwnedLibrary" ? currentTabType : libraryType == "ProjectProducts" ? currentProjectProductTabType : currentMSPTabType}
                            >
                                <Radio value="5">Approved Models</Radio>
                                <Radio value="4">In Customer QA</Radio>
                                {libraryType == "ProjectProducts" ? '' : <Radio value="3">In Admin QA</Radio>}
                                <Radio value="-1">All</Radio>
                            </Radio.Group>
                        </div>
            
                        {((libraryType == "OwnedLibrary" && currentTabType === '5') 
                        || (libraryType == "selectedCustomerOwned" && currentMSPTabType === '5')
                        || (libraryType == "ProjectProducts" && currentProjectProductTabType === '5')) && (
                            <div className={`w-100 ${gridStyle}`}>
                                {ModelsData}
                            </div>
                        )}
            
                        {((libraryType == "OwnedLibrary" && (currentTabType === '3' || currentTabType === '4'))
                        || (libraryType == "selectedCustomerOwned" && (currentMSPTabType === '3' || currentMSPTabType === '4'))
                        || (libraryType == "ProjectProducts" && (currentProjectProductTabType === '3' || currentProjectProductTabType === '4'))) && (
                            <Row>
                                <Col span={24} style={{ marginBottom: 10 }}>
                                    {InfoAlert}
                                </Col>
                                <Col span={24} className={`w-100 ${gridStyle}`}>
                                    {ModelsData}
                                </Col>
                            </Row>
                        )}
            
                        {((libraryType == "OwnedLibrary" && currentTabType === '-1')
                        || (libraryType == "selectedCustomerOwned" && currentMSPTabType === '-1')
                        || (libraryType == "ProjectProducts" && currentProjectProductTabType === '-1')) && (
                            <Row>
                                <Col span={24} style={{ marginBottom: 10 }}>
                                    {AllInfoAlert}
                                </Col>
                                <Col span={24} className={`w-100 ${gridStyle}`}>
                                    {ModelsData}
                                </Col>
                            </Row>
                        )}
                    </div>
                    : 
                    storeDataLoader ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    (displayedData && displayedData.length > 0 && checkFilteredResults(displayedData).length > 0 > 0 ?
                        <>
                            <ProductGrid />
                            {(allStoreDataLoader && libraryType == 'store') && <LoadingDiv/>}
                        </> :
                    <Col span={24} className="no-models-div">
                        <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span-modal" />
                    </Col>)
                }
            </Row> :
            <Row className={(libraryType == 'OtherLibrary') ? 'w-100' : `w-100 ${gridStyle}`}>
                {
                (libraryType == "OtherLibrary") ?
                <>
                    <div style={{ width: "100vw" }}>
                        <div className="shared-models-tab manrope f-14 black-55">
                            <span className="shared-models-tab-span">
                                Show Items:
                            </span>
                            <Radio.Group 
                                value={libraryType == "OtherLibrary" ? sharedTabType : mspSharedTabType}
                                onChange={(e) => onChangeSharedTabType(e, libraryType)}
                                >
                                <Radio value='all'>
                                    All
                                </Radio>
                                <Radio value='added_from_store'>
                                    Added from Store
                                </Radio>
                                <Radio value='shared_by_customer'>
                                    Shared
                                </Radio>
                            </Radio.Group>
                        </div> 
                        </div>
                        <div className={`w-100 ${gridStyle}`}>
                        {ModelsData}
                        </div>
                    </>: 
                    (libraryType == 'selectedCustomerOwned' || libraryType == 'OwnedLibrary' || libraryType == 'OtherLibrary') ? 
                    initialLoadOwned || initialLoadShared ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    (displayedData && displayedData.length > 0 && checkFilteredResults(displayedData).length > 0 > 0 ?
                    <ProductGrid /> :
                    <Col span={24} className="no-models-div">
                        <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span-modal" />
                    </Col>) :
                    storeDataLoader ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    (displayedData && displayedData.length > 0 && checkFilteredResults(displayedData).length > 0 > 0 ?
                        <>
                            <ProductGrid />
                            {(allStoreDataLoader && libraryType == 'store') && <LoadingDiv/>}
                        </> :
                    <Col span={24} className="no-models-div">
                        <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span-modal" />
                    </Col>)
                }
            </Row>
        }
        </>
    );

}

export default ProductGridWrapper;