import React, { useEffect, useMemo, useState } from "react";
import { Collapse, Col, Row } from "antd";
import ENVIRONMENT from "../../../../environments";
import { withRouter } from "react-router-dom";
import _ from "lodash"; // Import the entire lodash library
import axios from "axios";
import "./SpaceComponents.scss";
import SpaceVariantCard from "./SpaceVariantCard";
import AddEntityToProject from "../AddEntityToProject/AddEntityToProject";
import StoreActions from "./StoreAction";

const { Panel } = Collapse;

const CUSTOMER_USERNAME = localStorage.getItem("username");
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const IS_MS_PROVIDER =
  localStorage.getItem("is_msprovider") === "true" ? true : false;

const SpaceVariants = (props) => {
  const [data, setData] = useState(null);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [selectedSpaceId, setselectedSpaceId] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [removeFromStore, setRemoveFromStore] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [sellLoader, setSellLoader] = useState(false);
  const [sellVisible, setSellVisible] = useState(false);
  const [price, setPrice] = useState(0);

  const onChangePrice = (e) => {
    console.log(e.target.value);
    setPrice(e.target.value);
  };

  const getRoomPayload = (
    current_username,
    current_company_id,
    shared_entities,
    is_customer_data = false
  ) => {
    let payload = {
      required_fields: [
        "room_id",
        "room_name",
        "brand_id",
        "category",
        "dimensions",
        "customer_username",
        "model_status",
        "thumbnail",
        "last_modified_stamp",
        "variant_of",
        "is_store_item",
        "company_id",
        "style",
        "platform",
      ],
    };

    payload["order_by"] = "last_modified_stamp desc";

    let filter_string = "";
    let customer_username =
      "customer_username__exact='" + current_username + "'";
    let company_id = "";
    let model_status = "model_status__in=[5]";
    let is_hidden = "is_hidden__not=true";
    let variation_condition =
      "(variant_of__exact=" +
      props?.match?.params?.id +
      "||" +
      "immediate_parent_variant__exact=" +
      props?.match?.params?.id +
      ")";

    if (
      shared_entities != undefined &&
      current_company_id &&
      shared_entities?.split("_").includes("room")
    ) {
      company_id =
        "company_id__exact=" +
        current_company_id +
        "||additional_company_ids__contains='" +
        current_company_id +
        "'";
      filter_string =
        "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")";
    } else {
      if (IS_MS_PROVIDER && !is_customer_data) {
        company_id = "company_id__isnull=true";
        let requested_for = "requested_for__isnull=true";
        filter_string =
          "(" +
          company_id +
          "&&" +
          customer_username +
          ")&&(" +
          is_hidden +
          ")&&(" +
          requested_for +
          ")";
      } else {
        filter_string = "(" + customer_username + "&&" + is_hidden + ")";
      }
    }
    filter_string =
      filter_string + "&&(" + model_status + ")&&" + variation_condition;
    filter_string = filter_string;

    payload["filter_string"] = filter_string;
    payload["pagination_filters"] = {
      limit: 8,
      offset: 0,
    };

    return payload;
  };

  const payload = useMemo(
    () => getRoomPayload(CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES),
    [CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES]
  );

  const fetchRoomData = (payload) => {
    axios.post(ENVIRONMENT.LIST_ROOMS, payload).then((res) => {
      if (res.data) {
        setData(res.data);
      }
    });
  };

  const cancelSellModal = () => {
    setSellVisible(false);
    setSellLoader(false);
  };

  const performStoreAction = () => {
    //remove item from store if
    if (selectedSpace?.is_store_item == 1) {
      setRemoveFromStore(true);
    } else {
      setSellVisible(true);
      setSellLoader(false);
    }
  };

  useEffect(() => {
    fetchRoomData(payload);
  }, [payload]);

  return (
    <>
      {data?.length > 0 ? (
        <Row>
          <Col span={24}>
            <div className="display-flex j-s a-c w-100 mb-30">
              <h3 className="mb-0 manrope f-24 black-26">Space Variants</h3>
            </div>
          <Row>
          {data?.map((spaceVariant) => (
              <SpaceVariantCard
                spaceVariant={spaceVariant}
                projects={props.userProjects}
                setselectedSpaceId={setselectedSpaceId}
                setSelectedSpace={setSelectedSpace}
                setProjectModalVisible={setProjectModalVisible}
                performStoreAction={performStoreAction}
                optionsEnabled
              />
            ))}
            </Row>
            {props.userProjects ? (
              <AddEntityToProject
                entityId={selectedSpaceId}
                entityType="Room"
                modalVisible={projectModalVisible}
                setModalVisible={setProjectModalVisible}
                projects={props.userProjects}
              />
            ) : (
              <></>
            )}
            <StoreActions
              is_store_item={selectedSpace?.is_store_item}
              setRemoveFromStore={setRemoveFromStore}
              setSellLoader={setSellLoader}
              setRemoveLoader={setRemoveLoader}
              setSellVisible={setSellVisible}
              removeFromStore={removeFromStore}
              removeLoader={removeLoader}
              sellVisible={sellVisible}
              price={price}
              sellLoader={sellLoader}
              cancelSellModal={cancelSellModal}
              roomDetails={selectedSpace}
              room_id={selectedSpace?.room_id}
              onChangePrice={onChangePrice}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(SpaceVariants);
