

import * as THREE from "three";
import { WallMarker } from '../WallMarker/WallMarker.js';

export default class WallGrid {

    planeNormal = null;
    intersectableObjects = null;

    itemOfInterest = '-1';
    raycastColor = 0xff0000;

    shouldInvertNormals = true;
    allowedOverlapThreshold = 0;

    wallMarker = null;

    _unit = {
        X: new THREE.Vector3( 1, 0, 0 ),
        Y: new THREE.Vector3( 0, 1, 0 ),
        Z: new THREE.Vector3( 0, 0, 1 ),
        aX: new THREE.Vector3( -1, 0, 0 ),
        aY: new THREE.Vector3( 0, -1, 0 ),
        aZ: new THREE.Vector3( 0, 0, -1 ),
	};

    constructor(plane, offset, raycaster, scene,  isInside = false, checkWallCollision = false, useDynamicOffset = false, planeNormal) {
        this.raycaster = raycaster;
        this.scene = scene;
        this.plane = plane;
        this.checkWallCollision = checkWallCollision;
        this.offset = offset;
        this.isInside = isInside;
        this.useDynamicOffset = useDynamicOffset;
        this.planeNormal = planeNormal;

        this.bb = new THREE.Box3();
        if (Array.isArray(this.plane)) {
            this.plane.forEach(element => {
                this.bb.expandByObject(element);
            });
        }
        else {
            this.bb.setFromObject( plane );
        }

        this.bbSize = new THREE.Vector3();
        this.bb.getSize(this.bbSize);

        this.wallMarker = new WallMarker();
        this.wallMarker.setForWall(this.plane, this.planeNormal);
        this.resetMarker();
    }

    resetMarker() {
        this.wallMarker.resetPosition();
        this.wallMarker.moveHorizontally(this.offset.x);
    }

    debugLog(log) {
        if(!Array.isArray(this.plane) && this.plane.name.toString().toLowerCase().includes( this.itemOfInterest.toLowerCase())) {
            console.log(log);
        }
    }

    drawRayCastGizmo() {
        if(!Array.isArray(this.plane) && this.plane.name.toString().toLowerCase().includes( this.itemOfInterest.toLowerCase())) {
            this.scene.add(new THREE.ArrowHelper(this.raycaster.ray.direction, this.raycaster.ray.origin, this.raycaster.far, this.raycastColor) );
        }
    }

    drawBoundingBox() {
        let boxHelper = new THREE.BoxHelper( this.plane, 0xffff00 );
        this.scene.add( boxHelper );
    }

    setIntersectableObjects(intersectableObjects) {
        this.intersectableObjects = intersectableObjects;
    }

    setInvertNormalsFlag(shouldInvertNormals) {
        this.shouldInvertNormals = shouldInvertNormals;
    }

    setAllowedOverlapThreshold(threshold) {
        this.allowedOverlapThreshold = threshold;
    }

    getIntersectableObjects() {
        return this.intersectableObjects;
    }

    getMarker() {
        let retVal = new THREE.Vector3();
        retVal.add(this.wallMarker.position);
        return retVal;
    }

    canFitObj() {
        let markerWithOffset = this.getMarker();
        let newY = markerWithOffset.y + 0.01;
        if(this.bb.containsPoint(new THREE.Vector3(markerWithOffset.x, newY, markerWithOffset.z))) {
            return true;
        }
        else {
            return false;
        }
    }

    canContainObj(object) {
        let objSize = object.userData.size;

        if(objSize.y <= this.bbSize.y) {
            return true;
        }

        return false;
    }

    // Is object running out of bounds at current position. Only the lower corners of the object are checked here, 
    // the object is allowed to go out of bounds vertically up.
    canObjRunOutOfBounds(object) {

        // Get size of wall in local z direction of object
        let sizeArr = this.bbSize.toArray();
        let wallZOffset = Math.min(...sizeArr);

        // Move item towards wall so that corners c1 and c2 are inside wall bounds
        object.translateOnAxis( this._unit.aZ, wallZOffset / 2 );

        let pvb = object.userData.pvb;
        object.updateMatrixWorld();
        let corners = pvb.getCorners();

        let containsObj = false;
        if(this.bb.containsPoint(corners.c1) && this.bb.containsPoint(corners.c2)) {
            containsObj = true;
        }

        // Reset to original position
        object.translateOnAxis( this._unit.Z, wallZOffset / 2 );
        
        return !containsObj;
    }

    isAncestor ( ancestor, decendant ) {
        while ( decendant.parent != this.scene ) {
            if ( decendant.parent == ancestor ) {
                return true;
            }
            decendant = decendant.parent;
        }

        return false;
    }

    isFloorObj(object){
        if (object == this.plane || this.isAncestor(this.plane, object)) {
            return true;
        }

        return false;
    }

    isCeilingObj(object){
        if ( object == this.ceilingObj || this.isAncestor(this.ceilingObj, object)) {
            return true;
        }

        return false;
    }

    didDirectIntersectFloor() {
        let intersects = this.raycaster.intersectObjects( this.intersectableObjects, true );

        this.debugLog(intersects);
        this.drawRayCastGizmo();

        if (this.ceilingObj == null ||  (this.ceilingObj != null && intersects.length > 0 && this.isCeilingObj(intersects[0].object))) {
            // Remove ceiling from results
            intersects = intersects.filter( ( item ) => {
                return !( this.isCeilingObj(item.object) ) ;
            } );

            let floorIndex = 0;
            if(intersects.length >= floorIndex + 1 && this.isFloorObj(intersects[floorIndex].object) ){
                return [true, intersects];
            }
        }

        if (intersects.length > 0) {
            return [false, intersects];
        }

        return [false, null];
    }

    updateRayCastObject(origin, terminal) {
        let directionVector = new THREE.Vector3();
        directionVector.subVectors(terminal, origin).normalize();
        let near = 0;                               
        let far = origin.distanceTo(terminal);      // far = distance from origin to terminal   
        
        this.raycaster.near = near;
        this.raycaster.far = far;
        this.raycaster.set(origin, directionVector);
    }

    checkIntersectionWithFloor(object) { 
        let pvb = object.userData.pvb;
        object.updateMatrixWorld();
        let corners = pvb.getCorners();

        let raycastOffset = pvb.halfDepth * 3.0;
        let directedOffset = new THREE.Vector3().copy(this.planeNormal);
        directedOffset.multiplyScalar(raycastOffset);

        let raycastPoint = new THREE.Vector3();
        raycastPoint.copy(corners.c1);
        raycastPoint.add(directedOffset);

        let directionVector = new THREE.Vector3();
        directionVector.subVectors(corners.c1, raycastPoint).normalize();

        this.raycaster.far = raycastOffset * 2;
        this.raycaster.set(raycastPoint, directionVector);

        // Raycast at bottom-left corner
        this.raycastColor = 0xff0000;
        let raycastResult = this.didDirectIntersectFloor(); 
        if (!raycastResult[0]) {
            return raycastResult;
        }
        else {
            // Raycast at bottom-right corner
            raycastPoint.copy(corners.c2);
            raycastPoint.add(directedOffset);
            this.raycaster.set(raycastPoint, directionVector);

            this.raycastColor = 0xFFFF00;
            let raycastResult = this.didDirectIntersectFloor(true); 
            if (!raycastResult[0]) {
                return raycastResult;
            }
            else {
                // Raycast at top-right corner
                raycastPoint.copy(corners.c6);
                raycastPoint.add(directedOffset);
                this.raycaster.set(raycastPoint, directionVector);

                this.raycastColor = 0x0000FF;
                let raycastResult = this.didDirectIntersectFloor(true); 
                if (!raycastResult[0]) {
                    return raycastResult;
                }
                else {
                    // Raycast at top-left corner
                    raycastPoint.copy(corners.c5);
                    raycastPoint.add(directedOffset);
                    this.raycaster.set(raycastPoint, directionVector);

                    this.raycastColor = 0x000000;
                    let raycastResult = this.didDirectIntersectFloor(true); 
                    if (!raycastResult[0]) {
                        return raycastResult;
                    }
                    else {
                        // If no intersection was found at any corner of the objects bounding box.
                        return [true, null];
                    }
                }
            }
        }
    }

    checkIntersectionWithObstructionsOverLine(origin, terminalLineStart, terminalLineEnd) {
        for (let alpha = 0; alpha <= 1; alpha = alpha + 0.2) {
            this.updateRayCastObject(origin, terminalLineStart);
            let intersects = this.raycaster.intersectObjects( this.intersectableObjects, true );

            this.debugLog(intersects);
            this.drawRayCastGizmo();

            // Remove the mesh for this wall & ceiling from results
            intersects = intersects.filter( ( item ) => {
                return !( this.isCeilingObj(item.object) || this.plane ) ;
            } );

            if (intersects.length > 0) {
                return [true, intersects];
            }

            terminalLineStart.lerp(terminalLineEnd, alpha);
        }

        return [false, null];
    }

    checkIntersectionWithObstructions(object) {

        let pvb = object.userData.pvb;
        object.updateMatrixWorld();
        let corners = pvb.getCorners();

        this.raycastColor = 0x808080;

        let raycastResult = this.checkIntersectionWithObstructionsOverLine(corners.c5.clone(), corners.c6.clone() , corners.c2.clone());
        if (!raycastResult[0]) {
            this.raycastColor = 0xFF6347;
            raycastResult = this.checkIntersectionWithObstructionsOverLine(corners.c5.clone(), corners.c2.clone(), corners.c1.clone());
        }

        if (!raycastResult[0]) {
            this.raycastColor = 0xFF6347;
            raycastResult = this.checkIntersectionWithObstructionsOverLine(corners.c1.clone(), corners.c4.clone(), corners.c8.clone());
        }

        return raycastResult;
    }

    raycastForObject(object) {
        let raycastResult = this.checkIntersectionWithFloor(object);
        if (raycastResult[0]) {
            raycastResult = this.checkIntersectionWithObstructions(object);
            raycastResult[0] = !raycastResult[0];
        }
        return raycastResult;
    }

    placeObjAtCurrentMarker(object, getFinalPositionFromRaycast = false) {

        let pvb = object.userData.pvb;

        // console.log("Position before: ", this.getMarker())

        // Move marker to object position
        this.wallMarker.moveHorizontally(pvb.halfWidth);
        this.wallMarker.moveVertically(pvb.halfHeight + this.offset.y);

        if(getFinalPositionFromRaycast) {
            let sizeArr = this.bbSize.toArray();
            let wallZOffset = Math.min(...sizeArr);

            // For handling inaccurate large value for diagonal walls.
            if (wallZOffset > 0.5) {
                wallZOffset = 0.5;
            }

            let raycastOffset = (pvb.halfDepth * 2) + wallZOffset * 2;
            let directedOffset = new THREE.Vector3().copy(this.planeNormal);
            directedOffset.multiplyScalar(raycastOffset);

            let raycastPoint = new THREE.Vector3();
            raycastPoint.copy(this.getMarker());
            raycastPoint.add(directedOffset);

            let directionVector = new THREE.Vector3();
            directionVector.subVectors(this.getMarker(), raycastPoint).normalize();

            this.raycaster.far = raycastOffset * 2;
            this.raycaster.set(raycastPoint, directionVector);

            let raycastResult = this.didDirectIntersectFloor(); 
            if (raycastResult[0]) {
                let finalPosition = raycastResult[1][0].point;
                object.position.copy(finalPosition);
            }
            else {
                object.position.copy(this.getMarker());
            }
        }
        else {
            object.position.copy(this.getMarker());
        }

        // Revert marker to original position
        this.wallMarker.moveHorizontally(-(pvb.halfWidth));
        this.wallMarker.moveVertically(-(pvb.halfHeight + this.offset.y));

        // console.log("Position after: ", this.getMarker())
    }

    getOverlapPercentage(item1, item2) {
        let bb1 = new THREE.Box3().setFromObject( item1 );
        let bb2 = new THREE.Box3().setFromObject( item2 );

        // determine the coordinates of the intersection rectangle
        let x_left = Math.max(bb1.min.getComponent(this.planeOfMovement[0]), bb2.min.getComponent(this.planeOfMovement[0]));
        let y_top = Math.max(bb1.min.getComponent(this.planeOfMovement[1]), bb2.min.getComponent(this.planeOfMovement[1]));
        let x_right = Math.min(bb1.max.getComponent(this.planeOfMovement[0]), bb2.max.getComponent(this.planeOfMovement[0]));
        let y_bottom = Math.min(bb1.max.getComponent(this.planeOfMovement[1]), bb2.max.getComponent(this.planeOfMovement[1]));

        if (x_right < x_left || y_bottom < y_top) {
            return 0.0;
        }
            
        // The intersection of two axis-aligned bounding boxes is always an
        // axis-aligned bounding box
        let intersection_area = (x_right - x_left) * (y_bottom - y_top);

        // compute the area of both AABBs
        let bb1_area = (bb1.max.getComponent(this.planeOfMovement[0]) - bb1.min.getComponent(this.planeOfMovement[0])) * (bb1.max.getComponent(this.planeOfMovement[1]) - bb1.min.getComponent(this.planeOfMovement[1]))
        let bb2_area = (bb2.max.getComponent(this.planeOfMovement[0]) - bb2.min.getComponent(this.planeOfMovement[0])) * (bb2.max.getComponent(this.planeOfMovement[1]) - bb2.min.getComponent(this.planeOfMovement[1]))

        // compute the intersection over union by taking the intersection
        // area and dividing it by the sum of prediction + ground-truth
        // areas - the interesection area
        let iou = intersection_area / (bb1_area + bb2_area - intersection_area)
        
        return iou
    }

    placeObjInGrid(object) {

        if (this.planeNormal != null) {
            // Place and orient object accordinng to current marker position, so that plane and object are in the same coordinates
            object.position.copy(this.getMarker());
            let objPosition = object.position.clone();
            object.lookAt( objPosition.add ( this.planeNormal ) );
        }


        this.placeObjAtCurrentMarker(object, true);

        let canPlaceObj = !this.canObjRunOutOfBounds(object);
        let raycastResult = null;
        if (canPlaceObj && this.checkWallCollision) {
            raycastResult = this.raycastForObject(object);
            canPlaceObj = raycastResult[0];
        }

        // Check if overlap is allowed and object can be placed with overlap threshold catered.
        // if (!canPlaceObj && this.allowedOverlapThreshold > 0 && raycastResult != null && raycastResult[1] != null && raycastResult[1] != undefined) {
        //     let onlyOverlappingWithSceneAssets = true;
        //     let intersects = raycastResult[1];
        //     for (let index = 0; index < intersects.length && onlyOverlappingWithSceneAssets; index++) {
        //         const element = intersects[index];
        //         if(element.object.userData.isSceneAsset == undefined || !element.object.userData.isSceneAsset) {
        //             onlyOverlappingWithSceneAssets = false;
        //         }
        //     }

        //     if(onlyOverlappingWithSceneAssets) {
        //         this.placeObjAtCurrentMarker(object, sizeBoundingBoxObj);
        //         let overlapPercentage = this.getOverlapPercentage(object, raycastResult[1][0].object);
        //         if (overlapPercentage < this.allowedOverlapThreshold) {
        //             canPlaceObj = true;
                    
        //             console.log("Obstruction object :", raycastResult[1][0].object.name)
        //             console.log("Overlap amount :", overlapPercentage);
        //         }
        //     }
        // }

        let pvb = object.userData.pvb;

        // Place object at current marker coordinates
        if ( canPlaceObj == true ) {
            // Update grid markers according to object dimentsions.
            this.wallMarker.moveHorizontally((pvb.halfWidth * 2) + this.offset.x );

            this.debugLog("Placing "+ object.name + " at: " + this.getMarker().x + ", " + this.getMarker().y + ", " + this.getMarker().z);
        } else  {
            let width = pvb.halfWidth * 2;
            this.wallMarker.moveHorizontally((width / 10));

            this.debugLog("Skipping placement of " + object.name + " at: " + this.getMarker().x + ", " + this.getMarker().y + ", " + this.getMarker().z);
        }

        return canPlaceObj;
    }
}
