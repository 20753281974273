import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Row, Col, Table } from 'antd';
import DottedLoader from "../../DottedLoader";
import CustomerMainLayout from '../CustomerMainLayout';

const CustomerUsageQuota = (props) => {
   
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, setLoading] = useState(true);
    const [usageQuota, setUsageQuota] = useState(null);

    useEffect(() => {
       
        loadUsageQuota();
    },[]);
  
    const loadUsageQuota = () => {
        setLoading(true);
        let payload = {
          "username": localStorage.getItem('username'),
        }
        axios.post(ENVIRONMENT.CUSTOMER_USAGE_QUOTA, payload)
        .then(res => {
            console.log(res)
            let usage_quota = []
            if (res && res.data) {
                res.data.map((usage,i) => {
                    usage_quota.push({
                      key: i,
                      label: usage['label'],
                      category: usage['category'],
                      limit: usage['limit']
                    })
                  });
                setUsageQuota(usage_quota);
            }
            setLoading(false);

        });
    }

    const getColumns = () => [
        
        {
            title:  <span className="manrope f-16 black-14">Request Type</span>,
            dataIndex: 'label',
            key: 'label',
            render: text => <span className="manrope f-14 black-14">{text}</span>,
        },
        {
            title:  <span className="manrope f-16 black-14">Available Limit</span>,
            dataIndex: 'limit',
            key: 'limit',
            render: text => <span className="manrope f-14 black-14 italic" dangerouslySetInnerHTML={{ __html: (text)}}></span>,
        },
      ];

    const makeBold = (input, wordsToBold) => {
        return input.replace(new RegExp('(\\b)(' + wordsToBold.join('|') + ')(\\b)','ig'), '$1<b className="manrope f-14 black-14 normal-style">$2</b>$3');
    }
    

    const makeTextBold = (string) => {
        let new_string = string;
        var wordsToBold=["4Ks","2Ks","1Ks"];

        new_string = makeBold(string,wordsToBold)
      

        return new_string;
    }

    
    return (
          <CustomerMainLayout>
                <Row className="justify-space-between" style={{marginTop:"30px",marginBottom: 32}}>
                    <Col>
                        <div className='manrope f-36 w-900' style={{display:'flex',alignItems:"flex-end"}}>
                            Customer Usage Quota
                        </div>
                    </Col>
                </Row>
                {loading ? <DottedLoader/>
                :   <Row style={{marginTop: 40}}>
                    <Col span={24}>
                        <Table columns={getColumns()} dataSource={usageQuota}/>
                    </Col>
                    </Row>}
                
                    
            </CustomerMainLayout>
        );

    }

export default CustomerUsageQuota;
  