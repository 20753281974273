import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Table, Modal, Form, Select, Tooltip, Input } from "antd";
import axios from 'axios';
import AdminMainLayout from '../AdminMainLayout';
import ENVIRONMENT from '../../../../environments';
import DottedLoader from "../../DottedLoader";
import SuccessModal from "../../SuccessModal/SuccessModal";
import { InfoCircleOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

const { Option } = Select;

const DesignerData = (props) => {
  const [msProviderData, setMSProviderData] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [successfulAssignment, setSuccessFulAssignment] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [companyUserMapping, setCompanyUserMapping] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedMsprovider, setSelectedMsProvider] = useState({});
  const [removedCompanies, setRemovedCompanies] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handlebackclick=()=>{
    props.history.goBack();
}
  useEffect(() => {
    setLoader(true);
    getDesignerData();
    getCustomerData();
    getCompanyData();  
  },[]);

  const getColumnSearchProps = (dataIndex,placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumns = () => [
    {
      title: <span className="manrope f-16 black-14">MS Provider Username</span>,
      dataIndex: 'msprovider_name',
      key: 'msprovider_name',
      ...getColumnSearchProps('msprovider_name','MS Provider Username'),
      render: text => <span className="manrope f-14 black-14">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">MS Provider Email</span>,
      dataIndex: 'msprovider_email',
      key: 'msprovider_email',
      ...getColumnSearchProps('msprovider_email','MS Provider Email'),
      render: text => <span className="manrope f-14 black-14">{text}</span>,
    },
    {
        title:  <span className="manrope f-16 black-14" style={{display:'flex',justifyContent:'center'}}>Assigned Companies </span>,
        key: 'companies_count',
        sorter: (a, b) => a.companies.length - b.companies.length,
        render: record => <span className="manrope f-14 " style={{display:'flex',justifyContent:'center'}} >{record['companies'].length}</span>,
    },
    {
        title:  <span className="manrope f-16 black-14">Actions</span>,
        key: 'action',
        render: (text,record) => <span className="manrope f-14 blue hover-change" onClick={() => openAssignModal(record)}>Update Assigned Companies</span>,
    },
  ];

  const inviteDesigner = () => {
    window.location.href = '/msprovider_invite';
  }

  const openAssignModal = (data) => {
    setAssignModal(true);
    console.log(data);
    setRemovedCompanies([]);
    setSelectedMsProvider(data);
    setSelectedCompanies(data.companies)
  }

  const cancelAssignModal = () => {
    setAssignModal(false);
    setErrorMessage('');
  }

  const getDesignerData = () => {
    axios.post(ENVIRONMENT.GET_DESIGNER_DATA, {})
      .then(res => {
        let msprovider_data = []
        if (res.data) {
          res.data.map((msprovider, i) => {
            msprovider_data.push({
              key: i,
              msprovider_name: msprovider['designer_username'],
              msprovider_email: msprovider['designer_email'],
              companies: [...new Set(msprovider['customers'].map(item => item.company_id ? item.company_id : ''))]
            })
          });
          setMSProviderData(msprovider_data);
          setLoader(false);
        }
      });
  }

  const getCustomerData = () => {
    axios.post(ENVIRONMENT.CUSTOMER_GET_BATCH, {})
      .then(res => {
        var data = [];
        res.data.map((customer, i) => {
          if (customer['user_group'] == 'customer') {
            data.push({
              key: i,
              company_id: customer['company_id'],
              customer_username: customer['customer_username'],
              profile_name: customer['profile_name'],
              company_name: customer['company_name'],
              user_type: customer['user_type'],
              email: customer['email'],
            });
          }
        });
        setCustomerList(data);
      })
  }

  const getCompanyData = () => {
    let payload = {}
    axios.post(ENVIRONMENT.COMPANY_GET_BATCH, payload)
      .then(res => {
        var data = [];
        res.data.map((company, i) => {
          data.push({
            key: i,
            company_id: company['company_id'],
            company_domain: company['company_domain'],
            company_display_name: company['company_display_name']
          });
        })
        setCompanies(data)
      })
  }

  const updateCustomers = (values) => {
    setErrorMessage('');
    console.log(values, selectedCompanies)
    let payload = {
      designer_username: selectedMsprovider['msprovider_name']
    }
    let customers = []
    values['selected_customers'].map((selected_company_id, i) => {
      // checking if previously assigned customers do not include new customers
      if (!selectedCompanies.includes(selected_company_id)) {
        let username = ''
        let company_id = selected_company_id;
        for (let item of customerList) {
          if (parseInt(item['company_id']) == parseInt(selected_company_id))
            customers.push({
              company_id: item['company_id'],
              customer_username: item['customer_username']
            })
        }
      }
    });
    payload.customer_data = customers;

    console.log(payload);

    if (customers.length == 0 && removedCompanies.length == 0) {
      setErrorMessage('Please perform at least 1 action.');
      setButtonLoader(false);
    } else {
      setButtonLoader(true);
      if (removedCompanies.length > 0 && customers.length == 0) {
        removeCustomers();
      } else {
        assignCustomers(payload);
      }
    }

  }

  const assignCustomers = (payload) => {
    axios.post(ENVIRONMENT.ASSIGN_CUSTOMERS, payload).then(res => {
      console.log(res);
      if (removedCompanies.length > 0) {
        removeCustomers();
      } else {
        setButtonLoader(false);
        setAssignModal(false);
        setSuccessFulAssignment(true);
      }
    });
  }

  const removeCustomers = () => {
    let payload_removed = {
      designer_username: selectedMsprovider['msprovider_name']
    }
    let customer_names = {}
    let removed_customers = []
    removedCompanies.map((company_id) => {
      customerList.map((item) => {
        if (item.company_id == company_id) {
          removed_customers.push({
            customer_username: item.customer_username
          });
        }
      })
    });
    payload_removed.customer_data = removed_customers;
    axios.post(ENVIRONMENT.REMOVE_ASSIGNED_CUSTOMERS, payload_removed).then(res => {
      console.log(res)
      setButtonLoader(false);
      setAssignModal(false);
      setSuccessFulAssignment(true);
    });
  }

  const deselectCompany = (company_id) => {
    console.log('company_id: ', company_id);
    setRemovedCompanies((prev) => {
      return [...new Set([...prev, company_id])];
    })
  }

  const handleChange = (selected_customers) => {
    console.log('selected_customers: ', selected_customers);
    setSelectedCompanies(selected_customers);
  }

  const closeSuccessModal = () => {
    setSuccessFulAssignment(false);
    setTimeout(() => {
      getDesignerData();
    }, 500);
  }

  return (
    <AdminMainLayout selected={"20"}>
      <Row className="justify-space-between" style={{marginTop:"30px",marginBottom: 32}}>
        <Col>
            <div className='manrope f-36 w-900' style={{display:'flex',alignItems:"flex-end"}}>
            <img
                src={`${process.env.PUBLIC_URL}/img/back_arrow.png`}
                alt="Arrow Back"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={handlebackclick}
              />
              Managed Service Providers
            </div>
        </Col>
        <Col>
          <Button className="modal-okay square font-16"  style={{marginLeft: 20}} onClick={inviteDesigner}>
             Invite Managed Service Provider
          </Button> 
        </Col>
      </Row>
      {loader ? <DottedLoader/>
      :<Row style={{marginTop: 40}}>
          <Col span={24}>
              <Table columns={getColumns()} dataSource={msProviderData}/>
          </Col>
      </Row>}


        <Modal
        visible={assignModal}
        className="modal-share"
        width={788}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={() => setAssignModal(false)}
        footer={[
          <div className="justify-in-end">
              <Button disabled={buttonLoader} className="modal-okay-gray square font-14" onClick={cancelAssignModal}>
                Cancel
              </Button>
              <Button disabled={buttonLoader} className="modal-okay square font-14" onClick={() => document.getElementById("save-data").click()}>
                  Save&nbsp;{buttonLoader ? <LoadingOutlined/> : ''}
              </Button>
          </div>
        ]}>
        <div style={{padding:"16px 6px 0 14px"}}>
            <div className="justify-in-start" style={{marginBottom: 20}}>
                <h2 className="manrope f-18 black-14">Add/Remove Companies</h2>
            </div>
                
            <Form 
            initialValues={{
              selected_customers: selectedCompanies
            }}
            onFinish={updateCustomers}
            layout="vertical">
                <Row type='flex' gutter={12}>
                    <Col span={24}>
                        <Form.Item label={''} name="selected_customers" rules={[{ type:'array', required: false,  message: 'Please select Companies.' }]}>
                            <Select onDeselect={deselectCompany} showArrow showSearch disabled={props.buttonLoader} className="manrope f-12 black-55 select-tags" mode="multiple" placeholder="Search Companies" 
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                              {companies.map((company) => {
                                  return <Option value={company['company_id']} className="manrope f-12 black-14 select-view">{company['company_display_name']}</Option>
                              })}
                            </Select>
                        </Form.Item>
                    </Col>
                    {errorMessage == "" ? '' :
                    <Col span={24}  style={{marginTop: 20}}>
                      <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{errorMessage}</span></div>
                    </Col>}
                  
                    <Button id="save-data" type="primary" htmlType="submit" style={{"display": "none"}}>
                        Save
                    </Button>
                </Row>
            </Form>
        </div>
    </Modal>
      <SuccessModal
      visible={successfulAssignment}
      onCancel={() =>  setSuccessFulAssignment(false)}
      heading={"Companies Updated Successfully!"}
      text={"The companies you selected/removed have been updated for the designer."}
      footer={[
          <div className="justify-in-center">
              <Button className="modal-okay square font-14" htmlType="submit" onClick={() => window.location.reload()}>
                  Okay
              </Button>
          </div>
      ]}
      />
    </AdminMainLayout>
  );
}


export default withRouter(DesignerData);