import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const DateRangePicker = ({ start, end, date_format, onCalChange }) => {
    return (
        <RangePicker
            size={"medium"}
            defaultValue={[moment(start, date_format), moment(end, date_format)]}
            format={date_format}
            className="date-picker"
            popupStyle={{
                fontFamily: "Manrope",
                fontSize: 14,
                color: "#40a9ff",
                background: "#e6efff",
            }}
            placeholder={["Date (From)", "Date (To)"]}
            value={[moment(start, date_format), moment(end, date_format)]}
            onCalendarChange={(val) => onCalChange(val)}
        />
    );
};

export default DateRangePicker;
