/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useMemo } from 'react';
import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { Card, Tooltip, Space, Dropdown, Button } from 'antd';
import FileConstants from '../../../../FileConstants';
import _ from "lodash" // Import the entire lodash library
import './AddNewSceneFlow.scss'
import * as Utilities from '../../Utilities';

const SpaceAreaSelection = (props) => {

    const toggleAreaSelection = (area) => {
        // Clone the products array from props
        let products = _.cloneDeep(props.productsAddedToScene);
        // Find the product with the matching product_id
        const productIndex = products.findIndex(product => product.product_id === props.id && (product.area_name && product.area_name == area));
        if (productIndex !== -1) {
            products.splice(productIndex, 1)
        } else {
            // If the product_id doesn't exist, create a new entry
            products.push({ product_id: props.id, area_name: area});
        }
        props.setProductsAddedToScene(products);
    };


    const checkIfAreaSelected = (area) => {
        const productIndex = props.productsAddedToScene.findIndex(product => product.product_id == props.id && (product.area_name && product.area_name == area));
        if (productIndex !== -1) {
            return true;
        }
        return false;
    }

    const checkIfItemSelected = (ele) => {
        return (props.productsAddedToScene.some(item => item.product_id === props.id))
      }

    return (<Dropdown overlay={<div className='comment-option-dropdown'>
        {props.space_areas?.map((area, index) => (
            <Space key={index}
                className='area-pd-space justify-space-between'>
                <p className='manrope f-14 lh-28 mb-0'>{area}</p>
                <Button ghost className={`item-add-btn justify-in-center manrope f-14 lh-28 ${checkIfAreaSelected(area) ? `red` : `blue`}`} onClick={() => toggleAreaSelection(area)}>
                    {checkIfAreaSelected(area) ? 'Remove' : 'Add'}
                </Button>
            </Space>
        ))}
    </div>}
        trigger={['click']}>
        {checkIfItemSelected(props.product) ? 
            <CheckOutlined title="Added To Scene"
            className="item-icons plus button-checked"
          />
        :<PlusOutlined
            title="Add To Scene"
            className="item-icons plus"
        />}
    </Dropdown>);
}

const ProductCardBody = ({productsAddedToScene, handleAddAction,preselectedProducts, ele, id}) => {

  return  (productsAddedToScene.some(item => item.product_id === id)) ?
      <CheckOutlined title="Added To Scene"
        className="item-icons plus button-checked"
        onClick={() => handleAddAction(ele)}
      /> :  preselectedProducts?.includes(id) ?
      <CheckOutlined title="Added To Scene"
          className="item-icons plus button-checked-disabled"
          disabled
        /> :
        <PlusOutlined
            title="Add To Scene"
            className="item-icons plus"
            onClick={() => handleAddAction(ele)}
        /> 
  }


const ProductCard = (props) => {
  const {
    id,
    itemType,
    customer_username,
    item_type,
    requestPlan,
    thumbnail,
    name,
    category,
    company_display_name,
    subscriptionPlan,
    company_id,
    price,
    ele,
    setProductsAddedToScene,
    productsAddedToScene,
    selectedRoom,
    isStore,
    requestStatusRoom,
    page,
    preselectedProducts
  } = props;
  const CUSTOMER_USERNAME = localStorage.getItem('username');

  const checkIfItemAddedToLibrary = (product) => {
    return isStore && (item_type && item_type == 'not_shared') && (product.price != 0 || product.price != '');
  }

  const handleAddAction = (product) => {
    let products = _.cloneDeep(productsAddedToScene);
    if(products.find(ele => ele.product_id === product.id)){
        products = (products.filter((ele) => ele.product_id !== product.id));
    }else
    {
      products.push({ product_id: product.id});
    }
    setProductsAddedToScene(products);
  }
  
  return (
    <Card className={`card-shadow-product room-card ${ (!(requestStatusRoom == 'always_allowed' ||requestStatusRoom == 'allowed') && checkIfItemAddedToLibrary(ele)) ? `product-hover` : ``}`}>
      {
        (!(requestStatusRoom == 'always_allowed' || requestStatusRoom == 'allowed') && checkIfItemAddedToLibrary(ele)) ? 
        <a href='/store' target='_blank'>
            <Tooltip title={<span className='manrope f-12 white'>Please go to store to purchase this item</span>}>
                <img src='/img/redirect-icon.svg' className="item-icons plus" />
            </Tooltip>
        </a>
        : page === 'project' ?
        <ProductCardBody handleAddAction={handleAddAction} productsAddedToScene={productsAddedToScene} ele={ele} id={id} preselectedProducts={preselectedProducts}/> :
        (selectedRoom && selectedRoom.space_areas?.length != 0) ? 
        <SpaceAreaSelection
            productsAddedToScene={productsAddedToScene}
            setProductsAddedToScene={setProductsAddedToScene}
            product={ele}
            space_areas={selectedRoom.space_areas}
            id={id} /> : 
          (productsAddedToScene.some(item => item.product_id === id)) ?
          <CheckOutlined title="Added To Scene"
            className="item-icons plus button-checked"
            onClick={() => handleAddAction(ele)}
          /> :
        (selectedRoom && selectedRoom.space_areas?.length == 0) ?
            <PlusOutlined
                title="Add To Scene"
                className="item-icons plus"
                onClick={() => handleAddAction(ele)}
            /> :
            selectedRoom &&
            <SpaceAreaSelection
                productsAddedToScene={productsAddedToScene}
                setProductsAddedToScene={setProductsAddedToScene}
                product={ele}
                space_areas={selectedRoom.space_areas}
                id={id} />
        
      }
      <div
        className="suggested-product"
        target="_blank"
        style={{ zIndex: '1' }}
      >
        <div className="box">
          <img
            key={id}
            className="product-image space-card-img"
            src={thumbnail || require('../../../../assets/images/chair.png')}
          />
        </div>
        <div className='justify-in-start' style= {{ margin: '12px 12px 8px 12px' }}>
        <Tooltip title={<span className="manrope f-12 white"> {name}</span>}>
          <div
            className="manrope f-14 w-500 black-00 j-end clamp-text w-100">
            {name}
          </div>
        </Tooltip>
        {ele.last_modified_stamp ? 
        <Tooltip title={ <span className="manrope f-12 white"> { Utilities.timestampToTimeSince( ele.last_modified_stamp, "tooltip")} </span>}>
          <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
              <img src="/img/carbon_time.svg" style={{ marginRight: 2 }} /> {Utilities.timestampToTimeSince(ele.last_modified_stamp)}
          </span>
        </Tooltip> : ''}
        </div>
        {category ? (
          <>
            <div
              className="justify-in-start manrope f-12 grey-77 capitalize"
              style={{
                marginLeft: '12px',
                marginRight: '12px',
                marginBottom: '4px',
                textAlign: 'left',
              }}
            >
              <div style={{ marginRight: 4 }}>Category:</div>
              <div style={{ color: '#333333' }}>{category}</div>
            </div>
          </>
        ) : (
          ''
        )}
        {company_display_name ? (
          <>
            <div
              className="justify-in-start manrope f-12 grey-77 capitalize"
              style={{
                marginBottom: '4px',
                marginLeft: '12px',
                marginRight: '12px',
                textAlign: 'left',
              }}
            >
              <div style={{ marginRight: 4 }}>Brand:</div>
              <div style={{ color: '#333333' }}>
                {company_display_name}
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div
          className="justify-in-start manrope f-12 grey-77 capitalize"
          style={{
            marginBottom: '12px',
            marginLeft: '12px',
            marginRight: '12px',
            textAlign: 'left',
          }}
        >
          {itemType != 'scene' &&
          item_type == 'shared' &&
          subscriptionPlan != 'enterprise' &&
          !FileConstants.ALL3D_COMPANY_IDS().includes(
              company_id.toString(),
          ) ? (
            <div style={{ marginRight: 4 }}>Already Purchased</div>
          ) : itemType != 'scene' &&
            item_type == 'shared' &&
            subscriptionPlan == 'enterprise' &&
            !FileConstants.ALL3D_COMPANY_IDS().includes(
                company_id.toString(),
            ) ? (
            <div style={{ marginRight: 4 }}>Added to Library</div>
          ) : (
            <>
              {price == 0 ? (
                <div style={{ color: '#333333' }}>Free</div>
              ) : company_id &&
                FileConstants.ALL3D_COMPANY_IDS().includes(
                    company_id.toString(),
                ) &&
                price &&
                price != 0 ? (
                <div style={{ color: '#333333' }}>Included in platform fee</div>
              ) : price ? (
                <>
                  <div>Price:</div> &nbsp;
                  <div style={{ color: '#333333' }}>${price}</div>
                </>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
