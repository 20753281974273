import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Divider, Table, Card, Tooltip, Empty, Form, Input, Button, Space} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import * as InfoImage from '../../../../assets/images/info.png';
import FileConstants from "../../../../FileConstants";
import * as Utilities from "../../Utilities";
import ENVIRONMENT from '../../../../environments';
import axios from "axios";
import DottedLoader from "../../DottedLoader";
import moment from "moment";
import './CustomerReports.scss'

const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;

const CustomerProductReports = () => {
  const [pendingToBeDeliveredDataSource, setPendingToBeDeliveredDataSource] = useState([]);
  const [pendingToBeDelivered,setPendingToBeDelivered]=useState([]);
  const [pendingCustomerReviewProducts, setPendingCustomerReviewProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ====> Page Search Functionality
  const [searchValue, setSearchValue] = useState("");
  const changeSearchValue = (value) => {
    setSearchValue(value);
  };
  // <==== Page Search Functionality

  // ====> Search in Table Functionality
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  // Search in Table Functionality <====

  // Get products data when Managed Customer Is changed
  useEffect(() => {
    if(MANAGED_CUSTOMER_USERNAME && IS_MS_PROVIDER == true){
      setIsLoading(true);
      let payload = {
        "required_fields": ["id","name","brand_id", "category","customer_username",
        "model_status","thumbnail","last_modified_stamp","company_id","platform"],
      }
      payload["order_by"] = "last_modified_stamp desc"
      let customer_username_check = "customer_username__exact='" + MANAGED_CUSTOMER_USERNAME + "'"
      let company_id_check = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID)
      let model_status_list = [4,10]
      let model_status_check =  "model_status__in=["+ model_status_list+ "]"
      let requested_for_check = "requested_for__exact='" + MANAGED_CUSTOMER_USERNAME  + "'";
      let filter_string = "("+company_id_check+"||"+customer_username_check+")&&("+ model_status_check + ")"
      console.log(filter_string)
      payload['filter_string'] = filter_string;
      axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
        console.log("Pending Customer Review Products:", res.data);
        setPendingCustomerReviewProducts(res.data);
        setIsLoading(false);
      });
      setIsLoading(true);
      model_status_list = [2, 3, -4, -5, 6, -6, 7, 8, 11]
      model_status_check =  "model_status__in=["+ model_status_list+ "]"
      filter_string = "("+company_id_check+"||"+customer_username_check+"||"+requested_for_check+")&&(" + model_status_check + ")"
      payload['filter_string'] = filter_string;
      axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
        console.log("Pending To Be Delivered Products:", res.data);
        setPendingToBeDelivered(res.data);
        setIsLoading(false);
      });
    }
  }, [MANAGED_CUSTOMER_USERNAME]);

  // ====> Pending to be Delivered

  const pendingToBeDeliveredTableColumns = [
    {
      title: "Item ID",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Brand ID",
      dataIndex: "brand_id",
      key: "brand_id",
    },
    {
      title: "Submitted On",
      dataIndex: "customer_submitted_on",
      key: "customer_submitted_on",
      render: (text) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "State",
      dataIndex: "model_status",
      key: "model_status",
      render: (text) => (
        <span>{FileConstants.MODEL_STATUS_STRING[`${text}`]}</span>
      ),
    },
  ];

  useEffect(() => {
    getDataSource();
  }, [searchValue])
  

  const getDataSource=()=>{
    let itemList = [];
    if(pendingToBeDelivered.length>0){
    pendingToBeDelivered.map((ele, index) => {
      let entityEntry = null;
      if (
        searchValue == "" ||
        Utilities.isMatching(
          searchValue.toLowerCase(),
          ele.name.toLowerCase()
        ) ||
        Utilities.isMatching(
          searchValue.toLowerCase(),
          ele.category.toLowerCase()
        ) ||
        Utilities.isMatching(
          searchValue.toLowerCase(),
          ele.brand_id.toLowerCase()
        ) ||
        Utilities.isMatching(
          searchValue.toString(),
          ele.id.toString()
        )
      ) {
        entityEntry = ele;
      }

      if (entityEntry != null) {
        itemList.push(entityEntry);
      }
      console.log("Search Items List For Pending To Be Delivered Products ",itemList);
    });
    setPendingToBeDeliveredDataSource(itemList);
    }
  }
  // <==== Pending to be Delivered

  const getProductReviewLink = (itemId) => {
    let link = '/msp_products/' + itemId;
    return link
  }

  return (
    <>
        {isLoading ? (
          <DottedLoader />
        ) : MANAGED_CUSTOMER_USERNAME ? (
          <>
            <Row
              className="justify-in-end"
              style={{ marginTop: "10px", marginBottom: 20 }}
            >
              <Col lg={10} xl={10}>
                <Form>
                  <Input.Group compact size="large" className="d-inline">
                    <Input
                      placeholder="Search Customer Reports"
                      onChange={(e) => {
                        changeSearchValue(e.target.value);
                      }}
                      className="manrope f-14 mat-search-bar"
                    ></Input>
                  </Input.Group>
                </Form>
              </Col>
            </Row>
            <Divider />
            <Row
              className="justify-space-between"
              style={{ marginTop: "20px", marginBottom: 20 }}
            >
              <Col lg={10} xl={10}>
                <div
                  className="manrope f-24 w-600"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  Pending Customer Review
                </div>
              </Col>
            </Row>
            {pendingCustomerReviewProducts.length > 0 ? (
              <Row>
                  <>
                    <ReviewCardsLinkList
                      products={pendingCustomerReviewProducts}
                      searchValue={searchValue}
                      link={`/msp_products/`}
                      getProductReviewLink = {getProductReviewLink}
                    />
                  </>
              </Row>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            <Divider />
            <Row
              className="justify-space-between"
              style={{ marginTop: "20px", marginBottom: 20 }}
            >
              <>
                <Col lg={10} xl={10}>
                  <div
                    className="manrope f-24 w-600"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    Pending To Be Delivered
                  </div>
                </Col>
              </>
            </Row>
            <Table
              dataSource={pendingToBeDeliveredDataSource.length>0 ? pendingToBeDeliveredDataSource: pendingToBeDelivered}
              columns={pendingToBeDeliveredTableColumns}
              scroll
              style={{ overflowX: "scroll" }}
            />
          </>
        ) : (
          <div style={{ position: "relative", top: "40%", height: "300px" }}>
            <Empty className="default-user-empty" image={InfoImage} 
            description={<span>Please select a customer from Top bar to view their models report</span>} />
          </div>
        )}
    </>
  );
};

const ReviewCardsLinkList = (props) => {
  let itemList = [];

  props.products.map((ele, index) => {
    let entityEntry = null;
    if (
      props.searchValue == "" ||
      Utilities.isMatching(
        props.searchValue.toLowerCase(),
        ele.name.toLowerCase()
      ) ||
      Utilities.isMatching(
        props.searchValue.toLowerCase(),
        ele.category.toLowerCase()
      ) ||
      Utilities.isMatching(
        props.searchValue.toLowerCase(),
        ele.brand_id.toLowerCase()
      ) ||
      Utilities.isMatching(
        props.searchValue.toString(),
        ele.id.toString()
      )
    ) {
      entityEntry = <ProductItemLink ele={ele} key={index} getProductReviewLink={props.getProductReviewLink} />;
    }
      
    if (entityEntry != null) {
      itemList.push(entityEntry);
    }
  });
  
  console.log("Search Items List For Pending Customer Review Products = ", itemList);
  return <>{itemList}</>;
};

const ProductItemLink = (props) => {
  const { ele } = props;
  let thumbnail = ele.thumbnail.replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(ele.platform))

  return (
    <Col lg={6} sm={24} md={8} offset={0}>
      <Link to={props.getProductReviewLink(ele.id)}>
        <Card
          id="#entity-container"
          className="card-shadow-product"
          bordered={false}
          style={{ marginRight: "20px", marginBottom: "20px" }}
        >
          <div className="suggested-product">
            <div className="box">
              {thumbnail ? (
                <img
                  style={{
                    objectFit: "contain",
                    height: "280px",
                    opacity: "0.5",
                    filter: "blur(0px)",
                  }}
                  className="product-image"
                  src={thumbnail}
                />
              ) : (
                <>
                  <img
                    style={{
                      objectFit: "contain",
                      height: "280px",
                      opacity: "0.5",
                      filter: "blur(0px)",
                    }}
                    className="product-image"
                    src={require("../../../../assets/images/product_incomplete.svg")}
                  />
                  <div className="incomplete-request-text">
                    <span>Image not Found</span>
                  </div>
                </>
              )}

              <div
                className="home-entity-label justify-in-start"
                style={{ float: "right" }}
              >
                <span className="entity-label-bg manrope f-12 w-700 blue">
                  {FileConstants.MODEL_STATUS_STRING[`${ele.model_status}`]}
                </span>
              </div>
            </div>

            <div
              className="justify-in-start"
              style={{
                marginTop: "12px",
                "text-align": "left",
                marginLeft: "12px",
              }}
            >
              <Tooltip
                title={<span className="manrope f-12 white">{ele.name}</span>}
              >
                <span className="manrope f-14 black-14 w-500 clamp-text w-50">
                  {ele.name}
                </span>
              </Tooltip>
              <Tooltip
                title={
                  <span className="manrope f-12 white">
                    {Utilities.timestampToTimeSince(ele.last_modified_stamp, "tooltip")}
                  </span>
                }
              >
                <span
                  className="manrope f-12 grey-77 w-500 time-clock-bg"
                  style={{ marginLeft: 4 }}
                >
                  <img src="/img/carbon_time.svg" style={{ marginRight: 2 }} />
                  {Utilities.timestampToTimeSince(ele.last_modified_stamp)}
                </span>
              </Tooltip>
            </div>

            <div
              className="manrope f-12 grey-77 capitalize justify-in-start"
              style={{
                marginBottom: 12,
                marginTop: 8,
                textAlign: "left",
                marginRight: 12,
                marginLeft: 12,
              }}
            >
              <span style={{ marginRight: 4 }}>Category: </span>
              <span style={{ color: "#333333" }}>{ele.category ? ele.category : 'N/A'}</span>
            </div>
            <div
              className="manrope f-12 grey-77 capitalize justify-in-start"
              style={{
                marginBottom: 12,
                marginTop: 8,
                textAlign: "left",
                marginRight: 12,
                marginLeft: 12,
              }}
            >
              <span style={{ marginRight: 4 }}>Item ID: </span>
              <span style={{ color: "#333333" }}>{ele.id ? ele.id : 'N/A'}</span>
            </div>
            <div
              className="manrope f-12 grey-77 capitalize justify-in-start"
              style={{
                marginBottom: 12,
                marginTop: 8,
                textAlign: "left",
                marginRight: 12,
                marginLeft: 12,
              }}
            >
              <span style={{ marginRight: 4 }}>Brand ID: </span>
              <span style={{ color: "#333333" }}>{ele.brand_id ? ele.brand_id : 'N/A'}</span>
            </div>
          </div>
        </Card>
      </Link>
    </Col>
  );
};

export default CustomerProductReports;
