import { message, Image } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import ImageWithCustomMask from '../../ImageWithCustomMask/ImageWithCustomMask';

import { saveAs } from 'file-saver';

const LifeStyleImages = (props) => {

    const { baseURL, sceneId, before, after } = props

    return (
        <div style={{ width: '100%' }} className="display-flex-row manrope f-14 w-500" >
            <div style={{ borderRight: "0.01px solid #E3E3E3", width: "50%" }} className="align-col-left">
                <p style={{ paddingLeft: 15, paddingTop: 12, paddingBottom: 2 }}>Before</p>
                <div className="complaint-renders" >
                <ImageWithCustomMask 
                    height={350} 
                    image_class="lifestyle-image-settings-custom" 
                    image_name={before}
                    base_url={baseURL + sceneId + '/'}
                />

                </div>
            </div>
            <div className="align-col-left" style={{ marginLeft: 10, width: "50%" }}>
                <p style={{ paddingTop: 12, paddingBottom: 2 }} >{ after!='rejected'? after? 'After' : 'In Progress': 'Reviewed & Resolved'}</p>
                {after === 'rejected' ? 
                    <div className="complaint-renders flex margin-right-unset no-padding-margin" >
                        <CheckCircleOutlined className="manrope f-14 set-svg-dims"/>
                    </div> :
                    <div className="complaint-renders flex margin-right-unset" >
                        {!after ? 
                        <img className={'lifestyle-image-settings-custom'} src={require("../../../../../assets/images/inProgress icon.png")}></img>
                        :
                        <ImageWithCustomMask 
                            height={350} 
                            image_class="lifestyle-image-settings-custom" 
                            image_name={after}
                            base_url={baseURL + sceneId + '/'}
                        />
                    }
                    </div>
                }
            </div>
        </div>

    )
}

export default LifeStyleImages;