import React from "react";
import ArtistMainLayout from '../ArtistMainLayout';
import { Tabs } from "antd";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import ItemsTable from '../../ItemsTable';
import MaterialTable from "../../AdminComponents/MaterialTable/MaterialTable";
import RoomsTable from "../../RoomsTable/RoomsTable";
import FileConstants from "../../../../FileConstants";
import DottedLoader from "../../DottedLoader";

const { TabPane } = Tabs;
class ArtistJobDesk extends React.Component {
  state = { size: "small" };
  job_desk_data = []
  loading_data = false
  data_fetched = false

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  componentDidMount(){
    this.setState({loading_data: true})
    axios.post(ENVIRONMENT.ARTIST_JOB_DESK, {
      'artist_username': localStorage.getItem('username')
    }).then(res=>{
      this.setState({
        job_desk_data: res.data,
        loading_data: false,
        data_fetched: true
      })
    })
  }

  render() {
    const { size } = this.state;
    return (
      <ArtistMainLayout selected='3'>
        <div className="job-desk-layout">
          <span className="manrope f-24 33">
            Job Desk
          </span>
          <div className="job-desk-description">
            <span className="manrope f-12 l-140">
              Please note:
              <br/>
              1. Please pick items after viewing and understanding requirements.
              <br/>
              2. You will have 48 hours to submit a product model, 72 hours for space models and 24 hours for materials. If you do not submit within this time, the model will automatically be unassigned from you and you will not receive payment for it.
              <br/>
            </span>
          </div>
        <br />
        {this.state.data_fetched ? 
        <Tabs className='items-table' defaultActiveKey="1" size={size} tabBarStyle={{ textAlign: 'center' }} animated={false}>
          <TabPane tab="Product Modeling Jobs" key="1">
            <ItemsTable job_desk_data={this.state.job_desk_data} loading_data={this.state.loading_data} state="job_desk" />
          </TabPane>
          <TabPane tab="Space Modeling Jobs" key="2">
            <RoomsTable job_desk_data={this.state.job_desk_data} loading_data={this.state.loading_data} state="job_desk" />
          </TabPane>
          <TabPane tab="Material Modeling Jobs" key="3">
            <MaterialTable job_desk_data={this.state.job_desk_data} loading_data={this.state.loading_data} state="job_desk" />
          </TabPane>
        </Tabs>
        : <DottedLoader></DottedLoader>}
        </div>
      </ArtistMainLayout>
    );
  }
}

export default ArtistJobDesk;