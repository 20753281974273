import React from 'react';
import { Input, InputNumber, Slider} from 'antd';
import { black } from 'colorette';

class FocalControls extends React.Component{

    state= {
        marks : {25:{style:{color:"#276dd7", fontWeight:"bold", fontSize:"1.3vh", maxWidth:"5ch"}, label:"Human Eye"}, 50:{style:{color:"#276dd7", fontWeight:"bold", fontSize:"1.3vh"}, label:"Close Up"}, 75:{style:{color:"#276dd7", fontWeight:"bold",fontSize:"1.3vh"}, label:"Zoom"}},
        cameraFOV :40.06,
        cameraFocal: this.props.cameraFocal,
        SliderPosition:30,
        cameraFocalMin: 10
    }

    componentDidUpdate() {
        if(this.props.cameraFocal < this.state.cameraFocalMin){
            this.onChange(this.props.defaultCameraFocal);
        }
        if(this.state.cameraFocal != this.props.cameraFocal && this.props.snapshot_clicked){
            this.onChange(this.props.cameraFocal)
            this.props.toggleSnapshotTaken()
        }
    }

    componentDidMount() {
        this.onChange(this.state.cameraFocal);
    }

    onChange = (value) => {
        if(value>=this.state.cameraFocalMin && value <101){
            this.setState({
                cameraFOV: (this.props.setFocalOfCamera(value)).toFixed(2),
                cameraFocal:value.toFixed(0),
                SliderPosition: value.toFixed(0),
            })
        }
    }

    onFLChange = (val) => {
        var value = parseInt(val);
        if(value>=this.state.cameraFocalMin && value<101){
            this.setState({
                cameraFocal:value,
                cameraFOV: (this.props.setFocalOfCamera(value)).toFixed(2),
                SliderPosition:value,
            })
        }
    }

    render(){
        return <div style={{width:"100%", height:"100%"}}>
            <p style={{position:"absolute", left:"8%" , top:"0%", fontSize:'0.7vw', fontWeight:"bold", whiteSpace:"nowrap"}}>
                Focal Length/FOV Slider
            </p>
            <div id="sliderFocal" style={{position:"absolute", left:"3%", top: "8%", width:"90%"}}>
                <Slider onAfterChange={(event) => {console.log(event)}} onChange={this.onChange} tooltipVisible={false} trackStyle={{backgroundColor:"#276dd7"}} railStyle={{backgroundColor:"#cccccc"}} handleStyle={{backgroundColor:"#ffffff", borderColor:"#276dd7"}} included={true} marks={this.state.marks} step={1} min={this.state.cameraFocalMin} max={100} value={this.state.SliderPosition}/>
            </div>
            <label style={{position:"absolute", left:"5%", fontSize:"1.2vh", marginTop:"26%", width:"20%"}}>Focal Length</label>
            <InputNumber
            formatter={(value) => `${value} mm`}
            parser={(value) => (value.replace('mm', ''))}
            onChange={this.onFLChange} step="1" style={{align:"center" ,color:"#000000", height:"4vh", fontSize:"1.5vh", width:"27%" ,position:"absolute", marginTop:"23%", left:"26%", borderRadius:"3px", borderColor:"#276dd7", marginLeft:2, marginRight:2}} value={this.state.cameraFocal} min={this.state.cameraFocalMin} max={100}/>


            <label style={{position:"absolute", marginTop:"26%", left:"59%", fontSize:"1.2vh"}}>FOV</label>
            <InputNumber
            formatter={(value) => `${value}°`}
            parser={(value) => (value.replace('°', ''))}
            disabled={true} style={{fontSize:"1.4vh", height:"4vh", width:"23%" ,position:"absolute", marginTop:"23%", left:"68%", borderRadius:"3px", color:"#000000", textAlign:"center"}} step="0.01" value={this.state.cameraFOV}/>

        </div>
    }
}
export default FocalControls;