import React from 'react';
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Card, Row, Button, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import CustomerMainLayout from '../../CustomerMainLayout';
import CustomerOnboarding from '../../CustomerOnboarding';

class MaterialRequest extends React.Component {

    state = {
        onboarding: false,
    }

    componentDidMount() {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        if(onboarding != undefined){
            if (typeof(onboarding == 'string') || onboarding instanceof String)
            {
                onboarding = (onboarding.toLowerCase() === 'true');
            }

            if (onboarding == false) {
                this.setState({
                    onboarding: false
                });
            }
            else{  
                this.setState({
                    onboarding: true
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            let onboarding = new URLSearchParams(window.location.search).get("onboarding");
            if(onboarding != undefined){
                if (typeof(onboarding == 'string') || onboarding instanceof String)
                {
                    onboarding = (onboarding.toLowerCase() === 'true');
                }
                if (onboarding == false) {
                    this.setState({
                        onboarding: false
                    });
                }
                else{  
                    this.setState({
                        onboarding: true
                    });
                }
            }
        }
    }

    createNewMaterial = () => {
       
        window.location.href="/create-new-material?origin=/material-request";
        
    }


    uploadScannedMaterial = () => {
       
        window.location.href="/upload-scanned-material?origin=/material-request";
        
    }

    render () {
        return (
            <CustomerMainLayout selected='16'>
               <div>
                <div style={{display: "flex", justifyContent: "space-around", marginTop: 100}}>
                    <Row>
                        <Card style={{marginRight: 12,padding:20}} bodyStyle={{width: 400, textAlign:"center"}} data-tut="reactour__create_product_model">
                            <img style={{width: 120, marginBottom: 20,marginTop: 60}} alt="Create New Material" src={require("../../../../../assets/images/create-material.png")}></img>
                            <span style={{marginTop: 20,textAlign:'center'}}>
                                <h3 className="manrope f-18 black-14 w-600 justify-in-center">Create New Material&nbsp;&nbsp;<Tooltip title={<span className='manrope f-12 white'>If you want to request a material using photographs of your swatches, please provide the required information in the form ahead.</span>}><InfoCircleOutlined/></Tooltip></h3>
                            </span>
                            <Button shape="round" type="danger" className="modal-okay-orange square font-14 w-900" style={{margin: "20px auto 60px auto"}} onClick={this.createNewMaterial}>
                                Create <ArrowRightOutlined />
                            </Button>
                        </Card>
                        <Card style={{marginLeft: 12,padding:20}} bodyStyle={{width: 400, textAlign:"center"}} data-tut="reactour__upload_existing">
                            <img style={{width: 120, marginBottom: 20,marginTop: 60}} alt="Upload Existing Product Model" src={require("../../../../../assets/images/upload-scanned-material.png")}></img>
                            <span style={{marginTop: 20,textAlign:'center'}}>
                                <h3 className="manrope f-18 black-14 w-600 justify-in-center">Upload Scanned Material&nbsp;&nbsp;<Tooltip title={<span className='manrope f-12 white'>If you already have a scanned archive of your material, please upload it and provide the required information in the form ahead.</span>}><InfoCircleOutlined/></Tooltip></h3>
                            </span>

                            <Button shape="round" className="modal-okay-orange square font-14 w-900" type={this.state.onboarding?"danger":"danger"} style={{margin: "20px auto 60px auto"}} onClick={this.uploadScannedMaterial} disabled={this.state.onboarding?true:false}>
                                Upload <ArrowRightOutlined />
                            </Button>
                        </Card>
                        
                    </Row>
                </div>
            </div>
            </CustomerMainLayout>
        );
    }
}

export default withRouter(MaterialRequest);
