import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const StyleCategory = ({ value = undefined, onChange, className, disabled, placeholder, mode = undefined }) => {
    return (
        <Select
            className={`manrope f-10 black-55 ${className}`}
            onChange={onChange}
            disabled={disabled}
            value={value}
            showSearch
            mode={mode}
            placeholder={placeholder ? placeholder : "Select or type in your product style category"}
        >
            <Option className="manrope f-10 select-view" value="Mid Century">Mid Century</Option>
            <Option className="manrope f-10 select-view" value="Traditional">Traditional</Option>
            <Option className="manrope f-10 select-view" value="Farmhouse">Farmhouse</Option>
            <Option className="manrope f-10 select-view" value="Modern">Modern</Option>
            <Option className="manrope f-10 select-view" value="Glam">Glam</Option>
            <Option className="manrope f-10 select-view" value="Rustic">Rustic</Option>
            <Option className="manrope f-10 select-view" value="Global">Global</Option>
            <Option className="manrope f-10 select-view" value="Bohemian">Bohemian</Option>
            <Option className="manrope f-10 select-view" value="Ecclectic">Ecclectic</Option>
            <Option className="manrope f-10 select-view" value="Industrial">Industrial</Option>
            <Option className="manrope f-10 select-view" value="Coastal">Coastal</Option>
            <Option className="manrope f-10 select-view" value="Transitional">Transitional</Option>
            <Option className="manrope f-10 select-view" value="Other">Other</Option>
            
        </Select>
    );
}
export default StyleCategory;