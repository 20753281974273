import React from 'react';
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Card, Row, Button, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import CustomerMainLayout from '../../CustomerComponents/CustomerMainLayout/CustomerMainLayout';
import CustomerOnboarding from '../../CustomerComponents/CustomerOnboarding';
class ProductModelRequest extends React.Component {

    state = {
        onboarding: false,
    }

    componentDidMount() {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        if(onboarding != undefined){
            if (typeof(onboarding == 'string') || onboarding instanceof String)
            {
                onboarding = (onboarding.toLowerCase() === 'true');
            }

            if (onboarding == false) {
                this.setState({
                    onboarding: false
                });
            }
            else{  
                this.setState({
                    onboarding: true
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            let onboarding = new URLSearchParams(window.location.search).get("onboarding");
            if(onboarding != undefined){
                if (typeof(onboarding == 'string') || onboarding instanceof String)
                {
                    onboarding = (onboarding.toLowerCase() === 'true');
                }
                if (onboarding == false) {
                    this.setState({
                        onboarding: false
                    });
                }
                else{  
                    this.setState({
                        onboarding: true
                    });
                }
            }
        }
    }

    createNewProductPage() {
        if(this.state.onboarding){
            this.props.history.push({
                pathname: '/new-product-model',
                search: `${this.props.location.search}`
            });
        }
        else{
            window.location.href="/new-product-model";
        }
    }

    render () {
        return (
            <CustomerMainLayout selected='3'>
               <div>
                <div style={{display: "flex", justifyContent: "space-around", marginTop: 100}}>
                    <Row>
                        <Card style={{marginRight: 12,padding:20}} bodyStyle={{width: 420, textAlign:"center"}} data-tut="reactour__upload_existing">
                            <img style={{width: 120, marginBottom: 20,marginTop: 60}} alt="Upload Existing Product Model" src={require("../../../../assets/images/chair-model.png")}></img>
                            <span style={{marginTop: 20}}>

                                <h3 className="manrope f-18 black-14 w-600 justify-in-center" >Upload Existing 3D Product Model&nbsp;&nbsp;<Tooltip title={<span className='manrope f-12 white'>If you would like to make changes to your model, use Create option instead. </span>}><InfoCircleOutlined/></Tooltip></h3>
                            </span>
                            <Button shape="round"className="modal-okay-orange font-14 square w-900" type={this.state.onboarding?"danger":"danger"} style={{margin: "20px auto 60px auto"}}  onClick={() => window.location.href="/upload-product-model?origin=/product-model"} disabled={this.state.onboarding?true:false}>
                                Upload <ArrowRightOutlined />
                            </Button>
                        </Card>
                        <Card style={{marginLeft: 12,padding:20}} bodyStyle={{width: 420, textAlign:"center"}} data-tut="reactour__create_product_model">
                            <img style={{width: 120, marginBottom: 20,marginTop: 60}} alt="Create Product Model" src={require("../../../../assets/images/mesh-box.png")}></img>
                            <span style={{marginTop: 20}}>
                                <h3 className="manrope f-18 black-14 w-600 justify-in-center">Create A New Product Model&nbsp;&nbsp;<Tooltip title={<span className='manrope f-12 white'>If you want to create your product model from scratch or need changes to your 3D model, please provide the required information in the form ahead.</span>}><InfoCircleOutlined/></Tooltip></h3>
                            </span>
                            <Button shape="round" type="danger"  className="modal-okay-orange square font-14 w-900" style={{margin: "20px auto 60px auto"}} onClick={() => this.createNewProductPage()}>
                              Create<ArrowRightOutlined />
                            </Button>
                        </Card>
                        
                        
                    </Row>
                </div>
            </div>
            </CustomerMainLayout>
        );
    }
}

export default withRouter(ProductModelRequest);
