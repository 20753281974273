import React from "react";
import AmazonOauthCard from "../AmazonOauth/AmazonAuthCard";
import CustomerMainLayout from '../CustomerMainLayout'
import "./integrations.scss"

const Intergrations = () => {

    return (
        <>
            <CustomerMainLayout >
                <div style = {{marginTop:"40px"}}>
                    <span style={{padding:"20px"}} className="manrope f-36">Integrations</span> 
                    <div className="integrations-container">
                        <AmazonOauthCard/>
                    </div>
                </div>
            </CustomerMainLayout>
        </>
    );
}

export default Intergrations;
