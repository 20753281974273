import React from 'react';
import ENVIRONMENT from '../../../../environments';
import { Input, InputNumber, Button, Form, Modal } from 'antd';
import AdminMainLayout from '../AdminMainLayout';
import axios from 'axios';

class AdminCreateInvoice extends React.Component {
    
    handleCreateInvoice = values => {
        console.log(values)
        axios.post(ENVIRONMENT.INVOICE_CREATE, {
            customer_email: values.customer_email,
            amount: values.amount,
            external_link: values.external_link,
          })
            .then(res => {
                console.log(res);
                console.log(res.data);
                if (res.data.status == 0){
                    Modal.success({
                            content: 'Invoice Created and email sent to customer.',
                        });
                } else {
                    Modal.success({
                        content: res.data.status_message,
                    });
                }
            })
    }

    render (){
        return (
            <AdminMainLayout selected={"9"}>
                <div className="custom-frm" style={{marginTop: 10, maxWidth: 400}}>
                    <h3>Customer Account Invite</h3>
                    <Form className="input-form login-form" onFinish={this.handleCreateInvoice}>
                        <Form.Item
                        name="customer_email"
                        initialValue={new URLSearchParams(window.location.search).get("email")}
                        rules={[{ required: true, message: 'Please enter customer email!' }]}
                        >
                            <Input
                                placeholder="Customer Email"
                            />
                        
                        </Form.Item>
                        <Form.Item
                        name="amount"
                        rules={[{ required: true, message: 'Please enter invoice amount!' }]}
                        >
                            <InputNumber
                                placeholder="Amount"
                            />
                        
                        </Form.Item>
                        <Form.Item
                        name="external_link"
                        rules={[{ required: false, message: 'Please enter customer email!' }]}
                        >
                            <Input
                                placeholder="External Link containing Details"
                            />
                            
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            Create Invoice
                        </Button>
                    </Form>
                </div>
            </AdminMainLayout>
        );
    }
}

export default AdminCreateInvoice;