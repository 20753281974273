import React, { useState, useEffect, useMemo } from "react";
import ENVIRONMENT from "../../../../environments";
import { message } from "antd";
import { withRouter } from "react-router-dom";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import SpaceDetailsView from "./SpaceDetailsView";
import FileConstants from "../../../../FileConstants";
import SharingModal from "../SharingModal/SharingModal";
import * as Utilities from "../../Utilities";
import { useQuery } from "react-query";
import DottedLoader from "../../DottedLoader";


const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_EMAIL = FileConstants.MANAGED_CUSTOMER_EMAIL;



const SpaceDetails = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [sharingModal, setSharingModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState("embed");
  const [sharingLoader, setSharingLoader] = useState(false);
  const [embedLink, setEmbedLink] = useState(false);
  const [threeSixtyViews, setThreeSixtyViews] = useState({});
  const [rejectionDetails, setRejectionDetails] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [saveSuccessModal, setSaveSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [glbSize, setGlbSize] = useState(0);
  const [archiveSize, setArchiveSize] = useState(0);
  const [variationModal, setVariationModal] = useState(false);
  const [savedID, setSavedID] = useState(-1);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [variationSuccessModal, setVariationSuccessModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const [cardStatus, setCardStatus] = useState(null);
  const [paymentRequestID, setPaymentRequestID] = useState(-1);
  const [cardSaveFailure, setCardSaveFailure] = useState(false);
  const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
  const [requestType, setRequestType] = useState("variation_room_model");
  const [storePage, setStorePage] = useState(0);
  const [compareView, setCompareView] = useState(false);
  const [spaceCategories, setSpaceCategories] = useState([]);
  const [sellVisible, setSellVisible] = useState(false);
  const [sellLoader, setSellLoader] = useState(false);
  const [price, setPrice] = useState(0);
  const [removeFromStore, setRemoveFromStore] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [folderParentID, setFolderParentID] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState({});
  const [approveLoader, setApproveLoader] = useState(false);
  const [rejectionLoader, setRejectionLoader] = useState(false);
  const [approvalSuccess, setApprovalSuccess] = useState(false);
  const [baseVariantPlatform, setBaseVariantPlatform] = useState("aws");
  const [baseVariantPlatformLoader, setbaseVariantPlatformLoader] =
    useState(true);
  const [baseImmidiateVariantPlatform, setBaseImmidiateVariantPlatform] =
    useState("aws");
  const [
    baseImmidiateVariantPlatformLoader,
    setbaseImmidiateVariantPlatformLoader,
  ] = useState(true);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [updatingAccessEmails, setUpdatingAccessEmails] = useState({});
  const [accessUpdateLoader, setAccessUpdateLoader] = useState(false);
  const [inviteLoader, setInviteLoader] = useState(false);
  const [publicLinks, setPublicLinks] = useState({});
  const [userAccess, setUserAccess] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const {
    match: { params },
  } = props;


  const roomDetailsFetcher = async (id) => {
    return await axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: id }).then((res)=> ({ ...res?.data, isCreated: false, isUpdated: false }));
  };



  const getUserProjects = async () => {
    let payload = {
      order_by: "customer_username",
      required_fields: ["id", "name", "customer_username"],
      filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
    };

    return axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
      let projects = [];
      if (MANAGED_CUSTOMER_USERNAME) {
        projects = res.data.map((item) => ({
          ...item,
          name: `[${item.customer_username}] ${item.name} `,
        }));
        return projects;
      } else {
        return res.data;
      }
    });
  };

  const {
    data: userProjects,
    error: userProjectsError,
    isLoading: userProjectsLoading,
  } = useQuery(
    [`${ENVIRONMENT.GET_PROJECT_BATCH}`, CUSTOMER_USERNAME],
    () => getUserProjects(),
    {
      enabled: !!CUSTOMER_USERNAME,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data,
    error,
    isLoading,
  } = useQuery(
    [
      `${ENVIRONMENT.FETCH_ROOM}/${props?.match?.params?.id}`,
      props?.match?.params?.id,
    ],
    () => roomDetailsFetcher(props?.match?.params?.id),
    {
      enabled: !!props?.match?.params?.id,
      refetchOnWindowFocus: false,
    }
  );

  const companyNameFetcher = async (id) => {
    return axios
      .post(ENVIRONMENT.GET_CUSTOMER_AND_COMPANY, {
        company_id: id,
      })
      .then((res) => {
        return { ...res.data };
      });
  };

  const {
    data: companyData,
    error: companyDataError,
    isLoading: companyDataLoading,
  } = useQuery(
    [
      `${ENVIRONMENT.GET_CUSTOMER_AND_COMPANY}/${data?.company_id}`,
      data?.company_id,
    ],
    () => companyNameFetcher(data?.company_id),
    { enabled: !!data?.company_id }
  );

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isFullScreen]);

  useEffect(() => {
    axios
      .post(ENVIRONMENT.GET_ROOM_REJECTIONS, { room_id: params.id })
      .then((res) => {
        console.log(res);
        setRejectionDetails(res.data);
      });
    let payment_redirected = new URLSearchParams(window.location.search).get(
      "payment_redirected"
    );
    let payment_failure = new URLSearchParams(window.location.search).get(
      "payment_failure"
    );
    let payment_request_id = new URLSearchParams(window.location.search).get(
      "request_id"
    );
    setRequestType("variation_room_model");
    if (payment_redirected) {
      //    submitVariation(0);
    }
    if (payment_failure) {
      // setCardSaveFailure(true);
      if (payment_request_id) {
        setPaymentRequestID(payment_request_id);
        setCardSaveDrawer(true);
      }
    }
    Utilities.getUserAccess("room", params.id, setUserAccess);
    setRequestType("variation_room_model");
    loadUserRequestStatus();
    setSpaceCategoriesData();
    setRoomPageType();
  }, []);

  const getCompanyUsersEmails = () => {
    let emails = [];
    if (MANAGED_CUSTOMER_USERNAME) {
      emails.push({
        email: MANAGED_CUSTOMER_EMAIL,
        username: MANAGED_CUSTOMER_USERNAME,
      });
      setCompanyUsers(emails);
    }
    if (COMPANY_ID) {
      let payload = {
        required_fields: ["email", "username"],
        filter_string: `(company_id__exact=${COMPANY_ID})`,
        order_by: "email asc",
      };
      axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload).then((res) => {
        if (res && res.data) {
          console.log("Company User Emails", res.data);
          emails = res.data?.filter(
            (item) => item.username != CUSTOMER_USERNAME
          );
          setCompanyUsers(emails);
        }
      });
    }
  };

  useEffect(()=>{
    if(data) { 
      let publicLinks = {
      'Default Space': `${ENVIRONMENT.PORTAL_LINK}360_public_viewer/space/${params.id}/360Camera.jpg`,
      'Space Model File': (ENVIRONMENT.getBaseURL(data['platform']) + ENVIRONMENT.SPACE_LOW_POLY_URI + params.id + '.zip')
      }
      
      if(data.three_sixties) {
          data.three_sixties.map((three_sixty) => {
              publicLinks[three_sixty] = `${ENVIRONMENT.PORTAL_LINK}360_public_viewer/space/${params.id}/${three_sixty}` 
          })
      }

    setPublicLinks(publicLinks);
  }

  },[data])

  const performStoreAction = () => {
    //remove item from store if
    if (data?.is_store_item == 1) {
      setRemoveFromStore(true);
    } else {
      setSellVisible(true);
      setSellLoader(false);
    }
  };

  const cancelSellModal = () => {
    setSellVisible(false);
    setSellLoader(false);
  };

  const onChangePrice = (e) => {
    console.log(e.target.value);
    setPrice(e.target.value);
  };

  const removeItemFromStore = () => {
    let payload = {
      is_store_item: 0,
      room_id: params.id,
    };
    console.log(payload);
    setRemoveLoader(true);
    axios
      .post(ENVIRONMENT.UPDATE_ROOM, payload)
      .then((res) => {
        setStoreRequests();
      })
      .catch((error) => {
        setRemoveLoader(false);
        console.log(error);
        message.error("Error in removing from store.");
      });
  };

  const setStoreRequests = () => {
    let payload = {
      room_id: params.id,
    };
    if (data?.is_store_item == -2) {
      payload.is_store_item = -2;
    } else if (data?.is_store_item == 1) {
      payload.is_store_item = 0;
    } else {
      payload.is_store_item = -2;
    }

    let price_payload = {
      entity_id: params.id,
      customer_username: localStorage.getItem("username"),
      entity_type: "room",
      proposed_price: price,
    };
    setRemoveLoader(true);
    setSellLoader(true);
    console.log(price_payload);
    axios
      .post(ENVIRONMENT.UPDATE_ROOM, payload)
      .then((res) => {
        if (data?.is_store_item == -2) {
          price_payload.action = "update";
        } else if (data?.is_store_item == 1) {
          price_payload.action = "remove";
        } else {
          price_payload.action = "insert";
        }

        updateStore(price_payload);
      })
      .catch((error) => {
        setRemoveLoader(false);
        console.log(error);
        message.error("Error in removing from store.");
      });
  };

  const updateStore = (payload) => {
    axios
      .post(ENVIRONMENT.SET_STORE_PRICE, payload)
      .then((response) => {
        setSellLoader(false);
        setRemoveLoader(false);
        setRemoveFromStore(false);
        if (data?.is_store_item == -2) {
          message.info("Price updated successfully!");
        } else if (data?.is_store_item == 1) {
          message.info("Item removed from store successfully!");
        } else {
          message.info("Price submitted successfully!");
        }
        window.location.href = "/rooms";
      })
      .catch((error) => {
        setSellLoader(false);
        console.log(error);
        message.error("Error.");
      });
  };

  const setSpaceCategoriesData = () => {
    let payload = {
      request_type: "room_model",
    };
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload).then((res) => {
      let categories = [];
      if (res.data) {
        res.data.forEach((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
        });
        setSpaceCategories(categories);
      }
    });
  };

  const loadUserRequestStatus = () => {
    let payload = {
      username: localStorage.getItem("username"),
      action: "check",
      request_type: "variation_room_model",
    };
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload).then((res) => {
      setRequestStatus(res.data["request_allowed"]);
      setCardStatus(res.data["card_status"]);
    });
  };

  const setRoomPageType = () => {
    //load store page detaills if link is for room-assets, otherwise load all details
    console.log(props.match);
    if (props && props.match) {
      if (props.match.path.includes("room-assets")) {
        setStorePage(1);
      } else if (props.match.path.includes("room")) {
        setStorePage(0);
      }
    }
  };

  useEffect(() => {
    getCompanyUsersEmails();
  }, [params]);

  useEffect(() => {
    let room_id = params.id.toString();
    if (savedID != -1) {
      room_id = savedID.toString();
      console.log("Progress Previosuly Saved");
      axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: room_id }).then((res) => {
        console.log("Saved ", res.data);
        setSpaceDetails(res.data);
      });
    }
  }, [savedID]);

  useEffect(() => {
    let room_id = params.id.toString();
    axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: room_id }).then((res) => {
      console.log("Saved ", res.data);
      setSelectedSpace(res.data);

      let room_id = null;
      if (res.data?.immediate_parent_variant) {
        room_id = res.data?.immediate_parent_variant;
        axios.post(ENVIRONMENT.FETCH_ROOM, { room_id }).then((res) => {
          setBaseImmidiateVariantPlatform(res.data?.platform);
          setbaseImmidiateVariantPlatformLoader(false);
        });
      }
      if (res.data?.variant_of) {
        room_id = res.data?.variant_of;
        axios.post(ENVIRONMENT.FETCH_ROOM, { room_id }).then((res) => {
          setBaseVariantPlatform(res.data?.platform);
          setbaseVariantPlatformLoader(false);
        });
      }
    });
  }, []);



  const submitVariation = (status) => {
    setVariationModal(false);
    if (status == 0) {
      setVariationSuccessModal(true);
    } else {
      setSaveModal(true);
    }
  };

  const setFullScreen = (value) => {
    setIsFullScreen(value);
    if (value) {
      if (threeSixtyViews[selectedImage]) {
        threeSixtyViews[selectedImage].toggleFull(true);
      }
    } else {
      if (threeSixtyViews[selectedImage]) {
        threeSixtyViews[selectedImage].toggleFull(false);
      }
    }
  };

  const openVariationModal = () => {
    setVariationModal(true);
  };

  const cancelVariationModal = () => {
    setVariationModal(false);
  };

  const getGlbSize = () => {
    let link = (data?.download_links || []).find(
      (ele) => ele.display_name === "GLB"
    );
    let size = 0;
    if (link) {
      axios
        .head(
          link.model_url.replace(
            ENVIRONMENT.DEFAULT_BASE_URL,
            ENVIRONMENT.getBaseURL(data["platform"])
          )
        )
        .then((res) => {
          size = parseInt(res.headers["content-length"]);
          setGlbSize(size);
        });
    }
  };

  const getArchiveSize = () => {
    let link = (data?.download_links || []).find(
      (ele) => ele.display_name === "MAX"
    );
    let size = 0;

    if (link) {
      axios
        .head(
          link.model_url.replace(
            ENVIRONMENT.DEFAULT_BASE_URL,
            ENVIRONMENT.getBaseURL(data["platform"])
          )
        )
        .then((res) => {
          size = parseInt(res.headers["content-length"]);
          setArchiveSize(size);
        });
    }
  };

  const convertByteSize = (size_in_mbs) => {
    let result = size_in_mbs.toFixed(2) + " MBs";
    if (size_in_mbs / 1024 > 1024) {
      // means more then 1 GB
      size_in_mbs = (size_in_mbs / 1024).toFixed(2); // size in GBs
      result = size_in_mbs + " GBs";
    }
    return result;
  };

  const onReject = (values) => {
    let assigned_artist = data?.assigned_artist;
    let payload = {
      room_id: params.id,
      rejection_details: values.msg,
      rejection_against_artist: assigned_artist,
      reference_files: values.reference_files,
      username: localStorage.getItem("username"),
    };
    axios
      .post(ENVIRONMENT.ENTITY_MODEL_REJECT, payload)
      .then((res) => {
        let update_payload = {
          room_id: params.id,
          model_status: "-4",
          username: localStorage.getItem("username"),
        };
        axios
          .post(ENVIRONMENT.UPDATE_ROOM, update_payload)
          .then((res) => {
            window.location.href = "/home";
          })
          .catch((error) => {
            message.error("Error in rejecting room.");
          });
      })
      .catch((error) => {
        message.error("Error in rejection room APi.");
      });
  };

  const handleEmbedCancel = (e) => {
    setEmbedLink(false);
  };

  const onApprove = () => {
    setApproveLoader(true);
    let payload = {
      room_id: params.id,
      model_status: "5",
      username: localStorage.getItem("username"),
    };
    axios.post(ENVIRONMENT.UPDATE_ROOM, payload).then((res) => {
      if (res) {
        if (
          FileConstants.isMSProvider &&
          selectedSpace.requested_for != undefined &&
          selectedSpace.requested_for != ""
        ) {
          const transfer_payload = {
            entity_type: "room",
            room_id: params.id,
            requested_for: selectedSpace.requested_for,
          };
          axios
            .post(ENVIRONMENT.TRANSFER_OWNERSHIP, transfer_payload)
            .then((result) => {
              setApprovalSuccess(true);
            });
        } else {
          setApprovalSuccess(true);
        }
      }
    });
  };

  const handleShare = (email) => {
    setAccessUpdateLoader(true);
    // Keep track of email address whose access is being updated
    let emails = updatingAccessEmails;
    emails[email] = true;
    setUpdatingAccessEmails(emails);
    forceUpdate();
    let body = {};
    body.room_id = params.id;
    body.username = localStorage.getItem("username");
    let lowercase_emails = [email.toLowerCase()];
    body.emails = lowercase_emails;
    let permissions = {};
    permissions[selectedPermission] = 1;
    body.permissions = permissions;
    console.log(JSON.stringify(body));
    axios.post(ENVIRONMENT.SHARE_ROOM, { body }).then((res) => {
      setAccessUpdateLoader(false);
      let updating_emails = updatingAccessEmails;
      updating_emails[email] = false;
      setUpdatingAccessEmails(updating_emails);
      console.log(updatingAccessEmails);
      message.success("Space Shared Successfully!");
      setInviteLoader(false);
      forceUpdate();
    });
  };

  const downloadSceneImagesArchive = () => {
    let downloadData = [];
    if (data?.three_sixties != undefined) {
      if (data?.three_sixties && data?.three_sixties.length == 0) {
        downloadData.push(
          ENVIRONMENT.getBaseURL(data["platform"]) +
            ENVIRONMENT.ROOM_PANORAMA_ASSET_URI +
            params.id +
            "/360Camera.jpg"
        );
      } else {
        if (data?.three_sixties) {
          data.three_sixties.map((url) => {
            downloadData.push(
              ENVIRONMENT.getBaseURL(data["platform"]) +
                ENVIRONMENT.ROOM_PANORAMA_ASSET_URI +
                params.id +
                "/" +
                url
            );
          });
        }
      }
    }
    if (test360Renders && test360Renders.length > 0) {
      test360Renders.map((render) => {
        let scene_id = data.dummy_scene_id;
        if (render.generated_by === "Customer") {
          scene_id = data.customer_dummy_scene_id;
        }
        downloadData.push(
          ENVIRONMENT.getBaseURL(render.platform) +
          ENVIRONMENT.SCENE_THUMBNAIL_URI +
          scene_id +
          "/" +
          render.filename
        );
      });
    }
    Utilities.saveToZip("Space 360s.zip", downloadData, true);
  };

  const openEditForm = () => {
    setEditForm(true);
  };

  const closeEditForm = () => {
    setEditForm(false);
  };

  const saveSpaceData = (values) => {
    console.log(values);

    if (
      values.room_name == data?.room_name &&
      values.reference_urls == data?.reference_urls &&
      values.brand_id == data?.brand_id &&
      values.tags == data?.tags &&
      data?.category == values.category &&
      data?.style == values.style
    ) {
      setErrorMessage(
        "You have not made any change to your space model's data?. Kindly make changes to the data and 'Save'."
      );
    } else {
      if (values.room_name && values.category) {
        setErrorMessage("");
        setButtonLoader(true);
        let payload = {
          room_id: params.id,
          room_name:
            values.room_name == "" ? data?.room_name : values.room_name,
          reference_urls: values.reference_urls,
          brand_id: values.brand_id,
          tags: values.tags,
          style: values.style,
          category: values.category == "" ? data?.category : values.category,
          username: localStorage.getItem("username"),
        };
        axios
          .post(ENVIRONMENT.UPDATE_ROOM, payload)
          .then((res) => {
            setButtonLoader(false);
            setEditForm(false);
            setSaveSuccessModal(true);
            const currentRoute= window.location.href;
            window.location.href= currentRoute;
          })
          .catch((error) => {
            message.error("Error in approving product.");
          });
      } else {
        setErrorMessage("You cannot leave mandatory fields empty.");
      }
    }
  };

  const onMouseOut = (evt) => {
    let element = document.getElementById("edit-icon");
    if (element) {
      element.setAttribute("src", "/img/edit-w-hover.png");
    }
  };

  const onMouseOver = (evt) => {
    let element = document.getElementById("edit-icon");
    if (element) {
      element.setAttribute("src", "/img/edit-icon.png");
    }
  };

  // get folder parent id from parameters and set it
  const getURLParamsAndSetVariationParentID = () => {
    const url = new URL(window.location);
    let id = new URLSearchParams(url.search).get("folder_id");
    setFolderParentID(id);
  };

  useEffect(() => {
    getURLParamsAndSetVariationParentID();
  }, [folderParentID]);

  const testRendersFiltered = useMemo(
    () =>
      data?.test_renders.filter(
        (obj) => !obj?.is_hidden && obj?.generated_by === "Customer"
      ),
    [data]
  );

  const testImageRenders = useMemo(
    () =>
      data?.test_renders.filter(
        (obj) => !obj?.is_hidden && obj?.generated_by === "Customer" && obj?.type == "still"
      ),
    [data]
  );

  const test360Renders = useMemo(
    () =>
      data?.test_renders.filter(
        (obj) => !obj?.is_hidden && obj?.generated_by === "Customer" && obj?.type == "360"
      ),
    [data]
  );

  const downloadImagesMapping = useMemo(() => {

    const base_uri = ENVIRONMENT.BASE_URI + "space_test_renders/";

    const rendersList =testRendersFiltered? testRendersFiltered?.map((obj) => {
        const user_type =
          obj.generated_by === "Customer"
            ? "Cust."
            : obj.generated_by
            ? obj.generated_by
            : "Artist";

        const scene_id =
          user_type == "Cust."
            ? data.customer_dummy_scene_id
            : data.dummy_scene_id;

        let url =
          ENVIRONMENT.getBaseURL(data?.platform) +
          base_uri +
          obj.uid +
          "/" +
          obj.name;
        if (obj.status == "rendered") {
          url =
            ENVIRONMENT.getBaseURL(data?.platform) +
            ENVIRONMENT.SCENE_THUMBNAIL_URI +
            scene_id +
            "/" +
            obj.filename;
        } else if (obj.status == "pending") {
          url =
            ENVIRONMENT.getBaseURL(data?.platform) +
            ENVIRONMENT.SCENE_THUMBNAIL_URI +
            scene_id +
            "/" +
            obj.preview_render?.replace("tiff", "jpg");
        }

        return url ? { url: url, type: "render", status: obj.status } : null;
      })
      .filter((obj) => obj):[];

    const topViewList = data?.top_view_render
      ? [
          {
            url:
              ENVIRONMENT.getBaseURL(data?.platform) +
              ENVIRONMENT.SPACE_TOP_VIEW_RENDER_URI +
              data?.top_view_render,
            type: "top_view",
          },
        ]
      : [];

    const combinedList2DImages = [...rendersList, ...topViewList];

    const mapping= combinedList2DImages.reduce((acc, current) => {
      const { type, ...rest } = current;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(rest);
      return acc;
    }, {});

    return mapping;
  }, [testRendersFiltered, data?.top_view_render]);


  const downloadImagesByType = (type, directoryName) => {
    const imageUrlsListByType = downloadImagesMapping[type]?.map((obj) => obj.url);

    Utilities.saveToZip(directoryName, imageUrlsListByType, true);
  };


  return (
    <>
      {!isLoading && data ? (
        <>
          <SpaceDetailsView
            folderParentID={folderParentID}
            roomDetails={data}
            roomReducer={props.roomReducer}
            isLoading={isLoading}
            compareView={compareView}
            setCompareView={setCompareView}
            downloadSceneImagesArchive={downloadSceneImagesArchive}
            setEmbedLink={setEmbedLink}
            setSharingModal={setSharingModal}
            setFullScreen={setFullScreen}
            isFullScreen={isFullScreen}
            params={params}
            match={props?.match}
            onApprove={onApprove}
            onReject={onReject}
            embedLink={embedLink}
            handleEmbedCancel={handleEmbedCancel}
            sharingModal={sharingModal}
            sharingLoader={sharingLoader}
            handleShare={handleShare}
            successModal={successModal}
            setSelectedPermission={setSelectedPermission}
            setSuccessModal={setSuccessModal}
            selectedImage={selectedImage}
            openEditForm={openEditForm}
            closeEditForm={closeEditForm}
            editForm={editForm}
            saveSpaceData={saveSpaceData}
            buttonLoader={buttonLoader}
            saveSuccessModal={saveSuccessModal}
            errorMessage={errorMessage}
            onMouseOut={onMouseOut}
            getArchiveSize={getArchiveSize}
            getGlbSize={getGlbSize}
            onMouseOver={onMouseOver}
            rejectionDetails={rejectionDetails}
            convertByteSize={convertByteSize}
            variationModal={variationModal}
            openVariationModal={openVariationModal}
            cancelVariationModal={cancelVariationModal}
            savedID={savedID}
            spaceDetails={spaceDetails}
            submitVariation={submitVariation}
            variationSuccessModal={variationSuccessModal}
            saveModal={saveModal}
            setVariationSuccessModal={setVariationSuccessModal}
            setSaveModal={setSaveModal}
            setSaveSuccessModal={setSaveSuccessModal}
            paymentRequestID={paymentRequestID}
            requestType={requestType}
            cardStatus={cardStatus}
            requestStatus={requestStatus}
            cardSaveFailure={cardSaveFailure}
            setCardSaveFailure={setCardSaveFailure}
            cardSaveDrawer={cardSaveDrawer}
            setCardSaveDrawer={setCardSaveDrawer}
            storePage={storePage}
            spaceCategories={spaceCategories}
            performStoreAction={performStoreAction}
            sellVisible={sellVisible}
            price={price}
            onChangePrice={onChangePrice}
            cancelSellModal={cancelSellModal}
            sellLoader={sellLoader}
            setStoreRequests={setStoreRequests}
            removeFromStore={removeFromStore}
            setRemoveFromStore={setRemoveFromStore}
            removeLoader={removeLoader}
            removeItemFromStore={removeItemFromStore}
            selectedSpace={selectedSpace}
            approveLoader={approveLoader}
            rejectionLoader={rejectionLoader}
            setRejectionLoader={setRejectionLoader}
            approvalSuccess={approvalSuccess}
            setApprovalSuccess={setApprovalSuccess}
            baseVariantPlatform={baseVariantPlatform}
            baseVariantPlatformLoader={baseVariantPlatformLoader}
            baseImmidiateVariantPlatform={baseImmidiateVariantPlatform}
            baseImmidiateVariantPlatformLoader={
              baseImmidiateVariantPlatformLoader
            }
            companyUsers={companyUsers}
            updatingAccessEmails={updatingAccessEmails}
            inviteLoader={inviteLoader}
            accessUpdateLoader={accessUpdateLoader}
            userAccess={userAccess}
            userProjects={userProjects}
            testRendersFiltered={testRendersFiltered}
            companyData={companyData}
            setRemoveLoader={setRemoveLoader}
            setSellLoader={setSellLoader}
            downloadImagesByType={downloadImagesByType}
            downloadImagesMapping={downloadImagesMapping}
            testImageRenders={testImageRenders}
            test360Renders={test360Renders}
          />
          <SharingModal
            entityId={params.id}
            entityType={"room"}
            setSharingModal={setSharingModal}
            modalVisible={sharingModal}
            publicLinks={publicLinks}
            loggedInUserAccess={userAccess}
          />
        </>
      ) : (
        <DottedLoader />
      )}
    </>
  );
};

export default withRouter(SpaceDetails);
