
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class NormalLoginFormDummy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      username: props.registerUsername,
      password: props.registerPassword,
      isLoading: false
    };
    console.log(props);
  }

  componentDidMount() {
    if(this.props.registerUsername !== undefined && this.props.registerPassword !== undefined){
      this.props.onSubmit(this.props.registerUsername, this.props.registerPassword);
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.username, this.state.password);
  }

  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  }

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  componentWillUnmount = () => {
    this.props.clearCache();
  }

  handleSubmit = e => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.props.onSubmit(this.state.username, this.state.password);
    }, 500);
  };

  render() {
    return (
      <Row>
        <Col span={12}>
          <img className="branding-image" src={require("../../../../assets/images/login-branding.jpg")} alt="ALL3D BRAND"/>
        </Col>
        <Col span={12} style={{textAlign: "center"}}>
            <h3 className="text-center text-login-logo" style = {{marginTop: 80}}> <span>All</span><span className="text-red">3D</span> </h3>
            <div style = {{marginTop: 80}}>
            <LoadingOutlined spin />
            </div>
            <div>
              <h3>Logging in to the System.</h3>
            </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => state;
export default withRouter(connect(
  mapStateToProps
)(NormalLoginFormDummy)
)
