import React, {useEffect, useState} from 'react';
import { message, Modal, Select,Button, Form, Progress } from 'antd';
import {listCollections, updateCollection} from "../../../../redux/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { ExceptionOutlined, LoadingOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import CardSaveDrawer from '../../CardSaveDrawer/CardSaveDrawer';
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal'
const { Option } = Select;
const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const IS_MS_PROVIDER = FileConstants.isMSProvider;


const AddCollection = (props) =>  {
    const [visible, setVisible] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [spaceAreaCollections, setSpaceAreaCollections] = useState({});
    const [spaceAreaOptions, setSpaceAreaOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collectionValue,setCollectionValue] = useState(-1);
    const [form] = Form.useForm();
    const [libraryCollection, setLibraryCollection] = useState([]);
    const [successPopup, setSuccessPopup] = useState(false);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [price, setPrice] = useState(-1);
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [gatewayPayload, setGatewayPayload] = useState(null);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [isSubscriptionUpdateRequest, setIsSubscriptionUpdateRequest] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [collectionLoader, setCollectionLoader] = useState(true);

    const handleCancel = () => {
        setAddNew(false);
        setVisible(false);
        props.setCollectionModalVisible(false);
        form.current.resetFields();
        if (props.loadUserRequestStatusProduct != undefined)
            props.loadUserRequestStatusProduct()
    }

    useEffect(() => {
        setVisible(props.visible);
      
    }, [props.visible])

    const getCollectionPayload = () => {
        let payload = {
            "required_fields": ["id", "name", 
            "created_on", "spaces_count", "space_areas", "products_count", "template_collection_id", 
            "product_thumbnails", "thumbnail_link", "designed_for"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"
    
        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("collection")) {
            company_id = "company_id__exact=" + COMPANY_ID
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&(template_collection_id__is=null)"
        } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&(template_collection_id__is=null)"
        }
        filter_string = filter_string + "||(" + has_access_to + ")||(template_collection_id__not=NULL&&" + customer_username+ "&&"+ is_hidden +")&&(designed_for__is=null)"
       
        payload['filter_string'] = filter_string
    
        return payload;
      }

      const getMSPCollectionPayload = () =>
      {
        let payload = {
            "required_fields": ["id", "name", "customer_username", 
            "created_on", "spaces_count", "products_count", 
            "product_thumbnails", "space_areas", "thumbnail_link", "designed_for"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let designed_for = "designed_for__exact='" + MANAGED_CUSTOMER_USERNAME + "'"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"

        if (MANAGED_COMPANY_ID) {
            company_id = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID)
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden + ")&&(" + designed_for + ")"   
          } else {
            filter_string = "("+customer_username+ "&&" + is_hidden + ")&&(" + designed_for + ")" 
          }
          filter_string = filter_string + "||(" + has_access_to + ")||(" + customer_username+ "&&"+ is_hidden +"&&designed_for__is=null)"

          payload['filter_string'] = filter_string
      
          return payload;
    }

    useEffect(() => {
        let payload = {};
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
            payload = getMSPCollectionPayload();
        } else {
            payload = getCollectionPayload();
        }
        props.listCollections(payload);
        
    }, []);

    useEffect(() => {
        if (props.prices && props.prices[0]) {
            setPrice(parseInt(props.prices[0].price));
            setSelectedPriceCategory(props.prices[0].category);
        }
    },[props.prices]);

    useEffect(() => {
        if (props.collectionReducer.collections) {
            setCollectionLoader(false);
        }
    }, [props.collectionReducer])

    useEffect(() => {
        if (props.collectionReducer.collections) {
            let collections = [];
            if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
                if (props.collectionReducer.collections){
                    collections = props.collectionReducer.collections;
                    let unique = Array.from(new Set(collections.map(JSON.stringify))).map(JSON.parse);
                    let filtered_collection = unique.filter((collection) => (collection.designed_for == localStorage.getItem('managed_customer_username')) || collection.designed_for == "");
                    addSpaceAreas(filtered_collection);
                    setLibraryCollection(filtered_collection);
                }
            } else {
                collections = props.collectionReducer.collections;
                if (FileConstants.isMSProvider) {
                    collections = props.collectionReducer.collections.filter((collection) => collection.designed_for == "");
                }
                addSpaceAreas(collections);
                setLibraryCollection(collections);
            }
        }
    },[props]);

    const addSpaceAreas = (collections) => {
        let spaceAreaCollections = {};
        for(let collection of collections){
            if(collection.space_areas != undefined){
                if (collection.space_areas.length > 0){
                    spaceAreaCollections[collection.id] = collection.space_areas;
                }
            }
        }
        setSpaceAreaCollections(spaceAreaCollections);
    }
        
    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'save_card') {
              message.success('Payment Method Updated Successfully.')
            }      
        }, false);
      
        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
        if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);  
        }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const handleSubmit = (values) => {
        console.log(values)
       
        if (addNew){
            addSpaceAllocationToCollection(props.productId, collectionValue, values.areas);
            setAddNew(false);
        }
        else{
            let payload = {product_id: props.productId};

            if (collectionValue) {
                payload.collection_id = collectionValue;
            }
            payload['add_to_library'] = {
                "product_id": props.productId,
                "username": localStorage.getItem('username')
            }
            console.log(payload)
            let requestPayload = payload;
            requestPayload['username'] = localStorage.getItem('username');
            requestPayload['update_collection_metadata'] = true;

            let gateway_payload = {
                "username": localStorage.getItem('username'),
                "request_type": "product_prop",
                "action": "perform",
                "update": "",
                "request_payload": requestPayload,
                "price_category": selectedPriceCategory,
                "request_id": Date.now().toString()
            }
            if(props.addedToLibrary == true){
                processRequestSubmission(gateway_payload);
            }
            else{
                if (props.requestStatus == "always_allowed" || props.requestStatus == "allowed") {
                    processRequestSubmission(gateway_payload);
                }
            
                if (props.requestStatus == "payment_required" ) {
                    if (props.cardStatus == 1) {
                        processRequestSubmission(gateway_payload);
                        
                    }
                    if (props.cardStatus == 0) {
                        setGatewayPayload(gateway_payload)
                        window.addEventListener('card_saved', (e) => {
                            let action = e.detail.action;
                            setCardSaveFlowTriggered(false);
                            if (action == 'product_prop') {
                                processRequestSubmission(gateway_payload);
                            }
                            
                          }, false);

                        let child_window = window.open('/save-card?action=product_prop', '', "width=600,height=600");
                            let refreshInterval = setInterval(() => {
                            if(child_window.closed) {
                                setCardSaveFlowTriggered(false);
                                clearInterval(refreshInterval);  
                            }
                        }, 500);
                        setCardSaveFlowTriggered(true);
                    }
                }
            }
        }
    }

    const addSpaceAllocationToCollection = (product_id, collection_id ,selectedAreas) => {
        
        let payload = {}
        payload.product_id = product_id;
        payload.collection_id = collection_id;
        payload.area_names = selectedAreas;
        payload['add_to_library'] = {
            "product_id": props.productId,
            "username": localStorage.getItem('username')
        }
        let requestPayload = payload;
        requestPayload['username'] = localStorage.getItem('username');
        
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_prop",
            "action": "perform",
            "update": "",
            "request_payload": requestPayload,
            "price_category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if(props.addedToLibrary == true){
            processRequestSubmission(gateway_payload);
        }
        else
        {
            if (props.requestStatus == "always_allowed"  || props.requestStatus == "allowed")  {
                processRequestSubmission(gateway_payload);
            }
        
            if (props.requestStatus == "payment_required") {
                if (props.cardStatus == 1) {
                    processRequestSubmission(gateway_payload);
                }
                if (props.cardStatus == 0) {
                    setGatewayPayload(gateway_payload)
                    window.addEventListener('card_saved', (e) => {
                        let action = e.detail.action;
                        setCardSaveFlowTriggered(false);
                        if (action == 'product_prop') {
                            processRequestSubmission(gateway_payload);
                        }
                        
                      }, false);

                    let child_window = window.open('/save-card?action=product_prop', '', "width=600,height=600");
                        let refreshInterval = setInterval(() => {
                        if(child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);  
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }
        }
       

    }

    const processRequestSubmission = (gateway_payload = gatewayPayload) => {
        setLoading(true);
        if(props.addedToLibrary != false || props.entityPrice == 0){
            axios.post( ENVIRONMENT.UPDATE_COLLECTION,gateway_payload.request_payload)
            .then(res => {
                setLoading(false);
                setVisible(false);
                props.setCollectionModalVisible(false);
                setSuccessPopup(true);
                if(props.productsAddedToLibrary != undefined){
                    let product_added = props.productsAddedToLibrary;
                    product_added.push(props.productId);
                    props.setProductsAddedToLibrary(product_added)
                }
            })
        }
        else {
            axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then((response) => {
                if ('request_allowed' in response.data && response.data['request_allowed'] == 'not_allowed') {
                    props.setInAppLimitExceededModal(true);
                    props.setCollectionModalVisible(false);
                    setLoading(false);
                    setCollectionValue(-1);
                }
                else if (response.data.status!=undefined && response.data.status == 1) {
                    props.loadUserRequestStatusProduct()
                    setLoading(false);
                    setVisible(false);
                    if(props.productsAddedToLibrary != undefined){
                        let product_added = props.productsAddedToLibrary;
                        product_added.push(props.productId);
                        props.setProductsAddedToLibrary(product_added)
                    }
                    props.setCollectionModalVisible(false);
                    setSuccessPopup(true);
                }
                else{
                    setPaymentFailureMessage(response.data.error_message);
                    setPaymentFailureModal(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                message.error('An error occured while adding space to collection.')
            });
        }
    }

    const saveRequestIntermedietely = (callback = null) => {
        setLoading(true);
        let payload = {
            'request_type': 'product_prop',
            'request_body': gatewayPayload,
            'processed': false
        }
        
        if (props.paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
            .then(res => {
                console.log(res);
                setLoading(false);
                setVisible(false);
                props.setCollectionModalVisible(false);
                if (callback!= null)
                {
                    callback(res.data[0].request_id,'product_prop');
                }
            });
        } else {
            setLoading(false);
            setVisible(false);
            props.setCollectionModalVisible(false);
            if (callback!= null)
            {
                callback(props.paymentRequestID,'product_prop');
            }
        }
    }

    const initiateSubscriptionConfirmation = () => {

        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'subscription_update') {
              upgradeSubscriptionPlan();
            }
            
          }, false);
        
        if (props.cardStatus == 1) {
            upgradeSubscriptionPlan();
        } 
        if (props.cardStatus == 0) {
            setIsSubscriptionUpdateRequest(true);
            let child_window = window.open('/save-card?action=subscription_update', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                if(child_window.closed) {
                    setCardSaveFlowTriggered(false);
                    clearInterval(refreshInterval);  
                }
            }, 500);
            setCardSaveFlowTriggered(true);
        } 
    }

    const upgradeSubscriptionPlan = () => {
        let payload ={
          username: localStorage.getItem('username'),
          subscription_type: props.subscriptionPlan
        }
        console.log('Subscription payload',payload);
        setButtonLoader(true);
        axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
        .then( (response) => {
          console.log(response.data);
          setSubscriptionModal(false)
          setButtonLoader(false);
          props.loadUserRequestStatusProduct('confirm');
          message.success('Your subscription plan has been successfully upgraded.');
      })
        .catch((error) => {
        });
  
      }

    const onChange = (key,value) =>{
        setCollectionValue(value.key)
        if(value.key in spaceAreaCollections){
            setAddNew(true);
            setSpaceAreaOptions(spaceAreaCollections[value.key]);
        }
        else{
            setAddNew(false);
        }
    }

    return (
        <div>
            <Modal bodyStyle={{paddingTop: "0px"}}
            destroyOnClose={true}
            visible={visible}
            width={600}
            maskClosable={false}
            className="silo-success-modal"
            onCancel={handleCancel}
            footer={[
            <div className={"justify-space-between"}>
                <Button disabled={loading} className="modal-okay-gray square font-14" onClick={handleCancel}>
                Cancel
                </Button>
                <Button disabled={loading || collectionValue == -1 || cardSaveFlowTriggered} className="modal-okay square font-14" onClick={() => document.getElementById("assignment-submit-button").click()}>
                {
                props.entityPrice == 0? "Add":
                props.cardStatus == 1 && props.requestStatus == "payment_required" ? "Confirm & Process Payment" :
                props.cardStatus == 0 && props.requestStatus ==  "payment_required" ?"Confirm & Enter Payment Details" : "Add"} {loading == true ? <LoadingOutlined type="sync" spin />: ''}
                </Button>
            </div>
            ]}>
                <div style={{padding:'20px 10px'}}>
                    <div className="manrope f-18 black-14 w-700"  style={{marginBottom: 30}}>Add Product to Collection</div>
                    {collectionLoader && <div className="manrope f-12 black-14"  style={{marginBottom: 30}}><LoadingOutlined/>Fetching collection...</div>}
                    <Form ref={form} onFinish={handleSubmit} layout="vertical">
                        <Form.Item className="material-category-form" rules={[{ required: false, message: 'Please select a collection.' }]} name="collection_id"  label={''} colon={false}>
                            <Select
                                showSearch
                                placeholder="Select Collection"
                                className="manrope black-55 tree-select-material f-12"
                                onChange={(key,value) => onChange(key,value)}
                                notFoundContent={<span className="manrope f-12 select-view">No such collection found.</span>}>
                                {libraryCollection.map((ele, index) => {
                                    if (ele.spaces_count > 0){
                                        return <Option className="manrope f-12 select-view" key={ele.id} value={ele.name}>{ele.name}</Option>
                                    }
                                })}
                            </Select>
                        </Form.Item>
                        {addNew == true ? 
                        <Form.Item name="areas" rules={[{ required: addNew? true :false, message: 'Please Select Area' }]} label={<span className="manrope f-18 black-33">Placement Area</span>} colon={false}>
                            <Select
                                mode="tags"
                                showSearch
                                className="manrope f-12 black-55 select-tags" 
                                placeholder="Select Area">
                                {spaceAreaOptions.map((ele, index) => {
                                    return   <Option className="manrope f-12 black-55 select-view" key={index} value={ele}>{ele}</Option>
                                })}
                            </Select>
                                
                            </Form.Item> : ""
                        }

                        <Form.Item>
                            <Button id="assignment-submit-button" type="primary" htmlType="submit" style={{"display": "none"}}>
                                Submit
                            </Button>
                         </Form.Item>

                         {props.requestStatus == 'payment_required' && props.entityPrice != 0 ?
                            <div style={{marginTop: 12}} className="payment-modal-border full  justify-space-between">
                                <span className="manrope f-14 black-55">
                                    Price
                                </span>
                                <span className="manrope f-14 green w-700">
                                    ${props.entityPrice}
                                </span>
                            </div> :
                            // <div style={{marginTop: 12}} className="payment-modal-border full">
                            //     <div className='justify-space-between' style={{marginBottom:4}}>
                            //         <span className="manrope f-14 black-55">
                            //             Subscription Usage (Product Props)
                            //         </span>
                            //         <span className="manrope f-14 green w-700">
                            //             {props.usedCount}/{props.usedCount + props.availableCount}
                            //         </span>
                            //     </div>
                            //     <Progress percent={(props.usedCount/(props.usedCount + props.availableCount))*100} showInfo={false} type='line' strokeColor={"#25A759"} trailColor={"#D9D9D9"}/>
                            // </div>
                            ''
                            }
                            { (props.addedToLibrary == false && props.entityPrice != 0 && setSubscriptionModal != undefined && props.requestStatus == "payment_required" && (props.isALL3DEntity != undefined && props.isALL3DEntity)) ?
                            <div style={{textAlign: 'center',marginTop: 8}}>
                                Click <a onClick={() => setSubscriptionModal(true)}>here</a> to check subscription Packages for discounts
                            </div>
                            :''
                            }
                    </Form>
                </div>
            </Modal>
            <SuccessModal
            visible={successPopup || props.showSuccesProduct}
            onCancel={() => setSuccessPopup(false)}
            heading={"Added Successfully!"}
            text={<span>Product has been added to your collection successfully! Go to your <a className="manrope f-16 blue link" href="/list-collections">Library</a> to start using this collection. </span>}
            footer={[
                <div className="justify-in-center">
                    {
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => setSuccessPopup(false)} >
                        Okay
                    </Button>
                    }
                </div>
                
            ]}
            />
         
            <CardSaveDrawer
            saveRequestIntermedietely={saveRequestIntermedietely}
            setCardSaveFailure={props.setCardSaveFailure}
            visible={props.cardSaveDrawer}
            onClose={() => props.setCardSaveDrawer(false)}
            />
            <WarningModal
            visible={props.cardSaveFailure}
            onCancel={() => props.setCardSaveFailure(false)}
            heading={"Unable to Save Card"}
            text={"We are very sorry but we were unable to save your card. Please try again."}
            footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => props.setCardSaveFailure(false)}>
                    Okay
                </Button>
            </div>
            ]}/>
            <SubscriptionPackageUpdate
            subscriptionPlan={props.subscriptionPlan}
            setSubscriptionPlan={props.setSubscriptionPlan}
            currentPackage={props.currentPackage}
            currentSubscriptionPlan={props.currentSubscriptionPlan}
            isSubscriptionActive={props.isSubscriptionActive}
            visible={subscriptionModal}
            onCancel={() => setSubscriptionModal(false)}
            heading={<span>You have succeeded the quota for adding product props to your library. Do you want to upgrade your current plan?</span>}
            text={<span>Your current package is selected by default. Select another package to upgrade. </span>}
            footer={[
                <span className="justify-in-end">
                <Button key="back" className="modal-okay-gray square font-14" onClick={() => setSubscriptionModal(false)}>
                    Cancel
                </Button>
                    {(props.cardStatus == 1)?
                        <Button key="back"  className="modal-okay square font-14" onClick={upgradeSubscriptionPlan}>
                        {buttonLoader? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm </span>}
                        </Button>
                        :
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={initiateSubscriptionConfirmation}>
                        {buttonLoader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Enter Payment Details </span>}
                        </Button>
                        }
                </span>
            ]}
            />
        <PaymentFailureModal 
        paymentFailureMessage={paymentFailureMessage}
        setPaymentFailureModal={setPaymentFailureModal}
        updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
        paymentFailureModal={paymentFailureModal}
        />
        </div>
    );

}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listCollections: (payload) => {
        dispatch(listCollections(payload));
    },
    updateCollection: (payload) => {
        dispatch(updateCollection(payload));
    }
});

export default (withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddCollection)
  ));
