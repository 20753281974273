import React, { useState, useEffect, useCallback }  from 'react';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import AWS from 'aws-sdk';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Select, Upload, message, Tooltip } from 'antd';
import * as Constants from "../../../CustomerComponents/Constants";
import { updateRoom, fetchRoom} from "../../../../../redux/actions";
import {connect} from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import $ from 'jquery';
import { BUCKET_NAME } from '../../../../../environments/env';

const { Option } = Select;
const RoomReferenceForm = (props) => {

    const [uploadError, setUploadError] = useState('');
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [photoFileList, setPhotoFileList] = useState([]);
    const [spaceFileList, setSpaceFileList] = useState([]);
    const [rawFileList, setRawFileList] = useState([]);
    const [videoFileList, setVideoFileList] = useState([]);
    const [, updateState] = React.useState();
    const [elementArray, setElementArray] = useState([]);
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue } = props.form;
    const [submitType, setSubmitType] = useState('submit');
    useEffect(() => {

        // Re-populate video files, raw files, manuals, 
            
        let room_photos = props.roomReducer.room.room_photos;
        if (room_photos != undefined && room_photos.length > 0) {
            setSpaceFileList(room_photos);  
        }

        let raw_files = props.roomReducer.room.raw_files;
        if (raw_files != undefined && raw_files.length > 0) {
            setRawFileList(raw_files);  
        }

        let video = props.roomReducer.room.video;
        if (video != undefined && video.length > 0) {
            setVideoFileList(video);
        }

        console.log("roomReducer step3",props.roomReducer.room)
        if(props.roomReducer.isCreated == true || props.roomReducer.isUpdated == true) {
            if(submitType == 'continue') {
                let onboarding_string = '';
                let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                if(onboarding != undefined){
                    onboarding_string = '&onboarding=true'
                }
                let product_state_string = '';
                let product_state = new URLSearchParams(window.location.search).get("product_state");
                if(product_state != undefined){
                    product_state_string = '&product_state=' + product_state
                }

                props.history.push({
                    pathname: '/add-new-room',
                    search: `?step=${submitType == 'continue' ? 3: 1}&room_id=${props.roomReducer.room.room_id}${onboarding_string}${product_state_string}&origin=${props.redirectionPath}`
                });
                props.next();
                window.location.reload();
            } else if (submitType == 'save')  {
                // message.info('Space saved successfully');
                props.handlePageRedirection();
                document.activeElement.blur();
            }
        }
        if(props.productReducer.error) {
            message.error(props.roomReducer.error);
        }

        // Temporary fix for field because field not rendering data in edit mode
        for(let i=0;i<5;i++) {
            setTimeout(() => {
                setFieldsValue(patchRoom(props.roomReducer.room));
            },1000);
        }
    }, [props.roomReducer.room]);

    const handleRedirection = () => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let product_state_string = '';
        let product_state = new URLSearchParams(window.location.search).get("product_state");
        let step_no = '0';
        if(onboarding){
            if (product_state != undefined) {
                step_no = '4'
            } else {
                step_no = '14';
            }
            if(product_state != undefined){
                product_state_string = '&product_state='+product_state
            }
            props.history.push({
                pathname: '/home',
                search: `?step_no=${step_no}&room_state=added${product_state_string}`
            }); 
        } else {
            if(props.redirectionPath){
                props.history.push({
                    pathname: props.redirectionPath
                });  
            } else {
                props.history.push({
                    pathname: '/home'
                });
            }
        }
       
    }

    const handleSubmit = e => {
        e.preventDefault();
        let uploadElements = '';
        let flag = 0;
        props.form.validateFields((err, values) => {
            if (!err) {
                let space_element_check = 1;
                if (elementArray.length > 0) {
                    elementArray.map(element => {
                    
                        let element_status = validateFileUploads(photoFileList[element]);
                        if (element_status == 'uploading') {
                            space_element_check = 0;
                        } else if (element_status == "error") {
                            space_element_check = -1;
                        }
                    
                    });
                }

                let room_photos = validateFileUploads(values['room_photos']);
                let space_videos = validateFileUploads(values['video']);
                let raw_files = validateFileUploads(values['raw_files']);
                if (space_element_check == 0 || room_photos == 'uploading' || space_videos == 'uploading' || raw_files == 'uploading' || (flag == 1)) {
                    setUploadError('Uploads are in progress, please wait till file uploads are complete.')
                }
                else if (space_element_check == -1 || room_photos == 'error' || space_videos == 'error' || raw_files == 'error' || (flag == 2)) {
                    setUploadError('Error occured in uploading file(s), please re-upload your file(s).')
                }
                else {
                    setUploadError('');
                    props.updateRoom({...formatRoom(values), room_id: props.roomReducer.room.room_id});
                }
            }
        });
    };

    const patchRoom = (product) => {
        let updatedProduct = {elements: []};
        Object.keys(product).forEach((key) => {
            if(key != 'elements') {
                updatedProduct[key] = product[key];
            }
        });
        setElementArray([]);
        let element_arr = [];
        product.elements && product.elements.forEach((element) => {
            if (element) {
                element_arr.push(element.elementName);
                setElementArray(element_arr);
                updatedProduct.elements.push(element.elementName);
                updatedProduct[element.elementName] =element.elementImages;
                let text = {};
                if (Array.isArray(element.elementText)) {
                    text = {
                        [element.elementName+"_text"]: element.elementText
                    }
                } else {
                    let element_text = [];
                    element_text.push(element.elementText);
                    text = {
                        [element.elementName+"_text"]: element_text
                    }
                }
                updatedProduct[`${element.elementName}_text`] =element.elementText;
            }
        });
        return updatedProduct;
    }

    const setPhotoFileListByElement = (filesList, element_name) => {
        let newPhotoFileList = photoFileList;
        newPhotoFileList[element_name] = filesList;
        setPhotoFileList(newPhotoFileList);
    }


    const handleChangePhotos = (info,element_name) => {
        let fileList = [...info.fileList];
        let element_photos = photoFileList;
        element_photos[element_name] = fileList;
        setPhotoFileList(element_photos);
      
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }  
            setHasError(true);
            setUploading(false);

        }
    };

    const handleChangeRaw = info => {
        let fileList = [...info.fileList];
      setRawFileList(fileList);
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }  
            setHasError(true);
            setUploading(false);

        }
    };

    const handleChangeVideo = info => {
        let fileList = [...info.fileList];
        setVideoFileList(fileList)
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }  
            setHasError(true);
            setUploading(false);

        }
    };

    const handleChangeSpace = info => {
        let fileList = [...info.fileList];
        setSpaceFileList(fileList);
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }  
            setHasError(true);
            setUploading(false);

        }
    };

    const formatRoom = (form) => {
        let product = {...form};
        product.elements = [];
        if(form.elements) {
            form.elements.forEach((cmp) => {
                let existingFiles = props.roomReducer.room.elements.find((ele) => ele.elementName == cmp);
                if (form[cmp] && form[cmp].elementImages) {
                    product.elements.push({elementName: cmp, elementImages: form[cmp].elementImages, elementText: form[`${cmp}_text`]});
                } else {
                    product.elements.push({
                        elementName: cmp,
                        elementImages: existingFiles ? existingFiles.elementImages : [],
                        elementText: form[`${cmp}_text`] || ''
                    });
                }
            })
        }
        console.log(product);
        return product;
    }

    const triggerRoomPhotoUpload = () => {
        $('#room_reference_room_photos').trigger('click');
        let button = document.getElementById('room_reference_room_photos');
        button.disabled = false;
    }

    const triggerVideoUpload = () => {
        $('#room_reference_video').trigger('click');
        let button = document.getElementById('room_reference_video');
        button.disabled = false;
    }

    const triggerRawUpload = () => {
        $('#room_reference_raw_files').trigger('click');
        let button = document.getElementById('room_reference_raw_files');
        button.disabled = false;
    }

    const triggerSpaceElementUpload = (element_name) => {
        let element = document.getElementById('room_reference_' + element_name);
        $(element).trigger('click');
        let button = element;
        button.disabled = false;
    }

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const addElements = (array) => {
        let elements_array = array.filter(element => element != "");
        console.log(elements_array);
        setElementArray(elements_array);
    }

    const deselectElement = (value) => {
        console.log(value)
        let elements = elementArray.filter(element => element != value);
        console.log('deselected',elements)
        setElementArray(elements);
    }

    const productImages = e => {
        if (Array.isArray(e)) {
            return e;
        }
        var raw_files = {}
        raw_files['elementImages'] = e.fileList;
        return e && raw_files;
    };

    let compUploads = '';
    if (elementArray.length > 0) {
        compUploads= elementArray.map((elementName, index)=> {
            return (
                elementName ?<Col span={12} key={index} style={{marginBottom: 10}}>
                    <h1 style={{ fontSize: '15px', textTransform: "capitalize" }}>{elementName} Details {(<span><Tooltip title={<span className='manrope f-12 white'>Put refernce details and photos</span>}><InfoCircleOutlined /></Tooltip></span>)}</h1>
                    <div style={{border: "1px solid #e3e3e3 ", padding: 20, borderRadius: 4}}>
                    
                        <Form.Item colon={false} label = {<span className='manrope f-14 black-00 w-700'>{`${elementName.charAt(0).toUpperCase() + elementName.substring(1)} Reference URL`}</span>}  name={elementName + '_text'}>
                            {getFieldDecorator(`${elementName}_text`, {
                                    rules: [{ required: false, message: 'Please input element reference URL(s)',type:'array' }],
                                })(
                                    <Select className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter Reference URL(s) of your elements">
                                        <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                    </Select>
                        )}
                        </Form.Item>
                        <div className="d-flex"  style={{justifyContent:"center",cursor:"pointer"}} onClick={() => triggerSpaceElementUpload(elementName)}>
                            <div style={{textAlign:"left",minWidth:"100%"}}>
                                <Form.Item name ={elementName} label={<span className='manrope f-14 black-00 w-700'>{`${elementName.charAt(0).toUpperCase() + elementName.substring(1)} Photos`}</span>}  colon={false} style={{display: 'inline-block', width: "100%"}}>
                                    {getFieldDecorator(elementName, {
                                        valuePropName: elementName,
                                        getValueFromEvent: productImages,
                                        rules: [{ required: false, message: 'Please upload element photo' }],
                                    })(
                                        <Upload defaultFileList={props.roomReducer.room.elements && props.roomReducer.room.elements[index] && props.roomReducer.room.elements[index].elementImages} 
                                        {...(Constants.getUploadProps(props.roomReducer.room.platform))} 
                                        onChange={(info) => {
                                            handleChangePhotos(info,elementName);
                                        }}
                                        multiple={true}  
                                        listType="text" 
                                        openFileDialogOnClick={false}
                                        onChange={(info) => {
                                            handleChangePhotos(info,elementName);
                                            let fileList = [...info.fileList];
                                            let temp_file = [...photoFileList];
                                            temp_file[elementName] = fileList;
                                            setPhotoFileList(temp_file);   
                                            setUploading(true);
                                            if (info.file.status === "done") {
                                                setHasError(false);
                                                setErrorMessage('');
                                                setUploading(false);
                                            } else if (info.file.status === "error") {
                                                setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
                                                if (info.file.error.code.includes('Credentials')) {
                                                    setErrorMessage(info.file.error.code.concat('. Your credentials have expired. Please refresh the page.'));
                                                } 
                                                setHasError(true);
                                                setUploading(false);
                                            }                                     
                                            forceUpdate();
                                        }}
                                        fileList={photoFileList[elementName]}
                                        onRemove={file => {
                                            let button = document.getElementById('room_reference_' + elementName);
                                            button.disabled = true;
                                            let element_photos = photoFileList;
                                            const index = element_photos[elementName].indexOf(file);
                                            const newFileList = element_photos[elementName].slice();
                                            newFileList.splice(index, 1);
                                            element_photos[elementName] = newFileList;
                                            setPhotoFileList(element_photos);                                        
                                            return true;
                                            
                                        }}
                                    
                                        accept=".jpg,.jpeg,.png"
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color"
                                        progress= {{
                                            strokeColor: {
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                            },
                                            strokeWidth: 3,
                                            format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                            }}>
                                                <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                    <div style={{textAlign:"center"}}>
                                                    <img className="" src={require("../../../../../assets/images/upload.png")} alt=""></img>
                                                            <div className="ant-upload-text ant-upload-text-w" >
                                                            <div className="up-info manrope f-12">Drop your <span className="highlight-text">{elementName} </span> photos, or browse</div>
                                                                <div className='manrope f-12'>SUPPORTED FORMATS: JPG, PNG</div>
                                                            </div>
                                                            {(validateFileUploads(photoFileList[elementName]) == 'error') && (<Button
                                                            onClick={(event) => Constants.triggerFailedFilesUpload('room_reference_' + elementName, photoFileList[elementName], (fileList) => setPhotoFileListByElement(fileList, elementName), event)}
                                                            disabled={photoFileList[elementName].length === 0}
                                                            loading={uploading}
                                                            style={{top: 0}}
                                                            className="retry-btn manrope f-12" ghost
                                                            >
                                                            {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                        </Button>)}
                                                    </div>
                                                </div>
                                        </Upload>   
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                        {(validateFileUploads(photoFileList[elementName]) == 'error') ? 
                            <div className="manrope f-12 red" style={{ textAlign: "center"}}>
                                {errorMessage}
                            </div>: ''}
                </div>
                </Col>:''
            );
        });
    }

    return (
        <Form className="input-form" onSubmit={handleSubmit}>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerRoomPhotoUpload}>
                        <div style={{textAlign:"left",minWidth:"100%"}}>
                            <Form.Item name ="room_photos" label={(<span className='manrope f-14 black-00 w-700'>Photos&nbsp;<Tooltip title={<span className='manrope f-12 white'>Photos of entire space taken from each corner of your space</span>}><InfoCircleOutlined /></Tooltip></span>)} colon={false}>
                                {props.roomReducer.room.room_photos && getFieldDecorator('room_photos', {
                                    valuePropName: 'room_photos',
                                    getValueFromEvent:  e => e && e.fileList,
                                    rules: [{ required: false, message: 'You must upload a space photo.' }]
                                })(                                    
                                    <Upload
                                    {...(Constants.getUploadProps(props.roomReducer.room.platform))} 
                                    onChange={handleChangeSpace}
                                    multiple={true}  
                                    listType="text"
                                    fileList={spaceFileList}
                                    onRemove={file => {
                                        let button = document.getElementById('room_reference_room_photos');
                                        button.disabled = true;
                                        const index = spaceFileList.indexOf(file);
                                        const newFileList = spaceFileList.slice();
                                        newFileList.splice(index, 1);
                                        setSpaceFileList(newFileList);
                                        console.log(newFileList)
                                        newFileList.forEach((file) => {
                                            if (file.status !== "error"){
                                                setHasError(false)
                                            }
                                            else {
                                                setHasError(true)
                                            }
                                        })
                                        return true;
                                    }}
                                    accept=".jpg,.jpeg,.png"
                                    openFileDialogOnClick={false}
                                    // openFileDialogOnClick={!(validateFileUploads(spaceFileList) == 'error')} 
                                    className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                    progress= {{
                                        strokeColor: {
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                        },
                                        strokeWidth: 3,
                                        format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                    }}>
                                        <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                <img className="" src={require("../../../../../assets/images/upload.png")} alt=""></img>
                                                        <div className="ant-upload-text ant-upload-text-w" >
                                                        <div style={{lineHeight:"150%"}} className="up-info manrope f-10">Drop your <span className="highlight-text"> space photos </span>, or browse</div>
                                                            <div className='manrope f-10'>Supported formats: JPG, PNG</div>
                                                        </div>
                                                        {(validateFileUploads(spaceFileList) == 'error') && (<Button
                                                        onClick={(event) => Constants.triggerFailedFilesUpload('room_reference_room_photos', spaceFileList, setSpaceFileList, event)}
                                                        disabled={spaceFileList.length === 0}
                                                        loading={uploading}
                                                        style={{top: 0}}
                                                        className="retry-btn manrope f-12" ghost
                                                        >
                                                        {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                    </Button>)}
                                                </div>
                                            </div>    
                                    </Upload>
                                )}
                            </Form.Item>
                        </div>
                    </div>
                    {(validateFileUploads(spaceFileList) == 'error') ? 
                        <div className="manrope f-12 red" style={{ textAlign: "center"}}>
                            {errorMessage}
                        </div>: ''}
                </Col>
                <Col span={24} style={{marginTop: 10}}>
                    <Form.Item name="elements" label={(<span  className='manrope f-14 black-00 w-700'>Add Elements&nbsp;<Tooltip title={<span className='manrope f-12 white'>Space elements consist of closets, flooring (tiles/wood/laminate etc), wall paper, wall paint, lighting, built-in-shelves, moulding etc. Add as many space elements that you need to describe your space as tags separated by comma, and upload materials in the relevant section for each tag.</span>}><InfoCircleOutlined /></Tooltip></span>)}  
                    colon={false}>
                        {getFieldDecorator('elements', {
                            rules: [
                                { type: 'array' },
                            ],
                        })(
                            <Select className='manrope f-12 black-55' mode="tags" onDeselect={deselectElement} onChange={addElements} placeholder="Please add your space elements">
                                <Option className='manrope f-12 black-55' value=""></Option>
                            </Select>,
                        )}
                    </Form.Item>
                </Col>
                {compUploads}

                <Col span={24}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerVideoUpload}>
                                <div style={{textAlign:"left",minWidth:"100%"}}>
                                    <Form.Item name ="video" label={(<span className='manrope f-14 black-00 w-700'>Video&nbsp;<Tooltip title={<span className='manrope f-12 white'>360 video of your space recorded from space center</span>}><InfoCircleOutlined /></Tooltip></span>)} colon={false}>
                                        {props.roomReducer.room.video && getFieldDecorator('video', {
                                            valuePropName: 'video',
                                            getValueFromEvent:  e => e && e.fileList,
                                        })(
                                            <Upload
                                            {...(Constants.getUploadProps(props.roomReducer.room.platform))} 
                                            onChange={handleChangeVideo}
                                            fileList={videoFileList}
                                            accept=".mp4,.avi,.mov"
                                            onRemove={file => {
                                                let button = document.getElementById('room_reference_video');
                                                button.disabled = true;
                                                const index = videoFileList.indexOf(file);
                                                const newFileList = videoFileList.slice();
                                                newFileList.splice(index, 1);
                                                setVideoFileList(newFileList);
                                                console.log(newFileList)
                                                newFileList.forEach((file) => {
                                                    if (file.status !== "error"){
                                                        setHasError(false)
                                                    }
                                                    else {
                                                        setHasError(true)
                                                    }
                                                })
                                                return true;
                                            }}
                                            multiple={true}  
                                            listType="text" 
                                            openFileDialogOnClick={false}
                                            // openFileDialogOnClick={!(validateFileUploads(videoFileList) == 'error')}
                                            className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                            progress= {{
                                                strokeColor: {
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                                },
                                                strokeWidth: 3,
                                                format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                            }}>
                                               
                                                <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                    <img className="" src={require("../../../../../assets/images/upload.png")} alt=""></img>
                                                    <div className="ant-upload-text">
                                                        <div style={{lineHeight:"150%"}} className="up-info manrope f-10">Drop your <span className="highlight-text"> 360 video of your space </span>, or browse</div>
                                                        <div className='manrope f-10'>Supported formats: MP4, AVI</div>
                                                    </div>
                                                        {(validateFileUploads(videoFileList) == 'error') && (<Button
                                                        onClick={(event) => Constants.triggerFailedFilesUpload('room_reference_video', videoFileList, setVideoFileList, event)}
                                                        disabled={videoFileList.length === 0}
                                                        loading={uploading}
                                                        style={{top: 0}}
                                                        className="retry-btn manrope f-12" ghost
                                                        >
                                                        {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                    </Button>)}
                                                </div>
                                            </div>
                                            </Upload>
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                            {(validateFileUploads(videoFileList) == 'error') ? 
                            <div className="manrope f-12 red" style={{color: "#ff4d4f", textAlign: "center"}}>
                                {errorMessage}
                            </div>: ''}
                        </Col>

                        <Col span={12}>
                            <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerRawUpload}>
                                <div style={{textAlign:"left",minWidth:"100%"}}>
                                    <Form.Item name ="raw_files" label={(<span className='manrope f-14 black-00 w-700'>Raw Files &nbsp;<Tooltip title={<span className='manrope f-12 white'>Add any raw files containing space details</span>}><InfoCircleOutlined /></Tooltip></span>)} colon={false}>
                                        {props.roomReducer.room.raw_files && getFieldDecorator('raw_files', {
                                            valuePropName: 'raw_files',
                                            getValueFromEvent:  e => e && e.fileList,
                                        })(
                                            <Upload 
                                            {...(Constants.getUploadProps(props.roomReducer.room.platform))} multiple={true}  
                                            listType="text" 
                                            fileList={rawFileList}
                                            onChange={handleChangeRaw}
                                            // accept=".zip"
                                            openFileDialogOnClick={false}
                                            // openFileDialogOnClick={!(validateFileUploads(rawFileList) == 'error')}
                                            onRemove={file => {
                                                let button = document.getElementById('room_reference_raw_files');
                                                button.disabled = true;
                                                const index = rawFileList.indexOf(file);
                                                const newFileList = rawFileList.slice();
                                                newFileList.splice(index, 1);
                                                setRawFileList(newFileList);
                                                console.log(newFileList)
                                                newFileList.forEach((file) => {
                                                    if (file.status !== "error"){
                                                        setHasError(false)
                                                    }
                                                    else {
                                                        setHasError(true)
                                                    }
                                                })
                                                return true;
                                            }}
                                            className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                            progress= {{
                                                strokeColor: {
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                                },
                                                strokeWidth: 3,
                                                format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                            }}> 
                                                <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                    <img className="" src={require("../../../../../assets/images/upload.png")} alt=""></img>
                                                    <div className="ant-upload-text">
                                                        <div style={{lineHeight:"150%"}} className="up-info manrope f-10">Drop your <span className="highlight-text"> raw space files </span>, or browse</div>
                                                        <div className='manrope f-10'>Supported formats: .zip, png, jpg, pdf etc</div>
                                                    </div>
                                                    {(validateFileUploads(rawFileList) == 'error') && (<Button
                                                    onClick={(event) => Constants.triggerFailedFilesUpload('room_reference_raw_files', rawFileList, setRawFileList, event)}
                                                    disabled={rawFileList.length === 0}
                                                    loading={uploading}
                                                    style={{top: 0}}
                                                    className="retry-btn manrope f-12" ghost
                                                    >
                                                    {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                    </Button>)}
                                                </div>
                                            </div>
                                            </Upload>    
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                            {(validateFileUploads(rawFileList) == 'error') ? 
                            <div className="manrope f-12 red" style={{ textAlign: "center"}}>
                                {errorMessage}
                            </div>: ''}
                        </Col>
                    </Row>
                </Col>
                {(uploadError != '')?
                        <div className="manrope f-12 red" style={{ textAlign: "center"}}>{uploadError}</div>
                    :""}
                <Col span={24} style={{display:"flex",justifyContent:"space-between",marginTop: '30px'}}>
                    <Form.Item>
                        <Button onClick={handleRedirection} className="modal-okay-gray square font-14">
                            Cancel
                        </Button>
                    </Form.Item>
                    <Form.Item className="justify-buttons">
                        <Button type="primary" onClick={() => setSubmitType('save')} className="modal-okay-gray square font-14" htmlType="submit" style={{marginRight: '5px'}}>
                        Save Draft & Exit {props.roomReducer.isLoading && submitType == 'save'?     <LoadingOutlined spin />: ''}
                        </Button>
                        <Button type="primary" onClick={() => setSubmitType('continue')} className="modal-okay-green square font-14" htmlType="submit">
                            Continue {props.roomReducer.isLoading && submitType == 'continue'? <LoadingOutlined spin />: ''}
                            
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
const RoomReference = Form.create({ name: 'room_reference' })(RoomReferenceForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
    getRoom: (room) => {
        dispatch(fetchRoom(room));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(RoomReference)
)