import React, { useState } from "react";
import { Logout } from "react-cognito";
import { Layout, Button, Progress, Modal, Row, Col, Image } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import PreprocessingImages from "../../ArtistComponents/PreprocessingImages/PreprocessingImages";
import "./ModelDetails.scss";



function ModelDetails(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
          <div className="content-container">
            <h3 style={{ marginTop: 20, marginBottom: 20 }}>
              Product Model Details
            </h3>
            <div className="requirements-container  mb-20">
              <div className="manrope w-500 f-16 title">
                Requirements (READ CAREFULLY)
              </div>
              <div className="details-container manrope w-500 f-14 ">
                <li className="mb-10">
                  The name of the zip file you upload should be the ID of your test.
                  In this case <b>{props.testID}.zip</b> should be the name
                  of the zip file.
                </li>
                <li className="mb-10">
                  The name of the max file in the zip should be the ID of your test.
                  In this case <b>{props.testID}.max</b> should be the name
                  of the max file.
                </li>
                <li className="mb-10">
                  Please go through the &nbsp;
                  <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">
                    Asset Creation Guidelines
                  </a>
                </li>
                {props.requirements ?
                  <li className="mb-10">
                    {props.requirements}
                  </li>
                  : ''}
              </div>
            </div>
            <div className="assignment-container">
              <Row gutter={20}>
                <Col span={12} className="gutter-row">
                  <div className="mb-20">
                    <div className="br-top-4 manrope w-500 f-16 title">
                      Required Output
                    </div>
                    <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                      {props.outputRequired === "high_res" ||
                        props.outputRequired === "high_res_segmented" ? (
                        <>
                          <div className="tags">High Poly Geometry</div>
                          <div className="tags">Vray Materials</div>
                        </>
                      ) : props.outputRequired ==='high_pbr' || props.outputRequired =='high_pbr_segmented' ?(
                        <>
                          <div className="tags">High Poly Geometry</div>
                          <div className="tags">PBR Textures connected to Vray Materials</div>
                        </>
                      )
                      : props.outputRequired === "ar" ? (
                        <>
                          <div className="tags">Low Poly Geometry</div>
                          <div className="tags">PBR Textures connected to Physical Materials</div>
                        </>
                      ) : (
                        <>
                          <div className="tags">High Poly Geometry</div>
                          <div className="tags">Vray Materials</div>
                          <div className="tags">Low Poly Geometry</div>
                          <div className="tags">PBR Materials</div>
                        </>
                      )}
                    </div>
                  </div>
                  {props.height === "0" ||
                    props.width === "0" ||
                    props.depth === "0" ? (
                    ""
                  ) : (
                    <div className="mb-20">
                      <div className="br-top-4 manrope w-500 f-16 title">
                        Model Dimensions
                      </div>
                      <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                        <div className="values-container">
                          Height
                          {props.height === 1 ? (
                            <div className="mt-10 text-grey">
                              {props.height} inch
                            </div>
                          ) : (
                            <div className="mt-10 text-grey">
                              {props.height} inches
                            </div>
                          )}
                        </div>
                        <div className="values-container">
                          Width
                          {props.width === 1 ? (
                            <div className="mt-10 text-grey ">
                              {props.width} inch
                            </div>
                          ) : (
                            <div className="mt-10 text-grey ">
                              {props.width} inches
                            </div>
                          )}
                        </div>
                        <div className="values-container">
                          Depth
                          {props.depth === 1 ? (
                            <div className="mt-10 text-grey">
                              {props.depth} inch
                            </div>
                          ) : (
                            <div className="mt-10 text-grey">
                              {props.depth} inches
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mb-20">
                    <div className="br-top-4 manrope w-500 f-16 title">
                      Color Details
                    </div>
                    <div className="br-bottom-4 tags-container manrope w-500 f-14">
                      <div className="values-container">
                        Product Color
                        <div className="mt-10 text-grey">{props.color}</div>
                      </div>
                      <div className="values-container">
                        Closest matching color
                        <div className="mt-10 text-grey">
                          {props.similarColor}
                        </div>
                      </div>
                    </div>
                  </div>
                  {props.lightingRig ? (
                    <div className="mb-20">
                      <div className="br-top-4 manrope w-500 f-16 title">
                        Lighting Rig
                      </div>
                      <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                      <p className="mb-10" style={{textAlign: 'left', lineHeight: 1.35}}>Please use the following lighting rig to create your model and match the materials and colors to look same as reference imagery.</p>
                        <div className="values-container">
                          <a href={props.lightingRig}>Download Rig</a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={12} className="gutter-row">
                  {(props.referenceURLs && props.referenceURLs.length > 0) ||
                    (props.referenceImages &&
                      props.referenceImages.length > 0) ? (
                    <div className="mb-20">
                      <div className="br-top-4 manrope w-500 f-16 title">
                        Reference Link and Images
                      </div>
                      <div className="tags-container manrope w-500 f-14 overflowX-scroll br-bottom-4">
                        <div className="items-container">
                          {props.referenceURLs &&
                            props.referenceURLs.length !== 0 ? (
                            <>
                              Reference Link
                              {props.referenceURLs.map((i, index) => (
                                <a className="mt-10" key={index} href={i}>
                                  {i}
                                </a>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              props.referenceURLs &&
                                props.referenceURLs.length !== 0
                                ? "items-container mt-20"
                                : "items-container"
                            }
                          >
                            {props.referenceImages &&
                              props.referenceImages.length !== 0 ? (
                              <>
                                Reference Images
                                <div className="reference-img-container">
                                  <Image.PreviewGroup>
                                    {props.referenceImages.map(
                                      (item, index) => (
                                        <Image
                                          className="mt-10 mr-10 ref-img"
                                          key={index}
                                          src={
                                            ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.BASE_URI +
                                            item.uid +
                                            "/" +
                                            item.name
                                          }
                                        />
                                      )
                                    )}
                                  </Image.PreviewGroup>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.colorExtractedImages ?
                  <div className="mb-20">
                    <div className="br-top-4 manrope w-500 f-16 title">
                      Color Palette
                    </div>
                    <div className="br-bottom-4 tags-container manrope w-500 f-14 ">
                      <PreprocessingImages
                        color_extracted_images={props.colorExtractedImages}
                        product_id={props.productID}
                      />
                    </div>
                  </div> : ''}
                </Col>
              </Row>
            </div>
          </div>

    </React.Fragment>
  );
}

export default ModelDetails;
