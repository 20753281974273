import React from 'react';
import 'antd/dist/antd.css';
import ArtistMainLayout from '../ArtistMainLayout';
import ArtistProductsData from '../ArtistProductsData';
import ArtistOnboarding from '../ArtistOnboarding/ArtistOnboarding'
class ArtistProductsView extends React.Component {
    render() {
        return (
            <ArtistMainLayout selected='1'>
                <ArtistOnboarding />
                <ArtistProductsData />
                {/* Artist Data */}
            </ArtistMainLayout>
        );
    }
}

export default ArtistProductsView;
