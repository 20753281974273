import React from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ZoomImage = ({ image, className }) => {
    return (
        <TransformWrapper
            defaultScale={1}
            wheel={{ step: 0.5 }}
            doubleClick={{ step: 2 }}
            pinch={{ step: 0.5 }}
        >
            <TransformComponent>
                <img src={image} alt="Zoomable" className={className} />
            </TransformComponent>
        </TransformWrapper>
    );
};

export default ZoomImage;
