import React, { useContext } from 'react';
import FileConstants from '../../../../FileConstants';
import {
    Row, Col, Tooltip
} from 'antd';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;


const TextContainer = (props) => {
    return (<Tooltip title={<span className='manrope f-12 white'>{props.value}</span>}>
        <Col span={4} className='col-width-16'>
            <div className="manrope f-14 lh-14 grey-8c text-left">{props.name}</div>
            <div className="manrope f-16 lh-16 black-26">
                {props.value}
            </div>
        </Col>
    </Tooltip>)
}

const ProductModelSizeInformation = () => {
    const { totalFileSize, lowPolyModelSize, lowPolyVertexCount,
        lowPolyTextureSize, highPolyVertexCount,
        highPolyModelSize, highPolyTextureSize, archiveSize, glbSize } = useContext(CollaborateQAContext);

    return (<Row className='model-detail-product-qa'>
        <Col span={24}>
            <div className='model-info-heading-pos'>
                <h4 className='manrope f-16 black-26 m-0'>Model Specifications</h4>
            </div>
            <Row className='basic-detail-container p-20'>
                {totalFileSize != 0 &&
                    <TextContainer
                        name={"Total File Size"}
                        value={totalFileSize}
                    />}
                {highPolyModelSize != 0 &&
                    <TextContainer
                        name={"High Poly Texture + Mesh"}
                        value={highPolyModelSize}
                    />}
                {highPolyTextureSize != 0 &&
                    <TextContainer
                        name={"High Poly Texture"}
                        value={highPolyTextureSize}
                    />}
                {lowPolyModelSize != 0 &&
                    <TextContainer
                        name={"Low Poly Texture + Mesh"}
                        value={lowPolyModelSize}
                    />}
                {lowPolyTextureSize != 0 &&
                    <TextContainer
                        name={"Low Poly Texture"}
                        value={lowPolyTextureSize}
                    />}
                {glbSize != 0 &&
                    <TextContainer
                        name={"GLB Size"}
                        value={glbSize}
                    />}
                {archiveSize != 0 &&
                    <TextContainer
                        name={"Archive Size"}
                        value={archiveSize}
                    />}
                {lowPolyVertexCount != 0 &&
                    <TextContainer
                        name={"Low Poly Vertex Count"}
                        value={lowPolyVertexCount}
                    />}
                {highPolyVertexCount != 0 &&
                    <TextContainer
                        name={"High Poly Vertex Count"}
                        value={highPolyVertexCount}
                    />}
            </Row>
        </Col>
    </Row>)

}

export default ProductModelSizeInformation;