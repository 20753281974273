import React from 'react';
import { Form } from '@ant-design/compatible';
import {  Space } from 'antd';
import { CaretRightOutlined, ExclamationCircleOutlined,SearchOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Table, message, Modal, Select, Button, Input, Collapse, Upload, Form as CustomForm } from 'antd';
import * as Constants from "../../CustomerComponents/Constants";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import AWS from 'aws-sdk';
import { BUCKET_NAME } from '../../../../environments/env';
import DeleteEntityModal from '../../DeleteEntityModal';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';

const Option = { Select };
const { TextArea } = Input;
const { Panel } = Collapse;

const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class JobDeskTableUploadedProducts extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    hasData: true,
    hasError: false,
    uploading: false,
    dataSource: null,
    unAssignActionClass: 'hide',
    assignActionClass: 'hide',
    sendBackToCustomerActionClass: 'sendBackClass',
    customerModelReivewVisible: false,
    selectedProduct: '-1',
    errorMessage: "",
    adminComments: "",
    adminCommentsError: "",
    artists: [],
    filter_customers: [],
    filter_company:[],
    filter_artists: [],
    filter_category:[],
    adminAttach: [],
    refFileList: [],
    assignmentModelVisible: false,
    deleteModalVisible: false,
    deletingModelLoader: false,
    deleteSuccessModal: false,
    deleteWarningModal: false,
  };

  handleAssign = values => {

      console.log('in handleAssign',this.props.currentTab)
      let payload = {
        assigned_artist : values.artist_username,
        product_id: this.props.selected_id.toString(),
        admin_username: localStorage.getItem('username')
      }

      if (values.height) {
        payload.height = values.height;
      }
      if (values.width) {
        payload.width = values.width;
      }
      if (values.length) {
        payload.length = values.length;
      }

      if(values.admin_text_guidelines) {
        payload.admin_text_guidelines = values.admin_text_guidelines;
      }

      console.log('payload=', payload)
      axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
        .then(res => {
          console.log(res);
          console.log(res.data);
          message.info('Model has been assigned to Specified Artist');
          this.setState({
            assignmentModelVisible: false,
          });
          setTimeout(() => {window.location.reload();}, 1500)
            this.handleUnAssignedDataSource(); 
        })
        
  }

  delete_model = (id) =>{
    console.log('in delete product function')
    this.setState({
      deletingModelLoader: true
    })
    axios.post(
      ENVIRONMENT.DELETE_INCOMPLETE_REQUEST, {
        'entity_type': 'Product',
        'entity_id': id
      }).then(res => {
        console.log('Response',res);
        if (res && res.data.length == 0) {
          this.setState({
            deleteWarningModal: true
          })
        } else {
          this.setState({
            deleteSuccessModal: true
          });
        }
        this.setState({
          deletingModelLoader: false,
          deleteModalVisible: false,
        });
      })
  }

  onCancelDelete = () => {
    this.setState({
      deletingModelLoader: false,
      deleteModalVisible: false
    })
  };


  handleCancel = () => {
    this.setState({
      assignmentModelVisible: false
    });
  }

  setRefFileList = (fileList) =>{
    this.setState({
      refFileList: fileList
    })
  }

  validateFileUploads = (files) => {
    let status_done = false;
    if (files != undefined) {
        for(var file of files) {
            if (file['status'] == 'uploading') {
                return 'uploading';
            }
            else if (file['status'] == 'error') {
                return 'error';
            }
            else if (file['status'] == 'done') {
                status_done = true;
            }
        }
    }
    if (status_done) {
        return 'done'
    }
    return 'not_started';
}

  setUploading = (is_uploading) => {
    this.setState({
      uploading: is_uploading
    })
  }

  handleChangeAdminFile = info => {
    let fileList = [...info.fileList];
    this.setAdminAttach(fileList);
    this.setUploading(true);
    console.log(info,info.file);
    if (info.file.status === "done") {
        this.setHasError(false);
        this.setErrorMessage('');
        this.setUploading(false);
    } else if (info.file.status === "error") {
        console.log(info.file.error.message,info.file);
        this.setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
        if (info.file.error.code.includes('Credentials')) {
            this.setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
        }  else if (info.file.error.code.includes('Network')) {
            this.setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
        }
        this.setHasError(true);
        this.setUploading(false);
    }
  };

  setAdminAttach = (fileList) => {
    this.setState({
      adminAttach: fileList
    })
  }

  setHasError = (has_error) => {
    this.setState({
      hasError: has_error
    })
  }

  setErrorMessage = (error) => {
    this.setState({
      errorMessage: error
    })
  }

  showAssignmentModal = (id) => {
    console.log('id=',id)
    this.setState({
      selectedProduct: id,
      assignmentModelVisible: true
    });
    this.props.set_id(id);
    console.log('selectedProductId=', this.props.selected_id)
  }

  handleCancelCustomerForm = () => {
    this.setState({
      customerModelReivewVisible: false,
      adminAttach: [],
      adminComments: "",
      refFileList: []
    });
  }

  showSendBackModal = (id) => {
    console.log('id=',id)
    this.setState({
      selectedProduct: id,
      customerModelReivewVisible: true
    });
    this.props.set_id(id);
    console.log('selectedProductId=', this.props.selected_id)
  }

  onSendToCustomer = () => {
    console.log('state = ', this.state)
    if(this.validateFileUploads(this.state.adminAttach) == "uploading"){
      this.setErrorMessage("Uploads are in progress")
    }
    else{
      this.setErrorMessage("")
      if(this.state.adminComments == ""){
        this.setState({
          adminCommentsError: "Message for Customer is required"
        })
      }
      else{
        this.setState({
          adminCommentsError: ""
        })
        let payload = {
            product_id: parseInt(this.state.selectedProduct),
            username: localStorage.getItem('username'),
            model_status: "10",
            admin_comments: this.state.adminComments
        }

        if (this.state.adminAttach) {
            payload.admin_attachments = this.state.adminAttach
        }
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload).then(res => {
          console.log(res);
          console.log(res.data);
          message.info('Product has Sent Back to Customer.');
          setTimeout(() => {window.location.reload();}, 1500)
        });
    }
  }
}

  handleSubmitCustomerForm = values => {
      this.setState({
        customerModelReivewVisible: true
      });
      this.onSendToCustomer(values);
  }


  getColumns = () => [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Model Id',
      dataIndex: 'id',
      key: 'id',
      render: text => <a onClick={() => window.open('../product_guidelines/' + text, 'Product Guidelines', "height=600,width=1000")}>{text}</a>,
      sorter: (a, b) => a.id - b.id,
      ...this.getColumnSearchProps('id'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: this.state.filter_category,
      onFilter: (value, record) => record.category === value,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      sorter: (a, b) => new Date(a.last_modified) - new Date(b.last_modified),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      filters: this.state.filter_customers,
      onFilter: (value, record) => record.customer === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      filters: this.state.filter_company,
      onFilter: (value, record) => record.company === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Assigned Artist',
      dataIndex: 'assigned_artist',
      key: 'assigned_artist',
      filters: this.state.filter_artists,
      onFilter: (value, record) => record.assigned_artist === value,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.unAssignActionClass,
      render: (text, record) => (
        <span>
          <a onClick={() => this.unAssignProductConfirm(record.product_id, record.assigned_artist)}>UnAssign</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'assign_action',
      className: this.state.assignActionClass,
      render: (text, record) => (
        <span>
          <a onClick={() => this.showAssignmentModal(record.id)}>Assign to Artist</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'send_back_action',
      className: 'SendBacktoCustomer',
      render: (text, record) => (
        <span>
          <a onClick={() => this.showSendBackModal(record.id)}>Send Back to Customer</a>
        </span>
      ),
    },
    this.props.delete_model ? {
      title: 'Action',
      key: 'delete_model',
      className: 'delete-model',
      render: (text, record) => (
        <span>
          <a onClick={() => this.setState({
            selected_id: record.id,
            deleteModalVisible: record.model_status == -4 ? false : true,
            deleteWarningModal: record.model_status == -4 ? true : false
          })}>Delete</a>
        </span>
      ),
    } :{}
  ];
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  unAssignProductConfirm(product_id, assigned_artist) {
    Modal.confirm({
      title: 'Are you sure you want to unassign this product?',
      icon: <ExclamationCircleOutlined />,
      content: 'Artist might have already started working on it.',
      onOk: () => {
        console.log('OK');
        this.unAssignProduct(product_id, assigned_artist);
      },
      onCancel() {
      },
    });
  }

  unAssignProduct = (product_id, assigned_artist) => {
    axios.post(ENVIRONMENT.ASSIGN_PRODUCT_MODEL, {
      assigned_artist: '',
      product_id: product_id,
      original_artist: assigned_artist,
      admin_username: localStorage.getItem('username')
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Product has been unassigned.');
        setTimeout(() => {window.location.reload();}, 1500)
        this.handleAssignedDataSource(2);
        this.handleUnAssignedDataSource();
      })
  }

  loadArtistsData = () => {

    axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        console.log(res.data)
        let artist_list = [];
        res.data.map(artist => {
          if (artist.is_active == true || artist.is_active == "True") {
            artist_list.push(artist);
          }
        });
        this.setState({
          artists: artist_list
        });
      });
  }

  componentDidMount = () => {
    console.log('props.state = ', this.props.state)
    if (this.props.state == 'unassigned') {
      this.loadArtistsData();
      this.setState({
        unAssignActionClass: 'hide',
        assignActionClass: '',
        sendBackToCustomerActionClass: ''
      });
      this.handleUnAssignedDataSource(2);
    }
    if (this.props.state == 'assigned') {
      this.loadArtistsData();
      this.setState({
        unAssignActionClass: '',
        assignActionClass: 'hide',
        sendBackToCustomerActionClass: ''
      });
      this.handleAssignedDataSource(2);
    }
    if (this.props.state == 'failed') {
      this.loadArtistsData();
      this.setState({
        unAssignActionClass: 'hide',
        assignActionClass: 'hide',
      });
      console.log('calling the being processed ')
      this.fetchProducts(11);
    }
  }

  setAdminComments = (event) =>{
    this.setState({
      adminComments: event.target.value
    })
    if(event.target.value = ""){
      this.setState({
        adminCommentsError: "Message for Customer is required."
      })
    }
    else{
      this.setState({
        adminCommentsError: ""
      })
    }
  }

  handleFilteringOptions = (filter_customer_values, filter_artist_values,filter_company_values,filter_category_values) => {
    
    let filter_customer_objects = [];
    let filter_artist_objects = [];
    let filter_company_objects=[];
    let filter_category_objects=[];

    filter_customer_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });

    filter_customer_values.forEach( filter_customer_value => {
      filter_customer_objects.push({
        text: filter_customer_value,
        value: filter_customer_value,
      })
    });

    filter_category_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });

    filter_category_values.forEach( filter_category_value => {
      filter_category_objects.push({
        text: filter_category_value,
        value: filter_category_value,
      })
    });

    filter_artist_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });

    filter_artist_values.forEach( filter_artist_value => {
      filter_artist_objects.push({
        text: filter_artist_value,
        value: filter_artist_value,
      })
    });

    filter_company_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });

    filter_company_values.forEach( filter_company_value => {
      filter_company_objects.push({
        text: filter_company_value,
        value: filter_company_value,
      })
    });

    this.setState({
      filter_customers: filter_customer_objects,
      filter_artists: filter_artist_objects,
      filter_company:filter_company_objects,
      filter_category:filter_category_objects
    });

  }

  getPayload = () => {
    return {
      "required_fields": ["id","name","brand_id", "created_on","last_modified","category", "company_name", "model_type", 
      "model_type","need_to_model", "assigned_artist","customer_username", 
      "group_id", "uploaded_model", "variant_of", "variation_type", "segmented",
      "hidden_from_artist", "model_status", "last_modified_stamp"],
      "order_by": "last_modified_stamp desc"
    }
  }

  handleAssignedDataSource = (model_status) => {
    let data = [];
    let filter_customer_values = [];
    let filter_artist_values = [];
    let filter_company_values=[];
    let filter_category_values=[];

    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=["+ model_status + "]&&uploaded_model__is=true&&scans__isnull=true)"
    
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        console.log(res.data)
        for (let i = 1; i <= (res.data).length; i++) {
          //following check will be removed when we have this filter availale on API side    
          if(res.data[i - 1]['assigned_artist'] != ""){
          data.push({
            key: i,
            product_id: res.data[i - 1]['id'],
            name: res.data[i - 1]['name'],
            id: res.data[i - 1]['id'],
            category: res.data[i - 1]['category'],
            assigned_artist: res.data[i - 1]['assigned_artist'],
            customer: res.data[i - 1]['customer_username'],
            company: res.data[i - 1]['company_name'],
            created_on: res.data[i - 1]['created_on'],
            last_modified: res.data[i - 1]['last_modified'],
            model_status: model_status,
            variant_of: res.data[i - 1]['variant_of'],

          });
        }
          let customer_value = res.data[i - 1]['customer_username'];
          if(customer_value != "" && !filter_customer_values.includes(customer_value)){
            filter_customer_values.push(customer_value);
          }
          let company_value = res.data[i - 1]['company_name'];
          if(company_value != "" && !filter_company_values.includes(company_value)){
            filter_company_values.push(company_value);
          }
          let category_value = res.data[i - 1]['category'];
          if(category_value != "" && !filter_category_values.includes(category_value)){
            filter_category_values.push(category_value);
          }

          let artist_value = res.data[i - 1]['assigned_artist'];
          if(artist_value != "" && !filter_artist_values.includes(artist_value)){
            filter_artist_values.push(artist_value);
          }
        }
        this.setState({
          dataSource: data
        })
        this.handleFilteringOptions( filter_customer_values, filter_artist_values,filter_company_values,filter_category_values);
      });

  }

  fetchProducts = (model_status) => {
    let data = [];
    let filter_customer_values = [];
    let filter_company_values=[];
    let filter_category_values=[];

    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=["+ model_status + "]&&uploaded_model__is=true&&scans__isnull=true)"
    
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        for (let i = 1; i <= (res.data).length; i++) {
            data.push({
              key: i,
              product_id: res.data[i - 1]['id'],
              name: res.data[i - 1]['name'],
              id: res.data[i - 1]['id'],
              category: res.data[i - 1]['category'],
              customer: res.data[i - 1]['customer_username'],
              company: res.data[i - 1]['company_name'],
              created_on: res.data[i - 1]['created_on'],
              last_modified: res.data[i - 1]['last_modified'],
              model_status: model_status,
              variant_of: res.data[i - 1]['variant_of'],

            });
          let customer_value = res.data[i - 1]['customer_username'];
          if(customer_value != "" && !filter_customer_values.includes(customer_value)){
            filter_customer_values.push(customer_value);
          }
          let category_value = res.data[i - 1]['category'];
          if(category_value != "" && !filter_category_values.includes(category_value)){
            filter_category_values.push(category_value);
          }
          let company_value = res.data[i - 1]['company_name'];
          if(company_value != "" && !filter_company_values.includes(company_value)){
            filter_company_values.push(company_value);
          }
        }
        this.setState({
          dataSource: data
        })
        this.handleFilteringOptions( filter_customer_values, [],filter_company_values,filter_category_values);
      });
  }


  handleUnAssignedDataSource = (model_status) => {
    let data = [];
    let filter_customer_values = [];
    let filter_company_values=[];
    let filter_category_values=[];
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=["+ model_status + "]&&uploaded_model__is=true&&scans__isnull=true)"
    
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        console.log(res.data)
        for (let i = 1; i <= (res.data).length; i++) {
          //following check will be removed when we have this filter availale on API side
          if (res.data[i - 1]['assigned_artist'] == '') {
            data.push({
              key: i,
              product_id: res.data[i - 1]['id'],
              name: res.data[i - 1]['name'],
              id: res.data[i - 1]['id'],
              category: res.data[i - 1]['category'],
              customer: res.data[i - 1]['customer_username'],
              company: res.data[i - 1]['company_name'],
              created_on: res.data[i - 1]['created_on'],
              last_modified: res.data[i - 1]['last_modified'],
              model_status: model_status,
              variant_of: res.data[i - 1]['variant_of'],

            });
          }
          let customer_value = res.data[i - 1]['customer_username'];
          if(customer_value != "" && !filter_customer_values.includes(customer_value)){
            filter_customer_values.push(customer_value);
          }
          
          let category_value = res.data[i - 1]['category'];
          if(category_value != "" && !filter_category_values.includes(category_value)){
            filter_category_values.push(category_value);
          }
          let company_value = res.data[i - 1]['company_name'];
          if(company_value != "" && !filter_company_values.includes(company_value)){
            filter_company_values.push(company_value);
          }
          
        }

        this.setState({
          dataSource: data
        })
        this.handleFilteringOptions( filter_customer_values,[],filter_company_values,filter_category_values);  
      });

  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
        }
        <Modal bodyStyle={{ paddingTop: "2px" }}
          title="Please select artist to assign product to "
          visible={this.state.assignmentModelVisible}
          // onOk={this.handleAssign}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" type="primary" onClick={() => document.getElementById("artist-assignment-submit-button").click()}>
                Assign
            </Button>
            ,
            <Button key="submit" type="primary" onClick={this.handleCancel}>
                Cancel
            </Button>
        ]}
          >
          <div>
            <CustomForm className="input-form" onFinish={this.handleAssign}>
              <CustomForm.Item name="artist_username" label="Artist" colon={false} rules={[{ required: true, message: 'Please select Artist' }]}>

                <Select
                  showSearch
                  placeholder="Select Artist">
                  {this.state.artists.map((ele, index) => {
                    return <Option key={index} value={ele.artist_profile}>{ele.artist_profile + " at rate " + ele.hourly_rate}</Option>
                  })}
                </Select>
              </CustomForm.Item>
              <Collapse
                  bordered={false}
                  defaultActiveKey={[]}
                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  className="site-collapse-custom-collapse"
              >
                  <Panel header="Modeling Guidelines" key="1" className="site-collapse-custom-panel">
                    <h4><b>Dimensions (Inches)</b></h4>
                    <CustomForm.Item name="height" label="Height" colon={false}
                      rules= {[{ required: false, message: 'Please enter height' }]}
                    >
                        <Input
                            placeholder="Enter Height" type="number"
                        />
                    </CustomForm.Item>
                      <CustomForm.Item name="width" label="Width" colon={false}
                        rules= {[{ required: false, message: 'Please enter width' }]}
                      >
                        <Input
                            placeholder="Enter Width" type="number"
                        />
                      </CustomForm.Item>
                      <CustomForm.Item name="length" label="Depth" colon={false}
                        rules= {[{ required: false, message: 'Please enter depth'}]}
                      >
                        <Input
                            placeholder="Enter length" type="number"
                        />
                      </CustomForm.Item>
                      <CustomForm.Item name="admin_text_guidelines" label="Comments/Guidelines" colon={false}
                        rules= {[{ required: false, message: 'Please enter depth'}]}
                      >
                        <TextArea 
                            placeholder = "Enter Guidelines"
                            rows={2}
                            style={{height: "auto !important"}}
                        />
                      </CustomForm.Item>
                      </Panel>  
                  </Collapse>
              <Button id="artist-assignment-submit-button" type="primary" htmlType="submit" style={{"display": "none"}}>
                Submit
              </Button>
            </CustomForm>
          </div>
        </Modal>
      
        {/* Send back to customer modal */}
        <Modal
        title="Confirm"
        visible={this.state.customerModelReivewVisible}
        onOk={this.handleSubmitCustomerForm}
        closable={false}
        maskClosable={false}
        onCancel={this.handleCancelCustomerForm}>
        <div>
          <Form className="input-form">
            <Form.Item name="admin_comments" label="Message for Customer" colon={false} >
                <TextArea
                  placeholder="Enter Message"
                  value={this.state.adminComments}
                  onChange={this.setAdminComments}
                  rows={5}
                  style={{height: "auto !important"}}
                />
                {this.state.adminCommentsError != "" ?
                  <div className='retry-error-message left'>{this.state.adminCommentsError}</div>
            : ""}
            </Form.Item>
            
            <Form.Item name={'admin_attachments'} label="Reference Files" colon={false} getValueFromEvent= {e => e && e.fileList}>
                <Upload 
                  style={{display: 'inline-block', width: "100%"}}
                  {...Constants.upload_props} 

                  onRemove={file => {
                    message.success(`${file.name} removed successfully!`)
                    const index = this.state.adminAttach.indexOf(file);
                    const newFileList = this.state.adminAttach.slice();
                    newFileList.splice(index, 1);
                    this.setAdminAttach(newFileList);
                    console.log(newFileList)
                    newFileList.forEach((file) => {
                        if (file.status !== "error"){
                            this.setHasError(false)
                        }
                        else {
                            this.setHasError(true)
                        }
                    })
                    return true;
                  }}
                  fileList={this.state.adminAttach}
                  onChange = {this.handleChangeAdminFile}
                  openFileDialogOnClick={!(this.validateFileUploads(this.state.adminAttach) == 'error')}
                  multiple="true" listType="text" className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                >
                  <span class="admin-attachments-upload"></span>
                  <div className="ant-upload-text">
                  <div style={{display: "block"}}>
                  <div className="d-flex">
                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                    <div className="ant-upload-text">
                    <div className="up-info">Drop your Files</div>
                    <div>Support: JPG</div>
                    </div>
                  </div>
                  </div>
                 
                    {(this.validateFileUploads(this.state.adminAttach) == 'error') && (<Button
                          onClick={() => Constants.triggerFailedFilesUpload('.admin-attachments-upload', this.state.adminAttach, this.setAdminAttach)}
                          disabled={this.state.adminAttach.length === 0}
                          loading={this.state.uploading}
                          style={{position:'relative',top: 0}}
                          className="retry-btn" ghost
                          >
                          {this.state.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                      </Button>)}
                </div>
                </Upload>
            </Form.Item>
            {(this.validateFileUploads(this.state.adminAttach) == 'error' || this.state.errorMessage) ? 
                <div className='retry-error-message left'>{this.state.errorMessage}</div>
            : ''}
            </Form>
          </div>
        </Modal>
        <DeleteEntityModal
        onCancel={this.onCancelDelete}
        visible={this.state.deleteModalVisible}
        deletingModelLoader={this.state.deletingModelLoader}
        selected_id={this.state.selected_id}
        delete_model={this.delete_model}
        heading={'You are about to delete a Product'}
        subText={'Once deleted, it cannot be recovered. Are you sure you want to delete it?'}
        />
        <SuccessModal
        visible={this.state.deleteSuccessModal}
        heading={"Product Deleted Successfully!"}
        text={"The product has been deleted successfully."}
        footer={[
           <div className="justify-in-center">
               <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                   window.location.reload();}}>
                   Okay
               </Button>
           </div>
        ]}/>
        <WarningModal
        visible={this.state.deleteWarningModal}
        onCancel={() => this.setState({ deleteWarningModal: false })}
        heading={"Oops! You cannot delete this product."}
        text={"This product cannot be deleted directly since it has assets and information associated with it. Please contact the engineering team to get it removed."}
        footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => this.setState({ deleteWarningModal: false })}>
                    Okay
                </Button>
            </div>
        ]}
        />
      </div>
    );
  }
}

export default JobDeskTableUploadedProducts;
