import React from 'react';
import 'antd/dist/antd.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import ENVIRONMENT from '../../../../environments'
import {
    Row,
    Col,
    Button,
    Upload,
    message,
    Card,
    Modal,
    Input,
    Tabs
} from 'antd';
import CustomerMainLayout from '../CustomerMainLayout';
import Three360Viewer from "../Three360Viewer"
import axios from 'axios';
import * as Constants from "../../CustomerComponents/Constants";
import CustomerOnboarding from "../../CustomerComponents/CustomerOnboarding";
import { Icon as LegacyIcon } from '@ant-design/compatible';

const baseURL = ENVIRONMENT.SCENE_BASE_URL;

const TextArea = Input;
const { TabPane } = Tabs;

class ApproveSceneContainer extends React.Component{
    
    state = {
        renders: [],
        isLoading: false,
        isFullScreen: false,
        visible: false,
        name: '',
        unhappyModalVisible: false,
        three_sixties: [],
        onboarding: false,
        approvedModal: false,
        platform: ''
    };

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.setState({
                isFullScreen: false
            });
        }
    }

    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };

      showUnhappyModal = () => {
        this.setState({
            unhappyModalVisible: true,
        });
      };
    
      handleUnhappyOk = e => {
        console.log(e);
        this.setState({
            unhappyModalVisible: false,
        });
      };
    
      handleShare = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let body = this.generateSharingPayload(values);
                axios.post(ENVIRONMENT.SHARE_SCENE, {body})
                .then(res => {
                    console.log(res.data);
                    message.info('Scene has been shared.')
                    this.setState({
                      visible: false,
                    });
                });
            }
        });
      };

      handleCancel = e => {
        this.setState({
          visible: false,
        });
      };

      handleUnhappyCancel = e => {
        this.setState({
          unhappyModalVisible: false,
        });
      };

    componentDidMount(){
        this.setState({isLoading: true})
        var design = "";
        var room_model_file = "";
        var payload = {"scene_id" : this.props.match.params.id};
        axios.post(ENVIRONMENT.GET_SCENE_DETAIL, payload)
            .then(res => {
                console.log("scene data");
                console.log(res.data);
                var three_sixties_json = res.data['three_sixties'];
                var scene_platform = res.data['platform']

                var three_sixties_object = {};
                three_sixties_object["three_sixties"] = [];
                if (three_sixties_json != "") {
                    three_sixties_object = JSON.parse(three_sixties_json);
                }

                let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                if(onboarding != undefined){
                    onboarding = true;
                }
                else{
                    onboarding = false;
                }

                this.setState({
                    isLoading: false,
                    name: res.data['name'],
                    three_sixties: three_sixties_object["three_sixties"],
                    onboarding: onboarding,
                    platform: scene_platform
                });
            })

        document.addEventListener("keydown", this.escFunction, false);
        
    }

    setFullScreenState = () => {
        if(this.state.isFullScreen){
            this.setState({isFullScreen: false});
        }
        else{
            this.setState({isFullScreen: true});
        }
    }

    generateSharingPayload = (values) => {
        let payload = {};
        
        let permissions = {};
        permissions['360'] = values['360'];
        permissions['images'] =  values['images'];
        permissions['editable'] = values['editable'];
        if (values['download_options'] == 'download_and_embed')
        {
            permissions['download'] = true;
            permissions['embed'] = true;
        }
        if (values['download_options'] == 'embed')
        {
            permissions['embed'] = true;
        }

        payload['permissions'] = permissions;
        payload['emails'] = values['emails'];
        payload['username'] = localStorage.getItem("username");
        payload['scene_id'] = this.props.match.params.id;

        console.log(payload);
        return payload;
    }

    handleRedirection = () => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        if(onboarding){
            this.props.history.push({
                pathname: '/my_scenes',
                search: `?onboarding=true`
            }); 
        }
        else{
            this.props.history.push({
                pathname: '/my_scenes',
            });
        }
    }

    approveRender = () => {
        
        let payload = {
            "scene_id": this.props.match.params.id,
            "scene_status": '3',

        };
        console.log(JSON.stringify(payload));
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then( (response) => {
                // message.info('Render Approved.');
                // window.location.href = '/my_scenes';
                this.setState({
                    approvedModal: true
                });

            })
            .catch((error) => {
                message.error('Error in submitting Render Request');
            });
    }

    rejectRender = () => {
        
        let payload = {
            "scene_id": this.props.match.params.id,
            "scene_status": '1',

        };
        console.log(JSON.stringify(payload));
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then( (response) => {
                message.info('Render Rejeced.');
                window.location.href = '/scene_builder/' + this.props.match.params.id;
            })
            .catch((error) => {
                message.error('Error in submitting Render Request');
            });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let unhappy_details = {};
                unhappy_details['details'] = values['msg'];
                unhappy_details['photos'] = values['unhappy_photos'];
                let body = {
                    "scene_id": this.props.match.params.id,
                    "scene_status": '-3',
                    'unhappy_details': unhappy_details
        
                };
                console.log(JSON.stringify(body));

                axios.post(ENVIRONMENT.UPDATE_SCENE, body)
                .then(res => {
                    console.log(res.data);
                    message.info('Your concers are noted. We will contact you very soon.')
                    window.location.href = '/my_scenes';
                })
                .catch((error) => {
                    message.error('Error in submitting ');
                });
            }
        });
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched , getFieldValue , setFieldsValue, resetFields} =this. props.form;
        return (
            <CustomerMainLayout selected='6'>
                <Row style={{padding: 12}}>
                
                    <Col span={4}>
                        <h3>{this.state.name}</h3>
                    </Col>
                    <Col span={20} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {/* <Button shape="round" onClick={() => this.showModal()} type="danger" style={{marginRight: 2}}><Icon type="link" />Share</Button>
                        <Button shape="round" type="danger" style={{marginRight: 2}}><span>{"</>"}</span>&nbsp;Embed</Button>
                        <Button shape="round"  href={`/scene_builder/${this.props.match.params.id}`} type="danger"><Icon type="edit" />Edit Scene</Button> */}
                    </Col>
                </Row>
                <Row>{this.state.isLoading ? <div style={{textAlign: "center"}}> <LoadingOutlined style={{textAlign: "center", fontSize:"20px"}} size="large" spin /> </div>:'' }</Row>
                {/* <Card className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                            <PanoramaViewer customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl={baseURL + this.props.match.params.id + '/360Camera.jpg'} />
                    </Card> */}

                    {(this.state.three_sixties.length == 0) ?
                            <Card className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                                    <LegacyIcon style={{fontSize: 20, margin: -12, color: "#40a9ff"}} className="product-full-screen-icon" onClick={() => this.setFullScreenState()} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                    <Three360Viewer customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} fullScreen={this.state.isFullScreen} url={[(baseURL + this.props.match.params.id + '/360Camera.jpg').replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform))]}/>

                                    {/* <PanoramaViewer customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl={baseURL + this.props.match.params.id + '/360Camera.jpg'} /> */}
                            </Card>
                            :
                            <Tabs
                                    tabBarStyle={{ 
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                }}
                                centered
                                defaultActiveKey='1'
                                style={{marginTop: "-20px"}}
                            >
                                {this.state.three_sixties.map((name, index) => (
                                    <TabPane tab={name.split(".")[0]} key={index + 1}>
                                        <Card className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                                            <LegacyIcon style={{fontSize: 20, margin: -12, color: "#40a9ff"}} className="product-full-screen-icon" onClick={() => this.setFullScreenState()} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                            <Three360Viewer customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} url={[(baseURL + this.props.match.params.id + '/360Camera.jpg').replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform))]} fullScreen={this.state.isFullScreen}/>

                                            {/* <PanoramaViewer customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl={baseURL + this.props.match.params.id + '/' + name} /> */}
                                    </Card>
                                    </TabPane>
                                ))}
                                
                            </Tabs>}
                <Row style={{padding: 12}}>
                
                    <Col span={4}>
                    </Col>
                    <Col span={20} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button shape="round" style={{marginLeft: 2}} onClick={() => this.rejectRender()} disabled={this.state.onboarding}>Redesign Scene</Button>
                        <Button shape="round" style={{marginLeft: 2}} onClick={() => {this.showUnhappyModal()}} disabled={this.state.onboarding}>Flag if Unhappy</Button>
                        <Button shape="round" className="approve-btn" type="primary" style={{marginLeft: 2}} onClick={() => this.approveRender()} data-tut="reactour__approve_button">Approve Scene</Button>
                    </Col>
                </Row>
                <Modal bodyStyle={{paddingTop: "2px"}}
                    title="Tell Your Concerns"
                    visible={this.state.unhappyModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleUnhappyCancel}>
                    <div>
                        <Form className="input-form" onSubmit={this.state.handleSubmit}>
                            <Form.Item name="msg" label="Details" colon={false}>
                                {getFieldDecorator('msg', {
                                    rules: [{required: true, message: "Message is required!"}]
                                })(
                                    <TextArea
                                            placeholder = "Enter Message"
                                            rows={5}
                                            style={{height: "auto !important"}}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item name ={'unhappy_photos'} label="Reference Snapshots"  colon={false}>
                                {getFieldDecorator('unhappy_photos', {
                                    valuePropName: 'unhappy_photos',
                                    getValueFromEvent: e => e && e.fileList,
                                })(
                                    <Upload {...Constants.upload_props} multiple="true" listType="text" className="upload-lg-btn ant-upload-picture-card-wrapper">
                                        <div className="d-flex">
                                            <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                            <div className="ant-upload-text">
                                                <div className="up-info">Drop your Photos</div>
                                                <div>Support: JPG, PNG</div>
                                            </div>
                                        </div>
                                    </Upload>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
              
                <Modal
                    title={null}
                    closable={false}
                    footer={null}
                    visible={this.state.approvedModal}
                    bodyStyle={{paddingTop:0, paddingLeft: 0, paddingRight: 0}}
                    width={620}
                    centered={true}
                    >
                    <div style={{textAlign: "center"}}>
                        <img style={{width: 85, marginTop: 20}} src={require("../../../../assets/images/success-icon.png")}></img>
                    </div>
                    <div style={{width: 620, textAlign: "center"}}>
                        <h3 style={{marginTop: 12}}>360 Approved Successfully!</h3>
                        <p style={{width: 580, marginLeft:20, paddingLeft:45, paddingRight: 45}}>You have successfully approved your 360. Go to your scene Library to view it.</p>
                        <Button style={{marginTop: 12}} type="primary" onClick={() => this.handleRedirection()} >Go To Scene Library</Button>
                    </div>            
                </Modal>
            </CustomerMainLayout>
        );
    }
}

const ApproveScene = Form.create({ name: 'unhappy_details' })(ApproveSceneContainer);

export default ApproveScene;
