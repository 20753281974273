import React from 'react';
import {
    CheckCircleOutlined
} from '@ant-design/icons';
import {
    Row,
    Col,
    Card
} from 'antd';
import ComplaintInfo from '../ComplaintDetails/ComplaintInfo';
import ENVIRONMENT from '../../../../environments';
import { Link } from "react-router-dom";

const SiloComplaints = (props) => {
    const siloBaseUrl = ENVIRONMENT.QA_IMG_URL;

    const getCamera = (attachment) => {
        let camera = [];
        if (props.productAssetData['silo_data'] && props.productAssetData['silo_data'].length > 0) {
            camera = props.productAssetData['silo_data'].filter(img => (img.camera_name.includes(attachment) || attachment.includes(img.camera_name)));
            if (camera && camera.length > 0) {
                let img_format = camera[0].camera_name + '.' + camera[0].image_data.img_format;
                return img_format;
            }
        }
        return '';
    }

    return (
        <React.Fragment>
            <Row style={{ marginTop: 20 }}>
                <Col span={22} offset={1}>
                    <div style={{ marginTop: 10 }}>
                        {props.bread_crumbs}
                    </div>
                    <div className='manrope f-24 w-700' style={{ display: 'flex', alignItems: "center" }}>
                        <Link className="store-bd " to={`/products/${props.product_id}`} onClick={() => { props.setComplaintsView(false) }} ><img src="/img/back_arrow.png" style={{ marginRight: 20, cursor: "pointer" }} /> </Link>
                        Silo Complaints
                    </div>
                    {props.complaints.map((complaint) => {
                        const { status, issue, attachment, admin_message, submitted_on, id } = complaint
                        const productId = props.product_id
                        return (
                            <Row className="align-col-left" style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col style={{ width: "100%" }}>
                                    <Card className="complaint-card align-col-left card-shadow-product" bodyStyle={{ minWidth: 40, textAlign: "-webkit-center" }} style={{ marginTop: 10, width: '100%' }} bordered={false}>

                                        <div style={{ width: "100%", }} className="justify-in-start complaint-card-details" >
                                            <p style={{ paddingLeft: 15, overflow: 'hidden' }} className="manrope f-18 w-700">Issue:</p>
                                            <p style={{ paddingLeft: 15, overflow: 'hidden' }} className="manrope f-16 w-500"> {issue}</p>
                                        </div>

                                        <ComplaintInfo admin_message={admin_message} status={status} reference_images={null} submitted_on={submitted_on} />
                                        <div>
                                            {attachment.length > 0 ?
                                                <div style={{ width: '100%' }} className="display-flex-row manrope f-14 w-500" >
                                                    {status !== 'resolved' ?
                                                        <div style={{ borderRight: "0.01px solid #E3E3E3", paddingRight: 15, width: "50%" }} className="align-col-left">
                                                        <p style={{ paddingLeft: 15, paddingTop: 12, paddingBottom: 8 }}>Before</p>
                                                        <div className='complaint-renders'>
                                                            <img style={{ paddingLeft: 15, paddingBottom: 15 }} className="lifestyle-image-settings-custom" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.QA_IMG_URI + productId + '/' + (props.companyId == null ? props.username: ('company/' + props.companyId)) + '/' + getCamera(attachment)}></img>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    <div className="align-col-left" style={{ marginLeft: 15, width: "50%" }}>
                                                        <p style={{ paddingTop: 12, paddingBottom: 8 }} >{status === 'pending' ? 'In Progress' : status === 'resolved' ? 'Replaced With:' : 'Reviewed and Resolved'}</p>
                                                        {status === 'rejected' ?
                                                            <div className='in-progress-paddings complaint-renders flex' >
                                                                <CheckCircleOutlined className="manrope f-14 set-svg-dims" />
                                                            </div>
                                                        : status === 'pending' ?
                                                        <div className='in-progress-paddings complaint-renders flex' >
                                                            <img className='lifestyle-image-settings-custom h-100' src={(require("../../../../assets/images/inProgress icon.png"))}></img>
                                                        </div> : status === 'resolved' ? 
                                                        <div className='complaint-renders'>
                                                            <img style={{ paddingLeft: 15, paddingBottom: 15 }} className="lifestyle-image-settings-custom" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.QA_IMG_URI + productId + '/' + (props.companyId == null ? props.username: ('company/' + props.companyId)) + '/' + getCamera(attachment)}></img>
                                                        </div> : ''
                                                        }
                                                    </div>
                                                </div> : ''
                                            }
                                        </div>

                                    </Card>
                                </Col>

                            </Row>
                        )
                    })
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default SiloComplaints;