import React, { useEffect, useState } from 'react';
import { Grid, AutoSizer } from 'react-virtualized';
import './LibraryGrid.scss';

const LibraryGrid = (props) => {
    const height = props.height ?? 100;
    return(
        <div className='grid-scrollable' style={{height: `calc(100vh - ${height}px)`}}>
        <AutoSizer>
            {({ height, width }) => {
                const colCount =
                (Math.floor(width / props.maxColumnWidth) > 4 ?
                  4 :
                  Math.floor(width / props.maxColumnWidth)) || 1;
                props.setColumnCount(colCount);
                props.setRowCount(Math.ceil((props.data?.length) / colCount));
                const columnWidth = width / colCount;
                return(
                <Grid
                    className='product-tab-content-scrollable'
                    cellRenderer={props.cellRenderer}
                    columnCount={props.columnCount}
                    columnWidth={columnWidth}
                    height={height}
                    rowCount={props.rowCount}
                    estimatedRowSize={100}
                    rowHeight={props.getEstimatedRowSize}
                    width={width + 10}
                />);
            }}
        </AutoSizer>
        </div>
    )
}

export default LibraryGrid;