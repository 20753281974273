import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { Button, Row, Col, Card, Image } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ModelReferenceInfo = (props) => {
    const { referenceLinks, productData, productRefImages, downloadReferences } = useContext(props.context_for == "collaborate_qa" ? CollaborateQAContext : CustomerProductContext);

    return (<Row className='mt-40 mb-40'>
        <Col span={24} className="reference-section" style={{marginBottom:'40px'}}>
            <div className='display-flex j-s-b a-c w-100'>
                <h3 className='mb-0 manrope f-24 black-26'>Model Reference Info</h3>
                <Button onClick={downloadReferences} className='product-btn br-4 f-14 light-blue'>
                    <DownloadOutlined />Download References
                </Button>
            </div>

            {referenceLinks.length > 0 &&
                <div className='mt-30 display-flex j-s dir-col'>
                    <h6 className='manrope f-16 black-26'>Links</h6>
                    {referenceLinks.map((link, index) => (
                        <a className='manrope f-14 blue limit-lines limit-1' target='_blank' key={index} href={link}>
                            {link}
                        </a>
                    ))}
                </div>}

            {productData.guidelines &&
                <div className='mt-30 display-flex j-s dir-col'>
                    <h6 className='manrope f-16 black-26'>Guidelines</h6>
                    {productData.guidelines.split('\n').map((line, index) => (
                        <div className='manrope f-14 black-33' key={index}>
                            {line}
                        </div>
                    ))}
                </div>}

            {productRefImages.length > 0 &&
                <div className='mt-30 display-flex j-s dir-col'>
                    <h6 className='manrope f-16 black-26'>Files</h6>
                    <Col span={24} className='display-flex a-c j-s wrap'>
                        {productRefImages.map((reference_image, index) => (
                            <Card className="product-card-reference" key={index}>
                                {(['jpg', 'jpeg', 'png', 'svg'].includes(reference_image.format)) ?
                                    <Image src={reference_image.link} className="product-card-photo" /> :
                                    <a href={reference_image.link} target='_blank'>
                                        <div className='display-flex j-c a-c dir-col product-card-photo'>
                                            <DownloadOutlined className='manrope f-12 blue' />
                                            <div className='manrope f-12 blue'>
                                                .{reference_image.format}
                                            </div>
                                        </div>

                                    </a>}
                            </Card>
                        ))}
                    </Col>
                </div>}
        </Col>
    </Row>)
}

export default ModelReferenceInfo;