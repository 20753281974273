import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Input, Card } from "antd";
import AdminMainLayout from '../AdminMainLayout';
import CostBreakdown from "../CostBreakdown/CostBreakdown"
import InstanceHealth from "../InstanceHealth/InstanceHealth"
import AutomationRequestTable from "../AutomationRequestTable";

const { TabPane } = Tabs;

class AutomationRequests extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"14"}>
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false}>
          <TabPane tab="Waiting" key="1">
                <AutomationRequestTable state="waiting"/>
          </TabPane>
          <TabPane tab="Being Processed" key="2">
                <AutomationRequestTable state="being_processed"/>
          </TabPane>
          <TabPane tab="Completed" key="3" forceRender={true}>
                <AutomationRequestTable state="completed"/>
          </TabPane>
          <TabPane tab="Failed" key="4">
                <AutomationRequestTable state="failed"/>
          </TabPane>
          <TabPane tab="Aborted" key="5">
                <AutomationRequestTable state="aborted"/>
          </TabPane>
          <TabPane tab="Cost Details" key="6">
                <CostBreakdown/>
          </TabPane>
          <TabPane tab="Instance Health Status" key="7">
                <InstanceHealth/>
          </TabPane>
        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AutomationRequests;