import React, { useState, useEffect, useCallback, useContext } from 'react';
import FileConstants from '../../../../FileConstants';
import * as Constants from "../../CustomerComponents/Constants";
import {
    Button, Row,
    Modal,
    Col, Form, Input, Upload
} from 'antd';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
const { TextArea } = Input;

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');

const RejectionModal = () => {
    const { rejectModal, closeRejectModal, rejectFileList, setRejectFileList, rejectionDetails,
        rejectionPrice, cardStatus, requestStatus, saveCommentLoader, productAssetData,
        setErrorMessage, errorMessage, productData, rejectLoader, performRejection } = useContext(CollaborateQAContext);

    const [form] = Form.useForm();

    useEffect(() => {
        if (form && productData && productAssetData) {
            form.setFieldsValue({
                msg: productAssetData.rejection_details,
                reference_files: productAssetData.reference_files
            });
        }

    }, [form, rejectionDetails, productData, productAssetData]);

    const handleChangeRejectFilelist = (info) => {
        let fileList = [...info.fileList];
        setRejectFileList(fileList);
        if (info.file.status === "done") {
            setErrorMessage('');
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            } else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
        }
    };

    return (<Modal
        className="model-time-modal"
        title={<span className='manrope f-16 black-33 w-700'>Confirm Rejection</span>}
        visible={rejectModal}
        maskClosable={false}
        onCancel={closeRejectModal}>
        <div className='pd-32 pd-t-10'>
            <div className="note-bg-artist pd-10 gray justify-space-between mb-20">
                <div className='manrope f-12 black-55'>If you've added any feedback on the images, it will be shared with the modelling team for fixes.</div>
            </div>
            <Form form={form} layout={'vertical'}>
                <Row type='flex' gutter={[12, 0]}>
                    <Col span={24} className='mb-10'>
                        <Form.Item
                            label={<span className='manrope f-12'>Please add any additional comments below</span>}
                            name="msg" className="manrope f-12" colon={false} validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                { required: true, message: 'Please enter rejection message.' }
                            ]}>
                            <TextArea
                                rows={5}
                                className="custom-input-contain manrope f-12"
                                style={{ height: "auto !important" }}
                                placeholder={"Enter Rejection Message"}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} className="upload-pd mb-20" style={{ cursor: "default" }}>
                        <Form.Item
                            label={<span className='manrope f-12'>Please upload any additional files in the box below</span>}
                            name={'reference_files'}
                            colon={false}>
                            <Upload {...Constants.getUploadProps(productData.platform)}
                                onChange={handleChangeRejectFilelist}
                                onDrop={e => {
                                    console.log(e)
                                    console.log('file dropped')
                                }}
                                onRemove={file => {
                                    const index = rejectFileList.indexOf(file);
                                    const newFileList = rejectFileList.slice();
                                    newFileList.splice(index, 1);
                                    setRejectFileList(newFileList);

                                    return true;
                                }}
                                fileList={rejectFileList}
                                multiple="true"
                                openFileDialogOnClick={true}
                                listType="text"
                                className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-references">
                                <span>
                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                    <p className="manrope f-12 grey-77">Upload your reference files.</p>
                                    <p className="manrope f-12 grey-77">
                                        Supported formats: zip, jpg, png, tiff
                                    </p>
                                </span>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="justify-in-end">
                        <Form.Item className='mb-0-important'>
                            <div className="justify-in-end">
                                {
                                    productData.assigned_artist == '' && productData.uploaded_model == true && !productData.variant_of && requestStatus == 'payment_required' ?
                                        <span className='manrope f-14 justify-in-start' style={{ color: "#25D6A4", position: "absolute", left: "1%" }}>
                                            Price: ${rejectionPrice}
                                        </span>
                                        :
                                        ''
                                }
                                <Button
                                    loading={rejectLoader}
                                    disabled={rejectLoader || saveCommentLoader}
                                    onClick={(e) => performRejection(form, true)}
                                    className="modal-okay square font-12"
                                    type="primary"
                                    htmlType="submit">
                                    {
                                        productData.assigned_artist == '' && productData.uploaded_model == true && !productData.variant_of && requestStatus == 'payment_required' && cardStatus == 1 ?
                                            <span>
                                                Confirm & Process Payment
                                            </span>
                                            :
                                            productData.assigned_artist == '' && productData.uploaded_model == true && !productData.variant_of && requestStatus == 'payment_required' && cardStatus == 0 ?
                                                <span>
                                                    Confirm & Enter Payment Details
                                                </span>
                                                :
                                                <span>
                                                    Confirm
                                                </span>
                                    }
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {(errorMessage != '') ?
                <div className="manrope f-12 red" style={{ color: "#ff4d4f", textAlign: "center" }}>{errorMessage}</div>
                : ""}
        </div>
    </Modal>)

}

export default RejectionModal;