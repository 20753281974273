import React from 'react';
// import { Form } from '@ant-design/compatible';
import { CaretRightOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Table, message, Modal, Select, Button, Form, Input, Collapse, Popconfirm } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
const Option = { Select };
const { TextArea } = Input;
const { Panel } = Collapse;


const expandedRowRender = record => <p>{record.description}</p>;
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

const INPUT_BUNDLE_BASE_URL = 'https://all3d-reconstruction.s3.amazonaws.com/';
const OUTPUT_BUNDLE_BASE_URL = 'https://all3d-reconstruction.s3.amazonaws.com/intermediate_files_bundles/';

class ScansTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandedRowRender,
    title: undefined,
    showHeader,
    tableLayout: 'fixed',
    footer: false,
    scroll: undefined,
    hasData: true,
    dataSource: null,
    cancelJobAction: 'hide',
    jobDetailsAction: 'hide',
    inProgressState: 'hide',
};

getColumns = () => [
    {
      title: 'Model Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: text => <span>{text}</span>,
    },
    {
      title: 'User',
      dataIndex: 'username',
      key: 'username',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Job State',
      dataIndex: 'state',
      key: 'state',
      // className: this.state.inProgressState,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'cancel_action',
      dataIndex: 'cancel_action',
      className: this.state.cancelJobAction,
      render: (text, record) => (
        <span>
          <Popconfirm
              title="Are you sure delete this task?"
              onConfirm={() => this.cancelScan(record.name)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Cancel</a>
            </Popconfirm>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'view_results',
      className: this.state.jobDetailsAction,
      render: (text, record) => (
        <span>
            
        </span>
      ),
    }
  ];

  cancelScan = (name) => {
    axios.post(ENVIRONMENT.UPDATE_SCAN_DATE, {
      model_name : name,
      model_status: "Cancelled"
    })
      .then(res => {
          message.info("Scanning Job Cancelled");
          this.handleDataSet();
      });

  }

  handleDataSet = () => {
    let data = [];

    axios.post(ENVIRONMENT.GET_SCANS_DATA, {
      model_status : this.props.job_state
    })
      .then(res => {
        console.log(res.data)
        for (let i = 1; i <= (res.data.models).length; i++) {
            data.push({
              key: i,
              scan_id: res.data.models[i - 1]['id'],
              name: res.data.models[i - 1]['model_name'],
              date: res.data.models[i - 1]['date'],
              state: res.data.models[i - 1]['model_status'],
              username: res.data.models[i - 1]['customer_username'],
            });
        }
        this.setState({
          dataSource: data,
        })
      });
        
  }

 
  componentDidMount = () => {

    this.handleDataSet();

    let cancelJobAction = 'hide';
    if(this.props.job_state.includes('Enqueued')){
      cancelJobAction = 'show';

    }

    let jobDetailsAction = 'hide';
    if(this.props.job_state.includes('Completed') || this.props.job_state.includes('Failed')){
      jobDetailsAction = 'show';
    }

    this.setState({
      cancelJobAction: cancelJobAction,
      jobDetailsAction: jobDetailsAction,
    })
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandedRowRender: enable ? expandedRowRender : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state}
            columns={this.getColumns()}
            expandable={{
              expandedRowRender: record => (
                <span>
                <p style={{ margin: 5 }}>Input Bundle: <a href={INPUT_BUNDLE_BASE_URL + record.username + '/' + record.name}>download</a></p>
                {(record.state == 'Completed' || record.state == 'Failed')?
                <p style={{ margin: 5 }}>Output Bundle: <a href={OUTPUT_BUNDLE_BASE_URL + record.username + '/' + record.name + '.zip'}>download</a></p>
                :""
                }
                </span>  
              ),
              // rowExpandable: record => (record.state == 'Completed' || record.state == 'Failed'),
            }}
            dataSource={dataSource} />
        }
      </div>
    );
  }
}

export default ScansTable;
