import React from 'react';
import _ from 'lodash';
import { Breadcrumb, Tooltip, Row, Col } from 'antd';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import './AddProuctLifestyle.scss';

const RoomTypeSelect = (props) => {
  const { setStep, setRoomType, productId } = props;

  const handleSceneTypeClick = (val) => {
    setRoomType(val);
    setStep(2);
  };

  const SelectCard = (props) => {
    const { img, title, text, type } = props;
    return (
      <div className='scene-card' onClick={()=>{
        handleSceneTypeClick(type);
      }}>
        <img src={img} className='card-image' />
        <div className='card-content'>
          <p className='manrope subheading'>
            {title}
          </p>
          <p className='manrope text'>
            {text}
          </p>
        </div>
      </div>
    );
  };

  const handleBack = (event) => {
    event.preventDefault();
    window.history.back();
  };

  return (<div>
    <Header className='product-nav-header'>
      <Row className='display-flex j-s-b a-c'>
        <Col span={12}>
          <Breadcrumb className='display-flex j-s a-c'>
            <Breadcrumb.Item >
              <a
                className='manrope f-14 lh-28 black-73 white-space-text'
                href="#"
                onClick={handleBack}
              >
                <ArrowLeftOutlined className='mr-8' />
                  Your Product
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item className='manrope f-14 lh-28 black-d9 limit-lines limit-1'>
              Generate Lifestyle Image
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </Header>
    <Row className='scene-select-container'>
      <Row className='scene-select-sub-container'>
        <Row>
          <Col className='col-xs-9'>
            <p className='manrope subheading'>
                            First, let’s set the scene for your item.
              <Tooltip
                title='Within a scene, you’ll be able to add more products and props, adjust lighting, and easily position the camera to generate your image.'
              >
                <InfoCircleOutlined className='info-icon' />
              </Tooltip>
            </p>
            <p className='manrope heading'>
                            Do you want to start with an empty space or select a styled scene?
            </p>
          </Col>
          <Col className='col-xs-3'>
            <img src='/img/seat.png' />
          </Col>
        </Row>
        <Row>
          <Col className='col-xs-6'>
            <SelectCard
              title = "Select an empty space"
              text = "From your spaces or store templates to create a new scene for your item."
              img = "/img/emptySpace.png"
              type = 'space'
            />
          </Col>
          <Col className='col-xs-6'>
            <SelectCard
              title = "Select a styled scene"
              text = "From your scenes or store templates to place your item and easily make changes."
              img = "/img/styledScene.png"
              type = 'scene'
            />
          </Col>
        </Row>
      </Row>
    </Row>
  </div>);
};

export default RoomTypeSelect;
