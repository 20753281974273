import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip, Card, message, Empty } from 'antd';
import {
  RightOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';
import DottedLoader from '../../DottedLoader';

const image_style = {
  width: '100%',
  height: 'calc(100vh -  282px)',
  objectFit: 'contain',
};
const fullscreen_image_style = {
  width: '100vw',
  height: '100vh',
  objectFit: 'contain',
};

const TestModelStaticGuidelinesCard = ({
  referenceImages,
  platform,
  adminScrollCheck,
  compareView,
}) => {
  const [, updateState] = React.useState();
  const [viewType, setViewType] = useState(null);
  const [previewImage, setPreviewImage] = useState(true);
  const [borderState, setBorderState] = useState(false);
  const [imageSelected, setImageSelected] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [image, setImage] = useState(null);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [selectedImage, setSelectedImage] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [collapseViewer, setCollapseViewer] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const hideViewer = () => {
    setCollapseViewer(true);
  };
  const showViewer = () => {
    setCollapseViewer(false);
  };

  const setFullScreen = () => {
    if (!isFullScreen) {
      message.info('Press ESC to exit full screen');
    }
    setIsFullScreen(!isFullScreen);
  };

  const view2DImage = (image) => {
    setViewType('2D');
    const temp_img = new Image();
    temp_img.src = require('../../../../assets/images/blank.jpeg');
    // Set blank image first
    setImage(temp_img.src);
    setSelectedImage(temp_img.src);
    setDisplayLoader(true);
    forceUpdate();
    display_loader_and_set_image(image);
  };

  const display_loader_and_set_image = (image_src) => {
    const img = new Image();
      setDisplayLoader(false);
      setImage(image_src);
      setSelectedImage(
          image_src ||
          (referenceImages &&
            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI +
          referenceImages[0].uid + "/" + referenceImages[0].name),
      );
      forceUpdate();
    img.src = image_src;
  };

  useEffect(() => {
    if (referenceImages.length > 0) {
      view2DImage(
        ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URIL +
          referenceImages[0].uid + "/" + referenceImages[0].name
      );
    }
  }, [referenceImages]);

  const checkScroll = () => {
    const elem = document.getElementById('review-scrollable');
    const carousel_elem = document.getElementById('pdp-carousel-ref');
    if (elem != null) {
      elem.style.scrollBehavior = 'unset';

      const y1 = elem.scrollTop;
      elem.scrollTop += 1;
      const y2 = elem.scrollTop;
      elem.scrollTop -= 1;
      const y3 = elem.scrollTop;
      elem.scrollTop = y1;

      const x1 = elem.scrollLeft;
      elem.scrollLeft += 1;
      const x2 = elem.scrollLeft;
      elem.scrollLeft -= 1;
      const x3 = elem.scrollLeft;
      elem.scrollLeft = x1;
      const right = document.getElementById('right-scroll-btn');
      const left = document.getElementById('left-scroll-btn');
      const nextValue = elem.scrollLeft - 273;

      if (elem.style.left == '94px') {
        elem.style.left = '94px';
      } else {
        if (elem.scrollLeft == 0) {
          elem.style.left = '20px';
        }
      }
      if (right != null && (x1 !== x2 || x2 !== x3)) {
        right.style.display = 'inline-block';
        if (left != null) {
          if (elem.style.left == '20px' && elem.scrollLeft == 0) {
            left.style.display = 'none';
          }
        }
        if (elem.style.left == '20px' && nextValue <= 0) {
          // for right scroll
          if (window.innerWidth <= 1500 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1320 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1200 && compareView) {
            elem.style.width = '100%';
          }
          if (compareView) {
            console.log('set width', carousel_elem);
            carousel_elem.style.width = '80%';
          }
        } else {
          // for left scroll
          if (window.innerWidth <= 1500 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1320 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1200 && compareView) {
            elem.style.width = '100%';
          }

          if (compareView) {
            console.log('set width', carousel_elem);
            carousel_elem.style.width = '80%';
          }
        }
      }
      if (!compareView && adminScrollCheck) {
        elem.style.width = '100%';
        elem.style.left = '20px';
      }

      if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
        right.style.display = 'none';
        left.style.display = 'none';
      }
      // ---- NEED FOR LATER USE ----
      // console.log("H",x1 !== x2 || x2 !== x3);
      // console.log("V",y1 !== y2 || y2 !== y3);
    }
  };

  const leftScroll = () => {
    const elem = document.getElementById("review-scrollable");
    if (elem != null) {
      elem.style.scrollBehavior = "smooth";
      elem.scrollLeft -= 273;
      const left = document.getElementById("left-scroll-btn");
      const right = document.getElementById("right-scroll-btn");
      if (left != null && elem.scrollLeft == 0) {
        left.style.display = "none";
      }

      if (right != null) {
        right.style.visibility = "visible";
        elem.style.left = "20px";
      }
    }
  };

  const rightScroll = () => {
    const elem = document.getElementById("review-scrollable");
    if (elem != null) {
      elem.style.scrollBehavior = "smooth";
      elem.scrollLeft += 273;
      const left = document.getElementById("left-scroll-btn");
      const right = document.getElementById("right-scroll-btn");
      elem.style.left = "94px";
      if (right != null) {
        if (left != null) {
          left.style.display = "inline-block";
        }

        const newScrollLeft = elem.scrollLeft;
        const divWidth = elem.offsetWidth;
        const scrollwidth = elem.scrollWidth;
        if (newScrollLeft === scrollwidth - divWidth) {
          right.style.visibility = "hidden";
        }
      }
    }
  };


  useEffect(() => {
    checkScroll();
    forceUpdate();
  }, [compareView, adminScrollCheck]);

  useEffect(() => {
    checkScroll();
    forceUpdate();
  }, []);

  React.useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  });

  return (
    <>
      {displayLoader ? (
        <DottedLoader />
      ) : (
        <>
          <Card
            className={
              isFullScreen ? "product-full-screen" : "product-viewer-normal"
            }
            bodyStyle={{ padding: isFullScreen ? 0 : 12 }}
            style={{
              zIndex: isFullScreen ? 5 : "unset",
            }}
            id="pdp-card"
          >
            <div
              style={{
                height: "max-content",
              }}
            >
              <div
                className="bg-model"
                style={{
                  zIndex: "1",
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
              >
                <div className="bg-model-text">Reference Scans and Images</div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* 2D dimensions */}
                {referenceImages.length > 0 ? (
                  <ImageZoomAdmin
                    compare={true}
                    style={{ cursor: "zoom-in" }}
                    custom_class={
                      isFullScreen ? fullscreen_image_style : image_style
                    }
                    image={image ? image.replace(".tiff", ".jpg") : null}
                    className="image-zoom"
                  />
                ) : (
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Empty
                      className="position-empty-data"
                      description={
                        <span className="manrope f-14 black-55">
                          No References Images
                        </span>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pdp-carousel-scroll" id="pdp-carousel">
              {!collapseViewer ? (
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      Hide image viewer
                    </span>
                  }
                >
                  <DownCircleOutlined
                    className="left-coll-icon"
                    onClick={hideViewer}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      Show image viewer
                    </span>
                  }
                >
                  <UpCircleOutlined
                    className="right-coll-icon"
                    onClick={showViewer}
                  />
                </Tooltip>
              )}
              {!collapseViewer && (
                <div
                  onClick={leftScroll}
                  className="icon-con-left"
                  id="left-button-scr"
                >
                  <RightOutlined className="left-button-scr" />
                </div>
              )}
              {!collapseViewer ? (
                <div id="review-scrollable" className="pdp-scrollable">
                  {referenceImages.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        onClick={() => {
                          view2DImage(
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + item.uid + "/" + item.name
                          );
                          setPreviewImage(true);
                          setBorderState(false);
                          setImageSelected();
                        }}
                        bodyStyle={{
                          padding: "2px",
                          minWidth: 40,
                          textAlign: "-webkit-center",
                        }}
                        className="product-thumbnail-card"
                        style={{
                          border:
                            image ==
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI +
                                item.uid +
                                "/" +
                                item.name && borderState == false
                              ? "1px solid #333333"
                              : "1px solid #F0F0F0",
                        }}
                      >
                        <img
                          className="product-thumbnail-img"
                          src={
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + item.uid + "/" + item.name
                          }
                          alt="Clickable Product Image"
                        />
                      </Card>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              {!collapseViewer && (
                <div
                  onClick={rightScroll}
                  className="icon-con"
                  id="right-button-scr"
                >
                  <RightOutlined className="right-button-scr" />
                </div>
              )}
            </div>
            <Tooltip
              title={!isFullScreen ? "Full Screen" : "Exit Full Screen"}
              placement="left"
            >
              <img
                src={
                  isFullScreen
                    ? "/img/fullScreenIcon-2.svg"
                    : "/img/fullScreenIcon.jpg"
                }
                alt="Fullscreen Icon"
                style={{
                  position: "absolute",
                  bottom: "20px",
                  cursor: "pointer",
                  opacity: "0.8",
                  borderRadius: "4px",
                  right: "20px",
                }}
                className="product-full-screen-icon"
                onClick={() => setFullScreen()}
              />
            </Tooltip>
          </Card>
        </>
      )}
    </>
  );
};

export default TestModelStaticGuidelinesCard;
