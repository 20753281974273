import React from 'react';
 
const IntermediateMessage = (props) => {

    return (
        <>
        <div className="center-on-screen">
            <div style={{textAlign: "center"}}>
                <img src={props.icon}/>
            </div>
            <div style={{textAlign: "center",margin: "20px auto"}}>
                <h2  className={`manrope f-28 w-700 justify-in-center ${props.status == "error" ? `red-error` : props.status == 'in_progress' ?  `yellow` : `green-75`}`}>{props.heading}</h2>
                <p className="manrope f-18 grey-77">{props.text}</p>
                {props.buttons}
            </div>
        </div>
        </>
      );
}

export default IntermediateMessage;