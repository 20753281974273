import { Button, Divider, Modal } from 'antd'
import React from 'react'

function SpaceColorVariationModal( props ) {

  const showSpaceVariationModal = () => {
    props.setSpaceColorVariationModal(false);
    props.openVariationTool();
  }

  return (
    <Modal 
        visible={props.spaceColorVariationModal}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}   
        onCancel={() => props.setSpaceColorVariationModal(false)}
        centered
        footer={[]}
    >
        <div >
            <span className='manrope f-18 w-700'>
                Space Color & Texture Variation
            </span>
        </div>
        <Divider/>
        <div className='justify-in-center'>
            <p className='manrope f-14'>
                You will be taken into the space configuration tool to apply your color and texture changes and request a variation.                 
            </p>
        </div>
        <div className="justify-in-end">
            <Button style={{marginRight:10}} className="modal-okay-gray square font-14" onClick={() => props.setSpaceColorVariationModal(false)}>
                Cancel
            </Button>
            <Button className="modal-okay square font-14" onClick={()=> {showSpaceVariationModal()}} >
                Confirm
            </Button>
        </div>
    </Modal>
  )
}

export default SpaceColorVariationModal