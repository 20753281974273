import React from 'react';
import {
    Row,
    Col,
    Tabs,
    message,
    Card,
    Collapse,
    Image,
    Checkbox,
    List,
    Typography,
    Tooltip,
    Button} from 'antd';
import * as JSZip from 'jszip';
import axios from 'axios';
import ReactPlayer from 'react-player';
import * as Constants from '../../CustomerComponents/Constants';
import ENVIRONMENT from '../../../../environments'
import PanoramaViewer from '../../CustomerComponents/PanoramaViewer';
import Dragger from 'antd/lib/upload/Dragger';
import { SPACE_GUIDELINES } from '../../../../environments/env';
import * as Utilities from '../../Utilities';
const { TabPane } = Tabs;
const { Panel } = Collapse;

const bundleLinks = {
    Countryside: ENVIRONMENT.COUNTRY_SIDE_URL,
    Garden: ENVIRONMENT.GARDEN_URL
}

class RoomStaticGuidelines extends React.Component {
    threeSixtyViews = [];
    
    constructor(props){
        super(props);
        this.state = {
            room_id: 0,
            rejections_data: [],
            selected_scene: '',

            checkedScene: [],
            checkSceneState: false,
            room_guidelines: {
                room_name: '',
                room_photos: [],
                // product_manuals: [],
                reference_urls: [],
                floorplan: [],
                outside_view: {},

                elements: [
                    {
                        elementName: '',
                        elementImages: []
                    }
                ]

            },
            three_sixties: [],
            isFullScreen: false,
            annotated_floor_plan: false,
            disable_upload: false,
            base_platform: 'aws',
            base_platform_loader: true
        }
    }

    componentDidMount() {
        var room_id = 0;

        if (this.props.disable_upload != undefined) {
            this.setState({
                disable_upload: this.props.disable_upload
            })
        }

        if (this.props.match != undefined)
        {
            room_id = this.props.match.params.id;
        }
        else
        {
            room_id = this.props.room_id;
        }

        let parent = this;
        this.setState({room_id: room_id},() => {
            var img = document.createElement('img');
            img.onload = function() {
                // image exists and is loaded
                parent.setState({
                    annotated_floor_plan: true
                })
            }
            img.src = ENVIRONMENT.getBaseURL(this.props.platform) + ENVIRONMENT.FLOOR_PLAN_URI + room_id + '.jpg';
        });

        axios.post(ENVIRONMENT.FETCH_ROOM, {room_id})
            .then(res => {
                this.setState({
                    room_guidelines: res.data,
                    three_sixties: res.data.three_sixties
                },() => {
                    this.getBasePlatform(res.data.immediate_parent_variant, res.data.variant_of)
                });
            })
        axios.post(ENVIRONMENT.GET_ROOM_REJECTIONS, { room_id })
            .then(res => {

                this.setState({
                    rejections_data: res.data
                });

            })

    }

    getFullScreenTour = () =>  {
        if (this.state.isFullScreen) {
            this.setState({ isFullScreen: false });
            if(this.state.threed_tour != "" ) {

                setTimeout(function() {
                    this.virtualShowroom.toggleFull(false);
                }.bind(this), 1000)
            }
        }
        else {
            this.setState({ isFullScreen: true });
            message.info('Press ESC to exit full screen');
            if(this.state.threed_tour != "" ) {
                this.virtualShowroom.toggleFull(true);
            }
        }
    }

    setFullScreenState = (name,index) => {
        if (this.state.isFullScreen) {
            this.setState({ isFullScreen: false });
            if (this.threeSixtyViews[name] != null || this.threeSixtyViews[name] != undefined) {
                this.threeSixtyViews[name].toggleFull(false);
            }
        }
        else {
            this.setState({ isFullScreen: true });
            message.info('Press ESC to exit full screen');
            if (this.threeSixtyViews[name] != null || this.threeSixtyViews[name] != undefined) {
                this.threeSixtyViews[name].toggleFull(true);
            }
        }
    }

    checkFolderStructure = (file) => {
        let return_check = true;
        let parent = this;
        let vray_exist = false;
        let high_exist = false;
        let max_high_id = false;
        let upload_error = '';
        let new_zip = new JSZip();
        return new_zip.loadAsync(file)
        .then((zip) => {
        for(let key in zip['files']){
            if ((!high_exist || !max_high_id) && key.includes('High/')) {
                high_exist = true;
                if (!max_high_id) {
                  let max_id = key.split('/')[1];
                  console.log("max_id", key, max_id)
                  if (max_id == (parent.state.room_id + '.max')) {
                    max_high_id = true;
                  }
                }
              }
            if (!vray_exist && key.includes('VrayTextures/')){
            vray_exist = true;
            }
        }
        if (!high_exist && !vray_exist) {
            upload_error = "High and VrayTextures Folders are missing. Correct your folder structure and reupload file."
            return_check = false;
        }
        else if (high_exist && !vray_exist) {
            upload_error = "Incorrect folder structure! VrayTextures folder is missing. Correct your folder structure and reupload file."
            return_check = false;
        }
        else if (!high_exist && vray_exist) {
            upload_error = "Incorrect folder structure! High folder is missing. Correct your folder structure and reupload file."
            return_check = false;
        }
        else if (high_exist && vray_exist) {
            upload_error = '';
            if (!max_high_id) {
                upload_error = "Invalid max file name. It must be named " + parent.state.room_id  + '.max'
                return_check = false;
            }
        }
          parent.props.changeUploadError(upload_error)
          return return_check;
        }).catch( error => {
            parent.props.changeUploadWarning("Warning: File is too large!");
        }
        );
      }

      getBasePlatform = (immediate_parent_variant = undefined, variant_of = undefined) =>
      {
        let room_id = null;
        if(immediate_parent_variant)
            room_id = immediate_parent_variant;
        else if(variant_of)
            room_id = variant_of;
            
        if(room_id != null){
        axios.post(ENVIRONMENT.FETCH_ROOM, {room_id})
            .then(res => {
                this.setState({
                    base_platform: res.data.platform,
                    base_platform_loader: false
                },() => {

                });
            })
        }
      }

    render() {
        console.log(this.state.room_guidelines)
        let roomReferences = ''
        if (this.state.room_guidelines && this.state.room_guidelines.reference_urls) {
            roomReferences = this.state.room_guidelines.reference_urls.length != 0 ? this.state.room_guidelines.reference_urls.map((ele, index) => {
                return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                      <span className="note-text gray urls"><a href={ele} target='_blank' style={{color: "#276DD7"}}>{ele}</a></span>
                </div>
            }) : "";
        }

        let raw_files = ''
        if(this.state.room_guidelines && this.state.room_guidelines.raw_files){
                raw_files = this.state.room_guidelines.raw_files.map((ele, index) => {
                let file_url = ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
                return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                <span className="note-text gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
                </div>
            });
        }

        let tag_names = '';
        if (this.state.room_guidelines && this.state.room_guidelines.tags != undefined) {
        tag_names = this.state.room_guidelines.tags.map((ele, index) => {
            return <div className="note-bg-artist gray" style={{marginRight: 5}}>
                <span className="note-text blue">{ele}</span>
            </div>
        });
        }

        let roomPhotos = (this.state.room_guidelines.room_photos || []).map((ele, index) => {
            let fileURL = ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
            return <Col>
                <Card className="product-artist-card">
                    <Image
                    className="product-photo-artist" src={fileURL}
                    />
               </Card>
            </Col>
        });
        let floorplanPhotos = (this.state.room_guidelines.floorplan || []).map((ele, index) => {
            let fileURL = ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
            if (ele.name.includes('.jpg') || ele.name.includes('.png') || ele.name.includes('.jpeg')) {
                return  <div style={{textAlign:"left", marginBottom: 30}}>

                    <div className="manrope f-16 grey-77" style={{textAlign:"left", marginBottom: 10}}>
                        Floor Plan
                    </div>
                <Col>
                    <Card className="product-artist-card floorplan">

                        <Image
                        className="product-photo-artist floorplan" src={fileURL}
                        />

                    </Card>
                </Col>
            </div>
            }
            else {
                return  <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                <span className="note-text gray urls"><a href={fileURL} target='_blank' style={{color: "#276DD7"}}>{fileURL}</a></span>
                </div>
            }

        });

        let annotatedFloorplan =  null;
        annotatedFloorplan = this.state && this.state.room_id && this.state.annotated_floor_plan ? <div style={{textAlign:"left", marginBottom: 30}}>

            <div className="manrope f-16 grey-77" style={{textAlign:"left", marginBottom: 10}}>
                Labelled Floor Plan
            </div>
            <Col>
                <Card className="product-artist-card floorplan">

                    <Image
                    className="product-photo-artist floorplan" src={ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.FLOOR_PLAN_URI + this.state.room_id + '.jpg'}
                    />

                </Card>
            </Col>
        </div>:''


        let outsideViewData = '';
        if(this.state.room_guidelines.outside_view.constructor == Object) {
           if(this.state.room_guidelines.outside_view.name != undefined) {
                outsideViewData = <span>

                {(bundleLinks[this.state.room_guidelines.outside_view.name] != undefined)?
                    <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
                        <span className="note-text gray urls" style={{textTransform:"capitalize"}}>You can use outside view from this<a href={bundleLinks[this.state.room_guidelines.outside_view.name].replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform))} target='_blank' style={{color: "#276DD7"}}> bundle</a>.</span>
                    </div>
                :""
                }
                <Row>
                    <Col>
                        <Card className="product-artist-card">
                            <Image
                            className="product-photo-artist" src={this.state.room_guidelines.outside_view.image}
                            />
                        </Card>
                    </Col>
                </Row>

                </span>
           }
        }

        if(Array.isArray(this.state.room_guidelines.outside_view)) {
            outsideViewData = (this.state.room_guidelines.outside_view || []).map((ele, index) => {
                let fileURL = ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
                return <Col>
                <Card className="product-artist-card">
                    <Image
                    className="product-photo-artist" src={fileURL}
                    />
                </Card>
            </Col>
            });
        }

        let uploadedOutsideViewData = ''

        if(Array.isArray(this.state.room_guidelines.outside_view_custom)) {
            uploadedOutsideViewData = (this.state.room_guidelines.outside_view_custom || []).map((ele, index) => {
                let fileURL = ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
                return <Col>
                <Card className="product-artist-card">
                    <Image
                    className="product-photo-artist" src={fileURL}
                    />
                </Card>
            </Col>
            });
        }


        let roomElements = '';
        if (this.state.room_guidelines && this.state.room_guidelines.elements && this.state.room_guidelines.elements.length > 0) {

        roomElements =  <div className="j-start-a-start">
            <Row>
                
        {this.state.room_guidelines.elements.map(element => (
            element ?
            <Col span={6}>
                <div className="bg-tag" style={{width:'fit-content'}}>
                    <span className="manrope f-16 black-33 capitalize">
                        {element.elementName}
                    </span>
                </div>
                {((element.elementImages && element.elementImages.length > 0) || ((element.elementText && !Array.isArray(element.elementText)) || (element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0)  )) &&
                <Card className="product-artist-card w-auto" style={{padding: 16, marginTop: 8, maxWidth: 250}}>
                    {element.elementImages && element.elementImages.length > 0 && <div>
                        <div className="manrope f-18 grey-77" style={{marginBottom: 8}}>Reference Images</div>
                        <div className="justify-in-start">
                        {element.elementImages.map(photo => (
                            <Card className="product-artist-card">
                                <Image
                                className="product-photo-artist" src={ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)}
                                />
                            </Card>
                        ))}
                        </div>

                    </div>}
                    {element.elementText && !Array.isArray(element.elementText) ?
                     <div>
                        <div className="manrope f-18 grey-77" style={{marginBottom: 8}}>Reference URL(s)</div>
                        <div className="manrope f-14 blue hover-change clamp-w-width" style={{overflow: 'hidden'}}>{element.elementText}</div>
                    </div>:
                    element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0 ?
                    <div>
                        <div className="manrope f-18 grey-77" style={{marginBottom: 8}}>Reference URL(s)</div>
                        {element.elementText.map((element_text) => (
                            <div style={{display:'block',marginBottom: 5}} className="manrope f-14 blue hover-change clamp-w-width"><a target="_blank" href={element_text}>{element_text}</a></div>
                        ))}
                    </div> :''}
                </Card>}
            </Col> : ''

        ))}

        </Row>
        </div>
        }

    let rejections_data = '';
    if (this.state.rejections_data != undefined) {
      rejections_data = this.state.rejections_data.map((ele, index) => (
        <div style={{margin: '24px 16px'}}>
          <div className='artist-upload-txt small align-text'>Date <span style={{color:"#333333"}}>{Utilities.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span></div>
          <div className="flagged-txt gray">
            Comment
          </div>
          <div className="flagged-txt black" style={{marginBottom: 30}}>
            <ul>
              {ele.rejection_details.split('\n').map((item, index) => {
                return <li>{item}</li>
              })}
            </ul>
          </div>

          {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

            <span>
            <div className="flagged-txt gray">
              Uploads
            </div>
            {ele.reference_files && ele.reference_files.map((file,index) => (
                <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                  {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".svg")) ?
                  <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}/> :
                  (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                  <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                  <div className="flagged-txt upload">{file.name}</div>
                </a>
              ))}
              {(this.state.rejections_data.length > 1 && index != (this.state.rejections_data.length - 1)) ? <hr style={{borderTop: "1px solid #999999"}}/> : ''}
          </span>: ''}
        </div>
      ));
    }

        return (
            <div style={{padding: 10}}>
                {this.state.rejections_data.length > 0 && this.props.room_guidelines && this.props.room_guidelines.model_status == -4 ? <span>
                    { ( this.state.three_sixties && this.state.three_sixties.length == 0) ?
                        <Card className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                            <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                        <img
                                        src={this.state.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}
                                        alt="Fullscreen Icon"
                                        style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}}
                                        className="product-full-screen-icon"
                                        onClick={() => this.setState({isFullScreen: !this.state.isFullScreen})} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                    </Tooltip>

                            <PanoramaViewer id={'360Camera'} customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl= {ENVIRONMENT.getBaseURL(this.props.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + this.state.room_id + '/360Camera.jpg'} fullscreen={this.state.isFullScreen}/>
                        </Card>
                        :
                        (this.state.three_sixties != undefined && this.state.three_sixties.length > 0) ?
                            <Tabs
                                    tabBarStyle={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                centered
                                defaultActiveKey='1'>
                                {this.state.three_sixties.map((name, index) => (
                                    <TabPane tab={name.split(".")[0]} key={index + 1} className="tabAttr" >
                                        <Card className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                                        <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                            <img
                                            src={this.state.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}
                                            alt="Fullscreen Icon"
                                            style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}}
                                            className="product-full-screen-icon"
                                            onClick={() =>this.setState({isFullScreen: !this.state.isFullScreen})} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                        </Tooltip>
                                        <PanoramaViewer id={name} customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl= {ENVIRONMENT.getBaseURL(this.props.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + this.state.room_id + '/' + name}  fullscreen={this.state.isFullScreen}/>
                                        </Card>
                                    </TabPane>
                                ))}

                            </Tabs>
                            :
                            ""

                    }

                </span>: ''}
            {(this.props.room_guidelines && this.props.room_guidelines.model_status == 8) ?
            <Row style={{marginTop: 16}}>
                {this.props.validation_generated ?
                <Col span={12} style={{paddingRight: "5%"}}>
                    {
                        this.props.validation_report ?
                        this.props.validation_check && this.props.invalid_val_length == 0  ?
                        <div className="validation-heading" style={{marginBottom: 16}}>
                            <Checkbox className={this.state.auto_val_length > 0 ? "yellow-checkbox" : "green-checkbox"} checked={true} style={{marginRight: 10}}></Checkbox> <span>Model Validation Summary</span>
                        </div>:
                        <div className="validation-heading" style={{marginBottom: 16}}>
                            <img src="/img/alert-triangle.png" style={{marginRight: 10}}/><span>Model Validation Summary</span>
                        </div> : ''
                    }
                     {!this.props.space_valid && this.props.validation_check ?
                        <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>
                            <span className="scene-note flag red">
                                The space bundle you uploaded is invalid. Please re-upload the correct bundle.
                            </span>
                        </div>
                        : !this.props.space_valid && !this.props.validation_check ?
                        <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>
                            <span className="scene-note flag red">
                                The system was unable to detect space root nodes. Please re-upload the correct bundle. (Refer to Section 5 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>.)
                            </span>
                        </div> : ''}
                    {this.props.invalid_val_length == 0 ? '' :
                    <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => <div className="draw-full-circle red" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{this.props.invalid_val_length}</span></div>}
                    className="collapse-validation">
                    <Panel header={<span className="modal-text red">What Went Wrong?</span>} key="1" className="site-collapse-custom-panel">
                        <div className="collapse-validation-collapse">
                        {this.props.invalid_data_high.length == 0 ? '' :
                            <span>
                                <div className='val-sub-heading width-height-label'>High Poly</div>                                <List
                                bordered={false}
                                dataSource={this.props.invalid_data_high}
                                className="list-validation"
                                renderItem={item => (
                                <List.Item className="list-item-validation">
                                    <Typography.Text mark className="mark-style" style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-triangle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                </List.Item>
                                )}
                            />
                            </span>}
                        </div>
                    </Panel>
                    </Collapse>}
                    {this.props.auto_val_length == 0 ? '' :
                    <Collapse
                    bordered={false}
                    defaultActiveKey={this.props.invalid_val_length == 0 ? ['1']: ['0']}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => <div className="draw-full-circle yellow" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{this.props.auto_val_length}</span></div>}
                    className="collapse-validation">
                        <Panel header={<span className="modal-text yellow">Automatically Fixed</span>} key="1" className="site-collapse-custom-panel">
                            <div className="collapse-validation-collapse">
                            {this.props.automatically_fixed_high.length == 0 ? '' :
                            <span>
                                <div className='val-sub-heading width-height-label'>High Poly</div>                                <List
                                bordered={false}
                                dataSource={this.props.automatically_fixed_high}
                                className="list-validation"
                                renderItem={item => (
                                <List.Item className="list-item-validation">
                                    <Typography.Text mark className="mark-style" style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-circle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                </List.Item>
                                )}/>
                            </span>}

                            </div>
                        </Panel>
                    </Collapse>}
                    {this.props.success_val_length == 0 ? '' :
                    <Collapse
                    bordered={false}
                    defaultActiveKey={this.props.invalid_val_length == 0  && this.props.auto_val_length == 0 ? ['1']: ['0']}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => <div className="draw-full-circle" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{this.props.success_val_length}</span></div>}
                    className="collapse-validation">
                    <Panel header={<span className="modal-text green">Successfully Validated</span>} key="1" className="site-collapse-custom-panel">
                        <div className="collapse-validation-collapse">
                        {this.props.successfully_validated_high.length == 0 ? '' :
                        <span>
                            <div className='val-sub-heading width-height-label'>High Poly</div>
                            <List
                            bordered={false}
                            dataSource={this.props.successfully_validated_high}
                            className="list-validation"
                            renderItem={item => (
                            <List.Item className="list-item-validation">
                                <Typography.Text mark className="mark-style" style={{marginRight: 10}}><Checkbox className="green-checkbox" checked={true}/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                            </List.Item>
                            )}/>
                        </span>}

                        </div>
                    </Panel>
                    </Collapse>}
                </Col>:
                <Col span={12} style={{paddingRight: "5%"}}>
                    <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>
                        <span className="scene-note flag red">
                            Validation Summary could not be generated. Please reupload your file.
                        </span>
                    </div>
                </Col>}
                {this.props.reupload_flag ?
                <Col span={12} style={{paddingLeft: "5%"}}>
                <div className="dragger-upload-div" style={{maxWidth:"100%",padding:"20px"}}>
                <Dragger style={{display: 'inline-block', width: "100%"}}
                    className="upload-dragger-artist"
                    multiple={false}
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                    disabled={this.state.disable_upload || !this.props.reupload_flag || this.state.room_guidelines.model_retries >= this.props.retry_count || this.props.status == 6 || this.state.room_guidelines.model_status == 6}
                    openFileDialogOnClick={!(this.props.validateFileUploads(this.props.modelFileList) == 'error')}
                    beforeUpload={file => {
                      if(file.name != this.state.room_id + '.zip'){
                          this.props.changeUploadError("Invalid archive name, archive must be named " + this.state.room_id + '.zip')

                            return false;
                      }
                      else{
                          this.checkFolderStructure(file);
                        }
                    }}
                    {...(Constants.getRoomModelUploadProp(this.props.platform))}
                    onRemove={file => {
                        const index = this.props.modelFileList.indexOf(file);
                        const newFileList = this.props.modelFileList.slice();
                        newFileList.splice(index,1);
                        this.props.changeModelFileList(newFileList);
                        this.props.changeUploadError('');
                        this.props.changeUploadWarning('');
                        this.props.changeUploadFailedError('');
                    }
                    }
                    onChange = {this.props.handleReUploadChange}
                    listType="text"
                    fileList={this.props.modelFileList}>
                      <span className='model-upload-dragger3'></span>
                      {this.props.validateFileUploads(this.props.modelFileList) == "uploading"  ?
                      <span>
                        <p className="artist-upload-txt large">Upload in Progress... </p>
                      </span> :
                      this.props.validateFileUploads(this.props.modelFileList) == "done" ? (<span>
                        <div className="justify-in-center">
                          <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                          <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                        </div>
                      </span>):
                      this.props.validateFileUploads(this.props.modelFileList) == "error" ?
                      <span>
                        <div className="justify-in-center">
                          <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                          <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Error</p>
                          {(this.props.validateFileUploads(this.props.modelFileList) == 'error') && (<Button
                                onClick={() => Constants.triggerFailedFilesUpload('.model-upload-dragger3', this.props.modelFileList, this.props.changeModelFileList)}
                                disabled={this.props.modelFileList.length === 0}
                                style={{position: "unset",marginLeft: 8}}
                                loading={this.props.uploading}
                                className="retry-btn" ghost
                                >
                                {this.props.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                            </Button>)}
                        </div>
                      </span> :
                      this.state.disable_upload ?
                      <span>
                            <p className="artist-upload-txt">Test your GLB first and submit checklist to enable space upload</p>
                            <Button onClick={() => window.location.href = ('/artist_space_test/' + this.state.room_id)}
                            className="modal-okay square" style={{marginTop: 8}}>
                            <div className="modal-okay-text">Test your GLB</div>
                            </Button>
                        </span>
                      :
                      <span>
                        <p className="ant-upload-drag-icon">
                        <img src="/img/exclude.png"></img>
                        </p>
                        <p className="artist-upload-txt">Drop your product model’s <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                        <p className="artist-upload-txt small">
                          Only .zip files are supported
                        </p>
                        <Button
                        disabled={!this.props.reupload_flag || this.state.room_guidelines.model_retries >= this.props.retry_count}
                        className="modal-okay-orange square" style={{marginTop: 8}}>
                          <div className="modal-okay-text">Re-Upload Your File</div>
                        </Button>
                    </span> }
                </Dragger>
                </div>
                {this.props.upload_error == "" ? "" :
                <div style={{marginBottom: 8}}>
                  <span className="category-artist red">{this.props.upload_error}</span>
                </div>}
                {this.props.upload_warning == "" ? "" :
                <div style={{marginBottom: 8}}>
                  <span className="category-artist yellow">{this.props.upload_warning}</span>
                </div>}
                {this.props.upload_failed_message == "" ? "" :
                <div style={{marginBottom: 8}}>
                  <span className="category-artist red">{this.props.upload_failed_message}</span>
                </div>}
                </Col>: ''}
            </Row>: ''}
            {/* ((this.props.room_guidelines && this.props.room_guidelines.model_status == 8) && !this.props.validation_generated) ?
             <Row style={{marginTop: 16}}>
                <Col span={12} style={{paddingRight: "5%"}}>
                    <div style={{background: " #FEF6F6",borderRadius: "4px",padding:"16px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom: 16}}>
                        <span className="scene-note flag red">
                            The system was unable to generate validation summary for your model. Contact Admin.
                        </span>
                    </div>
                </Col>
            </Row> :  */}

            <Row style={{marginTop: 16,marginBottom:16}}>
                {this.state.rejections_data && this.state.rejections_data.length > 0 ?
                    <Col span={11} style={{marginRight: 35}}>
                        <div className='reject-heading' style={{display: 'flex'}}>Rejection History</div>
                        <div className="flagged-bg border custom">
                            {rejections_data}
                        </div>
                    </Col>
                    :''}

                {(this.state.room_guidelines.guidelines && this.state.room_guidelines.guidelines != "")?
                    <Col span={12}>
                        <div className='category-artist medium' style={{display: 'flex', fontSize: '20px', fontWeight: 600}}>
                        Initial Comments and Guidelines
                        </div>
                        <div className="note-bg-artist gray" style={{border: '#b1b1cd', borderStyle: 'dashed'}} >
                            <ul style={{paddingLeft: 10, paddingTop: 10, paddingBottom: 40}}>
                            {this.state.room_guidelines.guidelines.split('\n').map((item, i) => {
                                if (item !=  ""){
                                    return <li><div className="category-artist medium align-left" key={i}>{item}</div></li>;
                                }
                            })
                                }
                            </ul>
                        </div>
                    </Col>
                 : ''}
            </Row>

            <Row>
                <Col span={18}>
                    <Collapse defaultActiveKey={['1','2','3']} className="collapse-artist" ghost expandIconPosition={'right'}>
                    {floorplanPhotos.length > 0 || annotatedFloorplan != '' ||this.state.room_guidelines.tags != undefined && ((typeof (this.state.room_guidelines.tags) == "object" && Object.keys(this.state.room_guidelines.tags).length != 0)) || (this.state.room_guidelines.raw_files != undefined && ((typeof (this.state.room_guidelines.raw_files) == "object" && Object.keys(this.state.room_guidelines.raw_files).length != 0))) || (this.state.room_guidelines.reference_urls  && this.state.room_guidelines.reference_urls[0] && ((typeof (this.state.room_guidelines.reference_urls) == "object" && Object.keys(this.state.room_guidelines.reference_urls).length != 0))|| this.state.room_guidelines.guidelines) ?

                        <Panel header={<span className="note-text gray">Space Model Information</span>} key="1">
                            {this.state.room_guidelines.variant_of  ?
                                <Row style={{marginBottom:16,marginTop: 10}}>
                                <Col className="justify-in-start" span={24}>
                                    <div className="note-bg config">
                                        <div className="manrope f-16 black-55"><b>Note:</b>  Please use the provided space model linked below and make the requested changes.</div>
                                        <div className='manrope f-16 black-55 hide-fit-text'>
                                            <b>Base Model:</b>&nbsp;
                                            {this.state.base_platform_loader ? ('Fetching base model link...') : this.state.room_guidelines.immediate_parent_variant  ?
                                            <a className='manrope f-16 blue hide-fit-text' download href={ENVIRONMENT.getBaseURL(this.state.base_platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_guidelines.immediate_parent_variant  + '.zip'}>{ENVIRONMENT.getBaseURL(this.state.base_platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_guidelines.immediate_parent_variant  + '.zip'}</a>:
                                            <a className='manrope f-16 blue hide-fit-text'  download href={ENVIRONMENT.getBaseURL(this.state.base_platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_guidelines.variant_of + '.zip'}>{ENVIRONMENT.getBaseURL(this.state.base_platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_guidelines.variant_of + '.zip'}</a>}
                                        </div>
                                    </div>
                                </Col>
                                </Row>: ''}

                        <Row style={{marginBottom: 16}}>
                        {(this.state.room_guidelines.reference_urls  && this.state.room_guidelines.reference_urls[0] && ((typeof (this.state.room_guidelines.reference_urls) == "object" && Object.keys(this.state.room_guidelines.reference_urls).length != 0))) ?
                        <Col style={{display:"flex",alignItems:"baseline",marginRight: 30}}>
                            <div className="category-artist medium" style={{marginRight: 8}}>
                            Reference URL(s)
                            </div>
                            <div style={{display: "block"}}>
                            {roomReferences}
                            </div>
                        </Col> : ""}
                        {(this.state.room_guidelines.raw_files != undefined && ((typeof (this.state.room_guidelines.raw_files) == "object" && Object.keys(this.state.room_guidelines.raw_files).length != 0))) ?
                            <Col style={{display:"flex",alignItems:"baseline"}}>
                            <div className="category-artist medium" style={{marginRight: 8}}>
                                Raw File(s)
                            </div>
                            <div style={{display: "block"}}>
                                {raw_files}
                            </div>
                            </Col>: ""}
                        </Row>

                        <Row style={{marginBottom: 16}}>
                        {this.state.room_guidelines.tags != undefined && ((typeof (this.state.room_guidelines.tags) == "object" && Object.keys(this.state.room_guidelines.tags).length != 0)) ?
                            <Col style={{display:"flex",alignItems:"baseline"}}>
                            <div className="category-artist medium" style={{marginRight: 8}}>
                                Tag(s)
                            </div>

                            {tag_names}

                            </Col> : ""}
                        </Row>
                        {floorplanPhotos.length > 0 ? <Row style={{marginBottom: 16,display:'block'}}>
                            <Row>
                                {floorplanPhotos}
                            </Row>
                        </Row>: ''}
                        {annotatedFloorplan != '' ?
                        <Row style={{marginBottom: 16}}>
                            <Row>
                            {annotatedFloorplan}
                            </Row>
                        </Row>: ''}
                    </Panel>: ''}
                    {this.state.room_guidelines && this.state.room_guidelines.elements.length > 0 ?
                   <Panel header={<span className="note-text gray">Space Elements</span>} key="2">
                        {roomElements}
                    </Panel> : ''}
                    {(roomPhotos.length > 0 || outsideViewData != '' || (this.state.room_guidelines && this.state.room_guidelines.video && this.state.room_guidelines.video.length != 0 )) ?
                    <Panel header={<span className="note-text gray">Reference Images/ Space Model Videos</span>} key="3">
                        <Row>
                            <Col>
                            {(roomPhotos.length > 0) ? <div className="note-text gray" style={{marginBottom:16,marginTop: 16}}>Reference Images</div>: ''}
                                <Row style={{display:"flex"}}>
                                    {roomPhotos}
                                </Row>

                                {outsideViewData == '' ? '' :
                                <span>
                                    <div className="note-text gray" style={{marginBottom:16,marginTop: 16}}>Outside View</div>
                                    <Row style={{display:"flex"}}>
                                        {outsideViewData}
                                    </Row>
                                </span>}

                                {
                                    uploadedOutsideViewData == '' ? '' :
                                    <span>
                                    <div className="note-text gray" style={{marginBottom:16,marginTop: 16}}>Uploaded Outside View</div>
                                    <Row style={{display:"flex"}}>
                                        {uploadedOutsideViewData}
                                    </Row>
                                </span>
                                }
                                {this.state.room_guidelines && this.state.room_guidelines.video && this.state.room_guidelines.video.length != 0 ?
                                <span>
                                    <div className="note-text gray" style={{marginBottom:16,marginTop: 16}}>Space Videos</div>
                                    <Row style={{display:"flex"}}>
                                    {this.state.room_guidelines.video.map((item,index) => (
                                        <ReactPlayer width="100%" height='230px' url={ENVIRONMENT.getBaseURL(this.state.room_guidelines.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(item.originFileObj.uid + '/' + item.name)} controls={true} loop={true}/>
                                    ))
                                    }
                                    </Row>
                                </span>: ''}
                            </Col>
                        </Row>
                    </Panel>: ''}

                </Collapse>
            </Col>
        </Row>
    </div>
        );
    }
}

export default RoomStaticGuidelines;