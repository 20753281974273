import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card, Menu, Dropdown } from 'antd';
import { ArrowRightOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import ProductPageConstants from './ProductPageConstants.js';
import FileConstants from '../../../../FileConstants';


const BasicDetail = () => {
    const { productData, companyName } = useContext(CustomerProductContext);

    return (
        <Row className="basic-detail-container gap-32">
            {productData['company_id'] && 
            <Col span={6} className='div'>
                <img src='/img/shop.png' className="icon-instance-node" />
                <div className='div-2'>
                    <div className="manrope f-14 lh-14 grey-8c">Brand</div>
                    <Tooltip title={<span className='manrope f-12 white'>{companyName}</span>}>
                        <div className="manrope f-16 lh-16 black-26 clamp-w-width">
                            {companyName}
                        </div>
                    </Tooltip>
                </div>
            </Col>}
            <Col span={6} className='div'>
                <img src='/img/Tag.png' className="icon-instance-node" />
                <div className='div-2'>
                    <div className="manrope f-14 lh-14 grey-8c" >Category</div>
                    <Tooltip title={<span className='manrope f-12 white'>
                        {productData.parent_category && FileConstants.HIDDEN_CATEGORY[productData.parent_category] ?
                            productData.parent_category
                            : productData.category}
                    </span>}>
                        <div className="manrope f-16 lh-16 black-26 clamp-w-width">
                            {productData.parent_category && FileConstants.HIDDEN_CATEGORY[productData.parent_category] ?
                                productData.parent_category
                                : productData.category}
                        </div>
                    </Tooltip>
                </div>
            </Col>
            {productData['brand_id'] && 
            <Col span={6} className='div'>
                <img src='/img/Barcode.svg' className="icon-instance-node" />
                <div className='div-2'>
                    <div className="manrope f-14 lh-14 grey-8c">Item ID</div>
                    <Tooltip title={<span className='manrope f-12 white'>{productData['brand_id']}</span>}>
                        <div className="manrope f-16 lh-16 black-26 clamp-w-width">
                            {productData['brand_id']}
                        </div>
                    </Tooltip>
                </div>
            </Col>}
            {productData['gtin'] && <Col span={6} className='div'>
                <img src='/img/Global.svg' className="icon-instance-node" />
                <div className='div-2'>
                    <div className="manrope f-14 lh-14 grey-8c">GTIN</div>
                    <Tooltip title={<span className='manrope f-12 white'>{productData['gtin']}</span>}>
                        <div className="manrope f-16 lh-16 black-26 clamp-w-width">
                            {productData['gtin']}
                        </div>
                    </Tooltip>
                </div>
            </Col>}
        </Row>
    );
};

export default BasicDetail;