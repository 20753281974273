import React from 'react';
import PropTypes from 'prop-types';
import { LockOutlined, SyncOutlined, CodeOutlined,LoadingOutlined } from '@ant-design/icons';
import { Input, Button, Checkbox, Row, Col, Form } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class EmailVerificationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
      verificationCode: '',
    };
  }

  onSubmit = (event) => {
    // event.preventDefault();
    this.props.onSubmit(this.state.verificationCode);
  }

  changeVerificationCode = (event) => {
    this.setState({ verificationCode: event.target.value });
  }

  render = () => {
    const formDiv = {
      'display': 'flex',
      'justifyContent': 'space-around',
      'margin-top': '40px',
    }

    const formStyle = {
      'background-color': 'white',
      'borderRadius': '5px'

    }

    const logoStyle = {
      'display': 'flex',
      'justifyContent': 'space-around',
      'marginBottom': '30px'
    }
    return <Row>
        <Col span={12}>
          <img className="branding-image" src={require("../../../../assets/images/login-branding.jpg")} />
        </Col>
        <Col span={12}>
          <div className="input-form custom-frm" style={formDiv}>
            <div style={formStyle}>
              <div style={formDiv}>
                <div style={formStyle}>
                {/* <form onSubmit={this.onSubmit}>
                  <div>{this.props.error}</div>
                  <label>
                    Verification Code
                    <input placeholder="code" onChange={this.changeVerificationCode} required />
                  </label>
                  <button type="submit">Submit</button>
                  <button type="button" onClick={this.props.onCancel}>Cancel</button>
                </form> */}
                <Form onFinish={this.onSubmit} onFinishFailed={(e) => console.log(e) } className="login-form" style={{ width: "300px" }}>
                    <h3 className="text-center text-login-logo"> <span>All</span><span className="text-red">3D</span> </h3>
                    <p>You must verify your email address. Please check your email for code</p>
                    <Form.Item
                      name="code"
                      rules= {[{ required: true, message: 'Input verification code!' },
                            {pattern: '^[0-9]{1,6}$', message: 'Verification Code must consist of 6 digits'},
                            {pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: " Spaces are not allowed, whitespace found at the end."},
                      ]}
                    >
                      <Input
                              onChange={this.changeVerificationCode}
                              prefix={<CodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                              placeholder="Verification Code"
                          />
                    </Form.Item>
                    <Form.Item >

                      <Button type="danger" htmlType="submit" className="login-form-button">
                        Submit {this.state.isLoading ? <LoadingOutlined spin /> : ''}
                      </Button>
                    </Form.Item>
                    <div className="text-danger text-center">{this.props.error}</div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    
    }
 
}
EmailVerificationForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

export default EmailVerificationForm;
