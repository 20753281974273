import React from 'react';
import axios from 'axios';
import { ExclamationCircleOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Card,Tooltip,message, Row} from 'antd';
import ENVIRONMENT from '../../../../environments';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';

const image_style = { width: '100%',height: "calc(100vh -  282px)",objectFit:"contain" };
const fullscreen_image_style = { width: "100vw",height: "100vh",objectFit:"contain" };
class QuickRenders extends React.Component {

    state = {
        product_id: 0,
        product_guidelines: {
            quick_renders: [],
        },
        image: [],
        quick_image: [],
        dummy_quick_image: '',
        set_quick_image: '',
        is_fullscreen: false
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                is_fullscreen: false
            });
        }
    }
    
    componentDidMount() {
        var product_id = 0;
        if (this.props.match !== undefined) {
            product_id = this.props.match.params.id;
        }
        else {
            product_id = this.props.product_id;
            
        }

        console.log("get product id");

        this.setState({ product_id: product_id });

        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                this.setState({ product_guidelines: res.data },() => {
                    if (this.state.product_guidelines.quick_renders != undefined) {
                        this.state.product_guidelines.quick_renders.map((ele, index) => {
                            let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.QUICK_RENDERS_URI + ele;
                            this.state.quick_image[index] = file_url;
                            if (index == 0) {
                                this.setState({
                                    set_quick_image: file_url
                                });
                            }
                        });
                    }
                    this.scrollCheck();
                });
                console.log("Product Guidelines Data: ", res.data);
            });

        document.addEventListener("keydown", this.escFunction, false);
        window.addEventListener("resize", this.scrollCheck);
    }

    componentDidUpdate(prevProps) {
        if (prevProps!= this.props) {
          this.scrollCheck();
        }
      }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        window.removeEventListener("resize", this.scrollCheck);
    }

    leftScroll = () => {
        var elem = document.getElementById('review-quick-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft -= 273;
            var right = document.getElementById('right-scr-button-quick');
            var left = document.getElementById('left-scr-button-quick');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            
            if (right!=null) {
                right.style.visibility = 'visible';
                elem.style.left = "20px";
                // forceUpdate();
            }
        }
    }

    rightScroll = () => {
        var elem = document.getElementById('review-quick-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft += 273;
            var right = document.getElementById('right-scr-button-quick');
            var left = document.getElementById('left-scr-button-quick');
            elem.style.left = "94px";
            if (right != null) {
                if (left != null) {
                    left.style.display = "inline-block";
                }
                
                var newScrollLeft = elem.scrollLeft;
                var divWidth = elem.offsetWidth;
                var scrollwidth = elem.scrollWidth;
                if(newScrollLeft === scrollwidth - divWidth){
                    right.style.visibility = 'hidden';
                }
            }
            
        }
    }

    scrollCheck = () => 
    {
        var elem = document.getElementById('review-quick-scrollable'); 
        var carousel_elem = document.getElementById('quick-carousel'); 

        if (elem != null) {
            elem.style.scrollBehavior = "unset";

            var y1 = elem.scrollTop;  
            elem.scrollTop += 1; 
            var y2 = elem.scrollTop ; 
            elem.scrollTop -= 1; 
            var y3 = elem.scrollTop; 
            elem.scrollTop = y1; 
        
            var x1 = elem.scrollLeft;  
            elem.scrollLeft += 1; 
            var x2 = elem.scrollLeft; 
            elem.scrollLeft -= 1; 
            var x3 = elem.scrollLeft; 
            elem.scrollLeft = x1; 
            var right = document.getElementById('right-scr-button-quick');
            var left = document.getElementById('left-scr-button-quick');
            let nextValue = elem.scrollLeft - 273;

            if (elem.style.left == "94px") {
                elem.style.left = "94px";
            }
            else {
                if ( elem.scrollLeft == 0) {
                    elem.style.left = "20px";
                }
            }
            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
                if (left != null) {
                    if (elem.style.left == "20px" &&  elem.scrollLeft == 0) {
                        left.style.display = "none"; 
                    }
                }
                if (elem.style.left == "20px"  &&  nextValue <= 0) {// for right scroll
                    if (window.innerWidth <= 1500 && this.props.compareView) {
                        // elem.style.width = "40%";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && this.props.compareView) {
                        // elem.style.width = "300px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && this.props.compareView) {
                        // elem.style.width = "20%";
                        elem.style.width = "100%";
                    }
                    if (this.props.compareView) {
                        carousel_elem.style.width = "80%";
                    }
                }
                else { // for left scroll
                    if (window.innerWidth <= 1500 && this.props.compareView) {
                        // elem.style.width = "40%";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && this.props.compareView) {
                        // elem.style.width = "300px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && this.props.compareView) {
                        // elem.style.width = "20%";
                        elem.style.width = "100%";
                    }
                    if (this.props.compareView) {
                        carousel_elem.style.width = "80%";
                    }
                }
            }

            if (!this.props.compareView && this.props.adminScrollCheck) {
                elem.style.width = "100%";
                elem.style.left = "20px";
            }

            if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                left.style.display = "none";
            }
        }
    }
    

    render() {
        
        let quick_files_array = (this.state.product_guidelines.quick_renders || []);

        let quick_renders = '';
        if (quick_files_array != undefined && quick_files_array.length > 0) {
            quick_renders = quick_files_array.map((ele, index) => {
                let file_url = ENVIRONMENT.getBaseURL(this.state.product_guidelines.platform) + ENVIRONMENT.QUICK_RENDERS_URI  + ele;
                return <Col>
                <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{marginRight:"10px",width:"64px",height:"64px",padding:"10px"}}>
                    <a onClick={() => displayQuickImage(index)}>
                        <img alt="References" style={{ cursor: "pointer", height: "40px",width:"40px", objectFit: "contain", display: "block"}} src={file_url} />
                    </a>
                </Card>
                </Col>
            });
        }

        const setFullScreen  = () => {
            if(!this.state.is_fullscreen){
                message.info("Press ESC to exit full screen");
            }
            this.setState({
                is_fullscreen: !this.state.is_fullscreen
            });
        }
 

        const displayQuickImage = (index) => {
            this.setState({
                set_quick_image: this.state.quick_image[index],
            });
        }

        


        return (
            <Card className={this.state.is_fullscreen ? "product-full-screen-card" : "product-viewer-normal"} bodyStyle={{padding: this.state.is_fullscreen ? 0 : 12}}>
                <div className="bg-model" style={{zIndex:"1",position:"absolute",top:"20px",left:"20px"}}>
                    <div className="bg-model-text">
                        Rendered Model
                    </div>
                </div>
                {quick_renders != '' && quick_renders != undefined ? 
                <div>
                    <Tooltip title={(!this.state.is_fullscreen ? "Full Screen" : "Exit Full Screen")} placement="left">
                        <img src={this.state.is_fullscreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}  alt="Fullscreen Icon" 
                        style={{position:"absolute", bottom:"20px",zIndex:"10",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                        className="product-full-screen-icon" onClick={() => setFullScreen() } />
                    </Tooltip> 
                    <div style={{ display: 'flex', justifyContent: "space-around" }}>
                        <Col>
                            <Card bordered={false}>
                                <ImageZoomAdmin custom_class={this.state.is_fullscreen ? fullscreen_image_style : image_style}   compare={true} image={this.state.dummy_quick_image || this.state.set_quick_image}/>
                            </Card>
                        </Col>
                    </div>
                    <div className='pdp-carousel-scroll'>
                        <div className="icon-con-left" id="left-scr-button-quick" onClick={this.leftScroll}>
                            <RightOutlined  className="left-button-scr" />
                        </div>
                        <div id="review-quick-scrollable" className="pdp-scrollable">
                            {quick_renders}
                        </div>
                        <div className="icon-con" id="right-scr-button-quick" onClick={this.rightScroll}>
                            <RightOutlined  className="right-button-scr" />
                        </div>
                    </div>
                </div>
                : 
                (quick_renders == undefined && quick_renders == '')? 
                <div className='quick-render-error'>
                    <ExclamationCircleOutlined className='error-icon'/>
                    <p className="product-h2 red" style={{ textAlign:'center' }}>We were unable to generate quick renders for your model because there was some issue with the model you uploaded.</p>
                </div> :
                <div className='quick-render-error'>
                    <LoadingOutlined className='error-icon black'/>
                    <p className="product-h2 black" style={{ textAlign:'center' }}>Please wait while we complete process quick renders...</p>
                </div> 
                
                }
            </Card>
            );
    }
}

export default QuickRenders;
