import * as THREE from "three";

var NavigationControl = function ( camera, navPlanes, spaceWalls, sceneItems, miscItems, domElement ) {
	if ( domElement === undefined ) {
        domElement = document;
    }

    THREE.Object3D.call( this );

    this.visible = true;
    this.domElement = domElement;
    this.hide = false;  // To allow hiding the nav control from external scope
    var scope = this;

    // debugger;
    defineProperty( "camera", camera );
    defineProperty( "navPlane", navPlanes );
    defineProperty( "spaceWalls", spaceWalls );
    defineProperty( "sceneItems", sceneItems );
    defineProperty( "miscItems", miscItems);
    defineProperty( "enabled", true );

    var changeEvent = { type: "change" };

    var ray = new THREE.Raycaster();

    var _circle = constructCircle();
    this.add( _circle );

    var targetWorldPos = new THREE.Vector3();
    var targetOpacity = 1.0;
    var planeIntersected = false;

    function constructCircle () {
    	let dummyObject = new THREE.Object3D();

    	let circleGeom = new THREE.TorusBufferGeometry( 0.2, 0.025, 3, 60 );
    	let circleMat = new THREE.MeshBasicMaterial( {
    		color : 0x00aeff,
	        depthTest: false,
	        depthWrite: false,
	        transparent: true,
	        side: THREE.DoubleSide,
	        fog: false
	    } );

	    dummyObject.position.set(0, 0.001, 0);
	    dummyObject.rotation.set(Math.PI / 2, 0, 0);
	    dummyObject.updateMatrix();

	    circleGeom.applyMatrix4( dummyObject.matrix );

	    let circleObj = new THREE.Mesh( circleGeom, circleMat );
	    circleObj.renderOrder = Infinity;
        circleObj.name = "navCircle";
        return circleObj;
    }

    function defineProperty( propName, defaultValue ) {

        var propValue = defaultValue;

        Object.defineProperty( scope, propName, {

            get: function () {

                return propValue !== undefined ? propValue : defaultValue;

            },

            set: function ( value ) {

                if ( propValue !== value ) {

                    propValue = value;

                    scope.dispatchEvent( { type: propName + "-changed", value: value } );
                    scope.dispatchEvent( changeEvent );

                }

            }

        } );

        scope[ propName ] = defaultValue;
    }

    {
        domElement.addEventListener( "mousemove", onPointerMove, false );
    }

    this.dispose = function () {
        domElement.removeEventListener( "mousemove", onPointerMove );
        document.removeEventListener( "mousemove", onPointerMove );

        this.traverse( function ( child ) {

            if ( child.geometry ) child.geometry.dispose();
            if ( child.material ) child.material.dispose();

        } );
    };

    // updateMatrixWorld  updates key transformation variables
    this.updateMatrixWorld = function () {

        if(this.hide){
            targetOpacity = 0;
        }
        else {
            if(planeIntersected) targetOpacity = 1;
        }

        if( this.enabled ) {
            this.visible = true;
            _circle.position.lerp( targetWorldPos, 0.9 );

            let currentOpacity = _circle.material.opacity;
            _circle.material.opacity = THREE.MathUtils.lerp( currentOpacity, targetOpacity, 0.75 );
        }
        else {
            this.visible = false;
        }

        THREE.Object3D.prototype.updateMatrixWorld.call( this );
    };

    var X = new THREE.Vector3(1,0,0).normalize();
    var aX = new THREE.Vector3(-1,0,0).normalize();
    var Z = new THREE.Vector3(0,0,1).normalize();
    var aZ = new THREE.Vector3(0,0,-1).normalize();
    var XZ = new THREE.Vector3(1,0,1).normalize();
    var aZaX = new THREE.Vector3(-1,0,-1).normalize();
    var aXZ = new THREE.Vector3(-1,0,1).normalize();
    var aZX = new THREE.Vector3(1,0,-1).normalize();
    var directions = [];
    directions.push(X, aX, Z, aZ, XZ, aZaX, aXZ, aZX);
    var rayOrigin = new THREE.Vector3();
    this.pointerMove = function ( pointer ) {

        ray.far = Infinity;
        ray.setFromCamera( pointer, this.camera );

        let intersectableObjects = [];
        intersectableObjects = intersectableObjects.concat(navPlanes, spaceWalls, miscItems);
        var planeIntersect = ray.intersectObjects( intersectableObjects, true )[ 0 ] || false;

        if ( planeIntersect !== false && planeIntersect.object.name.toLowerCase().includes("floor")) {
            planeIntersected = true;
        }
        else {
            this.hide = true;
            planeIntersected = false;
        	return;
        }

        rayOrigin.copy(planeIntersect.point);
        rayOrigin.y += 0.15;
        let foundIntersect = false;
        intersectableObjects = [];
        intersectableObjects = intersectableObjects.concat(spaceWalls, sceneItems, miscItems);
        for (let index = 0; index < directions.length && !foundIntersect; index++) {
            const direction = directions[index];
            ray.set(rayOrigin, direction);
            ray.far = 0.5;  // Distance threshold

            var intersect =  ray.intersectObjects( intersectableObjects, true )[ 0 ] || false;
            if(intersect) {
                foundIntersect = true;          
            }
        }

        if (!foundIntersect) {
            targetWorldPos.copy( planeIntersect.point);
            targetWorldPos.y += 0.001;
        }
    };

    this.setOpacity = function (opacity) {
        _circle.material.opacity = opacity;
    }

    this.getCurrentTargetPosition = function () {
        return targetWorldPos;
    }
    
    // normalize mouse / touch pointer and remap {x,y} to view space.
    function getPointer( event ) {

        if ( document.pointerLockElement ) {

            return {
                x: 0,
                y: 0,
                button: event.button
            };

        } else {

            var pointer = event.changedTouches ? event.changedTouches[ 0 ] : event;

            var rect = domElement.getBoundingClientRect();

            return {
                x: ( pointer.clientX - rect.left ) / rect.width * 2 - 1,
                y: - ( pointer.clientY - rect.top ) / rect.height * 2 + 1,
                button: event.button
            };

        }

    }

    function onPointerMove( event ) {

        if ( !scope.enabled || scope.hide ) return;

        scope.pointerMove( getPointer( event ) );

    }
}

NavigationControl.prototype = Object.assign( Object.create( THREE.Object3D.prototype ), {
    constructor: NavigationControl,
} );

export { NavigationControl };
