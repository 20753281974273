import React, {useMemo} from "react";
import { Pie } from "@ant-design/charts";

const EntityDisplayName = {
    product : "Product",
    space : "Space",
    scene : "Scene",
    project : "Active Project",
    material : "Material"
};

const EntityColor = {
    product : '#597EF7',
    space : '#FFC53D',
    material : '#B37FEB',
    silo: '#40A9FF',
    lifestyle: '#BAE637',
    threesixty: '#FFEC3D',
    mp4video: '#FF7A45'
};

const RingPieChart = (props) => {
    let data = props.data;
    const formatterInfo = {
        product: "Products",
        space: "Spaces ", 
        material: "Materials ",
    };
    const config = useMemo(() => ({
        padding: 'auto',
        data,
        angleField: 'count',
        colorField: 'type',
        radius: 0.8,
        innerRadius: 0.7,
        label: false,
        tooltip: {
            fields: ['type', 'count'],
            formatter: (datum) => ({
              name: `${formatterInfo[datum.type]}`,
              value: `${datum.count}`,
            }),
          },
        legend: {
            itemName: {
                alignRight: false,
                formatter: (text, item) => {
                    let currentItem = (data.find(x => x.type === text)).count;
                    return `${currentItem} ${EntityDisplayName[text]}s`;
                },
            },
            itemHeight: 20
        },
        interactions: [
            { type: 'element-selected' },
            { type: 'element-active' },
        ],
        statistic: {
            title: {
                customHtml: (container, view, dataum, data) => {
                    return `<div>${props.getCumulativeCount(data)}\n</div>`
                },
                style: {
                    color: '#000000',
                    fontSize: 30,
                    fontFamily: 'Manrope',
                    fontWeight: 400
                }
            },
            content: {
                customHtml: (container, view, dataum, data) => {
                    return `<div>\n${props.content}</div>`
                },
                style: {
                    color: '#000000',
                    fontSize: 16,
                    fontFamily: 'Manrope',
                    fontWeight: 300,
                    lineHeight: 2
                },
            },
            visible: true,
        },
        colorField:'type',
        color:({ type }) => {
            return EntityColor[type]
        }
    }), [data]);
    return (<div className="pie-chart-container">
        <Pie {...config} />
    </div>);
};

//This will always return true so piechart will not be rendered again and again
const areEqual = (prevProps, nextProps) => true;

export default React.memo(RingPieChart, areEqual);