import React, { Fragment } from 'react';
import { Table, Badge, TreeSelect, Button, Row, Menu, Dropdown, Checkbox, Tooltip, Input, Space } from 'antd';
import { LoadingOutlined, EditOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
import * as Utilities from "../../Utilities";
import * as Styles from "../../../../Styles";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import EditSkillMatrixModal from '../EditSkillMatrixModal/EditSkillMatrixModal';

const categoryStyle = Styles.categoryStyle;

const proficiency_filters_value =  [
  {
    text: 'Good',
    value: 'Good',
  },
  {
    text: 'Average',
    value: 'Average',
  },
  {
    text: 'Bad',
    value: 'Bad'
  }
]

const skill_badge_color = {
  Bad: "#E54547",
  Average: "#E19B12",
  Good: "#29B862",
}

class SkillMatrix extends React.Component {
  state = {
    table_data: [],
    filteredInfo: [],
    edit_modal_visible: false,
    selected_artist: null,
    all_categories: [],
    skills: [],
    skill_matrix_loading: true,
    loading_data: false,
    skill_matrix_columns: [
      {
        title: "Artist Username",
        dataIndex: "artist_username",
        key: "artist_username",
        visible: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={'Search Artist Username'}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, 'artist_username')}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, 'artist_username')}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record['artist_username']
            ? record['artist_username'].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: (text, record) => <span>{<a style={{marginRight: 10}} className="onhover-underline" href={'../admin_artist_details/' + record.profile_id}>{text}</a>}</span>,
      },
      {
        dataIndex: "expertise_level",
        title: <span>Expertise Level <Tooltip className='skill-matrix-tooltip' title={<span>Bad: Artist cannot pick any models. <br/>Average: Artist can pick models of non-enterprise customers. <br/>Good: Artist can pick models of enterprise customers.</span>}><InfoCircleOutlined/></Tooltip></span>,
        visible: true,
        toggle_visibility_allowed: false,
        filters: proficiency_filters_value,
        onFilter: (value, record) => record.expertise_level === value,
        render: (text) => this.getColoredBadgeText(text),
      },
    ],
  };

  displayEditModal = (visible) => {
    if(!visible){
      this.setState({
        selected_artist: {}
      })
    }
    this.setState({
      edit_modal_visible: visible
    })
  }

  handleChange = (pagination, filters, soter) => {

    let skill_matrix_columns = this.state.skill_matrix_columns
    for(let col of skill_matrix_columns){
      col['filteredValue'] = filters[col['dataIndex']] || [];
    }
    this.setState({skill_matrix_columns: skill_matrix_columns})
  }

  resetFilters = () => {
    let skill_matrix_columns = this.state.skill_matrix_columns
    for(let col of skill_matrix_columns){
      if(col['filters'] || col['filteredValue']){
        col['filteredValue'] = []
      }
    }
    this.setState({skill_matrix_columns: skill_matrix_columns})
  }

  handleUpdateData = (updated_data) => {
    let table_data = this.state.table_data;
    table_data.map(ele => {
      if(ele['profile_id'] == updated_data['artist_id']){
        ele['expertise_level'] = FileConstants.ARTIST_EXPERTISE_LEVEL[updated_data['expertise_level']];
        Object.assign(ele, updated_data['skills'])
        ele['categories_list'] = updated_data['proficient_categories']
      }
    })
    this.setState({table_data: table_data})
  }


  componentDidUpdate = (prevProps) => {
    if (!this.props.is_loading && prevProps != this.props) {
      this.setState(
        {
          data_fetched: !this.props.is_loading,
        },
        () => this.setTableData(this.props.artists_data)
      );
    }
  };

  componentDidMount() {
    this.getAllCategories()
    this.getSkillList()
    
    this.setState(
      {
        data_fetched: !this.props.is_loading,
      },
      () => this.setTableData(this.props.artists_data)
    );
  }

  getAllCategories = () => {
    const payload = {
      output: 'serial'
    };
    axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
      this.setState({all_categories: res.data})
    })
  }

  getSkillList = () => {
    axios.post(ENVIRONMENT.SKILL_GET_BATCH, {      
    })
    .then(res => {
      if(res['data']){
        this.setState({skills: res['data']}, ()=> this.addSkillColumns())
      }
    })
  }

  getSelectedArtistProfile = (record) => {
    this.setState({
      loading_data: true
    })
    let selected_artist_details = {};
    axios.post(ENVIRONMENT.GET_ARTIST_PROFILE, {'artist_id': record['profile_id']})
    .then(res => {
        selected_artist_details['profile_id'] = record['profile_id']
        selected_artist_details['artist_username'] = res.data['artist_username']
        selected_artist_details['expertise_level'] = record['expertise_level']
        selected_artist_details['skills'] = res.data['skills']
        selected_artist_details['proficient_categories'] = res.data['proficient_categories']
        this.setState({selected_artist: selected_artist_details, loading_data: false})
    })

  }

  addSkillColumns() {
    let skillColumns = []
    let i = 0;
    let visibleColumn = false
    for(let skill of this.state.skills){
      visibleColumn = i < 5 ? true : false
      i++;
      skillColumns.push(
        {
            dataIndex: skill['name'],
            title: <span>{skill['display_name']} <Tooltip className='skill-matrix-tooltip' title={<span>Good: Artist can pick models of this category from the job desk.<br/> Average and Bad: Artist cannot pick models of this category. Admin can assign manually if needed. </span>}><InfoCircleOutlined/></Tooltip></span>,
            visible: visibleColumn,
            toggle_visibility_allowed: true,
            filters: proficiency_filters_value,
            onFilter: (value, record) => record[skill['name']] === value,
            render: (text) => !text ? 'N/A' : this.getColoredBadgeText(text),
          }
      )
    }
    // add the edit action column at the end.
    skillColumns.push({
      title: "",
      visible: true,
      toggle_visibility_allowed: false,
      render: (text, record) => (
        <EditOutlined
          onClick={()=>{this.displayEditModal(true); this.getSelectedArtistProfile(record)}}
          style={{
            cursor: "pointer",
            background: "#0000000A 4%",
            padding: 7,
            borderRadius: 4,
          }}
        />
      ),
    })

    this.setState({skill_matrix_columns: [...this.state.skill_matrix_columns, ...skillColumns], skill_matrix_loading: false})
  }

  getVisibleColumns() {
    return this.state.skill_matrix_columns.filter((item) => item.visible);
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColoredBadgeText(skill_set_level) {
    console.log('SL = ', skill_set_level)
    if (Object.keys(skill_badge_color).includes(skill_set_level)) {
      return (
        <Badge
          color={skill_badge_color[skill_set_level]}
          text={skill_set_level}
        />
      );
    } else {
      return skill_set_level;
    }
  }

  handleColumnVisibility(column, visibility) {
    for(let col of this.state.skill_matrix_columns){
        if(col['dataIndex']==column){
            col['visible'] = visibility
            this.setState({skill_matrix_columns: this.state.skill_matrix_columns})
        }
    }
  }

  getMenuForShowColumnButton = () => {
    let items = [];
    let menu = (
      <Menu>
        {this.state.skill_matrix_columns.map((ele, index) => {
          if (ele.toggle_visibility_allowed) {
            return (
                <Menu.Item >
                    <span onClick={e => e.stopPropagation()}><Checkbox id={ele['dataIndex']} checked={ele['visible']} onChange={(e)=>this.handleColumnVisibility(ele['dataIndex'], e.target.checked)}>{ele['title']}</Checkbox></span>
                </Menu.Item>
            )
          }
        })}
      </Menu>
    );
    
    return menu;
  };

  setTableData = (artists_data) => {
    let table_data = [];
    for (let val of artists_data) {
      if (val.is_active == true) {
        table_data.push({
          expertise_level:
            FileConstants.ARTIST_EXPERTISE_LEVEL[val["expertise_level"]] ||
            "N/A",
          ...val['skills'],
          categories_list: val["proficient_categories"],
          profile_id: val['id'],
          artist_username: val['artist_profile']
        });
      }
    }
    table_data.sort((a,b) => a.artist_username.localeCompare(b.artist_username))
    this.setState({
      table_data: table_data,
    });
  };

  render() {
    return (
      <Fragment>
        <Row style={{ float: "right" }}>
          <Button onClick={()=>this.resetFilters()} style={{marginBottom: 10, marginRight: 10, borderRadius: 4}}>Reset Filters</Button>
        </Row>
        <Row style={{ float: "right" }}>
          <Dropdown
            overlay={this.getMenuForShowColumnButton}
            trigger={["click"]}
          >
            <Button style={{marginBottom: 10, marginRight: 10, borderRadius: 4}}>Show Columns</Button>
          </Dropdown>
        </Row>
        <Table
          onChange={this.handleChange}
          columns={this.getVisibleColumns()}
          dataSource={this.state.table_data}
          pagination={true}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: this.props.is_loading || this.state.skill_matrix_loading,
          }}
        ></Table>
        {this.state.selected_artist ?
          <EditSkillMatrixModal 
          loading_data={this.state.loading_data}
          profile_id={this.state.selected_artist['profile_id']}
          inputInviteDetails={false}
          artist_username={this.state.selected_artist['artist_username']}
          visible={this.state.edit_modal_visible}
          expertise_level={this.state.selected_artist['expertise_level']}
          artist_skills={this.state.selected_artist['skills']}
          skill_list={this.state.skills}
          proficient_categories={this.state.selected_artist['proficient_categories']}
          all_categories={this.state.all_categories}
          set_visibility={this.displayEditModal}
          update_parent_state={this.handleUpdateData}
          />
        : ''}
      </Fragment>
    );
  }
}

export default SkillMatrix