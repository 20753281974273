import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import AWS from "aws-sdk";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CustomHeader from "../../CustomHeader";
import * as Utilities from '../../Utilities';
import {
  Layout,
  Upload,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Form,
  Statistic,
} from "antd";
import "./ArtistAssignment.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import * as Constants from "../../CustomerComponents/Constants";
import * as Styles from "../../../../Styles";
import PreprocessingImages from "../../ArtistComponents/PreprocessingImages/PreprocessingImages";
import DottedLoader from "../../DottedLoader";
import * as JSZip from "jszip";
import ModelDetails from "../TestModelDetails/ModelDetails";

const progress_bar = Styles.progress_bar;
const { Header, Content, Footer } = Layout;
const { Dragger } = Upload;
const { Countdown } = Statistic;

let folderStructure = {
  'vray_exist' : false,
  'high_exist' : false,
  'low_exist' : false,
  'pbr_exist' : false,
  'max_high_id' : false,
  'max_low_id' : false,
  'id' : 0
}

function ArtistAssignmentContainer() {
  const [assignmentFilesList, setAssignmentFilesList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [requirements, setRequirements] = useState(null);
  const [outputRequired, setOutputRequired] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [depth, setDepth] = useState(null);
  const [color, setColor] = useState(null);
  const [similarColor, setSimilarColor] = useState(null);
  const [lightingRig, setLightingRig] = useState(null);
  const [referenceURLs, setReferenceURLs] = useState([]);
  const [referenceImages, setReferenceImages] = useState([]);
  const [colorExtractedImages, setcolorExtractedImages] = useState(null);
  const [productID, setproductID] = useState(null);
  const [testID, setTestID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [testTimeRemaining, setTestTimeRemaining] = useState(null);
  const [placementType, setPlacementType] = useState(null);
  const [isTimeFinished, setIsTimeFinished] = useState(false);
  const [isCorrectFileStructure, setIsCorrectFileStructure] = useState(false);
  const [platform, setPlatform] = useState('aws');

  const onFinish = () => {
    console.log("Time finished!");
    setIsTimeFinished(true);
  };

  useEffect(() => {
    const payload = {
      username: localStorage.getItem("username"),
    };
    setIsLoading(true);
    axios.post(ENVIRONMENT.GET_ARTIST_TEST, payload).then((res) => {
      console.log(res.data);
      const timeStarted = new Date(res.data.time_started);
      let deadline = new Date(res.data.time_started);
      let timeNow = new Date();
      deadline.setDate(timeStarted.getDate() + 2);
      let offset = timeNow.getTimezoneOffset();
      deadline.setMinutes(deadline.getMinutes() - offset);
      deadline = deadline - timeNow;
      if (
        res.data.status === "artist_completed" ||
        res.data.status === "artist_submitted" ||
        res.data.status === "successful" ||
        res.data.status === "failed" ||
        res.data.status === "successfully_scored" ||
        (res.data.status === "in_progress" && deadline <= 0)
      ) {
        window.location.href = "/artist_assignment_results";
      }
    });
  }, []);

  useEffect(() => {
    const payload = {
      username: localStorage.getItem("username"),
    };
    setIsLoading(true);
    axios
      .post(ENVIRONMENT.GET_TEST_ARTIST_PRODUCT_DETAILS, payload)
      .then((res) => {
        setIsLoading(false);
        console.log(res.data);
        setRequirements(res.data.guidelines);
        setOutputRequired(res.data.need_to_model);
        setHeight(res.data.height);
        setWidth(res.data.width);
        setDepth(res.data.length);
        setColor(res.data.color_name);
        setSimilarColor(res.data.similar_color);
        setLightingRig(res.data.rig.rig_file);
        setReferenceURLs(res.data.reference_urls);
        setPlatform(res.data.platform);
        const refImages = [];
        if (res.data.product_manuals) {
          refImages.push(...res.data.product_manuals);
        }
        refImages.push(...res.data.product_photos);
        setReferenceImages(refImages);
        setTestID(res.data.id);
        setcolorExtractedImages(res.data.color_extracted_images);
        setproductID(res.data.product_id);
        setPlacementType(res.data.placement_type);

        const timeStarted = new Date(res.data.time_started);
        let deadline = new Date(res.data.time_started);
        let timeNow = new Date();

        console.log(timeNow);

        deadline.setDate(timeStarted.getDate() + 2);
        let offset = timeNow.getTimezoneOffset();
        deadline.setMinutes(deadline.getMinutes() - offset);
        console.log(deadline);

        setTestTimeRemaining(deadline);
      });
  }, []);

  const handleCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setIsChecked(true);
  };

  const handleChangeAssignmentFiles = (info, check = null) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setAssignmentFilesList(fileList);
    setUploading(true);
    if (info.file.status === "done") {
      setHasError(false);
      setErrorMessage("");
      setUploading(false);
    } else if (info.file.status === "error") {
      setErrorMessage(
        info.file.error.code.concat("   " + info.file.error.message)
      );
      if (info.file.error.code.includes("Credentials")) {
        setErrorMessage(
          info.file.error.code.concat(
            ". Your session has expired. Please reload the page."
          )
        );
      } else if (info.file.error.code.includes("Network")) {
        setErrorMessage(
          info.file.error.code.concat(
            ". We are unable to upload due to an issue with your internet connection."
          )
        );
      }
      setHasError(true);
      setUploading(false);
    }
  };

  const validateFileUploads = (files) => {
    let status_done = false;
    if (files != undefined) {
      for (const file of files) {
        if (file["status"] == "uploading") {
          return "uploading";
        } else if (file["status"] == "error") {
          return "error";
        } else if (file["status"] == "done") {
          status_done = true;
        }
      }
    }
    if (status_done) {
      return "done";
    }
    return "not_started";
  };

  const checkFolderStructure = (file) => {
    let return_check = true;

    let new_zip = new JSZip();
    
    return new_zip.loadAsync(file)
    .then((zip) => {
      for(let key in zip['files']){
        
        console.log("file in zip file : ", key);
        
        if( 
            (key.includes('/High') && !key.includes("_MACOSX/High")) || 
            (key.includes('/VrayTextures/') && !key.includes("_MACOSX/VrayTextures")) || 
            (key.includes('/PBRTextures') && !key.includes("_MACOSX/PBRTextures")) || 
            (key.includes('/Low') && !key.includes("_MACOSX/Low"))){
              return_check = false;
              setErrorMessage('Incorrect folder structure! Correct your folder structure and reupload file.')
        }
        
        if ( key.includes('High/') && !key.includes('_MACOSX/High') ){
          folderStructure['high_exist'] = true;
        }
        
        if ( key.includes('Low/') && !key.includes('_MACOSX/Low') ){
          folderStructure['low_exist'] = true;
        }
        
        if ( key.includes('PBRTextures/') && !key.includes('_MACOSX/PBRTextures') ){
          folderStructure['pbr_exist'] = true;
        }
        
        if ( key.includes('VrayTextures/') && !key.includes('_MACOSX/VrayTextures') ){
          folderStructure['vray_exist'] = true;
        }
        
        if ( key.includes('.max') ) {
          let max_id = key.split('/')[1];
          if( key.includes('High/') && max_id === (testID+'.max') ){
            folderStructure['max_high_id'] = true
          }
          if( key.includes('Low/') && max_id === (testID+'.max') ){
            folderStructure['max_low_id'] = true
          }
        }
      }

      folderStructure['id'] = testID
      let upload_error_message = ""
      upload_error_message =  Utilities.checkMaxBundleFolderStructure(outputRequired,folderStructure )
      if (upload_error_message != ""){
        return_check = false
        setErrorMessage(upload_error_message)
      }

      if (!return_check) {
        console.log('returning false')
        setIsCorrectFileStructure(false)
        return false;
      } else {
        console.log('returning true')
        setIsCorrectFileStructure(true)
        return true;
      }
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const submitTest = () => {
    const payload = {
      placement_type: placementType,
      test_id: testID,
      action: "artist_submit_test",
    };
    setIsLoading(true);
    axios.post(ENVIRONMENT.TEST_ARTIST_CONTROLLER, payload).then((res) => {
      setIsLoading(false);
      setRedirectURL("/artist_assignment_results");
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <DottedLoader />
      ) : (
        <>
          <Header className="header">
            <CustomHeader></CustomHeader>
          </Header>
          <Layout className="mb-90">
            <Content className="layout-container layout-snow overflowY-scroll p-0">
              <div className="heading-container">
                <div className="heading">
                  <h3 className="manrope w-600 f-24">Artist Assignment</h3>
                  <div className="remaining-time-container">
                    <div className="manrope w-500 f-16">
                      Time Remaining (hh:mm:ss) &nbsp;
                    </div>
                    <div className="countdown-container">
                      <Countdown
                        valueStyle={{ color: "#E19B12", fontSize: "16px" }}
                        value={testTimeRemaining}
                        onFinish={onFinish}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-container" style={{ paddingBottom: 0 }}>
                <div
                  className="upload-container mb-20"
                  style={
                    errorMessage === null || errorMessage === ""
                      ? { display: "inline-block" }
                      : { display: "none" }
                  }
                >
                  <div className="manrope w-500 f-16 title">
                    Submit Model Here
                  </div>
                  <div className="dragger">
                    <Dragger
                      {...Constants.upload_artist_test_props}
                      multiple={true}
                      fileList={assignmentFilesList}
                      listType="text"
                      onDrop={(e) => {
                        console.log(e);
                        console.log("file dropped");
                      }}
                      onRemove={(file) => {
                        const index = assignmentFilesList.indexOf(file);
                        const newFileList = assignmentFilesList.slice();
                        newFileList.splice(index, 1);
                        setAssignmentFilesList(newFileList);
                        newFileList.forEach((file) => {
                          if (file.status !== "error") {
                            setHasError(false);
                          } else {
                            setHasError(true);
                          }
                        });
                        return true;
                      }}
                      beforeUpload={(file) => {
                        if (file.name != (testID + ".zip")) {
                          setErrorMessage(
                            "Invalid archive name, archive must be named " +
                              testID +
                              ".zip"
                          );
                          return false;
                        } else {
                          return checkFolderStructure(file);
                        }
                      }}
                      onChange={handleChangeAssignmentFiles}
                      accept=".zip"
                      className="upload-box-hover-color dragger-size"
                      progress={progress_bar}
                      disabled={isTimeFinished ? true : false}
                    >
                      <p className="">
                        <img
                          className=""
                          src={require("../../../../assets/images/upload.png")}
                          alt=""
                        ></img>
                      </p>
                      <p className="ant-upload-text manrope f-12 w-500 ">
                        Drop your <b>Product Model File</b> here , or &nbsp;
                        <a href="#">Browse</a>
                      </p>
                      <p className="ant-upload-hint">SUPPORTED FORMATS: ZIP</p>

                      
                    </Dragger>
                  </div>
                </div>
                <div
                  className="upload-container mb-20"
                  style={
                    errorMessage
                      ? { display: "inline-block" }
                      : { display: "none" }
                  }
                >
                  <div className="manrope w-500 f-16 title validation-uploaded-model-header-rejection mr-0">
                    Model Validation Failed
                  </div>
                  <div className="dragger">
                    <p className="manrope f-14 w-600">Reason:</p>
                    <p className="manrope f-14 red">{errorMessage}</p>
                    <Row style={{ marginTop: 10 }}>
                      <Col span={12}>
                        <div className="justify-in-start">
                          <Button
                            className="modal-okay square font-12"
                            onClick={() => {
                              setAssignmentFilesList([]);
                              setHasError(false);
                              setUploading(false);
                              setErrorMessage("");
                            }}
                          >
                            Re-upload File
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <ModelDetails
                requirements={requirements}
                outputRequired={outputRequired}
                height={height}
                width={width}
                depth={depth}
                color={color}
                similarColor={similarColor}
                lightingRig={lightingRig}
                referenceURLs={referenceURLs}
                referenceImages={referenceImages}
                colorExtractedImages={colorExtractedImages}
                productID={productID}
                testID={testID}
              />
            </Content>
          </Layout>
          <Layout className="footer-layout">
            <Footer className="footer footer-container">
              <Button
                className="modal-okay square font-14 float-right"
                key="submit_test"
                onClick={showModal}
                disabled={
                  assignmentFilesList.length === 0 ||
                  uploading ||
                  isTimeFinished ||
                  isCorrectFileStructure === false
                    ? true
                    : false
                }
                
              >
                Submit
              </Button>
            </Footer>
          </Layout>
          <Modal
            title="Submission Confirmation"
            className=""
            maskClosable={false}
            onCancel={handleCancel}
            visible={isModalOpen}
            width={"47%"}
            centered={true}
            footer={[
              <div className="justify-in-end" key="submit_test">
                <Button
                  className="modal-okay square font-14"
                  onClick={submitTest}
                >
                  {redirectURL !== "" ? (
                    <Redirect to="/artist_assignment_results" />
                  ) : (
                    ""
                  )}
                  Confirm And Submit
                </Button>
              </div>,
            ]}
          >
            <p className="mb-0">
              Please note that you are about to submit your test assignment.
              Your submission will be analyzed. The results will be sent to your
              email as soon as the review is completed. Thank you for your
              patience.
              <br />
              <br /> You can reach out to&nbsp;
              <b>support@all3d.ai</b> in case of any questions.
            </p>
          </Modal>
        </>
      )}
    </>
  );
}

ArtistAssignmentContainer.propTypes = {
  user: PropTypes.object,
  attributes: PropTypes.object,
  state: PropTypes.string,
};

const mapStateToProps = (state) => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});

const ArtistAssignment = connect(
  mapStateToProps,
  null
)(ArtistAssignmentContainer);
export default ArtistAssignment;
