import React, { useEffect, useState } from "react";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import { Row, Modal, Button, Tabs, Col, Input, message, Radio, Alert } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import * as Utilities from "../../Utilities";
import DottedLoader from "../../DottedLoader/DottedLoader";
import ProductsList from "./ProductsList";
import FileConstants from "../../../../FileConstants";

const COMPANY_ID = FileConstants.COMPANY_ID;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const IS_MSPROVIDER = FileConstants.isMSProvider;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const MANAGED_SHARED_ENTITES = localStorage.getItem("managed_shared_entites");

const { TabPane } = Tabs;

const SwapProduct = (props) => {
    const { swap_space_modal, toggleSwapProductModal, setLoading, product_id, swapProduct, collection_id, space_area, project_ids, scene_id, render_generation, setRenderGeneration } =
        props;
    const [variantsData, setVariantsData] = useState([]);
    const [tabActiveKey, setTabActiveKey] = useState("variants");
    const [selected, setSelected] = useState([]);
    const [selectedElementDetials, setSelectedElementDetials] = useState([]);
    const [userData, setUserData] = useState([]);
    const [sharedData, setSharedData] = useState([]);
    const [storeData, setStoreData] = useState([]);
    const [mspOwnedData, setMSPOwnedData] = useState([]);
    const [mspSharedData, setMSPSharedData] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [filteredUserData, setFilteredUserData] = useState([]);
    const [filteredSharedData, setFilteredSharedData] = useState([]);
    const [filteredStoreData, setFilteredStoreData] = useState([]);
    const [filteredVariantsData, setFilteredVariantsData] = useState([]);
    const [filteredMSPOwnedData, setFilteredMSPOwnedData] = useState([]);
    const [filteredMSPSharedData, setFilteredMSPSharedData] = useState([]);
    const [filteredProjectData, setFilteredProjecteData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [initialLoadProducts, setInitialLoadProducts] = useState(false);
    const [storeProductsLoader, setStoreProductsLoader] = useState(false);
    const [requestStatusProduct, setRequestStatusProduct] = useState(false);
    const [initialLoadShared, setInitialLoadShared] = useState(true);
    const [initialLoadOwned, setInitialLoadOwned] = useState(true);
    const [sharedProductLoader, setSharedProductLoader] = useState(true);
    const [ownedProductLoader, setOwnedProductLoader] = useState(true);  
    const [currentTabType, setCurrentTabType] = useState("5");
    const [currentMSPTabType, setCurrentMSPTabType] = useState("5");
    const [currentProjectProductTabType, setCurrentProjectProductTabType] = useState("5");
    const [sharedTabType, setSharedTabType] = useState('all');
    const [mspSharedTabType, setMspSharedTabType] = useState('all');

    const getFilteredLibraryData = (currentData, modelStatus) => {
        if (modelStatus == -1)
            return currentData;
        let newData = []
        currentData.filter ((item) => {
            if (item.model_status == modelStatus) {
                newData.push(item)
            }
        })
        return newData
    }

    const tabs = [
        {
            key: "variants",
            title: "Your Variants",
            data: filteredVariantsData,
            length: filteredVariantsData.length,
            selectable: true,
            store: false,
        },
        {
            key: "project",
            type: 'project',
            title: "Project Products",
            data: filteredProjectData,
            length: getFilteredLibraryData(filteredProjectData, parseInt(currentProjectProductTabType)).length,
            selectable: true,
        },
        {
            key: "mspOwned",
            title: `${MANAGED_CUSTOMER_USERNAME}'s Products`,
            data: filteredMSPOwnedData,
            length: getFilteredLibraryData(filteredMSPOwnedData, parseInt(currentMSPTabType)).length,
            selectable: true,
            store: false,
        },
        {
            key: "mspShared",
            title: `${MANAGED_CUSTOMER_USERNAME}'s Other Products`,
            data: filteredMSPSharedData,
            length: filteredMSPSharedData.length,
            selectable: true,
            store: false,
        },
        {
            key: "your",
            title: "Your Products",
            data: filteredUserData,
            length: getFilteredLibraryData(filteredUserData, parseInt(currentTabType)).length,
            selectable: true,
            store: false,
        },
        {
            key: "shared",
            title: "Other Products",
            data: filteredSharedData,
            length: filteredSharedData.length,
            selectable: true,
            store: false,
        },
        {
            key: "store",
            title: "Store",
            data: filteredStoreData,
            length: filteredStoreData.length,
            selectable: true,
            store: true,
            loader: storeProductsLoader,
        },
    ];

    const customSort = (a, b) => {
        // Parse prices as numbers or set them to Infinity if empty or 0
        const priceA = a.price === "" ? Infinity : parseFloat(a.price || 0.0);
        const priceB = b.price === "" ? Infinity : parseFloat(b.price || 0.0);

        // Compare prices
        if (priceA < priceB) {
            return 1;
        } else if (priceA > priceB) {
            return -1;
        } else {
            // If prices are equal, maintain the original order
            return b.id - a.id;
        }
    }

    const getFilteredSharedLibraryData = (currentData, type) => {
        if(type == 'all'){
            return currentData;
        }
        else if(type == 'props'){
            let newData = []
            currentData.filter ((item) => {
                if (item.shared_product_type == type &&  item.is_store_item != 1) {
                    newData.push(item);
                }
            })
            return newData
        }
        else{
            let newData = []
            currentData.filter ((item) => {
                if (item.shared_product_type == type) {
                    newData.push(item)
                }
            })
            return newData
        }
    }

    const getProductPayload = (type, initial, sequence_id, current_customer = CUSTOMER_USERNAME, current_company_id = COMPANY_ID, current_shared_entities = SHARED_ENTITIES, is_customer_data = false) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "color_name", "materials",
                "platform", "style_category", "gtin", "tags", "product_model_type", "placement_type",
                 "material_type"],
        }

        if (IS_MSPROVIDER || sequence_id == -1) {
            payload["order_by"] = "last_modified_stamp desc"
        } else {
            payload['required_fields'].push('folder_id', 'folder_name', 'parent_folder_id',
                'library_order', 'entity_order')
            payload["order_by"] = "library_order asc,entity_order asc"
        }
        let filter_string = "";
        let shared_username = "";
        let customer_username = "customer_username__exact='" + current_customer + "'"
        let customer_username_notexact = "customer_username__notexact='" + current_customer + "'"
        let company_id_notexact = ""

        let company_id = "";
        let model_status = ""
        if(IS_MSPROVIDER && type == "owned"){
            model_status = "model_status__in=[3,4,5]"
        }
        else{
            model_status = "model_status__in=[5]"
        }
        let is_hidden = "is_hidden__not=true"
        let shared_hidden = "shared_hidden__not=true"
        let sequence_id_value = "sequence_id__exact=" + sequence_id
    

        if (type == 'shared') {
            payload['required_fields'].push('shared_product_type')
            shared_username = "shared_username__exact='" + current_customer + "'"
            filter_string = "(" + shared_username + "&&" + shared_hidden + ")"

            if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
                company_id_notexact = "company_id__notexact=" + current_company_id + "||company_id__isnull=true"
                filter_string = filter_string + "&&(" + company_id_notexact + ")&&("+customer_username_notexact+")";
            } else {
                filter_string = filter_string + "&&("+customer_username_notexact+")";
            }

        } else if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
            company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
        } else {
            if (IS_MSPROVIDER && !is_customer_data) {
                company_id = "company_id__isnull=true"
                let requested_for = "requested_for__isnull=true"
                filter_string = "(" + company_id + "&&" + customer_username + ")&&(" + is_hidden + ")&&(" + requested_for + ")"
            } else {
                filter_string = "(" + customer_username + "&&" + is_hidden + ")"
            }
        }
        filter_string = filter_string + "&&(" + model_status + ")"
        if (sequence_id != -1) {
            filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
        }
        if (current_company_id == ENVIRONMENT.WALMART_COMPANY_ID)
        {
            if(type == 'shared'){
                company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
                filter_string = '(' + filter_string + ')||(' + `(${company_id}||${customer_username})&&(${is_hidden})&&(${model_status})&&(product_model_type__exact='props'))`
            }
            else {
                filter_string = '(' + filter_string + `)&&(product_model_type__notexact='props')`
            }
        }
        filter_string = filter_string + "&&(has_active_project__is=false)"
        payload['filter_string'] = filter_string
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        if(localStorage.getItem('super_admin_username')){
            payload['is_customer_assumed_role'] = true
            payload['super_admin_username'] = localStorage.getItem('super_admin_username')
        }
        return payload;
    }

    const fetchProductData = async (type, initial = false) => {
        if (!initial) {
            setDataLoading(true);
          } else {
            if (type == 'shared') {
              setInitialLoadShared(true);
            } else {
              setInitialLoadOwned(true);
            }
        }
        let payload = getProductPayload(type, initial, -1);
        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
            if (res.data) {
                // setting data
                const dataWithKeywords = Utilities.getProductsDataWithKeywords(
                    res.data
                );
                if (type == "shared") {
                    let products =
                        Utilities.getProductsDataWithKeywords(dataWithKeywords);
                    setSharedData(products);
                    setFilteredSharedData(products);
                } else {
                    let products =
                        Utilities.getProductsDataWithKeywords(dataWithKeywords);
                    setUserData(products);
                    setFilteredUserData(products);
                }
            }
            if (initial) {
                if (type == 'shared') {
                  setInitialLoadShared(false);
                } else {
                  setInitialLoadOwned(false);
                }
              } else {
                if (type == 'shared') {
                  setSharedProductLoader(false);
                } else {
                  setOwnedProductLoader(false);
                }
                setDataLoading(false)
              }
        });
    };

    const fetchVariantsData = async () => {
        let payload = {
            required_fields: [
                "id",
                "name",
                "category",
                "height",
                "customer_username",
                "width",
                "depth",
                "thumbnail",
                "last_modified_stamp",
                "variant_of",
                "dimensions",
                "platform",
                "gtin",
                "tags",
                "product_model_type",
                "placement_type",
                "material_type"
            ],
        };

        payload["filter_string"] = `(variant_of__exact='${product_id}'&&model_status__in=[5])`;
        payload["order_by"] = "last_modified_stamp desc";

        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
            if (res.data) {
                const dataWithKeywords = Utilities.getProductsDataWithKeywords(
                    res.data
                );
                setVariantsData(dataWithKeywords);
                setFilteredVariantsData(dataWithKeywords);
            }
        });
    };

    const fetchStoreProductData = async (initial = false) => {
        const data = [];
        setStoreProductsLoader(true);
        let payload = {
            item_type: 'product',
            username: CUSTOMER_USERNAME
        }
        if (initial) {
            payload.limit = '';
            setInitialLoadProducts(true);
        }
        axios.post(ENVIRONMENT.STORE_LIST, payload)
            .then((res) => {
                if (!initial) {
                    setStoreProductsLoader(false);
                }
                let dataWithKeywords = Utilities.getProductsDataWithKeywords(res.data, "store");
                dataWithKeywords = dataWithKeywords.sort(customSort);
                setStoreData(dataWithKeywords);
                setFilteredStoreData(dataWithKeywords);
            })
            .catch((error) => {
            });
        return data;
    };

    const fetchMSPCustomerData = async (type) => {
        if (IS_MSPROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            const payload = getProductPayload(type, false, -1, MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true);
            await axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
                .then(res => {
                    if (res.data) {
                        const products = Utilities.getProductsDataWithKeywords(res.data);
                        if(type === 'owned'){
                            setMSPOwnedData(products);
                            setFilteredMSPOwnedData(products);
                        } else if(type === 'shared'){
                            setMSPSharedData(products);
                            setFilteredMSPSharedData(products);
                        }
                    }
                });
        }
    }

    let getProjectProductsPayload = (project_ids) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "is_shared", "platform", "placement_type", "material_type"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        payload["filter_string"] =  `(project_ids__in=[${project_ids.join(",")}]) && (model_status__in=[4, 5])`

        return payload;
    }

    const getProjectProductData = () => {
        let payload = getProjectProductsPayload(project_ids);
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((response) => {
                if (response.data) {
                    let owned_products = Utilities.getProductsDataWithKeywords(response.data);;
                    setProjectData(owned_products);
                    setFilteredProjecteData(owned_products);
                }
            })
            .catch((error) => {
            });
    }

    const updateSceneRenderGeneration = () => {
        if (!render_generation) {
            axios.post(ENVIRONMENT.UPDATE_SCENE, {
                scene_id: scene_id,
                render_generation: true
            })
            .then(res => {
                setRenderGeneration(true)
            });
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        handleCancelSwapProductModal();

        const product_id = selected[0];
        const payload = {
            products_list: [{product_id, area_name:space_area}],
            space_allocation: true,
            collection_id: collection_id,
            username: CUSTOMER_USERNAME,
            update_collection_metadata: true,
        };

        axios.post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
        .then((response) => {
            if (render_generation != null && render_generation != undefined) {
                updateSceneRenderGeneration()
            }
        })
        .catch(() => {
        });

        swapProduct(selectedElementDetials, () => {
            message.success("Product Swapped Successfully");
        })
    }

    const handleCancelSwapProductModal = () => {
        toggleSwapProductModal(false);
        setSelected([]);
        setSearchValue("");
    };

    const handleTabChange = (key) => {
        setTabActiveKey(key);
    };

    const getTabTitle = (tab) => {
      if (tab.key == "store" && storeProductsLoader)
        return tab.title;
      else return tab.title + ` (${tab.length})`;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (product_id) {
                setDataLoading(true);
                await fetchVariantsData();
                setDataLoading(false);
            }
        };
        fetchData();
    }, [product_id]);

    useEffect(()=>{
        if (project_ids.length > 0) {
            getProjectProductData()
        }
    },[project_ids])

    useEffect(() => {
        const fetchData = async () => {
            setDataLoading(true);
            fetchProductData("owned", true);
            fetchProductData("shared", true);
            await fetchMSPCustomerData('owned');
            await fetchMSPCustomerData('shared');
            await loadUserRequestStatusProducts();
            await fetchStoreProductData(true);
            setDataLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (searchValue.length) {
            const filteredVariantsData =
                Utilities.getRelevanceBasedOrderedSearchResult(
                    searchValue,
                    variantsData
                );
            const filteredUserData = getFilteredLibraryData(Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                userData
            ), parseInt(currentTabType));
            const filteredSharedData = getFilteredSharedLibraryData(Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                sharedData), sharedTabType);
            const filteredStoreData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                storeData
            );
            const filteredMSPOwnedData = getFilteredLibraryData(Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                mspOwnedData
            ), parseInt(currentMSPTabType));
            const filteredMSPSharedData = getFilteredSharedLibraryData(Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                mspSharedData), mspSharedTabType);
            const filteredProjectData = getFilteredLibraryData(Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                projectData), parseInt(currentProjectProductTabType));
            setFilteredMSPOwnedData(filteredMSPOwnedData);
            setFilteredMSPSharedData(filteredMSPSharedData);
            setFilteredSharedData(filteredSharedData);
            setFilteredUserData(filteredUserData);
            setFilteredVariantsData(filteredVariantsData);
            setFilteredStoreData(filteredStoreData);
            setFilteredProjecteData(filteredProjectData);
        }
        else {
            setFilteredMSPOwnedData(getFilteredLibraryData(mspOwnedData, parseInt(currentMSPTabType)));
            setFilteredMSPSharedData(getFilteredSharedLibraryData(mspSharedData, mspSharedTabType));
            setFilteredSharedData(getFilteredSharedLibraryData(sharedData, sharedTabType));
            setFilteredUserData(getFilteredLibraryData(userData, parseInt(currentTabType)));
            setFilteredVariantsData(variantsData);
            setFilteredStoreData(storeData);
            setFilteredProjecteData(getFilteredLibraryData(projectData, parseInt(currentProjectProductTabType)));
        }
    }, [searchValue, currentTabType, currentMSPTabType, currentProjectProductTabType,sharedTabType, mspSharedTabType]);

    useEffect(() => {
        if (initialLoadProducts) {
            fetchStoreProductData();
        }
    }, [initialLoadProducts]);

    useEffect(() => {
        if (!initialLoadOwned && ownedProductLoader) {
            fetchProductData('owned');
        }
      }, [initialLoadOwned]);
    
      useEffect(() => {
        if (!initialLoadShared && sharedProductLoader) {
            fetchProductData('shared');
        }
      }, [initialLoadShared]);

    useEffect(() => {
        if (!dataLoading && swap_space_modal) {
            const nextNonEmptyTab = tabs.find(tab => tab.data.length > 0);

            if (nextNonEmptyTab && tabActiveKey !== nextNonEmptyTab.key) {
                setTabActiveKey(nextNonEmptyTab.key);
            }
        }
    }, [swap_space_modal, dataLoading]);

    const onProductTabChange = (e, type) => {
        if (type == 'your') {
            setCurrentTabType(e.target.value)
        } else if (type == 'mspOwned') {
            setCurrentMSPTabType(e.target.value);
        } else if (type == 'project'){
            setCurrentProjectProductTabType(e.target.value);
        }
    }

    const loadUserRequestStatusProducts = async () => {
        const check_payload = {
            username: localStorage.getItem("username"),
            action: 'check',
            request_type: 'product_prop',
        };
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
            setRequestStatusProduct(res.data['request_allowed']);
        });
    };

    const onChangeSharedTabType = (e, type) => {
        if (type == 'shared') {
            setSharedTabType(e.target.value);
        } else if ('mspShared') {
            setMspSharedTabType(e.target.value);
        }
    }

    const InfoAlert = 
        <Alert
        message="Please note that these items are not yet approved. Any changes made to the model files will automatically apply to the item used in the scene."
        type="info"
        showIcon
        />;

    const AllInfoAlert = 
        <Alert
        message="Please note that some items are not yet approved. Any changes made to the model files will automatically apply to the item used in the scene."
        type="info"
        showIcon
        />;

    return (
        <Modal
            destroyOnClose={true}
            closable={true}
            onCancel={handleCancelSwapProductModal}
            className="swap-modal"
            bodyStyle={{ padding: 0 }}
            footer={null}
            visible={swap_space_modal}
        >
            <Row className="header-container">
                <div className="swap-heading-tag">
                    <span className="manrope f-14">Swap Product</span>
                </div>
            </Row>
            <Row className="justify-space-between swap-container">
                <Col span={16} className="justify-in-start">
                    <span className="manrope f-20 w-600">Choose a product below to replace the selected product</span>
                </Col>
                <Col span={8} className="justify-in-end">
                    <Input
                        className="manrope f-14 grey-99 pd-8"
                        prefix={
                            <SearchOutlined
                                style={{
                                    marginleft: "4px",
                                    marginRight: "8px",
                                    fontSize: "16px",
                                }}
                            />
                        }
                        placeholder="Search Products"
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                    />
                </Col>
            </Row>
            <div className="swap-container">
                <Tabs
                    onChange={(key) => handleTabChange(key)}
                    className="product-tab library"
                    tabBarGutter={16}
                    size="large"
                    activeKey={tabActiveKey}
                >
                    {tabs?.map((tab, index) => {
                        return ((index === 2 || index === 3) && (!IS_MSPROVIDER || !MANAGED_CUSTOMER_USERNAME)) || (index === 1 && project_ids.length == 0 ) ? null : (
                            <TabPane
                                tab={getTabTitle(tab)}
                                key={tab.key}
                                style={{ padding: "0px 10px" }}
                            >
                                <div className="product-tab library">
                                    {initialLoadOwned || initialLoadShared  ? (
                                        <div className="modal-tab">
                                            <DottedLoader />
                                        </div>
                                    ) : (
                                        <>
                                        {(IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME != undefined && (tabActiveKey == 'mspOwned' || tabActiveKey == 'your' || tabActiveKey == 'project')) ? 
                                                <>
                                                    <div className="shared-models-tab manrope f-14 black-55" style={{ marginBottom: 10, marginRight: 20 }}>
                                                        <Radio.Group
                                                            onChange={(e) => onProductTabChange(e, tabActiveKey)}
                                                            value={tabActiveKey == 'your' ? currentTabType : tabActiveKey == 'project' ? currentProjectProductTabType : currentMSPTabType}
                                                        >
                                                            <Radio value="5">Approved Models</Radio>
                                                            <Radio value="4">In Customer QA</Radio>
                                                            {tabActiveKey != 'project' ? <Radio value="3">In Admin QA</Radio> : null}
                                                            <Radio value="-1">All</Radio>
                                                        </Radio.Group>
                                                    </div>
                                        
                                                    {((tabActiveKey == "your" && (currentTabType === '3' || currentTabType === '4'))
                                                    || (tabActiveKey == "mspOwned" && (currentMSPTabType === '3' || currentMSPTabType === '4'))
                                                    || (tabActiveKey == "project" && (currentProjectProductTabType === '3' || currentProjectProductTabType === '4'))) && (
                                                        <Row>
                                                            <Col span={24} style={{ marginBottom: 10 }}>
                                                                {InfoAlert}
                                                            </Col>
                                                        </Row>
                                                    )}
                                        
                                                    {((tabActiveKey == "your" && currentTabType === '-1')
                                                    || (tabActiveKey == "mspOwned" && currentMSPTabType === '-1')
                                                    || (tabActiveKey == "project" && currentProjectProductTabType === '-1')) && (
                                                        <Row>
                                                            <Col span={24} style={{ marginBottom: 10 }}>
                                                                {AllInfoAlert}
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </>
                                                : tabActiveKey == 'mspShared' || tabActiveKey == 'shared' ? 
                                                <div className="shared-models-tab manrope f-14 black-55" style={{ marginBottom: 10, marginRight: 20 }}>
                                                    <span className="shared-models-tab-span">
                                                        Show Items:
                                                    </span>
                                                    <Radio.Group 
                                                        value={tabActiveKey == "shared" ? sharedTabType : mspSharedTabType}
                                                        onChange={(e) => onChangeSharedTabType(e, tabActiveKey)}
                                                    >
                                                        <Radio value='all'>
                                                            All
                                                        </Radio>
                                                        <Radio value='added_from_store'>
                                                            Added from Store
                                                        </Radio>
                                                        <Radio value='shared_by_customer'>
                                                            Shared
                                                        </Radio>
                                                        {(tabActiveKey == "shared" && COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID) || (tabActiveKey == "mspShared" && IS_MSPROVIDER && (MANAGED_COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID)) &&
                                                                <Radio value='props'>
                                                                    Your Props
                                                                </Radio>}
                                                    </Radio.Group>
                                                </div>
                                                : null
                                        }
                                        <ProductsList
                                            data={tab.data}
                                            selectable={tab.selectable}
                                            store={tab.store}
                                            selected={selected}
                                            setSelected={setSelected}
                                            setSelectedElementDetials={setSelectedElementDetials}
                                            product_id={product_id}
                                            loader={tab.loader}
                                            requestStatusProduct = {requestStatusProduct}
                                        />
                                        </>
                                    )}
                                </div>
                            </TabPane>
                        );
                    })}
                </Tabs>
            </div>
            <Row className="swap-modal-footer justify-in-end" onClick={() => { }}>
                <Button
                    style={{ marginRight: 10 }}
                    className="outline-red-btn square font-12 manrope"
                    onClick={handleCancelSwapProductModal}
                >
                    Cancel
                </Button>
                <Button
                    className="modal-okay square font-14"
                    disabled={selected.length === 0}
                    onClick={handleSubmit}
                >
                    Continue
                </Button>
            </Row>
        </Modal>
    );
};

export default SwapProduct;
