import React, { useState, useEffect } from "react";
import { Card, Row, Col, Table, Modal, Radio, Tooltip } from "antd";
import moment from "moment";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { ArrowDownOutlined, ArrowUpOutlined, LineOutlined, LoadingOutlined } from "@ant-design/icons";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import useTableFilter from "../../UseTableFilter/useTableFilter";

const Actions = {
    get_all_artist_details: "get_all_artist_details",
    get_artist_details: "get_artist_details"
};

const DATE_FORMAT = "YYYY-MM-DD";
const DISPLAY_DATE_FORMAT = "YYYY/MM/DD";

const ArtistSummary = () => {
    const [isArtistDataFetched, setIsArtistDataFetched] = useState(false);
    const [artistData, setArtistData] = useState([]);
    const [startDate, setStartDate] = useState(
        moment().startOf("month").format(DATE_FORMAT)
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("month").format(DATE_FORMAT)
    );
    const [isArtistDetailsModalVisible, setIsArtistDetailsModalVisible] = useState(false);
    const [isArtistDetailFetched, setIsArtistDetailFetched] = useState(false)
    const [artistDetails, setArtistDetails] = useState([]);
    const [artistUsername, setArtistUsername] = useState(null);
    const [modelCount, setModelCount] = useState(0);
    const [currentEntity, setCurrentEntity] = useState("product");
    const [systemAverage, setSystemAverage] = useState(0);
    const [getColumnSearchProps] = useTableFilter();
  
    const getArtistSummary = () => {
        console.log("Start Date", startDate);
        console.log("End Date", endDate);
        let payload = {
            "action" : Actions["get_all_artist_details"],
            "entity_type" : currentEntity,
            "start_date" : startDate,
            "end_date" : endDate
        };
        console.log("Payload", payload);
        
        setIsArtistDataFetched(true);
        axios.post(ENVIRONMENT.GET_ARTIST_EFFICIENCY, payload).then((response) => {
            const responseData = response.data;
            console.log('Artist Data', responseData);
            setSystemAverage(calculateAverage(responseData));
            setArtistData(responseData);
            setIsArtistDataFetched(false);
        });
    }

    useEffect(() => {
        setSystemAverage(0)
        getArtistSummary()
    }, [startDate, endDate, currentEntity]);

    const calculateAverage = (arrayOfObjects) => {
        const values = arrayOfObjects.map(obj => obj.rejection_count); // Extract 'value' property values
        const sum = values.reduce((acc, curr) => acc + curr, 0); // Calculate the sum of values
        const average = sum / values.length;
        return Number(average.toFixed(1));
    }


    // Table Columns
    const getArtistDataColumns = () => {
        if (artistData.length == 0) { return }
        const costAverages = artistData[0].cost_averages
        let artistDataColumns = [
            {
                title: "Artist Username",
                dataIndex: "artist_username",
                key: "artist_username",
                ...getColumnSearchProps("artist_username"),
            },
            {
                title: "Payment Mode",
                dataIndex: "payment_mode",
                key: "payment_mode",
            },
            {
                title: "Rate",
                dataIndex: "rate",
                key: "rate",
                render: (text) => '$'+text,
                sorter: (a, b) => a.rate - b.rate,
            },
            {
                title: "Models Worked On",
                dataIndex: "model_count",
                key: "model_count",
                sorter: (a, b) => a.model_count - b.model_count,
                render: (text, record) => <a
                className="onhover-underline"
                onClick={() => {
                    setModelCount(text);
                    showModelDetails(record.artist_username);
                }}>{text}</a>,
            },
            {
                title: "Average no. of Rejections / Model",
                dataIndex: "rejection_count",
                key: "rejection_count",
                sorter: (a, b) => a.rejection_count - b.rejection_count,
                render: (text, record) => {
                    let style = {marginRight : "8px"};
                    let iconStyle = {
                        strokeWidth: "50",
                    }
                    let icon;
                    let message = "";
                    if (text > systemAverage) {
                        let color = "red"
                        iconStyle.color = color
                        iconStyle.stroke = color
                        style.color = color
                        message = `${(text-systemAverage).toFixed(1)} higher than system average`
                        icon = <ArrowUpOutlined style={iconStyle}/>
                    } else if (text < systemAverage) {
                        let color = "green"
                        iconStyle.color = color
                        iconStyle.stroke = color
                        style.color = color
                        message = `${(systemAverage-text).toFixed(1)} lower than system average`
                        icon = <ArrowDownOutlined style={iconStyle}/>
                    } else if (text == systemAverage) {
                        let color = "blue"
                        iconStyle.color = color
                        iconStyle.stroke = color
                        style.color = color
                        message = `equal to system average`
                        icon = <LineOutlined style={iconStyle}/>
                    }
                    return (<>
                    <Tooltip title={message}>
                    <span className="onhover-underline" style={style}>{text}</span>{icon}
                    </Tooltip>
                    </>)
                }
            },
            {
                title: "Average Modeling Cost",
                dataIndex: "cost_averages",
                key: 'average',
                children:
                costAverages.map(cost => {
                    return {
                        title: cost.title,
                        dataIndex: "cost_averages",
                        key: cost.title,
                        sorter: (a, b) => {
                            // Implement sorting based on the 'average' property
                            const averageA = a.cost_averages.find(value => value.title === cost.title);
                            const averageB = b.cost_averages.find(value => value.title === cost.title);
                            return averageA.average - averageB.average;
                        },
                        render: (values) =>
                        values.map((value, index) => {
                            let ret;
                            if (value.title == cost.title) ret = `$${value.average}`;
                            return ret;
                        })
                    }
                })
            },
        ]
        return artistDataColumns
    }
    

    const artistDetailsColumns = [
        {
        title: "Entity ID",
        dataIndex: "entity_id",
        key: "entity_id",
        render: (text, record) => (
            <a
            className="onhover-underline"
            href={getModelLink(text)}>
            {text}
            </a>
        ),
        ...getColumnSearchProps("entity_id"),
        },
        {
            title: "Entity Name",
            dataIndex: "entity_name",
            key: "entity_name",
        },
        {
            title: "Model Type",
            dataIndex: "model_type",
            key: "model_type",
            ...getColumnSearchProps("model_type"),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            ...getColumnSearchProps("category"),
        },
        {
            title: "Hours Spent",
            dataIndex: "total_hours",
            key: "total_hours",
        },
        {
            title: "Rejections Count",
            dataIndex: "rejection_count",
            key: "rejection_count",
        },
        {
            title: "Cost",
            dataIndex: "cost",
            key: "cost",
            render: (text) => '$'+text,
        },
    ]

    const showModelDetails = (model_type) => {
        setArtistUsername(model_type);
        setIsArtistDetailsModalVisible(true);
        let payload = {
        "action" : Actions["get_artist_details"],
        "entity_type" : currentEntity,
        "start_date" : startDate,
        "end_date" : endDate,
        "artist_username" : model_type,
        };

        console.log("Payload", payload);
        
        setIsArtistDetailFetched(true);
        axios.post(ENVIRONMENT.GET_ARTIST_EFFICIENCY, payload).then((response) => {
            const responseData = response.data;
            console.log('Model Details', responseData);
            setArtistDetails(responseData);
            setIsArtistDetailFetched(false);
        });
    };

    const closeModelDetails = () => {
        setIsArtistDetailsModalVisible(false);
    };

    const getModelLink = (id)  => {
        if (currentEntity === 'material_entity') {
            return '/admin_materials/' + id
        } 
        else if (currentEntity === 'room') {
            return '/admin_rooms/' + id
        }
        else {
            return '/admin_products/' + id
        }
    }

    const onDateRangeChange = (date_values) => {
        if (date_values && date_values[0] && date_values[1]) {
            setStartDate(date_values[0].format(DATE_FORMAT));
            setEndDate(date_values[1].format(DATE_FORMAT));
        }
    };

    const changeEntityType = (e) => {
        setSystemAverage(0);
        setCurrentEntity(e.target.value);
    };

    return (
    <>
    <Card
    className="no-hover section-container"
    size="small"
    title=
    {
        <Row className="mt-10 justify-in-start" gutter={12}>
        <Col span={19}>
            <span className="sub-heading">Summary</span>
        </Col>
        <Col span={5} className="justify-in-end">
        <DateRangePicker
            start={startDate}
            end={endDate}
            date_format={DISPLAY_DATE_FORMAT}
            onCalChange={onDateRangeChange}>
            </DateRangePicker>
        </Col>
        </Row>
    }>
         <Row className="justify-in-start mt-20" gutter={16}>
            <Col span={15}>
                <Radio.Group
                value={currentEntity}
                onChange={changeEntityType}
                size="medium"
                className="manrope f-14 black-55"
                >
                    <Radio.Button value="product">Product</Radio.Button>
                    <Radio.Button value="room">Space</Radio.Button>
                    <Radio.Button value="material_entity">Material</Radio.Button>
                </Radio.Group>
          </Col>
        </Row>
        <Row className="justify-in-start mt-20" gutter={16}>
            <Col span={24}>
                <span className="manrope f-16 black-55">
                    <strong>System Average Rejection Count: </strong>{systemAverage}&nbsp;&nbsp;
                    <span className="manrope f-14 black-55">
                    (<ArrowUpOutlined style={{color: "red", strokeWidth: "50", stroke: "red"}}/> higher than average&nbsp;&nbsp;&nbsp;&nbsp;
                    <ArrowDownOutlined style={{color: "green", strokeWidth: "50", stroke: "green"}}/> lower than average&nbsp;&nbsp;&nbsp;&nbsp;
                    <LineOutlined style={{color: "blue", strokeWidth: "50", stroke: "blue"}}/> equal to average)
                    </span>
                </span>
          </Col>
        </Row>
        <Row>
            <div className="chart-container">
                {
                isArtistDataFetched ? (<LoadingOutlined className="loading-center top-20"/>) : 
                <Table
                dataSource={artistData}
                columns={getArtistDataColumns()}
                scroll
                style={{ overflowX: "scroll" }}/>
                }
            </div>
        </Row>
    </Card>
    <Modal
    title={`Artist: ${artistUsername} (${modelCount})`}
    visible={isArtistDetailsModalVisible}
    onCancel={closeModelDetails}
    footer={null}
    width={"70%"}
    bodyStyle={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Row>
        <div className="chart-container">
            {
            isArtistDetailFetched ? (<LoadingOutlined className="loading-center top-20"/>) : 
            <Table
            dataSource={artistDetails}
            columns={artistDetailsColumns}
            scroll
            style={{ overflowX: "scroll" }}
            />
            }
        </div>
        </Row>
    </Modal>
    </>);
};

export default ArtistSummary;