import React from 'react';
import 'antd/dist/antd.css';
import ProductsData from '../ProductsData'
import CustomerMainLayout from '../CustomerMainLayout'

class MyProductsView extends React.Component {
    render() {
        return (
            <CustomerMainLayout selected='4'>
                <ProductsData />
            </CustomerMainLayout>
        );
    }
}

export default MyProductsView;
