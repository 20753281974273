import React, { useState, useContext, useEffect  } from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    Upload,
    Select,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';

import TextArea from 'antd/lib/input/TextArea';

const CollaborateEmailSender = (props) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [emailContent, setEmailContent] = useState("");
    const [taggedUsers, setTaggedUsers] = useState([]);
    const { collabSendEmailModal, setCollabSendEmailModal, tag_user_list, collabEmailLoader, sendEmailNotification } = useContext(props.context_for == "collaborate_qa" ? CollaborateQAContext : CollaborateContext);

    const handleMessageChange = (value) => {
        const usernames = Object.keys(value).map(key => value[key]);
        setTaggedUsers(usernames);
    };

    const getUsersList = () => {
        let selectOpts = []
        tag_user_list.map ((user) => {
            selectOpts.push({label: user.display, value: user.id});
        })
        return selectOpts;
    }

    const handleEmailContent = (e) => {
        setEmailContent(e.target.value);
    }

    const handleEmailSend = () => {
        if (taggedUsers.length == 0) {
            setErrorMessage("Please select a user")
            return
        }
        if (emailContent == "") {
            setErrorMessage("Please enter email message")
            return
        }
        sendEmailNotification(emailContent, taggedUsers);
    }

    useEffect(() => {
        if (collabSendEmailModal == false) {
            setErrorMessage("")
            setTaggedUsers([]); 
            setEmailContent("")
        }
    }, [collabSendEmailModal]);

    return <Modal bodyStyle={{ paddingTop: "2px" }}
        title={<span className='manrope f-16 black-33 w-700'>Send Email Notification</span>}
        visible={collabSendEmailModal}
        width={500}
        centered={true}
        onCancel={() => { setTaggedUsers([]); setEmailContent(""); setCollabSendEmailModal(false);}}
        footer={[
            <div className='justify-in-end'>
                <Button disabled={collabEmailLoader} loading={collabEmailLoader} className="modal-okay square font-12" onClick={handleEmailSend}>
                    Send Email                
                </Button>
            </div>
        ]}>
        <Row>
            <Col span={24} style={{marginTop:8}}>
                <p className="manrope f-14 black-55 w-600 mb-4">Select users to notify</p>
            </Col>
            <Col span={24}>
                <Select
                placeholder="Select User(s)"
                mode="tags"
                style={{width: "100%"}}
                options={getUsersList()}
                onChange={handleMessageChange}
                value={taggedUsers}
                tokenSeparators={[',']}>
                </Select>
            </Col>
            <Col span={24} style={{marginTop:16}}>
                <p className="manrope f-14 black-55 w-600 mb-4">Add email content</p>
            </Col>
            <Col span={24}>
                <TextArea
                value={emailContent}
                onChange={handleEmailContent}
                className="manrope f-14 grey-77" 
                placeholder="Please write your email message here" rows={6}/>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{marginTop: 10}}>
            <p className="text-danger">{errorMessage}</p>
            </Col>
        </Row>
    </Modal>
}

export default (CollaborateEmailSender);