import React from "react";
import PropTypes from "prop-types";
import "./../styles/triple-toggle-switch.scss";

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool
]);

const propTypes = {
  labels: PropTypes.shape({
    left: {
      title: PropTypes.string.isRequired,
      value: valueType
    },
    center: {
      title: PropTypes.string.isRequired,
      value: valueType
    },
    right: {
      title: PropTypes.string.isRequired,
      value: valueType
    }
  }),
  onChange: PropTypes.func.isRequired,
  styles: PropTypes.object
};

const defaultProps = {
  labels: {
    left: {
      title: "left",
      value: "left"
    },
    center: {
      title: "center",
      value: "center"
    },
    right: {
      title: "right",
      value: "right"
    }
  },
  onChange: (value) => console.log("value:", value)
};

class TripleToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: null
    };
  }

  getSwitchAnimation = (value) => {
    let animation = null;
    if (value === "center" && this.props.toggleState === "left") {
      animation = "left-to-center";
    } else if (value === "right" && this.props.toggleState === "center") {
      animation = "center-to-right";
    } else if (value === "center" && this.props.toggleState === "right") {
      animation = "right-to-center";
    } else if (value === "left" && this.props.toggleState === "center") {
      animation = "center-to-left";
    } else if (value === "right" && this.props.toggleState === "left") {
      animation = "left-to-right";
    } else if (value === "left" && this.props.toggleState === "right") {
      animation = "right-to-left";
    }
    this.props.onChange(value);
    this.setState({ animation: animation });
  };

  render() {
    const { labels } = this.props;

    return (
      <div className="main-container">
        <div
          className={`switch ${this.state.animation} ${this.props.toggleState}-position`}
        ></div>
        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="left"
          type="radio"
          value="left"
        />
        <label
          className={ `left-label ${
            this.props.toggleState === "left"
          }`}
          htmlFor="left"
        >
          <h4 className={this.props.toggleState == "left" ? "label-text selected": "label-text"}>{labels.left.title}</h4>
        </label>

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="center"
          type="radio"
          value="center"
        />
        <label
          className={`center-label ${
            this.props.toggleState === "center"
          }`}
          htmlFor="center"
        >
          <h4 className={this.props.toggleState == "center" ? "label-text selected": "label-text"}>{labels.center.title}</h4>
        </label>

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="right"
          type="radio"
          value="right"
        />
        <label
          className={`right-label ${
            this.props.toggleState === "right"
          }`}
          htmlFor="right"
        >
          <h4 className={this.props.toggleState == "right" ? "label-text selected": "label-text"}>{labels.right.title}</h4>
        </label>
      </div>
    );
  }
}

TripleToggleSwitch.propTypes = propTypes;
TripleToggleSwitch.defaultProps = defaultProps;

export default TripleToggleSwitch;
