import React, { useEffect, useState, useRef } from 'react';
import CircularSlider from 'react-circular-slider-svg';
import FileConstants from '../../../FileConstants';

const labelStyle = FileConstants.SUN_DIRECTION_LABEL_STYLE;

const SunDirection = (props) => {

    const [sliderSize, setSliderSize] = useState(0);
    const [labelDistances, setLabelDistances] = useState({});
    const parentRef = useRef(null);
    const [imgDimensions, setImgDimensions] = useState({ maxWidth: 0, maxHeight: 0 });

    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                const parentWidth = parentRef.current.offsetWidth;
                setSliderSize(Math.floor(parentWidth * 0.7));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Calculate label distances based on slider size and parent div's width
        if (sliderSize > 0) {
            const parentWidth = parentRef.current.offsetWidth;
            const radius = sliderSize / 2;

            // Adjust distances to keep labels close to the border but inside the circle
            const labelDistances = {
                N: radius * 0.27,
                S: radius * 0.27,
                W: parentWidth * 0.27,
                E: parentWidth * 0.27,
            };
            setLabelDistances(labelDistances);

             // Calculate image dimensions dynamically
             const imgMaxSize = Math.floor(sliderSize * 0.5);
             setImgDimensions({ maxWidth: imgMaxSize, maxHeight: imgMaxSize });
        }
    }, [sliderSize, parentRef.current && parentRef.current.offsetWidth]);

    useEffect(() => {
        const svgElement = document.querySelector('#svg-container svg');
        if (svgElement) {
            svgElement.setAttribute('viewBox', `0 0 ${sliderSize} ${sliderSize}`);
            svgElement.setAttribute('width', '100%');
            svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');
        }
    }, [sliderSize]);

    return (
        <div id="svg-container" ref={parentRef} style={{ position: "relative" }}>
            {sliderSize > 0 && (
                <CircularSlider
                    size={sliderSize}
                    trackWidth={Math.floor(sliderSize * 0.03)}
                    minValue={0}
                    maxValue={360}
                    startAngle={0}
                    endAngle={360}
                    angleType={{
                        direction: "cw",
                        axis: "+y"
                    }}
                    handle1={{
                        value: props.value,
                        onChange: v => {
                            props.onChange(v);
                        }
                    }}
                    arcColor="#7caee8"
                    arcBackgroundColor="#7caee8"
                    coerceToInt={true}
                    handleSize={Math.floor(sliderSize * 0.05)}
                />
            )}
            <p style={{ ...labelStyle, top: labelDistances.N, left: "50%", transform: "translateX(-50%)" }}>N</p>
            <p style={{ ...labelStyle, bottom: labelDistances.S, left: "50%", transform: "translateX(-50%)" }}>S</p>
            <p style={{ ...labelStyle, left: labelDistances.W, top: "50%", transform: "translateY(-50%)" }}>W</p>
            <p style={{ ...labelStyle, right: labelDistances.E, top: "50%", transform: "translateY(-50%)" }}>E</p>
            <img
            src={props.roomImg}
            alt="Space Image"
            style={{
                ...labelStyle,
                maxWidth:`${imgDimensions.maxWidth}px`,
                maxHeight: `${imgDimensions.maxHeight}px`,
                width: "auto",
                height: "auto",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                userSelect: 'none'
                }}
            ></img>
        </div>
    );
};
export default SunDirection;
