import CollaborateConstants from './components/App/CustomerComponents/CollaborateTool/Constants';
import jsPDF from 'jspdf';
import * as Utilities from './components/App/Utilities'
import { BASE_URI, getBaseURL } from './environments/env';

class CanvasEditor {

	constructor(image_url, loading_state, class_name = 'canvas-editor', project_id = null) {
		this.image_url = image_url;
		this.loading_state = loading_state;
		this.class_name = class_name;
		this.user_annotations = [];
		this.project_id = project_id;

		//modes 
		this.annotate_mode = false;
		this.change_color_mode = false;
		this.add_text_mode = false;
		this.add_shape_mode = false;
		this.drawing_mode = false;
		this.change_pen_size_mode = false;
		this.eraser_mode = false;
		this.is_panning = false;
		this.allow_canvas_dragging = false;
		this.zoom_in_mode = false;
		this.zoom_out_mode = false;
		this.tagged_annotations = [];
		this.all_comments = [];

		// canvas_changed
		this.canvas_changed = false;
		this.annotation_create_dot = false;
		this.object_modfied_flag = false;


		// access level that is allowed to user
		this.access_level = "edit";

		this.annotate_group = '';
		this.active_text = null;

		this.lastPosX = 0;
		this.lastPosY = 0;
		this.canvasWidth = -1;
		this.canvasHeight = -1;
		this.annotation_loading = false;
		this.calculateAspectRatio();


		this.canvas_data = '';
		this.canvas = new window.fabric.Canvas(this.class_name, {
			isDrawingMode: false
		});

		this.setCornerStyles();

		// panning controls
		this.canvas.allowTouchScrolling = true;
		this.canvas.selection = false;

		this.loadImage();
		this.setUpEventListeners();
		this.toggleAnnotateDisplay("none");
	}

	setPlatform(platform) {
		this.platform = platform;
	}


	setAccessLevel(access_level = "") {
		this.access_level = access_level;
		if (access_level == "" || access_level == "restricted" || access_level == "view") {
			this.toggleObjectSelection(false);
		} else {
			this.toggleObjectSelection(true);
		}
	}

	reloadImageAndAspectRatio(state = null, callback = null, calculate_aspect = false) {
		if (calculate_aspect == false) {
			this.calculateAspectRatio();
		}
		this.loadImage(state, callback);
	}

	setUserAnnotations(annotations = []) {
		this.user_annotations = annotations;
		this.toggleObjectSelection(true);
	}

	setCornerStyles() {
		// Change controls colors for all shapes
		window.fabric.Object.prototype.set({
			borderColor: 'red', // Change the border color of the shape
			cornerColor: 'blue', // Change the color of the corner controls
			cornerSize: 8, // Adjust the size of the corner controls (optional)
			transparentCorners: false, // Set to true if you want the corner controls to be transparent when not hovered (optional)
			padding: 5, // Set to adjust the padding around the object for easier clicking on controls (optional)
		});
	}


	calculateAspectRatio() {

		let width = window.innerWidth;
		let height = window.innerHeight - CollaborateConstants.HEIGHT_OFFSET;
		if (this.class_name != "canvas-editor") {
			let width_perc = CollaborateConstants.WIDTH_PERC_PRODUCT_QA * width;
			width = window.innerWidth - width_perc;
			height = window.innerHeight - CollaborateConstants.HEIGHT_OFFSET_PRODUCT_QA;
			if ((this.access_level != "owner" && this.access_level != "co-owner") || (this.project_id && this.access_level != "edit")) {
				height = window.innerHeight - CollaborateConstants.HEIGHT_OFFSET_PRODUCT_QA_EDITOR;
			}
		}
		if (width / height >= CollaborateConstants.ASPECT_RATIO) {
			this.canvasHeight = height;
			this.canvasWidth = height * CollaborateConstants.ASPECT_RATIO;
		}
		else {
			this.canvasWidth = width;
			this.canvasHeight = this.canvasWidth * CollaborateConstants.ASPECT_RATIO_MULTIPLIER;
		}
	}


	toggleAnnotateDisplay(display_value) {
		let annotate_modal = document.getElementById("annotate-modal");
		if (annotate_modal) {
			annotate_modal.style.display = display_value;
			setTimeout(() => {
				let comment_container = document.querySelector('.comment-container');
				if (comment_container) {
					comment_container.scrollTop = comment_container.scrollHeight;
				}
			})
		}
	}


	enableAction(main_action, secondary_action = "", color = "#EE4B2B", pen_thickness = "thin") {
		switch (main_action) {
			case "select":
				this.disableAllActions();
				break;
			case "annotate":
				this.enableAnnotation();
				break;
			case "text":
				this.enableTextMode(color);
				break;
			case "shape":
				this.enableShapeMode(secondary_action, color);
				break;
			case "draw":
				this.enableDrawMode(secondary_action, color, pen_thickness);
				break;
			case "color":
				this.enableColorMode(color);
				break;
			case "erase":
				this.eraseMode();
				break;
			case "pan":
				this.panMode();
				break;
			case "zoom_out":
				this.zoomOutMode();
				break;
			case "zoom_in":
				this.zoomInMode()
				break;
			default:
				this.disableAllActions();
				break;
		}
	}


	disableAllActions() {
		this.annotate_mode = false;
		this.change_color_mode = false;
		this.add_text_mode = false;
		this.add_shape_mode = false;
		this.canvas.isDrawingMode = false;
		this.drawing_mode = false;
		this.change_pen_size_mode = false;
		this.eraser_mode = false;
		this.is_panning = false;
		this.zoom_out_mode = false;
		this.zoom_in_mode = false;
		if (this.annotation_loading == false) {
			this.toggleAnnotateDisplay("none");
		}
	}


	enableAnnotation() {
		this.toggleObjectSelection(true);
		this.disableAllActions();
		this.annotate_mode = true;
		this.toggleAnnotateDisplay("none");
	}

	enableColorMode(color) {
		this.toggleObjectSelection(true);
		this.disableAllActions();
		this.change_color_mode = true;
		this.changeColor(color);
		this.toggleAnnotateDisplay("none");
	}


	enableTextMode(color) {
		this.toggleObjectSelection(true);
		this.disableAllActions();
		this.add_text_mode = true;
		this.toggleAnnotateDisplay("none");
		this.addText(color);
	}


	enableShapeMode(secondary_action, color) {
		this.toggleObjectSelection(true);
		this.disableAllActions();
		this.add_shape_mode = true;
		this.toggleAnnotateDisplay("none");
		this.addShape(secondary_action, color);
	}


	enableDrawMode(secondary_action, color, pen_thickness) {
		this.toggleObjectSelection(true);
		this.disableAllActions();
		this.canvas.isDrawingMode = true;
		this.drawing_mode = true;
		this.toggleAnnotateDisplay("none");
		this.penDraw(color);
		switch (secondary_action) {
			case "pen_thickness":
				this.change_pen_size_mode = true;
				this.changePenSize(pen_thickness)
				break;
			default:
				break;
		}
	}


	eraseMode() {
		this.disableAllActions();
		this.eraser_mode = true;
		this.toggleObjectSelection(true);
		this.toggleAnnotateDisplay("none");
	}


	panMode() {
		this.disableAllActions();
		this.is_panning = true;
		this.toggleObjectSelection(false);
		this.toggleAnnotateDisplay("none");
	}


	zoomOutMode() {
		this.disableAllActions();
		this.zoom_out_mode = true;
		this.toggleObjectSelection(false);
		this.toggleAnnotateDisplay("none");
		this.zoomOutAction();
	}


	zoomInMode() {
		this.disableAllActions();
		this.zoom_in_mode = true;
		this.toggleObjectSelection(false);
		this.toggleAnnotateDisplay("none");
		this.zoomInAction();
	}


	enableAnnotationLoading() {
		this.annotation_loading = true;
	}


	disableAnnotationLoading() {
		this.annotation_loading = false;
	}


	zoomOutAction() {
		let zoom_out = this.canvas.getZoom();
		zoom_out = (zoom_out / CollaborateConstants.ZOOM_FACTOR);
		// minimum zoom allowed
		if (zoom_out < CollaborateConstants.MIN_ZOOM) {
			zoom_out = CollaborateConstants.MIN_ZOOM;
		}
		this.canvas.zoomToPoint({ x: this.canvas.width / 2, y: this.canvas.height / 2 }, zoom_out);
	}


	zoomInAction() {
		let zoom_in = this.canvas.getZoom();
		zoom_in = (zoom_in * CollaborateConstants.ZOOM_FACTOR);
		// maximum zoom allowed
		if (zoom_in > CollaborateConstants.MAX_ZOOM) {
			zoom_in = CollaborateConstants.MAX_ZOOM;
		}
		this.canvas.zoomToPoint({ x: this.canvas.width / 2, y: this.canvas.height / 2 }, zoom_in);
	}


	penDraw(color, size = 5) {
		this.canvas.freeDrawingBrush = new window.fabric.PencilBrush(this.canvas);
		this.canvas.freeDrawingBrush.width = size;
		this.canvas.freeDrawingBrush.color = color;
	}


	changePenSize(thickness) {
		let size = CollaborateConstants.THIN;
		switch (thickness) {
			case "thin":
				size = CollaborateConstants.THIN;
				break;
			case "medium":
				size = CollaborateConstants.MEDIUM;
				break;
			case "thick":
				size = CollaborateConstants.THICK;
				break;
			default:
				size = CollaborateConstants.THIN;
				break;
		}
		this.canvas.freeDrawingBrush.width = size;
	}

	changeImage(url, state = null, callback = null) {
		this.image_url = url;
		this.loadImage(state, callback);
	}


	loadImage(state = null, callback = null) {
		// Load the image onto the canvas
		let global_scope = this;
		this.canvasImg = new window.fabric.Image.fromURL(global_scope.image_url, (img) => {
			function resizeImage() {

				var maxWidth = global_scope.canvasWidth;
				var maxHeight = global_scope.canvasHeight;

				// Calculate the scale factors
				var widthScale = maxWidth / img.width;
				var heightScale = maxHeight / img.height;
				var scale = Math.min(widthScale, heightScale);

				img.scaleToWidth(img.width * scale);
				img.scaleToHeight(img.height * scale);

				// Calculate the centered position for the image
				var imgLeft = (maxWidth - (img.width * scale)) / 2;
				var imgTop = (maxHeight - (img.height * scale)) / 2;
				img.set({ left: imgLeft, top: imgTop });

				global_scope.canvas.renderAll();
			}

			resizeImage();

			// Add an event listener for the "resize" event on the window object
			window.addEventListener("resize", resizeImage);

			global_scope.canvas.setDimensions({ width: global_scope.canvasWidth, height: global_scope.canvasHeight });
			global_scope.canvas.setBackgroundImage(img, () => {
				global_scope.canvas.renderAll();
				global_scope.loading_state();
				if (state != null) {
					global_scope.repopulateSavedProgress(state, callback);
				} else if (callback != null) {
					callback();
				}
			});

		});
	}


	annotateCanvas(event) {
		const pointer = this.canvas.getPointer(event.e);
		const x = pointer.x;
		const y = pointer.y;
		this.annotation_create_dot = true;;
		this.createHotspot(x, y);
	}


	// Remove prev hotspot if its not saved
	removePrevHotspot() {
		if (this.annotate_group) {
			this.annotation_create_dot = true;

			var group = this.annotate_group
			this.object_modfied_flag = false;
			// Get an array of all objects within the group
			var objects = group.getObjects();

			// Remove each object from the group
			objects.forEach((object) => {
				group.removeWithUpdate(object);
			});
			this.canvas.remove(group);
			this.canvas.renderAll();
		}
	}


	clearGroup() {
		this.annotate_group = '';
	}


	// Create hotspot mark
	createHotspot(x, y) {
		this.removePrevHotspot();
		// this.object_modfied_flag = false;
		this.toggleAnnotateDisplay("none");

		let hotspot = new window.fabric.Circle({
			left: x,
			top: y,
			radius: 15,
			fill: 'white',
			stroke: '#D93025',
		});

		let text_value = "";

		// Create the text object
		let text = new window.fabric.Text(text_value, {
			left: x + 15,
			top: y + 16.5,
			fill: '#D93025',
			fontSize: 14,
			textAlign: 'center',
			originX: 'center',
			originY: 'center',
		});
		this.object_modfied_flag = false;
		// Group the shape and text objects together
		this.annotate_group = new window.fabric.Group([hotspot, text], {
			selectable: true
		});
		// this.object_modfied_flag = false;
		
		this.canvas.add(this.annotate_group); // Add the group to the canvas
		// this.object_modfied_flag = false;
		this.canvas.renderAll();

		this.canvas_changed = false;
		this.toggleAnnotateDisplay("block");
		this.adjustPositioningofAnnotate(this.annotate_group);
		window.parent.postMessage({ clear_comment: true }, '*');
	}


	// Adjust positioning of annotate modal
	adjustPositioningofAnnotate(obj, annotation_id = -1, is_resolved = false) {
		// Get references to annotation modal and comment history popup
		let annotate_modal = document.getElementById("annotate-modal");

		// Get the current window width
		let window_width = window.innerWidth;
		// Get the current window height
		let window_height = window.innerHeight - (CollaborateConstants.SAFE_ZONE_HEIGHT_OFFSET);

		if (this.class_name != "canvas-editor") {
			let width_perc = 0.38 * window_width;
			window_width = window.innerWidth - width_perc;
			window_height = window.innerHeight - CollaborateConstants.SAFE_ZONE_HEIGHT_OFFSET_PRODUCT_QA;
			if ((this.access_level != "owner" && this.access_level != "co-owner") || (this.project_id && this.access_level != "edit")) {
				window_height = window.innerHeight - CollaborateConstants.SAFE_ZONE_HEIGHT_OFFSET_PRODUCT_QA_EDITOR;
			}
		}

		// Set default annotation container width percentage
		let annotate_container_width_perc = CollaborateConstants.ANNOTATE_WIDTH_PERC; // some % of browser width

		// Set default annotation container height percentage
		let annotation_container_height_perc = CollaborateConstants.ANNOTATE_HEIGHT_PERC; // some % of browser height

		// // Adjust annotation container height based on annotation state (whether resolved or not)
		// if (annotation_id != -1 && !is_resolved) {
		// 	annotation_container_height_perc = CollaborateConstants.ANNOTATE_W_COMMENTS_HEIGHT_PERC; // some % of browser height
		// } else if (annotation_id != -1 && is_resolved) {
		// 	annotation_container_height_perc = CollaborateConstants.ANNOTATE_RESOLVED_HEIGHT_PERC; // some % of browser height
		// }

		// Get the current window height
		// let window_height = window.innerHeight - (CollaborateConstants.SAFE_ZONE_HEIGHT_OFFSET);

		// Check if the annotate modal element exists
		if (annotate_modal) {
			var boundingRect = obj.getBoundingRect(); // get bounding box of canvas container
			let bounding_left = boundingRect.left;
			let bounding_top = boundingRect.top;
			var canvasElement = this.canvas.getElement();
			var canvasRect = canvasElement.getBoundingClientRect(); // get canvas bounding box
			var canvasLeft = canvasRect.left; // left value w.r.t canvas
			var canvasTop = canvasRect.top; // top value w.r.t canvas
			if (this.class_name != "canvas-editor") {
				canvasLeft = 0;
				canvasTop = 0;
			}
			let new_x = canvasLeft + bounding_left; // actual left vaue from the browser's left for css
			let new_y = canvasTop + bounding_top; // actual top vaue from the browser's top for css
			// Set the top and left position attributes of the annotate modal
			annotate_modal.style.top = (CollaborateConstants.ANNOTATE_TOP_OFFSET + new_y) + "px";
			annotate_modal.style.left = (CollaborateConstants.ANNOTATE_LEFT_OFFSET + new_x) + "px";

			annotation_container_height_perc = annotate_modal.offsetHeight/window_height;

			if (annotation_container_height_perc > CollaborateConstants.ANNOTATE_W_COMMENTS_HEIGHT_PERC) {
				annotation_container_height_perc = CollaborateConstants.ANNOTATE_W_COMMENTS_HEIGHT_PERC;
			}
			
			// Calculate the width of the annotation container and safe zone width
			let annotation_container_width = annotate_container_width_perc * window_width; // some % of browser width
			let safe_zone_width = window_width - annotation_container_width;

			// if value of left is greater than the safe zone width, then readjust left position
			// to keep the modal within the safe zone
			if (new_x >= safe_zone_width) {
				annotate_modal.style.left = (new_x - annotation_container_width) + "px";
			}

			// Adjust the left position further if the modal extends beyond the right edge of the window
			if (new_x >= window_width) {
				let difference = new_x - window_width;
				annotate_modal.style.left = (new_x - annotation_container_width - difference - 40) + "px";
			}

			// Calculate the height of the annotation container and safe zone height
			let annotation_container_height = annotation_container_height_perc * window_height;
			let safe_zone_height = window_height - annotation_container_height;

			// if value of top is greater than the safe zone height, then readjust top position
			// to keep the modal within the safe zone
			if (new_y >= safe_zone_height) {
				annotate_modal.style.top = (new_y - annotation_container_height) + "px";
			}
		}
	}


	// Set hotspot sequence_no and annotation id when its generated from backend
	setHotspotIDs(annotation_id, sequence_no) {
		this.annotation_create_dot = false;
		this.annotate_group.annotation_id = annotation_id;
		this.annotate_group.sequence_no = sequence_no;
		this.annotate_group.is_resolved = false;
		this.addAnnotationIDsinGroupObject(annotation_id, sequence_no, false);
		this.setTextValueInGroupWithCustomID(annotation_id, sequence_no);
	}


	addAnnotationIDsinGroupObject(annotation_id = -1, sequence_no = -1, is_resolved = false, action = "set_annotation_id") {
		let global_scope = this;
		// ** Extend toObject() method of fabric.Group prototype to include annotation_id 
		// and sequence_no in the final JSON being saved
		// ** If this is not done, then fabric js discards 
		// our custom set attributes i.e., annotation_id and sequence_no
		window.fabric.Group.prototype.toObject = (function (toObject) {
			return function (propertiesToInclude) {
				var object = toObject.call(this, propertiesToInclude);
				// Add new annotation id and sequence_no for new groups
				if (this === global_scope.annotate_group && action == "set_annotation_id") {
					if (typeof object.annotation_id === 'undefined') {
						object.annotation_id = annotation_id;
					}

					if (typeof object.sequence_no === 'undefined') {
						object.sequence_no = sequence_no;
					}

					if (typeof object.is_resolved === 'undefined') {
						object.is_resolved = is_resolved;
					}

				} else { // add old annotation ids for older groups
					if (this.annotation_id) {
						object.annotation_id = this.annotation_id;
					}
					if (this.sequence_no) {
						object.sequence_no = this.sequence_no;
					}

					// checking for undefined because this is boolean
					// the other attributes can be checked directly
					if (this.is_resolved !== undefined) {
						object.is_resolved = this.is_resolved;
					}
				}
				return object;
			};
		})(window.fabric.Group.prototype.toObject);
	}


	// Get annotation group object through annotation id
	getGroupByAnnotationId(annotationId) {
		const allGroups = this.canvas.getObjects().filter((object) => object.type === 'group');
		const group = allGroups.find((group) => group.annotation_id === annotationId);
		return group;
	}

	setGroupPosition(annotation_id, is_resolved) {
		var group = this.getGroupByAnnotationId(annotation_id);
		if (group) {
			var x = group.left;
			var y = group.top;
			this.adjustPositioningofAnnotate(group, annotation_id, is_resolved);
		}
	}

	setGroupResolvedState(annotation_id) {
		var group = this.getGroupByAnnotationId(annotation_id);
		if (group) {
			group.is_resolved = true;
		}
	}


	// remove group object from canvas
	removeGroup(annotation_id) {
		let group = this.getGroupByAnnotationId(annotation_id);
		if (group) {
			var objects = group.getObjects();

			// Remove each object from the group
			objects.forEach((object) => {
				group.removeWithUpdate(object);
			});

			/// Remove the group from the canvas
			this.canvas.remove(group);
		}
	}


	// Add sequence no in hotspot mark
	setTextValueInGroupWithCustomID(annotation_id, sequence_no) {
		var objects = this.canvas.getObjects();
		for (var i = 0; i < objects.length; i++) {
			var object = objects[i];
			if (object.type === 'group' && object.get('annotation_id') === annotation_id) {
				// Group with matching custom ID found
				var subObjects = object.getObjects();

				for (var j = 0; j < subObjects.length; j++) {
					var subObject = subObjects[j];
					if (subObject.type === 'group') {
						// Recursive call to handle nested groups
						this.setTextValueInGroupWithCustomID(annotation_id, sequence_no);
					} else if (subObject.type === 'text') {
						// Text object found, set the new text value
						subObject.set('text', sequence_no.toString());
						this.canvas.renderAll(); // Render canvas to update the changes
					}
				}
				break;
			}
		}
	}

	// Change hotspot color to according to resolved/unresolved/tagged state
	changeHotspotColor(annotations, color_change_type = "basic", annotation_id = -1) {
		var objects = this.canvas.getObjects();
		let all_annotations = annotations;
		if (annotation_id != -1) {
			all_annotations.filter(id => annotation_id == id);
		}
		all_annotations.map((id) => {
			for (var i = 0; i < objects.length; i++) {
				var object = objects[i];
				if (object.type === 'group' && object.get('annotation_id') === id) {
					let is_resolved = object.get('is_resolved') == true ? "_resolved" : "_unresolved";
					let fill_color = CollaborateConstants.HOTSPOT_COLOR[color_change_type + is_resolved]["fill"];
					let stroke_color = CollaborateConstants.HOTSPOT_COLOR[color_change_type + is_resolved]["stroke"];
					let text_fill = CollaborateConstants.HOTSPOT_COLOR[color_change_type + is_resolved]["text"]["fill"];

					// Group with matching custom ID found
					var subObjects = object.getObjects();
					for (var j = 0; j < subObjects.length; j++) {
						var subObject = subObjects[j];
						if (subObject.type === 'circle') {
							// Circle object found, set the new fill value
							subObject.set('fill', fill_color);
							subObject.set('stroke', stroke_color);
							this.canvas.renderAll(); // Render canvas to update the changes
						} else if (subObject.type === 'text') {
							// Text object found, set the new fill value
							subObject.set('fill', text_fill);
							this.canvas.renderAll(); // Render canvas to update the changes
						}
					}
					break;
				}
			}
		})
	}


	canvasMouseDown(event) {
		// If annotation mode is selected and annotation is not loading, allow annotation
		if (this.annotate_mode && !this.annotation_loading) {
			this.annotateCanvas(event);
		}

		var target = event.target;
		var clickedGroup = null;

		// Iterate through objects to find the clicked group underneath
		var allObjects = this.canvas.getObjects();
		for (var i = allObjects.length - 1; i >= 0; i--) {
			var object = allObjects[i];

			// Check if the object is a group
			if (object.type === 'group') {
				var groupBoundingBox = object.getBoundingRect();
				var pointer = this.canvas.getPointer(event.e);

				// Check if the pointer is within the group's bounding box
				if (pointer.x >= groupBoundingBox.left &&
					pointer.x <= groupBoundingBox.left + groupBoundingBox.width &&
					pointer.y >= groupBoundingBox.top &&
					pointer.y <= groupBoundingBox.top + groupBoundingBox.height) {
					clickedGroup = object;
					break; // Exit the loop once a group is found
				}
			}
		}

		if (clickedGroup && !this.is_panning && !this.zoom_in_mode && !this.zoom_out_mode && !this.annotate_mode) {
			// You've found the group that's clicked underneath
			// Perform actions for the clicked group here
			var annotation_id = clickedGroup.get('annotation_id');
			var sequence_no = clickedGroup.get('sequence_no');
			var is_resolved = clickedGroup.get('is_resolved');

			if (annotation_id && sequence_no) {
				const pointer = this.canvas.getPointer(event.e);
				const x = pointer.x;
				const y = pointer.y;
				// this.adjustPositioningofAnnotate(clickedGroup, annotation_id, is_resolved);

				// Post message to react side
				window.parent.postMessage({ annotation_id: annotation_id, sequence_no: sequence_no }, '*');
				// this.adjustPositioningofAnnotate(clickedGroup, annotation_id, is_resolved);
			}
		} else if (target && target.type === 'i-text' && this.access_level === "edit") {
			this.active_text = target;
		} else if (this.active_text != null && this.access_level === "edit") {
			this.active_text.exitEditing();
			this.active_text = null;
		} else if (this.zoom_out_mode) {
			this.zoomOutAction();
		} else if (this.zoom_in_mode) {
			this.zoomInAction();
		} else if (this.is_panning) {
			this.allow_canvas_dragging = true;
			this.toggleAnnotateDisplay("none");

			// Store the clicked position for panning
			var pointer = this.canvas.getPointer(event.e);
			this.lastPosX = pointer.x;
			this.lastPosY = pointer.y;
		} else if (!target) {
			if (!this.annotate_mode && !this.annotation_loading) {
				this.toggleAnnotateDisplay("none");
			}
		}

		if (this.eraser_mode) {
			this.deleteObject();
		}
	}



	// Triggered on mouse move
	canvasMouseMove(event) {
		if (this.is_panning && this.allow_canvas_dragging) {
			// Gets the clicked position
			var pointer = this.canvas.getPointer(event.e);

			// Calculation of delta in x and y coordinates
			// of the mouse pointer since the last recorded position
			var deltaX = pointer.x - this.lastPosX;
			var deltaY = pointer.y - this.lastPosY;

			// Relative pan shifts the canvas content by 
			// a specified delta in x and y coordinates. 
			this.canvas.relativePan({ x: deltaX, y: deltaY });

			// Update to the current location
			// Helps in calculating change during NEXT mouse movement
			this.lastPosX = pointer.x;
			this.lastPosY = pointer.y;
		}
	}


	// Triggered on mouse wheel
	canvasMouseWheel(opt) {
		// Prevent default wheel behavior (i.e., scrolling the page)
		opt.preventDefault();
		opt.stopPropagation();
		const upperCanvasElement = this.canvas.upperCanvasEl;

		// Check if the mouse pointer is over the canvas and if the wheel event 
		// occurred on the upper canvas element
		if (this.isMouseOverCanvas(opt, this) && (upperCanvasElement == opt.target)) {
			// Determine the amount of zoom based on the wheel event (different for different browsers)
			var delta = 0;
			var wheelDelta = opt.wheelDelta;
			let deltaY = opt.deltaY;

			// CHROME WIN/MAC | SAFARI 7 MAC | OPERA WIN/MAC | EDGE
			if (wheelDelta) {
				delta = -wheelDelta / 120;
			}

			// FIREFOX WIN / MAC | IE
			if (deltaY) {
				deltaY > 0 ? delta = 1 : delta = -1;
			}

			// Calculate the new zoom level by adjusting the current zoom based on delta
			let zoom = this.canvas.getZoom() - delta / 10;
			let origZoom = this.canvas.getZoom();

			// Limit zoomin to 5
			if (zoom > CollaborateConstants.MAX_ZOOM) {
				zoom = CollaborateConstants.MAX_ZOOM;
			}

			// Limit zoom out
			//  Reset canvas position if zoomed out completely
			if (zoom < CollaborateConstants.MIN_ZOOM) {
				zoom = CollaborateConstants.MIN_ZOOM;
				this.resetImgPosition(this.canvas, origZoom);
			}

			// Zoom the canvas around the specified point i.e.,
			// where the cursor is
			this.canvas.zoomToPoint({
				x: opt.offsetX,
				y: opt.offsetY
			}, zoom);

			// Prevent default wheel behavior (i.e., scrolling the page)
			opt.preventDefault();
			opt.stopPropagation();

			// Render and update canvas offset
			this.canvas.renderAll();
			this.canvas.calcOffset();
		}
	}


	// Helper function to check if the mouse pointer is over the canvas or not
	isMouseOverCanvas(event, global_scope) {
		// Get the canvas bounding rectangle
		var canvasRect = global_scope.canvas.getElement().getBoundingClientRect();

		// Calculate the mouse coordinates relative to the canvas
		var mouseX = event.clientX - canvasRect.left;
		var mouseY = event.clientY - canvasRect.top;

		// Check if the mouse is within the canvas boundaries
		return mouseX >= 0 && mouseX < global_scope.canvas.width && mouseY >= 0 && mouseY < global_scope.canvas.height;
	}


	resetImgPosition(position, original_zoom_value) {
		let panX = 0;
		let panY = 0;

		// Get original zoom value and viewport transform
		let origZoom = original_zoom_value;
		let origViewportTransform = this.canvas.viewportTransform;

		// Animate the canvas pan to the new position
		// Checks if the fourth and fifth elements of the origViewportTransform array are not NaN and not equal to zero. 
		// This condition ensures that the canvas is already in a non-default position.
		// 4th and 5th elements are Horizontal (X offset) and Vertical (Y offset) translations
		if ((!isNaN(origViewportTransform[4]) && !isNaN(origViewportTransform[5])) &&
			(origViewportTransform[4] != 0 && origViewportTransform[5] != 0)) {

			// Animate the x-axis canvas pan
			window.fabric.util.animate({
				startValue: origViewportTransform[4],
				endValue: panX,
				duration: 100,
				easing: window.fabric.util.ease.easeOutElastic,
				onChange: (value) => {
					this.canvas.setViewportTransform([origZoom, 0, 0, origZoom, value, origViewportTransform[5]]);
					this.canvas.renderAll();
				}
			});

			// Animate Y-axis pan
			window.fabric.util.animate({
				startValue: origViewportTransform[5],
				endValue: panY,
				duration: 100,
				easing: window.fabric.util.ease.easeOutElastic,
				onChange: (value) => {
					this.canvas.setViewportTransform([origZoom, 0, 0, origZoom, this.canvas.viewportTransform[4], value]);
					this.canvas.renderAll();
				}
			});
		}
	}


	// Change selectable property of objects in canvas
	toggleObjectSelection(enableSelection) {
		// Iterate through all objects on the canvas
		// and update their selectable property
		this.canvas.forEachObject((obj) => {
			if (enableSelection) {
				obj.selectable = !this.user_annotations.includes(obj.annotation_id)
			} else {
				obj.selectable = enableSelection;
			}
		});

		// Reset annotation_id and sequence_no in hotspots
		// as fabricjs discards them when updating any attribute of the object
		this.addAnnotationIDsinGroupObject(-1, -1, false, "reset_annotation_id");
		this.canvas.renderAll();
	}


	// Change selectable property of objects in canvas
	changeObjectVisibility(annotations) {
		// Iterate through all objects on the canvas
		// and update their visible property
		this.canvas.forEachObject((obj) => {
			if (obj.type == "group") {
				if (annotations.includes(obj.annotation_id)) {
					obj.visible = true
					
				} else {
					obj.visible = false;
				}
			}
		});

		// Reset annotation_id and sequence_no in hotspots
		// as fabricjs discards them when updating any attribute of the object
		this.addAnnotationIDsinGroupObject(-1, -1, false, "reset_annotation_id");
		this.canvas.renderAll();
	}

	// Setup evenet listeners
	setUpEventListeners() {
		let global_scope = this;

		// Store references to the event handler functions
		this.mouseDownHandler = function (event) {
			global_scope.canvasMouseDown(event);
		};

		this.objectModified = function (event) {
			if (global_scope.object_modfied_flag) {
				console.log('Object Modified:', event.target);
			} else {
				console.log("No change on canvas");
				// Reset the flag for the next event
				global_scope.object_modfied_flag = true;
			}
		};

		this.objectAdded = function (event) {
			if (global_scope.object_modfied_flag) {
				console.log('Object Added:', event.target);
			} else {
				console.log("No object added on canvas");
				// Reset the flag for the next event
				global_scope.object_modfied_flag = true;
			}
			
		};

		this.objectRemoved = function (event) {
			if (global_scope.object_modfied_flag) {
				console.log('Object Removed:', event.target);
			} else {
				console.log("No object removed from canvas");
				// Reset the flag for the next event
				global_scope.object_modfied_flag = true;
			}
		};

		this.mouseMoveHandler = function (event) {
			global_scope.canvasMouseMove(event);
		};

		this.mouseUpHandler = function (event) {
			global_scope.allow_canvas_dragging = false;
		};

		this.mouseWheelHandler = function (event) {
			global_scope.canvasMouseWheel(event);
		};

		this.keydownHandler = function (event) {
			// KEY CODE FOR 'DELETE'
			if ((event.keyCode == 46 || event.keyCode == 8) && global_scope.access_level == "edit") {
				global_scope.deleteObject();
			}
		};

		// Add event listeners with the stored references
		this.canvas.on('mouse:down', this.mouseDownHandler);
		this.canvas.on('mouse:move', this.mouseMoveHandler);
		this.canvas.on('mouse:up', this.mouseUpHandler);
		this.canvas.on('object:added', this.objectAdded);
		this.canvas.on('object:modified', this.objectModified);
		this.canvas.on('object:removed', this.objectRemoved);
		document.addEventListener("mousewheel", this.mouseWheelHandler, false);
		document.addEventListener("keydown", this.keydownHandler, false);
	}

	// Remove event listeners
	removeEventListeners() {
		this.canvas.off('mouse:down', this.mouseDownHandler);
		this.canvas.off('mouse:move', this.mouseMoveHandler);
		this.canvas.off('mouse:up', this.mouseUpHandler);
		this.canvas.off('object:added', this.objectAdded);
		this.canvas.off('object:modified', this.objectModified);
		this.canvas.off('object:removed', this.objectRemoved);
		document.removeEventListener("mousewheel", this.mouseWheelHandler, false);
		document.removeEventListener("keydown", this.keydownHandler, false);
	}

	isCanvasModified() {
		return this.object_modfied_flag;
	}

	// Add text on canvas and change its color if passed
	addText(color = "#EE4B2B") {
		let centerX = this.canvas.getWidth() / 2;
		let centerY = this.canvas.getHeight() / 2;
		var text_object = new window.fabric.IText("Add text", {
			left: centerX,
			top: centerY,
			fontFamily: 'Calibri',
			fontSize: 28,
			fill: color,
			editable: true,
			hasControls: true,
			hasBorders: true,
		});

		this.canvas.add(text_object);
		this.active_text = text_object;

		this.canvas.setActiveObject(text_object);
		text_object.enterEditing();
		this.canvas.renderAll();
	}


	resetCanvasChangedState() {
		this.canvas_changed = false;
	}

	getCanvasChangedValue() {
		return this.canvas_changed;
	}


	// Delete active or selected object
	deleteObject() {
		var activeGroup = this.canvas.getActiveGroup();
		if (activeGroup) {
			var activeObjects = activeGroup.getObjects();
			for (let i in activeObjects) {
				this.canvas.remove(activeObjects[i]);
			}
			this.canvas.discardActiveGroup();
			this.canvas.renderAll();
		} else {
			if (this.canvas.getActiveObject() != undefined) {
				this.canvas.getActiveObject().remove();
				this.canvas.renderAll();
			}
		}
	}


	// Function to add shapes on canvas
	addShape(shape_type, color = "#EE4B2B") {
		var shape = "";
		// Calculate the center coordinates of the canvas
		let centerX = this.canvas.getWidth() / 2;
		let centerY = this.canvas.getHeight() / 2;
		switch (shape_type) {
			case 'rectangle':
				shape = new window.fabric.Rect({
					left: centerX,
					top: centerY,
					width: 100,
					height: 100,
					fill: 'transparent',
					stroke: color,
					strokeWidth: 2,
					draggable: true,
					cornerHoverCursor: 'move'
				});
				break;

			case 'circle':
				shape = new window.fabric.Circle({
					left: centerX,
					top: centerY,
					radius: 50,
					fill: 'transparent',
					stroke: color,
					strokeWidth: 2,
					draggable: true,
					cornerHoverCursor: 'move'
				});
				break;

			case 'triangle':
				shape = new window.fabric.Triangle({
					left: centerX,
					top: centerY,
					width: 100,
					height: 100,
					fill: 'transparent',
					stroke: color,
					strokeWidth: 2,
					draggable: true,
					cornerHoverCursor: 'move'
				});
				break;
			default:
				break;
		}

		if (shape) {
			this.canvas.add(shape);
			this.canvas.renderAll();
		}
	}


	// Function to change the color of the selected object type
	changeColor(newColor) {
		let activeObject = this.canvas.getActiveObject();
		if (activeObject && activeObject.type === 'triangle') {
			activeObject.set('stroke', newColor);
			this.canvas.renderAll();
		} else if (activeObject && activeObject.type === 'circle') {
			activeObject.set('stroke', newColor);
			this.canvas.renderAll();
		} else if (activeObject && activeObject.type === 'rect') {
			activeObject.set('stroke', newColor);
			this.canvas.renderAll();
		} else if (activeObject && activeObject.type === 'i-text') {
			activeObject.set("fill", newColor);
			this.canvas.renderAll();
		} else if (activeObject && activeObject.type === 'group') {
			var textObject = activeObject.getObjects().find(obj => obj.type === 'i-text');
			if (textObject) {
				textObject.set("fill", newColor);
				this.canvas.renderAll();
			}
		} else if (activeObject != null && activeObject.type == "path") {
			activeObject.set('stroke', newColor);
			this.canvas.renderAll();
		}
	}


	// Convert canvas data to json for saving
	convertToJSON() {
		// Create an object to store canvas values
		this.canvas_data = {
			objects: this.canvas.toJSON().objects,
			backgroundImage: this.canvas.backgroundImage,
			canvas_width: this.canvasWidth,
			canvas_height: this.canvasHeight
		};

		return this.canvas_data;
	}

	clearFabricCanvas() {
		this.removeEventListeners();
		this.canvas.dispose();
		this.canvas.clear();
	}

	clearCanvasData() {
		this.canvas.clear();
	}

	// Restore old progress
	repopulateSavedProgress(state, callback = null) {
		// this.clearCanvasData()
		this.canvas_data = state;
		this.calculateAspectRatio();
		let original_width = this.canvas_data.canvas_width;
		let original_height = this.canvas_data.canvas_height;

		// Calculate scaling factors
		var scaleX = this.canvasWidth / original_width;
		var scaleY = this.canvasHeight / original_height;

		// Clear existing objects on the canvas
		this.canvas.clear();

		// Load and scale objects
		let global_scope = this;
		let enlivenPromises = [];

		this.canvas_data.objects.forEach(objData => {
			// Create a promise for each object
			let enlivenPromise = new Promise(resolve => {
				if (objData.type === 'group') {
					// Handle group-type object
					window.fabric.util.enlivenObjects(objData.objects, enlivenedObjects => {
						const group = new window.fabric.Group(enlivenedObjects, {
							// Set group options
							scaleX: objData.scaleX * scaleX,
							scaleY: objData.scaleY * scaleY,
							left: objData.left * scaleX,
							top: objData.top * scaleY,
							// width: objData.width * scaleX,
							// height: objData.height * scaleY,
							annotation_id: objData.annotation_id,
							sequence_no: objData.sequence_no,
							is_resolved: objData.is_resolved
						});
						global_scope.canvas.add(group);
						resolve();
					});
				} else {
					// Commenting this because drawings are not getting scaled properly
					window.fabric.util.enlivenObjects([objData], ([obj]) => {
						// obj.height = objData.height * scaleY;
						// obj.width = objData.width * scaleX;
						obj.scaleX = objData.scaleX * scaleX;
						obj.scaleY = objData.scaleY * scaleY;
						obj.left = objData.left * scaleX;
						obj.top = objData.top * scaleY;
						
						global_scope.canvas.add(obj);
						resolve(); // Resolve the promise once the object is enlivened and added to the canvas
					});
				}
			});

			enlivenPromises.push(enlivenPromise);
		});

		// Wait for all promises to be resolved before rendering the canvas
		Promise.all(enlivenPromises).then(() => {
			global_scope.canvas.renderAll();
			if (callback != null) {
				callback();
			}
		});
	}


	async constructPDF(imageUrl, canvasData, backgroundColor, context, canvas, pdf) {
		const fetchImageAsBlob = async (url) => {
			try {
				const response = await fetch(url, { mode: 'cors' });
				console.log(response);
				if (!response.ok) {
					throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
				}
				return response.blob();
			} catch (error) {
				console.log(error)
				throw new Error(`Failed to fetch image: ${error.message}`);
			}
		};
		const resize = async (img) => {
			// Create a canvas element to manipulate    
			// Setup some resizing definitions
			let { width, height } = img;
			const maxWidth = context.canvas.width;
			const maxHeight = context.canvas.height;
			const widthScale = maxWidth / width;
			const heightScale = maxHeight / height;
			const scale = Math.min(widthScale, heightScale);
			img.width *= scale;
			img.height *= scale;
			const imgLeft = (maxWidth - img.width) / 2;
			const imgTop = (maxHeight - img.height) / 2;
	
			context.drawImage(img, imgLeft, imgTop, img.width, img.height);  // Place the image on the canvas
			const data = context.getImageData(0, 0, maxWidth, maxHeight);  // Get the current ImageData for the canvas
			const compositeOperation = context.globalCompositeOperation;  // Store the current globalCompositeOperation            
			context.globalCompositeOperation = 'destination-over';  // Set to draw behind current content
			context.fillStyle = backgroundColor;  // Set background color
			context.fillRect(0, 0, maxWidth, maxHeight);  // Draw background/rect on the entire canvas
			context.globalCompositeOperation = compositeOperation;  // Reset the globalCompositeOperation to what it was
	
			let state = canvasData.state['data'];
			let group_objects = canvasData.group_objects;
			let original_width = state.canvas_width;
			let original_height = state.canvas_height;
	
			// Calculate scaling factors
			const scaleX = context.canvas.width / original_width;
			const scaleY = context.canvas.height / original_height;
	
			// Load and scale objects
			state.objects.forEach(objData => {
				window.fabric.util.enlivenObjects([objData], ([obj]) => {
					if (obj.type === "group") {
						group_objects = canvasData.group_objects.filter(g_obj => g_obj.annotation_id === obj.annotation_id);
						if (group_objects.length > 0) {
							const group = new window.fabric.Group([], {
								scaleX: objData.scaleX * scaleX,
								scaleY: objData.scaleY * scaleY,
								left: objData.left * scaleX,
								top: objData.top * scaleY
							});
							const new_group_objects = group_objects[0].group_objects;
							window.fabric.util.enlivenObjects(new_group_objects, (enlivenedObjects) => {
								group._objects = enlivenedObjects;
								group.setCoords();
								group.render(context);
							});
						}
					} else {
						obj.scaleX = objData.scaleX * scaleX;
						obj.scaleY = objData.scaleY * scaleY;
						obj.left = objData.left * scaleX;
						obj.top = objData.top * scaleY;
						obj.setCoords();
						obj.render(context);
					}
				});
			});

			const imageData = canvas.toDataURL('image/png');  // Get the image data from the canvas
			context.clearRect(0, 0, maxWidth, maxHeight);  // Clear the canvas
			context.putImageData(data, 0, 0);  // Restore it with original/cached ImageData
			const desiredWidth = 200;  // Adjust as needed (size for the image in the PDF)
			const desiredHeight = 200;  // Adjust as needed (size for the image in the PDF)
			// Calculate the scaling factors to fit the image inside the desired size
			const scaleFactor = Math.min(desiredWidth / context.canvas.width, desiredHeight / context.canvas.height);
			// Calculate the scaled width and height
			const scaledWidth = context.canvas.width * scaleFactor;
			const scaledHeight = context.canvas.height * scaleFactor;
			// Add the scaled image to the PDF
			const x = (pdf.internal.pageSize.width - scaledWidth) / 2;
			const y = (pdf.internal.pageSize.height - scaledHeight) / 2;
			pdf.addImage(imageData, 'png', x, y, scaledWidth, scaledHeight);
			this.addCommentsLogicInPDF(pdf, canvasData);
		};
	
		// try {
		const blob = await fetchImageAsBlob(imageUrl);
		const img = new Image();
		img.crossOrigin = 'anonymous';

		const objectURL = URL.createObjectURL(blob);
		return new Promise((resolve, reject) => {
			img.onload = async () => {
				resolve(resize(img));
			};
			img.src = objectURL;
		})
	}
	

	addCommentsLogicInPDF(pdf, canvasData, type = 'product') {
		pdf.setFontSize(10);
		let lineHeight = 8; // Adjust this value based on the desired spacing between lines
		let start = 16;
		// Add text to the PDF
		let unresolved_comments = this.all_comments.filter(comment => (!comment.is_resolved && !comment.is_hidden));
		if (type == 'product') {
			unresolved_comments = this.all_comments.filter(comment => (comment.product_qa_collab_id == canvasData.id) && (!comment.is_resolved && !comment.is_hidden));
		}
		if (unresolved_comments.length > 0) {
			unresolved_comments.sort((a, b) => a.sequence_no - b.sequence_no || b.created_on.localeCompare(a.created_on));
			pdf.addPage();
			pdf.setFont('helvetica', 'bold');
			pdf.text("Unresolved Comments for this image:", 15, 8);
			let sequence_printed = [];
			let yPos = start;
			unresolved_comments.forEach((comment, index) => {
				// Check if there's enough space on the current page
				if (yPos > 275) {
					pdf.addPage();
					yPos = start;
				}
				if (!sequence_printed.includes(comment.sequence_no)) {
					sequence_printed.push(comment.sequence_no);
					pdf.setFont('helvetica', 'bold');
					pdf.text("Comment No: " + comment.sequence_no, 15, yPos + lineHeight);
					pdf.setFont('helvetica', 'normal');
					yPos = this.populatePDFComments(pdf, comment, yPos, start, lineHeight, 1);

				} else {
					yPos = this.populatePDFComments(pdf, comment, yPos, start, lineHeight, 0);
				}
			});
		}
	}

	populatePDFComments(pdf, comment, yPos, start, lineHeight, multiplier) {
		pdf.text("Date: " + Utilities.convertToCustomFormat(comment.created_on) + "   |   By: " + comment.created_by, 15, yPos + (lineHeight * (multiplier + 1)));
		// Wrap text for Message to handle horizontal overflow
		let messageWrappedText = pdf.splitTextToSize("Message: " + comment.message, pdf.internal.pageSize.width - 30, { fontSize: 12 });
		let mult = (multiplier + 2);
		// Check if the text exceeds the available width
		let i = 0;
		let start_index = 0;
		while (messageWrappedText.length > 1 && i != messageWrappedText.length) {
			pdf.text(messageWrappedText[i], 15, yPos + (lineHeight * mult));
			i = i + 1;
			mult = mult + 1;
			start_index = mult;
		}
		if (messageWrappedText.length == 1) {
			pdf.text(messageWrappedText, 15, yPos + (lineHeight * mult));
			start_index = mult + 1;
		}
		// let start_index = mult + 1;
		if (comment.attachment && comment.attachment.length > 0) {
			comment.attachment.map((file, index) => {
				if (yPos + (lineHeight * start_index) > 275) {
					pdf.addPage();
					yPos = start;
					start_index = 1;
				}
				let link = getBaseURL(this.platform) + BASE_URI + encodeURIComponent(file.uid + '/' + file.name);
				start_index = start_index + index;
				this.addLink(pdf, 15, yPos + (lineHeight * start_index), link);
			});
			yPos = yPos + (lineHeight * start_index);
		} else {
			yPos = yPos + (lineHeight * (start_index - 1));
		}
		return yPos;
	}

	// Function to add a link with specified options
	addLink(pdf, x, y, url) {
		// Set link options
		var options = {
			url: url,
			color: '#007bff', // Set the color of the link (adjust as needed)
			underline: true, // Set to true to underline the link
		};

		pdf.setTextColor(options.color);
		pdf.textWithLink(url, x, y, options);
		pdf.setTextColor(0, 0, 0); // Reset text color to black
	}

	async convertProductFeedbackToPDF(productQAImages, id, save, loader = null) {
		const pdf = new jsPDF();
		let i = 0;
		let empty_pdf_state = true;
		const processNextCanvas = async () => {
			if (i < productQAImages.length) {
				const canvasData = productQAImages[i];
				if (canvasData.state && (Object.entries(canvasData.state).length !== 0 || canvasData.state.length > 0)
					&& canvasData.state.data && canvasData.state.data.objects && canvasData.state.data.objects.length > 0) {
					empty_pdf_state = false;
					let url = canvasData.url;
					// Create a hidden canvas
					const hiddenCanvas = document.createElement('canvas');
					hiddenCanvas.width = 1600;
					hiddenCanvas.height = 900;
					const hiddenCanvasCtx = hiddenCanvas.getContext('2d');
					// Render the canvas based on JSON data
					await this.constructPDF(url, canvasData, '#FFF', hiddenCanvasCtx, hiddenCanvas, pdf);
					pdf.addPage();
				}
				i = i + 1
				// Move on to the next canvas
				return processNextCanvas();
			} else {
				// All canvases processed, save the PDF
				if (save) {
					if (loader != null) {
						setTimeout(loader);
					}
					pdf.save(id + '_Feedback.pdf');
				} else {
					if (empty_pdf_state == true || (pdf.getNumberOfPages() == 0 || pdf.getNumberOfPages() == 1)) {
						return -1;
					}
					let data_url_pdf = pdf.output('datauristring');
					return data_url_pdf;
				}
			}
		}
		// Start processing the canvases
		return processNextCanvas();
	}


	async convertRenderFeedbackToPDF(canvasData, id, save, loader = null) {
		const pdf = new jsPDF();

		const processNextCanvas = async () => {
			if (canvasData && (Object.entries(canvasData).length !== 0)) {
				let url = this.image_url;
				// Create a hidden canvas
				const hiddenCanvas = document.createElement('canvas');
				hiddenCanvas.width = this.canvasWidth;
				hiddenCanvas.height = this.canvasHeight;
				const hiddenCanvasCtx = hiddenCanvas.getContext('2d');
				// Render the canvas based on JSON data
				await this.constructRenderPDF(url, canvasData, '#FFF', hiddenCanvasCtx, hiddenCanvas, pdf);
				pdf.addPage();
			}

			// All canvases processed, save the PDF
			if (save) {
				if (loader != null) {
					setTimeout(loader);
				}
				pdf.save(id + '_Feedback.pdf');
			} else {
				let data_url_pdf = pdf.output('datauristring');
				return data_url_pdf;
			}
		}
		// Start processing the canvases
		return processNextCanvas();
	}


	async constructRenderPDF(imageUrl, canvasData, backgroundColor, context, canvas, pdf) {
		const img = new Image();

		const resize = async () => {
			// create a canvas element to manipulate	
			// setup some resizing definitions
			let { width, height } = img;
			var maxWidth = context.canvas.width;
			var maxHeight = context.canvas.height;
			var widthScale = maxWidth / width;
			var heightScale = maxHeight / height;
			var scale = Math.min(widthScale, heightScale);
			img.width *= scale;
			img.height *= scale;
			var imgLeft = (maxWidth - img.width) / 2;
			var imgTop = (maxHeight - img.height) / 2;

			context.drawImage(img, imgLeft, imgTop, img.width, img.height); // place the image on the canvas
			const data = context.getImageData(0, 0, maxWidth, maxHeight); // get the current ImageData for the canvas
			const compositeOperation = context.globalCompositeOperation; // store the current globalCompositeOperation			
			context.globalCompositeOperation = 'destination-over'; // set to draw behind current content
			context.fillStyle = backgroundColor; // set background color
			context.fillRect(0, 0, maxWidth, maxHeight); // draw background / rect on the entire canvas
			context.globalCompositeOperation = compositeOperation; // reset the globalCompositeOperation to what it was
			let state = canvasData;
			// let group_objects = canvasData.group_objects;
			let original_width = state.canvas_width;
			let original_height = state.canvas_height;

			// Calculate scaling factors
			var scaleX = context.canvas.width / original_width;
			var scaleY = context.canvas.height / original_height;

			// Load and scale objects
			state.objects.forEach(objData => {
				if (objData.type == "group") {
					window.fabric.util.enlivenObjects(objData.objects, enlivenedObjects => {
						const group = new window.fabric.Group(enlivenedObjects, {
							// Set group options
							scaleX: objData.scaleX * scaleX,
							scaleY: objData.scaleY * scaleY,
							left: objData.left * scaleX,
							top: objData.top * scaleY,
							width: objData.width * scaleX,
							height: objData.height * scaleY,
							annotation_id: objData.annotation_id,
							sequence_no: objData.sequence_no,
							is_resolved: objData.is_resolved
						});
						window.fabric.util.enlivenObjects(objData.objects, (enlivenedObjects) => {
							group._objects = enlivenedObjects;
							group.setCoords();
							group.render(context);
						});
					});
				} else {
					window.fabric.util.enlivenObjects([objData], ([obj]) => {
						obj.height = objData.height * scaleY;
						obj.width = objData.width * scaleX;
						obj.scaleX = objData.scaleX * scaleX;
						obj.scaleY = objData.scaleY * scaleY;
						obj.left = objData.left * scaleX;
						obj.top = objData.top * scaleY;
						obj.setCoords();
						obj.render(context);
					});
				}
			});

			const imageData = canvas.toDataURL('image/png'); // get the image data from the canvas
			context.clearRect(0, 0, maxWidth, maxHeight); // clear the canvas
			context.putImageData(data, 0, 0); // restore it with original / cached ImageData
			const desiredWidth = 200; // adjust as needed (size for the image in the PDF)
			const desiredHeight = 200; // adjust as needed (size for the image in the PDF)
			// Calculate the scaling factors to fit the image inside the desired size
			const scaleFactor = Math.min(desiredWidth / context.canvas.width, desiredHeight / context.canvas.height);
			// Calculate the scaled width and height
			const scaledWidth = context.canvas.width * scaleFactor;
			const scaledHeight = context.canvas.height * scaleFactor;
			// Add the scaled image to the PDF
			let x = (pdf.internal.pageSize.width - scaledWidth) / 2;
			let y = (pdf.internal.pageSize.height - scaledHeight) / 2;
			pdf.addImage(imageData, 'png', x, y, scaledWidth, scaledHeight);
			this.addCommentsLogicInPDF(pdf, canvasData, 'scene_render');
		};

		return new Promise((resolve, reject) => {
			img.onload = () => resolve(resize());
			img.onerror = () => reject(new Error('Error loading image from URL'));
			// img.crossOrigin = 'anonymous';
			img.src = imageUrl;
		});
	}


	setAllComments(comments) {
		this.all_comments = comments;
	}


}


export default CanvasEditor;