import React from 'react';
import { Table, Tooltip,Input,Space,Button } from 'antd';
import axios from 'axios';
import {  InfoCircleOutlined, CheckCircleFilled, CloseCircleFilled,SearchOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import FileConstants from '../../../../FileConstants'
class InstanceHealth extends React.Component {
    state = {
        hasData: true,
        dataSource: null,
        filter_status:[],
        filter_entity_types:[],
        filter_running_status:[]
      };
      getColumnSearchProps = (dataIndex,placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${placeholder}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        }
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    getColumns = () => [
        {
          title: 'Instance ID',
          dataIndex: 'cloud_machine_id',
          key: 'cloud_machine_id',
          ...this.getColumnSearchProps('cloud_machine_id','Instance ID'),
          render: text => <span>{text}</span>,
        },
        {
            title: 'Instance Type',
            dataIndex: 'cloud_machine_type',
            key: 'cloud_machine_type',
          ...this.getColumnSearchProps('cloud_machine_id','Instance Type'),
            render: text => <span>{text}</span>,
          },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          filters: this.state.filter_status,
          onFilter: (value, record) => record.status === value,
          render: text => <span>{text === 'active' ? <Tooltip title="Active"><CheckCircleFilled style={{color: "green", fontSize: 25}}/></Tooltip> :  <Tooltip title="Unhealthy"><CloseCircleFilled style={{color: "#ff0000", fontSize: 25}}/></Tooltip>}</span>,
        },
        {
          title: () => <Tooltip title="The ID of job that is running on this instance"><span>Job ID  <InfoCircleOutlined /></span></Tooltip>,
          dataIndex: 'job_id',
          key: 'job_id',
          sorter:(a,b)=> a.job_id-b.job_id,
          render: text => <span>{text === "" ? <span>-</span> : <span>{text}</span>}</span>,
        },
        {
          title: () => <Tooltip title="The ID of entity whose job is running in this instance"><span>Entity ID  <InfoCircleOutlined /></span></Tooltip>,
          dataIndex: 'entity_id',
          key: 'entity_id',
          sorter:(a,b)=>a.entity_id_real-b.entity_id_real,
          render: text => <span>{text === null ? <span>-</span> : <span><a href={text} target="_blank">{text.split('/').slice(-1)[0]}</a></span>}</span>,
        },
        {
          title: () => <Tooltip title="The type of entity whose job is running in this instance"><span>Entity Type  <InfoCircleOutlined /></span></Tooltip>,
          dataIndex: 'request_type',
          key: 'request_type',
          filters: this.state.filter_entity_types,
          onFilter: (value, record) => record.request_type === value,
          render: (text, record) => <span>{text === "" ? <span>-</span> : <span>{(FileConstants.REQUEST_TYPE_LABELS[text] != undefined) ? FileConstants.REQUEST_TYPE_LABELS[text]: <span> {text} </span>} </span>}</span>
        },
        {
          title: 'Turned On Since',
          dataIndex: 'start_time',
          key: 'start_time',
          render: text => <span>{text}</span>,
        },
        {
          title: 'Running Status',
          dataIndex: 'running_status',
          key: 'running_status',
          filters: this.state.filter_running_status,
          onFilter: (value, record) => record.running_status === value,
          render: text => <span style={text === 'running' ? {color: 'green', fontWeight: 'bold'} : {color: 'black'} }>{text.charAt(0).toUpperCase() + text.slice(1)}</span> ,
        },
        {
            title: () => <Tooltip title="Show completed, failed and aborted jobs of past 5 hours"><span>Completed / Failed / Aborted <InfoCircleOutlined /></span></Tooltip> ,
            dataIndex: 'throughput',
            key: 'throughput',
            render: text => <span>{text}</span>,
          },
      ];
    
      
      componentDidMount = () => {
        this.handleRequestLoadData();
      }

      timestampToTimeSince = (timestamp) => {
        let timeSince = ""
        let currentTimestamp = new Date()
        
        console.log(new Date(timestamp))
        console.log(currentTimestamp)
        var difference = currentTimestamp - (new Date(timestamp));
        console.log("Difference",difference);
    
        var daysDifference = Math.floor(difference/1000/60/60/24);
        difference -= daysDifference*1000*60*60*24
    
        var hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60
    
        var minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60
    
        if (minutesDifference  <= 1) {
            timeSince = "a minute ago"
        } else {
            timeSince = minutesDifference + " minutes ago"
        }
    
        if (hoursDifference != 0) {
            if (hoursDifference  == 1) {
                timeSince = "an hour ago"
            } else {
                timeSince = hoursDifference + " hours ago"
            }
        }
    
        if (daysDifference != 0) {
            if (daysDifference  == 1) {
                timeSince = "a day ago"
            } else {
                timeSince = daysDifference + " days ago"
            }
        }
    
        
        return timeSince; 
    }
    
    getEntityIdLink = (entity_type, entity_id) => 
    {
        let entity_id_link = null;
        console.log(entity_type)
        if (entity_type == 'product') {
            entity_id_link = '/admin_products/' + entity_id;
        }
        else if (entity_type == 'room') {
            entity_id_link = '/admin_rooms/' + entity_id;
        }
        else if (entity_type == 'scene') {
            entity_id_link = '/admin_scene_renders/' + entity_id;
        }
        else if (entity_type == 'material') {
            entity_id_link = '/admin_materials/' + entity_id;
        }
        else if (entity_type == 'test_product') {
            entity_id_link = '/admin_products/' + entity_id;
        }
        return entity_id_link;
    } 
      handleRequestLoadData = () => {
        let filter_status_values=[];
        let filter_entity_values=[];
        let filter_running_status=[];
        axios.post(ENVIRONMENT.INSTANCE_HEALTH_STATUS_GET, {})
          .then(res => {
            console.log(res);
            console.log(res.data);
            var data = [];
            let status_value=null;
            let entity_value=null;
            let running_value=null;

            for (let i = 1; i <= (res.data).length; i++) 
            {
                let entity_id_link = this.getEntityIdLink(res.data[i - 1]['entity_type'].split("_")[0], res.data[i - 1]['entity_id']);

                data.push({
                  key: i,
                  cloud_machine_id: res.data[i-1]['cloud_machine_id'],
                  cloud_machine_type: res.data[i-1]['instance_type'],
                  status: res.data[i-1]['status'],
                  job_id: res.data[i - 1]['job_id'],
                  entity_id: entity_id_link,
                  entity_id_real: res.data[i - 1]['entity_id'],
                  request_type: res.data[i-1]['entity_type'],
                  start_time:  res.data[i - 1]['start_time'] === '-' ? '-' : this.timestampToTimeSince(res.data[i - 1]['start_time']),
                  throughput: res.data[i - 1]['throughput'],
                  running_status: res.data[i-1]['running_status']
                });
                entity_value=res.data[i - 1]['entity_type'];
                if (entity_value && !filter_entity_values.includes(entity_value)) {
                  filter_entity_values.push(entity_value);
                }
                status_value=res.data[i - 1]['status'];
                if (status_value && !filter_status_values.includes(status_value)) {
                  filter_status_values.push(status_value);
                }
                running_value=res.data[i-1]['running_status'];
                if (running_value && !filter_running_status.includes(running_value)) {
                  filter_running_status.push(running_value);
                }
                this.handleFilteringOptions(filter_entity_values,filter_status_values,filter_running_status);


            }

            this.setState({
              dataSource: data
            })
          })
      }
      handleFilteringOptions = (filter_entity_values,filter_status_values,filter_running_status) => {

        let filter_entity_objects = [];
        filter_entity_values.forEach(filter_entity_value => {
          filter_entity_objects.push({
            text: (FileConstants.REQUEST_TYPE_LABELS[filter_entity_value] !== undefined) 
                    ? FileConstants.REQUEST_TYPE_LABELS[filter_entity_value] 
                    : filter_entity_value,
            value: filter_entity_value,
          });
        });

    
        let filter_status_objects = [];
        filter_status_values.forEach(filter_status_value => {
          filter_status_objects.push({
            text: filter_status_value,
            value: filter_status_value,
          })
        });
        let filter_running_objects = [];
        filter_running_status.forEach(filter_running_status => {
          filter_running_objects.push({
            text: filter_running_status,
            value: filter_running_status,
          })
        });
  
        this.setState({
          filter_entity_types: filter_entity_objects,
          filter_status:filter_status_objects,
          filter_running_status:filter_running_objects
        });
      }
      render() {
        const { state } = this;
        const { dataSource } = state;
    
        return (
          <div>
            {dataSource == undefined ?<DottedLoader/>:""}
                {
                dataSource &&
                <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
                }
          </div>
        );
      }
}
export default InstanceHealth;