import {
  LIST_SCENE,
  SCENE_SUCCESS,
  SCENE_FAILURE,
} from '../actions';

const initialState = {
  scenes: [],

  isLoading: false,
  isCreated: false,
  isUpdated: false,
  error: null,
};

export default function sceneReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_SCENE:
      return {
        scenes: state.scenes,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case SCENE_SUCCESS:
      return {
        scenes: action.payload.scenes || state.scenes,
        isLoading: false,
        isCreated: action.payload.isCreated,
        isUpdated: action.payload.isUpdated,
        error: null,
      };
    case SCENE_FAILURE:
      return {
        products: state.scenes,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
}
