import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col, Button, Card, Dropdown, Menu, Carousel, Modal, message, Tooltip } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons';
import ProductPageConstants from './ProductPageConstants.js';
import ImageMagnify from '../ImageMagnify/ImageMagnify';
import * as Utilities from '../../Utilities.jsx';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import DeleteImageModal from './DeleteImageModal';
import './ProductComponents.scss';
import ZoomImage from './imageZoomable.jsx';

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");

const customSortOrder = {
    "silo": 0,
    "dim": 1,
    "low_res_preview": 2,
    "lifestyle": 3
};


const ImageListType = (props) => {

    const openDeleteLifestyleModal = (image_data) => {
        props.setDeleteModal(true);
        props.setDeleteImageType(image_data.type)
        props.setSelectedDeleteLifestyle(image_data.filename)
        props.setSelectedLifestyleSceneId(image_data.scene_id)
    }

    const openDeleteSiloModal = (image_data) => {
        props.setDeleteImageType(image_data.type)
        props.setDeleteModal(true);
        props.setSelectedDeleteSilo(image_data.camera_name)
        props.setSelectedDeleteSiloURL(image_data.url)
    }

    const handleDeleteModal = (image) => {
        props.selectImage(image.url)
        if (props.type == 'lifestyle') {
            openDeleteLifestyleModal(image)
        }
        else if (props.type == 'silo') {
            openDeleteSiloModal(image)
        }
    }

    return (props.allProductImages.filter(image => image.main_type == props.type).length > 0 ?
        <>
            <div className='manrope f-14 black-26 w-100'>{props.name}</div>
            {props.allProductImages.filter(image => image.main_type == props.type).map((image, index) => (
                <Card className={`product-img-grid-card display-flex ${props.selectedProductImage == image.url ? `selected` : ``}`}
                    key={index} onClick={() => { props.selectImage(image.url) }}>
                    <img className='product-img-grid-img' src={image.low_url} />
                    {
                        (props.type == "lifestyle" || (props.type == 'silo' && image.status != 'pending')) ?
                            <img className='product-silo-lifestyle-delete-icon' src="/img/delete2.png" alt="Delte Icon" onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteModal(image);
                            }}
                            />
                            : ''
                    }
                </Card>
            ))}
        </> : "")
}


const ProductImageCarousel = () => {
    let carousel = React.createRef();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteImageType, setDeleteImageType] = useState('')
    const [selectedDeleteLifestyle, setSelectedDeleteLifestyle] = useState('')
    const [selectedDeleteSilo, setSelectedDeleteSilo] = useState('')
    const [selectedDeleteSiloURL, setSelectedDeleteSiloURL] = useState('')
    const [selectedLifestyleSceneId, setSelectedLifestyleSceneId] = useState(0)

    const { allProductImages, productImageCount, downloadImagesArchive, setSelectedImgOriginalFormat,
        imgAccessedFromCarousel, setImgAccessedFromCarousel, selectedImgOriginalFormat, selectedImgFilename,
        setSelectedImgFilename, selectedProductImage, setSelectedProductImage, downloadSiloImages, isStorePage, product_id,
        companyId, setAllProductImages, setProductImageCount, secondaryShots, setSecondaryShots, productAssetData,
        setProductAssetData, allSystemGeneratedProductImages, selectedImageResolution, setSelectedImageResolution, setSelectedImageFormat, selectedImageFormat, setSelectedImageName, selectedImageName, selectedImageCreationDate, setSelectedImageCreationDate } = useContext(CustomerProductContext);

    allProductImages.sort((a, b) => customSortOrder[a.main_type] - customSortOrder[b.main_type]);
    useEffect(() => {
        if (allProductImages.length > 0) {
            setInitialImageDetails(allProductImages[0]);
        }
    }, [allProductImages]);

    const setInitialImageDetails = (image) => {
        setSelectedProductImage(image.url);
        setSelectedImgOriginalFormat(image.format);
        setSelectedImgFilename(image.filename);
        setSelectedImageResolution(image.resolution);
        setSelectedImageFormat(image.format);
        setSelectedImageName(image.display_name);
        setSelectedImageCreationDate(image.created_at);
    };

    useEffect(() => {
        if (imgAccessedFromCarousel == false) {
            let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
            carousel.goTo(image_index, true);
        }
    }, [selectedProductImage, imgAccessedFromCarousel]);

    const selectImage = (url) => {
        setSelectedProductImage(url);
        let image_index = allProductImages.findIndex(img => img.url == url);
        console.log("Checking img", allProductImages[image_index])  
        setSelectedImgOriginalFormat(allProductImages[image_index].format);
        setSelectedImgFilename(allProductImages[image_index].display_name ? allProductImages[image_index].display_name : allProductImages[image_index].filename);
        setSelectedImageResolution(allProductImages[image_index].resolution);
        setSelectedImageFormat(allProductImages[image_index].format);
        setSelectedImageName(allProductImages[image_index].display_name);
        setSelectedImageCreationDate(allProductImages[image_index].created_at);

        carousel.goTo(image_index, true);
        setImgAccessedFromCarousel(true);
    }

    const onChangeCarousel = (before, to) => {
        if (before == (productImageCount - 1) && to == 0) {
            carousel.goTo(0, true);
            setImgAccessedFromCarousel(true);
        } else if (before == 0 && to == (productImageCount - 1)) {
            carousel.goTo(productImageCount - 1, true);
            setImgAccessedFromCarousel(true);
        }
    }

    const next = () => {
        let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);

        if ((image_index + 1) == productImageCount) {
            setSelectedProductImage(allProductImages[0].url)
            setSelectedImageResolution(allProductImages[0].resolution);
            setSelectedImageFormat(allProductImages[0].format);
            setSelectedImageName(allProductImages[0].display_name);
            setSelectedImageCreationDate(allProductImages[0].created_at);

            carousel.goTo(0, true);
            setImgAccessedFromCarousel(true);
        } else {
            setSelectedProductImage(allProductImages[image_index + 1].url);
            setSelectedImageResolution(allProductImages[image_index + 1].resolution);
            setSelectedImageFormat(allProductImages[image_index + 1].format);
            setSelectedImageName(allProductImages[image_index + 1].display_name);
            setSelectedImageCreationDate(allProductImages[image_index + 1].created_at);

            carousel.goTo(image_index + 1, true);
            setImgAccessedFromCarousel(true);
        }
    }

    const prev = () => {
        let image_index = allProductImages.findIndex(img => img.url === selectedProductImage);

        //if user moves back from step from carousel then close the carousel
        if ((image_index - 1) < 0) {
            carousel.goTo(productImageCount - 1, true);
            setSelectedProductImage(allProductImages[productImageCount - 1].url);
            setImgAccessedFromCarousel(true);
            setSelectedImageResolution(allProductImages[productImageCount - 1].resolution);
            setSelectedImageFormat(allProductImages[productImageCount - 1].format);
            setSelectedImageName(allProductImages[productImageCount - 1].display_name);
            setSelectedImageCreationDate(allProductImages[productImageCount - 1].created_at);

        } else {
            setSelectedProductImage(allProductImages[image_index - 1].url);
            setSelectedImageResolution(allProductImages[image_index - 1].resolution);
            setSelectedImageFormat(allProductImages[image_index - 1].format);
            setSelectedImageName(allProductImages[image_index - 1].display_name);
            setSelectedImageCreationDate(allProductImages[image_index - 1].created_at);
            carousel.goTo(image_index - 1, true);
            setImgAccessedFromCarousel(true);
        }
    }

    const getNextImageURL = (allProductImages) => {
        let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
        if ((image_index + 1) == productImageCount) {
            return allProductImages[productImageCount - 1].url;
        } else {
            return allProductImages[image_index + 1].url;
        }
    }

    const getImageNumber = () => {
        let image_index = allProductImages.findIndex(img => img.url === selectedProductImage);
        return image_index + 1;
    }

    const getStatus = () => {
        let image = allProductImages.filter(img => img.url === selectedProductImage);
        let status = "";
        if (image.length > 0) {
            status = image[0].status;
        }

        return status;
    }

    const deleteLifestyle = () => {
        setDeleteLoader(true)
        let payload = {
            action: "hide_camera_product_mapping",
            product_ids: [product_id],
            old_camera: selectedDeleteLifestyle,
            scene_id: selectedLifestyleSceneId
        }
        axios.post(ENVIRONMENT.CAMERA_PRODUCT_MAP_CONTROLLER, payload)
        const allImages = [];
        for (const imageObj of productAssetData['lifestyle_data']) {
            if (!(imageObj.camera_name == selectedDeleteLifestyle && imageObj.scene_id == selectedLifestyleSceneId)) {
                allImages.unshift(imageObj);
            }
        }

        productAssetData['lifestyle_data'] = allImages
        productAssetData['silo_data'] = productAssetData['silo_data'].reverse()

        setDeleteLoader(false);
        setDeleteModal(false);
        message.info('Lifestyle Image Deleted.');
        allSystemGeneratedProductImages(productAssetData);

        const allProdImages = [];
        for (const imageObj of allProductImages) {
            if (!(imageObj.type == 'lifestyle' && imageObj.filename == selectedDeleteLifestyle && imageObj.scene_id == selectedLifestyleSceneId)) {
                allProdImages.push(imageObj);
            }
        }
        var next_image = getNextImageURL(allProductImages)
        let image_index = allProductImages.findIndex(img => img.url == next_image);
        setSelectedProductImage(allProdImages[image_index - 1].url);
        setSelectedImageResolution(allProductImages[image_index - 1].resolution);
        setSelectedImageFormat(allProductImages[image_index - 1].format);
        setSelectedImageName(allProductImages[image_index - 1].display_name);
        setSelectedImageCreationDate(allProductImages[image_index - 1].created_at);

        carousel.goTo(image_index - 1);
        setImgAccessedFromCarousel(true);
    }

    const deleteSilo = () => {
        setDeleteLoader(true)
        let payload = {
            action: "delete",
            product_id: product_id,
            camera_name: selectedDeleteSilo,
            customer_username: CUSTOMER_USERNAME
        }

        if (companyId) {
            payload.company_id = companyId
        }

        axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
        const allImages = [];
        for (const imageObj of productAssetData['silo_data']) {
            if (imageObj.camera_name !== selectedDeleteSilo) {
                allImages.unshift(imageObj);
            }
        }

        productAssetData['silo_data'] = allImages
        productAssetData['lifestyle_data'] = productAssetData['lifestyle_data'].reverse()

        setDeleteLoader(false);
        setDeleteModal(false);
        message.info('Silo Image Deleted.');
        allSystemGeneratedProductImages(productAssetData);

        const allProdImages = [];
        for (const imageObj of allProductImages) {
            if (!(imageObj.type == 'silo' && imageObj.filename == selectedDeleteSilo)) {
                allProdImages.push(imageObj);
            }
        }
        var next_image = getNextImageURL(allProductImages)
        let image_index = allProductImages.findIndex(img => img.url == next_image);
        setSelectedProductImage(allProdImages[image_index - 1].url);
        carousel.goTo(image_index - 1);
        setImgAccessedFromCarousel(true);
    }

    const menu = (
        <Menu style={{ width: "148px" }}>
            <Menu.Item key={0}>
                <a onClick={() => {
                    Utilities.downloadFile(selectedImgOriginalFormat == "tiff" ? selectedProductImage.replace("jpg", "tiff") : selectedProductImage, selectedImgFilename, selectedImgOriginalFormat)
                }}
                    className='manrope f-14 black-55' download>This image only</a>
            </Menu.Item>
            <Menu.Item key={1}>
                <div className='manrope f-14 black-55' onClick={() => downloadSiloImages()}>Silo Images</div>
            </Menu.Item>
            <Menu.Item key={2}>
                <div className='manrope f-14 black-55' onClick={() => downloadImagesArchive()}>All Images</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <Row>
            <Col md={7} lg={7} xl={5} className='display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list'>
                <ImageListType
                    allProductImages={allProductImages}
                    type={"silo"}
                    name={"Silos"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                    setSelectedDeleteSilo={setSelectedDeleteSilo}
                    setDeleteImageType={setDeleteImageType}
                    setDeleteModal={setDeleteModal}
                    setSelectedDeleteSiloURL={setSelectedDeleteSiloURL}
                />
                <ImageListType
                    allProductImages={allProductImages}
                    type={"dim"}
                    name={"DIM"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListType
                    allProductImages={allProductImages}
                    type={"low_res_preview"}
                    name={"Previews"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListType
                    allProductImages={allProductImages}
                    type={"lifestyle"}
                    name={"Lifestyle"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                    setDeleteModal={setDeleteModal}
                    setDeleteImageType={setDeleteImageType}
                    setSelectedDeleteLifestyle={setSelectedDeleteLifestyle}
                    setSelectedLifestyleSceneId={setSelectedLifestyleSceneId}
                />
            </Col>
            <Col md={17} lg={17} xl={19} className='w-100'>
                <div className="resolutionText">
                    <div className="display-flex justify-start align-baseline" style={{ width: "80%" }}>
                        {selectedImageName &&
                            <Tooltip title={<span className="manrope f-12 white">{selectedImageName}</span>}>
                                <h4 className="manrope f-16 clamp-text w-60 ml-10 mb-0">{selectedImageName}</h4>
                            </Tooltip>}
                        {selectedImageCreationDate !== 'Invalid Date' && selectedImageCreationDate !== '' &&
                            <h5 className="manrope w-500 f-12 ml-8 grey-8c mb-0">
                                {`Created on ${selectedImageCreationDate}`}
                            </h5>}

                    </div>
                    {selectedImageResolution || selectedImageFormat ?
                        <h4 className="manrope w-500 f-16 mr-20 mb-0">{selectedImageResolution ? selectedImageResolution : ''}  {selectedImageFormat ? selectedImageFormat.toUpperCase() : ''}</h4>
                        : ''}
                </div>
                {getStatus() != "pending" && !isStorePage &&
                    <Dropdown overlay={menu} trigger={['click']} className='download-button'>
                        <Button className='product-btn dark-blue f-14 br-4 mt-50'>
                            Download <DownOutlined />
                        </Button>
                    </Dropdown>}
                {getStatus() == "pending" &&
                    <div className="render-in-progress">
                        Render in Progress
                    </div>}
                <div className="carousel-btns right-btn" onClick={next}>
                    <ArrowRightOutlined className="arrow" />
                </div>
                <Carousel autoplay={false} ref={node => (carousel = node)} className='w-100 product-carousel padding-10'
                    beforeChange={onChangeCarousel}>
                    {allProductImages.map((img, index) => (
                        <div key={index}>
                            {<ZoomImage
                                image={img.url}
                                index={index}
                                asset_viewer_modal={true}
                                className="product-carousel-image"
                            />}
                        </div>
                    ))}
                </Carousel>
                <div className="carousel-btns left-btn" onClick={prev}>
                    <ArrowLeftOutlined className="arrow" />
                </div>
                <div className='count-tag'>
                    <div className='manrope f-14 white lh-28'>{getImageNumber()} of {productImageCount}</div>
                </div>
            </Col>

            <DeleteImageModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteImageType={deleteImageType}
                deleteLifestyle={deleteLifestyle}
                deleteSilo={deleteSilo}
                deleteLoader={deleteLoader}
            />
        </Row>
    );
}
export default ProductImageCarousel;

