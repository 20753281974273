import React, { useState, useEffect } from "react";
import { Card, Row, Col, Empty, Space, Tag, Tooltip, Modal, Table } from "antd";
import moment from "moment";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/charts";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import useTableFilter from "../../UseTableFilter/useTableFilter";

const Actions = {
    get_failed_aborted_jobs_count: "get_failed_aborted_jobs_count",
    get_job_details : "get_job_details"
};

const DATE_FORMAT = "YYYY-MM-DD";
const DISPLAY_DATE_FORMAT = "YYYY/MM/DD";
const TOOLTIP_MESSAGE = "Click to View Details";
  
const CONFIG = (dataSource, xField, yField, seriesField) => {
    let config = {
        data: dataSource,
        xField: xField,
        yField: yField,
        yAxis: {
            label: {
            formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        smooth: true,
        seriesField: seriesField,
        legend: {
            position: "top",
            layout: "horizontal",
        },
        animation: {
            appear: {
            animation: "path-in",
            duration: 500,
            },
        },
    };
    config.color = (value, item) => {
        if (value) {
            return JOB_COLORS[value.job_status]
        }
    };
    return config;
};

const JOB_TYPES = ['Failed', 'Aborted', 'Product Preprocessing', 'Color Correction']
const JOB_KEYS = {
    'Failed' : 'failed', 
    'Aborted' : 'aborted', 
    'Product Preprocessing' : 'product_preprocessing', 
    'Color Correction' : 'color_correction'
}

const JOB_COLORS = {
    'Failed' : '#f50', 
    'Aborted' : '#2db7f5', 
    'Product Preprocessing' : '#87d068', 
    'Color Correction' : '#9d62c4'
}

const FailedAndAbortedJobs = () => {
    // Variables
    const [isJobsCountFetched, setIsJobsCountFetched] = useState(false);
    const [jobsData, setJobsData] = useState([]);
    const [jobPercentages, setJobPercentages] = useState({});
    const [startDate, setStartDate] = useState(
        moment().startOf("month").format(DATE_FORMAT)
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("month").format(DATE_FORMAT)
    );
    const [totalJobsCount] = useState({});
    const [isJobDetailsModalVisible, setIsJobDetailsModalVisible] = useState(false);
    const [jobStatus, setJobStatus] = useState(null);
    const [modelCount, setModelCount] = useState(0);
    const [jobDetails, setJobDetails] = useState([]);
    const [isJobDetailFetched, setIsJobDetailFetched] = useState(false);
    const [getColumnSearchProps] = useTableFilter();

    const getFailedAndAbortedJobs = () => {
        /* Function to get monthly average cost breakdown between given dates */
        
        let payload = {
            "action" : Actions["get_failed_aborted_jobs_count"],
            "start_date" : startDate,
            "end_date" : endDate
        };

        console.log("Payload", payload);
        
        setIsJobsCountFetched(true);
        axios.post(ENVIRONMENT.GET_AUTOMATION_DETAILS, payload).then((response) => {
            const responseData = response.data.jobs_data;
            console.log('Failed and Aborted Jobs Data', responseData);
            JOB_TYPES.map((job) => {
                let job_count = getCumulativeCount(responseData, job)
                totalJobsCount[job] = job_count
            })
            setJobPercentages(response.data.percentages);
            setJobsData(responseData);
            setIsJobsCountFetched(false);
        });
    }

    useEffect(() => {
        getFailedAndAbortedJobs();
    }, [startDate, endDate]);

    const getCumulativeCount = (response, jobType) => {
        let count = 0;
        response.filter((item) => {
          return (
            item.job_status == jobType &&
            (item.job_count ? (count = count + item.job_count) : "")
          );
        });
        return count;
    };

    const onDateRangeChange = (date_values) => {
        if (date_values && date_values[0] && date_values[1]) {
          setStartDate(date_values[0].format(DATE_FORMAT));
          setEndDate(date_values[1].format(DATE_FORMAT));
        }
    };

    const getJobDetailsColumns = () => {
        const jobDetailsColumns = [
            {
                title: "Job Type",
                dataIndex: "job_type",
                key: "job_type",
                ...getColumnSearchProps("job_type"),
            },
            {
                title: "Job Status",
                dataIndex: "automation_status",
                key: "automation_status",
            },
            {
                title: "Entity ID",
                dataIndex: "entity_id",
                key: "entity_id",
                ...getColumnSearchProps("entity_id"),
                render: (text, record) => (
                    <a
                    className="onhover-underline"
                    href={getModelLink(text, record.job_type)}
                    >
                    {text}
                    </a>
                ),
            },
            {
                title: "Entity Type",
                dataIndex: "entity_type",
                key: "entity_type",
            },
            {
                title: "Name",
                dataIndex: "entity_name",
                key: "entity_name",
            },
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
            },
            {
                title: "Company",
                dataIndex: "company_name",
                key: "company_name",
            },
        ];
        if (jobStatus=='Failed' || jobStatus=='Aborted') {
            jobDetailsColumns.splice(1, 1);
        }
        return jobDetailsColumns;
    }

    const getModelLink = (id, job_type)  => {
        if (job_type.startsWith('Material')) {
          return '/admin_materials/' + id
        } 
        else if (job_type.startsWith('Room')) {
          return '/admin_rooms/' + id
        }
        else if (job_type.startsWith('Product')) {
          return '/admin_products/' + id
        }
        else if (job_type.startsWith('Scene')) {
            return '/admin_scene_renders/' + id
        }
    };

    const closeJobDetails = () => {
        setIsJobDetailsModalVisible(false);
    };

    const showJobDetails = (job_status) => {
        setJobStatus(job_status);
        setModelCount(totalJobsCount[job_status]);
        setIsJobDetailsModalVisible(true);
        let payload = {
          "action" : Actions["get_job_details"],
          "start_date" : startDate,
          "end_date" : endDate,
          "job_status" : JOB_KEYS[job_status],
        };
    
        console.log("Payload", payload);
        
        setIsJobDetailFetched(true);
        axios.post(ENVIRONMENT.GET_AUTOMATION_DETAILS, payload).then((response) => {
            const responseData = response.data;
            console.log('Model Details', responseData);
            setJobDetails(responseData);
            setIsJobDetailFetched(false);
        });
    };
    

    return (
        <>
            <Card
            className="no-hover section-container mt-10"
            size="small"
            title={
                <Row className="mt-10 justify-in-start" gutter={12}>
                    <Col span={19}>
                        <span className="sub-heading">Failed and Aborted Jobs</span>
                    </Col>
                    <Col span={5} className="justify-in-end">
                        <DateRangePicker
                        start={startDate}
                        end={endDate}
                        date_format={DISPLAY_DATE_FORMAT}
                        onCalChange={onDateRangeChange}>
                        </DateRangePicker>
                    </Col>
                </Row>
            }>
                <Row>
                    <div className="chart-container">
                    <Row className="justify-in-start" gutter={16}>
                        <Col span={24}>
                        <Space size={[0, 16]} wrap>
                        {
                        JOB_TYPES.map((job) => (
                            <Tooltip title={TOOLTIP_MESSAGE} key={job}> {/* Ensure to include a unique key */}
                                <Tag
                                    color={JOB_COLORS[job]}
                                    className={totalJobsCount[job] > 0 ? "cursor-pointer" : "disable-click"}
                                    onClick={() => {
                                        showJobDetails(job);
                                    }}
                                >
                                    {`${job} Jobs (${totalJobsCount[job] ? totalJobsCount[job] : 0}) (${jobPercentages[job] ? jobPercentages[job] : 0}%)`}
                                </Tag>
                            </Tooltip>
                        ))
                        }
                        </Space>
                        </Col>
                    </Row>
                        {
                        isJobsCountFetched ? (<LoadingOutlined className="loading-center top-20"/>) : 
                        jobsData.length > 0 ? 
                        <Line
                        {...CONFIG(
                            jobsData,
                            "job_time",
                            "job_count",
                            "job_status",
                        )}
                        className="line-plot"/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </div>
                </Row>
            </Card>
            <Modal
            title={`${jobStatus} Jobs (${modelCount})`}
            visible={isJobDetailsModalVisible}
            onCancel={closeJobDetails}
            footer={null}
            width={"60%"}
            bodyStyle={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Row>
                <div className="chart-container">
                    {
                    isJobDetailFetched ? (<LoadingOutlined className="loading-center top-20"/>) : 
                    <Table
                    dataSource={jobDetails}
                    columns={getJobDetailsColumns(jobStatus)}
                    scroll
                    style={{ overflowX: "scroll" }}
                    />
                    }
                </div>
                </Row>
            </Modal>
        </>
    );
};

export default FailedAndAbortedJobs;
