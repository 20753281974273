import React, { useState, useEffect } from "react";
import { Button, Layout, message, Table } from "antd";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../environments";
import ArtistMainLayout from "../ArtistComponents/ArtistMainLayout";
import AgencyArtistInvite from "./AgencyArtistInvite";
import AgencyInvoices from "../AgencyInvoice/AgencyInvoice";
import AdminMainLayout from "../AdminComponents/AdminMainLayout";

const { Header, Content, Footer } = Layout;

function AgencyDetails(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [agencyArtists, setAgencyArtists] = useState([]);
  const [agencyName, setAgencyName] = useState(null);
  const [inviteAgencyArtist, setInviteAgencyArtist] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [artistUsernames, setArtistUsernames] = useState([]);
  const [isAdminView, setIsAdminView] = useState(false);
  const [agencyBillingRate, setAgencyBillingRate] = useState(0);
  const [agencyBillingType, setAgencyBillingType] = useState('')

  useEffect(() => {
    let currentUser = null;
    let payload = {};
    if (window.location.href.includes("admin_agency_details")) {
      // Admin is viewing the agency page
      payload.agency_id = props.match.params.id;
      setIsAdminView(true);
    } else {
      let currentUser = localStorage.getItem("username");
      payload.agency_admin_username = currentUser;
    }

    setIsLoading(true);
    axios.post(ENVIRONMENT.AGENCY_ARTIST_GET_BATCH, payload).then((res) => {
      let agencyArtists = [];
      let artistUsername = [];
      if (res["data"]) {
        if(res['data'][0]){
          setAgencyName(res["data"][0]["agency_name"]);
          for (let item of res["data"]) {
            artistUsernames.push(item["username"]);
            if (currentUser && item["username"] == currentUser) {
              item["username"] += " (You)";
            }
            if (item['is_admin']){
              setAgencyBillingRate(item['billing_rate'])
              setAgencyBillingType(item['billing_type'])
            }
            agencyArtists.push(item);
          }
        }
      }
      setAgencyArtists(agencyArtists);
      setArtistUsernames(artistUsernames);
      setIsLoading(false);
    });
  }, []);

  const deactivateAccount = (username) => {
    let payload = {
      username: username,
    }
    console.log(payload);
    axios.post(ENVIRONMENT.DEACTIVATE_USER,payload)
    .then(res => {
      console.log(res);
      window.location.reload();
    }).catch(err => {
      message.info("Something went wrong while disabling the.")
    })
  }

  const columns = [
    {
      title: "Artist Username",
      key: 1,
      dataIndex: "username",
    },
    {
      title: "Artist Email",
      key: 2,
      dataIndex: "email",
    },
    {
      title: "Role",
      key: 3,
      dataIndex: "is_admin",
      render: (text) => <span>{text ? "Agency Admin" : "Artist"}</span>,
    },
    {
      title: "",
      key: 4,
      dataIndex: "username",
      render: (text) => text!=localStorage.getItem('username') ? <a onClick={()=> deactivateAccount(text)}>Disable Artist Account</a> : '',
    },
  ];

  return (
    <React.Fragment>
      {isAdminView ? (
        <AdminMainLayout>
          {viewInvoice ? (
            <AgencyInvoices
              agencyBillingRate={agencyBillingRate}
              agencyBillingType={agencyBillingType}
              artistUsernames={artistUsernames}
              setViewInvoice={setViewInvoice}
            />
          ) : (
            <div className="content-container">
              <div style={{ display: "flex", float: "right" }}>
                <Button
                  className="modal-okay square font-14"
                  key="view_invoice"
                  style={{ marginBottom: 25 }}
                  onClick={() => setViewInvoice(true)}
                >
                  View Agency Invoice
                </Button>
              </div>

              <h3 style={{ marginTop: 20, marginBottom: 20 }}>
                Agency Details
              </h3>

              <Table
                columns={columns}
                dataSource={agencyArtists}
                bordered={true}
                loading={{
                  indicator: <LoadingOutlined />,
                  spinning: isLoading,
                }}
              />
            </div>
          )}
        </AdminMainLayout>
      ) : (
        <ArtistMainLayout>
          {inviteAgencyArtist ? (
            <AgencyArtistInvite
              agencyName={agencyName}
              setInviteAgencyArtist={setInviteAgencyArtist}
            />
          ) : viewInvoice ? (
            <AgencyInvoices
              agencyBillingRate={agencyBillingRate}
              agencyBillingType={agencyBillingType}
              artistUsernames={artistUsernames}
              setViewInvoice={setViewInvoice}
            />
          ) : (
            <div className="content-container">
              <div style={{ display: "flex", float: "right" }}>
                <Button
                  className="modal-okay square font-14"
                  key="invite_user"
                  style={{ marginBottom: 25, marginRight: 10 }}
                  onClick={() => setInviteAgencyArtist(true)}
                >
                  Invite New Artist
                </Button>

                <Button
                  className="modal-okay square font-14"
                  key="view_invoice"
                  style={{ marginBottom: 25 }}
                  onClick={() => setViewInvoice(true)}
                >
                  View Agency Invoice
                </Button>
              </div>

              <h3 style={{ marginTop: 20, marginBottom: 20 }}>
                Agency Details
              </h3>

              <Table
                columns={columns}
                dataSource={agencyArtists}
                bordered={true}
                loading={{
                  indicator: <LoadingOutlined />,
                  spinning: isLoading,
                }}
              />
            </div>
          )}
        </ArtistMainLayout>
      )}
    </React.Fragment>
  );
}

export default AgencyDetails;
