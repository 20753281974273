import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Button, Select } from "antd";
import AdminMainLayout from "../AdminMainLayout";
import FreeTrial from "./FreeTrialUsers";
import EnterpriseUser from "./EnterpriseUsers";
import InvitedCustomers from "./InvitationSent";
import SelfSubscribedUsers from "./SelfSubscribedUsers";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { withRouter } from 'react-router-dom';

const { TabPane } = Tabs;
const { Option } = Select;

const SalesFunnel = (props) => {
  const [invitationCount, setInvitedSizeCount] = useState('');
  const [freeTrialCount, setFreeTrialSizeCount] = useState('');
  const [enterpriseCount, setEnterpriseSizeCount] = useState('');
  const [selfSubscribedCount, setSelfSubscribedSizeCount] = useState('');

  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [managers, setManagers] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [loadingManagers, setLoadingManagers] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const handlebackclick=()=>{
    props.history.goBack();
}

  useEffect(() => {
    const fetchManagers = async () => {
      setLoadingManagers(true);
      try {
        const payload = { action: "get_account_managers" };
        const response = await axios.post(
          ENVIRONMENT.ACCOUNT_MANAGER_LIST,
          payload
        );
        const filteredManagers = response.data.filter(
          (manager) =>
            manager.account_manager_username &&
            manager.account_manager_username.trim() !== ""
        );
        setManagers(filteredManagers);
      } catch (error) {
        console.error("Error fetching account managers:", error);
      } finally {
        setLoadingManagers(false);
      }
    };

    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await axios.post(ENVIRONMENT.COMPANY_LIST, {});
        const filteredCompanies = response.data.filter(
          (company) =>
            company.company_display_name &&
            company.company_display_name.trim() !== ""
        );

        const uniqueCompanies = filteredCompanies.reduce((acc, current) => {
          const x = acc.find(
            (item) => item.company_display_name === current.company_display_name
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setCompanies(uniqueCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoadingCompanies(false);
      }
    };

    fetchManagers();
    fetchCompanies();
  }, []);

  const handleManagerChange = (value) => {
    setSelectedManagers(value);
  };

  const handleCompanyChange = (value) => {
    setSelectedCompanies(value);
  };

  const resetFilters = () => {
    setSelectedManagers([]);
    setSelectedCompanies([]);
  };

  return (
    <AdminMainLayout selected={"20"}>
      <Row justify="space-between" align="middle" style={{marginTop:'25px',marginBottom:'25px'}}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/back_arrow.png`}
              alt="Arrow Back"
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={handlebackclick}
            />
            <h1 className='manrope'style={{ fontSize: "36px", fontWeight: "900",marginTop:'10px' }}>Sales Funnel</h1>
          </div>
        </Col>
        
        <Col>
          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Filter by Account Manager"
            value={selectedManagers}
            onChange={handleManagerChange}
            style={{ width: 220 }} // Fixed width
            loading={loadingManagers}
            maxTagCount={1} // Show only one selected tag
            maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`} // Placeholder for hidden tags
          >
            {managers.map((manager) => (
              <Option key={manager.account_manager_username} value={manager.account_manager_username}>
                {manager.account_manager_username}
              </Option>
            ))}
          </Select>


          <Select
            mode="multiple"
            allowClear
            placeholder="Filter by Company"
            value={selectedCompanies}
            onChange={handleCompanyChange}
            style={{ width: 220 }} // Fixed width
            loading={loadingCompanies}
            virtual
            maxTagCount={1} // Show only one selected tag
            maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`} // Placeholder for hidden tags
          >
            {companies.map((company) => (
              <Option key={company.company_display_name} value={company.company_display_name}>
                {company.company_display_name}
              </Option>
            ))}
          </Select>


            <Button onClick={resetFilters} type="default">
              Reset Filters
            </Button>
          </div>
        </Col>
      </Row>


      <Tabs defaultActiveKey="1" animated={false}>
        <TabPane tab={`Invitation Sent (${invitationCount})`} key="1" forceRender>
          <InvitedCustomers
            setInvitedSizeCount={setInvitedSizeCount}
            selectedManagers={selectedManagers}
            selectedCompanies={selectedCompanies}
          />
        </TabPane>

        <TabPane tab={`Free Trial (${freeTrialCount})`} key="2" forceRender>
          <FreeTrial
            setFreeTrialSizeCount={setFreeTrialSizeCount}
            selectedManagers={selectedManagers}
            selectedCompanies={selectedCompanies}
          />
        </TabPane>

        <TabPane tab={`Self Subscription (${selfSubscribedCount})`} key="3" forceRender>
          <SelfSubscribedUsers
            setSelfSubscribedSizeCount={setSelfSubscribedSizeCount}
            selectedManagers={selectedManagers}
            selectedCompanies={selectedCompanies}
          />
        </TabPane>

        <TabPane tab={`Enterprise (${enterpriseCount})`} key="4" forceRender>
          <EnterpriseUser
            setEnterpriseSizeCount={setEnterpriseSizeCount}
            selectedManagers={selectedManagers}
            selectedCompanies={selectedCompanies}
          />
        </TabPane>
      </Tabs>
    </AdminMainLayout>
  );
};

export default withRouter(SalesFunnel);
