import React, { useState, useEffect, useContext } from 'react';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import * as Utilities from '../../Utilities';
import FileConstants from '../../../../FileConstants';
import * as Constants from "../Constants";
import {
    Button, Modal, Row, Card, Col
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';


const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");

const ImageListTypePreviews = (props) => {
    return (props.productQAImages && props.productQAImages.filter(image => image.main_type == props.type).length > 0 ?
        <>
            {props.productQAImages.filter(image => image.main_type == props.type).map((image, index) => (
                <Card className={`product-img-grid-card comparison-modal display-flex ${props.selectedProductImage == image.url ? `selected` : ``}`}
                    key={index} onClick={() => { props.selectImage(image.url) }}>
                    <img className='product-img-grid-img ' src={image.low_url} />
                </Card>
            ))}
        </> : "")
}

const ProductSelectionImageModal = () => {
    const { productQAImages, selectedComparisonImage, uploadImage,
        uploadingImageLoader, errorMessage, setErrorMessage, setRefImagePhotoList,
        setSelectedComparisonImage, setSelectComparisonImageModal, selectComparisonImageModal } = useContext(CollaborateQAContext);


    const cancelSelectedComparisonImageModal = () => {
        setSelectComparisonImageModal(false);
        setErrorMessage('');
        setSelectedComparisonImage('');
        setRefImagePhotoList([]);
    }

    const selectForComparison = (url) => {
        setSelectedComparisonImage(url);
    }


    return (
        <Modal
            className="comparison-image-popup"
            destroyOnClose={true}
            closable={true}
            title={<span className="manrope f-14 w-600">Select an image preview for side by side comparison with your uploaded image</span>}
            visible={selectComparisonImageModal}
            onCancel={cancelSelectedComparisonImageModal}
            footer={[
                <>
                    <div className="justify-in-end">
                        <Button className="modal-okay square font-14"
                            disabled={uploadingImageLoader}
                            onClick={uploadImage}>
                            Upload {uploadingImageLoader && <LoadingOutlined />}
                        </Button>
                    </div>
                    {errorMessage && <div className='manrope f-12 red mt-5'>{errorMessage}</div>}
                </>]}>
            <Row>
                <Col md={24} lg={24} xl={24}>
                    <div className='display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list qa w-100'>
                        <ImageListTypePreviews
                            productQAImages={productQAImages}
                            type={"low_res_preview"}
                            selectImage={selectForComparison}
                            selectedProductImage={selectedComparisonImage}
                        />
                    </div>
                </Col>
            </Row>
        </Modal>)

}

export default ProductSelectionImageModal;