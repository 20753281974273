
import { Col, Row, Card } from 'antd';
import React from 'react';
import ComplaintInfo from './ComplaintInfo';
import LifeStyle360 from './ComplaintRenders/Lifestyle360';
import LifeStyleImages from './ComplaintRenders/LifestyleImages';

const ComplaintDetailCard = (props) => {

    const { complaintInfo, baseURL, sceneId, is360, platform } = props
    const { status, issue, beforeAndAfterImg, admin_message, reference_images, submitted_on, id } = complaintInfo

    return (
        <Row className="align-col-left" style={{ marginTop: 20, marginBottom: 20 }}>
            <Col style={{ width: "100%" }}>
                <Card className="complaint-card align-col-left card-shadow-product" bodyStyle={{ minWidth: 40, textAlign: "-webkit-center" }} style={{ marginTop: 10, width: '100%' }} bordered={false}>

                    <div style={{ width: "100%", }} className="justify-in-start complaint-card-details" >
                        <p style={{ paddingLeft: 15, overflow: 'hidden' }} className="manrope f-18 w-700">Issue:</p>
                        <p style={{ paddingLeft: 5, overflow: 'hidden' }} className="manrope f-16 w-500"> {issue}</p>
                    </div>

                    <ComplaintInfo admin_message={admin_message} status={status} reference_images={reference_images} submitted_on={submitted_on} platform={platform}/>

                    {Object.keys(beforeAndAfterImg).map((key) => {
                        return is360 ? <LifeStyle360 baseURL={baseURL} sceneId={sceneId} before={key} after={beforeAndAfterImg[key]} is360={is360} id={id} /> :
                            <LifeStyleImages baseURL={baseURL} sceneId={sceneId} before={key} after={beforeAndAfterImg[key]} is360={is360} id={id} />

                    })}

                </Card>
            </Col>

        </Row>
    )
}

export default ComplaintDetailCard;