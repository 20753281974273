import {
  CREATE_COLLECTION,
  COLLECTION_FAILURE,
  COLLECTION_SUCCESS,
  UPDATE_COLLECTION,
  LIST_COLLECTION,
  FETCH_COLLECTION_PRODUCTS,
  FETCH_COLLECTION_ROOMS,
  DELETE_COLLECTION_PRODUCT
} from '../actions';

const initialState = {
  collection: {},
  collections: [],
  products: [],
  rooms: [],
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  error: null,
};

export default function collectionReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_COLLECTION:
      return {
        collection: { ...action.payload },
        collections: state.collections,
        products: state.products,
        rooms: state.rooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case FETCH_COLLECTION_PRODUCTS:
      return {
        collection: { ...action.payload },
        collections: state.collections,
        products: state.products,
        rooms: state.rooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case FETCH_COLLECTION_ROOMS:
      return {
        collection: { ...action.payload },
        collections: state.collections,
        products: state.products,
        rooms: state.rooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case CREATE_COLLECTION:
      return {
        collection: { ...action.payload },
        collections: state.collections,
        products: state.products,
        rooms: state.rooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case UPDATE_COLLECTION:
      return {
        collection: { ...state.collection },
        collections: state.collections,
        products: state.products,
        rooms: state.rooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case COLLECTION_SUCCESS:
      return {
        collection: { ...state.collection },
        collections: action.payload.collections || state.collections, // action.payload.collections || state.collections,
        products: action.payload.products || state.products,
        rooms: action.payload.rooms || state.rooms,
        isLoading: false,
        isCreated: action.payload.isCreated,
        isUpdated: action.payload.isUpdated,
        error: null,
      };
    case COLLECTION_FAILURE:
      return {
        collection: state.collection,
        collections: state.collections,
        products: state.products,
        rooms: state.rooms,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: action.payload.message,
      };
    case DELETE_COLLECTION_PRODUCT:
      return {
        collection: { ...action.payload },
        collections: state.collections,
        products: action.payload.products || state.products,
        rooms: state.rooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    default:
      return state;
  }
}
