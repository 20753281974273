import React, { useState, useEffect } from "react";
import { Button, Layout, Table, DatePicker, Select, Row, Col, Tooltip } from "antd";
import axios from "axios";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { getNeedToModelString, calcTotalModelsWorked } from "../Utilities";
import ENVIRONMENT from "../../../environments";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;
const DATE_FORMAT = "MM/DD/YYYY";

const { Header, Content, Footer } = Layout;

function AgencyDetails(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [invoiceSummary, setInvoiceSummary] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format(DATE_FORMAT)
  );

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      props.artistUsernames &&
      props.artistUsernames.length > 0
    ) {
      fetchArtistInvoice();
    }
  }, [startDate, endDate]);

  const fetchArtistInvoice = () => {
    let payload = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      artist_usernames: props.artistUsernames,
      artist_invoice: true,
    };
    console.log("payload = ", payload);
    setIsLoading(true);
    axios.post(ENVIRONMENT.GET_ARTIST_INVOICE, payload).then((res) => {
      console.log("invoicing data = ", res);
      setInvoiceData(res.data["invoice_data"]);
      setInvoiceSummary(res.data["summary"]);
      setIsLoading(false);
    });
  };

  
  const getTotalModelsWorked = () => {
    let total_models = 0;
    total_models = invoiceSummary['spaces'] + invoiceSummary['space_variants'] + invoiceSummary['product_variants'] + invoiceSummary['products'] + invoiceSummary['materials'] + invoiceSummary['complaints']
    if(!total_models)
      total_models = 0;
    return total_models;
  }

  const getBillingRateString = () => {
    let billing_rate = "N/A";
    if (props.agencyBillingRate && (props.agencyBillingRate == 0 || props.agencyBillingRate == "")){
      billing_rate = "N/A";
    }
    else if(props.agencyBillingRate){
      billing_rate = "$" + props.agencyBillingRate;
      if (props.agencyBillingType == "hourly")
        billing_rate += " / hour";
      else if(props.agencyBillingType == "per_model")
        billing_rate += " / model";
    }
    return billing_rate;
  }

  const onCalenderChange = (date_values) => {
    if (date_values && date_values[0] && date_values[1]) {
      setStartDate(date_values[0].format(DATE_FORMAT));
      setEndDate(date_values[1].format(DATE_FORMAT));
    }
  };

  const columns = [
    {
      title: <span className="manrope f-16 black-14">Model ID</span>,
      dataIndex: "model_id",
      key: 1,
      render: (text) => <span className="manrope f-14">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Model Name</span>,
      dataIndex: "model_name",
      key: 2,
      render: (text) => <span className="manrope f-14 ">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Model Type</span>,
      dataIndex: "model_type",
      key: 3,
      render: (text, index) => (
        <span className="manrope f-14">
          {text} {index["variant_of"] != null ? " Variation" : ""}{" "}
          {getNeedToModelString(index)}
        </span>
      ),
    },
    {
        title: <span className="manrope f-16 black-14">Artist</span>,
        dataIndex: "artist_username",
        key: 4,
        render: (text) => <span className="manrope f-14">{text}</span>,
      },
    {
      title: <span className="manrope f-16 black-14">Task Type</span>,
      dataIndex: "request_type",
      key: 5,
      render: (text) => <span className="manrope f-14">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Date</span>,
      dataIndex: "date",
      key: 6,
      render: (text) => <span className="manrope f-14 ">{text || "N/A"}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Hours Worked</span>,
      dataIndex: "hours",
      key: 7,
      render: (text) => (
        <span className="manrope f-14">{parseFloat(text || 0) + " Hours"}</span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="content-container" style={{ marginTop: 20 }}>
        <Row>
          <Col span={8}>
            <div
              className="manrope f-24 w-700"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="/img/back_arrow.png"
                onClick={() => props.setViewInvoice(false)}
                style={{ marginRight: 20, cursor: "pointer" }}
              />{" "}
              Agency Invoice
            </div>
          </Col>

          <Col span={10} offset={6}>
            <RangePicker
              style={{ marginRight: 20 }}
              size={"large"}
              defaultValue={[
                moment(startDate, DATE_FORMAT),
                moment(endDate.end_date, DATE_FORMAT),
              ]}
              format={DATE_FORMAT}
              className="date-picker float-right"
              popupStyle={{
                fontFamily: "Manrope",
                fontSize: 14,
                color: "#40a9ff",
                background: "#e6efff",
              }}
              placeholder={["Date (From)", "Date (To)"]}
              value={[
                moment(startDate, DATE_FORMAT),
                moment(endDate, DATE_FORMAT),
              ]}
              onCalendarChange={(val) => onCalenderChange(val)}
            />
          </Col>
        </Row>

        <Row style={{marginTop: 20}}>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={invoiceData}
              bordered={true}
              loading={{
                indicator: <LoadingOutlined />,
                spinning: isLoading,
              }}
            />
          </Col>
        </Row>

        <Row style={{display: 'block'}}>
            <span className="manrope f-20">Invoice Summary</span>
            <Row className="artist-details-info" style={{marginBottom: 20, marginTop :20}}>
              <Col span={6}>
                <div className="manrope f-16">
                  <p className="artist-details-heading">Total Hours Worked</p>
                  <p className="artist-details-value">{invoiceSummary['hours'] ? invoiceSummary['hours'] + ' Hours' : '0 Hours'}</p>
                </div>
              </Col>

              <Col span={6}>
              <div className="manrope f-16">
                    <p className="artist-details-heading">Total Models Made
                    {getTotalModelsWorked() > 0 ?
                    <Tooltip title={<span className='manrope f-12 white'>
                      {invoiceSummary['spaces'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Spaces: {invoiceSummary['spaces']}</span> : ''
                      }
                      {invoiceSummary['space_variants'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Space Variants: {invoiceSummary['space_variants']}</span> : ''
                      }
                      {invoiceSummary['products'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Products: {invoiceSummary['products']}</span> : ''
                      }
                      {invoiceSummary['product_variants'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Product Variants: {invoiceSummary['product_variants']}</span> : ''
                      }
                      {invoiceSummary['complaints'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Complaints: {invoiceSummary['complaints']}</span> : ''
                      }
                      {invoiceSummary['materials'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Materials: {invoiceSummary['materials']}</span> : ''
                      }
                    </span>}>
                      <InfoCircleOutlined style={{marginLeft: 3}}/>
                    </Tooltip> : ''}
                    </p>
                    <p className="artist-details-value">{getTotalModelsWorked()}</p>
                  </div>
              </Col>

              <Col span={6}>
                <div className="manrope f-16">
                  <p className="artist-details-heading">Billing Rate</p>
                  <p className="artist-details-value">{getBillingRateString()}</p>
                </div>
              </Col>

              <Col span={6}>
                <div className="manrope f-16">
                  <p className="artist-details-heading">Total Amount Invoiced</p>
                  <p className="artist-details-value">{invoiceSummary['amount'] || '$0.0'}</p>
                </div>
              </Col>
            </Row>
          </Row>
      </div>
    </React.Fragment>
  );
}

export default AgencyDetails;
