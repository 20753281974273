import React, { useEffect, useRef, useState } from "react";
import './ProjectsHome.scss'
import CustomerMainLayout from "../CustomerMainLayout";
import FileConstants from '../../../../FileConstants';
import { Col, Row, Skeleton, Card, Button, Divider, Tabs, Tooltip, Carousel, Empty, Menu, Dropdown } from "antd";
import ENVIRONMENT from '../../../../environments';
import axios from "axios";
import { ReactComponent as ProductIcon } from '../../../../icons/home-product.svg'
import { ReactComponent as SpaceIcon } from '../../../../icons/home-space.svg'
import { ReactComponent as SceneIcon } from '../../../../icons/home-scene.svg'
import { ReactComponent as ProjectIcon } from '../../../../icons/home-project.svg'
import { ReactComponent as FlagIcon } from '../../../../icons/home-flag.svg'
import { ArrowRightOutlined, CaretLeftOutlined, CaretRightOutlined, LoadingOutlined, FilterOutlined, DownOutlined } from "@ant-design/icons";
import { Pie } from "@ant-design/charts";
import * as Utilities from '../../Utilities'
import MaterialDetailModal from "../MaterialDetailModal/MaterialDetailModal";
import _ from "lodash";
import ProductAssetModals from "../ProductComponents/ProductAssetModals";
import CustomerChangeLog from '../CustomerChangeLog/CustomerChangeLog';
import RingPieChart from './RingPieChart';
import { getProductRender } from '../../../../redux/actions';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import ProductsCarousel from "./ProductsCarousel";
const { TabPane } = Tabs;
const { SubMenu } = Menu

let CUSTOMER_USERNAME = localStorage.getItem('username');
let COMPANY_ID = localStorage.getItem('company_id');
let SHARED_ENTITIES = localStorage.getItem('shared_entities');
let IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_SHARED_ENTITIES = localStorage.getItem('managed_shared_entites')
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_COMPANY_NAME = localStorage.getItem('managed_company_name')

const EntityDisplayName = {
    product : "Product",
    space : "Space",
    scene : "Scene",
    project : "Active Project",
    material : "Material"
};

const AssetTypes = {
    silo: 'Silo Image',
    lifestyle: 'Lifestyle Image',
    threesixty: '360°',
    mp4video: 'MP4 Video'
};

const AssetNames = {
    silo: 'Silos',
    lifestyle: 'Lifestyles',
    threesixty: '360°',
    mp4video: 'MP4 Videos',
    Images: 'Images',
    "In Progress": "In Progress"
};

const ResolutionTypes = {
    "1K" : "1K_count",
    "2K" : "2K_count",
    "4K" : "4K_count",
    "Custom": "Custom"
};

const StateDisplayName = {
    "active" : "Active Projects",
    "requested" : "Requested Projects",
};

const EntityStateDisplayName = {
    "in-review" : "To Review",
    "in-progress" : "Submitted",
    "drafts" : "Drafts",
};

const ActiveModelsHoverColor = {
    product : '#4764c5',
    space : '#cc9d30',
    material : '#B37FEB',
    silo: '#279eff',
    lifestyle: '#b2e320',
    threesixty: '#ffe70a',
    mp4video: '#ff682c'
}

const EntityIcon = {
    product : <ProductIcon/>,
    space : <SpaceIcon/>,
    scene : <SceneIcon/>,
    project : <ProjectIcon/>
};

const EntityColor = {
    product : '#597EF7',
    space : '#FFC53D',
    material : '#B37FEB',
    silo: '#40A9FF',
    lifestyle: '#BAE637',
    threesixty: '#FFEC3D',
    mp4video: '#FF7A45'
};

const ProjectKeys = {
    active : "active",
    requested : "requested",
};

let AssetTypesVisible = {
    silo: false,
    lifestyle: false,
    threesixty: false,
    mp4video: false
};

const ActiveModelsKeys = {
    review : "in-review",
    progress : "in-progress",
    drafts : "drafts"
}

const ActiveModels = (props) => {
    return (
        <Card className="ring-card-height">
            <Row style={{ marginTop: 20, marginLeft: 20, marginRight: 20, zIndex: 1 }} className="justify-in-center">
                <Col span={20}>
                    <div className="stats-txt-heading">{props.heading}</div>
                    <p className="info-txt">{props.info}</p>
                </Col>
                <Col span={4} className="justify-in-end" style={{ marginTop: -25, zIndex: 2 }}>
                    <a onClick={() => props.goToActiveModels(props.type)} className="view-btn">View All</a>
                </Col>
                <Col span={24} style={{ marginTop: -80, zIndex: 0 }}>
                    <RingPieChart
                    data={props.data}
                    content={EntityStateDisplayName[props.type]}
                    getCumulativeCount={props.getCumulativeCount}/>
                </Col>
            </Row>
        </Card>
    );
};

const ProjectsHome = (props) => {
    const [isActive, setIsActive] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isDetailsLoading, setIsDetailsLoading] = useState(true);
    const [reviewBanner, setReviewBanner] = useState(false);
    const [homeStats, setHomeStats] = useState({});
    const [homeDetails, setHomeDetails] = useState({});
    const [projectsTabKey, setProjectsTabKey] = useState(ProjectKeys.active);
    const carouselRef = useRef(null);
    const [itemIndex, setItemIndex] = useState(0);
    const [materialModal, setMaterialModal] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [productsData, setProductsData] = useState([]);
    const [recentScenes, setRecentScenes] = useState([]);
    const [sceneLoader, setSceneLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(props.match.path)
    const [activeData, setActiveData] = useState([]);
    const [requestedData, setRequestedData] = useState([]);
    const [isProjectDataLoading, setIsProjectDataLoading] = useState(false);
    const [resolutionCount, setResolutionCount] = useState(null);
    const [activeProjectCount, setActiveProjectCount] = useState(0);
    const [approvedProjectCount, setApprovedProjectCount] = useState(0);
    const [requestedProjectCount, setRequestedProjectCount] = useState(0);
    const [modelsTabKey, setModelsTabKey] = useState(ActiveModelsKeys.review);
    const [currentProductId, setCurrentProductId] = useState(0);
    const [productBasicInfo, setProductBasicInfo] = useState(null);
    const [selectedAssetModal, setSelectedAssetModal] = useState(null);

    const handleCreateNewFormNavigation = (e) => {
        if (e.key === "create_existing_model") {
            window.location.href = '/upload-product-model?origin=' + currentPage;
        }
        if (e.key === "create_from_empty_scene") {
            window.location.href = '/create-empty-scene?origin=' + currentPage;
        }
        if (e.key === "create_from_template") {
            window.location.href = '/create-scene-from-template?origin=' + currentPage;
        }
        if (e.key === "create_new_material") {
            window.location.href = '/create-new-material?origin=' + currentPage;
        }
        if (e.key === "create_from_scanned_material") {
            window.location.href = '/upload-scanned-material?origin=' + currentPage;
        }
        if (e.key === "create_physical_product") {
            window.location.href = '/create-existing-physical-product?origin=' + currentPage;
        }
        if (e.key === "create_design_new_product") {
            window.location.href = '/add-new-product?origin=' + currentPage;
        }
    };

    const ModelCreateSubMenus = {
        product: <Menu triggerSubMenuAction="hover">
                    <SubMenu title={<span>Create New Model</span>} popupOffset={[0, 0]} >
                        <Menu.Item key="create_physical_product" onClick={handleCreateNewFormNavigation}>
                            <span>Existing Physical Product</span>
                        </Menu.Item>
                        <Menu.Item key="create_design_new_product" onClick={handleCreateNewFormNavigation}>
                            <span>Design A New Product</span>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="create_existing_model" onClick={handleCreateNewFormNavigation}>
                        <span>Upload Existing Model</span>
                    </Menu.Item>
                </Menu>,
    
        scene: <Menu triggerSubMenuAction="hover">
                    <Menu.Item key="create_from_empty_scene" onClick={handleCreateNewFormNavigation}>
                        <span>From Empty Scene</span>
                    </Menu.Item>
                    <Menu.Item key="create_from_template" onClick={handleCreateNewFormNavigation}>
                        <span>From Template</span>
                    </Menu.Item>
                </Menu>
    };

    const getHomeStats = () => {
        let payload = {
            username: CUSTOMER_USERNAME,
            project_homepage: true
        };
        if (COMPANY_ID != undefined) {
            payload.company_id = COMPANY_ID;
            payload.shared_entities = SHARED_ENTITIES;
        };
        
        if(IS_MS_PROVIDER){
            if(MANAGED_CUSTOMER_USERNAME){
                payload['username'] = MANAGED_CUSTOMER_USERNAME
                payload['shared_entities'] = MANAGED_SHARED_ENTITIES
            }
            if(MANAGED_COMPANY_ID){
                payload['company_id'] = MANAGED_COMPANY_ID
            }
        }
        // Commenting for now, call the API and then set the data.
        axios.post(ENVIRONMENT.HOME_SCREEN_STATS, payload).then((res) => {
            console.log("Home Screen Stats:", res.data);
            setApprovedProjectCount((res.data.library_data.find(ele => ele.type === "project"))?.count)
            let approved_project = {
                "type": "approved",
                "count": (res.data.library_data.find(ele => ele.type === "project"))?.count
            }
            res.data.library_data = res.data.library_data.filter(ele => ele.type !== "project")
            setActiveProjectCount((res.data.review_data.find(ele => ele.type === "project"))?.count)
            let active_project = {
                "type": "active",
                "count": (res.data.review_data.find(ele => ele.type === "project"))?.count
            }
            res.data.review_data = res.data.review_data.filter(ele => ele.type !== "project")
            setRequestedProjectCount((res.data.in_progress_data.find(ele => ele.type === "project"))?.count)
            let requested_project = {
                "type": "requested",
                "count": (res.data.in_progress_data.find(ele => ele.type === "project"))?.count
            }
            res.data.in_progress_data = res.data.in_progress_data.filter(ele => ele.type !== "project")
            setHomeStats(res.data);
            let resolution = {
                "1K_count": res.data["1K_count"],
                "2K_count": res.data["2K_count"],
                "4K_count": res.data["4K_count"],
                "Custom": res.data["Custom"],
            }
            
            //Setting Entity Counts in localStorage
            let entity_counts = _.cloneDeep(res.data.library_data);
            
            entity_counts.push(approved_project)
            entity_counts.push(active_project)
            entity_counts.push(requested_project)
            
            localStorage.setItem('entity_counts', JSON.stringify(entity_counts));
            
            setResolutionCount(resolution)
            setIsActive(false);
            setIsLoading(false);
            getHomeDetails();
            fetchProductData();
            fetchAllProjectData();
        });
    };

    const getHomeDetails = () => {
        let payload = {
            username: CUSTOMER_USERNAME,
        };
        if (COMPANY_ID != undefined) {
            payload.company_id = COMPANY_ID;
            payload.shared_entities = SHARED_ENTITIES;
        };

        if(IS_MS_PROVIDER){
            if(MANAGED_CUSTOMER_USERNAME){
                payload['username'] = MANAGED_CUSTOMER_USERNAME
                payload['shared_entities'] = MANAGED_SHARED_ENTITIES
            }
            if(MANAGED_COMPANY_ID){
                payload['company_id'] = MANAGED_COMPANY_ID
            }
        }

        axios.post(ENVIRONMENT.HOME_SCREEN_DETAILS, payload).then((res) => {
            console.log("Home Screen Details:", res.data);
            setHomeDetails(res.data);
            if(res.data.details.your_scenes){
                setRecentScenes(res.data.details.your_scenes)
            }
            setSceneLoader(false)
            setIsDetailsLoading(false);
        });
    };

    const fetchAllProjectData = async () => {
        setIsProjectDataLoading(true);
        await getProjectDetails('active');
        await getProjectDetails('requested');
        setIsProjectDataLoading(false);
    };

    const getProjectPayload = (type) => {
        let status = "";
        if (type === "requested") {
        status = "status__exact='in_progress'"
        } else {
        status = "status__in=['in_review', 'ready_for_scenes']"
        }
        let payload = {
          order_by: "last_modified_stamp desc",
          required_fields: [
            "id",
            "name",
            "customer_username",
            "thumbnail",
            "company_id",
            "meta_data",
            "status",
            "last_modified",
            "last_modified_stamp"
          ],
          filter_string: `(${status}&&username__exact='${MANAGED_CUSTOMER_USERNAME ? MANAGED_CUSTOMER_USERNAME : CUSTOMER_USERNAME}')&&(access_level__in=['owner','viewer','co_owner','quality_control', 'editor'])&&(is_hidden__not=true)`,
        };
        payload['pagination_filters'] = {
            'limit': 6,
            'offset': 0
        }
    
        return payload;
    };

    const getProjectDetails = async (type) => {
        let payload = getProjectPayload(type);
        await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
            switch(type) {
                case 'active':
                  setActiveData(res.data);
                  break;
                case 'requested':
                  setRequestedData(res.data);
                  break;
            }
        })
    };

    useEffect(() => {
        CUSTOMER_USERNAME = localStorage.getItem('username');
        COMPANY_ID = localStorage.getItem('company_id');
        SHARED_ENTITIES = localStorage.getItem('shared_entities');
        IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

        if(CUSTOMER_USERNAME){
            getHomeStats();
        }
    }, []);

    const goToProjects = (type) => {
        window.location.href = `/projects?tab=${type}?origin=${currentPage}`;
    };

    const handleTabChange = (key) => {
        setProjectsTabKey(key);
    };

    const handleModelTabChange = (key) => {
        setModelsTabKey(key);
    };

    const onClickActiveModels = (id, type, entity) => {
        if (type === 'Product') {
            if (modelsTabKey === ActiveModelsKeys.drafts) {
                if (entity.uploaded_model && entity.model_status === "-6") {
                    window.location.href = `/product_page/${id}?origin=${currentPage}`;
                } else if (entity.uploaded_model) {
                    window.location.href = `/upload-product-model/${id}?uploaded-model=true&origin=${currentPage}`;
                } else if (entity.designed_product) {
                    window.location.href = `/add-new-product/${id}?origin=${currentPage}`;
                } else {
                    window.location.href = `/create-existing-physical-product/${id}?origin=${currentPage}`;
                }
            } else {
                if (entity.model_status === "4") {
                    window.location.href = `/product-qa/${id}?origin=${currentPage}`;
                } else {
                    window.location.href = `/product_page/${id}?origin=${currentPage}`;
                }
            }
        } else if (type === "Space") {
            if (modelsTabKey === ActiveModelsKeys.drafts) {
              window.location.href = `/add-new-room?step=0&room_id=${id}&origin=${currentPage}`;
            } else {
                window.location.href = `/room-qa/${id}?origin=${currentPage}`;
            }
        }else if (type === 'Material') {
            if (modelsTabKey === ActiveModelsKeys.drafts) {
                if (entity.is_scanned) {
                    window.location.href = `/upload-scanned-material/${id}?origin=${currentPage}`;
                } else {
                    window.location.href = `/create-new-material/${id}?origin=${currentPage}`;
                }
            } else {
                setMaterialModal(true);
                setSelectedMaterial('');
                let payload = {
                    material_id: id
                };
                axios.post(ENVIRONMENT.MATERIAL_GET, payload)
                .then(res => {
                    let material_data = JSON.parse(res.data.body);
                    setSelectedMaterial(material_data);
                });
            }
        }
    };

    const getCumulativeCount = (response) => {
        let count = 0;
        response.filter((item) => {
          return (
            (item.count ? (count = count + item.count) : 0)
          );
        });
        return count;
    };

    const getThumbnail = (ele) => {
        let uri = 'projects/thumbnail/';
        if (ele.thumbnail !== '') {
            return ENVIRONMENT.getBaseURL('google') + uri + ele.thumbnail;
        }
    };

    const getModelThumbnail = (ele) => {
        let uri = '';
        if (ele.type === 'Product') {
            uri = 'product_assets/thumbnail/'
        } else if (ele.type === 'Space') {
            uri = `room_assets/thumbnail/${ele.id}/`
        }
        if (ele.thumbnail != "") {
            return ENVIRONMENT.getBaseURL(ele.platform) + uri + ele.thumbnail;
        }
    };

    const getCollaborationThumbnail = (element) => {
        let thumbnail_link = "";
        if (element.thumbnail === "") {
            thumbnail_link = ENVIRONMENT.getBaseURL(element.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + element.scene_id + '/' + element.camera_name.replace('tiff', 'jpg');
        } else {
            thumbnail_link = ENVIRONMENT.getBaseURL(element.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + element.scene_id + '/' + element.thumbnail.replace('tiff', 'jpg');
        }
        return thumbnail_link;
    };

    const getSceneThumbnail = (ele) => {
        let thumbnail_link;
        if (ele.thumbnail != "") {
            thumbnail_link = ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + ele.id + '/' + ele.thumbnail;
        }
        return thumbnail_link;
    };

    const onProjectClick = (id, type) => {
        props.history.push(`/projects/${id}?tab=${type}`);
    };

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
            let currentIndex = itemIndex;
            setItemIndex(currentIndex-=1)
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
            let currentIndex = itemIndex;
            setItemIndex(currentIndex+=1)
        }
    };

    const getAssetMessage = (asset) => {
        if (asset.type === 'Product') {
            if (asset.customer_username === CUSTOMER_USERNAME) {
                return "From your product"
            } else {
                return `From ${asset.customer_username}'s product`
            }
        } else if (asset.type === 'Scene') {
            if (asset.customer_username === CUSTOMER_USERNAME) {
                return "From your scene"
            } else {
                return `From ${asset.customer_username}'s scene`
            }
        }
    };

    const getAssetThumbnail = (asset) => {
        let uri = '';
        if (asset.type === 'Product') {
            uri = 'product_assets/thumbnail/'
        } else if (asset.type === 'Scene') {
            uri = `scene_renders/${asset.id}/`
        }
        if (asset.thumbnail != "") {
            return ENVIRONMENT.getBaseURL(asset.platform) + uri + asset.thumbnail;
        }
    };

    const closeMaterialModal = () => {
        setMaterialModal(false);
    };

    const onClickNewEntity = (type) => {
        if (type === 'product') {
            window.location.href = `/product-model?origin=${currentPage}`;
        } else if (type === 'space') {
            window.location.href = `/add-new-room?origin=${currentPage}`;
        } else if (type === 'scene') {
            window.location.href = `/create-empty-scene?origin=${currentPage}`;
        } else if (type === 'project') {
            window.location.href = `/new_project?origin=${currentPage}`;
        }
    };

    const onClickGoToLibrary = (type) => {
        if (type == 'product') {
            window.location.href = `/list-products?origin=${currentPage}`;
        } else if (type == 'space') {
            window.location.href = `/rooms?origin=${currentPage}`;
        } else if (type == 'scene') {
            window.location.href = `/my_scenes?origin=${currentPage}`;
        } else if (type == 'project') {
            window.location.href = `/projects?tab=active&origin=${currentPage}`;
        }
    };

    const getProductPayload = (current_username, current_company_id, shared_entities) => {
        let payload = {
          "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
          "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
          "is_store_item", "group_id", "dimensions", "company_id", "color_name", "materials", 
          "platform", "style_category", "gtin", "tags", "product_model_type", 
          "user_render_count"],
        } 
        payload["order_by"] = "last_modified_stamp desc"
        let filter_string = "";
        let asset_username = "asset_username__exact='" + current_username + "'||asset_username__isnull=true";
        let user_render_count = 'user_render_count__isnull=true'
        let asset_company = "";
        let customer_username = "customer_username__exact='" + current_username + "'"
    
        let company_id = "";
        let model_status = "model_status__in=[5]"
        let is_hidden = "is_hidden__not=true"
        if (current_company_id) {
          payload['required_fields'].push('company_render_count');
          asset_company = "&&(asset_company__exact=" + current_company_id + "||asset_company__isnull=true)&&(company_render_count__isnull=true)";
        }
        if (shared_entities != undefined && current_company_id && shared_entities.split("_").includes("product")) {
          company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
          filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&("+asset_username+")&&("+user_render_count+")"+asset_company
        } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&("+asset_username+")&&("+user_render_count+")"+asset_company
        }
        filter_string = filter_string + "&&(" + model_status + ")"
        payload['filter_string'] = filter_string
        payload['pagination_filters'] = {
            'limit': 8,
            'offset': 0
        }
        return payload;
    };

    const fetchProductData = () => {
        let customer_username = MANAGED_CUSTOMER_USERNAME ? MANAGED_CUSTOMER_USERNAME : CUSTOMER_USERNAME
        let company_id = MANAGED_COMPANY_ID ? MANAGED_COMPANY_ID : COMPANY_ID
        let shared_entities = MANAGED_SHARED_ENTITIES ? MANAGED_SHARED_ENTITIES : SHARED_ENTITIES

        let payload = getProductPayload(customer_username, company_id, shared_entities);
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
        .then(res => {
            if (res.data) {
                let products = Utilities.getProductsDataWithKeywords(res.data);
                setProductsData(products);
            }
        });
    };

    const LibraryCountView = (props) => {
        return (
            <Row gutter={[20,20]}>
                {props.data.map ( entity =>
                <Col span={6} style={{ marginTop: 15}}>
                    <Card>
                        <Row style={{ marginTop: 15, marginLeft: 20, marginRight: 20, marginBottom: 25 }}>
                            <Col span={2} style={{ marginRight: 5 }}>
                                <div>{EntityIcon[entity.type]}</div>
                            </Col>
                            <Col span={17} style={{ paddingLeft:5 }}>
                                <h2 className="manrope f-14 justify-in-start">{EntityDisplayName[entity.type]}s</h2>
                            </Col>
                            <Col span={4} className="justify-in-end">
                                <Tooltip title={`Go to ${EntityDisplayName[entity.type]} Library`}>
                                    <ArrowRightOutlined style={{ color:"#276DD7", fontSize: 16 }} onClick={() => onClickGoToLibrary(entity.type)}/>
                                </Tooltip>
                            </Col>
                            <Col span={24} style={{ marginTop: 10 }}>
                                <p className="manrope f-30 w-500 black line-120">{entity.count== -1 ? 0 : entity.count}</p>
                            </Col>
                            <Col span={24}>
                                {ModelCreateSubMenus[entity.type] ? 
                                    <Dropdown overlay={ModelCreateSubMenus[entity.type]} trigger={['hover']}>
                                        <Button className="add-model-button">+&nbsp;&nbsp;Create New {EntityDisplayName[entity.type]}</Button>
                                    </Dropdown>
                                    :
                                    <Button className="add-model-button" onClick={() => onClickNewEntity(entity.type)}>+&nbsp;&nbsp;Create New {EntityDisplayName[entity.type]}</Button>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                )}
                <Col span={6} style={{ marginTop: 15}}>
                    <Card>
                        <Row style={{ marginTop: 15, marginLeft: 20, marginRight: 20, marginBottom: 25 }}>
                            <Col span={2} style={{ marginRight: 5 }}>
                                <div>{EntityIcon['project']}</div>
                            </Col>
                            <Col span={17}>
                                <h2 className="manrope f-14 justify-in-start">{EntityDisplayName['project']}s</h2>
                            </Col>
                            <Col span={4} className="justify-in-end">
                                <Tooltip title={`Go to ${EntityDisplayName['project']}s`}>
                                    <ArrowRightOutlined style={{ color:"#276DD7", fontSize: 16 }} onClick={() => onClickGoToLibrary('project')}/>
                                </Tooltip>
                            </Col>
                            <Col span={24} style={{ marginTop: 10 }}>
                                <p className="manrope f-30 w-500 black line-120">{activeProjectCount}</p>
                            </Col>
                            <Col span={24}>
                                {ModelCreateSubMenus['project'] ? 
                                    <Dropdown overlay={ModelCreateSubMenus['project']} trigger={['hover']}>
                                        <Button className="add-model-button">+&nbsp;&nbsp;Create New Project</Button>
                                    </Dropdown>
                                    :
                                    <Button className="add-model-button" onClick={() => onClickNewEntity('project')}>+&nbsp;&nbsp;Create New Project</Button>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        )
    };

    const SkeletonView = () => {
        const avaterSize = 200;
        const avatarShape = "square";
        const colStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
        return (
            <Row gutter={[10,10]} style={{marginTop: 20}} >
                <Col span={6} style={colStyle}>
                    <Skeleton.Avatar active={isActive} shape={avatarShape} size={avaterSize}></Skeleton.Avatar>
                </Col>
                <Col span={6} style={colStyle}>
                    <Skeleton.Avatar active={isActive} shape={avatarShape} size={avaterSize}></Skeleton.Avatar>
                </Col>
                <Col span={6} style={colStyle}>
                    <Skeleton.Avatar active={isActive} shape={avatarShape} size={avaterSize}></Skeleton.Avatar>
                </Col>
                <Col span={6} style={colStyle}>
                    <Skeleton.Avatar active={isActive} shape={avatarShape} size={avaterSize}></Skeleton.Avatar>
                </Col>
            </Row>
        );
    };

    const ProjectActivity = (props) => {
        return (
            <Card className="ring-card-height">
                <Row style={{ marginTop: 20, marginLeft: 20, marginRight: 20, zIndex: 1 }} className="justify-in-center">
                    <Col span={20}>
                        <div className="stats-txt-heading">{props.heading}</div>
                        <p className="info-txt">{props.info}</p>
                    </Col>
                    <Col span={4} className="justify-in-end" style={{ marginTop: -25, zIndex: 2 }}>
                        <a onClick={() => goToProjects(props.type)} className="view-btn">View All</a>
                    </Col>
                    <Col span={24} style={{ marginTop: -80, zIndex: 0 }}>
                        <RingPieChart
                        data={props.data}
                        project_count={props.project_count}
                        content={StateDisplayName[props.type]}/>
                    </Col>
                </Row>
            </Card>
        );
    };

    const getProjectMetaData = (metadata, type) => {
        if (type === 'models') {
            let product_count = metadata.product_count ? `${metadata.product_count} ${metadata.product_count > 1 ? 'products' : 'product'}` : '';
            let space_count = metadata.room_count ? `${metadata.room_count} ${metadata.room_count > 1 ? 'spaces' : 'space'}` : '';
            let scene_count = metadata.scene_count ? `${metadata.scene_count} ${metadata.scene_count > 1 ? 'scenes' : 'scene'}` : '';
            if (product_count === '' && space_count === '' && scene_count === '') {
                return 'None';
            } else {
                return `${product_count}   ${space_count}   ${scene_count}`;
            }
        }
        if (type === 'renders') {
            let lifestyle = metadata.lifestyle_count;
            let lifestyle_count = 0;
            for (const key in lifestyle) {
                if (lifestyle.hasOwnProperty(key)) {
                    lifestyle_count += lifestyle[key];
                }
            }

            let silo = metadata.silo_count;
            let silo_count = 0;
            for (const key in silo) {
                if (silo.hasOwnProperty(key)) {
                    silo_count += silo[key];
                }
            }

            if (lifestyle_count === 0 && silo_count === 0) {
                return 'None';
            } else {
                let s_count = silo_count ? `${silo_count} ${silo_count > 1 ? 'silos' : 'silo'}` : '';
                let l_count = lifestyle_count ? `${lifestyle_count} ${lifestyle_count > 1 ? 'lifestyles' : 'lifestyle'}` : '';
                return `${s_count} ${l_count}`;
            }
        }
    };
 
    const ProjectsTab = (props) => {
        return (
            isProjectDataLoading ? <LoadingOutlined style={{ fontSize: 50, marginTop: 20, marginBottom: 20 }} className="loading-center"/> :
            props.data.length <= 0 ? 
            <Empty
            style={{ marginTop: 20, marginBottom: 20 }}
            description={`No ${StateDisplayName[props.type]}`}
            className="manrope f-14"
            /> :
            props.data.map (entity => 
                <Card style={{ marginTop: 10 }}>
                    <a onClick={() => { onProjectClick(entity.id, props.type) }}>
                        <Row style={{ marginRight: 20 }}>
                            <Col span={4}>
                                <img
                                style={{ objectFit: "fill", width: "100%", height: "100%", zIndex:1 }}
                                src={getThumbnail(entity) ? getThumbnail(entity) : 
                                require(`../../../../icons/home-project.svg`)}/>
                            </Col>
                            <Col span={20} >
                                <Row style={ { marginLeft: '2%', marginTop: 10, marginBottom: 10 } }>
                                    <Col span={3} className="justify-in-start">
                                        <span className={`model-type-btn black`}>Project</span>
                                    </Col>
                                    <Col span={17} className="justify-in-start" style={{ marginTop: 10,marginLeft:'4%' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <Tooltip title={entity.name}>
                                                <p className="info-text size-14 black">{entity.name}</p>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                    <Col span={3} className="justify-in-end">
                                        <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(entity.last_modified, 'tooltip')}</span>}>                                                  
                                            <span className="manrope f-12 grey-77 w-500 time-clock-bg">
                                                <img src='/img/carbon_time.svg' style={{marginRight: 2}}/>{Utilities.timestampToTimeSince(entity.last_modified)}
                                            </span>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row style={ { marginLeft: 10 } }>
                                    <Col span={props.type === 'active' ? 9 : 18}>
                                        <div style={{ display: 'inline-block',marginLeft:"2%"}}>
                                            <span className="info-text size-14 light-gray">Models</span>
                                            <p className="info-text size-14 black limit-line w-350">{getProjectMetaData(entity.meta_data, 'models')}</p>
                                        </div>
                                    </Col>
                                    {props.type === 'active' ?
                                    <Col span={9}>
                                        <div style={{ display: 'inline-block' ,marginLeft:"35%"}}>
                                            <span className="info-text size-14 light-gray">Images</span>
                                            <p>
                                                <span className="info-text size-14 black limit-line w-350">
                                                    {getProjectMetaData(entity.meta_data, 'renders')}
                                                    {getProjectMetaData(entity.meta_data, 'renders') !== 'None' ?
                                                        <Tooltip title={
                                                            <Row>
                                                                {entity.meta_data.silo_count && 
                                                                    <Col span={12} className="justify-in-start">
                                                                        <span style={{marginLeft: 5}}>
                                                                            <span className='manrope f-12 black w-600' style={{marginBottom: 10}}>
                                                                                Silos
                                                                            </span>
                                                                            {
                                                                            Object.keys(entity.meta_data.silo_count).map(key => (
                                                                                <div className="justify-in-start" style={{ textAlign: "right" }}>
                                                                                    <span className='manrope f-12' style={{color: "#276DD7", marginRight: 15, marginTop: 8}}>{entity.meta_data.silo_count[key]}</span>
                                                                                    <span className='manrope f-12 black' style={{marginTop: 8}}>{key}</span>
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </span>
                                                                    </Col>
                                                                }
                                                                {entity.meta_data.lifestyle_count &&
                                                                    <Col span={12} className="justify-in-start">
                                                                        <span style={{marginRight: 20, marginLeft: 10}}>
                                                                            <span className='manrope f-12 black w-600' style={{marginBottom: 10}}>
                                                                                Lifestyles
                                                                            </span>
                                                                            {
                                                                            Object.keys(entity.meta_data.lifestyle_count).map(key => (
                                                                                <div className="justify-in-start" style={{ textAlign: "left" }}>
                                                                                    <span className='manrope f-12' style={{color: "#276DD7", marginRight: 15, marginTop: 8}}>{entity.meta_data.lifestyle_count[key]}</span>
                                                                                    <span className='manrope f-12 black' style={{marginTop: 8}}>{key}</span>
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </span>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        }
                                                        color={"white"}>
                                                            <img src='/img/info-blue.svg' style={{marginLeft: 3, marginBottom: 2}}/>
                                                        </Tooltip>
                                                        : ''}
                                                </span>
                                            </p>
                                        </div>
                                    </Col> : '' }
                                    {entity.meta_data.comment_count && entity.meta_data.comment_count > 0 ?
                                        <Col span={6} className="comment-col">
                                            <Tooltip title={<span className='manrope f-12 white'>This project has {entity.meta_data.comment_count} comment(s).</span>}>
                                                <div className='comment-annotation-circle'>
                                                    <img src='/img/collaborate_tool/comment.svg'  alt='comment icon'></img>
                                                    <div className='comment-count'>{entity.meta_data.comment_count}</div>
                                                </div>
                                            </Tooltip>
                                        </Col> : ''
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </a>
                </Card>
            )
        );
    };

    const ActiveModelsTab = (props) => {
        return (
            isDetailsLoading ? <LoadingOutlined style={{ fontSize: 50, marginTop: 20, marginBottom: 20 }} className="loading-center"/> :
            props.data.length <= 0 ? 
            <Empty
            style={{ marginTop: 20, marginBottom: 20 }}
            description={`No Models in ${EntityStateDisplayName[props.type]}`}
            className="manrope f-14"
            /> :
            props.data.map (entity => 
                <Card style={{ marginTop: 10 }}>
                    <a onClick={() => { onClickActiveModels(entity.id, entity.type, entity) }}>
                        <Row style={{ marginRight: 20 }}>
                            <Col span={3}>
                                <img
                                style={{ objectFit: "contain", width: "80px", height: "80px", zIndex:1 }}
                                src={getModelThumbnail(entity) ? getModelThumbnail(entity) : 
                                require(`../../../../assets/images/${entity.type === "Product" ? "product" : entity.type === "Space" ? "space" : "material"}_incomplete.svg`)}/>
                            </Col>
                            <Col span={3} className="justify-in-start">
                                <span className={`model-type-btn ${entity.type === "Product" ? "blue" : entity.type === "Space" ? "yellow" : "red"}`}>{entity.type}</span>
                            </Col>
                            <Col span={11} className="justify-in-start" style={{ marginTop: 10 }}>
                                <div style={{ display: 'inline-block' }}>
                                    <Tooltip title={entity.name}>
                                        <span className="info-text size-14 light-gray">Name</span>
                                        <p className="info-text size-14 black limit-line w-350">{entity.name}</p>
                                    </Tooltip>
                                </div>
                            </Col>
                            <Col span={4} className="justify-in-start" style={{ marginTop: 10 }}>
                                <div>
                                    <Tooltip title={entity.category}>
                                        <span className="info-text size-14 light-gray">Category</span>
                                        <p className="info-text size-14 black limit-line w-350">{entity.category}</p>
                                    </Tooltip>
                                </div>
                            </Col>
                            <Col span={3} className="justify-in-end">
                                <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(entity.last_modified, 'tooltip')}</span>}>                                                  
                                    <span className="manrope f-12 grey-77 w-500 time-clock-bg"  style={{marginLeft: 4}}>
                                        <img src='/img/carbon_time.svg'  style={{marginRight: 2}}/>{Utilities.timestampToTimeSince(entity.last_modified)}
                                    </span>
                                </Tooltip>
                            </Col>
                        </Row>
                    </a>
                </Card>
            )
        );
    };

    const MultiPartHorizontalLine = (props) => {
        const total = getCumulativeCount(props.data);
        const [tooltipVisible, setTooltipVisible] = useState(AssetTypesVisible);
        const [assetResolutionCount, setAssetResolutionCount] = useState(_.cloneDeep(props.resolutionCount));
        const [assetCount, setAssetCount] = useState(getCumulativeCount(props.data))
        const [entityName, setEntityName] = useState(props.message)
        const [selectedKey, setSelectedKey] = useState('all');
        const [currentData, setCurrentData] = useState(props.data);
        const [filter, setFilter] = useState("All Time")

        const aggregateCounts = (data) => {
            return data.reduce(
                (acc, item) => {
                    acc['1K_count'] += item['1K_count'];
                    acc['2K_count'] += item['2K_count'];
                    acc['4K_count'] += item['4K_count'];
                    acc['Custom'] += item['Custom'];
                    return acc;
                },
                { '1K_count': 0, '2K_count': 0, '4K_count': 0, 'Custom': 0 }
            );
        };

        const onVisibleChange = (e, item) => {
            if(!e){
                setAssetResolutionCount(_.cloneDeep(aggregateCounts(currentData)));
                AssetTypesVisible[item.type] = e
                setAssetCount(getCumulativeCount(currentData));
                setEntityName(props.message)
                setTooltipVisible(AssetTypesVisible);
            }
            else{
                AssetTypesVisible[item.type] = e
                setAssetCount(item.count);
                setEntityName(item.type)
                setTooltipVisible(AssetTypesVisible);
                updateResolutionCount(item.type)
            }
        }

        const updateResolutionCount = (type) => {
            let tempAssetCount = assetResolutionCount
            let hoveredAsset = currentData.find(ele => ele.type == type)
            if(hoveredAsset){
                for (const key in ResolutionTypes) {
                    tempAssetCount[ResolutionTypes[key]] = hoveredAsset[ResolutionTypes[key]]
                }
            }
            setAssetResolutionCount(tempAssetCount);
        }

        const handleMenuClick = ({ key }) => {
            setSelectedKey(key);
            switch (key) {
                case 'month':
                    setCurrentData(props.all_data.overall_assets_month);
                    setAssetCount(getCumulativeCount(props.all_data.overall_assets_month));
                    setAssetResolutionCount(aggregateCounts(props.all_data.overall_assets_month));
                    setFilter("This Month")
                    break;
                case 'year':
                    setCurrentData(props.all_data.overall_assets_year);
                    setAssetCount(getCumulativeCount(props.all_data.overall_assets_year));
                    setAssetResolutionCount(aggregateCounts(props.all_data.overall_assets_year));
                    setFilter("This Year")
                    break;
                case 'last_30_days':
                    setCurrentData(props.all_data.last_30_days_assets);
                    setAssetCount(getCumulativeCount(props.all_data.last_30_days_assets));
                    setAssetResolutionCount(aggregateCounts(props.all_data.last_30_days_assets));
                    setFilter("Last 30 Days")
                    break;
                case 'last_year':
                    setCurrentData(props.all_data.last_year_assets);
                    setAssetCount(getCumulativeCount(props.all_data.last_year_assets));
                    setAssetResolutionCount(aggregateCounts(props.all_data.last_year_assets));
                    setFilter("Last Year")
                    break;
                case 'last_month':
                    setCurrentData(props.all_data.last_month_assets);
                    setAssetCount(getCumulativeCount(props.all_data.last_month_assets));
                    setAssetResolutionCount(aggregateCounts(props.all_data.last_month_assets));
                    setFilter("Last Month")
                    break;
                case 'last_6_month':
                    setCurrentData(props.all_data.last_6_months_assets);
                    setAssetCount(getCumulativeCount(props.all_data.last_6_months_assets));
                    setAssetResolutionCount(aggregateCounts(props.all_data.last_6_months_assets));
                    setFilter("Last 6 Months")
                    break;
                default:
                    setCurrentData(props.data);
                    setAssetCount(getCumulativeCount(props.data));
                    setAssetResolutionCount(aggregateCounts(props.data));
                    setFilter("All Time")
                    break;
            }
        };
    
        const menu = (
            <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]}>
                <Menu.Item key="last_30_days">Last 30 days</Menu.Item>
                <Menu.Item key="month">This month</Menu.Item>
                <Menu.Item key="last_month">Last month</Menu.Item>
                <Menu.Item key="last_6_month">Last 6 months</Menu.Item>
                <Menu.Item key="year">This year</Menu.Item>
                <Menu.Item key="last_year">Last year</Menu.Item>
                <Menu.Item key="all">All time</Menu.Item>
            </Menu>
        );

        return (
            <Row style={{ marginTop: 20 }}>
                <Col span={24} className={props.showResolutionBreakdown ? "justify-in-between" : "justify-in-start"}>
                    <Col span={12}>
                        <div style={{
                            color: '#000000',
                            fontSize: 30,
                            fontFamily: 'Manrope',
                            fontWeight: 400,
                        }}>
                            {assetCount}
                        </div>
                    </Col>
                    <Col span={12}>
                        {props.showResolutionBreakdown ? 
                        <div className="info-text size-14 light-gray">
                            {props.time}
                        </div> :
                        ''}
                    </Col>
                </Col>
                <Col span={12} className="justify-in-start">
                    <div className="info-text black">
                        {AssetNames[entityName]}
                    </div>
                </Col>
                <Col span={12} className={props.showResolutionBreakdown ? "justify-in-start" : "justify-in-end"}>
                    {props.showResolutionBreakdown ? (
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <div>
                                        <FilterOutlined style={{ marginRight: 8 }} />
                                        {filter}
                                        <DownOutlined style={{ marginLeft: 8 }} />
                                    </div>
                                </Button>
                            </Dropdown>
                        ) : 
                        <div className="info-text size-14 light-gray">
                            {props.time}
                        </div>}
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        {currentData.map((item, index) => {
                            const divProps = {
                                key: index,
                                style: {
                                    width: `${(item.count / assetCount) * 100}%`,
                                    backgroundColor: `${tooltipVisible[item.type] ? ActiveModelsHoverColor[item.type] : EntityColor[item.type]}`,
                                    height: '24px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                },
                            };
    
                            if (props.showResolutionBreakdown) {
                                divProps.onMouseEnter = () => onVisibleChange(true, item);
                                divProps.onMouseLeave = () => onVisibleChange(false, item);
                            }

                            return (
                                props.showResolutionBreakdown ? 
                                <div {...divProps} /> :
                                <Tooltip title={`${AssetTypes[item.type]}s: ${item.count}`} color={`black`}>
                                    <div {...divProps} />
                                </Tooltip>
                            )
                        })}
                    </div>
                </Col>
                {   props.showResolutionBreakdown &&    
                    <Col span={24} style={{ marginTop: 10 }}>
                        <div className='your-images-legend' style={{ marginTop: 15 }}>
                            {Object.entries(ResolutionTypes).map(([key, value]) => (
                                <div className="info-text size-14" style={{display: "flex", justifyContent: "space-between"}}>
                                    <span style={{ color: '#276DD7', fontWeight: 'bold', marginRight:10}}>{assetResolutionCount[value]}</span> {key}
                                </div>
                            ))}
                        </div>
                    </Col>
                }
            </Row>
        );
    };

    const CollaborationCard = (props) => {
        let entity = props.data;
        return (
            <a onClick={() => window.location.href = `/collaborate-tool/${entity.id}?origin=${currentPage}`}>
                <Card>
                    <Row>
                        <Col span={24}>
                            <img
                            style={{ objectFit: "cover", width: "100%", height: 200, zIndex:1 }}
                            src={getCollaborationThumbnail(entity)}/>
                        </Col>
                    </Row>
                    <Row style={{ margin: 10 }}>
                        <Col span={12} className="justify-in-start">
                                <span className={`model-type-btn ${entity.type === "Product" ? "blue" : "green"}`}>{entity.type}</span>
                            </Col>
                        <Col span={12} className="justify-in-end" >
                            <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(entity.last_modified, 'tooltip')}</span>}>                                                  
                                <span className="manrope f-12 grey-77 w-500 time-clock-bg"  style={{marginLeft: 4}}>
                                    <img src='/img/carbon_time.svg'  style={{marginRight: 2}}/>{Utilities.timestampToTimeSince(entity.last_modified)}
                                </span>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row  style={{ margin: 15 }}>
                        <Col span={24}>
                            <Tooltip title={entity.name}>
                                <div className="info-text size-14 black limit-line w-150">{entity.name}</div>
                            </Tooltip>
                            {
                                entity.message ? 
                                <Col span={24}>
                                    <div className="info-text size-14 light-gray limit-line w-200">{entity.message}</div>
                                </Col> : ''
                            }
                        </Col>
                    </Row>
                </Card>
            </a>
        );
    };

    const CarouselSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    const RecentScenes = (prop) => {
        return (
            prop.data.length > 0 ?
            prop.data.map (entity => 
                <div className="recentScenes">
                <Card style={{ marginBottom: 10 }}>
                    <a onClick={() => { window.location = `/scene_renders/${entity.id}?origin=${currentPage}` }}>
                        <Row style={{ marginRight: 0 }}>
                            <Col span={6}>
                                <img
                                    style={{ objectFit: "scale-down", width: "80px", height: "80px", zIndex:1 }}
                                    src={getSceneThumbnail(entity) ? getSceneThumbnail(entity) : 
                                    require(`../../../../icons/my-scenes.svg`)}/>
                            </Col>
                            <Col span={5} className="justify-in-start scene" >
                                <span className={`model-type-btn green scenespan`}>Scene</span>
                            </Col>
                            <Col span={13} className="justify-in-start entityName " style={{ marginTop: 10 }}>
                                <div style={{ display: 'inline-block',width:"-webkit-fill-available" }}>
                                    <Tooltip title={entity.name}>
                                        <p className="info-text size-14 black limit-line">{entity.name}</p>
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                    </a>
                </Card>
            </div>            
            )
            :
            <Row>
                <Col span={24} className="justify-in-center">
                    <Empty
                    style={{ marginTop: 20, marginBottom: 20 }}
                    description={`No Recent Scenes!`}
                    className="manrope f-14"
                    />
                </Col>
            </Row>
        );
    };

    const goToActiveModels = (type) => {
        window.location.href = `/active-models?tab=${type}&origin=${currentPage}`;
    };

    const getAssetImageCount = (product) => {
        let local_company_id = COMPANY_ID;
        if (IS_MS_PROVIDER) {
            if (MANAGED_COMPANY_ID) {
                local_company_id = MANAGED_COMPANY_ID;
            }
        }
        if (product.company_id && product.company_id == local_company_id) {
            if (product.company_render_count) {
                return product.company_render_count;
            } else {
                return 0;
            }
        } else {
            if (product.user_render_count) {
                return product.user_render_count;
            } else {
                return 0;
            }
        }
            
    }
    
  const selectAssetAndSetBasicInfo = (id, entity, asset) => {
    setCurrentProductId(id);
    let payload = { product_id: id, username: entity.customer_username }
    if (entity.company_id) {
        payload.company_id = entity.company_id
    }
    props.getProduct(payload);
    setProductBasicInfo(entity);
    setSelectedAssetModal(asset);
  }

    const redirectToEntity = (entity) => {
        window.location.href = `/products/${entity.id}?entity_id=${entity.id}&origin=${currentPage}`;
    };


    let menu = (id, entity, selectAssetAndSetBasicInfo) => {
        return <Menu style={{ width: "148px", marginLeft: "10px", backgroundColor: '#E6F7FF' }}>
            <Menu.Item className='manrope f-14' key={0} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'silo')}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>Silo Images</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={1} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'mp4')}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>MP4 Video</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key={2} className='manrope f-14' onClick={() => selectAssetAndSetBasicInfo(id, entity, 'dim')}>
                <span className='cursor-pointer' style={{ color: '#276DD7' }}>Dimensional Image</span>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={3} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'lifestyle')}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>Lifestyle Images</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={4} onClick={() => selectAssetAndSetBasicInfo(id, entity, '360')}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product 360 Spin</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={5} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'variant')}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product Variant</span>
                </Tooltip>
            </Menu.Item>
        </Menu>
    }

    return (
        <>
            <CustomerMainLayout selected='9'>
            <ProductAssetModals props={props}
            currentPage={currentPage}
            accessed_from={'homepage'}
            currentProductId={currentProductId}
            setCurrentProductId={setCurrentProductId}
            selectedAssetModal={selectedAssetModal}
            productBasicInfo={productBasicInfo} />
                <div>
                    <div className="home-txt-heading">Your Dashboard</div>
                    {
                        isLoading ? 
                        <SkeletonView />
                        : 
                        <>
                            {activeProjectCount > 0 && !MANAGED_CUSTOMER_USERNAME ? 
                                <Row hidden={reviewBanner} style={{ padding: 15, backgroundColor: '#D9F7BE', borderRadius: 4, marginBottom: 10 }}>
                                    <Col span={22} className="justify-in-start">
                                        <div className="info-text size-14 green justify-in-center">
                                            <FlagIcon style={{ marginRight: 10 }}/>
                                            Heads up! You have {activeProjectCount} new active projects.
                                            <a className="view-btn green" onClick={() => window.location.href = `/projects?tab=active&origin=${currentPage}`} style={{ marginLeft: 10 }}>Check Now</a>
                                            <ArrowRightOutlined style={{ fontSize: 14, marginLeft: 5}}/>
                                        </div>
                                    </Col>
                                    <Col span={2} className="justify-in-end">
                                        <a className="info-text size-14 green" onClick={() => setReviewBanner(true)}>x</a>
                                    </Col>
                                </Row>
                            : 
                            requestedProjectCount > 0 && !MANAGED_CUSTOMER_USERNAME ? 
                                <Row hidden={reviewBanner} style={{ padding: 15, backgroundColor: '#D9F7BE', borderRadius: 4, marginBottom: 10 }}>
                                    <Col span={22} className="justify-in-start">
                                        <div className="info-text size-14 green justify-in-center">
                                            <FlagIcon style={{ marginRight: 10 }}/>
                                            Heads up! You have {requestedProjectCount} new requested projects.
                                            <a className="view-btn green" onClick={() => window.location.href = `/projects?tab=requested&origin=${currentPage}`} style={{ marginLeft: 10 }}>Check Now</a>
                                            <ArrowRightOutlined style={{ fontSize: 14, marginLeft: 5}}/>
                                        </div>
                                    </Col>
                                    <Col span={2} className="justify-in-end">
                                        <a className="info-text size-14 green" onClick={() => setReviewBanner(true)}>x</a>
                                    </Col>
                                </Row> : 
                            ' '}

                            {MANAGED_CUSTOMER_USERNAME ? 
                                <Row style={{marginBottom: 10 }}>
                                    <Col span={24}>
                                        <div className="custom-alert info">
                                            You are viewing the dashboard for customer {MANAGED_CUSTOMER_USERNAME}
                                            <>{(MANAGED_COMPANY_NAME != undefined) ? (' (Company: ' + MANAGED_COMPANY_NAME + ')'):''}</>
                                        </div>
                                    </Col>
                                </Row>
                        
                            : ''}
                            <LibraryCountView 
                            data={homeStats.library_data}/>
                            <Row gutter={[20,20]} style={{ marginTop: 20 }}>
                                <Col span={16}>
                                    <div className="stats-txt-heading">Your Activity</div>
                                    <Divider style={{ color : '#D9D9D9', marginTop: 10 }}/>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <ActiveModels 
                                            heading={getCumulativeCount(homeStats.review_data) == 0 ? "No Models in Review" : "Models in Review"}
                                            info="Review model accuracy and create scenes"
                                            type={ActiveModelsKeys.review}
                                            data={homeStats.review_data}
                                            getCumulativeCount={getCumulativeCount}
                                            goToActiveModels={goToActiveModels}/>
                                        </Col>
                                        <Col span={12}>
                                        <ActiveModels 
                                            heading={getCumulativeCount(homeStats.in_progress_data) == 0 ? "No Models in Progress" : "Models in Progress"}
                                            info="Models currently being created by ALL3D"
                                            type={ActiveModelsKeys.progress}
                                            data={homeStats.in_progress_data}
                                            getCumulativeCount={getCumulativeCount}
                                            goToActiveModels={goToActiveModels}/>
                                        </Col>
                                    </Row>
                                    <Col span={24} style={{padding: '0px'}}>
                                    {
                                    homeStats.incomplete_count > 0 ?
                                    <div style={{ marginTop: 20 }}>
                                        <Card>
                                            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
                                                <div style={{justifyContent: 'space-between', display: 'flex'}}>
                                                    <span>
                                                    <span className="view-btn black" style={{ marginRight: 20 }}>{homeStats.incomplete_count} Incomplete</span>
                                                    <span className="info-text">You have models started that need additional info before submitting.</span></span>
                                                    <span><a onClick={() => goToActiveModels(ActiveModelsKeys.drafts)} className="view-btn">View Drafts</a></span>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    : ''
                                    }
                                    </Col>
                                    {(approvedProjectCount == 0 && activeProjectCount == 0 && requestedProjectCount == 0) ? 
                                    <Row>
                                    <Col span={24}>
                                    <div style={{ marginTop: 20 }}>
                                        <Tabs
                                        defaultActiveKey={modelsTabKey}
                                        size="small"
                                        tabBarGutter={40}
                                        onChange={handleModelTabChange}>
                                            <TabPane tab={`To Review (${getCumulativeCount(homeStats.review_data)})`} key={ActiveModelsKeys.review}>
                                                <ActiveModelsTab 
                                                data={homeDetails.details ? homeDetails.details.review_data : []}
                                                type={ActiveModelsKeys.review}/>
                                            </TabPane >
                                            <TabPane tab={`In Progress (${getCumulativeCount(homeStats.in_progress_data)})`} key={ActiveModelsKeys.progress}>
                                                <ActiveModelsTab 
                                                data={homeDetails.details ? homeDetails.details.in_progress_data : []}
                                                type={ActiveModelsKeys.progress}/>
                                            </TabPane>
                                            <TabPane tab={`Drafts (${homeStats.incomplete_count})`} key={ActiveModelsKeys.drafts}>
                                                <ActiveModelsTab 
                                                data={homeDetails.details ? homeDetails.details.in_complete_data : []}
                                                type={ActiveModelsKeys.drafts}/>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                    </Col>
                                    <Col span={24} style={{ marginTop: 15 }}>
                                        <Button onClick={() => window.location.href = `/active-models?origin=${currentPage}`} className="add-model-button">View All Model Activity</Button>
                                    </Col>
                                    </Row>
                                    :
                                    <Row>
                                    <Col span={24}>
                                    <div style={{ marginTop: 20 }}>
                                        <Tabs
                                        defaultActiveKey={projectsTabKey}
                                        size="small"
                                        tabBarGutter={40}
                                        onChange={handleTabChange}>
                                            <TabPane tab={`${StateDisplayName[ProjectKeys.active]} (${activeData.length})`} key={ProjectKeys.active}>
                                                <ProjectsTab 
                                                data={activeData}
                                                type={ProjectKeys.active}/>
                                            </TabPane >
                                            <TabPane tab={`${StateDisplayName[ProjectKeys.requested]} (${requestedData.length})`} key={ProjectKeys.requested}>
                                                <ProjectsTab 
                                                data={requestedData}
                                                type={ProjectKeys.requested}/>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                    </Col>
                                    <Col span={24} style={{ marginTop: 15 }}>
                                        <Button onClick={() => props.history.push(`/projects?tab=active`)} className="add-model-button">View All Active Projects</Button>
                                    </Col>
                                    </Row>}

                                    <Row gutter={[15,15]} style={{ marginTop: 20 }}>
                                        <Col span={24}>
                                            <Card>
                                                <Row style={{ margin: 20 }}>
                                                    <Col span={20}>
                                                        <div className="stats-txt-heading">{homeDetails.counts && homeDetails.counts.collaboration.resolved == 0 ? 'No Collaborations to Resolve' : 'Collaborations to Resolve'}</div>
                                                    </Col>
                                                    <Col span={4} className="justify-in-end" style={{ zIndex: 2 }}>
                                                        <a onClick={() => props.history.push('/collaborations')} className="view-btn">View All</a>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: 20 }}>
                                                        <Row style={{ borderRadius: 4, overflow: 'hidden' }}>
                                                            <Col span={12} style={{ backgroundColor: '#E6F7FF' }}>
                                                                <Row style={{ margin: 15 }}>
                                                                    <Col span={24} className="justify-in-start">
                                                                        <div style={{
                                                                            color: '#000000',
                                                                            fontSize: 30,
                                                                            fontFamily: 'Manrope',
                                                                            fontWeight: 400,
                                                                        }}>
                                                                            {homeDetails.counts ? homeDetails.counts.collaboration.unresolved : 0}
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={12} className="justify-in-start">
                                                                        <div className="info-text black">
                                                                            Unresolved
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                </Col>
                                                            <Col span={12} style={{ backgroundColor: '#D9F7BE' }}>
                                                                <Row style={{ margin: 15 }}>
                                                                    <Col span={24} className="justify-in-start">
                                                                        <div style={{
                                                                            color: '#000000',
                                                                            fontSize: 30,
                                                                            fontFamily: 'Manrope',
                                                                            fontWeight: 400,
                                                                        }}>
                                                                            {homeDetails.counts ? homeDetails.counts.collaboration.resolved : 0}
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={12} className="justify-in-start">
                                                                        <div className="info-text black">
                                                                            Resolved
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        {
                                            isDetailsLoading ? <LoadingOutlined style={{ fontSize: 50, marginTop: 20, marginBottom: 20 }} className="loading-center"/> :
                                            (homeDetails.details.collaborations.length <= 0 ? 
                                            <Col span={24} className="justify-in-center">
                                                <Empty
                                                style={{ marginTop: 20, marginBottom: 20 }}
                                                description={`No new Collaborations to Resolve`}
                                                className="manrope f-14"
                                                />
                                            </Col> :
                                            homeDetails.details.collaborations.map(entity => (
                                                <Col span={8}>
                                                    <CollaborationCard
                                                    data={entity}/>
                                                </Col>
                                            )))
                                        }
                                        <Col span={24} style={{ marginTop: 15 }}>
                                            <Button onClick={() => props.history.push('/collaborations')} className="add-model-button">View All Collaborations</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <div className="stats-txt-heading">Your Images</div>
                                    <Divider style={{ color : '#D9D9D9', marginTop: 10}}/>
                                    <Row>
                                        <Col span={24}>
                                            <Card>
                                            <Row style={{ margin: 20, marginTop: 0 }}>
                                                <Col span={24}>
                                                    <div>
                                                        <MultiPartHorizontalLine
                                                        data={homeStats.overall_assets}
                                                        all_data={homeStats}
                                                        message="Images"
                                                        time="Showing Images for:"
                                                        showResolutionBreakdown={true}
                                                        resolutionCount={resolutionCount}/>
                                                    </div>
                                                    <div>
                                                        <MultiPartHorizontalLine
                                                        data={homeStats.inprogress_assets}
                                                        message="In Progress"
                                                        time="Typically takes 1-2 hrs"
                                                        showResolutionBreakdown={false}/>
                                                    </div>
                                                    <div className="your-images-legend">
                                                        {Object.entries(AssetTypes).map(([key, value]) => (
                                                            <div className="info-text size-14">
                                                                <span style={{ color: EntityColor[key], paddingRight: 10 }}>●</span>
                                                                {value}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <Divider style={{ color : '#D9D9D9', marginTop: 30 }}/>
                                                    <div>
                                                        <div className="stats-txt-heading" style={{ marginBottom: 15 }}>Recently Generated</div>
                                                        <p className="info-text size-14 black">Check out your newly rendered images and 3D assets now ready to download.</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {
                                                isDetailsLoading ? <LoadingOutlined style={{ fontSize: 50, marginTop: 20, marginBottom: 20 }} className="loading-center"/> :
                                                <Row gutter={[15,15]} style={{ marginLeft: 20, marginRight: 20 }}>
                                                    {homeDetails.details.recent_assets.map(entity => (
                                                        <Col span={12}>
                                                            <a onClick={() =>(props.history.push(`/${entity.type === 'Product' ? 'products' : 'scene_renders'}/${entity.id}`))}>
                                                            <Card>
                                                                <Row>
                                                                    <Col span={24}>                                                                    
                                                                        <img
                                                                        style={{ objectFit: "cover", width: "100%", height: 120, zIndex:1 }}
                                                                        src={getAssetThumbnail(entity)}/>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{ margin: 15 }}>
                                                                    <Col span={24}>
                                                                        <div className="info-text size-14 light-gray limit-line ">{getAssetMessage(entity) || ''}</div>
                                                                    </Col>
                                                                    <Col span={21} style={{ marginTop: 5 }}>
                                                                        <Tooltip title={entity.name}>
                                                                            <div className="info-text size-14 black limit-line ">{entity.name}</div>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col span={3} className="justify-in-end">
                                                                        <div>
                                                                            <ArrowRightOutlined style={{ color: '#276DD7' }}/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                            </a>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            }
                                            </Card>
                                        </Col>
                                    </Row>
                                    <div className="stats-txt-heading" style={{ marginTop: 35 }}>Your Scenes</div>
                                    <Divider style={{ color : '#D9D9D9', marginTop: 10}}/>
                                    <div>
                                        {
                                            sceneLoader ? <LoadingOutlined style={{ fontSize: 50, marginTop: 20, marginBottom: 20 }} className="loading-center"/> :
                                            <RecentScenes 
                                            data={recentScenes}/>
                                        }
                                    </div>
                                    <div style={{ marginTop: 25 }}>
                                        <Button onClick={() => props.history.push('/my_scenes')} className="add-model-button">View All Scenes</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Divider style={{ color : '#D9D9D9', marginTop: 5 }}/>
                                </Col>
                                <Col span={21}>
                                    <div className="info-text black">Models Ready to Generate Assets</div>
                                    <p style={{ marginTop:5 }} className="info-text size-14">Start generating images and 3D assets from models recently added to your library. </p>
                                </Col>
                                <Col span={3} className="justify-in-end">
                                    <Button onClick={() => props.history.push('/list-products')} className="add-model-button">View More</Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 20, marginBottom: 50 }}>
                                {/* <Col span={24}>
                                    <div>
                                        <ProductsCarousel/>
                                    </div>
                                </Col> */}
                                <Col span={24}>
                                    <div>
                                        {itemIndex > 0 ? <button className="prev-button" onClick={handlePrev}><CaretLeftOutlined/></button> : ''}
                                        {(productsData.length > 4 && itemIndex < productsData.length-4) ? <button className="next-button" onClick={handleNext}><CaretRightOutlined/></button> : ''}
                                        <Carousel {...CarouselSettings} ref={carouselRef}> 
                                            {productsData.map((entity, index) => (
                                            <div>
                                                 <a href={'/products/' + entity.id} >
                                                <Card style={{ marginRight: 20 }}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <img
                                                            style={{ objectFit: "contain", width: "100%", height: 250, zIndex:1 }}
                                                            src={(entity.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(entity.platform)))}/>
                                                        </Col>
                                                    </Row>
                                                    <Row  style={{ margin: 15, textAlign: 'left' }} className="justify-in-start">
                                                        <Col span={24}>
                                                            <Tooltip title={entity.name}>
                                                                <p className="info-text size-14 black limit-line">{entity.name}</p>
                                                            </Tooltip>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Tooltip title={entity.category}>
                                                                <div className="justify-in-start" style={{ marginRight: 12, marginBottom: 4, textAlign: "left" }}>
                                                                    <span className="info-text size-14 light-gray" style={{ marginRight: 10 }}>Category</span>
                                                                    <span className="info-text size-14 limit-line black">{entity.category}</span>
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip title={entity.brand_id || 'N/A'}>
                                                                <div className="justify-in-start" style={{ marginRight: 12, marginBottom: 4, textAlign: "left" }}>
                                                                    <span className="info-text size-14 light-gray" style={{ marginRight: 25 }}>Item ID</span>
                                                                    <span className="info-text size-14 black">{entity.brand_id || 'N/A'}</span>
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip title={`${entity.dimensions.width}"W x ${entity.dimensions.height}"H x ${entity.dimensions.depth}"D`}>
                                                                <div className="justify-in-start" style={{ marginRight: 12, textAlign: "left" }}>
                                                                    <span className="info-text size-14 light-gray" style={{ marginRight: 36 }}>Dims</span>
                                                                    <span className="info-text size-14 black limit-line w-350">{entity.dimensions.width}"W x {entity.dimensions.height}"H x {entity.dimensions.depth}"D</span>
                                                                </div>
                                                            </Tooltip>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 15 }}>
                                                                <Dropdown overlay={
                                                                    menu(entity.id, entity, selectAssetAndSetBasicInfo)
                                                                } trigger={'click'} placement="topLeft">
                                                                    <span className="justify-in-start generate-asset-btn assetBTN" style={{padding:"5px 5px"}}> Generate Assets + </span>
                                                                </Dropdown>
                                                                <div className="justify-in-end" onClick={() => redirectToEntity(entity)}>
                                                                    <span className="info-text size-14">{getAssetImageCount(entity)} Images</span>
                                                                    <img src='/img/ArrowRight.png' className="icon-instance-node" style={{ marginLeft: '4px' }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                </a>

                                            </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
                <MaterialDetailModal
                materialModal={materialModal}
                closeMaterialModal={closeMaterialModal}
                selectedMaterial={selectedMaterial}
                editable={false}
                errorMessage={''}
                />
                <CustomerChangeLog />
            </CustomerMainLayout>
        </>
    );
};

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsHome)
)