import {
  CREATE_ROOM,
  ROOM_FAILURE,
  RENDER_ROOM,
  FETCH_ROOM,
  GET_SCANNED_ROOMS,
  ROOM_SUCCESS,
  UPDATE_ROOM,
  LIST_ROOM,
  ROOM_REJECT_SUCCESS,
  REJECT_ROOM,
} from '../actions';

const initialState = {
  room: {},
  rooms: [],
  scannedRooms: [],
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  error: null,
};

export default function roomReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_ROOM:
      return {
        room: { ...action.payload },
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case GET_SCANNED_ROOMS:
      return {
        room: { ...action.payload },
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case RENDER_ROOM:
      return {
        room: { ...action.payload },
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case FETCH_ROOM:
      return {
        room: { ...action.payload },
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case CREATE_ROOM:
      return {
        room: { ...action.payload },
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case UPDATE_ROOM:
      return {
        room: { ...action.payload },
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case ROOM_SUCCESS:
      return {
        room: { ...action.payload },
        rooms: action.payload.rooms || state.rooms,
        scannedRooms: action.payload.scannedRooms || state.scannedRooms,
        isLoading: false,
        isCreated: action.payload.isCreated,
        isUpdated: action.payload.isUpdated,
        error: null,
      };
    case ROOM_FAILURE:
      return {
        room: state.room,
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: action.payload.message,
      };
    case REJECT_ROOM:
      return {
        room: state.room,
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    case ROOM_REJECT_SUCCESS:
      return {
        room: state.room,
        rooms: state.rooms,
        scannedRooms: state.scannedRooms,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: null,
      };
    default:
      return state;
  }
}
