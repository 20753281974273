import React from 'react';
import { SCENE_BASE_URL, FETCH_ROOM, SCENE_THUMBNAIL_URI, ROOM_PANORAMA_ASSET_URI, getBaseURL, GET_SCENE_BATCH } from '../../../../environments/env';
import { ROOM_PANORAMA_ASSET } from '../../../../environments/env';
import ThreeSixtyViewer from '../../../../ThreeSixtyViewer';
import DottedLoader from '../../DottedLoader';
import axios from 'axios';

const baseURL = ROOM_PANORAMA_ASSET;
const sceneBaseURL = SCENE_BASE_URL;

class PanoramaPublicViewer extends React.Component {

    constructor(props){
        super(props);
        this.viewer = null
        this.room_id = this.props.match.params.id
        this.room_area = this.props.match.params.area
        this.type =  this.props.match.params.type
        this.url = ''
        this.platform = ''
    }

    componentDidMount() {
        // Create source.
        if (this.type == "space") {
            axios.post(FETCH_ROOM, { room_id: this.room_id })
            .then(res => {
                let platform = res.data.platform;
                if (this.room_area == "360Camera.jpg") {
                    this.url = getBaseURL(platform) + ROOM_PANORAMA_ASSET_URI + this.room_id + '/360Camera.jpg';
                }
                else {
                    this.url = getBaseURL(platform) + ROOM_PANORAMA_ASSET_URI + this.room_id + '/' + this.room_area;
                }
                this.viewer = new ThreeSixtyViewer(this.room_id,`loading-container-${this.room_id}`,this.url,true);
                this.forceUpdate();
            })
        }
        else if (this.type == "scene") {
            axios.post(GET_SCENE_BATCH, { filter_string: `(id__exact=${this.room_id})`, required_fields: ['id', 'platform'], order_by: 'id desc' })
            .then(res => {
                let platform = res.data.platform;
                if (this.room_area == "360Camera.jpg") {
                    this.url = getBaseURL(platform) + SCENE_THUMBNAIL_URI + this.room_id + '/360Camera.jpg';
                }
                else {
                    this.url = getBaseURL(platform) + SCENE_THUMBNAIL_URI + this.room_id + '/' + this.room_area;
                }
                this.viewer = new ThreeSixtyViewer(this.room_id,`loading-container-${this.room_id}`,this.url,true);
                this.forceUpdate();
            })
        }
    }

    render () {
        return (
            <span>
                <div id={this.room_id} style={{cursor:"grabbing",background:"white"}}></div>
                <div id={`loading-container-${this.room_id}`}>
                    <div id={`loadingImages-${this.room_id}`} style={{position: "absolute",bottom: 160,width: "100%"}}>
                        <DottedLoader/>
                        <div style={{display: "flex", justifyContent: "center"}} className="my_font">
                        powered by
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginTop: 10}} >
                        <img className="logo" style={{position: "absolute", margin: "auto"}} src="/img/logo.svg" width="111px" />
                        </div>
                    </div>   
                </div>
            </span>
        );
    }
}

export default PanoramaPublicViewer;