import * as THREE from "three";

var SnapGuide = function() {

	'use strict';

	var points = [];
	points.push( new THREE.Vector3( -100, 0, 0 ) );
	points.push( new THREE.Vector3( 100, 0, 0 ) );

	THREE.Line.call( this,
        new THREE.BufferGeometry().setFromPoints( points ),
        new THREE.LineBasicMaterial( {
		     visible: true,
		     depthTest: false,
		     depthWrite: false,
		     linewidth: 1,
		     fog: false,
		     color: 0xf542da
     	} )
    );

    this.target = null;

    this.setTarget = function ( targetObj ) {
    	this.target = targetObj;
    }

    this.updateMatrixWorld = function () {

    	if ( this.target != null && this.target.userData.snapped ) {

    		let data = this.target.userData.pvb.getDataForSnapTest();

    		switch( this.target.userData.snapDir ) {
    			
    			case "front":
    			this.position.copy( data.frontOrigin );
    			this.lookAt( this.position.clone().add( data.frontDir ) );
    			break;

    			case "back":
    			this.position.copy( data.backOrigin );
    			this.lookAt( this.position.clone().add( data.backDir ) );
    			break;

    			case "left":
    			this.position.copy( data.leftOrigin );
    			this.lookAt( this.position.clone().add( data.leftDir ) );
    			break;

    			case "right":
    			this.position.copy( data.rightOrigin );
    			this.lookAt( this.position.clone().add( data.rightDir ) );
    			break;

    			default:
    			break;

    		}

    		this.visible = true;

    	}

    	else {

    		this.visible = false

    	}

    	THREE.Object3D.prototype.updateMatrixWorld.call( this );
    };
	
};

SnapGuide.prototype = Object.assign( Object.create( THREE.Line.prototype ), {
	constructor: SnapGuide,
	isSnapGuide: true
} )

export { SnapGuide };
