import React from "react";
import { Table, Button, Input, Space } from "antd";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import DottedLoader from "../../DottedLoader";
import { SearchOutlined } from "@ant-design/icons";

const title = () => "Here is title";
const showHeader = true;
const footer = () => "Here is footer";
const scroll = { y: 240 };
const pagination = { position: "bottom" };

class ArtistPendingOnboardingTable extends React.Component {
  formRef = React.createRef();

  state = {
    bordered: false,
    loading: false,
    pagination,
    size: "default",
    title: undefined,
    showHeader,
    footer: false,
    tableLayout: "fixed",
    scroll: undefined,
    hasData: true,
    dataSource: null,
    isLoading: false,
  };

  getColumns = () => [
    {
      title: "Artist Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...this.getColumnSearchProps("name"),
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Overall Score",
      dataIndex: "overall_score",
      key: "overall_score",
      sorter: (a, b) => a.overall_score - b.overall_score,
      render: (text, record) => <span>{text}/60</span>,
    },
    {
      title: "Action",
      key: "verdict",
      render: (text, record) => (
        <>
        <span>
              <a
                style={{ marginRight: 10 }}
                className="onhover-underline"
                href={"../admin_score_artist/" + record.id}
              >
                Add to system
              </a>
        </span>
        </>
      ),
    },
  ];

  componentDidMount = () => {
    this.handleDataSource();
  };

  handleDataSource = () => {
    let data = [];
    let payload = {
      status: ["successfully_scored"],
    };
    this.setState({ isLoading: true });
    axios.post(ENVIRONMENT.GET_ARTIST_TEST_BATCH, payload).then((res) => {
      console.log("ArtistPendingOnboardingTable Data Source", res.data);
      for (let i = 1; i <= res.data.length; i++) {
        let geometryAccuracy = res.data[i - 1]["geometry_accuracy"];
        let pbrAccuracy = res.data[i - 1]["pbr_accuracy"];
        let vrayAccuracy = res.data[i-1]['vray_accuracy']
        let uvAccuracy = res.data[i - 1]["uv_accuracy"];
        let dimensionalAccuracy = res.data[i - 1]["dimensional_accuracy"];
        let colorAccuracy = res.data[i - 1]["color_accuracy"];
        let total =
          parseInt(geometryAccuracy) +
          parseInt(pbrAccuracy) +
          parseInt(vrayAccuracy) +
          parseInt(uvAccuracy) +
          parseInt(dimensionalAccuracy) +
          parseInt(colorAccuracy);
        data.push({
          key: i,
          name: res.data[i - 1]["username"],
          overall_score: total,
          verdict: res.data[i - 1]["status"],
          id: res.data[i - 1]["id"],
        });
      }
      data = data.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.setState({
        dataSource: data,
        isLoading: false,
      });
    });
  };

  handleToggle = (prop) => (enable) => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = (enable) => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = (enable) => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = (enable) => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = (enable) => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = (hasData) => {
    this.setState({ hasData });
  };

  handlePaginationChange = (e) => {
    const { value } = e.target;
    this.setState({
      pagination: value === "none" ? false : { position: value },
    });
  };

  // Search in table functionality
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <DottedLoader />
        ) : (
          <div>
            {this.state.dataSource && (
              <Table
                {...this.state}
                columns={this.getColumns()}
                dataSource={this.state.dataSource}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default ArtistPendingOnboardingTable;