import React, { useState, useEffect }  from 'react';
import '@ant-design/compatible/assets/index.css';
import { DownloadOutlined } from '@ant-design/icons';
import { Row, Col, Card, Image, Button, Modal, Tooltip, Collapse} from 'antd';
import 'rc-color-picker/assets/index.css';
import ENVIRONMENT from '../../../environments';
const { Panel } = Collapse;

 
const MaterialComponents = (props) => {
    const [showRejectModel, setRejectModal] = useState(false);
    const [materialInfo, setMaterialInfo] = useState('');
    const [materialRequiredOutput, setMaterialRequiredOutput] = useState('');
    const [statusValue, setStatusValue] = useState('');
    const [renders, setRenders] = useState([]);
    const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        setMaterialInfo(props.material_data);
        setMaterialRequiredOutput(props.materialRequiredOutput)
        if (props.material_data) {
            if (props.material_data.status == 1) {
                setStatusValue('Material is incomplete, and is yet to be submitted by the Customer.');
            } else if (props.material_data.status == 2 && props.material_data.assigned_artist == '' ) {
                setStatusValue('Material is yet to be Assigned to an Artist.');
            } else if (props.material_data.status == 2 && props.material_data.assigned_artist) {
                setStatusValue('Material has been Assigned to an Artist since ' + props.material_data.assigned_on);
            } else if (props.material_data.status == 6) {
                setStatusValue('Material is being Processed since ' + props.material_data.last_modified);
            } else if (props.material_data.status == 3) {
                setStatusValue('Material is Pending for Admin QA since ' + props.material_data.last_modified);
            } else if (props.material_data.status == -4) {
                setStatusValue('Material was Rejected by Admin on ' + props.material_data.rejected_on);
            } else if (props.material_data.status == 5) {
                setStatusValue('Material was Shared with Customer on ' + props.material_data.admin_approved_on);
            } else if (props.material_data.status == 9) {
                setStatusValue('Material is Approved');
            } else if (props.material_data.status == 11) {
                setStatusValue("Material's Automation Job failed on " + props.material_data.last_modified);
            } else if (props.material_data.status == 12) {
                setStatusValue("Material's Automation Job aborted on " + props.material_data.last_modified);
            }
            if (props.material_data.renders && props.material_data.renders.data && props.material_data.renders.data && props.material_data.renders.data.QA_renders) {
                setRenders(props.material_data.renders.data.QA_renders);
                setThumbnail(props.material_data.renders.data.thumbnail);
            }
        }
        
    },[props.material_data]);

    const showRejectionModal = () => {
        setRejectModal(true);
    }

    const closeRejectModel = () => {
        setRejectModal(false);
    }

    let rejections_data = '';
    if (props.rejectionData && props.rejectionData.length > 0) {
        let ele = props.rejectionData[props.rejectionData.length - 1];
        let time = Date.parse(ele.rejection_time);
        time = new Date(time).toDateString();
        rejections_data = (
            <div style={{marginTop: 20}}>
            <div className='manrope f-14 grey-77'>Date <span style={{color:"#555555"}}>{time || '-'}</span></div>
            <div style={{display: "block",marginTop: 16}}>
                <Col span={16}>
                    <a className='manrope f-16 blue hover-change' href={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.material_data.id + '/' + props.material_data.id + '.zip'} download style={{paddingRight: 5, overflow: 'hidden'}}>
                        Download Material Bundle
                    </a>
                </Col>
            </div>
            {(props.artist && props.material_data.status == -4) ? <Col span={20} style={{marginTop: 16}}>
                <div className="manrope f-16 grey-77" style={{marginBottom: 8}}>
                    Rejected Material Renders
                </div>
                <div style={{display:'flex', flexWrap:'wrap'}}>
                {renders.map((image,index) => (
                    <Card style={{marginRight: 16,padding: 4}}>
                        <Image src={image.toLowerCase().includes('.jpg') || image.toLowerCase().includes('.png') || image.toLowerCase().includes('.jpeg') ? ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.material_data.id + '/' + image : ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.material_data.id + '/' + image + '.jpg'} className="rejected-mat"/>
                        {/* <div className="manrope f-14 black-55 justify-in-center" style={{textTransform:'capitalize'}}>{image}</div> */}
                    </Card>
                ))}
                    <Card style={{marginRight: 16,padding: 4}}>
                        <Image src={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.material_data.id + '/' + thumbnail} className="rejected-mat"/>
                        {/* <div className="manrope f-14 black-55 justify-in-center">Swatch</div> */}
                    </Card>
                </div>
            </Col>: ''}
           
            <div style={{display: "block",marginTop: 16}}>
                <Col span={16}>
                <span style={{paddingRight: 5, overflow: 'hidden'}}>
                    <span className='manrope f-16 grey-77' style={{textAlign: 'left',display: 'block', marginBottom: 7}}>
                        Comment
                    </span>
                    <span style={{display: 'flex'}}>
                        {!props.artist ? 
                        <span className="manrope f-14 black-55" style={{marginBottom: 0, width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left'}}>
                            {ele.message}
                        </span> : 
                         <span className="manrope f-14 black-55" style={{marginBottom: 0, width: '90%', textAlign: 'left'}}>
                            {ele.message}
                        </span> }
                    </span>
                </span>
                </Col>
                {(ele.reference_file != [] && ele.reference_file != undefined) && (typeof (ele.reference_file) == "object" && Object.keys(ele.reference_file).length != 0) ?
                    <Col span={20}>
                        <div className='manrope f-16 grey-77' style={{textAlign: 'left', marginBottom: 7}}>
                            Uploads
                        </div>
                        <div style={{display:'flex', flexWrap: 'wrap'}}>
                        {ele.reference_file && ele.reference_file.map((file,index) => (
                            ((file.name.split('.')[1] && (file.name.split('.')[1].toLowerCase() == 'jpg' || file.name.split('.')[1].toLowerCase() == 'jpeg' || file.name.split('.')[1].toLowerCase() == 'png' || file.name.split('.')[1].toLowerCase() == 'svg' )) ? 
                            <div style={{marginRight: 16}}>
                                <Image src={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} className="rejected-mat"/>
                            </div> 
                            : (file.name.split('.')[1] && (file.name.split('.')[1].toLowerCase() == 'tiff') ? 
                            <a style={{marginRight: 16}} href={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target="_blank">
                                <img className="rejected-mat" src={'/img/tiff_icon.png'}/>
                            </a> : 
                            <a className="upload-a-settings" style={{display: 'flex'}} target="_blank" href={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                                <img className="upload-image-settings" src={'/img/file-icon.png'}/> 
                                <div className="manrope f-14 black-55" style={{textAlign: 'left', marginRight: 10}}>{file.name}</div>
                            </a>)
                        )))}
                        </div>
                    </Col>
                : ''} 

                    
          </div>
        </div>
      );
    }

    let detailed_rejection_data = '';
    if (props.rejectionData && props.rejectionData.length > 0) {
        detailed_rejection_data = props.rejectionData.slice().reverse().map((ele, index) => (
        <div style={{marginTop: 25}}>
            <div className='manrope f-14 grey-77'>Date <span style={{color:"#555555"}}>{new Date(Date.parse(ele.rejection_time)).toDateString() || '-'}</span></div>
            <div style={{marginTop: 20}}>
                <span style={{paddingRight: 5}}>
                    <span className='manrope f-14 grey-77' style={{textAlign: 'left',display: 'block', marginBottom: 7}}>
                    Comment
                    </span>
                    <span className="manrope f-16 grey-77" style={{marginBottom: 0, width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left'}}>
                        {ele.message}
                    </span>
                </span>
            {(ele.reference_file != [] && ele.reference_file != undefined) && (typeof (ele.reference_file) == "object" && Object.keys(ele.reference_file).length != 0) ?
                <span>
                    <div className='manrope f-14 grey-77' style={{textAlign: 'left', marginBottom: 7,marginTop: 20}}>
                        Uploads
                    </div>

                <div style={{display:'flex', flexWrap: 'wrap'}}>
                    {ele.reference_file && ele.reference_file.map((file,index) => (
                        (file.name.split('.')[1] && (file.name.split('.')[1].toLowerCase() == 'jpg' || file.name.split('.')[1].toLowerCase() == 'jpeg' || file.name.split('.')[1].toLowerCase() == 'png' || file.name.split('.')[1].toLowerCase() == 'svg')) ?
                        <div style={{marginRight: 16}}>
                            <Image src={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} className="rejected-mat"/>
                        </div> 
                        : (file.name.split('.')[1] && (file.name.split('.')[1].toLowerCase() == 'tiff' ? 
                        <a style={{marginRight: 16}} href={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target="_blank">
                            <img className="rejected-mat" src={'/img/tiff_icon.png'}/>
                        </a> : 
                        <a className="upload-a-settings" style={{display: 'flex'}} target="_blank" href={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                            <img className="upload-image-settings" src={'/img/file-icon.png'}/> 
                            <div className="manrope f-14 black-55" style={{textAlign: 'left', marginRight: 10}}>{file.name}</div>
                        </a>)
                    )))}
                    </div>
                </span>
            : ''}
        </div> 
        <hr/>
    </div>
     ));
    }

    let product_photos = [];
    if (props.productData != undefined && props.productData.length > 0) {
        console.log('data',props.productData)
        Object.keys(props.productData.product_photos).forEach((key) => {
            let photos_array = props.productData.product_photos[key];
            if(!Array.isArray(photos_array))
            {
                if(key in photos_array){
                    photos_array = props.productData.product_photos[key][key];
                }
                else{
                    photos_array = [];
                }
            }
            let product_photos_subset = (photos_array || []).map((ele, index) => {
                let file_url = ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + ele.uid + '/' + ele.name;
                return <Card className="product-artist-card">
                    <Image
                    className="material-img" src={file_url}
                    />
                </Card>
            
            });
            if (key == 'product_photos_all')
            {
                product_photos.unshift(product_photos_subset);
            }
            else{
                product_photos.push(product_photos_subset);
            }
        });
    }
    

    
    return (
        <div style={{marginBottom: 32}}>
            <Row>
                <Col style={{display:"flex",alignItems:"center",marginTop:'32px'}}>
                    <div className="manrope f-18 black-33" style={{marginRight: 10}}>
                    Status:
                    </div>
                    <div className="note-bg-artist red">
                    <span className="manrope f-18 red">{statusValue}</span>
                    </div>
                </Col>
            </Row>
            {rejections_data ?
                    <Row style={{marginBottom: 16, marginTop: 15}}>
                        <Col sm={24} lg={24} xl={12} className="note-bg-artist red">
                            
                            <div className="justify-space-between">
                                <div className='manrope f-16 red-03' style={{display: 'inline'}}>
                                    Rejection Comments
                                </div>
                                {!props.artist ?
                                <Button type="link" onClick={() => {showRejectionModal()}} className='manrope f-16 blue' style={{display: 'inline'}}>
                                    View Details
                                </Button> : ""}
                            </div>
                            
                            {rejections_data} 
                        </Col>
                    </Row> : ""}
            <Row>
                <Col style={{marginTop:'32px'}} lg={20} xl={15} xxl={14} className="justify-space-between">
                    {!props.guidelines_page ? 
                    <span className="justify-space-between">
                        <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                        Customer Name
                        </div>
                        <div className="note-bg-artist pd-8 gray">
                            <span className="manrope f-16 black-55">{materialInfo.username}</span>
                        </div>
                    </span> : '' }
                    <span className="justify-space-between">
                        <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                        Category
                        </div>
                        <div className="note-bg-artist pd-8 gray">
                            <span className="manrope f-16 black-55 capitalize">{materialInfo.category}</span>
                        </div>
                    </span>
                    <span className="justify-space-between">
                        <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                        Type
                        </div>
                        <div className="note-bg-artist pd-8 gray">
                            <span className="manrope f-16 black-55 capitalize">{materialInfo.type}</span>
                        </div>
                    </span>
                    <span className="justify-space-between">
                        <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                        Requirement
                        </div>
                        <div className="note-bg-artist pd-8 gray" style={{width:"max-content"}}>
                            <span className="manrope f-16 black-55 capitalize">{materialRequiredOutput}</span>
                        </div>
                    </span>
                    {(materialInfo.width && materialInfo.length) && (materialInfo.width != 0 && materialInfo.length != 0) ?
                    <span className="justify-space-between">
                        <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                        Dimensions
                        </div>
                        <div className="note-bg-artist pd-8 gray" style={{width:"max-content"}}>
                            <span className="manrope f-16 black-55 capitalize">W {materialInfo.width}" H {materialInfo.length}"</span>
                        </div>
                    </span>: ''}
                    <span className="justify-space-between">
                        <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                        ID
                        </div>
                        <div className="note-bg-artist pd-8 gray">
                            <span className="manrope f-16 black-55">{materialInfo.id}</span>
                        </div>
                    </span>
                </Col>
            </Row>
            { materialInfo.requested_for && materialInfo.requested_for != "" &&
            <Row>
                <Col style={{marginTop:'15px'}} lg={20} xl={15} xxl={14} className="justify-space-between">
                    {!props.guidelines_page ? 
                    <span className="justify-space-between">
                        <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                        Requested For
                        </div>
                        <div className="note-bg-artist pd-8 gray">
                            <span className="manrope f-16 black-55 capitalize">{materialInfo.requested_for}</span>
                        </div>
                    </span>: ''}
                </Col>
            </Row>
            }
            {materialInfo && Object.keys(materialInfo.material_files).length != 0 ?
            <Row style={{marginTop: 40}}>
                <Col span={18}>
                    <Collapse defaultActiveKey={['1','2','3']} className="collapse-artist" ghost expandIconPosition={'right'}>
                        <Panel header={<span className="manrope black-55 w-700">Material Information</span>} key="1">
                            {materialInfo && Object.keys(materialInfo.material_files).length != 0 &&
                            <Row>
                                <Col>
                                    <div className="manrope f-16 grey-77" style={{marginTop: 32,marginBottom: 16}}>Material Swatch Uploaded by Customer</div>
                                    <Row style={{display:"flex"}}>
                                        <Col>
                                        { 
                                            materialInfo.material_files.map((file,index) => (
                                            materialInfo.is_scanned ?
                                            <div className="note-bg-specs font-14" style={{flexDirection: 'column',alignItems:"flex-start"}}>
                                                <a className="manrope f-14 blue hover-change clamp-text" style={{width: "200"}} href={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target="_blank">{ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)}</a>
                                            </div>
                                            : <Card className="product-artist-card">
                                                <Image
                                                className="material-img" src={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)}
                                                />              
                                            </Card> 
                                            ))
                                        }
                                            
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}
                        {materialInfo && Object.keys(materialInfo.reference_files).length != 0 && materialInfo.reference_files.length > 0 ? 
                            <Row>
                                <Col>
                                    <div className="manrope f-16 grey-77" style={{marginTop: 32,marginBottom: 16}}>Reference Material Images Uploaded By Customer</div>
                                    <Row style={{display:"flex"}}>
                                    {materialInfo.reference_files.map((file,index) => (
                                        <Col>
                                            <Card className="product-artist-card">
                                                    {(file.name.split('.')[1] && (file.name.split('.')[1].toLowerCase() == 'jpg' || file.name.split('.')[1].toLowerCase() == 'jpeg' || file.name.split('.')[1].toLowerCase() == 'png' || file.name.split('.')[1].toLowerCase() == 'svg')) ?
                                                    <Tooltip title={<span className="manrope f-14 white">{file.name}</span>}>

                                                    <Image
                                                    className="material-img" src={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)}
                                                    />
                                                    </Tooltip> : 
                                                    <a href={ENVIRONMENT.getBaseURL(props.material_data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} download>
                                                        <Tooltip title={<span className="manrope f-14 white">{file.name}</span>}>
                                                            <img  className="material-img" src="/img/file-icon.png"/> 
                                                        </Tooltip>
                                                    </a>
                                                    }  
                                            </Card>
                                        </Col>))}
                                    </Row>
                                </Col>
                            </Row>: ''}
                            {(materialInfo && materialInfo.guidelines && materialInfo.guidelines!= "")? 
                            <Row style={{marginBottom: 40,marginTop: 32}}>
                                <Col>
                                    <div className="manrope f-16 grey-77"  style={{textAlign:"left", marginBottom: 16}}>Guidelines and Comments</div>
                                    <ul>
                                    {materialInfo.guidelines.split('\n').map((item, i) => {
                                            if (item === "") {
                                            }
                                            else {
                                            return <li><div className="manrope f-14 black-55" style={{textAlign:'left'}} key={i}>{item}</div></li>;
                                            }
                                        })
                                        }
                                    </ul>
                                </Col>
                            </Row>: ''}   
                        </Panel>
                    </Collapse>
                </Col>
            </Row> : ''}
            {props.exportedMaterial && props.productDetails ? 
            <Row style={{marginTop: 40}}>
                <Col span={18}>
                    <Collapse defaultActiveKey={['1','2','3']} className="collapse-artist" ghost expandIconPosition={'right'}>
                        <Panel header={<span className="manrope black-55 w-700">Associated Model Information</span>} key="1">
                        <div className="manrope f-16 grey-77" style={{marginTop: 32,marginBottom: 16}}>Product Archive  </div>
                        <a download href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productID + '.zip'}>
                            <div className="download-model manrope f-14 grey-99">
                                <DownloadOutlined style={{marginBottom: 8, fontSize: 18}}/>
                                <div className="manrope f-14 black-55">{props.productID+ '.zip'}</div>
                            </div>
                        </a>

                        {props.productData && product_photos.length > 0 ?
                        <Row>
                            <Col>
                                <div className="manrope f-16 grey-77" style={{marginTop: 32,marginBottom: 16}}>Product Reference Images</div>
                                <Row >
                                    <Col style={{display:"flex",flexWrap: 'wrap'}}>
                                        {product_photos}
                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row> : ''}

                        {props.productDetails['2d'] && props.productDetails['2d'].length > 0 ?
                        <Row>
                            <Col>
                                <div className="manrope f-16 grey-77" style={{marginTop: 32,marginBottom: 16}}>QA Images    </div>
                                <Row >
                                    <Col style={{display:"flex",flexWrap: 'wrap'}}>
                                    { 
                                        props.productDetails['2d'].map((file,index) => (
                                            !file.includes('Debug') ?
                                            <Card className="product-artist-card">
                                                <Image 
                                                className="material-img" src={file}
                                                />     
                                            </Card> : ''
                                        ))
                                    }
                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row> : ''}
                        </Panel>
                    </Collapse>
                </Col>
            </Row> : ''}
            <Modal visible={showRejectModel} onCancel={closeRejectModel} footer={false} width={1000}>
                <div className="manrope f-24 black-14">
                        Rejection Comments
                </div>
                <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}}>
                    <div>
                        {detailed_rejection_data}
                    </div>
                    
                </div>
                    
            </Modal>
        </div>
      );
}

export default MaterialComponents;