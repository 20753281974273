import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Input, Card } from "antd";
import AdminMainLayout from '../AdminMainLayout';

import ScenesTable from '../ScenesTable';

const { TabPane } = Tabs;

class AdminScenesData extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"4"}>
        <Input
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search Products"
            onChange={this.changeUsername}
        />
        <Tabs defaultActiveKey="3" size={size} tabBarStyle={{textAlign: 'center'}} animated={false}>
          <TabPane tab="Customer QA Pending" key="1">
            
          </TabPane>
          <TabPane tab="Customer Approved" key="2">          
          
          </TabPane>
          <TabPane tab="Customer Complaints" key="3">
                <ScenesTable state="unhappy"/>
          </TabPane>
          
        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminScenesData;