import './GetProductFiles.css';
import React, { useEffect, useState } from 'react';
import {
  Input,
  Button,
  Card,
  Modal,
  InputNumber,
  TreeSelect,
  DatePicker,
  Typography,
  Select,
  Tag,
  Table,
  message,
} from 'antd';
import { ControlOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import ENVIRONMENT from '../../../../environments';
import AdminMainLayout from '../AdminMainLayout';
import DottedLoader from '../../DottedLoader';
import { saveAs } from 'file-saver';
const { Option } = Select;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { SHOW_PARENT, SHOW_CHILD } = TreeSelect;

const GetProductFiles = () => {
  const [inputValue, setInputValue] = useState('');
  const [productIDs, setProductIDs] = useState([]);
  const [filteredResultData, setFilteredResultData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('filters'); // 'tags' or 'filters'
  const [buttonLoader, setButtonLoader] = useState(false);
  const [attributeModal, setAttributeModal] = useState(false);

  const [maxRecords, setMaxRecords] = useState(10);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesTitles, setSelectedCategoriesTitles] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([
    'all_attributes',
  ]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getCompanyData();
    getCategoriesData();
  }, []);

  const tabOptions = [
    {
      tab: 'Filters',
      key: 'filters',
    },
    {
      tab: 'Product IDs',
      key: 'product_ids',
    },
  ];
  const attributes = [
    {
      title: 'All Attributes',
      value: 'all_attributes',
      key: 'all_attributes',
      children: [
        {
          title: 'Product ID',
          value: 'id',
          key: 'id',
        },
        {
          title: 'Product Name',
          value: 'name',
          key: 'name',
        },
        {
          title: 'Category',
          value: 'category',
          key: 'category',
        },
        {
          title: 'Manuals',
          value: 'manuals',
          key: 'manuals',
        },
        {
          title: 'Materials Files',
          value: 'material_files',
          key: 'material_files',
        },
        {
          title: 'Model Files',
          value: 'model_files',
          key: 'model_files',
        },
        {
          title: 'Raw Files',
          value: 'raw_files',
          key: 'raw_files',
        },
        {
          title: 'Product Thumbnail',
          value: 'thumbnail',
          key: 'thumbnail',
        },
        {
          title: 'Product Models',
          value: 'product_models',
          key: 'product_models',
        },
        {
          title: 'Perspective Renders',
          value: 'perspective_renders',
          key: 'perspective_renders',
        },
        {
          title: 'Quick Renders',
          value: 'quick_renders',
          key: 'quick_renders',
        },
        {
          title: 'Dimensional Renders',
          value: 'dimensional_render',
          key: 'dimensional_render',
        },
        {
          title: 'ThreeSixty Frames',
          value: 'threesixty_frames',
          key: 'threesixty_frames',
        },
        {
          title: 'Reference Files',
          value: 'reference_files',
          key: 'reference_files',
        },
        {
          title: 'Silo Data',
          value: 'silo_data',
          key: 'silo_data',
        },
        {
          title: 'Assets Dimensional Render',
          value: 'assets_dimensional_render',
          key: 'assets_dimensional_render',
        },
        {
          title: 'Assets Threesixty Frames',
          value: 'assets_threesixty_frames',
          key: 'assets_threesixty_frames',
        },
      ],
    },
  ];

  const defaultAttributes = attributes[0].children.map((child) => child.value);

  const updateCategoriesValues = (array) => {
    return array.map((item) => {
      const newItem = { ...item };

      // Update the value to be equal to the key
      newItem.value = newItem.key;

      // Recursively update children values
      if (newItem.children && newItem.children.length > 0) {
        newItem.children = updateCategoriesValues(newItem.children);
      }

      return newItem;
    });
  };

  const getCategoriesData = () => {
    const payload = {
      output: 'tree',
      disable_parents: 'false',
    };
    axios
        .post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then((res) => {
          const newArray = updateCategoriesValues(res.data);
          setCategoriesData(newArray);
        })
        .catch((error) => {
          message.error('Error occurred while fetching category data.');
        });
  };

  const getCompanyData = () => {
    axios
        .post(ENVIRONMENT.COMPANY_GET_BATCH)
        .then((response) => {
          setCompanyData(response.data);
        })
        .catch((error) => {
          message.error('Error occurred while fetching company data.');
        })
        .finally(() => {
          setLoader(false);
        });
  };

  const handleOptionChange = (key) => {
    setSelectedOption(key);
  };

  const convertToCSV = (data, includeHeaders = true) => {
    if (data.length === 0) {
      return null;
    }
    const header = Object.keys(data[0]);
    const csv = includeHeaders ? [header.join(',')] : [];

    for (const item of data) {
      const row = header.map((key) => {
        let cell = item[key];

        if (typeof cell === 'object' && cell !== null) {
          // If the value is an object (e.g., 'raw_files' or 'perspective_renders'), flatten and stringify it
          cell = JSON.stringify(cell);
        } else if (cell === null) {
          // If the value is null, convert it to an empty string
          cell = 'NULL';
        }

        // Wrap the cell in double quotes and handle escaping
        cell = `"${cell.replace(/"/g, '""')}"`;
        cell = cell.replace(/,/g, ' , ');
        cell = `"${cell.replace(/[\[\]"']/g, '')}"`;
        return cell;
      });
      csv.push(row.join(','));
    }

    const csvString = csv.join('\n');
    return csvString;
  };

  const handleDownloadCSV = (record) => {
    const csvData = convertToCSV([record]); // Convert record to CSV
    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `product_${record.id}.csv`);
    }
  };

  const handleDownloadMultipleCSV = (records) => {
    const combinedCSVData = records.map((record, index) => {
      const includeHeaders = index === 0;
      return convertToCSV([record], includeHeaders);
    });

    if (combinedCSVData.length > 0) {
      const combinedCSV = combinedCSVData.join('\n');
      const blob = new Blob([combinedCSV], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'all3d_product_files.csv');
    }
  };

  const openNewPageWithHtml = (data) => {
    // Generating HTML content with the added data
    const record = JSON.stringify(data, null, 4);
    const htmlContent = `
      <html>
        <head>
          <title>Product Details JSON</title>
        </head>
        <body>
          <h1>Product Details:</h1>
          <pre>${record}</pre>
        </body>
      </html>
    `;

    // Creating a new window or tab
    const newPage = window.open();

    // Writing the HTML content to the new tab
    if (newPage) {
      newPage.document.write(htmlContent);
      newPage.document.close();
    } else {
      message.error('Failed to open a new tab.');
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'JSON File',
      dataIndex: 'json',
      key: 'json',
      render: (_, record) => (
        <span>
          <a
            href={`data:application/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(record, null, 4),
            )}`}
            download={`all3d_product_data.json`}
          >
            Download
          </a>
          <span style={{ margin: '0 8px' }}>|</span>
          <a
            href='#'
            onClick={() => openNewPageWithHtml(record)}
            rel='noopener noreferrer'
          >
            Preview
          </a>
        </span>
      ),
    },
    {
      title: 'CSV File',
      dataIndex: 'csv',
      key: 'csv',
      render: (_, record) => (
        <a onClick={() => handleDownloadCSV(record)}>Download</a>
      ),
    },
  ];

  const handleAddProductID = () => {
    if (inputValue) {
      if (inputValue != '' && !productIDs.includes(inputValue)) {
        setProductIDs([...productIDs, inputValue]);
      }
      setInputValue('');
    }
  };

  const handleRemoveProductID = (tag) => {
    setProductIDs(productIDs.filter((t) => t !== tag));
  };

  const handleChangeCategories = (value, node, extra) => {
    setSelectedCategories(value);
    setSelectedCategoriesTitles(node);
  };

  const handleCompanySelect = (value) => {
    setSelectedCompany(value);
  };

  const handleDateRangeSelect = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleMaxRecordsChange = (value) => {
    setMaxRecords(value);
  };

  const handleAttributesChange = (value) => {
    if (value.length == 0) {
      setSelectedAttributes(['all_attributes']);
    } else {
      setSelectedAttributes(value);
    }
  };

  function deleteKeysFromObject(data) {
    if (!selectedAttributes.includes('all_attributes')) {
      const filteredAttributes = defaultAttributes.filter(
          (attr) => !selectedAttributes.includes(attr),
      );

      filteredAttributes.forEach((attribute) => {
        if (data.hasOwnProperty(attribute)) {
          delete data[attribute];
        }
      });
    }
  }

  const handleDataChange = () => {
    setButtonLoader(true);

    const updatedData = resultData.map((data) => {
      const newData = { ...data };
      deleteKeysFromObject(newData);
      return newData;
    });

    setFilteredResultData(updatedData);
    setAttributeModal(false);
    setButtonLoader(false);
  };

  const handleReset = () => {
    setSelectedCompany([]);
    setSelectedDateRange([]);
    setSelectedCategories([]);
    setProductIDs([]);
    setMaxRecords(10);
  };

  const handleSubmit = async () => {
    setButtonLoader(true);
    const payload = {};
    if (selectedOption == 'product_ids') {
      payload['product_ids'] = productIDs;
      if (productIDs.length == 0) {
        message.error('There is no PRODUCT_ID');
        setButtonLoader(false);
        return;
      }
    } else if (selectedOption == 'filters') {
      payload['limit'] = maxRecords;
      if (selectedCompany.length > 0) {
        payload['company_ids'] = selectedCompany;
      }
      if (selectedDateRange?.length == 2) {
        const startDate = moment(selectedDateRange[0]).format('YYYY-MM-DD');
        const endDate = moment(selectedDateRange[1]).format('YYYY-MM-DD');
        payload['start_date'] = startDate;
        payload['end_date'] = endDate;
      }
      if (selectedCategoriesTitles.length > 0) {
        payload['categories'] = selectedCategoriesTitles;
      }
    }

    axios
        .post(ENVIRONMENT.FETCH_PRODUCT_FILES, payload)
        .then((response) => {
          setResultData(response.data);
          setFilteredResultData(response.data);
        })
        .catch((error) => {
          message.error('Error occurred while retrieving data.');
        })
        .finally(() => {
          setButtonLoader(false);
          setSelectedAttributes(['all_attributes']);
        });
  };

  const contentList = {
    product_ids: (
      <div className='filterDiv'>
        <Input
          placeholder='Enter Product ID'
          value={inputValue}
          style={{ width: 200 }}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleAddProductID}
        />
        <div>
          {productIDs.map((id) => (
            <Tag
              className='manrope f-14 black-55'
              key={id}
              closable
              onClose={() => handleRemoveProductID(id)}
              style={{ marginTop: '5px' }}
            >
              {id}
            </Tag>
          ))}
        </div>
      </div>
    ),
    filters: (
      <div>
        <div className='filterDiv'>
          <Text>Max Records: </Text>
          <InputNumber
            value={maxRecords}
            style={{
              marginLeft: '16px',
            }}
            onChange={handleMaxRecordsChange}
            default={10}
            parser={(value) => (isNaN(value) ? '' : value)} // Ensure input is a number
          />
        </div>
        <div className='filterDiv'>
          <Text>Filter Companies: </Text>
          <Select
            mode='multiple'
            allowClear
            style={{ width: '20%', marginLeft: '16px' }}
            placeholder='All Companies'
            optionFilterProp='children'
            maxTagCount={0}
            onChange={handleCompanySelect}
            value={selectedCompany}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {companyData.map((company) => (
              <Option key={company.company_id} value={company.company_id}>
                {company.company_display_name}
              </Option>
            ))}
          </Select>
        </div>
        <div className='filterDiv'>
          <Text>Select Date Range: </Text>
          <RangePicker
            style={{
              marginLeft: '16px',
            }}
            allowClear
            value={selectedDateRange}
            onChange={handleDateRangeSelect}
          />
        </div>
        <div className='filterDiv'>
          <Text>Filter Categories: </Text>
          <TreeSelect
            value={selectedCategories}
            treeData={categoriesData}
            allowClear
            showSearch
            onChange={handleChangeCategories}
            treeCheckable
            maxTagCount={0}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={'All Categories'}
            style={{
              width: '50%',
              marginLeft: '16px',
            }}
            filterTreeNode={(input, treeNode) => {
              const title = treeNode.props.title.toLowerCase();
              return title.includes(input.toLowerCase());
            }}
          />
        </div>
      </div>
    ),
  };

  return (
    <AdminMainLayout selected={'21'}>
      <>
        <div
          className='manrope f-36 w-900'
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginTop: '30px',
            marginBottom: 32,
          }}
        >
          Product Details
        </div>
        {loader ? (
          <DottedLoader />
        ) : (
          <div>
            <Card
              title={<Title level={4}>Choose Option:</Title>}
              size='small'
              tabList={tabOptions}
              activeTabKey={selectedOption}
              onTabChange={handleOptionChange}
              style={{
                marginTop: '8px',
                marginRight: '8px',
                marginBottom: '8px',
              }}
            >
              {contentList[selectedOption]}
              <div className='buttonDiv'>
                <Button
                  type='primary'
                  style={{ margin: '8px', marginBottom: '10px' }}
                  onClick={handleSubmit}
                  loading={buttonLoader}
                >
                  {buttonLoader === false ? 'Submit' : 'Processing'}
                </Button>
                <Button
                  danger
                  style={{ margin: '8px', marginBottom: '10px' }}
                  onClick={handleReset}
                  disabled={buttonLoader}
                >
                  Reset
                </Button>
              </div>
            </Card>
            <div className='attributeDiv'>
              <Button
                type='primary'
                icon={<ControlOutlined />}
                disabled={buttonLoader}
                onClick={() => {
                  setAttributeModal(true);
                }}
              >
                Filter Attributes
              </Button>
              <Modal
                title='Filter Attributes'
                visible={attributeModal}
                okText='Save'
                onOk={() => handleDataChange()}
                onCancel={() => setAttributeModal(false)}
              >
                <TreeSelect
                  treeData={attributes}
                  value={selectedAttributes}
                  allowClear={true}
                  onChange={handleAttributesChange}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder={'Select Attributes'}
                  style={{
                    width: '100%',
                  }}
                />
              </Modal>
            </div>
            {filteredResultData.length > 0 && (
              <div style={{ marginTop: '10px', marginBottom: '8px' }}>
                <Title level={3}>
                  {filteredResultData.length}{' '}
                  {filteredResultData.length > 1 ? 'products' : 'product'}{' '}
                  found:{' '}
                </Title>
                <div className='bulkDiv'>
                  <Text>Download Found Products Data: </Text>
                  <a
                    href={`data:application/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(filteredResultData),
                    )}`}
                    download={`all3d_products_data.json`}
                  >
                    Download JSON
                  </a>
                  <a
                    onClick={() =>
                      handleDownloadMultipleCSV(filteredResultData)
                    }
                  >
                    Download CSV
                  </a>
                </div>
              </div>
            )}
            <Table dataSource={filteredResultData} columns={columns} />
          </div>
        )}
      </>
    </AdminMainLayout>
  );
};

export default GetProductFiles;
