import React from "react";
import { Table, Button, Input, Space } from "antd";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import DottedLoader from "../../DottedLoader";
import { SearchOutlined } from "@ant-design/icons";
import ArtistMainLayout from "../../ArtistComponents/ArtistMainLayout";

const title = () => "Here is title";
const showHeader = true;
const footer = () => "Here is footer";
const scroll = { y: 240 };
const pagination = { position: "bottom" };

class ArtistTestAssessmentTable extends React.Component {
  formRef = React.createRef();

  state = {
    bordered: false,
    loading: false,
    pagination,
    size: "default",
    title: undefined,
    showHeader,
    footer: false,
    tableLayout: "fixed",
    scroll: undefined,
    hasData: true,
    dataSource: null,
    isLoading: false,
  };

  getColumns = () => [
    {
      title: "Artist Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...this.getColumnSearchProps("name"),
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Submission Date",
      dataIndex: "submission_date",
      key: "submission_date",
      sorter: (a, b) => new Date(a.submission_date) - new Date(b.submission_date),
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a
            style={{ marginRight: 10 }}
            className="onhover-underline"
            href={`../${!!this.props?.isSuperArtist ? 'super_artist' : 'admin'}_score_artist/${record.action}`}
          >
          Evaluate Test
          </a>
        </span>
      ),
    },
  ];

  componentDidMount = () => {
    this.handleDataSource();
  };

  handleDataSource = () => {
    let data = [];
    let payload = {
      status: ["artist_completed"],
    };
    this.setState({ isLoading: true });
    axios.post(ENVIRONMENT.GET_ARTIST_TEST_BATCH, payload).then((res) => {
      console.log("ArtistTestAssessmentTable Data Source", res.data);
      for (let i = 1; i <= res.data.length; i++) {
        data.push({
          key: i,
          name: res.data[i - 1]["username"],
          submission_date: res.data[i - 1]["submitted_on"].split(" ")[0],
          action: res.data[i - 1]["id"],
        });
      }
      data = data.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.setState({
        dataSource: data,
        isLoading: false,
      });
    });
  };

  handleToggle = (prop) => (enable) => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = (enable) => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = (enable) => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = (enable) => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = (enable) => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = (hasData) => {
    this.setState({ hasData });
  };

  handlePaginationChange = (e) => {
    const { value } = e.target;
    this.setState({
      pagination: value === "none" ? false : { position: value },
    });
  };

  // Search in table functionality
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const artistTestTable = (
      <>
        {this.state.isLoading ? (
            <DottedLoader />
          ) : (
            <div style={{margin: !!this.props?.isSuperArtist ? 20 : 0}}>
              
              {!!this.props?.isSuperArtist && 
              <span  className="manrope f-24 33" style={{marginBottom:20}}>
                Artist Test List
              </span>
              }
  
              {this.state.dataSource && (
                <Table
                  {...this.state}
                  columns={this.getColumns()}
                  dataSource={this.state.dataSource}
                />
              )}
            </div>
          )}
      </>
      )

    return (
      <>
      {!!this.props?.isSuperArtist ? (
      <ArtistMainLayout selected={"10"}>
        {artistTestTable}
      </ArtistMainLayout>) : 
      (
        <>
        {artistTestTable}
        </>
        )};
      </>
    );
  }
}

export default ArtistTestAssessmentTable;
