import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Row, Col, Button,notification ,Tabs,Card} from 'antd';
import SaveCardFlow from '../SaveCardFlow';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import CustomerMainLayout from '../CustomerMainLayout';
import ShowCardFlow from '../ShowCarfFlow/ShowCardFlow';
import SubscriptionPlanDetails from '../SubscriptionPlansDetails/SubscriptionPlansDetails';
import './AccountDetails.scss'
import CustomerOnboarding from '../CustomerOnboarding';
import CompanyProfile from '../CompanyProfile/CompanyProfile';
import FileConstants from '../../../../FileConstants';
import GroupManagement from '../GroupManagement/GroupManagement';

const { TabPane } = Tabs;
const COMPANY_ID = FileConstants.COMPANY_ID;

const openNotification = placement => {
    notification.info({
      message: 'Deleted!',
      description:
        'Your card has been removed from the system, you can now enter information for a new card.',
      placement,
    });
  };

const AccountDetails = (props) => {
   
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, setLoading] = useState(true);
    const [cardSaveSuccess, setCardSaveSucces] = useState(false);
    const [cardStatus, setCardStatus] = useState(null);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [userInformation, setUserInformation] = useState("");
    const [openModal, setOpenModal] = useState(false);
    let tour_step = new URLSearchParams(window.location.search).get("step_no");
    let payment_redirected = new URLSearchParams(window.location.search).get("payment_redirected");

    useEffect(() => {
        console.log('REDIRECTION ATTRIBYTE',payment_redirected);
        if (payment_redirected) {
            setCardSaveSucces(true);
        }
        loadUserPaymentType();
    },[]);
  
    const loadUserPaymentType = (callback = undefined) => {
        let payload = {
          "username": localStorage.getItem('username'),
          "action": "getinfo"
        }
        axios.post(ENVIRONMENT.GET_USER_PAYMENT_INFORMATION, payload)
        .then(res => {
            setUserInformation(res.data['card_information'])
            setCardStatus(res.data['card_status']);
            if (callback != undefined) {
                callback();
            }
        });
      }

    const deleteUserPaymentType = () => {
        let payload = {
            "username": localStorage.getItem('username'),
            "action": "delete"
          }
        axios.post(ENVIRONMENT.GET_USER_PAYMENT_INFORMATION, payload)
        .then(res => {
            setUserInformation(res.data['card_information'])
            setCardStatus(res.data['card_status']);
            loadUserPaymentType(() => setOpenModal(false));
            openNotification('topRight');
        });
    }

    
    return (
          <CustomerMainLayout>
                
            <Row className="justify-space-between" style={{marginTop:"30px",marginBottom: 32}}>
                <Col>
                    <div className='manrope f-24 w-900' style={{display:'flex',alignItems:"flex-end"}}>
                        Account Settings
                    </div>
                </Col>
            </Row>
            <Card>
            <Tabs style={{padding:"10px"}} defaultActiveKey={ (new URL(window.location.href)).searchParams.get("tab") == "subs" || tour_step == '4' ? "2" : (new URL(window.location.href)).searchParams.get("tab") == "group" ? "3": "1"}  >
                {/* {localStorage.getItem('company_id') &&
                <TabPane tab="Profile Settings" key={"1"}>
                    <CompanyProfile/>
                </TabPane>} */}
                <TabPane tab="Credit Card Information" key={"1"}>
                    <Row>
                        {cardStatus == 0 ?
                        <Col span={16}>
                                <h2 className="manrope f-16 black-14" style={{marginBottom: 28}}>Credit Card Information</h2>
                                <SaveCardFlow account_details_form={true} setCardSaveFailure={setCardSaveFailure}/>
                            </Col>:                     
                            cardStatus == 1 ? <ShowCardFlow 
                                deleteUserPaymentType={deleteUserPaymentType}
                                userInformation={userInformation}
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                /> : ''
                            }
                    </Row>  
                </TabPane>
                <TabPane tab="Subscription Plan" key={"2"}>
                    <SubscriptionPlanDetails tour_step={tour_step} cardStatus={cardStatus}/>
                </TabPane>
                <TabPane tab="Group Management" key={"3"}>
                    <GroupManagement />
                </TabPane>
            </Tabs>
            </Card>

            
            <SuccessModal
            visible={cardSaveSuccess}
            heading={"Card Saved Successfully"}
            text={"Your card has been successfully saved!"}
            footer={[
            <div className="justify-in-center">
                <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                    window.location.reload();}}>
                    Okay
                </Button>
            </div>
            ]}/>
        
            <WarningModal
            visible={cardSaveFailure}
            onCancel={() => setCardSaveFailure(false)}
            heading={"Unable to Save Card"}
            text={"We are very sorry but we were unable to save your card. Please try again."}
            footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => setCardSaveFailure(false)}>
                    Okay
                </Button>
            </div>
            ]}/>
            
            {tour_step ?
            <CustomerOnboarding/>
            :
            ""    
            }
        </CustomerMainLayout>
      );

}

export default AccountDetails;
  