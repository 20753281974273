import React, { Component } from "react";
import "@google/model-viewer";

const isTouchDevice = require('is-touch-device');

class ProductGoogleViewer extends React.Component{

    render() {
        let policy = 'always-allow'
        if (isTouchDevice()) {
            policy = 'allow-when-focused'
        }
        return <div><model-viewer
                    ar
                    style={this.props.custom_style}
                    src={this.props.gltf}
                    ios-src={this.props.gltf.replace("glb", "usdz").replace(".glb",".usdz")}
                    alt="3D View"
                    auto-rotate camera-controls
                    data-js-focus-visible
                    // poster="/img/loading.svg"
                    interaction-policy={policy}
                    ar-modes="webxr scene-viewer quick-look"
                    environment-image="neutral"
                >
            </model-viewer>
        </div>
    }
}

export default ProductGoogleViewer;
