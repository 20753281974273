import React, { useContext } from 'react';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import {
    Row, Col, Tooltip
} from 'antd';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;


const TextContainer = (props) => {
    return (<Tooltip title={<span className='manrope f-12 white'>{props.value}</span>}>
        <Col span={4} className='col-width-16'>
            <div className="manrope f-14 lh-14 grey-8c text-left">{props.name}</div>
            <div className="manrope f-16 lh-16 black-26 clamp-w-width">
                {props.value}
            </div>
        </Col>
    </Tooltip>)
}

const ProductModelInformation = () => {
    const { productAssetData, productData, modelWidth, modelHeight, modelDepth, baseVariantPlatform } = useContext(CollaborateQAContext);


    return (<Row className='model-detail-product-qa'>
        <Col span={24}>
            <div className='model-info-heading-pos pd-20'>
                <h4 className='manrope f-16 black-26 m-0'>Product Details</h4>
            </div>
            <Row className='basic-detail-container p-20'>

                {productData["group_id"] &&
                    <TextContainer
                        name={"Group ID"}
                        value={productData["group_id"]}
                    />}

                {modelWidth != "N/A" && modelHeight != "N/A" && modelDepth != "N/A" &&
                    <TextContainer
                        name={"Dimensions"}
                        value={<>{modelWidth == "N/A" ? "N/A" : modelWidth.toFixed(1)}"W x {modelHeight == "N/A" ? 'N/A' : modelHeight.toFixed(1)}"H x {modelDepth == "N/A" ? 'N/A' : modelDepth.toFixed(1)}"D</>}
                    />
                }

                {productData["category_type"] ?
                    <TextContainer
                        name={"Bedding Type"}
                        value={<>{FileConstants.HIDDEN_CATEGORY[productData.category].map((cat) => (
                            cat == productData.category_type ? cat : ''
                        ))}</>}
                    />
                    :
                    FileConstants.HIDDEN_CATEGORY[productData.category] ?
                        <TextContainer
                            name={"Bedding Type"}
                            value={"White Mattress & Pillows"}
                        />
                        : FileConstants.HIDDEN_CATEGORY[productData.parent_category] ?
                            <TextContainer
                                name={"Bedding Type"}
                                value={productData.category}
                            /> : ''}

                {productData["color_name"] &&
                    <TextContainer
                        name={"Color"}
                        value={productData.color_name}
                    />}
                {productData["similar_color"] &&
                    <TextContainer
                        name={"Similar Color"}
                        value={productData.similar_color}
                    />}
                {productData["hex_color"] &&
                    <TextContainer
                        name={"Hex Color"}
                        value={productData.hex_color}
                    />}
                {productData["pantone_color"] &&
                    <TextContainer
                        name={"Pantone Color"}
                        value={productData.pantone_color}
                    />}
                {productData["style_category"] &&
                    <TextContainer
                        name={"Style Category"}
                        value={productData.style_category}
                    />}
                {productData["product_model_type"] &&
                    <TextContainer
                        name={"Type"}
                        value={FileConstants.PRODUCT_MODEL_TYPE[productData["product_model_type"]]}
                    />}
                {(productData["materials"] && productData["materials"].length > 0) &&
                    <Col span={4}>
                        <div className="manrope f-14 lh-14 grey-8c text-left">
                            Materials
                        </div>
                        <div className="justify-in-start" style={{ display: 'inline-block' }}>
                            {productData["materials"].map((material, index) => (
                                <span className="manrope f-16 lh-16 black-26" key={index}>
                                    {index > 0 ? `, ${material}` : `${material}`}
                                </span>
                            ))
                            }
                        </div>
                    </Col>}

                {(productData["tags"] && productData["tags"].length > 0) &&
                    <Col span={4}>
                        <div className="manrope f-14 grey-8c">Tags</div>
                        <div className="justify-in-start" style={{ display: 'inline-block' }}>
                            {productData["tags"].map((tag, index) => (
                                <span className="manrope f-16 black-26" key={index}>
                                    {index > 0 ? `, ${tag}` : `${tag}`}
                                </span>
                            ))
                            }
                        </div>
                    </Col>}

                {productData["variant_of"] &&
                    <TextContainer
                        name={"Model Type"}
                        value={"Variation"}
                    />}
                {productData["segmented"] &&
                    <TextContainer
                        name={"Segmented"}
                        value={"Yes"}
                    />}
                {productData["variation_type"] &&
                    <TextContainer
                        name={"Variation Type"}
                        value={FileConstants.variationDict(productData["variation_type"], productAssetData['need_to_model'])}
                    />
                }
                {(productData["variant_of"] && (CUSTOMER_USERNAME == productData['customer_username'] || productData['company_id'] == COMPANY_ID)) &&
                    <Col span={4}>
                        <div className="manrope f-14 lh-14 grey-8c text-left">Parent Model</div>
                        <a className="manrope f-16 lh-16 blue"
                            href={ENVIRONMENT.getBaseURL(baseVariantPlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + productData["variant_of"] + '.zip'}
                            download>
                            {productData["variant_of"]}
                        </a>
                    </Col>}

                {productData.licensing_options && productData.licensing_options.license &&
                    <Col span={4}>
                        <div className="manrope f-14 lh-14 grey-8c text-left">License</div>
                        <div className="manrope f-16 lh-16 black-26 capitalize clamp-w-width">
                            {productData.licensing_options.license}
                            {productData.licensing_options.license == 'public' ? productData.licensing_options.public_option == 'forCreativeCommon' ?
                                <Tooltip title={<span className='manrope f-12 white'>{productData.licensing_options.creative_common}</span>}>
                                    <img className='not-downloadable ml-4' src={FileConstants.getLicensingIcon(productData.licensing_options.creative_common)} />
                                </Tooltip> :
                                <>
                                    <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                                        <img className="all3d-logo-dim ml-4" src={FileConstants.getLicensingIcon(productData.licensing_options.public_option)} />
                                    </Tooltip>
                                    &nbsp;<Tooltip title={<span className='manrope f-12 white'>Not Downloadable</span>}>
                                        <img src='/img/not_downloadable.png' className='not-downloadable' />
                                    </Tooltip>
                                </>
                                : ''}
                        </div>
                    </Col>}
            </Row>
        </Col>
    </Row>)

}

export default ProductModelInformation;