import React, {useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import CustomerMainLayout from '../CustomerMainLayout'
import { DownOutlined, FilterOutlined, PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Input,
    Col,
    Row,
    Card,
    Select,
    Radio,
    Menu,
    Dropdown,
    Button,
    Drawer,
    Checkbox,
    Tabs
} from 'antd';
import AddCollection  from "./AddCollection";
import {listProducts, getScannedProducts} from "../../../../redux/actions";
import {connect} from "react-redux";

import {withRouter, Link} from 'react-router-dom'
const { TabPane } = Tabs;
const InputGroup = Input.Group;
const {Option} = Select;

const assets_menu = (
    <Menu>
      <Menu.Item>
        <Link to="/add-new-product">
            Create Product
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/add-new-room">
            Create Room
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/add_scene">
            Create Scene
        </Link>
      </Menu.Item>
      
    </Menu>
  );

const CustomerLibrary = (props) => {

    const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue} = props.form;
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };
    const [filterDrawer, setFilterDrawer] = useState({
        visible: false
    });
    const [selectedProductIdForCollection, setSelectedProductIdForCollection] = useState(null);
    const [collectionModalVisible, setCollectionModalVisible] = useState(false);
    useEffect(() => {
        props.listProducts();
        props.getScannedProducts();
    }, []);

    const onAddCollectionClick = (id) => {
        setSelectedProductIdForCollection(id);
        setCollectionModalVisible(true);
    }
    const onChange = () => {};

    const qaPassedProducts = props.productReducer.products.filter((ele) => ele.model_status==5).map((ele, index) => {
        return (
            <Col span={6} key={index} offset={0}>
                    <Card bodyStyle={{padding: "10px"}} className="card-shadow-product" bordered={false} style={{marginRight: "15px", marginBottom: "15px"}}>
                        <PlusOutlined
                            title="Add to collection"
                            onClick={() => onAddCollectionClick(ele.id)}
                            style={{float: "right", marginBottom: "5px", cursor: "pointer"}} />
                        <Link to={`/products/${ele.id}`} className="suggested-product" >
                            <img className="product-image" src={ele.thumbnail || require("../../../../assets/images/collection_incomplete.svg")}/>
                            <div className="product-name">{ele.name}</div>
                            <div className="product-detail"> {ele.category || '-'}</div>
                        </Link>
                    </Card>
            </Col>
        );
    });

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <Checkbox onChange={onChange}>Product Library</Checkbox>
            </Menu.Item>
            <Menu.Item key="2">
                <Checkbox onChange={onChange}>Room Library</Checkbox>
            </Menu.Item>
            <Menu.Item key="3">
                <Checkbox onChange={onChange}>Scene Library</Checkbox>
            </Menu.Item>
            <Menu.Item key="4">
                <Checkbox onChange={onChange}>Collection Library</Checkbox>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="my-products">
            <CustomerMainLayout selected='14'>
                <div id="hellodiv" style={{position: "absolute", left: 0, zIndex: "1", width: "100%"}}>
                    <div style={{paddingLeft: "254px", paddingRight: "20px", background: "#F3F3F3", height: "70px", marginRight: "15px"}}>
                        <Form className="product-filter-form" style={{paddingTop: "15px"}}>
                            <Input.Group compact size="large" className="d-inline">
                                <div className="filter-box d-inline">
                                    <Select compact={true} mode="tags" style={{width: "100%"}}
                                            placeholder="Search all items">
                                        <Option value="chair">chair</Option>
                                        <Option value="bed">bed</Option>
                                    </Select>
                                </div>
                                <Dropdown overlay={menu}>
                                    <Button size="large">
                                        Item Type <DownOutlined />
                                    </Button>
                                </Dropdown>
                                <Button className="d-inline" style={{marginLeft: "5px"}} size="large"
                                        onClick={() => setFilterDrawer({visible: true})}>
                                    <img style={{height: "25px"}} src={require("../../../../assets/images/filter.svg")}/>Filters
                                </Button>

                                    {/* <Button onClick={() => props.history.push('/add-new-product')}  type="danger" shape="round" className="d-inline float-right" style={{float: "right"}} size="large">
                                        <Icon type="plus"/> Add new
                                    </Button> */}

                                    <Dropdown overlay={assets_menu} trigger={['click']}>
                                        <Button type="danger"  className="d-inline float-right" style={{float: "right"}} size="large" shape="round" icon={<PlusOutlined />}>
                                            Add New
                                        </Button>
                                            
                                        
                                    </Dropdown>

                                <div style={{width: "1px"}}>
                                </div>
                            </Input.Group>

                            <Drawer
                                title="Filters"
                                placement="right"
                                onClose={() => setFilterDrawer({visible: false})}
                                closable={true}
                                visible={filterDrawer.visible}>
                                <div className="product-filters">
                                    <div className="heading-filter">
                                        <p>Model Status</p>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Checkbox onChange={onChange}>My Products</Checkbox>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Checkbox onChange={onChange}>QA pending Products</Checkbox>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Checkbox onChange={onChange}>Products in progress</Checkbox>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Checkbox onChange={onChange}> Incomplete products</Checkbox>
                                    </div>

                                    <div className="filter-item-padding">
                                        <Checkbox onChange={onChange}> Rejected products</Checkbox>
                                    </div>

                                    <div className="heading-filter">
                                        <p>License Type </p>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Checkbox onChange={onChange}>Free</Checkbox>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Checkbox onChange={onChange}>Paid</Checkbox>
                                    </div>

                                    <div className="heading-filter">
                                        <p> Tags </p>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Select mode="tags" style={{width: '100%', marginBottom: "10px"}}
                                                placeholder="Please type your tag">
                                            <Option value="chair">chair</Option>
                                            <Option value="bed">bed</Option>
                                        </Select>
                                    </div>

                                    <div className="heading-filter">
                                        <p> Created By Date </p>
                                    </div>
                                    <div className="filter-item-padding">
                                        <Radio.Group onChange={onChange}>
                                            <Radio style={radioStyle} value={1}>
                                                Any Date
                                            </Radio>
                                            <Radio style={radioStyle} value={2}>
                                                Last 24 hours
                                            </Radio>
                                            <Radio style={radioStyle} value={3}>
                                                Last 48 hours
                                            </Radio>
                                            <Radio style={radioStyle} value={3}>
                                                Last 72 hours
                                            </Radio>
                                            <Radio style={radioStyle} value={3}>
                                                Last 30 months
                                            </Radio>

                                        </Radio.Group>
                                    </div>
                                    <div style={{textAlign: "center"}}>
                                        <Button shape="round">
                                            <FilterOutlined /> RESET
                                        </Button>
                                    </div>
                                </div>
                            </Drawer>
                        </Form>

                    </div>
                </div>
                <div style={{height: "90px"}}></div>
                <div>
                    <Tabs defaultActiveKey={new URLSearchParams(window.location.search).get("tabId") || '1'} >
                        <TabPane tab="Product Library" key="1">
                            <Row className="product-tab-content-scrollable" type="flex"  style={{marginTop:"10px", justifyContent: "start"}}>
                                {qaPassedProducts}
                            </Row>
                        </TabPane>
                        <TabPane  tab="Room Library" key="2">
                            <div className="text-center"> {props.productReducer.isLoading? <LoadingOutlined spin />: ''}</div>
                            <Row className="product-tab-content-scrollable" type="flex"  style={{marginTop:"10px", justifyContent: "start"}}>
                            </Row>
                        </TabPane>
                        <TabPane tab="Scene Library" key="3">
                            <Row className="product-tab-content-scrollable" type="flex"  style={{marginTop:"10px", justifyContent: "start"}}>
                            </Row>
                        </TabPane>
                        <TabPane tab="Collection Library" key="4">
                            <Row className="product-tab-content-scrollable" type="flex"  style={{marginTop:"10px", justifyContent: "start"}}>
                            </Row>
                        </TabPane>
                        
                    </Tabs>
                </div>
                <AddCollection productId={selectedProductIdForCollection} visible={collectionModalVisible} setCollectionModalVisible={setCollectionModalVisible}></AddCollection>
            </CustomerMainLayout>
        </div>
    );
}
const CustomerLibraryForm = Form.create({name: 'product_filters'})(CustomerLibrary);


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listProducts: () => {
        dispatch(listProducts());
    },
    getScannedProducts: () => {
        dispatch(getScannedProducts())
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(CustomerLibraryForm)
)
