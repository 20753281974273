import React from "react";
import "@google/model-viewer";

class PublicProductGoogleViewer extends React.Component{

    render() {
        return <div><model-viewer
                    style={{height: 700, width: "100%"}}
                    src="https://all3d.s3.amazonaws.com/product_assets/glb/629V2.glb"
                    alt="3D View"
                    auto-rotate camera-controls
                >
            </model-viewer>
        </div>
    }
}

export default PublicProductGoogleViewer;
