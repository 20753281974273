import React, { useEffect, useState } from 'react';
import environments from '../../../environments';
import axios from 'axios';
import { Select } from 'antd';
import FileConstants from '../../../FileConstants';

const { Option } = Select;
const COMPANY_ID = FileConstants.COMPANY_ID;

const EmailSelect = ({ value = undefined, onChange, className, disabled, placeholder, mode = undefined, allowClear = undefined }) => {
    const [emailList, setEmailList] = useState([]);

    const getCompanyUsersEmails = () => {
        if(COMPANY_ID){
            let payload = {
                "required_fields": ["email"],
                "filter_string": `(company_id__exact=${COMPANY_ID})`,
                "order_by": "email asc"
            }
            axios.post(environments.COMPANY_ENTITY_GET_BATCH, payload)
            .then(res => {
                console.log('Company User Emails', res.data);
                setEmailList(res.data);
            })
        }
    }

    useEffect(() => {
        getCompanyUsersEmails();
    }, [])
    
    return (
        <Select
            className={className}
            onChange={onChange}
            disabled={disabled}
            value={value}
            showSearch
            mode={mode}
            allowClear={allowClear}
            placeholder={placeholder ? placeholder : "Enter Email Address of people to share"}
        >
            {emailList.length> 0 && emailList.map((item,key)=>(
            <Option className="manrope f-12 select-view" value={item.email} key={key}>{item.email}</Option>
        ))}
        </Select>
    );
}
export default EmailSelect;