import React, { useEffect, useState, useCallback } from 'react';
import 'antd/dist/antd.css';
import axios from 'axios';
import { Row, Col, Input, Button, Upload, Form, message, Card,Modal,Select, Dropdown, TreeSelect,Menu } from 'antd';
import * as Constants from '../../CustomerComponents/Constants'
import MaterialComponents from '../../MaterialComponents/MaterialComponents'
import { getProductRender, updateProduct } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminMainLayout from '../AdminMainLayout';
import { CheckOutlined, LoadingOutlined, PaperClipOutlined,DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { ADMIN_MATERIAL_BATCH, ARTIST_PROFILE_LIST, MATERIAL_GET, ENTITY_MODEL_REJECT, REJECT_MATERIALS_BATCH, UPDATE_MATERIAL, UPDATE_MATERIALS_BATCH, MATERIAL_ARTIST_HOUR_GET,MATERIAL_REJECTIONS_GET_BATCH } from '../../../../environments/env';
import ENVIRONMENT from '../../../../environments';
import DottedLoader from '../../DottedLoader';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';
import * as Utilities from '../../Utilities';
import FileConstants from "../../../../FileConstants"

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

const material_type = [
  { id: 1, pId: 0, value: 'plain', title: 'Plain' },
  { id: 2, pId: 0, value: 'patterned', title: 'Patterned' },

]

const MODEL_VIEWER_KEY = 'JKQ76HB9AB4HLNH8FK4'
const AdminMaterialGuidelines = (props) => {
  const [materialsData, setMaterialsData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [materialType, setMaterialType] = useState('');
  const [materialSearchType, setMaterialSearchType] = useState([]);
  const [materialValue, setMaterialValue] = useState('');
  const [rejectionError, setRejectionError] = useState('');
  const [selectedImage,setSelectedImage] = useState();
  const [selectedView, setSelectedView] = useState('2D');
  const [approve, setApprove] = useState(false);
  const [rejectModal , setRejectModal] = useState(false);
  const [rejectedState, setRejectedState] = useState(false);
  const [materialData, setMaterialData] = useState('');
  const [rejectionMessage, setRejectionMessage] = useState('');
  const [rejectSuccess, setRejectSuccess] = useState(false);
  const [customerSuccess, setCustomerSuccess] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [uploadError, setUploadError]  = useState('');
  const [assignArtistModal, setAssignArtistModal] = useState(false);
  const [renders, setRenders] = useState([]);
  const [materialSearchValue, setMaterialSearchValue] = useState('');
  const [matImage, setMatImage] = useState('');
  const [approveModal, setApproveModal] = useState(false);
  const [materialStatus, setMaterialStatus] = useState(-1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [, updateState] = React.useState();
  const [relatedMaterials, setRelatedMaterials] = useState([]);
  const [relatedProductID, setRelatedProductID] = useState(-1);
  const [approveLoader, setApproveLoader] = useState(false);
  const [productStatus, setProductStatus] = useState(-1);
  const [rejectedMaterials, setRejectedMaterials] = useState([]);
  const [allApproved, setAllApproved] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState('');
  const [artists, setArtists] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [exportedMaterial, setExportedMaterial] = useState(false);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [artistError, setArtistError] = useState('');
  const [metaError, setMetaError] = useState('');
  const [productDetails, setProductDetails] = useState([]);
  const [productID, setProductID] = useState(-1);
  const [productData,setProductData] = useState([]);
  const [rejectionData, setRejectionData] = useState([]);
  const [artistTime, setArtistTime] = useState(-1);
  const [fixesTime, setFixesTime] = useState([])
  const [materialRequiredOutput, setMaterialRequiredOutput] = useState('');
  const [show3D, setShow3D] = useState(false);
  const [renderedMaterial, setRenderedMaterial] = useState(false);
  const [nonRenderedMaterial, setNonRenderedMaterial] = useState(false)
  const [adminModelStatusChange,setAdminModelStatusChange] = useState(false);
  const { match: { params } } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    let payload = {
      material_id: params.id
    };
    axios.post(MATERIAL_GET, payload )
    .then(res => {
      let material_data = JSON.parse(res.data.body);
      console.log('==>', material_data);
      if (!Array.isArray(material_data.product_id) && material_data?.product_id) {
        fetchProductData(material_data.product_id);
        setProductID(material_data.product_id);
      }
      loadArtistsData();
      setMaterialData(material_data);
      console.log(material_data);
      setSelectedArtist(material_data.assigned_artist);
      setMaterialStatus(parseInt(material_data.status));
      setMaterialRequiredOutput(FileConstants.MATERIAL_TYPE_LABELS[material_data.material_output])
      if (material_data.renders && material_data.renders.data && material_data.renders.data && material_data.renders.data.QA_renders) {
        setRenders(material_data.renders.data.QA_renders);
        setMatImage(material_data.renders.data.thumbnail);
        setSelectedImage(material_data.renders.data.QA_renders[0]);

      }
      if(FileConstants.AR_MATERIAL_OUTPUT_TYPES.includes(material_data.material_output)){
        setShow3D(true);
        setNonRenderedMaterial(true);
      }
      if(FileConstants.RENDERING_MATERIAL_OUTPUT_TYPES.includes(material_data.material_output)){
        setRenderedMaterial(true);
      }
      if (material_data.status == 9) {
        setRejectedState(false);
        setApprove(true);
      } else if (material_data.status == -4) {
        setRejectedState(true);
        setApprove(false);
      }
      if (material_data.status == 3 || material_data.status == -4) {
        axios.post(MATERIAL_ARTIST_HOUR_GET,{material_id: material_data.id})
        .then(res => {
          if (res.data && res.data.body) {
            let artist_hour_data = JSON.parse(res.data.body);
            console.log(artist_hour_data);
            setArtistTime(artist_hour_data.time_spent);
          }

        })
      }

      loadRejectionData(material_data.id);
      setLoadingState(false);



      //This code is to be used later on
      // axios.post(GET_CONFIGURATION_DATA, payload)
      // .then(res => {
      //   console.log('Configuration Data',res.data);
      //   setRelatedProductID(res.data.product_id);
      //   setProductStatus(res.data.product_status);
      //   addToGroup(res.data.materials);
      //   setLoadingState(false);
      // }).catch(err => {
      //   console.log(err);
      // });
    });
  },[]);

  const fetchProductData = (id) => {
    console.log(id);
    props.getProduct({ product_id: id, admin: true, debug: true });
    axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id: id })
        .then(res => {
          console.log(res.data)
          setProductData(res.data);
          setExportedMaterial(true);
        });
  }

  useEffect(() => {
    console.log(props.productReducer.product)
    setProductDetails(props.productReducer.product);
  },[props.productReducer.product])

  const loadRejectionData = (id) => {
    let payload = {
      material_id: id
    };
    console.log('Rejection Payload',payload);
    axios.post(MATERIAL_REJECTIONS_GET_BATCH, payload )
    .then(res => {
        console.log(res.data);
        setRejectionData(res.data);
        let fixes_time = []
        res.data.map(rejection_data => {
          if(rejection_data.fix_time)
            fixes_time.push(rejection_data.fix_time);
        })
        setFixesTime(fixes_time);
        forceUpdate();
    });
  }

  const saveMaterialInfo = () => {
    document.getElementById("save_material").click();
  }

  const loadArtistsData = () => {
    axios.post(ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        let artist_list = [];
        res.data.map(artist => {
          if (artist.is_active == true || artist.is_active == "True") {
            artist_list.push(artist);
          }
        });
        setArtists(artist_list);
      });
  }

  const changeArtist = (value) => {
    console.log(value)
    setSelectedArtist(value);
  }

  const cancelEditModal = () => {
    setEditModal(false);
  }

  const onFinish = (values) => {
    let payload = {
      material_id: materialData.id,
      category: values.material_category,
      type: values.material_type
    };
    setEditModalLoader(true);
    axios.post(UPDATE_MATERIAL, payload)
    .then(res => {

      let payload = {
        material_id: params.id
      };
      axios.post(MATERIAL_GET, payload )
      .then(res => {
        let material_data = JSON.parse(res.data.body);
        setMaterialData(material_data);

        setButtonLoader(true);
        let payload_send_customer = {
          material_id: params.id,
          status: "5",
          username: localStorage.getItem("username")
        }
        console.log('Payload for Send to Customer', payload_send_customer)
        axios.post(UPDATE_MATERIAL,payload_send_customer)
        .then(res => {
          if (materialData.requested_for != "") {
            const transfer_payload = {
              entity_type : 'material',
              material_id: params.id,
              requested_for : materialData.requested_for
            }
            axios.post(ENVIRONMENT.TRANSFER_OWNERSHIP, transfer_payload)
            .then(res => {
              setEditModal(false);
              setEditModalLoader(false);
              setButtonLoader(false);
              setCustomerSuccess(true);
            });
          } else {
            setEditModal(false);
            setEditModalLoader(false);
            setButtonLoader(false);
            setCustomerSuccess(true);
          }
        });
      });
    });
    console.log(values);

  }

  useEffect(() => {

    let data = [];
    axios.post(ADMIN_MATERIAL_BATCH, {
    })
    .then(res => {
        let categoriesData = getMaterialsData( res.data );
        for (let i = 1; i <= (res.data).length; i++) {
            if( res.data[i - 1].parent_id == "")
            {
                data.push({
                    key: res.data[i - 1]['material_id'],
                    title: res.data[i - 1]['name'],
                    value: res.data[i - 1]['name'],
                    children: categoriesData[res.data[i - 1]['material_id']],
                });
            }

        }
        setMaterialsData(data);
    });
  },[]);

  const addMaterialOption = (e) => {
    if(e.keyCode == 13){
        if(!materialsData.some(e => e.key == materialSearchValue)){
            setMaterialsData(materialsData.concat([{
                key: materialSearchValue,
                value: materialSearchValue,
                title: materialSearchValue,
                children: [],
            }]));
        }
    }
  }

  const getMaterialsData = ( catDataAray ) => {
    var catDataDict = {};

    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id == "") {
            catDataDict[ catDataAray[ i - 1 ].material_id ] = []
        }
    }


    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id != "") {

            catDataDict[ catDataAray[ i - 1 ].parent_id ].push( {
                    title: catDataAray[ i - 1 ].name,
                    value: catDataAray[ i - 1 ].name,
                    key: catDataAray[ i - 1 ].material_id,
                }
            );

        }
    }

    return catDataDict;
  }

  const statusMessage = (status) => {
    if (status == 2) {
      return 'Pending';
    } else if (status == 6) {
      return 'In Progress';
    } else if (status == 3) {
      return 'Material in Review';
    } else if (status == -4) {
      return 'Material Reject';
    } else if (status == 5) {
      return 'Material in Customer Library';
    } else if (status == 9) {
      return 'Material is Approved';
    } else if (status == 11) {
      return 'Material automaton job get failed';
    } else if (status == 12) {
      return 'Material automaton job get aborted';
    }
  }

  const addToGroup = (materials) => {
    let groups = materials && materials.reduce((groups, material) => {
      let category = material.category;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(material);
      return groups;
    }, {});

    console.log('Materials',materials);

    let check_if_all_approved = true;
    materials && materials.map((mat,index) => {
      if (mat.status != 9) {
        check_if_all_approved = false;
      }
    });

    if (check_if_all_approved) {
      setAllApproved(true);
    }

    // To add it in the array format instead
    let groupArrays = Object.keys(groups).map((category) => {
      return {
        category,
        material: groups[category]
      };
    });

    setRelatedMaterials(groupArrays);

  }

  const changeView = (e) => {
    setSelectedView(e.target.value);
  }

  const selectImage = (image) => {
    setSelectedImage(image);
  }

  const reviewModel = () => {
    window.location.href = "/admin_products/" + relatedProductID;
  }

  const toggleApprove = () => {
    if (materialData.status != 9 && relatedMaterials.length > 0) {
      let payload_approve = {
        material_id: materialData.id,
        status: "9",
      }
      setApproveLoader(true);
      console.log('Payload for Approve', payload_approve)
      axios.post(UPDATE_MATERIAL,payload_approve)
      .then(res => {
        let payload_mat = {
          material_id: params.id
        };

        //This code is to be used later
        // axios.post(GET_CONFIGURATION_DATA, payload_mat)
        // .then(res => {
        //   console.log(res.data);

        //   setRelatedProductID(res.data.product_id);
        //   setProductStatus(res.data.product_status);
        //   addToGroup(res.data.materials);


        // }).catch(err => {
        //   console.log(err);
        // })
        setMaterialStatus(9);
        setApprove(true);
        setApproveLoader(false);
        setRejectedState(false);
        forceUpdate();
      });
    } else if (relatedMaterials.length == 0) {
      setApprove(true);
      setRejectedState(false);
    }
  }

  const writeMessage = (e) => {
    setRejectionMessage(e.target.value);
  }

  const cancelApproveModal = () => {
    setApproveModal(false);
    setConfirmModal(true);
  }

  const rejectionAPICall = (payload_rejection, payload) => {
    axios.post(ENTITY_MODEL_REJECT, payload_rejection )
    .then(res => {
      axios.post(UPDATE_MATERIAL,payload)
      .then(res => {
        console.log(res);
        setRejectModal(false);
        setButtonLoader(false);
        setRejectSuccess(true);
      }).catch(err => {
        console.log(err);
         setButtonLoader(false);
        message.error('An error occured. Unable to update material.');
      });
    }).catch(err => {
        console.log(err);
        setButtonLoader(false);
        message.error('An error occured. Unable to reject material.');
    });
  }

  const rejectMaterials = () => {
    setMetaError('');
    setArtistError('');
    setRejectionError('');
    if (relatedMaterials.length <= 1 && rejectionMessage != "") {

      let payload_rejection = {};
      let uploadAttachStatus = validateFileUploads(attachments);
      if (uploadAttachStatus == "error") {
        setUploadError('An error occured while uploading. Please try again.')
      } else if (uploadAttachStatus == "uploading") {
        setUploadError('Please wait while uploads are in progress.');
      } else {
        setUploadError('');
        setButtonLoader(true);
        let payload = {
          material_id: params.id,
          status: "-4",
          admin_username: localStorage.getItem('username')
        }

        if (assignArtistModal) {
          payload_rejection = {
            material_id: params.id,
            message: rejectionMessage,
            reference_file: attachments,
            qa_person: localStorage.getItem('username'),
            artist: selectedArtist,
            rejection_against_artist: materialData.assigned_artist
          };

          if (selectedArtist) {
            setArtistError('');
            if ((materialData.category == 'default' || materialData.type == "default") && (materialType && materialValue)) {
              setMetaError('');
              payload.category = materialValue;
              payload.type = materialType;
              payload.assigned_artist = selectedArtist;
              payload.status = -4;
              payload.qa_role = 'admin';

              rejectionAPICall(payload_rejection, payload);

            } else if (materialData.category != 'default' && materialData.type != "default") {
              rejectionAPICall(payload_rejection, payload);
            } else {
              setButtonLoader(false);
              setMetaError('You cannot leave material type and category fields empty.');
            }

          } else {
            setButtonLoader(false);
            if (assignArtistModal) {
              setArtistError('Please select an artist');
            }
            if ((materialData.category == 'default' || materialData.type == "default") && (materialType == '' && materialValue == '')) {
              setMetaError('You cannot leave material type and category fields empty.');
            }
          }

        } else {
          payload_rejection = {
            material_id: params.id,
            message: rejectionMessage,
            reference_file: attachments,
            qa_person: localStorage.getItem('username'),
            artist: materialData.assigned_artist,
            qa_role: 'admin',
            rejection_against_artist: materialData.assigned_artist
          };
          rejectionAPICall(payload_rejection, payload);
        }

      }
    } else if (relatedMaterials.length == 0 && rejectionMessage == "")  {
      setRejectionError('Please enter a rejection message.');
      if (materialData.assigned_artist == "") {
        setArtistError('Please select an artist');
      }
      if ((materialData.category == 'default' || materialData.type == "default") && (materialType == '' && materialValue == '')) {
        setMetaError('You cannot leave material type and category fields empty.');
      }

    }
      else {
      document.getElementById('submit_button').click();
    }


  }

  const openArtistModal = () => {
    setAssignArtistModal(true);
  }

  const cancelRejectModal = () => {
    setRejectModal(false);
    setRejectionError('');
    setMetaError('');
    setArtistError('');
    setButtonLoader(false);
    setUploadError('');
    setRejectionMessage('');
  }

  const sendBackMaterials = (values) => {
    if ((relatedMaterials && relatedMaterials.length > 0) && (rejectedMaterials && rejectedMaterials.length > 0)) {
      setButtonLoader(true);
      let payload_rejection = [];
      let payload_entry = {};
      let payload = {
        product_id: relatedProductID,
        customer_username: materialData.username,
        materials: []
      }
      let material_status_entry = {};
      rejectedMaterials.map((mat,index) => {
        payload_entry = {
          material_id: mat.id,
          message: values["text_"+mat.id],
          reference_file: values["image_"+mat.id] && values["image_"+mat.id].fileList,
          qa_person: localStorage.getItem("username"),
          assigned_artist: mat.assigned_artist
        };
        payload_rejection.push(payload_entry);

        material_status_entry = {
          material_id: mat.id,
          status: -4
        };
        payload.materials.push(material_status_entry);
      });
      console.log('Payload Rejection',payload_rejection);
      console.log('Payload Update Status',payload);

      axios.post(REJECT_MATERIALS_BATCH, payload_rejection )
      .then(res => {
        console.log(res);

        axios.post(UPDATE_MATERIALS_BATCH,payload)
        .then(res => {
          console.log(res);
          setRejectModal(false);
          setButtonLoader(false);
          setRejectSuccess(true);
        }).catch(err => {
          console.log(err);
          message.error('An error occured. Unable to update material(s).');
          setButtonLoader(false);
        })
      }).catch(err => {
        console.log(err);
        setButtonLoader(false);
        message.error('An error occured. Unable to reject material(s).');
      });
    }
  }



  const handleChange = (info) => {
    let fileList = [...info.fileList];
    setAttachments(fileList);
  }

  const shareWithCustomer = () => {
    if (relatedMaterials.length == 0 ) {
      if (materialData.category == 'default' || materialData.type == "default") {
        setEditModal(true);
      } else {
        setButtonLoader(true);
        setApproveLoader(true);
        let payload_send_customer = {
          material_id: params.id,
          status: "5",
          username: localStorage.getItem("username")
        }
        console.log('Payload for Send to Customer', payload_send_customer)
        axios.post(UPDATE_MATERIAL,payload_send_customer)
        .then(res => {
          if (materialData.requested_for != "") {
            const transfer_payload = {
              entity_type : 'material',
              material_id: params.id,
              requested_for : materialData.requested_for
            }
            axios.post(ENVIRONMENT.TRANSFER_OWNERSHIP, transfer_payload)
            .then(res => {
              setButtonLoader(false);
              setCustomerSuccess(true);
              setApproveLoader(false);
            });
          } else {
            setButtonLoader(false);
            setCustomerSuccess(true);
            setApproveLoader(false);
          }
        });
      }

    } else if (relatedMaterials.length > 0 && allApproved) {
      if (productStatus != 9) {
        setApproveModal(true);
      } else if (productStatus == 9) {
        setButtonLoader(true);
        props.updateProduct({
          product_id: params.id,
          model_status: "4"
        });

        let payload_approve = {
          product_id: relatedProductID,
          customer_username: materialData.username,
          materials: []
        }
        let material_entry = {};

        relatedMaterials.map((data,index) => {
          data.material.map((mat) => {
            material_entry = {
              material_id: mat.id,
              status: 5
            };
            payload_approve.materials.push(material_entry);
          });
        });

        axios.post(UPDATE_MATERIALS_BATCH,payload_approve)
          .then(res => {
            console.log(res);

            setButtonLoader(false);
            setCustomerSuccess(true);
          });
        console.log('Payload Approve',payload_approve);

      }
    }
  }

  const checkIfRejected = () => {
    let material_already_rejected = rejectedMaterials && rejectedMaterials.filter((x) => { return x.id == (materialData.id)});
    if (material_already_rejected && material_already_rejected.length != 0) {
      return true;
    }
    return false;

  }

  const openRejectModal = () => {
    if (relatedMaterials.length > 1) {
      let rejected_arr = rejectedMaterials;
      if (!checkIfRejected()) {
        rejected_arr.push(materialData);
        setRejectedMaterials(rejected_arr);
        forceUpdate();
      }
    } else {
        openArtistModal();
        setRejectModal(true);
        setUploadError('');
        setRejectionError('');
        setMetaError('');
        setArtistError('');
    }
  }

  const applyMaterial = (material) => {
    setMaterialData(material);

    setMaterialStatus(parseInt(material.status));
    if (material.renders && material.renders.data && material.renders.data && material.renders.data.QA_renders) {
      setRenders(material.renders.data.QA_renders);
      setMatImage(material.renders.data.thumbnail);
      setSelectedImage(material.renders.data.QA_renders[0]);
    }
    if (material.status == 9) {
      setApprove(true);
      setRejectedState(false);
    } else if (material.status == -4) {
      setRejectedState(true);
      setApprove(false);
    } else {
      setRejectedState(false);
      setApprove(false);
    }

    if (material.category == 'default' || material.type == "default") {
      setEditModal(true);
    }
  }

  const validateFileUploads = (files) => {
    let status_done = false;
    if (files != undefined) {
      for(var file of files) {
        if (file['status'] == 'uploading') {
          return 'uploading';
        }
        else if (file['status'] == 'error') {
          return 'error';
        }
        else if (file['status'] == 'done') {
          status_done = true;
        }
      }
    }
    if (status_done) {
      return 'done'
    }
    return 'not_started';
  }

  const setImageAfterInspector = (img) =>{
    selectImage (img);
    setShow3D(false);
    if(document.getElementById('material-inspector') != null)
      document.getElementById('material-inspector').style.display = 'none';

  }

  const downloadMaterialRenders = () => {
    let downloadData = [];
    if(materialData && renders && renders.length > 0) {
      message.info('Generating Material Renders, your download will start shortly.', 6);
      renders.map((image) => {
        if(image.includes('.jpg') || image.includes('.png') || image.includes('.jpeg'))
          downloadData.push(ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + image);
        else
          downloadData.push(ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + image + '.jpg');
      });
    }
    Utilities.saveToZip (`material_renders_${params.id}.zip`, downloadData);
  }

  const renameMaterialBundle = () => {
    if (materialData && materialData.status == 5 && materialData.input_bundle) {
      message.info('Your download will start shortly.', 6);
      Utilities.downloadAs(materialData.input_bundle,materialData.id+'.zip')
    }
  }

  const renameMaterialSwatch = () => {
    if (materialData && materialData.status == 5 && matImage) {
        message.info('Your download will start shortly.', 6);
        Utilities.downloadAs(ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + matImage, materialData.id  + ".jpg")
    }
  }

  const totalFixesTime = () => {
    var hours = 0;
    for(var i=0; i<fixesTime.length; i++){
        hours += +fixesTime[i];
    }
    return Utilities.convertTimeToHoursString(hours)
  }

  const sendMaterialToAdminQA = () => {
    let payload = {
      material_id: params.id,
      status: "3",
      previous_status:materialData.status,
      username: localStorage.getItem("username"),
      isAdminOverride : true
    }
    axios.post(UPDATE_MATERIAL,payload)
    .then(res => {
      setAdminModelStatusChange(true);
    }); 
  }


  const menu = (
    <Menu style={{ width: "200px",fontFamily:"Avenir" }}>
        <Menu.Item key={0}>
          <a download href={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.input_bundle}>Material Bundle<span style={{ float: "right", color:"#1681E5" }}><DownloadOutlined/></span></a>
        </Menu.Item>
        {
          renderedMaterial
          ?
          <>
            <Menu.Item key={1}>
            <a download onClick={downloadMaterialRenders}>Material Renders<span style={{ float: "right" , color:"#1681E5" }}><DownloadOutlined/></span></a>
            </Menu.Item>
            <Menu.Item key={2}>
              <a download href={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + matImage}>Material Swatch<span style={{ float: "right" , color:"#1681E5" }}><DownloadOutlined/></span></a>
            </Menu.Item>
          </>
          :
          ''
        }


    </Menu>
  );

  const admin_override_menu = (
    <Menu style={{ width: "145px",fontFamily:"Avenir" }}>
        <Menu.Item key={1}>
            <span style={{cursor: "pointer"}} onClick = {() => sendMaterialToAdminQA()}>Move to Admin QA</span>
        </Menu.Item>
    </Menu>
);

  return (<AdminMainLayout selected="16">
    {materialData == '' || loadingState ? <DottedLoader/> :<div>
    <Row style={{marginTop: 32}}>
      <Col span={24}>
        <div className="manrope f-16 grey-77">
          {/* <a className="manrope f-16 grey-77" href="/admin_materials">Home</a> / Review&nbsp;<span style={{color: "#555555",textTransform:"capitalize"}}>{materialData.name}</span> */}
          <div style={{display: 'flex', float: 'right', marginBottom: 5, justifyContent: 'space-between'}}>
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <Button className="modal-okay" style={{float: 'right', marginRight: 2, minHeight: '42px', maxHeight: '42px'}}>
                <span className="modal-okay-text">Download Links <DownOutlined/></span>
              </Button>
            </Dropdown>
            {![3,6].includes(parseInt(materialData.status)) && <Dropdown overlay={admin_override_menu} trigger={['click']} >
              <Button className="modal-okay-orange" style={{float: 'right', marginRight: 2, minHeight: '42px', maxHeight: '42px'}}>
                <span className="modal-okay-text">Admin Overrides <DownOutlined/></span>
              </Button>
            </Dropdown>}
          </div>
        </div>
      </Col>
    </Row>
    <Row style={{marginTop: 48 }}>
      <Col span={24}>
        {(selectedView == '2D') && ![11,12,6].includes(parseInt(materialData.status)) ?
        <Row>
          <Col span={relatedMaterials.length > 1 ? 18 : 24}>
            {(materialData && (materialStatus == 3 || materialStatus == 5 )) ?
            <div style={{textAlign:'center', marginTop: 10}} >
              <div  style={{textAlign:'center',display:'flex',justifyContent:'center'}} >
              {
                nonRenderedMaterial ?
                <iframe  id='material-inspector' onLoad={()=>{
                  document.getElementById('material-inspector').contentWindow.postMessage(MODEL_VIEWER_KEY, "*");
                }} src={FileConstants.MaterialInspector + materialData.id} />
                  :
                ''
              }
              {
                !show3D?
              selectedImage && (selectedImage.includes('.jpg') || selectedImage.includes('.jpeg') || selectedImage.includes('.png')) ?
              <ImageZoomAdmin material={true} image={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + selectedImage} className={`selected-mat admin ${rejectedState ? `blur` : ``}`}/>
              :
              <ImageZoomAdmin material={true} image={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + selectedImage + '.jpg'} className={`selected-mat admin ${rejectedState ? `blur` : ``}`}/> : ''}
              </div>

              {rejectedState ?
              <div className="reject-admin-bg manrope f-16 white">This material was rejected by admin and is currently being fixed by the Artist. The admin will be notified via email when the material is ready for review again.</div>
              : ''}
            </div> :
            <div style={{textAlign:'center', marginTop: 10}}>
              {materialData && materialData.material_files[0] && !materialData.is_scanned ?

                <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(materialData.material_files[0].uid + '/' + materialData.material_files[0].name)}  className={`selected-mat ${rejectedState ? `blur` : ``}`}/>
                :
                materialData && materialData.material_files[0] && materialData.is_scanned ?
                <img src={'/img/placeholder.png'} className={`selected-mat ${rejectedState ? `blur` : ``}`}/>
                :
                materialData && materialData.renders && materialData.renders.data && materialData.renders.data.thumbnail ?
                <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + materialData.renders.data.thumbnail}  className={`selected-mat ${rejectedState ? `blur` : ``}`}/>  : ''
              }
                {rejectedState ?
                  <div className="reject-admin-bg manrope f-16 white">This material was rejected by admin and is currently being fixed by the Artist. The admin will be notified via email when the material is ready for review again.</div>
                :
                materialData.is_scanned  && (materialStatus != 3 && materialStatus != 5 && materialStatus != -4)?
                  <div className="reject-admin-bg manrope f-16 white">Scanned Material Uploaded by Customer</div>
                : ''}
            </div>

            }

            <div className="justify-space-between" style={{padding: "0 3% 0 0",display: rejectedState ? "none" : "flex"}}>

              {(materialData && (materialStatus == 3 || materialStatus == 5 || materialStatus == 9)) ?
              <div className="justify-in-start">
                  {renderedMaterial && renders.map((image,index) => (
                    <div>
                      {
                        show3D
                        ?
                        ''
                        :
                        <div className="tag-bg large"><span className="tag-txt large">{FileConstants.MATERIAL_TYPE_LABELS_IMAGE[FileConstants.materialRenderTypeResolution(selectedImage)]}</span></div>
                      }
                      <Card style={{marginRight: 16,padding: 4, cursor:"pointer",border: image == selectedImage && !show3D ? "1px solid #141414" : "1px solid #e4e4e4"}} onClick={() => {
                        setImageAfterInspector (image)
                        }}>

                        <div className="tag-bg"><span className="tag-txt">{FileConstants.MATERIAL_TYPE_LABELS_IMAGE[FileConstants.materialRenderTypeResolution(image)]}</span></div>
                        <img src={image.includes('.jpg') || image.includes('.png') || image.includes('.jpeg') ? ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + image : ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + image + '.jpg'} className="select-mat"/>
                      </Card>
                    </div>
                  ))}
                  {
                    renderedMaterial ?
                    <Card style={{marginRight: 16,padding: 4, cursor:"pointer",border: matImage == selectedImage && !show3D? "1px solid #141414" : "1px solid #e4e4e4"}}
                    onClick={() => {
                      setImageAfterInspector(matImage)
                    }}
                    >
                      <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' + matImage} className="select-mat"/>
                      <div className="tag-bg" ><span className="tag-txt">Material</span></div>
                    </Card>
                    :
                  ''
                  }

                  {
                      renderedMaterial &&  nonRenderedMaterial
                         ?
                        <Card style={{marginRight: 16,padding: 4, cursor:"pointer", textAlign:'center',border: show3D ? "1px solid #141414" : "1px solid #e4e4e4"}}
                        onClick={() => {
                          setShow3D(true);
                          if(document.getElementById('material-inspector') != null)
                            document.getElementById('material-inspector').style.display = 'block';
                        }}
                        >
                          <img src={"/img/3d.jpg"} className="select-mat-small"/>
                          <div className="manrope f-14 grey-77 justify-in-center" style={{paddingTop: 8, textTransform: 'capitalize'}}>inspector</div>
                        </Card>
                    :
                    ''
                  }
                </div>: ''}

              </div>

          </Col>

        {relatedMaterials.length > 1 ?
        <Col span={6} style={{padding: 16, borderLeft: "1px solid #E4E4E4"}}>
          {relatedMaterials.map((data, index) => (
            <span>
          <div className="manrope f-18 black-33" style={{marginBottom: 8}}>{data.category}</div>
          <div style={{display:"flex",flexFlow:"wrap",marginBottom: 8}}>
            {data.material.map((material,index) =>
                <Card className="material-card select" style={{border: materialData.id == material.id ? "1px solid black" : "none"}} onClick={() => applyMaterial(material)}>

                  {material.renders.data && material.renders.data && material.renders.data.thumbnail ?
                    <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className={'material-img pattern-select'}/>
                    : material.material_files[0] ?
                    <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)} className={'material-img pattern-select'}/> :
                    <img src={'/img/placeholder.png'} className={'material-img pattern-select'}/>}

                    {material.status == 9 || (materialData.id == material.id && materialStatus == 9) ?
                      <img className="success-img smaller" src="/img/success-small.png"/> : material.status == -4 || (materialData.id == material.id && materialStatus == -4) ?
                      <img className="success-img smaller" src="/img/alert-triangle.png"/>: ''}
                </Card>
            )}
            </div>
            </span>
            ))}
        </Col>: ''}
        </Row> : ''}

        {rejectedMaterials && rejectedMaterials.length > 0 ?
        <Row style={{marginTop: 32}}>
          <Col span={24}>
            <div className="note-bg-artist pd-16 gray justify-space-between">
              <div>
                <div className="manrope f-18 black-55" style={{marginBottom: 16}}>Rejections</div>
                {rejectedMaterials.map((element) => (
                  element.renders && element.renders.data && element.renders.data.thumbnail ?
                  <img className="material-img reject" src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + params.id + '/' + element.renders.data.thumbnail}/> :
                  <img className="material-img reject" src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(element.material_files[0].uid + '/' + element.material_files[0].name)} />
                ))}
              </div>
              <Button className="modal-okay square font-14" onClick={() => setRejectModal(true)}>
                Submit Rejections
              </Button>
            </div>
          </Col>
        </Row>: ''}

        <span style={{marginTop: 48}} className="justify-in-start">
          <div className="manrope f-32 black-14" style={{textTransform:"capitalize"}}>{materialData.name}</div>
          {materialData.assigned_artist != "" && materialData.assigned_artist != null ? <div className="manrope f-16 black-55" style={{marginLeft: 16}}>by {materialData.assigned_artist}</div>: ''}
        </span>
        <MaterialComponents material_data={materialData} exportedMaterial={exportedMaterial} materialRequiredOutput={materialRequiredOutput} productDetails={productDetails} productID={productID} productData={productData}  rejectionData={rejectionData}/>
        { artistTime != -1  ?
                <Col span={24} style={{paddingBottom: 10}}>
                <React.Fragment>
                    <div>
                        <span className="manrope f-16 grey-77">Initial Modeling Time</span>
                        <span className="manrope f-16 blue" style={{paddingLeft: 10}}>{artistTime} Hours</span>
                    </div>
                </React.Fragment>
            </Col> :''}
            { fixesTime.length > 0 ?
                 <Col span={24} style={{paddingBottom: 10}}>
                  <React.Fragment>
                      <div>
                          <span className="manrope f-16 grey-77">Total Fixing Time</span>
                          <span className="manrope f-16 blue" style={{paddingLeft: 10}}>{totalFixesTime()}</span>
                      </div>
                      <table className="stats-table" style={{marginTop: 13, display: 'block', marginBottom: 90}}>
                        <tr>
                        {
                          fixesTime.map((time, index) => (
                            <td style={{display: 'inline-block'}}>
                                <span className="manrope f-16 grey-77" style={{display: 'block', paddingTop: 7}}>Fix {fixesTime.length - index}</span>
                                <span className="manrope f-14 black-55" style={{paddingTop: 7}}>{Utilities.convertTimeToHoursString(time)}</span>
                            </td>
                          ))

                        }
                        </tr>

                    </table>
                  </React.Fragment>
             </Col> :''}
      </Col>
    </Row>

    {/* To be used later */}
    {/* {((relatedMaterials.length == 0 && approve) || (relatedMaterials.length > 0 && allApproved)) ?
      <div className="sticky-banner">
          <div className="justify-in-end">
              <Button disabled={buttonLoader} className="modal-okay square font-16" onClick={shareWithCustomer}>
                {buttonLoader ? "Sharing With Customer" : "Share With Customer"}
                {buttonLoader  ? <LoadingOutlined/> : ''}
              </Button>
          </div>
      </div>: ''} */}
      {materialStatus == 3 ?
      <Row type="flex" justify="space-between">
        <div className='product-status-review-admin' style={{display: 'flex', justifyContent: 'end'}}>
        {approve ? '' :
          (materialStatus == 3) ?
          <Button disabled={approveLoader} style={{marginRight: 16}} onClick={openRejectModal}>{checkIfRejected() ? "Rejected" : "Reject"}</Button>
          : ''}
          {(materialStatus == 3) && !checkIfRejected() ?
          <Button disabled={approveLoader} type='danger' onClick={()=> {shareWithCustomer()}}>
            <span>Approve&nbsp;{approveLoader ? <LoadingOutlined/> : ''}</span>
          </Button> : ''}
        </div>
      </Row> : '' }

    <Modal
    visible={rejectModal}
    onCancel={cancelRejectModal}
    destroyOnClose={true}
    maskClosable={false}
    className="aspect-ratio-modal small"
    footer={[
      <span className="justify-in-end">
        <Button disabled={buttonLoader} className="modal-okay-gray square font-14" onClick={() => setRejectModal(false)}>
          Cancel
        </Button>
        <Button disabled={buttonLoader} className="modal-okay square font-14" htmlType="submit" onClick={rejectMaterials}>
          Submit&nbsp;{buttonLoader ? <LoadingOutlined/>: ''}
        </Button>
      </span>
    ]}
  >
    <div style={{padding:"32px 32px 24px 32px"}}>
        <div className="manrope f-24 black-55" style={{marginBottom: 24}}>
          Submit Material Rejection
        </div>
        {relatedMaterials.length <= 1 ?
        <div className="justify-align-start">

        {
          renderedMaterial ?
          <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + params.id + '/' + matImage} className="reject-mat-img"/>
          :
          ''
        }
          <div style={{marginLeft: 16,width: "100%"}}>
              {materialData.category == "default" ?
                <TreeSelect
                className="tree-select-material"
                showSearch
                style={{ width: '50%', color:"#333333", marginBottom: 8}}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={'Select a material category'}
                treeData={materialsData}
                // value={materialValue}
                onChange={(value) => setMaterialValue(value)}
                onSearch={(e) => {setMaterialSearchValue(e)}}
                onInputKeyDown={(e) => { addMaterialOption(e)} }
                notFoundContent={<span className='tree-select-material'>No such material found. Press enter to add your material.</span>}>
              </TreeSelect>: ""}

              {materialData.type == "default" ?
              <TreeSelect
                  className="tree-select-material"
                  showSearch
                  style={{ width: '50%', color:"#333333",marginBottom: 8, paddingLeft: 8}}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder={'Select a material type'}
                  treeData={material_type}
                  // value={materialType}
                  onChange={(value) => setMaterialType(value)}
                  onSearch={(e) => {setMaterialSearchType(e)}}
                  notFoundContent={<span className='tree-select-material'>No such material type found. Press enter to add your material.</span>}>
              </TreeSelect>: ""}
              {metaError == "" ? "" :
            <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8,marginBottom: 8}}><img src="/img/error-small.png" style={{marginRight: 8,marginBottom:8}}/><span>{metaError}</span></div>}
            {assignArtistModal ?
            <>
              <p className="manrope f-14 w-600">
                  Assigned Artist
              </p>
              <Select
                showSearch
                style={{marginBottom: 8,width: "100%"}}
                onChange={changeArtist}
                defaultValue={materialData.assigned_artist}
                className="tree-select-material"
                placeholder="Select Artist">
                {artists.map((ele, index) => {
                  return <Option key={index} className="manrope f-12 select-view" value={ele.artist_profile}>{ele.artist_profile + " at rate " + ele.hourly_rate}</Option>
                })}
              </Select>
            </>
            : ''}
            {artistError == "" ? "" :
            <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8,marginBottom: 8}}><img src="/img/error-small.png" style={{marginRight: 8,marginBottom:8}}/><span>{artistError}</span></div>}
            <TextArea onChange={writeMessage} style={{marginBottom: 8}} className="manrope f-16 grey-77" placeholder="Please write a message for the artist specifying why you are rejecting this material" rows={6}/>
            {rejectionError == "" ? "" :
            <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8,marginBottom: 8}}><img src="/img/error-small.png" style={{marginRight: 8,marginBottom:8}}/><span>{rejectionError}</span></div>}

            <Dragger className="material-reject-uploader"
            listType="text"
            fileList={attachments}
            onChange={handleChange}
            multiple={true}
            onRemove={file => {
              setAttachments([]);
            }}
            progress= {{
              strokeColor: {
              '0%': '#108ee9',
              '100%': '#87d068',
              },
              strokeWidth: 3,
              format: percent => `${parseFloat(percent.toFixed(2))}%`,
              }}
            {...Constants.getUploadProps(materialData.platform)}>
               <p className="artist-upload-txt small"><PaperClipOutlined/>&nbsp;Drop any attachments here, or <span style={{color: "#276DD7"}}>Browse them</span>.</p>
              </Dragger>
          </div>
        </div> :
        <Form onFinish={sendBackMaterials}>
        {rejectedMaterials && rejectedMaterials.map((element,index) => (

          <div className="justify-align-start" style={{marginBottom: (index == rejectedMaterials.length - 1) ? 0 : 32}}>

            {element.renders && element.renders.data && element.renders.data.thumbnail ?
            <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + params.id + '/' + element.renders.data.thumbnail} className="reject-mat-img"/> :
            <img className="reject-mat-img" src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(element.material_files[0].uid + '/' + element.material_files[0].name)} />}
            <div style={{marginLeft: 16,width: "100%"}}>
              <Form.Item name={'text_'+element.id} rules={[{required: true,message: 'Please enter a message!'}]}>
                <TextArea onChange={writeMessage} style={{marginBottom: 8}} className="manrope f-16 grey-77" placeholder="Please write a message for the artist specifying why you are rejecting this material" rows={6}/>
              </Form.Item>

              <Form.Item name={'image_'+element.id}  rules={[{required: false,message: 'Please upload attachments you might have for the materials.'}]}>
                <Dragger className="material-reject-uploader"
                listType="text"
                multiple={true}
                progress= {{
                  strokeColor: {
                  '0%': '#108ee9',
                  '100%': '#87d068',
                  },
                  strokeWidth: 3,
                  format: percent => `${parseFloat(percent.toFixed(2))}%`,
                  }}
                {...Constants.getUploadProps(materialData.platform)}>
                  <p className="artist-upload-txt small"><PaperClipOutlined/>&nbsp;Drop any attachments here, or <span style={{color: "#276DD7"}}>Browse them</span>.</p>
                  </Dragger>
                </Form.Item>

            </div>

          </div>


        ))}
        <Form.Item style={{display: 'none'}} >
          <Button id="submit_button" style={{display: 'none'}} htmlType="submit">
            Submit&nbsp;{buttonLoader ? <LoadingOutlined/>: ''}
          </Button>
        </Form.Item>
        </Form>}
        {uploadError == '' ? '' :<div className="err-bg manrope f-12 red-error"  style={{textAlign:"right", display:"flex",alignItems:"center",marginLeft:"auto",marginTop: 16}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{uploadError}</span></div>}
      </div>
    </Modal>
    <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={800}
        onCancel={() => setRejectSuccess(false)}
        visible={rejectSuccess}
        bodyStyle={{padding: 0}}

        footer={[
          <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
            window.location.reload();
          }}>
              Okay
          </Button>
      ]}>

            <div style={{padding:"40px"}}>
                <div style={{textAlign: "center"}}>
                    <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                </div>
                <div style={{textAlign: "center",margin: "20px 0"}}>
                    <h2 className="manrope f-24 black-14 justify-in-center">Material Rejected!</h2>
                    <p className="manrope f-16 grey-77">You have rejected this material, and it has been sent to artist so that the artist can make the changes.</p>
                </div>
            </div>
        </Modal>
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        width={800}
        onCancel={() => setCustomerSuccess(false)}
        visible={customerSuccess}
        bodyStyle={{padding: 0}}

        footer={[
            <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
              window.location.reload();
            }}>
                Okay
            </Button>
        ]}>
            <div style={{padding:"40px"}}>
                <div style={{textAlign: "center"}}>
                    <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                </div>
                <div style={{textAlign: "center",margin: "20px auto"}}>
                    <h2 className="manrope f-24 black-14 justify-in-center">Material{relatedMaterials && relatedMaterials.length > 0 ? " and Product Model" : ""} Shared With Customer Successfully!</h2>
                    <p className="manrope f-16 grey-77">{relatedMaterials && relatedMaterials.length > 0 ? "The materials and product model have" : "The material has"} been approved and shared with customer successfully.</p>
                </div>
            </div>
        </Modal>
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        width={800}
        onCancel={() => setApproveModal(false)}
        visible={approveModal}
        bodyStyle={{padding: 0}}

        footer={[
          <span className="justify-in-center">
            <Button className="modal-okay-gray square font-14" onClick={cancelApproveModal}>
              Not Now
            </Button>
            <Button className="modal-okay square font-14" htmlType="submit" onClick={reviewModel}>
              Review Model
            </Button>
          </span>
        ]}>
            <div style={{padding:"40px"}}>
                <div style={{textAlign: "center"}}>
                    <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                </div>
                <div style={{textAlign: "center",margin: "20px auto"}}>
                    <h2 className="manrope f-24 black-14 justify-in-center">Materials Approved Successfully!</h2>
                    <p className="manrope f-16 grey-77">Your have successfully reviewed and approved the materials.</p>
                    <p className="manrope f-16 grey-77">Please proceed to review the product model, so the customer can be provided with the configurable model and these materials in their Material Library.</p>

                </div>
            </div>
        </Modal>
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        width={800}
        onCancel={() => setConfirmModal(false)}
        visible={confirmModal}
        bodyStyle={{padding: 0}}
        footer={[
          <span className="justify-in-center">
            <Button className="modal-okay-gray square font-14" onClick={reviewModel}>
              Review Model
            </Button>
            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => setConfirmModal(false)}>
              Yes, I'm Sure
            </Button>
          </span>
        ]}>
            <div style={{padding:"40px"}}>
                <div style={{textAlign: "center",margin: "0px auto"}}>
                    <h2 className="manrope f-24 black-14 justify-in-center">Are you sure?</h2>
                    <p className="manrope f-16 grey-77">The customer will not receive the configurable model until you review both the model and the materials.</p>
                    <p className="manrope f-16 grey-77">Are you sure you don’t want to review the model right now?</p>

                </div>
            </div>
        </Modal>

        <Modal
        visible={editModal}
        onCancel={cancelEditModal}
        closable={false}
        maskClosable={true}
        className="aspect-ratio-modal-closable"
        footer={[
          <span className="justify-in-end">
            <Button disabled={editModalLoader} className="modal-okay square font-14" htmlType="submit" onClick={saveMaterialInfo}>
              {buttonLoader ? "Sharing Material With Customer"  : "Done"}
              {editModalLoader ? <LoadingOutlined/> : ''}
            </Button>
          </span>
        ]}>
        <div style={{padding:"32px 32px 24px 32px"}}>
          <div className="manrope f-32 black-14" style={{marginBottom: 16,textTransform:"capitalize"}}>
            Assign Category and Material Type
          </div>
          <div className="manrope f-18 grey-77" style={{marginBottom: 32}}>
          Assign category and material type to the material before you share it with the customer.
          </div>
          <Row className="justify-space-between align-start">
            <Col span={11} className="material-img-bg justify-in-center" style={{paddingRight: 16}}>
              {materialData && materialData.renders.data && materialData.renders.data.thumbnail ?
              <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.MATERIAL_FILE_URI + materialData.id + '/' +  encodeURIComponent(materialData.renders.data.thumbnail)} className="selected-mat modal-img"/>
              :
              materialData && Object.keys(materialData.material_files).length != 0 &&
                  materialData.material_files[0] ?
                      <img src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(materialData.material_files[0].uid + '/' + materialData.material_files[0].name)} className="selected-mat modal-img"/> :
                      <img src={'/img/placeholder.png'}className="selected-mat modal-img"/>
                  }

            </Col>
            <Col span={12}>
            <Form
              ref={form}
              name="material-form"
              onFinish={onFinish}>
                <div className="manrope f-14 black-55" style={{marginBottom: 8}}>Material Category</div>
                <Form.Item name="material_category" className="material-category-form"
                  rules={[{ required: true, message: 'Please select a category!' }]}>
                  <TreeSelect
                    className="tree-select-material"
                    showSearch
                    style={{ width: '100%', color:"#333333"}}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder={'Select or type in to search'}
                    treeData={materialsData}
                    value={materialValue}
                    onChange={(value) => setMaterialValue(value)}
                    onSearch={(e) => {setMaterialSearchValue(e)}}
                    onInputKeyDown={(e) => { addMaterialOption(e)} }
                    notFoundContent={<span className='tree-select-material'>No such material found. Press enter to add your material.</span>}>
                  </TreeSelect>
                </Form.Item>
              <div className="manrope f-14 black-55" style={{marginBottom: 8,marginTop: 22}}>Material Type</div>
                <Form.Item style={{marginBottom: 32}} name="material_type" className="material-category-form"
                  rules={[{ required: true, message: 'Please select a type!' }]}>
                  <TreeSelect
                      className="tree-select-material"
                      showSearch
                      style={{ width: '100%', color:"#333333"}}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      placeholder={'Select or type in to search'}
                      treeData={material_type}
                      value={materialType}
                      onChange={(value) => setMaterialType(value)}
                      onSearch={(e) => {setMaterialSearchType(e)}}
                      notFoundContent={<span className='tree-select-material'>No such material type found. Press enter to add your material.</span>}>
                    </TreeSelect>
                </Form.Item>
                <Form.Item style={{display: 'none'}}>
                  <Button id="save_material" style={{display: "none"}} className="modal-okay square font-14" htmlType="submit">
                  Submit&nbsp;{buttonLoader ? <LoadingOutlined/>: ''}
                  </Button>
                </Form.Item>
             </Form>
            </Col>
            </Row>
            <Row style={{marginTop: 32}} className="justify-in-start">
                <Col >
                    <div className="manrope f-32 black-14 justify-in-start">{materialData.name} {materialData.width != 0 && materialData.length != 0 ? <span className="manrope f-16 grey-77" style={{marginLeft: 8}}>{materialData.width}x{materialData.length} inches</span>:''}</div>
                </Col>
            </Row>
            <Row style={{marginTop: 16}} className="justify-in-start">
              <Col className="justify-space-between" style={{flexWrap:'wrap'}}>
                <span className="justify-space-between" style={{marginRight: 10}}>
                    <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                    Status
                    </div>
                    <div className="note-bg-artist pd-8 gray">
                        <span className="manrope f-16 black-55">{statusMessage(materialData.status)}</span>
                    </div>
                </span>
                <span className="justify-space-between" style={{marginRight: 10}}>
                    <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                    Customer Name
                    </div>
                    <div className="note-bg-artist pd-8 gray">
                        <span className="manrope f-16 black-55">{materialData.username}</span>
                    </div>
                </span>
                <span className="justify-space-between" style={{marginRight: 10}}>
                    <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                    Category
                    </div>
                    <div className="note-bg-artist pd-8 gray">
                        <span className="manrope f-16 black-55">{materialData.category}</span>
                    </div>
                </span>
                <span className="justify-space-between" style={{marginRight: 10}}>
                    <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                    Type
                    </div>
                    <div className="note-bg-artist pd-8 gray">
                        <span className="manrope f-16 black-55" style={{textTransform:"capitalize"}}>{materialData.type}</span>
                    </div>
                </span>
                <span className="justify-space-between">
                    <div className="manrope f-14 grey-77" style={{marginRight: 10}}>
                    ID
                    </div>
                    <div className="note-bg-artist pd-8 gray">
                        <span className="manrope f-16 black-55">{materialData.id}</span>
                    </div>
                </span>
              </Col>
            </Row>
            {materialData && Object.keys(materialData.reference_files).length != 0 && materialData.reference_files.length > 0 ?
            <span>
                <div className="manrope f-14 grey-77" style={{marginTop: 16,marginBottom: 8}}>Reference Images</div>
                <Row>
                    <Col  className="justify-in-start">
                        <Card bordered style={{padding: "10px 0 10px 10px"}} className="mat-img-modal">
                        {materialData && Object.keys(materialData.reference_files).length != 0 && materialData.reference_files.length > 0 ?
                        materialData.reference_files.map((file,index) => (
                            <Col style={{paddingRight: 8}}>
                                <img
                                className="rejected-mat" src={ENVIRONMENT.getBaseURL(materialData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)}
                                />
                            </Col>
                            )) : ''}
                        </Card>
                    </Col>
                </Row>
            </span>: ''}
        </div>
    </Modal>

    <Modal
      className="modal-share-success"
      width={620}
      visible={adminModelStatusChange}
      closable={false}
      footer={[
          <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={() => window.history.back()}>
              <div className="modal-okay-text">Okay</div>
          </Button>
      ]}
      centered={true}
      >
      <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
          <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
          <div>
              <h2 className="success-modal-text">Material Moved to Admin QA</h2>
              <p className="modal-text">The material has been successfully moved to Admin QA.</p>
          </div>
      </div>
    </Modal>
    </div>}
  </AdminMainLayout>)


}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    updateProduct: (product) => {
        dispatch(updateProduct(product));
    }
});

export default (withRouter(connect(

  mapStateToProps,
  mapDispatchToProps
)(AdminMaterialGuidelines)
));