import React from 'react';
import { message } from 'antd';
import { withRouter } from "react-router-dom";
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import ENVIRONMENT  from '../../../../environments'
import VirtualShowroom from '../../../../../src/VirtualShowroom';
import { getProductRender } from "../../../../redux/actions";
import { connect } from "react-redux";
import ComplaintDetailView from './ComplaintDetailView';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import _ from "lodash" // Import the entire lodash library
import { BUCKET_NAME, DOWNLOAD_COMPLETE_BUNDLE, SPACE_GUIDELINES } from '../../../../environments/env';

const { GET_SCENE_DETAIL, GET_COMPLAINT,FETCH_PRODUCT} = ENVIRONMENT;

function saveToZip (filename, urls) {
  message.info('Generating Images Archive, your download will start shortly.', 6);
  const zip = new JSZip()
  urls.forEach((url)=> {
      const blobPromise = fetch(url, {cache: 'no-store'}).then(r => {
          if (r.status === 200) return r.blob()
          return Promise.reject(new Error(r.statusText))
      })
      const name = url.split('/').slice(-1)[0]
      console.log(url, name) 
      zip.file(name, blobPromise)
  })

  zip.generateAsync({type:"blob"})
      .then(blob => saveAs(blob, filename))
      .catch(e => console.log(e));
}

class ComplaintDetail extends React.Component {
  virtualShowroom = null;
  state = {
    selected: '',
    scene_name: '',
    customer_name: '',
    unhappy_details: '',
    unhappy_images: [],
    selected_image: '',
    flagged_images: [],
    flagged_bool_check:{},
    loading_state: true,
    isFullScreen: false,
    replace_modal: false,
    show_more: false,
    uploaded_files: [],
    lifestyle_renders: [],
    three_sixties: [],
    replace_scene_modal: false,
    uploadStatus: '',
    product_guidelines: [],
    replace_3dsmax: false,
    flag_config_data: [],
    config_data: [],
    retrieved_config_data: [],
    scene_3ds_download: false,
    button_loader: false,
    scene_max_bundle: [],
    space_bundle_modal: false,
    success_modal: false,
    design: null,
    status_text: '',
    issue_resolve_modal: false,
    mark_resolve_button: false,
    submit_json_success: false,
    submit_bundle_success: false,
    uploading: [],
    uploading_progress: [],
    config_uploading: [],
    json_uploading: [],
    config_progress: [],
    silo_data: [],
    bundle_uploading: false,
    bundle_progress: '',
    enable_upload_button: true,
    config_exist: false,
    space_success_option: 0,
    compare_state: false,
    render_ready_email: false,
    upload_validation_status: [],
    download_loader: [],
    download_complete_loader: false,
    download_wait_modal: false,
    room_max_archive: '',
    selected_renders: [],
    generate_new_renders: false,
    replace_json_modal: false,
    payload_3ds: [],
    config_3ds: {},
    reject_flag_modal: false,
    send_back_original: false,
    payload_json: [],
    config_json: {},
    complaint_lifestyle: [],
    complaint_360: [],
    approve_render: {},
    enable_resolve_btn: false,
    submit_scene_success: false,
    room_id: '',
    upload_error: '',
    generated_renders: [],
    discard_uploads: false,
    all_uploaded: false,
    upload_type: '',
    render_request_success: false,
    new_renders: [],
    original_renders: {},
    original_exist: false,
    approve_original: false,
    scene_3ds_bundle:[],
    scene_3ds_json: [],
    defaultFileList:[],
    jsonFileList: [],
    bundlefileList: [],
    validation_report:[],
    validation_generated: false,
    automatically_fixed_high: [],
    successfully_validated_high:[],
    invalid_data_high: [],
    success_val_length: 0,
    invalid_val_length: 0,
    auto_val_length: 0,
    validation_check: false,
    reupload_flag: false,
    space_valid: true,
    space_validation: 0,
    toggle_summary: false,
    bundle_upload_status: [],
    json_upload_status: [],
    destroy_modal: false,
    help_button: false,
    asset_count: 0,
    enable_request_render: true,
    customer_username: '',
    reject_flag_success: false,
    selected_format: '.jpg',
    upload_failure_message: {},
    upload_bundle_failure_message: '',
    by_msprovider: false,
    super_artist_complaint_page: false,
    assigned_to: '',
    rejection_history: [],
    complaint_time_log: [],
    time_spent: 0,
    scene_configurations: false,
    crypto_exists: false,
    crypto_images: [],
    use_default_sun : false,
    platform: "",
    file_copied: false,
    uploaded_text : "",
    company_id: -1,
    room_platform: ""
  }

  componentDidMount() {
    let scene_id = ''
    let customer_name = '';
    let unhappy_content = '';
    let type = '';
    let attachment = '';
    let references = '';
    let status = '';
    let by_msprovider = 0;
    let assigned_to = '';
    let rejection_history = [];
    let complaint_time_log = [];

    if(this.props && this.props.match){
      if(this.props.match.path.includes('artist_complaint')){
        this.setState({super_artist_complaint_page: true})
      }
    }

    axios.post(GET_COMPLAINT, { complaint_id: this.props.match.params.id })
      .then(res => {
        console.log(res)
        scene_id = res.data[0]['entity_id'];
        customer_name = res.data[0]['username'];
        unhappy_content = res.data[0]['issue'];
        type = res.data[0]['complaint_against'];
        status = res.data[0]['status'];
        attachment = res.data[0]["attachment"];
        references = res.data[0]["reference_images"];
        by_msprovider = res.data[0]['by_msprovider'];
        assigned_to = res.data[0]["assigned_to"];
        rejection_history = res.data[0]["rejection_history"]
        complaint_time_log = res.data[0]["time_log"]
        
        let selected_image =  '';
        let selected_format = '.jpg';
        let images_flag = [];
        if (Array.isArray(attachment) == false) {
          selected_image = attachment;
          if (selected_image.includes('.')) {
            selected_image = selected_image.split('.')[0];
            selected_format = selected_image.split('.')[1];
          }
          images_flag.push(selected_image);
          this.setState({
            flagged_images: images_flag
          });
        }
        else {
          selected_image =  attachment[0]
          images_flag = attachment;

          this.setState({
            flagged_images: images_flag
          });
        }
        if (status == "admin_qa") {
          this.setState({
            render_ready_email: true
          })
        }
        this.setState({
          scene_id: scene_id,
          customer_name: customer_name,
          unhappy_details: unhappy_content,
          unhappy_images: attachment,
          references: references,
          status: status,
          assigned_to: assigned_to,
          type: type,
          by_msprovider: by_msprovider,
          selected_image: selected_image,
          selected_format: selected_format,
          rejection_history: rejection_history,
          complaint_time_log: complaint_time_log
        });
        if (status == "pending") 
        {
          this.setState({
            status_text: status
          });
        } else if (status == "admin_qa") {
          this.setState({
            status_text: "Waiting for Admin to Review Request"
          });
        } else if (status == "resolved") {
          this.setState({
            status_text: "Issue Resolved"
          });
        } else if (status == "in_progress") {
          this.setState({
            status_text: "Request is In Progress"
          });
        } else if (status == "rejected") {
          this.setState({
            status_text: "Flag Rejected by Admin"
          });
        } else if (status == "sent_back_to_artist") {
          this.setState({
            status_text: "Admin Rejected the artist's Changes"
          });
        }
        if (type == "scene_360" || type=="scene_lifestyle" || type == "scene_3dtour"){
          var payload = { "scene_id": scene_id };
          
          axios.post(GET_SCENE_DETAIL, payload)
            .then(res => {
                this.setState({
                    platform: res.data['platform'],
                    room_platform: res.data['room_platform']
                }); 
              var final_renders_json = res.data['final_renders'];
              var three_sixties_json = res.data['three_sixties'];

              var final_renders_object = {};
              final_renders_object["final_renders"] = [];
              if (final_renders_json !== "" && final_renders_json !== undefined) {
                  final_renders_object = JSON.parse(final_renders_json);
                  final_renders_object["final_renders"] = final_renders_object["final_renders"].filter(x => {return x.status != 'pending'});
              }
              var three_sixties_object = {};
              three_sixties_object["three_sixties"] = [];
              if (three_sixties_json !== "" && three_sixties_json !== undefined) {
                  three_sixties_object = JSON.parse(three_sixties_json);
                  let temp_arr = [];
                  if (status == "resolved") {
                    three_sixties_object["three_sixties"].map((image,index) => {   
                        if (!image.revision_of ) {
                          temp_arr.push(image);
                        }
                    });
                    three_sixties_object["three_sixties"] = temp_arr;
                }
              }
              let threed_tour = "";
              if("threed_tour" in res.data) {
                threed_tour = JSON.stringify(JSON.parse(res.data["threed_tour"])["threed_tour"]);
              }
              // let it be commented until this is tested
              let design_string = res.data['design'];
              let design = '';
              if (design_string != "" && design_string != undefined) {
                design = JSON.parse(design_string)['design']; 
                // if (design['assets']) {
                //   this.setState({
                //     asset_count: design['assets'].length
                //   });  
                // }
              }

              let room_url = res.data['room_max_archive'].split('/');
              let room_id = room_url[room_url.length - 1];
              room_id = room_id.split('.')[0];

              this.setState({
                room_id: room_id
              });
              console.log("room", room_id)
              if (type != "scene_3dtour")
              {
                axios.post(ENVIRONMENT.GET_COMPLAINT_RENDER, {"complaint_id": this.props.match.params.id})
                .then((res) => {
                  let generated_renders = [];
                  let first_renders = [];
                  let new_renders = []
                  let original_exist = false;
                  var final_renders_json = res.data['final_renders']['final_renders'] && res.data['final_renders']['final_renders'].filter(x => {return x.status != 'pending'});
                  if (type == "scene_lifestyle" && final_renders_json && final_renders_json.length > 0) {
                  
                    console.log(final_renders_json)
                  
                    if ((this.state.status == "admin_qa" || this.state.status == "resolved" || this.state.status == "sent_back_to_artist") && final_renders_json && final_renders_json.length > 0) {
                        final_renders_json.map(complaint_render => {
                          if (complaint_render.generated_by_admin || (complaint_render.flagged_by_user && images_flag.includes(complaint_render.filename.split('.')[0]))) {
                            generated_renders.push(complaint_render.filename.split('.')[0]);
                            first_renders.push(complaint_render.filename);
                            
                          }
                          if (complaint_render.generated_by_admin) {
                            new_renders.push(complaint_render.filename);
                          }
                          if (!complaint_render.generated_by_admin && (complaint_render.flagged_by_user && images_flag.includes(complaint_render.filename.split('.')[0]))) {
                            original_exist = true;
                          }
                          
                        })
                    }
                  } else if (type == "scene_360") {
                    var three_sixties_json = res.data['three_sixties']['three_sixties'];
                
                    if ((this.state.status == "admin_qa" || this.state.status == "resolved" || this.state.status == "sent_back_to_artist")  && three_sixties_json && three_sixties_json.length > 0) {
                      three_sixties_json.map(complaint_render => {
                        console.log(complaint_render)
                        if (complaint_render.generated_by_admin || (complaint_render.flagged_by_user && images_flag.includes(complaint_render.filename.split('.')[0]))) {
                          generated_renders.push(complaint_render.filename.split('.')[0]);
                          first_renders.push(complaint_render.filename);
                          console.log(complaint_render.filename)
                        }
                        if (complaint_render.generated_by_admin) {
                          new_renders.push(complaint_render.filename);
                        }
                        if (!complaint_render.generated_by_admin && (complaint_render.flagged_by_user && images_flag.includes(complaint_render.filename.split('.')[0]))) {
                          original_exist = true;
                        }
                        
                      });
                    }
                  }

                  this.setState({
                    complaint_lifestyle: final_renders_json,
                    generated_renders: generated_renders,
                    selected: first_renders[0],
                    new_renders: new_renders,
                    original_exist: original_exist,
                    complaint_360: three_sixties_json
                  });
                }).catch((error) => {
                  console.log(error,'Error in getting complaint renders.');
                });
              }
              
              this.setState({
                scene_name: res.data['name'],
                threed_tour: threed_tour,
                room_max_archive: res.data['room_max_archive'],
                lifestyle_renders: final_renders_object['final_renders'],
                three_sixties: three_sixties_object["three_sixties"],
                design: design
              }, () => {
                console.log('Design JSON',this.state.design);           
                if (type == "scene_3dtour") {
                  this.callback();
                }
                let temp_bool = {};
                let temp_upload_bool = [];
                let temp_status = [];
                if (type == "scene_360")
                {  
                  let assigned = false;
                  if(this.state.three_sixties.length > 0) {
                    this.state.three_sixties.map((url,index) => {
                      
                      let temp_url = url.filename.split('.')[0];
                      this.checkIfCryptoExists(temp_url, index);
                      temp_upload_bool[temp_url] = false;
                      temp_status[temp_url] = '';
                      if (images_flag.includes(temp_url)) {
                        if (!assigned) {
                          this.setState({
                            selected_image: temp_url,
                            selected_format: url.filename.split('.')[1]
                          });
                          console.log(url.filename)
                          assigned = true;
                        }
                        temp_bool[temp_url] = true;
                      }
                      else {
                        temp_bool[temp_url] = false;
                      }
                    });
                    this.setState({
                      flagged_bool_check: temp_bool,
                      uploading: temp_upload_bool,
                      uploading_progress: temp_status
                    }, () => {
                      console.log('Flagged Images', this.state.flagged_bool_check)
                    });
                  }
                } else if (type == "scene_lifestyle") {
                  let assigned = false;
                  
                    if(this.state.lifestyle_renders.length > 0) {
                      this.state.lifestyle_renders.map((url,index) => {
                        
                        let temp_url = url.filename.split('.')[0];
                        this.checkIfCryptoExists(temp_url, index);
                        temp_status[temp_url] = '';
                        temp_upload_bool[temp_url] = false;
                        
                        if (images_flag.includes(temp_url)) {
                          if (!assigned) {
                            this.setState({
                              selected_image: temp_url,
                              selected_format: url.filename.split('.')[1]
                            });
                            assigned = true;
                          }
                          temp_bool[temp_url] = true;
                        }
                        else {
                          temp_bool[temp_url] = false;
                        }
                      });

                      this.setState({
                        flagged_bool_check: temp_bool,
                        uploading: temp_upload_bool,
                        uploading_progress: temp_status
                      }, () => {
                        this.forceUpdate();
                        console.log('Flagged Images', this.state.flagged_bool_check)
                      });
                    }
                }

                
                  axios.post(ENVIRONMENT.SCENE_CONFIG_GET_BATCH, { "scene_id": scene_id  })
                    .then((res) => {
                      this.setState({
                        retrieved_config_data: res.data
                      }, () => {
                        let temp = {};
                        let temp_camera_config = [];
                        let temp_config = [];
                        let new_temp_cameras = []
                        let new_retrieved = [];
                        let temp_link = [];
                        let progress_config = [];
                        let uploading_config = [];
                        let upload_files = [];
                        let json_files = [];
                        let camera_flag = false;
                        let config_camera_arr = [];
                        console.log(this.state.retrieved_config_data, 'retried')
                        if (this.state.retrieved_config_data  && this.state.retrieved_config_data.length != 0) {
                          this.state.retrieved_config_data.map((config,index) => {
                            progress_config[config.scene_config_id] = '';
                            upload_files[config.scene_config_id] = [];
                            uploading_config[config.scene_config_id] = false;
                            json_files[config.scene_config_id] = '';
                            temp_camera_config = [];
                            new_temp_cameras = [];
                            config_camera_arr = _.cloneDeep(config.cameras);
                            if (config_camera_arr[0] && Array.isArray(config_camera_arr[0])) {
                              config_camera_arr = config_camera_arr[0];
                            }
                            config_camera_arr.map((camera_name)=> {
                              
                              if (temp_bool[camera_name]) {
                                temp_camera_config.push(camera_name);
                                camera_flag = true;
                              }

                              if (type == "scene_360") {
                                if (!camera_name.includes('camera')) {
                                  new_temp_cameras.push(camera_name);
                                  
                                }
                              } else if (type == "scene_lifestyle") {
                                if (camera_name.includes('camera')) {
                                  new_temp_cameras.push(camera_name);
                                  
                                }
                              }
                              
                            });
                            if (type == "scene_360" || (type == "scene_lifestyle")) {
                                temp = _.cloneDeep(config);
                                temp.cameras = _.cloneDeep(new_temp_cameras);
                                new_retrieved.push(temp);
                                this.setState({
                                  retrieved_config_data: new_retrieved
                                });
                              
                            } 
                            if (camera_flag) {
                              camera_flag = false;
                              temp = _.cloneDeep(config);
                              temp.cameras = _.cloneDeep(temp_camera_config);
                              temp_config.push(temp);
                            }
                            temp_link = _.cloneDeep(config.scene_bundle);
                          });
                          this.setState({
                            flag_config_data: temp_config,
                            bundle_upload_status: _.cloneDeep(progress_config),
                            config_data: temp_config,
                            scene_max_bundle: temp_link,
                            config_progress: progress_config,
                            config_uploading: uploading_config,
                            json_uploading: uploading_config,
                            defaultFileList: _.cloneDeep(upload_files),
                            jsonFileList: _.cloneDeep(json_files),
                            upload_validation_status: progress_config,
                            loading_state: false,
                          }, () => {
                            window.addEventListener("unload", () => {
                              this.state.config_data.map((config,index) => {
                                localStorage.removeItem(`design_json_${config.scene_config_id}`);
                              });
                            });
                            if (temp_config && temp_config.length == 0) {
                              this.setState({
                                config_exist: false,
                              });
                            } else {
                              this.setState({
                                config_exist: true,
                              });
                            }
                            this.setState({
                              scene_configurations: true
                            })
                          });
                          console.log("Flagged configuration",temp_config,"Retrieved configuration",this.state.retrieved_config_data);
                        } else {
                          this.setState({
                            config_exist: false,
                            loading_state: false,
                          });
                        }
                      })
                      
                  })
                .catch((error) => {
                  console.log('Error in retrieving Scene Configurations.');
                  this.setState({
                    config_exist: false,
                    loading_state: false,
                  });
                });     
              });
            }).catch((error) => {
              console.log(error,'Error in getting scene detail.');
              message.error('Error in getting scene detail.');
            });
        }
        else if (type == "Product") {
          var product_id = 0;
          product_id = scene_id;
          this.getCustomerAndCompany(customer_name);
          axios.post(FETCH_PRODUCT, { 
            product_id,
            username: customer_name
           })
            .then(res => {
              let payload = {
                product_id: product_id,
                username: customer_name
              };
              if (res.data['company_id']){
                payload['company_id'] = res.data['company_id']
              }
              this.props.getProduct(payload);
              let temp_bool = {};
              let upload_progress = [];
              let uploading_silo = [];
              if (res.data['silo_data'] != undefined) {
                let flag = false;
                if(res.data['silo_data'].length > 0) {
                  res.data['silo_data'].map((url) => {
                    flag = false;
                    upload_progress[url.camera_name] = '';
                    uploading_silo[url.camera_name] = false;
                    images_flag.map((image,index) => {
                      if (url.camera_name == image) {
                        flag = true;
                        temp_bool[url.camera_name] = true;
                      }
                    });
                    if (!flag){
                      temp_bool[url.camera_name] = false;
                    }
                  });
                }
              }
              this.setState({
                uploading: uploading_silo,
                uploading_progress: upload_progress,
                product_name: res.data.product_name,
                flagged_bool_check: temp_bool,
                loading_state: false,
                product_guidelines: res.data,
                silo_data: res.data['silo_data'],
                platform: res.data['platform']
              }, () => {
                console.log('Flagged Images', this.state.flagged_bool_check)
              });

            }).catch((error) => {
              console.log(error,'Error in fetching product.');
              message.error('Error in fetching product.');
            });
        }
      }).catch((error) => {
        message.error('Error in getting complaint.');
        console.log('Error in getting complaint.');
      });
      document.addEventListener("keydown", this.escFunction, false);
  }

  cancelRenderRequestModal = () => {
    this.setState({
      render_request_success: false
    },() => {
      window.location.reload(true);
    });
  }

  getCustomerAndCompany = (customer_username) => {
    axios.post(ENVIRONMENT.GET_CUSTOMER_AND_COMPANY, { customer_username: customer_username })
    .then(res => {
      if (res.data && res.data['company_id']) {
        this.setState({
          company_id: res.data['company_id']
        });
      }
    })
    
  }

  componentDidUpdate (prevProps) {
    if (this.props !=prevProps) {
      let temp_bool = {};
      let upload_progress = [];
      let uploading_silo = [];
      if (this.props.productReducer.product['silo_data'] != undefined && this.state.type == "Product") {
        let flag = false;
        if(this.props.productReducer.product['silo_data'].length > 0) {
          this.props.productReducer.product['silo_data'].map((url) => {
            flag = false;
            upload_progress[url.camera_name] = '';
            uploading_silo[url.camera_name] = false;
            this.state.flagged_images.map((image,index) => {
              if (url.camera_name == image) {
                flag = true;
                temp_bool[url.camera_name] = true;
              }
            });
            if (!flag){
              temp_bool[url.camera_name] = false;
            }
          });
        }
        this.setState({
          uploading: uploading_silo,
          uploading_progress: upload_progress,
          flagged_bool_check: temp_bool,
          loading_state: false,
          silo_data: this.props.productReducer.product['silo_data'],
        });
      }
      
    }
  }
  cancelHelp = () => {
    this.setState({
      help_button: false
    });
  }

  openHelp = () => {
    this.setState({
      help_button: true
    });
  }

  openRejectFlag = () => {
    this.setState({
      reject_flag_modal: true
    });
  }

  cancelRejectModal = () => {
    this.setState({
      reject_flag_modal: false
    });
  }

  rejectFlag = (values) => {
    this.setState({
      button_loader: true
    })
    let payload = {}
    payload = {
      complaint_id: this.props.match.params.id,
      status: "rejected",
      admin_message: values.reject_message
    }
    axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS,payload)
    .then(res => {
      this.setState({
        button_loader: false,
        reject_flag_modal: false,
        reject_flag_success: true
      });
    }).catch((error) => {
        message.error('Error in rejecting flag.');
        console.log(error,'Error in rejecting flag.');
      });
  }

  cancelRejectFlag = () => {
    this.setState({
      reject_flag_success: false
    });
    window.location.reload();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  cancelRenderReadyModal = () => {
    this.setState({
      render_ready_email: false
    });
  }

  cancelDownloadWaitModal = () => {
    this.setState({
      download_wait_modal: false
    });
  }

  handleSubmit = (values) => {
    let temp_data = {};
    this.setState({
      button_loader: true
    });
    let data = {};
    let upload_status = '';
    let scene_payload_data = {};
    let scene_images = [];
    for (const [key, value] of Object.entries(values)) {
      if (value != undefined) {
        let temp_arr = [];
        temp_arr.push({
          uid: value[0].uid,
          name: value[0].name,
          date: value[0].lastModifiedDate
        });
        temp_data[key] = temp_arr;
        scene_images.push(key);
        let uploadStatus = this.validateFileUploads(value);
        if (uploadStatus == 'uploading') {
          upload_status = 'in_progress';
          this.setState({
            uploadStatus: 'Uploads are in progress, please wait till file uploads are complete.',
            button_loader: false
          });
        }
        else if (uploadStatus == 'error') {
          upload_status = 'error';
          this.setState({
            uploadStatus: 'An error occured, please upload again',
            button_loader: false
          });
          
        }
        else if (uploadStatus == 'success'){
        }
      }
    }
    
    data = {
      id: this.state.scene_id,
      type: this.state.type,
      override_data: temp_data,
    }  
    if (this.state.type == "Product") {
      data = {
        id: this.state.scene_id,
        type: this.state.type,
        override_data: temp_data,
        customer_username: this.state.customer_name
      }  
      
      if (this.state.product_guidelines && this.state.product_guidelines.company_id) {
        data.company_id = this.state.product_guidelines.company_id
      }
    } else if (this.state.type == "scene_360") {
      scene_payload_data = {
        status: 3,
        three_sixties: scene_images,
        complaint_id: this.props.match.params.id
      }
    } else if (this.state.type == "scene_lifestyle") {
      scene_payload_data = {
        status: 3,
        final_renders: scene_images,
        complaint_id: this.props.match.params.id
      }
    }

    console.log('SCENE PAYLOAD IMAGE REPLACEMENT',scene_payload_data);
    
    if (upload_status == '') {
      this.setState({
        uploadStatus: ''
      });
      let temp_url = '';
      let temp = [];
      let temp_progress = [];
      if (this.state.type == "Product") {
        axios.post(ENVIRONMENT.REPLACE_SILO_IMAGE, data)
          .then((res) => {
            this.state.silo_data.map((url) => {
              temp[url.camera_name] = false;
              temp_progress[url.camera_name] = null;
            });
            let payload = {};
            payload = {
              complaint_id: this.props.match.params.id,
              status: "resolved"
            }
            // pass the time_spent info to the complaint-update lambda
            if(values.time_spent){
              payload['time_spent'] = values.time_spent;
              payload['qa_username'] = localStorage.getItem('username')
            }
            axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
            .then(res => {
              let metadata_update_payload = {
                'entity_type': 'product',
                'lambda_payload': {
                  'product_id': this.state.scene_id // for product complaints, scene_id holds the product_id
                }
              }

              axios.post(ENVIRONMENT.TRIGGER_ENTITY_METADATA_UPDATE, metadata_update_payload)
              .then(() => {
                this.setState({
                  replace_modal: false,
                  button_loader: false,
                  show_more: false,
                  success_modal: true,
                  uploading: temp,
                  uploading_progress: temp_progress,
                  enable_upload_button: true
                });
              })
            })
            .catch((error) => {
              message.error('Error in updating complaint status.');
              console.log(error,'Error in updating complaint status');
            });  
            
            
          })
          .catch((error) => {
            message.error('An error occured while trying to replace flagged silo image(s).');
            console.log(error,'An error occured while trying to replace flagged silo image(s).');
          });
      }
      else if (this.state.type == "scene_360" || this.state.type == "scene_lifestyle")
      {
        axios.post(ENVIRONMENT.ADD_TO_COMPLAINT_RENDERS, scene_payload_data)
          .then((res) => {
            if (this.state.type == "scene_360") {
              this.state.three_sixties.map((url) => {
                temp_url = url.filename.split('.')[0];
                temp[temp_url] = false;
                temp_progress[temp_url] = null;
              });
            }
            else if (this.state.type == "scene_lifestyle") {
              this.state.lifestyle_renders.map((url) => {
                temp_url = url.filename.split('.')[0];
                temp[temp_url] = false;
                temp_progress[temp_url] = null;
              });
            }

            let payload = {};
            payload = {
              complaint_id: this.props.match.params.id,
              status: "admin_qa"
            }
            // pass the time_spent info to the complaint-update lambda
            if(values.time_spent){
              payload['time_spent'] = values.time_spent;
              payload['qa_username'] = localStorage.getItem('username')
            }
            axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
            .then(res => {
              this.setState({
                replace_modal: false,
                replace_scene_modal: false,
                button_loader: false,
                show_more: false,
                success_modal: true,
                uploading: temp,
                uploading_progress: temp_progress,
                enable_upload_button: true
              });
            })
            .catch((error) => {
              message.error('Error in updating complaint status.');
              console.log(error,'Error in updating complaint status');
            });  
          })
          .catch((error) => {
            console.log(error,'An error occured while trying to replace flagged scene item(s).');
            message.error('An error occured while trying to replace flagged scene item(s).');
          });
      }
    }
  }

  getPlacementCameraMapping = (config_bundles = null) => {
    let camera_config_maping = {};
    let placement_versions = this.state.retrieved_config_data;
    for (let config_id of Object.keys(placement_versions)) {
        for (let camera of placement_versions[config_id].cameras) {
            if (camera_config_maping[camera] == undefined) {
                camera_config_maping[camera] = config_id;
            }
        }

    }

    let mapings = {
        camera_config_maping: camera_config_maping
    }

    return mapings
  }

  getLinkFromDict = (obj) => {
    return (ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.BASE_URI + obj['uid'] + '/' + obj['name']);
  }

  handleSubmit3dsMax = (values) => {
    if('time_spent' in  values){
      this.setState({
        time_spent: values['time_spent']
      })
      delete values['time_spent'];
    }
    this.setState({
      button_loader: true
    });
    let temp_data = [];
    let check_if_empty = false;
    let config_bundles = {};
    let upload_status = '';
    let temp_upload = _.cloneDeep(this.state.bundle_upload_status);
    // let temp_upload_json = this.state.json_upload_status;
    for (const [key, value] of Object.entries(values)) {
      if (value != undefined) {
        let temp_arr = {};
        let config_id_key = key.replace(/\D/g,'');
        temp_arr['scene_configuration_id'] = config_id_key;
        let zip_file = '';
        value.map(file => {
          if (file.name.includes('.zip')|| this.state.scene_3ds_bundle[config_id_key]) {
            zip_file = this.state.scene_3ds_bundle[config_id_key];
            let replaced_file = zip_file;
            temp_arr['scene_bundle_override'] = replaced_file;
            config_bundles[config_id_key] = this.getLinkFromDict(replaced_file)
          } else {
            temp_upload[config_id_key] = 'Scene Bundle File Missing'
            this.setState({
              bundle_upload_status: _.cloneDeep(temp_upload),
              enable_upload_button: true,
              button_loader: false,
              upload_error: 'error'
            });
            check_if_empty = true;

          }
          if (file.name.includes('.json') || this.state.scene_3ds_json[config_id_key]) {
            temp_arr['placement_info'] = JSON.parse(localStorage.getItem(`design_json_${config_id_key}`));
          } else {
            temp_upload[config_id_key] = 'JSON File Missing'
            this.setState({
              bundle_upload_status: _.cloneDeep(temp_upload),
              enable_upload_button: true,
              button_loader: false,
              upload_error: 'error'
            });
            check_if_empty = true;

          }
        })
        temp_data.push(temp_arr);
        let uploadStatus = this.validateFileUploads(value);
        if (uploadStatus == 'uploading') {
          upload_status = 'in_progress';
          this.setState({
            uploadStatus: 'Uploads are in progress, please wait till file uploads are complete.',
            button_loader: false
          });
        }
        else if (uploadStatus == 'error') {
          upload_status = 'error';
          this.setState({
            uploadStatus: 'An error occured, please upload again',
            button_loader: false
          });
        }
        else if (uploadStatus == 'success'){
          
        }
      }
    }
    if (upload_status == '' && check_if_empty == false) {
      this.setState({
        uploadStatus: '',
        replace_3dsmax: false,
        button_loader: false,
        payload_3ds: temp_data,
        config_3ds: config_bundles,
        success_modal: true,
        submit_scene_success: true
      });
      
    }
  }

  setBundleUploadStatus = (config_id,status,type) => {
    let temp_arr = this.state.bundle_upload_status;
    temp_arr[config_id] = status;
    this.setState({
      bundle_upload_status: _.cloneDeep(temp_arr)
    });
  }

  handleSubmitSpaceBundle = (values) => {
    this.setState({
      button_loader: true,
    });
    if('time_spent' in  values){
      this.setState({
        time_spent: values['time_spent']
      })
      delete values['time_spent'];
    }
    let upload_status = '';
    let uploadStatus = this.validateFileUploads(values.space_bundle);
    if (uploadStatus == 'uploading') {
      upload_status = 'in_progress';
      this.setState({
        uploadStatus: 'Uploads are in progress, please wait till file uploads are complete.',
        button_loader: false
      });
    }
    else if (uploadStatus == 'error') {
      upload_status = 'error';
      this.setState({
        uploadStatus: 'An error occured, please upload again',
        button_loader: false
      });
    }
    else if (uploadStatus == 'success'){
    }

    if (upload_status == '') {
      this.setState({
        uploadStatus: '',
        success_modal: true,
        space_bundle_modal: false,
        submit_bundle_success: true
      });
    }
  }

  cancelGenerateNewRenders = () => {
    this.setState({
      generate_new_renders: false
    })
  }

  cancelResolveSuccessModal = () => {
    this.setState({
      issue_resolve_modal: false
    },() => {
      window.location.reload(true);
    });
  }
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  validateFileUploads = (files) => { 
    if ((files != undefined) && typeof (files) == "object" && Object.keys(files).length != 0) {
      for(var file of files) {
        if (file['status'] == 'uploading') {
          return 'uploading';
        }
        if (file['status'] == 'error') {
          return 'error';
        }
        // if (file['status'] == 'done') {
        //   return 'done';
        // }
      }
      return 'success';
    }
  }

  setDefaultSunSettings = (e) => {
    this.setState({
      use_default_sun: e.target.checked
    })
  }

  requestRenders = () => {
    this.setState({
      button_loader: true,
    });

    if (this.state.payload_3ds.length != 0){
      let temp_data = this.state.payload_3ds;

      axios.post(ENVIRONMENT.SCENE_CONFIG_UPDATE_BATCH, temp_data )
      .then(res => {
        let scene_data = _.cloneDeep(this.state.design);
        let config_camera_mapings = this.getPlacementCameraMapping(this.state.config_3ds);
        let camera_status_indexes = config_camera_mapings.camera_config_maping;
        
        let flagged_images = this.state.flagged_bool_check;
        for (let camera of scene_data.cameras){
          if (camera_status_indexes[camera.camera_name] != undefined) {
            camera['scene_state_index'] = camera_status_indexes[camera.camera_name];
          }
          
          if (flagged_images[camera.camera_name] != undefined && this.state.selected_renders[camera.camera_name] == true) {
            camera['selected'] = true;
          } else {
            camera['selected'] = false;
          }
        }

        scene_data['final_renders'] = this.state.lifestyle_renders;
        scene_data['three_sixties'] = this.state.three_sixties;
        
        if ( this.state.type == "scene_lifestyle" ){
          scene_data['generate_360'] = 0;
          scene_data['generate_still'] = 1;
        }
        if ( this.state.type == "scene_360" ){
          scene_data['generate_360'] = 1;
          scene_data['generate_still'] = 0;
        }
        scene_data['room_max_archive'] = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_id + '.zip'//this.state.room_max_archive;
        scene_data['complaint_id'] = this.props.match.params.id;
        scene_data['username'] = localStorage.getItem('username');

        let scene_update_payload = {
          "scene_id": this.state.scene_id,
          "username": localStorage.getItem('username'),
          "design": scene_data,
          "genertate_renders": 1,
          "admin_request": true,
          "room_max_archive": ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_id + '.zip',//this.state.room_max_archive,
          "complaint_id": this.props.match.params.id
        };

        // append render settings that need to be overriden in the request
        scene_update_payload['override_render_settings'] = {
          use_default_sun: this.state.use_default_sun
        }
      
      
      console.log('Scene Update Payload',scene_update_payload);
      
      console.log(JSON.stringify(scene_update_payload));
      
        axios.post(ENVIRONMENT.UPDATE_SCENE, scene_update_payload)
        .then( (response) => {
          let temp_uploading = [];
          let temp_progress = [];
          let temp_id = '';
          this.state.config_data.map((config,index) => {
            temp_id = _.cloneDeep(config.scene_config_id);
            temp_uploading[temp_id] = false;
            temp_progress[temp_id] = null;
          });

          this.setState({
            button_loader: false,
            replace_3dsmax: false,
            enable_upload_button: true,
            config_progress: temp_progress,
            config_uploading: temp_uploading,
            json_uploading: temp_uploading,
            upload_error: '',
            uploadStatus: '',
            generate_new_renders: false,
            payload_3ds: [],
            render_request_success: true,
            config_3ds: {}
          }, () => {
            let payload = {};
            payload = {
              complaint_id: this.props.match.params.id,
              status: "in_progress"
            }
            // pass the complaint time spent info as well
            if(this.state.time_spent != 0 && this.state.super_artist_complaint_page){
              payload['time_spent'] = this.state.time_spent;
              payload['qa_username'] = localStorage.getItem('username')
            }
            axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
            .then(res => {
            })
            .catch((error) => {
              message.error('Error in updating complaint status.');
              console.log(error,'Error in updating complaint status');
            });  
          });
        })
        .catch((error) => {
          message.error('Error in updating scene.');
          console.log(error,'Error in updating scene');
          this.setState({
            button_loader: false,
          });
        });  
      }).catch((error) => {
        console.log(error,'Error in updating configuration.');
        message.error('Error in updating configuration.');
      });
    } else if (this.state.payload_json.length != 0) {
        let temp_data = this.state.payload_json;

        axios.post(ENVIRONMENT.SCENE_CONFIG_UPDATE_BATCH, temp_data )
        .then(res => {
          let scene_data = this.state.design;
          let config_camera_mapings = this.getPlacementCameraMapping();
          let camera_status_indexes = config_camera_mapings.camera_config_maping;
          
          let flagged_images = this.state.flagged_bool_check;
          for (let camera of scene_data.cameras){
            if (camera_status_indexes[camera.camera_name] != undefined) {
              camera['scene_state_index'] = camera_status_indexes[camera.camera_name];
            }
            
            if (flagged_images[camera.camera_name] != undefined && this.state.selected_renders[camera.camera_name] == true) {
              camera['selected'] = true;
            } else {
              camera['selected'] = false;
            }
          }

          scene_data['final_renders'] = this.state.lifestyle_renders;
          scene_data['three_sixties'] = this.state.three_sixties;
          scene_data['complaint_id'] = this.props.match.params.id;
          
          if ( this.state.type == "scene_lifestyle" ){
            scene_data['generate_360'] = 0;
            scene_data['generate_still'] = 1;
          }
          if ( this.state.type == "scene_360" ){
            scene_data['generate_360'] = 1;
            scene_data['generate_still'] = 0;
          }
          scene_data['room_max_archive'] = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_id + '.zip';//this.state.room_max_archive;
          scene_data['username'] = localStorage.getItem('username');


          let scene_update_payload = {
            "scene_id": this.state.scene_id,
            "username": localStorage.getItem('username'),
            "design": scene_data,
            "genertate_renders": 1,
            "room_max_archive": ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_id + '.zip',//this.state.room_max_archive,
            "admin_request": true,
            "complaint_id": this.props.match.params.id
          };

        // append render settings that need to be overriden in the request
        scene_update_payload['override_render_settings'] = {
          use_default_sun: this.state.use_default_sun
        }

        console.log('Scene Update Payload',scene_update_payload);
        console.log(JSON.stringify(scene_update_payload));

          axios.post(ENVIRONMENT.UPDATE_SCENE, scene_update_payload)
          .then( (response) => {
            let temp_uploading = [];
            let temp_progress = [];
            let temp_id = '';
            this.state.config_data.map((config,index) => {
              temp_id = _.cloneDeep(config.scene_config_id);
              temp_uploading[temp_id] = false;
              temp_progress[temp_id] = null;
            });
    
            this.setState({
              button_loader: false,
              replace_3dsmax: false,
              enable_upload_button: true,
              config_progress: temp_progress,
              config_uploading: temp_uploading,
              json_uploading: temp_uploading,
              upload_error: '',
              uploadStatus: '',
              render_request_success: true,
              generate_new_renders: false,
              payload_3ds: [],
              config_3ds: {}
            }, () => {
              let payload = {};
              payload = {
                complaint_id: this.props.match.params.id,
                status: "in_progress"
              }
              // pass the complaint time spent info as well
              if(this.state.time_spent != 0 && this.state.super_artist_complaint_page){
                payload['time_spent'] = this.state.time_spent;
                payload['qa_username'] = localStorage.getItem('username')
              }
              axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
              .then(res => {
              })
              .catch((error) => {
                message.error('Error in updating complaint status.');
              });  
            });
          })
          .catch((error) => {
            message.error('Error in updating scene.');
            this.setState({
              button_loader: false,
            });
          }); 
        }).catch((error) => {
          console.log(error,'Error in updating configuration.');
          message.error('Error in updating configuration.');
        });
    } else {

      let scene_data = this.state.design;
      let config_camera_mapings = this.getPlacementCameraMapping();
      let camera_status_indexes = config_camera_mapings.camera_config_maping;
      
      let flagged_images = this.state.flagged_bool_check;
      for (let camera of scene_data.cameras){
        if (camera_status_indexes[camera.camera_name] != undefined) {
            camera['scene_state_index'] = camera_status_indexes[camera.camera_name];
        }
        
        if (flagged_images[camera.camera_name] != undefined && this.state.selected_renders[camera.camera_name] == true) {
          camera['selected'] = true;
        } else {
          camera['selected'] = false;
        }
      }

      scene_data['final_renders'] = this.state.final_renders;
      scene_data['three_sixties'] = this.state.three_sixties;

      if ( this.state.type == "scene_lifestyle" ){
        scene_data['generate_360'] = 0;
        scene_data['generate_still'] = 1;
      }
      if ( this.state.type == "scene_360" ){
          scene_data['generate_360'] = 1;
          scene_data['generate_still'] = 0;
      }
      scene_data['complaint_id'] = this.props.match.params.id;
      scene_data['room_max_archive'] = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_id + '.zip';
      scene_data['username'] = localStorage.getItem('username');


      let scene_update_payload = {
        "scene_id": this.state.scene_id,
        "username": localStorage.getItem('username'),
        "design": scene_data,
        "genertate_renders": 1,
        "room_max_archive": ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SPACE_LOW_POLY_URI + this.state.room_id + '.zip',
        "admin_request": true,
        "complaint_id": this.props.match.params.id
      };

      // append render settings that need to be overriden in the request
      scene_update_payload['override_render_settings'] = {
        use_default_sun: this.state.use_default_sun
      }
      
      console.log("Scene Update Payload", scene_update_payload);
        axios.post(ENVIRONMENT.UPDATE_SCENE, scene_update_payload)
          .then( (response) => {
            let temp_uploading = [];
            let temp_progress = [];
            let temp_id = '';
            this.state.config_data.map((config,index) => {
              temp_id = _.cloneDeep(config.scene_config_id);
              temp_uploading[temp_id] = false;
              temp_progress[temp_id] = null;
            });
  
            this.setState({
              button_loader: false,
              replace_3dsmax: false,
              enable_upload_button: true,
              config_progress: temp_progress,
              config_uploading: temp_uploading,
              json_uploading: temp_uploading,
              generate_new_renders: false,
              space_bundle_modal: false,
              render_request_success: true,
              upload_error: '',
              uploadStatus: '',
              generate_new_renders: false,
            }, () => {
              let payload = {};
              payload = {
                complaint_id: this.props.match.params.id,
                status: "in_progress"
              }
              // pass the complaint time spent info as well
              if(this.state.time_spent != 0 && this.state.super_artist_complaint_page){
                payload['time_spent'] = this.state.time_spent;
                payload['qa_username'] = localStorage.getItem('username')
              }
              axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
              .then(res => {
              })
              .catch((error) => {
                console.log(error,'Error in updating complaint status.');
                message.error('Error in updating complaint status.');
              });  
            });
          })
          .catch((error) => {
            console.log(error, 'Error in updating scene');
            message.error('Error in updating scene.');
            this.setState({
              button_loader: false,
            });
          });  
    }
  }

  callback = () => {
    if (this.virtualShowroom === null){
      if(this.state.threed_tour != "" && this.state.type == "scene_3dtour") {
        setTimeout(function() {
            this.virtualShowroom = new VirtualShowroom(this.state.threed_tour, this.state.scene_id, false, "hotspot-admin","vs-container-admin");
        }.bind(this), 1000);
      }
    }
  }

  changeSelectedAsset = (asset) => {
    console.log('asset = ', asset)
    this.setState({
      selected_image: asset.split('.')[0],
      selected_format: asset.split('.')[1]
    });
  }

  downloadSiloFlaggedArchive = () => {
    let downloadData = [];
    if(this.props.productReducer.product != undefined){
      if (this.props.productReducer.product['silo_data'] != undefined) {
        if(this.props.productReducer.product['silo_data'].length > 0) {
          this.props.productReducer.product['silo_data'].map((url) => {
            this.state.flagged_images.map((image,index) => {
              if (image.includes(url.camera_name)) {
                let img_url = '';
                if(this.state.product_guidelines && this.state.product_guidelines.company_id && this.state.product_guidelines.company_id == this.state.company_id){
                  img_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/company/' + this.state.product_guidelines.company_id + '/' + url.camera_name + '.' + url.image_data.img_format
                }
                else {
                  img_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/' + this.state.customer_name + '/' + url.camera_name + '.' + url.image_data.img_format
                }
                downloadData.push(img_url);
              }
            });
          });
        }
      }
      saveToZip ('Flagged Silo Images.zip', downloadData);
    }
  }

  downloadSceneImagesArchive = () => {
    let downloadData = [];
    if (this.state.type == "scene_360") {

      if (this.state.three_sixties != undefined) {
        if(this.state.three_sixties.length > 0) {
          this.state.three_sixties.map((url) => {
            downloadData.push(ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + url.filename);
          });
        }
      }
    }
    else if (this.state.type == "scene_lifestyle") {
      if (this.state.lifestyle_renders != undefined && this.state.lifestyle_renders.length > 0) {
        this.state.lifestyle_renders.map((url) => {
          downloadData.push(ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + url.filename);
        });
      }
    }

    let renders = [];
    if (this.state.type == "scene_360") {
      renders = this.state.three_sixties;
    } else if (this.state.type == "scene_lifestyle") {
      renders = this.state.lifestyle_renders;
    }
    if (this.state.crypto_exists) {
      if (renders != undefined && renders.length > 0) {
        if (this.state.crypto_images && this.state.crypto_images.length > 0) {
          renders.map((url,index) => {
            if (this.state.crypto_images.includes(index)) {
                let image_name = url.filename.split('.');
                let image_name_w_format = image_name[0] + '_cMatte.jpg'
                let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + image_name_w_format;
                if (url.status == 'rendered'){
                  downloadData.push(new_url);
                }
            }
                
            });
        } else {
          this.setState({
            crypto_exists: false
          });  
        }
      }
    }
    saveToZip (this.state.scene_id+'_Scene Images.zip', downloadData);
  }

  checkIfCryptoExists = (name, index, type) => {
    if (type != 'Product') {
      let temp_crypto_image = this.state.crypto_images;
      let image_name_w_format = name + '_cMatte.jpg'
      let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + image_name_w_format;
      axios.head(new_url,{})
      .then(res => {
        this.setState({
            crypto_exists: true
        })
        temp_crypto_image.push(index)
        this.setState({
          crypto_images: temp_crypto_image
        });
      })
    }
  }

  downloadCryptoImagesArchive = () => {
    if (this.state.type == 'scene_lifestyle') {
      this.downloadSceneImageCryptomattes(this.state.lifestyle_renders);
    } else if (this.state.type == "scene_360") {
      this.downloadCryptoImagesArchive(this.state.three_sixties);
    } else if (this.state.type == 'Product') {
      this.downloadSiloCryptoMattes();
    }
  }

  downloadSceneImageCryptomattes = (renders) => {
    if (this.state.crypto_exists) {
      let downloadData = [];
      if (renders != undefined && renders.length > 0) {
        if (this.state.crypto_images && this.state.crypto_images.length > 0) {
          renders.map((url,index) => {
            if (this.state.crypto_images.includes(index)) {
                let image_name = url.filename.split('.');
                let image_name_w_format = image_name[0] + '_cMatte.jpg'
                let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + image_name_w_format;
                if (url.status == 'rendered'){
                  downloadData.push(new_url);
                }
            }
                
            });
            saveToZip (this.state.scene_id + '_Scene Lifestyle Cryptomatte.zip', downloadData);
        } else {
          this.setState({
            crypto_exists: false
          });  
        }
      }
    } else {
      this.setState({
        crypto_exists: false
      });
    }
  }

  downloadSiloCryptoMattes = () => {
    let downloadData = [];
    if (this.state.silo_data != undefined && this.state.silo_data.length > 0) {
      this.state.silo_data.map((url) => {      
        let img_url = '';
        if(this.state.product_guidelines && this.state.product_guidelines.company_id){
          img_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/company/' + this.state.product_guidelines.company_id + '/' + url.camera_name + "_cMatte.jpg"
        }
        else {
          img_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/' + this.state.customer_name + '/' + url.camera_name + "_cMatte.jpg"
        }
        downloadData.push(img_url);
      }); 
    }
    saveToZip (this.state.scene_id + '_Cryptomatte.zip', downloadData);
  }

  downloadSceneFlaggedArchive = () => {
    let downloadData = [];
    if (this.state.type == "scene_360") {
      if (this.state.three_sixties != undefined) {
        if(this.state.three_sixties.length > 0) {
          this.state.three_sixties.map((url) => {
            this.state.flagged_images.map((image,index) => {
              if (url.filename.includes(image)) {
                downloadData.push(ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + url.filename);
              }
            });
          });
        }
      }
    } else if (this.state.type == "scene_lifestyle") {
      if (this.state.lifestyle_renders != undefined) {
        if(this.state.lifestyle_renders.length > 0) {
          this.state.lifestyle_renders.map((url) => {
            this.state.flagged_images.map((image,index) => {
              if (url.filename.includes(image)) {
                downloadData.push(ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.scene_id + '/' + url.filename);
              }
            });
          });
        }
      }
    }
    saveToZip ('Flagged Scene Images.zip', downloadData);
  }
 
  downloadImagesArchive = () => {
    let downloadData = [];
    if(this.props.productReducer.product != undefined){
      if (this.props.productReducer.product['silo_data'] != undefined) {
        if(this.props.productReducer.product['silo_data'].length > 0) {
          this.props.productReducer.product['silo_data'].map((url) => {
            let img_url = '';
            if(this.state.product_guidelines && this.state.product_guidelines.company_id){
              img_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/company/' + this.state.product_guidelines.company_id + '/' + url.camera_name + "." + url.image_data.img_format
            }
            else {
              img_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/' + this.state.customer_name + '/' + url.camera_name + "." + url.image_data.img_format
            }
            downloadData.push(img_url);

            let crypto_url = '';
            if(this.state.product_guidelines && this.state.product_guidelines.company_id){
              crypto_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/company/' + this.state.product_guidelines.company_id + '/' + url.camera_name + "_cMatte.jpg"
            }
            else {
              crypto_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.QA_IMG_URI  + this.state.scene_id + '/' + this.state.customer_name + '/' + url.camera_name + "_cMatte.jpg"
            }
            downloadData.push(crypto_url);
          });
        }
      }
      saveToZip (this.state.scene_id+'_Silo Images.zip', downloadData);
    }
  }

  change360FullScreen = () => {
    this.setState({
      isFullScreen: !this.state.isFullScreen
      },() => {
      if (this.state.isFullScreen) {
        if (document.getElementById("fullscreen-image") != null) {
          var targetelement = document.getElementById("fullscreen-image");  
          if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
            targetelement.webkitRequestFullScreen()
          } else {
            targetelement.requestFullscreen();
          }
        }
      }
      else {
        if (document.getElementById("fullscreen-image") != null) {
          if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
            document.webkitCancelFullScreen(); 
          } else {
            document.exitFullscreen();
          }
        }
      }
    });
  }

  handleImageChange = (info, filename) => {
    let fileList = [...info.fileList];
    if (fileList != undefined) {
      fileList = fileList.slice(-1);
      if ((this.state.type == "scene_360" || this.state.type == "scene_lifestyle")) {
        if (info.file.status == "done") {
            let payload = {
                "action" : "copy_file",
                "source_path" : 'test_files/' + info.file.uid + '/' + info.file.name,
                "destination_path" : 'complaint_renders/' + this.props.match.params.id + '/' + filename,
                "platform" : this.state.platform
            }
            this.setState({
              enable_upload_button: true,
              file_copied: false,
              uploaded_text: "Copying Files"
            })
            axios.post(ENVIRONMENT.CLOUD_FILE_COPY, payload)
            .then(response => {
              this.setState({
                enable_upload_button: false,
                file_copied: true,
                uploaded_text: "Uploaded"
              });
              console.log("File copied successfully");
            })
            .catch(error => {
              this.setState({
                enable_upload_button: true,
                file_copied: false,
                uploaded_text: "Failed"
              });
              console.log("File could not be copied");
            });

          let temp_arr = this.state.upload_failure_message;
          temp_arr[filename] = ''
          this.setState({
            upload_failure_message: temp_arr
          });
        } 
        else if (info.file.status == "error") {
          let temp_arr = this.state.upload_failure_message;
          temp_arr[filename] = info.file.error.code.concat('.   ' + info.file.error.message) 
          
          if (info.file.error.code.includes('Credentials')) {
            temp_arr[filename] = info.file.error.code.concat('. Your session has expired. Please reload the page.') 
          }  else if (info.file.error.code.includes('Network')) {
            temp_arr[filename] = info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
          }
          this.setState({
            upload_failure_message: temp_arr
          });
        }
      } else if (this.state.type == "Product") {
        if (info.file.status == "done") {
          this.setState({
            enable_upload_button: false,
            file_copied: true,
            uploaded_text: "Uploaded"
          });
        }
      }
    }    
  }

  handleMaxChange = (info, config_id=null) => {
    let fileList = [...info.fileList];
    if (fileList != undefined) {
      fileList = fileList.slice(-1);
      let temp_json = this.state.scene_3ds_json;
      let temp_config = this.state.scene_3ds_bundle;
      let temp_max = _.cloneDeep(this.state.defaultFileList);
      let temp_json_file = _.cloneDeep(this.state.jsonFileList);

      if (fileList.length != 0 && config_id !=null) {
        fileList.map((file) => {
          if (file.name.includes('.json')) {
            temp_json_file[config_id] = fileList;
            this.setState({
              jsonFileList: _.cloneDeep(temp_json_file)
            });
            var reader = new FileReader();
            reader.onload = (event) => {
              var obj = event.target.result;
              localStorage.setItem(`design_json_${config_id}`, obj);
              this.validateJSON(obj,config_id);
              temp_json[config_id] = obj;
              this.setState({
                scene_3ds_json: _.cloneDeep(temp_json),
              });
            };
            reader.readAsText(fileList[0].originFileObj);
          } else if (file.name.includes('.zip')) {
            temp_max[config_id] = fileList;
            this.setState({
              defaultFileList: _.cloneDeep(temp_max)
            });
            temp_config[config_id] = file.originFileObj;
            this.setState({
              scene_3ds_bundle: _.cloneDeep(temp_config)
            });
          }
        })
        if (info.file.status == "done") {
          let temp_arr = this.state.upload_failure_message;
            temp_arr[config_id] = ''
            this.setState({
              upload_failure_message: temp_arr
            });
        } else if (info.file.status == "error") {
          let temp_arr = this.state.upload_failure_message;
          temp_arr[config_id] = info.file.error.code.concat('.   ' + info.file.error.message) 
          
          if (info.file.error.code.includes('Credentials')) {
            temp_arr[config_id] = info.file.error.code.concat('. Your session has expired. Please reload the page.') 
          }  else if (info.file.error.code.includes('Network')) {
            temp_arr[config_id] = info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
          }
          this.setState({
            upload_failure_message: temp_arr
          });
        }
      }
      
    }    
  }

  getConfigInfo = (config_id, parsed_json) => {
    // add callback to fetch placement info after the scene config id is passed
    let sceneConfigPayload = {
      scene_config_id: config_id
    }
    axios.post(ENVIRONMENT.SCENE_CONFIG_GET, sceneConfigPayload)
      .then((res) => {
        if (res && res.data) {
          if (res.data['placement_info'] && res.data['placement_info']['assets']) {
            this.setState({
              asset_count: res.data['placement_info']['assets'].length
            }, () => {
              if (parsed_json['assets'].length != this.state.asset_count) {
                let upload_status = 'Asset count does not match count in original JSON.\n';
                this.setUploadProgress('error');
                let temp_arr =  _.cloneDeep(this.state.bundle_upload_status);
                temp_arr[config_id] = temp_arr[config_id].concat(upload_status);
                this.setState({
                  bundle_upload_status: _.cloneDeep(temp_arr)
                });
              }
            });  
          }
        }
      });
  }

  validateJSON = (json,config_id) => {
    let parsed_json = JSON.parse(json);
    
    let upload_status = '';
    if (!parsed_json['assets']) {
      upload_status = upload_status.concat('Assets missing.\n');
      this.setUploadProgress('error');
    }
    if (!parsed_json['cameras']) {
      upload_status = upload_status.concat('Cameras missing.\n');
      this.setUploadProgress('error');
    }
    if (!parsed_json['sun']) {
      upload_status = upload_status.concat('Sun Information missing.\n');

      this.setUploadProgress('error');
    }
    if (!parsed_json['lights']) {
      upload_status = upload_status.concat('Light Information missing.\n');

      this.setUploadProgress('error');
    }

    if (!parsed_json['scene_id']) {
      upload_status = upload_status.concat('Scene ID missing.\n');

      this.setUploadProgress('error');
    } else  {
      if (parsed_json['scene_id'] != this.state.scene_id) {
        upload_status = upload_status.concat('Invalid Scene ID.\n');

        this.setUploadProgress('error');
      }
    }

    if (!parsed_json['room_id']) {
      upload_status = upload_status.concat('Space ID missing.\n');
      this.setUploadProgress('error');
    } else  {
      if (parsed_json['room_id'] != this.state.room_id) {
        upload_status = upload_status.concat('Invalid Space ID.\n');

        this.setUploadProgress('error');
      }
    }

    if (!parsed_json['room_name']) {
      upload_status = upload_status.concat('Space Name missing.\n');
      this.setUploadProgress('error');
    }

    if (upload_status != '') {
      upload_status = upload_status.concat('Please fix your json file and reupload.\n');
    }
    this.getConfigInfo(config_id, parsed_json);
    // this.setState({
    //   uploadStatus: upload_status
    // });
    this.setBundleUploadStatus(config_id,upload_status,'max_json');
  }


  handleJSONFileChange = (info, config_id) => {
    let fileList = [...info.fileList];
    if (fileList != undefined) {
      fileList = fileList.slice(-1);
      let temp_json_file= _.cloneDeep(this.state.jsonFileList);
      if (fileList.length != 0) {
        temp_json_file[config_id] = fileList;
        this.setState({
          jsonFileList: _.cloneDeep(temp_json_file)
        });
        var reader = new FileReader();
        reader.onload = (event) => {
          var obj = event.target.result;
          localStorage.setItem(`design_json_${config_id}`, obj);
          this.validateJSON(obj,config_id);
        };
        reader.readAsText(fileList[0].originFileObj);
      }
      if (info.file.status == "done") {
        let temp_arr = this.state.upload_failure_message;
        temp_arr[config_id] = ''
        this.setState({
          upload_failure_message: temp_arr
        });
      } else if (info.file.status == "error") {
        let temp_arr = this.state.upload_failure_message;
        temp_arr[config_id] = info.file.error.code.concat('.   ' + info.file.error.message) 
        
        if (info.file.error.code.includes('Credentials')) {
          temp_arr[config_id] = info.file.error.code.concat('. Your session has expired. Please reload the page.') 
        }  else if (info.file.error.code.includes('Network')) {
          temp_arr[config_id] = info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
        }
        this.setState({
          upload_failure_message: temp_arr
        });
      }
     
    }  
  }

  getFileStatus = (name,info) => {
    let file = info.file;
    if (file != undefined) {
      let temp = [];
      let temp_url = '';
      if (this.state.type == "scene_360") {
        this.state.three_sixties.map((url) => {
          temp_url = url.filename.split('.')[0];
          if (name == temp_url) {
            if (file['status'] == 'uploading') {
              temp[name] = 'uploading';
            }
            else if (file['status'] == 'error') {
              temp[name] = 'error';
            }
            else if (file['status'] == 'done') {
              temp[name] = 'done';
              this.setState({
                enable_upload_button: false
              });
            }
          } else {
            temp[temp_url] = this.state.uploading_progress[temp_url];
          }
        });
      } else if (this.state.type == "scene_lifestyle") {
        this.state.lifestyle_renders.map((url) => {
          temp_url = url.filename.split('.')[0];
          if (name == temp_url) {
            if (file['status'] == 'uploading') {
              temp[name] = 'uploading';
            }
            else if (file['status'] == 'error') {
              temp[name] = 'error';
            }
            else if (file['status'] == 'done') {
              temp[name] = 'done';
              this.setState({
                enable_upload_button: false
              });
            }
          } else {
            temp[temp_url] = this.state.uploading_progress[temp_url];
          }
        });
      } else if (this.state.type == "Product") {
        let camera_name = '';
        this.state.silo_data.map((url) => {
          camera_name = url.camera_name;
          if (name == camera_name) {
            if (file['status'] == 'uploading') {
              temp[name] = 'uploading';
            }
            else if (file['status'] == 'error') {
              temp[name] = 'error';
            }
            else if (file['status'] == 'done') {
              temp[name] = 'done';
              this.setState({
                enable_upload_button: false
              });
            }
          } else {
            temp[camera_name] = this.state.uploading_progress[camera_name];
          }
        });
      }
      this.setState({
        uploading_progress: temp
      });
    }
  }

  getBundleFileStatus = (info) => {
    let fileList = [...info.fileList];
    if (fileList != undefined) {
      fileList = fileList.slice(-1);
      this.setState({
        bundlefileList: fileList
      });
      
    }    
    
    let file = info.file;
    let file_status = '';
    if (file != undefined) {
      if (file['status'] == 'uploading') {
        file_status = 'uploading';
        this.setState({
          upload_bundle_failure_message: ''
        });
      }
      else if (file['status'] == 'error') {
        file_status = 'error';
        this.setState({
          upload_bundle_failure_message: info.file.error.code.concat('.   ' + info.file.error.message) 
        });
        if (info.file.error.code.includes('Credentials')) {
          this.setState({
            upload_bundle_failure_message: info.file.error.code.concat('. Your session has expired. Please reload the page.') 
          });
        }  else if (info.file.error.code.includes('Network')) {
          this.setState({
            upload_bundle_failure_message: info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
          });
        }
      }
      else if (file['status'] == 'done') {
        file_status = 'done';
        this.setState({
          enable_upload_button: false
        });
      }

      this.setState({
        bundle_progress: file_status
      });
    }
  }

  setValidationData = () => {
    let automatically_fixed_high = [];
    let successfully_validated_high = [];
    let invalid_data_high = [];
    let success_val_length = 0;
    let invalid_val_length = 0;
    let auto_val_length = 0;

    if (this.state.validation_report != undefined) {
      if (this.state.validation_report['High'] != undefined && Object.keys(this.state.validation_report['High']).length != 0) {
        if (this.state.validation_report['High']['dimensions']) {
          successfully_validated_high.push(`Depth is ${this.state.validation_report['High']['dimensions'].depth} inches`);
          successfully_validated_high.push(`Height is ${this.state.validation_report['High']['dimensions'].height} inches`);
          successfully_validated_high.push(`Width is ${this.state.validation_report['High']['dimensions'].width} inches`);
        }
        if (this.state.validation_report['High'].vertexCount) {
          successfully_validated_high.push(`Vertex Count is ${this.state.validation_report['High'].vertexCount}`);
        }
        if (this.state.validation_report['High']['space_information']) {
          if (this.state.validation_report['High']['space_information']['sun']) {
            if (this.state.validation_report['High']['space_information']['sun'].valid && !this.state.validation_report['High']['space_information']['sun'].fixed) {
              successfully_validated_high.push('Sun Node');
            }
            if (!this.state.validation_report['High']['space_information']['sun'].valid && this.state.validation_report['High']['space_information']['sun'].fixed) {
              automatically_fixed_high.push('Sun Node');
            }
            if (!this.state.validation_report['High']['space_information']['sun'].valid && !this.state.validation_report['High']['space_information']['sun'].fixed) {
              invalid_data_high.push(<span>Sun Node (Refer to Section 8 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
            }
          }
          if (this.state.validation_report['High']['space_information']['lights'] != undefined) {
            if (this.state.validation_report['High']['space_information']['lights'].valid) {
              successfully_validated_high.push('Lights');
            } else if (!this.state.validation_report['High']['space_information']['lights'].valid) {
              invalid_data_high.push(<span>Lights are Missing (Refer to Section 8 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
            }
          }

          if (this.state.validation_report['High']['space_information']['asset_node']) {
            if (!this.state.validation_report['High']['space_information']['asset_node'].valid && this.state.validation_report['High']['space_information']['asset_node'].fixed) {
              automatically_fixed_high.push('Space Node');
            }
        
            if (this.state.validation_report['High']['space_information']['asset_node'].valid && !this.state.validation_report['High']['space_information']['asset_node'].fixed) {
              successfully_validated_high.push('Space Node');
            }
        
            if (!this.state.validation_report['High']['space_information']['asset_node'].valid && !this.state.validation_report['High']['space_information']['asset_node'].fixed) {
              invalid_data_high.push(<span>Space Node (Refer to Section 5 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
            }
          }
        
          if (this.state.validation_report['High']['space_information']['areas']) {
            this.state.validation_report['High']['space_information']['areas'].map((area) => {
              if (area.valid) {

              } else if (!area.valid && !area.fixed) {
                if (!area.structure.valid) {
                  invalid_data_high.push(<span>Invalid Structure for {area.name} (Refer to section 6 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                } 
                if (area.window) {
                  if (!area.window.valid) {
                    invalid_data_high.push(<span>Invalid Window for {area.name} (Refer to section 6 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  }
                }
              } 
              else if (!area.valid && area.fixed) {
                if (area.structure.fixed) {
                  automatically_fixed_high.push(`Fixed Structure for ${area.name}`);
                } 
                if (area.window) {
                  if (area.structure.fixed) {
                    automatically_fixed_high.push(`Fixed Window for ${area.name}`);
                  }
                }
              }
            });
          }
        }
        if (this.state.validation_report['High']['vray_materials']) {
          this.state.validation_report['High']['vray_materials'].map((material) => {
            if (!material.valid) {
              invalid_data_high.push(<span> {material.name} is not a valid VRay Material (Refer to section 10 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
            }
          });
        }

        if (this.state.validation_report['High']['vray_textures']) {
          this.state.validation_report['High']['vray_textures'].map((texture) => {
            if (!texture.found) {
              invalid_data_high.push(<span>{texture.name} was missing in VRay Textures (Refer to section 10 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
            }
          });
        }
      }
    }
    success_val_length = successfully_validated_high.length;
    auto_val_length = automatically_fixed_high.length;
    invalid_val_length = invalid_data_high.length;

    if (invalid_val_length == 0) {
      this.setState({
        reupload_flag: false
      });
    }

    if (this.state.validation_report != undefined  && (success_val_length != 0 || auto_val_length != 0 || invalid_val_length != 0)) {
      if (this.state.validation_report.length == 0) {
        this.setState({
          validation_generated: false,
        });
      } else {
        this.setState({
          validation_generated: true,
        });
        if (this.state.validation_report['High']) {
          if (this.state.validation_report['High'].report) {
            this.setState({
              validation_check: true,
            });
            if (invalid_val_length > 0) {
              this.setState({
                reupload_flag: true
              });
            } else if (invalid_val_length == 0) {
              this.setState({
                reupload_flag: false
              });
            }
          }
          else {
            this.setState({
              validation_check: false,
              reupload_flag: true
            });
            if (!this.state.validation_report['High'].space_valid) {
              this.setState({
                space_valid: false
              });
            }
          }
        }
      }
    } else {
      if (this.state.validation_report['High']) {
          if (this.state.validation_report['High'].report) {
            this.setState({
              validation_check: true,
            });
            if (invalid_val_length > 0) {
              this.setState({
                reupload_flag: true
              });
            } else if (invalid_val_length == 0) {
              this.setState({
                reupload_flag: false
              });
            }
          }
          else {
            this.setState({
              validation_check: false,
              reupload_flag: true
            });
            if (!this.state.validation_report['High'].space_valid) {
              this.setState({
                space_valid: false
              });
            }
          }
        } else {
          this.setState({
            validation_generated: false,
            reupload_flag: true
          });
        }
        this.forceUpdate();
    }


    this.setState({
      automatically_fixed_high: automatically_fixed_high,
      successfully_validated_high: successfully_validated_high,
      invalid_data_high: invalid_data_high,
      success_val_length: success_val_length,
      invalid_val_length: invalid_val_length,
      auto_val_length: auto_val_length
    });
  }

  changeBundleUploadStatus = (status) => {
    let uploading_status = status;
    this.setState({
      bundle_uploading: uploading_status,
      upload_type:'space',
      bundlefileList: [],
      space_validation: 0,
      toggle_summary: false,
      validation_report:[],
      validation_generated: false,
      automatically_fixed_high: [],
      successfully_validated_high:[],
      invalid_data_high: [],
      success_val_length: 0,
      invalid_val_length: 0,
      auto_val_length: 0,
      upload_bundle_failure_message: '',
      validation_check: false,
      reupload_flag: false,
      space_valid: true,
    });
  }

  getFullScreenTour = () =>  {
    if (this.state.isFullScreen) {
      this.setState({ isFullScreen: false });
      if(this.state.threed_tour != "" ) {
          setTimeout(function() {
              this.virtualShowroom.toggleFull(false);
          }.bind(this), 1000)
      }
    }
    else {
      this.setState({ isFullScreen: true });
      message.info('Press ESC to exit full screen');
      if(this.state.threed_tour != "" ) { 
          this.virtualShowroom.toggleFull(true);
      }
    } 
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
        this.setState({
            isFullScreen: false
        });
    }
  }

  openReplaceModal = () => {
    if (this.state.type == "scene_360") {
      this.setState({
        replace_scene_modal: true,
        uploadStatus: ''
      });
    }
    else if (this.state.type == "Product" || this.state.type == "scene_lifestyle"){
      this.setState({
        replace_modal: true,
        uploadStatus: ''
      });
    }
  }

  cancelReplaceModal= () => {
    let temp_url = '';
    let temp = [];
    let temp_progress = [];
    if (this.state.type == "scene_360") {
      this.state.three_sixties.map((url) => {
        temp_url = url.filename.split('.')[0];
        temp[temp_url] = false;
        temp_progress[temp_url] = null;
      });
      this.setState({
        replace_scene_modal: false,
        show_more: false,
        uploading: temp,
        uploading_progress: temp_progress,
        upload_error: '',
        uploadStatus: '',
      }, () => {
        this.forceUpdate();
      });
    }
    else if (this.state.type == "Product" || this.state.type == "scene_lifestyle"){
      if (this.state.type == "scene_lifestyle") {
        this.state.lifestyle_renders.map((url) => {
          temp_url = url.filename.split('.')[0];
          temp[temp_url] = false;
          temp_progress[temp_url] = null;
        });
      }
      else if (this.state.type == "Product") {
        this.state.silo_data.map((url) => {
          temp[url.camera_name] = false;
          temp_progress[url.camera_name] = null;
        });
      }
      this.setState({
        replace_modal: false,
        show_more: false,
        uploading: temp,
        enable_upload_button: true,
        uploading_progress: temp_progress,
        upload_error: '',
        uploadStatus: '',
      }, () => {
        this.forceUpdate();
      });
    }
  }

  replace3dsMaxModal = () => {
    this.setState({
      replace_3dsmax: true,
      uploadStatus: ''
    });
  }

  cancelReplace3dsMaxModal = () => {
    let temp_uploading = [];
    let temp_progress = [];
    let temp_id = '';
    let temp_filelist = [];
    this.state.config_data.map((config,index) => {
      temp_id = _.cloneDeep(config.scene_config_id);
      temp_uploading[temp_id] = false;
      temp_progress[temp_id] = null;
      temp_filelist[temp_id] = [];
    });

    this.setState({
      success_modal: false,
      discard_uploads: false,
      defaultFileList: _.cloneDeep(temp_filelist),
      jsonFileList: _.cloneDeep(temp_filelist),
      submit_scene_success: false,
      scene_3ds_json: temp_progress,
      scene_3ds_bundle: temp_progress,
      replace_3dsmax: false,
      config_progress: temp_progress,
      config_uploading: temp_uploading,
      json_uploading: temp_uploading,
      enable_upload_button: true,
      upload_error: '',
      uploadStatus: '',
      all_uploaded: false,
      upload_type: '',
      bundle_upload_status: _.cloneDeep(temp_progress),
      upload_failure_message: {}
    });
  }

  showMore = () => {
    this.setState({
      show_more: true
    });
  }

  showFlagged = () => {
    this.setState({
      show_more: false
    });
  }

  toggleShowMore = () => {
    this.setState({
      show_more: !this.state.show_more
    },() => {
      if (this.state.show_more) {
        this.setState({
          config_data: this.state.retrieved_config_data
        }, () => {
          console.log(this.state.config_data,this.state.retrieved_config_data,'Retreived')
        });
      } else {
        this.setState({
          config_data: this.state.flag_config_data
        }, () => {
          console.log(this.state.config_data,'Flagged Configurations')
        });
      }
    });

    this.forceUpdate();
  }

  show3dsDownload = () => {
    
    this.setState({
      scene_3ds_download: true
    });
    
  }

  cancel3dsDownload = () => {
    this.setState({
      scene_3ds_download: false
    });
  }

  openSpaceBundleModal = () => {
    this.setState({
      space_bundle_modal: true,
      uploadStatus: ''
    });
  }

  cancelSpaceBundleModal = () => {
    this.setState({
      space_bundle_modal: false,
      bundle_progress: '',
      bundlefileList: [],
      bundle_uploading: false,
      enable_upload_button: true,
      upload_error: '',
      uploadStatus: '',
      upload_type: '',
      all_uploaded: false,
      discard_uploads: false,
      success_modal: false,
      space_validation: 0,
      toggle_summary: false,
      validation_report:[],
      validation_generated: false,
      automatically_fixed_high: [],
      successfully_validated_high:[],
      invalid_data_high: [],
      success_val_length: 0,
      invalid_val_length: 0,
      auto_val_length: 0,
      validation_check: false,
      reupload_flag: false,
      space_valid: true,
      upload_failure_message: {},
      upload_bundle_failure_message: ''
    });
  }

  closeSuccessModal = () => {
    this.setState({
      success_modal: false,
      submit_json_success: false,
      submit_bundle_success: false,
      submit_scene_success: false
    },() => {
      window.location.reload(true);
    });
  }

  setUploadStatus= (status) => {
    this.setState({
      uploadStatus: status
    });
  }

  checkImageFormat = (file,name,format) => {
    console.log(name,format,file.name)
    let filename = name + '.' + format;
    if(!file.name.includes(format)){
      let temp_arr = this.state.upload_failure_message;
      temp_arr[filename] = `Error. Please upload image in ${format} format.`
      this.setState({
        upload_failure_message: temp_arr
      })
      return false;
    } else {
      let temp_arr = this.state.upload_failure_message;
      temp_arr[filename] = ``
      this.setState({
        upload_failure_message: temp_arr
      })
    }
    return true;
  }

  changeUploadStatus = (name,status) => {
    console.log(name,status)
    let temp = [];
    let temp_url = '';
    let camera_name = '';
    let temp_arr = _.cloneDeep(this.state.upload_failure_message);
   
    if (this.state.type == "scene_360") {
      this.state.three_sixties.map((url) => {
        temp_url = _.cloneDeep(url.filename.split('.')[0]);
        if (temp_url == name) {
          temp[temp_url] = status;
          temp_arr[url.filename] = "";
        } else {
          temp[temp_url] = this.state.uploading[temp_url];
        }
      });
    } else if (this.state.type == "scene_lifestyle") {
      this.state.lifestyle_renders.map((url) => {
        temp_url = _.cloneDeep(url.filename.split('.')[0]);
        if (temp_url == name) {
          temp[temp_url] = status;
          temp_arr[url.filename] = "";
          console.log(url,temp_arr[url])
        } else {
          temp[temp_url] = this.state.uploading[temp_url];
        }
      });
    } else if (this.state.type == "Product") {
        this.state.silo_data.map((url) => {
         camera_name = _.cloneDeep(url.camera_name);
         if (camera_name == name) {
          temp[camera_name] = status;
          temp_arr[url.camera_name] = "";
        } else {
          temp[camera_name] = this.state.uploading[camera_name];
        }
      });
    }

    console.log('temp arr',temp_arr)
    
    this.setState({
      upload_failure_message: temp_arr
    });

    this.setState({
      uploading: temp
    });
  }

  removeFile = (config_id,type) => {
    let temp_arr = [];

    if (type == 'max') {
      temp_arr = _.cloneDeep(this.state.defaultFileList);
      temp_arr[config_id] = [];
      this.setState({
        defaultFileList: _.cloneDeep(temp_arr)
      });
    } else if (type == 'json') {
      temp_arr = _.cloneDeep(this.state.jsonFileList);
      temp_arr[config_id] = [];
      this.setState({
       jsonFileList: _.cloneDeep(temp_arr)
      });
    }
    let temp_error = this.state.upload_failure_message;
    temp_error[config_id] = ''
    this.setState({
      upload_failure_message: temp_error
    });
  }

  changeConfigUploadStatus = (id,status,type='',max_type='') => {
    let temp = [];
    let temp_id = '';
    let temp_json = [];
    let temp_config = [];
    let temp_arr = [];
    this.state.config_data.map((config,index) => {
      temp_id = _.cloneDeep(config.scene_config_id);

      if (type == 'max_json' || max_type == 'max_json') {
        if (temp_id == id) {
          temp_arr[temp_id] = status;
        } else {
          temp_arr[temp_id] = this.state.json_uploading[temp_id];
        }
      } else {
        if (temp_id == id) {
          temp[temp_id] = status;
        } else {
          temp[temp_id] = this.state.config_uploading[temp_id];
        }
      }
    });

    if (type == 'max_json' || max_type == 'max_json') {
      this.setState({
        json_uploading: temp_arr,
        upload_type: type
      });
    } else {
      this.setState({
        config_uploading: temp,
        upload_type: type
      });
    }
   
    if (max_type == 'max_json') {
      temp_json[id] = null;
      this.setState({
        scene_3ds_json: temp_json,
      });
    }
    if (max_type == 'max') {
      temp_config[id] = null;
      this.setState({
        scene_3ds_bundle: temp_config
      });
    }
  }

  getConfigFileStatus = (id,info) => {
    let file = info.file;
    if (file != undefined) {
      let temp = [];
      let temp_id = '';
        this.state.config_data.map((config,index) => {
          temp_id = _.cloneDeep(config.scene_config_id);
          if (id == temp_id) {
            if (file['status'] == 'uploading') {
              temp[id] = 'uploading';
            }
            else if (file['status'] == 'error') {
              temp[id] = 'error';
            }
            else if (file['status'] == 'done') {
              temp[id] = 'done';
              this.setState({
                enable_upload_button: false
              });
            }
          } else {
            temp[temp_id] = this.state.config_progress[temp_id]
          }
        });
      this.setState({
        config_progress: temp
      }); 
    }
  }

  onChangeSpaceSuccessOption = (e) => {
    this.setState({space_success_option: e.target.value});
  }

  downloadCompleteBundle = () => {
    this.setState({
      download_complete_loader: true
    });
    axios.post(DOWNLOAD_COMPLETE_BUNDLE, { 
      scene_id: this.state.scene_id,
      username: localStorage.getItem('username'),
     })
    .then(res => {
      this.setState({
        download_complete_loader: false,
        scene_3ds_download: false,
        download_wait_modal:true
      });
    }).catch((error) => {
      message.error('Error in downloading bundle.');
      console.log(error,'Error in downloading bundle.');
    })
  }

  downloadConfigurationBundle = (config_id) => {
    let temp_arr = [];
    temp_arr[config_id] = true;
    this.setState({
      download_loader: temp_arr
    });
    axios.post(DOWNLOAD_COMPLETE_BUNDLE, { 
      scene_id: this.state.scene_id,
      username: localStorage.getItem('username'),
      configuration_id: config_id
     })
    .then(res => {
      let temp_arr = [];
      temp_arr[config_id] = false;
      this.setState({
        download_loader: temp_arr,
        scene_3ds_download: false,
        download_wait_modal:true
      });
    }).catch((error) => {
      message.error('Error in download bundle.');
    })
  }

  toggleCompareButton = () => {
    this.setState({
      compare_state: !this.state.compare_state,
      selected_image: this.state.selected.split('.')[0]
    });
    if (this.state.status == "resolved" && this.state.type == "scene_360") {
      this.setState({
        selected_image: this.state.flagged_images[0]
      });
    }
  }

  selectRenders = (e) => {
    let flag = false;
    let temp_arr = this.state.selected_renders;
    if (this.state.type == "scene_360") {
      this.state.three_sixties.map((name,index) => {
        let url = name.filename.split(".")[0];
        if (url == e.target.selected_render) {
          temp_arr[url] = e.target.checked;
        }
        if (temp_arr[url]) {
          flag = true;
        }
      });
    } else if (this.state.type == "scene_lifestyle") {
      this.state.lifestyle_renders.map((name,index) => {
        let url = name.filename.split(".")[0];
        if (url == e.target.selected_render) {
          temp_arr[url] = e.target.checked;
        }
        if (temp_arr[url]) {
          flag = true;
        }
      });
    }

    if (flag) {
      this.setState({
        enable_request_render: false
      });
    } else {
      this.setState({
        enable_request_render: true
      });
    }
   
    this.setState({
      selected_renders: temp_arr
    });
  }

  approveRender = () => {
    let temp_arr = _.cloneDeep(this.state.approve_render);
    let count = 0;
    let count_trues = 0;
    if (this.state.type == "scene_360") {
      this.state.complaint_360.map((name,index) => {
        let url = name.filename.split(".")[0];
        if (url == this.state.selected.split(".")[0]) {
          if (temp_arr[url] && !url.includes('camera')) {
            temp_arr[url] = false;

          } else {
            temp_arr[url] = true;
            count_trues = count_trues + 1;
          }
        }  else {
          if (temp_arr[url]) {
            count_trues = count_trues + 1;
          }
        }
        
        if (name.generated_by_admin) {
          count = count + 1;
        }
      });
    } else if (this.state.type == "scene_lifestyle") {
      this.state.complaint_lifestyle.map((name,index) => {
        let url = name.filename.split(".")[0];
        if (url == this.state.selected.split(".")[0]) {
          if (temp_arr[url] && url.includes('camera')) {
            temp_arr[url] = false;
          } else {
            temp_arr[url] = true
            count_trues = count_trues + 1;
          }
        } else {
          if (temp_arr[url]) {
            count_trues = count_trues + 1;
          }
        }
        
        if (name.generated_by_admin && name.filename.includes('camera')) {
          count = count + 1;
        }
      });
    }
    console.log('approved renders',temp_arr, count_trues,count, this.state.approve_original,this.state.original_exist);
    this.setState({
      approve_render: temp_arr
    }) 
    if (count_trues == count) {
      this.setState({
        enable_resolve_btn: true,
        
      });
      if (this.state.original_exist && this.state.approve_original) {
        this.setState({
          enable_resolve_btn: true
        });
      } else if (this.state.original_exist && !this.state.approve_original) {
        this.setState({
          enable_resolve_btn: false
        });
      }
    } else {
      this.setState({
        enable_resolve_btn: false
      });
    }
  }

  useOriginalRender = () => {
    let temp_arr = _.cloneDeep(this.state.original_renders);
    let temp_approve = _.cloneDeep(this.state.approve_render);
    let count = 0;
    let count_trues = 0;
    let count_admin = 0;
    let count_app = 0;
    if (this.state.type == "scene_360") {
      this.state.complaint_360.map((name,index) => {
        let url = name.filename.split(".")[0];
        if (url == this.state.selected.split(".")[0]) {
          if (this.state.original_renders[url] && !url.includes('camera')) {
            temp_arr[url] = false;
          } else {

            temp_arr[url] = true;
            count_trues = count_trues + 1;

          }
        }  else {
          if (this.state.original_renders[url]) {
            temp_arr[url] = true;
            count_trues = count_trues + 1;
          } else {
            temp_arr[url] = false;
          }
        }
        if (this.state.approve_render[url]) {
          count_app = count_app + 1;//approved renders
        } 
        if (!name.generated_by_admin && name.flagged_by_user) {
          count = count + 1;//number of original renders and flagged items
        }
        if (name.generated_by_admin) {
          count_admin = count_admin + 1;//generated by admin
        }
      });
    } else if (this.state.type == "scene_lifestyle") {
      this.state.complaint_lifestyle.map((name,index) => {
        let url = name.filename.split(".")[0];
        if (url == this.state.selected.split(".")[0]) {
          if (this.state.original_renders[url]) {
            temp_arr[url] = false;
          } else {
            temp_arr[url] = true;
            count_trues = count_trues + 1; //nnumber of original renders
          } 
        } else {
          if (this.state.original_renders[url]) {
            temp_arr[url] = true;
            count_trues = count_trues + 1;
          } else {
            temp_arr[url] = false;
          }
        }
        if (this.state.approve_render[url]) {
          count_app = count_app + 1;//approved renders
        } 
        if (!name.generated_by_admin && name.flagged_by_user) {
          count = count + 1;//number of original renders and flagged items
        }
        if (name.generated_by_admin) {
          count_admin = count_admin + 1;//generated by admin
        }
      });
    }
    this.setState({
      original_renders: temp_arr
    }, () => {
      if (count_trues == count) {
        this.setState({
          approve_original: true
        });
        if (count_app == count_admin) {
          this.setState({
            enable_resolve_btn: true
          });
        } else {
          this.setState({
            enable_resolve_btn: false
          });
        }
      } else {
        this.setState({
          approve_original: false,
          enable_resolve_btn: false
        });
      }
    });
  }

  selectForApproval = (e) => {
    console.log(e)
  }

  handleSubmitJSON = (values) => {
    let time_spent = 0;
    if(values.time_spent){
      time_spent = values.time_spent;
    }
    this.setState({
      button_loader: true,
      upload_failure_message: {},
      time_spent: time_spent
    });
  
    let temp_data = [];
    let upload_status = '';
    for (const [key, value] of Object.entries(values)) {
      if (value != undefined) {
        let temp_arr = {};
        let config_id_key = key.replace(/\D/g,'');
        temp_arr['scene_configuration_id'] = config_id_key;
        let replaced_file = value[0];
        temp_arr['placement_info'] = JSON.parse(localStorage.getItem(`design_json_${config_id_key}`));
        temp_data.push(temp_arr);

        let uploadStatus = this.validateFileUploads(value);
        if (uploadStatus == 'uploading') {
          upload_status = 'in_progress';
          this.setState({
            uploadStatus: 'Uploads are in progress, please wait till file uploads are complete.',
            button_loader: false
          });
        }
        else if (uploadStatus == 'error') {
          upload_status = 'error';
          this.setState({
            uploadStatus: 'An error occured, please upload again',
            button_loader: false
          });
        }
        else if (uploadStatus == 'success'){
          
        }
      }
    }

    if (upload_status == '') {
      this.setState({
        uploadStatus: '',
        replace_json_modal: false,
        button_loader: false,
        payload_json: temp_data,
        success_modal: true,
        submit_json_success: true
      });      
    }
  }

  openRequestRenderModal = () => {
    this.setState({
      generate_new_renders: true,
      button_loader: false,
      success_modal: false,
      submit_json_success: false,
      submit_scene_success: false,
      submit_bundle_success: false
    })
  }

  backToUpload = () => {
    if (this.state.upload_type == 'json') {
      this.setState({
        success_modal: false,
        submit_json_success: false,
        replace_json_modal: true,
        discard_uploads: false,
        button_loader: false
      })
    }  else if (this.state.upload_type == 'max' || this.state.upload_type == 'max_json') {
      this.setState({
        success_modal: false,
        submit_scene_success: false,
        replace_3dsmax: true,
        discard_uploads: false,
        button_loader: false
      })
    } else if (this.state.upload_type == 'space') {
      this.setState({
        success_modal: false,
        submit_bundle_success: false,
        space_bundle_modal: true,
        discard_uploads: false,
        button_loader: false
      })
    }
    
  }

  discardUploads = () => {
   
    if (this.state.upload_type == 'json') {
      this.cancelJSONModal();
    } else if (this.state.upload_type == 'max' || this.state.upload_type == 'max_json') {
      this.cancelReplace3dsMaxModal();
    } else if (this.state.upload_type == 'space') {
      this.cancelSpaceBundleModal();
    }
  }

  setDiscardUploadTrue = () => {
    this.setState({
      discard_uploads: true,
      upload_failure_message: {},
      upload_bundle_failure_message: ''
    });
  }

  cancelJSONModal = () => {
    let temp_uploading = [];
    let temp_progress = [];
    let temp_id = '';
    let temp_filelist = [];

    this.state.config_data.map((config,index) => {
      temp_id = _.cloneDeep(config.scene_config_id);
      temp_uploading[temp_id] = false;
      temp_progress[temp_id] = null;
      temp_filelist[temp_id] = [];
    });

    this.setState({
      replace_json_modal: false,
      success_modal: false,
      discard_uploads: false,
      config_progress: _.cloneDeep(temp_progress),
      jsonFileList: _.cloneDeep(temp_filelist),
      json_uploading: temp_uploading,
      config_uploading: temp_uploading,
      submit_json_success: false,
      payload_json: [],
      enable_upload_button: true,
      upload_error: '',
      uploadStatus: '',
      all_uploaded: false,
      upload_failure_message: {},
      upload_type: '',
      bundle_upload_status: _.cloneDeep(temp_progress)
    });
  }

  cancelOriginalModal = () => {
    this.setState({
      send_back_original: false
    });
  }

  sendBackOriginal = (values) => {
    this.setState({
      button_loader: true
    })
    
    axios.post(ENVIRONMENT.TRIGGER_COMPLAINT_UPDATE, {
      "complaint_id": this.props.match.params.id,
      "status": "resolved",
      "admin_message": values.reason_message})
      .then(res => {
        this.setState({
          button_loader: false,
          send_back_original: false,
          issue_resolve_modal: true
        });
      })
      .catch((error) => {
        message.error('Error in adding renders.');
        console.log(error,'Error in adding renders.');
      });  
  }

  replaceJSONModal = () => {
    this.setState({
      replace_json_modal: true,
      uploadStatus: ''
    });
  }

  openSendBackModal = () => {
    this.setState({
      send_back_original: true
    });
  }

  changeSelectedRender = (asset) => {
    this.setState({
      selected: asset
    });
    this.forceUpdate();
  }

  markAsResolve = () => {
    if (this.state.original_exist) {
      this.setState({
        send_back_original: true
      });
    } else {
      this.setState({
        mark_resolve_button: true
      });
      axios.post(ENVIRONMENT.TRIGGER_COMPLAINT_UPDATE, {
        "complaint_id": this.props.match.params.id,
        "is_msprovider": this.state.by_msprovider,
        "captured_by": this.state.customer_name,
        "admin_message": "Complaint has been resolved successfully!",
        "status": "resolved"
      })
      .then(res => {
        
        this.setState({
          issue_resolve_modal: true,
          mark_resolve_button: false
        });
      
      }).catch((error) => {
        message.error('Error in updating complaint status.');
        console.log(error,'Error in updating complaint status.');
      });
    }
  }

  checkSceneFolderStructure = (file) => {
    let return_check = false;
    let parent = this;
    let new_zip = new JSZip();
    new_zip.loadAsync(file)
    .then((zip) => {
      for(let key in zip['files']){
        if (key.includes('.max')) {
          if (key == (parent.state.room_id + '.max')) {
            return_check = true;
          } else {
            return_check = false;
          }
        }
      }
      if (!return_check) {
          parent.setState({
            uploadStatus: "Incorrect folder structure! Max file should be named as room_id.max and should be present at root.",
            upload_error: 'error'
          });
        return false;
      } else {
        parent.setState({
          uploadStatus: "",
          upload_error: ''
        });
        return true;
      }
    });
  }

  checkSpaceFolder = (file) => {
    let return_check = false;
    let parent = this;
    let vray_exist = false;
    let high_exist = false;
    let max_high_id = false;
    let new_zip = new JSZip();
    return new_zip.loadAsync(file)
    .then((zip) => {
      for(let key in zip['files']){
        if (key.includes('/High')) {
          parent.setState({
            uploadStatus: "Incorrect folder structure! Correct your folder structure and reupload file.",
            upload_error: 'error'
          });
          return_check = false;
        } else if (key.includes('high/')){
          parent.setState({
            uploadStatus: "Incorrect naming! Correct your folder naming and reupload file.",
            upload_error: 'error'
          });
          return_check = false;
        }
        else if (key.includes('High/')){
          high_exist = true;
          let max_id = key.split('/')[1];
          if (max_id == (parent.state.room_id + '.max')) {
              max_high_id = true;
            }
        }
        
        if (key.includes('/VrayTextures')) {
          parent.setState({
            uploadStatus: "Incorrect folder structure! Correct your folder structure and reupload file.",
            upload_error: 'error'
          });
          return_check = false;
        }  else if (key.includes('VrayTextures/')){
          vray_exist = true;
        }
      }
      if (high_exist && !vray_exist) {
        parent.setState({
          uploadStatus: "Incorrect folder structure! VrayTextures folder is missing. Correct your folder structure and reupload file.",
          upload_error: 'error'
        });
        return_check = false;

      } else if (high_exist && vray_exist) {
        parent.setState({
          uploadStatus: "",
          upload_error: ''
        });
        if (!max_high_id) {
          return_check = true;
          parent.setState({
            uploadStatus: "Invalid max file name. It must be named " + parent.state.room_id + ".max",
            upload_error: 'error'
          });
          return_check = false;
        }
        
      } else if (!high_exist && !vray_exist) {
        parent.setState({
          uploadStatus: "Incorrect folder structure! High and VRAY Folders Missing!",
          upload_error: 'error'
        });
        return_check = false;
      } else if (!high_exist && vray_exist) {
        parent.setState({
          uploadStatus: "Incorrect folder structure! High folder is missing. Correct your folder structure and reupload file.",
          upload_error: 'error'
        });
        return_check = false;
      }
      if (!return_check) {
        return false;
      } else {
        return true;
      }
    });
  }

  setUploadProgress = (progress) => {
    this.setState({
      upload_error: progress
    });
  }
  
  toggleSummary = () => {
    this.setState({
      toggle_summary: !this.state.toggle_summary
    });
  }

  render() {
    const handleFullscreenChange = (event) => {
      let elem = event.target;
      let isFullscreen = document.fullscreenElement === elem;
      this.setState({
        isFullScreen: isFullscreen
      });
    }

    var targetelement = document.getElementById("fullscreen-image");  
    if (targetelement != null) {
      targetelement.onfullscreenchange = handleFullscreenChange;
    }   

    return (
      <ComplaintDetailView
      superArtistComplaintPage={this.state.super_artist_complaint_page}
      rejectionDetails={this.state.rejection_history}
      complaint_time_log={this.state.complaint_time_log}
      assigned_to={this.state.assigned_to}
      checkJSONStructure={this.checkJSONStructure}
      upload_error={this.state.upload_error}
      setUploadProgress={this.setUploadProgress}
      setUploadStatus={this.setUploadStatus}
      checkSpaceFolder={this.checkSpaceFolder}
      approve_render={this.state.approve_render}
      selectForApproval={this.selectForApproval}
      selected={this.state.selected}
      sendBackOriginal={this.sendBackOriginal}
      openSendBackModal={this.openSendBackModal}
      cancelOriginalModal={this.cancelOriginalModal}
      send_back_original={this.state.send_back_original}
      complaint_id={this.props.match.params.id}
      references={this.state.references}
      downloadImagesArchive={this.downloadImagesArchive}
      loading_state={this.state.loading_state}
      type={this.state.type}
      product_name={this.state.product_name}
      scene_name={this.state.scene_name}
      isFullScreen={this.state.isFullScreen}
      change360FullScreen={this.change360FullScreen}
      unhappy_images={this.state.unhappy_images}
      selected_image={this.state.selected_image}
      scene_id={this.state.scene_id}
      replace_modal={this.state.replace_modal}
      changeSelectedAsset={this.changeSelectedAsset}
      customer_name={this.state.customer_name}
      status={this.state.status}
      unhappy_details={this.state.unhappy_details}
      openReplaceModal={this.openReplaceModal}
      cancelReplaceModal={this.cancelReplaceModal}
      productReducer={this.props.productReducer}
      flagged_images={this.state.flagged_images}
      show_more={this.state.show_more}
      showMore={this.showMore}
      showFlagged={this.showFlagged}
      setDiscardUploadTrue={this.setDiscardUploadTrue}
      downloadSiloFlaggedArchive={this.downloadSiloFlaggedArchive}
      handleImageChange={this.handleImageChange}
      uploaded_files={this.state.uploaded_files}
      handleSubmit={this.handleSubmit}
      normFile={this.normFile}
      validateFileUploads={this.validateFileUploads}
      lifestyle_renders={this.state.lifestyle_renders}
      three_sixties={this.state.three_sixties}
      downloadSceneImagesArchive={this.downloadSceneImagesArchive}
      downloadSceneFlaggedArchive={this.downloadSceneFlaggedArchive}
      replace_scene_modal={this.state.replace_scene_modal}
      upload_type={this.state.upload_type}
      uploadStatus={this.state.uploadStatus}
      product_guidelines={this.state.product_guidelines}
      replace_3dsmax={this.state.replace_3dsmax}
      cancelReplace3dsMaxModal={this.cancelReplace3dsMaxModal}
      replace3dsMaxModal={this.replace3dsMaxModal}
      flagged_bool_check={this.state.flagged_bool_check}
      toggleShowMore={this.toggleShowMore}
      config_data={this.state.config_data}
      show3dsDownload={this.show3dsDownload}
      cancel3dsDownload={this.cancel3dsDownload}
      scene_3ds_download={this.state.scene_3ds_download}
      handleSubmit3dsMax={this.handleSubmit3dsMax}
      handleSubmitSpaceBundle={this.handleSubmitSpaceBundle}
      button_loader={this.state.button_loader}
      cancelSpaceBundleModal={this.cancelSpaceBundleModal}
      space_bundle_modal={this.state.space_bundle_modal}
      openSpaceBundleModal={this.openSpaceBundleModal}
      success_modal={this.state.success_modal}
      defaultFileList={this.state.defaultFileList}
      jsonFileList={this.state.jsonFileList}
      closeSuccessModal={this.closeSuccessModal}
      issue_resolve_modal={this.state.issue_resolve_modal}
      mark_resolve_button={this.state.mark_resolve_button}
      cancelResolveSuccessModal={this.cancelResolveSuccessModal}
      status_text={this.state.status_text}
      markAsResolve={this.markAsResolve}
      submit_json_success={this.state.submit_json_success}
      submit_bundle_success={this.state.submit_bundle_success}
      uploading={this.state.uploading}
      removeFile={this.removeFile}
      uploading_progress={this.state.uploading_progress}
      changeUploadStatus={this.changeUploadStatus}
      getFileStatus={this.getFileStatus}
      getConfigFileStatus={this.getConfigFileStatus}
      changeConfigUploadStatus={this.changeConfigUploadStatus}
      config_progress={this.state.config_progress}
      json_uploading={this.state.json_uploading}
      config_uploading={this.state.config_uploading}
      silo_data={this.state.silo_data}
      getBundleFileStatus={this.getBundleFileStatus}
      changeBundleUploadStatus={this.changeBundleUploadStatus}
      bundle_progress={this.state.bundle_progress}
      bundle_uploading={this.state.bundle_uploading}
      getFullScreenTour={this.getFullScreenTour}
      enable_upload_button={this.state.enable_upload_button}
      config_exist={this.state.config_exist}
      onChangeSpaceSuccessOption={this.onChangeSpaceSuccessOption}
      space_success_option={this.state.space_success_option}
      compare_state={this.state.compare_state}
      toggleCompareButton={this.toggleCompareButton}
      cancelRenderReadyModal={this.cancelRenderReadyModal}
      render_ready_email={this.state.render_ready_email}
      upload_validation_status={this.state.upload_validation_status}
      downloadCompleteBundle={this.downloadCompleteBundle}
      downloadConfigurationBundle={this.downloadConfigurationBundle}
      download_loader={this.state.download_loader}
      download_complete_loader={this.state.download_complete_loader}
      cancelDownloadWaitModal={this.cancelDownloadWaitModal}
      download_wait_modal={this.state.download_wait_modal}
      room_max_archive={this.state.room_max_archive}
      selected_renders={this.state.selected_renders}
      selectRenders={this.selectRenders}
      cancelGenerateNewRenders={this.cancelGenerateNewRenders}
      generate_new_renders={this.state.generate_new_renders}
      requestRenders={this.requestRenders}
      replace_json_modal={this.state.replace_json_modal}
      handleSubmitJSON={this.handleSubmitJSON}
      cancelJSONModal={this.cancelJSONModal}
      replaceJSONModal={this.replaceJSONModal}
      payload_3ds={this.state.payload_3ds}
      config_3ds= {this.state.config_3ds}
      cancelRejectFlag={this.cancelRejectFlag}
      openRejectFlag={this.openRejectFlag}
      reject_flag_modal={this.state.reject_flag_modal}
      rejectFlag={this.rejectFlag}
      handleJSONFileChange={this.handleJSONFileChange}
      complaint_360={this.state.complaint_360}
      complaint_lifestyle={this.state.complaint_lifestyle}
      changeSelectedRender={this.changeSelectedRender}
      approveRender={this.approveRender}
      enable_resolve_btn={this.state.enable_resolve_btn}
      submit_scene_success={this.state.submit_scene_success}
      handleMaxChange={this.handleMaxChange}
      checkSceneFolderStructure={this.checkSceneFolderStructure}
      room_id={this.state.room_id}
      generated_renders={this.state.generated_renders}
      discard_uploads={this.state.discard_uploads}
      openRequestRenderModal={this.openRequestRenderModal}
      backToUpload={this.backToUpload}
      discardUploads={this.discardUploads}
      all_uploaded={this.state.all_uploaded}
      cancelRenderRequestModal={this.cancelRenderRequestModal}
      render_request_success={this.state.render_request_success}
      new_renders={this.state.new_renders}
      bundlefileList={this.state.bundlefileList}
      validation_report={this.state.validation_report}
      validation_generated={this.state.validation_generated}
      automatically_fixed_high={this.state.automatically_fixed_high}
      successfully_validated_high={this.state.successfully_validated_high}
      invalid_data_high={this.state.invalid_data_high}
      success_val_length={this.state.success_val_length}
      invalid_val_length={this.state.invalid_val_length}
      auto_val_length={this.state.auto_val_length}
      validation_check={this.state.validation_check}
      reupload_flag={this.state.reupload_flag}
      space_valid={this.state.space_valid}
      space_validation={this.state.space_validation}
      toggle_summary={this.state.toggle_summary}
      toggleSummary={this.toggleSummary}
      bundle_upload_status={this.state.bundle_upload_status}
      setBundleUploadStatus={this.setBundleUploadStatus}
      json_upload_status={this.state.json_upload_status}
      destroy_modal={this.state.destroy_modal}
      help_button={this.state.help_button}
      openHelp={this.openHelp}
      original_exist={this.state.original_exist}
      approve_original={this.state.approve_original}
      original_renders={this.state.original_renders} 
      useOriginalRender={this.useOriginalRender}
      enable_request_render={this.state.enable_request_render}
      cancelHelp={this.cancelHelp}
      reject_flag_success={this.state.reject_flag_success}
      cancelRejectModal={this.cancelRejectModal}
      selected_format={this.state.selected_format}
      checkImageFormat={this.checkImageFormat}
      upload_failure_message={this.state.upload_failure_message}
      scene_configurations={this.state.scene_configurations}
      upload_bundle_failure_message={this.state.upload_bundle_failure_message}
      downloadCryptoImagesArchive={this.downloadCryptoImagesArchive}
      crypto_exists={this.state.crypto_exists}
      use_default_sun={this.state.use_default_sun}
      setDefaultSunSettings={this.setDefaultSunSettings}
      platform = {this.state.platform}
      file_copied = {this.state.file_copied}
      uploaded_text = {this.state.uploaded_text}
      company_id={this.state.company_id}
      room_platform={this.state.room_platform}
      />
    );
  }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplaintDetail)))