import React from 'react';
import 'antd/dist/antd.css';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import CustomerMainLayout from '../CustomerMainLayout'


class CreateRoomForm extends React.Component {

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(this.props.match.params.id){
                    console.log('edit case');
                }
                else
                {
                    console.log('add case');
                }
            }
        });
    };

    componentDidMount = () => {
        if((this.props.match.params.id))
        {
            console.log('update case');
        }
        else
        {
            console.log('add new case');
        }
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <CustomerMainLayout selected='2'>
            </CustomerMainLayout>
        );
    }
}

const CreateRoom = Form.create({ name: 'room_add' })(CreateRoomForm);

export default CreateRoom;
