import React from 'react';
import { Card, Row, Col, Radio, Popover, Upload, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import * as Constants from '../../CustomerComponents/Constants';
import * as Styles from '../../../../Styles';
import { UploadOutlined } from '@ant-design/icons';

const ColorSelection = (props) => {

    return(
    <Row style={{marginTop: 20,width: "100%",padding: "0 6px"}}>
        <Col span={24}>
            <h1 className="manrope f-14 black-55 w-600 justify-in-start" style={{marginBottom: 5}}>{props.mandatory && <span className="manrope f-18 red-ff w-600">*&nbsp;</span>}Select your color type and provide the relevant information</h1>

            <Radio.Group value={props.colorSelectionType}  onChange={props.onChangeColorSelectionType} className={`justify-align-start`} style={{marginBottom: 10,flexWrap:"unset"}}>
               
                
             
                <Radio style={{marginTop: 12,width: "100%",whiteSpace:"break-spaces"}} value={2}  className={`${props.colorSelectionType == 2 ? `model-type-radio black-border`: `model-type-radio grey-border`}`}>
                   {props.colorSelectionType == 2 && <span className='manrope f-14 red-ff w-600'>*&nbsp;</span>}

                    <span className="manrope f-14 black-55" >
                        Add Color Photos
                        <div style={{width: "100%",marginTop: 12}} className={props.colorFileList.length == 0 ? "bedding-card center" : 'bedding-card'}>
                            <Form.Item
                            style={{ display: props.colorFileList && props.colorFileList.length == 1 ? 'content' : 'flex'}}
                            colon={false}
                            label={''}
                            className="picture-card-custom-88"
                            name="color_photos"
                            rules={[{ required: false, message: 'You must upload an image.' }]}>
                                <Upload
                                    className={"upload-picture-card-88"}
                                    accept=".jpg,.jpeg,.png"
                                    multiple={true}
                                    onChange = {props.handleChangeColorSelection}
                                    fileList = {props.colorFileList}
                                    progress= {Styles.progress_bar}
                                    {...(Constants.getUploadProps())}
                                    onRemove={file => {
                                        const index = props.colorFileList.indexOf(file);
                                        const newFileList = props.colorFileList.slice();
                                        newFileList.splice(index, 1);
                                        props.setColorFileList(newFileList);                                        
                                        return true;
                                    }}
                                    listType={"picture-card"}>
                                        {Utilities.validateFileUploads(props.colorFileList) == "done" || Utilities.validateFileUploads(props.colorFileList) == "uploading" ? 
                                        <div className="manrope f-12 blue w-600">
                                            <UploadOutlined style={{marginBottom: 2}}/>
                                            <div>Upload</div>
                                            <div className="manrope f-12 grey-99 italic">(jpg,png)</div>
                                        </div> : 
                                        Utilities.validateFileUploads(props.colorFileList) == "error" ? 
                                        <span>
                                            <div className="justify-in-center">
                                                <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                            </div>                        
                                        </span> :
                                        (Utilities.validateFileUploads(props.colorFileList) == "not_started" ? 
                                            <div className="manrope f-12 blue w-600">
                                                <UploadOutlined style={{marginBottom: 2}}/>
                                                <div>Upload</div>
                                                <div className="manrope f-12 grey-99 italic">(jpg,png)</div>
                                            </div>: '')}
                                </Upload>
                            </Form.Item>
                        </div>
                    </span>
                </Radio>
                <Radio style={{marginTop: 12,width: "100%",whiteSpace:"break-spaces"}} value={0} className={`${props.colorSelectionType == 0 ? `model-type-radio black-border`: `model-type-radio grey-border`}`}>
                    <span className="manrope f-14 black-55" >
                       Hex Color
                       <div style={{width: "100%",marginTop: 12}}>
                            <Form.Item className='remove-antd-margin' colon={false} label={''} name="hex_color">
                                <Input onChange={props.changeHexColor} type="color" value={props.hexColor}
                                style={{padding: '0 2px'}}
                                placeholder={'Enter Hex Color'} className="manrope f-12 black-55 library-search"/>
                            </Form.Item>
                        </div>
                    </span>
                </Radio>
                <Radio style={{marginTop: 12,width: "100%",whiteSpace:"break-spaces"}} value={1} className={`${props.colorSelectionType == 1 ? `model-type-radio black-border`: `model-type-radio grey-border`}`}>
                    <span className="manrope f-14 black-55">
                        Pantone Color
                        <div style={{width: "100%",marginTop: 12}}>
                            <Form.Item className='remove-antd-margin' colon={false} label='' name="pantone_color">
                                <Input onChange={props.changePantoneColor} placeholder={'Enter Pantone Color'} className="manrope f-12 black-55 library-search"/>   
                            </Form.Item>
                        </div>
                    </span>
                </Radio>
                
            </Radio.Group>
        </Col>
    </Row>);
}

export default ColorSelection;
