import * as THREE from "three"; 
import GridBoxGeometry from "./GridBoxGeometry.js" 
import Constants from "./SceneCreator/Constants.js" 
import {ConvexGeometry} from "./ConvexGeometry"; 
import {getNormalForIntersect} from "./SceneCreator/HelperFunctions.js" 
 
export default class GridViewManager { 
 
	raycastManager  = null; 
	spaceManager 	= null; 
	LFAAreaManager  = null; 
	sceneCreator = null; 
	objectGrids = null; 
	ceilingGrids = null; 
	tileSize = 0; 
	distanceUnit = "meters"; 
	color = 0xffffff; 
	lineWidth = 2; 
	enableGrid = false; 
	enableCeiling = false; 
	unitOffset = { 
		"meters" : 1, 
		"feet"	: 3.2,
		"inches": 39.3701
	} 
	spaceCenter = null; 
	spaceBbox = null; 
	spaceSize = null; 
	fixNormal = true;
 
	/*** 
	 * Grid View Manager allows you to manager grids on walls, floors and ceilings in the scene 
	 * @param {SceneCreator} sceneCreator - The main scene creator instances 
	 * @param {Object} managersDict - A dictionary that contains all the available managers. 
	 */ 
 
	constructor ( managersDict, fixNormal ) { 
		this.setupRequiredManagers(managersDict); 
		this.objectGrids = {}; 
		this.ceilingGrids = {}; 
		this.spaceBbox = new THREE.Box3().setFromObject(this.spaceManager.scene); 
		this.spaceCenter = this.spaceBbox.getCenter(new THREE.Vector3()); 
		this.spaceSize = this.spaceBbox.getSize(new THREE.Vector3()); 
		this.fixNormal = fixNormal;
	} 
 
	/** 
	 *  
	 * @param {Object} managersDict - A dictionary that contains all the available managers. 
	 */ 
	setupRequiredManagers(managersDict) { 
		this.raycastManager = managersDict[Constants.Manager.RaycastManager]; 
		this.spaceManager = managersDict[Constants.Manager.SpaceManager]; 
		this.LFAAreaManager = managersDict[Constants.Manager.LFAAreaManager]; 
	} 
 
	/** 
     * Setup space grid view to add grids to floors, walls and ceilings 
     */ 
	setupGridView(enableGrid, enableCeiling, size, color, lineWidth, unit) { 
 
		if (!enableGrid && this.isGridShowStateUpdated(enableGrid)) { 
			this.hideGridsOnObjects(); 
			this.hideGridsOnCeilings(); 
		} 
		else if (enableGrid) { 
			if (this.isGridSettingUpdated(size, unit, color, lineWidth)) { 
				this.setGridSettings(enableGrid, enableCeiling, size, color, unit, lineWidth); 
				this.placeGrids(); 
			} 
			else if (this.isGridShowStateUpdated(enableGrid)) { 
				this.showGridsOnObjects(); 
				if (enableCeiling && this.isCeilingGridShowStateUpdated(enableCeiling)) { 
					this.showGridsOnCeilings(); 
				} 
				this.setGridSettings(enableGrid, enableCeiling, size, color, unit, lineWidth); 
			} 
		} 
	} 
	 
	/*** 
	 * @param {boolean} enableGrid - the flag to set grids state 
	 * @param {boolean} enableCeiling - the flag to set ceiling grids state 
	 * @param {number} size - the size of grid tile to be set 
	 * @param {string} color - the grid color to be set 
	 * @param {string} unit - the unit of grid tile to be set 
	 * @param {number} lineWidth  - the lineWidth to be set 
	 */ 
	setGridSettings (enableGrid, enableCeiling, size, color, unit, lineWidth) { 
		this.enableCeiling = enableCeiling 
		this.color = color; 
		this.tileSize = size; 
		this.distanceUnit = unit; 
		this.enableGrid = enableGrid; 
		this.lineWidth = lineWidth; 
	} 
	 
	/** 
	 * Place the grids on walls, floors, ceilings 
	 */ 
	placeGrids() { 
		for (let area in this.spaceManager.areas) { 
			let areaCenter = new THREE.Box3().setFromObject(this.spaceManager.areas[area].root).getCenter(new THREE.Vector3()); 
			for (let wall in this.spaceManager.areas[area].walls) { 
				if ( this.spaceManager.areas[area].walls[wall] != null ) { 
					this.addGridToWall(this.spaceManager.areas[area].walls[wall], areaCenter); 
				} 
			}	 
		} 
		this.addGridToCompleteFloor(); 
		this.addGridToCompleteCeiling(); 
	} 
 
	/** 
	 *  
	 * @param {number} size  - the new tile size of the grid box 
	 * @param {string} unit  - the new units for the grids 
	 * @param {string} color - the new color for the grids 
	 * @param {number} lineWidth  - the new line thickness for the grids 
	 */ 
	isGridSettingUpdated(size, unit, color, lineWidth) { 
		return (size != this.tileSize || unit != this.distanceUnit ||  color != this.color || lineWidth != this.lineWidth); 
	} 
 
	/** 
	 *  
	 * @param {boolean} newState  - the show/no show state for the grids 
	 */ 
	isGridShowStateUpdated (newState) { 
		return this.enableGrid != newState; 
	} 
 
	/** 
	 * @param {boolean} newState  - the show/no show state for the ceiling grids 
	 */ 
	isCeilingGridShowStateUpdated (newState) { 
		return this.enableCeiling != newState; 
	} 
 
	/*** 
	 * @param {boolean} enableGrid - the flag to set grids state 
	 */ 
	setEnabledState(enableGrid) { 
		this.enableGrid = enableGrid; 
	} 
 
	/*** 
	 * getter for grid state 
	 */ 
	getEnabledState() { 
		return this.enableGrid 
	} 
	 
	/** 
	 *  
	 * @param {THREE.Mesh} wallMesh - wall object on which the grid is to be added 
	 * @param {THREE.Vector3} areaCenter - center of the area to which the wall object belongs 
	 */ 
	addGridToWall(wallMesh, areaCenter) { 
		let xthicknessOffset = 0.001; 
		let zthicknessOffset = 0.001; 
        let bbox = new THREE.Box3().setFromObject(wallMesh); 
        let size = bbox.getSize(new THREE.Vector3()); 
        let center = bbox.getCenter(new THREE.Vector3()); 
		let extractNormal = true; 
		let targetObjects = [wallMesh]; 
		let directionVector = new THREE.Vector3().subVectors(center, areaCenter).normalize(); 
		let width = Math.max(size.x, size.z, 1); 
        let gridGeometry = new GridBoxGeometry(width, size.y, 0 , (width * this.unitOffset[this.distanceUnit]) / this.tileSize, (size.y * this.unitOffset[this.distanceUnit]) / this.tileSize, 0); 
		let grid = null; 
		let obj = this.objectGrids[wallMesh.name]; 
		if (obj) { 
			obj.remove(...obj.children); 
			let limit = Math.ceil((this.lineWidth) + 15 * (this.lineWidth - 1)); 
			for (let i = 0; i < limit; i++) { 
				grid = new THREE.LineSegments(gridGeometry, new THREE.LineBasicMaterial({ 
					color: this.color , linewidth: 1 
				})); 
				grid.position.x += xthicknessOffset * i; 
				grid.position.z += zthicknessOffset * i; 
				grid.userData.isGrid = true;
				obj.add(grid); 
			}; 
			obj.visible = true; 
			 
		} 
		else { 
			let sourceVector = areaCenter.clone(); 
			sourceVector.set(sourceVector.x, center.y, sourceVector.z); 
			let results = this.raycastManager.setAndIntersect( sourceVector, directionVector, targetObjects ); 
			if (!results) { 
				const geometry = new ConvexGeometry( wallMesh ); 
				const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } ); 
				const convexMesh = new THREE.Mesh( geometry, material ); 
				targetObjects = [convexMesh]; 
				results = this.raycastManager.setAndIntersect( sourceVector, directionVector, targetObjects ) 
				extractNormal = false; 
			} 
			if (results) { 
				let normalVector = null; 
				if (extractNormal == true) { 
					normalVector = getNormalForIntersect(results, this.LFAAreaManager, this.fixNormal).clone(); 
				} 
				else { 
					normalVector = results.face.normal.clone(); 
				} 
				let targetVector = results.point.clone().add(normalVector); 
				obj = new THREE.Object3D(); 
				let limit = Math.ceil((this.lineWidth) + 15 * (this.lineWidth - 1)); 
				for (let i = 0; i < limit; i++) { 
					grid = new THREE.LineSegments(gridGeometry, new THREE.LineBasicMaterial({ 
						color: this.color , linewidth: 1 
					})); 
			 
					grid.position.x += xthicknessOffset * i; 
					grid.position.z += zthicknessOffset * i; 
					grid.userData.isGrid = true;
					obj.add(grid); 
				}; 
				obj.position.copy(results.point.clone()); 
				obj.lookAt(targetVector); 
				this.spaceManager.scene.add(obj); 
				this.objectGrids[wallMesh.name] = obj; 
				obj.visible = true;    
				directionVector = new THREE.Vector3().subVectors(center.clone(), targetVector.clone()).normalize(); 
				results = this.raycastManager.setAndIntersect(targetVector, directionVector, targetObjects); 
				if (results) { 
					obj.position.copy(results.point); 
				}	 
			}  
		} 
	} 
 
	 
	/** 
	 * Add Grid to floor plane of the scene 
	 */ 
    addGridToCompleteFloor() { 
		let xthicknessOffset = 0.001; 
		let zthicknessOffset = 0.001; 
		let bbox = this.spaceBbox; 
		let size = this.spaceSize; 
		let areaCenter = this.spaceCenter.clone(); 
		var gridGeometry = new GridBoxGeometry( size.x * 1.1, 0,  size.z * 1.1,  (size.x * 1.1 * this.unitOffset[this.distanceUnit]) / this.tileSize , 0,  (size.z * 1.1 * this.unitOffset[this.distanceUnit])/this.tileSize); 
		var grid = null; 
		let obj = this.objectGrids["floor"] ; 
		if (obj != undefined && obj != null) { 
			obj.remove(...obj.children); 
			let limit = Math.ceil((this.lineWidth) + 15 * (this.lineWidth - 1)); 
			for (let i = 0; i < limit; i++) { 
				grid = new THREE.LineSegments(gridGeometry, new THREE.LineBasicMaterial({ 
					color: this.color , linewidth: 1 
				})); 
				grid.position.x += xthicknessOffset * i; 
				grid.position.z += zthicknessOffset * i; 
				grid.userData.isGrid = true;
				obj.add(grid); 
			} 
			obj.visible = true; 
		} 
		else { 
			let directionVector = new THREE.Vector3(0,-1,0); 
			let results = this.raycastManager.setAndIntersect(areaCenter, directionVector, this.spaceManager.floors); 
			let yOffset = bbox.max.y; 
			if (results) { 
				yOffset = results.point.y; 
			} 
			obj = new THREE.Object3D(); 
			let limit = Math.ceil((this.lineWidth) + 15 * (this.lineWidth - 1)); 
			for (let i = 0; i < limit; i++) { 
				grid = new THREE.LineSegments(gridGeometry, new THREE.LineBasicMaterial({ 
					color: this.color , linewidth: 1 
				})); 
		 
				grid.position.x += xthicknessOffset * i; 
				grid.position.z += zthicknessOffset * i; 
				grid.userData.isGrid = true;
				obj.add(grid); 
			} 
			obj.position.copy(areaCenter); 
			obj.position.y = yOffset; 
			this.spaceManager.scene.add(obj); 
			this.objectGrids["floor"] = obj; 
			obj.visible = true; 
		} 
	} 
	 
	/** 
	 * Add Grid to floor plane of the scene 
	 */ 
    addGridToCompleteCeiling() { 
		let xthicknessOffset = 0.001; 
		let zthicknessOffset = 0.001; 
		let bbox = this.spaceBbox; 
		let size = this.spaceSize; 
		let areaCenter = this.spaceCenter.clone(); 
		var gridGeometry = new GridBoxGeometry( size.x * 1.1, 0,  size.z * 1.1,  (size.x * 1.1 * this.unitOffset[this.distanceUnit]) / this.tileSize , 0,  (size.z * 1.1 * this.unitOffset[this.distanceUnit])/this.tileSize); 
		var grid = null; 
		let obj = this.ceilingGrids["ceiling"] ; 
		if (obj != undefined && obj != null) { 
			obj.remove(...obj.children); 
			let limit = Math.ceil((this.lineWidth) + 15 * (this.lineWidth - 1)); 
			for (let i = 0; i < limit; i++) { 
				grid = new THREE.LineSegments(gridGeometry, new THREE.LineBasicMaterial({ 
					color: this.color , linewidth: 1 
				})); 
		 
				grid.position.x += xthicknessOffset * i; 
				grid.position.z += zthicknessOffset * i; 
				grid.userData.isGrid = true;
				obj.add(grid); 
			} 
			if (this.enableCeiling) { 
				obj.visible = true; 
			} 
			else { 
				obj.visible = false; 
			} 
		} 
		else { 
			let directionVector = new THREE.Vector3(0,1,0); 
			let results = this.raycastManager.setAndIntersect(areaCenter, directionVector, this.spaceManager.ceilings); 
			let yOffset = bbox.min.y; 
			if (results ) { 
				yOffset = results.point.y; 
			} 
			obj = new THREE.Object3D(); 
			let limit = Math.ceil((this.lineWidth) + 15 * (this.lineWidth - 1)); 
			for (let i = 0; i < limit; i++) { 
				grid = new THREE.LineSegments(gridGeometry, new THREE.LineBasicMaterial({ 
					color: this.color , linewidth: 1 
				})); 
		 
				grid.position.x += xthicknessOffset * i; 
				grid.position.z += zthicknessOffset * i; 
				grid.userData.isGrid = true;
				obj.add(grid); 
			} 
			obj.position.copy(areaCenter); 
			obj.position.y = yOffset; 
			this.spaceManager.scene.add(obj); 
			this.ceilingGrids["ceiling"] = obj; 
			if (this.enableCeiling) { 
				obj.visible = true; 
			} 
			else { 
				obj.visible = false; 
			}		         
		}	 
    } 
 
	/** 
	 * Hide the grids on floors/walls in the scene 
	 */ 
    hideGridsOnObjects() { 
		if (this.enableGrid) { 
			for (var i in this.objectGrids) { 
				this.objectGrids[i].visible = false; 
			} 
			this.enableGrid = false; 
		} 
    } 
 
	/** 
	 * Make the grids on floors/walls visible in the scene 
	 */ 
    showGridsOnObjects() { 
		if (!this.enableGrid) { 
			this.enableGridsInScene(); 
		} 
	} 
 
	/** 
	 * Make grids on floors/walls visible in the scene 
	 */ 
	enableGridsInScene() { 
		for (var i in this.objectGrids) { 
			this.objectGrids[i].visible = true; 
		} 
		this.enableGrid = true; 
	} 
	 
	/** 
	 * Hide the grids on ceilings in the scene in 2D view when ceilings are also invisible 
	 */ 
	hideGridsOnCeilings() { 
		if (this.enableCeiling) { 
			for (var i in this.ceilingGrids) { 
				this.ceilingGrids[i].visible = false; 
			} 
			this.enableCeiling = false; 
		} 
    } 
 
	/** 
	 * Make the grids on ceiling visible in the scene in the 3D view 
	 */ 
    showGridsOnCeilings() { 
		if (this.enableGrid && !this.enableCeiling) { 
			this.enableCeilingGridsInScene(); 
		} 
	} 
 
	/** 
	 * Make grids on Ceilings visible in the scene 
	 */ 
	enableCeilingGridsInScene() { 
		for (var i in this.ceilingGrids) { 
			this.ceilingGrids[i].visible = true; 
		} 
		this.enableCeiling = true; 
	} 
	 
} 
