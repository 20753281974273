import React, { useState, useEffect, useRef} from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Card, Checkbox, Menu, Dropdown, Button, Tooltip, Form, Empty, Modal, Input } from 'antd';
import '@ant-design/compatible/assets/index.css';
import '../../../../styles/helper.scss'
import {Link} from 'react-router-dom'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import ENVIRONMENT from '../../../../environments';
import * as Utilities from '../../Utilities'
import * as Styles from '../../../../Styles'
import FileConstants from '../../../../FileConstants';
import _ from "lodash";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";
import { reorderData } from '../../../../redux/actions';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, AutoSizer } from 'react-virtualized';
import axios from 'axios';
import AddEntityToProject from '../AddEntityToProject/AddEntityToProject';
import LibraryGrid from '../LibraryGrid/LibraryGrid';
import SuccessModal from '../../SuccessModal/SuccessModal';

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem('username');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const IS_MS_PROVIDER = FileConstants.isMSProvider;


let get_folder_options = (
    renameFolderOnclick,
    unGroupFolderItemsOnclick,
    ele
    ) => {
    return (
        <Menu>
        <Menu.Item>
            <a className="manrope f-14" onClick={() => renameFolderOnclick(ele)}>
            Rename Folder
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
            className="manrope f-14"
            onClick={() => unGroupFolderItemsOnclick(ele)}
            >
            Ungroup Items
            </a>
        </Menu.Item>
        </Menu>
    );
};

const RoomsList = (props) => {
  const [items, setItems] = useState([]);
  const [entityArrangement, setEntityArrangement] = useState(null);
  const [folderArrangement, setFolderArrangement] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [columnCount, setColumnCount] = useState(4);
  const [rowCount, setRowCount] = useState(1);
  const [data, setData] = useState([]);
  const previousRoomsLength = useRef(null);
  const maxColumnWidth = 280;
  const rowSize = 395;


    // column widths are stored in a state by index
    const [columnWidths, setColumnWidths] = useState(() => {
        const containerWidth = window.innerWidth - 80; // Get the width of the container element
        const columnWidthPercentage = 25; // Set the desired column width percentage

        const columnWidth = ((containerWidth * columnWidthPercentage) / 100) - 38;
        const initialWidths = new Array(columnCount).fill(columnWidth); // Set initial column widths here
        return initialWidths;
    });

    // function to handle column width changes
    const handleColumnWidthChange = ({ index, width }) => {
        setColumnWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width;
            return newWidths;
        });
    };


    const getColumnWidth = ({ index }) => columnWidths[index];

    const returnCreateRoomLink = () => 
    {
        props.history.push('/add-new-room?origin=/rooms')
    }

    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const itemIndex = (rowIndex * columnCount) + columnIndex;
        const ele = data[itemIndex] && data[itemIndex];
        
        if (!ele) {
            return null;
        }
        
        return (
            <div key={key} style={style}>
                <RoomItems
            key={itemIndex}
            space_type={props.space_type}
            searchValue={props.searchValue}
            rooms={props.rooms}
            folderState={props.folderState}
            enterFolderState={props.enterFolderState}
            onAddCollectionClick={props.onAddCollectionClick}
            sellOnStore={props.sellOnStore}
            ele={ele}
            groupedRooms={props.groupedRooms}
            managed_customer={props.managed_customer}
            link={`${props.link}${ele.room_id}`}
            selectable={props.selectable}
            selectedRoomIds={props.selectedRoomIds}
            setSelectedRoomIds={props.setSelectedRoomIds}
            setCurrentId={props.setCurrentId}
            setCompanyID={props.setCompanyID}
            setCurrentCustomer={props.setCurrentCustomer}
            removeItemFromStore={props.removeItemFromStore}
            reorderItems={props.reorderItems}
            setSelectedFolderRooms={props.setSelectedFolderRooms}
            selectedFolderRooms={props.selectedFolderRooms}
            folderArrangement={props.folderArrangement}
            renameFolderOnclick={props.renameFolderOnclick}
            unGroupFolderItemsOnclick={props.unGroupFolderItemsOnclick}
            setDeleteModal={props.setDeleteModal}
            projects={props.projects}
            />
            </div>
            )
    }
  useEffect(()=>{
    if (!props.saveOrderedItems) {
      populateArrangementInfo();
    }
    },[props.rooms])

  useEffect(() => {
    let roomsData = [];

    if (props.searchValue == '' && !props.filtersApplied) {
        roomsData = [...props.rooms];
        if (!props.folderState && roomsData.length !== data.length) {
          props.updateTabCount(-1, props.tabKey)
        }
    } 
    else if (props.searchValue != '' || props.filtersApplied) {
        roomsData = props.checkFilteredResults(props.rooms);
        
        if (!props.folderState && roomsData.length == 0 && props.filtersApplied && props.searchValue == "" && props.tabChangeThroughClick == false) {
          props.switchTabIfEmpty();
        } 
        if (!props.folderState && roomsData.length > 0) {
          props.setTabChangeThroughClick(false);
        }
        const newRoomListLength = roomsData.length;
        // Call updateTabCount if new length is different or new length is 0 and previous length is not 0
        if(!props.folderState) {
            if (newRoomListLength !== previousRoomsLength.current) {
              props.updateTabCount(newRoomListLength, props.tabKey); 
            }
        }
    }
    // Update the ref after the check
    previousRoomsLength.current = roomsData.length;
    setData(roomsData);
    setRowCount(Math.ceil(roomsData?.length / columnCount));
  },[props.searchValue, props.filtersApplied, props.rooms, props.filtersAppliedFlag])



    const populateArrangementInfo = () => {
        const entityArr = _.cloneDeep(props.entityArrangement);
        const folderArr = _.cloneDeep(props.folderArrangement);
        setEntityArrangement(entityArr);
        setFolderArrangement(folderArr);
        props.setHashUpdated(false);
        forceUpdate();
    }

    useEffect(()=>{
        if (props.hashUpdated && !props.saveOrderedItems) {
            populateArrangementInfo();
        }
    },[props.hashUpdated, props.reorderItems])

    useEffect(() => {
        if (props.rooms) {
            setItems(props.rooms);
        }
    }, [props.rooms]);

  //called when user presses back button to save rearrangement
  const updateEntitiesArrangement = () => {
    let new_entity_arrangement = [];
    let new_folder_arrangement = [];
    let arrangement_info = {};
    let folder_arrangement_info = {};
    items.map((item, index) => {
        if (Array.isArray(item)) {
            folder_arrangement_info = {
                folder_id: parseInt(item[0].folder_id),
                ordering_number: index,
                folder_name: item[0].folder_name,
                sequence_id: props.roomTab == "owned" ? props.SEQUENCE_ID_OWNED : props.SEQUENCE_ID_OTHER
            }
            new_folder_arrangement.push(folder_arrangement_info);
            item.map((folder_item, folder_index) => {
                arrangement_info = {
                    entity_id: parseInt(folder_item.room_id),
                    ordering_number: folder_index,
                    parent_folder_id: parseInt(folder_item.folder_id),
                    sequence_id: props.roomTab == "owned" ? props.SEQUENCE_ID_OWNED : props.SEQUENCE_ID_OTHER
                }
                new_entity_arrangement.push(arrangement_info);
            })
        } else {
            arrangement_info = {
                entity_id: parseInt(item.room_id),
                ordering_number: index,
                parent_folder_id: item.folder_id,
                sequence_id: props.roomTab == "owned" ? props.SEQUENCE_ID_OWNED : props.SEQUENCE_ID_OTHER
            }
            new_entity_arrangement.push(arrangement_info);
        }
    })

    setEntityArrangement(new_entity_arrangement);
    setFolderArrangement(new_folder_arrangement);
    console.log(new_entity_arrangement,new_folder_arrangement)
  };

  useEffect(() => {
    if (props.saveOrderedItems && (entityArrangement!==null  || folderArrangement !== null) && 
        ((props.tabKey && props.tabKey == props.tabActiveKey) || props.tabKey === undefined) ) {
        props.setSaveOrderedItems(false);
        props.reorderArrangementData(entityArrangement,folderArrangement);
        props.setOrderUpdated(false);
    }
  }, [props.saveOrderedItems, entityArrangement, folderArrangement]);

  useEffect(() => {
    if (items.length > 0 && entityArrangement && !props.saveOrderedItems && orderUpdated ) {
        updateEntitiesArrangement();
        setOrderUpdated(false);
    }
  }, [orderUpdated]);
  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
    setOrderUpdated(true);
    if (oldIndex != newIndex) {
      props.setOrderUpdated(true);
    }
  };

  const getFolderName = (ele) => {
    if (Array.isArray(ele)) {
        return ele[0].folder_name;
    }
    return ele.folder_name;
    };

  if(!props.reorderItems){
    let roomsList = null;
    if(props.searchValue == '' && !props.filtersApplied){
        return (
            <>
            <LibraryGrid
                setColumnCount = {setColumnCount}
                setRowCount = {setRowCount}
                maxColumnWidth = {maxColumnWidth}
                data = {data}
                cellRenderer={cellRenderer}
                columnCount={columnCount}
                getColumnWidth={getColumnWidth}
                rowCount={rowCount}
                getEstimatedRowSize={rowSize}
                handleColumnWidthChange={handleColumnWidthChange}
            />
            </>
          );
    } else {
        if ((props.space_type == 'your_spaces' && props.ownedRoomLoader) ||
            (props.space_type == 'shared_models' && props.sharedRoomLoader)) {
                return <div className="justify-in-center" style={{width: '100%'}}>
                <p className="empty-section-text manrope f-16 black-55">
                    Please wait while we load all your spaces.
                    <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
                </p>
                </div>
        } else {
            return(
                <>
                  {
                    data.length > 0 ?
                    <LibraryGrid
                    setColumnCount = {setColumnCount}
                    setRowCount = {setRowCount}
                    maxColumnWidth = {maxColumnWidth}
                    data = {data}
                    cellRenderer={cellRenderer}
                    columnCount={columnCount}
                    getColumnWidth={getColumnWidth}
                    rowCount={rowCount}
                    getEstimatedRowSize={rowSize}
                    handleColumnWidthChange={handleColumnWidthChange}
                  /> :
                  <div className="no-models-div" style={{ marginTop: "25%"}}>
                      <Empty description={"No Models To Show"} className="manrope f-14 no-models-span" />
                  </div>
                  }
                    
                </>
            )
      }
    }
  return (<>{roomsList}</>)
  } else {
    return items && <SortableList 
    axis={'xy'} props={props} 
    items={items} onSortEnd={onSortEnd} 
    getContainer={() => document.getElementById('room-scrollable-'+props.space_type)}
     />
  }
};

const SortableList = SortableContainer(({ items, props }) => {
  return (
    <Row
    id={`room-scrollable-${props.space_type}`}
      className="product-tab-content-scrollable draggable-div"
      type="flex"
      style={{ justifyContent: "start" }}
    >
      {items &&
        items.map((item, index) =>
          props.searchValue === "" ? (
            <SortableListItem
              props={props}
              axis={"xy"}
              key={`item-${index}`}
              index={index}
              item={item}
              useWindowAsScrollContainer={true}
            />
          ) : Utilities.isMatching(
              props.searchValue.toLowerCase(),
              item.room_name.toLowerCase()
            ) ||
            Utilities.isMatching(
              props.searchValue.toLowerCase(),
              item.category.toLowerCase()
            ) ||
            Utilities.isMatching(
              props.searchValue.toLowerCase(),
              item.brand_id.toLowerCase()
            ) ||
            props.searchValue === item.room_id ? (
            <SortableListItem
              props={props}
              key={index}
              index={index}
              item={item}
              useWindowAsScrollContainer={true}
            />
          ) : (
            ""
          )
        )}
    </Row>
  );
});


const SortableListItem = SortableElement(({ item, index, props }) => {
  return (
    <RoomItems
      key={`item-${index}`}
      space_type={props.space_type}
      searchValue={props.searchValue}
      rooms={props.rooms}
      folderState={props.folderState}
      enterFolderState={props.enterFolderState}
      onAddCollectionClick={props.onAddCollectionClick}
      sellOnStore={props.sellOnStore}
      ele={item}
      link={`${props.link}${item.room_id}`}
      groupedRooms={props.groupedRooms}
      managed_customer={props.managed_customer}
      selectable={props.selectable}
      selectedRoomIds={props.selectedRoomIds}
      setSelectedRoomIds={props.setSelectedRoomIds}
      setCurrentId={props.setCurrentId}
      setCompanyID={props.setCompanyID}
      setCurrentCustomer={props.setCurrentCustomer}
      removeItemFromStore={props.removeItemFromStore}
      reorderItems={props.reorderItems}
      setSelectedFolderRooms={props.setSelectedFolderRooms}
      selectedFolderRooms={props.selectedFolderRooms}
      folderArrangement={props.folderArrangement}
      renameFolderOnclick={props.renameFolderOnclick}
      unGroupFolderItemsOnclick={props.unGroupFolderItemsOnclick}
      setDeleteModal={props.setDeleteModal}
      projects={props.projects}
    />
  );
});

const RoomItems = (props) => {
  const { ele } = props;
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [projectModalVisible, setProjectModalVisible] = useState(false)
  const [selectedProduct, setSelectedProductId] = useState(null)
  const [duplicateSuccessModal, setDuplicateSuccessModal] = useState(false)
  const [duplicateSpaceModal, setDuplicateSpaceModal] = useState(false)
  const [duplicateSpaceName, setDuplicateSpaceName] = useState('')
  const [duplicateLoader, setDuplicateLoader] = useState(false);
  const [duplicate_form] = Form.useForm()
  const isDuplicationInProgress = ele.duplication_status === 'inprogress'
  
  const deleteRoom = (id, customerUsername, companyID) => {
    props.setCurrentId(id);
    props.setCurrentCustomer(customerUsername);
    props.setCompanyID(companyID);
    props.setDeleteModal(true);
  };

  const openDuplicateSpaceModal = () => {
    const new_space_name = `Copy of ${ele.room_name}`
    setDuplicateSpaceName(new_space_name);
    setDuplicateSpaceModal(true);
  }

  const duplicateSpace = async () => {

    setDuplicateLoader(true);
    const room_id = ele.room_id
    const customerUsername = ele.customer_username
    const companyID = ele.company_id

    try{
      const payload = {
        "room_id": room_id,
        "customer_username": customerUsername,
        "company_id": companyID,
        "new_space_name": duplicateSpaceName
      }

      const response = axios.post(ENVIRONMENT.SPACE_REPLICA, payload).then((res)=> {
        setDuplicateSpaceModal(false);
        setDuplicateLoader(false);
        setDuplicateSuccessModal(true);
      })
    }
    catch(error){
        console.log('Error = ', error)
    }
  }
  
  let get_options = (onAddToCollection, onDelete, id, customerUsername,managed_customer, sellOnStore,room_data, space_type, removeItemFromStore, companyID) => {
    return <Menu>
    {/* <Menu.Item>
      <a  className="manrope f-14" onClick={() => onAddToCollection(id) }>Add to Collection</a>
    </Menu.Item> */}
    <Menu.Item>
      <a  className="manrope f-14" onClick={() => openDuplicateSpaceModal()  }>Duplicate Space</a>
    </Menu.Item>
    {(Utilities.companyMatch(room_data.company_id,COMPANY_ID) || CUSTOMER_USERNAME == customerUsername)  && room_data.is_store_item != 1 ?
    <Menu.Item>
      <a  className="manrope f-14" onClick={() => sellOnStore(room_data)  }>Sell on Store</a>
    </Menu.Item>: ''}
    {(Utilities.companyMatch(room_data.company_id,COMPANY_ID) || CUSTOMER_USERNAME == customerUsername) && room_data.is_store_item == "owned" ?
    <Menu.Item>
      <a className="manrope f-14" onClick={() => removeItemFromStore(room_data) }>Remove From Store</a>
    </Menu.Item>: ''}
    {managed_customer ? '' :
    <Menu.Item>
      <a  className="manrope f-14" onClick={() => onDelete(id, customerUsername, companyID) }>Delete Space</a>  
    </Menu.Item>}
    <Menu.Item>
      <a className="manrope f-14" onClick={() => {setProjectModalVisible(true); setSelectedProductId(id)}}>Add to Project</a>
    </Menu.Item>
  </Menu>
  };

  const onChange = (e) => {
    Utilities.updateEntityInUrl(e.target.roomSelected);
    let spaceIds = props.selectedRoomIds;
    if (e.target.checked) {
      spaceIds.push(e.target.roomSelected);
    } else {
      spaceIds = props.selectedRoomIds.filter(
        (ele) => ele != e.target.roomSelected
      );
    }
    props.setSelectedRoomIds([...spaceIds]);
    setTriggerUpdate(!triggerUpdate);
    forceUpdate();
    let url = new URL(window.location);
    url.searchParams.delete("folder_id");
    url.searchParams.delete("library_folder_id");
    url.searchParams.set("entity_id", e.target.roomSelected);
    window.history.replaceState({}, "", url);
  };

  const toggleChecked = (room) => {
    if (!isFolder()) {
      if (props.reorderItems) {
        let roomIds = props.selectedFolderRooms;
        if (props.selectedFolderRooms.includes(parseInt(room.room_id))) {
          roomIds = props.selectedFolderRooms.filter((ele) => ele != parseInt(room.room_id));
        } else {
          roomIds.push(parseInt(room.room_id));
        }
        props.setSelectedFolderRooms([...roomIds]);
      } else if (props.selectable) {
        let spaceIds = props.selectedRoomIds;
        if (props.selectedRoomIds.includes(room.room_id)) {
          spaceIds = props.selectedRoomIds.filter((ele) => ele != room.room_id);
        } else {
          spaceIds.push(room.room_id);
        }
        props.setSelectedRoomIds(spaceIds);
      }
      Utilities.updateEntityInUrl(room.room_id);
      setTriggerUpdate(!triggerUpdate);
      forceUpdate();
    }
    else {
        Utilities.updateFolderIDInUrl(room[0].folder_id);
    }
  };

    // select rooms for folder
  const selectForFolder = (e) => {
    Utilities.updateEntityInUrl(e.target.roomSelected);
    let roomIds = props.selectedFolderRooms;
    if (e.target.checked) {
      roomIds.push(parseInt(e.target.roomSelected));
    } else {
      roomIds = props.selectedFolderRooms.filter(
        (ele) => {return ele != e.target.roomSelected}
      );
    }
    props.setSelectedFolderRooms([...roomIds]);
    
  };

    // get folder id of the product selected
    const getFolderID = (element) => {
        if (Array.isArray(element)) {
            return element[0].folder_id;
        }
        return -1;
    }


    const goInsideFolder = (element) => {
        //if product is a parent room, and it's clicked, then enter folder
        if (isFolder()) {
            props.enterFolderState(element)
        }
    }
    

    const checkIfFolderPresent = () => {
        if (Array.isArray(ele)) {
            return true;
        }
        return false
    }

    const isFolder = () => {
        if (Array.isArray(ele)) {
            return true;
        }
        return false;
    }

    const getName = () => {
        if (Array.isArray(ele)) {
            return ele[0].folder_name;
        }
        return ele.name;
    }

    const getThumbnail = () => {
        if (Array.isArray(ele)) {
            return ele[0].thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(ele[0].platform));
        }
        return ele.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(ele.platform));
    }

    const isFolderItem = () => {
        if (ele.folder_id != "") 
            return true;
        return false;
    }

    const getLink = () => {
        return `${props.link + window.location.search}`
    }

    const getID = () => {
        //return folder id if item is a folder, otherwise return
        // item id
        if (isFolder()) {
            return `folder-${ele[0].folder_id}`
        } else {
            return `element-${ele.room_id}`
        }
    }

  const performAction = (ele) => {
    // perform action when user clicks on a card
    if (props.selectable || props.reorderItems || !isFolder()) {
        toggleChecked(ele);
    } else {
        goInsideFolder(ele);
    }
    if (props.selectable || props.reorderItems || isFolder()) {
        window.location.href = "#";
    } else {
        window.location.href = getLink();
    }
  }

  return (<Col
        className="draggable-element" id={getID()} lg={props.reorderItems ? 6 : 24} sm={24} md={props.reorderItems ? 8 : 24} offset={0} style={props.reorderItems ? { display: 'inline-block' } : {}}>
        <Card bodyStyle={{ padding: "5px" }}
          className={`${isFolder() ? `stacked-card stacked-card-top-right card-margins stack` : `card-margins`
          } card-shadow-product`}
          bordered={false}
          style={ isFolder()
              ? Styles.folder_border
              : {
                  pointerEvents: isDuplicationInProgress ? 'none' : '',
                  width: "97%",
                  border: props.selectedFolderRooms.includes(parseInt(ele.room_id)) 
                    ? "solid 1px #276DD7"
                    : props.selectedRoomIds.includes(parseInt(ele.room_id)) ? "solid 1px #333333" : "solid 1px #e3e3e3", }}>
          {props.reorderItems ? (
            <span>
              <div className="group-settings more-icon" onClick={() => {}}>
                <img src={require("../../../../assets/images/Move.svg")} />
              </div>
              {isFolder() ? (
              <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                <span className={`group-settings-checkbox-reorder-items`}>
                    <img src={'/img/folder_white_bg.svg'} />
                </span>
              </Tooltip> 
              ) : (
                <span className={`group-settings-checkbox-reorder-items`}>
                  <Checkbox
                    className="blue-checkbox"
                    onChange={selectForFolder}
                    roomSelected={ele.room_id}
                    checked={props.selectedFolderRooms.includes(parseInt(ele.room_id))}
                  />
                </span>
              )}
            </span>
          ) : props.selectable ? isFolder() ? (
              <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                <span className={`group-settings-checkbox-reorder-items`}>
                    <img src={'/img/folder_white_bg.svg'} />
                </span>
              </Tooltip> 
              ) :
          (
            <span className="group-settings-checkbox">
              <Checkbox
                className="black-checkbox" onChange={onChange} checked={props.selectedRoomIds.includes(ele.room_id)} roomSelected={ele.room_id}/>
            </span>
          ) : (<>
            {isFolder() ? (
              <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                <span className={`group-settings-checkbox-reorder-items`}>
                    <img src={'/img/folder_white_bg.svg'} />
                </span>
              </Tooltip> ) : ''}
            {!isDuplicationInProgress && 
              <Dropdown
                className="group-settings more-icon"
                overlay={isFolder() ? get_folder_options(props.renameFolderOnclick, props.unGroupFolderItemsOnclick, ele ) : get_options(props.onAddCollectionClick, deleteRoom, ele.room_id, ele.customer_username, props.managed_customer, props.sellOnStore, ele, props.space_type, props.removeItemFromStore, ele.company_id)} trigger={["click"]}>
                <Button
                  shape="circle"
                  size={"large"}
                  className="more-option-library font-14 w-900"
                  icon={<MoreIcon />}
                ></Button>
              </Dropdown>
            }
            </>)}
          <div className="stacked-card-inner">
          <Link to={
                     (props.selectable || props.reorderItems || isFolder(ele)) ?
                         "#" : (getLink())}>
            {
              isDuplicationInProgress &&
              <div style = {{    
                position: "absolute",
                zIndex: 2,
                top: "40%",
                width: "100%"
              }}>
              <span style = {{
                  padding: "6px 10px",
                  border: "1px dashed white",
                  borderRadius: "6px",
                  color: "white"
                }}
              >Copy in Progress
              </span>
              <span style ={{
                  color: "white",
                  fontSize: "14px",
                  display: "block",
                  margin: "10px"
                }}
              >Est. 2 min</span>
            </div>}
          <div 
                className="suggested-product" style={{zIndex:"1",cursor: "pointer" }} 
                onMouseDown={(e) => {
                    // on left click call the performAction function instead of directly navigating to link
                    if((e.button === 0 && !(e.ctrlKey || e.metaKey)) || isFolder(ele)){
                        performAction(ele)
                        }
                    }
                }
                onClick={(e) => {
                  if (e.button === 0 && !(e.ctrlKey || e.metaKey))
                        {
                            e.preventDefault();
                        }
                }}
                >
              <div className="box">
                {getThumbnail() ? (
                  <img
                    src={getThumbnail()}
                    style={{ objectFit: "cover", width: "100%", height: 280 , filter: isDuplicationInProgress ? "blur(2px) brightness(.7)" : '' }}
                  />) 
                  : (
                  <React.Fragment>
                    <img
                      src={require("../../../../assets/images/space_incomplete.svg")}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: 280,
                        opacity: "0.5",
                        filter: "blur(0px)",
                      }}
                    />
                    <div className="incomplete-request-text">
                      <span>Image not Found</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div
                className="justify-in-start"
                style={{ margin: "12px 12px 8px 12px" }}
              >
                <Tooltip title={ <span className="manrope f-12 white"> {isFolder()  ? getName() : ele.room_name} </span>}>
                    {isFolder() ? 
                    <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100">{getName()}</span> :  
                    <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100">{ele.room_name}</span>}
                </Tooltip>

                {isFolder() ? '' :
                <Tooltip title={ <span className="manrope f-12 white"> { Utilities.timestampToTimeSince( ele.last_modified_stamp, "tooltip")} </span>}>
                  <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                    <img src="/img/carbon_time.svg" style={{ marginRight: 2 }} /> {Utilities.timestampToTimeSince(ele.last_modified_stamp)}
                  </span>
                </Tooltip>}
              </div>

              
              <div className="justify-in-start" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"4px",textAlign:"left"}}> 
                <span className="manrope f-12 grey-77 capitalize" style={{marginRight: 4}}>{isFolder() ? '': 'Category:'}</span>
                {isFolder() ? '': <span className="manrope f-12 black-33 capitalize">{ele.category}</span>}
            </div>

            <div className="justify-in-start manrope f-12 grey-77 capitalize"  style= {{marginBottom:"4px", marginLeft:"12px",marginRight:"12px",textAlign:"left"}}> 
                <div style={{marginRight: 4}}>{isFolder()  ? '' : 'Item ID:'}</div> 
                <div  style={{"color":"#333333"}}>{isFolder()  ? '' : ele.brand_id || 'N/A'}</div>
            </div>
            <div className="justify-in-start manrope f-12 grey-77 capitalize"  style= {{marginBottom: !isFolder() && props.folderState ? 12 : 
                isFolder() ? 48 : 12, marginLeft:"12px",marginRight:"12px",textAlign:"left"}}> 
                <div style={{marginRight: 4}}>{isFolder() ?  '' : 'Dimensions:'}</div> 
                {isFolder() ?  '' :
                    (Array.isArray(ele.dimensions)) ? <div className="manrope f-12 black-33">N/A</div> :<div className="manrope f-12 black-33">
                    D {Math.round(ele.dimensions.depth)}" W {Math.round(ele.dimensions.width)}" H {Math.round(ele.dimensions.height)}"</div>}
            </div>  
        </div>
        </Link>
        </div>
    </Card>
    <Modal
            visible={duplicateSpaceModal}
            onCancel={() => {
                    setDuplicateSpaceModal(false)  
            }}
            width={700}
            className="modal-space"
            bodyStyle={{padding: 0}}
            maskClosable={false}
            footer={[
                <div className="justify-in-end">
                    <Button disabled ={duplicateLoader} className="modal-okay-gray square font-12" key="back" onClick={() => setDuplicateSpaceModal(false)}>
                        Cancel
                    </Button>
                    <Button disabled ={duplicateLoader} className="modal-okay square font-12" key="ok" onClick={duplicateSpace}>
                        { duplicateLoader && 
                          <LoadingOutlined/> 
                        } Duplicate Space
                    </Button>
                </div>
            ]}>
            <Form
                form={duplicate_form}
                initialValues={{
                    space_name: duplicateSpaceName,
                }}
            >
                <div style={{padding:"30px",display:"flex"}}>
                    <Col span={24}>
                        <h2 className="modal-heading manrope f-14 black-00 w-600">Add Space Name</h2>
                        <h5 className='manrope f-12 bg grey' style={{lineHeight: 'inherit'}}><InfoCircleOutlined/>&nbsp;This will be the name of your space. The duplicated space will appear in your library.</h5>
                        <Form.Item
                            name="space_name"
                            rules={[{ required: true, message: 'Please add space name.' }]}
                        >
                            <Input onChange={(e)=>{setDuplicateSpaceName(e.target.value)}} className='manrope f-12 black-55' placeholder='Enter Scene Name' style={{width: "100%",height:"40px"}}/>

                        </Form.Item>
                    </Col>
                </div>
            </Form>
        </Modal>
    <SuccessModal
        visible={duplicateSuccessModal}
        heading={"Duplicate Space Requested!"}
        text={"You have successfully requested a duplicate of the space. Space imagery are being replicated which can take upto a minute."}
        footer={[
        <div className='justify-in-center'>
        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-16" 
          onClick={() => {
                setDuplicateSuccessModal(false);
                window.location.reload();
              }}>
              Okay
        </Button>
        </div>
        ]}
    />

    <AddEntityToProject 
        entityId={selectedProduct}
        entityType='Room'
        modalVisible={projectModalVisible}
        setModalVisible={setProjectModalVisible}
        projects={props.projects}
    />
    </Col>)

};

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  reorderData : (payload)=>{
    dispatch(reorderData(payload))
  }
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomsList)
)