import React, { useState, useEffect } from "react";
import { Card, Row, Col, Table, Select, Modal, Calendar, Button } from "antd";
import moment from "moment";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { LoadingOutlined, DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

const Actions = {
  get_system_summary: "get_system_summary",
  get_model_details: "get_model_details",
};

const DATE_FORMAT = "YYYY-MM-DD";
const ALL_TIME_START = "2022-01-01";
const CALENDAR_LEFT = "left";
const CALENDAR_RIGHT = "right";

const MODELS_ORDER = [
  'Product Model AR Conversion Variation',
  'Product Model Bedding Variation',
  'Product Model Color Variation',
  'Product Customer Model Fix',
  'Product Model Hardware Variation',
  'Product Model Material Variation',
  'Product Model Other Variation',
  'Product Model Size Variation',
  'Product Model State Variation',
  'Product Model (VRAY + AR)',
  'Product Model (VRAY)',
  'Product Model (AR)',
  'High Poly PBR',
  'User Uploaded Model',
  'Space Model Variation',
  'Space Base Model',
  'Material',
]

const CostSummary = () => {
  const [isSummaryFetched, setIsSummaryFetched] = useState(false);
  const [costSummary, setCostSummary] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf("month").format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'months').endOf("month").format(DATE_FORMAT)
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [customers, setCustomers] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [companies, setCompanies] = useState([]);
  const [companiesData, setCompaniesData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [categories, setCategories] = useState([]);
  const [isCustomDateModalVisible, setIsCustomDateModalVisible] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(
      moment().subtract(1,"months").startOf('month')
  );
  const [customEndDate, setCustomEndDate] = useState(
      moment()
  );
  const [selectedValue, setSelectedValue] = useState("1");
  const [previousSelectedValue, setPreviousSelectedValue] = useState("1");
  const [isModelDetailsModalVisible, setIsModelDetailsModalVisible] = useState(false);
  const [isModelsDetailFetched, setIsModelsDetailFetched] = useState(false)
  const [modelDetails, setModelDetails] = useState([]);
  const [modelType, setModelType] = useState(null);
  const [modelCount, setModelCount] = useState(0);
  
  const getCostSummary = () => {
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    let payload = {
        "action" : Actions["get_system_summary"],
        "start_date" : startDate,
        "end_date" : endDate
    };

    if (selectedCustomer != null) {
      payload["customer"] = selectedCustomer
    }

    if (selectedCompany != null) {
      payload["company_id"] = selectedCompany
    }

    if (selectedCategory != null) {
      payload["category"] = selectedCategory
    }

    console.log("Payload", payload);
    
    setIsSummaryFetched(true);
    axios.post(ENVIRONMENT.GET_MODELING_COST_REPORT, payload).then((response) => {
        const responseData = response.data;
        console.log('Cost Summary', responseData);
        const orderedData = responseData.sort((a, b) => {
          const indexA = MODELS_ORDER.indexOf(a.model_type);
          const indexB = MODELS_ORDER.indexOf(b.model_type);
      
          if (indexA < indexB) return -1;
          if (indexA > indexB) return 1;
          return 0;
        });
        setCostSummary(orderedData);
        setIsSummaryFetched(false);
    });
  }

  useEffect(() => {
    getCostSummary()
  }, [startDate, endDate, selectedCustomer, selectedCompany, selectedCategory]);

  // Table Columns
  const costSummaryColumns = [
    {
      title: "Model Type",
      dataIndex: "model_type",
      key: "model_type"
    },
    {
      title: "Average Artist Cost",
      dataIndex: "avg_modeling_cost",
      key: "avg_modeling_cost",
      render: (text) => '$'+text,
    },
    {
      title: "Average Automation Cost",
      dataIndex: "avg_automation_cost",
      key: "avg_automation_cost",
      render: (text) => '$'+text,
    },
    {
      title: "Average Total Cost",
      dataIndex: "avg_cost",
      key: "avg_cost",
      render: (text) => '$'+text,
    },
    {
      title: "Frequency/Count",
      dataIndex: "frequency",
      key: "frequency",
      render: (text, record) => <a
      className="onhover-underline"
      onClick={() => {
        setModelCount(text);
        showModelDetails(record.model_type);
      }}>{text}</a>,
    },
  ]

  const costDetailsColumns = [
    {
      title: "Model ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <a
          className="onhover-underline"
          href={getModelLink(text, record.model_type)}>
        {text}
        </a>
      ),
    },
    {
      title: "Model Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Username",
      dataIndex: "customer_username",
      key: "customer_username",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Artist Cost",
      dataIndex: "artist_modeling_cost",
      key: "artist_modeling_cost",
      render: (text) => '$'+text,
    },
    {
      title: "Automation Cost",
      dataIndex: "automation_cost",
      key: "automation_cost",
      render: (text) => '$'+text,
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "total_cost",
      render: (text) => '$'+text,
    },
  ]

  // Select (Date Dropdown) functions
  const dateOptions = [
    {
        label: "Current Month",
        value: "0"
    },
    {
        label: "Last Month",
        value: "1"
    },
    {
        label: moment().subtract(2, 'months').format('MMMM YYYY'),
        value: "2"
    },
    {
      label: moment().subtract(3, 'months').format('MMMM YYYY'),
      value: "3"
    },
    {
      label: "All Time",
      value: "all_time"
    },
    {
        label: "Custom",
        value: "custom"
    }
  ];

  const handleDateChange = (value) => {
    console.log("Date Value: ", value);
    if (value === "all_time") {
      setSelectedValue(value);
      setStartDate(ALL_TIME_START);
      setEndDate(moment().format(DATE_FORMAT));
    }
    else if (value == "custom") {
      console.log("handleDateSelect")
    }
    else {
      setSelectedValue(value);
      const numberOfMonths = parseInt(value, 10);
      setStartDate(moment().subtract(numberOfMonths, 'months').startOf("month").format(DATE_FORMAT));
      setEndDate(moment().subtract(numberOfMonths, 'months').endOf("month").format(DATE_FORMAT));
    } 
  };

  const handleDateSelect = (value) => {
    console.log("Date Value: ", value);
    if (value == "custom") {
      setPreviousSelectedValue(selectedValue);
      setSelectedValue(value);
      setIsCustomDateModalVisible(true);
    }
  };

  // Select (Customers Dropdown) functions
  const getUsersList = () => {
    let payload = {};
    axios.post(ENVIRONMENT.CUSTOMER_GET_BATCH, payload).then((response) => {
      console.log("Customers Data",response.data)
      let users = [{label : "Default (All Customers)", value: "all"}];
      if (response.data) {
        response.data.map((request) => {
          users.push({label:request.customer_username, value:request.customer_username});
        });
        setCustomers(users);
        console.log("Users List", users);
      }
    });
  };

  const handleCustomerChange = (value) => {
    if (value == "all") {
      setSelectedCustomer(null);
    }
    else {
      setSelectedCustomer(value);
    }
  };

  // Select (Companies Dropdown) functions
  const getCompaniesList = () => {
    let payload = {};
    axios.post(ENVIRONMENT.COMPANY_GET_BATCH, payload).then((response) => {
      console.log("Companies Data", response.data);
      setCompaniesData(response.data);
      let companiesData = [{label : "Default (All Companies)", value: "all"}];
      if (response.data) {
        response.data.map((request) => {
          companiesData.push({label:request.company_display_name, value:request.company_display_name});
        });
        const uniqueCompaniesList = Array.from(new Set(companiesData.map(JSON.stringify)), JSON.parse);
        setCompanies(uniqueCompaniesList);
        console.log("Companies List", uniqueCompaniesList);
      }
    });
  };

  const handleCompanyChange = (value) => {
    if (value == "all") {
      setSelectedCompany(null);
    }
    else {
      const filteredList = companiesData.filter(
        (company) => company.company_display_name == value
      );
      const companyIds = filteredList.map((company) => company.company_id);
      setSelectedCompany(companyIds);
    }
  };

  // Select (Categories Dropdown) functions
  const getCategoryList = () => {
    let payload = {};
    axios.post(ENVIRONMENT.GET_ALL_CATEGORIES, payload).then((response) => {
      console.log("Categories Data", response.data);
      let categoriesData = [{label : "Default (All Categories)", value: "all"}];
      if (response.data) {
        response.data.map((request) => {
          categoriesData.push({label:request.category, value:request.value});
        });
        setCategories(categoriesData);
        console.log("Categories List", categoriesData);
      }
    });
  }

  const handleCategoryChange = (value) => {
    if (value == "all") {
      setSelectedCategory(null);
    }
    else {
      setSelectedCategory(value);
    }
  };

  useEffect(() => {
    getUsersList();
    getCompaniesList();
    getCategoryList();
  }, []);

  // Custom Date Modal (Dropdown) functions
  const handleModalClose = () => {
    setIsCustomDateModalVisible(false);
    setSelectedValue(previousSelectedValue);
};

  const handleModalOk = () => {
    const start = customStartDate.format(DATE_FORMAT);
    const end = customEndDate.format(DATE_FORMAT);
    if (end < start) {
        showAlert('Start date cannot be greater than end date!');
        return;
    }
    setStartDate(start);
    setEndDate(end);
    setIsCustomDateModalVisible(false);
    setSelectedValue(customStartDate.format("DD/MM/YYYY") + " → " + customEndDate.format("DD/MM/YYYY"));
  }

  const showAlert = (message) => {
    alert(message);
  };

  // Calendar Functions
  const handleDisabledDates = (date) => {
    if (date.format(DATE_FORMAT) < ALL_TIME_START || date.format(DATE_FORMAT) > moment().format(DATE_FORMAT)) {
        return true;
    }
    return false;
  };

  const CalendarHeader = ((calendarType) => {
    const calendarDate = calendarType.calendarType === CALENDAR_LEFT ? customStartDate : customEndDate;
    return (
      <Row>
        <Col span={3} className="justify-in-start">
          <Button 
            onClick={() => {
              handlePreviousChange(calendarType.calendarType, "years");
            }} 
            icon={<DoubleLeftOutlined />} />
        </Col>
        <Col span={3} className="justify-in-start">
          <Button 
            onClick={() => {
              handlePreviousChange(calendarType.calendarType, "months");
            }}
            icon={<LeftOutlined />} />
        </Col>
        <Col span={12} className="justify-in-center">
          <span style={{color: "black", fontWeight: "bold"}}>{calendarDate.format("MMMM YYYY")}</span>
        </Col>
        <Col span={3} className="justify-in-end">
          <Button 
            onClick={() => {
              handleNextChange(calendarType.calendarType, "months");
            }}
            icon={<RightOutlined />} />
        </Col>
        <Col span={3} className="justify-in-end">
          <Button 
            onClick={() => {
              handleNextChange(calendarType.calendarType, "years");
            }}  
            icon={<DoubleRightOutlined />} />
        </Col>
      </Row>
    );
  }); 

  const handlePreviousChange = (type, locale) => {
    if (type == CALENDAR_LEFT) {
        const updatedCustomStartDate = moment(customStartDate).subtract(1, locale);
        if (updatedCustomStartDate.format(DATE_FORMAT) >= ALL_TIME_START) {
            setCustomStartDate(updatedCustomStartDate);
        }
    }
    if (type == CALENDAR_RIGHT) {
        const updatedCustomEndDate = moment(customEndDate).subtract(1, locale);
        if (updatedCustomEndDate.format(DATE_FORMAT) >= ALL_TIME_START) {
            setCustomEndDate(updatedCustomEndDate);
        }
    }
  };

  const handleNextChange = (type, locale) => {
      const currentDate = moment().format(DATE_FORMAT)
      if (type == CALENDAR_LEFT) {
          const updatedCustomStartDate = moment(customStartDate).add(1, locale);
          if (updatedCustomStartDate.format(DATE_FORMAT) <= currentDate) {
              setCustomStartDate(updatedCustomStartDate);
          }
      }
      if (type == CALENDAR_RIGHT) {
          const updatedCustomEndDate = moment(customEndDate).add(1, locale);
          if (updatedCustomEndDate.format(DATE_FORMAT) <= currentDate) {
              setCustomEndDate(updatedCustomEndDate);
          }
      }
  };

  const handleOnSelect = (newValue, calendarType) => {
    if (calendarType == CALENDAR_LEFT) {
        setCustomStartDate(newValue);
    }
    if (calendarType == CALENDAR_RIGHT) {
        setCustomEndDate(newValue);
    }
  };

  const showModelDetails = (model_type) => {
    setModelType(model_type);
    setIsModelDetailsModalVisible(true);
    let payload = {
      "action" : Actions["get_model_details"],
      "start_date" : startDate,
      "end_date" : endDate,
      "model_type" : model_type,
    };

    if (selectedCustomer != null) {
      payload["customer"] = selectedCustomer
    }

    if (selectedCompany != null) {
      payload["company_id"] = selectedCompany
    }

    if (selectedCategory != null) {
      payload["category"] = selectedCategory
    }

    console.log("Payload", payload);
    
    setIsModelsDetailFetched(true);
    axios.post(ENVIRONMENT.GET_MODELING_COST_REPORT, payload).then((response) => {
        const responseData = response.data;
        console.log('Model Details', responseData);
        setModelDetails(responseData);
        setIsModelsDetailFetched(false);
    });
  };

  const closeModelDetails = () => {
    setIsModelDetailsModalVisible(false);
  };

  const getModelLink = (id, model_type)  => {
    if (model_type === 'Material') {
      return '/admin_materials/' + id
    } 
    else if (model_type === 'Space Model Variation' || model_type === 'Space Base Model') {
      return '/admin_rooms/' + id
    }
    else {
      return '/admin_products/' + id
    }
  }

  return (
    <>
      <Card
      className="no-hover section-container"
      size="small"
      title={
        <Row className="mt-10 justify-in-start" gutter={12}>
          <Col span={8}>
            <span className="sub-heading">Summary Table</span>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            showSearch
            placeholder="Select Company"
            style={{width: "100%"}}
            options={companies}
            onChange={handleCompanyChange}>
            </Select>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            showSearch
            placeholder="Select Customer"
            style={{width: "100%"}}
            options={customers}
            onChange={handleCustomerChange}>
            </Select>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            showSearch
            placeholder="Select Category"
            style={{width: "100%"}}
            options={categories}
            onChange={handleCategoryChange}>
            </Select>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            placeholder="Select Date"
            style={{width: "100%"}}
            options={dateOptions}
            defaultValue={dateOptions[1]["value"]}
            onChange={handleDateChange}
            onSelect={handleDateSelect}
            value={selectedValue}>
            </Select>
          </Col>
        </Row>
      }>
        <Row>
            <div className="chart-container">
                {
                isSummaryFetched ? (<LoadingOutlined className="loading-center top-20"/>) : 
                <Table
                dataSource={costSummary}
                columns={costSummaryColumns}
                scroll
                style={{ overflowX: "scroll" }}/>
                }
            </div>
        </Row>
      </Card>
      <Modal
        title="Custom Date"
        visible={isCustomDateModalVisible}
        onCancel={handleModalClose}
        onOk={handleModalOk}
        width={"50%"}
        bodyStyle={{ maxWidth: '100%', overflowX: 'auto' }}
        okText="Confirm"
        >
          <Row gutter={20}>
              <Col span={12}>
                  <span className="ant-picker-cell-inner" style={{color: "black"}}>
                      Select Start Date:
                  </span>
              </Col>
              <Col span={12}>
                  <span className="ant-picker-cell-inner" style={{color: "black"}}>
                      Select End Date:
                  </span>
              </Col>
          </Row>
          <Row gutter={20} className="justify-in-center">
              <Col span={12}>
                  <div className="wrapper-style" style={{ maxWidth: '100%', overflowX: 'auto' }}>
                      <Calendar 
                      fullscreen={false} 
                      disabledDate={handleDisabledDates}
                      headerRender={({ value, type, onChange, onTypeChange }) => {
                          onChange(customStartDate);
                          return (
                            <CalendarHeader calendarType={CALENDAR_LEFT}/>
                          );
                      }}
                      onSelect={(date) => {
                          handleOnSelect(date, CALENDAR_LEFT);
                      }}
                      />
                  </div>
              </Col>
              <Col span={12}>
              <div className="wrapper-style" style={{ maxWidth: '100%', overflowX: 'auto' }}>
                      <Calendar 
                      fullscreen={false} 
                      disabledDate={handleDisabledDates}
                      headerRender={({ value, type, onChange, onTypeChange }) => {
                          onChange(customEndDate);
                          return (
                            <CalendarHeader calendarType={CALENDAR_RIGHT} onChange={onChange}/>
                          );
                      }}
                      onSelect={(date) => {
                          handleOnSelect(date, CALENDAR_RIGHT);
                      }}
                      />
                </div>
              </Col>
          </Row>
      </Modal>
      <Modal
      title={`${modelType} Details (${modelCount})`}
      visible={isModelDetailsModalVisible}
      onCancel={closeModelDetails}
      footer={null}
      width={"70%"}
      bodyStyle={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Row>
          <div className="chart-container">
              {
              isModelsDetailFetched ? (<LoadingOutlined className="loading-center top-20"/>) : 
              <Table
              dataSource={modelDetails}
              columns={costDetailsColumns}
              scroll
              style={{ overflowX: "scroll" }}
              />
              }
          </div>
        </Row>
      </Modal>
    </>
  );
};

export default CostSummary;