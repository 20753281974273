import React, {useState, useEffect} from 'react'
import { Radio, Row, Button, Popover, Modal, Tooltip, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import SiloSettings from './SiloSettings';

export default function SiloSnapshotModal(props) {
    
    const [editCameraName, setEditCameraName] = useState(false);
    const [cameraName, setCameraName] = useState('');
    
    const onCancelModal = () => {
        props.resetZoom();
        props.setCameraSelected(false)
        props.setTakeSiloShotModal(false);
        props.changePerspective(props.perspective);
    }
    
    useEffect(() => {
        if (props.takeSiloShotModal){
            if(document.getElementById("silo-inspector-modal") != undefined && document.getElementById("silo-inspector")!= undefined){
                document.getElementById("silo-inspector").prepend(document.getElementById("silo-inspector-child"));
                document.getElementById("silo-inspector-modal").appendChild(document.getElementById("silo-inspector"));
                document.getElementById("silo-inspector").style.zIndex = 2;
                document.getElementById("silo-inspector").style.position = 'relative';
            }          
        }
        else {
            
            if(document.getElementById("silo-inspector-main") != undefined && document.getElementById("silo-inspector")!= undefined && document.getElementById("silo-inspector-modal") != undefined) {
                document.getElementById("silo-inspector-modal").prepend(document.getElementById("silo-inspector-child"));
                document.getElementById("silo-inspector-main").appendChild(document.getElementById("silo-inspector"));
                document.getElementById("silo-inspector").style.zIndex = -10;
                document.getElementById("silo-inspector").style.position = 'fixed';
                document.getElementById('silo-inspector').style.top = "1"
                document.getElementById('silo-inspector').style.top = "1"
            }
        }
    },[props])
    
  return (
    <div>
        <Modal
            className ='take-silo-shot-modal'
            visible = {props.takeSiloShotModal}
            centered = {true}
            onCancel = { () => onCancelModal()}
            footer = {
                <div style={{display:"flex"}}>
                    <span data_tut="reactour__panrotate" style={{display:"flex"}}>
                        <Radio.Group
                                disabled={props.loadingProgress ? true : false}
                                className="silo-controls"
                                value={props.panToggle ? "pan" : "rotate"}
                                onChange={props.onChangeToggle}
                                style={{position:"relative", left:"27vw", display:"flex"}}>
                                    <div>
                                        <Radio.Button value="rotate"><img src={props.panToggle ? "/img/rotate_default.png" :  "/img/rotate_selected.png"} className='silo-zoom-pan pan'/></Radio.Button>
                                        <Radio.Button value="pan"><img src={props.panToggle ? "/img/pan_selected.png" : "/img/pan_default.png"} className='silo-zoom-pan pan'/></Radio.Button>
                                        <div style={{display:"flex",justifyContent:"space-between"}}>
                                            <div  className="icon-label-text2" >Rotate</div>
                                            <div  className="icon-label-text2" >Pan</div>
                                        </div>
                                    </div>
                        </Radio.Group>
                        <div className='zoom-silo-settings'> 
                            <span className='zoom-silo-span'  onClick={() => props.zoomIn()}>
                                <img src={"/img/SiloZoomIn.png"} className='silo-zoom-pan zoom' />
                            </span>
                            <div style={{top:0}} className='separator-no-margin'/>
                            <span className='zoom-silo-span' onClick={() => props.zoomOut()}>
                                <img src={"/img/SiloZoomOut.png"} className='silo-zoom-pan zoom' />
                            </span>
                        </div>
                        <div className='silo-snapshot-settings-buttons'>
                            <Button className="modal-okay-gray square font-14" style={{marginRight:"12px"}} onClick={() => onCancelModal()}>
                                Cancel
                            </Button>
                            <Button className="modal-okay square font-14" onClick={() => {
                                props.setCameraSelected(false)
                                if(!props.editCamera) {
                                    props.snapshotTaken()  
                                }
                                else {
                                    props.snapshotUpdate()
                                }
                                props.resetZoom();
                            }}>
                                {!props.editCamera? 'Create' : 'Update'}
                            </Button>
                        </div>
                    </span>
                </div>

            }
            title={
                !props.editCamera?
                <div style={{display:"flex", alignItems:"center"}}>
                    <Input id ='input-field-camera-name' disabled={!editCameraName} style={{maxWidth:"110px", padding:0, cursor:'text', color:'black', fontSize:"16px",width:(props.cameraDisplayName.length+1)*10+'px'}} bordered={false} value={!editCameraName ? props.cameraDisplayName : cameraName} 
                    onChange={(event) => {
                        setCameraName(event.target.value);
                        document.getElementById('input-field-camera-name').style.width = (document.getElementById('input-field-camera-name').value.length+1)*10 +'px'
                    }}
                    />
                    {
                        editCameraName ?
                        <Button onClick={()  => {props.setCameraDisplayName(cameraName); setEditCameraName(false)}}>
                            Save
                        </Button>
                        :
                            <EditOutlined onClick={() => {setEditCameraName(true); setCameraName(props.cameraDisplayName)}} style={{color:'rgba(31,31,31,1'}} />
                    }   
                </div>
                :
                <div >
                    <span className='manrope f-16'>
                        {props.cameraDisplayName}
                    </span>
                </div>
            }
        >
            <div >
            <div className='silo-tool-container-modal'>
                <div id='silo-inspector-modal' style={{filter: props.loadingProgress ? "blur(12px)": "", placeSelf:"center"}} data_tut="reactour__dragrotate">
                    <div id = 'silo-inspector-child' style={{position: "relative"}}>
                        <Row id ='row-controls-silo' type="flex" style={{placeContent:'center', padding:"5px", position:"absolute", left:"40%", zIndex:10}}>
                            <div id='controls-row-silo'>
                                <Popover placement="top" content={"Grid"}>
                                    <div>
                                        <span style={{cursor: "pointer", marginTop: 5}} onClick={() => {props.showGridForSilos()}}>
                                            <img src={props.gridState == false ? "/img/grid.png" : "/img/grid.png"} class="contained-icon-x-large"/>
                                        </span>
                                    </div>
                                </Popover>
                                <Popover placement="top" content={"Safe Zone"}>
                                    <span onClick={props.OpenOverlaySettingsModal}>
                                        <img src={"/img/safe_zone.png"} className="contained-icon-x-large"></img>
                                    </span>
                                </Popover>
                                <Popover placement="top" content={"Vertical Camera Align"}>
                                    <span style={{cursor: "pointer"}} onClick={() => {props.alignCameraForSilos()}}>
                                        <img src={"/img/camera_align.png"} class="contained-icon-x-large"/>
                                    </span>
                                </Popover>
                                <Popover placement="rightTop" content={"Camera Controls"}>
                                    <span style={{cursor: "pointer",zIndex:"10"}} data_tut="reactour__cameraheight" onClick={props.changeCameraState}>
                                    <img src={props.cameraSelected == false ? "/img/camera_default_silo.png" : "/img/camera_default_silo.png"} className="contained-icon-x-large"/>
                                    </span>
                                </Popover>
                            </div>
                        </Row>
                        <div id = "grid">
                                <img id="grid-snapshot" src={require("../../../assets/images/grid.png")} style={{display: "none" , position: "absolute" ,zIndex:"1"}} />
                        </div>
                        <div id="overlay-blur-top" className='overlay-blur'>
                        </div>
                        <div id="overlay-blur-bottom" className='overlay-blur'>
                        </div>
                        <div id="overlay-blur-left" className='overlay-blur'>
                        </div>
                        <div id="overlay-blur-right" className='overlay-blur'>
                        </div>
                    </div>
                
                </div>
                <SiloSettings border={true} {...props}/>
            </div>
            </div>
        </Modal>
    </div>
  )
}
