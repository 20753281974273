import React from "react";
import axios from 'axios';
import "antd/dist/antd.css";
import ENVIRONMENTS from '../../../../environments'
import '@ant-design/compatible/assets/index.css';
import {  Row, Col, Button, message, Select, Typography, Table, Image, Rate,Input,Space  } from "antd";
import { LoadingOutlined,SearchOutlined } from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";

const { Option } = Select;
const { Text } = Typography;
const days_of_week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']


class AvailabilitySummary extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            filter_by_day: '',
            data_fetched: false,
            artist_list: [],
            table_data: [],

          };
    }

    componentDidUpdate = (prevProps) => {
        if(!this.props.is_loading && prevProps!=this.props){
            this.setState({
                artist_list: this.props.artists_data,
                data_fetched: !this.props.is_loading
            }, ()=> this.setTableData())
        }
      }
    
    componentDidMount() {
        this.setState({
            artist_list: this.props.artists_data,
            data_fetched: !this.props.is_loading
        }, ()=> this.setTableData())
    }
    getColumnSearchProps = (dataIndex,placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${placeholder}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        }
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
  getTotalHours = (val) => {
      let total_hours = 0;
      for(let day of days_of_week){
        if(!isNaN(val.availability[day])){
            total_hours += parseInt(val.availability[day]);
        }
    }
    return total_hours;
  }


  setTableData = () => {
      let table_data = [];
      for(let val of this.state.artist_list){
          if(val.is_active == true){
          table_data.push({
              artist_name:val['artist_profile'],
              artist_profile: <span>{<a style={{marginRight: 10}} className="onhover-underline" href={'../admin_artist_details/' + val['id']}>{val['artist_profile']}</a>}</span>,
              full_name: val['full_name'],
              Monday: this.getReadableAvailabilityValue(val.availability['Monday']),
              Tuesday: this.getReadableAvailabilityValue(val.availability['Tuesday']),
              Wednesday: this.getReadableAvailabilityValue(val.availability['Wednesday']),
              Thursday: this.getReadableAvailabilityValue(val.availability['Thursday']),
              Friday: this.getReadableAvailabilityValue(val.availability['Friday']),
              Saturday: this.getReadableAvailabilityValue(val.availability['Saturday']),
              Sunday: this.getReadableAvailabilityValue(val.availability['Sunday']),
              total: this.getTotalHours(val)

          })
        }
      }
      this.setState({
          table_data: table_data
      })
  }

  getReadableAvailabilityValue = (value) => {
    if(value == 0)
      return 'off';
    else
      return value;
  }

  displayColumn = (col) => {
        if(this.state.filter_by_day == '')
            return true;
        else if(col.dataIndex == 'artist_profile' || col.dataIndex == 'full_name' || col.dataIndex == this.state.filter_by_day){
            return true;
        }
        return false;
  }

  columns = () => {
    return [
        {
            title: 'Artist Username',
            dataIndex: 'artist_profile',
            align: 'center',
            ...this.getColumnSearchProps('artist_name','Artist Username'),
            
        },
        {
            title: 'Artist Name',
            dataIndex: 'full_name',
            align: 'center',
            ...this.getColumnSearchProps('full_name','Artist Name'),
        },
        {
            title: 'Mon',
            dataIndex: 'Monday',
            align: 'center'
        },
        {
            title: 'Tues',
            dataIndex: 'Tuesday',
            align: 'center'
        },
        {
            title: 'Wed',
            dataIndex: 'Wednesday',
            align: 'center'
        },
        {
            title: 'Thurs',
            dataIndex: 'Thursday',
            align: 'center'
        },
        {
            title: 'Fri',
            dataIndex: 'Friday',
            align: 'center'
        },
        {
            title: 'Sat',
            dataIndex: 'Saturday',
            align: 'center'
        },
        {
            title: 'Sun',
            dataIndex: 'Sunday',
            align: 'center'
        },
        {
            title: 'Total',
            dataIndex: 'total',
            align: 'center'
        }
    
      ].filter(col => this.displayColumn(col))
    
  }



  filterAvailability = () => {
      this.columns()

  }

  displaySummary = () => {
        if(this.state.filter_by_day == '')
            return false;
        else {
            let total_hours = 0;
            let count = 0;
            for(let val of this.state.table_data){
                count += 1;
                if(!isNaN(val[this.state.filter_by_day])){
                    total_hours += parseInt(val[this.state.filter_by_day])
                }
            }
    
            return (
                <>
                <Table.Summary.Row style={{backgroundColor: '#FAFAFA', textAlign: 'center', fontWeight: 'bold'}}>
                    <Table.Summary.Cell colSpan={2}>Total Artists: {count}</Table.Summary.Cell>
                    <Table.Summary.Cell>
                    <Text>Total Hours: {total_hours}</Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
                </>
            );
            }
  }

  render() {
    return (
        <Row style={{marginTop: 20}}>
            <Col span={24}>
                <div style={{paddingBottom: 10}}>
                <Select placeholder="Selected Day: All" size={"large"} style={{width: 300}} onChange={val => {this.setState({filter_by_day: val}, ()=>this.filterAvailability())}}>
                    <Option className="manrope f-14 black-55 select-view" value="">Selected Day: All</Option>
                    <Option className="manrope f-14 black-55 select-view" value="Monday">Selected Day: Monday</Option>
                    <Option className="manrope f-14 black-55 select-view" value="Tuesday">Selected Day: Tuesday</Option>
                    <Option className="manrope f-14 black-55 select-view" value="Wednesday">Selected Day: Wednesday</Option>
                    <Option className="manrope f-14 black-55 select-view" value="Thursday">Selected Day: Thursday</Option>
                    <Option className="manrope f-14 black-55 select-view" value="Friday">Selected Day: Friday</Option>
                    <Option className="manrope f-14 black-55 select-view" value="Saturday">Selected Day: Saturday</Option>
                    <Option className="manrope f-14 black-55 select-view" value="Sunday">Selected Day: Sunday</Option>
                </Select>
                </div>
            <Table
            columns={this.columns()}
            bordered={true}
            dataSource={this.state.table_data}
            pagination={true}
            loading={{indicator: <LoadingOutlined/>, spinning: this.props.is_loading}}
            summary={pageData => this.displaySummary()}
            >
            </Table>
            </Col>
        </Row>
    );
  }
}

export default AvailabilitySummary;