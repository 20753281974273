import React, { useContext } from 'react';
import FileConstants from '../../../../FileConstants';
import { Row, Tabs } from 'antd';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import ProductQANavigation from './ProductQANavigation';
import DottedLoader from '../../DottedLoader';
import ProductCollaborateQAContainer from './ProductCollaborateQAContainer';
import ProductQAActionButtons from './ProductQAActionButtons';
import ProductModelInformation from './ProductModelInformation';
import ProductModelSizeInformation from './ProductModelSizeInformation';
import ModelReferenceInfo from '../ProductComponents/ModelReferenceInfo';
import ARViewTab from './ARViewTab';
import SubmitFeedbackModal from './SubmitFeedbackModal';
import RejectionModal from './RejectionModal';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import ProductValidationState from './ProductValidationState';
import RejectionDetails from './RejectionDetails';
import RejectionDetailsModals from './RejectionDetailsModals';
import ProductQACanvasLoader from './ProductQACanvasLoader';

const { TabPane } = Tabs;

const ProductQAPageView = () => {
    const { access_level, productAssetData, collaborateMode, productData,
        setPaymentFailureModal, paymentFailureModal, productQAImages,
        inAppLimitExceededModal, setInAppLimitExceededModal, initialLoader,
        updatePaymentDetailsOnFailure, paymentFailureMessage, projectID,
        totalFileSize, changeAssetTab, selectedAsset, product_id } = useContext(CollaborateQAContext);

    return (productAssetData && productData ? <>
        <ProductQANavigation />

        {(productData && productData.model_status == 4) ?
            <Tabs className={`product-qa-tabpane-margin ${collaborateMode ? `qa` : ``}`} onChange={(key) => changeAssetTab(key)} activeKey={selectedAsset}>
                <TabPane
                    tab={collaborateMode && productQAImages && productQAImages.length > 0 ? "" : <h4 className='manrope f-14 black-33 m-0'>
                        <img src="/img/collaborate_qa/FileImage.svg" /> Images
                    </h4>} key="images" className={collaborateMode ? `product-qa-tab-overflow` : ``}>
                    <Row className={`product-qa-bg`}>
                        <ProductCollaborateQAContainer />
                        <ProductQACanvasLoader/>
                    </Row>
                </TabPane>
                {collaborateMode && productQAImages && productQAImages.length > 0 ? "" : <>
                    <TabPane tab={<h4 className='manrope f-14 black-33 m-0'><img src="/img/collaborate_qa/Block.svg" /> 3D View</h4>} key="3d_preview">
                        <div className='product-qa-bg'>
                            <Row id='model-inspector-frame' className='model-inspector-viewer-customer'>
                                <iframe id='model-viewer-3d' className='model-inspector-frame-customer' src={FileConstants.ModelInspector + product_id} />
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tab={<h4 className='manrope f-14 black-33 m-0'><img src="/img/collaborate_qa/Mobile.svg" /> AR View</h4>} key="ar_view">
                        <Row className="product-qa-bg">
                            <ARViewTab />
                        </Row>
                    </TabPane>
                </>}
            </Tabs> : ''}
        <ProductValidationState />
        {collaborateMode ? "" : <RejectionDetails />}
        {collaborateMode ? "" : <RejectionDetailsModals />}
        {collaborateMode ? "" :
            <>
                <ProductModelInformation />
                {totalFileSize != 0 && <ProductModelSizeInformation />}
            </>}
        {collaborateMode ? "" :
            <ModelReferenceInfo context_for={"collaborate_qa"} />}
        {((["co-owner", "owner"].includes(access_level) || (projectID && access_level == "edit")) &&
            productData &&
            productData.model_status == 4) &&
            <ProductQAActionButtons />}
        <SubmitFeedbackModal />
        <RejectionModal />
        <PaymentFailureModal
            paymentFailureMessage={paymentFailureMessage}
            setPaymentFailureModal={setPaymentFailureModal}
            updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
            paymentFailureModal={paymentFailureModal}
        />

        <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />

    </> : <DottedLoader />)

}

export default ProductQAPageView;