import React from 'react';
import { Col, Button,Card, Tooltip } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import ENVIRONMENT  from '../../../../environments'
import PanoramaViewer from '../../../App/CustomerComponents/PanoramaViewer';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const ComplaintImageViewer = (props) => {
    return(<div id="fullscreen-image">
        {props.status=="resolved" || props.status=="admin_qa" || props.status=="sent_back_to_artist" ? 
            <Button style={{ top: props.isFullScreen ? 20 : 138,marginRight: 10}} className={`modal-okay square font-14 ${!props.compare_state ? `flag-compare` : `flag-compare true`}`} key="submit" onClick={props.toggleCompareButton}>
              {!props.compare_state ? <span>Compare with Flagged Image &nbsp;<ArrowRightOutlined/></span> : <span><ArrowLeftOutlined/>&nbsp;Back To Original View</span>}
            </Button>
        : ''}
        {props.status=="in_progress" ? 
        <span>     
          <Card className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles auto-height"}>
            <img className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles image"} style={{objectFit:"scale-down"}} src={"/img/in_progress.png"} />
            <Button className="modal-okay square flag-in-progress " key="submit">
              <div className="modal-okay-text">Render In Progress</div>
            </Button>
          </Card> 
        </span>
        :
        props.status == "pending" || props.status=="rejected" ? 
        <Col span={24}>
           <Tooltip title={( props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
               <img src={ props.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}  alt="Fullscreen Icon" className={ props.isFullScreen ?`fullscreen-360 exit dim` :`fullscreen-360 exit dim`} 
               onClick={ props.change360FullScreen} type={( props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
           </Tooltip>

           {props.type == "scene_360" ? <div>
            <PanoramaViewer  id={props.selected_image + '_old_pending'} customClass={ props.isFullScreen ? 'room-panorama-full-screen': 'panorama-default-styles large'} threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + props.selected_image + '.' + props.selected_format.replace('tiff', 'jpg')} />

            {(Array.isArray( props.three_sixties) == true &&  props.three_sixties.length > 1) ? 
            <div style={{display:"flex",position:props.isFullScreen ? "fixed" : "absolute", bottom: 20}} className="scrollable-items"> 
                { props.three_sixties.map((name,index) => (
                  props.flagged_bool_check[name.filename.split('.')[0]] ?
                <Card onClick={() =>  props.changeSelectedAsset(name.filename)} key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="scene-card-bg flag bt-0" style={{border: name.filename.split('.')[0] ==  props.selected_image ? "1px solid #ffffff" : "1px solid #333333"}}> 
                    <span className="flag-card-settings"> {name.filename.split('.')[0].split('_')[0]}</span>
                </Card>: ''
                ))}
            </div> : ""}
           </div>
           :
           <Card className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles auto-height"}>
                <img className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles image"} style={{objectFit:"scale-down"}} src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' +  props.selected_image + '.' + props.selected_format.replace('tiff', 'jpg')} />
                {
                ((Array.isArray( props.lifestyle_renders) == true &&  props.lifestyle_renders.length > 1)) ? 
                <div className="unhappy-div scrollable-items" style={{display:"flex"}}> 
                { props.lifestyle_renders.map((name,index) => (
                  props.flagged_bool_check[name.filename.split('.')[0]] ?
                    <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="unhappy-selected-card" style={{border: name.filename.split('.')[0] ==  props.selected_image ? "1px solid #333333" : "1px solid #f0f0f0"}}> 
                    {props.flagged_bool_check[name.filename.split('.')[0]] ?
                    <Tooltip placement='right' title={'Flagged By Customer'}>
                        <div className="note-bg-artist deep-red flag-note-pos">
                        <img className="flag-img" src="/img/flag_selected.png"/>
                        </div>
                    </Tooltip> : ''}

                    <img onClick={() =>  props.changeSelectedAsset(name.filename)} className="unhappy-image-property" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + name.filename.replace('tiff', 'jpg')} alt={name.filename}/>
                    <span className="artist-upload-txt scene-name small">{name.filename.split('.')[0]}</span>
                    </Card>: ''))}
                </div>
                : ""
                }
            </Card>}
        </Col>: 
        !props.compare_state && (props.status == "resolved" || props.status == "admin_qa" || props.status=="sent_back_to_artist") ? 
        <Col span={24}>
          <Tooltip title={( props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
            <img src={ props.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" className={ props.isFullScreen ? `fullscreen-360 exit dim` : `fullscreen-360 exit dim`} 
            onClick={ props.change360FullScreen} type={( props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
          </Tooltip>
          {props.type == "scene_360" ? 
          <div>
          {props.selected && props.status == "admin_qa" && props.new_renders.includes(props.selected) && !props.superArtistComplaintPage ? 
            <Tooltip placement="right" title={props.approve_render[props.selected.split('.')[0]] ? 'Click to Unapprove.' : 'Approve this and all other flagged to resolve the complaint.'}>
                <div className={props.approve_render[props.selected.split('.')[0]]?  `approve-render-bg dark-green` :  `approve-render-bg`} onClick={props.approveRender}>
                    <span className={props.approve_render[props.selected.split('.')[0]]? `approve-render approved`: `approve-render`}>
                        Approve{props.approve_render[props.selected.split('.')[0]]?<span>d&nbsp;<CheckOutlined/></span>: ''}
                    </span>
                </div>
            </Tooltip> 
            : props.selected && props.flagged_bool_check[props.selected.split('.')[0]] && props.status == "admin_qa" && !props.superArtistComplaintPage ?  
            <Tooltip placement="right" title={props.original_renders[props.selected.split('.')[0]] ? 'Click to Deselect.' : 'Use Original Render for this flagged image.'}>
                <div className={"approve-render-bg white"} onClick={props.useOriginalRender}>
                    <span className={"share-modal-input"}>
                        {props.original_renders[props.selected.split('.')[0]] ?
                        <span>Use Original Render&nbsp;<CheckOutlined style={{color: "#29B862"}}/></span>: ''}
                    </span>
                </div>
            </Tooltip>
            : ''}
            {props.selected && props.new_renders.includes(props.selected) ? //if new renders are generated then show the new renders, otherwise show the old render
                <PanoramaViewer id={props.selected.split('.')[0] + '_new_compare'} area={props.selected.split('.')[0]} customClass={ props.isFullScreen ? 'room-panorama-full-screen': 'panorama-default-styles large'} threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.COMPLAINT_URI +  props.complaint_id + '/' +  props.selected} />
                :props.selected ?
                <PanoramaViewer id={props.selected.split('.')[0] + '_new_compare'} area={props.selected.split('.')[0]} customClass={ props.isFullScreen ? 'room-panorama-full-screen': 'panorama-default-styles large'} threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + props.selected} />:''
            }

          {
          (Array.isArray( props.complaint_360) == true &&  props.complaint_360.length > 1) ? 
          <div style={{display:"flex",position:props.isFullScreen ? "fixed" : "absolute", bottom: 20}} className="scrollable-items"> 
            { props.complaint_360.map((name,index) => (
              name.generated_by_admin || props.flagged_bool_check[name.filename.split('.')[0]] ?
              <Card onClick={() =>  props.changeSelectedRender(name.filename)} key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="scene-card-bg flag bt-0" style={{border: name.filename.split('.')[0] ==  props.selected.split('.')[0] ? "1px solid #ffffff" : "1px solid #333333"}}> 
              {(name.generated_by_admin || name.flagged_by_user) && props.status == "resolved" ?
              <Tooltip placement='right' title={'Flagged By Customer'}>
                <div className="note-bg-artist green flag-note-pos">
                  <img className="flag-img" src="/img/flag_selected.png"/>
                </div>
              </Tooltip> : ''}
              {
                name.generated_by_admin && props.approve_render[name.filename.split('.')[0]] ? 
                <Checkbox checked={props.approve_render[name.filename.split('.')[0]]} approve_render={name.filename.split('.')[0]} onChange={props.selectForApproval} style={{marginRight: 10}} className="light-green-checkbox checkbox-pos"/> : ''
              }
                <span className="flag-card-settings"> {name.filename.split('.')[0].split('_')[0]}</span>
              </Card>: ''
            ))}
          </div> : ""
          } 
          </div> :
          <Card className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles auto-height"}>
          {props.selected && props.status == "admin_qa" && props.new_renders.includes(props.selected) && !props.superArtistComplaintPage ? 
            <Tooltip placement="right" title={props.approve_render[props.selected.split('.')[0]] ? 'Click to Unapprove.' : 'Approve this and all other flagged to resolve the complaint.'}>
              <div className={props.approve_render[props.selected.split('.')[0]]? `approve-render-bg dark-green`: `approve-render-bg`} onClick={props.approveRender}>
                <span className={props.approve_render[props.selected.split('.')[0]]? `approve-render approved`: `approve-render`}>Approve{props.approve_render[props.selected.split('.')[0]]?<span>d&nbsp;<CheckOutlined/></span>: ''}</span>
              </div></Tooltip> 
              : props.flagged_bool_check[props.selected.split('.')[0]] && props.status == "admin_qa" && !props.superArtistComplaintPage ?  
              <Tooltip placement="right" title={props.original_renders[props.selected.split('.')[0]] ? 'Click to Deselect.' : 'Use Original Render for this flagged image.'}>
              <div className={"approve-render-bg white"} onClick={props.useOriginalRender}>
                <span className={"share-modal-input"}>{props.original_renders[props.selected.split('.')[0]]?<span><CheckOutlined style={{color: "#29B862"}}/>&nbsp;</span>: ''}Use Original Render</span>
              </div></Tooltip>
              : ''}
              {props.new_renders.includes(props.selected) ?
              <img className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles image"} style={{objectFit:"scale-down"}} src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.COMPLAINT_URI +  props.complaint_id + '/' +  props.selected.replace('tiff', 'jpg')} /> :
              <img className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles image"} style={{objectFit:"scale-down"}} src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + props.selected.replace('tiff', 'jpg')} /> 
              }
            {
            ((Array.isArray( props.complaint_lifestyle) == true &&  props.complaint_lifestyle.length > 1)) ? 
            <div style={{display:"flex"}} className="unhappy-div scrollable-items"> 
            { 
              props.complaint_lifestyle.map((name,index) => (
              name.generated_by_admin || props.flagged_bool_check[name.filename.split('.')[0]] ?
              <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="unhappy-selected-card" style={{border: name.filename ==  props.selected ? "1px solid #333333" : "1px solid #f0f0f0"}}> 
              {props.flagged_bool_check[name.filename.split('.')[0]] && props.status == "resolved" ?
                <Tooltip placement='right' title={'Flagged By Customer'}>
                  <div className="note-bg-artist green flag-note-pos">
                    <img className="flag-img" src="/img/flag_selected.png"/>
                  </div>
                </Tooltip> : ''}
                {
                  name.generated_by_admin && props.approve_render[name.filename.split('.')[0]] ? 
                  <Checkbox checked={props.approve_render[name.filename.split('.')[0]]} approve_render={name.filename.split('.')[0]} onChange={props.selectForApproval} style={{marginRight: 10}} className="light-green-checkbox checkbox-pos"/> : ''
                }
                {name.generated_by_admin ? 
                <img onClick={() =>  props.changeSelectedRender(name.filename)} className="unhappy-image-property" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.COMPLAINT_URI +  props.complaint_id + '/' + name.filename.replace('tiff', 'jpg')} alt={name.filename}/>:  
                <img onClick={() =>  props.changeSelectedRender(name.filename)} className="unhappy-image-property" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + name.filename.replace('tiff', 'jpg')} alt={name.filename}/>}
                <span className="artist-upload-txt scene-name small">{name.filename.split('.')[0]}</span>
              </Card>: ''))}
              </div>
            : ""
            }
          </Card> }
      </Col> :
       props.compare_state ? 
       props.type == "scene_360" ?
       <span style={{display:"flex"}}>
        <Col span={12}>
        
        {props.selected && props.status == "admin_qa" && props.new_renders.includes(props.selected) && !props.superArtistComplaintPage ? 
        <Tooltip placement="right" title={props.approve_render[props.selected.split('.')[0]] ? 'Click to Unapprove.' : 'Approve this and all other flagged to resolve the complaint.'}>
            <div className={props.approve_render[props.selected.split('.')[0]]? `approve-render-bg dark-green`: `approve-render-bg`} onClick={props.approveRender}>
            <span className={props.approve_render[props.selected.split('.')[0]]? `approve-render approved`: `approve-render`}>Approve{props.approve_render[props.selected.split('.')[0]]?<span>d&nbsp;<CheckOutlined/></span>: ''}</span>
            </div></Tooltip> 
            : props.flagged_bool_check[props.selected.split('.')[0]] && props.status == "admin_qa" && !props.superArtistComplaintPage ?  
            <Tooltip placement="right" title={props.original_renders[props.selected.split('.')[0]] ? 'Click to Deselect.' : 'Use Original Render for this flagged image.'}>
            <div className={"approve-render-bg white"} onClick={props.useOriginalRender}>
            <span className={"share-modal-input"}>{props.original_renders[props.selected.split('.')[0]]?<span><CheckOutlined style={{color: "#29B862"}}/>&nbsp;</span>: ''}Use Original Render</span>
            </div></Tooltip>
            : ''}
        {props.new_renders.includes(props.selected) ?
            <PanoramaViewer  id={props.selected.split('.')[0] + '_new'}  customClass={ props.isFullScreen ? 'room-panorama-full-screen': 'panorama-default-styles large'} threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.COMPLAINT_URI +  props.complaint_id + '/' +  props.selected} />
            :
            <PanoramaViewer  id={props.selected.split('.')[0] + '_new'} customClass={ props.isFullScreen ? 'room-panorama-full-screen': 'panorama-default-styles large'} threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + props.selected} />
        }

        {
        (Array.isArray( props.complaint_360) == true &&  props.complaint_360.length > 1) ? 
        <div style={{display:"flex",position:props.isFullScreen ? "fixed" : "absolute", bottom: 20}} className="scrollable-items"> 
            { props.complaint_360.map((name,index) => (
            name.generated_by_admin || props.flagged_bool_check[name.filename.split('.')[0]] ?
            <Card onClick={() =>  props.changeSelectedRender(name.filename)} key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="scene-card-bg flag bt-0" style={{border: name.filename.split('.')[0] ==  props.selected.split('.')[0] ? "1px solid #ffffff" : "1px solid #333333"}}> 
            {(name.generated_by_admin || name.flagged_by_user) && props.status == "resolved" ?
            <Tooltip placement='right' title={'Flagged By Customer'}>
                <div className="note-bg-artist green flag-note-pos">
                <img className="flag-img" src="/img/flag_selected.png"/>
                </div>
            </Tooltip> : ''}
            {
                name.generated_by_admin && props.approve_render[name.filename.split('.')[0]] ? 
                <Checkbox checked={props.approve_render[name.filename.split('.')[0]]} approve_render={name.filename.split('.')[0]} onChange={props.selectForApproval} style={{marginRight: 10}} className="light-green-checkbox checkbox-pos"/> : ''
            }
                <span className="flag-card-settings"> {name.filename.split('.')[0].split('_')[0]}</span>
            </Card>: ''
            ))}
        </div> : ""
        } 
        
        </Col>
        <Col span={12}>
            <Tooltip title={( props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
              <img src={ props.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" className={`fullscreen-360 exit dim`} 
              onClick={ props.change360FullScreen} type={( props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
            </Tooltip>
           <PanoramaViewer id={props.selected_image.split('.')[0] + '_old'} customClass={ props.isFullScreen ? 'room-panorama-full-screen': 'panorama-default-styles large'} threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + "/" +  props.selected_image.split('.')[0] +"." + props.selected_format.replace('tiff', 'jpg')} />
            {
            (Array.isArray(  props.three_sixties) == true &&   props.three_sixties.length > 1) ? 
            <div style={{display:"flex",position:props.isFullScreen ? "fixed" : "absolute", bottom: 20}} className="scrollable-items"> 
              {  props.three_sixties.map((name,index) => (
                
                props.generated_renders.includes(name.filename.split('.')[0]) || props.flagged_bool_check[name.filename.split('.')[0]] ?
                <Card onClick={() =>  props.changeSelectedAsset(name.filename)} key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="scene-card-bg flag bt-0" style={{border: name.filename.split('.')[0] ==  props.selected_image ? "1px solid #ffffff" : "1px solid #333333"}}> 
                  {props.flagged_bool_check[name.filename.split('.')[0]] ?
                  <Tooltip placement='right' title={'Flagged By Customer'}>
                    <div className="note-bg-artist deep-red flag-note-pos">
                      <img className="flag-img" src="/img/flag_selected.png"/>
                    </div>
                  </Tooltip> : ''}
                  <span className="flag-card-settings"> {name.filename.split('.')[0].split('_')[0]}</span>
                </Card>
                : ''
              ))}
            </div> : ""
            }            
          </Col>
        </span> : 
        <span style={{display:"flex"}}>
          <Col span={12}>
          <Card className={( props.isFullScreen) ? "full-img img-width-pc" : "panorama-default-styles auto-height"}>
          {props.selected && props.status == "admin_qa" && props.new_renders.includes(props.selected) && !props.superArtistComplaintPage ? 
            <Tooltip placement="right" title={props.approve_render[props.selected.split('.')[0]] ? 'Click to Unapprove.' : 'Approve this and all other flagged to resolve the complaint.'}>
              <div className={props.approve_render[props.selected.split('.')[0]]? `approve-render-bg dark-green`: `approve-render-bg`} onClick={props.approveRender}>
                <span className={props.approve_render[props.selected.split('.')[0]]? `approve-render approved`: `approve-render`}>Approve{props.approve_render[props.selected.split('.')[0]]?<span>d&nbsp;<CheckOutlined/></span>: ''}</span>
              </div></Tooltip> 
              : props.flagged_bool_check[props.selected.split('.')[0]] && props.status == "admin_qa" && !props.superArtistComplaintPage ?  
              <Tooltip placement="right" title={props.original_renders[props.selected.split('.')[0]] ? 'Click to Deselect.' : 'Use Original Render for this flagged image.'}>
              <div className={"approve-render-bg white"} onClick={props.useOriginalRender}>
                <span className={"share-modal-input"}>{props.original_renders[props.selected.split('.')[0]]?<span><CheckOutlined style={{color: "#29B862"}}/>&nbsp;</span>: ''}Use Original Render</span>
              </div></Tooltip>
              : ''}
              {props.new_renders.includes(props.selected) ?
              <img className={( props.isFullScreen) ? "full-img img-width-pc" : "panorama-default-styles image"} style={{objectFit:"scale-down"}} src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.COMPLAINT_URI +  props.complaint_id + '/' +  props.selected.replace('tiff', 'jpg')} /> :
              <img className={( props.isFullScreen) ? "full-img img-width-pc" : "panorama-default-styles image"} style={{objectFit:"scale-down"}} src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + props.selected.replace('tiff', 'jpg')} /> 
              }
            {
            ((Array.isArray( props.complaint_lifestyle) == true &&  props.complaint_lifestyle.length > 1)) ? 
            <div style={{display:"flex"}} className="unhappy-div scrollable-items"> 
            { 
              props.complaint_lifestyle.map((name,index) => (
              name.generated_by_admin || props.flagged_bool_check[name.filename.split('.')[0]] ?
              <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="unhappy-selected-card" style={{border: name.filename ==  props.selected ? "1px solid #333333" : "1px solid #f0f0f0"}}> 
              {props.flagged_bool_check[name.filename.split('.')[0]] && props.status == "resolved" ?
                <Tooltip placement='right' title={'Flagged By Customer'}>
                  <div className="note-bg-artist green flag-note-pos">
                    <img className="flag-img" src="/img/flag_selected.png"/>
                  </div>
                </Tooltip> : ''}
                {
                  name.generated_by_admin && props.approve_render[name.filename.split('.')[0]] ? 
                  <Checkbox checked={props.approve_render[name.filename.split('.')[0]]} approve_render={name.filename.split('.')[0]} onChange={props.selectForApproval} style={{marginRight: 10}} className="light-green-checkbox checkbox-pos"/> : ''
                }
                {name.generated_by_admin ? 
                <img onClick={() =>  props.changeSelectedRender(name.filename)} className="unhappy-image-property" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.COMPLAINT_URI +  props.complaint_id + '/' + name.filename.replace('tiff', 'jpg')} alt={name.filename}/>:  
                <img onClick={() =>  props.changeSelectedRender(name.filename)} className="unhappy-image-property" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + name.filename.replace('tiff', 'jpg')} alt={name.filename}/>}
                <span className="artist-upload-txt scene-name small">{name.filename.split('.')[0]}</span>
              </Card>: ''))}</div>
            : ""
            }
          </Card> 
            
          </Col>
          <Col span={12}>
            <Tooltip title={( props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
              <img src={ props.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" className={`fullscreen-360 exit dim`}
              onClick={ props.change360FullScreen} type={( props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
            </Tooltip>
              
            <Card className={( props.isFullScreen) ? "full-img img-width-pc" : "panorama-default-styles auto-height"}>
              <img className={( props.isFullScreen) ? "full-img img-width-pc" : "panorama-default-styles image"} style={{objectFit:"scale-down"}} src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' +  props.selected_image + "." +props.selected_format.replace('tiff', 'jpg')} />
              {
              ((Array.isArray( props.lifestyle_renders) == true &&  props.lifestyle_renders.length > 1)) ? 
              <div style={{display:"flex"}} className="unhappy-div scrollable-items"> { props.lifestyle_renders.map((name,index) => (
                props.generated_renders.includes(name.filename.split('.')[0]) || props.flagged_bool_check[name.filename.split('.')[0]] ?
                <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="unhappy-selected-card" style={{border: name.filename.split('.')[0] ==  props.selected_image ? "1px solid #333333" : "1px solid #f0f0f0"}}> 
                {props.flagged_bool_check[name.filename.split('.')[0]] ?
                  <Tooltip placement='right' title={'Flagged By Customer'}>
                    <div className="note-bg-artist deep-red flag-note-pos">
                      <img className="flag-img" src="/img/flag_selected.png"/>
                    </div>
                  </Tooltip> : ''}
                  <img onClick={() =>  props.changeSelectedAsset(name)} className="unhappy-image-property" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + name.filename.replace('tiff', 'jpg')} alt={name.filename}/>
                  <span className="artist-upload-txt scene-name small">{name.filename.split('.')[0]}</span>
                </Card>: ''))}</div>
              : ""
              }
            </Card> 
            
          </Col>
          </span> : ''
        }
    </div>);
}

export default ComplaintImageViewer;