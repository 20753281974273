import React, { useEffect, useMemo } from "react";

import { Col, Button, Card, Image } from "antd";

import { DownloadOutlined } from "@ant-design/icons";

import FileConstants from "../../../../FileConstants";
import AssetGrid from "./AssetGrid";
import { BASE_URL } from "../../../../environments/env";
import { saveToZip } from "./utils";
import ENVIRONMENT from "../../../../environments";

const COMPANY_ID = FileConstants.COMPANY_ID;

const ReferenceInfoSection = ({ assetsCollection, roomDetails, storePage,platform,elements }) => {

  // + encodeURIComponent(photo.uid + '/' + photo.name)

  const assetsList = useMemo(
    () =>
      assetsCollection.map((obj) => ({
        ...obj,
        link: obj?.uid
          ? ENVIRONMENT.getBaseURL(platform) +
            ENVIRONMENT.BASE_URI
            + encodeURIComponent(obj.uid + '/' + obj.name)
            // obj?.uid?.replace(/%/g, "") +
            // "/" +
            // obj?.name?.replace(/%/g, "")
          : obj?.image,
      })),
    [assetsCollection]
  );

  useEffect(()=>{
    console.log('ELEMENTS: ',elements)
  },[elements])

  const assetLinks = useMemo(
    () => assetsList.map((asset) => asset?.link),
    [assetsList]
  );

  const downloadReferenceFiles = () => {
    if (assetLinks?.length > 0) saveToZip("Reference Files.zip", assetLinks);
  };

  return (
    <Col
      span={24}
      className="reference-section"
      style={{ width: "100vw", paddingLeft: "40px", paddingBottom: "140px" }}
    >
      {storePage === 0 ? (
        <div className="display-flex j-s-b a-c w-100 mb-30">
          <h3 className="mb-0 manrope f-24 black-26">
            Space Reference Info
          </h3>
          <Button
            onClick={downloadReferenceFiles}
            className="product-btn br-4 f-14 light-blue"
          >
            <DownloadOutlined />
            Download References
          </Button>
        </div>
      ) : (
        <></>
      )}
      {roomDetails.reference_urls.length > 0 ? (
        <div
          className="display-flex flex-col w-100"
          style={{justifyContent: "start" }}
        >
          <h3 className="mb-0 manrope f-16 black-26">Links</h3>
          <div className="mt-8">
            {roomDetails.reference_urls.map((url) => (
              <div>
                <a
                  href={url}
                  className="mb-0 manrope f-14 blue limit-lines limit-1"
                >
                  {url}
                </a>
                <br />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {roomDetails.guidelines  ? (
        <div
          className="display-flex flex-col w-100"
          style={{justifyContent: "start" }}
        >
          <h3 className="mb-0 manrope f-16 black-26">Guidelines</h3>
          <div className="mt-8">
            {roomDetails.guidelines ? (
              <Col span={6} style={{ marginBottom: "10px" }}>
                    <div
                      className="manrope f-16 black-33 w-400"
                      style={{ textAlign: "left", marginBottom: 4 }}
                    >
                      {roomDetails.guidelines}
                    </div>
              </Col>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {assetsCollection.length > 0 ? (
        <div
          className="display-flex flex-col w-100"
          style={{justifyContent: "start" }}
        >
          <h3
            className="mb-0 manrope f-16 black-26"
            style={{ marginBottom: "0.5rem" }}
          >
            Files
          </h3>
          <AssetGrid
            assetsCollection={assetsList}
            platform={roomDetails?.platform}
          />
        </div>
      ) : (
        <></>
      )}

      {elements &&  elements.length > 0 ? (
        <div
          className="display-flex flex-col w-100 mt-8"
          style={{justifyContent: "start" }}
        >
          <h3
            className="mb-0 manrope f-16 black-26"
            style={{ marginBottom: "0.5rem",marginTop: "10px" }}
          >
            Space Elements
          </h3>
          {elements && elements.length > 0 ?
                    <div style={{marginBottom:"12px"}}>
                    <div className="j-start-a-start ">
                    {elements.map(element => (
                        element ?
                        <Col style={{maxWidth:"25%",marginRight:"50px"}}>
                            <div style={{width:'auto',marginBottom:"4px"}}>
                                <span className="manrope f-12 black-55 capitalize">
                                    {element.elementName}
                                </span>
                            </div>
                            {((element.elementImages && element.elementImages.length > 0) || ((element.elementText && !Array.isArray(element.elementText)) || (element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0)  )) &&
                            <div>
                         {/* <Card className="reference-img-card" style={{padding: 8, marginTop: 8}}> */}
                                {element.elementImages && element.elementImages.length > 0 && <div>

                                    <div className="justify-in-start" style={{marginBottom:"4px"}}>
                                    {element.elementImages.map(photo => (
                                        <Card className="space-element-card">
                                            <Image style={{width: "100%", height: "100%", objectFit: "contain"}} src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)}
                                            />
                                        </Card>
                                    ))}
                                    </div>

                                </div>}
                                {element.elementText && !Array.isArray(element.elementText) ?
                                    <div>
                                    <div className="manrope f-12 grey-55" style={{marginBottom: 8}}>Reference URL(s)</div>
                                    <div className="manrope f-12 blue clamp-w-width" style={{maxWidth: "100px"}}>{element.elementText}</div>
                                </div>:
                                element.elementText && Array.isArray(element.elementText) && element.elementText.filter((obj)=>obj)?.length && element.elementText.length > 0 ?
                                <div>
                                    <div className="manrope f-12 grey-55" style={{marginBottom: 8}}>Reference URL(s)</div>
                                    {element.elementText.map((element_text) => (
                                        <div style={{display:'block',marginBottom: 5, maxWidth: "100px"}} className="manrope f-12 blue hover-change clamp-w-width"><a target="_blank" href={element_text}>{element_text}</a></div>
                                    ))}
                                </div> : <></>}
                            {/* </Card> */}
                            </div>
                            }
                        </Col> : ''
                    ))}
                    </div>
                </div>: ''}
        </div>
      ) : (
        <></>
      )}
    </Col>
  );
};

export default ReferenceInfoSection;
