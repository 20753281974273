import React from "react";
import { Tooltip } from "antd";
import { TagOutlined, BarcodeOutlined, ShopOutlined } from "@ant-design/icons";




const QUERY_PARAMS_TO_RETAIN = ["category", "search"];

const SpaceInfo = ({ category, itemId, companyId, companyData }) => {
  return (
    <div className="j-start-a-start row w-full mx-none" style={{flexWrap: "wrap"}}>

        {companyData?.company_display_name ? (
        <div className="flex-row items-center" style={{ marginRight: "2.5rem" }}>
          <ShopOutlined className="info-icon" />
          <div className="flex-col" style={{ marginLeft: "1rem" }}>
            <div
              className="manrope f-14 w-500 grey-8c"
              style={{
                textAlign: "left",
              }}
            >
              Brand
            </div>
            <div className="manrope f-16 black-33 w-600">
              <Tooltip
                title={
                  <span className="manrope f-12 white">
                    {companyData?.company_display_name}
                  </span>
                }
              >
                <div className="manrope f-16 w-500 lh-16 black-26 clamp-w-width">
                  {companyData?.company_display_name}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {category ? (
        <div className="flex-row items-center" style={{ marginRight: "2.5rem" }}>
          <TagOutlined className="info-icon" />
          <div className="flex-col" style={{ marginLeft: "1rem" }}>
            <div
              className="manrope f-14 w-500 grey-8c"
              style={{
                textAlign: "left",
              }}
            >
              Category
            </div>
            <div className="manrope rem-20 black-33 w-600">
              <Tooltip
                title={<span className="manrope f-12 white">{category}</span>}
              >
                <div className="manrope f-16 w-500 lh-16 black-26 clamp-w-width">
                  {category}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {itemId ? (
        <div className="flex-row items-center"x>
          <BarcodeOutlined className="info-icon" />
          <div className="flex-col" style={{ marginLeft: "1rem" }}>
            <div
              className="manrope f-14 w-500 grey-8c"
              style={{
                textAlign: "left",
               
              }}
            >
              Item ID
            </div>
            <Tooltip
                title={<span className="manrope f-12 white">{itemId}</span>}
              >
                <div
              className="manrope f-16 w-500 black-33 w-600 clamp-w-width"
              style={{ maxWidth: "80px"}}
            >
              {itemId}
            </div>
              </Tooltip>
            
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SpaceInfo;
