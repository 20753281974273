import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Row, Col, Button, Select } from "antd";
import ArtistMainLayout from '../ArtistMainLayout';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';

import SuperArtistComplaintsTable from "../SuperArtistComplaintsTable";

const { TabPane } = Tabs;
const { Option } = Select;


class SuperArtistComplaints extends React.Component {
  constructor(props){
    super(props)
  
    this.state = {
      size: "small"
    }; 
  }
 

  render() {
    const { size } = this.state;
    return (
      <ArtistMainLayout selected={"7"}>
        
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false} style={{marginTop: 30}}>
          <TabPane tab="Assigned" key="1">
                {/* This is the assigned section for the complaints but we are passing the state as pending  */}
                <SuperArtistComplaintsTable state="pending"/>
          </TabPane>
          <TabPane tab="In Progress" key="2">
                <SuperArtistComplaintsTable state="in_progress"/>
          </TabPane>
          <TabPane tab="Rejected" key="3">
                <SuperArtistComplaintsTable state="sent_back_to_artist"/>
          </TabPane>
          <TabPane tab="Admin QA" key="4">
              <SuperArtistComplaintsTable state="admin_qa"/>
          </TabPane>
          <TabPane tab="Resolved" key="5">
              <SuperArtistComplaintsTable state="resolved_and_flag_rejected"/>
          </TabPane>
        </Tabs>
      </ArtistMainLayout>
    );
  }
}

export default SuperArtistComplaints;