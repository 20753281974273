import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const ColorSelect = ({ value = undefined, onChange, className, disabled, placeholder, mode = undefined }) => {
    return (
        <Select
            className={`manrope f-10 black-55 ${className}`}
            onChange={onChange}
            disabled={disabled}
            value={value}
            showSearch
            mode={mode}
            placeholder={placeholder ? placeholder : "Search and select closest color match"}
        >
            <Option className="manrope f-10 select-view" value="Beige">Beige</Option>
            <Option className="manrope f-10 select-view" value="Black">Black</Option>
            <Option className="manrope f-10 select-view" value="Blue">Blue</Option>
            <Option className="manrope f-10 select-view" value="Brass">Brass</Option>
            <Option className="manrope f-10 select-view" value="Bronze">Bronze</Option>
            <Option className="manrope f-10 select-view" value="Brown">Brown</Option>
            <Option className="manrope f-10 select-view" value="Chrome">Chrome</Option>
            <Option className="manrope f-10 select-view" value="Copper">Copper</Option>
            <Option className="manrope f-10 select-view" value="Gold">Gold</Option>
            <Option className="manrope f-10 select-view" value="Gray">Gray</Option>
            <Option className="manrope f-10 select-view" value="Green">Green</Option>
            <Option className="manrope f-10 select-view" value="Iron">Iron</Option>
            <Option className="manrope f-10 select-view" value="Ivory/Cream">Ivory/Cream</Option>
            <Option className="manrope f-10 select-view" value="Multi-color">Multi-color</Option>
            <Option className="manrope f-10 select-view" value="Natural  (bone, almond)">Natural  (bone, almond)</Option>
            <Option className="manrope f-10 select-view" value="Navy">Navy</Option>
            <Option className="manrope f-10 select-view" value="Nickel">Nickel</Option>
            <Option className="manrope f-10 select-view" value="Orange">Orange</Option>
            <Option className="manrope f-10 select-view" value="Pattern">Pattern</Option>
            <Option className="manrope f-10 select-view" value="Pewter">Pewter</Option>
            <Option className="manrope f-10 select-view" value="Pink">Pink</Option>
            <Option className="manrope f-10 select-view" value="Purple">Purple</Option>
            <Option className="manrope f-10 select-view" value="Reflective ">Reflective</Option>
            <Option className="manrope f-10 select-view" value="Transparent">Transparent</Option>
            <Option className="manrope f-10 select-view" value="Red">Red</Option>
            <Option className="manrope f-10 select-view" value="Rose Gold">Rose Gold</Option>
            <Option className="manrope f-10 select-view" value="Rust">Rust</Option>
            <Option className="manrope f-10 select-view" value="Silver">Silver</Option>
            <Option className="manrope f-10 select-view" value="Stainless Steel">Stainless Steel</Option>
            <Option className="manrope f-10 select-view" value="Unfinished">Unfinished</Option>
            <Option className="manrope f-10 select-view" value="White">White</Option>
            <Option className="manrope f-10 select-view" value="Yellow">Yellow</Option>
            <Option className="manrope f-10 select-view" value="Other">Other</Option>
        </Select>
    );
}
export default ColorSelect;