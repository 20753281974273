import React from 'react';
import axios from 'axios';
import { ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Card,Tooltip,message, Empty } from 'antd';
import ENVIRONMENT from '../../../../environments';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';
import ModelOBJViewer from '../../CustomerComponents/OBJViewer/OBJViewer';
import {  SCANS_BASE_URL } from '../../../../environments/env';
import DottedLoader from '../../DottedLoader';

const image_style = { width: '100%',height: "calc(100vh -  282px)",objectFit:"contain" };
const fullscreen_image_style = { width: "100vw",height: "100vh",objectFit:"contain" };
class ProductStaticGuidelinesCard extends React.Component {

    state = {
        images: [],
        product_id: 0,
        product_guidelines: {},
        image: [],
        display_loader: false,
        is_fullscreen: false,
        current_scan: '',
        selected_image: ''
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                is_fullscreen: false
            });
        }
    }
    
    componentDidMount() {
        var product_id = 0;
        if (this.props.match !== undefined) {
            product_id = this.props.match.params.id;
        }
        else {
            product_id = this.props.product_id;
        }

        this.setState({ product_id: product_id });

        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                let current_scan = '';
                if (res.data && res.data.scans && res.data.scans.length > 0) {
                    current_scan = res.data.scans[0];
                }
                this.setState({
                    product_guidelines: res.data,
                    current_scan: current_scan
                }, () => {
                    this.checkScroll();
                    this.populatePhotos();
                });
            });

        document.addEventListener("keydown", this.escFunction, false);
        window.addEventListener("resize", this.checkScroll);
        document.addEventListener("wheel", () => {
            this.checkScroll();
        }, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps!= this.props) {
          this.checkScroll();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        window.removeEventListener("resize", this.checkScroll);
        document.removeEventListener("wheel", () => {
            this.checkScroll();
        }, false);
    }

    populatePhotos = () => {
        let image_list = [];
      
        //////////////////// REJECTION IMAGES //////////////////
        let rejection_and_product_photos_urls = []
        if(this.props.compareView){
            if(this.props.rejectionDetails && !this.props.match.path.includes('artist_products')){
                this.props.rejectionDetails.map((elem, index) => {
                    if(elem.qa_role == "" && !elem.customer_rejection){
                        elem.reference_files.map((ele, i) => {
                            let file_url = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                            if(!rejection_and_product_photos_urls.includes(file_url) && (file_url.includes('.jpg') || file_url.includes('.jpeg') || file_url.includes('.png')))
                                rejection_and_product_photos_urls.push(file_url);
                        })
                    }
                })
            }
        }

        image_list = rejection_and_product_photos_urls;

        //////////////////// COLOR IMAGES //////////////////
        let color_images = []
        if (this.state.product_guidelines.color_photos && this.state.product_guidelines.color_photos.length > 0) {
            this.state.product_guidelines.color_photos.map((file) => {
                let file_url = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name);
                color_images.push(file_url);
            })
        }

        if (color_images.length > 0) {
            image_list = image_list.concat(color_images);
        }

        //////////////////// VARIATION IMAGES //////////////////
        let variation_type = this.state.product_guidelines.variation_type;
        if (variation_type == 'material' || variation_type == 'state') {
            let image = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.ANNOTATED_PRODUCT_IMAGE_URI + this.state.product_id + '.jpg';
            image_list = image_list.concat(image);
        }

        
        //////////////////// HIDDEN CATEGORY ATTACHMENT //////////////////
        let hidden_category_images = []
        if (this.state.product_guidelines.category_attachment && this.state.product_guidelines.category_attachment.length > 0) {
            this.state.product_guidelines.category_attachment.map((file) => {
                let file_url = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name);
                hidden_category_images.push(file_url);
            })
        }

        if (hidden_category_images.length > 0) {
            image_list = image_list.concat(hidden_category_images);
        }
        //////////////////// PRODUCT PHOTOS //////////////////
        let product_photos = []
        Object.keys(this.state.product_guidelines.product_photos).forEach((key) => {
            let photos_array = this.state.product_guidelines.product_photos[key];
            if(!Array.isArray(photos_array)) {
                if(key in photos_array){
                    photos_array = this.state.product_guidelines.product_photos[key][key];
                }
                else {
                    photos_array = []
                }
            }
            let product_photos_subset = (photos_array || []).map((ele, index) => {
                return ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);                
            });
            if (key === 'product_photos_all') {
                product_photos = product_photos.concat(product_photos_subset);
            }
            else {
                product_photos = product_photos.concat(product_photos_subset);
            }
        });

        if (this.state.product_guidelines && this.state.product_guidelines.product_photos && Array.isArray(this.state.product_guidelines.product_photos) && product_photos && product_photos.length == 0) {
            product_photos = []
            this.state.product_guidelines.product_photos.map((ele) => {
                let file_url = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                product_photos.push(file_url);
            }); 
        }
        if (product_photos.length > 0) {
            image_list = image_list.concat(product_photos);
        }
        
      

        /////////////// Materials /////////////////////
        let material_files_array = (this.state.product_guidelines.material_files || []);
        if(!Array.isArray(this.state.product_guidelines.material_files))
        {
            if(this.state.product_guidelines.material_files !== undefined){
                if('componentImages' in this.state.product_guidelines.material_files){
                    material_files_array = this.state.product_guidelines.material_files['componentImages'];
                }
            }
        }
       
        material_files_array.map((ele, index) => {
            let file_url = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
            image_list.push(file_url);
        });


        ////// PRODUCT MANUALS /////
        if (this.state.product_guidelines.product_manuals != undefined) {
            this.state.product_guidelines.product_manuals.map((ele) => {
                let file_url = ENVIRONMENT.getBaseURL(this.props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((ele.originFileObj ? ele.originFileObj.uid : ele.uid) + '/' + ele.name);
                image_list.push(file_url);
            });
        }
  

        this.setState({
            images: image_list,
            selected_image: image_list[0]
        });
        console.log('IMAGE LIST', image_list);
    }
    
    showScan = (scan) => {
        this.setState({
            current_scan: scan, 
            selected_image: scan});

        this.forceUpdate();
    }

    leftScroll = () => {
        var elem = document.getElementById('review-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft -= 273;
            var left = document.getElementById('left-scroll-btn');
            var right = document.getElementById('right-scroll-btn');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            
            if (right!=null) {
                right.style.visibility = 'visible';
                elem.style.left = "20px";
                // forceUpdate();
            }
        }
    }

    rightScroll = () => {
        var elem = document.getElementById('review-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft += 273;
            var left = document.getElementById('left-scroll-btn');
            var right = document.getElementById('right-scroll-btn');
            elem.style.left = "94px";
            if (right != null) {
                if (left != null) {
                    left.style.display = "inline-block";
                }
                
                var newScrollLeft = elem.scrollLeft;
                var divWidth = elem.offsetWidth;
                var scrollwidth = elem.scrollWidth;
                if(newScrollLeft === scrollwidth - divWidth){
                    right.style.visibility = 'hidden';
                }
            }
            
        }
    }

    selectDisplayImage = (url) => {
        this.display_loader_and_set_image(url);
    }

    checkScroll = () => {
        var elem = document.getElementById('review-scrollable');
        var carousel_elem = document.getElementById('pdp-carousel-ref'); 
        if (elem != null) {
            elem.style.scrollBehavior = "unset";

            var y1 = elem.scrollTop;  
            elem.scrollTop += 1; 
            var y2 = elem.scrollTop ; 
            elem.scrollTop -= 1; 
            var y3 = elem.scrollTop; 
            elem.scrollTop = y1; 
        
            var x1 = elem.scrollLeft;  
            elem.scrollLeft += 1; 
            var x2 = elem.scrollLeft; 
            elem.scrollLeft -= 1; 
            var x3 = elem.scrollLeft; 
            elem.scrollLeft = x1; 
            var right = document.getElementById('right-scroll-btn');
            var left = document.getElementById('left-scroll-btn');
            let nextValue = elem.scrollLeft - 273;

            if (elem.style.left == "94px") {
                elem.style.left = "94px";
            }
            else {
                if ( elem.scrollLeft == 0) {
                    elem.style.left = "20px";
                }
            }
            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
                if (left != null) {
                    if (elem.style.left == "20px" &&  elem.scrollLeft == 0) {
                        left.style.display = "none"; 
                    }
                }
                if (elem.style.left == "20px"  &&  nextValue <= 0) {// for right scroll
                    if (window.innerWidth <= 1500 && this.props.compareView) {
                        // elem.style.width = "320px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && this.props.compareView) {
                        // elem.style.width = "300px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && this.props.compareView) {
                        // elem.style.width = "20%";
                        elem.style.width = "100%";
                    }
                    if (this.props.compareView) {
                        console.log("set width",carousel_elem);
                        carousel_elem.style.width = "80%";
                    }
                }
                else { // for left scroll
                    if (window.innerWidth <= 1500 && this.props.compareView) {
                        // elem.style.width = "320px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && this.props.compareView) {
                        // elem.style.width = "300px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && this.props.compareView) {
                        // elem.style.width = "20%";
                        elem.style.width = "100%";
                    }

                    if (this.props.compareView) {
                        console.log("set width",carousel_elem);
                        carousel_elem.style.width = "80%";
                    }
                }
            }
            if (!this.props.compareView && this.props.adminScrollCheck) {
                elem.style.width = "100%";
                elem.style.left = "20px";
            }

            if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // right.style.visibility = 'hidden';
                left.style.display = "none";
            }
            // ---- NEED FOR LATER USE ----
            // console.log("H",x1 !== x2 || x2 !== x3); 
            // console.log("V",y1 !== y2 || y2 !== y3);
        }
    }    
    
    display_loader_and_set_image = (image_src) =>{
        // First set blank image
        if (image_src && (image_src.toLowerCase().includes('jpg') || image_src.toLowerCase().includes('jpeg') || image_src.toLowerCase().includes('png') || image_src.toLowerCase().includes('svg')) ) {
            let temp_img = new Image();
            temp_img.src = require("../../../../assets/images/blank.jpeg");
            this.setState({
                selected_image: temp_img.src,
                current_scan: '',
                display_loader: true,
            });
            const img = new Image();
            let parent = this;
            img.onload = function() {
                parent.setState({
                    selected_image: img.src,
                    display_loader: false
                });
            }
            img.src = image_src;
        } else if (image_src) {
            this.setState({
                selected_image: '/img/file-icon.png',
                display_loader: false
            })
        }
        
    }
    

    
    render() {
        
        const setFullScreen  = () => {
            if(!this.state.is_fullscreen){
                message.info("Press ESC to exit full screen");
            }
            this.setState({
                is_fullscreen: !this.state.is_fullscreen
            });
        }

        let scans_cards = [];
        if(this.state.product_guidelines && this.state.product_guidelines.scans){
            this.state.product_guidelines.scans.map((ele, index) => {
                return <Col>
                    <Card bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center", cursor: "pointer" }} style={{marginRight:"10px",width:"64px",height:"64px",padding:"10px"}} onClick={() => this.showScan(ele)}>
                        <div className="product-view-icon" style={{marginTop: 8}}>
                        <span style={{background: "url(/img/scan.jpg)",objectFit:"contain",height:"36px",width:"36px", marginTop: -4}}><b style={{color: "white"}}></b></span>
                        </div>
                    </Card>
                </Col>
            });
        }

        
        return (
            <React.Fragment>
            {this.state.display_loader ? 
            <DottedLoader  custom_class={true} display_inline={true}/> : ""}
            <Card className={this.state.is_fullscreen ? "product-full-screen-card" : "product-viewer-normal"} bodyStyle={{padding: this.state.is_fullscreen ? 0 : 12}}>
                <div className="bg-model" style={{zIndex:"1",position:"absolute",top:"20px",left:"20px"}}>
                    <div className="bg-model-text">
                        Reference Scans and Images
                    </div>
                </div>
                <Tooltip title={(!this.state.is_fullscreen ? "Full Screen" : "Exit Full Screen")} placement="left">
                    <img src={this.state.is_fullscreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}  alt="Fullscreen Icon" 
                    style={{position:"absolute", bottom:"20px",zIndex:"10",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                    className="product-full-screen-icon" onClick={() => setFullScreen() } />
                </Tooltip> 
                {this.state.current_scan != ''  || this.state.images.length > 0 ? 
                    <span position='relative'>
                        {this.state.current_scan == ''?
                        <div>
                            <div style={{ display: 'flex', justifyContent: "space-around" }}>
                                <div>   
                                    <ImageZoomAdmin custom_class={this.state.is_fullscreen ? fullscreen_image_style : image_style}  compare={true} image={this.state.selected_image} />: 
                                </div> 
                            </div>
                        </div> :
                        <div>
                            <div id={`loading-container`} 
                                style={{textAlign: 'center',
                                position: 'absolute', 
                                width:'100%', 
                                height:'100%', 
                                display:'flex', 
                                alignItems:'center', 
                                justifyContent:'center',
                                backgroundColor:'white'}}>Loading ...</div>
                            <ModelOBJViewer
                            model_url={SCANS_BASE_URL + (this.state.current_scan.replace('.zip', '/'))}
                            width='100%'
                            loaderId={`loading-container`}/>  
                        </div>}
                        <div className='pdp-carousel-scroll' id='pdp-carousel-ref'>
                            <div className="icon-con-left" id="left-scroll-btn" onClick={this.leftScroll}>
                                <RightOutlined  className="left-button-scr" />
                            </div>
                            <div id="review-scrollable" className="pdp-scrollable">
                                {this.state.images && this.state.images.length > 0 && this.state.images.map((url,index)=> ((
                                    <Col>
                                        <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{marginRight:"10px",width:"64px",height:"64px",padding:"10px"}}>
                                            {url.toLowerCase().includes('.jpg') || url.toLowerCase().includes('.jpeg') || url.toLowerCase().includes('.png') || url.toLowerCase().includes('.svg') ?
                                                <img onClick={() => this.selectDisplayImage(url)} style={{ cursor: "pointer", height: "40px", width:"40px",objectFit: "contain", display: "block"}} src={url} />
                                            :   <Tooltip title={<span className='manrope f-12 white'>Click to Download</span>}>
                                                <a onClick={() => this.selectDisplayImage(url)} target="_blank" href={url}>
                                                    <img alt="References" style={{ cursor: "pointer", height: "40px",width:"40px", objectFit: "contain", display: "block"}} src={'/img/file-icon.png'} /> 
                                                </a>
                                                </Tooltip>}
                                        </Card>
                                    </Col>
                                    
                                )))}
                                {scans_cards}
                            </div>
                            <div className="icon-con" id="right-scroll-btn" onClick={this.rightScroll}>
                                <RightOutlined  className="right-button-scr" />
                            </div>
                        </div>
                    </span>
                : 
                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                    <Empty className='position-empty-data' description={
                    <span className='manrope f-14 black-55'>
                        No References Images
                    </span>
                    }/>
                </div>}
            </Card>
            </React.Fragment>);
    }
}

export default ProductStaticGuidelinesCard;
