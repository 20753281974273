import React from 'react';
import { Table } from 'antd';
import { CSVLink } from "react-csv";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class ArtistMonthlyHours extends React.Component {
  csvLink = React.createRef()
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandable,
    title: undefined,
    showHeader,
    footer: false,
    rowSelection: {},
    scroll: undefined,
    tableLayout: 'fixed',
    hasData: true,
    dataSource: [],
    csvDataSource: [],
    csvFilename: 'combined_invoice.csv'
  };

  getColumns = () => [
    {
      title: 'Product Id',
      dataIndex: 'product_id',
      key: 'product_id',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      render: text => <span>{text}</span>,
    },
    {
      title: 'High Poly',
      dataIndex: 'high_poly',
      key: 'high_poly',
      sorter: (a, b) => a - b,
    },
    {
      title: 'Low Poly',
      dataIndex: 'low_poly',
      key: 'low_poly',
      sorter: (a, b) => a - b,
    },
    {
      title: 'Vray Materials',
      dataIndex: 'vray_materials',
      key: 'vray_materials',
      sorter: (a, b) => a - b,
    },
    {
      title: 'PBR Materials',
      dataIndex: 'pbr_materials',
      key: 'pbr_materials',
      sorter: (a, b) => a - b,
    },
    {
      title: 'animation',
      dataIndex: 'Animation',
      key: 'animation',
      sorter: (a, b) => a - b,
    },
    {
      title: 'Date',
      dataIndex: 'time_spent_date',
      key: 'time_spent_date',
      sorter: (a, b) => a - b,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.jobDeskAction,
      render: (text, record) => (
        <span>
          <a onClick={() => { }}>Product Details</a>
        </span>
      ),

    }
  ];

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    let data = [];

    axios.post(ENVIRONMENT.ARTIST_HOURS_GET, {
      'artist_username': localStorage.getItem('username'),
      'month': '01-2020'
    })
      .then(res => {
        console.log(res.data)
        for (let i = 1; i <= (res.data).length; i++) {
          data.push({
            key: i,
            product_id: res.data[i - 1]['product_id'],
            product_name: res.data[i - 1]['product_name'],
            high_poly: res.data[i - 1]['high_poly'],
            low_poly: res.data[i - 1]['low_poly'],
            vray_materials: res.data[i - 1]['vray_materials'],
            pbr_materials: res.data[i - 1]['pbr_materials'],
            animation: res.data[i - 1]['animation'],
            time_spent_date: res.data[i - 1]['time_spent_date']
          });

        }
        this.setState({
          dataSource: data
        })
      });

  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  prepareCSVData = () => {
    console.log('preapring to export csv');

    let data = [];
    let dataSource = Array.from(this.state.dataSource);
    console.log(dataSource);
    for (let i = 0; i < dataSource.length; i++) {
      data.push({
        'Artist Username': dataSource[i]['name'],
        'Hours Spent': dataSource[i]['modeling_time'],
        'Products Modeled': dataSource[i]['products_count'],
        'Month': dataSource[i]['month'],
        'Hourly Rate ($)': dataSource[i]['hourly_rate'],
        'Amount ($)': parseFloat(dataSource[i]['modeling_time']) * parseFloat(dataSource[i]['hourly_rate'])
      });

    }

    console.log(data);
    this.setState({
      csvDataSource: data,
      csvFilename: 'combined_invoice.csv'
    }, () => {
      this.csvLink.current.link.click()
    })
  }

  prepareArtistCSVData = (artist_username, month) => {

    let data = [];
    let dataSource = Array.from(this.state.dataSource);
    console.log(dataSource);
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i]['name'] == artist_username && dataSource[i]['month'] == month) {
        data.push({
          'Artist Username': dataSource[i]['name'],
          'Hours Spent': dataSource[i]['modeling_time'],
          'Products Modeled': dataSource[i]['products_count'],
          'Month': dataSource[i]['month'],
          'Hourly Rate ($)': dataSource[i]['hourly_rate'],
          'Amount ($)': parseFloat(dataSource[i]['modeling_time']) * parseFloat(dataSource[i]['hourly_rate'])
        });
      }

    }

    console.log(data);
    this.setState({
      csvDataSource: data,
      csvFilename: artist_username + '_' + month + '_invoice.csv'
    }, () => {
      this.csvLink.current.link.click()
    })
  }

  render() {
    const { state } = this;
    const { dataSource, csvDataSource, csvFilename } = state;

    return (
      <div>
        <div style={{ textAlign: 'left' }}>
          <br />
          <h3>
            Product Details
            </h3>
          {/* <Button type="danger" onClick={this.prepareCSVData}>
                Export Artists Combined Invoice
            </Button> */}
          <CSVLink data={csvDataSource}
            filename={csvFilename}
            style={{ display: 'none' }}
            ref={this.csvLink}
          >

          </CSVLink>
        </div>
        <div>
          {
            dataSource &&
            <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
          }
        </div>
      </div>
    );
  }
}

export default ArtistMonthlyHours;
