import React, { useContext } from 'react';
import _ from "lodash";
import { Row, Col, Tooltip } from 'antd';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';


const TextContainer = (props) => {
    return (<Tooltip title={<span className='manrope f-12 white'>{props.value}</span>}>
        <Col span={8}>
            <div className="manrope f-14 lh-14 grey-8c text-left">{props.name}</div>
            <div className="manrope f-16 lh-16 black-26">
                {props.value}
            </div>
        </Col>
    </Tooltip>)
}

const ModelSpecifications = () => {
    const { totalFileSize, lowPolyModelSize, lowPolyVertexCount,
        lowPolyTextureSize, highPolyVertexCount,
        highPolyModelSize, highPolyTextureSize, archiveSize, glbSize, lowPolyTriangleCount, highPolyTriangleCount } = useContext(CustomerProductContext);

    return (
        <Row className="basic-detail-container j-s-b">
            {totalFileSize != 0 &&
                <TextContainer
                    name={"Total File Size"}
                    value={totalFileSize}
                />}
            {highPolyModelSize != 0 &&
                <TextContainer
                    name={"High Poly Texture + Mesh"}
                    value={highPolyModelSize}
                />}
            {highPolyTextureSize != 0 &&
                <TextContainer
                    name={"High Poly Texture"}
                    value={highPolyTextureSize}
                />}
            {lowPolyModelSize != 0 &&
                <TextContainer
                    name={"AR Low Poly Texture + Mesh"}
                    value={lowPolyModelSize}
                />}
            {lowPolyTextureSize != 0 &&
                <TextContainer
                    name={"AR Low Poly Texture"}
                    value={lowPolyTextureSize}
                />}

            {glbSize != 0 &&
                <TextContainer
                    name={"GLB Size"}
                    value={glbSize}
                />}
            {archiveSize != 0 &&
                <TextContainer
                    name={"Archive Size"}
                    value={archiveSize}
                />}
            {lowPolyVertexCount != 0 && !lowPolyTriangleCount &&
                <TextContainer
                    name={"Low Poly Vertex Count"}
                    value={lowPolyVertexCount}
                />}
            {highPolyVertexCount != 0 && ~highPolyTriangleCount &&
                <TextContainer
                    name={"High Poly Vertex Count"}
                    value={highPolyVertexCount}
                />}
            {lowPolyTriangleCount &&
                <TextContainer
                name={"Low Poly Triangle Count"}
                value={lowPolyTriangleCount}
            />
            }
            {highPolyTriangleCount &&
                <TextContainer
                name={"Low Poly Triangle Count"}
                value={highPolyTriangleCount}
            />
            }
        </Row>
    );
}

export default ModelSpecifications;