import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CognitoState } from "react-cognito";
import CustomHeader from "../../CustomHeader";
import { Layout, Button, Modal } from "antd";
import "./TestArtistMainLayout.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import DottedLoader from "../../DottedLoader";

const { Header, Content } = Layout;

class TestArtistMainLayoutContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_test_artist: false,
      isModalOpen: false,
      isLoading: false,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount = () => {
    // Check if the current user is Test Artist
    var groups = [];
    if (
      this.props.state === CognitoState.LOGGED_IN ||
      this.props.state === CognitoState.LOGGING_IN
    ) {
      if (
        "cognito:groups" in
        this.props.user["signInUserSession"]["idToken"]["payload"]
      ) {
        var groups =
          this.props.user["signInUserSession"]["idToken"]["payload"][
            "cognito:groups"
          ];
      }
    }

    if (groups.indexOf("TestArtist") !== -1) {
      this.setState({
        is_test_artist: true,
      });
    }

    let payload = {
      username: localStorage.getItem("username"),
    };
    this.setState({ isLoading: true });
    axios.post(ENVIRONMENT.GET_ARTIST_TEST, payload).then((res) => {
      console.log(res.data);

      if(res.data){
        // Artist ha picked up a test model
        const timeStarted = new Date(res.data.time_started);
        let deadline = new Date(res.data.time_started);
        let timeNow = new Date();
        deadline.setDate(timeStarted.getDate() + 2);
        let offset=timeNow.getTimezoneOffset();
        deadline.setMinutes(deadline.getMinutes()-offset);
        deadline = deadline - timeNow;
        this.setState({isLoading: false})
        if (res.data.status==="in_progress" && deadline > 0){
          this.props.history.push("/artist_assignment");
        } else if(res.data.status==="in_progress" && deadline <= 0){
          this.props.history.push("/artist_assignment_results");
        }else if (res.data.status === "artist_completed" || res.data.status === "artist_submitted" || res.data.status === "successful" || res.data.status === "failed" || res.data.status === "successfully_scored") {
          this.props.history.push("/artist_assignment_results");
        } else if (res.data.status === "not_started"){
        }
      }
      else{
        // The artist has not picked up a model, show job desk
        this.props.history.push("/test_job_desk");
      }
    });
  };

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  startTest = () => {
    let payload = {
      action: "artist_start_test",
      username: localStorage.getItem("username"),
    };
    this.setState({ isLoading: true });
    axios.post(ENVIRONMENT.TEST_ARTIST_CONTROLLER, payload).then((res) => {
      console.log(res.data);
      this.setState({ isLoading: false });
      this.setState({ redirectURL: "/artist_assignment" });
    });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <DottedLoader />
        ) : (
          <>
            <Header className="header">
              <CustomHeader toggleMethod={this.toggle}></CustomHeader>
            </Header>
            <Layout style={{ minHeight: "calc(100vh - 55px)" }}>
              <Content
                className="layout-container overflowY-hidden layout-snow"
                id="layout-section"
              >
                <div className="home2-container">
                  <img
                    className="home2-img"
                    alt="Artist Test Home"
                    src={require("../../../../assets/images/Home2.png")}
                  />
                  <h1 className="manrope f-16 w-600 mb-10 ">
                    Welcome {localStorage.getItem("username")}!
                  </h1>
                  <p className="manrope f-16 w-400 mb-28 text-center letter-spacing-pt2">
                    We’re super excited to have you here. As a part of our
                    hiring process, we will give you a test. Your
                    selection as an artist in ALL3D will depend on how well you
                    do in that test.
                    <br />
                    <br />
                    Please note that you will have <b>48 hours</b> to complete
                    this test. Go through the{" "}
                    <a href="#" onClick={this.showModal}>
                      guidelines
                    </a>{" "}
                    carefully before you submit your file. Best of luck!
                  </p>
                  <Button
                    className="start-test-button manrope f-16 w-500"
                    type="primary"
                    onClick={this.showModal}
                  >
                    Let's Begin <ArrowRightOutlined />
                  </Button>
                </div>
              </Content>
              <Modal
                title="Guidelines"
                className=""
                maskClosable={false}
                onCancel={this.handleCancel}
                visible={this.state.isModalOpen}
                width={"47%"}
                centered={true}
                footer={[
                  <div className="justify-in-end" key="start_test">
                    <Link to={'/test_job_desk'}>
                      <Button
                        className="modal-okay square font-14"
                      >
                        Let's Begin
                      </Button>
                    </Link>
                  </div>,
                ]}
              >
                <p className="mb-0">
                  Before you start the test, please make sure that you read the
                  guidelines for creating a model
                </p>
                <a href={ENVIRONMENT.PRODUCT_GUIDELINES} target="_blank">
                  Asset Creation Guidelines
                </a>
              </Modal>
            </Layout>
          </>
        )}
      </>
    );
  }
}

TestArtistMainLayoutContainer.propTypes = {
  user: PropTypes.object,
  attributes: PropTypes.object,
  state: PropTypes.string,
};

const mapStateToProps = (state) => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});

const TestArtistMainLayout = connect(
  mapStateToProps,
  null
)(TestArtistMainLayoutContainer);
export default TestArtistMainLayout;
