import React from 'react';
import 'antd/dist/antd.css';
import { Steps, Button, message, Input } from 'antd';
import { GET_SCENE_DETAIL, getBaseURL, SCENE_THUMBNAIL_URI } from '../../../../environments/env';
import axios from 'axios';

const style = {
    position: "relative",
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100%'
  };

class ScenePanoramaPublicViewer extends React.Component {
    
    constructor(props){
        super(props);
    }

    componentDidMount() {
        // Create viewer.
        var viewer = new window.Marzipano.Viewer(this.el);
        var autorotate = window.Marzipano.autorotate({
            yawSpeed: 0.1,         // Yaw rotation speed
            targetPitch: 0,        // Pitch value to converge to
            targetFov: Math.PI/2   // Fov value to converge to
        });
        var payload = {"scene_id" : this.props.match.params.id};
        axios.post(GET_SCENE_DETAIL, payload).then(res => {
            let platform = res.data['platform']
            // Create source.
            var source = window.Marzipano.ImageUrlSource.fromString(
                getBaseURL(platform) + SCENE_THUMBNAIL_URI + this.props.match.params.id + "/360Camera.jpg"
            );

            // Create geometry.
            var geometry = new window.Marzipano.EquirectGeometry([{ width: 4000 }]);

            // Create view.
            var limiter = window.Marzipano.RectilinearView.limit.traditional(1024, 100*Math.PI/180);
            var view = new window.Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

            // Create scene.
            var scene = viewer.createScene({
            source: source,
            geometry: geometry,
            view: view,
            pinFirstLevel: true
            });

            // Display scene.
            scene.switchTo();
            viewer.startMovement(autorotate);
        });
    }

    render () {
        return (
            <div className={'public-panorama-default-styles'} ref={ref => (this.el = ref)} />
        );
    }
}

export default ScenePanoramaPublicViewer;