import React, { useState } from 'react';
import { InputNumber, Slider, Row, Col, Tooltip, Radio, Switch} from 'antd';
import Constants from '../../../../SceneCreator/Constants';
import TripleToggleSwitch from "../../../../utils/triple-toggle-switch.js"
import {
    CloseOutlined
} from '@ant-design/icons';

function TransformControls (props) {

    const [hookDimensions, setDimensionsHook] = useState(false);
    const {placementType, changePlacementType, flipProduct, resetPlacementType, resetFreeModeTransform, freeMode, setFreeMode} = props;
    const minSize = 0.1;
    const maxSize = 500;
    const labels = {
        left: {
          title: "Rotation",
          value: "left"
        },
        right: {
          title: "Position",
          value: "right"
        },
        center: {
          title: "Off",
          value: "center"
        }
    };

    const freeModeOptions = {
        "rotate": "left",
        "off": "center",
        "translate": "right",
    }
    
    const onFreeRotationToggleChange = (value) => {
        let currentMode = Object.keys(freeModeOptions).find(key => freeModeOptions[key] === value);
        console.log("Free mode", currentMode);
        setFreeMode(currentMode);
    }


    const placementOptions = [
        { label: 'Floor', value: 'floor' },
        { label: 'Wall', value: 'wall' },
        { label: 'Ceiling', value: 'ceiling' },
      ];

    const updateProductLength = (value) => {
        if (value >= minSize && value <= maxSize) {
            let length = value;
            let height = props.productSize.height;
            let depth = props.productSize.depth;
            if (hookDimensions) {
                let scale = length / props.productSize.length;
                height = props.productSize.height * scale;
                depth = props.productSize.depth * scale;
            }
            props.setProductSize(length, height, depth);
        }
    }

    const updateProductHeight = (value) => {
        if (value >= minSize && value <= maxSize) {
            let length = props.productSize.length;
            let height = value;
            let depth = props.productSize.depth;
            if (hookDimensions) {
                let scale = height / props.productSize.height;
                length = props.productSize.length * scale;
                depth = props.productSize.depth * scale;
            }
            props.setProductSize(length, height, depth);
        }
    }

    const updateProductDepth = (value) => {
        if (value >= minSize && value <= maxSize) {
            let length = props.productSize.length;
            let height = props.productSize.height;
            let depth = value;
            if (hookDimensions) {
                let scale = depth / props.productSize.depth;
                length = props.productSize.length * scale;
                height = props.productSize.height * scale;
            }
            props.setProductSize(length, height, depth);
        }
    }

    const onPlacementChange = ({ target: { value } }) => {
        changePlacementType(value)
    };

    return (
        <>
            <div className='size-controls-container'>
                <strong className = "justify-in-center"><img className="transform-move-icon" draggable="false" src={require("../../../../assets/images/move-drag-icon.svg")}/></strong>
                    <div className='size-controls-cross-button' onClick={()=>{props.showProductSizeControls(false)}}>
                        <CloseOutlined />
                    </div>  
                <div className='size-controls-title-container'>
                    <span className='size-controls-text'>Resize</span>
                    <Tooltip title={<span className='manrope f-12 white'>Reset to original size of the product</span>}> 
                        <div className="size-controls-text reset" onClick={()=>{props.resetProductSize()}}>
                            <div><img className='p-5 p-right' src={require("../../../../assets/images/reset-controls.svg")}/>Reset</div>
                    </div>
                    </Tooltip>
                </div>
                    
                <Row className='m-10 m-top'>
                    <Col span={22}>
                        <div  className='clipping-setting-div align-center' >
                            <span className='size-controls-text slider-text' >Width</span>
                            <Slider className='clipping-slider' onChange={(value)=>{ updateProductLength(value) }} tooltipVisible={false} trackStyle={{backgroundColor:"#276dd7"}} railStyle={{backgroundColor:"#cccccc"}} handleStyle={{backgroundColor:"#ffffff", borderColor:"#276dd7"}} step={5} min={minSize} max={maxSize} value={props.productSize.length}/>
                            <InputNumber
                            formatter={(value) => `${value}"`}
                            parser={(value) => (value.replace('"', ''))}
                            disabled={false} onChange={(value)=>{ updateProductLength(value) }} step="5" className='size-controls-number-input' value={props.productSize.length} min={minSize} max={maxSize}/>
                        </div>
                        <div className='clipping-setting-div align-center'>
                            <span className='size-controls-text slider-text'>Height</span>
                            <Slider className='clipping-slider' onChange={(value)=>{ updateProductHeight(value) }} tooltipVisible={false} trackStyle={{backgroundColor:"#276dd7"}} railStyle={{backgroundColor:"#cccccc"}} handleStyle={{backgroundColor:"#ffffff", borderColor:"#276dd7"}} step={5} min={minSize} max={maxSize} value={props.productSize.height}/>
                            <InputNumber
                            formatter={(value) => `${value}"`}
                            parser={(value) => (value.replace('"', ''))}
                            disabled={false} onChange={(value)=>{ updateProductHeight(value) }} step="5" className='size-controls-number-input' value={props.productSize.height} min={minSize} max={maxSize}/>

                        </div>
                        <div className='clipping-setting-div align-center'>
                            <span className='size-controls-text slider-text'>Depth </span>
                            <Slider className='clipping-slider' onChange={(value)=>{ updateProductDepth(value) }} tooltipVisible={false} trackStyle={{backgroundColor:"#276dd7"}} railStyle={{backgroundColor:"#cccccc"}} handleStyle={{backgroundColor:"#ffffff", borderColor:"#276dd7"}} step={5} min={minSize} max={maxSize} value={props.productSize.depth}/>
                            <InputNumber
                            formatter={(value) => `${value}"`}
                            parser={(value) => (value.replace('"', ''))}
                            disabled={false} onChange={(value)=>{ updateProductDepth(value) }} step="5" className='size-controls-number-input' value={props.productSize.depth} min={minSize} max={maxSize}/>
                        </div>
                    </Col>
                    <Col className='size-controls-pin-icon-horizontal' span={1}>
                        <div className={`horizontal-divider ${hookDimensions ? 'enabled' : ''}`}></div>
                        <div className={`horizontal-divider ${hookDimensions ? 'enabled' : ''}`}></div>
                    </Col>
                    <Col className='size-controls-pin-icon-vertical' span={1}>
                        <div className={`vertical-divider ${hookDimensions ? 'enabled' : ''}`}></div>
                        <Tooltip title={<span className='manrope f-12 white'>Pin to uniformly resize product in all dimensions</span>}> 
                            <img className='pin-icon p-5 p-top p-bottom' onClick={()=> { setDimensionsHook(!hookDimensions) }} src={hookDimensions ? require("../../../../assets/images/pin-controls-selected.svg"): require("../../../../assets/images/pin-controls.svg") }/>
                        </Tooltip>
                        <div className={`vertical-divider ${hookDimensions ? 'enabled' : ''}`}></div>
                    </Col>
                </Row>
                { 
                    placementType === Constants.PlacementType.FLOOR && 
                    <div className='mt-20'>
                        <div>
                            <div className='size-controls-title-container m-10 m-top'>
                                <span className='size-controls-text'>
                                    Flip Item
                                </span>
                            </div>
                        </div>
                        <div>
                            <div onClick={flipProduct} className='size-controls-text flip-product-button'>
                                <img alt = "rotate-product" src = {require("../../../../assets/images/rotate-icon.svg")}/>
                                <span> 
                                    Flip 90° 
                                 </span>
                            </div>
                        </div>
                    </div>
                }
                {
                    <div className='mt-20'>
                        <div>
                            <div className='size-controls-title-container m-10 m-top'>
                                <span className='size-controls-text'>
                                    Free Mode
                                </span>
                                <Tooltip title={<span className='manrope f-12 white'>Reset rotation / position of the product</span>}> 
                                    <div className="size-controls-text reset" onClick={ resetFreeModeTransform }>
                                        <div><img className='p-5 p-right' src={require("../../../../assets/images/reset-controls.svg")}/>Reset</div>
                                    </div>
                                </Tooltip>
                                
                            </div>
                            <div className='free-rotation-toggle'>
                                <TripleToggleSwitch labels={labels} toggleState={freeModeOptions[freeMode]} onChange={onFreeRotationToggleChange} />
                            </div>
                        </div>
                    </div>
                }
                <Row className='mt-20'>
                    <div className='size-controls-title-container'>
                        <span className='size-controls-text'>Change Placement</span>
                        <Tooltip title={<span className='manrope f-12 white'>Reset to original placement of the product</span>}> 
                        <div className="size-controls-text reset" onClick={ resetPlacementType }>
                            <div><img className='p-5 p-right' src={require("../../../../assets/images/reset-controls.svg")}/>Reset</div>
                        </div>
                        </Tooltip>
                    </div>
                    <Radio.Group 
                        className='mt-16'
                        options={placementOptions} 
                        onChange={onPlacementChange}  
                        value={placementType}
                    />
                </Row>
 
            </div>

        </>
    )
}

export default TransformControls