import React from 'react';
import { Form } from '@ant-design/compatible';
import { LoadingOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Table, message, Modal, Input, Collapse, Button, Divider, Upload, Col, Row, Tooltip, Space } from 'antd';
import axios from 'axios';
import * as Constants from "../../CustomerComponents/Constants";
import ENVIRONMENT from '../../../../environments';
import AssignArtist from '../AssignArtist/AssignArtist';
import SuccessModal from '../../SuccessModal/SuccessModal';
import DeleteEntityModal from '../../DeleteEntityModal';
import WarningModal from '../../WarningModal/WarningModal';
import { getReadableModelFormat, makeGroupItemsConsectivelyOrdered } from '../../Utilities';
import GroupItemsModal from '../../GroupItemsModal/GroupItemsModal';
import FileConstants from '../../../../FileConstants';
import DottedLoader from '../../DottedLoader';
import ReassignArtist from '../ReassignArtist/ReassignArtist';
import jwtDecode from 'jwt-decode'
import _ from "lodash"; // Import the entire lodash library
const { TextArea } = Input;
const { Panel } = Collapse;

const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class JobDeskTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    tableLayout: 'fixed',
    showHeader,
    footer: false,
    scroll: undefined,
    hasData: true,
    dataSource: null,
    unAssignActionClass: 'hide',
    assignActionClass: 'hide',
    selectedProduct: '-1',
    artists: [],
    filter_customers: [],
    filter_artists: [],
    filter_companies: [],
    filter_categories: [],
    filter_model_types: [],
    assignmentModelVisible: false,
    buttonLoader: false,
    deleteModalVisible: false,
    deletingModelLoader: false,
    deleteSuccessModal: false,
    deleteWarningModal: false,
    customerModelReivewVisible: false,
    adminAttach: [],
    adminComments: "",
    refFileList: [],
    parentAssignedArtist: '',
    groupProducts: [],
    selectedGroupId: '',
    groupHaveUnassignedItems: false,
    groupProductsModal: false,
    load_assigned_artist: false,
    reassignmentModelVisible: false,
    ReassignmentbuttonLoader: false,
    filtered_artists: [],
    previous_artist: ''
  };

  handleAssign = values => {

      console.log(this.props.currentTab)
      this.setState({
        buttonLoader: true
      });
      let payload = {
        assigned_artist : values.artist_username,
        product_id: this.props.selected_id.toString(),
        admin_username: localStorage.getItem('username')
      }

      if (values.height) {
        payload.height = values.height;
      }
      if (values.width) {
        payload.width = values.width;
      }
      if (values.length) {
        payload.length = values.length;
      }

      if(values.admin_text_guidelines) {
        payload.admin_text_guidelines = values.admin_text_guidelines;
      }

      // when assign to artist is called for a preprocessing image product also update the model_status
      if (this.props.currentTab == 1){
        payload.model_status = 2;
      }
      console.log('payload=', payload)
      axios.post(ENVIRONMENT.ASSIGN_PRODUCT_MODEL, payload)
        .then(res => {
          console.log(res);
          console.log(res.data);
          this.setState({
            assignmentModelVisible: false,
            buttonLoader: false,
          });
          if(res.data.message == 'assignment_not_allowed'){
            message.info('Model can no longer be assigned to an artist');
            if(this.props.currentTab == 1){
              this.handlePreprocessingDataSource();
            }
            else{
              this.handleUnAssignedDataSource(); 
            }
          }
          else{
            message.info('Model has been assigned to Specified Artist');
            this.removeRowFromTable(this.state.selectedProduct);
          }
        })
        
  }

  handleReAssign = values => {
    this.setState({
        ReassignmentbuttonLoader: true
      });
      axios.post(ENVIRONMENT.ASSIGN_PRODUCT_MODEL, {
        assigned_artist: values.artist_username,
        product_id: this.state.selectedProduct,
        original_artist: this.state.previous_artist,
        admin_username: localStorage.getItem('username')
      })
        .then(res => {
          console.log(res.data);
          this.setState({
            reassignmentModelVisible: false,
            ReassignmentbuttonLoader: false,
          });
          message.info('Product has been Re-Assigned.');
          this.handleAssignedDataSource(2, -4);
        })
  }

  handleCancel = () => {
    this.setState({
      assignmentModelVisible: false
    });
  }
  
  handleReassignmentModalCancel = () => {
    this.setState({
        reassignmentModelVisible: false
    });
  }

  setErrorMessage = (error) => {
    this.setState({
      errorMessage: error
    })
  }

  getFilteredGroupProducts = () =>{
    let filtered_group_products = []

    if(this.state.dataSource != null){
      let selected_product = this.state.dataSource.filter((ele) => ele.product_id == this.state.selectedProduct)[0]
      this.state.groupProducts.map((item) => {
        if(this.state.selectedGroupId && item.group_id == this.state.selectedGroupId && (selected_product.company_id == item.company_id || selected_product.customer == item.customer)){
          filtered_group_products.push(item);
        }
      })
    }
    return filtered_group_products;
  }

  onSendToCustomer = () => {
    console.log('state = ', this.state)
    if(this.validateFileUploads(this.state.adminAttach) == "uploading"){
      this.setErrorMessage("Uploads are in progress")
    }
    else{
      this.setErrorMessage("")
      if(this.state.adminComments == ""){
        this.setState({
          adminCommentsError: "Message for Customer is required"
        })
      }
      else{
        this.setState({
          adminCommentsError: ""
        })
        let payload = {
            product_id: parseInt(this.state.selectedProduct),
            username: localStorage.getItem('username'),
            model_status: "10",
            admin_comments: this.state.adminComments
        }

        if (this.state.adminAttach) {
            payload.admin_attachments = this.state.adminAttach
        }
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload).then(res => {
          console.log(res);
          console.log(res.data);
          message.info('Product has been sent back to customer.');
          setTimeout(() => {window.location.reload();}, 1500)
        });
    }
  }
}

  handleSubmitCustomerForm = values => {
    this.setState({
      customerModelReivewVisible: true
    });
    this.onSendToCustomer(values);
}

  handleCancelCustomerForm = () => {
    this.setState({
      customerModelReivewVisible: false,
      adminAttach: [],
      adminComments: "",
      refFileList: []
    });
  }

  setAdminComments = (event) =>{
    this.setState({
      adminComments: event.target.value
    })
    if(event.target.value == ""){
      this.setState({
        adminCommentsError: "Message for Customer is required."
      })
    }
    else{
      this.setState({
        adminCommentsError: ""
      })
    }
  }

  setAdminAttach = (fileList) => {
    this.setState({
      adminAttach: fileList
    })
  }

  setHasError = (has_error) => {
    this.setState({
      hasError: has_error
    })
  }

  handleChangeAdminFile = info => {
    let fileList = [...info.fileList];
    this.setAdminAttach(fileList);
    this.setUploading(true);
    console.log(info,info.file);
    if (info.file.status === "done") {
        this.setHasError(false);
        this.setErrorMessage('');
        this.setUploading(false);
    } else if (info.file.status === "error") {
        console.log(info.file.error.message,info.file);
        this.setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
        if (info.file.error.code.includes('Credentials')) {
            this.setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
        }  else if (info.file.error.code.includes('Network')) {
            this.setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
        }
        this.setHasError(true);
        this.setUploading(false);
    }
  };

  validateFileUploads = (files) => {
    let status_done = false;
    if (files != undefined) {
        for(var file of files) {
            if (file['status'] == 'uploading') {
                return 'uploading';
            }
            else if (file['status'] == 'error') {
                return 'error';
            }
            else if (file['status'] == 'done') {
                status_done = true;
            }
        }
    }
    if (status_done) {
        return 'done'
    }
    return 'not_started';
}

setUploading = (is_uploading) => {
  this.setState({
    uploading: is_uploading
  })
}
isGroupHaveUnassignedItems = (group_id, id) => {
  let unassignedGroupedItems = false;
  for(let item of this.state.groupProducts){
    if(item['group_id'] == group_id && !item['assigned_artist'] && (item['model_status'] == "2" || item["model_status"] == "-4") && item['product_id'] != id){
      unassignedGroupedItems = true;
    }
  }
  return unassignedGroupedItems;
}

  showAssignmentModal = (id, data, group_id) => {
    let groupHaveUnassignedItems = this.isGroupHaveUnassignedItems(group_id, id)
    this.setState({
      selectedProduct: id,
      assignmentModelVisible: true,
      load_assigned_artist: true,
      groupHaveUnassignedItems: groupHaveUnassignedItems
    });
    if (data.variant_of) {
      let product_id = data.variant_of.toString();
      axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
      .then(res => {
        this.setState({
          parentAssignedArtist: res.data.assigned_artist,
          load_assigned_artist: false
        });
      });
    } else {
      this.setState({
        parentAssignedArtist: "",
        load_assigned_artist: false
      });
    }
    this.props.set_id(id);
    console.log('selectedProductId=', this.props.selected_id)
  }

  showReassignmentModal = (data) => {
    let tempArtist = _.cloneDeep(this.state.artists)
    tempArtist = tempArtist.filter(ele => ele.artist_profile != data.assigned_artist)
    this.setState({
        filtered_artists: tempArtist
    })
    this.setState({
        selectedProduct: data.product_id,
        previous_artist: data.assigned_artist,
        reassignmentModelVisible: true
      });

    this.props.set_id(data.product_id);
    console.log('selectedProductId=', this.props.selected_id)
  }

  delete_model = (id) =>{
    console.log('in delete product function')
    this.setState({
      deletingModelLoader: true
    })
    axios.post(
      ENVIRONMENT.DELETE_INCOMPLETE_REQUEST, {
        'entity_type': 'Product',
        'entity_id': id
      }).then(res => {
        console.log(res);
        if (res && res.data.length == 0) {
          this.setState({
            deleteWarningModal: true
          })
        } else {
          this.setState({
            deleteSuccessModal: true
          });
        }
        this.setState({
          deletingModelLoader: false,
          deleteModalVisible: false,
        });

      })
  }

  onCancelDelete = () => {
    this.setState({
      deletingModelLoader: false,
      deleteModalVisible: false
    })
  };

  getColumns = () => [
    {
      title: <Col offset={4} span={18}>Product Name</Col>,
      dataIndex: 'name',
      key: 'name',
      width: '17%',
      ...this.getColumnSearchProps('name'),
      render: (text, record) => <Row><Col span={3} style={{marginRight: 10}}><span className='group-product-image' onClick={() => record.group_id ? this.setState({groupProductsModal: true, selectedGroupId: record.group_id, selectedProduct: record.product_id}) : ''}>{record.hidden_from_artist && !record.group_id ? <Tooltip title="Not visible to artists for picking up"><a><img src={"/img/crossed-eye-icon.png"} style={{height: 20, width: 20}}></img></a></Tooltip>: record.group_id != '' ? <Tooltip title={`This Product is part of a group '${record.group_id}'`}><img src={"/img/grouped-icon.svg"} style={{height: 20, width: 20, cursor: 'pointer'}}></img> </Tooltip> : ''}</span> </Col> <Col span={18}><span>{text}</span></Col></Row>,
    },
    {
      title: 'Model Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      ...this.getColumnSearchProps('id'),
      render: text => <a onClick={() => window.open('../product_guidelines/' + text, 'Product Guidelines', "height=600,width=1000")}>{text}</a>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: this.state.filter_categories,
      onFilter: (value, record) => record.category === value,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      sorter: (a, b) => new Date(a.last_modified) - new Date(b.last_modified),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      filters: this.state.filter_customers,
      onFilter: (value, record) => record.customer === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      filters: this.state.filter_companies,
      onFilter: (value, record) => record.company === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Model Type',
      dataIndex: 'model_type',
      key: 'model_type',
      filters: this.state.filter_model_type,
      onFilter: (value, record) => record.model_type === value,
      render: (text, record) => <span>{this.addVariationTypeForModelType(text, record)}</span>,
    },
    {
      title: 'Requirement',
      dataIndex: 'need_to_model',
      key: 'need_to_model',
      render: (text, record) => <span>{getReadableModelFormat(text || record.model_type)}</span>,
    },
    {
      title: 'Assigned Artist',
      dataIndex: 'assigned_artist',
      key: 'assigned_artist',
      filters: this.state.filter_artists,
      onFilter: (value, record) => record.assigned_artist === value,
      hidden: this.props.state == 'unassigned' || this.props.state == 'preprocessing_images' ? true : false,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.unAssignActionClass,
      render: (text, record) => (
        <span>
          <a onClick={() => this.unAssignProductConfirm(record.product_id, record.assigned_artist)}>UnAssign</a>
        </span>

      ),
    },
    {
        title: 'Action',
        key: 'action',
        className: this.state.unAssignActionClass,
        render: (text, record) => (
          <span>
            <a onClick={() => this.showReassignmentModal(record)}>Re-Assign</a>
          </span>
        )
      },
    {
      title: 'Action',
      key: 'assign_action',
      className: this.state.assignActionClass,
      render: (text, record) => (
        <React.Fragment>
          <span>
          <a onClick={() => this.showAssignmentModal(record.id, record, record.group_id)}>Assign to Artist</a>
          </span>
          {record.uploaded_model && !record.variant_of ? 
          <React.Fragment>

            <Divider type='vertical' />
            <span>
              <a onClick={() => this.showSendBackModal(record.id)}>Send Back to Customer</a>
            </span>  
          </React.Fragment>: ''
        }
        </React.Fragment>

      ),
    },
    this.props.delete_model ? {
      title: 'Action',
      key: 'delete_model',
      className: 'delete-model',
      render: (text, record) => (
        <span>
          <a onClick={() => this.setState({
            selected_id: record.id,
            deleteModalVisible: record.model_status == -4 ? false : true,
            deleteWarningModal: record.model_status == -4 ? true : false
          })}>Delete</a>
        </span>
      ),
    } :{}
  ].filter(item => !item.hidden);

  showSendBackModal = (id) => {
    console.log('id=',id)
    this.setState({
      selectedProduct: id,
      customerModelReivewVisible: true
    });
    this.props.set_id(id);
    console.log('selectedProductId=', this.props.selected_id)
  }

  unAssignProductConfirm(product_id, assigned_artist) {
    Modal.confirm({
      title: 'Are you sure you want to unassign this product?',
      icon: <ExclamationCircleOutlined />,
      content: 'Artist might have already started working on it.',
      onOk: () => {
        console.log('OK');
        this.unAssignProduct(product_id, assigned_artist);
      },
      onCancel() {
      },
    });
  }


  // Search in table functionality
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  unAssignProduct = (product_id, assigned_artist) => {
    axios.post(ENVIRONMENT.ASSIGN_PRODUCT_MODEL, {
      assigned_artist: '',
      product_id: product_id,
      original_artist: assigned_artist,
      admin_username: localStorage.getItem('username')
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Product has been unassigned.');
        this.handleAssignedDataSource(2, -4);
        this.handleUnAssignedDataSource();
      })
  }

  loadArtistsData = () => {

    axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        console.log(res.data)
        
        let artist_list = [];
        res.data.map(artist => {
          if (artist.is_active == true || artist.is_active == "True") {
            artist_list.push(artist);
          }
        });
        this.setState({
          artists: artist_list
        });
      });
  }

  componentDidMount = () => {
    var query = window.location.search.substring(1);
    console.log('in component did mount', query)
    var active_tab = 'preprocessing_images'
    if(query === 'tab=p1'){
      // Show first tab of products
      active_tab = 'preprocessing_images'
    }
    else if(query === 'tab=p2'){
      console.log('in tab=2')
      // Show second product tab
      active_tab = 'unassigned'
    }
    else if(query === 'tab=p3'){
      // Show third product tab
      active_tab = 'assigned_not_modeled'
    }
    else if(query === 'tab=p4'){
      // Show forth product tab
      active_tab = 'assigned_modeled'
    }

    if (active_tab == 'assigned_not_modeled') {
      this.loadArtistsData();
      this.setState({
        unAssignActionClass: ''
      });
      this.handleAssignedDataSource(2, -4);
    }
    if (active_tab == 'assigned_modeled') {
      this.setState({
        unAssignActionClass: 'hide',
      });
      this.handleAssignedDataSource(3);
    }
    if (active_tab == 'unassigned') {
      this.loadArtistsData();
      this.setState({
        unAssignActionClass: 'hide',
        assignActionClass: '',
      });
      this.handleUnAssignedDataSource();
    }
    if (active_tab == 'preprocessing_images') {
      this.loadArtistsData();
      this.setState({
        unAssignActionClass: 'hide',
        assignActionClass: '',
      });
      this.handlePreprocessingDataSource();
    }
  }

  handleFilteringOptions = (filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values) => {
    
    let filter_categories_objects = [];
    let filter_customer_objects = [];
    let filter_company_objects = [];
    let filter_artist_objects = [];
    let filter_model_type_objects = [];

    filter_categories_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_customer_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_company_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_artist_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_model_type_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });


    filter_categories_values.forEach( filter_categories_value => {
      filter_categories_objects.push({
        text: filter_categories_value,
        value: filter_categories_value,
      })
    });

    filter_customer_values.forEach( filter_customer_value => {
      filter_customer_objects.push({
        text: filter_customer_value,
        value: filter_customer_value,
      })
    });

    filter_company_values.forEach( filter_company_value => {
      filter_company_objects.push({
        text: filter_company_value,
        value: filter_company_value,
      })
    });
    filter_artist_values.forEach( filter_artist_value => {
      filter_artist_objects.push({
        text: filter_artist_value,
        value: filter_artist_value,
      })
    });
    filter_model_type_values.forEach( filter_model_type_value => {
      filter_model_type_objects.push({
        text: filter_model_type_value == 'Change' ? 'Change (All Types)' : filter_model_type_value,
        value: filter_model_type_value,
      })
    });

    this.setState({
      filter_categories: filter_categories_objects,
      filter_customers: filter_customer_objects,
      filter_companies: filter_company_objects,
      filter_artists: filter_artist_objects,
      filter_model_type: filter_model_type_objects
    });

  }

  addVariationTypeForModelType = (model_type, data_record) => {
    let result = model_type;
    if(model_type == 'Change' && data_record['variation_type'])
      result = result +  ' (' + FileConstants.variationDict(data_record['variation_type']) + ')';
    return result
  }

  getModelTypeString = (data_record) => {
    let model_type = data_record['uploaded_model'] && !data_record['variant_of'] ? 'Uploaded Model' + (data_record['model_status'] == -4 ? ' Fix ' : '') + '(' + getReadableModelFormat(data_record['model_type']) + ')' : data_record['is_configurable'] ? 'Configurable' + (data_record['model_status'] == -4 ? ' Model Fix' : '') : data_record['variant_of'] ? 'Change' + (data_record['model_status'] == -4 ? ' Model Fix' : '') : data_record['segmented'] ? 'Segmented' + (data_record['model_status'] == -4 ? ' Model Fix' : '') : 'Simple' + (data_record['model_status'] == -4 ? ' Model Fix' : '')
    return model_type;
  }

  create_dic = (data, model_status, i) => {
    let res = {}
    let requested_for = data['requested_for']
    let customer_username = data['customer_username']
    let company = data['company_name']
    let requested_for_company = data['requested_for_company']
    res = {
      key: i,
      product_id: data['id'],
      name: data['name'],
      id: data['id'],
      category: data['category'],
      model_type: this.getModelTypeString(data),
      assigned_artist: data['assigned_artist'],
      customer: (requested_for == '' || requested_for == customer_username) ? customer_username : `${customer_username} (${requested_for})`,
      company: (requested_for == '' || requested_for == customer_username) ? company : requested_for_company,
      created_on: data['created_on'],
      last_modified: data['last_modified'],
      uploaded_model: data['uploaded_model'],
      need_to_model: data['need_to_model'],
      model_status: data['model_status'],
      variant_of: data['variant_of'],
      variation_type: data['variation_type'],
      hidden_from_artist: data['hidden_from_artist'],
      group_id: data['group_id'],
      company_id: data['company_id']
    }
    return res
  }

  populate_filtering_data = (data) => {
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];

    for(let i = 1; i <= (data).length; i++){
      let category_value = data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let customer_value = data[i - 1]['customer'];
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = data[i - 1]['company'];
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }
      let model_type_value = data[i - 1]['model_type']
      if(model_type_value != "" && !filter_model_type_values.includes(model_type_value)){
        filter_model_type_values.push(model_type_value)
      }
    }

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values)
  }


  handleAssignedDataSource = (model_status, second_status = null) => {
    let data = [];
    let product_group_ids = [];
    this.setState({
      loading: true
    })
    let model_status_list = [model_status]
    if(second_status)
      model_status_list.push(second_status)
    console.log('Model Status list', model_status_list)
    
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=["+ model_status_list+ "]&&scans__isnull=true&&assigned_artist__isnull=false)"
    console.log(payload)
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        for (let i = 1; i <= (res.data).length; i++) {
          data.push(this.create_dic(res.data[i - 1], model_status, i));
          if(res.data[i - 1].group_id && !product_group_ids.includes(res.data[i - 1].group_id)){
            product_group_ids.push(res.data[i - 1].group_id);
          }
        }
        data = makeGroupItemsConsectivelyOrdered(data);
        this.setState({
          dataSource: data,
          loading: false
        })
        this.populate_filtering_data(data)
        if(product_group_ids)
          this.fetchGroupProducts(product_group_ids);
      });

  }



  handlePreprocessingDataSource = () => {
    let data = [];
    let filter_customer_values = [];
    let product_group_ids = [];

    this.setState({
      loading: true
    })
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=[7]&&assigned_artist__isnull=true)"
    
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        for (let i = 1; i <= (res.data).length; i++) {
          data.push(this.create_dic(res.data[i - 1], 7, i));
          let customer_value = res.data[i - 1]['customer_username'];
          if(customer_value != "" && !filter_customer_values.includes(customer_value)){
            filter_customer_values.push(customer_value);
          }
          if(res.data[i - 1].group_id && !product_group_ids.includes(res.data[i - 1].group_id)){
            product_group_ids.push(res.data[i - 1].group_id);
          }
        }
        data = makeGroupItemsConsectivelyOrdered(data);
        console.log(' GG = ', product_group_ids)
        this.setState({
          dataSource: data,
          loading: false
        })
        this.populate_filtering_data(data)
        if(product_group_ids)
          this.fetchGroupProducts(product_group_ids);
      });
  }

  fetchGroupProducts = (group_ids) => {
    if (group_ids.length) {
      axios.post(ENVIRONMENT.GROUP_IDS_GET_BATCH, { group_ids })
        .then(res => {
          console.log('GROUP PRODUCTS = ', res.data)
          this.setState({ groupProducts: res.data })
        })
    }
  }

  getPayload = () => {
    return {
      "required_fields": ["id","name","brand_id", "created_on","last_modified","category", "company_name", "model_type", 
      "model_type","need_to_model", "assigned_artist","customer_username", 
      "group_id", "uploaded_model", "variant_of", "variation_type", "segmented",
      "hidden_from_artist", "model_status", "last_modified_stamp", "company_id", "requested_for", "requested_for_company"],
      "order_by": "last_modified_stamp desc"
    }
  }

  handleUnAssignedDataSource = () => {
    let data = [];
    let filter_customer_values = [];
    let product_group_ids = [];

    this.setState({
      loading: true
    })
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=[2,-4]&&scans__isnull=true&&assigned_artist__isnull=true)"
    
    axios.post(ENVIRONMENT.LIST_PRODUCT , payload)
      .then(res => {
        console.log('Product',res.data)
        for (let i = 1; i <= (res.data).length; i++) {
          data.push(this.create_dic(res.data[i - 1], -4, i));
          let customer_value = res.data[i - 1]['customer_username'];
          if(customer_value != "" && !filter_customer_values.includes(customer_value)){
            filter_customer_values.push(customer_value);
          }
          if(res.data[i - 1].group_id && !product_group_ids.includes(res.data[i - 1].group_id)){
            product_group_ids.push(res.data[i - 1].group_id);
          }
        }
        data = makeGroupItemsConsectivelyOrdered(data);
        this.setState({
          dataSource: data,
          loading: false
        })
        this.populate_filtering_data(data)
        if(product_group_ids)
          this.fetchGroupProducts(product_group_ids);
      });
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };


  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };


  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  removeRowFromTable = (model_id) => {
    this.setState({
      dataSource: this.state.dataSource.filter(item => item.id !== model_id)
    })
  }

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {this.state.loading ?
        <DottedLoader/> :
        dataSource &&
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
        }
        {/* Send Back to Customer */}
        <Modal
        title="Confirm"
        visible={this.state.customerModelReivewVisible}
        onOk={this.handleSubmitCustomerForm}
        closable={false}
        maskClosable={false}
        onCancel={this.handleCancelCustomerForm}>
        <div>
          <Form className="input-form">
            <Form.Item name="admin_comments" label="Message for Customer" colon={false} >
                <TextArea
                  placeholder="Enter Message"
                  value={this.state.adminComments}
                  onChange={this.setAdminComments}
                  rows={5}
                  style={{height: "auto !important"}}
                />
                {this.state.adminCommentsError != "" ?
                  <div className='retry-error-message left'>{this.state.adminCommentsError}</div>
            : ""}
            </Form.Item>
            
            <Form.Item name={'admin_attachments'} label="Reference Files" colon={false} getValueFromEvent= {e => e && e.fileList}>
                <Upload 
                  style={{display: 'inline-block', width: "100%"}}
                  {...Constants.upload_props} 

                  onRemove={file => {
                    message.success(`${file.name} removed successfully!`)
                    const index = this.state.adminAttach.indexOf(file);
                    const newFileList = this.state.adminAttach.slice();
                    newFileList.splice(index, 1);
                    this.setAdminAttach(newFileList);
                    console.log(newFileList)
                    newFileList.forEach((file) => {
                        if (file.status !== "error"){
                            this.setHasError(false)
                        }
                        else {
                            this.setHasError(true)
                        }
                    })
                    return true;
                  }}
                  fileList={this.state.adminAttach}
                  onChange = {this.handleChangeAdminFile}
                  openFileDialogOnClick={!(this.validateFileUploads(this.state.adminAttach) == 'error')}
                  multiple="true" listType="text" className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                >
                  <div className="ant-upload-text">
                  <div style={{display: "block"}}>
                  <div className="d-flex">
                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                    <div className="ant-upload-text">
                    <div className="up-info">Drop your Files</div>
                    <div>Support: JPG</div>
                    </div>
                  </div>
                  </div>
                 
                </div>
                </Upload>
            </Form.Item>
            {(this.validateFileUploads(this.state.adminAttach) == 'error' || this.state.errorMessage) ? 
                <div className='retry-error-message left'>{this.state.errorMessage}</div>
            : ''}
            </Form>
          </div>
        </Modal>

        <AssignArtist
        entity={'product'}
        groupHaveUnassignedItems={this.state.groupHaveUnassignedItems}
        parentAssignedArtist={this.state.parentAssignedArtist}
        handleCancel={this.handleCancel}
        buttonLoader={this.state.buttonLoader}
        handleAssign={this.handleAssign}
        load_assigned_artist={this.state.load_assigned_artist}
        assignmentModelVisible={this.state.assignmentModelVisible}
        artists={this.state.artists}
        />

        <ReassignArtist
        entity={'product'}
        handleCancel={this.handleReassignmentModalCancel}
        buttonLoader={this.state.ReassignmentbuttonLoader}
        handleAssign={this.handleReAssign}
        assignmentModelVisible={this.state.reassignmentModelVisible}
        artists={this.state.filtered_artists}
        />

        <DeleteEntityModal
        onCancel={this.onCancelDelete}
        visible={this.state.deleteModalVisible}
        deletingModelLoader={this.state.deletingModelLoader}
        selected_id={this.state.selected_id}
        delete_model={this.delete_model}
        heading={'You are about to delete a Product'}
        subText={'Once deleted, it cannot be recovered. Are you sure you want to delete it?'}
        />

        <SuccessModal
        visible={this.state.deleteSuccessModal}
        heading={"Product Deleted Successfully!"}
        text={"The product has been deleted successfully."}
        footer={[
           <div className="justify-in-center">
               <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                   window.location.reload();}}>
                   Okay
               </Button>
           </div>
        ]}/>

        <GroupItemsModal 
          visible={this.state.groupProductsModal}
          onCancel={() => {this.setState({groupProductsModal: false})}}
          group_id={this.state.selectedGroupId}
          groupedProducts={this.getFilteredGroupProducts()}
        />

        <WarningModal
        visible={this.state.deleteWarningModal}
        onCancel={() => this.setState({ deleteWarningModal: false })}
        heading={"Oops! You cannot delete this product."}
        text={"This product cannot be deleted directly since it has assets and information associated with it. Please contact the engineering team to get it removed."}
        footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => this.setState({ deleteWarningModal: false })}>
                    Okay
                </Button>
            </div>
        ]}
        />

      </div>
    );
  }
}

export default JobDeskTable;
