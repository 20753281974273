import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import {
  Row,
  Col,
  Tabs,
  Collapse,
  Card,
  Image,
  message,
  Button,
  Tooltip,
} from 'antd';
import DottedLoader from '../../DottedLoader';
import { ReactComponent as SmallEmptyBox } from '../../../../icons/small-empty-box.svg';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import CustomerMainLayout from '../CustomerMainLayout';
import PresetManagement from '../PresetManagement/PresetManagement';
import ProductPresetManagement from '../ProductPresetManagement/ProductPresetManagement';
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const BulkConfiguration = (props) => {
  const [completedRequests, setCompletedRequests] = useState([]);
  const [inProgressRequests, setInProgressRequests] = useState([]);
  const [selectedRequestDetails, setSelectedRequestDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBulkRequestID, setSelectedBulkRequestID] = useState(null);
  const [selectedRequestRetailer, setSelectedRequestRetailer] = useState(null);
  const [preparingArchive, setPreparingArchive] = useState(false);
  const [imageVisible, setImageVisible] = useState([]);
  const [expandRender, setExpandRender] = useState(false);
  const [selectedRequestCSV, setSelectedRequestCSV] = useState('');
  const [defaultActiveTabKey, setDefaultActiveTabKey] = useState('1');
  const { tabData } = props.location.state || {};
  const [refreshData, setRefreshData] = useState(false)

  const downloadFile = (url, filename) => {
    const loader = message.loading('Preparing to download..', 0);

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.responseType = 'blob';
    xhr.onload = function(e) {
      if (this.status == 200) {
        setTimeout(loader);

        const myBlob = this.response;
        saveAs(myBlob, filename);
      }
    };
    xhr.send();
  };

  useEffect(() => {
    if (tabData) {
      setDefaultActiveTabKey(tabData.tabKey);
    }
    setIsLoading(true);
    const payload = {
      'created_by': FileConstants.CUSTOMER_USERNAME,
    };
    if(localStorage.getItem('company_id')){
      payload['company_id'] = localStorage.getItem('company_id')
    }
    axios.post(ENVIRONMENT.BULK_REQUEST_LIST, payload).then((res) => {
      setIsLoading(false);
      console.log('res', res);
      if (res['data']) {
        setCompletedRequests(
            res['data'].filter((item) => item['request_status'] == 'completed'),
        );
        setInProgressRequests(
            res['data'].filter((item) => item['request_status'] == 'in_progress'),
        );
      }
    });
  }, []);

  const getThumbnail = (thumbnail, config_id) => {
    let thumbnail_url = null;
    if (thumbnail) {
      thumbnail = thumbnail.replace('.tiff', '.jpg');
      thumbnail_url =
        ENVIRONMENT.PRODUCT_CONFIG_RENDERS_URL + config_id + '/' + thumbnail;
    }

    return thumbnail_url;
  };

  const visibilityChange = (visible, prevVisible) => {
    const temp_arr = {};
    if (expandRender) {
      selectedRequestDetails.map((request) => {
        request.renders.map((x) => {
          if (imageVisible[x.camera_name] && !visible) {
            temp_arr[x.camera_name] = false;
          } else if (imageVisible[x.camera_name] && visible) {
            temp_arr[x.camera_name] = true;
          } else {
            temp_arr[x.camera_name] = false;
          }
        });
      });
      console.log('temp_arr = ', temp_arr);
      setImageVisible(temp_arr);
    }
    if(!visible){
      setExpandRender(false)
    }
  };

  const expandImage = (name) => {
    const temp_arr = imageVisible;
    selectedRequestDetails.map((config_request) => {
      config_request.renders.map((item) => {
        if (item.camera_name == name) {
          temp_arr[item.camera_name] = true;
        } else {
          temp_arr[item.camera_name] = false;
        }
      });
    });
    console.log('temp_arr = ', temp_arr);
    setImageVisible(temp_arr);
    setExpandRender(true);
  };

  async function checkFileExists(url, loader) {
    console.log('file existing');
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.ok) {
        window.location.href = url;
        setPreparingArchive(false);
        loader();
      } else {
        setTimeout(() => checkFileExists(url, loader), 5000); // try again in 5 seconds
      }
    } catch (error) {
      setTimeout(() => checkFileExists(url, loader), 5000); // try again in 5 seconds
    }
  }

  const downloadConfigurationRenders = () => {
    setPreparingArchive(true);
    const loader = message.loading('Preparing your Archive...', 0);
    const payload = {
      'bulk_request_id': selectedBulkRequestID,
      'customer_username': selectedRequestRetailer,
      'csv_filename': selectedRequestCSV,
    };

    axios.post(ENVIRONMENT.INIT_BULK_RENDERS_ARCHIVE, payload).then((res) => {
      console.log('res = ', res);
      if (res['data'] && res['data']['archiveUrl']) {
        checkFileExists(res['data']['archiveUrl'], loader);
      }
    });
  };

  const getRendersForSelectedRequest = (
      bulk_request_id,
      csv_filename,
      retailer_username,
  ) => {
    setIsLoading(true);
    setSelectedRequestCSV(csv_filename);
    setSelectedBulkRequestID(bulk_request_id);
    setSelectedRequestRetailer(retailer_username);
    const payload = {
      'bulk_request_id': bulk_request_id,
    };
    axios.post(ENVIRONMENT.PRODUCT_CONFIG_RENDERS_LIST, payload).then((res) => {
      if (res['data']) formatAndSendRequestDetails(res['data']);
      setIsLoading(false);
    });
  };

  const formatAndSendRequestDetails = (data) => {
    data.map((item) => {
      item.renders.map((render) => {
        let img_format = 'jpg';
        if (render.image_data && render.image_data.img_format) {
          img_format = render.image_data.img_format;
        }
        render.camera_name += '.' + img_format;
      });
    });
    setSelectedRequestDetails(data);
  };

  return (
    <CustomerMainLayout selected='19'>
      {isLoading ? (
        <DottedLoader />
      ) : selectedRequestDetails ? (
        // Display renders for the selected config
        <>
          <Row gutter={20}>
            <Col span={12}>
              <div
                className='manrope f-24 w-700'
                style={{ marginTop: 30, marginLeft: 17 }}
              >
                <img
                  src='/img/back_arrow.png'
                  style={{ marginRight: 20, cursor: 'pointer' }}
                  onClick={() => setSelectedRequestDetails(null)}
                />
                Product Configuration Renders
              </div>
            </Col>
            <Col span={10}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 30,
                }}
              >
                <Button
                  disabled={preparingArchive}
                  className='modal-okay square font-14 float-right'
                  onClick={() => {
                    downloadConfigurationRenders();
                  }}
                >
                  Download Renders
                </Button>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              marginTop: 30,
              marginLeft: 17,
              marginTop: 20,
              display: 'block',
            }}
          >
            <div style={{ display: 'flex' }}>
              <span style={{ fontWeight: 600 }}>Spec File: </span>
              <span> {selectedRequestCSV}</span>
            </div>

            <div style={{ display: 'flex' }}>
              <span style={{ fontWeight: 600 }}>Customer Name: </span>
              <span> {selectedRequestRetailer}</span>
            </div>
          </Row>
          <Row style={{ margin: '50px 20px 20px 20px', display: 'flex' }}>
            {selectedRequestDetails.map((config_request) => {
              return config_request.renders.map((item) => {
                console.log('..', item);
                return (
                  <Col span={11} style={{ marginRight: 25, marginBottom: 25 }}>
                    <div
                      style={{
                        marginBottom: 4,
                        marginLeft: 2,
                        fontWeight: 500,
                      }}
                    >
                      {item['camera_name']}
                    </div>
                    <Card className='lifestyle-image-card'>
                      <Image
                        preview={{
                          visible: imageVisible[item['camera_name']],
                          onVisibleChange: visibilityChange,
                          maskClassName: 'customize-mask',
                          mask: (
                            <span>
                              <img
                                className='image-thumbnail'
                                src='/img/expand_thumbnail.png'
                                onClick={() => {
                                  expandImage(item.camera_name);
                                }}
                                style={{ marginRight: 16 }}
                              />

                              <a
                                onClick={() => {
                                  const filename = item.camera_name.split('.')[0];
                                  downloadFile(
                                      ENVIRONMENT.PRODUCT_CONFIG_RENDERS_URL +
                                      config_request['id'] +
                                      '/' +
                                      item.camera_name,
                                      filename,
                                  );
                                }}
                              >
                                <img
                                  className='image-thumbnail'
                                  src='/img/download.png'
                                />
                              </a>
                            </span>
                          ),
                        }}
                        className='lifestyle-image-settings'
                        src={
                          ENVIRONMENT.PRODUCT_CONFIG_RENDERS_URL +
                          config_request['id'] +
                          '/' +
                          encodeURIComponent(item.camera_name.replace('tiff', 'jpg'))
                        }
                      />
                    </Card>
                  </Col>
                );
              });
            })}
          </Row>
        </>
      ) : (
        <>
          <Row gutter={20}>
            <Col span={22} offset={1} style={{ marginTop: 30 }}>
              <h3>Bulk Configuration</h3>
              <Tabs
                defaultActiveKey={defaultActiveTabKey}
                tabBarStyle={{ textAlign: 'center' }}
                centered
                animated={false}
              >
                <TabPane tab='Completed' key='1'>
                  <Row>
                    <Col lg={6} sm={24} md={8} offset={0}>
                      <Card
                        onClick={() =>
                          (window.location.href = '/bulk-product-config-create')
                        }
                        className='card-shadow-product'
                        bordered={false}
                        style={{
                          marginRight: '16px',
                          marginBottom: '20px',
                          height: '375px',
                          backgroundColor: '#FAFAFA',
                          cursor: 'pointer',
                        }}
                      >
                        <div>
                          <img
                            className='create-button-box'
                            src={require('../../../../assets/images/AddBulkRequest.svg')}
                          />
                          <div className='create-button-text  manrope f-14 red-00'>
                            Create Bulk Requests
                          </div>
                        </div>
                      </Card>
                    </Col>
                    {completedRequests.map((item) => {
                      return (
                        <Col span={6} offset={0}>
                          <Card
                            className='stacked-card stacked-card-top-right card-margins stack'
                            style={{ width: '93%' }}
                            onClick={() =>
                              getRendersForSelectedRequest(
                                  item.request_id,
                                  item.csv_filename,
                                  item.customer_username,
                              )
                            }
                          >
                            <span
                              className={`group-settings-checkbox-reorder-items`}
                            >
                              <img src={'/img/folder_white_bg.svg'} />
                            </span>
                            <div className='stacked-card-inner'>
                              <div className='box'>
                                {getThumbnail(
                                    item['thumbnail'],
                                    item['config_id'],
                                ) ? (
                                  <img
                                    src={getThumbnail(
                                        item['thumbnail'],
                                        item['config_id'],
                                    )}
                                    style={{
                                      objectFit: 'contain',
                                      width: '100%',
                                      height: '275px',
                                      zIndex: 1,
                                    }}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      objectFit: 'contain',
                                      height: '275px',
                                      zIndex: 1,
                                    }}
                                    className='product-image'
                                    src={require('../../../../assets/images/chair.png')}
                                  />
                                )}
                              </div>

                              <div
                                className='justify-in-start'
                                style={{ margin: '12px 12px 8px 12px' }}
                              >
                                <Tooltip
                                  title={
                                    <span className='manrope f-12 white'>
                                      Spec File: {item['csv_filename']}
                                    </span>
                                  }
                                >
                                  <span className='justify-in-start manrope f-14 black-00 clamp-text'>
                                    {item['csv_filename']}
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <span className='manrope f-12 white'>
                                      {Utilities.timestampToTimeSince(
                                          item.last_modified,
                                          'tooltip',
                                      )}
                                    </span>
                                  }
                                >
                                  <span
                                    className='manrope f-12 grey-77 w-500 time-clock-bg'
                                    style={{ marginLeft: 4 }}
                                  >
                                    <img
                                      src='/img/carbon_time.svg'
                                      style={{ marginRight: 2 }}
                                    />
                                    {Utilities.timestampToTimeSince(
                                        item.last_modified,
                                    )}
                                  </span>
                                </Tooltip>
                              </div>

                              <div
                                className='justify-in-start'
                                style={{
                                  marginLeft: '12px',
                                  marginRight: '12px',
                                  marginBottom: '15px',
                                  textAlign: 'left',
                                }}
                              >
                                <span
                                  className='manrope f-12 grey-77 capitalize'
                                  style={{ marginRight: 4 }}
                                >
                                  Customer Username: {item['customer_username']}
                                </span>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </TabPane>
                <TabPane tab='In Progress' key='2'>
                  <Row>
                    <Col lg={6} sm={24} md={8} offset={0}>
                      <Card
                        onClick={() =>
                          (window.location.href = '/bulk-product-config-create')
                        }
                        className='card-shadow-product'
                        bordered={false}
                        style={{
                          marginRight: '16px',
                          marginBottom: '20px',
                          height: '345px',
                          backgroundColor: '#FAFAFA',
                          cursor: 'pointer',
                        }}
                      >
                        <div>
                          <img
                            className='create-button-box'
                            src={require('../../../../assets/images/AddBulkRequest.svg')}
                          />
                          <div className='create-button-text  manrope f-14 red-00'>
                            Create Bulk Requests
                          </div>
                        </div>
                      </Card>
                    </Col>
                    {inProgressRequests.map((item) => {
                      return (
                        <Col lg={6} sm={24} md={8} offset={0}>
                          <Card style={{ width: '93%', marginBottom: 20 }}>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <img
                                  className='product-image'
                                  style={{
                                    maxWidth: '100%',
                                    height: '280px',
                                    opacity: '0.5',
                                    filter: 'blur(0px)',
                                  }}
                                  src={require('../../../../assets/images/product_incomplete.svg')}
                                />
                              </div>

                              <div
                                className='justify-in-start'
                                style={{ margin: '12px 12px 8px 12px' }}
                              >
                                <Tooltip
                                  title={
                                    <span className='manrope f-12 white'>
                                      Spec File: {item['csv_filename']}
                                    </span>
                                  }
                                >
                                  <span className='justify-in-start manrope f-14 black-00 clamp-text'>
                                    {item['csv_filename']}
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <span className='manrope f-12 white'>
                                      {Utilities.timestampToTimeSince(
                                          item.last_modified,
                                          'tooltip',
                                      )}
                                    </span>
                                  }
                                >
                                  <span
                                    className='manrope f-12 grey-77 w-500 time-clock-bg'
                                    style={{ marginLeft: 4 }}
                                  >
                                    <img
                                      src='/img/carbon_time.svg'
                                      style={{ marginRight: 2 }}
                                    />
                                    {Utilities.timestampToTimeSince(
                                        item.last_modified,
                                    )}
                                  </span>
                                </Tooltip>
                              </div>

                              <div
                                className='justify-in-start'
                                style={{
                                  marginLeft: '12px',
                                  marginRight: '12px',
                                  marginBottom: '15px',
                                  textAlign: 'left',
                                }}
                              >
                                <span
                                  className='manrope f-12 grey-77 capitalize'
                                  style={{ marginRight: 4 }}
                                >
                                  Customer Username: {item['customer_username']}
                                </span>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </TabPane>
                <TabPane tab='Manage Customer Presets' key='3'>
                  <PresetManagement setRefreshData={setRefreshData} />
                </TabPane>
                <TabPane tab='Manage Product Presets' key='4'>
                  <ProductPresetManagement
                    selectedCustomer = {tabData?.customerName}
                    refreshData = {refreshData}
                    setRefreshData={setRefreshData}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </CustomerMainLayout>
  );
};

export default BulkConfiguration;
