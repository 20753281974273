import React, { useMemo } from "react";
import { Image } from "antd";
import "./SpaceComponents.scss";
import { isFormatImage } from "../../Utilities";
import { DownloadOutlined } from "@ant-design/icons";

const AssetCard = ({ platform, uid, name, link }) => {

  const isImageFormat = useMemo(
    () => (uid ? isFormatImage(name) : isFormatImage(link)),
    [name, uid, link]
  );

  return (
    <div
      className="space-reference-file-card"
    >
      {isImageFormat ? (
        <Image
          src={link}
          className="space-image-card"
        />
      ) : (
        <a href={link} target="_blank" className="w-full h-full">
          <div
            className="w-full h-full flex-center link-card"
          >
            <DownloadOutlined />
            <span style={{maxWidth: "90%", marginRight: "auto", marginLeft: "auto"}} className="p-max-2">{name}</span>
          </div>
        </a>
      )}
    </div>
  );
};

export default AssetCard;
