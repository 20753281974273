import React, { useState, useEffect }  from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Progress, Button, message } from 'antd';
import RoomInformation from "./forms/RoomInformation";
import RoomReference from "./forms/RoomReference";
import RoomFloorPlan from "./forms/RoomFloorPlan";
import RoomElements from './forms/RoomElements';
import RoomOutsideView from './forms/RoomOutsideView';
import CustomerMainLayout from '../CustomerMainLayout/CustomerMainLayout';
import MspWarningModal from '../MspWarningModal/MspWarningModal';
import {fetchRoom} from "../../../../redux/actions";
import {withRouter} from "react-router-dom";
import ENVIRONMENT from '../../../../environments';
import {connect} from "react-redux";
import FileConstants from '../../../../FileConstants';
import axios from 'axios';
import CustomerOnboarding from './../../CustomerComponents/CustomerOnboarding';
import HelpMessageModal from '../../HelpMessageModal/HelpMessageModal';
import * as Utilities from "../../Utilities";


const AddNewRoom = (props) => {

    const [form, setForm] = useState({
        currentStep: 0
    });
    const [isMspUserWarningModal, setIsMspUserWarningModal] = useState(false);
    const [redirectionPath, setRedirectionPath] = useState(null);
    const [helpMessageModal, setHelpMessageModal] = useState(false);

    useEffect(() => {
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        setRedirectionPath(redirection_path);
    }, [])
    

    let onboarding = new URLSearchParams(window.location.search).get("onboarding");
    if(onboarding == undefined){
        onboarding = false;
    }
    else {
        if (typeof(onboarding == 'string') || onboarding instanceof String) {
            onboarding = (onboarding.toLowerCase() === 'true');
        }
        if (onboarding == false) {
            onboarding = false;
        }
        else {
            onboarding = true;
        }
    }
    const [showOnboarding, setShowOnboarding] = useState(onboarding);

    useEffect(() => {
       let step = new URLSearchParams(window.location.search).get("step");
       let id = new URLSearchParams(window.location.search).get("room_id");
       if(step) {
           let currentVal = {...form};
           currentVal.currentStep = step;
           setForm(currentVal);
       }
       if(id) {
           props.getRoom({room_id: id});
       }
    }, []);

    useEffect(() => {
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') == null)) {
            setIsMspUserWarningModal(true);
        }
    },[])
  
    const handleSubmit = (values, e) => {
        e.preventDefault();
    }

    const goToNextForm = ()=> {
        let currentVal = {...form};
        currentVal.currentStep = new URLSearchParams(window.location.search).get("step") || currentVal.currentStep + 1;
        setForm(currentVal);
    }

    const getClassNameByState = (state, index) => {
        if (state == index) {
            return 'form-state-incomplete'
        } else if (state > index) {
            return 'form-state-complete'
        } else {
            return 'form-state-disabled'
        }
    }

    const handlePageRedirection = () => {
        let cookie_name = Utilities.getCookie("save_help")
        if (!cookie_name) {
            setHelpMessageModal(true);
        } else {
            message.info('Space form saved successfully');
            if (redirectionPath) {
                props.history.push({
                    pathname: redirectionPath
                });
            } else {
                props.history.push({
                    pathname: '/home'
                });
            }
        }
    }

    const handleWarningModalClose = () => {
        setIsMspUserWarningModal(false);
    }

    const done =  <img src={require("../../../../assets/images/check-mark.png")} alt=""></img>
    return <CustomerMainLayout selected='5'>
                <div style={{marginBottom:"100px"}}>
                    <div className="add-product-layout-container  bg-color color-6b">
                        <Progress className="product-add-progress" strokeLinecap="square" strokeColor={'#70d09f'} showInfo={false} percent={((form.currentStep)/3)*100} />
                        <Row className="product">
                            <Col span={4}></Col>
                            <Col span={16} >
                                {
                                (localStorage.getItem("is_msprovider") == 'true') && localStorage.getItem('managed_customer_username') &&
                                <Row gutter={[12,15]}>
                                <Col span={24}>
                                <div className="custom-alert info">
                                You are requesting this Space for customer {localStorage.getItem('managed_customer_username')}
                                <>{(localStorage.getItem('managed_company_name') != undefined) ? (' (Company: ' + localStorage.getItem('managed_company_name') + ')'):''}</>
                                </div>
                                </Col>
                                </Row>
                                
                                }
                                <Row>
                                    <Col span={12}>
                                        <div className="manrope f-24 black-14 w-700">Add New Space</div>
                                    </Col>
                                   
                                </Row>
                                <Row style={{marginTop: '20px', marginBottom: '20px'}}>
                                    <Col span={24} style={{display: 'flex', justifyContent: 'space-around'}}>
                                        <div style={{ borderRight: '0px', width: '100%'}} className={getClassNameByState(form.currentStep, 0)}>
                                            {form.currentStep> 0?  <span className="done"> {done} </span> :  <span className="info manrope f-14 w-700">Space Info</span>}
                                        </div>
                                        <div style={{ borderRight: '0px', width: '100%'}} className={getClassNameByState(form.currentStep, 1)}>
                                            {form.currentStep> 1?  <span className="done"> {done} </span> :  <span className="info manrope f-14 w-700">Floor Plan</span>}
                                        </div>
                                        <div style={{ borderRight: '0px', width: '100%'}} className={getClassNameByState(form.currentStep, 2)}>
                                            {form.currentStep> 2?  <span className="done"> {done} </span> :  <span className="info manrope f-14 w-700">Reference Imagery</span>}
                                        </div>
                                        <div  style={{ borderRight: '0px', width: '100%'}} className={getClassNameByState(form.currentStep, 3)}>
                                            {form.currentStep> 3?  <span className="done"> {done} </span> :  <span className="info manrope f-14 w-700">Tagging Elements</span>}
                                        </div>
                                        <div  style={{width: '100%'}} className={getClassNameByState(form.currentStep, 4)}>
                                            {form.currentStep> 4?  <span className="done"> {done} </span> :  <span className="info manrope f-14 w-700">Outside View</span>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 100}}>
                                    <Col span={24}>
                                        {form.currentStep == 0 &&  <RoomInformation next={goToNextForm} onSubmit={handleSubmit} redirectionPath={redirectionPath} handlePageRedirection={handlePageRedirection}/>}
                                        {form.currentStep == 1 &&  <RoomFloorPlan next={goToNextForm} onSubmit={handleSubmit} redirectionPath={redirectionPath}  handlePageRedirection={handlePageRedirection}/>}
                                        {form.currentStep == 2 &&  <RoomReference  next={goToNextForm} onSubmit={handleSubmit} redirectionPath={redirectionPath}  handlePageRedirection={handlePageRedirection}/>}
                                        {form.currentStep == 3 &&  <RoomElements  next={goToNextForm} onSubmit={handleSubmit} redirectionPath={redirectionPath}  handlePageRedirection={handlePageRedirection}/>}
                                        {form.currentStep == 4 &&  <RoomOutsideView next={goToNextForm} onSubmit={handleSubmit} redirectionPath={redirectionPath}  handlePageRedirection={handlePageRedirection}/>}
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                    </div>
                    <MspWarningModal
                        visible={isMspUserWarningModal}
                        handleModalClose={handleWarningModalClose}
                    />
                    <HelpMessageModal
                    visible={helpMessageModal}
                    closable={true}
                    onCancel={handlePageRedirection}
                    heading={"Draft Saved Successfully!"}
                    help_image={'/img/inprogress-screen.png'}
                    text={`Your drafts are saved in 'Incomplete' section on your home dashboard.`}
                    cookie_name={"save_help"}
                    />
                </div>
            </CustomerMainLayout>
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getRoom: (room) => {
        dispatch(fetchRoom(room));
    },
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(AddNewRoom)
)