import { SCENE_BASE_URL } from "../../../../environments/env";

export const selected_angle_style={ width: "100%", border: "2.5px solid #276dd7",  borderRadius:6,"box-shadow": "rgba(0, 0, 0, 0.8) 0px 0px 21px -1px", "transition": "all 0.3s ease 0s", opacity: 0.7, height: 110, "object-fit": "contain"};
export const angle_style={ width: "100%", border: "2.5px solid #276dd7", borderRadius:6,height: 110, "object-fit": "contain"};

export const cameraRenderBaseURL = SCENE_BASE_URL;

export const resolutionsMapping = {
    "horizontal":{
        "1":{
            "width":3200,
            "height":1800
        },
        "2":{
            "width":1600,
            "height":900
        },
        "3":{
            "width":800,
            "height":450
        }
    },
    "vertical":{
        "1":{
            "width":1800,
            "height":3200
        },
        "2":{
            "width":900,
            "height":1600
        },
        "3":{
            "width":450,
            "height":800
        }
    },
    "square":{
        "1":{
            "width":4096,
            "height":4096
        },
        "2":{
            "width":2048,
            "height":2048
        },
        "3":{
            "width":1024,
            "height":1024
        }
    }
}

export const aspectRatioLabelsMap = {
    "horizontal":"16:9 Horizontal (Default)",
    "vertical":"9:16 Vertical",
    "square":"1:1 Square",
    "custom":"Custom Aspect Selected",
};

export const squareResolutionsMaping = {
    '1k': {
        width: 1024,
        height: 512
    },
    '2k': {
        width: 2048,
        height: 1024
    },
    '4k': {
        width: 4096,
        height: 2048
    },
}

export const gridColors = [
    '#FFFFFF',
    '#000000',
    '#0000FF'
]

export const overlayColors =
[
    '#500000',
    '#000000',
    '#FFFFFF'
]

export const gridThicknessValues = [
    1,
    2,
    3
]