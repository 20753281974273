import React from "react";
import ENVIRONMENT from '../../../../environments';
import { Row, Col, Tooltip, Card, Button, Modal } from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image-more';

const ref = React.createRef();


class PreprocessingImages extends React.Component {
    
    state = {
        expanded_preprocessed_images: false,
        hex_palettes: true,
        isLoading: false
    }

    render() {
        const hexToRGB = (hex) => {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return "rgb(" + parseInt(result[1], 16) + ", " + parseInt(result[2], 16) + "," + parseInt(result[3], 16) +")";        
        }
        const stopLoader = () => {
            this.setState({
                isLoading: false
            })
        }

        const convertToPDF = (product_id) => {
            var element = document.getElementById('preprocessed-pdf');
            domtoimage.toPng(element)
            .then(function (blob) {
                console.log(element.offsetWidth)
                console.log(element.offsetHeight)
                var pdf = new jsPDF('p', 'px', [element.offsetHeight, element.offsetWidth], 'px_scaling');
                pdf.addImage(blob, 'PNG', 0, 0);
                pdf.save("Product " + product_id + " preprocessing-images.pdf");
                stopLoader();
              });
        }

        // PREPROCESSING CODE
        let primary_palettes = []
        let primary_image = ""
        let primary = []
        let segments = []
        let segment_images = []
        let segment_palettes_arrays = []
        let color_extracted_images = this.props.color_extracted_images
        if (color_extracted_images != undefined && Object.keys(color_extracted_images).length > 0){
            primary = color_extracted_images.primary
            if(primary != undefined){
                // Primary image and color palettes
                Object.keys(primary.color_segmented_info).forEach(
                    (key) => {
                        let color_code = primary.color_segmented_info[key]
                        primary_palettes.push(
                            <div className="palette" style={{backgroundColor: color_code}}>
                            <p className="colorCode-label">{this.state.hex_palettes ? color_code : hexToRGB(color_code)}</p>
                            </div>
                        )
                    })
                    let file_url = ENVIRONMENT.getBaseURL() + ENVIRONMENT.PREPROCESSING_URI + this.props.product_id + "/" + primary.img_url;
                    primary_image = (<p className="apply-Avenir"><a href={file_url} target='_blank'><img crossOrigin="anonymous" className="segment-images" src={file_url}></img></a></p>)
            }

            segments = color_extracted_images.segments
            if(segments != undefined){
                // Segmented clusters of color palettes
                let temp_cluster = []
                segments.map((segment) => {
                    // Placing the color palettes of segmented images in an array
                    let cluster = Object.values(segment)[0]
                    temp_cluster = []
                    cluster.map((palette) =>{
                        temp_cluster.push(
                            <div className="palette" style={{backgroundColor: palette}}>
                                <p className="colorCode-label">{this.state.hex_palettes ? palette : hexToRGB(palette)}</p>
                            </div>
                        )
                    })
                    segment_palettes_arrays.push(temp_cluster)

                    // Placing the segmented images in an array
                    let segment_img = Object.keys(segment)[0]
                    let file_url = ENVIRONMENT.getBaseURL() + ENVIRONMENT.PREPROCESSING_URI + this.props.product_id + "/" + segment_img
                    segment_images.push(
                        <Col span={12} className={"segment-image-with-palette"} >
                            <p className="apply-Avenir"><a href={file_url} target='_blank'><img crossOrigin="anonymous" className="segment-images" src={file_url}></img></a></p>
                            <div style={{display:"inline-block", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "30px"}}>
                            {temp_cluster}
                            </div>
                            
                        </Col>                        
                    )
                
                })
            }

            const expandPreprocessedImages = () => {
                this.setState({
                    expanded_preprocessed_images: !this.state.expanded_preprocessed_images
                })
            }

            const convertToRGB = () => {
                this.setState({
                    hex_palettes: false
                })
            }

            const convertToHex = () => {
                this.setState({
                    hex_palettes: true
                })
            }

            const preprocessing_images = (
                <React.Fragment>
                    <div id="preprocessed-pdf">
                        
                        <Row>
                            <div>
                                <p className={"preprocessing-text"} style={{display: "inline-block"}}><b>RGB Color Palette of Reference Image</b></p>
                                <Button id="hexButton" onClick={() => convertToHex()} size="small" style={{marginLeft: "15px", borderRadius: "4px 0px 0px 4px", width: "48px", height: "28px", background: this.state.hex_palettes ? "#333333" : "white", color: this.state.hex_palettes ? "white" : "#777777" }}>
                                    Hex
                                </Button>
                                <Button onClick={() => convertToRGB()} size="small" style={{borderRadius: "0px 4px 4px 0px", width: "48px", height: "28px", background: !this.state.hex_palettes ? "#333333" : "white", color: !this.state.hex_palettes ? "white" : "#777777"}}>
                                    RGB
                                </Button>
                            </div>
                        </Row>
                        <Row id={"primary-preprocessed-image"} style={{marginTop: 15}}>
                            
                            <Col span={12} style={{display: "inline-block"}} >
                                {primary_image}
                            </Col>
                            <Col span={12} style={{display:"inline-block", paddingLeft: "10px", paddingBottom: "30px"}}>
                                <div style={{marginTop: "12%", marginRight: "7%"}}>
                                    <p className={"palette-row-heading"} style={{margin: 0, textAlign: "center", color: "black", marginRight: "7%"}}>Color palette for Reference Image</p>
                                    {primary_palettes}
                                </div>
                                
                            </Col>
                        </Row>
                        
                        <p className={"preprocessing-text"}><b>Segment based Color Clusters:</b></p>
                        <Row>
                            {segment_images}
                        </Row>
                    </div>
                    
                    <Button onClick={() => {
                        convertToPDF(this.props.product_id); this.setState({isLoading: true})}} id={"export_pdf_button"} type="primary" style={{marginLeft: "30px", marginBottom: "15px", backgroundColor: "#276DD7"}}>
                        {this.state.hex_palettes ? "Export As PDF in Hex"  : "Export As PDF in RGB"}
                        {this.state.isLoading ? <LoadingOutlined spin/> : ''}
                    </Button>
                </React.Fragment>
                
            )
            if(!this.state.expanded_preprocessed_images){
                return (
                    <Col ref={ref} xs={24} sm={24} lg={24} xl={24} style={{paddingTop: "30px", paddingLeft: "15px"}} className="preprocessed-images">
                        <Card>
                        <Tooltip placement="left">
                            <img src={"/img/fullScreenIcon.jpg"}  alt="Fullscreen Icon" 
                            style={{position:"absolute", top:"20px",zIndex:"10",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                            className="product-full-screen-icon" onClick={() => expandPreprocessedImages() } />
                        </Tooltip> 
                        {preprocessing_images}                                                                                              
                        </Card>
                    </Col>
                )
            }
            else
            {
                // Render expanded version of preprocessing card
                return (
                    
                    <Modal
                        visible={this.state.expanded_preprocessed_images}
                        width={"1024px"}
                        footer={false}
                        maskClosable={true}
                        onCancel={() => expandPreprocessedImages() }
                        closeIcon={
                            <img src={"/img/closeIcon.png"}  alt="Close Modal Icon" 
                            style={{cursor:"pointer",borderRadius:"4px"}} 
                            onClick={() => expandPreprocessedImages() } />
                        }
                    >
                        <Col ref={ref} span={24} style={{padding: "30px"}} className="preprocessed-images">
                        <div>
                            {preprocessing_images}                                                                   
                        </div>
                    </Col>
                    </Modal>
                )
            }
            
        }
        else {
            return null;
        }
        }
   
}
export default PreprocessingImages;