import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import CustomerMainLayout from '../../CustomerComponents/CustomerMainLayout/CustomerMainLayout';
import AddNewProductForm from "./AddNewProductForm"
import {fetchProduct} from "../../../../redux/actions"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import ModelVariationRequest from '../../CustomerComponents/ModelVariationRequest/ModelVariationRequest';
import SuccessModal from '../../SuccessModal/SuccessModal';
import MspWarningModal from '../../CustomerComponents/MspWarningModal/MspWarningModal';
import FileConstants from '../../../../FileConstants';

const AddNewProduct = (props) => {
  const { match: {params} } = props;
  const [formType, setFormType] = useState(0);
  const [form, setForm] = useState({
    currentStep: 0
  });
  const [prices, setPrices] = useState([]);
  const [requestStatus, setRequestStatus] = useState(null);
  const [cardStatus, setCardStatus] = useState(null);
  const [modelHeight, setModelHeight] = useState('');
  const [modelWidth, setModelWidth] = useState('');
  const [modelDepth, setModelDepth] = useState('');
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [submitSuccess,setSubmitSuccess] = useState(false);
  const [currentPackage, setCurrentPackage] = useState('');
  const [isMspUserWarningModal, setIsMspUserWarningModal] = useState(false);
  
  useEffect(() => {
    let product_id = params.id;
    if (product_id) {
      props.getProduct({ product_id: product_id });
    }
    loadUserRequestStatus();
    if (props && props.match) {
      if (props.match.path.includes("add-new-product")) {
          setFormType('designed_product');
      }
      else if (props.match.path.includes("create-existing-physical-product")) {
          setFormType('existing_product');
      }
    }
  }, []);

  useEffect(() => {
    let model_height = "N/A", model_depth = "N/A", model_width = "N/A";
    if (props.productReducer.product) {
      if(props.productReducer.product.model_info){
        // If model_info has low poly info
        if(props.productReducer.product.model_info.low){
            model_height = props.productReducer.product.model_info.low.height || "N/A";
            model_depth = props.productReducer.product.model_info.low.depth || "N/A";
            model_width = props.productReducer.product.model_info.low.width || "N/A";
        }
        else if(props.productReducer.product.model_info.high){
            // if model_info has high poly info
            model_height = props.productReducer.product.model_info.high.height || "N/A";
            model_depth = props.productReducer.product.model_info.high.depth || "N/A";
            model_width = props.productReducer.product.model_info.high.width || "N/A";
        }
        else {
            // for backward compatibility, where model_info only contains model_dimenions and vertex count
            model_height = props.productReducer.product.model_info.height || "N/A";
            model_depth = props.productReducer.product.model_info.depth || "N/A";
            model_width = props.productReducer.product.model_info.width || "N/A";
        }
      } else if (model_depth == 'N/A' && model_height == 'N/A' && model_height == 'N/A' && props.productReducer.product.width && props.productReducer.product.height && props.productReducer.product.length) {
        model_depth = parseInt(props.productReducer.product.length);
        model_height = parseInt(props.productReducer.product.height);
        model_width = parseInt(props.productReducer.product.width);
      }
    }

    setModelHeight(model_height);
    setModelDepth(model_depth);
    setModelWidth(model_width);
  }, [props.productReducer.product]);

  useEffect(() => {
    if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') == null)) {
        setIsMspUserWarningModal(true);
    }
  },[])

  const afterSubmit = () => {
    setSubmitSuccess(true);
  }

  const afterSave = () => {
    setSaveSuccess(true);
  }

  const getProductCategories = () => {
    let payload = {
        "request_type": 'product_model'
    }

    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
    .then(res => {
        console.log(res);
        if (res.data) {
            setPrices(res.data);
        }
    })
  }

  const loadUserRequestStatus = () => {
    let payload = {
      "username": localStorage.getItem('username'),
      "request_type": "product_model_high_res",
      "action": "check"
    }
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then(res => {
          console.log(res)
            setRequestStatus(res.data['request_allowed']);
            setCardStatus(res.data['card_status']);
            setCurrentPackage(res.data['subscription_plan']);
            getProductCategories();
        });
  }

  const handleSubmit = (values, e) => {
    e.preventDefault();
  }

  const handleWarningModalClose = () => {
    setIsMspUserWarningModal(false);
  }
  
  const goToNextForm = () => {
    let currentVal = { ...form };
    currentVal.currentStep = new URLSearchParams(window.location.search).get("step") || currentVal.currentStep + 1;
    setForm(currentVal);
  }
  return <CustomerMainLayout selected='3'>
          <div>
            <div className="add-product-layout-container   bg-color color-6b">
              <Row className="product justify-in-center">
                <Col span={props.productReducer.product.variant_of ? 20 : 16} >
                {props.productReducer.product.admin_comments ?
                  <div className="flagged-bg border pd-16" style={{marginBottom: 32}}>
                    <Row type="flex" justify="space-between" style={{marginBottom: 10}}>
                        <div className="manrope f-14 red">Comments from ALL3D:&nbsp;<span className="manrope f-14 black-33">{props.productReducer.product.admin_comments}</span></div>
                    </Row>
                    <Row type="flex" justify="space-between">
                      {(props.productReducer.product.admin_attachments != undefined && props.productReducer.product.admin_attachments.length > 0) ?
                        <span>
                          <div className="manrope f-14 red">Attachments: </div>
                          {props.productReducer.product.admin_attachments && props.productReducer.product.admin_attachments.map(file => (
                            <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                              {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".svg")) ? 
                              <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                              (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> : 
                              <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                              <div className="manrope f-14 blue link">{file.name}</div>
                            </a>
                            ))}
                        </span> : ""}
                    </Row>
                  </div>
              : ""}
                  {
                  (localStorage.getItem("is_msprovider") == 'true') && localStorage.getItem('managed_customer_username') &&
                  <Row gutter={[12,15]}>
                    <Col span={24}>
                    <div className="custom-alert info">
                    You are requesting this Product Model for customer {localStorage.getItem('managed_customer_username')}
                    <>{(localStorage.getItem('managed_company_name') != undefined) ? (' (Company: ' + localStorage.getItem('managed_company_name') + ')'):''}</>

                    </div>
                    </Col>
                  </Row>
                  }
                  <Row>
                    {props.productReducer.product.variant_of  ?
                    <Col span={12}>
                      <div className="manrope f-24 black-33 w-700">Product Model Variation Request</div>
                    </Col>:
                    formType == 'designed_product' ?
                    <Col span={12}>
                      <div className="manrope f-24 black-33 w-700">Design a New Product</div>
                    </Col>:
                    <Col span={12}>
                      <div className="manrope f-24 black-33 w-700">Existing Physical Product</div>
                    </Col>
                    }
                  </Row>
                  {props.productReducer.product.variant_of ?
                  <Row>
                    <div className="note-bg-specs" style={{marginTop: 20,marginBottom: 20, width:"max-content"}}>
                        <span  className="manrope f-14 blue">Base Model:&nbsp;</span>                   
                        <a className="manrope f-14 blue hover-change" href={"/products/"+props.productReducer.product.variant_of} target="_blank">
                          {props.productReducer.product.variant_of}
                        </a>
                    </div>
                    <div className="note-bg-specs" style={{marginTop: 20,marginBottom: 20, marginLeft:20, width:"max-content"}}>
                        <span  className="manrope f-14 blue">Variation Type:&nbsp;</span>      
                        <span className='manrope f-14 blue'>{FileConstants.variationDict(props.productReducer.product.variation_type)}</span>             
                       
                    </div>
                  </Row>: ''}
                  <Row>
                    <Col span={24}>
                    {props.productReducer.product.variant_of ? 
                    <ModelVariationRequest
                    requestStatus={requestStatus} 
                    cardStatus={cardStatus}
                    variationType={props.productReducer.product.variation_type}
                    savedID={props.productReducer.product.product_id}
                    product_id={props.productReducer.product.variant_of}
                    product_details={props.productReducer.product}
                    page_format={true}
                    model_depth={modelDepth}
                    model_height={modelHeight}
                    model_width={modelWidth}
                    variationCreated={afterSubmit}
                    variationSaved={afterSave}
                    />
                    :
                      <AddNewProductForm formType={formType} next={goToNextForm} onSubmit={handleSubmit} prices={prices} 
                      requestStatus={requestStatus} 
                      currentPackage={currentPackage}
                      loadUserRequestStatus={loadUserRequestStatus}
                      cardStatus={cardStatus}/>}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <SuccessModal
           visible={saveSuccess}
           onCancel={() => setSaveSuccess(false)}
           heading={"Product Variation Saved Successfully!"}
           text={"You can come back any time by reopening this form from the incomplete section to fill out the remaining information."}
           footer={[
            <div className="justify-in-center">
              <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => window.location.href = "/home"}>
                Okay
              </Button>
            </div>
           ]}
          />
           <SuccessModal
            visible={submitSuccess}
            onCancel={() => setSubmitSuccess(false)}
            heading={"Model Variation Requested Successfully!"}
            text={"Your Product Model's Variation request has been successfully submitted. You will be notified via email once your Product Model is ready to be reviewed."}
            footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay square font-12"   onClick={() => window.location.href = "/home"}>
                        Okay
                    </Button>
                </div>
            ]}
            />
            <MspWarningModal
              visible={isMspUserWarningModal}
              handleModalClose={handleWarningModalClose}
            />
      </CustomerMainLayout>
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  getProduct: (product) => {
    dispatch(fetchProduct(product));
  },
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewProduct)
)