import React from 'react';
import {
    CloseOutlined,
    LoadingOutlined,
    RightOutlined,
    CameraOutlined
} from '@ant-design/icons';
import {
    Row,
    Col,
    Radio,
    Button,
    Modal,
    InputNumber,
    Dropdown,
    Input,
    Menu,
    Switch,
    Checkbox,
    Popover,
    Slider,
    Divider,
    Upload
} from 'antd';
import Tour from "reactour";
import * as Settings from './settingsConstants';
import { string } from 'prop-types';
import SuccessModal from '../SuccessModal/SuccessModal';
import WarningModal from '../WarningModal/WarningModal';
import CardSaveDrawer from '../CardSaveDrawer/CardSaveDrawer';
import SubscriptionPackageUpdate from '../SubscriptionPackageUpdate';
import InAppPurchaseLimitModal from '../CustomerComponents/InAppPurchaseLimitModal';
import SiloSnapshotModal from './SiloSnapshotModal';
import SiloPhotographyPresetsModal from './SiloPhotographyPresetsModal';
import * as Constants from "../CustomerComponents/Constants";
import ENVIRONMENT from '../../../environments'
import axios from 'axios';

const radioStyleInline = {
    display: 'inline',
    height: '20px',
    lineHeight: '120%'
};

const angle_marks = {
    20: '20°',
    30: '',
    40: '',
    50: '',
    60: '',
    70: '',
    80: '80°',
};

const mm_marks = {
    10: '10mm',
    20: '',
    30: '',
    40: '',
    50: '',
    60: '',
    70: '',
    80: '',
    90: '',
    100: '100mm',
};

const SiloPhotographyModals = (props) => {
    let aspectRatio = props.selectedAspectRatio;
    console.log("aspectRatio", aspectRatio)
    if (props.orientation != 'Custom') {
        let aspectDecoded = props.selectedAspectRatio.split(':');
        aspectRatio = aspectDecoded[0]/aspectDecoded[1];
    }
    let focusPointerMargin = -16;
    if (aspectRatio < 1) {
        focusPointerMargin = -20;
    }
    const steps = [
        {
        selector: '[data_tut="reactour__panrotate"]',
        content: () => (
            <div style={{padding: "12px"}}>
                <h5 className="help-silo-heading">Rotate and Pan</h5>
                <p className="help-silo-body">You can switch between rotate and pan by simply clicking on their icons. These will allow you to place your model precisely for the snapshot.</p>
            </div>
            ),
        position: 'right',
        style: {minWidth: 400}
        },
        {
        selector: '[data_tut="reactour__dragrotate"]',
        content: () => (
            <div style={{padding: "12px"}}>
                <h5 className="help-silo-heading">Click and Drag to Rotate and Pan Model</h5>
                <p className="help-silo-body">Click and drag to rotate and pan your Model to take angled snapshots.</p>
            </div>
            ),
        position: 'left',
        style: {minWidth: 400,transform: "translate(-29px, 32%)",right:"19%",left:"unset",}
        },
        {
            selector: '[data_tut="reactour__dragrotate"]',
            content: () => (
                <div>
                <div style={{padding: "12px"}}>
                    <h5 className="help-silo-heading">Double Click on Model to Focus</h5>
                    <p className="help-silo-body">Double click on any point on the Model to shift to focus mode around that point. <br/><br/> Double click outside the Model to shift out of the focus mode.</p>
                </div>
                <div style={{ transform: "translate(" + focusPointerMargin +"vw, -10vh)", pointerEvents: "none"}}>
                <img  src={require("../../../assets/images/pointHand.png")} style={{height:"45px", width:"45px", borderRadius: "50%" ,animation:"pulse-pointer 1.2s infinite", opacity: 0.7}} ></img>
                </div>
                </div>
                ),
            position: 'absolute',
            style: { minWidth: 400,transform: "translate(55vw, 15vh)" ,right:"unset",},


        },
        {
        selector: '[data_tut="reactour__cameraheight"]',
        content: () => (
            <div style={{padding: "12px"}}>
                <h5 className="help-silo-heading">Change Camera Height to take Top Down Snapshots</h5>
                <p className="help-silo-body">Click the Camera widget to expand it. Move the camera up and down the slider to change camera height for top down shots.</p>
            </div>
            ),
        observe: '[data_tut="reactour__cameraheight--observe"]',
        position: 'bottom',
        style: {minWidth: 400}
        },
        {
        selector: '[data_tut="reactour__resetcamera"]',
        content: () => (
            <div style={{padding: "12px"}}>
                <h5 className="help-silo-heading">Reset Camera to Original View</h5>
                <p className="help-silo-body">Click to reset your camera if you cant figure your angle out and start again from the original view.</p>
            </div>
            ),
        position: 'top',
        style: {minWidth: 400}
        },
        {
        selector: '[data_tut="reactour__defaultsnapshot"]',
        content: () => (
            <div style={{padding: "12px"}}>
                <h5 className="help-silo-heading">Default Snapshots to the rescue!</h5>
                <p className="help-silo-body">You can simply choose any of the snapshots provided by us and click on “Take Snapshot” to capture it.</p>
            </div>
            ),
        position: 'right',
        style: {minWidth: 400}
        }
  ];

    return <div>
            <Modal
            maskClosable={false}
            closable
            centered
            className="aspect-ratio-modal-closable"
            onCancel={props.closeAspectRatioModal}
            visible={props.aspectRatioModal}
            footer={[
                <div className="justify-in-end">
                <Button disabled={(!props.aspectClicked ? ((props.orientation == 'Custom' || props.orientation == "Square") && props.orientationClicked ? false : true) : false)} onClick={() => props.orientationSettings()} className="modal-okay font-14 square" htmlType="submit">
                    Done
                </Button>
                </div>
            ]}>
                <div style={{padding:"40px"}}>
                    <h2 className="orientation-heading">Select Orientation</h2>
                    <Row>
                        <Col span={24} style={{display:"flex"}}>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Landscape') }}>
                                <img className="ori-img landscape" style={{opacity: props.orientation == 'Landscape' && props.orientationClicked ? 1 : 0.5}} src={`/img/Landscape_default.png`}/>
                            </div>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Portrait') }}>
                                <img style={{opacity: props.orientation == 'Portrait' && props.orientationClicked  ? 1 : 0.5}} className="ori-img portrait" src={`/img/Portrait_default.png`}/>
                            </div>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Square') }}>
                                <img style={{opacity: props.orientation == 'Square' && props.orientationClicked  ? 1 : 0.5}} className="ori-img square" src={`/img/Square_default.png`}/>
                            </div>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Custom') }}>
                                <img style={{opacity: props.orientation == 'Custom' && props.orientationClicked  ? 1 : 0.5}} className="ori-img custom" src={`/img/Custom_default.png`}/>
                            </div>
                        </Col>
                    </Row>
                    <h2 className="orientation-heading" style={{marginTop:32,display:((props.orientation == 'Landscape' || props.orientation =='Portrait') && props.orientationClicked) ? "flex" : "none"}}>Select Aspect Ratio</h2>
                    <Row>
                        <Col span={24} style={{display: props.orientation == 'Landscape' && props.orientationClicked ? "flex" : "none"}}>

                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('16:10','Landscape')}}>
                                <img className="ori-img screen-16-10" style={{opacity: props.selectedAspectRatio == '16:10' && props.aspectClicked ? 1 : 0.5}} src={`/img/16_10.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('16:9','Landscape')}}>
                                <img className="ori-img screen-16-9" style={{opacity: props.selectedAspectRatio == '16:9'  && props.aspectClicked ? 1 : 0.5}} src={`/img/16_9.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('1.91:1','Landscape')}}>
                                <img className="ori-img screen-1-9-1" style={{opacity: props.selectedAspectRatio == '1.91:1' && props.aspectClicked ? 1 : 0.5}} src={`/img/1_9_1.png`}/>
                            </div>
                        </Col>
                        <Col span={24} style={{display: props.orientation =='Portrait' && props.orientationClicked ? "flex" : "none"}}>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('9:16','Portrait')}}>
                                <img className="ori-img screen-9-16" style={{opacity: props.selectedAspectRatio == '9:16'  && props.aspectClicked? 1 : 0.5}} src={`/img/9_16.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('10:16','Portrait')}}>
                                <img className="ori-img screen-10-16" style={{opacity: props.selectedAspectRatio == '10:16' && props.aspectClicked ? 1 : 0.5}} src={`/img/10_16.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('1:1.91','Portrait')}}>
                                <img className="ori-img screen-1-1-9" style={{opacity: props.selectedAspectRatio == '1:1.91' && props.aspectClicked ? 1 : 0.5}} src={`/img/1_1_9.png`}/>
                            </div>
                        </Col>
                    </Row>
                    <h2  className="orientation-heading" style={{display: props.orientation !='Custom' && props.aspectClicked|| props.orientation =="Square" && props.orientationClicked ? "block" : "none",marginTop:32}}>Select Resolution</h2>
                    <Row style={{display: props.orientation != 'Custom' && props.aspectClicked|| props.orientation =="Square" && props.orientationClicked ? "flex" : "none"}}>
                        <Col>
                            <Radio.Group onChange={props.onResolutionChange} style={{display:"contents"}} defaultValue={1} value={props.resolutionStatus}>
                                <Radio style={radioStyleInline} value={3}><span style={{color: props.resolutionStatus == 3 ? "#333333" : "#999999"}}  className="radio-btn-change f-14" >1K ({props.imageResArr[2]})</span></Radio>
                                <Radio style={radioStyleInline} value={2}><span style={{color: props.resolutionStatus == 2 ? "#333333" : "#999999"}}  className="radio-btn-change f-14" >2K ({props.imageResArr[1]})</span></Radio>
                                <Radio style={radioStyleInline} value={1}><span style={{color: props.resolutionStatus == 1 ? "#333333" : "#999999"}} className="radio-btn-change f-14" >4K ({props.imageResArr[0]})</span></Radio>

                                <Radio style={radioStyleInline} value={4}>
                                    <span className={`radio-btn-change f-14 ${props.resolutionStatus == 4 ? `custom-res-message` : ``}`} style={{color: props.resolutionStatus == 4 ? "#333333" : "#999999"}} >Add Custom Resolution
                                    {
                                        props.resolutionStatus == 4 ?
                                        <span style={{display:"flex",marginTop: 9}}>
                                            <div className="custom-input-box f-14" style={{marginRight:8}}>
                                                <span style={{marginRight:5}}>Width</span>
                                                <div className="custom-input-contain">
                                                    <InputNumber onChange={props.onImageWidthRes}
                                                    value={props.customWRes}
                                                    min={0.1} max={8096} />
                                                </div>
                                            </div>

                                            <div className="custom-input-box f-14">
                                                <span style={{marginRight:5}}>Height</span>
                                                <div className="custom-input-contain">
                                                    <InputNumber onChange={props.onImageHeightRes}
                                                    value={props.customHRes} min={0.1} max={8096} />
                                                </div>
                                            </div>
                                        </span> : ""
                                    }
                                    </span>
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <h2 className="orientation-heading" style={{display: props.orientation == 'Custom' && props.orientationClicked ? "block" : "none",marginTop:32}}>Enter Custom Resolution</h2>
                    <Row style={{display: props.orientation == 'Custom' && props.orientationClicked ? "block" : "none"}} >
                        <Col className="custom-res-row">
                            <div className="custom-aspects" style={{marginRight:20}}>
                                <span className="width-height-label f-14">Width</span>
                                <InputNumber className="width-height-label f-14" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.imageWidth}  min={1} max={8096} onChange={props.onChangeWidth}/>
                            </div>
                            <div className="custom-aspects" style={{marginRight:20}}>
                                <span className="width-height-label f-14">Height</span>
                                <InputNumber className="width-height-label f-14" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.imageHeight}  min={1} max={8096} onChange={props.onChangeHeight}/>
                            </div>
                            <div className="custom-aspects">
                                <span className="width-height-label f-14">Unit</span>
                                <Input className="width-height-label f-14" style={{width: 68,marginLeft:10}} value={'Pixels'} readOnly autoFocus={false} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display:props.orientation == 'Custom' && props.orientationClicked ? "block" : "none"}} >
                        <Col className="custom-res-row">
                                <div className="note-bg">
                                    <span className="width-height-label f-14" style={{color: "#262626"}}>Note:&nbsp;</span><span className="width-height-label f-14" style={{color: "#595959"}}>The width and height you add here will be the resolution in pixels for your image.</span>
                                </div>
                        </Col>
                    </Row>
                </div>
            </Modal>


            <Modal
                className="aspect-ratio-modal"
                visible={props.snapshotModal}
                maskClosable={false}
                centered
                onCancel={() => props.showSnapshotModal(false)}
                centered={true}
                width={900}
                footer={[
                    <div className='justify-in-end'>
                    <Button className="modal-okay-gray square font-14 pd-20" onClick={props.discardSnapshot}>
                        {props.editResFlag ? `Cancel` :`Discard Snapshot`}
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={props.snapshotTaken}>
                        Apply
                    </Button>
                    </div>
                ]}>
                <div style={{padding:"40px"}}>
                    <h2 className="manrope f-18 black-33 w-700">Snapshot Configuration</h2>
                    <Row type="flex">
                        <Col span={12}>
                            <img src={props.modalShot} className="snapshot-modal-img">
                            </img>
                        </Col>
                        <Col span={12} style={{paddingLeft: 20}}>
                            <div style={{display:"flex",justifyContent:"flex-start"}}>
                                <h5 className="manrope f-16 black-33 w-600">Select Orientation</h5>
                            </div>
                            <Row>
                                <Col span={24} style={{display:"flex"}}>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Landscape');
                                        props.setAspects('Landscape','16:10');
                                    }}>
                                        <img className="ori-img landscape" style={{opacity: props.orientation == 'Landscape' ? 1 : 0.5}} src={`/img/landscape_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Portrait');
                                        props.setAspects('Portrait','9:16');
                                    }}>
                                        <img style={{opacity: props.orientation == 'Portrait' ? 1 : 0.5}} className="ori-img portrait" src={`/img/portrait_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Square');
                                        props.setAspects('Square','1:1');
                                    }}>
                                        <img style={{opacity: props.orientation == 'Square' ? 1 : 0.5}} className="ori-img square" src={`/img/square_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Custom');
                                        props.setAspects('Custom','1:1');
                                    }}>
                                        <img style={{opacity: props.orientation == 'Custom' ? 1 : 0.5}} className="ori-img custom" src={`/img/custom_s.png`}/>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{display:"flex",justifyContent:"flex-start"}}>
                                <h5 className="manrope f-16 black-33 w-600" style={{marginTop:32,display: (props.orientation == 'Custom' || props.orientation =='Square') ? "none" : "flex"}}>Select Aspect Ratio</h5>
                            </div>
                            <Row>
                                <Col span={24} style={{display: props.orientation == 'Landscape' ? "flex" : "none"}}>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('16:10','Landscape');props.setAspects('Landscape','16:10');}}>
                                        <img style={{opacity: props.selectedAspectRatio == '16:10' ? 1 : 0.5}} className="ori-img screen-16-10" src={`/img/16_10_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('16:9','Landscape');props.setAspects('Landscape','16:9');}}>
                                        <img style={{opacity: props.selectedAspectRatio == '16:9' ? 1 : 0.5}} className="ori-img screen-16-9" src={`/img/16_9_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('1.91:1','Landscape');props.setAspects('Landscape','1.91:1');}}>
                                        <img style={{opacity: props.selectedAspectRatio == '1.91:1' ? 1 : 0.5}} className="ori-img screen-1-9-1" src={`/img/1_9_1_s.png`}/>
                                    </div>
                                </Col>
                                <Col span={24} style={{display: props.orientation =='Portrait' ? "flex" : "none"}}>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('9:16','Portrait');props.setAspects('Portrait','9:16');}}>
                                        <img style={{opacity: props.selectedAspectRatio == '9:16' ? 1 : 0.5}} className="ori-img screen-9-16" src={`/img/9_16_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('10:16','Portrait');props.setAspects('Portrait','10:16');}}>
                                        <img style={{opacity: props.selectedAspectRatio == '10:16' ? 1 : 0.5}} className="ori-img screen-10-16" src={`/img/10_16_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('1:1.91','Portrait');props.setAspects('Portrait','1:1.91');}}>
                                        <img style={{opacity: props.selectedAspectRatio == '1:1.91' ? 1 : 0.5}} className="ori-img screen-1-1-9" src={`/img/1_1_9_s.png`}/>
                                    </div>
                                </Col>
                            </Row>
                            <h5 className="manrope f-16 black-33 w-600" style={{display: props.orientation =='Custom' ? "none" : "block",marginTop:32}}>Resolution</h5>
                            <Row style={{display: props.orientation == 'Custom' ? "none" : "flex"}}>
                                <Col>
                                    <Radio.Group style={{marginBottom: 8}} className="res-radio-settings" onChange={props.onResolutionChange} defaultValue={1} value={props.resolutionStatus}>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={3}><span style={{color: props.resolutionStatus == 3 ? "#333333" : "#999999"}}  className="radio-btn-change" >1K ({props.imageResArr[2]})</span></Radio>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={2}><span style={{color: props.resolutionStatus == 2 ? "#333333" : "#999999"}}  className="radio-btn-change" >2K ({props.imageResArr[1]})</span></Radio>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={1}><span style={{color: props.resolutionStatus == 1 ? "#333333" : "#999999"}} className="radio-btn-change" >4K ({props.imageResArr[0]})</span></Radio>

                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={4}>
                                            <span className={`radio-btn-change ${props.resolutionStatus == 4 ? `custom-res-message` : ``}`} style={{color: props.resolutionStatus == 4 ? "#333333" : "#999999"}} >Add Custom Resolution
                                            {
                                                props.resolutionStatus == 4 ?
                                                <span style={{display:"flex",marginTop: 9}}>
                                                    <div className="custom-input-box" style={{marginRight:8}}>
                                                        <span style={{marginRight:5}}>Width</span>
                                                        <div className="custom-input-contain">
                                                            <InputNumber onChange={props.onImageWidthRes}
                                                            value={props.customWRes}  min={0.1} max={8096} />
                                                        </div>
                                                    </div>

                                                    <div className="custom-input-box">
                                                        <span style={{marginRight:5}}>Height</span>
                                                        <div className="custom-input-contain">
                                                            <InputNumber onChange={props.onImageHeightRes}
                                                            value={props.customHRes} min={0.1} max={8096} />
                                                        </div>
                                                    </div>
                                                </span> : ""
                                            }
                                            </span>
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <h2  style={{display: props.orientation == 'Custom' ? "block" : "none",marginTop:32}} className="manrope f-16 black-33 w-600">Enter Resolution</h2>
                            <Row style={{display: props.orientation == 'Custom' ? "block" : "none"}} >
                                <Col className="custom-res-row">
                                    <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                        <span className="width-height-label s-modal">Width</span>
                                        <InputNumber className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={props.imageWidth}  min={1} max={8096} onChange={props.onChangeWidthDynamically}/>
                                    </div>
                                    <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                        <span className="width-height-label s-modal">Height</span>
                                        <InputNumber className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={props.imageHeight}  min={1} max={8096} onChange={props.onChangeHeightDynamically}/>
                                    </div>
                                    <div className="custom-aspects s-modal">
                                        <span className="width-height-label s-modal">Unit</span>
                                        <Input className="width-height-label s-modal" style={{width: 68,marginLeft:10}} value={'Pixels'} readOnly autoFocus={false} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{display: props.orientation == 'Custom' ? "block" : "none",marginBottom:32}} >
                                <Col className="custom-res-row">
                                        <div className="note-bg">
                                            <span className="width-height-label s-modal" style={{color: "#262626"}}>Note:&nbsp;</span><span className="width-height-label s-modal" style={{color: "#595959"}}>The width and height you add here will be the resolution in pixels for your image.</span>
                                        </div>
                                </Col>
                            </Row>
                            <Row gutter={[32, 32]} className="justify-in-start" style={{alignItems:"flex-start"}}>
                                <Col>
                                    <h5 className="manrope f-16 black-33 w-600">Select DPI</h5>
                                    <Radio.Group
                                        defaultValue={'300'}
                                        value={props.dpi}
                                        onChange={props.onDPIChange}
                                        buttonStyle="solid"
                                        className="dpi-radio">
                                        <Radio.Button value={'300'} className="dpi-settings">300 DPI</Radio.Button>
                                        <Radio.Button value={'400'} className="dpi-settings">400 DPI</Radio.Button>
                                        <Radio.Button value={'600'} className="dpi-settings">600 DPI</Radio.Button>
                                    </Radio.Group>
                                </Col>

                                <Col>
                                    <h5 className="manrope f-16 black-33 w-600" >Image Format</h5>
                                    <Radio.Group value={props.imageFormat} className="res-radio-settings" defaultValue={'jpg'} onChange={props.onImageFormatChange} className="modal-text">
                                        <Radio style={radioStyleInline} value={'jpg'}><span className="radio-btn-change" style={{color: props.imageFormat == 'jpg' ? "#333333": "#999999"}}>JPG</span></Radio>
                                        <Radio style={radioStyleInline} value={'png'}><span className="radio-btn-change" style={{color: props.imageFormat == 'png' ? "#333333": "#999999"}}>PNG</span></Radio>
                                        <Radio style={radioStyleInline} value={'tiff'}><span className="radio-btn-change" style={{color: props.imageFormat == 'tiff' ? "#333333": "#999999"}}>TIFF</span></Radio>
                                    </Radio.Group>
                                </Col>

                            </Row>

                            <h5 className="manrope f-16 black-33 w-600" style={{marginTop: 32}}>Adjust Field of View / Focal Length</h5>
                            <Row>
                            <Col span={12} >
                                    <Radio.Group
                                        disabled ={props.defaultSettingsState}
                                        defaultValue={'fov'}
                                        value={props.fovSwitch}
                                        onChange={props.onFovSwitchChange}
                                        buttonStyle="solid"
                                        className="dpi-radio">
                                        <Radio.Button value = {'fov'} className="dpi-settings fov-settings">Field of View</Radio.Button>
                                        <Radio.Button value = {'fl'} onClick={props.setFocalLengthRangeValues} className="dpi-settings fov-settings">Focal Length</Radio.Button>
                                    </Radio.Group>
                            </Col>
                            </Row>
                            {props.fovSwitch == 'fov' ?
                            <Row>

                                <Col span={12} >
                                    <Slider
                                        marks={angle_marks}
                                        className="black-slider"
                                        min={20}
                                        max={80}
                                        onChange={props.onChangeFov}
                                        value={props.fovValue}
                                    />
                                </Col>
                                <Col span={4} className="custom-input-contain" style={{marginLeft: 16}}>
                                    <InputNumber
                                        className="input-fov"
                                        min={20}
                                        max={80}
                                        value={props.fovValue}
                                        onChange={props.onChangeFov}
                                        formatter={value => `${value}°`}
                                        parser={value => value.replace('°', '')}
                                    />
                                </Col>
                            </Row>
                            :
                            <Row>

                                <Col span={12} >
                                    <Slider
                                        marks={mm_marks}
                                        className="black-slider"
                                        min={props.focalLengthRange.min}
                                        max={props.focalLengthRange.max}
                                        onChange={props.onChangeFocalLength}
                                        value={props.focalLengthValue}
                                    />
                                </Col>
                                <Col span={4} className="custom-input-contain" style={{marginLeft: 16}}>
                                    <InputNumber
                                        className="input-fov"
                                        min={props.focalLengthRange.min}
                                        max={props.focalLengthRange.max}
                                        value={props.focalLengthValue}
                                        onChange={props.onChangeFocalLength}
                                        formatter={value => `${value}mm`}
                                        parser={value => value.replace('mm', '')}
                                    />
                                </Col>
                            </Row>
                            }
                            <h5 className="manrope f-16 black-33 w-600" style={{marginTop:32}}>Background and Shadow Settings</h5>
                            <Radio.Group value={props.defaultChecked} className="res-radio-settings" defaultValue={true} onChange={props.onChangeDefault} className="modal-text">
                                <Radio style={{radioStyleInline, marginBottom:24}} value={true}><span style={{color: props.defaultChecked ? "#333333" : "#999999"}} className="radio-btn-change">Use  <Popover content={
                                    <div className="silo-shot-popup">
                                    <img  src={props.modalShot} className="popup-silo-shot"/>
                                    <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Image with Default Settings</div>
                                    <div className="width-height-label">Default background and shadow settings are the ones used to generate Sample Images for you to Review your model.</div>
                                </div>}>
                                    <span style={{textDecoration:"underline"}}>default settings</span>
                                </Popover>
                                </span></Radio>
                                <Radio style={{radioStyleInline, marginBottom:24}} value={false}><span style={{color: props.defaultChecked ? "#999999" : "#333333"}} className="radio-btn-change">Use custom settings</span></Radio>
                            </Radio.Group>

                           {props.defaultChecked ? '' :
                            <Row style={{marginTop:8}}>
                                <Col style={{marginRight:32}}>
                                    <h5 className="manrope f-14 black-33 w-600" style={{fontSize:"14px"}}>Select Background</h5>
                                    <Radio.Group
                                        disabled={props.defaultChecked}
                                        defaultValue={1}
                                        value={props.background}
                                        onChange={props.onBackgroundChange}
                                        buttonStyle="solid"
                                        className="dpi-radio">
                                        <Radio.Button value={1} className="dpi-settings">White</Radio.Button>
                                        <Radio.Button value={2} disabled = {props.dropShadows} className="dpi-settings">Grey</Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <Col>
                                    <h5 className="modal-text" style={{fontSize:"14px"}}><Popover content={
                                    <div className="silo-shot-popup">
                                    <img  src={props.regularShadowShot} className="popup-silo-shot"/>
                                    <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Image with Plain Shadows</div>
                                    <div className="width-height-label">These will generate renders with default shadows.</div>
                                </div>}>
                                    <span style={{textDecoration:"underline"}}>Shadows</span>
                                </Popover></h5>
                                    <div className='switch-flex'><Switch style={{marginRight: 8}} checked={props.shadows} disabled={props.defaultChecked} onChange={props.onChangeShadows}></Switch><span className="category-artist gray" >{props.shadows == false ? 'Off' : 'On'}</span></div>
                                </Col>
                                <Col>
                                    <h5 className="modal-text" style={{fontSize:"16px",marginLeft: 20}}><Popover content={ props.isFloorItem == true ?
                                    <div className="silo-shot-popup">
                                    <img  src={props.dropShadowShot} className="popup-silo-shot"/>
                                    <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Image with Drop Shadows</div>
                                    <div className="width-height-label">These will generate renders with contact shadows</div>
                                    <div className="width-height-label s-modal gray" style={{marginBottom: 8,marginTop: 16}}>Note: Drop Shadows are only available with white background</div>
                                    </div> :
                                    <div className="silo-shot-popup">
                                    <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Note: Drop Shadows are only available for Floor items</div>
                                    </div>
                                    }>
                                    <span style={{textDecoration:"underline",fontSize:"14px"}}>Drop Shadows</span>
                                </Popover></h5>
                                    <div className='switch-flex' style={{marginLeft: 50}}><Switch style={{marginRight: 8}} checked={props.dropShadows} disabled={props.defaultChecked || props.background == 2 || !props.isFloorItem} onChange={props.onChangeDropShadows}></Switch><span className="category-artist gray" >{props.dropShadows == false ? 'Off' : 'On'}</span></div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>

                </div>
            </Modal>

            <Modal
                className="aspect-ratio-modal"
                visible={props.defaultSilosModal}
                maskClosable={false}
                centered
                onCancel={() => props.showDefaultSilosModal(false)}
                width={1000}
                footer={[
                    <div className='justify-in-end'>
                    <Button className="modal-okay-gray square font-14 pd-20" onClick={() => props.showDefaultSilosModal(false)}>
                        {`Cancel`}
                    </Button>,
                    <Button loading={props.defaultLoader} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                        if (!props.defaultSettingsState) {
                            props.editNextDefaultSilo(false);
                        }
                        props.setDefaultLoader(true)
                        props.defaultRequested();
                    } }>
                    Apply and Request Silo Images
                    </Button>
                    </div>
                ]}>
                <div style={{padding:"40px"}}>
                    <h2 className="manrope f-18 black-33 w-700">Default Silo Images Configuration</h2>
                    <Row type="flex">
                        <Col span={12}>
                            <div className="snapshot-modal-img">
                                <Row type="flex">
                                <Col span={2}>
                                <div id="left-default-button-scr" style={{position: "absolute", top: "50%", left:"25"}} onClick={() => props.editPreviousDefaultSilo()}>
                                <img src={'/img/leftScroll.png'}  />
                                </div>
                                </Col>
                                <Col span={20}>
                                <img src={props.modalShot} className="snapshot-modal-img" id = "snapshot-default-img">
                                </img>
                                </Col>
                                <Col span={2}>
                                <div id="right-default-button-scr" style={{position: "absolute",top: "50%"}} onClick={() => props.editNextDefaultSilo()}>
                                    <img src={'/img/rightScroll.png'}  />
                                </div>
                                </Col>
                                </Row>
                            </div>
                            <div  data_tut="reactour__defaultsnapshot">
                                <Row>
                            <Col span = {20}>
                            <div className="snapshot-controls" >
                                <div style={{display:"flex", marginLeft:"10px", marginRight:"10px"}} id="default-silo-scrollable" className="silo-scrollable">
                                {props.perspectiveImages != [] ? props.perspectiveImages.map((view,index) => (

                                    <Row>

                                        <Col span={24} style={{padding: "2px"}}>
                                            <div id="default-silo-snapshot" className="perspective-bg" style={{border: (props.perspective == index) ? "1px solid #333333" : "1px solid #e4e4e4" }} onClick={()=> props.editSelectedDefaultSilo(index)}>
                                            <img className="perspective-img-dimensions" src={view}/>
                                                <div className="persp-img-txt">{props.perspectiveLabels[index]}</div>
                                            </div>
                                        </Col>

                                    </Row>

                                )) : ""}
                                </div>

                            </div>
                            </Col>
                            <Col span = {4}>
                                <div id="right-default-button-scr" style={{position: "absolute", top: "30%"}} onClick={() => props.rightPerspectiveScroll()}>
                                <img src={'/img/rightScroll.png'}  />
                                </div>
                            </Col>
                            <input type="checkbox" id="default-settings-checkbox" style={{marginTop: "25px"}} defaultChecked={false} onChange={(e) => {props.setEditCapturedDefaultSilos(!props.editCapturedDefaultSilos); }} />
                            <label style={{marginTop: "20px", marginLeft: "10px"}}>
                            Use default settings for all images
                            </label>
                            </Row>
                        </div>
                        </Col>
                        <Col span={12} style={{paddingLeft: 20}}>
                            <div style={{display:"flex",justifyContent:"flex-start"}}>
                                <h5 className="manrope f-16 black-33 w-600">Select Orientation</h5>
                            </div>
                            <Row>
                                <Col span={24} style={{display:"flex"}}>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        if(!props.defaultSettingsState) {
                                            props.changeOrientation('Landscape')
                                            props.setAspects('Landscape','16:10');
                                        }

                                    }}>
                                        <img className="ori-img landscape" style={{opacity: props.orientation == 'Landscape' ? 1 : 0.5}} src={`/img/landscape_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        if (!props.defaultSettingsState ) {
                                            props.changeOrientation('Portrait')
                                            props.setAspects('Portrait','9:16');
                                        }

                                    }}>
                                        <img style={{opacity: props.orientation == 'Portrait' ? 1 : 0.5}} className="ori-img portrait" src={`/img/portrait_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        if (!props.defaultSettingsState) {
                                            props.changeOrientation('Square');
                                            props.setAspects('Square','1:1');
                                        }

                                    }}>
                                        <img style={{opacity: props.orientation == 'Square' ? 1 : 0.5}} className="ori-img square" src={`/img/square_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        if (!props.defaultSettingsState) {
                                            props.changeOrientation('Custom');
                                            props.setAspects('Custom','1:1');
                                        }

                                    }}>
                                        <img style={{opacity: props.orientation == 'Custom' ? 1 : 0.5}} className="ori-img custom" src={`/img/custom_s.png`}/>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{display:"flex",justifyContent:"flex-start"}}>
                                <h5  className="manrope f-16 black-33 w-600" style={{marginTop:32,display: (props.orientation == 'Custom' || props.orientation =='Square') ? "none" : "flex"}}>Select Aspect Ratio</h5>
                            </div>
                            <Row>
                                <Col span={24} style={{display: props.orientation == 'Landscape' ? "flex" : "none"}}>
                                    <div className="ori-img-bg s-image-modal" onClick={() => { if(!props.defaultSettingsState) {props.changeAspectRatio('16:10','Landscape') ; props.setAspects('Landscape','16:10');}}}>
                                        <img style={{opacity: props.selectedAspectRatio == '16:10' ? 1 : 0.5}} className="ori-img screen-16-10" src={`/img/16_10_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {if (!props.defaultSettingsState) { props.changeAspectRatio('16:9','Landscape') ; props.setAspects('Landscape','16:9');}}}>
                                        <img style={{opacity: props.selectedAspectRatio == '16:9' ? 1 : 0.5}} className="ori-img screen-16-9" src={`/img/16_9_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {if (!props.defaultSettingsState) { props.changeAspectRatio('1.91:1','Landscape') ; props.setAspects('Landscape','1.91:1');}}}>
                                        <img style={{opacity: props.selectedAspectRatio == '1.91:1' ? 1 : 0.5}} className="ori-img screen-1-9-1" src={`/img/1_9_1_s.png`}/>
                                    </div>
                                </Col>
                                <Col span={24} style={{display: props.orientation =='Portrait' ? "flex" : "none"}}>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {if (!props.defaultSettingsState) {props.changeAspectRatio('9:16','Portrait') ; props.setAspects('Portrait','9:16');}}}>
                                        <img style={{opacity: props.selectedAspectRatio == '9:16' ? 1 : 0.5}} className="ori-img screen-9-16" src={`/img/9_16_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {if (!props.defaultSettingsState) { props.changeAspectRatio('10:16','Portrait') ; props.setAspects('Portrait','10:16');}}}>
                                        <img style={{opacity: props.selectedAspectRatio == '10:16' ? 1 : 0.5}} className="ori-img screen-10-16" src={`/img/10_16_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {if (!props.defaultSettingsState) { props.changeAspectRatio('1:1.91','Portrait') ; props.setAspects('Portrait','1:1.91');}}}>
                                        <img style={{opacity: props.selectedAspectRatio == '1:1.91' ? 1 : 0.5}} className="ori-img screen-1-1-9" src={`/img/1_1_9_s.png`}/>
                                    </div>
                                </Col>
                            </Row>
                            <h5  className="manrope f-16 black-33 w-600" style={{display: props.orientation =='Custom' ? "none" : "block",fontSize:"16px",marginTop:32}}>Resolution</h5>
                            <Row style={{display: props.orientation == 'Custom' ? "none" : "flex"}}>
                                <Col>
                                    <Radio.Group disabled ={props.defaultSettingsState} style={{marginBottom: 8}} className="res-radio-settings" onChange={props.onResolutionChange} defaultValue={1} value={props.resolutionStatus}>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={3}><span style={{color: props.resolutionStatus == 3 ? "#333333" : "#999999"}}  className="radio-btn-change" >1K ({props.imageResArr[2]})</span></Radio>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={2}><span style={{color: props.resolutionStatus == 2 ? "#333333" : "#999999"}}  className="radio-btn-change" >2K ({props.imageResArr[1]})</span></Radio>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={1}><span style={{color: props.resolutionStatus == 1 ? "#333333" : "#999999"}} className="radio-btn-change" >4K ({props.imageResArr[0]})</span></Radio>

                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={4}>
                                            <span className={`radio-btn-change ${props.resolutionStatus == 4 ? `custom-res-message` : ``}`} style={{color: props.resolutionStatus == 4 ? "#333333" : "#999999"}} >Add Custom Resolution
                                            {
                                                props.resolutionStatus == 4 ?
                                                <span style={{display:"flex",marginTop: 9}}>
                                                    <div className="custom-input-box" style={{marginRight:8}}>
                                                        <span style={{marginRight:5}}>Width</span>
                                                        <div className="custom-input-contain">
                                                            <InputNumber disabled ={props.defaultSettingsState} onChange={props.onImageWidthRes}
                                                            value={props.customWRes}  min={0.1} max={8096} />
                                                        </div>
                                                    </div>

                                                    <div className="custom-input-box">
                                                        <span style={{marginRight:5}}>Height</span>
                                                        <div className="custom-input-contain">
                                                            <InputNumber disabled ={props.defaultSettingsState} onChange={props.onImageHeightRes}
                                                            value={props.customHRes} min={0.1} max={8096} />
                                                        </div>
                                                    </div>
                                                </span> : ""
                                            }
                                            </span>
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <h2  style={{display: props.orientation == 'Custom' ? "block" : "none",marginTop:32}}  className="manrope f-16 black-33 w-600">Enter Resolution</h2>
                            <Row style={{display: props.orientation == 'Custom' ? "block" : "none"}} >
                                <Col className="custom-res-row">
                                    <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                        <span className="width-height-label s-modal">Width</span>
                                        <InputNumber disabled ={props.defaultSettingsState} className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={props.imageWidth}  min={1} max={8096} onChange={props.onChangeWidthDynamically}/>
                                    </div>
                                    <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                        <span className="width-height-label s-modal">Height</span>
                                        <InputNumber disabled ={props.defaultSettingsState} className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={props.imageHeight}  min={1} max={8096} onChange={props.onChangeHeightDynamically}/>
                                    </div>
                                    <div className="custom-aspects s-modal">
                                        <span className="width-height-label s-modal">Unit</span>
                                        <Input disabled ={props.defaultSettingsState} className="width-height-label s-modal" style={{width: 68,marginLeft:10}} value={'Pixels'} readOnly autoFocus={false} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{display: props.orientation == 'Custom' ? "block" : "none",marginBottom:32}} >
                                <Col className="custom-res-row">
                                        <div className="note-bg">
                                            <span className="width-height-label s-modal" style={{color: "#262626"}}>Note:&nbsp;</span><span className="width-height-label s-modal" style={{color: "#595959"}}>The width and height you add here will be the resolution in pixels for your image.</span>
                                        </div>
                                </Col>
                            </Row>
                            <Row gutter={[32, 32]} className="justify-in-start" style={{alignItems:"flex-start"}}>
                                <Col>
                                    <h5  className="manrope f-16 black-33 w-600">Select DPI</h5>
                                    <Radio.Group

                                        disabled ={props.defaultSettingsState}
                                        defaultValue={'300'}
                                        value={props.dpi}
                                        onChange={props.onDPIChange}
                                        buttonStyle="solid"
                                        className="dpi-radio">
                                        <Radio.Button value={'300'} className="dpi-settings">300 DPI</Radio.Button>
                                        <Radio.Button value={'400'} className="dpi-settings">400 DPI</Radio.Button>
                                        <Radio.Button value={'600'} className="dpi-settings">600 DPI</Radio.Button>
                                    </Radio.Group>
                                </Col>

                            </Row>

                            <Col>
                                <h5 className="manrope f-16 black-33 w-600">Image Format</h5>
                                <Radio.Group disabled ={props.defaultSettingsState} value={props.imageFormat} className="res-radio-settings" defaultValue={'jpg'} onChange={props.onImageFormatChange} className="modal-text">
                                    <Radio style={radioStyleInline} value={'jpg'}><span className="radio-btn-change" style={{color: props.imageFormat == 'jpg' ? "#333333": "#999999"}}>JPG</span></Radio>
                                    <Radio style={radioStyleInline} value={'png'}><span className="radio-btn-change" style={{color: props.imageFormat == 'png' ? "#333333": "#999999"}}>PNG</span></Radio>
                                    <Radio style={radioStyleInline} value={'tiff'}><span className="radio-btn-change" style={{color: props.imageFormat == 'tiff' ? "#333333": "#999999"}}>TIFF</span></Radio>
                                </Radio.Group>
                            </Col>

                            <h5 className="manrope f-16 black-33 w-600" style={{marginTop: 32}}>Adjust Field of View / Focal Length</h5>
                            <Row>
                            <Col span={12} >
                                    <Radio.Group
                                        disabled ={props.defaultSettingsState}
                                        defaultValue={'fov'}
                                        value={props.fovSwitch}
                                        onChange={props.onFovSwitchChange}
                                        buttonStyle="solid"
                                        className="dpi-radio">
                                        <Radio.Button value = {'fov'} className="dpi-settings fov-settings">Field of View</Radio.Button>
                                        <Radio.Button value = {'fl'} onClick={props.setFocalLengthRangeValues} className="dpi-settings fov-settings">Focal Length</Radio.Button>
                                    </Radio.Group>
                            </Col>
                            </Row>
                            {props.fovSwitch == 'fov' ?
                            <Row>

                                <Col span={12} >
                                    <Slider
                                        disabled ={props.defaultSettingsState}
                                        marks={angle_marks}
                                        className="black-slider"
                                        min={20}
                                        max={80}
                                        onChange={props.onChangeFov}
                                        value={props.fovValue}
                                    />
                                </Col>
                                <Col span={4} className="custom-input-contain" style={{marginLeft: 16}}>
                                    <InputNumber
                                        disabled ={props.defaultSettingsState}
                                        className="input-fov"
                                        min={20}
                                        max={80}
                                        value={props.fovValue}
                                        onChange={props.onChangeFov}
                                        formatter={value => `${value}°`}
                                        parser={value => value.replace('°', '')}
                                    />
                                </Col>
                            </Row>
                            :
                            <Row>

                                <Col span={12} >
                                    <Slider
                                        disabled ={props.defaultSettingsState}
                                        marks={mm_marks}
                                        className="black-slider"
                                        min={10}
                                        max={100}
                                        onChange={props.onChangeFocalLength}
                                        value={props.focalLengthValue}
                                    />
                                </Col>
                                <Col span={4} className="custom-input-contain" style={{marginLeft: 16}}>
                                    <InputNumber
                                        disabled ={props.defaultSettingsState}
                                        className="input-fov"
                                        min={10}
                                        max={100}
                                        value={props.focalLengthValue}
                                        onChange={props.onChangeFocalLength}
                                        formatter={value => `${value}mm`}
                                        parser={value => value.replace('mm', '')}
                                    />
                                </Col>
                            </Row>
                            }
                            <Row style={{marginTop:8}}>
                                <Col style={{marginRight:32}}>
                                    <h5  className="manrope f-16 black-33 w-600" style={{marginTop:20}}>Background and Shadow Settings</h5>
                                    <Radio.Group value={props.defaultChecked} className="res-radio-settings" disabled={props.defaultSettingsState} defaultValue={true} onChange={props.onChangeDefault} className="modal-text">
                                        <Radio style={{radioStyleInline, marginBottom:24}} value={true}><span style={{color: props.defaultChecked ? "#333333" : "#999999"}} className="radio-btn-change">Use  <Popover content={
                                            <div className="silo-shot-popup">
                                            <img  src={props.modalShot} className="popup-silo-shot"/>
                                            <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Image with Default Settings</div>
                                            <div className="width-height-label">Default background and shadow settings are the ones used to generate Sample Images for you to Review your model.</div>
                                        </div>}>
                                            <span style={{textDecoration:"underline"}}>default settings</span>
                                        </Popover>
                                        </span></Radio>
                                        <Radio style={{radioStyleInline, marginBottom:24}} disabled={props.defaultSettingsState} value={false}><span style={{color: props.defaultChecked ? "#999999" : "#333333"}} className="radio-btn-change">Use custom settings</span></Radio>
                                    </Radio.Group>

                                    {props.defaultChecked ? '' :
                                    <Row style={{marginTop:8}}>
                                        <Col style={{marginRight:32}}>
                                            <h5  className="manrope f-16 black-33 w-600">Select Background</h5>
                                            <Radio.Group
                                                disabled={props.defaultSettingsState || props.defaultChecked}
                                                defaultValue={1}
                                                value={props.background}
                                                onChange={props.onBackgroundChange}
                                                buttonStyle="solid"
                                                className="dpi-radio">
                                                <Radio.Button disabled={props.defaultSettingsState} value={1} className="dpi-settings">White</Radio.Button>
                                                <Radio.Button disabled={props.defaultSettingsState || props.dropShadows} value={2} className="dpi-settings">Grey</Radio.Button>
                                            </Radio.Group>
                                        </Col>
                                        <Col>
                                            <h5 className="modal-text" style={{fontSize:"14px"}}><Popover content={
                                    <div className="silo-shot-popup">
                                    <img  src={props.regularShadowShot} className="popup-silo-shot"/>
                                    <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Image with Plain Shadows</div>
                                    <div className="width-height-label">These will generate renders with default shadows.</div>
                                </div>}>
                                    <span style={{textDecoration:"underline"}}>Shadows</span>
                                     </Popover></h5>
                                            <div className='switch-flex'><Switch style={{marginRight: 8}} checked={props.shadows} disabled={props.defaultSettingsState || props.defaultChecked}  onChange={props.onChangeShadows}></Switch><span className="category-artist gray" >{props.shadows == false ? 'Off' : 'On'}</span></div>
                                        </Col>
                                        <Col>
                                            <h5 className="modal-text" style={{fontSize:"16px",marginLeft: 20}}><Popover content={ props.isFloorItem == true ?
                                            <div className="silo-shot-popup">
                                            <img  src={props.dropShadowShot} className="popup-silo-shot"/>
                                            <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Image with Drop Shadows</div>
                                            <div className="width-height-label">These will generate renders with contact shadows</div>
                                            <div className="width-height-label s-modal gray" style={{marginBottom: 8,marginTop: 16}}>Note: Drop Shadows are only available with white background</div>
                                            </div> :
                                            <div className="silo-shot-popup">
                                            <div className="width-height-label s-modal gray" style={{marginBottom: 16,marginTop: 8}}>Note: Drop Shadows are only available for Floor items</div>
                                            </div>}>
                                            <span style={{textDecoration:"underline", fontSize:"14px"}}>Drop Shadows</span>
                                            </Popover></h5>
                                            <div className='switch-flex' style={{marginLeft: 50}}><Switch style={{marginRight: 8}} checked={props.dropShadows} disabled={props.defaultSettingsState || props.defaultChecked || props.background == 2 || !props.isFloorItem}  onChange={props.onChangeDropShadows}></Switch><span className="category-artist gray" >{props.dropShadows == false ? 'Off' : 'On'}</span></div>
                                        </Col>
                                    </Row>}
                                </Col>

                            </Row>
                        </Col>
                    </Row>


                </div>
            </Modal>


            <Modal className="modal-silo-request"
            maskClosable={false}
            visible={props.summaryModal}
            onCancel={() => {props.resetPaymentsOnCancel();props.showSummaryModal(false)}}
            centered={true}
            width={900}
            footer={[
                <div className={(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed" || props.requestStatus == "update_subscription") ? "justify-space-between" : "justify-in-end"}>
                    {(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed" || props.requestStatus == "update_subscription") ?
                    <div className="manrope f-20 black-55">
                        Total Price: <span className="manrope f-20 w-700" style={{color: "#25D6A4"}}>${props.totalPrice()}</span>
                    </div>: ''}

                    <Button className="modal-okay font-16 square" htmlType="submit" disabled={props.siloRequestLoader || props.cardSaveFlowTriggered} onClick={props.confirmRequest}>
                        {(props.requestStatus == "allowed" || props.requestStatus == "always_allowed") ? "Confirm" :
                        props.cardStatus == 0 && (props.requestStatus == "payment_required" || props.requestStatus == "not_allowed" || props.requestStatus == "update_subscription") ?"Confirm & Enter Payment Details" : "Confirm"}  {props.siloRequestLoader ? <LoadingOutlined spin />:""}
                    </Button>
                </div>
            ]}>
                <div style={{padding:"32px 48px 0 48px"}}>
                    <h2 className="manrope f-18 black-00 w-700">Summary</h2>
                    <div className="modal-heading-text">You have successfully captured your Silo Images. Please review and confirm your request. We will notify you once the Images are ready.</div>
                    <Row type="flex" style={{marginTop:"30px"}}  className={props.camera.length > 1 ? `request-silo-scrollable` : ``}>
                        {console.log("cameras are", props.camera)}
                        {props.camera.map((element, index)=> (
                            <Col span={20} style={{margin: "auto"}}>
                            {(element.camera_render != '') ?
                                <span>
                                    <img src={element.camera_render} className="request-silo-img"/>
                                    <div className="price-bottom-text left manrope f-14">
                                        {element.resolution}
                                    </div>

                                    {(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed" || props.requestStatus == "update_subscription") ?
                                    <div className="price-bottom-text right manrope f-14">
                                        Price: <span className="manrope f-14 w-700" style={{color: "#25D6A4"}}>${props.returnPrice(element.resolution)}</span>
                                    </div> : ""}

                                    <Dropdown overlay={
                                        <Menu style={{ width: "160px",fontFamily:"Avenir" }}>
                                            <Menu.Item key={0}>
                                                <a href="#"  className="select-aspect delete" onClick={() =>  props.deleteSnapshot(element.name,index)} >
                                                    Delete
                                                </a>
                                            </Menu.Item>
                                        </Menu>}
                                        placement="topRight" trigger={["click"]}>
                                        <CloseOutlined className="cross-icon-position" type="close" />
                                    </Dropdown>
                                </span> :
                                <div>
                                    <img src={element.thumbnail} className="request-silo-img"/>
                                    <div className="price-bottom-text left manrope f-14">
                                        {element.resolution}
                                    </div>

                                    {(props.requestStatus == "payment_required" || props.requestStatus == "not_allowed" || props.requestStatus == "update_subscription") ?
                                    <div className="price-bottom-text right manrope f-14">
                                        Price: <span className="manrope f-14 w-700" style={{color: "#25D6A4"}}>${props.returnPrice(element.resolution)}</span>
                                    </div> : ""}

                                    <Dropdown overlay={
                                    <Menu style={{ width: "160px",fontFamily:"Avenir" }}>
                                        <Menu.Item key={0}>
                                            <a href="#"  className="select-aspect delete" onClick={() => props.deleteSnapshot(element.name,index)} >
                                                Delete
                                            </a>
                                        </Menu.Item>
                                    </Menu>}
                                    placement="topRight" trigger={["click"]}>
                                        <CloseOutlined className="cross-icon-position" type="close" />
                                    </Dropdown>
                                </div>}
                            </Col>
                        ))}
                    </Row>
                </div>
            </Modal>
            <Modal
            onCancel={() => {window.location.href = "/products/" + props.params.id;}}
            visible={props.successModal}
            centered={true}
            footer={false}
            >
                <div style={{textAlign: "center"}}>
                    <img style={{width: 85,height:85}} src={require("../../../assets/images/success-icon.png")}></img>
                    <p style={{marginTop:"40px"}} className='manrope f-20 black'>
                        Preset Saved and Silo Images Requested!
                    </p>
                    <p className='manrope f-14 black'>
                        You’ll receive an email notification when they’re ready to download.
                    </p>
                    <div style={{display:'flex', placeContent:"center", width:"100%"}}>
                        <Button style={{margin:"25px 5px"}} className='modal-okay-blue square font-14' onClick={() => {
                                window.location.href = '/products/' + props.params.id;
                            }}>
                            Done
                        </Button>
                        <Button style={{margin:"25px 5px"}} className='modal-okay-blue invert square font-14' onClick={() => {
                            window.location.href = '/silo-tool/' + props.params.id;
                        }}>
                            Generate More Images
                        </Button>
                    </div>
                </div>
                
            </Modal>

            <Modal
                visible={props.goBackConfirmModal}
                className="silo-success-modal"
                closable={false}
                width={788}
                onCancel={() => props.setGoBackConfirmModal(false)}
                footer={[
                    <div className="justify-in-center">
                    <Button className="modal-okay-gray font-14 square" htmlType="submit" onClick={() => {
                        window.history.back();
                    }}>
                        Exit
                    </Button>
                    <Button className="modal-okay font-14 square" htmlType="submit" onClick={() => {
                        props.setGoBackConfirmModal(false)
                    }}>
                       Stay On This Page
                    </Button>
                    </div>
                ]}>
                    <div style={{padding:"16px 6px 8px 14px"}}>
                      <h2 className="share-modal-heading" style={{textAlign:"center",marginBottom: 8}}>Are you sure you want to exit?</h2>
                      <div className="modal-heading-text" style={{textAlign:"center"}}>
                        The snapshots you took will be discarded if you go back. Click on “Request Silo Images” to request your renders.
                        </div>
                    </div>
                </Modal>

                {/* Modal for showing the Overlay Settings */}
        <Modal
            className="overlay-controls-modal"
            visible={props.overlaySettingsModal}
            centered={true}
            onOk={null}
            onCancel={props.handleDiscardOverlaySettings}
            bodyStyle={{padding: 0}}
            footer={[
                <div className="justify-in-end">
                <Button  className="modal-okay-gray square font-14 pd-20" onClick={props.handleDiscardOverlaySettings}>
                    Discard Settings
                </Button>,
                <Button className="modal-okay square font-14" htmlType="submit" onClick={props.handleApplyOverlaySettings} >
                    Apply Safe Zone Settings
                </Button>
                </div>
            ]}
        >
            <div className='safe-zone-wrapper' style={{padding:'40px'}}>
                <h2 className="modal-heading-bold" style={{marginTop: 0}}>Safe Zone Settings</h2>
                <p className='manrope f-12' style={{color: '#333333', marginTop:'-10px'}}>You can set an overlay around the edges of your shot to help you frame your shots and focus on the right items.<br/>Please note that the overlay will not appear in the final processed image</p>

                <h2 className="overlay-controls-heading" style={{marginTop:"20px"}}>Show Overlay</h2>
                <div style={{marginTop:"-13px"}}><Switch checked={props.overlaySwitch} defaultChecked size="small" onChange={e => props.setShowOverlaySwitch(e)} /> {(props.overlaySwitch == true)? <span className='manrope f-12'>YES</span>:<span  className='manrope f-12'>NO</span>}</div>
                <div className='custom-overlay-opacity'>
                    <h2 className="overlay-controls-heading" style={{marginTop:"20px"}}>Overlay Opacity</h2>
                    <InputNumber size="large" className="opacity-label manrope f-12" style={{width: 130,marginTop:-13}} autoFocus={false} formatter={value => `${value}%`} parser={value => value.replace('%', '')} value={props.overlayOpacity}  min={0} max={100} onChange={props.onChangeOverlayOpacity}/>
                </div>
                <h2 className="overlay-controls-heading" style={{marginTop:"20px"}}>Overlay Color</h2>
                <div style={{display: 'flex', marginTop:"-13px"}}>
                    <div className="overlay-color-container" style={{border: (props.overlayColor == Settings.overlayColors[0] ) ?"1px solid gray":"none"}} onClick={() => props.onChangeOverlayColor(Settings.overlayColors[0])}>
                        <div className="overlay-color-box" style={{backgroundColor: Settings.overlayColors[0]}}></div>
                    </div>
                    <div className="overlay-color-container" style={{border: (props.overlayColor == Settings.overlayColors[1] ) ?"1px solid gray":"none"}} onClick={() => props.onChangeOverlayColor(Settings.overlayColors[1])}>
                        <div className="overlay-color-box" style={{backgroundColor: Settings.overlayColors[1]}}></div>
                    </div>
                    <div className="overlay-color-container" style={{border: (props.overlayColor == Settings.overlayColors[2] ) ?"1px solid gray":"none"}} onClick={() => props.onChangeOverlayColor(Settings.overlayColors[2])}>
                        <div className="overlay-color-box" style={{backgroundColor: Settings.overlayColors[2]}}></div>
                    </div>
                </div>
                <h2 className="overlay-controls-heading" style={{marginTop:"90px"}}>Overlay Size</h2>
                <Row>
                    <Col className="custom-res-row">
                        <div className="custom-overlay-aspects" style={{marginRight:20}}>
                            <span className="width-height-label">Vertical</span>
                            <InputNumber size="large" className="width-height-label" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.overlayHeight}  min={1} max={props.overlayLimit} onChange={props.onChangeOverlayHeight}/>
                        </div>
                        <div className="custom-overlay-aspects" style={{marginRight:20}}>
                            <span className="width-height-label">Horizontal</span>
                            <InputNumber size="large" className="width-height-label" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.overlayWidth}  min={1} max={props.overlayLimit} onChange={props.onChangeOverlayWidth}/>
                        </div>
                        <div className="custom-overlay-aspects">
                            <span className="width-height-label" style={{marginRight: 10}}>Unit</span>
                            <Radio.Group
                                defaultValue={300}
                                value={props.overlaySizeUnit}
                                onChange={props.onChangeOverlayUnit}
                                buttonStyle="solid"
                            >
                                <Radio.Button value={'pixels'} className="dpi-settings">Pixels</Radio.Button>
                                <Radio.Button value={'percentage'} className="dpi-settings">Percentage</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>
                <div className={"img-container"}>
                    <img onLoad={props.setTempOverlayLines} src={props.tempSnapshotOverlay}  className='snapshot-overlay-img'/>
                    <div id="overlay-temp-top" style={{width:"45%"}} className='overlay-temp-blur'>
                    </div>
                    <div id="overlay-temp-bottom" style={{width:"45%"}} className='overlay-temp-blur'>
                    </div>
                    <div id="overlay-temp-left" style={{}} className='overlay-temp-blur'>
                    </div>
                    <div id="overlay-temp-right" style={{}} className='overlay-temp-blur'>
                    </div>
                </div>
            </div>
        </Modal>


        <Modal
            visible = {props.presetModal}
            width={520}
            onCancel={()=>{props.setPresetModal(false)}}
            footer={false}        
            centered={true}
            className='silo-tool-modal'
        >
            <div>
                <div style={{marginTop:"50px"}}>
                    <p className='manrope f-20 black'>
                        Name Your Preset
                    </p>
                    <p className='manrope f-14 black'>
                    You will be able to easily apply this preset to other products in your library.
                    </p>
                </div>
                <div style={{marginTop:"20px"}}>
                    <Input placeholder='Enter your preset name' value={props.presetName} className='presets-dropdown manrope f-14 black' onChange={(event) => {props.setPresetName(event.target.value)}} />
                </div>
                <div className='justify-in-end' style={{display:'flex', margin:"20px"}}>
                    <Button style={{marginRight:"12px"}} onClick={()=> {props.setPresetModal(false)}} className='modal-okay-red square invert font-14'>
                        Cancel
                    </Button>
                    <Button disabled={props.presetName=='' || props.siloRequestLoader || props.cardSaveFlowTriggered || props.loadingProgress} onClick={()=> {props.confirmRequest(true)}} className={props.presetName=='' ? "modal-okay-gray square-disabled font-14":"modal-okay-blue square invert font-14"}>
                        Save {props.savePresetLoader ? <LoadingOutlined></LoadingOutlined>:''}
                    </Button>
                </div>
            </div> 
        </Modal>
            
        <SiloSnapshotModal {...props}/>      
        
        <Modal
            onCancel={() => {props.setLightingModal(false)}}
            visible={props.lightingModal}
            centered={true}
            footer={false}
            >
                <div style={{textAlign: "center"}}>
                    <img style={{width: 85,height:85}} src={require("../../../assets/images/success-icon.png")}></img>
                    <p style={{marginTop:"40px"}} className='manrope f-20 black'>
                        Default lighting would be used for the remaining images.
                    </p>
                    <div style={{display:'flex', placeContent:"center", width:"100%"}}>
                        <Button style={{margin:"25px 5px"}} className='modal-okay-blue square font-14' onClick={() => {
                                props.setLightingModal(false)
                            }}>
                            Stay On This Page
                        </Button>
                        <Button style={{margin:"25px 5px"}} className='modal-okay-blue invert square font-14' onClick={() => {
                                props.setLightingModal(false)
                                props.setStep(2)
                            }}>
                            Proceed
                        </Button>
                    </div>
                </div>
                
        </Modal>      
        
        <CardSaveDrawer
        setCardSaveFailure={props.setCardSaveFailure}
        saveRequestIntermedietely={props.saveRequestIntermedietely}
        visible={props.cardSaveDrawer}
        onClose={() => {props.setCardSaveDrawer(false);props.setSiloRequestLoader(false)}}
        />

        <WarningModal
        visible={props.cardSaveFailure}
        onCancel={() => props.setCardSaveFailure(false)}
        heading={"Unable to Save Payment Details"}
        text={"Failed to process payment details. Please try another payment method."}
        footer={[
        <div className="justify-in-end">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => props.setCardSaveFailure(false)}>
                Okay
            </Button>
        </div>
        ]}/>

        <SiloPhotographyPresetsModal id={props.params.id} openSiloPresetModal={props.openSiloPresetModal} selectedPreset={props.selectedPreset} allPresets={props.allPresets} setSelectedPreset={props.setSelectedPreset} setOpenSiloPresetModal={props.setOpenSiloPresetModal} />

        <Modal
            visible = {props.customLightingModal}
            width={520}
            onCancel={()=>{props.setCustomLightingModal(false)}}
            footer={false}        
            centered={true}
            className='silo-tool-modal'
        >
            <div>
                <div style={{marginTop:"50px"}}>
                    {
                        props.customLightingModalStep == 0 ? 
                        <>
                            <p className='manrope f-20 black'>
                                Name Your Custom Lighting
                            </p>
                            <p className='manrope f-14 black'>
                                Upload your reference image below. Please note, custom lighting takes up to 48 hrs to complete prior to rendering your silo images.
                            </p>
                            <Upload
                                {...Constants.getUploadProps(props.platform)}
                                accept=".jpg,.jpeg,.png,.zip"
                                multiple="true"
                                listType="text"
                                fileList = {props.customLightingImages}
                                beforeUpload={() => {
                                    props.setFileUploaded(false)
                                }}
                                onChange = {(info) => {
                                    let fileList = [...info.fileList];
                                    props.setCustomLightingImages(fileList);
                                    if (info.file.status == 'done') {
                                        props.setFileUploaded(true)
                                    }
                                }}
                                className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                            >
                                <div className="d-flex">
                                    <img className="" src={require("../../../assets/images/upload.png")} alt=""></img>
                                    <div className="ant-upload-text">
                                        <div className="up-info">Click to upload your reference files</div>
                                        <div>Supported Formats: JPG, PNG, ZIP</div>
                                    </div>
                                </div>
                            </Upload>
                        </>
                      :  
                      props.customLightingModalStep == 1 ?
                        <>
                            <p className='manrope f-20 black'>
                                Name Your Custom Lighting
                            </p>
                            <p className='manrope f-14 black'>
                                You’ll be able to use this lighting to render any product slio images.
                            </p>
                            <div style={{marginTop:"20px"}}>
                                <Input placeholder='Enter the lighting name' value={props.customLightingRigName} onChange={(event)=>props.setCustomLightingRigName(event.target.value)}/>
                            </div>
                            <div style={{marginTop:"20px"}}>
                                <Input placeholder='Add comments' value={props.customLightingRigComment} onChange={(event)=>props.setCustomLightingRigComment(event.target.value)}/>
                            </div>
                        </>
                        :
                        <>
                            <p className='manrope f-20 black'>
                                Custom Lighting Requested
                            </p>
                            <p className='manrope f-14 black'>
                                This Lighting Rig will be added to the lightings in 48 hours.
                            </p>
                        </>
                    }
                    
                    
                </div>
                
                <div className='justify-in-end' style={{display:'flex', margin:"20px"}}>
                    {
                        props.customLightingModalStep == 2 ?
                            ''
                        :
                        <Button style={{marginRight:"12px"}} onClick={()=> {
                            if (props.customLightingModalStep == 0)
                                props.setCustomLightingModal(false)
                            if (props.customLightingModalStep == 1)
                                props.setCustomLightingModalStep(0)
                            
                        }} className='modal-okay-red square invert font-14'>
                            {props.customLightingModalStep == 0 ? 'Cancel' : 'Back'}
                        </Button>    
                    }
                    
                    <Button disabled={props.customLightingModalStep == 1 && props.customLightingRigName == '' || props.customLightingModalStep == 0 && !props.fileUploaded } onClick={()=> {
                        if (props.customLightingModalStep == 0)
                            props.setCustomLightingModalStep(1)
                        if (props.customLightingModalStep == 1) {
                            let payload = {
                                'rig_name' : props.customLightingRigName,
                                'customer_username' : localStorage.getItem('username'),
                                'comments' : props.customLightingRigComment,
                                'reference_images' : props.customLightingImages,
                                'status' : 'in_progress',
                                'action' : 'add_lighting_rig'
                            }
                            if (localStorage.getItem('company_id')) {
                                payload['company_id'] = localStorage.getItem('company_id')
                            }
                            axios.post(ENVIRONMENT.CUSTOMER_LIGHT_RIG_CONTROLLER, payload)
                            .then((res) => {
                                props.setCustomLightingModalStep(2)
                            })
                        }
                        if (props.customLightingModalStep == 2) { 
                            props.setCustomLightingModal(false)                            
                        }
                    }} 
                    className={props.customLightingModalStep == 1 && props.customLightingRigName == '' || props.customLightingModalStep == 0 && !props.fileUploaded ? "modal-okay-gray square-disabled font-14":"modal-okay-blue square invert"}
                    >
                        {props.customLightingModalStep == 0 ? 'Continue' : props.customLightingModalStep == 1 ?  'Save' : 'Close'}
                    </Button>
                </div>
            </div> 
        </Modal>


        <Tour
            steps={steps}
            isOpen={props.isTourOpen}
            maskClassName="custom-mask"
            className="helper"
            rounded={4}
            startAt={0}
            disableDotsNavigation={true}
            closeWithMask={false}
            lastStepNextButton={<Button type="primary" className="modal-okay square" style={{position:"absolute", right: 20, bottom: 20}}><span className="help-btn-txt" onClick={() => {
                if (!props.getCookie("silo-help")) {
                    props.setCookie("silo-help",true, 365);
                }
            }}>Okay</span></Button>}
            nextButton={<Button type="primary" className="modal-okay square" style={{position:"absolute", right: 20, bottom: 20}}><span className="help-btn-txt">Next</span></Button>}
            prevButton={<span></span>}
            showCloseButton={false}
            showNavigation={false}
            showNavigationNumber={false}
            showNumber={false}
            accentColor="#D93025"
            onRequestClose={() => props.setIsTourOpen(false)}
        />
         <SubscriptionPackageUpdate
        subscriptionPlan={props.subscriptionPlan}
        setSubscriptionPlan={props.setSubscriptionPlan}
        currentPackage={props.currentPackage}
        isSubscriptionActive={props.isSubscriptionActive}
        currentSubscriptionPlan={props.currentPackage}
        visible={props.subscriptionModal}
        onCancel={props.cancelSubscriptionModal}
        heading={<span>You have suceeded the quota for requesting silo renders in your current plan. Do you wannt to upgrade your current plan?</span>}
        text={<span>Your current package is selected by default. Select another package to upgrade. </span>}
        footer={[
            <span className="justify-in-end">
              <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={props.cancelSubscriptionModal}>
                  Cancel
              </Button>
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={props.upgradeSubscriptionPlan}>
                  {props.subscriptionLoader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Yes </span>}
                </Button>
            </span>
          ]}
        />
        <InAppPurchaseLimitModal visible={props.inAppLimitExceededModal} setVisible={props.setInAppLimitExceededModal} />
        </div>
}

export default SiloPhotographyModals;