import React, { useEffect, useState, useRef } from 'react';
import {  Row, Col,Form, Upload, Button,Modal, Input,Tabs,  Collapse, Select} from 'antd';
import {
  LoadingOutlined, WindowsFilled,
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import axios from 'axios';
import AWS from 'aws-sdk';
import * as JSZip from 'jszip';
import ENVIRONMENT  from '../../../../environments'
import DottedLoader from '../../../App/DottedLoader/DottedLoader';
import * as Constants from "../../CustomerComponents/Constants";
import { FLAG_IF_UNHAPPY_GUIDELINES } from '../../../../environments/env';
import ComplaintImageViewer from './ComplaintImageViewer';
import { BUCKET_NAME } from '../../../../environments/env';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const ComplaintRejectionModal = (props) => {
    const [refFileList, setRefFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [artistList, setArtistList] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [form] = Form.useForm();

    const handleCancel = () => {
        props.setRejectionConfirmationModal(false);
      }
    

    const loadArtistsData = () => {
        // Load super artist data
        axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
        })
          .then(res => {
              let data = [];
              for(let i=0; i<res.data.length; i++){
                  if(res.data[i].is_superartist === true && res.data[i].is_active === true){
                      data.push(res.data[i])
                  }
              }
              console.log('000', data)
            setArtistList(data)
          });
      }
  
      useEffect(() => {
        loadArtistsData();
      }, []);

    const handleChangeRefFile = info => {
        let fileList = [...info.fileList];
        setRefFileList(fileList);
        setUploading(true);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploadError('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
    
        }
      };
    
    
    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const handleSubmit = (e) => {
        let values = e;
        // e.preventDefault();
        // form.current.validateFields().then(values => {
        if (values.reference_files == undefined) {
            values.reference_files = null;
        }

        let uploadStatus = validateFileUploads(refFileList);
        if (uploadStatus == 'uploading') {
            setUploadError('Uploads are in progress, please wait till file uploads are completed.')
        }
        else if (uploadStatus == 'error') {
            setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
        }
        else if (uploadStatus == 'done' || uploadStatus == 'not_started') {
            setButtonLoader(true);
            let payload = {
                'complaint_id': props.complaint_id,
                'rejection_message': values['msg'],
                'status': 'sent_back_to_artist',
                'admin_username': localStorage.getItem('username')
            }
            if(refFileList){
                payload['reference_files'] = refFileList;
            }
            if(values['assigned_artist']){
                payload['assigned_to'] = values['assigned_artist']
            }
            else {
                payload['assigned_to'] = props.assigned_to
            }
            axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
            .then(res => {
                setButtonLoader(false);
                props.setRejectionConfirmationModal(false);
                window.location.reload();
            })
        }
        // });
    }

    return (
        <React.Fragment>
            {/* Send back to Super Artist Confirmation */}
            <Modal bodyStyle={{padding: 0}}
                className="aspect-ratio-modal small"
                visible={props.rejectionConfirmationModal}
                // onOk={handleSubmit}
                onCancel={handleCancel}
                footer={[
                    <div className="justify-in-end">
                        <Button  className="modal-okay-gray square font-14" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button disabled={buttonLoader} className="modal-okay square font-14" onClick={() => document.getElementById("complaint-rejection-button").click()}>
                            Assign&nbsp;{buttonLoader ? <LoadingOutlined/> : ""}
                        </Button>
                    </div>
                ]}>
                <div style={{padding:"32px 32px 24px 32px"}}>
                    <div className="manrope f-24 black-55" >
                        Confirm Rejection
                    </div>
                    <Form className="input-form" onFinish={handleSubmit} style={{marginTop: 24}} initialValues={{assigned_artist: props.assigned_to !="" ? props.assigned_to : undefined }}>
                    <div className="manrope f-16 black-55" style={{marginBottom: 8}}>Rejection Message {<p style={{color: 'red', display: 'inline'}}>*</p>}</div>
                    <Form.Item name="msg" rules={[{required: true, message: 'Please enter Rejection Message'}]}>
                        <TextArea
                        rows={5}
                        className="manrope f-16 grey-77"
                        style={{height: "auto !important"}}
                        placeholder = "Enter Rejection Message"
                        />
                    </Form.Item>
                    <div className="manrope f-16 black-55" style={{marginBottom: 8}}>Reference Files</div>
                    <Form.Item name ={'reference_files'}>
                        <Upload style={{marginLeft: 0}}
                        // accept=".zip,.png,.jpg,.jpeg,.pdf"
                        onRemove={file => {
                            const index = refFileList.indexOf(file);
                            const newFileList = refFileList.slice();
                            newFileList.splice(index, 1);
                            setRefFileList(newFileList);
                            console.log(newFileList)
                            newFileList.forEach((file) => {
                                if (file.status !== "error"){
                                    setHasError(false)
                                }
                                else {
                                    setHasError(true)
                                }
                            })
                            return true;
                        }}
                        fileList={refFileList}
                        onChange = {handleChangeRefFile}
                        openFileDialogOnClick={!(validateFileUploads(refFileList) == 'error')}
                        {...Constants.getUploadProps(props.platform)} 
                        multiple="true" 
                        listType="text" 
                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-height-confirm ml-0" >
                            <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                            <div style={{textAlign:"center"}}>
                                <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                <div className="ant-upload-text ant-upload-text-w" >
                                    <div className="up-info">Drop your reference files.</div>
                                    {/* <div>SUPPORTED FORMATS: ZIP, JPG, PNG, PDF, DOCS</div> */}
                                </div>
                                {(validateFileUploads(refFileList) == 'error') && (<Button
                                    onClick={() => Constants.triggerFailedFilesUpload('reference_files', refFileList, setRefFileList)}
                                    disabled={refFileList.length === 0}
                                    style={{position:'relative',top: 0}}
                                    loading={uploading}
                                    className="retry-btn" ghost
                                    >
                                    {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                </Button>)}
                            </div>
                            </div>
                        </Upload>
                    </Form.Item>
                    {(uploadError != '')?
                    <div className="ant-legacy-form-explain" style={{color: "#ff4d4f", textAlign: "center"}}>{uploadError}</div> :""}
                    
                    {(validateFileUploads(refFileList) == 'error') ? 
                    <div className='retry-error-message left'>{errorMessage}</div>
                    : ''}
                    <div className="manrope f-16 black-55" style={{marginBottom: 8, marginTop: 10}}>Assigned Super Artist {<p style={{color: 'red', display: 'inline'}}>*</p>}</div>
                    <Form.Item name="assigned_artist" colon={false} rules={[{required: true, message: 'Please select Super Artist from the List.'}]}>
                        <Select
                            style={{color:"#333333"}}
                            placeholder="Select Super Artist">
                            {artistList.map((ele, index) => {
                            return <Option key={index} value={ele.artist_profile}>{ele.artist_profile}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Button id="complaint-rejection-button" type="primary" htmlType="submit" style={{"display": "none"}}>
                        Submit
                    </Button>
                    </Form>
                </div>
            </Modal>
        </React.Fragment>
    
    )

}

export default ComplaintRejectionModal;