import React, { useContext, useState } from 'react';
import ENVIRONMENT from '../../../../environments';
import { Button, Dropdown, Space, Card, Tooltip, Checkbox, InputNumber, Badge } from 'antd';
import './ProductGrid.scss'
import _ from "lodash" // Import the entire lodash library
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import { CheckOutlined, PlusOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Grid, AutoSizer } from 'react-virtualized';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext';
import { useEffect } from 'react';
import EntityConstants from '../EntityCard/Constants';

const entityData = EntityConstants.entity_data;

const SpaceAreaSelection = (props) => {

    const toggleAreaSelection = (area) => {
        // Clone the products array from props
        let products = _.cloneDeep(props.productsAddedToScene);

        // Find the product with the matching product_id
        const productIndex = products.findIndex(product => product.product_id === props.id);

        if (productIndex !== -1) {
            const product = products[productIndex];
            const areas = product.areas;

            // Check if the area exists in the areas array
            const areaIndex = areas.indexOf(area);

            if (areaIndex !== -1) {
                // If the area exists, remove it
                areas.splice(areaIndex, 1);

                // If all areas are removed, delete the product entry
                if (areas.length === 0) {
                    products.splice(productIndex, 1);
                }
            } else {
                // If the area doesn't exist, add it
                areas.push(area);
            }
        } else {
            // If the product_id doesn't exist, create a new entry
            products.push({ product_id: props.id, area: area, placement_type: props.product.placement_type, 
                platform: props.product.platform, category: props.product.category, material_type: props.product.material_type});
        }

        props.setProductsAddedToScene(products);
    };


    const checkIfAreaSelected = (area) => {
        const productIndex = props.productsAddedToScene.findIndex(product => product.product_id == props.id && product.areas.includes(area));

        if (productIndex !== -1) {
            return true;
        }
        return false;
    }


    return (<Dropdown overlay={<div className='comment-option-dropdown'>
        {props.space_areas.map((area, index) => (
            <Space key={index}
                className='area-pd-space justify-space-between'>
                <p className='manrope f-14 lh-28 mb-0'>{area}</p>
                <Button ghost className={`item-add-btn justify-in-center manrope f-14 lh-28 ${checkIfAreaSelected(area) ? `red` : `blue`}`} onClick={() => toggleAreaSelection(area)}>
                    {checkIfAreaSelected(area) ? 'Remove' : 'Add'}
                </Button>
            </Space>
        ))}
    </div>}
        trigger={['click']}>
        <PlusOutlined
            title="Add To Scene"
            className="item-icons plus"
        />
    </Dropdown>);
}

const ProductGrid = () => {
    const { searchValue, displayedData, space_areas, collection_products, deletedProducts, libraryType, collection_id,
        productsAddedToScene, setProductsAddedToScene, filtersApplied,
        returnSearchValFunction, productsForLibrary, setProductsForLibrary, appliedFiltersList,
        productsRestoredToScene, setProductsRestoredToScene, requestStatusProduct, restoreCount, setRestoreCount } = useContext(AddProductsToSceneContext);

    const [, updateState] = React.useState();
    const [productsData, setProductsData] = useState([])
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(0);
    const maxColWidth = 280;

    useEffect(() => {
        let data = getDataForProduct()
        setProductsData(data)
    }, [appliedFiltersList, searchValue, displayedData])

    const handleAddAction = (product) => {
        if(productsAddedToScene.find(ele => ele.product_id === product.id)){
            setProductsAddedToScene(productsAddedToScene.filter((ele) => ele.product_id !== product.id));
        }
        else
        {
            let products = _.cloneDeep(productsAddedToScene);
            products.push({ product_id: product.id, placement_type: product.placement_type, 
                platform: product.platform, category: product.category, material_type: product.material_type});
            setProductsAddedToScene(products);
        }
    }

    const handleRestoreAction = (product, restoreCountNum) => {
        if(productsRestoredToScene.find(ele => ele.id === product.id)){
            setProductsRestoredToScene(productsRestoredToScene.filter((ele) => ele.id !== product.id));
        }
        else{
            let products = _.cloneDeep(productsRestoredToScene);
            products.push(product);
            setProductsRestoredToScene(products);
        }
        if (!restoreCount[product.id.toString()]) {
            restoreCount[product.id.toString()] = restoreCountNum
            setRestoreCount(restoreCount)
        }
    }

    const checkIfProductExists = (product) => {
        const productIndex = productsAddedToScene.findIndex(ele => ele.product_id == product.id);
        if (productIndex !== -1) {
            return true;
        }
        return false;
    }

    const checkIfItemNotFreeOrAddedToLibrary = (product) => {
        return libraryType == "store" && (product.item_type == 'not_shared') && (product.price != 0 || product.price != '');
    }


    const checkIfItemInLibrary = (product) => {
        return product.item_type != "shared";
    }

    const getDataForProduct = () => {
        let product_data = displayedData;
        if (libraryType == "store") {
            product_data = product_data.filter(product => checkIfItemInLibrary(product));
        }
        if (searchValue == '' && !filtersApplied) {
            product_data = product_data;
        } else {
            if (searchValue != '' || filtersApplied) {
                if (searchValue != '' && !filtersApplied) {
                    product_data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, product_data)
                } else if (searchValue == '' && filtersApplied) {
                    product_data = product_data.filter((entity) => { return returnSearchValFunction(entity) == true })
                    product_data = Utilities.getRelevanceBasedFilteredResults(product_data, appliedFiltersList)
                } else if (searchValue != '' && filtersApplied) {
                    let filter_data = product_data.filter((entity) => { return returnSearchValFunction(entity) == true })
                    product_data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, filter_data);
                    product_data = Utilities.getRelevanceBasedFilteredResults(product_data, appliedFiltersList)
                }
            }
        }
        return product_data;
    }

    // no of rows based on the data length
    useEffect(() => {   
        setRowCount(Math.ceil(productsData.length / columnCount));
    }, [productsData])

    // column widths are stored in a state by index
    const [columnWidths, setColumnWidths] = useState(() => {
        const containerWidth = window.innerWidth - 96; // Get the width of the container element
        const columnWidthPercentage = 25; // Set the desired column width percentage

        const columnWidth = ((containerWidth * columnWidthPercentage) / 95) - 24;
        const initialWidths = new Array(columnCount).fill(columnWidth); // Set initial column widths here
        return initialWidths;
    });

    // function to handle column width changes
    const handleColumnWidthChange = ({ index, width }) => {
        setColumnWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width;
            return newWidths;
        });
    };

    const addProductToLibrary = (e) => {
        let product_id = e.target.product_id;
        let already_added_products = _.cloneDeep(productsForLibrary);
        if (productsForLibrary.includes(product_id)) {
            already_added_products = productsForLibrary.filter((ele) => ele != product_id);
            setProductsForLibrary(already_added_products);
        } else {
            already_added_products.push(product_id);
            setProductsForLibrary(already_added_products);
        }

    }

    const getEntityDimensions = (ele) => {
        if (Array.isArray(ele.dimensions)) {
          return !!ele.dimensions.length ? ele.dimensions.join(" x ") : "N/A";
        } else if (ele.dimensions) {
          return `D${Math.trunc(ele.dimensions.depth)}  x  W${Math.trunc(
            ele.dimensions.width
          )}  x H${Math.trunc(ele.dimensions.height)}`;
        } else {
          return "N/A";
        }
      };

    const getColumnWidth = ({ index }) => columnWidths[index];

    // function to render an individual cell in the grid
    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const itemIndex = rowIndex * columnCount + columnIndex;
        const product = productsData[itemIndex] && productsData[itemIndex];

        if (!product) {
            return null;
        }

        const numberOfDeletedInstances = deletedProducts.filter((deletedProduct)=>{
            return deletedProduct == product.id.toString()
         }).length;
        
        const entity_type = 'product';

        let restoreCountNum = restoreCount[product.id.toString()];
        if (!restoreCount[product.id.toString()] && numberOfDeletedInstances > 0) {
            restoreCountNum = numberOfDeletedInstances
        }
        
        let updateTime = Utilities.timestampToTimeSince(product.last_modified_stamp);
        if (updateTime.includes("h")) {
            updateTime = "Today";
        }

        return (
            <div key={key} style={style}>
                <Card className={`entity-card ${(!(requestStatusProduct == 'always_allowed' || requestStatusProduct == 'allowed') && checkIfItemNotFreeOrAddedToLibrary(product)) || collection_products.includes(product.id.toString()) ? `product-hover` : ``}`}
                    bordered={false}
                    bodyStyle={{ padding: "5px" }}
                    data-tut="reactour__approved_scene"
                    style={{ width: "94%" }}>
                    <div className="stacked-project-card-inner">
                    {!(requestStatusProduct == 'always_allowed' || requestStatusProduct == 'allowed') && checkIfItemNotFreeOrAddedToLibrary(product) ?
                        <a href='/store' target='_blank'>
                            <Tooltip title={<span className='manrope f-12 white'>Please go to store to purchase this item</span>}>
                                <img src='/img/redirect-icon.svg' className="item-icons plus" />
                            </Tooltip>
                        </a>
                        : 
                        numberOfDeletedInstances > 0 ? 
                            <>
                            {productsRestoredToScene.find(restoredProduct => restoredProduct.id == product.id) ? (
                                <CheckOutlined 
                                    title="Added To Scene"
                                    className="item-icons tick" 
                                    onClick={() => handleRestoreAction(product, restoreCountNum)}
                                />
                            ) : (
                                <img 
                                    src='/img/Restore.svg' 
                                    className="item-icons plus" 
                                    onClick={() => handleRestoreAction(product, restoreCountNum)}
                                />
                            )}
                            {
                                numberOfDeletedInstances > 1 &&
                                <Tooltip title="Number of deleted instances of this product">
                                <div style={{ position: "absolute", top: "6px", right: "6px", zIndex: "1000" }}>
                                    <Badge style={{ backgroundColor: "#276dd7" }} count={numberOfDeletedInstances} />
                                </div>
                            </Tooltip>}
                        </>
                        :
                        collection_products.includes(product.id.toString()) ?
                        <Tooltip title={<span className='manrope f-12 white'>Please go to scene creator to delete this product</span>}>
                            <CheckOutlined title="Added To Scene"
                                className="item-icons tick" />
                        </Tooltip>
                        : 
                        (checkIfProductExists(product)) || collection_products.includes(product.id.toString()) ?
                            <CheckOutlined title="Added To Scene"
                                className="item-icons tick" 
                                onClick={() => handleAddAction(product)} />
                            :
                            <span>
                                {(space_areas.length == 0) ?
                                    <PlusOutlined
                                        title="Add To Scene"
                                        className="item-icons plus"
                                        onClick={() => handleAddAction(product)}
                                    /> :
                                    <SpaceAreaSelection
                                        productsAddedToScene={productsAddedToScene}
                                        setProductsAddedToScene={setProductsAddedToScene}
                                        libraryType={libraryType}
                                        product={product}
                                        collection_id={collection_id}
                                        space_areas={space_areas}
                                        id={product.id} />
                                }
                            </span>
                    }
                    <a className="suggested-product z-1">
                        <div className="box">
                            <img key={product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform)) || require("../../../../assets/images/chair.png")}
                                className="product-image z- product-img-specs"
                                src={product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform)) || require("../../../../assets/images/chair.png")} 
                                style={{ objectFit: "contain", width: "100%", height: 260 }}/>
                        </div>

                        <div style={{ margin: "2px 10px 0px 10px" }}>
                        <div className="tag-section">
                        <div className={`tag ${entityData[entity_type].className}`}>
                            <span>{entityData[entity_type].title}</span>
                        </div>
                        <div className={`tag ${updateTime == "Today" && "today-tag"}`}>
                            <ClockCircleOutlined />
                            <span className="ml-4">{updateTime}</span>
                        </div>
                        </div>
                        <div className="entity-name">
                        <Tooltip title={product.name}>
                            <span className="manrope f-14 w-500 card-name">
                            {product.name}
                            </span>
                        </Tooltip>
                        </div>
                        <div className="ml-8 mb-10">
                        <div>
                            <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                            Category
                            </span>
                            <span className="manrope f-14 w-500 black-d9 ml-8">
                            {product.category || "N/A"}
                            </span>
                        </div>
                        <div>
                            <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                            Item ID
                            </span>
                            <span className="manrope f-14 w-500 black-d9 ml-8">
                            {product.brand_id || "N/A"}
                            </span>
                        </div>
                        <div>
                            <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                            Dims
                            </span>
                            <span className="manrope f-14 w-500 black-d9 ml-8">
                            {getEntityDimensions(product)}
                            </span>
                        </div>
                        </div>
                    </div>
                        {libraryType == "store" && ((requestStatusProduct == 'always_allowed' || requestStatusProduct == 'allowed') || !checkIfItemNotFreeOrAddedToLibrary(product)) &&
                            <div className="justify-in-start text-left" style={{ marginBottom: "2px", marginLeft: "17px" }}>
                                <Checkbox disabled={(!checkIfProductExists(product)) && !collection_products.includes(product.id)} className="manrope f-12 grey-77"
                                    product_id={product.id}
                                    defaultChecked={productsForLibrary.includes(product.id)}
                                    onChange={e => addProductToLibrary(e)} >Add product to library as well</Checkbox>
                            </div>}
                    </a>
                    </div>
                </Card>
            </div>
        );
    };

    return (
        <>
            <AutoSizer>
                {({ height, width }) => {
                    const colCount =
                    (Math.floor(width / maxColWidth) > 4 ?
                      4 :
                      Math.floor(width / maxColWidth)) || 1;
                    setColumnCount(colCount);
                    setRowCount(Math.ceil(productsData.length / colCount));
                    const colWidth = width / colCount;
                    return (
                        <Grid
                            className='product-tab-content-scrollable'
                            cellRenderer={cellRenderer}
                            columnCount={columnCount}
                            columnWidth={colWidth}
                            height={height}
                            rowCount={rowCount}
                            estimatedRowSize={95}
                            rowHeight={libraryType == "store" ? 484 : 460}
                            width={width + 10}
                            onColumnResized={handleColumnWidthChange}
                        />)
                }}
            </AutoSizer>
        </>
    );

}

export default ProductGrid;
