import React from 'react';
import { Card, Row, Col, Radio, Popover, Upload, Tooltip } from 'antd';
import { Form } from '@ant-design/compatible';
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import * as Constants from '../../CustomerComponents/Constants';
import * as Styles from '../../../../Styles';
import { UploadOutlined } from '@ant-design/icons';

const HiddenCategory = (props) => {

    return(
    <Row style={{marginTop: 20,width: "100%"}}>
        <Col span={24}>
            <h1 className="manrope f-14 black-14 w-600" style={{marginBottom: 5}}>Bedding Type</h1>
            <Radio.Group value={props.hiddenCategoryIndex}  onChange={props.onChangeHiddenCategory} className="model-type-radio justify-align-start" style={{marginBottom: 10,flexWrap:"unset", height: '71%'}}>
                {Object.values(FileConstants.HIDDEN_BEDDING_CATEGORY)[0].map((cat, index) => (
                    <Tooltip title={cat.attachment ? <span className='manrope f-12 white'>An image of the bedding is required for this selection - please upload</span>: ''}>
                    <Radio style={{marginTop: 12,width: "100%",whiteSpace:"break-spaces", height: cat.attachment ? '100%': 'unset'}} value={index}>
                        <span className="manrope f-14 black-55" >
                            {cat.attachment && <span className="manrope f-14 red-ff w-600" >*&nbsp;</span>}
                            {cat.heading} {cat.price_text ? <span className="manrope f-14 green"> {cat.price_text} </span>: ''}
                            <div style={{marginTop: 12,marginBottom: 12}} className="manrope f-10 grey-77">{cat.subtext}</div>
                            {cat.attachment ? 
                            <div style={{width: "100%"}} className={props.hiddenCategoryFilelist.length == 0 ? "bedding-card center" : 'bedding-card'}>
                                <Popover content={<img className="bedding-img bedding-160" src={cat.image}/>}>
                                    <img style={{display: props.hiddenCategoryFilelist.length > 0 ? "none" : "inline-block",marginRight: 8}} className="bedding-img" src={cat.image}/>
                                </Popover>
                                <Form.Item
                                style={{ display: props.hiddenCategoryFilelist && props.hiddenCategoryFilelist.length == 1 ? 'content' : 'flex'}}
                                colon={false}
                                label={''}
                                className="picture-card-custom-88"
                                name="hidden_custom_file"
                                rules={[{ required: false, message: 'You must upload an image.' }]}>
                                    <Upload
                                        className={"upload-picture-card-88"}
                                        accept=".jpg,.jpeg,.png"
                                        multiple={true}
                                        onChange = {props.handleChangeHiddenCategoryFile}
                                        fileList = {props.hiddenCategoryFilelist}
                                        progress= {Styles.progress_bar}
                                        {...(Constants.getUploadProps(props.platform))}
                                        onRemove={file => {
                                            const index = props.hiddenCategoryFilelist.indexOf(file);
                                            const newFileList = props.hiddenCategoryFilelist.slice();
                                            newFileList.splice(index, 1);
                                            props.setHiddenCategoryFilelist(newFileList);
                                            
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error"){
                                                    props.setHasError(false)
                                                }
                                                else {
                                                    props.setHasError(true)
                                                }
                                            })
                                            return true;
                                        }}
                                        listType={"picture-card"}>
                                            {Utilities.validateFileUploads(props.hiddenCategoryFilelist) == "done" || Utilities.validateFileUploads(props.hiddenCategoryFilelist) == "uploading" ? 
                                            <div className="manrope f-12 blue w-600">
                                                <UploadOutlined style={{marginBottom: 2}}/>
                                                <div>Upload</div>
                                                <div className="manrope f-12 grey-99 italic">(jpg,png)</div>
                                            </div> : 
                                            Utilities.validateFileUploads(props.hiddenCategoryFilelist) == "error" ? 
                                            <span>
                                                <div className="justify-in-center">
                                                    <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                    <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                </div>                        
                                            </span> :
                                            (Utilities.validateFileUploads(props.hiddenCategoryFilelist) == "not_started" ? 
                                                <div className="manrope f-12 blue w-600">
                                                    <UploadOutlined style={{marginBottom: 2}}/>
                                                    <div>Upload</div>
                                                    <div className="manrope f-12 grey-99 italic">(jpg,png)</div>
                                                </div>: '')}
                                    </Upload>
                                </Form.Item>
                            </div>
                            :    
                            <Popover content={<img className="bedding-img bedding-160" src={cat.image}/>}>
                            <img className="bedding-img" src={cat.image}/>
                            </Popover>}
                        </span>
                    </Radio>
                    </Tooltip>
                ))}
            </Radio.Group>
        </Col>
    </Row>);
}

export default HiddenCategory;
