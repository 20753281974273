import React, { useEffect } from "react";
import AssetCard from "./AssetCard";

const AssetGrid = ({ assetsCollection, platform }) => {
  return (
    <div className="flex flex-row" style={{ flexWrap: "wrap" }}>
      {assetsCollection.map((asset) =>
        asset.uid ? (
          <AssetCard
            platform={platform}
            uid={asset?.uid}
            name={asset?.name}
            link={asset?.link}
          />
        ) : (
          <AssetCard link={asset?.link} name={asset.name} />
        )
      )}
    </div>
  );
};

export default AssetGrid;
